import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../../../components/Typography';
import TextInput from '../../../../components/TextInput';
import FileUploadInput from '../../../../components/FileUploadInput';
import Switch from '../../../../components/Switch';
import { SOURCE_PRIVACY, SOURCE_LOGO } from './SourceDetails.constants';

const SourceDetailsAdditionalInfo = ({
  editMode,
  handleChanges,
  handleChangesEvent,
  sourceDetails,
  errorFields
}) => {
  const disableEdit = !editMode;

  return (
    <div className="additionalInfo">
      <div className="additionalInfo_block">
        <Typography variant="h5">Source Logo</Typography>
        <FileUploadInput
          label="Logo"
          onChange={handleChanges}
          // accept='image/*'
          isDisabled={disableEdit}
          name={SOURCE_LOGO.logo_image}
          uploadedFiles={sourceDetails[SOURCE_LOGO.logo_image]}
        />
        {disableEdit && sourceDetails[SOURCE_LOGO.logo_url] && (
          <img
            className="additionalInfo_logoImage"
            src={sourceDetails[SOURCE_LOGO.logo_url]}
            alt={sourceDetails[SOURCE_LOGO.logo_url].substring(0, 30)}
          />
        )}
        <TextInput
          name={SOURCE_LOGO.logo_url}
          label="Logo URL"
          value={sourceDetails[SOURCE_LOGO.logo_url]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isError={errorFields.includes(SOURCE_LOGO.logo_url)}
          isLink
        />
      </div>
      <div className="additionalInfo_block">
        <Typography variant="h5">Premium Content</Typography>
        <Switch
          name={SOURCE_PRIVACY.premium}
          label="Premium Content Provider"
          value={sourceDetails[SOURCE_PRIVACY.premium]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        />
      </div>
    </div>
  );
};

SourceDetailsAdditionalInfo.propTypes = {
  editMode: PropTypes.bool,
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  crawlDetails: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

SourceDetailsAdditionalInfo.defaultProps = {
  editMode: false,
  crawlDetails: {},
  errorFields: []
};

export default SourceDetailsAdditionalInfo;
