export enum CRAWLER_ATTRIBUTES {
  ID = 'id',
  SPIDER_NAME = 'spider_name',
  IS_ACTIVE = 'is_active',
  IS_RESOURCE = 'is_resource',
  STATUS = 'status',
  STATE = 'state',
  RUN_STATUS = 'run_status',
  NEWS_SOURCE = 'news_source',
  EXTERNAL_API_ID = 'external_api_id',
  SOURCE_URL = 'source_url',
  JURISDICTION = 'jurisdiction',
  AGENCY = 'agency',
  TYPE = 'type',
  CATEGORY = 'category',
  TOTAL_RUNS = 'total_runs',
  LAST_RUN = 'last_run',
  FIRST_RUN = 'first_run',
  INGESTED_AVERAGE = 'ingested_average',
  TOTAL_INGESTED = 'total_ingested',
  LAST_INGESTED = 'last_ingested',
  LAST_INGESTION_TIME = 'last_ingestion_time',
  LAST_SKIPPED = 'last_skipped',
  TOTAL_SKIPPED = 'total_skipped',
  SKIPPED_AVERAGE = 'skipped_average',
  CRAWLER_RECURRING_FREQUENCY = 'details.crawler_recur.interval',
  LAST_PROCESSED = 'crawler_statistics.last_processed',
  TOTAL_PROCESSED = 'crawler_statistics.total_processed',
  PROCESSED_AVERAGE = 'total_processed/total_runs',
  LAST_ERRORS = 'crawler_statistics.last_errors',
  TOTAL_ERRORS = 'crawler_statistics.total_errors',
  ERRORS_AVERAGE = 'total_errors/total_runs',
  TOTAL_INGESTED_SLA = 'crawler_statistics.total_ingested_sla',
  INGESTED_SLA_AVERAGE = 'total_ingested_sla/total_runs',
  TOTAL_INGESTED_OUTSIDE_SLA = 'total_ingested-total_ingested_sla',
  INGESTED_OUTSIDE_SLA_AVERAGE = 'total_ingested_outside_sla/total_runs',
  SCRAPED_AGENCIES = 'doc_scraped_agencies',
  SCRAPED_NEWS_SOURCES = 'doc_scraped_news_sources',
  SCRAPED_CATEGORIES = 'doc_scraped_categories',
  // only filters
  META_CATEGORY = 'meta_category',
  CRAWL_TOTAL_RUNS = 'crawl_total_runs',
  DOCS_PROCESSED = 'docs_processed',
  DOCS_SKIPPED = 'docs_skipped',
  DOCS_INGESTED = 'docs_ingested',
  DOCS_ERRORS = 'docs_errors',
  DOCS_WITHIN_SLA = 'docs_within_sla',
  DOCS_OUTSIDE_SLA = 'docs_outside_sla',
  LAST_UPDATED_TIME = 'last_updated_time',
  CRAWLER_FREQUENCY = 'crawler_frequency',
  MULTI_FIELD = 'multi_input',
  SPIDER_RUN_REPORT_STATUS = 'spider_run_report_status',
  SPIDER_RUN_REPORT_ID = 'spider_run_report_id',
  LAST_SPIDER_RUN_REPORT_FAILED_DOCS_STAT = 'last_spider_run_report_failed_docs_stat',
  LAST_CRAWLED = 'last_crawled',
  DEV_COMPLEXITY = 'dev_complexity'
}
