import type { DocsModalData } from './useDocsModalData.types';
import type { GlobalState } from 'shared/reducers';

import * as userSelectors from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useDocsModalData = (): DocsModalData => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<
    DocsModalData['localState']['selectedDocumentIds']
  >([]);
  const [reloadTrigger, setReloadTrigger] =
    useState<DocsModalData['localState']['reloadTrigger']>(false);

  const [isBulkReprocessModalOpen, setIsBulkReprocessModalOpen] =
    useState<DocsModalData['localState']['isBulkReprocessModalOpen']>(false);

  const localState: DocsModalData['localState'] = {
    isBulkReprocessModalOpen,
    selectedDocumentIds,
    reloadTrigger
  };

  const localActions: DocsModalData['localActions'] = useMemo(
    () => ({
      setIsBulkReprocessModalOpen,
      setSelectedDocumentIds,
      setReloadTrigger
    }),
    []
  );

  const reduxState = useSelector<GlobalState, DocsModalData['reduxState']>(state => ({
    user: userSelectors.getCurrentUser(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
