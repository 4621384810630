import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CRAWL_GENERAL, CRAWL_EXTRA_RECURRING, PUB_IN_PAST } from '../../CrawlerDetails.constants';
import Typography from '../../../../../components/Typography';
import TextInput from '../../../../../components/TextInput';
import Select from '../../../../../components/ReactSelect/Select';
import Switch from '../../../../../components/Switch';
import * as uiLib from '@compliance.ai/web-components';
import {
  AUTO_RESUME_TOOLTIP,
  CURSOR_RUN_TOOLTIP,
  UPDATE_DOC_METADATA_TOOLTIP,
  UPDATE_DOC_TOOLTIP
} from './CrawlerDetailsRecur.constants';
import './crawler-details-recur.scss';

export const CrawlerDetailsRecur = ({
  editMode,
  crawlDetails,
  handleChanges,
  handleChangesEvent,
  errorFields
}) => {
  const disableEdit = !editMode;
  const optionsPubPast = useMemo(
    () =>
      PUB_IN_PAST.map(item => ({
        value: item,
        label: item
      })),
    []
  );
  const crawlRecurDisabled = !_.get(crawlDetails, CRAWL_GENERAL.crawlerRecurActive, false);

  return (
    <div className="generalInfo">
      <div className="generalInfo_header">
        <Typography variant="h5">Recurring Settings</Typography>
      </div>
      <div className="generalInfo_body">
        {/* First row */}
        <Switch
          className="input_8 singleCheckBoxOnRow"
          name={CRAWL_GENERAL.crawlerRecurActive}
          label="Recurring Run Schedule"
          value={crawlDetails[CRAWL_GENERAL.crawlerRecurActive]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        />
        {/* Second row */}
        <Select
          className="input_2"
          label="Published in Past"
          isDisabled={disableEdit || crawlRecurDisabled}
          value={crawlDetails[CRAWL_GENERAL.pubInPast]}
          options={optionsPubPast}
          onChange={e => handleChanges(CRAWL_GENERAL.pubInPast, e)}
        />
        <TextInput
          className="input_2"
          type="number"
          name={CRAWL_GENERAL.pubInPastValue}
          value={crawlDetails[CRAWL_GENERAL.pubInPastValue]}
          isDisabled={disableEdit || crawlRecurDisabled}
          onChange={handleChangesEvent}
        />
        <TextInput
          className="input_4"
          type="number"
          label="Scheduled Frequency (hours)"
          name={CRAWL_GENERAL.frequency}
          value={crawlDetails[CRAWL_GENERAL.frequency]}
          isDisabled={disableEdit || crawlRecurDisabled}
          onChange={handleChangesEvent}
        />
        {/* Third row */}
        <div className="custom-component">
          <uiLib.Switch
            name={CRAWL_EXTRA_RECURRING.softUpdate}
            label="Update document with document version creation"
            className="input_2"
            isChecked={crawlDetails[CRAWL_EXTRA_RECURRING.softUpdate]}
            isDisabled={disableEdit || crawlRecurDisabled}
            onChange={e => handleChanges(CRAWL_EXTRA_RECURRING.softUpdate, e)}
          />
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={UPDATE_DOC_TOOLTIP}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </div>
        <div className="custom-component">
          <uiLib.Switch
            name={CRAWL_EXTRA_RECURRING.forceUpdate}
            label="Update document with document version overwrite"
            className="input_2"
            isChecked={crawlDetails[CRAWL_EXTRA_RECURRING.forceUpdate]}
            isDisabled={disableEdit || crawlRecurDisabled}
            onChange={e => handleChanges(CRAWL_EXTRA_RECURRING.forceUpdate, e)}
          />
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={UPDATE_DOC_METADATA_TOOLTIP}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </div>
        <div className="custom-component">
          <uiLib.Switch
            name={CRAWL_EXTRA_RECURRING.cursorRun}
            label="Generate Cursor Info"
            className="input_2"
            isChecked={
              crawlDetails[CRAWL_EXTRA_RECURRING.cursorRun] ||
              crawlDetails[CRAWL_EXTRA_RECURRING.autoResume]
            }
            isDisabled={disableEdit || crawlRecurDisabled}
            onChange={e => handleChanges(CRAWL_EXTRA_RECURRING.cursorRun, e)}
          />
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={CURSOR_RUN_TOOLTIP}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </div>
        <div className="custom-component">
          <uiLib.Switch
            name={CRAWL_EXTRA_RECURRING.autoResume}
            label="Resume Automatically"
            className="input_2"
            isChecked={crawlDetails[CRAWL_EXTRA_RECURRING.autoResume]}
            isDisabled={disableEdit || crawlRecurDisabled}
            onChange={e => handleChanges(CRAWL_EXTRA_RECURRING.autoResume, e)}
          />
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={AUTO_RESUME_TOOLTIP}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </div>
      </div>
    </div>
  );
};

CrawlerDetailsRecur.propTypes = {
  editMode: PropTypes.bool,
  crawlDetails: PropTypes.shape({}),
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

CrawlerDetailsRecur.defaultProps = {
  editMode: false,
  crawlDetails: {},
  errorFields: []
};
