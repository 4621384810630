import React from 'react';
import PropTypes from 'prop-types';
import { ERRORS, INFO_HELP, NAMES_LOCATION } from "../CrawlSpecCreate.constants";
import ExpandableList from "../../../components/ExpandableList";
import TextInput from "../../../components/TextInput";
import SpecElementsWrapper from "./SpecElementsWrapper";
import Checkbox from "../../../components/Checkbox_NEW";
import Typography from "../../../components/Typography";

const Location = ({
  disabled,
  show,
  element,
  changeLocation,
  errorFields
}) => {
  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changeLocation(name, value);
  };

  return (
    <SpecElementsWrapper
      show={show}
      label="Location"
      tooltip={INFO_HELP.EXAMPLE}
    >
      <TextInput
        label="Pages"
        name={NAMES_LOCATION.PAGES}
        value={element[NAMES_LOCATION.PAGES]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_LOCATION.PAGES)}
        errorText={ERRORS.locationPages}
      />
      <hr />
      <Typography variant="p">Coordinates</Typography>
      <TextInput
        label="Top"
        type="number"
        name={NAMES_LOCATION.COORDINATES_TOP}
        value={element[NAMES_LOCATION.COORDINATES_TOP]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_LOCATION.COORDINATES_TOP)}
        errorText={ERRORS.locationCoordinates}
      />
      <TextInput
        label="Bottom"
        type="number"
        name={NAMES_LOCATION.COORDINATES_BOTTOM}
        value={element[NAMES_LOCATION.COORDINATES_BOTTOM]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_LOCATION.COORDINATES_BOTTOM)}
        errorText={ERRORS.locationCoordinates}
      />
      <TextInput
        label="Left"
        type="number"
        name={NAMES_LOCATION.COORDINATES_LEFT}
        value={element[NAMES_LOCATION.COORDINATES_LEFT]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_LOCATION.COORDINATES_LEFT)}
        errorText={ERRORS.locationCoordinates}
      />
      <TextInput
        label="Right"
        type="number"
        name={NAMES_LOCATION.COORDINATES_RIGHT}
        value={element[NAMES_LOCATION.COORDINATES_RIGHT]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_LOCATION.COORDINATES_RIGHT)}
        errorText={ERRORS.locationCoordinates}
      />
      <hr />
      <TextInput
        label="Margins"
        type="number"
        name={NAMES_LOCATION.MARGINS}
        value={element[NAMES_LOCATION.MARGINS]}
        // isDisabled={disabled}
        isDisabled
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_LOCATION.MARGINS)}
        errorText={ERRORS.locationCoordinates}
      />
      <Checkbox
        label="Remove Prepositions"
        disabled={disabled}
        onClick={checked => changeLocation(NAMES_LOCATION.REMOVE_PREPOSITIONS, checked)}
        isError={errorFields.includes(NAMES_LOCATION.REMOVE_PREPOSITIONS)}
        checked={element[NAMES_LOCATION.REMOVE_PREPOSITIONS]}
      />
      <ExpandableList
        label="Regex"
        name={NAMES_LOCATION.REGEX}
        value={element[NAMES_LOCATION.REGEX]}
        isDisabled={disabled}
        onChange={changeLocation}
        isError={errorFields.includes(NAMES_LOCATION.REGEX)}
      />
    </SpecElementsWrapper>
  );
};

Location.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  element: PropTypes.shape({}),
  changeLocation: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Location.defaultProps = {
  disabled: false,
  show: false,
  element: {},
  errorFields: []
};

export default Location;
