import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";

const InformationModal = ({
  show,
  mode,
  onHide
}) => {
  return (
    <Modal
      className="confirmationModal"
      title={mode.title}
      show={show}
      onHide={onHide}
      size="small"
    >
      <div className="confirmationModal_body">
        <Typography variant="p" className="confirmationModalText">
          {mode.body}
        </Typography>
      </div>
    </Modal>
  );
};

InformationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  mode: PropTypes.shape({})
};

InformationModal.defaultProps = {
  mode: {}
};

export default InformationModal;
