import type { FC } from 'react';
import type { AuditEntriesTableProps } from './AuditEntriesTable.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './AuditEntriesTable.constants';
import * as queryKeyConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as documentAuditEntries from 'shared/features/documentAuditEntries/documentAuditEntries.constants';

import { useAuditEntriesTableDataSelector, useAuditEntriesTableHandlers } from './hooks';

export const AuditEntriesTable: FC<AuditEntriesTableProps> = ({ documentId, classNames }) => {
  const handlers = useAuditEntriesTableHandlers({
    props: {
      documentId
    }
  });

  const tableDataSelector = useAuditEntriesTableDataSelector();

  return (
    <uiLib.Table
      rowKey={documentAuditEntries.DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.ID}
      columns={constants.COLUMNS}
      shouldShowRefreshButton
      shouldShowTableOptions
      shouldShowColumnsSettingsButton
      shouldShowFirstPageButton
      shouldShowLastPageButton
      rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
      initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
      onDataFetch={handlers.handleDataFetch}
      localStorageKey={constants.LOCAL_STORAGE_KEY}
      classNames={classNames}
      queryOptions={{
        queryKey: [queryKeyConstants.QUERY_KEYS.DOCUMENT_AUDIT_ENTRIES, documentId],
        select: tableDataSelector,
        onError: errorUtils.logReactQueryError
      }}
    />
  );
};
