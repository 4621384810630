
import { maxFileSizeRunNow } from 'shared/config';

// run now modal errors
// // common errors
export const COMMAND_NOT_EXIST = 'Sorry, command for this "Type of mode" does not exist.';
export const TYPE_MODE_EMPTY = 'Please choose "Type of mode" from list.';
export const REQUIRED_VALUES_MISSED = 'The next values is required: ';
// // check format errors
// // // publication Date Interval errors
export const WRONG_DATE_SIZE = 'Starting date should be less that finishing';
// // spreadsheet command_request errors
export const SECOND_FILE = 'There could be upload only one file for one category.';
export const WRONG_FILE_SIZE = `File should less than ${(maxFileSizeRunNow/1024/1024/1024).toFixed(3)} GB`;
// // // premium content errors
export const DIFFERENT_FILE_NAMES = 'Spreadsheet file name and archive file name must be the same for premium crawl.';
export const WRONG_SPREADSHEET_EXTENSION = 'Premium content spreadsheet must have xlsx extension.';
export const WRONG_ARCHIVE_EXTENSION = 'Premium content archive must have zip extension.';
// // custom crawl settings do not exist
export const CUSTOM_CRAWL_SETTINGS = 'Settings for this crawl does not exist.';
export const MANAGE_RSS_ERROR_NAME = 'Name is a required field';
export const MANAGE_RSS_ERROR_DOC_FILTER = 'Doc filter is a required field';
export const MANAGE_RSS_ERROR_PUBLISHED = 'Published in last X days is a required field';
export const MANAGE_RSS_ERROR_MISSING_FIELD_CREATOR = key => `Missing field(s) in the ${key} section`;
export const MANAGE_RSS_ERROR_DOC_FILTER_JSON = 'Doc filter must be JSON';
export const WARNING_IGNORE_SPIDER = 'Crawl has not been added to the “ignore spider” tool';
