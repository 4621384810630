import type { FC } from 'react';
import type { ConceptsFiltersProps } from './ConceptsFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import { useConceptsFiltersConfig, useConceptsFiltersHandlers } from './hooks';

import './_concepts-filters.scss';

export const ConceptsFilters: FC<ConceptsFiltersProps> = ({ onSubmit }) => {
  const filterConfig = useConceptsFiltersConfig();

  const handlers = useConceptsFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search Concept"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="concepts-filters__container"
    />
  );
};
