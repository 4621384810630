import type { GlobalState } from '../../../../shared/reducers';
import type { AdminPermissions, AdminPermissionsReduxState } from './useAdminPermissions.types';

import ROUTES from 'admin/constants/routes';
import { nameOfCategories } from 'admin/constants/dashboardCategory';
import { ADMIN_PERMISSIONS } from './useAdminPermissions.constants';
import { getCurrentUser, getCurrentUserRoles } from 'shared/features/user/user.selectors';
import checkCurrentCategoryByURL, {
  hasAccess,
  hasFullAccess
} from 'admin/utils/checkCurrentCategoryByURL';

import { useSelector } from 'react-redux';
import { usePathname } from '../usePathname';

export const useAdminPermissions = (): AdminPermissions => {
  const pathname = usePathname();

  const reduxState = useSelector<GlobalState, AdminPermissionsReduxState>(state => ({
    currentUser: getCurrentUser(state),
    currentUserRoles: getCurrentUserRoles(state)
  }));

  let currentCategory = checkCurrentCategoryByURL(pathname);

  if (['/', '', ROUTES.login].includes(pathname.trim())) {
    /**
     * This ensures that the fallback redirect will actually redirect
     */
    return {
      [ADMIN_PERMISSIONS.VIEW]: true,
      [ADMIN_PERMISSIONS.EDIT]: false
    };
  }

  if (pathname === ROUTES.dashboard) {
    currentCategory = nameOfCategories.myAnnotationTasks;
  }

  if (currentCategory) {
    const hasViewAccess = hasAccess(reduxState.currentUser, currentCategory) || false;

    if (hasViewAccess) {
      return {
        [ADMIN_PERMISSIONS.VIEW]: hasViewAccess,
        [ADMIN_PERMISSIONS.EDIT]: hasFullAccess(reduxState.currentUser, currentCategory)
      };
    }
  }

  return {
    [ADMIN_PERMISSIONS.VIEW]: false,
    [ADMIN_PERMISSIONS.EDIT]: false
  };
};
