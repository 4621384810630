import type { CategorySplitModalHandlers } from './useCategorySplitModalHandlers.types';
import type { ComponentProps } from 'react';
import type { ScrapedCategoryFromResponse } from 'shared/features/categories/scrapedCategories/scrapedCategories.types';
import type { JurisdictionFromResponse } from 'shared/features/jurisdictionsData/jurisdictionsData.api.types';

import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';

export const useCategorySplitModalSelector = () => {
  return (
    data: Awaited<ReturnType<CategorySplitModalHandlers['handleScrapedCategoriesFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => {
        const category = row as ScrapedCategoryFromResponse;
        return {
          [categoryConstants.CATEGORY_ATTRIBUTES.ID]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.ID],
          [categoryConstants.CATEGORY_ATTRIBUTES.NAME]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.NAME],
          [categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION]:
            (
              category[
                categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION
              ] as JurisdictionFromResponse
            )?.short_name ||
            (
              category[
                categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION
              ] as JurisdictionFromResponse
            )?.name
        };
      })
    };
  };
};
