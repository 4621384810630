import type { ComponentProps } from 'react';
import type { useCategoriesFiltersAsyncLoaders } from './useCategoriesFiltersAsyncLoaders';

import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';
import * as constants from '../CategoriesFilters.constants';

export const useCategoriesFiltersConfig = ({
  filterAsyncLoaders
}: {
  filterAsyncLoaders: ReturnType<typeof useCategoriesFiltersAsyncLoaders>;
}): ComponentProps<typeof uiLib.Filters>['config'] => [
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.SCRAPED_CATEGORIES,
    label: 'Scraped Category',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[categoryConstants.CATEGORY_ATTRIBUTES.SCRAPED_CATEGORIES]
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.META_CATEGORY,
    label: 'Meta Category',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    options: [],
    onOptionsLoad: filterAsyncLoaders[categoryConstants.CATEGORY_ATTRIBUTES.META_CATEGORY]
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION,
    label: 'Jurisdiction',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION]
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.AGENCY,
    label: 'Agency',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[categoryConstants.CATEGORY_ATTRIBUTES.AGENCY]
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.ID,
    label: 'Cai Category Name',
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[categoryConstants.CATEGORY_ATTRIBUTES.ID]
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.FROM_ID,
    label: 'From ID',
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[categoryConstants.CATEGORY_ATTRIBUTES.ID]
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.TO_ID,
    label: 'To ID',
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[categoryConstants.CATEGORY_ATTRIBUTES.ID]
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT,
    label: 'Created At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT,
    label: 'Updated At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.SURFACE_IN_FILTER,
    label: 'Surface Category in App',
    type: uiLib.FILTER_TYPES.SELECT,
    inputComponentProps: {
      options: constants.BOOLEAN_OPTIONS
    }
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.IS_ARCHIVED,
    label: 'Is Archived',
    type: uiLib.FILTER_TYPES.SELECT,
    inputComponentProps: {
      options: constants.BOOLEAN_OPTIONS
    }
  }
];
