import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import Typography from './Typography';

function SortableList({
  className,
  sortableClassName,
  labelClassName,
  elementClassName,
  list,
  label,
  group,
  options,
  onChange
}) {
  return (
    <label className={cn(
      "sortable_wrapper",
      className
    )}
    >
      {
        label
          && (
            <Typography
              variant="tag"
              color="gray"
              className={labelClassName}
            >
              {label}
            </Typography>)
      }
      <ReactSortable
        className={cn(
          sortableClassName
        )}
        list={list}
        group={group}
        options={options}
        setList={onChange}
      >
        {list.map(item => (
          <div
            className={cn(
              "sortable_element",
              elementClassName
            )}
            key={item.value || item.id}
          >
            <Typography variant="p">
              {item.label || item.name}
            </Typography>
          </div>
        ))}
      </ReactSortable>
    </label>
  );
}


SortableList.propTypes = {
  className: PropTypes.string,
  sortableClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  elementClassName: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  group: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({})
};

SortableList.defaultProps = {
  className: '',
  sortableClassName: '',
  labelClassName: '',
  elementClassName: '',
  list: [],
  label: '',
  group: '',
  options: {}
};


export default SortableList;
