import type { Middleware } from '@reduxjs/toolkit';

import { configureStore } from '@reduxjs/toolkit';
import promiseMiddleware from 'redux-promise';
import rootReducer from 'shared/reducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const middleware = (getDefaultMiddleware: (...args: any[]) => Middleware[]) => {
  return getDefaultMiddleware({
    serializableCheck: false, // Prevents tons of error logs caused by the non-serializable data in legacy reducers
    immutableCheck: false // Due to the extremely huge state it causes terrible freezes in dev mode
  }).concat(promiseMiddleware);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: middleware
});

export type StoreDispatch = typeof store.dispatch;
