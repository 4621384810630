import type {FC} from "react";
import type {AppSwitchProps} from "./AppSwitch.types";

import React from "react";
import { useLocation } from "react-router-dom";
import { externalAppRoutes } from "admin/constants/routes";
import { AuthCheck } from "admin/containers/AuthCheck";
import { useAnalytics } from "./hooks";
import { App } from 'admin/containers/App';

export const AppSwitch: FC<AppSwitchProps> = ({ children }) => {
  const location = useLocation();

  useAnalytics();

  const isRouteWithoutAuthCheck = Object.values(externalAppRoutes).includes(location.pathname);

  if (isRouteWithoutAuthCheck) {
    return (
      <>{children}</>
    );
  }

  return (
    <AuthCheck>
      <App>
        {children}
      </App>
    </AuthCheck>
  );
};
