import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/Button";

const SourcesHeaderSearchModalBtnBlock = ({
  clearSearch,
  requestAgencies
}) => {
  return (
    <div className="footer-btn-block">
      <div>
        <Button
          size="small"
          color="highlighted"
          onClick={clearSearch}
        >
          Clear all
        </Button>
      </div>
      <div>
        {/*
        <Button
          size="small"
          color="highlighted"
          disabled
          onClick={() => {
          }}
        >
          Save as Widget
        </Button>
        */}
        <Button
          size="small"
          onClick={requestAgencies}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

SourcesHeaderSearchModalBtnBlock.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  requestAgencies: PropTypes.func.isRequired
};

export default SourcesHeaderSearchModalBtnBlock;
