import type { CategoryHandlers } from './useCaiCategoriesHandlers.types';
import type { CaiCategoriesTableConfig } from './useCaiCategoriesTableConfig.types';
import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { CategoryData } from './useCaiCategoriesData.types';

import * as elements from '../elements';
import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';

export const useCaiCategoriesTableConfig = ({
  events,
  formattedData
}: {
  events: {
    onEditCaiCategoryModalOpen: CategoryHandlers['handleEditCategoryModalOpen'];
    onSplit: CategoryHandlers['handleSplitCategoryModalOpen'];
    onMerge: CategoryHandlers['handleMergeCategoryModalOpen'];
  };
  formattedData: CategoryData['formattedData'];
}): CaiCategoriesTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'ID',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.ID,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.ID,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'CAI Category Name',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.NAME,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.NAME,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Meta Category',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.META_CATEGORY,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.META_CATEGORY,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: elements.MetaCategoryCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Meta Category ID',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.DOC_META_CATEGORY_ID,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.DOC_META_CATEGORY_ID,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Scraped Categories',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.SCRAPED_CATEGORIES,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.SCRAPED_CATEGORIES,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: elements.ScrapedCategoryCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Jurisdiction',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: elements.JurisdictionCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Updated by',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.USER_ID,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.USER_ID,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Agency',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.AGENCY,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.AGENCY,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: elements.AgencyCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Created',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Updated',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Surface Category in App',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.SURFACE_IN_FILTER,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.SURFACE_IN_FILTER,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: elements.SurfaceInFilterCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Description',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.DESCRIPTION,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.DESCRIPTION,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'From ID',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.FROM_ID,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.FROM_ID,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'To ID',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.TO_ID,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.TO_ID,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Active ID',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.ACTIVE_ID,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.ACTIVE_ID,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Is Archived',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.IS_ARCHIVED,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.IS_ARCHIVED,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: elements.ArchivedCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    }
  ];
  const actions: (uiLib.RowAction | uiLib.ColumnAction)[] = [
    {
      title: '',
      shouldPreventRefreshOnTrigger: true,
      icon: <uiLib.IconEdit />,
      trigger: events.onEditCaiCategoryModalOpen,
      isRowAction: true,
      disableColumn: true
    } as uiLib.RowAction,
    {
      title: 'Split',
      icon: <uiLib.IconDiagram />,
      shouldPreventRefreshOnTrigger: true,
      shouldKeepSelectedRows: true,
      isColumnAction: true,
      disabled: formattedData.isSplitDisabled,
      trigger: selectedCategoryIds =>
        events.onSplit(selectedCategoryIds as CaiCategoriesFromResponse['id'][])
    } as uiLib.ColumnAction,
    {
      title: 'Merge',
      icon: <uiLib.IconMove />,
      shouldPreventRefreshOnTrigger: true,
      shouldKeepSelectedRows: true,
      isColumnAction: true,
      disabled: formattedData.isMergeDisabled,
      trigger: selectedCategoryIds =>
        events.onMerge(selectedCategoryIds as CaiCategoriesFromResponse['id'][])
    } as uiLib.ColumnAction
  ];

  return {
    columns,
    actions
  };
};
