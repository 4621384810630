import type { EditCrawlConfigModalProps } from '../EditCrawlConfigModal.types';
import type { EditCrawlConfigModalData } from './useEditCrawlConfigModalData.types';

import { checkProductionEnv } from 'admin/utils/checkENVIRONMENT';

import { useMemo } from 'react';

export const useEditCrawlConfigModalData = ({
  props
}: {
  props: Pick<EditCrawlConfigModalProps, 'isEditDisabled' | 'initialCrawlConfig' | 'crawlConfig'>;
}): EditCrawlConfigModalData => {
  const formattedData: EditCrawlConfigModalData['formattedData'] = useMemo(() => {
    const isEditorReadOnly: EditCrawlConfigModalData['formattedData']['isEditorReadOnly'] =
      props.isEditDisabled || checkProductionEnv();

    return {
      isEditorReadOnly,
      isSubmitButtonDisabled: isEditorReadOnly || props.initialCrawlConfig === props.crawlConfig,
      modalTitle: `${isEditorReadOnly ? 'View' : 'Edit'} crawl_config`
    };
  }, [props.isEditDisabled, props.initialCrawlConfig, props.crawlConfig]);

  return {
    formattedData
  };
};
