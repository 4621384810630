import type { ApiSortSettings } from './useNewsSourcesData.types';
import type * as newsSourcesApiTypes from 'shared/features/sources/newsSources/newsSources.api.types';
import type { TableNewsSource } from '../NewsSources.types';

import * as constants from '../NewsSources.constants';
import * as sourcesConstants from 'admin/constants/sources';
import ROUTES from 'admin/constants/routes';

export const getSortField = (sortString: string): sourcesConstants.NEWS_SOURCE_ATTRIBUTES => {
  return (
    sortString.at(0) === '-' ? sortString.slice(1) : sortString
  ) as sourcesConstants.NEWS_SOURCE_ATTRIBUTES;
};

export const getSortDirection = (sortString: string): constants.SORT_DIRECTIONS => {
  return sortString.at(0) === '-' ? constants.SORT_DIRECTIONS.DESC : constants.SORT_DIRECTIONS.ASC;
};

export const getApiSortSettings = (sortParam: string | null): ApiSortSettings | undefined => {
  if (sortParam === null) {
    /**
     * Undefined ensures that the query param won't be included in the payload
     */
    return undefined;
  }

  const sortField = getSortField(sortParam);

  return {
    [sortField]: {
      sorting_priority: 1,
      sorting_direction: getSortDirection(sortParam)
    }
  };
};

export const formatNewsSource = (
  newsSource: newsSourcesApiTypes.NewsSourceFromResponseV2
): TableNewsSource => {
  return {
    id: newsSource.id,
    name: {
      href: `${window.location.origin}${ROUTES.sources}/${newsSource?.id}`,
      children: newsSource.name,
      tooltip: newsSource.name
    },
    isShared: newsSource.is_shared,
    active: newsSource.active ? 'active' : 'deactivated',
    logoUrl: {
      href: newsSource.logo_url ?? undefined,
      children: newsSource.logo_url,
      tooltip: newsSource.logo_url
    },
    createdAt: newsSource.created_at,
    updatedAt: newsSource.updated_at,
    jurisdictions: newsSource.jurisdictions,
    jurisdictionIds: newsSource.jurisdictions_ids
  };
};
