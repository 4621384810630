import {
  api_getExpertConnectionData,
  api_getExperts,
  api_getAreasOfExpertise
} from './expertConnection.api';
import {getUserAuthenticatedState} from "../auth/auth.selectors";

export const GET_EXPERT_CONNECTION_DATA = 'GET_EXPERT_CONNECTION_DATA';
export const GET_EXPERTS = 'GET_EXPERTS';
export const GET_AREAS_OF_EXPERTISE = 'GET_AREAS_OF_EXPERTISE';

export function fetchExpertConnectionData() {
  return function dofetch(dispatch, getState) {
    const isAuthenticated = getUserAuthenticatedState(getState())

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    return api_getExpertConnectionData()
      .then(response => {
        dispatch({ type: GET_EXPERT_CONNECTION_DATA, payload: response });
      });
  };
}

export function fetchExperts() {
  return function dofetch(dispatch) {
    return api_getExperts().then(response => dispatch({ type: GET_EXPERTS, payload: response }));
  };
}

export function fetchAreasOfExpertise() {
  return function dofetch(dispatch) {
    return api_getAreasOfExpertise().then(response => dispatch(
      { type: GET_AREAS_OF_EXPERTISE, payload: response.data }
    ));
  };
}
