import type { CommandRequestsDetailsModalData } from './useCommandRequestsDetailsModalData.types';
import type { CommandRequestSelect } from 'admin/containers/CommandRequests/elements/CommandRequestsCreationModal/hooks/useCommandRequestsCreationModalData.types';
import { COMMAND_REQUEST_NAMES, COMMAND_REQUEST_STATUS } from 'admin/constants/commandRequestTypes';

import { useMemo } from 'react';

export const useCommandRequestsDetailsModalData = ({
  props
}: {
  props: {
    selectedCommandRequest: CommandRequestSelect | null;
  };
}): CommandRequestsDetailsModalData => {
  const formattedData: CommandRequestsDetailsModalData['formattedData'] = useMemo(() => {
    const command_request_id = props.selectedCommandRequest?.id;
    const enforcement_command =
      props.selectedCommandRequest?.type?.name === COMMAND_REQUEST_NAMES.UPLOAD_ENFORCEMENT_COMMAND;
    const ready_for_db_status =
      props.selectedCommandRequest?.status === COMMAND_REQUEST_STATUS.READY_FOR_DB;
    const processed_ready_for_fix_status =
      props.selectedCommandRequest?.status === COMMAND_REQUEST_STATUS.PROCESSED_READY_FOR_FIX;
    const primaryLabel =
      enforcement_command && (ready_for_db_status || processed_ready_for_fix_status)
        ? 'update'
        : 'ok';
    const secondaryLabel = 'cancel';
    const optionLabel =
      enforcement_command && ready_for_db_status && !processed_ready_for_fix_status
        ? 'fix documents with missing enforcement'
        : '';
    return {
      primaryLabel: primaryLabel,
      secondaryLabel: secondaryLabel,
      optionLabel: optionLabel,
      enforcement_command: enforcement_command,
      ready_for_db_status: ready_for_db_status,
      processed_ready_for_fix_status: processed_ready_for_fix_status,
      command_request_id: command_request_id
    };
  }, [props.selectedCommandRequest]);

  return {
    formattedData
  };
};
