import React, { FC } from 'react';
import type { CommandRequestsDetailsModalProps } from './CommandRequestsDetailsModal.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useCommandRequestsDetailsModalData,
  useCommandRequestsDetailsModalHandlers
} from './hooks';

import './_command-requests-details-modal.scss';
export const CommandRequestsDetailsModal: FC<CommandRequestsDetailsModalProps> = ({
  details,
  onClose,
  selectedCommandRequest
}) => {
  const { formattedData } = useCommandRequestsDetailsModalData({
    props: { selectedCommandRequest }
  });
  const handlers = useCommandRequestsDetailsModalHandlers({ formattedData, props: { onClose } });
  return (
    <uiLib.Modal
      isOpen
      title="Details"
      withFooter
      className="command-requests-details-modal"
      onSubmit={handlers.handleOnSubmit}
      // TODO: https://complianceai.atlassian.net/browse/DATAWEB-2452; we do not want that also X on the top right of modal moves the command request to failed
      // modal needs to support that separatly , in the meanwhile we just close the modal
      // onClose={handlers.handleOnCancel}
      onClose={onClose}
      secondaryButtonText={formattedData.secondaryLabel}
      primaryButtonText={formattedData.primaryLabel}
      onOptionsClick={handlers.handleOnOption}
      optionsButtonText={formattedData.optionLabel}
    >
      <div className="command-requests-details-modal__content-container">
        <uiLib.CodeEditor value={details} isReadOnly />
      </div>
    </uiLib.Modal>
  );
};
