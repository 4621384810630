import type { ReactNode } from 'react';
import type { DryRunReport } from '../DryRunReportsTable.types';
import type { DryRunReportsTableProps } from '../DryRunReportsTable.types';

import { LOG_TYPES } from 'shared/features/spiderRunReports/spiderRunReports.constants';
import { ButtonDownloadCell } from '../elements/DryRunReportsTableCell/elements';

export const formatReason = (
  report: DryRunReport,
  onOpenModal: DryRunReportsTableProps['onOpenModal'],
  tooltip: boolean
): ReactNode => {
  if (
    [LOG_TYPES.VIDEO_LOG, LOG_TYPES.TEXT_LOG, LOG_TYPES.TEXT_METRICS].includes(
      report?.official_id as LOG_TYPES
    )
  ) {
    if (tooltip) return null;
    return <ButtonDownloadCell report={report} onOpenModal={onOpenModal} />;
  }

  if (!report.reason) {
    return null;
  }

  return report.reason;
};
