import type { CrawlerHistoryData } from './useCrawlerHistoryData.types';
import type { CrawlerHistoryHandlers } from './useCrawlerHistoryHandlers.types';

import { useCallback } from 'react';

import { useCrawlerHistoryReduxActions } from 'shared/features/crawlerSettings/crawlerHistory/hooks';

export const useCrawlerHistoryHandlers = ({
  formattedData
}: {
  formattedData: CrawlerHistoryData['formattedData'];
}): CrawlerHistoryHandlers => {
  const crawlerHistoryReduxActions = useCrawlerHistoryReduxActions();

  const handleRequestHistory = useCallback(() => {
    crawlerHistoryReduxActions.getHistory(formattedData.id);
  }, [crawlerHistoryReduxActions, formattedData.id]);

  return {
    handleRequestHistory
  };
};
