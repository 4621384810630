import type { ViewCrawlSentencificationConfigurationModalProps } from './ViewCrawlSentencificationConfigurationModal.types';

import type { FC } from 'react';
import * as uiLib from '@compliance.ai/web-components';
import { useCrawlDetailsConfigurationsHandlers } from './hooks/useCrawlDetailsConfigurationHandlers';
import { useCrawlDetailsConfigurationData } from './hooks/useCrawlDetailsConfigurationData';

import './_edit-crawl-sent-config-modal.scss';

export const ViewCrawlSentencificationConfigurationModal: FC<
  ViewCrawlSentencificationConfigurationModalProps
> = ({ show, onHide, onModalAction, crawlSentencificationConfigurartion }) => {
  const { localActions, formattedData } = useCrawlDetailsConfigurationData();
  const handlers = useCrawlDetailsConfigurationsHandlers({
    localActions
  });

  return (
    <uiLib.Modal
      className="edit-crawl-sent-config-modal"
      title="View crawl sentencification configuration"
      isOpen={show}
      onClose={onHide}
    >
      <div className="edit-crawl-sent-config-modal__content-container">
        <uiLib.CodeEditor
          value={crawlSentencificationConfigurartion ?? ''}
          onChange={handlers.handleCrawlSentencificationConfigurationChange}
          isReadOnly={formattedData?.isEditDisabled || formattedData?.isProductionEnvironment}
        />
      </div>
    </uiLib.Modal>
  );
};
