import { fetch } from 'fetch';

export function api_fetchMetrics(params = {}) {
  return fetch({
    url: '/jurisdiction_analyzer_metrics',
    method: 'GET',
    dataType: 'Metrics',
    params
  });
}

export function api_addMetric(params) {
  return fetch({
    url: `/jurisdiction_analyzer_metrics`,
    method: 'POST',
    dataType: 'Metrics',
    params
  });
}

export function api_updateMetric(currentID, params) {
  return fetch({
    url: `/jurisdiction_analyzer_metrics/${currentID}`,
    method: 'POST',
    dataType: 'Metrics',
    params
  });
}

export function api_deleteMetric(currentID) {
  return fetch({
    url: `/jurisdiction_analyzer_metrics/${currentID}`,
    dataType: 'Metrics',
    method: 'DELETE'
  });
}
