import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getCategories } from 'shared/features/categories/categories/categories.actions';
import { sortOrderChangesHelper } from "../../utils/sort/sort.helper";
import {
  formatSearchValues,
  checkCombinedCategories,
  checkSplitCategory
} from "./DocumentCategories.helper";
import Typography from "../../components/Typography";
import DocumentCategoriesFilter from "./DocumentCategoriesFilter";
import DocumentCategoriesButtons from "./DocumentCategoriesButtons";
import DocumentCategoriesTable from "./DocumentCategoriesTable";
import DocumentCategoriesFooter from "./DocumentCategoriesFooter";
import DocumentCategoriesModal from "./DocumentCategoriesModal";
import DocumentCategoriesSplitModal from "./DocumentCategoriesSplitModal";

const DocumentCategories = () => {
  const dispatch = useDispatch();
  const metaCategories = useSelector(state => state.documentMetaCategories);
  const categories = useSelector(state => state.documentCategories);

  // table settings
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(7);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState({});
  const [selectedCategory, setSelectedCategory] = useState([]);
  // modal settings
  const [categoryModal, setCategoryModal] = useState(false);
  const [splitModal, setSplitModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});

  const categoriesRequest = () => {
    dispatch(getCategories({
      offset: (page - 1) * limit,
      limit,
      sort: JSON.stringify(sort),
      ...formatSearchValues(search)
    }));
  };

  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(categoriesRequest, [page, limit, sort]);

  const changeFilter = fieldName => {
    const sortedFields = sortOrderChangesHelper(fieldName, sort);
    setSort(sortedFields);
  };

  const setSelectedCategories = ({ category, add }) => {
    setSelectedCategory(
      add
        ? [...selectedCategory, category]
        : selectedCategory.filter(catId => catId.id !== category.id)
    );
  };

  const uncheckAllCategory = () => setSelectedCategory([]);

  const setSearchValues = (name, value) => {
    setSearch({
      ...search,
      [name]: value
    });
  };

  const openCategoryModal = (row, index) => {
    setCurrentCategory(_.get(categories, ['items', index], {}));
    setCategoryModal(true);
  };

  const combineCategories = () => {
    const result = checkCombinedCategories(selectedCategory);
    if (result) {
      setCurrentCategory(result);
      setCategoryModal(true);
    }
  };

  const splitCategory = () => {
    const result = checkSplitCategory(selectedCategory);
    if (result) {
      setCurrentCategory(result);
      setSplitModal(true);
    }
  };

  return (
    <div className="document-categories">
      <Typography variant="h4">Document Categories</Typography>
      <hr />
      <DocumentCategoriesFilter
        categories={categories.items}
        metaCategories={metaCategories.items}
        setSearchValues={setSearchValues}
        search={search}
      />
      <hr />
      <DocumentCategoriesButtons
        splitCategory={splitCategory}
        combineCategories={combineCategories}
        uncheckAll={uncheckAllCategory}
        filterCategories={categoriesRequest}
      />
      <DocumentCategoriesTable
        categories={categories.items}
        setCategoryModal={openCategoryModal}
        selectedCategory={selectedCategory}
        selectCategory={setSelectedCategories}
        changeFilter={changeFilter}
        sort={sort}
      />
      <DocumentCategoriesFooter
        onChangePage={setPage}
        onChangeLimit={setLimit}
        limit={limit}
        pageQuantity={Math.ceil(categories.count / limit)}
      />
      <DocumentCategoriesModal
        show={categoryModal}
        onHide={() => setCategoryModal(false)}
        currentCategory={currentCategory}
      />
      <DocumentCategoriesSplitModal
        show={splitModal}
        onHide={() => setSplitModal(false)}
        currentCategory={currentCategory}
      />
    </div>

  );
};

export default DocumentCategories;
