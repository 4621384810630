import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'admin/utils/hooks';
import moment from 'moment';
import _ from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  IconClose,
  IconCached,
  IconBarChart,
  IconDangerousOutlined,
  BUTTON_TYPES
} from '@compliance.ai/web-components';
import ReportIcon from '@material-ui/icons/Report';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import BlockIcon from '@material-ui/icons/Block';
import BuildIcon from '@material-ui/icons/Build';
import ROUTES from '../../constants/routes';
import { DATE_TIME_FORMAT } from '../../constants/projectSettings';
import {
  CRAWLER_TYPE,
  crawlStates,
  crawlStatesTree,
  SOURCE_CHANGED_ENABLED_OPTIONS
} from '../../constants/crawlersTypes';
import Typography from '../../components/Typography';
import {
  BODY_CRAWL_STATUS_TEXT_ALIGNMENT,
  BodyCrawlStatus
} from './CrawlerActivity/elements/CrawlActivityTable/elements/StatusCell/elements';
import { CrawlStatusSelect } from './CrawlStatusSelect';

const CrawlerSettingsStatus = ({
  crawlDetails,
  crawlerState,
  lastReport,
  isCommandRequest,
  activateCrawl,
  stopCrawl,
  handleRunAutoQA,
  openRunNowModal,
  changeCrawlState
}) => {
  const history = useHistory();
  const location = useLocation();
  const allCrawlStatesValues = {
    [crawlStates.active]: {
      startIcon: <CheckCircleIcon />,
      label: 'Activate',
      value: crawlStates.active,
      action: activateCrawl,
      buttonType: BUTTON_TYPES.SUCCESS
    },
    [crawlStates.deactivate]: {
      startIcon: <ReportIcon />,
      label: 'Deactivate',
      value: crawlStates.deactivate,
      updateValue: { status: crawlStates.deactivate, is_active: false },
      buttonType: BUTTON_TYPES.DELETE
    },
    [crawlStates.runNow]: {
      startIcon: <DirectionsRunIcon />,
      label: 'Run Now',
      value: crawlStates.runNow,
      action: openRunNowModal,
      buttonType: BUTTON_TYPES.PRIMARY
    },
    [crawlStates.stopRun]: {
      startIcon: <IconDangerousOutlined />,
      label: 'Stop Run',
      value: crawlStates.stopRun,
      action: stopCrawl,
      buttonType: BUTTON_TYPES.DELETE
    },
    [crawlStates.construction]: {
      startIcon: <BuildIcon />,
      label: 'Construction',
      value: crawlStates.construction,
      updateValue: { status: crawlStates.construction, is_active: false },
      buttonType: BUTTON_TYPES.WARNING
    },
    [crawlStates.idle]: {
      startIcon: <BlockIcon />,
      label: 'Idle',
      value: crawlStates.idle,
      buttonType: BUTTON_TYPES.SUCCESS
    },
    [crawlStates.queued]: {
      startIcon: <RotateLeftIcon />,
      label: 'Queued',
      value: crawlStates.queued,
      buttonType: BUTTON_TYPES.PRIMARY
    },
    [crawlStates.running]: {
      startIcon: <RotateLeftIcon />,
      label: 'Running',
      value: crawlStates.running,
      buttonType: BUTTON_TYPES.PRIMARY
    },
    [crawlStates.timed_out]: {
      startIcon: <CheckCircleIcon />,
      label: 'Timed out',
      value: crawlStates.timed_out,
      buttonType: BUTTON_TYPES.DELETE
    },
    [crawlStates.failed]: {
      startIcon: <CheckCircleIcon />,
      label: 'Failed',
      value: crawlStates.failed,
      buttonType: BUTTON_TYPES.DELETE
    },
    [crawlStates.sourceChanged]: {
      startIcon: <IconCached />,
      label: 'Source Changed',
      value: crawlStates.sourceChanged,
      buttonType: BUTTON_TYPES.SUCCESS
    },
    [crawlStates.autoQA]: {
      startIcon: <IconBarChart />,
      label: 'Auto QA',
      value: crawlStates.autoQA,
      action: handleRunAutoQA,
      buttonType: BUTTON_TYPES.PRIMARY
    }
  };

  // help functions
  const goBack = () => history.push(ROUTES.crawlerMonitoring, location.state);
  const formatDate = date => (date ? moment(date).format(DATE_TIME_FORMAT) : 'N/A');

  // variables
  const last_ingested_date = _.get(crawlDetails, ['crawler_statistics', 'last_ingested_date']);
  const last_skipped_date = _.get(crawlDetails, ['crawler_statistics', 'last_skipped_date']);
  const last_updated_date = _.get(crawlDetails, ['crawler_statistics', 'last_updated_date']);
  const last_failed_date = _.get(crawlDetails, ['crawler_statistics', 'last_failed_date']);
  const last_error_date = _.get(crawlDetails, ['crawler_statistics', 'last_error_date']);
  const last_run = _.get(crawlDetails, ['crawler_statistics', 'last_run']);

  const stateOptions = _.get(crawlStatesTree, crawlerState, [])
    // Only unified crawl can have construction status option (DATAWEB-2177)
    .flatMap(includeStatus => {
      if (
        includeStatus === crawlStates.construction &&
        ![CRAWLER_TYPE.UNIFIED, CRAWLER_TYPE.EMAIL].includes(crawlDetails.type)
      ) {
        return [];
      }
      if (crawlerState === crawlStates.sourceChanged) {
        return {
          ...allCrawlStatesValues[includeStatus],
          isDisabled: !SOURCE_CHANGED_ENABLED_OPTIONS.includes(includeStatus)
        };
      }
      return allCrawlStatesValues[includeStatus];
    });

  const stateValue = isCommandRequest
    ? {
        startIcon: <RotateLeftIcon />,
        label: crawlerState,
        value: crawlStates.commandRequest,
        buttonType: BUTTON_TYPES.PRIMARY
      }
    : allCrawlStatesValues[crawlerState];

  return (
    <div className="crawl-navigation_status">
      <div className="crawl-navigation_status_date-block">
        {last_ingested_date && (
          <Typography variant="p" italic>
            {`Docs last ingested on ${formatDate(last_ingested_date)}`}
          </Typography>
        )}
        {last_skipped_date && (
          <Typography variant="p" italic>
            {`Docs last skipped on ${formatDate(last_skipped_date)}`}
          </Typography>
        )}
        {last_updated_date && (
          <Typography variant="p" italic>
            {`Docs last updated on ${formatDate(last_updated_date)}`}
          </Typography>
        )}
        {last_failed_date && (
          <Typography variant="p" italic>
            {`Docs last failed on ${formatDate(last_failed_date)}`}
          </Typography>
        )}
        {last_error_date && (
          <Typography variant="p" italic>
            {`Docs last errror on ${formatDate(last_error_date)}`}
          </Typography>
        )}
        <Typography variant="p" italic>
          {`Crawl ran successfully on ${formatDate(last_run)}`}
        </Typography>
      </div>
      <div className="crawl-navigation_status_status-block">
        <Typography variant="p" color="darkGrey" bold>
          {'Status'}
        </Typography>
        {/* if last report is yet empty we rather show blank status instead of default processed */}
        {lastReport && Object.keys(lastReport).length > 0 ? (
          <BodyCrawlStatus
            report={lastReport}
            className={'crawl-navigation_status_status-block_badge'}
            textAlign={BODY_CRAWL_STATUS_TEXT_ALIGNMENT.LEFT}
          />
        ) : (
          <div className={'crawl-navigation_status_status-block_badge-placeholder'} />
        )}
      </div>
      <div className="crawl-navigation_status_status-block">
        <Typography variant="p" color="darkGrey" bold>
          {isCommandRequest ? 'Command Request State' : 'State'}
        </Typography>
        <CrawlStatusSelect
          crawlState={stateValue}
          crawlStateOptions={stateOptions}
          changeCrawlState={changeCrawlState}
        />
      </div>
      <div className="crawl-navigation_status_icon-block">
        <button onClick={goBack}>
          <IconClose />
        </button>
      </div>
    </div>
  );
};

CrawlerSettingsStatus.propTypes = {
  crawlDetails: PropTypes.shape({}),
  activateCrawl: PropTypes.func.isRequired,
  stopCrawl: PropTypes.func.isRequired,
  openRunNowModal: PropTypes.func.isRequired,
  changeCrawlState: PropTypes.func.isRequired
};

CrawlerSettingsStatus.defaultProps = {
  crawlDetails: {}
};

export default CrawlerSettingsStatus;
