import type { ExternalApiCrawlFromResponse } from './externalApiCrawl.api.types';
import * as constants from './externalApiCrawl.constants';
import * as commandRequestTypesConstants from '../../../admin/constants/commandRequestTypes';
import { GlobalState } from '../../reducers';
import { createSelector } from '@reduxjs/toolkit';

export const getExternalApiCrawlReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state?.[constants.REDUCER_NAME]
);

export const getExternalApiCrawl = createSelector(
  getExternalApiCrawlReducer,
  (currentExternalApiCrawlReducer): ExternalApiCrawlFromResponse[] => {
    return currentExternalApiCrawlReducer?.items;
  }
);

export const getFirstExternalApiCrawlElement = createSelector(
  getExternalApiCrawl,
  (currentExternalApiCrawlReducer): ExternalApiCrawlFromResponse => {
    return currentExternalApiCrawlReducer?.[0] ?? {};
  }
);

export const getExternalApiCrawlTimestamp = createSelector(
  getExternalApiCrawlReducer,
  (currentExternalApiCrawlReducer): string => {
    return currentExternalApiCrawlReducer?.timestamp ?? '';
  }
);

export const getExternalApiCrawlLoad = createSelector(
  getExternalApiCrawlReducer,
  (currentExternalApiCrawlReducer): boolean => {
    return currentExternalApiCrawlReducer?.load;
  }
);

export const getExternalApiCrawlCount = createSelector(
  getExternalApiCrawlReducer,
  (currentExternalApiCrawlReducer): number => {
    return currentExternalApiCrawlReducer?.count ?? 1;
  }
);

export const getTimedOutExternalApiCrawl = createSelector(
  [getExternalApiCrawl, (state, selectedCrawls) => selectedCrawls],
  (currentExternalApiCrawlReducer, selectedCrawls): number => {
    return currentExternalApiCrawlReducer.filter(
      item =>
        selectedCrawls.includes(item?.id) &&
        item.status === commandRequestTypesConstants.COMMAND_REQUEST_STATUS.TIMED_OUT
    ).length;
  }
);
