import type { CrawlerHistoryHandlers } from './useCrawlerHistoryHandlers.types';

import { useEffect } from 'react';

export const useCrawlerHistoryLifecycle = ({
  onInitialisation
}: {
  onInitialisation: CrawlerHistoryHandlers['handleRequestHistory'];
}): void => {
  useEffect(onInitialisation, [onInitialisation]);
};
