import type * as documentDataTypes from 'shared/features/documentData/documentData.types';
import type * as commandsApiTypes from 'shared/features/commands/commands.api.types';

import type { BulkEditModalProps } from '../BulkEditModal.types';

import * as documentDataConstants from 'shared/features/documentData/documentData.constants';
import * as documentDataApi from 'shared/features/documentData/documentData.apiV2';
import * as commandsApi from 'shared/features/commands/commands.api';
import * as errorUtils from 'shared/utils/errors';

import { QUERY_KEYS, QUERY_SECTIONS, COMPLEX_QUERY_KEY_GETTERS } from 'admin/constants/queryKeys';

import { useQuery } from '@tanstack/react-query';

export const useBulkEditModalQueries = ({
  props
}: {
  props: Pick<BulkEditModalProps, 'selectedDocumentIds'>;
}) => {
  const getSelectedDocumentsQuery = useQuery({
    queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.DOCUMENTS_DATA][
      QUERY_SECTIONS.DOCUMENTS_BULK_EDIT
    ]({
      selectedDocumentIds: props.selectedDocumentIds
    }),
    queryFn: async () => {
      if (!props.selectedDocumentIds.length) {
        return [];
      }

      const response = await documentDataApi.fetchDocumentsData({
        [documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.DOC_IDS]:
          props.selectedDocumentIds.map(String),
        [documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.LIMIT]:
          props.selectedDocumentIds.length,
        [documentDataConstants.DOCUMENTS_DATA_FETCH_QUERY_PARAMS.EXCLUSIVE_FIELDS]: [
          'items.eitl_labels'
        ]
      });

      return response.items as documentDataTypes.DocumentDataFromResponse[];
    },
    onError: errorUtils.logReactQueryError,
    refetchOnWindowFocus: false
  });

  const fetchCommands = useQuery({
    queryKey:
      COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.DOCUMENTS_DATA][QUERY_SECTIONS.DOCUMENT_BULK_COMMANDS](),
    queryFn: async () => {
      const response = await commandsApi.api_fetchAllCommands();

      return response as commandsApiTypes.CommandRequestFromResponse[];
    },
    onError: errorUtils.logReactQueryError,
    refetchOnWindowFocus: false
  });

  return {
    getSelectedDocumentsQuery,
    fetchCommands
  };
};
