import type { FC } from 'react';
import type { NumberOfIssuesCellProps } from './NumberOfIssuesCell.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as components from 'admin/components';

import { useNumberOfIssuesCellData, useNumberOfIssuesCellHandlers } from './hooks';

export const NumberOfIssuesCell: FC<NumberOfIssuesCellProps> = ({ cellValue, row }) => {
  const { localState, localActions, formattedData } = useNumberOfIssuesCellData({
    props: {
      cellValue,
      row
    }
  });

  const handlers = useNumberOfIssuesCellHandlers({
    localActions
  });

  if (formattedData.shouldShowExtraLogsButton) {
    return (
      <>
        <components.CrawlActivityExtraContentModal
          show={localState.isModalOpen && ([formattedData.logType, cellValue] as string[])}
          onHide={handlers.handleModalToggle(false)}
        />
        <uiLib.Button onClick={handlers.handleModalToggle(true)}>Show</uiLib.Button>
      </>
    );
  }

  return <>{cellValue}</>;
};
