import React from 'react';
import PropTypes from "prop-types";

const GlassdoorTableInfo = ({
  children
}) => (
  <div className="glassdoor_info-wrapper">{children}</div>
);

GlassdoorTableInfo.propTypes = {
  children: PropTypes.element
};

GlassdoorTableInfo.defaultProps = {
  children: null
};

export default GlassdoorTableInfo;
