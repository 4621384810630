import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { LocalDateFormat } from 'app/utils/keyDates';
import './_date-picker.scss';

const StyledDatePicker = styled(TextField)`
  width: 100%;
  .jurispect-MuiFormLabel-root {
    color: #4a4a4a;
    font-size: 11px;
  }
  .jurispect-MuiInputBase-root {
    height: 38px;
    margin-top: 12px;
    font-size: 14px;
    background: white;
    border: solid 1px #cccccc;

    input {
      padding-left: 10px;
    }

    &:hover:before,
    &:hover:after,
    &:before,
    &:after {
      border: none;
    }
  }
`;

function DatePickerSharedComponent({
  value,
  placeholder,
  onChange,
  disabled,
  label,
  className,
  inputClassName
}) {
  return (
    <StyledDatePicker
      value={value}
      type="date"
      disabled={disabled}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      label={label}
      classes={{
        root: className
      }}
      InputProps={{
        classes: {
          root: inputClassName
        }
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
}

DatePickerSharedComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

DatePickerSharedComponent.defaultProps = {
  value: '',
  onChange: null,
  placeholder: LocalDateFormat.toLowerCase(),
  className: '',
  inputClassName: '',
  disabled: false
};

export default DatePickerSharedComponent;
