import {
  api_fetchFolders,
  api_addUserFolder,
  api_addDocumentsToFolder,
  api_updateFolder,
  api_deleteFolder
} from './folders.api';
import {getUserAuthenticatedState} from "../auth/auth.selectors";

export const REQUEST_ALL_SHARED_FOLDERS = 'REQUEST_ALL_SHARED_FOLDERS';
export const RECEIVE_ALL_SHARED_FOLDERS = 'RECEIVE_ALL_SHARED_FOLDERS';
export const ADD_NEW_FOLDER = 'ADD_NEW_FOLDER';
export const NEW_FOLDER_ADDED = 'NEW_FOLDER_ADDED';
export const REQUEST_FOLDERS = 'REQUEST_FOLDERS';
export const RECEIVE_FOLDERS = 'RECEIVE_FOLDERS';
export const CHANGE_SELECTED_FOLDER = 'CHANGE_SELECTED_FOLDER';
export const CLEAR_SELECTED_FOLDER = 'CLEAR_SELECTED_FOLDER';
export const REQUEST_FOLDER_DOCUMENTS = 'REQUEST_FOLDER_DOCUMENTS';
export const RECEIVE_FOLDER_DOCUMENTS = 'RECEIVE_FOLDER_DOCUMENTS';
export const DOCUMENTS_ADDED_TO_FOLDER = 'DOCUMENTS_ADDED_TO_FOLDER';
export const CLEAR_FOLDER_DOCUMENTS = 'CLEAR_FOLDER_DOCUMENTS';
export const DELETE_CONFIRM_FOLDER_OPEN = 'DELETE_CONFIRM_FOLDER_OPEN';
export const DELETE_CONFIRM_FOLDER_CLOSE = 'DELETE_CONFIRM_FOLDER_CLOSE';
export const SHARE_FOLDER_MENU_OPEN = 'SHARE_FOLDER_MENU_OPEN';
export const SHARE_FOLDER_MENU_CLOSE = 'SHARE_FOLDER_MENU_CLOSE';
export const COPY_FOLDER_MENU_OPEN = 'COPY_FOLDER_MENU_OPEN';
export const COPY_FOLDER_MENU_CLOSE = 'COPY_FOLDER_MENU_CLOSE';
export const OPEN_ADD_FOLDER_MENU = 'OPEN_ADD_FOLDER_MENU';
export const CLOSE_ADD_FOLDER_MENU = 'CLOSE_ADD_FOLDER_MENU';
export const ADDING_DOCUMENTS_TO_FOLDER = 'ADDING_DOCUMENTS_TO_FOLDER';
export const UPDATE_FOLDER_NAME = 'UPDATE_FOLDER_NAME';
export const FOLDER_NAME_UPDATED = 'FOLDER_NAME_UPDATED';
export const DELETE_USER_FOLDER = 'DELETE_USER_FOLDER';
export const USER_FOLDER_DELETED = 'USER_FOLDER_DELETED';
export const DELETE_DOCUMENTS_FROM_FOLDERS = 'DELETE_DOCUMENTS_FROM_FOLDERS';
export const DOCUMENTS_DELETED_FROM_FOLDERS = 'DOCUMENTS_DELETED_FROM_FOLDERS';
export const REQUEST_ADD_SHARED_FOLDER = 'REQUEST_ADD_SHARED_FOLDER';
export const RECEIVE_ADD_SHARED_FOLDER = 'RECEIVE_ADD_SHARED_FOLDER';
export const REQUEST_ADD_SHARED_FOLDER_USERS = 'REQUEST_ADD_SHARED_FOLDER_USERS';
export const RECEIVE_ADD_SHARED_FOLDER_USERS = 'RECEIVE_ADD_SHARED_FOLDER_USERS';
export const REQUEST_UPDATE_SHARED_FOLDER_USERS = 'REQUEST_UPDATE_SHARED_FOLDER_USERS';
export const RECEIVE_UPDATE_SHARED_FOLDER_USERS = 'RECEIVE_UPDATE_SHARED_FOLDER_USERS';

function addNewFolder() {
  return {
    type: ADD_NEW_FOLDER
  };
}

function newFolderAdded() {
  return {
    type: NEW_FOLDER_ADDED
  };
}

function requestFolders() {
  return {
    type: REQUEST_FOLDERS
  };
}

function receiveFolders(all_folders) {
  return {
    type: RECEIVE_FOLDERS,
    personal_folders: all_folders.personal_folders,
    shared_folders: all_folders.shared_folders,
    document_folders: all_folders.document_folders
  };
}

function documentsAddedToFolder(response) {
  return {
    type: DOCUMENTS_ADDED_TO_FOLDER,
    document_folders: response.documents.document_folders
  };
}

function renameFolder() {
  return {
    type: UPDATE_FOLDER_NAME
  };
}

function folderRenamed() {
  return {
    type: FOLDER_NAME_UPDATED
  };
}

function deletingUserFolder() {
  return {
    type: DELETE_USER_FOLDER
  };
}

function folderDeleted() {
  return {
    type: USER_FOLDER_DELETED
  };
}

function deletingDocumentsFromFolder() {
  return {
    type: DELETE_DOCUMENTS_FROM_FOLDERS
  };
}

function documentsDeletedFromFolder() {
  return {
    type: DOCUMENTS_DELETED_FROM_FOLDERS
  };
}

function requestAddSharedFolder() {
  return {
    type: REQUEST_ADD_SHARED_FOLDER
  };
}

function receiveAddSharedFolder() {
  return {
    type: RECEIVE_ADD_SHARED_FOLDER
  };
}

function requestAddSharedFolderUsers() {
  return {
    type: REQUEST_ADD_SHARED_FOLDER_USERS
  };
}

function recieveAddSharedFolderUsers() {
  return {
    type: RECEIVE_ADD_SHARED_FOLDER_USERS
  };
}

function requestUpdateSharedFolderUsers() {
  return {
    type: REQUEST_UPDATE_SHARED_FOLDER_USERS
  };
}

function recieveUpdateSharedFolderUsers() {
  return {
    type: RECEIVE_UPDATE_SHARED_FOLDER_USERS
  };
}

export function renameUserFolder(name, folder_id) {
  return function dopost(dispatch) {
    dispatch(renameFolder());

    return api_updateFolder(folder_id, { name })
      .then(response => {
        dispatch(folderRenamed());
        return response;
      });
  };
}

export function changeSelectedFolder(folder) {
  return {
    type: CHANGE_SELECTED_FOLDER,
    folder
  };
}

export function clearSelectedFolder() {
  return {
    type: CLEAR_SELECTED_FOLDER
  };
}

export function openAddFolderMenu() {
  return {
    type: OPEN_ADD_FOLDER_MENU
  };
}

export function closeAddFolderMenu() {
  return {
    type: CLOSE_ADD_FOLDER_MENU
  };
}

export function clearFolderDocuments() {
  return {
    type: CLEAR_FOLDER_DOCUMENTS
  };
}

export function openDeleteModal() {
  return {
    type: DELETE_CONFIRM_FOLDER_OPEN
  };
}

export function closeDeleteModal() {
  return {
    type: DELETE_CONFIRM_FOLDER_CLOSE
  };
}

export function openShareModal(menu) {
  return {
    type: SHARE_FOLDER_MENU_OPEN,
    menu
  };
}

export function closeShareModal(menu) {
  return {
    type: SHARE_FOLDER_MENU_CLOSE,
    menu
  };
}

export function openCopyFolderModal() {
  return {
    type: COPY_FOLDER_MENU_OPEN
  };
}

export function closeCopyFolderModal() {
  return {
    type: COPY_FOLDER_MENU_CLOSE
  };
}

function addingDocumentsToFolder() {
  return {
    type: ADDING_DOCUMENTS_TO_FOLDER
  };
}

export function fetchFolders() {
  return function doFetch(dispatch, getState) {
    const isAuthenticated = getUserAuthenticatedState(getState())

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    dispatch(requestFolders());
    return api_fetchFolders()
      .then(response => {
        dispatch(receiveFolders(response));
        return response;
      });
  };
}

export function addUserFolder(name) {
  return function doPost(dispatch) {
    dispatch(addNewFolder());
    return api_addUserFolder(name)
      .then(response => {
        dispatch(newFolderAdded(response));
        return response;
      });
  };
}

export function addDocumentsToFolder(documentIds, folderId) {
  return function dopost(dispatch) {
    dispatch(addingDocumentsToFolder());
    return api_addDocumentsToFolder(documentIds, folderId)
      .then((response) => {
        dispatch(documentsAddedToFolder(response));
      });
  };
}

export function addSharedFolder(folder_id, user_id, share) {
  return function dopost(dispatch) {
    dispatch(requestAddSharedFolder());
    return api_updateFolder(folder_id, { user_id, share })
      .then(response => {
        dispatch(receiveAddSharedFolder(response));
        return response;
      });
  };
}

export function addSharedFolderUsers(folder_id, users, share_add_users, user_msg) {
  return function dopost(dispatch) {
    dispatch(requestAddSharedFolderUsers());
    return api_updateFolder(folder_id, { users, share_add_users, user_msg })
      .then(response => {
        dispatch(recieveAddSharedFolderUsers());
        return response;
      });
  };
}

export function updateSharedFolderUsers(folder_id, users, removed_users, update_share_permissions) {
  return function dopost(dispatch) {
    dispatch(requestUpdateSharedFolderUsers());
    return api_updateFolder(folder_id, { users, update_share_permissions, removed_users })
      .then(response => {
        dispatch(recieveUpdateSharedFolderUsers());
        return response;
      });
  };
}

export function removeUserFolder(folder_id) {
  return function doDelete(dispatch) {
    dispatch(deletingUserFolder());

    return api_deleteFolder(folder_id, { folder_id })
      .then(() => {
        dispatch(folderDeleted());
      });
  };
}

export function removeDocumentsFromFolder(document_ids, folder_id) {
  return function doDelete(dispatch) {
    dispatch(deletingDocumentsFromFolder());
    return api_deleteFolder(folder_id, { document_ids })
      .then(() => {
        dispatch(documentsDeletedFromFolder());
      });
  };
}
