import type { JurisdictionFromResponse } from 'shared/features/jurisdictionsData/jurisdictionsData.api.types';
import type { AgencyFromResponse } from 'shared/features/agencies/agencies.api.types';
import type { ScrapedCategoryFromResponse } from 'shared/features/categories/scrapedCategories/scrapedCategories.types';
import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';

import * as errorUtils from 'shared/utils/errors';
import * as categoryConstants from 'admin/constants/categories';
import * as scrapedCategoryApi from 'shared/features/categories/scrapedCategories/scrapedCategories.api';
import * as jurisdictionsApi from 'shared/features/jurisdictionsData/jurisdictionsData.api';
import * as agenciesApi from 'shared/features/agencyData/agencyData.api';
import * as caiCategoriesApi from 'shared/features/categories/caiCategories/caiCategories.apiV2';

export const useCategoriesFiltersAsyncLoaders = () => {
  const handleJurisdictionsFetch = async (inputString: string) => {
    try {
      const response = await jurisdictionsApi.api_getJurisdictions({ short_name: inputString });

      return response.items.map((jurisdiction: JurisdictionFromResponse) => ({
        value: jurisdiction.id,
        label: jurisdiction.short_name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleAgenciesFetch = async (inputString: string) => {
    try {
      const response = await agenciesApi.api_getAgencies({ short_name: inputString });

      return response.items.map((agency: AgencyFromResponse) => ({
        value: agency.id,
        label: agency.short_name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleScrapedCategoryFetch = async (inputString: string) => {
    try {
      const response = await scrapedCategoryApi.api_getScrapedCategories({ name: inputString });

      return response.items.map((scrapedCategory: ScrapedCategoryFromResponse) => ({
        value: scrapedCategory.id,
        label: scrapedCategory.name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleCaiCategoryFetch = async (inputString: string) => {
    try {
      const response = await caiCategoriesApi.fetchCaiCategoriesData({ name: inputString });

      return response.items.map((caiCategory: CaiCategoriesFromResponse) => ({
        label: caiCategory.name,
        value: caiCategory.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    [categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION]: handleJurisdictionsFetch,
    [categoryConstants.CATEGORY_ATTRIBUTES.AGENCY]: handleAgenciesFetch,
    [categoryConstants.CATEGORY_ATTRIBUTES.ID]: handleScrapedCategoryFetch,
    [categoryConstants.CATEGORY_ATTRIBUTES.DOCUMENT_CAI_CATEGORY]: handleCaiCategoryFetch
  };
};
