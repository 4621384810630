import React from 'react';
import PropTypes from "prop-types";
import Typography from '../../components/Typography';
import TextInput from '../../components/TextInput';
import Pagination from '../../components/Pagination';

const DocumentCategoriesFooter = ({
  onChangePage,
  pageQuantity,
  onChangeLimit,
  limit,
  externalActivePage
}) => (
  <div className='document-categories_footer'>
    <Pagination
      clasName="pagination"
      pageQuantity={pageQuantity}
      onChange={onChangePage}
      externalActivePage={externalActivePage}
    />
    <div className="limitWrapper">
      <Typography variant="p">Show</Typography>
      <TextInput
        className='limitInput'
        type="number"
        onChange={(e) => onChangeLimit(e.target.value)}
        value={limit}
      />
      <Typography variant="p">Runs</Typography>
    </div>
  </div>
);

DocumentCategoriesFooter.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pageQuantity: PropTypes.number,
  externalActivePage: PropTypes.number
};

DocumentCategoriesFooter.defaultProps = {
  pageQuantity: 1,
  externalActivePage: 1
};

export default DocumentCategoriesFooter;
