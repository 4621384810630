import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { getSpiderRunReports as reduxGetSpiderRunReports } from '../spiderRunReports.actions';

export const useSpiderRunReportsReduxActions = () => {
  const dispatch = useDispatch();

  const getSpiderRunReports = useCallback(
    (...params) => {
      return dispatch(reduxGetSpiderRunReports(...params));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getSpiderRunReports
    }),
    [getSpiderRunReports]
  );
};
