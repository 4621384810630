// ----- ERRORS -----

export const ERRORS = {
  // combine category
  errorCombineLength: {
    title: 'Category Amount Issue',
    text: 'Please select from two lines to combine categories.'
  },
  errorAppCategory: {
    title: 'App Category Issue',
    text: 'Please select categories with the same "app_categories" to combine.'
  },
  errorScrapedCategory: {
    title: 'Scraped Category Issue',
    text: 'Please select categories with the same "scraped_category" to combine.'
  },
  errorDocCategoryId: {
    title: 'Scraped Doc Category Id Issue',
    text: 'Please select categories with the same "doc_category_id" to combine.'
  },
  // split category
  errorSplitLength: {
    title: 'Category Amount Issue',
    text: 'Please select at least one category for split.'
  },
  errorJurisdictions: {
    title: 'Jurisdictions Amount Issue',
    text: 'Please select category with two or more Jurisdictions for split.'
  },
  errorSplitJurisdictions: {
    title: 'Jurisdictions Amount Issue',
    text: 'Please left at least one Jurisdiction to category.'
  },
  errorMetaCategory: {
    title: 'Meta Category Variant Issue',
    text: 'Please set different meta categories to avoid duplications.'
  }
};

// ----- SELECT CONSTANTS -----

export const SELECT_BOOL = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];

// ----- TABLE CONSTANTS -----

export const TABLE_VARIABLES = {
  id: 'id',
  scraped_category: 'scraped_category',
  app_category: 'app_category',
  document_meta_category: 'document_meta_categories_id',
  surface_docs: 'surface_docs',
  surface_category: 'surface_category',
  jurisdictions: 'jurisdictions',
  doc_category_id: 'doc_category_id',
  indexed: 'indexed',
  category_details: 'category_details',
  description: 'description'
};

export const DEFAULT_TABLE_COLUMNS = [
  TABLE_VARIABLES.scraped_category,
  TABLE_VARIABLES.app_category,
  TABLE_VARIABLES.document_meta_category,
  TABLE_VARIABLES.surface_docs,
  TABLE_VARIABLES.surface_category,
  TABLE_VARIABLES.jurisdictions
];

export const COLUMNS_CONFIG = [
  { name: 'Scraped Category', id: TABLE_VARIABLES.scraped_category, filter: true },
  { name: 'App Category', id: TABLE_VARIABLES.app_category, filter: true },
  { name: 'Meta Category', id: TABLE_VARIABLES.document_meta_category, filter: true },
  { name: 'Surface Docs In App', id: TABLE_VARIABLES.surface_docs, filter: true },
  { name: 'Category In App Filters', id: TABLE_VARIABLES.surface_category, filter: true },
  { name: 'Jurisdictions', id: TABLE_VARIABLES.jurisdictions, filter: true }
];
