import React from 'react';
import {
  Modal,
  Button
} from 'react-bootstrap';
import { popupSettings } from '../constants/popupInfoConst';

export default function PopupInfo(
  {
    show,
    onHide,
    popupName,
    onSecondBtn,
    onThirdBtn,
    errorText,
    children
  }
) {
  const currentPopup = popupSettings.find(item => item.name === popupName) || {};
  return (
    <Modal show={show} onHide={onHide} className="popupInfoModal thinBtn">
      <Modal.Header>
        <Modal.Title>{(currentPopup.title) || "Alert"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children || errorText || currentPopup.body || 'Are you sure'}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>
          {(currentPopup.secondButton || currentPopup.thirdButton) ? "Cancel" : "Ok"}
        </Button>
        {currentPopup.secondButton && (
          <Button onClick={onSecondBtn} className="btn-danger">
            {currentPopup.secondButton.name}
          </Button>
        )}
        {currentPopup.thirdButton && (
          <Button onClick={onThirdBtn} className="btn-primary">
            {currentPopup.thirdButton.name}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
