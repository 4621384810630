import type { EitlLabelActionsDropdownData } from './useEitlLabelActionsDropdownData.types';

import { useMemo, useState } from 'react';

export const useEitlLabelActionsDropdownData = (): EitlLabelActionsDropdownData => {
  const [isOpen, setIsOpen] = useState<EitlLabelActionsDropdownData['localState']['isOpen']>(false);

  const localState: EitlLabelActionsDropdownData['localState'] = {
    isOpen
  };

  const localActions: EitlLabelActionsDropdownData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    [setIsOpen]
  );

  return {
    localState,
    localActions
  };
};
