import type { GlobalState } from '../../../reducers';
import type { NewsSourceFromResponseV2 } from './newsSources.api.types';

import { createSelector } from 'reselect';

export const getNewsSourcesReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.newsSources
);

export const getNewsSources = createSelector(
  getNewsSourcesReducer,
  (currentNewsSourcesReducer): NewsSourceFromResponseV2[] => {
    return currentNewsSourcesReducer?.items;
  }
);

export const getFirstNewsSource = createSelector(
  getNewsSources,
  (newsSources: NewsSourceFromResponseV2[]): NewsSourceFromResponseV2 => newsSources?.[0]
);
