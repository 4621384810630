export const UPDATE_DOC_TOOLTIP =
  'When the document is updated at the source, if the crawling process ingests it again and detects changes in “full_text”, “PDF”, “parent document_id” and “unofficial publication date flag”, a new document version may be created.';

export const UPDATE_DOC_METADATA_TOOLTIP =
  'When the document is updated at the source and the crawling process ingests it again, the information in the current document version will be replaced or overwritten with the latest information retrieved from the source.';
export const DEBUG_MODE_TOOLTIP =
  'Unified crawl generates a huge lot more debug log than it does with this on, please check first a crawler log in the run report if the normal verbosity is sufficient';
export const DEBUG_VIDEO_TOOLTIP =
  'Unified crawl video records the entire run , but limits the recording to max size';
export const CURSOR_RUN_TOOLTIP = 'Unified crawl generates cursor information';
export const AUTO_RESUME_TOOLTIP =
  'Unified crawl resumes automatically from it lastest cursor, if this run is timedout';
