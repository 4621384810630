import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from "redux";
import { connect } from "react-redux";
import { uploadNewCommandRequest, fetchAllCommands } from 'shared/features/commands/commands.actions';
import Modal from "../../components/Modal";
import CRAWL_TYPES from "../../constants/crawlersTypes";
import { setNotification, NOTIFICATION_TYPES } from '../../components/Notification';
import { RUN_NOW_ERROR } from './CrawlMonitorTool.constants';
import CrawlRunNowModalBody from '../CrawlerPage/CrawlRunNowModal/CrawlRunNowModalBody';
import { reformatCrawlCommandRequest } from '../CrawlerPage/CrawlRunNowModal/crawlRunNowModal.helper';

const CrawlRunNowModal = ({
  commands,
  show,
  onHide,
  reduxFetchAllCommands,
  createRunNowCommandRequest,
  selectedCrawl,
  crawlers
}) => {
  useEffect(() => {
    if (!commands.length) {
      reduxFetchAllCommands();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createMultiRunNow = (formatCommandRequest) => {
    const collectedCrawl = crawlers.filter(crawl => selectedCrawl.includes(crawl.id));
    const allIsUnified = collectedCrawl.every(crawl => crawl.type === CRAWL_TYPES.unified.value);
    const crawlsCount = collectedCrawl.length;
    if (crawlsCount > 1) {
      if (allIsUnified) {
        collectedCrawl.forEach(crawl => {
          const reformatRequest = reformatCrawlCommandRequest(crawl, formatCommandRequest);
          createRunNowCommandRequest(reformatRequest);
        });
      } else {
        setNotification({ message: RUN_NOW_ERROR, type: NOTIFICATION_TYPES.ERROR });
      }
    } else {
      createRunNowCommandRequest(formatCommandRequest);
    }
  };

  const crawlDetails = crawlers.find(crawl => selectedCrawl.includes(crawl.id)) || {};

  return (
    <Modal
      className="crawlRunNow-Modal"
      title="Run Now"
      show={show}
      onHide={onHide}
      size="small"
    >
      <CrawlRunNowModalBody
        closePopup={onHide}
        popupState={show}
        createRunNowCommandRequest={createMultiRunNow}
        crawlDetails={crawlDetails}
      />
    </Modal>
  );
};

CrawlRunNowModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  commands: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  crawlers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  reduxFetchAllCommands: PropTypes.func,
  selectedCrawl: PropTypes.arrayOf(PropTypes.number)
};

CrawlRunNowModal.defaultProps = {
  show: false,
  commands: [],
  crawlers: [],
  onHide: () => {},
  reduxFetchAllCommands: () => {},
  selectedCrawl: []
};

const mapStateToProps = state => ({
  crawlers: _.get(state.externalApiCrawl, 'items', []),
  commands: state.commands.items
});

export default compose(connect(mapStateToProps, {
  reduxFetchAllCommands: fetchAllCommands,
  createRunNowCommandRequest: uploadNewCommandRequest
}))(CrawlRunNowModal);
