export const DOC_RELATION_GENERAL = {
  id: 'id',
  related_doc_id: 'related_doc_id',
  relation_type: 'relation_type'
};

export const DOC_RELATION_DETAILS = {
  [DOC_RELATION_GENERAL.id]: 'new', // number | new
  [DOC_RELATION_GENERAL.related_doc_id]: null,
  [DOC_RELATION_GENERAL.relation_type]: null // { value: "", label: "" }
};
