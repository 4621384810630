import {fetch, BASE_API_OPTIONS} from "fetch";

export function api_getCrawlerRawDryRun(crawlId) {
  return fetch({
    url: `/dry_run/${crawlId}`,
    dataType: 'Crawler',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_getCrawlerRawSpec(crawlId) {
  return fetch({
    url: `/crawl_spec/${crawlId}`,
    dataType: 'Crawler',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_postCrawlRawSpec(params) {
  return fetch({
    url: `/crawl_spec`,
    dataType: 'Crawler',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    jsonSpaces: 2
  });
}

export function api_getCrawlSpecOptions() {
  return fetch({
    url: `/crawl_spec_options`,
    dataType: 'Crawler',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_getCrawlerRawConfig(crawlId) {
  return fetch({
    url: `/crawl_config/${crawlId}`,
    dataType: 'Crawler',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_postCrawlRawConfig(params) {
  return fetch({
    url: `/crawl_config`,
    dataType: 'Crawler',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    jsonSpaces: 2
  });
}
