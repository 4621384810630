import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SpecElementsHeader from './SpecElementsHeader';

const SpecElementsWrapper = ({
  show,
  label,
  tooltip,
  children,
  debugValue,
  onDebug
}) => (
  <div
    className={cn(
      "crawl-spec_element",
      { "crawl-spec_element_hide": !show }
    )}
  >
    <SpecElementsHeader
      label={label}
      tooltip={tooltip}
      debugValue={debugValue}
      onDebug={onDebug}
    />
    <div className="crawl-spec_element_body">
      {children}
    </div>
  </div>
);

SpecElementsWrapper.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  debugValue: PropTypes.bool,
  onDebug: PropTypes.func
};

SpecElementsWrapper.defaultProps = {
  show: false,
  label: '',
  tooltip: '',
  debugValue: false,
  onDebug: null
};

export default SpecElementsWrapper;
