import type { EitlLabelsImportModalData } from './useEitlLabelsImportModalData.types';

import { useState } from 'react';

export const useEitlLabelsImportModalData = (): EitlLabelsImportModalData => {
  const [attachments, setAttachments] = useState<
    EitlLabelsImportModalData['localState']['attachments']
  >([]);

  const localState: EitlLabelsImportModalData['localState'] = {
    attachments
  };

  const localActions: EitlLabelsImportModalData['localActions'] = {
    setAttachments
  };

  return {
    localState,
    localActions
  };
};
