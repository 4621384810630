import type { ComponentProps, FC } from 'react';
import type { StatusCellProps } from './StatusCell.types';

import * as elements from './elements';

export const StatusCell: FC<StatusCellProps> = ({ row }) => {
  return (
    <elements.BodyCrawlStatus
      report={row as ComponentProps<typeof elements.BodyCrawlStatus>['report']}
      textAlign={elements.BODY_CRAWL_STATUS_TEXT_ALIGNMENT.CENTER}
    />
  );
};
