import { useLocation, useParams } from 'react-router-dom';

/**
 *
 * Returns a pathname without URL params
 *
 */
export const usePathname = (): string => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  return Object.values(params).reduce(
      (path, param) => path?.replace(`/${param}`, ''),
      location.pathname
  ) as string;
};
