import { fetch } from 'fetch';

export function api_subscribe(data) {
  return fetch({
    url: `/subscriptions`,
    dataType: 'Subscription',
    method: 'POST',
    params: data
  });
}

export function api_fetchSubscriptions() {
  return fetch({
    url: `/subscriptions`,
    dataType: 'Subscription',
    method: 'GET'
  });
}

export function api_updateUserSubscription(subscription_id, data) {
  return fetch({
    url: `/subscriptions/${subscription_id}`,
    dataType: 'Subscription',
    method: 'POST',
    params: data
  });
}

export function api_updateUserSubscriptionByUserId(user_id, data) {
  return fetch({
    url: `/subscriptions_by_user_id/${user_id}`,
    dataType: 'Subscription',
    method: 'POST',
    params: data
  });
}

export function api_fetchAllSubscriptions() {
  return fetch({
    url: `/subscriptions/all`,
    dataType: 'Subscription',
    method: 'GET'
  });
}

export function api_unsubscribeBasicUser(data) {
  return fetch({
    url: `/basic_user_unsubscribe`,
    dataType: 'Subscription',
    method: 'POST',
    params: data
  });
}
