import { createSelector } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './documentData.constants';
import { GlobalState } from '../../reducers';
import { DocumentDataFromResponse } from './documentData.types';

export const getDocumentApiDataReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state?.[REDUCER_NAME]
);

export const getDocumentApiData = createSelector(
  getDocumentApiDataReducer,
  (currentDocumentApiDataReducer): DocumentDataFromResponse[] => {
    return currentDocumentApiDataReducer?.items;
  }
);

export const getFirstDocumentApiElement = createSelector(
  getDocumentApiData,
  (currentExternalApiCrawlReducer): DocumentDataFromResponse => {
    return currentExternalApiCrawlReducer?.[0] ?? {};
  }
);

export const getDocumentApiTimestamp = createSelector(
  getDocumentApiDataReducer,
  (currentDocumentApiDataReducer): string => {
    return currentDocumentApiDataReducer?.timestamp ?? '';
  }
);
