import { fetch } from 'shared/utils/api';

export function api_getUserById(id) {
  return fetch({
    url: `/get_user/${id}`,
    method: 'GET'
  });
}

export function api_updateUserById(id, params) {
  return fetch({
    url: `/update_user/${id}`,
    method: 'POST',
    params
  });
}
