import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import * as actions from '../newsSources.actions';

export const useNewsSourcesReduxActions = () => {
  const dispatch = useDispatch();

  const fetchNewsSource = useCallback(
    async (...args) => {
      return dispatch(actions.getNewsSources(...args));
    },
    [dispatch]
  );

  const updateNewsSource = useCallback(
    async (...args) => {
      return dispatch(actions.updateNewsSources(...args));
    },
    [dispatch]
  );

  const clearNewsSources = useCallback(
    async (...args) => {
      return dispatch(actions.clearNewsSources(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      clearNewsSources,
      fetchNewsSource,
      updateNewsSource
    }),
    [clearNewsSources, fetchNewsSource, updateNewsSource]
  );
};
