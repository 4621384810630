import {fetch, BASE_API_OPTIONS, AUTHENTICATION_OPTIONS_KEYS} from "fetch";
import { buildUrlFromParams } from './documents.helper';
import { googleSearchAPIKey, googleSearchCXId } from 'shared/config';
import { DOCUMENTS } from 'app/constants/DataTypes';

export function api_getDocTypes() {
  return fetch({
    url: '/doc_types',
    dataType: 'DocTypes',
    method: 'GET'
  });
}

export function api_fetchGoogleResults(queryParam) {
  return fetch({
    url: `https://www.googleapis.com/customsearch/v1?key=${
      googleSearchAPIKey
    }&cx=${googleSearchCXId}&q=${queryParam}`,
    method: 'GET',
    dataType: 'GoogleResults',
    baseApi: BASE_API_OPTIONS.BASE_API_ABSOLUTE
  });
}

export function api_fetchDocDiff(docs) {
  return fetch({
    url: '/diff_documents',
    method: 'POST',
    dataType: 'DocDiff',
    params: docs
  });
}

export function api_getDocumentsBasic(params) {
  return fetch({
    url: '/docs',
    dataType: DOCUMENTS,
    params,
    method: 'GET'
  });
}

export function api_getDocsForIFrame(params) {
  return fetch({
    url: buildUrlFromParams(params, '/docs'),
    dataType: DOCUMENTS,
    method: 'GET',
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY
  });
}

export function api_fetchSentences(docIds, params, contentType = 'application/json') {
  return fetch({
    url: `/sentences`,
    dataType: 'Sentences',
    params: {
      ...params,
      ids: docIds.join(',')
    },
    headers: {
      'Content-type': contentType
    },
    method: 'GET'
  });
}

export function api_getEnforcements(params, useAPIKey) {
  return fetch({
    url: buildUrlFromParams(params, '/documents'),
    dataType: 'Enforcements',
    method: 'GET',
    authenticationType: useAPIKey ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : AUTHENTICATION_OPTIONS_KEYS.REDUX_STATE
  });
}

export function api_getDocuments(params, rnToken) {
  return fetch({
    url: buildUrlFromParams(params, '/documents'),
    customToken: rnToken,
    dataType: DOCUMENTS,
    method: 'GET'
  });
}

export function api_getDataDocs(params, rnToken) {
  return fetch({
    url: buildUrlFromParams(params, '/documents'),
    customToken: rnToken,
    dataType: DOCUMENTS,
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_fetchAggregateEnforcements(params) {
  return fetch({
    url: buildUrlFromParams({
      ...params,
      limit: 2000 // need to aggregate all enforcements
    }, '/documents/enforcements-aggregate'),
    dataType: 'AggregateEnforcements',
    method: 'GET'
  });
}

export function api_vanillaGetDocuments(params) {
  return fetch({
    url: '/documents',
    dataType: DOCUMENTS,
    params,
    method: 'GET'
  });
}

export function api_getIncompleteDocuments(params) {
  return fetch({
    url: '/document-data',
    dataType: 'IncompleteDocuments',
    params,
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_updateDocuments(document_ids, data) {
  return fetch({
    url: '/documents',
    dataType: DOCUMENTS,
    method: 'POST',
    params: {
      ...data,
      document_ids
    }
  });
}

export function api_updateDocument(documentId, params) {
  return fetch({
    url: `/documents/${documentId}`,
    dataType: DOCUMENTS,
    method: 'POST',
    params
  });
}

export function api_updateDataDocument(documentId, params) {
  return fetch({
    url: `/document/${documentId}`,
    dataType: DOCUMENTS,
    method: 'POST',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_createDocument(params) {
  return fetch({
    url: `/user_created_documents`,
    dataType: 'Document',
    method: 'POST',
    params
  });
}

export function api_getHiddenDocuments(params) {
  return fetch({
    url: `/hidden_documents`,
    dataType: 'HiddenDocuments',
    method: 'GET',
    params
  });
}

export function api_getDocument(documentId) {
  return fetch({
    url: `/documents/${documentId}`,
    dataType: 'Document',
    method: 'GET'
  });
}

export function api_fetchPopularDocs(params) {
  return fetch({
    url: '/popular_docs',
    params,
    method: 'GET',
    dataType: 'PopularDocs'
  });
}

export function api_getDocumentResponseHeaders(params) {
  return fetch({
    url: '/new_document_url',
    params,
    method: 'GET',
    dataType: 'DocumentResponseHeaders'
  });
}

export function api_fetchRecentActivity(params) {
  return fetch({
    url: `/rules_created_by_time`,
    dataType: 'RecentActivity',
    params,
    method: 'GET'
  });
}
