import type { GlobalState } from 'shared/reducers';
import type { FlagModalData } from './useFlagModalData.types';

import { useSelector } from 'react-redux';
import { getCurrentUser } from 'shared/features/user/user.selectors';
import { useMemo, useState } from 'react';
import { DOCUMENT_DETAILS } from '../../../../DocumentPage/DocumentDetails/DocumentDetails.constants';
import { documentFlagIssueTypes } from '../../../../DocumentPage/DocumentDetails/elements/DocumentFlagModal/DocumentFlagModal.constants';

export const useFlagModalData = (): FlagModalData => {
  const [docDetails, setDocDetails] =
    useState<FlagModalData['localState']['docDetails']>(DOCUMENT_DETAILS);
  const [issueType, setIssueType] = useState<FlagModalData['localState']['issueType']>(
    documentFlagIssueTypes.TECHNICAL
  );
  const [field, setField] = useState<FlagModalData['localState']['field']>('');
  const [notes, setNotes] = useState<FlagModalData['localState']['notes']>('');
  const [relatedDocuments, setRelatedDocuments] =
    useState<FlagModalData['localState']['relatedDocuments']>('');
  const [error, setError] = useState<FlagModalData['localState']['error']>(false);
  const [isLoading, setIsLoading] = useState<FlagModalData['localState']['isLoading']>(false);

  const reduxState = useSelector<GlobalState, FlagModalData['reduxState']>(state => ({
    user: getCurrentUser(state)
  }));

  const formattedData: FlagModalData['formattedData'] = useMemo(
    () => ({
      issueTypeOptions: Object.values(documentFlagIssueTypes)
    }),
    []
  );

  const localState: FlagModalData['localState'] = {
    docDetails,
    issueType,
    field,
    notes,
    relatedDocuments,
    error,
    isLoading
  };

  const localActions: FlagModalData['localActions'] = useMemo(
    () => ({
      setDocDetails,
      setIssueType,
      setField,
      setNotes,
      setRelatedDocuments,
      setError,
      setIsLoading
    }),
    []
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
