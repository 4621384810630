import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ErrorRounded, InfoRounded, CheckCircle, Close } from '@material-ui/icons';
import { Fade } from '@material-ui/core';
import { ALERT_TYPES } from './Alert.constants';
import { useAlertData, useAlertLifecycle } from './hooks';
import './_alert.scss';

const ALERT_ICONS = {
  [ALERT_TYPES.INFO]: InfoRounded,
  [ALERT_TYPES.ERROR]: ErrorRounded,
  [ALERT_TYPES.SUCCESS]: CheckCircle
};

export const DEFAULT_TIMEOUT = 3000;

export const TEST_IDS = {
  CONTAINER: 'alert-test-id',
  TITLE: 'alert-title',
  CLOSE_BUTTON: 'alert-close-button'
};

export const Alert = ({
  type,
  title,
  content,
  className,
  dataTestId,
  timeout,
  onClose,
  isNotification,
  isInlineNotification,
  canBeClosed,
  extraElement
}) => {
  const Icon = ALERT_ICONS[type];

  const { localState, localActions } = useAlertData();

  useAlertLifecycle({
    props: {
      timeout,
      type,
      title,
      content
    },
    localState,
    localActions
  });

  const renderContent = useCallback(
    () => (
      <div
        data-testid={dataTestId || TEST_IDS.CONTAINER}
        className={classnames(className, 'alert__container', {
          [type]: Boolean(type),
          'alert__container--is-notification': isNotification,
          'alert__container--is-inline-notification': isInlineNotification
        })}
      >
        <div className={'alert__content-wrapper'}>
          <div className="alert__icon-wrapper">{Icon && <Icon className="alert__icon" />}</div>
          <div className="alert__body">
            {title && (
              <h3 data-testid={TEST_IDS.TITLE} className="alert__title">
                {title}
              </h3>
            )}
            <p className="alert__content">{content}</p>
          </div>
          {extraElement}
        </div>
        {canBeClosed && (
          <Close
            className={'alert__close-icon'}
            onClick={onClose}
            data-testid={TEST_IDS.CLOSE_BUTTON}
          />
        )}
      </div>
    ),
    [
      Icon,
      className,
      content,
      dataTestId,
      isInlineNotification,
      isNotification,
      title,
      type,
      canBeClosed,
      extraElement,
      onClose
    ]
  );

  if (isNotification || isInlineNotification) {
    return (
      <Fade in={localState.isVisible} onExit={onClose}>
        {renderContent()}
      </Fade>
    );
  }

  return renderContent();
};

Alert.propTypes = {
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isNotification: PropTypes.bool,
  isInlineNotification: PropTypes.bool,
  timeout: PropTypes.number,
  canBeClosed: PropTypes.bool,
  extraElement: PropTypes.node
};

Alert.defaultProps = {
  type: ALERT_TYPES.INFO,
  title: undefined,
  content: undefined,
  className: undefined,
  dataTestId: '',
  isNotification: false,
  isInlineNotification: false,
  timeout: DEFAULT_TIMEOUT,
  canBeClosed: false,
  extraElement: null
};
