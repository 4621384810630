import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import { api_getCaiCategories } from 'shared/features/categories/caiCategories/caiCategories.api';
import Typography from "../../../../components/Typography";
import Spinner from "../../../../components/spinner";
import { CAT_CONST, SRS_CONST } from '../Glassdoor.constants';
import { formatCat, getPubType } from "../Glassdoor.helper";
import Info from "./GlassdoorTableComponents/Info";
import Header, { HeaderRow } from "./GlassdoorTableComponents/Header";
import GlassdoorScrapedCategories from "./GlassdoorScrapedCategories";
import Accordion from "./Accordion";

const ERROR = 'Cai Category Upload Failed';
const NOT_FOUND = 'Cai Category for this Publisher not found';

const SHOW_SCRAPPED_OPEN = 1;

const GlassdoorCategories = ({
  openPub,
  pubType,
  pubID,
  artifacts
}) => {
  const [load, setLoad] = useState(false);
  const [caiCategory, setCaiCategory] = useState([]);
  const [count, setCount] = useState(0);
  const [timestamp, setTimestamp] = useState('time');
  const [error, setError] = useState('');

  const publicationType = getPubType(pubType);

  const request = async () => {
    await setLoad(true);
    await setError('');
    try {
      const response = await api_getCaiCategories({
        glassdoor: true,
        [publicationType]: pubID,
        limit: 3,
        offset: caiCategory.length,
        ...artifacts
      });
      const items = _.get(response, 'items', []);
      const countItems = _.get(response, 'count', []);
      if (items) {
        await setCaiCategory([...caiCategory, ...items]);
        await setCount(countItems);
        await setTimestamp(Date().toString());
      }
    } catch {
      await setError(ERROR);
    } finally {
      await setLoad(false);
    }
  };

  useEffect(() => {
    if (openPub && pubID) {
      request();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openPub, pubID]);

  const formatCaiCategory = formatCat(caiCategory, timestamp);

  return (
    <div className="glassdoor_table glassdoor_table_left-border">
      <Header
        className="glassdoor_table_header_cai-category"
        leftBlockClass="category-header-left-block"
        leftChild={(
          <HeaderRow items={[
            'CAI Category'
          ]}
          />
        )}
      />
      {formatCaiCategory.map(item => (
        <GlassdoorCategory
          key={`${item[CAT_CONST.ID]}-${item[CAT_CONST.NAME]}`}
          item={item}
          artifacts={{
            ...artifacts,
            [publicationType]: pubID
          }}
        />
      ))}
      {load && <Info><Spinner /></Info>}
      {error && <Info><Typography bold variant="p" color="red">{error}</Typography></Info>}
      {!formatCaiCategory.length && !load && !error && (
        <Info><Typography bold variant="p" color="yellow">{NOT_FOUND}</Typography></Info>
      )}
      {count > caiCategory.length && !load && (
        <Info>
          <Typography onClick={request} bold variant="p" color="blue" className={"load-more"}>Load More Cai</Typography>
        </Info>
      )}
    </div>
  );
};

GlassdoorCategories.propTypes = {
  openPub: PropTypes.bool,
  pubType: PropTypes.string,
  pubID: PropTypes.number,
  artifacts: PropTypes.shape({
    jurisdiction_id: PropTypes.number
  })
};

GlassdoorCategories.defaultProps = {
  openPub: false,
  pubType: '',
  pubID: null,
  artifacts: {}
};

const GlassdoorCategory = ({
  item,
  artifacts
}) => {
  const [open, setOpen] = useState(item[CAT_CONST.SCRAPPED_COUNT] <= SHOW_SCRAPPED_OPEN);

  const toggleAccordion = (id, isOpen) => {
    setOpen(!isOpen);
  };

  return (
    <Accordion
      key={item[CAT_CONST.ID]}
      open={open}
      headerClass="accordion-update accordion-update_cai-category"
      leftInfo={(
        <div className="table-row table-row_source">
          <div className="table-cell"><Typography variant="p">{item[CAT_CONST.NAME]}</Typography></div>
        </div>
      )}
      // commented to speedup glassdoor (counter doesn't come from be)
      // rightInfo={(<Typography variant="p">{item[CAT_CONST.DOC_COUNT]}</Typography>)}
      onClick={toggleAccordion}
      id={item[SRS_CONST.ID]}
    >
      <GlassdoorScrapedCategories
        caiCategoryID={item[CAT_CONST.ID]}
        openPub={open}
        artifacts={artifacts}
      />
    </Accordion>
  );
};

GlassdoorCategory.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }),
  artifacts: PropTypes.shape({
    jurisdiction_id: PropTypes.number
  })
};

GlassdoorCategory.defaultProps = {
  item: {},
  artifacts: {}
};

export default GlassdoorCategories;
