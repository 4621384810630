import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'jurisdictionsData';
export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const jurisdictionsReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setJurisdiction: (state, action) => action.payload,
    createJurisdiction: (state, action) => ({
      ...state,
      count: state.count+1,
      items: [...state.items, action.payload]
    }),
    updateJurisdiction: (state, action) => ({
      ...state,
      items: state.items.map(item => item.id === action.payload.id ? action.payload : item)
    }),
    deleteJurisdiction: (state, action) => ({
      ...state,
      count: state.count-1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default jurisdictionsReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setJurisdiction,
  createJurisdiction,
  updateJurisdiction,
  deleteJurisdiction
} = jurisdictionsReducer.actions;
