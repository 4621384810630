import { AUTOCOMPLETE } from "./fieldTypes";
import {
  CONSTRUCTOR_PARAMS,
  YEAR,
  MAX_INGESTED,
  YEARS,
  WEEKS_AGO,
  DATE_FILTER,
  DATE_TODAY,
  AREAS_INTEREST,
  AGENCIES,
  SECTIONS
} from './runNowParametersNames';

/*
  key - which value should include extra object
  value - name of filed in which should be store value

  example:
    before:
      key: fieldValue
    after:
      key: { [value]: fieldValue }
*/
const extraWrappingFields = {
  [CONSTRUCTOR_PARAMS]: YEAR
};

// this crawls always should have this values in this fields
const predefinedValues= {
  'references.uk_boe_pra_regs_crawler': [{ value: MAX_INGESTED, predefinedValue: 0 }],
  'references.uk_boe_prudential_reg_pub': [{ value: MAX_INGESTED, predefinedValue: 0 }]
};

const customCrawlsComponents = {
  maxIngested: { value: MAX_INGESTED, name: 'Max Ingested' },
  years: { value: YEARS, name: 'Years' },
  weeksAgo: { value: WEEKS_AGO, name: 'Weeks Ago' },
  constructorParams: { value: CONSTRUCTOR_PARAMS, name: 'Constructor Params' },
  dayFilter: { value: DATE_FILTER, name: 'Date Filter' },
  dateToday: { value: DATE_TODAY, name: 'Date Today' },
  areasInterest: { value: AREAS_INTEREST, name: 'Areas Interest' },
  agencies: {
    name: 'Agencies',
    value: AGENCIES,
    type: AUTOCOMPLETE,
    options: ['HM-Treasury', 'CMA'],
    defaultValue: null,
    multiple: true
  },
  sections: {
    name: 'Sections',
    value: SECTIONS,
    type: AUTOCOMPLETE,
    options: ['CRR Firms', 'Non-CRR Firms', 'Solvency II Firms', 'Non-Solvency II Firms', 'Non-authorised Persons'],
    defaultValue: null,
    multiple: true
  }
};

// custom crawl that have access to update switches
const ARCHIVE_CUSTOM_FULL_ACCESS = [
  'references.sec_litigation_releases',
  'references.regulationsgov_ingest_comments'
];

const customCrawlRunNowSettings = {
  'references.nyse_rule_filings': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.years
  ],
  'references.nyse_rule_interpretations': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.years
  ],
  'references.lexology': [
    customCrawlsComponents.maxIngested
  ],
  'references.sec_administrative_proceedings': [
    customCrawlsComponents.maxIngested
  ],
  'references.frs_press_releases': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo
  ],
  'references.frs_speeches': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo
  ],
  'references.frs_testimonies': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo
  ],
  'references.fdic_press_releases': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo,
    customCrawlsComponents.constructorParams
  ],
  'references.freddie_guide_bulletin': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.years
  ],
  'references.cftc_cotton_on_call_report': [
    customCrawlsComponents.maxIngested
  ],
  'references.cftc_cleared_margin_reports': [
    customCrawlsComponents.maxIngested
  ],
  'references.fannie_single_family_news': [
    customCrawlsComponents.maxIngested
  ],
  'references.freddie_single_family_news': [
    customCrawlsComponents.maxIngested
  ],
  'references.fha_info': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.years
  ],
  'references.fha_mortgagee_letters': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.years
  ],
  'leverx-il-idfpr-news': [
    customCrawlsComponents.maxIngested
  ],
  idpfr: [
    customCrawlsComponents.maxIngested
  ],
  'references.idpfr': [
    customCrawlsComponents.maxIngested
  ],
  'references.mo_difp_news': [
    customCrawlsComponents.maxIngested
  ],
  'references.fha_quaterly_report': [
    customCrawlsComponents.maxIngested
  ],
  'references.regulationsgov_ingest_comments': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.dayFilter
  ],
  'reporting.fed_api_daily_report': [
    customCrawlsComponents.maxIngested
  ],
  'references.fed_api': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.dateToday
  ],
  'references.publaw': [
    customCrawlsComponents.maxIngested
  ],

  //only sandbox
  'references.fdic_enforcements': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo
  ],
  'references.finra_enforcements': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo
  ],
  'references.nyse_enforcements': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.years
  ],
  'references.occ_enforcements': [
    customCrawlsComponents.maxIngested
  ],
  'references.cfpb_enforcements': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo
  ],
  'references.frs_enforcements': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.years
  ],
  'references.ca_dbo_enforcements': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.weeksAgo
  ],
  'references.cards_commerce_enforcement_crawler': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.areasInterest
  ],
  'references.in_enforcements': [
    customCrawlsComponents.maxIngested
  ],
  'references.sec_litigation_releases': [
    customCrawlsComponents.maxIngested
  ],

  // UK custom crawls
  'references.uk_gov_api': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.agencies
  ],
  'references.uk_boe_pra_regs_crawler': [
    customCrawlsComponents.maxIngested,
    customCrawlsComponents.sections
  ],
  'references.uk_boe_prudential_reg_pub': [
    customCrawlsComponents.maxIngested
  ]
};

export default customCrawlRunNowSettings;
export { extraWrappingFields, predefinedValues, ARCHIVE_CUSTOM_FULL_ACCESS };
