import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';
import TextInput from '../../../components/TextInput';
import { DOC_RELATION_DETAILS, DOC_RELATION_GENERAL } from './DocumentRelations.constants';
import Button from '../../../components/Button';
import { formDocumentRelationParameters, loadLanguages } from './DocumentRelations.helper';
import { createDocumentRelations } from '../../../../shared/features/documentRelations/documentRelations.actions';
import * as uiLib from '@compliance.ai/web-components';
import { RELATION_TYPES_OPTIONS } from 'admin/containers/DocumentPage/DocumentDetails/DocumentDetails.constants';

const DocumentRelationsCreation = ({ toggleEditMode, current_user, docDetailsInfo }) => {
  const dispatch = useDispatch();
  const [docRelationDetails, setDocRelationDetails] = useState(DOC_RELATION_DETAILS);
  const [error, setError] = useState(false);
  const [editMode] = useState();

  useEffect(() => {
    setError(false);
  }, [docRelationDetails]);

  const handleChanges = (name, value) => {
    setDocRelationDetails({
      ...docRelationDetails,
      [name]: value?.value ? value.value : value
    });
  };

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  useEffect(
    () => handleChanges(DOC_RELATION_GENERAL.id, _.get(docDetailsInfo, 'id', 0)),
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [docDetailsInfo]
  );

  const createDocRelation = () => {
    if (
      docRelationDetails[DOC_RELATION_GENERAL.related_doc_id] &&
      docRelationDetails[DOC_RELATION_GENERAL.relation_type]
    ) {
      const docRelationInfo = formDocumentRelationParameters(docRelationDetails, current_user);
      dispatch(createDocumentRelations(docRelationInfo));
      toggleEditMode();
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <div className="document-list_details_items_list">
        <TextInput
          className="input_4"
          label="Related Document ID"
          name={DOC_RELATION_GENERAL.related_doc_id}
          value={docRelationDetails[DOC_RELATION_GENERAL.related_doc_id]}
          onChange={handleChangesEvent}
        />
        <uiLib.LabeledField label="Relation Type" className="input_1">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            isDisabled={editMode}
            isClearable
            value={docRelationDetails[DOC_RELATION_GENERAL.relation_type]}
            options={RELATION_TYPES_OPTIONS}
            onChange={e => handleChanges(DOC_RELATION_GENERAL.relation_type, e)}
          />
        </uiLib.LabeledField>
        {error && (
          <Alert bsStyle="danger" className="adminToolErrorMessage">
            Please check your entries
          </Alert>
        )}
      </div>
      <Button size="custom" color="success" onClick={() => createDocRelation()}>
        Save Changes
      </Button>
    </div>
  );
};

DocumentRelationsCreation.propTypes = {
  toggleEditMode: PropTypes.func.isRequired,
  current_user: PropTypes.shape({}).isRequired,
  docDetailsInfo: PropTypes.shape({}).isRequired
};

export default DocumentRelationsCreation;
