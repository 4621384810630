import type {
  BulkReprocessModalData,
  BulkReprocessModalRule
} from './useBulkReprocessModalData.types';
import type { BulkReprocessModalHandlers } from './useBulkReprocessModalHandlers.types';

import * as helpers from './useBulkReprocessModalHandlers.helpers';
import * as uiLib from '@compliance.ai/web-components';

export const useBulkReprocessModalHandlers = ({
  localState,
  localActions
}: {
  localState: BulkReprocessModalData['localState'];
  localActions: BulkReprocessModalData['localActions'];
}): BulkReprocessModalHandlers => {
  const handleRuleAdd: BulkReprocessModalHandlers['handleRuleAdd'] = () => {
    const newRuleId = helpers.getNewRuleId(localState.rules);

    localActions.setRules([...localState.rules, helpers.getNewEmptyRule(newRuleId)]);
  };

  const handleRuleActionChange: BulkReprocessModalHandlers['handleRuleActionChange'] =
    id => selectValue => {
      const option = selectValue as uiLib.SelectOption;
      const ruleAction = option?.value ?? null;

      localActions.setRules(rules =>
        rules.map(rule => {
          if (rule.id === id) {
            return {
              ...rule,
              action: ruleAction as BulkReprocessModalRule['action'],
              attribute: null,
              ...option
            };
          }

          return rule;
        })
      );
    };

  return {
    handleRuleAdd,
    handleRuleActionChange
  };
};
