import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CRAWL_GENERAL, CRAWL_SCRAPED } from '../../CrawlerDetails.constants';
import CRAWL_TYPES from '../../../../../constants/crawlersTypes';
import {
  loadCategoryOptions,
  loadAgencyOptions,
  loadJurisdictionsOptions,
  loadNewsSourceOptions
} from '../../CrawlerDetails.helper';
import Typography from '../../../../../components/Typography';
import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/TextInput';
import Select from '../../../../../components/ReactSelect/Select';
import AsyncSelect from '../../../../../components/ReactSelect/SelectAsync';
import CRAWLERS_TYPES from '../../../../../constants/crawlersTypes';
import { useCrawlerDetailsGeneralHandlers } from './hooks';

export const CrawlerDetailsGeneral = ({
  editMode,
  handleToggleEditMode,
  crawlDetails,
  handleSaveChanges,
  handleChanges,
  handleChangesEvent,
  errorFields
}) => {
  const disableEdit = !editMode;

  const handlers = useCrawlerDetailsGeneralHandlers({
    props: {
      handleChanges
    }
  });

  const shouldDisplayDevComplexity =
    crawlDetails[CRAWL_GENERAL.crawlType] &&
    [CRAWL_TYPES.unified.value, CRAWL_TYPES.email.value].includes(
      crawlDetails[CRAWL_GENERAL.crawlType].value
    );

  return (
    <div className="generalInfo">
      <div className="generalInfo_header">
        <Typography variant="h5">General info</Typography>
        <div className="generalInfo_header_btnBlock">
          <Button size="custom" color="highlighted" onClick={handleToggleEditMode}>
            {editMode ? 'Cancel' : 'Edit'}
          </Button>
          {editMode && (
            <Button size="custom" color="success" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          )}
        </div>
      </div>
      <div className="generalInfo_body">
        {/* First row */}
        <TextInput
          className="input_4"
          label="Spider Name"
          name={CRAWL_GENERAL.spiderName}
          value={crawlDetails[CRAWL_GENERAL.spiderName]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isError={errorFields.includes(CRAWL_GENERAL.spiderName)}
        />
        <Select
          className="input_4"
          label="Crawler Type"
          isDisabled={disableEdit}
          value={crawlDetails[CRAWL_GENERAL.crawlType]}
          options={Object.values(CRAWLERS_TYPES)}
          onChange={option => handlers.handleCrawlerTypeChange(option)}
          isError={errorFields.includes(CRAWL_GENERAL.crawlType)}
        />
        {/* Second row */}
        <TextInput
          className="input_4"
          label="Crawler ID"
          name={CRAWL_GENERAL.crawlID}
          value={crawlDetails[CRAWL_GENERAL.crawlID]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isError={errorFields.includes(CRAWL_GENERAL.crawlID)}
        />
        <TextInput
          className="input_2"
          label="Relative Complexity"
          name={CRAWL_GENERAL.devComplexity}
          value={shouldDisplayDevComplexity ? crawlDetails[CRAWL_GENERAL.devComplexity] : 'N/A'}
          isDisabled={disableEdit || !shouldDisplayDevComplexity}
          onKeyPress={e => (e.key === 'e' || e.key === '.' || e.key === ',') && e.preventDefault()}
          onChange={handleChangesEvent}
          type={'number'}
          isError={errorFields.includes(CRAWL_GENERAL.devComplexity)}
        />
        <TextInput
          className="input_2"
          label="Complexity Classification"
          name={CRAWL_GENERAL.devComplexityClassification}
          value={
            shouldDisplayDevComplexity
              ? crawlDetails[CRAWL_GENERAL.devComplexityClassification]
              : 'N/A'
          }
          isDisabled
          onChange={() => {}}
        />
        {/* Third row */}
        <AsyncSelect
          className="input_4"
          label="Jurisdiction"
          isDisabled={disableEdit}
          value={crawlDetails[CRAWL_GENERAL.jurisdiction]}
          onChange={e => handleChanges(CRAWL_GENERAL.jurisdiction, e)}
          loadOptions={value => loadJurisdictionsOptions('short_name', value, 'short_name')}
          isClearable
        />
        {/* Fourth row */}
        <AsyncSelect
          label="Agencies"
          className="input_8"
          isDisabled={disableEdit}
          value={crawlDetails[CRAWL_GENERAL.agencies]}
          onChange={e => handleChanges(CRAWL_GENERAL.agencies, e)}
          loadOptions={value => loadAgencyOptions('short_name', value, 'short_name')}
          isMulti
          isClearable
        />
        {/* Fifth row */}
        <AsyncSelect
          className="input_8"
          label="News Sources"
          isDisabled={disableEdit}
          value={crawlDetails[CRAWL_GENERAL.newsSource]}
          onChange={e => handleChanges(CRAWL_GENERAL.newsSource, e)}
          loadOptions={value => loadNewsSourceOptions('name', value, 'name')}
          isMulti
          isClearable
        />
        {/* Sixth row */}
        <AsyncSelect
          label="Document type"
          className="input_8"
          isDisabled={disableEdit}
          value={crawlDetails[CRAWL_GENERAL.docType]}
          onChange={e => handleChanges(CRAWL_GENERAL.docType, e)}
          loadOptions={value => loadCategoryOptions('scraped_category', value, 'scraped_category')}
          isMulti
          isClearable
        />
        {/* Seventh row */}
        <Select
          className="input_8"
          label="Scraped Agencies"
          isDisabled
          value={crawlDetails[CRAWL_SCRAPED.scrapedAgencies]}
          onChange={() => {}}
          isMulti
        />
        {/* Eighth row */}
        <Select
          className="input_8"
          label="Scraped Categories"
          isDisabled
          value={crawlDetails[CRAWL_SCRAPED.scrapedCategory]}
          onChange={() => {}}
          isMulti
        />
        {/* Ninth row */}
        <Select
          className="input_8"
          label="Scraped News Sources"
          isDisabled
          value={crawlDetails[CRAWL_SCRAPED.scrapedNewsSource]}
          onChange={() => {}}
          isMulti
        />
        {/* Tenth row */}
        <Select
          className="input_8"
          label="CAI Categories"
          isDisabled
          value={crawlDetails[CRAWL_SCRAPED.scrapedCAICategories]}
          onChange={() => {}}
          isMulti
        />
      </div>
    </div>
  );
};

CrawlerDetailsGeneral.propTypes = {
  editMode: PropTypes.bool,
  handleToggleEditMode: PropTypes.func.isRequired,
  crawlDetails: PropTypes.shape({}),
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

CrawlerDetailsGeneral.defaultProps = {
  editMode: false,
  crawlDetails: {},
  errorFields: []
};
