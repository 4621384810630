import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import _ from 'lodash';
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  Modal,
  Button,
  FormControl,
  ControlLabel,
  FormGroup,
  Radio, Col, Row
} from 'react-bootstrap';
import { fetchAutoComplete } from 'shared/features/search/search.actions';
import { getConcepts } from 'shared/features/jurisdictionAnalyzerConcepts/jurisdictionAnalyzerConcept.selector';
import { addMetric, updateMetric, deleteMetric } from 'shared/features/metrics/metric.actions';

const filterState = {
  publication_date_from: {},
  publication_date_to: {},
  jurisdictions: null,
  topics: null,
  documents_type: null,
  regulations: null,
  acts: null
};

const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

const MetricsModal = props => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [concept, setConcept] = useState('');
  const [filters, setFilter] = useState(filterState);
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const metric = props.metric;
    setName(metric.name || '');
    setDescription(metric.description || '');
    setConcept(
      metric.concept && metric.concept.id
        ? { label: metric.concept.name, value: metric.concept.id }
        : ''
    );
    const filtersReceived = _.transform(metric.filters, (result, value, key) => {
      if (key === 'publication_date_from' || key === 'publication_date_to') {
        result[key] = moment(value);
      } else {
        result[key] = value.map(item => ({ label: item, value: item }));
      }
    }, {});
    setFilter(filtersReceived || '');
    setSearch(metric.advance_search || '');
    setActive(metric.active || false);
    setError(false);
  }, [props.metric]);

  const checkInput = () => {
    if (!name || !concept) {
      setError(true);
      return false;
    }
    return {
      name,
      description,
      jurisdiction_analyzer_concepts_id: concept.value,
      filters: _.transform(filters, (result, value, key) => {
        if (value && (key === 'publication_date_from' || key === 'publication_date_to')) {
          result[key] = value.format(dateFormat);
        } else if (value && _.isArray(value) && value.length > 0) {
          result[key] = value.map(item => item.label);
        }
      }, {}),
      advance_search: search,
      active
    };
  };

  const saveChangesMetric = () => {
    const collectMetric = checkInput();
    if (!collectMetric) {
      return;
    }
    const metricId = props.metric.id;
    if (metricId) {
      props.updateMetric(metricId, collectMetric);
    } else {
      props.addMetric(collectMetric);
    }
    props.closeModal();
  };

  const removeMetric = () => {
    props.deleteMetric(props.metric.id);
    props.closeModal();
  };

  const saveFilterData = (stateName, e) => {
    setFilter({ ...filters, [stateName]: e });
  };

  const getOptions = (input, routeName) => {
    if (!input || input.length < 3) {
      return Promise.resolve({
        options: []
      });
    }
    return props.fetchAutoComplete(input, routeName).then(response => ({
      options: response.results.map(cat => ({
        label: cat.name,
        value: cat.name
      }))
    }));
  };

  const filtersGroup = [
    {
      name: 'Jurisdictions',
      stateName: 'jurisdictions',
      asyncSelect: false,
      options: props.all_jurisdictions.items
        && props.all_jurisdictions.items.map(jurisdiction => ({
          label: jurisdiction.short_name,
          value: jurisdiction.short_name
        })) || []
    },
    {
      name: 'Topics',
      stateName: 'topics',
      asyncSelect: false,
      options: props.sources && props.sources.sources && props.sources.sources.activeTopics
        && props.sources.sources.activeTopics.map(topic => ({
          label: topic.name,
          value: topic.name
        })) || []
    },
    {
      name: 'Documents type',
      stateName: 'documents_type',
      asyncSelect: false,
      options: props.docTypes && props.docTypes.docTypes
        && props.docTypes.docTypes.app_to_scraped_dic
        && Object.keys(props.docTypes.docTypes.app_to_scraped_dic).map(docType => ({
          label: docType,
          value: docType
        })) || []
    },
    {
      name: 'Regulations',
      stateName: 'regulations',
      asyncSelect: false,
      options: props.regulations && props.regulations.regulations
        && props.regulations.regulations.map(regulation => ({
          label: regulation.name,
          value: regulation.name
        })) || []
    },
    {
      name: 'Acts',
      stateName: 'acts',
      asyncSelect: true,
      options: props.acts && props.acts.items
        && props.acts.items.map(act => ({
          label: act.name,
          value: act.name
        })) || []
    }
  ];

  const filterGroup = filtersGroup.map(filterElem => (
    <Row key={filterElem.stateName} className="filterSpace">
      <Col componentClass={ControlLabel} sm={2}>
        {filterElem.name}
      </Col>
      <Col sm={10}>
        {filterElem.asyncSelect
          ? (
            <Select.Async
              value={filters[filterElem.stateName]}
              onChange={(e) => saveFilterData(filterElem.stateName, e)}
              options={filterElem.options}
              loadOptions={input => getOptions(input, filterElem.stateName)}
              multi
            />
          ) : (
            <Select
              value={filters[filterElem.stateName]}
              onChange={(e) => saveFilterData(filterElem.stateName, e)}
              options={filterElem.options}
              multi
            />
          )}
      </Col>
    </Row>
  ));

  return (
    <Modal
      show={props.showModal}
      onHide={props.closeModal}
      className="docMetaModal thinBtn"
    >
      <Modal.Header>
        <Modal.Title>Create, Edit, or Delete Metric</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <FormGroup className="filterAdminToolWrap_elem">
            <ControlLabel>
              Name of Metric
            </ControlLabel>
            <FormControl
              className={
                error && !name.trim()
                  ? "emptyFormAdminToolControl"
                  : ""
              }
              type="text"
              name='name'
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </FormGroup>
          <FormGroup className="filterAdminToolWrap_elem">
            <ControlLabel>
              Description of Metric
            </ControlLabel>
            <FormControl
              componentClass="textarea"
              name='description'
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </FormGroup>
          <FormGroup className="filterAdminToolWrap_elem">
            <ControlLabel>
              Concept
            </ControlLabel>
            <Select
              className={
                error && !concept
                  ? "emptyFormSelectAdminToolControl"
                  : ""
              }
              options={
                props.concepts && props.concepts.jurisdiction_analyzer_concepts
                && props.concepts.jurisdiction_analyzer_concepts.map(currentConcept => ({
                  value: currentConcept.id, label: currentConcept.name
                }))
              }
              value={concept}
              name='concept'
              onChange={setConcept}
            />
          </FormGroup>
          <hr />
          <Row>
            <Col componentClass={ControlLabel} sm={2}>
              Publication date
            </Col>
            <Col sm={5}>
              <FormGroup>
                <ControlLabel>
                  From
                </ControlLabel>
                <DatePicker
                  selected={filters.publication_date_from}
                  onChange={(date) => saveFilterData('publication_date_from', date)}
                />
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <ControlLabel>
                  To
                </ControlLabel>
                <DatePicker
                  selected={filters.publication_date_to}
                  onChange={(date) => saveFilterData('publication_date_to', date)}
                />
              </FormGroup>
            </Col>
          </Row>
          {filterGroup}
          <hr />
          <FormGroup className="filterAdminToolWrap_elem">
            <ControlLabel>
              Advance Search
            </ControlLabel>
            <FormControl
              type="text"
              name='search'
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Metric Active?</ControlLabel>
            <Radio
              name="active"
              onChange={(e) => setActive(true)}
              checked={active}
            >
              True
            </Radio>
            <Radio
              name="active"
              onChange={(e) => setActive(false)}
              checked={!active}
            >
              False
            </Radio>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={saveChangesMetric}>Save</Button>
          <Button onClick={props.closeModal}>Cancel</Button>
          {props.metric && props.metric.id && (
            <Button bsStyle="danger" onClick={removeMetric}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    metrics: state.metrics,
    concepts: getConcepts(state),
    all_jurisdictions: state.jurisdictionsData,
    acts: state.acts,
    regulations: state.regulations,
    sources: state.sources,
    docTypes: state.docTypes
  };
};

const ReduxMetricsModal = connect(mapStateToProps, {
  addMetric,
  updateMetric,
  deleteMetric,
  fetchAutoComplete
})(MetricsModal);

export default ReduxMetricsModal;
