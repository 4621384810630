import type { EitlLabelsSelectProps } from '../EitlLabelsSelect.types';
import type { EitlLabelsSelectData } from './useEitlLabelsSelectData.types';

import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';
import * as eitlLabelsApiTypes from 'shared/features/eitlLabels/eitlLabels.api.types';
import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { useMutation } from '@tanstack/react-query';
import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';

export const useEitlLabelsSelectMutations = ({
  props,
  localActions,
  reduxState
}: {
  props: Pick<EitlLabelsSelectProps, 'onEitlLabelCreate'>;
  localActions: EitlLabelsSelectData['localActions'];
  reduxState: EitlLabelsSelectData['reduxState'];
}) => {
  const reduxActions = useEitlLabelsApi();

  const addEitlLabel = useMutation<
    Awaited<eitlLabelsApiTypes.NormalizedEitlLabel>,
    Error,
    Parameters<Required<uiLib.SelectProps>['onCreateOption']>
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.EITL_LABELS],
    mutationFn: ([eitlLabelName]) => {
      localActions.setIsOpen(false);

      return reduxActions.addEitlLabel({
        user_id: reduxState.currentUserId,
        name: eitlLabelName
      });
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: normalizedEitlLabel => {
      props.onEitlLabelCreate(eitlLabelsApiHelpers.formatEitlLabelsOption(normalizedEitlLabel));

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        title: 'EITL Label successfully added'
      });
    }
  });

  return {
    addEitlLabel
  };
};
