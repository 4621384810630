import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MTypography from "../Typography/mTypography";
import { colors } from "../colors/colors";


const useStyle = makeStyles({
  withoutLabel: {
    marginTop: 20
  },
  disableBorders: {
    border: 'none'
  },
  error: {
    border: `1px solid ${colors.RED_TOMATO}`
  },
  inLineInputWithIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 35
  }
});

const CDatePicker = ({
  calendarClass,
  calendarIcon,
  clearIcon,
  disabled,
  error,
  fullWidth,
  label,
  labelId,
  onChange,
  labelClass,
  value
}) => {
  const classes = useStyle();
  return (
    <FormControl
      fullWidth={fullWidth}
      error={error}
      className={classNames(!label ? classes.withoutLabel : undefined)}
    >
      <MTypography id={labelId} className={labelClass}>{label}</MTypography>
      <DatePicker
        disabled={disabled}
        defaultActiveStartDate={new Date(value)}
        format={'MM/dd/y'}
        value={new Date(value)}
        onChange={date => onChange(date)}
        className={calendarClass}
        clearIcon={clearIcon}
        calendarIcon={calendarIcon}
      />
    </FormControl>
  );
};

CDatePicker.propTypes = {
  calendarClass: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labelClass: PropTypes.string
};

CDatePicker.defaultProps = {
  calendarClass: '',
  disabled: false,
  error: false,
  fullWidth: false,
  label: '',
  labelClass: ''
};

export default CDatePicker;
