import { setNotification, NOTIFICATION_TYPES } from "admin/components/Notification";
import {
  api_getNewsSources,
  api_postNewsSources,
  api_putNewsSources,
  api_deleteNewsSources
} from './newsSources.api';
import {
  startLoad,
  finishLoad,
  setNewsSource,
  createNewsSource,
  updateNewsSource,
  deleteNewsSource,
  initialState
} from './newsSources.reducer';

const SUCCESS = {
  newsSourceCreated: 'News Source successfully created',
  newsSourceUpdated: 'News Source successfully updated',
  newsSourceDeleted: 'News Source successfully deleted'
};

export const getNewsSources = params => dispatch => {
  dispatch(startLoad());
  return api_getNewsSources(params)
    .then(res => dispatch(setNewsSource(res)))
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const createNewsSources = params => dispatch => {
  dispatch(startLoad());
  return api_postNewsSources(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.newsSourceCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createNewsSource(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const updateNewsSources = params => dispatch => {
  dispatch(startLoad());
  return api_putNewsSources(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.newsSourceUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateNewsSource(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const removeNewsSources = params => dispatch => {
  dispatch(startLoad());
  return api_deleteNewsSources(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.newsSourceDeleted, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteNewsSource(params));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const clearNewsSources = () => dispatch => {
  dispatch(finishLoad());
  return dispatch(setNewsSource(initialState));
};
