export enum DOCUMENT_AUDIT_ENTRY_ATTRIBUTE {
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  DETAILS = 'details',
  INCOMING_PIPELINE_STATUS = 'incoming_pipeline_status',
  RESULTING_PIPELINE_STATUS = 'resulting_pipeline_status',
  REAL_CREATED_AT = 'real_created_at',
  PROCESS = 'process',
  NOTES = 'notes',
  DOC_ID = 'doc_id'
}
