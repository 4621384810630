import { DOCUMENT_PIPELINE_STATUSES } from '../../constants/docPipelineStatuses';

import * as uiLib from '@compliance.ai/web-components';
import * as documentConstants from '../../constants/document';

export const DOCUMENT_STATUS_COLORS: Record<string, uiLib.TYPOGRAPHY_STATUS> = {
  [documentConstants.DOC_STATUS_COLORS.DEFAULT]: uiLib.TYPOGRAPHY_STATUS.INITIAL,
  [documentConstants.DOC_STATUS_COLORS.GREEN]: uiLib.TYPOGRAPHY_STATUS.SUCCESS,
  [documentConstants.DOC_STATUS_COLORS.RED]: uiLib.TYPOGRAPHY_STATUS.ERROR,
  [documentConstants.DOC_STATUS_COLORS.ORANGE]: uiLib.TYPOGRAPHY_STATUS.WARNING
};

export const EXTRA_INFO_STATUSES = [
  DOCUMENT_PIPELINE_STATUSES.PROBLEMATIC.type,
  DOCUMENT_PIPELINE_STATUSES.INCOMPLETE.type
];
