import _ from "lodash";
import {
  NAMES_CONTEXT,
  NAMES_EXAMPLE,
  NAMES_STEP,
  SPEC_GENERAL_SETTINGS,
  NAMES_DOC,
  NAMES_ACTION,
  NAMES_RELATION,
  NAMES_RESET_COUNTER,
  NAMES_META_DATA,
  NAMES_EXTRACT_TAGS,
  NAMES_LOCATION,
  NAMES_PATH,
  ERRORS,
  SUCCESS_MESSAGES,
  VALUE_TYPES,
  ACTION_TYPES_SETTINGS,
  NAMES_CAT_CONV,
  SPEC_GENERAL,
  CODE_FILTER_EXTRA_OPTION,
  NAMES_CONFIG
} from "./CrawlSpecCreate.constants";
import { INPUT_TYPE } from "../../components/TextInput";
import { NOTIFICATION_TYPES, setNotification } from "../../components/Notification";

export default class CrawlCollectorSpec {
  // CrawlCollectorSpec - class get info about different elements (steps / context / doc / example)
  // then class create a json with right format and structure
  // important because we use detailed breakdown for json and elements
  // implementation of this class by functions could lead to big functions with a lot of props
  /*
    specMain - array of strings - include all main info (spider_name, agency, category and etc.)
    config - object - info about configuration
    categoryConversion - object or null - info about conversion map
    steps - array of objects - include all steps and info about them
    contexts - array of objects - include all contexts and info about them
    docs - array of objects - include all docs elements and info about them
    actions - array of objects - include all actions elements and info about them
    relations - array of objects - include all relations elements and info about them
    resetCounters - array of objects - include all reset counter elements and info about them
    metaData - array of objects - include all metaData elements and info about them
    locations - array of objects - include all locations elements and info about them
    extractTags - array of objects - include all extractTags elements and info about them
    examples - array of objects - include all examples and info about them
    // errors
    errorBool - bool - show that spec has wrong / right format
    errorsText - array of strings - messages that will be shown to user (top right angle)
                                    could be populated during spec check
    errorGeneral - array of string - include all info about errors in specMain
    errorConfig - array of string - include all info about errors in configuration
    errorCategoryConversion - array of string - include all info about errors in category conversion
    errorStep - array of objects - include all info about errors in steps
    errorContext - array of objects - include all info about errors in contexts
    errorAction - array of objects - include all info about errors in actions
    errorRelation - array of objects - include all info about errors in relations
    errorResetCounters - array of objects - include all info about errors in reset counters
    errorDoc - array of objects - include all info about errors in docs
    errorMetaData - array of objects - include all info about errors in metaData
    errorLocation - array of objects - include all info about errors in extractTags
    errorExtractTags - array of objects - include all info about errors in extractTags
    errorExample - array of objects - include all info about errors in examples

    // structures
    Structure (errorStep, errorContext, errorDoc, errorAction, errorMetaData, errorExample):
    [
      'element-1 UUID': ['field-1 Name', 'field-2 Name'],
      'element-2 UUID': ['field-1 Name', 'field-2 Name']
    ]
  */
  constructor({
    specMain,
    config,
    categoryConversion,
    steps,
    contexts,
    docs,
    actions,
    relations,
    resetCounters,
    metaData,
    locations,
    extractTags,
    paths,
    examples
  }) {
    this.specMain = specMain;
    this.config = config;
    this.categoryConversion = categoryConversion;
    this.steps = steps;
    this.contexts = contexts;
    this.docs = docs;
    this.actions = actions;
    this.relations = relations;
    this.resetCounters = resetCounters;
    this.metaData = metaData;
    this.locations = locations;
    this.extractTags = extractTags;
    this.paths = paths;
    this.examples = examples;
    // errors
    this.errorBool = false;
    // issues could be populated during checking
    // could be used to show users more info
    this.errorsText = [ERRORS.common];
    this.errorGeneral = [];
    this.errorConfig = [];
    this.errorCategoryConversion = [];
    this.errorStep = {};
    this.errorContext = {};
    this.errorAction = {};
    this.errorRelations = {};
    this.errorResetCounters = {};
    this.errorDoc = {};
    this.errorMetaData = {};
    this.errorLocation = {};
    this.errorExtractTags = {};
    this.errorPath = {};
    this.errorExample = {};
  }

  // result of spec format checking
  get error() {
    return this.errorBool;
  }

  // return all collected errors in one object
  get errorCollection() {
    return {
      main: this.errorGeneral,
      config: this.errorConfig,
      categoryConversion: this.errorCategoryConversion,
      steps: this.errorStep,
      contexts: this.errorContext,
      docs: this.errorDoc,
      actions: this.errorAction,
      relations: this.errorRelations,
      resetCounters: this.errorResetCounters,
      metaData: this.errorMetaData,
      locations: this.errorLocation,
      extractTags: this.errorExtractTags,
      paths: this.errorPath,
      examples: this.errorExample
    };
  }

  // show text messages to user (top right angle)
  showErrors() {
    this.errorsText.forEach(
      error => setNotification({
        message: { text: error },
        type: NOTIFICATION_TYPES.ERROR,
        width: 800
      })
    );
  }

  // show text messages to user (top right angle)
  showSuccess() {
    setNotification({
      message: { title: 'Success', text: SUCCESS_MESSAGES.specCreated },
      type: NOTIFICATION_TYPES.SUCCESS,
      width: 800
    });
  }

  // collect main spec info (spider_name, category and etc.)
  collectGeneralInfo() {
    return SPEC_GENERAL_SETTINGS.reduce((acc, item) => {
      const fieldName = item.name;
      if (item.required && (
        !this.specMain[fieldName] || (
          (Array.isArray(this.specMain[fieldName])) && !this.specMain[fieldName].length
        )
      )) {
        this.errorGeneral.push(fieldName);
        this.errorBool = true;
      }
      let value = _.get(this.specMain, fieldName, '');
      switch (item.name) {
        case SPEC_GENERAL.apiTable:
        case SPEC_GENERAL.metaTable:
          if (value && value.value) {
            value = value.value;
          }
          break;
        default:
          console.log(value);
      }
      switch (item.type) {
        case INPUT_TYPE.NUMBER:
          if (Array.isArray(value)) value = value.map(num => (Number(num) || ''));
          else value = Number(value) || '';
          break;
        default:
          console.log(value);
      }
      return {
        ...acc,
        [fieldName]: value
      };
    }, {});
  }

  collectParentContext() {
    return this.steps.reduce((parentCollector, step) => {
      const stepContextID = step[NAMES_STEP.CONTEXT];
      const nextSteps = step[NAMES_STEP.NEXT_STEP];
      if (stepContextID && nextSteps) {
        const context = this.contexts.find(item => item.id === stepContextID);
        if (!context[NAMES_CONTEXT.URL]) {
          nextSteps.forEach(item => {
            parentCollector[item.value] = step[NAMES_STEP.NAME];
          });
        }
      }
      return parentCollector;
    }, {});
  }

  collectExample(example) {
    const exampleIssues = [];
    const stepUrl = _.get(example, [NAMES_EXAMPLE.URL], '');
    const stepText = _.get(example, [NAMES_EXAMPLE.TEXT], '');
    const stepOccur = _.get(example, [NAMES_EXAMPLE.OCCURRENCE], '');
    const exactMatch = _.get(example, [NAMES_EXAMPLE.EXACT_MATCH], false);
    if (!stepUrl) exampleIssues.push(NAMES_EXAMPLE.URL);
    if (!stepText) exampleIssues.push(NAMES_EXAMPLE.TEXT);
    if (!stepOccur) exampleIssues.push(NAMES_EXAMPLE.OCCURRENCE);

    if (exampleIssues.length) {
      this.errorBool = true;
      this.errorExample[example.id] = exampleIssues;
    }

    const newExample = {
      url: stepUrl,
      text: stepText,
      occurrence: Number(stepOccur),
      exact_match: exactMatch
    };

    return {
      error: exampleIssues.length,
      example: newExample
    };
  }

  collectPath(path) {
    const pathIssues = [];
    let newPath = null;

    if (_.isString(path[NAMES_PATH.PATH]) && path[NAMES_PATH.PATH].trim()) {
      newPath = path[NAMES_PATH.PATH].trim();
    } else if (path[NAMES_PATH.EXAMPLES] && path[NAMES_PATH.EXAMPLES].length) {
      const example = this.examples.filter(
        item => path[NAMES_PATH.EXAMPLES].includes(item.id)
      );
      newPath = example.map(item => {
        const newExample = this.collectExample(item);
        if (newExample.error) pathIssues.push(NAMES_PATH.EXAMPLES);
        return newExample.example;
      });
    } else {
      pathIssues.push(NAMES_PATH.EXAMPLES);
    }

    if (pathIssues.length) {
      this.errorBool = true;
      this.errorPath[path.id] = pathIssues;
    }

    return {
      error: pathIssues.length,
      path: newPath
    };
  }

  collectContext(context) {
    const contextIssues = [];

    const newContext = {
      is_url: _.get(context, [NAMES_CONTEXT.URL], false),
      is_text: _.get(context, [NAMES_CONTEXT.IS_TEXT], false),
      is_single: _.get(context, [NAMES_CONTEXT.IS_SINGLE], false),
      is_sibling_tag: _.get(context, [NAMES_CONTEXT.IS_SIBLING_TAG], false),
      combine_context: _.get(context, [NAMES_CONTEXT.COMBINE_CONTEXT], false),
      is_page_source: _.get(context, [NAMES_CONTEXT.IS_PAGE_SOURCE], false)
    };

    if (context[NAMES_CONTEXT.CODE_FILTER]) {
      newContext.code_filter = context[NAMES_CONTEXT.CODE_FILTER];
    }

    if (context[NAMES_CONTEXT.TAG_FILTER]) {
      newContext.tag_filter = context[NAMES_CONTEXT.TAG_FILTER];
    }

    if (context[NAMES_CONTEXT.PATH] && context[NAMES_CONTEXT.PATH].length) {
      const path = this.paths.filter(
        item => context[NAMES_CONTEXT.PATH].includes(item.id)
      );
      newContext.examples = path.map(item => {
        const newPath = this.collectPath(item);
        if (newPath.error) contextIssues.push(NAMES_CONTEXT.PATH);
        return newPath.path;
      });
    } else {
      contextIssues.push(NAMES_CONTEXT.PATH);
    }

    if (contextIssues.length) {
      this.errorBool = true;
      this.errorContext[context.id] = contextIssues;
    }

    return {
      error: contextIssues.length,
      context: newContext
    };
  }

  collectAction(action) {
    const actionIssues = [];

    const ACTION_SETTING = _.get(
      ACTION_TYPES_SETTINGS,
      _.get(action[NAMES_ACTION.TYPE], 'value'),
      []
    );
    const newAction = {
      use_counter: _.get(action, [NAMES_ACTION.COUNTER], false)
    };

    if (ACTION_SETTING.includes(NAMES_ACTION.EXAMPLES)) {
      if (action[NAMES_ACTION.EXAMPLES] && action[NAMES_ACTION.EXAMPLES].length) {
        const example = this.examples.filter(
          item => action[NAMES_ACTION.EXAMPLES].includes(item.id)
        );
        newAction.examples = example.map(item => {
          const newExample = this.collectExample(item);
          if (newExample.error) actionIssues.push(NAMES_ACTION.EXAMPLES);
          return newExample.example;
        });
      } else {
        actionIssues.push(NAMES_ACTION.EXAMPLES);
      }
    }

    if (ACTION_SETTING.includes(NAMES_ACTION.PATH)) {
      const actionPath = _.get(action, NAMES_ACTION.PATH);
      if (actionPath && !isNaN(actionPath)) {
        newAction.path = Number(actionPath);
      } else {
        actionIssues.push(NAMES_ACTION.PATH);
      }
    }

    const actionMethod = _.get(action, [NAMES_ACTION.METHOD, 'value']);
    if (actionMethod) {
      newAction.method = actionMethod;
    } else {
      actionIssues.push(NAMES_ACTION.METHOD);
    }

    const actionType = _.get(action, [NAMES_ACTION.TYPE, 'value']);
    if (actionType) {
      newAction.type = actionType;
    } else {
      actionIssues.push(NAMES_ACTION.TYPE);
    }

    const actionRepeat = _.get(action, NAMES_ACTION.REPEAT);
    if (actionRepeat && !isNaN(actionRepeat)) {
      newAction.repeat = Number(actionRepeat);
    }

    if (actionIssues.length) {
      this.errorBool = true;
      this.errorAction[action.id] = actionIssues;
    }

    return {
      error: actionIssues.length,
      action: newAction
    };
  }

  collectRelation(relation) {
    const relationIssues = [];

    const newRelation = {
      clear_existing_relations: _.get(relation, [NAMES_RELATION.CLEAR_EXISTING_RELATIONS], false)
    };

    const relationType = _.get(relation, [NAMES_RELATION.RELATION_TYPE, 'value']);
    if (relationType) {
      newRelation.relation_type = relationType;
    } else {
      relationIssues.push(NAMES_RELATION.RELATION_TYPE);
    }

    if (relationIssues.length) {
      this.errorBool = true;
      this.errorRelations[relation.id] = relationIssues;
    }

    return {
      error: relationIssues.length,
      relation: newRelation
    };
  }

  collectResetCounter(resetCounter) {
    const resetCounterIssues = [];

    const newResetCounter = {};

    if (resetCounter[NAMES_RESET_COUNTER.STEP] && resetCounter[NAMES_RESET_COUNTER.STEP].length) {
      const steps = resetCounter[NAMES_RESET_COUNTER.STEP];
      newResetCounter.step = steps.map(item => item.value);
    } else {
      resetCounterIssues.push(NAMES_RESET_COUNTER.STEP);
    }

    if (resetCounterIssues.length) {
      this.errorBool = true;
      this.errorResetCounters[resetCounter.id] = resetCounterIssues;
    }

    return {
      error: resetCounterIssues.length,
      reset_counter: newResetCounter
    };
  }

  collectLocation(location) {
    const locationIssues = [];

    const newLocation = {
      coordinates: {
        top: _.get(location, NAMES_LOCATION.COORDINATES_TOP, 0),
        bottom: _.get(location, NAMES_LOCATION.COORDINATES_BOTTOM, 0),
        left: _.get(location, NAMES_LOCATION.COORDINATES_LEFT, 0),
        right: _.get(location, NAMES_LOCATION.COORDINATES_RIGHT, 0)
      },
      // margins: _.get(location, NAMES_LOCATION.MARGINS, 0),
      margins: null,
      remove_prepositions: _.get(location, NAMES_LOCATION.REMOVE_PREPOSITIONS, false),
      regex: _.get(location, NAMES_LOCATION.REGEX, [])
    };

    const pages = _.get(location, NAMES_LOCATION.PAGES);
    if (pages) {
      newLocation.pages = pages;
    } else {
      locationIssues.push(NAMES_LOCATION.PAGES);
    }

    if (locationIssues.length) {
      this.errorBool = true;
      this.errorLocation[location.id] = locationIssues;
    }

    return {
      error: locationIssues.length,
      location: newLocation
    };
  }

  collectExtractTags(extractTag) {
    const extractTagsIssues = [];

    const newExtractTag = {};
    if (extractTag[NAMES_EXTRACT_TAGS.PATH] && extractTag[NAMES_EXTRACT_TAGS.PATH].length) {
      const path = this.paths.filter(
        item => extractTag[NAMES_EXTRACT_TAGS.PATH].includes(item.id)
      );
      newExtractTag.examples = path.map(item => {
        const newPath = this.collectPath(item);
        if (newPath.error) extractTagsIssues.push(NAMES_EXTRACT_TAGS.PATH);
        return newPath.path;
      });
    } else {
      extractTagsIssues.push(NAMES_EXTRACT_TAGS.PATH);
    }

    if (extractTagsIssues.length) {
      this.errorBool = true;
      this.errorExtractTags[extractTag.id] = extractTagsIssues;
    }

    return {
      error: extractTagsIssues.length,
      extractTags: newExtractTag
    };
  }

  collectMetaData(metaData, duplicate) {
    const metaDataIssues = [];
    let newMetaData = {};

    const valueType = _.get(metaData, [NAMES_META_DATA.VALUE_TYPE, 'value'], VALUE_TYPES.NOT_USE);

    if (valueType === VALUE_TYPES.NOT_USE) {
      if (metaData[NAMES_META_DATA.PATH] && metaData[NAMES_META_DATA.PATH].length) {
        const path = this.paths.filter(
          item => metaData[NAMES_META_DATA.PATH].includes(item.id)
        );
        newMetaData.examples = path.map(item => {
          const newPath = this.collectPath(item);
          if (newPath.error) metaDataIssues.push(NAMES_META_DATA.PATH);
          return newPath.path;
        });
      } else {
        metaDataIssues.push(NAMES_META_DATA.PATH);
      }

      if (metaData[NAMES_META_DATA.LOCATIONS] && metaData[NAMES_META_DATA.LOCATIONS].length) {
        const locations = this.locations.filter(
          item => metaData[NAMES_META_DATA.LOCATIONS].includes(item.id)
        );
        newMetaData.locations = locations.map(item => {
          const newLocation = this.collectLocation(item);
          if (newLocation.error) {
            metaDataIssues.push(NAMES_META_DATA.LOCATIONS);
          }
          return newLocation.location;
        });
        if (!locations.length) {
          metaDataIssues.push(NAMES_META_DATA.LOCATIONS);
        }
      }

      if (metaData[NAMES_META_DATA.EXTRACT_TAGS]) {
        const extractTag = this.extractTags.find(
          item => item.id === metaData[NAMES_META_DATA.EXTRACT_TAGS]
        );
        const newExtractTag = this.collectExtractTags(extractTag);
        newMetaData.extract_tags = newExtractTag.extractTags;
        if (!extractTag || newExtractTag.error) metaDataIssues.push(NAMES_META_DATA.EXTRACT_TAGS);
      }

      if (metaData[NAMES_META_DATA.REGEX]) {
        newMetaData.regex = metaData[NAMES_META_DATA.REGEX];
      }

      const codeFilter = metaData[NAMES_META_DATA.CODE_FILTER];
      if (codeFilter) {
        if (codeFilter.label === CODE_FILTER_EXTRA_OPTION.label) {
          newMetaData.code_filter = codeFilter.value;
        } else {
          newMetaData.code_filter = metaData[NAMES_META_DATA.CODE_FILTER];
        }
      }

      if (metaData[NAMES_META_DATA.DATE_FORMAT]) {
        newMetaData.date_format = metaData[NAMES_META_DATA.DATE_FORMAT];
      }

      newMetaData.is_text = _.get(metaData, NAMES_META_DATA.IS_TEXT, false);
      newMetaData.is_url = _.get(metaData, NAMES_META_DATA.IS_URL, false);
      newMetaData.is_single = _.get(metaData, NAMES_META_DATA.IS_SINGLE, false);
      newMetaData.is_sibling_tag = _.get(metaData, NAMES_META_DATA.IS_SIBLING_TAG, false);
      newMetaData.is_sibling_text = _.get(metaData, NAMES_META_DATA.IS_SIBLING_TEXT, false);
      newMetaData.is_date = _.get(metaData, NAMES_META_DATA.IS_DATE, false);
      newMetaData.dayfirst = _.get(metaData, NAMES_META_DATA.DAY_FIRST, false);
      newMetaData.parse_pdf = _.get(metaData, NAMES_META_DATA.PARSE_PDF, false);
      newMetaData.from_url = _.get(metaData, NAMES_META_DATA.FROM_URL, false);
      newMetaData.is_current_url = _.get(metaData, NAMES_META_DATA.IS_CURRENT_URL, false);
      newMetaData.is_text_generated = _.get(metaData, NAMES_META_DATA.IS_TEXT_GENERATED, false);
    } else {
      const defaultValue = _.get(metaData, NAMES_META_DATA.DEFAULT_VALUE);

      if (valueType === VALUE_TYPES.NULL) {
        newMetaData = null;
      } else if (valueType === VALUE_TYPES.BOOL) {
        newMetaData = !!defaultValue;
      } else if (valueType === VALUE_TYPES.NUMBER) {
        if (defaultValue && !isNaN(defaultValue)) newMetaData = Number(defaultValue);
        else metaDataIssues.push(NAMES_META_DATA.DEFAULT_VALUE);
      } else if (valueType === VALUE_TYPES.STRING) {
        if (_.isString(defaultValue)) newMetaData = defaultValue;
        else newMetaData = '';
      } else {
        metaDataIssues.push(NAMES_META_DATA.VALUE_TYPE);
      }
    }

    const metaDataName = metaData[NAMES_META_DATA.NAME];
    if (!metaDataName || !metaDataName.trim() || duplicate) {
      metaDataIssues.push(NAMES_META_DATA.NAME);
    }

    if (metaDataIssues.length) {
      this.errorBool = true;
      this.errorMetaData[metaData.id] = metaDataIssues;
    }

    return {
      error: metaDataIssues.length,
      metaData: newMetaData
    };
  }

  collectDoc(doc) {
    const docIssues = [];
    const docMetaData = _.get(doc, NAMES_DOC.META_DATA, []);
    const newDoc = docMetaData.reduce((metaDataAcc, metaDataID) => {
      const metaDataRaw = this.metaData.find(item => item.id === metaDataID);
      if (metaDataRaw) {
        const duplicateMetaCategory = metaDataAcc[metaDataRaw[NAMES_META_DATA.NAME]];
        const newMetaData = this.collectMetaData(metaDataRaw, !!duplicateMetaCategory);
        const newMetaDataName = metaDataRaw[NAMES_META_DATA.NAME];
        if (newMetaData.error) docIssues.push(NAMES_DOC.META_DATA);
        metaDataAcc[newMetaDataName] = newMetaData.metaData;
      }
      return metaDataAcc;
    }, {});

    newDoc.incomplete_pub_date = _.get(doc, NAMES_DOC.INCOMPLETE_PUB_DATE, false);

    if (docIssues.length) {
      this.errorBool = true;
      this.errorDoc[doc.id] = docIssues;
    }

    return {
      error: docIssues.length,
      doc: newDoc
    };
  }

  collectSteps() {
    // Settings for parent_context
    const parentContext = this.collectParentContext();

    return this.steps.reduce((stepsCollector, step) => {
      const stepIssues = [];
      // Main settings
      const stepName = _.get(step, [NAMES_STEP.NAME], '');
      const stepDescription = _.get(step, [NAMES_STEP.DESC], '');
      const newStep = {
        description: stepDescription,
        save_document: _.get(step, [NAMES_STEP.SAVE_DOC], false),
        process_related_documents: _.get(step, [NAMES_STEP.PROCESS_RELATED_DOCUMENTS], false),
        is_parent: _.get(step, [NAMES_STEP.IS_PARENT], false),
        continue_crawl: _.get(step, [NAMES_STEP.CONTINUE_CRAWL], false)
      };
      if (!stepName) stepIssues.push(NAMES_STEP.NAME);
      if (!stepDescription) stepIssues.push(NAMES_STEP.DESC);

      // Debug settings
      if (step[NAMES_STEP.DEBUG]) {
        newStep.debug = step[NAMES_STEP.DEBUG];
      }

      // Pre Generated Full Text settings
      if (step[NAMES_STEP.PRE_GENERATED_FULL_TEXT]) {
        newStep.pre_generate_full_text = step[NAMES_STEP.PRE_GENERATED_FULL_TEXT];
      }

      // Context settings
      if (step[NAMES_STEP.CONTEXT]) {
        const context = this.contexts.find(item => item.id === step[NAMES_STEP.CONTEXT]);
        const newContext = this.collectContext(context);
        newStep.context = newContext.context;
        if (!context || newContext.error) stepIssues.push(NAMES_STEP.CONTEXT);
      }

      // Doc settings
      if (step[NAMES_STEP.DOC]) {
        const doc = this.docs.find(item => item.id === step[NAMES_STEP.DOC]);
        const newDoc = this.collectDoc(doc);
        newStep.doc = newDoc.doc;
        if (!doc || newDoc.error) stepIssues.push(NAMES_STEP.DOC);
      }

      // Action settings
      if (step[NAMES_STEP.ACTIONS] && step[NAMES_STEP.ACTIONS].length) {
        const actions = this.actions.filter(item => step[NAMES_STEP.ACTIONS].includes(item.id));
        newStep.actions = actions.map(item => {
          const newAction = this.collectAction(item);
          if (newAction.error) stepIssues.push(NAMES_STEP.ACTIONS);
          return newAction.action;
        });
      }

      // Relation settings
      if (step[NAMES_STEP.RELATION] && step[NAMES_STEP.RELATION].length) {
        const relations = this.relations.filter(
          item => step[NAMES_STEP.RELATION].includes(item.id)
        );
        newStep.create_relations = relations.map(item => {
          const newRelation = this.collectRelation(item);
          if (newRelation.error) stepIssues.push(NAMES_STEP.RELATION);
          return newRelation.relation;
        });
      }

      // Reset counter settings
      if (step[NAMES_STEP.RESET_COUNTER]) {
        const resetCounter = this.resetCounters.find(
          item => item.id === step[NAMES_STEP.RESET_COUNTER]
        );
        const newResetCounter = this.collectResetCounter(resetCounter);
        newStep.reset_counter = newResetCounter.reset_counter;
        if (!resetCounter || newResetCounter.error) stepIssues.push(NAMES_STEP.RESET_COUNTER);
      }

      // Next steps
      const nextStepLength = _.get(step, [NAMES_STEP.NEXT_STEP, 'length'], 0);
      if (nextStepLength) {
        newStep.next_step = step[NAMES_STEP.NEXT_STEP].map(item => item.value);
      }

      if (step[NAMES_STEP.CONTEXT] && !nextStepLength) {
        stepIssues.push(NAMES_STEP.NEXT_STEP);
      }

      // parent_context settings
      if (parentContext[stepName]) {
        newStep.parent_context = parentContext[stepName];
      }

      if (stepIssues.length) {
        this.errorBool = true;
        this.errorStep[step.id] = stepIssues;
      }

      return {
        ...stepsCollector,
        [stepName]: newStep
      };
    }, {});
  }

  collectCategoryConversion() {
    const categoryConversionIssues = [];

    const defaultCatConv = _.get(this.categoryConversion, [NAMES_CAT_CONV.DEFAULT]);
    if (!defaultCatConv) categoryConversionIssues.push(NAMES_CAT_CONV.DEFAULT);

    const approachCatConv = _.get(this.categoryConversion, [NAMES_CAT_CONV.APPROACH, 'value']);
    if (!approachCatConv) categoryConversionIssues.push(NAMES_CAT_CONV.APPROACH);

    let mapCatConv = _.get(this.categoryConversion, [NAMES_CAT_CONV.MAP], []);
    let issueCatConv = !mapCatConv.length;
    mapCatConv = mapCatConv.reduce((conversionMap, item) => {
      conversionMap[item.label] = item.value;
      if (!item.label || !item.value) issueCatConv = true;
      return conversionMap;
    },{});
    if (issueCatConv) categoryConversionIssues.push(NAMES_CAT_CONV.MAP);

    if (categoryConversionIssues.length) {
      this.errorBool = true;
      this.errorCategoryConversion = categoryConversionIssues;
    }

    return {
      default_category: defaultCatConv,
      category_approach: approachCatConv,
      conversion_map: mapCatConv
    };
  }

  collectConfig() {
    const configIssues = [];
    const configuration = {};

    const fullTextPercentage = _.get(this.config, [NAMES_CONFIG.FULL_TEXT_PERCENTAGE], '');
    if (fullTextPercentage) {
      if (!isNaN(fullTextPercentage)) {
        configuration.full_text_percentage = Number(fullTextPercentage);
      } else {
        configIssues.push(NAMES_CONFIG.FULL_TEXT_PERCENTAGE);
      }
    }

    if (configIssues.length) {
      this.errorBool = true;
      this.errorConfig = configIssues;
    }

    return configuration;
  }

  collectSpecInfo() {
    const generalInfo = this.collectGeneralInfo();
    const stepsInfo = this.collectSteps();
    const configInfo = this.collectConfig();
    const specInfo = {
      ...generalInfo,
      configurations: {
        ...configInfo,
        steps: stepsInfo
      }
    };

    if (this.categoryConversion) {
      const categoryConversionInfo = this.collectCategoryConversion();
      specInfo.configurations.category_conversion = [categoryConversionInfo];
    }
    return specInfo;
  }
}
