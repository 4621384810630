import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Typography from "./Typography";

// size
const MEDIUM = 'medium';
const BIG = 'big';
const SMALL = 'small';
const CUSTOM = 'custom';

// colors
const BLUE = 'blue';
const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';
const RED = 'red';
const HIGHLIGHTED = 'highlighted';

const defaultColor = {
  [SUCCESS]: 'button_success',
  [WARNING]: 'button_warning',
  [ERROR]: 'button_error',
  [RED]: 'button_red',
  [BLUE]: 'button_blue',
  [HIGHLIGHTED]: 'button_highlighted'
};

const defaultSize = {
  [BIG]: 'button_large',
  [SMALL]: 'button_small',
  [MEDIUM]: 'button_medium',
  [CUSTOM]: 'button_custom'
};

function Button({
  children,
  className,
  size,
  color,
  onClick,
  textClassName,
  disabled,
  isError
}) {
  const onClickButton = (e) => {
    if (disabled) return;
    onClick(e);
  };

  return (
    <button
      type='button'
      onClick={onClickButton}
      className={cn(
        'button',
        {
          button_disabled: disabled,
          "button_is-error": isError
        },
        defaultColor[color],
        defaultSize[size],
        className
      )}
    >
      <Typography
        variant="p"
        className={cn(textClassName)}
      >
        {children}
      </Typography>
    </button>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf(Object.keys(defaultSize)),
  color: PropTypes.oneOf(Object.keys(defaultColor)),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isError: PropTypes.bool
};

Button.defaultProps = {
  size: CUSTOM,
  color: BLUE,
  className: '',
  textClassName: '',
  disabled: false,
  isError: false
};

export default Button;
