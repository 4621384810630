import type { FC } from 'react';
import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { ScrapedCategoryCellProps } from './ScrapedCategoryCell.types';

import * as uiLib from '@compliance.ai/web-components';

export const ScrapedCategoryCell: FC<ScrapedCategoryCellProps> = ({ row }) => {
  const scrapedCategoriesValue =
    row.scraped_categories as CaiCategoriesFromResponse['scraped_categories'];

  return (
    <div>
      {scrapedCategoriesValue.map(item => {
        const categoryText = item?.name;
        return (
          <uiLib.Tooltip isInteractive title={categoryText}>
            <uiLib.Typography shouldTruncate>{categoryText}</uiLib.Typography>
          </uiLib.Tooltip>
        );
      })}
    </div>
  );
};
