import type { ComponentType } from 'react';

import React from 'react';
import { useAdminPermissions } from './useAdminPermissions';

/**
 *
 * Note: use this HOC only for legacy class components
 *
 */
export const withAdminPermissions = (Component: ComponentType<Record<string, unknown>>) => {
  return function WrappedComponent(props: Record<string, unknown>) {
    const adminPermissions = useAdminPermissions();

    const newProps = { ...props, ...adminPermissions };

    /**
     * Silencing TS because it doesn't recognize additional props
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...newProps} />;
  };
};
