import type { CommandRequestType } from 'shared/features/commands/commands.api.types';
import type { CommandRequestsCreationModalHandlers } from './useCommandRequestsCreationModalHandlers.types';
import type { CommandRequestsCreationModalData } from './useCommandRequestsCreationModalData.types';
import type { CommandRequestsHandlers } from '../../../hooks/useCommandRequestsHandlers.types';

import * as errorUtils from 'shared/utils/errors';
import * as commandApi from 'shared/features/commands/commands.apiv2';

import { useCallback } from 'react';
import { useCommandsReduxActions } from 'shared/features/commands/hooks';

export const useCommandRequestsCreationModalHandlers = ({
  localState,
  localActions,
  reduxState,
  onClose
}: {
  localState: CommandRequestsCreationModalData['localState'];
  localActions: CommandRequestsCreationModalData['localActions'];
  reduxState: CommandRequestsCreationModalData['reduxState'];
  onClose: CommandRequestsHandlers['handleCreationModalClose'];
}): CommandRequestsCreationModalHandlers => {
  const commandsReduxActions = useCommandsReduxActions();
  const handleCommandsFetch: CommandRequestsCreationModalHandlers['handleCommandsFetch'] = async (
    inputString: string
  ) => {
    try {
      const response = await commandApi.fetchCommandsData({ name: inputString });

      return response.map((command: CommandRequestType) => ({
        ...command,
        label: command.name,
        value: command.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleChangeAdditionalParam: CommandRequestsCreationModalHandlers['handleChangeAdditionalParam'] =
    (name, value) => {
      localActions.setAdditionalParams({ ...localState.additionalParams, [name]: value });
    };

  const handleChangeCommand: CommandRequestsCreationModalHandlers['handleChangeCommand'] =
    useCallback(() => {
      const count =
        localState?.selectedCommand?.parameters?.additional_params?.reduce(
          (result, item) => ({
            ...result,
            [item.name]: item?.default
          }),
          {}
        ) || {};
      localActions.setAdditionalParams(count);
    }, [localActions, localState?.selectedCommand?.parameters?.additional_params]);

  const handleSaveCommandRequest: CommandRequestsCreationModalHandlers['handleSaveCommandRequest'] =
    params => {
      const formData = new window.FormData();
      if (localState.files?.[0]?.name) formData.append('attached_file', localState.files?.[0]);
      formData.append('command_request_parameters', JSON.stringify(params));
      commandsReduxActions.uploadNewCommandRequest(formData);
      onClose();
    };

  const handleCreateCommandRequest: CommandRequestsCreationModalHandlers['handleCreateCommandRequest'] =
    () => {
      let parameters = {};
      const command = localState.selectedCommand;
      const commandParams = command?.parameters;
      const additionalParams = commandParams?.additional_params;
      if (additionalParams) {
        const params = localState.additionalParams;
        try {
          parameters = additionalParams?.reduce((result, item) => {
            let currentVariable: string | number = params[item.name] && params[item.name]?.trim?.();
            const error = { error: 'wrong type' };
            if (item.type === 'num') {
              if (currentVariable && isNaN(Number(currentVariable))) throw error;
              currentVariable = Number(currentVariable);
            }
            return {
              ...result,
              [item.name]: currentVariable || currentVariable === 0 ? currentVariable : item.default
            };
          }, {});
        } catch (e) {
          localActions.setIsUserInputError(true);
          return;
        }
      }

      if (!localState.commandName?.trim?.()) {
        localActions.setIsUserInputError(true);
        return;
      }

      if (commandParams && commandParams?.file) {
        parameters = {
          ...parameters,
          file_name: localState.files?.[0]?.name
        };
      }

      const commandRequestParameters = {
        name: localState.commandName?.trim?.(),
        type: localState.selectedCommand?.id,
        user_id: reduxState.userId,
        parameters
      };

      handleSaveCommandRequest(commandRequestParameters);
    };

  return {
    handleCommandsFetch,
    handleChangeAdditionalParam,
    handleChangeCommand,
    handleCreateCommandRequest,
    handleSaveCommandRequest
  };
};
