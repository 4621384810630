import React, { useMemo } from "react";
import _ from 'lodash';
import { TABLE_VARIABLES } from "./DocumentCategories.constants";
import BodyCategoryCheckbox from "./BodyCategoryCheckbox";

const getDocsCategoryRowInfo = ({
  category,
  categoryInfo,
  selectedCategory,
  selectCategory
}) => {
  const formatInfo = categoryInfo.map(field => {
    const fieldValue = _.get(category, field, null);
    switch (field) {
      case TABLE_VARIABLES.jurisdictions:
        return fieldValue.join(', ');
      case TABLE_VARIABLES.surface_category:
      case TABLE_VARIABLES.surface_docs:
        return String(fieldValue);
      default:
        return fieldValue;
    }
  });
  return [
    <BodyCategoryCheckbox
      category={category}
      selectCategory={selectCategory}
      selectedCategory={selectedCategory}
    />,
    ...formatInfo
  ];
};

export default ({
  categoriesFormat,
  categoryInfo,
  selectedCategory,
  selectCategory
}) => {
  return useMemo(
    () => categoriesFormat.map(category => getDocsCategoryRowInfo({
      category,
      categoryInfo,
      selectedCategory,
      selectCategory
    })),
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoriesFormat, categoryInfo, selectedCategory.length]
  );
};
