import React from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";
import DocumentVersionList from "./DocumentVersionList";

const DocumentDetailsTabs = ({
  docDetailsInfo,
  currentDocVersion,
  changeCurrentDocVersion
}) => {
  return (
    <div className="document-list_details_items">
      <div className="document-list_details_items_title">
        <div>
          <Typography variant="title" bold>Version History</Typography>
        </div>
        <Button disabled onClick={() => {}} color="blue">Edit</Button>
      </div>
      <DocumentVersionList
        docVersions={_.get(docDetailsInfo, 'document_versions', [])}
        currentDocVersionID={_.get(currentDocVersion, 'id', 0)}
        changeCurrentDocVersion={changeCurrentDocVersion}
      />
    </div>
  );
};

DocumentDetailsTabs.propTypes = {
  docDetailsInfo: PropTypes.shape({}).isRequired,
  currentDocVersion: PropTypes.shape({}).isRequired,
  changeCurrentDocVersion: PropTypes.func.isRequired
};

export default DocumentDetailsTabs;
