import { setCommentsArray, addCommentToArray } from './crawlerComments.reducer';
import { openErrorSnackbar } from '../../snackbar/snackbar.reducer';
import { api_createComment, api_getComments } from './crawlerComments.api';

export const ERROR = 'Comment has not been added';

export const getCommentAC = (crawlID, params) => dispatch => {
  return api_getComments(crawlID, params).then(res => dispatch(setCommentsArray(res)));
};

export const createCommentAC = params => async dispatch => {
  try {
    const res = await api_createComment(params);

    dispatch(setCommentsArray(res));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR));

    throw e;
  }
};

export const addInternalComment = comment => dispatch => dispatch(addCommentToArray({ comment }));

export const clearCrawComments = () => dispatch => dispatch(setCommentsArray({ comments: [] }));
