import type { FC } from 'react';
import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { MetaCategoryCellProps } from './MetaCategoryCell.types';

import * as uiLib from '@compliance.ai/web-components';

export const MetaCategoryCell: FC<MetaCategoryCellProps> = ({ row }) => {
  const metaCategoryValue = row.meta_category as CaiCategoriesFromResponse['meta_category'];

  return <uiLib.Typography>{metaCategoryValue?.name}</uiLib.Typography>;
};
