import { isObject, isString } from "lodash";
import { DEFAULT_ERROR_MESSAGE } from "./auth.constants";

export const getErrorMessageFromResponse = error => {
  if (isString(error)) {
    return error;
  }

  if (isObject(error) && isString(error.message)) {
    return error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return DEFAULT_ERROR_MESSAGE;
};
