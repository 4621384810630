import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import {
  deleteUser, fetchSpecifiedUser, updateSpecifiedUser, sendConfirmationEmail
} from 'shared/features/user/user.actions';
import { CONTRIBUTOR, POWER_USER, QA } from "app/constants/UserRoles";
import {withAdminPermissions} from "../../utils/hooks";

class UsersItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
      isInternal: false,
      isQA: false,
      isSuspended: false,
      isContributor: false,
      isPowerUser: false,
      createdAt: null
    };
  }

  UNSAFE_componentWillMount() {
    this.checkUserIsEnabledAndSetState(this.props);
    this.checkUserIsInternalAndSetState(this.props);
    this.checkUserAndSetState(this.props);
    this.checkUserSuspendedStatus(this.props);
    this.checkUserIsContributorAndSetState(this.props);
    this.checkUserIsPowerUserAndSetState(this.props);
    this.getCreatedAtAndSetState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkUserIsEnabledAndSetState(nextProps);
    this.checkUserIsInternalAndSetState(nextProps);
    this.checkUserAndSetState(nextProps);
    this.checkUserSuspendedStatus(nextProps);
    this.checkUserIsContributorAndSetState(nextProps);
    this.checkUserIsPowerUserAndSetState(nextProps);
  }

  getCreatedAtAndSetState(props) {
    if (!_.isNil(props.user)) {
      return this.setState({ createdAt: props.user.created_at });
    }
    return this.setState({ createdAt: null });
  }

  checkUserIsEnabledAndSetState(props) {
    // checks if user has been enabled or not and sets state accordingly
    if (!_.isNil(props.user)) {
      if (props.user.enabled) {
        return this.setState({ isEnabled: true });
      }
    }
    return this.setState({ isEnabled: false });
  }

  checkUserIsInternalAndSetState(props) {
    // checks if user is internal or not and sets state accordingly
    if (!_.isNil(props.user)) {
      if (props.user.is_internal_user) {
        return this.setState({ isInternal: true });
      }
    }
    return this.setState({ isInternal: false });
  }

  checkUserAndSetState(props) {
    // checks if user has qa role or not and set's state accordingly
    if (!_.isNil(props.user)) {
      if (!_.isNil(props.user.roles)) {
        if (!_.isNil(props.user.roles.find(role => role === QA))) {
          return this.setState({ isQA: true });
        }
      }
    }
    return this.setState({ isQA: false });
  }

  checkUserIsContributorAndSetState(props) {
    // checks if user has contributor role or not and set's state accordingly
    if (!_.isNil(props.user)) {
      if (!_.isNil(props.user.roles)) {
        if (!_.isNil(props.user.roles.find(role => role === CONTRIBUTOR))) {
          return this.setState({ isContributor: true });
        }
      }
    }
    return this.setState({ isContributor: false });
  }

  checkUserIsPowerUserAndSetState(props) {
    // checks if user has qa role or not and set's state accordingly
    if (!_.isNil(props.user)) {
      if (!_.isNil(props.user.roles)) {
        if (!_.isNil(props.user.roles.find(role => role === POWER_USER))) {
          return this.setState({ isPowerUser: true });
        }
      }
    }
    return this.setState({ isPowerUser: false });
  }

  checkUserSuspendedStatus(props) {
    // checks if user is suspended and set state accordingly
    if (!_.isNil(props.user)) {
      if (props.user.suspended) {
        return this.setState({ isSuspended: true });
      }
    }
    return this.setState({ isSuspended: false });
  }

  handleEnabled(e, user) {
    const isEnabled = !this.state.isEnabled;
    if (isEnabled) {
      this.setState({ isEnabled });
      this.props.updateSpecifiedUser(user.email, { enabled: isEnabled }).then(res => {
        // get all users and rerender only if the this.state.isEnabled
        // doesn't match the server enabled
        if (res.user.enabled !== this.state.isEnabled) {
          this.props.fetchSpecifiedUser(user.email);
        }
      });
    }
  }

  handleInternal(e, user) {
    this.setState(state => {
      const isInternal = !state.isInternal;
      return { isInternal };
    }, () => {
      this.props.updateSpecifiedUser(
        user.email, { is_internal_user: this.state.isInternal }
      ).then(res => {
        // get all users and rerender only if the this.state.isInternal
        // doesn't match the server internal
        if (res.user.is_internal_user !== this.state.isInternal) {
          this.props.fetchSpecifiedUser(user.email);
        }
      });
    });
  }

  handleQA(e, user) {
    this.setState(state => {
      const isQA = !state.isQA;
      return { isQA };
    },
    () => {
      const isQA = this.state.isQA;
      this.props.updateSpecifiedUser(user.email, { isQA }).then(res => {
        // get all users and rerender only if the this.state.isQA
        // role doesn't match the server qa role
        const serverRoles = res.user.roles;
        const isQAServer = !_.isNil(serverRoles.find(role => role === QA));
        if (isQAServer !== this.state.isQA) {
          this.props.fetchSpecifiedUser(user.email);
        }
      });
    });
  }

  handleSuspendClick = e => {
    e.preventDefault();
    if (!this.state.isSuspended) {
      this.props.open_suspend_modal(this.props.user.email);
    } else {
      this.props.updateSpecifiedUser(this.props.user.email, { suspended: false }).then(() => {
        this.props.fetchSpecifiedUser(this.props.user.email);
      });
    }
  };

  handleDeleteClick = e => {
    e.preventDefault();
    this.props.open_delete_modal(this.props.user.email);
  }

  handleContributorToggle(e, user) {
    this.setState(state => {
      const isContributor = !state.isContributor;
      return { isContributor };
    }, () => {
      const isContributor = this.state.isContributor;
      this.props.updateSpecifiedUser(user.email, { isContributor }).then(res => {
        // get all users and rerender only if the this.state.isContributor
        // role doesn't match the server contributor role
        const serverRoles = res.user.roles;
        const isContributorServer = !_.isNil(serverRoles.find(role => role === CONTRIBUTOR));
        if (isContributorServer !== this.state.isContributor) {
          this.props.fetchSpecifiedUser(user.email);
        }
      });
    });
  }

  handlePowerUserToggle(e, user) {
    this.setState(state => {
      const isPowerUser = !state.isPowerUser;
      return { isPowerUser };
    }, () => {
      const isPowerUser = this.state.isPowerUser;
      this.props.updateSpecifiedUser(user.email, { isPowerUser }).then(res => {
        // get all users and rerender only if the this.state.isPowerUser
        // role doesn't match the server power_user role
        const serverRoles = res.user.roles;
        const isPowerUserServer = !_.isNil(serverRoles.find(role => role === POWER_USER));
        if (isPowerUserServer !== this.state.isPowerUser) {
          this.props.fetchSpecifiedUser(user.email);
        }
      });
    });
  }

  render() {
    const { open_user_modal, user } = this.props;

    const userEnabled = (
      <input
        name="isEnabled"
        type="checkbox"
        checked={this.state.isEnabled}
        onClick={e => e.stopPropagation()}
        onChange={e => this.handleEnabled(e, user)}
        className="qa-checkbox"
        disabled={!this.props.haveEditAccess}
      />
    );

    const userIsInternal = (
      <input
        name="isInternal"
        type="checkbox"
        checked={this.state.isInternal}
        onClick={e => e.stopPropagation()}
        onChange={e => this.handleInternal(e, user)}
        className="qa-checkbox"
        disabled={!this.props.haveEditAccess}
      />
    );

    const userQA = (
      <input
        name="isQA"
        type="checkbox"
        checked={this.state.isQA}
        onClick={e => e.stopPropagation()}
        onChange={e => this.handleQA(e, user)}
        className="qa-checkbox"
        disabled={this.state.isContributor || !this.props.haveEditAccess}
      />
    );

    const suspend_button = (
      <input
        type="checkbox"
        checked={this.state.isSuspended}
        onClick={e => e.stopPropagation()}
        onChange={e => this.handleSuspendClick(e)}
        className="qa-checkbox"
        disabled={!this.props.haveEditAccess}
      />
    );

    const userContributor = (
      <input
        name="isContributor"
        type="checkbox"
        checked={this.state.isContributor}
        onClick={e => e.stopPropagation()}
        onChange={e => this.handleContributorToggle(e, user)}
        className="qa-checkbox"
        disabled={this.state.isQA || !this.props.haveEditAccess}
      />
    );

    const userPowerUser = (
      <input
        name="isPowerUser"
        type="checkbox"
        checked={this.state.isPowerUser}
        onClick={e => e.stopPropagation()}
        onChange={e => this.handlePowerUserToggle(e, user)}
        className="qa-checkbox"
        disabled={!this.props.haveEditAccess}
      />
    );

    const lastLogin = user.enabled
      ? moment(user.properties.session_end).format('MM/DD/YYYY')
      : 'N/A';

    const activatedTime = user.properties && user.properties.activation_time
      ? moment(user.properties.activation_time).format('MM/DD/YYYY')
      : null;

    const invited = user.properties && !!user.properties.invited_by ? 'Yes' : 'No';

    const reinvited_date = user.properties && user.properties.resent_invite_time
      ? moment(user.properties.resent_invite_time).format('MM/DD/YYYY')
      : null;

    const reconfirmed_date = user.properties && user.properties.confirmation_resent_time
      ? moment(user.properties.confirmation_resent_time).format('MM/DD/YYYY')
      : null;

    const na_or_null = invited === 'Yes' ? 'Invited User' : 'Has Not Confirmed';

    const confirmed_date = user.properties && user.properties.confirmed_date
      ? moment(user.properties.confirmed_date).format('MM/DD/YYYY')
      : na_or_null;

    const user_created_on_time = moment(this.state.createdAt).format('MM/DD/YYYY');
    const sendConfirmationEmailButton = this.props.haveEditAccess ? (
      <Button
        onClick={e => {
          e.stopPropagation();
          this.props.sendConfirmationEmail(user.email).then(() => {
            this.props.fetchSpecifiedUser(user.email);
          });
        }}
      >
        send
      </Button>
    ) : null;

    const deleteUserButton = this.props.haveEditAccess ? (
      <Button
        onClick={e => {
          e.stopPropagation();
          this.handleDeleteClick(e);
        }}
      >
        delete
      </Button>
    ) : null;

    const user_team = !_.isNil(user.team_id)
      ? _.find(this.props.teams, team => {
        return team.id === user.team_id;
      })
      : { name: '' };
    return (
      <tr
        className="email-col-left"
        onClick={() => {
          if (this.props.haveEditAccess) {
            open_user_modal(`user_id=${user.id}`);
          }
        }}
      >
        <td>{user.email}</td>
        <td>{user.first_name + ' ' + user.last_name}</td>
        <td>{invited}</td>
        <td>{reinvited_date}</td>
        <td>{userEnabled}</td>
        <td>{userIsInternal}</td>
        <td>{user.company}</td>
        <td>{!user_team ? '' : user_team.name}</td>
        <td>{user.industry}</td>
        <td>{user.discipline}</td>
        <td>{user.level}</td>
        <td>{userQA}</td>
        <td>{userContributor}</td>
        <td>{userPowerUser}</td>
        <td>{user_created_on_time}</td>
        <td>{confirmed_date}</td>
        <td>{activatedTime}</td>
        <td>{lastLogin}</td>
        <td>{this.state.isSuspended ? 'Suspended' : 'Active'}</td>
        <td>{suspend_button}</td>
        <td>{this.state.isSuspended ? user.suspended_reason : ''}</td>
        <td>{reconfirmed_date}</td>
        <td>{sendConfirmationEmailButton}</td>
        <td>{deleteUserButton}</td>
      </tr>
    );
  }
}
const mapStateToProps = state => {
  return {
    teams: state.teams.all_teams
  };
};
export default withAdminPermissions(connect(mapStateToProps, {
  updateSpecifiedUser,
  sendConfirmationEmail,
  fetchSpecifiedUser,
  deleteUser
})(UsersItem));
