import type { CommandRequestsDetailsModalData } from './useCommandRequestsDetailsModalData.types';
import type { CommandRequestsDetailsModalHandlers } from './useCommandRequestsDetailsModalHandlers.types';
import { api_updateCommandRequest } from 'shared/features/commands/commands.api';
import { COMMAND_REQUEST_STATUS } from 'admin/constants/commandRequestTypes';
import * as errorUtils from 'shared/utils/errors';

export const useCommandRequestsDetailsModalHandlers = ({
  formattedData,
  props
}: {
  formattedData: CommandRequestsDetailsModalData['formattedData'];
  props: {
    onClose: () => void;
  };
}): CommandRequestsDetailsModalHandlers => {
  const handleOnSubmit: CommandRequestsDetailsModalHandlers['handleOnSubmit'] = async () => {
    if (
      formattedData.enforcement_command &&
      (formattedData.ready_for_db_status || formattedData.processed_ready_for_fix_status)
    ) {
      const params = { status: COMMAND_REQUEST_STATUS.COMMIT_TO_DB };
      try {
        await api_updateCommandRequest(formattedData.command_request_id, params);
        props.onClose();
      } catch (e) {
        errorUtils.logError(e as Error, 'Error');
      }
    } else {
      props.onClose();
    }
  };
  // for now the data service does not change command request status after a fix , allowing
  // the fix link to be there, until update is called
  // later if the data service changed to status processed_ready_for_fix_status,
  // we will allow fix link to appear only until fixed not performed
  const handleOnOption: CommandRequestsDetailsModalHandlers['handleOnOption'] = async () => {
    if (formattedData.enforcement_command && formattedData.ready_for_db_status) {
      const params = { status: COMMAND_REQUEST_STATUS.READY_FOR_FIX };
      try {
        await api_updateCommandRequest(formattedData.command_request_id, params);
        props.onClose();
      } catch (e) {
        errorUtils.logError(e as Error, 'Error');
      }
    } else {
      props.onClose();
    }
  };

  return {
    handleOnSubmit,
    handleOnOption
  };
};
