import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { ERRORS, INFO_HELP, NAMES_STEP } from '../CrawlSpecCreate.constants';
import {
  createNewContext,
  createNewDoc,
  createNewAction,
  createNewRelation,
  createNewResetCounter
} from '../CrawlSpecCreate.helper';
import TextInput from "../../../components/TextInput";
import Select from "../../../components/ReactSelect/Select";
import BtnNewElement from "../HelpComponents/BtnNewElement";
import SpecElementsWrapper from "./SpecElementsWrapper";
import ExpandableBtnList from "../HelpComponents/ExpandableBtnList";
import Checkbox from "../../../components/Checkbox_NEW";

const Step = ({
  disabled,
  show,
  currentContextUUID,
  currentDocUUID,
  currentActionUUID,
  currentRelationUUID,
  currentResetCounterUUID,
  element,
  steps,
  contexts,
  docs,
  actions,
  relations,
  resetCounters,
  changeStep,
  changeContext,
  changeCurrentContext,
  changeCurrentAction,
  changeCurrentRelation,
  changeCurrentResetCounter,
  changeDoc,
  changeAction,
  changeRelation,
  changeResetCounter,
  changeCurrentDoc,
  clearStepElements,
  clearActionElements,
  clearRelationElements,
  errorFieldsActions,
  errorFieldsRelations,
  errorFields
}) => {
  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changeStep(name, value);
  };

  const addNewContext = () => {
    const newContext = createNewContext();
    changeContext([...contexts, newContext]);
    changeStep(NAMES_STEP.CONTEXT, newContext.id);
  };

  const addNewDoc = () => {
    const newDoc = createNewDoc();
    changeDoc([...docs, newDoc]);
    changeStep(NAMES_STEP.DOC, newDoc.id);
  };

  const addNewAction = () => {
    const newAction = createNewAction();
    changeAction([...actions, newAction]);
    return newAction.id;
  };

  const addNewRelation = () => {
    const newRelation = createNewRelation();
    changeRelation([...relations, newRelation]);
    return newRelation.id;
  };

  const addNewResetCounter = () => {
    const newResetCounter = createNewResetCounter();
    changeResetCounter([...resetCounters, newResetCounter]);
    changeStep(NAMES_STEP.RESET_COUNTER, newResetCounter.id);
  };

  return (
    <SpecElementsWrapper
      show={show}
      label="Step"
      tooltip={INFO_HELP.STEP}
      debugValue={element[NAMES_STEP.DEBUG]}
      onDebug={() => changeStep(NAMES_STEP.DEBUG, !element[NAMES_STEP.DEBUG])}
    >
      <TextInput
        label="Name"
        name={NAMES_STEP.NAME}
        value={element[NAMES_STEP.NAME]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_STEP.NAME)}
        errorText={ERRORS.stepName}
      />
      <TextInput
        label="Description"
        name={NAMES_STEP.DESC}
        value={element[NAMES_STEP.DESC]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_STEP.DESC)}
        errorText={ERRORS.stepDesc}
      />
      <TextInput
        label="Pre Generated Full Text"
        name={NAMES_STEP.PRE_GENERATED_FULL_TEXT}
        value={element[NAMES_STEP.PRE_GENERATED_FULL_TEXT]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_STEP.PRE_GENERATED_FULL_TEXT)}
      />
      <Select
        label="Next Step"
        value={element[NAMES_STEP.NEXT_STEP]}
        onChange={e => changeStep(NAMES_STEP.NEXT_STEP, e)}
        options={
            steps.map(item => ({
              value: item[NAMES_STEP.NAME],
              label: item[NAMES_STEP.NAME]
            }))
          }
        isDisabled={disabled}
        isClearable
        isMulti
        isError={errorFields.includes(NAMES_STEP.NEXT_STEP)}
        errorText={ERRORS.stepNextStep}
      />
      <Checkbox
        label="Save Document"
        disabled={disabled}
        onClick={checked => changeStep(NAMES_STEP.SAVE_DOC, checked)}
        isError={errorFields.includes(NAMES_STEP.SAVE_DOC)}
        checked={element[NAMES_STEP.SAVE_DOC]}
      />
      <Checkbox
        label="Process Related Documents"
        disabled={disabled}
        onClick={checked => changeStep(NAMES_STEP.PROCESS_RELATED_DOCUMENTS, checked)}
        isError={errorFields.includes(NAMES_STEP.PROCESS_RELATED_DOCUMENTS)}
        checked={element[NAMES_STEP.PROCESS_RELATED_DOCUMENTS]}
      />
      <Checkbox
        label="Is Parent"
        disabled={disabled}
        onClick={checked => changeStep(NAMES_STEP.IS_PARENT, checked)}
        isError={errorFields.includes(NAMES_STEP.IS_PARENT)}
        checked={element[NAMES_STEP.IS_PARENT]}
      />
      <Checkbox
        label="Continue Crawl"
        disabled={disabled}
        onClick={checked => changeStep(NAMES_STEP.CONTINUE_CRAWL, checked)}
        isError={errorFields.includes(NAMES_STEP.CONTINUE_CRAWL)}
        checked={element[NAMES_STEP.CONTINUE_CRAWL]}
      />
      <BtnNewElement
        elementExist={!!element[NAMES_STEP.CONTEXT]}
        isDisabled={disabled || element.locked}
        label="Context"
        active={element[NAMES_STEP.CONTEXT] === currentContextUUID}
        name={NAMES_STEP.CONTEXT}
        addNew={addNewContext}
        removeItem={() => {
          changeStep(NAMES_STEP.CONTEXT, null);
          clearStepElements(element[NAMES_STEP.CONTEXT]);
        }}
        setCurrent={() => changeCurrentContext(element[NAMES_STEP.CONTEXT])}
        isError={errorFields.includes(NAMES_STEP.CONTEXT)}
      />
      <BtnNewElement
        elementExist={!!element[NAMES_STEP.DOC]}
        isDisabled={disabled || element.locked}
        label="Doc"
        active={element[NAMES_STEP.DOC] === currentDocUUID}
        name={NAMES_STEP.DOC}
        addNew={addNewDoc}
        removeItem={() => {
          changeStep(NAMES_STEP.DOC, null);
          clearStepElements(element[NAMES_STEP.DOC]);
        }}
        setCurrent={() => changeCurrentDoc(element[NAMES_STEP.DOC])}
        isError={errorFields.includes(NAMES_STEP.DOC)}
      />
      <ExpandableBtnList
        label="Actions"
        isDisabled={disabled || element.locked}
        value={element[NAMES_STEP.ACTIONS]}
        name={NAMES_STEP.ACTIONS}
        addMoreName="Action"
        activeID={currentActionUUID}
        onChange={changeStep}
        onClickBtn={changeCurrentAction}
        createNewItemTemplate={addNewAction}
        helpRemoveFunc={clearActionElements}
        errorFields={Object.keys(errorFieldsActions)}
        isError={
            errorFields.includes(NAMES_STEP.ACTIONS)
            && !_.get(element, [NAMES_STEP.ACTIONS, 'length'], '')
          }
        errorText={ERRORS.stepActions}
      />
      <ExpandableBtnList
        label="Relations"
        isDisabled={disabled || element.locked}
        value={element[NAMES_STEP.RELATION]}
        name={NAMES_STEP.RELATION}
        addMoreName="Relation"
        activeID={currentRelationUUID}
        onChange={changeStep}
        onClickBtn={changeCurrentRelation}
        createNewItemTemplate={addNewRelation}
        helpRemoveFunc={clearRelationElements}
        errorFields={Object.keys(errorFieldsRelations)}
        isError={
            errorFields.includes(NAMES_STEP.RELATION)
            && !_.get(element, [NAMES_STEP.RELATION, 'length'], '')
          }
        errorText={ERRORS.stepRelations}
      />
      <BtnNewElement
        elementExist={!!element[NAMES_STEP.RESET_COUNTER]}
        isDisabled={disabled || element.locked}
        label="Reset Counter"
        active={element[NAMES_STEP.RESET_COUNTER] === currentResetCounterUUID}
        name={NAMES_STEP.RESET_COUNTER}
        addNew={addNewResetCounter}
        removeItem={() => {
          changeStep(NAMES_STEP.RESET_COUNTER, null);
          clearStepElements(element[NAMES_STEP.RESET_COUNTER]);
        }}
        setCurrent={() => changeCurrentResetCounter(element[NAMES_STEP.RESET_COUNTER])}
        isError={errorFields.includes(NAMES_STEP.RESET_COUNTER)}
      />
    </SpecElementsWrapper>
  );
};

Step.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentContextUUID: PropTypes.string,
  currentDocUUID: PropTypes.string,
  currentActionUUID: PropTypes.string,
  currentRelationUUID: PropTypes.string,
  currentResetCounterUUID: PropTypes.string,
  element: PropTypes.shape({}),
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  contexts: PropTypes.arrayOf(PropTypes.shape({})),
  docs: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  relations: PropTypes.arrayOf(PropTypes.shape({})),
  resetCounters: PropTypes.arrayOf(PropTypes.shape({})),
  changeStep: PropTypes.func.isRequired,
  changeContext: PropTypes.func.isRequired,
  changeCurrentContext: PropTypes.func.isRequired,
  changeCurrentAction: PropTypes.func.isRequired,
  changeCurrentRelation: PropTypes.func.isRequired,
  changeCurrentResetCounter: PropTypes.func.isRequired,
  changeDoc: PropTypes.func.isRequired,
  changeAction: PropTypes.func.isRequired,
  changeRelation: PropTypes.func.isRequired,
  changeResetCounter: PropTypes.func.isRequired,
  changeCurrentDoc: PropTypes.func.isRequired,
  clearStepElements: PropTypes.func,
  clearActionElements: PropTypes.func,
  clearRelationElements: PropTypes.func,
  errorFieldsActions: PropTypes.shape({}),
  errorFieldsRelations: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Step.defaultProps = {
  disabled: false,
  show: false,
  currentContextUUID: '',
  currentDocUUID: '',
  currentActionUUID: '',
  currentRelationUUID: '',
  currentResetCounterUUID: '',
  element: {},
  steps: [],
  contexts: [],
  docs: [],
  actions: [],
  relations: [],
  resetCounters: [],
  clearStepElements: () => {},
  clearActionElements: () => {},
  clearRelationElements: () => {},
  errorFieldsActions: {},
  errorFieldsRelations: {},
  errorFields: []
};

export default Step;
