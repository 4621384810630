import React from 'react';
import PropTypes from 'prop-types';
import { createNewStep } from '../CrawlSpecCreate.helper';
import ExpandableBtnList from '../HelpComponents/ExpandableBtnList';
import SpecElementsWrapper from "./SpecElementsWrapper";
import { INFO_HELP } from "../CrawlSpecCreate.constants";

const StepsList = ({
  disabled,
  show,
  currentUUID,
  element,
  changeElements,
  changeCurrentStep,
  clearAllSteps,
  errorFields
}) => {
  const onChange = (name, items) => changeElements(items);

  return (
    <SpecElementsWrapper
      show={show}
      label="Steps"
      tooltip={INFO_HELP.STEPS_LIST}
    >
      <ExpandableBtnList
        isDisabled={disabled}
        activeID={currentUUID}
        value={element}
        name="steps"
        addMoreName="Step"
        onChange={onChange}
        onClickBtn={changeCurrentStep}
        createNewItemTemplate={createNewStep}
        helpRemoveFunc={clearAllSteps}
        errorFields={Object.keys(errorFields)}
      />
    </SpecElementsWrapper>
  );
};

StepsList.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentUUID: PropTypes.string,
  element: PropTypes.arrayOf(PropTypes.shape({})),
  changeElements: PropTypes.func.isRequired,
  changeCurrentStep: PropTypes.func.isRequired,
  clearAllSteps: PropTypes.func.isRequired,
  errorFields: PropTypes.shape({})
};

StepsList.defaultProps = {
  disabled: false,
  show: false,
  currentUUID: '',
  element: {},
  errorFields: {}
};

export default StepsList;
