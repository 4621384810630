import type * as apiTypes from './scrapedCategories.apiV2.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchScrapedCategoriesData: apiTypes.FetchScrapedCategoriesData = async params => {
  return fetch({
    url: `/scraped-categories/`,
    dataType: 'Scraped Categories',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const modifyScrapedCategory: apiTypes.ModifyScrapedCategory = (id, params) => {
  return fetch({
    url: `/scraped-categories/${id}`,
    method: 'PATCH',
    dataType: 'Scraped Categories',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    shouldHideErrorNotifications: true
  });
};
