import React from 'react';
import cn from 'classnames';
import _ from "lodash";
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select-3/async';
import Typography from '../Typography';

function CustomAsyncSelect({
  className,
  classNamePrefix,
  selectClassName,
  labelClassName,
  isDisabled,
  isMulti,
  options,
  label,
  isSearchable,
  isClearable,
  placeholder,
  value,
  onChange,
  loadOptions,
  getOptionLabel,
  getOptionValue,
  cacheOptions,
  defaultOptions,
  components,
  noOptionsMessage,
  debounceTime
}) {
  const loadSuggestions = _.debounce((query, callback) => {
    loadOptions(query).then(resp => callback(resp));
  }, debounceTime);
  return (
    <label className={cn(
      "select_wrapper",
      className
    )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
      </Typography>
      <AsyncSelect
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        loadOptions={loadSuggestions}
        classNamePrefix={classNamePrefix}
        className={cn(
          selectClassName
        )}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onChange={onChange}
        isSearchable={isSearchable}
        isClearable={isClearable}
        value={value || null}
        components={components}
        noOptionsMessage={noOptionsMessage}
      />
    </label>
  );
}


CustomAsyncSelect.propTypes = {
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  noOptionsMessage: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  components: PropTypes.shape({}),
  debounceTime: PropTypes.number
};

CustomAsyncSelect.defaultProps = {
  isMulti: false,
  isDisabled: false,
  isClearable: false,
  isSearchable: true,
  classNamePrefix: 'custom-select',
  getOptionLabel: e => e.label,
  getOptionValue: e => e.value,
  cacheOptions: true,
  defaultOptions: false,
  components: {},
  noOptionsMessage: () => 'Start typing to show options',
  debounceTime: 1000
};


export default CustomAsyncSelect;
