import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from "prop-types";
import ButtonPrimaryProgress from '../ButtonPrimaryProgress/mButtonPrimaryProgress';

export const BUTTON_TYPES = {
  primary: {
    variant: 'contained',
    color: 'primary'
  },
  secondary: {
    variant: 'outlined',
    color: 'secondary'
  },
  text: {
    variant: 'text',
    color: 'default'
  },
  nav: {
    variant: 'outlined',
    color: 'default'
  },
  submit: {
    variant: 'outlined',
    color: 'secondary'
  }
};

const DEFAULT_SIZE = 'small';
const DEFAULT_TYPE = 'primary';

const MButton = ({
  children,
  classes,
  disabled,
  endIcon,
  id,
  isButtonBlocked,
  label,
  onClick,
  startIcon,
  size = DEFAULT_SIZE,
  type = 'button',
  variant = DEFAULT_TYPE,
  ...props
}) => {
  return (
    <Button
      id={id}
      variant={BUTTON_TYPES[variant].variant}
      color={BUTTON_TYPES[variant].color}
      size={size}
      onClick={onClick}
      className={classes}
      endIcon={endIcon}
      startIcon={startIcon}
      type={type}
      disabled={disabled}
      aria-label={label}
      {...props}
    >
      {(isButtonBlocked && <ButtonPrimaryProgress />) || children}
    </Button>
  );
};

MButton.propTypes ={
  children: PropTypes.node,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.element,
  id: PropTypes.number,
  isButtonBlocked: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  startIcon: PropTypes.element,
  type: PropTypes.string,
  variant: PropTypes.string
};

MButton.defaultProps ={
  children: null,
  classes: '',
  disabled: false,
  endIcon: null,
  id: null,
  isButtonBlocked: false,
  label: '',
  size: DEFAULT_SIZE,
  startIcon: null,
  type: 'submit',
  variant: DEFAULT_TYPE
};

export default MButton;
