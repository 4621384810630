import type { DryRunReportsTableHandlers } from './useDryRunReportsTableHandlers.types';
import type { DryRunDebugInfo, DryRunReportsTableProps } from '../DryRunReportsTable.types';

import { LOG_TYPES } from 'shared/features/spiderRunReports/spiderRunReports.constants';

import { useCallback } from 'react';

export const useDryRunReportsTableHandlers = ({
  props
}: {
  props: Pick<DryRunReportsTableProps, 'onOpenDryRunInfoModal'>;
}): DryRunReportsTableHandlers => {
  const { onOpenDryRunInfoModal } = props;

  const handleReasonClick: DryRunReportsTableHandlers['handleReasonClick'] = useCallback(
    report => () => {
      if (
        ![LOG_TYPES.VIDEO_LOG, LOG_TYPES.TEXT_LOG, LOG_TYPES.TEXT_METRICS].includes(
          report?.official_id as LOG_TYPES
        )
      ) {
        onOpenDryRunInfoModal(report);
      }
    },
    [onOpenDryRunInfoModal]
  );

  const handleChildReportsOpen: DryRunReportsTableHandlers['handleChildReportsOpen'] = row => {
    const expandableInfo = row?.expandableInfo as DryRunDebugInfo[];
    return {
      results: expandableInfo,
      count: expandableInfo.length
    };
  };

  return {
    handleReasonClick,
    handleChildReportsOpen
  };
};
