import type { FC } from 'react';
import type { CrawlerHistoryElementProps } from './CrawlerHistoryElement.types';

import * as uiLib from '@compliance.ai/web-components';
import * as helpers from './CrawlerHistoryElement.helpers';

import CrawlerHistoryElementLine from './CrawlerHistoryElementLine';

export const CrawlerHistoryElement: FC<CrawlerHistoryElementProps> = ({ history }) => (
  <div className="crawler-history_element">
    <div>
      <CrawlerHistoryElementLine
        label="User: "
        value={(history.user_name?.trim() || history.user) as string}
      />
      <CrawlerHistoryElementLine
        label="Date: "
        value={uiLib.formatDate(history.created_at, {
          format: uiLib.DATE_FORMATS.AMERICAN_DATE_TIME
        })}
      />
    </div>
    <div>
      <uiLib.Typography isItalic>Fields: </uiLib.Typography>
      {helpers.collectFields({ data: history.new_data })}
    </div>
  </div>
);
