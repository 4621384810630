import { createSlice } from '@reduxjs/toolkit';
import {
  setTokens,
  authenticateUser,
  changePassword,
  refreshSessionTokens,
  signUpUser,
  sendPasswordResetEmail,
  signOut
} from './auth.actions';
import { REDUCER_NAME } from "./auth.constants";

export const INITIAL_STATE = {
  isLoading: false,
  isAuthenticated: false,
  tokens: {
    JWTToken: null,
    accessToken: null,
    idToken: null,
    refreshToken: null
  }
};

export const { reducer } = createSlice({
  name: REDUCER_NAME,
  initialState: INITIAL_STATE,
  extraReducers: {
    [setTokens.type]: (state, action) => {
      const { accessToken, idToken, refreshToken, JWTToken } = action.payload;

      state.isAuthenticated = Boolean(JWTToken);
      state.tokens = {
        JWTToken,
        accessToken,
        idToken,
        refreshToken
      };
    },
    [authenticateUser.pending]: state => {
      state.isLoading = true;
    },
    [authenticateUser.fulfilled]: state => {
      state.isLoading = false;
    },
    [authenticateUser.rejected]: state => {
      state.isLoading = false;
    },
    [signUpUser.pending]: state => {
      state.isLoading = true;
    },
    [signUpUser.fulfilled]: state => {
      state.isLoading = false;
    },
    [signUpUser.rejected]: state => {
      state.isLoading = false;
    },
    [refreshSessionTokens.pending]: state => {
      state.isLoading = true;
    },
    [refreshSessionTokens.fulfilled]: state => {
      state.isLoading = false;
    },
    [refreshSessionTokens.rejected]: state => {
      state.isLoading = false;
    },
    [sendPasswordResetEmail.pending]: state => {
      state.isLoading = true;
    },
    [sendPasswordResetEmail.fulfilled]: state => {
      state.isLoading = false;
    },
    [sendPasswordResetEmail.rejected]: state => {
      state.isLoading = false;
    },
    [changePassword.pending]: state => {
      state.isLoading = true;
    },
    [changePassword.fulfilled]: state => {
      state.isLoading = false;
    },
    [changePassword.rejected]: state => {
      state.isLoading = false;
    },
    [signOut.pending]: state => {
      state.isLoading = true;
    },
    [signOut.fulfilled]: state => {
      state.isLoading = false;
    },
    [signOut.rejected]: state => {
      state.isLoading = false;
    }
  }
});
