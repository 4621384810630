import type * as commandsApiTypes from 'shared/features/commands/commands.api.types';

import * as commandsApi from 'shared/features/commands/commands.api';
import * as errorUtils from 'shared/utils/errors';

import { QUERY_KEYS, QUERY_SECTIONS, COMPLEX_QUERY_KEY_GETTERS } from 'admin/constants/queryKeys';

import { useQuery } from '@tanstack/react-query';
import * as helpers from './useDocsModalQueries.helperts';

export const useDocsModalQueries = () => {
  const fetchCommands = useQuery({
    queryKey:
      COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.DOCUMENTS_DATA][QUERY_SECTIONS.DOCUMENT_BULK_COMMANDS](),
    queryFn: async () => {
      const response = await commandsApi.api_fetchAllCommands();

      return response as commandsApiTypes.CommandRequestFromResponse[];
    },
    select: helpers.findBulkUpdateCommandId,
    onError: errorUtils.logReactQueryError,
    refetchOnWindowFocus: false
  });

  return {
    fetchCommands
  };
};
