import type { FC } from 'react';
import type { ExportModalProps } from './ExportModal.types';

import * as uiLib from '@compliance.ai/web-components';

import * as exportConstants from 'admin/constants/Export';

import { useExportModalData, useExportModalHandlers } from './hooks';

import './_export-modal.scss';

export const ExportModal: FC<ExportModalProps> = ({
  documentsCount,
  contentType,
  onExport,
  onClose
}) => {
  const { localState, localActions, formattedData } = useExportModalData({
    props: { documentsCount, contentType }
  });

  const handlers = useExportModalHandlers({
    props: { contentType, onExport, onClose },
    localState,
    localActions,
    formattedData
  });

  return (
    <uiLib.Modal
      title={`Export to ${exportConstants.CONTENT_DISPLAY_NAMES[contentType]}`}
      className="document-export-modal__container"
      isOpen
      onClose={onClose}
      childContainerClassName="document-export-modal-child__container"
    >
      <>
        <div className="document-export-modal__header">{formattedData.headerText}</div>

        <div className="document-export-modal__body">
          <div className="document-export-modal__body-section document-export-modal__selectors">
            <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleSelectAll}>
              Select All
            </uiLib.Button>
            <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleClearAll}>
              Clear All
            </uiLib.Button>
          </div>

          <div className="document-export-modal__body-section document-export-modal__columns">
            {formattedData.basicColumnsToDisplay.map((sectionColumns, sectionIndex) => (
              <div className="document-export-modal__body-section-block" key={sectionIndex}>
                {sectionColumns.map(column => (
                  <div key={sectionIndex + column}>
                    <uiLib.Checkbox
                      isChecked={localState.selectedColumns.includes(column)}
                      label={column}
                      onChange={handlers.handleColumnSelectChange(column)}
                      classNames={{ checkbox: 'document-export-modal__checkbox' }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="document-export-modal__footer">
          <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={onClose}>
            Cancel
          </uiLib.Button>
          <uiLib.Button
            type={uiLib.BUTTON_TYPES.PRIMARY}
            onClick={handlers.handleExport}
            isDisabled={formattedData.isExportDisabled}
          >
            Export
          </uiLib.Button>
        </div>
      </>
    </uiLib.Modal>
  );
};
