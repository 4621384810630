import React, { useState } from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";
import DocumentRelationsList from "./DocumentRelationsList";
import DocumentRelationsCreation from "./DocumentRelationsCreation";


const DocumentRelationsInfo = ({
  docDetailsInfo,
  currentDocRelation,
  changeCurrentDocRelation,
  user
}) => {
  const id = _.get(currentDocRelation, 'id', 0);
  const [editMode, setEditMode] = useState(isNaN(+id));
  const [createMode, setCreateMode] = useState(false);

  const openCreateMode = () => {
    setEditMode(!editMode);
    setCreateMode(true);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setCreateMode(false);
  };

  return (
    <div className="document-list_details_items">
      <div className="document-list_details_items_title">
        <div>
          <Typography variant="title" bold>Document Relations</Typography>
        </div>
        <div className="document-list_details_items_title_buttons">
          <Button
            onClick={toggleEditMode}
            color="blue"
          >
            {editMode ? 'Cancel' : 'Edit'}
          </Button>
          {!editMode && (
            <Button
              onClick={() => openCreateMode()}
              color="blue"
            >
              Create
            </Button>
          )}
        </div>
      </div>
      {createMode
        ? <DocumentRelationsCreation
          current_user={user}
          docDetailsInfo={docDetailsInfo}
          toggleEditMode={toggleEditMode}
        />
        : <DocumentRelationsList
          docRelations={_.get(docDetailsInfo, 'document_relations', [])}
          currentDocRelationID={_.get(currentDocRelation, 'id', 0)}
          changeCurrentDocRelation={changeCurrentDocRelation}
          editMode={editMode}
          user={user}
          toggleEditMode={toggleEditMode}
        />}
    </div>
  );
};

DocumentRelationsInfo.propTypes = {
  docDetailsInfo: PropTypes.shape({}).isRequired,
  currentDocRelation: PropTypes.shape({}).isRequired,
  changeCurrentDocRelation: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired
};

export default DocumentRelationsInfo;
