import type { FC } from 'react';
import type { EitlLabelCreateDropdownProps } from './EitlLabelCreateDropdown.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import {
  useEitlLabelCreateDropdownData,
  useEitlLabelCreateDropdownHandlers,
  useEitlLabelCreateDropdownListConfig
} from './hooks';

import './_eitl-label-create-dropdown.scss';

export const EitlLabelCreateDropdown: FC<EitlLabelCreateDropdownProps> = ({
  onAddModalOpen,
  onImportModalOpen
}) => {
  const { localState, localActions } = useEitlLabelCreateDropdownData();

  const handlers = useEitlLabelCreateDropdownHandlers({
    props: {
      onAddModalOpen,
      onImportModalOpen
    },
    localActions
  });

  const listConfig = useEitlLabelCreateDropdownListConfig();

  return (
    <uiLib.Dropdown
      isOpen={localState.isOpen}
      onClose={handlers.handleEitlLabelCreateDropdownToggle(false)}
      containerClassName="eitl-label-create__dropdown-button"
      className="eitl-label-create__dropdown"
      triggerContent={
        <uiLib.ActionButton onClick={handlers.handleEitlLabelCreateDropdownToggle(true)}>
          <uiLib.IconAdd />
        </uiLib.ActionButton>
      }
      dropdownContent={
        <uiLib.List
          selectedOptions={[]}
          onOptionClick={handlers.handleListOptionClick}
          options={listConfig.options}
        />
      }
    />
  );
};
