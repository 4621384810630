import type { CaiCategoriesApi } from './useCaiCategoriesApi.types';

import * as hookConstants from './useCaiCategoriesApi.constants';
import * as api from '../caiCategories.apiV2';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { StatusCodes } from 'http-status-codes';

export const useCaiCategoriesApi = (): CaiCategoriesApi => {
  const getCaiCategories: CaiCategoriesApi['getCaiCategories'] = async params => {
    try {
      const response = await api.fetchCaiCategoriesData(params);

      return response;
    } catch (e) {
      errorUtils.logError(e as Error);
      throw e;
    }
  };

  const modifyCaiCategory: CaiCategoriesApi['modifyCaiCategory'] = async ({ id, params }) => {
    try {
      const response = await api.modifyCaiCategory(id, params);

      return response;
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      if (isValidationError) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
          title: 'Validation error',
          message: hookConstants.CAI_CATEGORIES_ERRORS_MESSAGES.CAI_CATEGORIES_NAME_VALIDATION
        });
      }

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const addCaiCategory: CaiCategoriesApi['addCaiCategory'] = async params => {
    try {
      const response = await api.addCaiCategory(params);

      return response;
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      if (isValidationError) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
          title: 'Validation error',
          message: hookConstants.CAI_CATEGORIES_ERRORS_MESSAGES.CAI_CATEGORIES_NAME_VALIDATION
        });
      }

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const splitCaiCategory: CaiCategoriesApi['splitCaiCategory'] = async params => {
    try {
      const response = await api.splitCaiCategory(params);

      return response;
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      if (isValidationError) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
          title: 'Validation error',
          message: hookConstants.CAI_CATEGORIES_ERRORS_MESSAGES.CAI_CATEGORIES_NAME_VALIDATION
        });
      }

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.ERROR,
        message: (e as Error)?.message
      });
      throw e;
    }
  };

  const mergeCaiCategory: CaiCategoriesApi['mergeCaiCategory'] = async params => {
    try {
      const response = await api.mergeCaiCategory(params);

      return response;
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      if (isValidationError) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
          title: 'Validation error',
          message: hookConstants.CAI_CATEGORIES_ERRORS_MESSAGES.CAI_CATEGORIES_NAME_VALIDATION
        });
      }

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  return {
    getCaiCategories,
    modifyCaiCategory,
    addCaiCategory,
    splitCaiCategory,
    mergeCaiCategory
  };
};
