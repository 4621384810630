import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Typography from "../../components/Typography";

function RightTab({
  className,
  label,
  onClick,
  activeTab,
  icon
}) {
  return (
    <li
      className={cn(
        "custom-right-tab-list-item",
        {
          "custom-right-tab-list-item_active": activeTab
        },
        className
      )}
      onClick={() => {
        onClick(label);
      }}
    >
      {icon}
      <Typography variant="tag" bold>{label}</Typography>
    </li>
  );
}

RightTab.propTypes = {
  activeTab: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string
};

RightTab.defaultProps = {
  className: '',
  label: ''
};

function Tabs({
  children,
  className,
  headClassName,
  tabClassName,
  extraInfo
}) {
  children = Array.isArray(children) ? children : [children];
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  return (
    <div
      className={cn(
        "custom-right-tabs",
        className
      )}
    >
      <div className="custom-right-tabs_content-wrapper">
        {children.map((child) => (
          child.props.label === activeTab
            ? child
            : null
        ))}
      </div>
      <div
        className={cn(
          "custom-right-tabs_list-wrapper",
          headClassName
        )}
      >
        <div>{extraInfo}</div>
        <ol className="custom-right-tabs_list">
          {children.map(({ props: { label, icon } }) => (
            <RightTab
              activeTab={label === activeTab}
              key={String(label)}
              icon={icon}
              label={String(label)}
              onClick={setActiveTab}
              className={tabClassName}
            />
          ))}
        </ol>
      </div>
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object)
  ]).isRequired,
  extraInfo: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object)
  ]),
  className: PropTypes.string,
  tabClassName: PropTypes.string,
  headClassName: PropTypes.string
};

Tabs.defaultProps = {
  className: '',
  tabClassName: '',
  headClassName: '',
  extraInfo: null
};

export default Tabs;
