import type { JurisdictionCellData } from './useJurisdictionCellData.types';
import type { TableNewsSource } from '../../../NewsSources.types';

import type * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useJurisdictionCellData = (row: uiLib.Row): JurisdictionCellData => {
  const jurisdictionsValue = row.jurisdictions as TableNewsSource['jurisdictions'];
  const jurisdictionsText = jurisdictionsValue.map(item => item?.short_name || item?.name);
  const formattedData = useMemo(() => ({ jurisdictionsText }), [jurisdictionsText]);

  return {
    formattedData
  };
};
