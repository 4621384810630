import type { CategorySplitModalData } from './useCategorySplitModalData.types';
import type { CategoryEditModalFormattedData } from './useCategorySplitModalFormattedData.types';
import type { useCategorySplitModalMutations } from './useCategorySplitModalMutations';

import * as constants from '../CategorySplitModal.constants';

import { useMemo } from 'react';

export const useCategorySplitModalFormattedData = ({
  localState,
  mutations
}: {
  localState: CategorySplitModalData['localState'];
  mutations: ReturnType<typeof useCategorySplitModalMutations>;
}): CategoryEditModalFormattedData =>
  useMemo(
    () => ({
      isSubmitButtonDisabled: !localState.docCaiCategory,
      isLoading: mutations.splitCaiCategory.isLoading,
      isMetaCategoryDisabled: localState.docCaiCategory?.type !== constants.CREATED_TYPE
    }),
    [localState.docCaiCategory, mutations.splitCaiCategory.isLoading]
  );
