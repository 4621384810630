import type { FC } from 'react';
import type { BulkEditModalProps } from './BulkEditModal.types';

import React from 'react';

import * as elements from './elements';
import * as uiLib from '@compliance.ai/web-components';

import {
  useBulkEditModalData,
  useBulkEditModalHandlers,
  useBulkEditModalMutations,
  useBulkEditModalQueries,
  useBulkEditModalQueriesFormattedData,
  useBulkEditModalFormattedData
} from './hooks';

import './_bulk-edit-modal.scss';

export const BulkEditModal: FC<BulkEditModalProps> = ({
  selectedDocumentIds,
  onSubmit,
  onClose
}) => {
  const { localState, localActions, reduxState } = useBulkEditModalData();

  const handlers = useBulkEditModalHandlers({
    localState,
    localActions
  });

  const queries = useBulkEditModalQueries({
    props: {
      selectedDocumentIds
    }
  });

  const mutations = useBulkEditModalMutations({
    props: {
      selectedDocumentIds,
      onSubmit,
      onClose
    },
    localState,
    reduxState,
    queries
  });

  const queriesFormattedData = useBulkEditModalQueriesFormattedData({
    queries
  });

  const formattedData = useBulkEditModalFormattedData({
    props: {
      selectedDocumentIds
    },
    localState,
    queries,
    mutations
  });

  return (
    <uiLib.Modal
      isOpen
      title={formattedData.modalTitle}
      onClose={onClose}
      onSubmit={mutations.updateDocumentDataMutation.mutate}
      withFooter
      secondaryButtonType={uiLib.BUTTON_TYPES.SECONDARY}
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Apply edits"
      classNames={{
        title: 'bulk-edit-modal__title',
        container: 'bulk-edit-modal__container',
        content: 'bulk-edit-modal__content',
        footer: 'bulk-edit-modal__footer'
      }}
    >
      <div className="bulk-edit-modal__rules-container">
        {formattedData.isUpdating && <uiLib.Loader withoutBackground={false} />}
        {formattedData.isRulesListShown && (
          <div className="bulk-edit-modal__rules-list">
            {localState.rules.map(rule => (
              <elements.BulkEditModalRule
                key={rule.id}
                action={rule.action}
                onActionChange={handlers.handleRuleActionChange(rule.id)}
                attribute={rule.attribute}
                onAttributeChange={handlers.handleRuleAttributeChange(rule.id)}
                value={rule.value}
                onValueChange={handlers.handleRuleValueChange(rule.id)}
                onRuleRemove={handlers.handleRuleRemove(rule.id)}
                selectedDocumentLabelsOptions={queriesFormattedData.selectedDocumentLabelsOptions}
                areSelectedDocumentLabelsLoaded={formattedData.areSelectedDocumentLabelsLoaded}
                areCommandsLoaded={formattedData.areCommandsLoaded}
                availableBulkEditActions={formattedData.availableBulkEditActions[rule.id]}
              />
            ))}
          </div>
        )}
        <uiLib.Button
          type={uiLib.BUTTON_TYPES.LINK}
          onClick={handlers.handleRuleAdd}
          startIcon={<uiLib.IconAdd className="bulk-edit-modal__add-rule-button-icon" />}
        >
          Add rule
        </uiLib.Button>
      </div>
    </uiLib.Modal>
  );
};
