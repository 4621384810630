import {
  CommandRequestFromResponse,
  CommandRequestType
} from 'shared/features/commands/commands.api.types';
import type { ExternalApiCrawlFromResponse } from 'shared/features/externalApiCrawl/externalApiCrawl.api.types';
import type { UserFromResponse } from 'shared/features/user/user.api.types';

import * as errorUtils from 'shared/utils/errors';
import * as commandRequestsConstants from 'admin/constants/commandRequests';
import * as commandApi from 'shared/features/commands/commands.apiv2';
import * as externalApiCrawlApi from 'shared/features/externalApiCrawl/externalApiCrawl.api';
import * as usersApi from 'shared/features/user/user.apiV2';
import * as externalApiCrawlConstants from 'shared/features/externalApiCrawl/externalApiCrawl.constants';

export const useCommandRequestsFiltersAsyncLoaders = () => {
  const handleCommandsFetch = async (inputString: string) => {
    try {
      const response = await commandApi.fetchCommandsData({ name: inputString });

      return response.map((command: CommandRequestType) => ({
        label: command.name,
        value: command.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleCommandRequestsStatusFetch = async (inputString: string) => {
    try {
      const response = await commandApi.fetchCommandRequestsStatusData({ name: inputString });

      return response.items.map((status: CommandRequestFromResponse['status']) => ({
        label: status,
        value: status
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleSpiderNamesFetch = async (inputString: string) => {
    try {
      const response = await externalApiCrawlApi.api_getExternalApiCrawls({
        spider_name: inputString,
        limit: externalApiCrawlConstants.SPIDER_NAMES_COUNT_PER_REQUEST,
        light_version: true
      });

      return response.items.map((item: ExternalApiCrawlFromResponse) => ({
        label: item.spider_name,
        value: item.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleUserEmailFetch = async (inputString: string) => {
    try {
      const response = await usersApi.fetchGetAllUsers({ email: inputString });
      return response.users.flatMap((item: UserFromResponse) => {
        if (!inputString || item.email.includes(inputString)) {
          return {
            label: item.email,
            value: item.id
          };
        } else {
          return [];
        }
      });
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    [commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.TYPE]: handleCommandsFetch,
    [commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.SPIDER_NAMES]: handleSpiderNamesFetch,
    [commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER_ID]: handleUserEmailFetch,
    [commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS]: handleCommandRequestsStatusFetch
  };
};
