import { fetch } from 'fetch';

export function api_fetchContributorStatistics(annotation_task_id, data) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}/contributor_review_breakdown`,
    params: data,
    dataType: 'ContributorStatistics',
    method: 'GET'
  });
}

export function api_fetchAnnotationTasksByUser(topic_group_id, user_id) {
  return fetch({
    url: `/annotation_task_groups/topic_annotations/${topic_group_id}`,
    params: {
      user_id,
      include_aggregated_annotation_info: true
    },
    dataType: 'AnnotationTasks',
    method: 'GET'
  });
}

export function api_editAggregatedAnnotation(aggregated_annotation_id, data) {
  return fetch({
    url: `/aggregated_annotations/${aggregated_annotation_id}`,
    method: 'POST',
    dataType: 'aggregatedAnnotation',
    params: data
  });
}

export function api_requestAggregatedAnnotations(topic_id, data) {
  return fetch({
    url: `/aggregated_annotations/${topic_id}`,
    method: 'GET',
    dataType: 'aggregatedAnnotation',
    params: data
  });
}

export function api_editAggregatedAnnotationResearch(aggregated_annotation_id, data) {
  return fetch({
    url: `/aggregated_annotations/research/${aggregated_annotation_id}`,
    method: 'POST',
    dataType: 'aggregatedAnnotation',
    params: data
  });
}

export function api_fetchActiveTopicAnnotationModelIds(topic_id) {
  return fetch({
    url: '/topic_annotation_models',
    method: 'GET',
    dataType: 'modelIds',
    params: { topic_id }
  });
}

export function api_editAggregatedAnnotationGold(aggregated_annotation_id, data) {
  return fetch({
    url: `/aggregated_annotations/gold/${aggregated_annotation_id}`,
    params: data,
    dataType: 'aggregatedAnnotation',
    method: 'POST'
  });
}

export function api_fetchAllAnnotationTasks() {
  return fetch({
    url: '/annotation_tasks',
    dataType: 'annotation_task',
    method: 'GET'
  });
}

export function api_createAnnotationTaskFromParams(data) {
  return fetch({
    url: '/annotation_tasks',
    method: 'POST',
    dataType: 'annotation_task',
    params: data
  });
}

export function api_updateAnnotationTaskWithParams(annotation_task_id, data) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}`,
    method: 'POST',
    dataType: 'annotation_task',
    params: data
  });
}

export function api_deleteAnnotationTaskWithParams(annotation_task_id, data) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}`,
    method: 'DELETE',
    dataType: 'annotation_task',
    params: data
  });
}

export function api_fetchAnnotationJob(annotation_task_id) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}/annotation_jobs/pop`,
    dataType: 'AnnotationJob',
    method: 'GET'
  });
}

export function api_createAnnotationsForJob(annotation_task_id, annotation_job_id, data) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}/annotation_jobs/${annotation_job_id}`,
    method: 'POST',
    dataType: 'Annotations',
    params: data
  });
}

export function api_fetchAnnotationStatistics(annotation_task_id, data) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}/topic_annotations`,
    method: 'GET',
    dataType: 'AnnotationStatistics',
    params: data
  });
}

export function api_fetchAllAnnotationJobs(annotation_task_id, data) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}/annotation_jobs`,
    method: 'GET',
    dataType: 'AnnotationJobs',
    params: data
  });
}

export function api_fetchAnnotationJobById(annotation_task_id, annotation_job_id) {
  return fetch({
    url: `/annotation_tasks/${annotation_task_id}/annotation_jobs/${annotation_job_id}`,
    dataType: 'AnnotationTasks',
    method: 'GET'
  });
}

export function api_fetchAllSkippedAnnotations() {
  return fetch({
    url: '/annotation_jobs/skipped',
    dataType: 'Annotations',
    method: 'GET'
  });
}

export function api_fetchAnnotationTaskTopicGroups(groupId) {
  return fetch({
    url: `/annotation_task_groups${groupId ? `/${groupId}` : ''}`,
    method: 'GET',
    dataType: 'annotation_task'
  });
}

export function api_postAnnotationTaskTopicGroup(groupId, data) {
  return fetch({
    url: `/annotation_task_groups${groupId ? `/${groupId}` : ''}`,
    method: 'POST',
    dataType: 'AnnotationTaskGroups',
    params: data
  });
}

export function api_deleteAnnotationTaskTopicGroup(groupId) {
  return fetch({
    url: `/annotation_task_groups/${groupId}`,
    dataType: 'AnnotationTasks',
    method: 'DELETE'
  });
}

export function api_fetchOnboardingResults() {
  return fetch({
    url: '/annotation_task_groups/user_statistics',
    dataType: 'OnboardingResults',
    method: 'GET'
  });
}
