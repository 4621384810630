import moment from 'moment';
import {
  REQUEST_ALL_SUBSCRIPTIONS,
  RECEIVE_ALL_SUBSCRIPTIONS,
  REQUEST_SUBSCRIPTIONS,
  RECEIVE_SUBSCRIPTIONS
} from './subscriptions.actions';


export const getInitialSubscriptionState = () => ({
  isFetching: false,
  isReady: false,
  subscriptions: []
});

export const subscriptions = (state = getInitialSubscriptionState(), action) => {
  switch (action.type) {
    case REQUEST_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_SUBSCRIPTIONS: {
      const decoratedSubscriptions = action.subscriptions.map(subscription => {
        subscription.expirationDate = moment(subscription.expiration_date).format('l');
        if (subscription.latest) {
          subscription.within10dayofExpiration = moment().isAfter(
            moment(subscription.expirationDate).subtract(10, 'days')
          );
        }
        subscription.nextBillDate = subscription.next_bill_date
          ? moment.unix(subscription.next_bill_date).format('l')
          : '';
        return subscription;
      });
      return {
        ...state,
        isFetching: false,
        isReady: true,
        subscriptions: decoratedSubscriptions
      };
    }
    default:
      return state;
  }
};

export const getInitialAllSubscriptionState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  subscriptions: []
});

export const all_subscriptions = (state = getInitialAllSubscriptionState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        subscriptions: action.all_subscriptions,
        updated: false
      };
    default:
      return state;
  }
};
