import type {
  FetchDocumentCategoryResponse,
  MetaCategoryFromResponse
} from 'shared/features/categories/metaCategories/metaCategories.api.types';

import * as constants from './useDocumentFiltersAsyncLoaders.constants';

export const createDocTypeDropdownOptions = (response: FetchDocumentCategoryResponse) => [
  ...response?.items.map((metaCategory: MetaCategoryFromResponse) => ({
    label: metaCategory.name,
    options: metaCategory?.cai_categories?.map(category => ({
      label: category.name,
      value: category.id
    }))
  })),
  constants.UNMAPPED_ELEMENT
];
