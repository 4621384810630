import * as uiLib from '@compliance.ai/web-components';

export const DATE_FILTER_ALLOWED_MODIFIERS = [
  uiLib.DATE_MODIFIERS.BETWEEN,
  uiLib.DATE_MODIFIERS.ON,
  uiLib.DATE_MODIFIERS.ON_OR_AFTER,
  uiLib.DATE_MODIFIERS.ON_OR_BEFORE,
  uiLib.DATE_MODIFIERS.ON_OR_BEFORE,
  uiLib.DATE_MODIFIERS.TODAY,
  uiLib.DATE_MODIFIERS.YESTERDAY,
  uiLib.DATE_MODIFIERS.TOMORROW
];

export const BOOLEAN_OPTIONS = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];
