import type { ComponentProps } from 'react';
import type { CrawlQAReportsHandlers } from './useCrawlerQAReportsHandlers.types';
import type { CommandRequestFromResponse } from 'shared/features/commands/commands.api.types';
import type { CrawlQAReportsData } from './useCrawlerQAReportsData.types';
import type { CommandRequestSelect } from '../CrawlerQAReports.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constantsCommandRequest from 'admin/constants/commandRequests';
import * as helpers from './useCrawlerQAReportsTableDataSelector.helpers';
import * as constants from '../CrawlerQAReports.constants';

export const useCrawlerQAReportsTableDataSelector = ({
  events,
  reduxState
}: {
  events: {
    onDetailsClick: CrawlQAReportsHandlers['handleDetailsModalOpen'];
  };
  reduxState: CrawlQAReportsData['reduxState'];
}) => {
  return (
    data: Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      results: data.results.map(result => {
        const commandRequest = result as CommandRequestFromResponse;
        return {
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.ID]: commandRequest.id,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.NAME]: commandRequest.name,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.FAILED_DOCS]:
            commandRequest?.failed_docs,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.SUCCEEDED_DOCS]:
            commandRequest?.succeeded_docs,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.CREATED_AT]: {
            value: commandRequest.created_at,
            format: uiLib.DATE_FORMATS.API_DATE,
            passedTimezone: uiLib.TIMEZONES.UTC,
            timezone: uiLib.TIMEZONES.UTC,
            shouldKeepLocalTime: true
          } as uiLib.DateCellValue,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.UPDATED_AT]: {
            value: commandRequest.updated_at,
            format: uiLib.DATE_FORMATS.API_DATE,
            passedTimezone: uiLib.TIMEZONES.UTC,
            timezone: uiLib.TIMEZONES.UTC,
            shouldKeepLocalTime: true
          } as uiLib.DateCellValue,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.TYPE]: commandRequest?.type?.name,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.STATUS]: commandRequest.status,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.USER]:
            reduxState.usersEmails?.[commandRequest?.user_id as string],
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.USER_ID]: commandRequest.user_id,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.UPLOAD_LINK]: {
            href: commandRequest.upload_link,
            tooltip: commandRequest.upload_link?.slice(0, constants.TOOLTIP_LENGTH),
            children: commandRequest.upload_link
          } as uiLib.LinkCellValue,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.QA_URL_FILE_NAME]:
            commandRequest?.qa_url_file_name,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.QA_URL]: {
            href: commandRequest.qa_url,
            tooltip: commandRequest.qa_url?.slice(0, constants.TOOLTIP_LENGTH),
            children: commandRequest.qa_url
          } as uiLib.LinkCellValue,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.PARAMETERS]: {
            children: helpers.formatDetails(
              JSON.stringify(commandRequest.parameters, null, ' '),
              (commandRequest as CommandRequestSelect) || null,
              events.onDetailsClick
            ),
            tooltip: null
          } as uiLib.LinkCellValue,
          [constantsCommandRequest.COMMAND_REQUESTS_ATTRIBUTES.STATUS_DETAILS]: {
            children: helpers.formatDetails(
              commandRequest.status_details ?? '',
              (commandRequest as CommandRequestSelect) || null,
              events.onDetailsClick
            ),
            tooltip: null
          } as uiLib.LinkCellValue
        };
      }),

      count: data.count
    };
  };
};
