import type { EitlLabelsData } from './useEitlLabelsData.types';

import * as constants from '../EitlLabels.constants';
import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useEitlLabelsFiltersConfig = ({
  reduxState,
  formattedData
}: {
  reduxState: EitlLabelsData['reduxState'];
  formattedData: EitlLabelsData['formattedData'];
}): uiLib.Filter[] => {
  return useMemo(
    () => [
      {
        key: constants.EITL_LABELS_FILTER_KEY.CREATED_AT,
        type: uiLib.FILTER_TYPES.DATE,
        label: 'Date created',
        isFixed: true
      },
      {
        key: constants.EITL_LABELS_FILTER_KEY.USER_ID,
        type: uiLib.FILTER_TYPES.SELECT,
        label: 'Created by',
        inputComponentProps: {
          isLoading: reduxState.isOrganizationLoading,
          options: formattedData.orgMembersOptions
        },
        isMulti: false,
        isFixed: true
      }
    ],
    [formattedData.orgMembersOptions, reduxState.isOrganizationLoading]
  );
};
