import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import React, { useState } from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import { COLORS } from 'app/constants/Style';
import TextField from 'app/common/TextField';
import Button from 'app/common/Button';
import Select from 'app/common/Select';
import {
  MANAGE_RSS_ERROR_NAME,
  MANAGE_RSS_ERROR_DOC_FILTER,
  MANAGE_RSS_ERROR_PUBLISHED,
  MANAGE_RSS_ERROR_MISSING_FIELD_CREATOR,
  MANAGE_RSS_ERROR_DOC_FILTER_JSON
} from "admin/constants/errors";

const AddButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const SmallContainer = styled.div`
  padding: 5px 0px;
`;

const ErrorContainer = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(255,90,90,0.05);
  color: ${COLORS.dangerRed};
  border: solid 1px ${COLORS.dangerRed};
  border-radius: 5px;
`;

const FieldGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FieldGroupSeparator = styled.div`
  width: 20px;
`;

const FieldHeader = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #2d2d2d;
  font-weight: bold;
`;

const FieldDivider = styled.hr`
`;

const FieldActions= styled.div`
  text-align: right;
`;

const FieldsTable = styled.table`
  width: 100%;

  thead th {
    color: #B2B2B2;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 0px;
  }

  tbody tr {
    td {
      padding: 5px;
      &:first-of-type {
        padding-left: 0px;
      }
      &:last-of-type {
        padding-right: 0px;
      }
    }
  }
`;

const CustomSettingsRSSManageModal = ({
  name: initialName,
  doc_filter: initialDocFilter,
  published_in_last_x_days: initialPublishedInLastXDays,
  fields: initialFields,
  active: initialActive,
  uuid,
  handleSave
}) => {
  const [showHelp, setShowHelp] = useState(false);
  const [errors, setErrors] = useState([]);
  const [active, setActive] = useState(initialActive);
  const [name, setName] = useState(initialName);
  const [docFilter, setDocFilter] = useState(JSON.stringify(initialDocFilter));
  const [publishedInLastXDays, setPublishedInLastXDays] = useState(initialPublishedInLastXDays ? `${initialPublishedInLastXDays}` : null);
  const [fields, setFields] = useState(initialFields);

  const jsonFields = [
    { title: 'Required Fields', key: 'required' },
    { title: 'Extended Fields', key: 'extended', canManage: true }
  ];

  const onSave = () => {
    const newErrors = [];
    if (!name) {
      newErrors.push(MANAGE_RSS_ERROR_NAME);
    }
    if (!docFilter) {
      newErrors.push(MANAGE_RSS_ERROR_DOC_FILTER);
    }
    if (!publishedInLastXDays) {
      newErrors.push(MANAGE_RSS_ERROR_PUBLISHED);
    }
    jsonFields.forEach(({ key }) => {
      const errorExists = !!fields[key].find(({ doc_attribute, rss_attribute }) => {
        if (!doc_attribute || !rss_attribute) {
          return true;
        }
        return false;
      });
      if (errorExists) {
        newErrors.push(MANAGE_RSS_ERROR_MISSING_FIELD_CREATOR(key));
      }
    });

    let parsedDocFilter = {};
    try {
      parsedDocFilter = JSON.parse(docFilter);
    } catch (Exception) {
      newErrors.push(MANAGE_RSS_ERROR_DOC_FILTER_JSON);
    }

    setErrors(newErrors);
    if (newErrors.length === 0) {
      handleSave({
        name,
        active,
        doc_filter: parsedDocFilter,
        published_in_last_x_days: parseInt(publishedInLastXDays, 10),
        fields
      });
    }
  };

  const toggleShowHelp = () => setShowHelp(!showHelp);

  const setFieldsValue = (fieldsKey, value) => {
    const updatedFields = _.set(_.clone(fields), fieldsKey, value);
    setFields(updatedFields);
  };

  const addFieldsRow = (key) => {
    setFields({
      ...fields,
      [key]: [
        ...fields[key],
        {
          doc_attribute: '',
          rss_attribute: '',
          doc_attribute_map_key: '',
          max_length: ''
        }
      ]
    });
  };

  const removeFieldsRow = (key, indexToRemove) => {
    setFields({
      ...fields,
      [key]: _.filter(fields[key], (ignore, index) => indexToRemove !== index)
    });
  };

  return (
    <>
      {errors.length > 0 && (
        <ErrorContainer>
          {errors.map((error, i) => <div key={i}>{error}</div>)}
        </ErrorContainer>
      )}
      <SmallContainer>
        <SmallContainer><strong>Managing RSS</strong></SmallContainer>
        {uuid && (
          <SmallContainer>{`You are editing the RSS configuration of UUID(${uuid}).`}</SmallContainer>
        )}
        <SmallContainer>
          <Button type="tertiary" onClick={toggleShowHelp}>
            {`${showHelp ? 'Hide' : 'Show'} Help`}
          </Button>
        </SmallContainer>
        {showHelp && (
          <ul>
            <li>
              <strong>Doc Filter:&nbsp;</strong>
              <span>
                This forms the payload that is sent to the API to filter documents for the
                RSS filter. It should be a JSON object of attributes to filter a document.
              </span>
            </li>
            <li>
              <strong>Basic Field Configuration:&nbsp;</strong>
              <span>
                <i>{" doc_attribute "}</i>
                is the attribute on the document to map to the
                <i>{" rss_attribute "}</i>
                that you want on the RSS feed. See the example below where
                we are making the RSS title the document title and the RSS description the penalty:
                <div>{'Document object: { "title": "Hello World", "enforcement": { "penalty": 1 } } '}</div>
                <div>1) doc_attribute=title &amp; rss_attribute=title</div>
                <div>2) doc_attribute=enforcement.penalty &amp; rss_attribute=description</div>
                Note that
                <i>{" doc_attribute "}</i>
                supports
                <a
                  href="https://codeburst.io/javascript-quickie-dot-notation-vs-bracket-notation-333641c0f781"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" dot notation "}
                </a>
              </span>
            </li>
            <li>
              <strong>Advanced Field Configuration:&nbsp;</strong>
              <i>{" doc_attribute_map_key "}</i>
              should be used when you want a document property that has a list of attributes you
              want to display as a comma separated list. Let's take the example when you want to
              list jursidictions as the RSS title from a document like
              <i>{'{ "jursidictions": [{ "short_name": "US" }, { "short_name": "CA" }] }'}</i>
              You could achieve this through:
              <div>
                doc_attribute=jurisdictions &amp;
                doc_attribute_map_key=short_name &amp;
                rss_attribute=title
              </div>
              The RSS item would have the title
              <i>{" US, CA " }</i>
            </li>
          </ul>
        )}
      </SmallContainer>
      <FieldGroup>
        <TextField
          value={name}
          onChange={setName}
          label="Name"
          placeholder="Name"
        />
        <FieldGroupSeparator>&nbsp;</FieldGroupSeparator>
        <Select
          clearable={false}
          value={active}
          onChange={setActive}
          placeholder="Active"
          label="Active"
          options={[
            { value: true, label: 'Active' },
            { value: false, label: 'Inactive' }
          ]}
        />
      </FieldGroup>
      <FieldGroup>
        <TextField
          value={docFilter}
          onChange={setDocFilter}
          label="Doc Filter"
          placeholder="Doc Filter"
        />
        <FieldGroupSeparator>&nbsp;</FieldGroupSeparator>
        <TextField
          value={publishedInLastXDays}
          onChange={setPublishedInLastXDays}
          type="number"
          label="Published in last X days"
          placeholder="Published in last X days"
        />
      </FieldGroup>
      {jsonFields.map(({ title, key, canManage }) => (
        <div key={key}>
          <FieldDivider />
          <FieldHeader>{title}</FieldHeader>
          <FieldsTable>
            <thead>
              <tr>
                <th>Doc Attribute</th>
                <th>RSS Attribute</th>
                <th>Doc attribute map key</th>
                <th>Max Length</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {fields[key].map(({
                doc_attribute,
                rss_attribute,
                doc_attribute_map_key,
                max_length
              }, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <TextField
                        value={doc_attribute}
                        onChange={(newValue) => setFieldsValue(`${key}[${index}].doc_attribute`, newValue)}
                        placeholder="Doc Attribute"
                      />
                    </td>
                    <td>
                      <TextField
                        value={rss_attribute}
                        onChange={(newValue) => setFieldsValue(`${key}[${index}].rss_attribute`, newValue)}
                        placeholder="RSS Attribute"
                      />
                    </td>
                    <td>
                      <TextField
                        value={doc_attribute_map_key}
                        onChange={(newValue) => setFieldsValue(`${key}[${index}].doc_attribute_map_key`, newValue)}
                        placeholder="Doc attribute map key"
                      />
                    </td>
                    <td>
                      <TextField
                        value={max_length}
                        type="number"
                        onChange={(newValue) => setFieldsValue(`${key}[${index}].max_length`, newValue)}
                        placeholder="Max Length"
                      />
                    </td>
                    <td>
                      {canManage && (
                        <AddButton onClick={() => removeFieldsRow(key, index)}>
                          <RemoveCircleOutline />
                        </AddButton>
                      )}
                    </td>
                  </tr>
                );
              })}
              {canManage && (
                <tr>
                  <td colSpan="3">
                    <AddButton onClick={() => addFieldsRow(key)}>
                      <AddCircleOutline />
                      Add
                    </AddButton>
                  </td>
                </tr>
              )}
            </tbody>
          </FieldsTable>
        </div>
      ))}
      <FieldDivider />
      <FieldActions>
        <Button onClick={onSave} type="primary">
          Save
        </Button>
      </FieldActions>
    </>
  );
};

CustomSettingsRSSManageModal.propTypes = {
  active: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  doc_filter: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,

  published_in_last_x_days: PropTypes.number
};

CustomSettingsRSSManageModal.defaultProps = {
  published_in_last_x_days: null
};

export default CustomSettingsRSSManageModal;
