import { createSlice } from "@reduxjs/toolkit";
import {
  INPUT,
  PHONE,
  SELECT,
  CALENDAR,
  BUTTON,
  AUTOCOMPLETE,
  GROUP_OF_FIELDS,
  TEXTFIELD } from "../../../admin/constants/fieldTypes";
import {
  addAdditionalPremiumContentSourceAC,
  changeGeneralTableValuesAC, changePremiumContentSourceAC,
  prepareDataForUpdatingUserAC,
  removePremiumContentSourceAC,
  setGeneralTableOptionsAC
} from "./userModal.helper";
import {
  ACCOUNT_LABEL, ADDRESS_LABEL, DATE_ACTIVATED_LABEL, DATE_CONFIRMED_LABEL, DATE_JOINED_LABEL,
  EMAIL_LABEL, END_DATE_LABEL, INDUSTRY_LABEL, INFO_LABEL, LAST_LOGIN_LABEL, ORGANIZATION_LABEL,
  PAYMENT_TYPE_LABEL, PHONE_LABEL, PLAN_TIME_LABEL, PREMIUM_CONTENT_ACCESS_LABEL,
  PROVIDER_LABEL, RESEND_CONFIRMATION_EMAIL_LABEL, RESET_PASSWORD_LABEL, ROLE_LABEL,
  START_DATE_LABEL, SUBSCRIPTION_LABEL, TEAMS_LABEL, TITLE_LABEL
} from "./labelConstants";

export const reducerName = 'userModal';

export const initialState = {
  initialUserData: {
    address: '',
    company: '',
    created_at: '',
    deleted: false,
    discipline: null,
    email: '',
    enabled: true,
    first_name: '',
    id: null,
    industry: null,
    is_internal_user: null,
    last_name: '',
    level: null,
    phone: '',
    premium_provider_access: [],
    properties: {
      filter: {},
      last_session_end: '',
      session_end: '',
      session_start: '',
      confirmed_at: '',
      date_activated: ''
    },
    roles: [],
    suspended: null,
    suspended_reason: null,
    team_id: null,
    teams: [],
    title: ''
  },
  generalTable: [
    {
      label: INFO_LABEL,
      rows: [
        [
          { label: ORGANIZATION_LABEL, type: INPUT, value: '', responseName: 'company' },
          { label: INDUSTRY_LABEL, type: SELECT, value: '', options: [], responseName: 'industry', optionResponseName: 'all_industries' },
          { label: TITLE_LABEL, type: INPUT, value: '', responseName: 'title' }
        ],
        [
          { label: ROLE_LABEL, type: AUTOCOMPLETE, value: [], options: [], responseName: 'roles', optionResponseName: 'all_roles' }
        ],
        [
          { label: EMAIL_LABEL, type: INPUT, value: '', responseName: 'email' },
          { label: PHONE_LABEL, type: PHONE, value: '', options: [], responseName: 'phone' },
          { label: ADDRESS_LABEL, type: INPUT, value: '', fullWidth: true, responseName: 'address' }
        ]
      ]
    },
    {
      label: ACCOUNT_LABEL,
      rows: [
        [
          { label: DATE_JOINED_LABEL, type: TEXTFIELD, value: 'Not joined', responseName: 'created_at' },
          { label: DATE_CONFIRMED_LABEL, type: TEXTFIELD, value: 'Not confirmed', responseName: 'confirmed_at' },
          { label: DATE_ACTIVATED_LABEL, type: TEXTFIELD, value: 'Not activated', responseName: 'activated_at' },
          { label: LAST_LOGIN_LABEL, type: TEXTFIELD, value: 'No last login date', responseName: 'last_session_end' }
        ],
        [
          {
            label: RESET_PASSWORD_LABEL,
            type: BUTTON
          },
          {
            label: RESEND_CONFIRMATION_EMAIL_LABEL,
            type: BUTTON
          }
        ]
      ]
    },
    {
      label: SUBSCRIPTION_LABEL,
      rows: [
        [
          { label: PLAN_TIME_LABEL, type: SELECT, value: '', options: [], responseName: 'subscription', optionResponseName: 'all_plans' }
        ],
        [
          { label: START_DATE_LABEL, type: CALENDAR, value: new Date(), responseName: 'start_subscription' },
          { label: END_DATE_LABEL, type: CALENDAR, value: new Date(), responseName: 'end_subscription' },
          { label: PAYMENT_TYPE_LABEL, type: SELECT, value: '', options: [], responseName: 'payment_type', optionResponseName: 'all_payment_types' }
        ]
      ]
    },
    {
      label: PREMIUM_CONTENT_ACCESS_LABEL,
      rows: [
        [
          {
            label: PROVIDER_LABEL,
            type: GROUP_OF_FIELDS,
            value: [],
            options: [],
            responseName: 'premium_provider_access',
            optionResponseName: 'all_premium_content_sources',
            desiredProperty: 'name'
          }
        ]
      ]
    },
    {
      label: TEAMS_LABEL,
      rows: [
        [
          {
            type:
            AUTOCOMPLETE,
            value: [],
            options: [],
            responseName: 'teams',
            optionResponseName: 'all_teams',
            desiredProperty: 'name'
          }
        ]
      ]
    }
  ],
  dataForUpdate: {}
};

const userModalReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setInitialUserData: (state, action) => {
      state.initialUserData = action.payload;
    },
    changeGeneralTableValues: (state, action) => {
      state.generalTable = changeGeneralTableValuesAC(state.generalTable, action.payload);
    },
    setGeneralTableOptions: (state, action) => {
      state.generalTable = setGeneralTableOptionsAC(state.generalTable, action.payload);
    },
    prepareDataForUpdatingUser: (state) => {
      state.dataForUpdate = prepareDataForUpdatingUserAC(state.initialUserData, state.generalTable);
    },
    addAdditionalPremiumContentSource: (state) => {
      state.generalTable = addAdditionalPremiumContentSourceAC(state.generalTable);
    },
    removePremiumContentSource: (state, action) => {
      state.generalTable = removePremiumContentSourceAC(state.generalTable, action.payload);
    },
    changePremiumContentSource: (state, action) => {
      state.generalTable = changePremiumContentSourceAC(state.generalTable, action.payload);
    }
  }
});


export default userModalReducer.reducer;
export const {
  setInitialUserData,
  changeGeneralTableValues,
  setGeneralTableOptions,
  prepareDataForUpdatingUser,
  addAdditionalPremiumContentSource,
  removePremiumContentSource,
  changePremiumContentSource
} = userModalReducer.actions;
