import runNowTypes from './runNowTypes';
import { checkProductionEnv } from '../utils/checkENVIRONMENT';

// there are all possible crawl states
export const crawlStates = {
  // crawl field 'status' (backend statuses, string)
  construction: 'construction',
  // crawl field 'is_active' (backend field, boolean)
  active: 'active',
  deactivate: 'deactivate',
  // only FE states
  runNow: 'run_now',
  cancelRun: 'cancel_run',
  terminateRun: 'terminate_run',
  stopRun: 'stop_run',
  autoQA: 'auto_qa',
  // statuses
  idle: 'idle',
  queued: 'queued',
  msgQueued: 'msg_queued',
  running: 'running',
  timed_out: 'timed_out',
  failed: 'failed',
  inProgress: 'in_progress',
  commandRequest: 'command_request',
  sourceChanged: 'source_changed'
};

// there is shown which states could be used / displayed to user if key status is active
/*
  key - current active status
  value - state actions that could be used / displayed
*/
export const crawlStatesTree = {
  [crawlStates.idle]: checkProductionEnv()
    ? [crawlStates.deactivate, crawlStates.runNow]
    : [crawlStates.deactivate, crawlStates.runNow, crawlStates.construction],
  [crawlStates.running]: [crawlStates.stopRun],
  [crawlStates.queued]: [crawlStates.stopRun],
  [crawlStates.msgQueued]: [crawlStates.stopRun],
  [crawlStates.inProgress]: [crawlStates.stopRun],
  [crawlStates.timed_out]: checkProductionEnv()
    ? [crawlStates.deactivate, crawlStates.runNow]
    : [crawlStates.deactivate, crawlStates.runNow, crawlStates.construction],
  [crawlStates.deactivate]: checkProductionEnv()
    ? [crawlStates.active, crawlStates.autoQA]
    : [crawlStates.active, crawlStates.construction, crawlStates.autoQA],
  [crawlStates.failed]: checkProductionEnv()
    ? [crawlStates.active, crawlStates.deactivate]
    : [crawlStates.active, crawlStates.construction, crawlStates.deactivate],
  [crawlStates.construction]: [crawlStates.active, crawlStates.deactivate, crawlStates.autoQA],
  [crawlStates.commandRequest]: [crawlStates.stopRun],
  [crawlStates.autoQA]: [crawlStates.stopRun],
  [crawlStates.sourceChanged]: [
    crawlStates.deactivate,
    crawlStates.runNow,
    crawlStates.construction,
    crawlStates.stopRun
  ]
};

export const SOURCE_CHANGED_ENABLED_OPTIONS = [crawlStates.stopRun];

export enum CRAWLER_TYPE {
  FEEDLY = 'feedly',
  CRAWLBOT = 'crawlbot',
  OSAPI = 'osapi',
  CODEBOT = 'codebot',
  MOZENDA = 'mozenda',
  UNIFIED = 'unified',
  EMAIL = 'email',
  CUSTOM = 'custom',
  DATA_ADMIN = 'data_admin',
  PREMIUM = 'premium'
}

export default {
  [CRAWLER_TYPE.FEEDLY]: {
    value: CRAWLER_TYPE.FEEDLY,
    label: 'feedly',
    runNowMode: [runNowTypes.archive_feedly, runNowTypes.pub_date_interval_feedly]
  },
  [CRAWLER_TYPE.CRAWLBOT]: { value: 'crawlbot', label: 'crawlbot' },
  [CRAWLER_TYPE.OSAPI]: {
    value: CRAWLER_TYPE.OSAPI,
    label: 'osapi',
    runNowMode: [runNowTypes.archive_osapi]
  },
  [CRAWLER_TYPE.CODEBOT]: {
    value: CRAWLER_TYPE.CODEBOT,
    label: 'codebot',
    runNowMode: [runNowTypes.archive_codebot]
  },
  [CRAWLER_TYPE.MOZENDA]: { value: 'mozenda', label: 'mozenda' },
  [CRAWLER_TYPE.UNIFIED]: {
    value: CRAWLER_TYPE.UNIFIED,
    label: 'unified',
    runNowMode: [runNowTypes.archive, runNowTypes.pub_date_interval]
  },
  [CRAWLER_TYPE.EMAIL]: {
    value: CRAWLER_TYPE.EMAIL,
    label: 'email',
    runNowMode: [
      runNowTypes.archive_email
      // runNowTypes.pub_date_interval
    ]
  },
  [CRAWLER_TYPE.CUSTOM]: {
    value: CRAWLER_TYPE.CUSTOM,
    label: 'custom',
    runNowMode: [runNowTypes.archive_custom]
  },
  [CRAWLER_TYPE.DATA_ADMIN]: {
    value: CRAWLER_TYPE.DATA_ADMIN,
    label: 'data_admin',
    runNowMode: [runNowTypes.upload_doc_spreadsheet]
  },
  [CRAWLER_TYPE.PREMIUM]: {
    value: CRAWLER_TYPE.PREMIUM,
    label: 'premium',
    runNowMode: [runNowTypes.upload_premium_docs]
  }
};

export const CRAWL_ACTIVITY_TYPE = [true, false];
