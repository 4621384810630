import type { CrawlStatusSelectData } from './useCrawlStatusSelectData.types';

import React, { useState } from 'react';

export const useCrawlStatusSelectData = (): CrawlStatusSelectData => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const localState: CrawlStatusSelectData['localState'] = {
    isDropdownOpen
  };

  const localActions: CrawlStatusSelectData['localActions'] = {
    setIsDropdownOpen
  };

  return {
    localState,
    localActions
  };
};
