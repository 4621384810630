import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Button, Form, FormGroup, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Loader from 'app/common/Loader';
import {
  fetchAllOrganization as reduxFetchAllOrganization,
  deleteOrganizations as reduxDeleteOrganization
} from 'shared/features/organizations/organizations.actions';
import PopupInfo from '../components/PopupInfo';
import { popupName } from '../constants/popupInfoConst';
import ROUTES from '../constants/routes';
import {useDidMount, withAdminPermissions} from "../utils/hooks";

const initialPopupState = { id: null, show: false, action: null };

const Organizations = ({
  haveEditAccess,
  allOrganizations,
  fetchAllOrganizations,
  deleteOrganization
}) => {
  const {
    isReady = false,
    isUpdated = false,
    isDeleting = false,
    organizations = []
  } = allOrganizations;

  const [popup, setPopup] = useState(initialPopupState);

  useDidMount(() => {
    fetchAllOrganizations();
  });

  useEffect(() => {
    if (isUpdated && !isDeleting) {
      fetchAllOrganizations();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, isDeleting]);

  const confirmDeleteOrganization = (event, id) => {
    event.preventDefault();

    setPopup({
      id,
      show: true,
      action: popupName.deleteOrganizationPopup
    });
  };

  const completeDeleteOrganization = () => {
    if (popup.id) {
      deleteOrganization(popup.id);
      setPopup(initialPopupState);
    }
  };

  const renderOrganizationList = () => {
    if (!isReady) {
      return <Loader />;
    }

    return (
      <Table striped condensed hover>
        <tbody>
          {!isEmpty(organizations)
            ? organizations.map((organization) => {
              return (
                <tr key={organization.id}>
                  <td className="all_org_table_td">
                    <h1>
                      <b>{organization.name}</b>
                    </h1>
                    <p>
                      <b>URL: </b>
                      {get(organization, ['properties', 'url', ''])}
                    </p>
                    <p>
                      <b>Address: </b>
                      {get(organization, ['properties', 'address', ''])}
                    </p>
                    <p>
                      <b>Phone: </b>
                      {get(organization, ['properties', 'phone', ''])}
                    </p>
                  </td>
                  <td className="allorg-team-id all_org_table_td">
                    <p>
                      <b>
                        {haveEditAccess ? (
                          <Link
                            to={`${ROUTES.manageOrgTeams}?org_id=${organization.id}`}
                          >
                            Teams
                          </Link>
                        ) : (
                          'Teams'
                        )}
                      </b>
                    </p>
                    <p>
                      <b>ID: </b>
                      {organization.id}
                    </p>
                  </td>
                  <td className="all_org_table_td">
                    {haveEditAccess && (
                    <p>
                      <Link
                        to={`${ROUTES.editOrganizations}?org_id=${organization.id}`}
                      >
                        <CreateIcon />
                      </Link>
                      <DeleteIcon
                        onClick={(e) => confirmDeleteOrganization(e, organization.id)
                            }
                      />
                    </p>
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="all_organizations_view_page">
      <h1>Organizations</h1>
      <Form horizontal>
        <FormGroup bsSize="small">
          <Col sm={12}>
            {haveEditAccess && (
              <Link to={ROUTES.createOrganizations}>
                <Button bsStyle="primary">ADD</Button>
              </Link>
            )}
          </Col>
        </FormGroup>
      </Form>
      {renderOrganizationList()}
      {popup.show && (
        <PopupInfo
          show={popup.show}
          onHide={() => {
            setPopup(initialPopupState);
          }}
          popupName={popup.action}
          onSecondBtn={completeDeleteOrganization}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllOrganizations: () => {
      return dispatch(reduxFetchAllOrganization());
    },
    deleteOrganization: (org_id) => {
      dispatch(reduxDeleteOrganization(org_id));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    allOrganizations: state.all_organizations
  };
};

Organizations.propTypes = {
  allOrganizations: PropTypes.shape({
    isReady: PropTypes.bool,
    isUpdated: PropTypes.bool,
    isDeleting: PropTypes.bool,
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        url: PropTypes.string,
        address: PropTypes.string,
        phone: PropTypes.string
      })
    )
  }).isRequired,
  fetchAllOrganizations: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired
};

export default withAdminPermissions(connect(mapStateToProps, mapDispatchToProps)(Organizations));
