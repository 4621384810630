import React from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'react-bootstrap';
import AppNotificationsModal from './AppNotificationsModal';
import {withAdminPermissions} from "../../utils/hooks";

class AppNotifications extends React.Component {
  state = {
    showModal: false,
    editNotificationId: null
  }

  render() {
    const { haveEditAccess, current_user: { appNotifications } } = this.props;

    return (
      <div ref="main_container" className="thinBtn">
        <h1>App Notifications</h1>
        {haveEditAccess && (
          <div className="boldNotification">
            <p className="boldNotification_Text">New app notification</p>
            <Button
              bsStyle="primary"
              onClick={() => this.setState({ showModal: true, editNotification: null })}
            >
              Create
            </Button>
          </div>
        )}
        <Table striped condensed hover className="commandTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Enabled</th>
              <th>Title</th>
              <th>Notification Type</th>
              <th>Configurations</th>
              <th>Created At</th>
              <th>Expires At</th>
            </tr>
          </thead>
          <tbody>
            {appNotifications.map(appNotification => (
              <tr
                key={appNotification.id}
                onClick={() => {
                  if (haveEditAccess) {
                    this.setState({
                      showModal: true,
                      editNotification: appNotification
                    });
                  }
                }}
              >
                <td>{appNotification.id}</td>
                <td>{appNotification.enabled ? 'Yes' : 'No'}</td>
                <td>{appNotification.title}</td>
                <td>{appNotification.notification_type}</td>
                <td>
                  {`Video Link: ${appNotification.video_link}`}
                  <br />
                  {`External Link: ${appNotification.external_link}`}
                  <br />
                  {`External Link Text: ${appNotification.external_link_text}`}
                </td>
                <td>{appNotification.created_at}</td>
                <td>{appNotification.expires_at}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {this.state.showModal
        && (
          <AppNotificationsModal
            editingAppNotification={this.state.editNotification}
            closeModal={() => this.setState({ showModal: false, editNotificationId: null })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    current_user: state.current_user
  };
};

export default withAdminPermissions(connect(mapStateToProps, {})(AppNotifications));
