import _ from 'lodash';
import {
  REQUEST_TERM_SAMPLING_GROUPS,
  RECEIVE_TERM_SAMPLING_GROUPS,
  CREATE_TERM_SAMPLING_GROUP,
  TERM_SAMPLING_GROUP_CREATED,
  UPDATE_TERM_SAMPLING_GROUP,
  TERM_SAMPLING_GROUP_UPDATED
} from './term.actions';

export const getInitialTermSamplingGroupsState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const term_sampling_groups = (state = getInitialTermSamplingGroupsState(), action) => {
  switch (action.type) {
    case REQUEST_TERM_SAMPLING_GROUPS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_TERM_SAMPLING_GROUPS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.term_sampling_groups
      };
    default:
      return state;
  }
};

export const getInitialTermSamplingGroupState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  term_sampling_group: {}
});

export const term_sampling_group = (state = getInitialTermSamplingGroupState(), action) => {
  switch (action.type) {
    case CREATE_TERM_SAMPLING_GROUP:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case TERM_SAMPLING_GROUP_CREATED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        term_sampling_group: _.get(action, 'term_sampling_group', state.term_sampling_group)
      };

    case UPDATE_TERM_SAMPLING_GROUP:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case TERM_SAMPLING_GROUP_UPDATED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        term_sampling_group: _.get(action, 'term_sampling_group', state.term_sampling_group)
      };
    default:
      return state;
  }
};
