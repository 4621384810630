import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withHistory } from 'admin/utils/hooks';
import { compose } from 'redux';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes, { arrayOf } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_TIME_FORMAT } from '../../../../../../../constants/projectSettings';
import { fetchCurrentUser, receiveCurrentUser } from 'shared/features/user/user.actions';
import {
  createCommentAC,
  addInternalComment
} from 'shared/features/crawlerSettings/crawlerComments/crawlerComments.actions';
import { colors } from 'admin/components/MaterialComponents/colors/colors';
import ButtonGroup from 'admin/components/MaterialComponents/Buttons/ButtonGroup/ButtonGroup';
import MTextField from 'admin/components/MaterialComponents/TextField/mTextField';

const SAVE_COMMENT = 'Save Comment';
const CLEAR_COMMENT = 'Clear';
const EDIT_COMMENT = 'Edit';
const DELETE_COMMENT = 'Delete';

const useStyles = makeStyles({
  commentsWrapper: {},
  addComment: {},
  clearComment: {},
  deleteComment: {},
  editComment: {},
  commentDetails: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonGroupWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textField: {
    minHeight: 120,
    border: `1px solid ${colors.GREY_LIGHT}`,
    marginTop: 12,
    '& .jurispect-MuiInputBase-input': {
      fontSize: 14
    }
  },
  wrapperAllComments: {
    width: '100%',
    border: `0.5px solid ${colors.GREY_LIGHT}`
  },
  wrapperComment: {
    padding: 10,
    width: '100%',
    borderBottom: `0.5px solid ${colors.GREY_LIGHT}`,
    '&:last-child': {
      border: 0
    }
  },
  wrapperCommentHead: {
    paddingBottom: 10
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingRight: 10
  },
  date: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.GREY_LIGHT
  },
  refererName: {
    fontSize: 14,
    color: colors.BLUE_MAIN_DISABLED,
    paddingRight: 10
  },
  comment: {
    fontSize: 14,
    fontWeight: 400
  }
});

const Comments = ({
  comments,
  match: {
    params: { id }
  },
  user: { email, first_name, last_name },
  reduxFetchCurrentUser,
  reduxCreateCommentAC,
  reduxReceiveCurrentUser,
  reduxAddInternalComment
}) => {
  const [currentCommentValue, setCurrentCommentValue] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const classes = useStyles();
  useEffect(() => {
    if (!email) {
      reduxFetchCurrentUser().then(res => {
        reduxReceiveCurrentUser(res);
        setCurrentUser(`${res.user.first_name} ${res.user.last_name}`);
      });
    } else {
      setCurrentUser(`${first_name} ${last_name}`);
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first_name, last_name, email]);

  const saveComment = () => {
    const data = {
      crawl_id: id,
      comment: currentCommentValue,
      user: currentUser,
      referer: ''
    };
    if (isNaN(+id)) {
      const newDate = new Date();
      data.updated_at = newDate;
      data.created_at = newDate;
      reduxAddInternalComment(data);
      setCurrentCommentValue('');
    } else if (currentCommentValue.trim().length) {
      reduxCreateCommentAC(data).then(() => {
        setCurrentCommentValue('');
      });
    }
  };
  const textEditButtons = [
    {
      label: SAVE_COMMENT,
      classes: classes.addComment,
      onClick: saveComment,
      icon: <CheckIcon className={classes.typo} />
    },
    {
      label: CLEAR_COMMENT,
      classes: classes.clearComment,
      onClick: () => setCurrentCommentValue(''),
      icon: <CloseIcon className={classes.typo} />
    }
  ];
  const commentButtons = [
    { label: EDIT_COMMENT, classes: classes.editComment, onClick: () => {} },
    { label: DELETE_COMMENT, classes: classes.deleteComment, onClick: () => {} }
  ];

  const mountCommentsTable = () => {
    return (
      <div className={comments.length ? classes.wrapperAllComments : null}>
        {comments.map(comment => {
          return (
            <div key={comment.comment} className={classes.wrapperComment}>
              <div className={classes.wrapperCommentHead}>
                <span className={classes.userName}>{comment.user}</span>
                <span className={classes.date}>
                  {moment(comment.created_at).format(DATE_TIME_FORMAT)}
                </span>
              </div>
              <div className={classes.commentDetails}>
                <div>
                  <span className={classes.comment}>{comment.comment}</span>
                </div>
                <div className={classes.commentButtonGroup}>
                  <ButtonGroup buttons={commentButtons} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.mainWrapper}>
      {mountCommentsTable()}
      <MTextField
        value={currentCommentValue}
        onChange={s => setCurrentCommentValue(s.target.value)}
        className={classes.textField}
        fullWidth
        placeholder="Add comment..."
      />
      <div>
        <ButtonGroup buttons={textEditButtons} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  comments: state.crawlerComments.comments,
  user: state.current_user.user
});

Comments.propTypes = {
  comments: arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string,
  reduxFetchCurrentUser: PropTypes.func,
  reduxCreateCommentAC: PropTypes.func,
  reduxReceiveCurrentUser: PropTypes.func,
  reduxAddInternalComment: PropTypes.func.isRequired
};

Comments.defaultProps = {
  id: '',
  reduxFetchCurrentUser: () => {},
  reduxCreateCommentAC: () => {},
  reduxReceiveCurrentUser: () => {}
};

export default compose(
  withHistory,
  connect(mapStateToProps, {
    reduxCreateCommentAC: createCommentAC,
    reduxReceiveCurrentUser: receiveCurrentUser,
    reduxFetchCurrentUser: fetchCurrentUser,
    reduxAddInternalComment: addInternalComment
  })
)(Comments);
