import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'paymentTypes';

export const initialState = {
  payment_types: []
};

const paymentTypesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setPaymentTypes: (state, action) => {
      state.payment_types = action.payload;
    }
  }
});


export default paymentTypesReducer.reducer;
export const {
  setPaymentTypes
} = paymentTypesReducer.actions;
