import type { BulkEditModalRuleProps } from '../BulkEditModalRule.types';
import type { BulkEditModalRuleHandlers } from './useBulkEditModalRuleHandlers.types';

import * as modalConstants from '../../../BulkEditModal.constants';
import * as uiLib from '@compliance.ai/web-components';

import { EitlLabelsSelect } from 'admin/components/EitlLabelsSelect';

export const useBulkEditModalRuleValueInput = ({
  props,
  events
}: {
  props: Pick<
    BulkEditModalRuleProps,
    | 'action'
    | 'attribute'
    | 'value'
    | 'onValueChange'
    | 'selectedDocumentLabelsOptions'
    | 'areSelectedDocumentLabelsLoaded'
    | 'areCommandsLoaded'
  >;
  events: {
    onEitlLabelCreate: BulkEditModalRuleHandlers['handleEitlLabelCreate'];
  };
}) => {
  if (!props.action || !props.attribute) {
    return null;
  }

  switch (props.attribute) {
    case modalConstants.BULK_EDIT_ATTRIBUTES.EITL_LABEL: {
      if (
        [modalConstants.BULK_EDIT_ACTIONS.ADD, modalConstants.BULK_EDIT_ACTIONS.EDIT].includes(
          props.action
        )
      ) {
        return (
          <EitlLabelsSelect
            value={props.value as uiLib.SelectProps['value']}
            onChange={props.onValueChange as uiLib.SelectProps['onChange']}
            onEitlLabelCreate={events.onEitlLabelCreate}
          />
        );
      }

      return (
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          value={props.value as uiLib.SelectProps['value']}
          options={props.selectedDocumentLabelsOptions}
          onChange={props.onValueChange as uiLib.SelectProps['onChange']}
          isLoading={!props.areSelectedDocumentLabelsLoaded && !props.areCommandsLoaded}
          shouldCloseMenuOnSelect={false}
          isMulti
        />
      );
    }

    case modalConstants.BULK_EDIT_ATTRIBUTES.SUMMARY: {
      if (
        [modalConstants.BULK_EDIT_ACTIONS.ADD, modalConstants.BULK_EDIT_ACTIONS.REMOVE].includes(
          props.action
        )
      ) {
        return null;
      }

      return (
        <uiLib.TextField
          value={(props.value ?? '') as uiLib.TextFieldProps['value']}
          onChange={props.onValueChange as uiLib.TextFieldProps['onChange']}
        />
      );
    }

    default: {
      return null;
    }
  }
};
