import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { formatDateHelper } from './CrawlerCatalog.helper';
import Typography from '../../../components/Typography';
import CrawlerCatalogCommandRequestResult from './CrawlerCatalogCommandRequestResult';
import { DryRunReportsTable } from './elements';
import { useCrawlerCatalogDryRunData } from './hooks';

const CrawlerCatalogDryRun = ({
  crawlerDocuments,
  crawlerActivity,
  openConfirmation,
  disableEdit,
  showInfo,
  shouldShowInfo,
  dryRunCommandRequest
}) => {
  const { formattedData } = useCrawlerCatalogDryRunData({
    crawlerDocuments,
    crawlerActivity,
    disableEdit
  });
  return (
    <div className="crawlCatalog_dry-run">
      <CrawlerCatalogCommandRequestResult commandRequest={dryRunCommandRequest} />
      <div className="crawlCatalog_dry-run_btn-block">
        <Button
          size="custom"
          color="highlighted"
          onClick={openConfirmation}
          disabled={formattedData.isConfirmationDisabled}
        >
          Dry Run
        </Button>
        <div className="crawlCatalog_dry-run_file-info">
          <Typography variant="p" italic>
            {formattedData.fileName}
          </Typography>
          <Typography variant="p" italic>
            {formatDateHelper(formattedData.fileUpdateDate)}
          </Typography>
        </div>
      </div>
      <DryRunReportsTable
        reports={formattedData.reportDocumentsData}
        onOpenDryRunInfoModal={showInfo}
        onOpenModal={shouldShowInfo}
      />
    </div>
  );
};

CrawlerCatalogDryRun.propTypes = {
  crawlerDocuments: PropTypes.shape({
    config: PropTypes.shape({}),
    spec: PropTypes.shape({}),
    dryRun: PropTypes.shape({})
  }),
  crawlerActivity: PropTypes.shape({
    crawler_id: PropTypes.number
  }),
  openConfirmation: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  disableEdit: PropTypes.bool,
  dryRunCommandRequest: PropTypes.shape({})
};

CrawlerCatalogDryRun.defaultProps = {
  crawlerDocuments: { spec: {}, config: {}, dryRun: {} },
  crawlerActivity: {},
  disableEdit: false,
  dryRunCommandRequest: {}
};

export default CrawlerCatalogDryRun;
