import type { FC } from 'react';
import type { EitlLabelsSelectProps } from './EitlLabelsSelect.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import {
  useEitlLabelsSelectData,
  useEitlLabelsSelectHandlers,
  useEitlLabelsSelectMutations
} from './hooks';

export const EitlLabelsSelect: FC<EitlLabelsSelectProps> = props => {
  const { localState, localActions, reduxState } = useEitlLabelsSelectData();

  const handlers = useEitlLabelsSelectHandlers({
    localActions
  });

  const mutations = useEitlLabelsSelectMutations({
    props,
    localActions,
    reduxState
  });

  return (
    <uiLib.Select
      {...props}
      isOpen={localState.isOpen}
      onOpen={handlers.handleEitlLabelsSelectToggle(true)}
      onClose={handlers.handleEitlLabelsSelectToggle(false)}
      type={props.type ?? uiLib.SELECT_TYPES.DEFAULT}
      value={props.value}
      isDisabled={props.isDisabled}
      onChange={props.onChange}
      loadOptions={handlers.handleEitlLabelsFetch}
      onCreateOption={inputValue => mutations.addEitlLabel.mutate([inputValue])}
      isLoading={mutations.addEitlLabel.isLoading}
      shouldCacheFetchedOptions
      shouldFetchDefaultOptions
      shouldKeepInputValue
      isCreatable
      isAsync
      isMulti
    />
  );
};
