import _ from 'lodash';
import {
  REQUEST_ALL_PUBLICATIONS,
  RECEIVE_ALL_PUBLICATIONS,
  UPDATE_PUBLICATION,
  PUBLICATION_UPDATED
} from './publication.actions';

export const getInitialAllPublicationsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  publications: []
});

export const all_publications = (state = getInitialAllPublicationsState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_PUBLICATIONS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ALL_PUBLICATIONS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        publications: action.publications,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialPublicationState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false
});

export const publication = (state = getInitialPublicationState(), action) => {
  switch (action.type) {
    case UPDATE_PUBLICATION:
      return {
        ...state,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case PUBLICATION_UPDATED:
      return {
        ...state,
        isReady: true,
        isUpdating: false,
        updated: true,
        publication: _.get(action, 'publication', state.publication)
      };
    default:
      return state;
  }
};
