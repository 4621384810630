import type { CrawlDetailsHandlers } from './useCrawlDetailsHandlers.types';

import { useEffect } from 'react';

export const useCrawlDetailsLifecycle = ({
  onCalculateCrawlDetails,
  onCreateNewCrawl
}: {
  onCalculateCrawlDetails: CrawlDetailsHandlers['handleCalculateCrawlDetails'];
  onCreateNewCrawl: CrawlDetailsHandlers['handleCreateNewCrawl'];
}): void => {
  useEffect(onCalculateCrawlDetails, [onCalculateCrawlDetails]);
  useEffect(onCreateNewCrawl, [onCreateNewCrawl]);
};
