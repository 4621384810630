import type { FC } from 'react';
import type { BulkEditModalRuleProps } from './BulkEditModalRule.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import {
  useBulkEditModalRuleData,
  useBulkEditModalRuleHandlers,
  useBulkEditModalRuleValueInput
} from './hooks';

import * as constants from './BulkEditModalRule.constants';

import './_bulk-edit-modal-rule.scss';

export const BulkEditModalRule: FC<BulkEditModalRuleProps> = ({
  action,
  onActionChange,
  attribute,
  onAttributeChange,
  value,
  onValueChange,
  onRuleRemove,
  selectedDocumentLabelsOptions,
  areSelectedDocumentLabelsLoaded,
  areCommandsLoaded,
  availableBulkEditActions
}) => {
  const { formattedData } = useBulkEditModalRuleData({
    props: {
      action,
      attribute,
      availableBulkEditActions
    }
  });

  const handlers = useBulkEditModalRuleHandlers({
    props: {
      value,
      onValueChange
    }
  });

  const valueInput = useBulkEditModalRuleValueInput({
    props: {
      action,
      attribute,
      value,
      onValueChange,
      selectedDocumentLabelsOptions,
      areSelectedDocumentLabelsLoaded,
      areCommandsLoaded
    },
    events: {
      onEitlLabelCreate: handlers.handleEitlLabelCreate
    }
  });

  return (
    <div className="bulk-edit-modal-rule">
      <div className="bulk-edit-modal-rule__section">
        <uiLib.LabeledField label="Select action" className="bulk-edit-modal-rule__label">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            value={action as uiLib.SelectProps['value']}
            options={constants.BULK_EDIT_ACTIONS_OPTIONS}
            onChange={onActionChange}
            isSearchable={false}
          />
        </uiLib.LabeledField>
      </div>
      {formattedData.isAttributeSectionShown && (
        <div className="bulk-edit-modal-rule__section">
          <uiLib.LabeledField label="Select attribute" className="bulk-edit-modal-rule__label">
            <uiLib.Select
              type={uiLib.SELECT_TYPES.DEFAULT}
              value={attribute as uiLib.SelectProps['value']}
              options={formattedData.bulkEditAttributesOptions}
              onChange={onAttributeChange}
              isSearchable={false}
            />
          </uiLib.LabeledField>
        </div>
      )}
      {formattedData.isValueSectionShown && valueInput && (
        <div className="bulk-edit-modal-rule__section">
          <uiLib.LabeledField
            label={formattedData.valueInputLabel}
            className="bulk-edit-modal-rule__label"
          >
            {valueInput}
          </uiLib.LabeledField>
        </div>
      )}
      <uiLib.IconDelete
        className="bulk-edit-modal-rule__remove-button-icon"
        onClick={onRuleRemove}
      />
    </div>
  );
};
