import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import TextInput from '../../../../components/TextInput';
import Button from '../../../../components/Button';
import { Checkbox } from '@compliance.ai/web-components';

const DryRunSettingsModal = ({ show, onHide, modalAction }) => {
  const [docQuantity, setDocQuantity] = useState('20');
  const [isVideoRecordingMode, setIsVideoRecordingMode] = useState(false);
  const handleChange = e => setDocQuantity(e.target.value);

  return (
    <Modal className="confirmationModal" title="Dry Run" show={show} onHide={onHide} size="small">
      <div className="confirmationModal_body">
        <TextInput
          label="Number of Documents"
          value={docQuantity}
          type="number"
          onChange={handleChange}
        />
        <Checkbox
          isChecked={isVideoRecordingMode}
          onChange={setIsVideoRecordingMode}
          label={<label>"Video Debug mode"</label>}
        />
        <div className="buttonBlock">
          <Button onClick={onHide} color="highlighted" size="medium">
            Cancel
          </Button>
          <Button
            onClick={() => modalAction(docQuantity, isVideoRecordingMode)}
            color="blue"
            size="medium"
          >
            Dry Run
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DryRunSettingsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  modalAction: PropTypes.func.isRequired
};

export default DryRunSettingsModal;
