import lodash from 'lodash';
import getBase64FromFile from 'admin/utils/getBase64FromFile';

import { AGENCY_GENERAL, AGENCY_WORDS, AGENCY_LOGO, ERRORS } from './AgencyDetails.constants';
import { SOURCE_TYPES } from 'admin/constants/sources';
import { api_getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.api';

import { useMemo } from 'react';

// ---------------- GET Options ----------------

const formatSelectOptions = (array, valueName, labelName) =>
  array.map(item => ({
    value: lodash.get(item, valueName, item),
    label: lodash.get(item, labelName, item)
  }));

export const loadJurisdictionsOptions = (name, searchValue, responseName) =>
  api_getJurisdictions({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, 'id', responseName));

export const calculateSourceTypes = () =>
  // TODO Remove hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMemo(
    () =>
      Object.values(SOURCE_TYPES).map(item => ({
        value: item,
        label: item
      })),
    []
  );

// ---------------- GET Agency Info ----------------

const getAgencyType = agencyInfo => {
  const agencyType = lodash.get(agencyInfo, 'type', null);
  return agencyType ? { value: agencyType, label: agencyType } : null;
};

const getJurisdiction = agencyInfo => {
  const id = lodash.get(agencyInfo, 'jurisdiction_id', '');
  const name = lodash.get(agencyInfo, 'jurisdiction', '');
  return lodash.isNumber(id) && name ? { value: id, label: name } : null;
};

const getSourceType = () => {
  const sourceType = SOURCE_TYPES.AGENCY;
  return sourceType ? { value: sourceType, label: sourceType } : null;
};

// convert server agency info to screen crawl details
export const fromRawInfoToDetails = agencyInfo => ({
  // general info
  [AGENCY_GENERAL.agencyId]: lodash.get(agencyInfo, 'id', ''),
  [AGENCY_GENERAL.parentID]: lodash.get(agencyInfo, 'parent_id', ''),
  [AGENCY_GENERAL.name]: lodash.get(agencyInfo, 'name', ''),
  [AGENCY_GENERAL.shortName]: lodash.get(agencyInfo, 'short_name', ''),
  [AGENCY_GENERAL.slug]: lodash.get(agencyInfo, 'slug', ''),
  [AGENCY_GENERAL.url]: lodash.get(agencyInfo, 'url', ''),
  [AGENCY_GENERAL.description]: lodash.get(agencyInfo, 'description', ''),
  [AGENCY_GENERAL.timesCited]: lodash.get(agencyInfo, 'times_cited', ''),
  [AGENCY_GENERAL.active]: lodash.get(agencyInfo, 'active', false),
  [AGENCY_GENERAL.blacklisted]: lodash.get(agencyInfo, 'blacklisted', false),
  [AGENCY_GENERAL.popular]: lodash.get(agencyInfo, 'popular', false),
  [AGENCY_GENERAL.type]: getAgencyType(agencyInfo),
  [AGENCY_GENERAL.jurisdiction]: getJurisdiction(agencyInfo),
  [AGENCY_GENERAL.sourceType]: getSourceType(),
  // image
  [AGENCY_LOGO.logoUrl]: lodash.get(agencyInfo, 'logo_url', ''),
  [AGENCY_LOGO.logoImage]: [],
  // words
  [AGENCY_WORDS.synonyms]: lodash.get(agencyInfo, ['words', 'synonyms'], []),
  [AGENCY_WORDS.departments]: lodash.get(agencyInfo, ['words', 'departments'], []),
  [AGENCY_WORDS.subdepartments]: lodash.get(agencyInfo, ['words', 'subdepartments'], [])
});

// ---------------- POST Agency Info ----------------

// // check functions
export const checkRequiredFields = field => !field && ERRORS.commonError;

// function that format crawl object to send

export const fromDetailsToRawInfo = async (newInfo, oldInfo) => {
  const agencyDetails = {};
  const errors = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }) => {
    let valueNew;
    if (lodash.isBoolean(predefinedValue)) valueNew = predefinedValue;
    else valueNew = predefinedValue || lodash.get(newInfo, newPath, null);

    const valueOld = lodash.get(oldInfo, oldPath, null);
    if (!lodash.isEqual(valueNew, valueOld)) {
      lodash.set(agencyDetails, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew);
        if (result) errors.push({ text: result, title: newPath[0] });
      }
    }
  };

  // general info
  setDifference({
    newPath: [AGENCY_GENERAL.parentID],
    oldPath: ['parent_id'],
    predefinedValue: Number(newInfo[AGENCY_GENERAL.parentID]) || null
  });
  setDifference({
    newPath: [AGENCY_GENERAL.agencyId],
    oldPath: ['id'],
    superPath: ['agency_id'],
    predefinedValue: Number(newInfo[AGENCY_GENERAL.agencyId]) || null,
    checkFunction: checkRequiredFields
  });
  setDifference({
    newPath: [AGENCY_GENERAL.name],
    oldPath: ['name']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.shortName],
    oldPath: ['short_name']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.slug],
    oldPath: ['slug']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.type, 'value'],
    oldPath: ['type']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.slug],
    oldPath: ['slug']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.url],
    oldPath: ['url']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.description],
    oldPath: ['description']
  });
  // commented due to lack of field
  // setDifference({
  //   newPath: [AGENCY_GENERAL.timesCited],
  //   oldPath: ['times_cited']
  // });
  setDifference({
    newPath: [AGENCY_GENERAL.active],
    oldPath: ['active']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.blacklisted],
    oldPath: ['blacklisted']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.popular],
    oldPath: ['popular']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.jurisdiction, 'label'],
    oldPath: ['jurisdiction']
  });
  setDifference({
    newPath: [AGENCY_GENERAL.jurisdiction, 'value'],
    oldPath: ['jurisdiction_id']
  });
  setDifference({
    newPath: [AGENCY_LOGO.logoUrl],
    oldPath: ['logo_url']
  });
  // image
  let fileImage = lodash.get(newInfo, [AGENCY_LOGO.logoImage, 0], null);
  if (fileImage) {
    fileImage = await getBase64FromFile(lodash.get(newInfo, [AGENCY_LOGO.logoImage, 0], null));
  }
  setDifference({
    newPath: [AGENCY_LOGO.logoImage, 0],
    oldPath: ['logo_url'],
    superPath: ['logo_image'],
    predefinedValue: fileImage
  });
  // words
  setDifference({
    newPath: [AGENCY_WORDS.synonyms],
    oldPath: ['synonyms']
  });
  setDifference({
    newPath: [AGENCY_WORDS.departments],
    oldPath: ['departments']
  });
  setDifference({
    newPath: [AGENCY_WORDS.subdepartments],
    oldPath: ['subdepartments']
  });

  return { agencyDetails, errors };
};

/*
  export only for tests
  if function should be used in other place that this helper
  please export it by place (like export const ...)
*/
export { getAgencyType, getJurisdiction };
