import type { AuditEntriesTableHandlers } from './useAuditEntriesTableHandlers.types';
import type { ComponentProps } from 'react';
import type { DocumentStatusCellValue } from '../../TableCells';

import * as uiLib from '@compliance.ai/web-components';
import * as dateConstants from 'admin/constants/date';

import { DOCUMENT_AUDIT_ENTRY_ATTRIBUTE } from 'shared/features/documentAuditEntries/documentAuditEntries.constants';

export const useAuditEntriesTableDataSelector = () => {
  return (
    data: Awaited<ReturnType<AuditEntriesTableHandlers['handleDataFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      results: data.results.map(row => ({
        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.ID]: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.ID],

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.CREATED_AT]: {
          value: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.CREATED_AT],
          passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
          passedTimezone: uiLib.TIMEZONES.UTC,
          format: uiLib.DATE_FORMATS.API_DATE_TIME
        } as uiLib.DateCellValue,

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.UPDATED_AT]: {
          value: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.UPDATED_AT],
          passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
          passedTimezone: uiLib.TIMEZONES.UTC,
          format: uiLib.DATE_FORMATS.API_DATE_TIME
        } as uiLib.DateCellValue,

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.REAL_CREATED_AT]: {
          value: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.UPDATED_AT],
          passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
          passedTimezone: uiLib.TIMEZONES.UTC,
          format: uiLib.DATE_FORMATS.API_DATE_TIME
        } as uiLib.DateCellValue,

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.NOTES]: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.NOTES],

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.DETAILS]: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.DETAILS],

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.PROCESS]: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.PROCESS],

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.INCOMING_PIPELINE_STATUS]: {
          pipelineStatus: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.INCOMING_PIPELINE_STATUS]
        } as DocumentStatusCellValue,

        [DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.RESULTING_PIPELINE_STATUS]: {
          pipelineStatus: row[DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.RESULTING_PIPELINE_STATUS]
        } as DocumentStatusCellValue
      })),
      count: data.count
    };
  };
};
