export const ERRORS = {
  commandMissed: 'Proper command not found.',
  spiderNameMissed: 'Spider name not found.',
  userIdMissed: 'User Id not found'
};

export const IMPORT_EXPORT_GENERAL = {
  importExportId: 'import_export_id',
  docIds: 'doc_ids',
  importMode: 'import_mode',
  exportMode: 'export_mode'
};

export const IMPORT_EXPORT_DETAILS = {
  [IMPORT_EXPORT_GENERAL.importExportId]: '',
  [IMPORT_EXPORT_GENERAL.docIds]: [],
  [IMPORT_EXPORT_GENERAL.importMode]: false,
  [IMPORT_EXPORT_GENERAL.exportMode]: true
};
