import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Modal';
import Textarea from '../../../../../components/Textarea';
import Button from '../../../../../components/Button';
import { checkProductionEnv } from '../../../../../utils/checkENVIRONMENT';

export const ViewCrawlSourceStructureModal = ({
  show,
  onHide,
  modalAction,
  crawlSourceStructure,
  changeCrawlSourceStructure,
  isEditDisabled
}) => {
  const handleChange = e => changeCrawlSourceStructure(e.target.value);
  const productionEnv = checkProductionEnv();

  return (
    <Modal
      className="confirmationModal"
      title="View crawl source structure"
      show={show}
      onHide={onHide}
      size="small"
    >
      <div className="confirmationModal_body">
        <Textarea
          value={crawlSourceStructure}
          onChange={handleChange}
          isDisabled={isEditDisabled || productionEnv}
        />
        <div className="buttonBlock">
          <Button onClick={onHide} color="highlighted" size="medium">
            Cancel
          </Button>
          <Button
            onClick={() => modalAction(crawlSourceStructure)}
            color="blue"
            size="medium"
            disabled={isEditDisabled}
          >
            Save changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ViewCrawlSourceStructureModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  modalAction: PropTypes.func.isRequired,
  crawlSourceStructure: PropTypes.string,
  disableEdit: PropTypes.bool
};

ViewCrawlSourceStructureModal.defaultProps = {
  crawlSourceStructure: '',
  disableEdit: false
};
