import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';
import {
  api_getMetaCategories,
  api_postMetaCategories,
  api_putMetaCategories,
  api_deleteMetaCategories
} from './metaCategories.api';
import {
  setMetaCategory,
  createMetaCategory,
  updateMetaCategory,
  removeMetaCategory,
  initialState
} from './metaCategories.reducer';

const SUCCESS = {
  metaCategoryCreated: 'Meta Categories successfully created',
  metaCategoryUpdated: 'Meta Categories successfully updated',
  metaCategoryDeleted: 'Meta Categories successfully deleted'
};

export const getMetaCategories = params => dispatch =>
  api_getMetaCategories(params).then(res => dispatch(setMetaCategory(res)));

export const createMetaCategories = params => dispatch =>
  api_postMetaCategories(params).then(res => {
    setNotification({
      message: { text: SUCCESS.metaCategoryCreated, title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(createMetaCategory(res));
  });

export const updateMetaCategories = params => dispatch =>
  api_putMetaCategories(params).then(res => {
    setNotification({
      message: { text: SUCCESS.metaCategoryUpdated, title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(updateMetaCategory(res));
  });

export const removeMetaCategories = params => dispatch =>
  api_deleteMetaCategories(params).then(res => {
    setNotification({
      message: { text: SUCCESS.metaCategoryDeleted, title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(removeMetaCategory(params));
  });

export const clearMetaCategories = () => dispatch => dispatch(setMetaCategory(initialState));
