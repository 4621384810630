import type { EitlLabelAddModalData } from './useEitlLabelAddModalData.types';
import type { EitlLabelAddModalFormattedData } from './useEitlLabelAddModalFormattedData.types';
import type { useEitlLabelAddModalMutations } from './useEitlLabelAddModalMutations';

import { useMemo } from 'react';

export const useEitlLabelAddModalFormattedData = ({
  localState,
  mutations
}: {
  localState: EitlLabelAddModalData['localState'];
  mutations: ReturnType<typeof useEitlLabelAddModalMutations>;
}): EitlLabelAddModalFormattedData =>
  useMemo(
    () => ({
      isSubmitButtonDisabled: !localState.eitlLabelName || mutations.addEitlLabel.isLoading
    }),
    [localState.eitlLabelName, mutations.addEitlLabel.isLoading]
  );
