import { COMMAND_REQUEST_NAMES } from '../../../constants/commandRequestTypes';
import { createCommandRequest } from '../../../utils/commandRequestHelper';
import { ERRORS } from './DocumentsIngested.constants';

export function createDeprecateDocsCommandRequest({ crawlDetailsInfo, commands,
  current_user, parentIds }) {
  // error check block
  let error = null;
  if (!crawlDetailsInfo.spider_name) error = ERRORS.spiderNameMissed;
  if (error) return { error };

  return createCommandRequest({
    name: `Deprecate documents: ${crawlDetailsInfo.spider_name}`,
    parameters: {
      spider_name: crawlDetailsInfo.spider_name,
      crawl_id: crawlDetailsInfo.id,
      parent_ids: parentIds
    },
    commands,
    current_user,
    commandRequestName: COMMAND_REQUEST_NAMES.DOCUMENT_DEPRECATION_COMMAND
  });
}

export function createDocumentSeedingCommandRequest(props) {
  const { crawlDetailsInfo, exportImportDetailsInfo, commands, current_user } = props;

  // error check block
  let error = null;
  if (!crawlDetailsInfo.spider_name) error = ERRORS.spiderNameMissed;
  if (error) return { error };

  return createCommandRequest({
    name: `Export/Import documents: ${crawlDetailsInfo.spider_name}`,
    parameters: {
      spider_name: crawlDetailsInfo.spider_name,
      ...exportImportDetailsInfo
    },
    commands,
    current_user,
    commandRequestName: COMMAND_REQUEST_NAMES.DOCUMENT_SEEDING
  });
}
