import type { EitlLabelDeleteModalProps } from '../EitlLabelDeleteModal.types';

import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { useMutation } from '@tanstack/react-query';
import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';

export const useEitlLabelDeleteModalMutations = ({
  props
}: {
  props: Pick<EitlLabelDeleteModalProps, 'label' | 'onConfirm'>;
}) => {
  const eitlLabelsApi = useEitlLabelsApi();

  const deleteEitlLabel = useMutation<Awaited<void>, Error, void>({
    mutationKey: queryKeysConstants.COMPLEX_QUERY_KEY_GETTERS[
      queryKeysConstants.QUERY_KEYS.EITL_LABELS
    ][queryKeysConstants.QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_DELETE]({
      id: Number(props.label.id)
    }),
    mutationFn: async () => {
      await eitlLabelsApi.deleteEitlLabel(Number(props.label.id));
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      props.onConfirm();

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        message: 'EITL Label successfully deleted'
      });
    }
  });

  return {
    deleteEitlLabel
  };
};
