import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import React from "react";
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from "prop-types";


const MModal = ({
  children,
  classNameModal,
  classNamePaper,
  closeModal,
  showModal
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classNameModal}
      open={showModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={showModal}>
        <div className={classNamePaper}>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

MModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  classNameModal: PropTypes.string.isRequired,
  classNamePaper: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default MModal;
