import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../../../../../components/Button';

const CrawlActivityHeaderSearchModalBtnBlock = ({ clearSearch, requestCrawls }) => {
  return (
    <div className="footer-btn-block">
      <div>
        <Button size="small" color="highlighted" onClick={clearSearch}>
          Clear all
        </Button>
      </div>
      <div>
        {/*
        <Button
          size="small"
          color="highlighted"
          disabled
          onClick={() => {
          }}
        >
          Save as Widget
        </Button>
        */}
        <Button size="small" onClick={requestCrawls}>
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

CrawlActivityHeaderSearchModalBtnBlock.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  requestCrawls: PropTypes.func.isRequired
};

export default CrawlActivityHeaderSearchModalBtnBlock;
