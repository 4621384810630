import React from 'react';
import PropTypes from 'prop-types';
import * as uiLib from '@compliance.ai/web-components';
import _ from 'lodash';
import cn from 'classnames';

const DocumentVersionList = ({ docVersions, currentDocVersionID, changeCurrentDocVersion }) => (
  <div className="document-list_details_items_list">
    {docVersions.map(item => {
      const itemId = _.get(item, 'id');
      const isActive = itemId === currentDocVersionID;
      const createdAt = _.get(item, 'created_at', '');
      const updatedAt = _.get(item, 'updated_at', '');
      const pubDate = _.get(item, 'publication_date', '');
      const status = _.get(item, 'versionize_status', '');
      const fulltextHash = _.get(item, 'full_text_hash', '');
      const fullxmlHash = _.get(item, 'full_xml_hash', '');
      const pdfHash = _.get(item, 'pdf_hash', '');
      return (
        <div
          key={itemId}
          onClick={() => changeCurrentDocVersion(itemId)}
          className={cn('document-list_details_items_list_element', {
            'document-list_details_items_list_element_active': isActive,
            'document-list_details_items_list_element_disabled': !isActive
          })}
        >
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Created at: ${createdAt}`}</uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Updated at: ${updatedAt}`}</uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Publication Date: ${pubDate}`}</uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY1}
          >{`Status: ${status}`}</uiLib.Typography>
          <uiLib.Tooltip
            title={fulltextHash}
            classNames={{
              tooltip: 'document-list_details_items_list_element_hash-text'
            }}
          >
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >{`Full Text Hash: ${fulltextHash}`}</uiLib.Typography>
          </uiLib.Tooltip>
          <uiLib.Tooltip
            title={fullxmlHash}
            classNames={{
              tooltip: 'document-list_details_items_list_element_hash-text'
            }}
          >
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >{`Full Xml Hash: ${fullxmlHash}`}</uiLib.Typography>
          </uiLib.Tooltip>
          <uiLib.Tooltip
            title={pdfHash}
            classNames={{
              tooltip: 'document-list_details_items_list_element_hash-text'
            }}
          >
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.BODY1}
            >{`Pdf Hash: ${pdfHash}`}</uiLib.Typography>
          </uiLib.Tooltip>
        </div>
      );
    })}
  </div>
);
DocumentVersionList.propTypes = {
  docVersions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentDocVersionID: PropTypes.number.isRequired,
  changeCurrentDocVersion: PropTypes.func.isRequired
};

export default DocumentVersionList;
