import type {FC, ReactElement} from "react";

import React from 'react';
import {useRoutes} from "react-router-dom";
import {ROUTES_CONFIG} from "./AppRoutes.config";
import {AdminPermissionsWrapper} from "./elements";

export const AppRoutes: FC = () => {
  return useRoutes(ROUTES_CONFIG.map(route => ({
    ...route,
    element: (
      <AdminPermissionsWrapper>
        {route.element as ReactElement}
      </AdminPermissionsWrapper>
    )
  })));
}
