import * as uiLib from '@compliance.ai/web-components';
import * as env from 'shared/config';

import Bugsnag from '@bugsnag/js';

/**
 * Shows error notification and logs an error to Bugsnag
 */
export const logError = (
  error: unknown,
  title = 'Error',
  options: {
    isSilent: boolean;
  } = {
    isSilent: false
  }
) => {
  console.error(error);

  const errorInstance = error instanceof Error ? error : new Error('Unknown');

  if (!options.isSilent) {
    uiLib.showNotification({
      type: uiLib.ALERT_TYPES.ERROR,
      title,
      message: errorInstance.message
    });
  }

  if (env.bugsnagApiKey) {
    Bugsnag.notify(errorInstance);
  }
};

export const logReactQueryError = (error: Error) => {
  logError(error, 'Error', {
    isSilent: true
  });
};

export class NetworkError extends Error {
  status: number;
  type: string;
  message: string;

  constructor({ message, type, status }: { message: string; status: number; type: string }) {
    super(message);

    this.status = status;
    this.type = type;
    this.message = message;
  }
}
