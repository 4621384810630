import type { CrawlDetailsConfigurationHandlers } from './useCrawlDetailsConfigurationHandlers.types';

import { CrawlDetailsData } from 'admin/containers/CrawlerPage/CrawlerDetails/hooks/useCrawlDetailsData.types';
import { CrawlDetailsConfigurationData } from './useCrawlDetailsConfigurationData.types';

export const useCrawlDetailsConfigurationsHandlers = ({
  localActions
}: {
  localActions: CrawlDetailsConfigurationData['localActions'];
}): CrawlDetailsConfigurationHandlers => {
  const handleDetailConfigurationChange = (changedConfiguration: string) => {
    localActions.setCrawlSentencificationConfiguration(changedConfiguration);
  };

  return {
    handleCrawlSentencificationConfigurationChange: handleDetailConfigurationChange
  };
};
