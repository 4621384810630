import type { FC } from 'react';
import type { CommandRequestsFiltersProps } from './CommandRequestsFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useCommandRequestsFiltersAsyncLoaders,
  useCommandRequestsFiltersConfig,
  useCommandRequestsFiltersHandlers
} from './hooks';

import './_command-requests-filters.scss';

export const CommandRequestsFilters: FC<CommandRequestsFiltersProps> = ({ onSubmit }) => {
  const filterAsyncLoaders = useCommandRequestsFiltersAsyncLoaders();

  const filterConfig = useCommandRequestsFiltersConfig({
    filterAsyncLoaders
  });

  const handlers = useCommandRequestsFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search Command Request"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="command-requests-filters"
    />
  );
};
