import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import classnames from 'classnames';

import loadingLight from 'shared/images/loaders/loading-spinner-light-64.gif';
import loadingDark from 'shared/images/loaders/loading-spinner-dark-64.gif';

const useStyle = makeStyles({
  backdropStyle: {
    background: 'white',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    'z-index': '9999',
    opacity: '0.5'
  },
  loaderStyle: {
    width: '100px',
    height: '100px',
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)',
    color: '#2C6DDF'
  }
});

export const TEST_ID = 'loader';
export const LEGACY_TEST_ID = 'legacy-loader';
export const LEGACY_TYPES = {
  LIGHT: 'light',
  DARK: 'dark'
};
const LEGACY_IMAGES = {
  [LEGACY_TYPES.LIGHT]: loadingLight,
  [LEGACY_TYPES.DARK]: loadingDark
};

const MCircularProgress = ({ legacy, legacyType, containerClassName, spinnerClassName }) => {
  const classes = useStyle();

  if (legacy) {
    return (
      <div data-testid={LEGACY_TEST_ID} className={classnames("image-container", containerClassName)}>
        <img src={LEGACY_IMAGES[legacyType]} alt="loading" className={spinnerClassName} />
      </div>
    );
  }

  return (
    <div data-testid={TEST_ID} className={classnames(classes.backdropStyle, containerClassName)}>
      <CircularProgress className={classnames(classes.loaderStyle, spinnerClassName)} />
    </div>
  );
};

export default MCircularProgress;

MCircularProgress.propTypes = {
  legacy: PropTypes.bool,
  legacyType: PropTypes.oneOf(Object.values(LEGACY_TYPES)),
  containerClassName: PropTypes.string,
  spinnerClassName: PropTypes.string
};

MCircularProgress.defaultProps = {
  legacy: false,
  legacyType: LEGACY_TYPES.LIGHT,
  containerClassName: '',
  spinnerClassName: ''
};
