import type { FC } from 'react';
import type { EventsTableProps } from './EventsTable.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './EventsTable.constants';
import * as queryKeyConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as documentEventsConstants from 'shared/features/documentEvents/documentEvents.constants';

import { useEventsTableDataSelector, useEventsTableHandlers } from './hooks';

export const EventsTable: FC<EventsTableProps> = ({ documentId, classNames }) => {
  const handlers = useEventsTableHandlers({
    props: {
      documentId
    }
  });

  const tableDataSelector = useEventsTableDataSelector();

  return (
    <uiLib.Table
      rowKey={documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.ID}
      columns={constants.COLUMNS}
      shouldShowRefreshButton
      shouldShowTableOptions
      shouldShowColumnsSettingsButton
      shouldShowFirstPageButton
      shouldShowLastPageButton
      rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
      initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
      onDataFetch={handlers.handleDataFetch}
      localStorageKey={constants.LOCAL_STORAGE_KEY}
      classNames={classNames}
      queryOptions={{
        queryKey: [queryKeyConstants.QUERY_KEYS.DOCUMENT_EVENTS, documentId],
        select: tableDataSelector,
        onError: errorUtils.logReactQueryError
      }}
    />
  );
};
