import { api_fetchAllPlans } from './plans.api';

export const REQUEST_ALL_PLANS = 'REQUEST_ALL_PLANS';
export const RECEIVE_ALL_PLANS = 'RECEIVE_ALL_PLANS';

function requestAllPlans() {
  return {
    type: REQUEST_ALL_PLANS
  };
}

function receiveAllPlans(json) {
  return {
    type: RECEIVE_ALL_PLANS,
    plans: json.all_plans
  };
}

export function fetchAllPlans() {
  return function dofetch(dispatch) {
    dispatch(requestAllPlans());
    return api_fetchAllPlans()
      .then(response => {
        dispatch(receiveAllPlans(response));
      });
  };
}
