import type { GlobalState } from 'shared/reducers';
import type { AuthCheckData } from './useAuthCheckData.types';

import * as authSelectors from 'shared/features/auth/auth.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useAuthCheckData = (): AuthCheckData => {
  const [isReady, setIsReady] = useState(false);

  const localState: AuthCheckData['localState'] = {
    isReady
  };

  const localActions: AuthCheckData['localActions'] = useMemo(
    () => ({
      setIsReady
    }),
    []
  );

  const reduxState = useSelector<GlobalState, AuthCheckData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state),
    JWTToken: authSelectors.getJWTToken(state as never)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
