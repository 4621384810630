import type { DocumentStatusData } from './useDocumentStatusData.types';
import type { DocumentStatusProps } from '../DocumentStatus.types';

import * as documentUtils from 'utils/document';
import * as constants from '../DocumentStatus.constants';

import { useMemo } from 'react';

export const useDocumentStatusData = ({
  pipelineStatus,
  documentExaminationRecord
}: Pick<
  DocumentStatusProps,
  'pipelineStatus' | 'documentExaminationRecord'
>): DocumentStatusData => {
  const formattedData = useMemo(() => {
    const statusText = documentUtils.getDocStatusLabel(pipelineStatus);
    const tooltipText = `${
      Array.isArray(documentExaminationRecord) ? documentExaminationRecord.join(', ') : 'Unknown'
    } issue`;

    const shouldRenderTooltip = pipelineStatus
      ? constants.EXTRA_INFO_STATUSES.includes(pipelineStatus)
      : false;

    return {
      statusText,
      tooltipText,
      shouldRenderTooltip
    };
  }, [documentExaminationRecord, pipelineStatus]);

  return {
    formattedData
  };
};
