import type { CrawlQAReportsHandlers } from './useCrawlerQAReportsHandlers.types';
import type { CrawlQAReportsData } from './useCrawlerQAReportsData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as commandRequestsDataApi from 'shared/features/commands/commands.apiv2';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useCrawlerQAReportsHandlers.helpers';
import * as commandRequestTypesConstants from 'admin/constants/commandRequestTypes';

export const useCrawlerQAReportsHandlers = ({
  localActions,
  formattedData
}: {
  localActions: CrawlQAReportsData['localActions'];
  formattedData: CrawlQAReportsData['formattedData'];
}): CrawlQAReportsHandlers => {
  const handleCommandRequestsFetch: CrawlQAReportsHandlers['handleCommandRequestsFetch'] =
    async params => {
      try {
        const commandRequestsResponse = await commandRequestsDataApi.fetchCommandRequestsData({
          ...params,
          crawl_id: formattedData.crawlId,
          auto_qa_url: true,
          command_name: [
            commandRequestTypesConstants.COMMAND_REQUEST_NAMES.AUTOMATED_CRAWL_QA_COMMAND
          ],
          [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
        });

        return {
          results: commandRequestsResponse.command_request,
          count: commandRequestsResponse.command_request_count ?? 0
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    };

  const handleDetailsModalOpen: CrawlQAReportsHandlers['handleDetailsModalOpen'] = (
    details,
    selectedCommandRequest
  ) => {
    localActions.setSelectedCommandRequest(selectedCommandRequest);
    localActions.setDetailsModalInfo(details);
    localActions.setIsDetailsModalOpen(true);
  };

  const handleDetailsModalClose: CrawlQAReportsHandlers['handleDetailsModalClose'] = () => {
    localActions.setSelectedCommandRequest(null);
    localActions.setIsDetailsModalOpen(false);
    localActions.setDetailsModalInfo('');
  };

  const handleDelete: CrawlQAReportsHandlers['handleDelete'] = async category => {};

  return {
    handleCommandRequestsFetch,
    handleDetailsModalOpen,
    handleDetailsModalClose,
    handleDelete
  };
};
