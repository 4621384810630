import _ from 'lodash';
import {
  NAMES_STEP,
  NAMES_CONTEXT,
  NAMES_META_DATA,
  NAMES_ACTION,
  NAMES_EXTRACT_TAGS,
  NAMES_DOC,
  NAMES_PATH
} from './CrawlSpecCreate.constants';

// ------ CHECK ERRORS ------

// This is quite big part of logic
// but it response only to remove errors from fixed fields
// need discussion about optimization / necessity of this

export const checkGeneral = ({
  errorFields,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    const generalErrors = _.cloneDeep(_.get(errorFields, 'main', []));
    const main = generalErrors.filter(item => item !== fieldName);
    return { ...errorFields, main };
  }
  return {};
};

export const checkConfiguration = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (_.get(errorFields, 'configuration', []).length) {
    // uuids
    const configurationErrors = _.get(errorFields, 'configuration', []);
    const newConfigurationErrors = configurationErrors.filter(item => item !== fieldName);
    return {
      ...errorFields,
      configuration: newConfigurationErrors
    };
  }
  return errorFields;
};

export const checkCategoryConversion = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (_.get(errorFields, 'categoryConversion', []).length) {
    // uuids
    const categoryConversionErrors = _.get(errorFields, 'categoryConversion', []);
    const newCategoryConversionErrors = categoryConversionErrors.filter(item => item !== fieldName);
    return {
      ...errorFields,
      categoryConversion: newCategoryConversionErrors
    };
  }
  return errorFields;
};

export const checkStep = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const stepUUID = currentUUID.step;

    let steps = _.cloneDeep(_.get(errorFields, 'steps', {}));
    const stepErrors = _.get(steps, stepUUID, []);
    const newStepErrors = stepErrors.filter(item => item !== fieldName);
    if (newStepErrors.length) {
      steps[stepUUID] = newStepErrors;
    } else {
      steps = _.omit(steps, stepUUID);
    }
    return { ...errorFields, steps };
  }
  return {};
};

export const checkContext = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const contextUUID = currentUUID.context;

    let contexts = _.cloneDeep(_.get(errorFields, 'contexts', {}));
    const contextErrors = _.get(contexts, contextUUID, []);
    const newContextErrors = contextErrors.filter(item => item !== fieldName);
    if (newContextErrors.length) {
      contexts[contextUUID] = newContextErrors;
    } else {
      contexts = _.omit(contexts, contextUUID);
      return checkStep({
        errorFields: { ...errorFields, contexts },
        currentUUID,
        fieldName: NAMES_STEP.CONTEXT
      });
    }
    return { ...errorFields, contexts };
  }
  return {};
};

export const checkDoc = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const docUUID = currentUUID.doc;

    let docs = _.cloneDeep(_.get(errorFields, 'docs', {}));
    const docErrors = _.get(docs, docUUID, []);
    const newDocErrors = docErrors.filter(item => item !== fieldName);
    if (newDocErrors.length) {
      docs[docUUID] = newDocErrors;
    } else {
      docs = _.omit(docs, docUUID);
      return checkStep({
        errorFields: { ...errorFields, docs },
        currentUUID,
        fieldName: NAMES_STEP.DOC
      });
    }
    return { ...errorFields, docs };
  }
  return {};
};

export const checkAction = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const actionUUID = currentUUID.action;

    let actions = _.cloneDeep(_.get(errorFields, 'actions', {}));
    const actionErrors = _.get(actions, actionUUID, []);
    const newCActionErrors = actionErrors.filter(item => item !== fieldName);
    if (newCActionErrors.length) {
      actions[actionUUID] = newCActionErrors;
    } else {
      actions = _.omit(actions, actionUUID);
      return checkStep({
        errorFields: { ...errorFields, actions },
        currentUUID,
        fieldName: NAMES_STEP.ACTIONS
      });
    }
    return { ...errorFields, actions };
  }
  return {};
};

export const checkRelation = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const relationUUID = currentUUID.relation;

    let relations = _.cloneDeep(_.get(errorFields, 'relations', {}));
    const relationErrors = _.get(relations, relationUUID, []);
    const newRelationErrors = relationErrors.filter(item => item !== fieldName);
    if (newRelationErrors.length) {
      relations[relationUUID] = newRelationErrors;
    } else {
      relations = _.omit(relations, relationUUID);
      return checkStep({
        errorFields: { ...errorFields, relations },
        currentUUID,
        fieldName: NAMES_STEP.RELATION
      });
    }
    return { ...errorFields, relations };
  }
  return {};
};

export const checkResetCounter = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const resetCounterUUID = currentUUID.resetCounter;

    let resetCounters = _.cloneDeep(_.get(errorFields, 'resetCounters', {}));
    const resetCounterErrors = _.get(resetCounters, resetCounterUUID, []);
    const newResetCounterErrors = resetCounterErrors.filter(item => item !== fieldName);
    if (newResetCounterErrors.length) {
      resetCounters[resetCounterUUID] = newResetCounterErrors;
    } else {
      resetCounters = _.omit(resetCounters, resetCounterUUID);
      return checkStep({
        errorFields: { ...errorFields, resetCounters },
        currentUUID,
        fieldName: NAMES_STEP.RESET_COUNTER
      });
    }
    return { ...errorFields, resetCounters };
  }
  return {};
};

export const checkMetaData = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const metaDataUUID = currentUUID.metaData;

    let metaData = _.cloneDeep(_.get(errorFields, 'metaData', {}));
    const metaDataErrors = _.get(metaData, metaDataUUID, []);
    const newMetaDataErrors = metaDataErrors.filter(item => item !== fieldName);
    if (newMetaDataErrors.length) {
      metaData[metaDataUUID] = newMetaDataErrors;
    } else {
      metaData = _.omit(metaData, metaDataUUID);
      return checkDoc({
        errorFields: { ...errorFields, metaData },
        currentUUID,
        fieldName: NAMES_DOC.META_DATA
      });
    }
    return { ...errorFields, metaData };
  }
  return {};
};

export const checkLocation = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const docUUID = currentUUID.doc;
    const metaDataUUID = currentUUID.metaData;
    const locationUUID = currentUUID.location;

    let locations = _.cloneDeep(_.get(errorFields, 'locations', {}));
    const locationErrors = _.get(locations, locationUUID, []);
    const newLocationErrors = locationErrors.filter(item => item !== fieldName);
    if (newLocationErrors.length) {
      locations[locationUUID] = newLocationErrors;
    } else {
      locations = _.omit(locations, locationUUID);
      const newErrorFields = { ...errorFields, locations };
      if (metaDataUUID && docUUID) {
        return checkMetaData({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_META_DATA.LOCATIONS
        });
      }
      return newErrorFields;
    }
    return { ...errorFields, locations };
  }
  return {};
};

export const checkExtractTags = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const contextUUID = currentUUID.context;
    const docUUID = currentUUID.doc;
    const metaDataUUID = currentUUID.metaData;
    const extractTagsUUID = currentUUID.extractTags;

    let extractTags = _.cloneDeep(_.get(errorFields, 'extractTags', {}));
    const extractTagsErrors = _.get(extractTags, extractTagsUUID, []);
    const newExtractTagsErrors = extractTagsErrors.filter(item => item !== fieldName);
    if (newExtractTagsErrors.length) {
      extractTags[extractTagsUUID] = newExtractTagsErrors;
    } else {
      extractTags = _.omit(extractTags, extractTagsUUID);
      const newErrorFields = { ...errorFields, extractTags };
      if (metaDataUUID && docUUID) {
        return checkMetaData({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_META_DATA.EXTRACT_TAGS
        });
      } if (contextUUID) {
        return checkContext({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_CONTEXT.EXTRACT_TAGS
        });
      }
      return newErrorFields;
    }
    return { ...errorFields, extractTags };
  }
  return {};
};

export const checkPath = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const contextUUID = currentUUID.context;
    const docUUID = currentUUID.doc;
    const metaDataUUID = currentUUID.metaData;
    const extractTagsUUID = currentUUID.extractTags;
    const pathUUID = currentUUID.path;

    let paths = _.cloneDeep(_.get(errorFields, 'paths', {}));
    const pathsErrors = _.get(paths, pathUUID, []);
    const newPathsErrors = pathsErrors.filter(item => item !== fieldName);
    if (newPathsErrors.length) {
      paths[pathUUID] = newPathsErrors;
    } else {
      paths = _.omit(paths, pathUUID);
      const newErrorFields = { ...errorFields, paths };
      if (extractTagsUUID) {
        return checkExtractTags({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_EXTRACT_TAGS.PATH
        });
      } if (metaDataUUID && docUUID) {
        return checkMetaData({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_META_DATA.PATH
        });
      } if (contextUUID) {
        return checkContext({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_CONTEXT.PATH
        });
      }
      return newErrorFields;
    }
    return { ...errorFields, paths };
  }
  return {};
};

export const checkExample = ({
  errorFields,
  currentUUID,
  fieldName
}) => {
  if (Object.keys(errorFields).length) {
    // uuids
    const actionUUID = currentUUID.action;
    const pathUUID = currentUUID.path;
    const exampleUUID = currentUUID.example;

    let examples = _.cloneDeep(_.get(errorFields, 'examples', {}));
    const exampleErrors = _.get(examples, exampleUUID, []);
    const newExampleErrors = exampleErrors.filter(item => item !== fieldName);
    if (newExampleErrors.length) {
      examples[exampleUUID] = newExampleErrors;
    } else {
      examples = _.omit(examples, exampleUUID);
      const newErrorFields = { ...errorFields, examples };
      if (pathUUID) {
        return checkPath({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_PATH.EXAMPLES
        });
      } if (actionUUID) {
        return checkAction({
          errorFields: newErrorFields,
          currentUUID,
          fieldName: NAMES_ACTION.EXAMPLES
        });
      }
      return newErrorFields;
    }
    return { ...errorFields, examples };
  }
  return {};
};
