// this component should not be reused (used only in CrawlPerformanceTool)
// will be refactored in the future to ./DatePicker

import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const DualDatepicker = ({ stateNames, state, handleChange, title }) => (
  <div className="filterAdminToolWrap_elem" key={stateNames}>
    <ControlLabel>{title || 'Date picker'}</ControlLabel>
    <div className="filterAdminToolWrap_elem_double">
      {stateNames.map(stateName => (
        <DatePicker
          key={stateName}
          className="filterAdminToolWrap_elem_double_datePicker"
          placeholderText="Date"
          selected={state[stateName]}
          onChange={date =>
            handleChange({
              target: {
                value: date,
                name: stateName
              }
            })
          }
          isClearable
        />
      ))}
    </div>
  </div>
);

export default DualDatepicker;
