import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Typography from './Typography';

const Tooltip = ({
  children,
  label,
  table,
  className,
  childClassName,
  variant,
  tooltipX,
  tooltipY,
  tooltipArrowX,
  tooltipArrowY,
  fullDisable,
  floatTooltip,
  classNameTooltipID
}) => {
  const [visible, setVisible] = useState(false);

  if (fullDisable) return children;

  const onMouseEnter = e => {
    if (floatTooltip) {
      const element = document.querySelector(`.${classNameTooltipID}`);
      if (element) {
        const text = element.querySelector('.custom-tooltip-text');
        element.style.display = 'flex';
        element.style.position = 'fixed';
        element.style.top = `${e.clientY}px`;
        element.style.left = `${e.clientX}px`;
        element.style.transform = 'translate(-50%, 50%)';
        text.textContent = label;
      }
    }
    setVisible(true);
  };
  const onMouseLeave = e => {
    if (floatTooltip) {
      const element = document.querySelector(`.${classNameTooltipID}`);
      if (element) {
        element.style.display = 'none';
      }
    }
    setVisible(false);
  };
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(className, 'custom-tooltip')}
    >
      <Typography variant={variant} className={cn('custom-tooltip_children', childClassName)}>
        {children}
      </Typography>
      <TooltipMessage
        classNameTooltipID={classNameTooltipID}
        visible={visible}
        table={table}
        tooltipX={tooltipX}
        tooltipY={tooltipY}
        tooltipArrowY={tooltipArrowY}
        tooltipArrowX={tooltipArrowX}
        label={label}
        floatTooltip={floatTooltip}
      />
    </div>
  );
};

Tooltip.propTypes = {
  label: PropTypes.string,
  table: PropTypes.bool,
  className: PropTypes.string,
  childClassName: PropTypes.string,
  variant: PropTypes.string,
  tooltipX: PropTypes.string,
  tooltipY: PropTypes.string,
  tooltipArrowX: PropTypes.string,
  tooltipArrowY: PropTypes.string,
  fullDisable: PropTypes.bool,
  floatTooltip: PropTypes.bool
};

Tooltip.defaultProps = {
  label: '',
  table: false,
  className: '',
  childClassName: '',
  variant: 'p',
  tooltipX: '-20%',
  tooltipY: '0%',
  tooltipArrowX: '50%',
  tooltipArrowY: '-9px',
  fullDisable: false,
  floatTooltip: false
};

export default Tooltip;

const TooltipMessage = ({
  visible,
  table,
  tooltipX,
  tooltipY,
  tooltipArrowY,
  tooltipArrowX,
  label,
  floatTooltip,
  classNameTooltipID
}) => {
  if (floatTooltip) return null;
  return (
    <div
      className={cn(
        classNameTooltipID,
        'custom-tooltip_container',
        { 'custom-tooltip_container--visible': visible },
        { 'custom-tooltip_container_table': table }
      )}
      style={{
        transform: `translate(${tooltipX}, ${tooltipY})`
      }}
    >
      <div
        className="custom-tooltip_arrow"
        style={{
          top: tooltipArrowY,
          left: tooltipArrowX
        }}
      />
      <div className="custom-tooltip_inner">
        <Typography variant="p" className="custom-tooltip-text">
          {label}
        </Typography>
      </div>
    </div>
  );
};

TooltipMessage.propTypes = {
  visible: PropTypes.bool,
  label: PropTypes.string,
  classNameTooltipID: PropTypes.string,
  table: PropTypes.bool,
  tooltipX: PropTypes.string,
  tooltipY: PropTypes.string,
  tooltipArrowX: PropTypes.string,
  tooltipArrowY: PropTypes.string,
  floatTooltip: PropTypes.bool
};

TooltipMessage.defaultProps = {
  label: '',
  classNameTooltipID: '',
  table: false,
  tooltipX: '-20%',
  tooltipY: '0%',
  tooltipArrowX: '50%',
  tooltipArrowY: '-9px',
  floatTooltip: false
};

export { TooltipMessage };
