import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ERRORS, INFO_HELP, NAMES_PATH } from '../CrawlSpecCreate.constants';
import ExpandableBtnList from '../HelpComponents/ExpandableBtnList';
import SpecElementsWrapper from "./SpecElementsWrapper";
import TextInput from "../../../components/TextInput";

const Path = ({
  disabled,
  show,
  currentExampleUUID,
  element,
  changePath,
  changeCurrentExample,
  addNewExample,
  clearExampleElements,
  errorFieldsExamples,
  errorFields
}) => {
  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changePath(name, value);
  };

  const customOath = _.get(element, NAMES_PATH.PATH, "");

  return (
    <SpecElementsWrapper
      show={show}
      label="Path"
      tooltip={INFO_HELP.PATH}
    >
      <ExpandableBtnList
        label="Examples"
        isDisabled={disabled || !!customOath.trim()}
        isDisabledBtn={!!customOath.trim()}
        value={element[NAMES_PATH.EXAMPLES]}
        name={NAMES_PATH.EXAMPLES}
        addMoreName="Example"
        activeID={currentExampleUUID}
        onChange={changePath}
        onClickBtn={changeCurrentExample}
        createNewItemTemplate={addNewExample}
        helpRemoveFunc={clearExampleElements}
        errorFields={Object.keys(errorFieldsExamples)}
        isError={
            errorFields.includes(NAMES_PATH.EXAMPLES)
            && !_.get(element, [NAMES_PATH.EXAMPLES, 'length'], '')
          }
        errorText={ERRORS.pathExample}
      />
      <TextInput
        label="Custom path"
        name={NAMES_PATH.PATH}
        value={customOath}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_PATH.PATH)}
      />
    </SpecElementsWrapper>
  );
};

Path.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentExampleUUID: PropTypes.string,
  element: PropTypes.shape({}),
  changePath: PropTypes.func.isRequired,
  changeCurrentExample: PropTypes.func.isRequired,
  addNewExample: PropTypes.func.isRequired,
  clearExampleElements: PropTypes.func.isRequired,
  errorFieldsExamples: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Path.defaultProps = {
  disabled: false,
  currentExampleUUID: '',
  show: false,
  element: {},
  errorFieldsExamples: {},
  errorFields: []
};

export default Path;
