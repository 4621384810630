import type { DocsModalData } from './useDocsModalData.types';
import type { FlagModalProps } from 'admin/containers/DocumentManagement/elements/FlagModal/FlagModal.types';
import type { DocumentsBulkUpdatePayloadAction } from 'shared/features/documentData/documentData.apiV2.types';
import type { DocumentDataFromResponse } from 'shared/features/documentData/documentData.types';
import type { CommandRequestFromResponse } from 'shared/features/commands/commands.api.types';
import type { useDocsModalQueries } from './useDocsModalQueries';

import * as uiLib from '@compliance.ai/web-components';
import * as errorUtils from 'shared/utils/errors';
import * as documentDataApi from 'shared/features/documentData/documentData.apiV2';
import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as commandRequestTypeConstants from 'admin/constants/commandRequestTypes';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDocsModalMutations = ({
  reduxState,
  queries,
  events
}: {
  reduxState: DocsModalData['reduxState'];
  queries: ReturnType<typeof useDocsModalQueries>;
  events: Pick<FlagModalProps, 'onSubmit'>;
}) => {
  const queryClient = useQueryClient();

  const updateDocumentDataFlagMutation = useMutation<
    Awaited<ReturnType<typeof documentDataApi.updateDocumentDataBulk>>,
    Error,
    {
      selectedDocumentIds: DocumentDataFromResponse['id'][];
      variables: DocumentsBulkUpdatePayloadAction[];
    }
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.DOCUMENTS_DATA],
    mutationFn: ({ selectedDocumentIds, variables }) =>
      documentDataApi.updateDocumentDataBulk({
        name: commandRequestTypeConstants.COMMAND_REQUEST_NAMES.BULK_DOCUMENT_UPDATE,
        type: queries.fetchCommands.data as CommandRequestFromResponse['id'],
        user_id: reduxState.user.id,
        parameters: {
          docIds: selectedDocumentIds,
          actions: variables
        }
      }),
    onError: errorUtils.logReactQueryError,
    onSuccess: async () => {
      events.onSubmit();

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        title: 'Command request successfully created.'
      });

      await queryClient.invalidateQueries([queryKeysConstants.QUERY_KEYS.DOCUMENTS_DATA]);
    }
  });

  return {
    updateDocumentDataFlagMutation
  };
};
