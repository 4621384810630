import type { CrawlMonitorToolData } from './useCrawlMonitorToolData.types';
import type { GlobalState } from 'shared/reducers';
import * as lodash from 'lodash';
import * as helpers from '../CrawlMonitorTool.helper';
import * as uiLib from '@compliance.ai/web-components';
import * as crawlerSettingsConstants from '../../CrawlerPage/CrawlerSettings.constants';
import * as externalApiCrawlSelectors from 'shared/features/externalApiCrawl/externalApiCrawl.selectors';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const useCrawlMonitorToolData = (): CrawlMonitorToolData => {
  const location = useLocation();

  const [search, setSearch] = useState<CrawlMonitorToolData['localState']['search']>(
    lodash.get(location.state, 'search', helpers.defaultCrawlMonitorToolSearch)
  );
  const [tableParams, setTableParams] = useState<CrawlMonitorToolData['localState']['tableParams']>(
    { ...uiLib.DEFAULT_TABLE_PARAMS }
  );
  const [appliedSearch, setAppliedSearch] = useState<
    CrawlMonitorToolData['localState']['appliedSearch']
  >(lodash.get(location.state, 'search', {}));
  const [searchModal, setSearchModal] =
    useState<CrawlMonitorToolData['localState']['searchModal']>(false);
  const [selectedCrawl, setSelectedCrawls] = useState<
    CrawlMonitorToolData['localState']['selectedCrawl']
  >(lodash.get(location.state, 'selectedCrawl', []));
  const [reloadTrigger, setReloadTrigger] =
    useState<CrawlMonitorToolData['localState']['reloadTrigger']>(false);
  const [runNowModalActive, setRunNowModalActive] =
    useState<CrawlMonitorToolData['localState']['runNowModalActive']>(false);
  const [showModal, setShowModal] =
    useState<CrawlMonitorToolData['localState']['showModal']>(false);
  const [error, setError] = useState<CrawlMonitorToolData['localState']['error']>('');
  const [isActionDisabled, setIsActionDisabled] =
    useState<CrawlMonitorToolData['localState']['isActionDisabled']>(false);

  const [mode, setMode] = useState<CrawlMonitorToolData['localState']['mode']>(
    crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.MOVE
  );
  const [deselectAllCrawlsTrigger, setDeselectAllCrawlsTrigger] =
    useState<CrawlMonitorToolData['localState']['deselectAllCrawlsTrigger']>(false);

  const localState: CrawlMonitorToolData['localState'] = {
    search,
    tableParams,
    reloadTrigger,
    appliedSearch,
    searchModal,
    selectedCrawl,
    runNowModalActive,
    showModal,
    isActionDisabled,
    mode,
    error,
    deselectAllCrawlsTrigger
  };

  const reduxState = useSelector<GlobalState, CrawlMonitorToolData['reduxState']>(state => ({
    timedOutCrawlsNumber: externalApiCrawlSelectors.getTimedOutExternalApiCrawl(
      state,
      localState.selectedCrawl
    ),
    crawls: externalApiCrawlSelectors.getExternalApiCrawl(state)
  }));

  const moveMode = useMemo(() => {
    const moveTimedOut = {
      title: crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.MOVE.title,
      body:
        'You have selected ' +
        selectedCrawl.length +
        ' crawls. The ' +
        reduxState.timedOutCrawlsNumber +
        ' Timed Out crawls will be updated to idle. Do you wish to continue?'
    };
    return moveTimedOut;
  }, [reduxState.timedOutCrawlsNumber, selectedCrawl.length]);

  const actionMode = useMemo(() => {
    switch (localState.mode.title) {
      case crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.MOVE.title:
        return moveMode;
      case crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.EXPORT.title:
        return crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.EXPORT;
      case crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.IGNORE.title:
        return crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.IGNORE;
      default:
        return { title: '', body: '' };
    }
  }, [localState.mode.title, moveMode]);

  const localActions: CrawlMonitorToolData['localActions'] = useMemo(
    () => ({
      setSearch,
      setAppliedSearch,
      setSearchModal,
      setReloadTrigger,
      setSelectedCrawls,
      setRunNowModalActive,
      setTableParams,
      setShowModal,
      setIsActionDisabled,
      setMode,
      setError,
      setDeselectAllCrawlsTrigger
    }),
    []
  );
  const formattedData: CrawlMonitorToolData['formattedData'] = useMemo(
    () => ({
      externalTableParams: {
        ...localState.tableParams,
        [uiLib.EXTERNAL_TABLE_PARAMS.TRIGGER_DESELECT_ALL]: localState.deselectAllCrawlsTrigger
      },
      formattedMode: actionMode
    }),
    [localState.deselectAllCrawlsTrigger, localState.tableParams, actionMode]
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
