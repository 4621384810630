import { useMemo } from "react";
import _ from 'lodash';
import { TABLE_VARIABLES } from "./DocumentMetaCategories.constants";

const getDocsMetaCategoryRowInfo = ({
  metCategory,
  metaCategoryInfo
}) => {
  return metaCategoryInfo.map(field => {
    const fieldValue = _.get(metCategory, field, null);
    switch (field) {
      case TABLE_VARIABLES.document_categories:
        return fieldValue.join(', ');
      case TABLE_VARIABLES.app_filter:
        return String(fieldValue);
      default:
        return fieldValue;
    }
  });
};

export default ({
  metaCategoriesFormat,
  metaCategoryInfo
}) => {
  return useMemo(
    () => metaCategoriesFormat.map(metCategory => getDocsMetaCategoryRowInfo({
      metCategory,
      metaCategoryInfo
    })),
    [metaCategoriesFormat, metaCategoryInfo]
  );
};
