import { setNotification, NOTIFICATION_TYPES } from "admin/components/Notification";
import {
  api_getJurisdictions,
  api_postJurisdictions,
  api_putJurisdictions,
  api_deleteJurisdictions
} from './jurisdictionsData.api';
import {
  startLoad,
  finishLoad,
  setJurisdiction,
  createJurisdiction,
  updateJurisdiction,
  deleteJurisdiction,
  initialState
} from './jurisdictionsData.reducer';

const SUCCESS = {
  jurisdictionCreated: 'Jurisdiction successfully created',
  jurisdictionUpdated: 'Jurisdiction successfully updated',
  jurisdictionDeleted: 'Jurisdiction successfully deleted'
};

export const getJurisdictions = params => dispatch => {
  dispatch(startLoad());
  return api_getJurisdictions(params)
    .then(res => dispatch(setJurisdiction(res)))
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const createJurisdictions = params => dispatch => {
  dispatch(startLoad());
  return api_postJurisdictions(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.jurisdictionCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createJurisdiction(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const updateJurisdictions = params => dispatch => {
  dispatch(startLoad());
  return api_putJurisdictions(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.jurisdictionUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateJurisdiction(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const removeJurisdictions = params => dispatch => {
  dispatch(startLoad());
  return api_deleteJurisdictions(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.jurisdictionUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteJurisdiction(params));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const clearJurisdictions = (params) => dispatch => {
  dispatch(finishLoad());
  return dispatch(setJurisdiction(params || initialState));
};
