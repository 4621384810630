import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './NewsSources.constants';
import * as sourcesConstants from 'admin/constants/sources';
import * as elements from './elements';

import { useNewsSourcesData, useNewsSourcesHandlers, useNewsSourcesTableConfig } from './hooks';

import './_news-sources.scss';

export const NewsSources: FC = () => {
  const { localState, localActions } = useNewsSourcesData();

  const handlers = useNewsSourcesHandlers({ localState, localActions });

  const tableConfig = useNewsSourcesTableConfig();

  return (
    <uiLib.ErrorBoundary>
      <div className="news-sources__container">
        <div className="news-sources__filters">
          <elements.NewsSourcesFilters onSubmit={handlers.handleFiltersSubmit} />
          <uiLib.Button onClick={handlers.handleCreateNewsSource}>+ Add News Source</uiLib.Button>
        </div>
        <div className="news-sources__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            rowKey={sourcesConstants.NEWS_SOURCE_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleNewsSourcesFetch}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            onParamsChange={localActions.setTableParams}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            classNames={{
              container: 'news-sources__table-outer-container',
              tableContainer: 'news-sources__table-inner-container',
              toolbar: 'news-sources__table-toolbar',
              headerCell: 'news-sources__table-header-cell',
              selectedRowsCount: 'news-sources__table-selected-rows-count'
            }}
          />
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};
