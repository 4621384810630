import type { DocsModalHandlers } from './useDocsModalHandlers.types';
import type { DocsModalData } from './useDocsModalData.types';
import type { DocsModalProps } from '../DocsModal.types';
import type { ReportDocumentFromResponse } from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.api.types';

import * as errorUtils from 'shared/utils/errors';
import * as reportDocumentsApi from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.api';

import { useCallback } from 'react';

export const useDocsModalHandlers = ({
  props,
  localActions
}: {
  props: Pick<DocsModalProps, 'reportId'>;
  localActions: DocsModalData['localActions'];
}): DocsModalHandlers => {
  const handleDocumentsFetch: DocsModalHandlers['handleDocumentsFetch'] = useCallback(
    async params => {
      try {
        const response = await reportDocumentsApi.api_getReportDocuments(props.reportId, {
          ...params
        });

        return {
          results: response.items,
          count: response.count
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    },
    [props.reportId]
  );

  const handleBulkReprocessModalToggle: DocsModalHandlers['handleBulkReprocessModalToggle'] =
    isOpen => async () => {
      localActions.setIsBulkReprocessModalOpen(isOpen);
    };

  const handleTableRefresh: DocsModalHandlers['handleTableRefresh'] = () => {
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleRowsSelection: DocsModalHandlers['handleRowsSelection'] = rowKeys => {
    localActions.setSelectedDocumentIds(rowKeys.flatMap(rowKey => Number(rowKey) || []));
  };

  const handleSelectAllClick: DocsModalHandlers['handleSelectAllClick'] = async params => {
    try {
      const response = await reportDocumentsApi.api_getReportDocuments(props.reportId, {
        ...params,
        limit: 600
      });

      return response.items.flatMap((item: ReportDocumentFromResponse) => Number(item?.id) || []);
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    handleDocumentsFetch,
    handleBulkReprocessModalToggle,
    handleTableRefresh,
    handleRowsSelection,
    handleSelectAllClick
  };
};
