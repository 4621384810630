import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from '../Modal';
import { api_getExtraReportInfo } from 'shared/features/crawlerSettings/spiderRunReports/spiderRunReports.api';
import { LOG_TYPES } from 'shared/features/spiderRunReports/spiderRunReports.constants';

const TextLogs = ({ text }) =>
  text.split('\n').map(p => (
    <section className="extra-info-modal_paragraph-block" key={p}>
      {p.split(/[;,]+/).map(a => (
        <article key={a}>{a}</article>
      ))}
    </section>
  ));

TextLogs.propTypes = {
  text: PropTypes.string
};

TextLogs.defaultProps = {
  text: ''
};

const VideoPlayer = ({ link }) => (
  <div className="video-container">
    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    <video controls autoPlay>
      <source src={link} />
      Your browser does not support the video tag.
    </video>
  </div>
);

VideoPlayer.propTypes = {
  link: PropTypes.string
};

VideoPlayer.defaultProps = {
  link: ''
};

export const CrawlActivityExtraContentModal = ({ onHide, show }) => {
  const [info, setInfo] = useState('');
  const [type, setType] = useState('');

  const getLogByLink = () => {
    const link = _.get(show, 1);
    if (
      _.isString(link) &&
      (link.includes('https://') ||
        (link.startsWith('http://') && link.includes('/local_file_access')))
    ) {
      try {
        api_getExtraReportInfo(link).then(response => {
          setInfo(response);
        });
      } catch (error) {
        console.log(error);
        setInfo(`Request failed with issue: ${error}`);
      }
    }
  };

  useEffect(() => {
    const logType = _.get(show, 0);
    setType(logType);
    if (
      [
        LOG_TYPES.TEXT_LOG,
        LOG_TYPES.TEXT_METRICS,
        LOG_TYPES.CRAWLER_LOG,
        LOG_TYPES.TIME_TRACKING,
        LOG_TYPES.CRAWLING_METRICS
      ].includes(logType)
    ) {
      getLogByLink();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Modal
      className="crawlActivity-Modal"
      title="Crawl run report info"
      show={!!show}
      onHide={onHide}
      size="medium"
    >
      <div className="extra-info-modal">
        {[
          LOG_TYPES.TEXT_LOG,
          LOG_TYPES.TEXT_METRICS,
          LOG_TYPES.CRAWLER_LOG,
          LOG_TYPES.TIME_TRACKING,
          LOG_TYPES.CRAWLING_METRICS
        ].includes(type) &&
          info?.includes(',') && <TextLogs text={info} />}
        {type === LOG_TYPES.VIDEO_LOG && <VideoPlayer link={_.get(show, 1)} />}
      </div>
    </Modal>
  );
};

CrawlActivityExtraContentModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])
};

CrawlActivityExtraContentModal.defaultProps = {
  show: false
};

export default CrawlActivityExtraContentModal;
