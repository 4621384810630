import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import queryString from 'query-string';
import Snackbar from '@material-ui/core/Snackbar';

import { compose } from 'redux';
import { withAdminPermissions, withHistory } from 'admin/utils/hooks';
import UserModal from './UserInfoModal/UserInfoModal/UserInfoModal';
import DeleteUserModal from './UserDeleteModal/UserDeleteModal';
import { fetchSpecifiedUser, fetchAllUsers } from 'shared/features/user/user.actions';
import { fetchCampaignDetails } from 'shared/features/analytics/analytics.actions';
import { fetchTeams } from 'shared/features/organizations/organizations.actions';
import { fetchAllPlans } from 'shared/features/plans/plans.actions';
import UsersItem from './UsersItem';
import SuspendUserModal from './SuspendUserModal';
import DeleteErrorModal from './DeleteErrorModal';
import { fetchSubscriptions } from 'shared/features/subscriptions/subscriptions.actions';
import { getAllRolesAC } from 'shared/features/userRoles/userRoles.actions';
import { fetchPremiumContentSources } from 'shared/features/premiumContent/premiumContent.actions';
import { getAllPaymentTypesAC } from 'shared/features/paymentTypes/paymentTypes.actions';
import { getAllIndustriesAC } from '../../../shared/features/industries/industries.actions';
import MSnackbar from 'admin/components/MaterialComponents/Snackbar/mSnackbar';
import { CONTRIBUTOR, POWER_USER, QA } from 'app/constants/UserRoles';

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      specified_email: '',
      hide_internal_users: false,
      sortedUsers: [],
      specified_users: {},
      sortField: '',
      sortOrder: 'asc',
      show_suspend_modal: false,
      show_delete_modal: false,
      show_error_modal: false,
      errorText: '',
      show_success_snackbar: false
    };
  }

  componentDidMount() {
    const parsedQuery = queryString.parse(this.props.location.search);

    if (parsedQuery.marketing_campaign_id) {
      this.props.fetchCampaignDetails(parsedQuery.marketing_campaign_id);
    }
    if (this.props.location.search) this.setState({ showModal: true });
    this.props.fetchAllPlans();
    this.props.fetchSubscriptions();
    this.props.getAllRolesAC();
    this.props.fetchPremiumContentSources();
    this.props.getAllPaymentTypesAC();
    this.props.getAllIndustriesAC();
    this.props.fetchTeams();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /*
      Keep track of all the specified users in the state
      so as to make sure only the latest (most-up-to-date)
      user is being passed to the user table
    */
    const specified_users = {};
    if (!_.isEmpty(nextProps.specified_user)) {
      specified_users[nextProps.specified_user.id] = nextProps.specified_user;
    }

    this.setState(state => {
      return {
        specified_users: {
          ...state.specified_users,
          ...specified_users
        },
        sortedUsers: nextProps.all_users.users
      };
    });
  }

  open_user_modal(id) {
    this.props.history.push(`${this.props.location.pathname}?${id}`);
    this.setState({ showModal: true });
  }

  getIcons(sortField) {
    if (this.state.sortField !== sortField) {
      return (
        <span className="sort-icons">
          <i className="material-icons header-arrow">keyboard_arrow_down</i>
          <i className="material-icons header-arrow">keyboard_arrow_up</i>
        </span>
      );
    }
    if (this.state.sortOrder === 'desc') {
      return <i className="material-icons header-arrow active">keyboard_arrow_down</i>;
    }
    return <i className="material-icons header-arrow active">keyboard_arrow_up</i>;
  }

  sort(sortField) {
    let sortOrder;
    if (sortField !== this.state.sortField) {
      sortOrder = 'asc';
    } else {
      sortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
    }
    this.setState(state => {
      const sortedUsers = _.orderBy(
        state.sortedUsers,
        user => {
          if (sortField === 'lastLogin') {
            const lastLogin = user.enabled
              ? moment(user.properties.session_end).format('MM/DD/YYYY')
              : 'N/A';
            return lastLogin;
          }
          if (sortField === 'activatedOn') {
            const activatedOn =
              user.properties && user.properties.activation_time
                ? moment(user.properties.activation_time).format('MM/DD/YYYY')
                : null;
            return activatedOn;
          }
          if (sortField === 'reinvited') {
            const reinvtedOn =
              user.properties && user.properties.resent_invite_time
                ? moment(user.properties.resent_invite_time).format('MM/DD/YYYY')
                : null;
            return reinvtedOn;
          }
          if (sortField === 'createdOn') {
            const createdOn = moment(user.created_at).format('MM/DD/YYYY');
            return createdOn;
          }
          if (sortField === 'confirmedOn') {
            const confirmedOn =
              user.properties && user.properties.confirmed_date
                ? moment(user.properties.confirmed_date).format('MM/DD/YYYY')
                : null;
            return confirmedOn;
          }
          if (sortField === 'confirmationEmailDate') {
            const confirmedOn =
              user.properties && user.properties.confirmation_resent_time
                ? moment(user.properties.confirmation_resent_time).format('MM/DD/YYYY')
                : null;
            return confirmedOn;
          }
          if (sortField === 'invited') {
            return !!user.properties.invited_by;
          }
          if (sortField === 'team') {
            const user_team = !_.isNil(user.team_id)
              ? _.find(this.props.teams, team => {
                  return team.id === user.team_id;
                })
              : { name: '' };
            return user_team.name;
          }
          if (sortField === 'qa') {
            return user.roles.includes(QA);
          }
          if (sortField === 'contributor') {
            return user.roles.includes(CONTRIBUTOR);
          }
          if (sortField === 'powerUser') {
            return user.roles.includes(POWER_USER);
          }
          if (typeof user[sortField] === 'string') {
            return user[sortField].toLowerCase();
          }
          return user[sortField];
        },
        sortOrder
      );
      return { sortedUsers, sortField, sortOrder };
    });
  }

  toggleHide = () => {
    this.setState(state => ({ hide_internal_users: !state.hide_internal_users }));
    this.props.fetchAllUsers();
  };

  render() {
    const parsedQuery = queryString.parse(this.props.location.search);
    const { haveEditAccess } = this.props;
    if (
      !this.state.sortedUsers ||
      !this.props.all_users.isReady ||
      (parsedQuery.marketing_campaign_id && _.isNil(this.props.marketing.details.users))
    ) {
      return null;
    }

    const close = () => {
      this.setState({ showModal: false });
    };

    const closeUserModal = () => {
      this.props.history.push(this.props.location.pathname);
      this.setState({ showModal: false });
    };

    const close_suspend_modal = () => {
      this.setState({ show_suspend_modal: false });
    };

    const open_suspend_modal = email => {
      this.props.fetchSpecifiedUser(email);
      this.setState({ show_suspend_modal: true, specified_email: email });
    };

    const close_delete_modal = () => {
      this.setState({ show_delete_modal: false });
    };

    const open_delete_modal = email => {
      this.props.fetchSpecifiedUser(email);
      this.setState({ show_delete_modal: true, specified_email: email });
    };

    const close_error_modal = () => {
      this.setState({ show_error_modal: false, errorText: '' });
    };

    const close_success_snackbar = () => {
      this.setState({ show_success_snackbar: false });
    };

    let users = parsedQuery.marketing_campaign_id
      ? this.props.marketing.details.users
      : this.state.sortedUsers;

    if (this.state.hide_internal_users) {
      users = users.filter(user => {
        if (user.is_internal_user) {
          return null;
        }
        return user;
      });
    }

    const mountUserModal = () => {
      if (parsedQuery.user_id) {
        return (
          <UserModal
            close={closeUserModal}
            open_user_modal={this.open_user_modal}
            showModal={this.state.showModal}
          />
        );
      }
      return null;
    };

    return (
      <div className="users-container users-div">
        <h1 className="menu">Users</h1>
        <Button
          className="hide_btn menu"
          bsStyle="primary"
          bsSize="xsmall"
          onClick={this.toggleHide}
        >
          {this.state.hide_internal_users ? 'Show Internal Users' : 'Hide Internal Users'}
        </Button>
        <table className="table user-header-table table-hover">
          <thead>
            <tr className="user-header-top">
              <th className="user-header" title="User Email" onClick={() => this.sort('email')}>
                {'Email '}
                {this.getIcons('email')}
              </th>
              <th className="user-header" title="User Name" onClick={() => this.sort('first_name')}>
                {'Name '}
                {this.getIcons('first_name')}
              </th>
              <th
                className="user-header"
                title="User received an email invitation to become a user"
                onClick={() => this.sort('invited')}
              >
                {'Invited'}
                {this.getIcons('invited')}
              </th>
              <th
                className="user-header"
                title="Last date a follow-up invite email was sent"
                onClick={() => this.sort('reinvited')}
              >
                {'Reinvited '}
                {this.getIcons('reinvited')}
              </th>
              <th
                className="user-header"
                title="User has activated invitation or confirmed account after sign up"
                onClick={() => this.sort('enabled')}
              >
                {'Enabled '}
                {this.getIcons('enabled')}
              </th>
              <th className="user-header" title="Internal" onClick={() => this.sort('is_internal')}>
                {'Internal '}
                {this.getIcons('is_internal')}
              </th>
              <th className="user-header" title="User Company" onClick={() => this.sort('company')}>
                {'Company '}
                {this.getIcons('company')}
              </th>
              <th className="user-header" title="User Team" onClick={() => this.sort('team')}>
                {'Team '}
                {this.getIcons('team')}
              </th>
              <th
                className="user-header"
                title="User Industry"
                onClick={() => this.sort('industry')}
              >
                {'Industry '}
                {this.getIcons('industry')}
              </th>
              <th
                className="user-header"
                title="User Discipline"
                onClick={() => this.sort('discipline')}
              >
                {'Discipline '}
                {this.getIcons('discipline')}
              </th>
              <th
                className="user-header"
                title="User Job-title/Level"
                onClick={() => this.sort('level')}
              >
                {'Level '}
                {this.getIcons('level')}
              </th>
              <th
                className="user-header"
                title="Quality Assurance/Annotator"
                onClick={() => this.sort('qa')}
              >
                {'QA '}
                {this.getIcons('qa')}
              </th>
              <th
                className="user-header"
                title="Contributor"
                onClick={() => this.sort('contributor')}
              >
                {'Contributor '}
                {this.getIcons('contributor')}
              </th>
              <th className="user-header" title="Power User" onClick={() => this.sort('powerUser')}>
                {'Power User '}
                {this.getIcons('powerUser')}
              </th>
              <th
                className="user-header"
                title="Date user record created by invitation OR sign up"
                onClick={() => this.sort('lastLogin')}
              >
                {'Created On '}
                {this.getIcons('createdOn')}
              </th>
              <th
                className="user-header"
                title="Date user confirmed account via email sent after sign up"
                onClick={() => this.sort('confirmedOn')}
              >
                {'Confirmed On '}
                {this.getIcons('confirmedOn')}
              </th>
              <th
                className="user-header"
                title="Date user completes registration and accepts TOS by invitation OR sign up"
                onClick={() => this.sort('activatedOn')}
              >
                {'Activated On '}
                {this.getIcons('activatedOn')}
              </th>
              <th className="user-header" title="Last Login" onClick={() => this.sort('lastLogin')}>
                {'Last Login '}
                {this.getIcons('lastLogin')}
              </th>
              <th
                className="user-header"
                title="Account InteractionStatus"
                onClick={() => this.sort('accountStatus')}
              >
                {'Account status '}
                {this.getIcons('accountStatus')}
              </th>
              <th
                className="user-header"
                title="Account Suspended"
                onClick={() => this.sort('suspend')}
              >
                {'Suspend '}
                {this.getIcons('suspend')}
              </th>
              <th
                className="user-header"
                title="Suspension Reason"
                onClick={() => this.sort('suspendedReason')}
              >
                {'Suspended reason '}
                {this.getIcons('suspendedReason')}
              </th>
              <th
                className="user-header"
                title="Resend confirmation email"
                onClick={() => this.sort('confirmationEmailDate')}
              >
                {'Last Confirmation Email Sent '}
                {this.getIcons('confirmationEmailDate')}
              </th>
              <th
                className="user-header"
                title="Resend confirmation email"
                onClick={() => this.sort('confirmationEmail')}
              >
                {'Send Confirmation Email '}
                {this.getIcons('confirmationEmail')}
              </th>
              <th
                className="user-header"
                title="Delete User"
                onClick={() => this.sort('deleteUser')}
              >
                {'Delete User '}
                {this.getIcons('deleteUser')}
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => {
              // replace user here with more update to date specifed user
              // to avoid having to fetch all_users when changing single user data
              let actual_user = user;
              if (user.id === this.props.specified_user.id) {
                actual_user = this.props.specified_user;
              } else if (this.state.specified_users[user.id]) {
                actual_user = this.state.specified_users[user.id];
              }
              return (
                <UsersItem
                  user={actual_user}
                  open_user_modal={this.open_user_modal.bind(this)}
                  close={close}
                  key={user.email}
                  open_suspend_modal={open_suspend_modal}
                  open_delete_modal={open_delete_modal}
                  haveEditAccess={haveEditAccess}
                />
              );
            })}
          </tbody>
        </table>
        {mountUserModal()}
        <SuspendUserModal
          close_suspend_modal={close_suspend_modal}
          showModal={this.state.show_suspend_modal}
        />
        <DeleteUserModal
          showModal={this.state.show_delete_modal}
          email={this.state.specified_email}
          close={close_delete_modal}
        />
        <DeleteErrorModal
          close_error_modal={close_error_modal}
          showModal={this.state.show_error_modal}
          email={this.state.specified_email}
          errorText={this.state.errorText}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.show_success_snackbar}
          onClose={close_success_snackbar}
          message={
            <span style={{ fontSize: 14 }}>
              {this.state.specified_email + ' successfully deleted'}
            </span>
          }
          autoHideDuration={5000}
        />
        {this.props.openSnackbar && <MSnackbar />}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAllUsers: () => {
      dispatch(fetchAllUsers());
    },
    fetchSpecifiedUser: email => {
      dispatch(fetchSpecifiedUser(email));
    },
    fetchCampaignDetails: id => {
      dispatch(fetchCampaignDetails(id));
    },
    fetchTeams: () => {
      dispatch(fetchTeams());
    },
    fetchAllPlans: () => {
      dispatch(fetchAllPlans());
    },
    fetchSubscriptions: () => {
      dispatch(fetchSubscriptions());
    },
    getAllRolesAC: () => {
      dispatch(getAllRolesAC());
    },
    fetchPremiumContentSources: () => {
      dispatch(fetchPremiumContentSources());
    },
    getAllPaymentTypesAC: () => {
      dispatch(getAllPaymentTypesAC());
    },
    getAllIndustriesAC: () => {
      dispatch(getAllIndustriesAC());
    }
  };
};

const mapStateToProps = state => {
  return {
    specified_user: state.specified_user.user,
    all_users: state.all_users,
    teams: state.teams.all_teams,
    marketing: state.marketing,
    viewer: state.current_user,
    openSnackbar: state.snackbar.open
  };
};

export default compose(
  withAdminPermissions,
  withHistory,
  connect(mapStateToProps, mapDispatchToProps)
)(Users);
