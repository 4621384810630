import type { GlobalState } from '../../reducers';
import type { UserFromResponse } from './user.api.types';

import { createSelector } from 'reselect';

export const getCurrentUserReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.current_user
);

export const getCurrentUser = createSelector(
  getCurrentUserReducer,
  (currentUserReducer): UserFromResponse => {
    return currentUserReducer.user;
  }
);

export const getCurrentUserFullName = createSelector(getCurrentUser, (user): string => {
  return `${user.first_name} ${user.last_name}`;
});

export const getCurrentIsFetching = createSelector(
  getCurrentUserReducer,
  (currentUserReducer): boolean => {
    return currentUserReducer.isFetching;
  }
);

export const getCurrentUserRoles = createSelector(
  getCurrentUser,
  (currentUser): UserFromResponse['roles'] => {
    return currentUser.roles ?? [];
  }
);

export const getCurrentUserId = createSelector(
  getCurrentUser,
  (currentUser): UserFromResponse['id'] => {
    return currentUser.id;
  }
);

export const getCurrentUserOrgId = createSelector(
  getCurrentUser,
  (currentUser): UserFromResponse['org_id'] => {
    return currentUser.org_id;
  }
);

export const getCurrentIsInternal = createSelector(
  getCurrentUser,
  (currentUser): UserFromResponse['is_internal_user'] => {
    return currentUser.is_internal_user;
  }
);

export const getCurrentUserFullname = createSelector(getCurrentUser, (currentUser): string => {
  return `${currentUser.first_name}${
    currentUser.last_name ? ` ${currentUser.last_name}` : ''
  }`.trim();
});

export const getCurrentUserEmail = createSelector(getCurrentUser, (currentUser): string => {
  return `${currentUser.email}`;
});

export const getUsersReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.all_users
);

export const getUsers = createSelector(
  getUsersReducer,
  (all_users): UserFromResponse[] => all_users.users
);

export const getUsersEmails = createSelector(
  getUsers,
  (users): { [key: string | number]: string } => {
    return users.reduce(
      (userObj, user) => ({
        ...userObj,
        [user.id]: user.email
      }),
      {}
    );
  }
);
