import React from "react";
import PropTypes from "prop-types";
import DatePicker, { CalendarContainer } from 'react-datepicker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import cn from 'classnames';
import Typography from "./Typography";

function CustomDatePicker({
  className,
  labelClassName,
  pickerClassName,
  label,
  onChange,
  isError,
  isDisabled,
  classNamePoper,
  extraContent,
  selected,
  startDate,
  endDate,
  selectsRange,
  monthsShown,
  inline,
  value
}) {
  const CustomCalendarContainer = ({ children }) => (
    <CalendarContainer
      className={cn(
        "react-datepicker-popper_container",
        classNamePoper
      )}
    >
      {extraContent}
      <div
        className={cn(
          "react-datepicker-popper_container_pickers",
          pickerClassName
        )}
      >
        {children}
      </div>
    </CalendarContainer>
  );

  return (
    <label className={cn(
      'custom-date-picker_wrapper',
      { 'custom-date-picker_wrapper_error': isError },
      className
    )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
      </Typography>
      <DatePicker
        calendarContainer={CustomCalendarContainer}
        selected={selected}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        monthsShown={monthsShown}
        selectsRange={selectsRange}
        inline={inline}
        value={value}
        disabled={isDisabled}
      />
      <CalendarTodayIcon />
    </label>
  );
}


CustomDatePicker.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectsRange: PropTypes.bool,
  inline: PropTypes.bool,
  className: PropTypes.string,
  classNamePoper: PropTypes.string,
  labelClassName: PropTypes.string,
  pickerClassName: PropTypes.string,
  label: PropTypes.string,
  monthsShown: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

CustomDatePicker.defaultProps = {
  isError: false,
  isDisabled: false,
  selectsRange: false,
  inline: false,
  className: '',
  classNamePoper: '',
  labelClassName: '',
  pickerClassName: '',
  label: '',
  monthsShown: 1
};


export default CustomDatePicker;
