import type { BulkEditModalQueriesFormattedData } from './useBulkEditModalQueriesFormattedData.types';
import type { useBulkEditModalQueries } from './useBulkEditModalQueries';

import * as queriesFormattedDataHelpers from './useBulkEditModalQueriesFormattedData.helpers';

import { useMemo } from 'react';

export const useBulkEditModalQueriesFormattedData = ({
  queries
}: {
  queries: ReturnType<typeof useBulkEditModalQueries>;
}): BulkEditModalQueriesFormattedData => {
  return useMemo(() => {
    const selectedDocuments = queries.getSelectedDocumentsQuery.data ?? [];

    return {
      selectedDocumentLabelsOptions:
        queriesFormattedDataHelpers.getUniqueDocumentsEitlLabels(selectedDocuments)
    };
  }, [queries.getSelectedDocumentsQuery.data]);
};
