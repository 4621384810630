import _ from 'lodash';
import {
  DOC_ACTIONS_TREE,
  docStatusStyle,
  DOCUMENT_GENERAL,
  STATUSES_TREE
} from '../../../../../DocumentDetails.constants';
import { DOCUMENT_PIPELINE_STATUSES } from '../../../../../../../../constants/docPipelineStatuses';
import { useMemo } from 'react';
import { getDocStatusColor } from '../../../../../../../../../utils/document';
import { DocumentDetailsTabsData } from '../../../hooks/useDocumentDetailsTabsData.types';
import { DocumentDetailsFieldsData } from './useDocumentDetailsFieldsData.types';
import type { SelectOption } from '@compliance.ai/web-components';

export const useDocumentDetailsFieldsData = ({
  editMode,
  docDetails
}: {
  editMode: DocumentDetailsTabsData['localState']['editMode'];
  docDetails: DocumentDetailsTabsData['localState']['docDetails'];
}): DocumentDetailsFieldsData => {
  const pipelineStatus = _.get(docDetails, [DOCUMENT_GENERAL.pipelineStatus, 'value']);
  const parentPipelineStatus = _.get(docDetails, [DOCUMENT_GENERAL.parentStatus, 'value']);
  // count tooltip
  const labelText = docDetails[DOCUMENT_GENERAL.document_examination_record] || [];
  const label = `${
    labelText && (labelText as string[]).length ? (labelText as string[]).join(', ') : 'unknown'
  } issue`;
  const extraInfo = [
    DOCUMENT_PIPELINE_STATUSES.PROBLEMATIC.type,
    DOCUMENT_PIPELINE_STATUSES.INCOMPLETE.type
  ].includes(pipelineStatus);

  const disableEdit = !editMode;

  const getStatusStyle = useMemo(
    () => docStatusStyle[getDocStatusColor(pipelineStatus)],
    [pipelineStatus]
  );

  const getStatusOptions = useMemo(
    () =>
      _.get(STATUSES_TREE, pipelineStatus, []).map(
        item => ({ value: item, label: item } as SelectOption)
      ),
    [pipelineStatus]
  );

  const getActionOptions = useMemo(
    () =>
      _.get(DOC_ACTIONS_TREE, pipelineStatus, []).map(item => ({
        value: item.type,
        label: item.label
      })),
    [pipelineStatus]
  );

  const getParentStatusStyle = useMemo(
    () => (!!parentPipelineStatus ? docStatusStyle[getDocStatusColor(parentPipelineStatus)] : null),
    [parentPipelineStatus]
  );

  return {
    formattedData: {
      label,
      extraInfo,
      disableEdit,
      getStatusStyle,
      getStatusOptions,
      getActionOptions,
      getParentStatusStyle
    }
  };
};
