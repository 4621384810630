import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { safe_mixpanel_track } from 'shared/utils/analytics';
import ErrorBoundary from 'app/common/ErrorBoundary';
import { clearAutoComplete } from 'shared/features/search/search.actions';
import { closeOverlay } from 'shared/features/view/view.actions';
import { updateDashboardLayout, postSearchQuery } from 'shared/features/user/user.actions';
import { FINANCE_REGULATORY, INSIGHTS } from 'app/constants/UserDashboardTypes';
import {getUserAuthenticatedState} from "../../../shared/features/auth/auth.selectors";

const REMOVE_COMPONENT_ACTION = 'remove';
const MOVE_COMPONENT_ACTION = 'move';
const ADD_COMPONENT_ACTION = 'add';
const GRID_BREAKPOINTS = { lg: 992, sm: 0 };

const ResponsiveGridLayout = WidthProvider(Responsive);

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    const {
      components,
      layout
    } = this.getDashboardLayout(props.current_user.user.dashboard_layouts);

    this.state = {
      loading: this.props.isAuthenticated && !props.current_user.isReady,
      components,
      layout,
      updatedDimensions: null
    };
  }

  getDashboardLayout = dashboard_layouts => {
    const foundDash = _.find(dashboard_layouts, { dashboard_type: this.props.dashboardType }) || {};

    return {
      components: foundDash.components || this.props.defaultComponents,
      layout: foundDash.layout || this.props.defaultLayout
    };
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.closeOverlay();
    this.props.clearAutoComplete();
  }

  componentDidUpdate(prevProps) {
    if (this.props.current_user.isReady
      && prevProps.componentDimensions !== this.props.componentDimensions
    ) {
      const stateLayoutCopy = _.cloneDeep(this.state.layout);
      const updatedDimensions = Object.entries(_.cloneDeep(this.props.componentDimensions))
        .forEach(([dimension, value]) => {
          const updatedWidget =_.find(stateLayoutCopy.lg, { i: dimension });
          if (!_.isEqual(value, prevProps.componentDimensions[dimension]) && updatedWidget) {
            updatedWidget.w = _.get(value, ['lg', 'w'], 1);
          }
        });
      this.setState({ updatedDimensions });
      const newLayout = Object.keys(stateLayoutCopy).reduce((layout, size) => {
        layout[size] = stateLayoutCopy[size].map(({ i, h, w, x, y }) => ({ i, h, w, x, y }));
        return layout;
      }, {});
      this.handleLayoutChange(newLayout);
    }
    if (!prevProps.current_user.isReady && this.props.current_user.isReady) {
      this.setState({
        loading: false,
        ...this.getDashboardLayout(this.props.current_user.user.dashboard_layouts)
      });
    }

    if (
      (JSON.stringify(prevProps.userComponents) !== JSON.stringify(this.props.userComponents))
      && this.props.userComponents.length
    ) {
      const newOptions = this.props.userComponents.filter(component => {
        return !prevProps.userComponents.includes(component);
      });
      newOptions.forEach(option => {
        this.handleSelectComponentOption(option);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleComponentAction(componentAction, componentActionName) {
    switch (componentAction) {
      case REMOVE_COMPONENT_ACTION:
        safe_mixpanel_track(`Dashboard - ${this.props.dashboardType} – Remove Widget`, {
          eventCategory: 'Dashboard',
          eventAction: 'Remove component',
          eventLabel: componentActionName
        });
        break;

      case ADD_COMPONENT_ACTION:
        safe_mixpanel_track(`Dashboard - ${this.props.dashboardType} – Add Widget`, {
          eventCategory: 'Dashboard',
          eventAction: 'Add component',
          eventLabel: componentActionName
        });
        break;

      case MOVE_COMPONENT_ACTION:
        safe_mixpanel_track(`Dashboard - ${this.props.dashboardType} – Move Widget`, {
          eventCategory: 'Dashboard',
          eventAction: 'Move component',
          eventLabel: componentActionName
        });
        break;

      default:
        return;
    }
  }

  handleLayoutChange = (allLayouts, componentAction, componentActionName) => {
    // only want to compare relevant properties
    const stateLayout = Object.keys(this.state.layout).reduce((layout, size) => {
      layout[size] = this.state.layout[size].map(({ i, h, w, x, y }) => ({ i, h, w, x, y }));
      return layout;
    }, {});
    const newLayout = Object.keys(allLayouts).reduce((layout, size) => {
      layout[size] = allLayouts[size].map(({ i, h, w, x, y }) => ({ i, h, w, x, y }));
      return layout;
    }, {});

    this.handleComponentAction(componentAction, componentActionName);

    if (!_.isEqual(stateLayout, newLayout) || componentAction) {
      this.setState({ layout: newLayout });
      if (this.props.isAuthenticated) {
        this.props.updateDashboardLayout({
          components: this.state.components,
          layout: newLayout,
          dashboard_type: this.props.dashboardType
        });
      }
    }
  };

  handleSelectComponentOption = option => {
    let newComponents = [...this.state.components];
    const dimensions = this.state.updatedDimensions || this.props.componentDimensions;
    let newLayout;
    if (newComponents.includes(option)) {
      newComponents = newComponents.filter(component => component !== option);
      newLayout = Object.keys(this.state.layout).reduce((layout, size) => {
        layout[size] = this.state.layout[size].filter(component => component.i !== option);
        return layout;
      }, {});
      // grid layout doesn't always change when removing a child
      this.setState(
        { components: newComponents, layout: newLayout },
        () => this.handleLayoutChange(newLayout, REMOVE_COMPONENT_ACTION, option)
      );
    } else {
      newComponents.push(option);
      const maxY = Object.keys(this.state.layout).reduce((currentMaxY, size) => {
        return Math.max(...this.state.layout[size].map(component => component.y || 0), currentMaxY);
      }, 0);
      newLayout = Object.keys(this.state.layout).reduce((layout, size) => {
        layout[size] = [
          ...this.state.layout[size],
          { ...dimensions[option][size], i: option, x: 0, y: maxY + 1 }
        ];
        return layout;
      }, {});
      this.setState(
        { components: newComponents, layout: newLayout },
        () => this.handleLayoutChange(newLayout, ADD_COMPONENT_ACTION, option)
      );
    }
  };

  render() {
    const grid_components = this.state.components
      .filter(key => _.has(this.props.layoutComponentMapping, key))
      .map(key => {
        const { component: content } = this.props.layoutComponentMapping[key];
        return (
          <div key={key} className="widget-container">
            {content}
          </div>
        );
      });

    const component_options = _.orderBy(
      Object.keys(this.props.layoutComponentMapping),
      key => this.props.layoutComponentMapping[key].name
    ).map(option => (
      <MenuItem
        eventKey={option}
        key={option}
        onSelect={() => this.handleSelectComponentOption(option)}
      >
        <div className="component-option">
          {this.state.components.includes(option) ? (
            <CheckBoxIcon className="component-option-checkbox checked" />
          ) : (
            <CheckBoxOutlineBlankIcon className="component-option-checkbox" />
          )}
          {this.props.layoutComponentMapping[option].name}
        </div>
      </MenuItem>
    ));

    return (
      <div className="newDashboardContainer container-fluid loading-overlay-light" data-testid="dashboard-default-container">
        <div className="dash-header">
          {this.props.title
            && (
              <div className="greeting">
                <h1>{this.props.title}</h1>
              </div>
            )
          }
          {!this.state.loading && (
            <div className="component-dropdown">
              <DropdownButton
                open={this.state.component_dropdown_open}
                title="Customize"
                id="component-dropdown-button"
                className="component-dropdown-button"
                pullRight
                onToggle={(newValue, _event, { source }) => {
                  if (newValue || source !== 'select') {
                    this.setState({ component_dropdown_open: newValue });
                  }
                }}
              >
                {component_options}
              </DropdownButton>
            </div>
          )}
        </div>
        {this.state.loading ? (
          <div>loading...</div>
        ) : (
          <ErrorBoundary>
            <ResponsiveGridLayout
              className="layout dash-grid"
              layouts={this.state.layout}
              breakpoints={GRID_BREAKPOINTS}
              cols={this.props.gridCols}
              rowHeight={this.props.gridRowHeight}
              isResizable={false}
              measureBeforeMount
              useCSSTransforms={false}
              draggableHandle=".dash-drag-handle"
              onDragStop={
                (ignore, widget) => this.handleComponentAction(MOVE_COMPONENT_ACTION, widget.i)
              }
              onLayoutChange={(_currentLayout, allLayouts) => this.handleLayoutChange(allLayouts)}
            >
              {grid_components}
            </ResponsiveGridLayout>
          </ErrorBoundary>
        )}
      </div>
    );
  }
}

const layoutPropTypes = PropTypes.arrayOf(PropTypes.shape({
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  i: PropTypes.string.isRequired
})).isRequired;

Dashboard.propTypes = {
  defaultComponents: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultLayout: PropTypes.shape({
    lg: layoutPropTypes,
    sm: layoutPropTypes
  }).isRequired,
  componentDimensions: PropTypes.shape({}).isRequired,
  layoutComponentMapping: PropTypes.shape({}).isRequired,
  dashboardType: PropTypes.oneOf([
    FINANCE_REGULATORY,
    INSIGHTS
  ]).isRequired,
  gridCols: PropTypes.shape({
    lg: PropTypes.number.isRequired,
    sm: PropTypes.number.isRequired
  }),
  gridRowHeight: PropTypes.number
};

Dashboard.defaultProps = {
  gridCols: { lg: 3, sm: 1 },
  gridRowHeight: 320
};

const mapStateToProps = (state) => {
  return { current_user: state.current_user, isAuthenticated: getUserAuthenticatedState(state) };
};

export default connect(mapStateToProps, {
  closeOverlay,
  clearAutoComplete,
  postSearchQuery,
  updateDashboardLayout
})(Dashboard);
