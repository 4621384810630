import React from 'react';
import { useHistory } from './useHistory';
import {useLocation, useParams} from 'react-router-dom';

/**
 *
 * Note: use this HOC only for legacy class components
 *
 */
export const withHistory = Component => {
  return function WrappedComponent(props) {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    return <Component {...props} history={history} location={location} match={{ params }} />;
  };
};
