import type { BulkEditModalRuleProps, BulkEditAttributeOption } from '../BulkEditModalRule.types';

import * as modalConstants from '../../../BulkEditModal.constants';
import * as constants from '../BulkEditModalRule.constants';

export const getBulkEditRuleAttributes = ({
  action
}: Pick<BulkEditModalRuleProps, 'action'>): modalConstants.BULK_EDIT_ATTRIBUTES[] => {
  if (!action) {
    return [];
  }

  return constants.BULK_EDIT_ACTION_ATTRIBUTES[action];
};

export const getBulkEditAttributesOptions = (
  props: Pick<BulkEditModalRuleProps, 'action'>
): BulkEditAttributeOption[] => {
  const ruleAttributes = getBulkEditRuleAttributes(props);

  return constants.BULK_EDIT_ATTRIBUTES_OPTIONS.filter(bulkEditActionOption =>
    ruleAttributes.includes(bulkEditActionOption.value)
  );
};
