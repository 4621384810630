import type { GlobalState } from 'shared/reducers';
import type { SourcePageData } from './useSourcePageData.types';

import * as newsSourcesSelectors from 'shared/features/sources/newsSources/newsSources.selectors';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useSourcePageData = (): SourcePageData => {
  const { id } = useParams();

  const reduxState: SourcePageData['reduxState'] = useSelector<
    GlobalState,
    SourcePageData['reduxState']
  >(state => ({
    newsSource: newsSourcesSelectors.getFirstNewsSource(state)
  }));

  const formattedData: SourcePageData['formattedData'] = useMemo(
    () => ({
      newsSourceId: id,
      isActive: reduxState.newsSource?.active
    }),
    [id, reduxState.newsSource?.active]
  );

  return {
    reduxState,
    formattedData
  };
};
