import type { AppHandlers } from './useAppHandlers.types';

import { useEffect } from 'react';

export const useAppLifecycle = ({
  onInitialDataFetch,
  onMixpanelSetup
}: {
  onInitialDataFetch: AppHandlers['handleInitialDataFetch'];
  onMixpanelSetup: AppHandlers['handleMixpanelSetup'];
}) => {
  useEffect(() => {
    onInitialDataFetch();
  }, [onInitialDataFetch]);

  useEffect(() => {
    onMixpanelSetup();
  }, [onMixpanelSetup]);
};
