import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CrawlActivityHeaderSearchModalBtnBlock from './CrawlActivityHeaderSearchModalBtnBlock';
import Select from '../../../../../../../../../components/ReactSelect/Select';
import { TABLE_VARIABLES } from '../../../../CrawlActivityTable.constants';
import { RUN_TYPES } from './CrawlActivityHeaderSearchModal.constants';
import CrawlActivityHeaderSearchGapSelector from './CrawlActivityHeaderSearchGapSelector';
import CrawlActivityHeaderSearchGapTime from './CrawlActivityHeaderSearchGapTime';

export const CrawlActivityHeaderSearchModal = ({
  clearSearch,
  requestCrawls,
  changeFilter,
  search,
  handleChangesEvent,
  show
}) => {
  return (
    <div
      className={cn('crawlActivity_header_search-modal', {
        'crawlActivity_header_search-modal_visible': show
      })}
    >
      <div className="body-search-block">
        <CrawlActivityHeaderSearchGapTime
          label="Date Run Start"
          handleChanges={changeFilter}
          name={TABLE_VARIABLES.run_start}
          search={search}
        />
        <Select
          label="Run Type"
          value={search[TABLE_VARIABLES.run_type]}
          onChange={e => changeFilter(TABLE_VARIABLES.run_type, e)}
          options={Object.values(RUN_TYPES)}
          isClearable
        />
        <CrawlActivityHeaderSearchGapSelector
          label="Ingested"
          name={TABLE_VARIABLES.docs_ingested}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlActivityHeaderSearchGapSelector
          label="Updated"
          name={TABLE_VARIABLES.docs_updated}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlActivityHeaderSearchGapSelector
          label="Skipped"
          name={TABLE_VARIABLES.docs_skipped}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlActivityHeaderSearchGapSelector
          label="Failed"
          name={TABLE_VARIABLES.docs_failed}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlActivityHeaderSearchGapSelector
          label="Processed"
          name={TABLE_VARIABLES.docs_processed}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
      </div>
      <CrawlActivityHeaderSearchModalBtnBlock
        clearSearch={clearSearch}
        requestCrawls={requestCrawls}
      />
    </div>
  );
};

CrawlActivityHeaderSearchModal.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  requestCrawls: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  search: PropTypes.shape({}),
  show: PropTypes.bool
};

CrawlActivityHeaderSearchModal.defaultProps = {
  search: {},
  show: false
};
