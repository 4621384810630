import type { CategoryHandlers } from './useScrapedCategoriesHandlers.types';
import type { CategoryData } from './useScrapedCategoriesData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as scrapedCategoryDataApi from 'shared/features/categories/scrapedCategories/scrapedCategories.apiV2';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useScrapedCategoriesHandlers.helpers';

export const useScrapedCategoriesHandlers = ({
  localState,
  localActions
}: {
  localState: CategoryData['localState'];
  localActions: CategoryData['localActions'];
}): CategoryHandlers => {
  const handleCategoriesFetch: CategoryHandlers['handleCategoriesFetch'] = async (
    params,
    options
  ) => {
    try {
      const categoriesResponse = await scrapedCategoryDataApi.fetchScrapedCategoriesData({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      return {
        results: categoriesResponse.items,
        count: categoriesResponse.count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleFiltersSubmit: CategoryHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  return {
    handleCategoriesFetch,
    handleFiltersSubmit
  };
};
