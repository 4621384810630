import { FC } from 'react';

import { useCrawlerHistoryData } from './hooks/useCrawlerHistoryData';
import { useCrawlerHistoryHandlers } from './hooks/useCrawlerHistoryHandlers';
import { useCrawlerHistoryLifecycle } from './hooks/useCrawlerHistoryLifecycle';

import { CrawlerHistoryElement } from './CrawlerHistoryElement';

import './_crawler-history.scss';

export const CrawlerHistory: FC = () => {
  const { reduxState, formattedData } = useCrawlerHistoryData();
  const { handleRequestHistory } = useCrawlerHistoryHandlers({ formattedData });

  useCrawlerHistoryLifecycle({ onInitialisation: handleRequestHistory });

  return (
    <div className="crawler-history">
      {reduxState.history.map(history => (
        <CrawlerHistoryElement key={history.id} history={history} />
      ))}
    </div>
  );
};
