import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import PDFobjecct from "pdfobject";
import DocumentRelationsInfo from './DocumentRelationsInfo';

const DocumentRelations = ({ docDetailsInfo, docTimestamp, user }) => {
  const getPDFurl = () => {
    return _.get(docDetailsInfo, ['document_versions', 0, 'file_s3_url']);
  };

  const [docRelation, setDocRelation] = useState(_.get(docDetailsInfo, ['document_relations', 0], {}));

  useEffect(() => {
    PDFobjecct.embed(getPDFurl(), 'div#document-text');
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docTimestamp]);

  const changeCurrentDocRelation = id => setDocRelation(
    _.get(docDetailsInfo, 'document_relations', []).find(item => item.id === id)
  );

  return (
    <div className="document-list">
      <div className="document-text" id="document-text" />
      <div className="document-list_details">
        <DocumentRelationsInfo
          docDetailsInfo={docDetailsInfo}
          currentDocRelation={docRelation}
          changeCurrentDocRelation={changeCurrentDocRelation}
          user={user}
        />
      </div>
    </div>
  );
};

DocumentRelations.propTypes = {
  docDetailsInfo: PropTypes.shape({}).isRequired,
  docTimestamp: PropTypes.string,
  user: PropTypes.shape({}).isRequired
};

DocumentRelations.defaultProps = {
  docTimestamp: ''
};

export default DocumentRelations;
