import type { EitlLabelDropdownData } from './useEitlLabelCreateDropdownData.types';

import { useMemo, useState } from 'react';

export const useEitlLabelCreateDropdownData = (): EitlLabelDropdownData => {
  const [isOpen, setIsOpen] = useState<EitlLabelDropdownData['localState']['isOpen']>(false);

  const localState: EitlLabelDropdownData['localState'] = {
    isOpen
  };

  const localActions: EitlLabelDropdownData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
