import { api_getWidgets, api_saveWidget, api_deleteWidget } from './widgets.api';

export const GET_WIDGETS = 'GET_WIDGETS';

export const fetchWidgets = () => async dispatch => {
  try {
    const res = await api_getWidgets();
    dispatch({ type: GET_WIDGETS, payload: res });
    return res;
  } catch (error) {
    return error;
  }
};

export const saveWidget = (payload) => async dispatch => {
  try {
    const res = await api_saveWidget(payload);
    dispatch({ type: GET_WIDGETS, payload: res });
  } catch (error) {
    // pass
  }
};

export const deleteWidget = ({ widgetId }) => async dispatch => {
  try {
    const res = await api_deleteWidget({ widgetId });
    dispatch({ type: GET_WIDGETS, payload: res });
  } catch (error) {
    // pass
  }
};
