import type { BulkReprocessModalRule } from './useBulkReprocessModalData.types';

import * as hookConstants from './useBulkReprocessModalData.constants';

export const getNewRuleId = (rules: BulkReprocessModalRule[]): BulkReprocessModalRule['id'] => {
  return Math.max(...rules.map(({ id }) => id), 0) + 1;
};

export const getNewEmptyRule = (id: BulkReprocessModalRule['id']): BulkReprocessModalRule => {
  return {
    id,
    ...hookConstants.BULK_REPROCESS_MODAL_EMPTY_RULE
  };
};
