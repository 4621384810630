import type { ExportData } from './useExportData.types';

import * as exportConstants from 'admin/constants/Export';

import { useState, useMemo } from 'react';

export const useExportData = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [contentType, setContentType] = useState(
    exportConstants.CONTENT_TYPES.CSV as keyof typeof exportConstants.CONTENT_TYPES
  );

  const localState: ExportData['localState'] = {
    isDownloading,
    isExportModalOpen,
    contentType
  };

  const localActions: ExportData['localActions'] = useMemo(
    () => ({
      setIsDownloading,
      setIsExportModalOpen,
      setContentType
    }),
    [setIsDownloading, setIsExportModalOpen, setContentType]
  );

  return {
    localState,
    localActions
  };
};
