import React from 'react';

import { getCrawlerStatistic } from 'shared/features/crawlerSettings/crawlerActivity/crawlerActivity.actions';

import * as constants from './CrawlActivityBillboard.constants';
import * as elements from './elements';

import { useParams } from 'react-router-dom';
import { useDidMount } from '@compliance.ai/web-components';
import { useDispatch, useSelector } from 'react-redux';

// TODO Refactor
export const CrawlActivityBillboard = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const crawlerActivity = useSelector(state => state.crawlerActivity);

  useDidMount(() => {
    dispatch(getCrawlerStatistic(id));
  });

  return (
    <div className="billboardWrapper">
      {constants.CONFIG.map((blockInfo, index) => (
        <elements.CrawlActivityBillboardBlock
          key={index}
          blockInfo={blockInfo}
          crawlerActivity={crawlerActivity}
        />
      ))}
    </div>
  );
};
