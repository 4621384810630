import type { ComponentProps } from 'react';
import type { DocsModalData } from './useDocsModalData.types';
import type { DocsModalHandlers } from './useDocsModalHandlers.types';
import type { useDocsModalMutations } from './useDocsModalMutations';

import * as uiLib from '@compliance.ai/web-components';
import * as docPipelineStatusesConstants from 'admin/constants/docPipelineStatuses';
import * as constants from 'shared/features/documentData/documentData.constants';
import * as elements from 'admin/containers/DocumentManagement/elements';

export const useDocsModalTableActions = ({
  localState,
  events
}: {
  localState: DocsModalData['localState'];
  events: {
    onBulkReprocessClick: ReturnType<DocsModalHandlers['handleBulkReprocessModalToggle']>;
    onStatusAction: ReturnType<
      typeof useDocsModalMutations
    >['updateDocumentDataFlagMutation']['mutateAsync'];
  };
}): Required<ComponentProps<typeof uiLib.Table>>['actions'] => {
  return [
    {
      title: 'Deprecate',
      icon: <uiLib.IconThumbDown />,
      shouldPreventRefreshOnTrigger: true,
      disableRow: true,
      disabled: !localState.selectedDocumentIds.length,
      confirmationDialogMessage: `Are you sure you want to deprecate ${localState.selectedDocumentIds.length} documents?`,
      trigger: async () => {
        await events.onStatusAction({
          selectedDocumentIds: localState.selectedDocumentIds,
          variables: [
            {
              action: constants.DocumentsBulkUpdateActions.EDIT,
              pipelineStatus:
                docPipelineStatusesConstants.DOCUMENT_PIPELINE_STATUSES.DEPRECATED.type,
              bulkAction: docPipelineStatusesConstants.DOCUMENT_BULK_ACTIONS.DEPRECATE.type
            }
          ]
        });
      }
    } as uiLib.ColumnAction,
    {
      title: 'Undeprecate',
      icon: <uiLib.IconThumbUp />,
      shouldPreventRefreshOnTrigger: true,
      disableRow: true,
      disabled: !localState.selectedDocumentIds.length,
      confirmationDialogMessage: `Are you sure you want to undeprecate ${localState.selectedDocumentIds.length} documents?`,
      trigger: async () => {
        await events.onStatusAction({
          selectedDocumentIds: localState.selectedDocumentIds,
          variables: [
            {
              action: constants.DocumentsBulkUpdateActions.EDIT,
              pipelineStatus:
                docPipelineStatusesConstants.DOCUMENT_PIPELINE_STATUSES.UNDEPRECATED.type,
              bulkAction: docPipelineStatusesConstants.DOCUMENT_BULK_ACTIONS.UNDEPRECATE.type
            }
          ]
        });
      }
    } as uiLib.ColumnAction,
    {
      title: 'Un-Duplicate',
      icon: <uiLib.IconEnforcement />,
      shouldPreventRefreshOnTrigger: true,
      disableRow: true,
      disabled: !localState.selectedDocumentIds.length,
      confirmationDialogMessage: `Are you sure you want to unduplicate ${localState.selectedDocumentIds.length} documents?`,
      trigger: async () => {
        await events.onStatusAction({
          selectedDocumentIds: localState.selectedDocumentIds,
          variables: [
            {
              action: constants.DocumentsBulkUpdateActions.EDIT,
              pipelineStatus: docPipelineStatusesConstants.DOCUMENT_PIPELINE_STATUSES.ORIGINAL.type,
              bulkAction: docPipelineStatusesConstants.DOCUMENT_BULK_ACTIONS.UNDUPLICATE.type
            }
          ]
        });
      }
    } as uiLib.ColumnAction,
    {
      title: 'Reprocess',
      icon: <uiLib.IconFactCheck />,
      shouldPreventRefreshOnTrigger: true,
      shouldKeepSelectedRows: true,
      disableRow: true,
      disabled: !localState.selectedDocumentIds.length,
      trigger: async () => events.onBulkReprocessClick()
    } as uiLib.ColumnAction,
    {
      title: '',
      icon: (
        <elements.Export
          selectedDocumentIds={localState.selectedDocumentIds}
          onStatusAction={events.onStatusAction}
        />
      ),
      shouldPreventRefreshOnTrigger: true,
      shouldKeepSelectedRows: true,
      disableRow: true,
      disabled: !localState.selectedDocumentIds.length
    } as uiLib.ColumnAction
  ];
};
