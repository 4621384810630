import lodash from 'lodash';
import getBase64FromFile from 'admin/utils/getBase64FromFile';

import { SOURCE_TYPES } from 'admin/constants/sources';
import * as constants from './SourceDetails.constants';

// ---------------- GET Source Info ----------------

const getSourceType = () => {
  const sourceType = SOURCE_TYPES.NEWS;
  return sourceType ? { value: sourceType, label: sourceType } : null;
};

// convert server news source info to screen news source details
export const fromRawInfoToDetails = newsSourceInfo => ({
  // general info
  [constants.SOURCE_GENERAL.source_type]: getSourceType(),
  [constants.SOURCE_GENERAL.name]: lodash.get(newsSourceInfo, 'name', ''),
  [constants.SOURCE_GENERAL.id]: lodash.get(newsSourceInfo, 'id', 'new'),
  // logo
  [constants.SOURCE_LOGO.logo_image]: [], // array
  [constants.SOURCE_LOGO.logo_url]: lodash.get(newsSourceInfo, 'logo_url', ''),
  // privacy
  [constants.SOURCE_PRIVACY.premium]: lodash.get(newsSourceInfo, 'premium_content_provider', false)
});

// ---------------- POST Source Info ----------------

// // check functions

export const checkRequiredFields = field => !field && constants.ERRORS.commonError;

// function that format crawl object to send

export const fromDetailsToRawInfo = async (newInfo, oldInfo) => {
  const sourceDetails = {};
  const errors = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }) => {
    const valueNew = predefinedValue || lodash.get(newInfo, newPath);
    const valueOld = lodash.get(oldInfo, oldPath);

    if (!lodash.isEqual(valueNew, valueOld)) {
      lodash.set(sourceDetails, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew);
        if (result) errors.push({ text: result, title: newPath[0] });
      }
    }
  };

  // general info
  setDifference({
    newPath: [constants.SOURCE_GENERAL.source_type, 'value'],
    oldPath: ['source_type']
  });
  setDifference({
    newPath: [constants.SOURCE_GENERAL.name],
    oldPath: ['name'],
    checkFunction: checkRequiredFields
  });
  setDifference({
    newPath: [constants.SOURCE_GENERAL.id],
    oldPath: ['id']
  });

  // logo
  setDifference({
    newPath: [constants.SOURCE_LOGO.logo_url],
    oldPath: ['logo_url']
  });
  let fileImage = lodash.get(newInfo, [constants.SOURCE_LOGO.logo_image, 0], null);
  if (fileImage) {
    fileImage = await getBase64FromFile(
      lodash.get(newInfo, [constants.SOURCE_LOGO.logo_image, 0], null)
    );
  }
  setDifference({
    newPath: [constants.SOURCE_LOGO.logo_image, 0],
    oldPath: ['logo_url'],
    superPath: ['logo_image'],
    predefinedValue: fileImage
  });

  // privacy
  setDifference({
    newPath: [constants.SOURCE_PRIVACY.premium],
    oldPath: ['premium_content_provider']
  });

  return { sourceDetails, errors };
};
