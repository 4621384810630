import type { ReportDocumentFromResponse } from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.api.types';
import type { ReportDocumentCrawlStatusCellProps } from './ReportDocumentCrawlStatusCell.types';
import type { FC } from 'react';

import React from 'react';

import * as reportDocumentsConstants from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.constants';
import * as uiLib from '@compliance.ai/web-components';

import Badge from 'admin/components/Badge';

export const ReportDocumentCrawlStatusCell: FC<ReportDocumentCrawlStatusCellProps> = ({ row }) => {
  const document = row as ReportDocumentFromResponse;

  const status =
    Object.values(reportDocumentsConstants.CRAWL_STATUS).find(
      status => status === document.status
    ) ?? reportDocumentsConstants.CRAWL_STATUS.SUCCESS;
  const statusText = uiLib.capitalize(status);

  return (
    <Badge type={status} size="medium" title={statusText}>
      {statusText}
    </Badge>
  );
};
