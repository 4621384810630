import React from 'react';
import PropTypes from 'prop-types';
import { NAMES_EXAMPLE, ERRORS, INFO_HELP } from "../CrawlSpecCreate.constants";
import TextInput from "../../../components/TextInput";
import SpecElementsWrapper from "./SpecElementsWrapper";
import Checkbox from "../../../components/Checkbox_NEW";

const Example = ({
  disabled,
  show,
  element,
  changeExample,
  errorFields
}) => {
  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changeExample(name, value);
  };

  return (
    <SpecElementsWrapper
      show={show}
      label="Example"
      tooltip={INFO_HELP.EXAMPLE}
    >
      <TextInput
        label="Url"
        name={NAMES_EXAMPLE.URL}
        value={element[NAMES_EXAMPLE.URL]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_EXAMPLE.URL)}
        errorText={ERRORS.exampleURL}
      />
      <TextInput
        label="Text"
        name={NAMES_EXAMPLE.TEXT}
        value={element[NAMES_EXAMPLE.TEXT]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_EXAMPLE.TEXT)}
        errorText={ERRORS.exampleURL}
      />
      <TextInput
        label="Occurrence"
        name={NAMES_EXAMPLE.OCCURRENCE}
        value={element[NAMES_EXAMPLE.OCCURRENCE]}
        isDisabled={disabled}
        type="number"
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_EXAMPLE.OCCURRENCE)}
        errorText={ERRORS.exampleOccur}
      />
      <Checkbox
        label="Exact Match"
        disabled={disabled}
        onClick={checked => changeExample(NAMES_EXAMPLE.EXACT_MATCH, checked)}
        isError={errorFields.includes(NAMES_EXAMPLE.EXACT_MATCH)}
        checked={element[NAMES_EXAMPLE.EXACT_MATCH]}
      />
    </SpecElementsWrapper>
  );
};

Example.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  element: PropTypes.shape({}),
  changeExample: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Example.defaultProps = {
  disabled: false,
  show: false,
  element: {},
  errorFields: []
};

export default Example;
