import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'concepts';
export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const conceptsReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setConcept: (state, action) => {
      return action.payload;
    },
    createConcept: (state, action) => ({
      ...state,
      count: state.count+1,
      items: [...state.items, action.payload]
    }),
    updateConcept: (state, action) => ({
      ...state,
      items: state.items.map(item => item.id === action.payload.id ? action.payload : item)
    }),
    deleteConcept: (state, action) => ({
      ...state,
      count: state.count-1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default conceptsReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setConcept,
  createConcept,
  updateConcept,
  deleteConcept
} = conceptsReducer.actions;
