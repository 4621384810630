import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select-3/async-creatable';
import Typography from '../Typography';

function CustomAsyncCreatableSelect({
  className,
  classNamePrefix,
  selectClassName,
  labelClassName,
  isDisabled,
  isMulti,
  options,
  label,
  isSearchable,
  isClearable,
  placeholder,
  value,
  onChange,
  loadOptions,
  getOptionLabel,
  getOptionValue,
  cacheOptions,
  defaultOptions
}) {
  return (
    <label className={cn(
      "select_wrapper",
      className
    )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
      </Typography>
      <AsyncCreatableSelect
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        classNamePrefix={classNamePrefix}
        className={cn(
          selectClassName
        )}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onChange={onChange}
        isSearchable={isSearchable}
        isClearable={isClearable}
        value={value || null}
      />
    </label>
  );
}


CustomAsyncCreatableSelect.propTypes = {
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  isClearable: PropTypes.bool
};

CustomAsyncCreatableSelect.defaultProps = {
  isMulti: false,
  isDisabled: false,
  isSearchable: true,
  isClearable: false,
  classNamePrefix: 'custom-select',
  getOptionLabel: e => e.label,
  getOptionValue: e => e.value,
  cacheOptions: true,
  defaultOptions: true
};


export default CustomAsyncCreatableSelect;
