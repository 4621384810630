import React, { useMemo } from 'react';
import _ from "lodash";
import { COLUMNS_CONFIG } from './DocumentCategories.constants';
import HeaderSort from '../../components/SortElements/HeaderSort';

const getCategoryHeader = ({
  categoryInfo,
  changeFilter,
  sort
}) => {
  const categoryColumns = categoryInfo.map(item => COLUMNS_CONFIG.find(col => col.id === item));
  const formatInfo = categoryColumns.map(item => (
    <HeaderSort
      type={_.get(sort, [item.id, 'sorting_direction'], null)}
      onClick={() => changeFilter(item.id)}
      item={item}
    />
  ));

  return [
    // settings for checkbox
    '',
    ...formatInfo
  ];
};

export default ({ categoryInfo, changeFilter, sort }) => {
  return useMemo(() => [getCategoryHeader({
    categoryInfo,
    changeFilter,
    sort
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })], [categoryInfo, sort]);
};
