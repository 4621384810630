import type { EitlLabelCreateDropdownListConfig } from './useEitlLabelCreateDropdownListConfig.types';

import React from 'react';

import * as constants from '../EitlLabelCreateDropdown.constants';
import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useEitlLabelCreateDropdownListConfig = (): EitlLabelCreateDropdownListConfig => {
  const options: EitlLabelCreateDropdownListConfig['options'] = useMemo(
    () => [
      {
        label: (
          <uiLib.Typography className="eitl-label-actions__list-option-label">
            Add New EITL Label
          </uiLib.Typography>
        ),
        value: constants.EITL_LABEL_CREATION_VALUES.ADD
      },
      {
        label: (
          <uiLib.Typography className="eitl-label-actions__list-option-label">
            Import Multiple EITL Labels
          </uiLib.Typography>
        ),
        value: constants.EITL_LABEL_CREATION_VALUES.IMPORT
      }
    ],
    []
  );

  return {
    options
  };
};
