import _ from 'lodash';
import {
  api_fetchTopicsStats,
  api_fetchModelValidationTable,
  api_fetchAllTopics,
  api_createTopic,
  api_updateTopic
} from './topics.api';

export const REQUEST_TOPICS = 'REQUEST_TOPICS';
export const RECEIVE_TOPICS = 'RECEIVE_TOPICS';
export const FOLLOW_TOPICS = 'FOLLOW_TOPICS';
export const REQUEST_ALL_TOPICS = 'REQUEST_ALL_TOPICS';
export const RECEIVE_ALL_TOPICS = 'RECEIVE_ALL_TOPICS';
export const REQUEST_TOPICS_STATS = 'REQUEST_TOPICS_STATS';
export const RECEIVE_TOPICS_STATS = 'RECEIVE_TOPICS_STATS';
export const REQUEST_MODEL_VALIDATION_TABLE = 'REQUEST_MODEL_VALIDATION_TABLE';
export const RECEIVE_MODEL_VALIDATION_TABLE = 'RECEIVE_MODEL_VALIDATION_TABLE';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const TOPIC_CREATED = 'TOPIC_CREATED';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const TOPIC_UPDATED = 'TOPIC_UPDATED';

function requestAllTopics() {
  return {
    type: REQUEST_ALL_TOPICS
  };
}

function receiveAllTopics(json) {
  return {
    type: RECEIVE_ALL_TOPICS,
    all_topics: json
  };
}

function requestTopicsStats() {
  return {
    type: REQUEST_TOPICS_STATS
  };
}

function receiveTopicsStats(json) {
  return {
    type: RECEIVE_TOPICS_STATS,
    topics_stats: json
  };
}

function requestModelValidationTable() {
  return {
    type: REQUEST_MODEL_VALIDATION_TABLE
  };
}

function receiveModelValidationTable(json) {
  return {
    type: RECEIVE_MODEL_VALIDATION_TABLE,
    topic_annotation_model_validations: json
  };
}

function createTopic(params) {
  return {
    type: CREATE_TOPIC,
    params
  };
}

function topicCreated(json) {
  return {
    type: TOPIC_CREATED,
    topic: json
  };
}

function updateTopic() {
  return {
    type: UPDATE_TOPIC
  };
}

function topicUpdated(json) {
  return {
    type: TOPIC_UPDATED,
    topic: _.get(json, 'topic')
  };
}

export function fetchTopicsStats() {
  return function dofetch(dispatch) {
    dispatch(requestTopicsStats());
    return api_fetchTopicsStats()
      .then(response => {
        dispatch(receiveTopicsStats(response));
      });
  };
}

export function fetchModelValidationTable(topic_id) {
  return function doPost(dispatch) {
    dispatch(requestModelValidationTable());
    return api_fetchModelValidationTable(topic_id)
      .then(response => {
        dispatch(receiveModelValidationTable(response));
      });
  };
}

export function fetchAllTopics() {
  return function dofetch(dispatch) {
    dispatch(requestAllTopics());
    return api_fetchAllTopics()
      .then(response => {
        dispatch(receiveAllTopics(response));
      });
  };
}

export function createTopicFromParams(params) {
  return function dopost(dispatch) {
    dispatch(createTopic(params));
    return api_createTopic(params)
      .then(response => {
        dispatch(topicCreated(response));
        return response;
      })
      .catch(error => {
        dispatch(topicCreated(null));
      });
  };
}

export function updateTopicWithParams(topic_id, data) {
  return function dopost(dispatch) {
    dispatch(updateTopic(data));
    return api_updateTopic(topic_id, data)
      .then(response => {
        dispatch(topicUpdated(response));
        return response;
      })
      .catch(error => {
        dispatch(topicUpdated(null));
      });
  };
}
