import PropTypes from 'prop-types';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';
import * as crawlRunNowModalHelpers from './crawlRunNowModal.helper';
import * as errorConstants from '../../../constants/errors';
import customCrawlSettings, {
  ARCHIVE_CUSTOM_FULL_ACCESS
} from '../../../constants/customCrawlRunNowSettings';
import * as crawlerDetailsRecurConstants from '../CrawlerDetails/elements/CrawlerDetailsRecur/CrawlerDetailsRecur.constants';
import * as runNowParametersNameConstants from '../../../constants/runNowParametersNames';
import CRAWL_TYPES from '../../../constants/crawlersTypes';
import RUN_NOW_TYPES from '../../../constants/runNowTypes';

import Select from '../../../components/ReactSelect/Select';
import CrawlRunNowModalBodySpreadsheet from './CrawlRunNowModalBodySpreadsheet';
import CrawlRunNowModalBodyInterval from './CrawlRunNowModalBodyInterval';
import CrawlRunNowModalBodyArchiveCustom from './CrawlRunNowModalBodyArchiveCustom';
import CrawlRunNowModalBodyArchiveUnified from './CrawlRunNowModalBodyArchiveUnified';
import CrawlRunNowModalBodyBottomBtnBlock from './CrawlRunNowModalBodyBottomBtnBlock';
import CrawlRunNowModalBodyArchiveResource from './CrawlRunNowModalBodyArchiveResource';
import * as uiLib from '@compliance.ai/web-components';
import '../CrawlerDetails/elements/CrawlerDetailsRecur/crawler-details-recur.scss';

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const CrawlRunNowModalBody = ({
  crawlDetails,
  closePopup,
  show,
  createRunNowCommandRequest,
  onCancel
}) => {
  const current_user = useSelector(state => state.current_user);
  const commands = _.get(
    useSelector(state => state.commands),
    'items',
    []
  );

  const [modeType, setModeType] = useState(null);
  const [currentCommand, setCommand] = useState(null);
  const [parameters, setParameters] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setParameters({});
    setModeType(null);
    setCommand(null);
  }, [show]);

  useEffect(() => {
    setError(null);
  }, [parameters]);

  const setParameter = (name, value) => {
    setParameters({ ...parameters, [name]: value });
  };

  const setBunchParameters = bunch => setParameters({ ...parameters, ...bunch });

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    setParameter(name, value);
  };

  // body to display
  const runNowTypes = {
    [RUN_NOW_TYPES.upload_doc_spreadsheet.value]: (
      <CrawlRunNowModalBodySpreadsheet parameters={parameters} setParameter={setParameter} />
    ),
    [RUN_NOW_TYPES.upload_premium_docs.value]: (
      <CrawlRunNowModalBodySpreadsheet parameters={parameters} setParameter={setParameter} />
    ),
    [RUN_NOW_TYPES.pub_date_interval.value]: (
      <CrawlRunNowModalBodyInterval parameters={parameters} setParameter={setParameter} />
    ),
    [RUN_NOW_TYPES.pub_date_interval_feedly.value]: (
      <CrawlRunNowModalBodyInterval parameters={parameters} setParameter={setParameter} />
    ),
    [RUN_NOW_TYPES.archive.value]: (
      <CrawlRunNowModalBodyArchiveUnified
        parameters={parameters}
        setParameter={setParameter}
        setBunchParameters={setBunchParameters}
      />
    ),
    [RUN_NOW_TYPES.archive_feedly.value]: null,
    [RUN_NOW_TYPES.archive_email.value]: null,
    [RUN_NOW_TYPES.archive_osapi.value]: null,
    [RUN_NOW_TYPES.archive_codebot.value]: (
      <CrawlRunNowModalBodyArchiveResource parameters={parameters} setParameter={setParameter} />
    ),
    [RUN_NOW_TYPES.archive_custom.value]: (
      <CrawlRunNowModalBodyArchiveCustom
        crawlExternalApiId={crawlDetails.external_api_id}
        parameters={parameters}
        setParameter={setParameter}
      />
    )
  };

  // run now modal const settings
  const crawlType = _.find(CRAWL_TYPES, ['value', crawlDetails.type]);
  const options = (crawlType && crawlType.runNowMode) || [];

  // check that settings for custom crawl exist
  const checkCustomCrawlSettings = () => {
    if (!customCrawlSettings[crawlDetails.external_api_id]) {
      setError(errorConstants.CUSTOM_CRAWL_SETTINGS);
    }
  };

  // change command_request type, change commandType and commandRequest (modeType) type
  const changeCommandRequestType = async commandRequestType => {
    const commandType = commands.find(
      command => _.get(command, ['parameters', 'type']) === commandRequestType.commandName
    );
    setModeType(commandRequestType);
    if (commandType) {
      setCommand(commandType);
      if (crawlDetails.type === CRAWL_TYPES.custom.value) {
        checkCustomCrawlSettings();
      }
    } else {
      setError(errorConstants.COMMAND_NOT_EXIST);
    }
  };

  // button "Run" - create command-request
  const createCommandRequest = () => {
    // check that user choose one of type
    if (!modeType) {
      setError(errorConstants.TYPE_MODE_EMPTY);
      return;
    }
    // format command request parameters
    const commandRequestParams = crawlRunNowModalHelpers.formRunNowCommandRequestParameters(
      modeType,
      parameters,
      crawlDetails
    );
    if (commandRequestParams.error) {
      setError(commandRequestParams.error);
      return;
    }

    // format form data body of command request
    const commandRequest = crawlRunNowModalHelpers.formFormDateRunNowCommand(
      commandRequestParams,
      parameters,
      currentCommand,
      current_user
    );

    createRunNowCommandRequest(commandRequest);
    closePopup();
  };
  return (
    <div className="bodyWrapper">
      <Select
        label="Type of mode"
        options={options}
        value={modeType}
        onChange={changeCommandRequestType}
      />
      {runNowTypes[modeType && modeType.value]}
      <div className="custom-component">
        <uiLib.Switch
          name={runNowParametersNameConstants.UPDATE_FIELD_SOFT}
          label="Update document with document version creation"
          className="updateCheckBox"
          isChecked={parameters[runNowParametersNameConstants.UPDATE_FIELD_SOFT]}
          isDisabled={!_.get(modeType, 'update_existing_documents', true)}
          onChange={e => setParameter(runNowParametersNameConstants.UPDATE_FIELD_SOFT, e)}
        />
        <uiLib.Tooltip
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          placement={uiLib.TOOLTIP_POSITION.BOTTOM}
          title={crawlerDetailsRecurConstants.UPDATE_DOC_TOOLTIP}
        >
          <uiLib.IconInfoRounded className="infoTooltipIcon" />
        </uiLib.Tooltip>
      </div>
      <div className="custom-component">
        <uiLib.Switch
          name={runNowParametersNameConstants.UPDATE_FIELD_FORCE}
          label="Update document with document version overwrite"
          className="updateCheckBox"
          isChecked={parameters[runNowParametersNameConstants.UPDATE_FIELD_FORCE]}
          isDisabled={
            !(
              _.get(modeType, 'force_update_existing_documents', true) ||
              ARCHIVE_CUSTOM_FULL_ACCESS.includes(crawlDetails.external_api_id)
            )
          }
          onChange={e => setParameter(runNowParametersNameConstants.UPDATE_FIELD_FORCE, e)}
        />
        <uiLib.Tooltip
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          placement={uiLib.TOOLTIP_POSITION.BOTTOM}
          title={crawlerDetailsRecurConstants.UPDATE_DOC_METADATA_TOOLTIP}
        >
          <uiLib.IconInfoRounded className="infoTooltipIcon" />
        </uiLib.Tooltip>
      </div>
      <div className="custom-component">
        <uiLib.Switch
          name={runNowParametersNameConstants.DEBUG_MODE}
          label="Run in max verbosity debug mode"
          className="updateCheckBox"
          isChecked={parameters[runNowParametersNameConstants.DEBUG_MODE]}
          isDisabled={
            !(
              _.get(modeType, 'crawl_run_debug_mode', true) ||
              ARCHIVE_CUSTOM_FULL_ACCESS.includes(crawlDetails.external_api_id)
            )
          }
          onChange={e => setParameter(runNowParametersNameConstants.DEBUG_MODE, e)}
        />
        <uiLib.Tooltip
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          placement={uiLib.TOOLTIP_POSITION.BOTTOM}
          title={crawlerDetailsRecurConstants.DEBUG_MODE_TOOLTIP}
        >
          <uiLib.IconInfoRounded className="infoTooltipIcon" />
        </uiLib.Tooltip>
      </div>
      <div className="custom-component">
        <uiLib.Switch
          name={runNowParametersNameConstants.VIDEO_MODE}
          label="Video record the run"
          className="updateCheckBox"
          isChecked={parameters[runNowParametersNameConstants.VIDEO_MODE]}
          isDisabled={
            !(
              _.get(modeType, 'crawl_run_video_mode', true) ||
              ARCHIVE_CUSTOM_FULL_ACCESS.includes(crawlDetails.external_api_id)
            )
          }
          onChange={e => setParameter(runNowParametersNameConstants.VIDEO_MODE, e)}
        />
        <uiLib.Tooltip
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          placement={uiLib.TOOLTIP_POSITION.BOTTOM}
          title={crawlerDetailsRecurConstants.DEBUG_VIDEO_TOOLTIP}
        >
          <uiLib.IconInfoRounded className="infoTooltipIcon" />
        </uiLib.Tooltip>
      </div>
      <div className="custom-component">
        <uiLib.Switch
          name={runNowParametersNameConstants.CURSOR_RUN}
          label="Capture crawl cursor in this run"
          className="updateCheckBox"
          isChecked={
            parameters[runNowParametersNameConstants.CURSOR_RUN] ||
            parameters[runNowParametersNameConstants.AUTO_RESUME] ||
            false
          }
          isDisabled={
            !(
              _.get(modeType, 'crawl_cursor_run_mode', true) ||
              ARCHIVE_CUSTOM_FULL_ACCESS.includes(crawlDetails.external_api_id)
            )
          }
          onChange={e => setParameter(runNowParametersNameConstants.CURSOR_RUN, e)}
        />
        <uiLib.Tooltip
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          placement={uiLib.TOOLTIP_POSITION.BOTTOM}
          title={crawlerDetailsRecurConstants.CURSOR_RUN_TOOLTIP}
        >
          <uiLib.IconInfoRounded className="infoTooltipIcon" />
        </uiLib.Tooltip>
      </div>
      <div className="custom-component">
        <uiLib.Switch
          name={runNowParametersNameConstants.AUTO_RESUME}
          label="Resume automatically if this run times out"
          className="updateCheckBox"
          isChecked={parameters[runNowParametersNameConstants.AUTO_RESUME]}
          isDisabled={
            !(
              _.get(modeType, 'crawl_auto_resume_mode', true) ||
              ARCHIVE_CUSTOM_FULL_ACCESS.includes(crawlDetails.external_api_id)
            )
          }
          onChange={e => setParameter(runNowParametersNameConstants.AUTO_RESUME, e)}
        />
        <uiLib.Tooltip
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          placement={uiLib.TOOLTIP_POSITION.BOTTOM}
          title={crawlerDetailsRecurConstants.AUTO_RESUME_TOOLTIP}
        >
          <uiLib.IconInfoRounded className="infoTooltipIcon" />
        </uiLib.Tooltip>
      </div>
      {error && (
        <Alert bsStyle="danger" className="adminToolErrorMessage">
          {error || 'Please check your entries'}
        </Alert>
      )}
      <hr />
      <CrawlRunNowModalBodyBottomBtnBlock
        closePopup={closePopup}
        runNow={createCommandRequest}
        onCancel={onCancel}
      />
    </div>
  );
};

CrawlRunNowModalBody.propTypes = {
  crawlDetails: PropTypes.shape({}),
  closePopup: PropTypes.func,
  createRunNowCommandRequest: PropTypes.func,
  show: PropTypes.bool,
  onCancel: PropTypes.func
};

CrawlRunNowModalBody.defaultProps = {
  closePopup: () => {},
  crawlDetails: {},
  createRunNowCommandRequest: () => {},
  show: false,
  onCancel: () => {}
};

export default CrawlRunNowModalBody;
