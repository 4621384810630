import {
  api_fetchAutoComplete,
  api_fetchIframeAutoComplete,
  api_fetchSearchQueries
} from './search.api';
import { api_vanillaGetDocuments } from '../documents/documents.api';

export const REQUEST_SEARCH_QUERIES = 'REQUEST_SEARCH_QUERIES';
export const RECEIVE_SEARCH_QUERIES = 'RECEIVE_SEARCH_QUERIES';
export const REQUEST_AUTO_COMPLETE = 'REQUEST_AUTO_COMPLETE';
export const RECEIVE_AUTO_COMPLETE = 'RECEIVE_AUTO_COMPLETE';
export const CLEAR_AUTO_COMPLETE = 'CLEAR_AUTO_COMPLETE';

function requestSearchQueries() {
  return {
    type: REQUEST_SEARCH_QUERIES
  };
}

function recieveSearchQueries(data) {
  return {
    type: RECEIVE_SEARCH_QUERIES,
    search_queries: data.search_queries
  };
}

function requestAutoComplete() {
  return {
    type: REQUEST_AUTO_COMPLETE
  };
}

function receiveAutoComplete(json, currentAutoComplete) {
  return {
    type: RECEIVE_AUTO_COMPLETE,
    autocompletes: json.results,
    currentAutoComplete
  };
}

export function clearAutoComplete() {
  return {
    type: CLEAR_AUTO_COMPLETE,
    autocompletes: null
  };
}

export function fetchAutoComplete(partialValue, category) {
  return function dofetch(dispatch) {
    dispatch(requestAutoComplete());
    return api_fetchAutoComplete(partialValue, { category })
      .then(response => {
        dispatch(receiveAutoComplete(response, category));
        return response;
      });
  };
}

export function fetchIframeAutoComplete(data, useAPIKey) {
  return function dofetch(dispatch) {
    dispatch(requestAutoComplete());
    return api_fetchIframeAutoComplete(data, useAPIKey)
      .then(response => {
        dispatch(receiveAutoComplete(response));
        return response;
      });
  };
}

export function fetchSearchQueries(data = { num_queries: 3 }) {
  return function doFetch(dispatch) {
    dispatch(requestSearchQueries());
    return api_fetchSearchQueries(data)
      .then(response => {
        // FIXME This is a temporary fix so search queryies with 0 docs don't show up in the
        // Common Searches on the dashboard
        const search_queries = response.search_queries;
        let queriesWithDocs = [];
        return Promise.all(
          // for each search query get the number of docs
          search_queries.map(searchQuery => {
            return api_vanillaGetDocuments({
              ...searchQuery.search_args,
              get_count_only: true
            });
          })
        ).then(docs => {
          queriesWithDocs = search_queries.filter((searchQuery, i) => {
            return docs[i].count > 0;
          });
          // we want to display only 5 search queries
          response.search_queries = queriesWithDocs.slice(0, 5);
          dispatch(recieveSearchQueries(response));
          return response;
        });
      });
  };
}
