import type { FC } from 'react';
import type { EitlLabelActionsDropdownProps } from './EitlLabelActionsDropdown.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import {
  useEitlLabelActionsDropdownData,
  useEitlLabelActionsDropdownHandlers,
  useEitlLabelActionsDropdownListConfig
} from './hooks';

import './_eitl-label-actions-dropdown.scss';

export const EitlLabelActionsDropdown: FC<EitlLabelActionsDropdownProps> = ({
  onRenameModalOpen,
  onDeleteModalOpen,
  shouldShowDeleteOption
}) => {
  const { localState, localActions } = useEitlLabelActionsDropdownData();

  const handlers = useEitlLabelActionsDropdownHandlers({
    props: {
      onRenameModalOpen,
      onDeleteModalOpen
    },
    localActions
  });

  const listConfig = useEitlLabelActionsDropdownListConfig({
    props: {
      shouldShowDeleteOption
    }
  });

  return (
    <uiLib.Dropdown
      isOpen={localState.isOpen}
      onClose={handlers.handleEitlLabelActionsDropdownToggle(false)}
      containerClassName="eitl-label-actions__dropdown-button"
      className="eitl-label-actions__dropdown"
      triggerContent={
        <uiLib.ActionButton onClick={handlers.handleEitlLabelActionsDropdownToggle(true)}>
          <uiLib.IconMenu />
        </uiLib.ActionButton>
      }
      dropdownContent={
        <uiLib.List
          selectedOptions={[]}
          onOptionClick={handlers.handleListOptionClick}
          options={listConfig.options}
        />
      }
    />
  );
};
