import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import UserAnnotationTasks from './UserAnnotationTasks';
import InsightsCsv from './InsightsCsv';
import ContributorTasks from './ContributorTasks';
import dashboardCategory from '../constants/dashboardCategory';
import { typeOfAccess } from '../constants/userRoles';
import { CONTRIBUTOR } from "../../app/constants/UserRoles";
import {withAdminPermissions} from "../utils/hooks";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderLinks = (link) => {
    const li_key = link.path.replace('/', '');
    return (
      <li key={li_key}>
        <Link to={link.path}>
          {link.title}
        </Link>
      </li>
    );
  };

  render() {
    if (!this.props.viewer.isReady) {
      return null;
    }

    const { user } = this.props.viewer;
    // admin users have access to all pages here, qa users only have access to some
    const contributor_user = user.roles.includes(CONTRIBUTOR);

    if (contributor_user) {
      return (
        <div className="dashboard-container">
          <Row>
            <Col sm={8}>
              <h1>Contributor Dashboard</h1>
              <ContributorTasks />
            </Col>
            <Col sm={4}>
              <UserAnnotationTasks
                hasViewAccess={this.props.hasViewAccess}
              />
            </Col>
          </Row>
        </div>
      );
    }

    const dasboardHierarchy = Object.values(dashboardCategory).map(category => {
      if (category.subcategory) {
        const noneAcceses = !user.permissions || !user.permissions[category.value]
          || user.permissions[category.value] === typeOfAccess.none;
        return (
          <div key={category.value}>
            <h2>{category.title}</h2>
            <ul>
              {category.subcategory.map(subcategory => {
                if (noneAcceses) {
                  return (
                    <li key={subcategory.title}>{subcategory.title}</li>
                  );
                }
                if (!subcategory.hidden) {
                  return this.renderLinks(subcategory, category.value);
                }
                return null;
              })}
            </ul>
          </div>
        );
      }
      return null;
    });

    return (
      <div className="dashboard-container">
        <Row>
          <h1>Dashboard</h1>
          <Col sm={8}>
            {dasboardHierarchy}
          </Col>
          <Col sm={4}>
            <UserAnnotationTasks
              hasViewAccess={this.props.hasViewAccess}
            />
          </Col>
        </Row>
        <InsightsCsv />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    viewer: state.current_user,
    current_view: state.current_view,
    errors: state.errors
  };
};

export default withAdminPermissions(connect(mapStateToProps)(Dashboard));
