export const SOURCE_GENERAL = {
  name: 'name',
  id: 'id',
  source_type: 'source_type',
  description: 'description'
};

export const SOURCE_LOGO = {
  logo_image: 'logo_image',
  logo_url: 'logo_url'
};

export const SOURCE_PRIVACY = {
  premium: 'premium_content_provider'
};

// Errors

export const ERRORS = {
  commonError: 'This value is required'
};

// crawls details use by SourceDetails component (just help to show structure)
export const SOURCE_DETAILS = {
  // general info
  [SOURCE_GENERAL.name]: '', // string
  [SOURCE_GENERAL.id]: 'new', // number | new
  [SOURCE_GENERAL.source_type]: null, // { value: "", label: "" }
  // logo
  [SOURCE_LOGO.logo_image]: [], // array
  [SOURCE_LOGO.logo_url]: '', // string (link)
  // privacy
  [SOURCE_PRIVACY.premium]: false // boolean
};
