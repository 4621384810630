import React from 'react';
import PropTypes from 'prop-types';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Typography from '../../../components/Typography';
import Tooltip from '../../../components/Tooltip';
import { SETTINGS_TOOLTIP } from "../CrawlSpecCreate.constants";
import Button from "../../../components/Button";

const SpecElementsHeader = ({
  label,
  tooltip,
  debugValue,
  onDebug
}) => (
  <div className="crawl-spec_element_head">
    <Typography variant="body">{label}</Typography>
    <Tooltip
      label={tooltip}
      childClassName="crawl-spec_element_head_tooltip_child"
      tooltipX={SETTINGS_TOOLTIP.tooltipX}
      tooltipY={SETTINGS_TOOLTIP.tooltipY}
      tooltipArrowX={SETTINGS_TOOLTIP.tooltipArrowX}
      tooltipArrowY={SETTINGS_TOOLTIP.tooltipArrowY}
    >
      <InfoRoundedIcon />
    </Tooltip>
    {onDebug && (
      <Button
        onClick={onDebug}
        color={debugValue ? "red" : "error"}
        size="small"
      >
        Debug
      </Button>
    )}
  </div>
);

SpecElementsHeader.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  debugValue: PropTypes.bool,
  onDebug: PropTypes.func
};

SpecElementsHeader.defaultProps = {
  label: '',
  tooltip: '',
  debugValue: false,
  onDebug: null
};

export default SpecElementsHeader;
