import React from 'react';
import { connect } from 'react-redux';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CreatableSelect from '../../components/ReactSelect/SelectCreatable';
import TextInput from '../../components/TextInput';
import { getCategories } from 'shared/features/categories/categories/categories.actions';
import { updateDocument, updateDataDocument } from 'shared/features/documents/documents.actions';

const ENFORCEMENT_SELECT = [
  { initName: 'init_individual_respondent', name: 'individual_respondent', label: 'respondents - individual' },
  { initName: 'init_entity_respondent', name: 'entity_respondent', label: 'respondents - entity' },
  { initName: 'init_violated_rules', name: 'violated_rules', label: 'violated - rules' }
];

const ENFORCEMENT_INPUT = [
  { type: 'text', name: 'respondents', label: 'respondents' },
  { type: 'text', name: 'monetary_penalty', label: 'monetary_penalty' },
  { type: 'text', name: 'violation', label: 'violation' }
];

class DocumentUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDisabled: false,
      notes: '',
      format_str: 'YYYY-MM-DDTHH:mm:ss',
      showAlert: false,
      errorMessage: '',
      agencies: [],
      topics: [],
      title: '',
      initial_title: '',
      summary_text: '',
      initial_summary_text: '',
      category: '',
      initial_category: '',
      publication_date: '',
      effective_date: '',
      comment_close_date: '',
      initial_effective_date: '',
      initial_comment_close_date: '',
      initial_publication_date: '',
      user_flagged_document_id: '',
      initial_topics: [],
      initial_agencies: [],
      enforcement: '',
      respondents: '',
      monetary_penalty: '',
      individual_respondent: [],
      entity_respondent: [],
      violated_rules: [],
      violation: '',
      updateEnforcementMetadata: false,
      enforcementMetadataIsExist: false
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getCategories();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.documents_full
      && nextProps.documents_full.isReady
      && nextProps.documents_full.ids[this.props.document_id]
    ) {
      const document = nextProps.documents_full.ids[this.props.document_id];
      const enforcementSelect = ENFORCEMENT_SELECT.reduce((acc, enforcementItem) => {
        const itemValue = _.get(document.enforcement, enforcementItem.name, '');
        const resultValue = itemValue
          ? itemValue.split(', ').map(item => ({ value: item, label: item }))
          : [];
        return {
          ...acc,
          [enforcementItem.name]: resultValue,
          [enforcementItem.initName]: resultValue
        };
      }, {});
      const enforcementInput = ENFORCEMENT_INPUT.reduce((acc, enforcementItem) => ({
        ...acc,
        [enforcementItem.name]: _.get(document.enforcement, enforcementItem.name, '')
      }), {});
      this.setState({
        title: document.title,
        initial_title: document.title,
        summary_text: document.summary_text,
        initial_summary_text: document.summary_text,
        category: document.category,
        initial_category: document.category,
        publication_date: moment(document.publication_date || undefined).toDate(),
        initial_publication_date: moment(document.publication_date || undefined).toDate(),
        user_flagged_document_id: _.get(document.flagged, ['user_flagged_document_id']),
        topics: document.topics.map(topic => ({ name: topic.name, id: topic.id })),
        initial_topics: document.topics.map(topic => ({ name: topic.name, id: topic.id })),
        agencies: document.agencies
          && document.agencies.map
          && document.agencies.map(agencies => ({ name: agencies.name, id: agencies.id }))
          || [],
        initial_agencies: document.agencies
          && document.agencies.map
          && document.agencies.map(agencies => ({ name: agencies.name, id: agencies.id }))
          || [],
        effective_date: moment(
          (document.rule && document.rule.effective_on) || undefined
        ).toDate(),
        comment_close_date: moment(
          (document.rule && document.rule.comments_close_on) || undefined
        ).toDate(),
        initial_effective_date: moment(
          (document.rule && document.rule.effective_on) || undefined
        ).toDate(),
        initial_comment_close_date: moment(
          (document.rule && document.rule.comments_close_on) || undefined
        ).toDate(),
        enforcement: document.enforcement,
        ...enforcementInput,
        ...enforcementSelect,
        updateEnforcementMetadata: false,
        enforcementMetadataIsExist: document.enforcement && 'violation' in document.enforcement,
        // optional fields
        duplicate: document.duplicate,
        potential_duplicate: document.potential_duplicate
      });
    }

    if (!nextProps.updated_document.isUpdating) {
      // close the modal on success
      if (this.props.showModal && this.state.saveDisabled) {
        this.props.close();
      }
      this.setState({
        saveDisabled: false
      });
    }
  }

  handleFieldChange = (changedfieldname, event) => {
    const new_state = {};
    if (changedfieldname === 'topics') {
      new_state.topics = event.map(obj => ({ name: obj.label, id: obj.value }));
    } else if (changedfieldname === 'agencies') {
      new_state.agencies = event.map(obj => ({ name: obj.label, id: obj.value }));
    } else {
      new_state[changedfieldname] = event.target.value;
    }
    this.setState({ showAlert: false, errorMessage: '', ...new_state });
  };

  handleDateChange = (date, dateName) => {
    this.setState({ [dateName]: date, showAlert: false, errorMessage: '' });
  };

  handleSubmit = event => {
    event.preventDefault();
    const changes = {};
    const wrongFields = [];
    let extraErrors = '';
    if (this.state.title && this.state.title.trim()) {
      if (this.state.title !== this.state.initial_title) {
        changes.title = this.state.title;
      }
    } else wrongFields.push('"Title"');
    if (this.state.category && this.state.category.trim()) {
      if (this.state.category !== this.state.initial_category) {
        changes.category = this.state.category;
      }
    } else wrongFields.push('"Category"');
    if (this.state.summary_text && this.state.summary_text.trim()) {
      if (this.state.summary_text !== this.state.initial_summary_text) {
        changes.summary_text = this.state.summary_text;
      }
    } else wrongFields.push('"Summary"');

    if (this.state.agencies && !_.isEqual(this.state.agencies, this.state.initial_agencies)) {
      changes.agencies = this.state.agencies;
    }

    if (this.state.topics && !_.isEqual(this.state.topics, this.state.initial_topics)) {
      const initial_topic_lookup = {};
      const existing_topic_lookup = {};
      const topics_to_remove = [];
      const topics_to_add = [];
      for (const topic_dict of this.state.initial_topics) {
        initial_topic_lookup[topic_dict.id] = true;
      }

      for (const topic_dict of this.state.topics) {
        existing_topic_lookup[topic_dict.id] = true;
        if (!initial_topic_lookup[topic_dict.id]) {
          topics_to_add.push(topic_dict);
        }
      }

      for (const topic_dict of this.state.initial_topics) {
        if (!existing_topic_lookup[topic_dict.id]) {
          topics_to_remove.push(topic_dict);
        }
      }

      if (topics_to_add.length > 0) {
        changes.topics_to_add = topics_to_add;
      }

      if (topics_to_remove.length > 0) {
        changes.topics_to_remove = topics_to_remove;
      }
    }

    const publication_date = moment(this.state.publication_date);
    if (publication_date) {
      if (moment().add(1, 'day') > publication_date) {
        const initial_date = moment(this.state.initial_publication_date)
          .format(this.state.format_str);
        const changed_date = moment(this.state.publication_date)
          .format(this.state.format_str);
        if (initial_date !== changed_date) {
          changes.publication_date = changed_date;
        }
      } else {
        extraErrors = 'Publication Date should not be in future';
      }
    } else {
      wrongFields.push('"Publication date"');
    }
    if (this.state.effective_date) {
      const initial_date = moment(this.state.initial_effective_date)
        .format(this.state.format_str);
      const changed_date = moment(this.state.effective_date)
        .format(this.state.format_str);
      if (initial_date !== changed_date) {
        changes.effective_on = changed_date;
      }
    } else {
      wrongFields.push('"Effective date"');
    }
    if (this.state.comment_close_date) {
      const initial_date = moment(this.state.initial_comment_close_date)
        .format(this.state.format_str);
      const changed_date = moment(this.state.comment_close_date)
        .format(this.state.format_str);
      if (initial_date !== changed_date) {
        changes.comments_close_on = changed_date;
      }
    } else {
      wrongFields.push('"Comment close date"');
    }
    if (this.state.updateEnforcementMetadata) {
      changes.enforcements_metadata = {};
      if (this.state.respondents && this.state.respondents.trim()) {
        if (this.state.respondents !== this.state.enforcement.respondents) {
          changes.enforcements_metadata.respondent = this.state.respondents;
        }
      }
      if (this.state.monetary_penalty && !isNaN(+this.state.monetary_penalty)) {
        if (this.state.monetary_penalty !== this.state.enforcement.monetary_penalty) {
          changes.enforcements_metadata.monetary_penalty = +this.state.monetary_penalty;
        }
      } else wrongFields.push('"monetary_penalty(should be number)"');
      if (this.state.violation && this.state.violation.trim()) {
        if (this.state.violation !== this.state.enforcement.violation) {
          changes.enforcements_metadata.violation = this.state.violation;
        }
      }

      ENFORCEMENT_SELECT.forEach(item => {
        const itemValue = _.get(this.state, item.name, null);
        if (_.get(itemValue, 'length', 0)) {
          const individualStringValue = itemValue.map(option => option.label).join(', ');
          const originalStringValue = _.get(this.state.enforcement, item.name, '');
          if (individualStringValue !== originalStringValue) {
            changes.enforcements_metadata[item.name] = individualStringValue;
          }
        }
      });
    }

    const wrongFieldsLength = wrongFields.length;
    if (wrongFieldsLength || extraErrors) {
      let errorMessage = '';
      if (wrongFieldsLength > 0) {
        const complexError = wrongFieldsLength > 1;
        errorMessage = `${complexError ? 'Fields' : 'Field'} ${wrongFields.join(', ')} should not be empty.`;
      } else if (extraErrors) {
        errorMessage = extraErrors;
      } else {
        errorMessage = 'Something went wrong!';
      }
      this.setState({
        showAlert: true,
        errorMessage
      });
      return;
    }

    // Submit if changes were made
    if (_.isEmpty(changes)) {
      this.setState({ showAlert: true });
    } else {
      if (this.state.notes) {
        changes.notes = this.state.notes;
      }
      changes.user_flagged_document_id = this.props.userFlaggedDocumentsID
        || this.state.user_flagged_document_id;
      this.props.close();
      this.setState({ saveDisabled: true });
      if (this.props.flagged_status === this.props.CONTRIBUTOR_FLAG.name) {
        changes.fix_contributor_notes = true;
      }
      if (this.props.flagged_status === this.props.FUTURE_PUBLICATION.name) {
        this.updateFuturePubDocs(this.props.document_id, changes);
        return;
      }
      this.props.updateDocument(this.props.document_id, changes).then(() => {
        this.props.updateDocumentsResult();
      });
    }
  };

  updateFuturePubDocs = (doc_id, changes) => {
    if (changes.publication_date) {
      const updatedDoc = this.props.all_documents
        && this.props.all_documents.items
        && this.props.all_documents.items.documents
        && this.props.all_documents.items.documents.find(doc => doc.id === this.props.document_id);
      if (updatedDoc.document_misc) {
        changes = {
          ...changes,
          document_misc: { details: _.omit(updatedDoc.document_misc, 'future_publication_date') }
        };
      }
    }
    this.props.updateDataDocument(doc_id, changes).then(() => {
      this.props.updateDocumentsResult();
    });
  };

  closeModal = () => {
    this.setState({ showAlert: false, errorMessage: '' });
    this.props.close();
  };

  handleDuplicateDocChanges = (duplicate) => {
    this.props.unflagDocument(
      this.props.document_id, this.state.user_flagged_document_id, {}, !duplicate
    );
    this.closeModal();
  };

  render() {
    if (!this.props.documents_full.isReady) return null;

    const allCategories = this.props.categories.items;
    const categoriesItems = [];
    categoriesItems.push(<option key={0} value="" />);
    allCategories.forEach((category, i) => {
      categoriesItems.push(
        <option key={i + 1} value={category.app_category}>
          {category.app_category}
        </option>
      );
    });

    const isDuplicate = [
      this.props.DUPLICATE.name,
      this.props.POTENTIALLY_DUPLICATE.name
    ].includes(this.props.flagged_status);
    return (
      <Modal show={this.props.showModal} onHide={this.closeModal}>
        <Modal.Header>
          <Modal.Title>{'Document ID: ' + this.props.document_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Title</ControlLabel>
            <FormControl
              componentClass="textarea"
              value={this.state.title}
              onChange={e => this.handleFieldChange('title', e)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Summary</ControlLabel>
            <FormControl
              componentClass="textarea"
              value={this.state.summary_text}
              onChange={e => this.handleFieldChange('summary_text', e)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Category</ControlLabel>
            <FormControl
              componentClass="select"
              value={this.state.category}
              onChange={e => this.handleFieldChange('category', e)}
            >
              {categoriesItems}
            </FormControl>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Publication Date</ControlLabel>
            <div>
              <DatePicker
                selected={this.state.publication_date}
                onChange={(date) => this.handleDateChange(date, 'publication_date')}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Effective Date</ControlLabel>
            <div>
              <DatePicker
                selected={this.state.effective_date}
                onChange={(date) => this.handleDateChange(date, 'effective_date')}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Comments Close Date</ControlLabel>
            <div>
              <DatePicker
                selected={this.state.comment_close_date}
                onChange={(date) => this.handleDateChange(date, 'comment_close_date')}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Agency</ControlLabel>
            <Select
              multi
              options={this.props.agencies.items.map(agency => ({
                value: agency.id,
                label: agency.name
              }))}
              value={this.state.agencies.map(agency => ({ value: agency.id, label: agency.name }))}
              onChange={e => this.handleFieldChange('agencies', e)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Topics</ControlLabel>
            <Select
              multi
              options={this.props.sources.sources.activeTopics.map(source => ({
                value: source.id,
                label: source.name
              }))}
              value={this.state.topics.map(topic => ({ value: topic.id, label: topic.name }))}
              onChange={e => this.handleFieldChange('topics', e)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Notes with additional relevant details</ControlLabel>
            <FormControl
              componentClass="textarea"
              onChange={e => this.handleFieldChange('notes', e)}
            />
          </FormGroup>
          {this.state.enforcement && (
            <React.Fragment>
              <hr />
              <Button onClick={() => {
                this.setState(state => (
                  { updateEnforcementMetadata: !state.updateEnforcementMetadata }
                ));
              }}
              >
                {`${this.state.enforcementMetadataIsExist ? 'Edit' : 'Create'} Enforcement Metadata`}
              </Button>
            </React.Fragment>)}
          {
            _.get(this.state.duplicate, 'length', null)
            && (
              <p>
                <b>Duplicate: </b>
                {this.state.duplicate.map(duplDoc => (
                  <>
                    <a href={duplDoc.web_url || duplDoc.pdf_url}>{duplDoc.id}</a>
                    {', '}
                  </>
                ))}
              </p>
            )
          }
          {
            _.get(this.state.potential_duplicate, 'length', null)
            && (
              <p>
                <b>Potential Duplicate: </b>
                {this.state.potential_duplicate.map(duplDoc => (
                  <>
                    <a href={duplDoc.web_url || duplDoc.pdf_url}>{duplDoc.id}</a>
                    {', '}
                  </>
                ))}
              </p>
            )
          }
          {this.state.updateEnforcementMetadata && (
            <React.Fragment>
              {ENFORCEMENT_SELECT.map(item => (
                <CreatableSelect
                  key={item.name}
                  options={this.state[item.initName]}
                  label={item.label}
                  value={this.state[[item.name]]}
                  onChange={e => this.setState({ [[item.name]]: e })}
                  isMulti
                />
              ))}
              {ENFORCEMENT_INPUT.map(item => (
                <TextInput
                  type={item.type}
                  label={item.label}
                  onChange={e => this.handleFieldChange(item.name, e)}
                  value={this.state[item.name]}
                />
              ))}
            </React.Fragment>
          )}
          <div className={this.state.showAlert ? 'alert alert-danger' : 'hidden'} role="alert">
            {this.state.errorMessage || 'Please modify at least one document field before submit'}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isDuplicate && (
            <>
              <Button onClick={() => this.handleDuplicateDocChanges(true)}>Duplicate</Button>
              <Button onClick={() => this.handleDuplicateDocChanges(false)}>Not Duplicate</Button>
            </>
          )
          }
          <Button onClick={this.closeModal}>Close</Button>
          {!isDuplicate && (
            <Button
              bsStyle="primary"
              type="submit"
              disabled={this.state.saveDisabled}
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateDocument: (id, data) => {
      return dispatch(updateDocument(id, data));
    },
    getCategories: () => {
      dispatch(getCategories());
    },
    updateDataDocument: (doc_id, params) => {
      return dispatch(updateDataDocument(doc_id, params));
    }
  };
};

const mapStateToProps = state => {
  return {
    all_documents: state.all_documents,
    updated_document: state.updated_document,
    categories: state.documentCategories,
    documents_full: state.documents_full,
    sources: state.sources,
    agencies: state.agencies
  };
};

const ReduxDocumentUpdateModal = connect(mapStateToProps, mapDispatchToProps)(DocumentUpdateModal);

export default ReduxDocumentUpdateModal;
