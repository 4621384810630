import React from 'react';
import _ from 'lodash';
import { Modal, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import AppNotificationTypes from 'app/constants/AppNotificationTypes';

import {
  createAppNotification,
  editAppNotification,
  fetchAppNotifications
} from 'shared/features/user/user.actions';

const notEnabledOption = { value: false, label: 'No' };
const enabledOption = { value: true, label: 'Yes' };
const requiredFields = [
  'title', 'external_link', 'external_link_text', 'enabled', 'notification_type', 'expires_at'
];

const initialState = {
  title: '',
  video_link: '',
  external_link: '',
  external_link_text: 'Learn More',
  notification_type: null,
  enabled: notEnabledOption,
  expires_at: moment().add(2, 'weeks').toDate(),
  missingFields: []
};

class AppNotificationsModal extends React.Component {
  constructor(props) {
    super(props);
    const { editingAppNotification } = props;
    if (editingAppNotification) {
      this.state = {
        title: editingAppNotification.title,
        video_link: editingAppNotification.video_link,
        external_link: editingAppNotification.external_link,
        external_link_text: editingAppNotification.external_link_text,
        enabled: editingAppNotification.enabled ? enabledOption : notEnabledOption,
        notification_type: AppNotificationTypes.find(
          ({ value }) => value === editingAppNotification.notification_type
        ),
        expires_at: moment(editingAppNotification.expires_at).toDate(),
        missingFields: []
      };
    } else {
      this.state = initialState;
    }
  }

  handleChange = (field, value) => this.setState({ [field]: value });

  submit = () => {
    const { editingAppNotification } = this.props;
    const data = {
      title: this.state.title,
      video_link: this.state.video_link,
      external_link: this.state.external_link,
      external_link_text: this.state.external_link_text,
      enabled: _.get(this.state.enabled, 'value'),
      notification_type: _.get(this.state.notification_type, 'value'),
      expires_at: moment(this.state.expires_at)
    };

    const missingFields = requiredFields.filter(requiredField => (
      data[requiredField] === undefined || data[requiredField] === ''
    ));
    if (missingFields.length) {
      this.setState({ missingFields });
      return Promise.reject();
    }

    this.setState({ saving: true });
    let action;
    if (editingAppNotification) {
      action = this.props.editAppNotification(editingAppNotification.id, data);
    } else {
      action = this.props.createAppNotification(data);
    }
    return action
      .then(this.props.fetchAppNotifications.bind(null, { all: true }))
      .then(this.props.closeModal)
      // keep saving true until catch so if the API fails we don't reset the form
      .then(() => this.setState({
        ...initialState,
        saving: true
      }))
      .catch(() => this.setState({ saving: false }));
  }

  render() {
    const { missingFields } = this.state;
    return (
      <Modal show>
        <Modal.Body>
          {missingFields.length > 0 && `Complete required fields: ${missingFields.join(', ')}`}
          <FormGroup>
            <ControlLabel>Title</ControlLabel>
            <FormControl
              type="text"
              disabled={this.state.saving}
              value={this.state.title}
              onChange={e => this.handleChange('title', e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Video Link</ControlLabel>
            <FormControl
              type="text"
              value={this.state.video_link}
              disabled={this.state.saving}
              onChange={e => this.handleChange('video_link', e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>External Link</ControlLabel>
            <FormControl
              type="text"
              value={this.state.external_link}
              disabled={this.state.saving}
              onChange={e => this.handleChange('external_link', e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>External Link Text</ControlLabel>
            <FormControl
              type="text"
              value={this.state.external_link_text}
              disabled={this.state.saving}
              onChange={e => this.handleChange('external_link_text', e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Enabled</ControlLabel>
            <Select
              multi={false}
              options={[notEnabledOption, enabledOption]}
              value={this.state.enabled}
              disabled={this.state.saving}
              onChange={obj => this.handleChange('enabled', obj)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Notification Type</ControlLabel>
            <Select
              multi={false}
              options={AppNotificationTypes}
              value={this.state.notification_type}
              disabled={this.state.saving}
              onChange={obj => this.handleChange('notification_type', obj)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Expires At</ControlLabel>
            <DatePicker
              placeholderText="Expires At"
              selected={this.state.expires_at}
              disabled={this.state.saving}
              onChange={val => this.handleChange('expires_at', val)}
            />
          </FormGroup>
        </Modal.Body>
        <div>
          <Button
            bsStyle="primary"
            onClick={this.submit}
            disabled={this.state.saving}
          >
            Submit
          </Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              this.props.closeModal();
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  createAppNotification,
  editAppNotification,
  fetchAppNotifications
})(AppNotificationsModal);
