import _ from 'lodash';
import { ASC, DESC } from '../../components/SortElements/FilterArrow';

// changes flow null -> DESC -> ASC -> null
// example of sort file:
/*
  sorting_direction - asc / desc
  sorting_priority - integer, need to relative sorting
  {
    'spider_name':{
      'sorting_direction': 'asc',
      'sorting_priority': 1
    }
  }
*/
export const sortOrderChangesHelper = (fieldName, sort) => {
  let nextFilterValue = _.cloneDeep(sort);
  const currentValue = _.get(sort, [fieldName, 'sorting_direction'], '');
  const currentPriority = _.get(sort, [fieldName, 'sorting_priority'], 1);
  const isFirstPriority = currentPriority === 1;

  switch (currentValue) {
    case DESC:
      nextFilterValue[fieldName].sorting_direction = ASC;
      if (!isFirstPriority) {
        _.mapValues(nextFilterValue, sortField => ({
          ...sortField,
          sorting_priority: sortField.sorting_priority < currentPriority
            ? ++sortField.sorting_priority
            : sortField.sorting_priority
        }));
        nextFilterValue[fieldName].sorting_priority = 1;
      }
      break;
    case ASC:
      _.mapValues(nextFilterValue, sortField => ({
        ...sortField,
        sorting_priority: sortField.sorting_priority > currentPriority
          ? --sortField.sorting_priority
          : sortField.sorting_priority
      }));
      nextFilterValue = _.omit(nextFilterValue, fieldName);
      break;
    default:
      _.mapValues(nextFilterValue, sortField => ({
        ...sortField,
        sorting_priority: ++sortField.sorting_priority
      }));
      nextFilterValue = {
        ...nextFilterValue,
        [fieldName]: {
          sorting_priority: 1,
          sorting_direction: DESC
        }
      };
  }

  return nextFilterValue;
};
