import React from 'react';
import {
  AuthForm,
  AuthFormContainer,
  useAuthFormAlert,
  Button,
  BUTTON_TYPES,
  LoadingOverlay,
  TextField,
  LabeledField
} from 'app/common';
import { useLoginHandlers, useLoginData, useVerificationCodeEmailData } from './hooks';
import { useAuthReduxActions } from 'shared/features/auth/hooks';
import { PasswordResetModal } from './elements';
import './_login.scss';

export const Login = () => {
  const verificationCodeEmailData = useVerificationCodeEmailData();

  const { localState, localActions, reduxState } = useLoginData({ verificationCodeEmailData });

  const reduxActions = useAuthReduxActions();

  const { Alert, alertActions } = useAuthFormAlert();

  const {
    handleSubmitClick,
    handleForgetPasswordModalOpen,
    handleForgetPasswordModalClose,
    handleMessagePasswordConfirmed,
    handleInvalidCodeError
  } = useLoginHandlers({
    localState,
    localActions,
    reduxActions,
    alertActions,
    verificationCodeEmailData
  });

  return (
    <>
      {localState.isPasswordResetOpen && (
        <PasswordResetModal
          onClose={handleForgetPasswordModalClose}
          onSuccess={handleMessagePasswordConfirmed}
          onInvalidCodeError={handleInvalidCodeError}
        />
      )}
      <AuthFormContainer>
        <AuthForm>
          <LoadingOverlay isLoading={reduxState.isLoading}>
            <section className="login__form-body">
              <Alert className="login__form-alert" />
              <LabeledField label="Email" className="login__form-labeled-field">
                <TextField
                  type="email"
                  className="login__form-input"
                  placeholder="me@example.com"
                  onChange={localActions.setEmail}
                  value={localState.email}
                  required
                  autoFocus
                />
              </LabeledField>
              <LabeledField label="Password" className="login__form-labeled-field">
                <TextField
                  type="password"
                  className="login__form-input"
                  placeholder="password"
                  onChange={localActions.setPassword}
                  value={localState.password}
                  required
                />
              </LabeledField>
            </section>
            <section className="login__form-footer">
              <Button
                type={BUTTON_TYPES.PRIMARY}
                className="login__form-submit-button"
                onClick={handleSubmitClick}
              >
                Login
              </Button>
              <Button
                type={BUTTON_TYPES.TERTIARY_BOLD}
                className="login__form-secondary-button"
                onClick={handleForgetPasswordModalOpen}
              >
                Forgot your password?
              </Button>
            </section>
          </LoadingOverlay>
        </AuthForm>
      </AuthFormContainer>
    </>
  );
};
