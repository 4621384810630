import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../../components/DatePicker';
import { STARTING_DATE, FINISHING_DATE } from '../../../constants/runNowParametersNames';

const CrawlRunNowModalBodyInterval = ({ parameters, setParameter }) => {
  const handleDateChange = (name, value) => {
    setParameter(name, value);
  };

  const startingDateName = STARTING_DATE;
  const finishingDateName = FINISHING_DATE;

  return (
    <div className="dateWrapper">
      <DatePicker
        label="Starting Date"
        clearIcon={null}
        onChange={date => {
          handleDateChange(startingDateName, date);
        }}
        value={parameters[startingDateName]}
      />
      <DatePicker
        label="Finishing Date"
        clearIcon={null}
        onChange={date => {
          handleDateChange(finishingDateName, date);
        }}
        value={parameters[finishingDateName]}
      />
    </div>
  );
};

CrawlRunNowModalBodyInterval.propTypes = {
  parameters: PropTypes.shape({}),
  setParameter: PropTypes.func
};

CrawlRunNowModalBodyInterval.defaultProps = {
  parameters: {},
  setParameter: () => {}
};

export default CrawlRunNowModalBodyInterval;
