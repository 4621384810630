// Confirmation Modal State
export const MODE_CONFIRMATION_MODAL = {
  EXPORT: {
    title: 'Update file with construction',
    body: 'The config file previously used by the crawl runs will now be replaced with the config file updated in construction mode!'
  },
  IGNORE: { title: 'Ignore crawl', body: 'Crawl has not been added to the “ignore spider” tool!' },
  MOVE: { title: 'Update crawl', body: 'Selected crawls will be moved to idle!' }
};

export const CRAWL_DETAILS_REQUIRED_FOR_ACTIVATION = [
  'spider_name',
  'id',
  'source_url',
  'category',
  'jurisdiction'
];

export const CRAWL_DETAILS_REQUIRED_FOR_ACTIVATION_FOR_TENANT = [
  'spider_name',
  'id',
  'jurisdiction'
];

export const CRAWL_DETAILS_LABEL_MAP = {
  spider_name: 'Spider Name',
  id: 'Crawler ID',
  source_url: 'Source URL',
  category: 'Document type',
  jurisdiction: 'Jurisdiction'
};

export const CRAWL_STATUS_LABEL_MAP = {
  timed_out: 'Timed Out',
  construction: 'Construction',
  idle: 'Idle',
  running: 'Running',
  failed: 'Failed',
  deactivate: 'Deactivated',
  queued: 'Queued'
};

export const CRAWL_RUN_STATUS_LABEL_MAP = {
  processed: 'Processed',
  error: 'Error',
  failed: 'Failed',
  warning: 'Processed with some failures'
};

export const MAIN_TENANTS_HOSTNAME = [
  'admin-integration.compliance.ai',
  'admin-staging.compliance.ai',
  'admin.compliance.ai'
];
