import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FileUploadInput from '../../../components/FileUploadInput';
import {
  SPREADSHEET,
  ARCHIVE_FILE,
  DRY_RUN,
  DRY_RUN_TOOL_TIP
} from '../../../constants/runNowParametersNames';
import * as uiLib from '@compliance.ai/web-components';
import * as runNowParametersNameConstants from '../../../constants/runNowParametersNames';
import { ARCHIVE_CUSTOM_FULL_ACCESS } from '../../../constants/customCrawlRunNowSettings';
import * as crawlerDetailsRecurConstants from '../CrawlerDetails/elements/CrawlerDetailsRecur/CrawlerDetailsRecur.constants';

const CrawlRunNowModalBodySpreadsheet = ({ parameters, setParameter }) => {
  // spreadsheet
  const uploadedSpreadsheets = _.get(parameters, [SPREADSHEET], []);

  // archive
  const uploadedArchives = _.get(parameters, [ARCHIVE_FILE], []);
  return (
    <>
      <FileUploadInput
        label="Spreadsheet Upload"
        onChange={setParameter}
        accept=".csv, .xls, .txt, .xlsx, .xlsm, .xlsb, .odf, .tsv"
        name={SPREADSHEET}
        uploadedFiles={uploadedSpreadsheets}
      />
      <FileUploadInput
        label="Zip File Upload"
        onChange={setParameter}
        accept=".zip"
        name={ARCHIVE_FILE}
        uploadedFiles={uploadedArchives}
      />
      <div className="custom-component">
        <uiLib.Switch
          name={DRY_RUN}
          label="dry run"
          className="updateCheckBox"
          isChecked={parameters[runNowParametersNameConstants.DRY_RUN]}
          onChange={e => setParameter(runNowParametersNameConstants.DRY_RUN, e)}
        />
        <uiLib.Tooltip
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          placement={uiLib.TOOLTIP_POSITION.BOTTOM}
          title={DRY_RUN_TOOL_TIP}
        >
          <uiLib.IconInfoRounded className="infoTooltipIcon" />
        </uiLib.Tooltip>
      </div>
    </>
  );
};

CrawlRunNowModalBodySpreadsheet.propTypes = {
  parameters: PropTypes.shape({}),
  setParameter: PropTypes.func
};

CrawlRunNowModalBodySpreadsheet.defaultProps = {
  parameters: {},
  setParameter: () => {}
};

export default CrawlRunNowModalBodySpreadsheet;
