const HOST_PRODUCTION = 'admin.compliance.ai';
const HOST_STAGING = 'admin-staging.compliance.ai';
const HOST_INTEGRATION = 'admin-integration.compliance.ai';

function checkProductionEnv() {
  return window.location.host.includes(HOST_PRODUCTION)
    || window.location.host.includes(HOST_STAGING);
}

function checkIntegrationEnv() {
  return window.location.host.includes(HOST_INTEGRATION);
}

export { checkProductionEnv,checkIntegrationEnv };
