import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'documentCaiCategories';
export const initialState = {
  items: [],
  count: 0,
  load: false,
  timestamp: Date().toString()
};

const documentCaiCategoriesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setCaiCategory: (state, action) => action.payload,
    createCaiCategory: (state, action) => ({
      ...state,
      count: state.count+1,
      items: [...state.items, action.payload]
    }),
    updateCaiCategory: (state, action) => ({
      ...state,
      items: state.items.map(item => item.id === action.payload.id ? action.payload : item)
    }),
    removeCaiCategory: (state, action) => ({
      ...state,
      count: state.count-1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default documentCaiCategoriesReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setCaiCategory,
  createCaiCategory,
  updateCaiCategory,
  removeCaiCategory
} = documentCaiCategoriesReducer.actions;
