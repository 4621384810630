import type { CommandRequestsHandlers } from './useCommandRequestsHandlers.types';
import type { CommandRequestsData } from './useCommandRequestsData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as commandRequestsDataApi from 'shared/features/commands/commands.apiv2';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useCommandRequestsHandlers.helpers';

export const useCommandRequestsHandlers = ({
  localState,
  localActions
}: {
  localState: CommandRequestsData['localState'];
  localActions: CommandRequestsData['localActions'];
}): CommandRequestsHandlers => {
  const handleCommandRequestsFetch: CommandRequestsHandlers['handleCommandRequestsFetch'] = async (
    params,
    options
  ) => {
    try {
      const commandRequestsResponse = await commandRequestsDataApi.fetchCommandRequestsData({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      return {
        results: commandRequestsResponse.command_request,
        count: commandRequestsResponse.command_request_count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleFiltersSubmit: CommandRequestsHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleDetailsModalOpen: CommandRequestsHandlers['handleDetailsModalOpen'] = (
    details,
    selectedCommandRequest
  ) => {
    localActions.setSelectedCommandRequest(selectedCommandRequest);
    localActions.setDetailsModalInfo(details);
    localActions.setIsDetailsModalOpen(true);
  };

  const handleDetailsModalClose: CommandRequestsHandlers['handleDetailsModalClose'] = () => {
    localActions.setSelectedCommandRequest(null);
    localActions.setIsDetailsModalOpen(false);
    localActions.setDetailsModalInfo('');
  };

  const handleCreationModalOpen: CommandRequestsHandlers['handleCreationModalOpen'] = () => {
    localActions.setIsCreationModalOpen(true);
  };

  const handleCreationModalClose: CommandRequestsHandlers['handleCreationModalClose'] = () => {
    localActions.setIsCreationModalOpen(false);
  };

  return {
    handleCommandRequestsFetch,
    handleFiltersSubmit,
    handleDetailsModalOpen,
    handleDetailsModalClose,
    handleCreationModalOpen,
    handleCreationModalClose
  };
};
