import type { CrawlerActivity } from 'shared/features/crawlerSettings/crawlerActivity/crawlActivity.api.types';
import type { CrawlerDocumentsResponse } from 'shared/features/crawlerSettings/crawlerDocuments/crawlerDocuments.api.types';
import type { CrawlerCatalogDryRunData } from './useCrawlerCatalogDryRunData.types';

import { useMemo } from 'react';
import {
  collectErrorDocs,
  collectSkippedDocs,
  concatIngestedDocsRelation
} from '../CrawlerCatalog.helper';

export const useCrawlerCatalogDryRunData = ({
  crawlerDocuments,
  crawlerActivity,
  disableEdit
}: {
  crawlerDocuments: CrawlerDocumentsResponse;
  crawlerActivity: CrawlerActivity;
  disableEdit: boolean;
}): CrawlerCatalogDryRunData => {
  const ingestedDocs = useMemo(
    () => concatIngestedDocsRelation(crawlerDocuments?.dryRun),
    [crawlerDocuments]
  );

  const spiderRunReport = useMemo(
    () => crawlerDocuments?.dryRun?.spider_run_report ?? {},
    [crawlerDocuments]
  );
  const skippedDocs = useMemo(
    () => collectSkippedDocs(spiderRunReport?.skipped_docs_stats ?? []),
    [spiderRunReport]
  );
  const errorDocs = useMemo(() => collectErrorDocs(spiderRunReport?.logs ?? []), [spiderRunReport]);

  const reportDocumentsData = [...errorDocs, ...ingestedDocs, ...skippedDocs] as [];

  // dry run file params
  const fileName = crawlerActivity?.crawl_construction_dry_run ?? '';
  const fileUpdateDate = crawlerActivity?.crawl_construction_dry_run_update_date ?? '';

  // check that the dry run fulfills the prerequisites
  const constructionConfig = crawlerActivity?.crawl_construction_config;
  const configPrepared = crawlerDocuments.config.crawl_id || crawlerDocuments.config.spider_name;
  const isConfirmationDisabled = disableEdit || !configPrepared || !constructionConfig;
  return {
    formattedData: {
      fileName,
      fileUpdateDate,
      isConfirmationDisabled,
      reportDocumentsData
    }
  };
};
