import type { GlobalState } from '../../../reducers';
import type { HistoryFromResponse } from './crawlerHistory.api.types';

import { createSelector } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './crawlerHistory.constants';

export const getHistory = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state?.[REDUCER_NAME]
);

export const getHistoryList = createSelector(
  getHistory,
  (currentHistoryReducer): HistoryFromResponse[] => {
    return currentHistoryReducer?.history;
  }
);
