import React from 'react';
import MaterialSwitch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const Switch = ({
  id,
  onChange,
  isChecked,
  isDisabled,
  classes,
  className
}) => {
  return (
    <MaterialSwitch
      id={id}
      checked={isChecked}
      onChange={onChange}
      disabled={isDisabled}
      color={'default'}
      disableRipple
      className={className}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
    />
  );
};

const StyledSwitch = withStyles({
  switchBase: {
    color: '#FAFAFA',
    '&$checked': {
      color: '#078CFF'
    },
    '&$checked + $track': {
      backgroundColor: '#D3D3D3'
    }
  },
  checked: {},
  track: {
    backgroundColor: '#D3D3D3'
  }
})(Switch);

export default StyledSwitch;

StyledSwitch.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string
};

StyledSwitch.defaultProps = {
  id: 'switch',
  className: '',
  isDisabled: false
};
