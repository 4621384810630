import {
  api_fetchSpiderRunReportsCrawlerStatistic
} from './spiderRunReports.api';

export const RECEIVE_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC = 'RECEIVE_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC';
export const CLEAR_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC = 'CLEAR_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC';
export const FILTER_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC = 'FILTER_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC';

function receiveSpiderRunReportsCrawlerStatistic(json) {
  return {
    type: RECEIVE_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC,
    spiderRunReportsCrawlerStatistic: json
  };
}

function clearSpiderRunReportsCrawlerStatistic() {
  return {
    type: CLEAR_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC,
    spiderRunReportsCrawlerStatistic: null
  };
}

function filterSpiderRunReportsCrawlerStatistic(sort, columName) {
  return {
    type: FILTER_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC,
    filteredFieldName: { sort, columName }
  };
}

export function fetchSpiderRunReportsCrawlerStatistic(params = {}) {
  return function dofetch(dispatch) {
    dispatch(clearSpiderRunReportsCrawlerStatistic());
    return api_fetchSpiderRunReportsCrawlerStatistic(params)
      .then(response => {
        dispatch(receiveSpiderRunReportsCrawlerStatistic(response));
      })
      .catch(error => {
        dispatch(receiveSpiderRunReportsCrawlerStatistic({}));
      });
  };
}


export function sortCrawlerStatistic(sort, columName) {
  return dispatch => dispatch(
    filterSpiderRunReportsCrawlerStatistic(sort, columName)
  );
}
