import React from 'react';
import {
  Modal,
  Button,
  Alert,
  Col, Row,
  ControlLabel,
  FormGroup
} from 'react-bootstrap';
import Select from "react-select";

import usersRoles from '../../constants/userRoles';

export default function AdminRolesToolModal(
  {
    showModal,
    closeModal,
    handleChange,
    error,
    errorText,
    onSave,
    user_email,
    user_role,
    all_users,
    user_id
  }
) {
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="docMetaModal thinBtn"
    >
      <Modal.Header>
        <Modal.Title>
          {user_id ? user_email : 'Create new admin'}
        </Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <Row>
            {!user_id && (
              <Col sm={6}>
                <FormGroup>
                  <ControlLabel>
                    Select email
                  </ControlLabel>
                  <Select
                    className={error && !user_email ? "errorSelect" : ""}
                    options={all_users.map(user_profile => ({
                      value: user_profile.email,
                      label: user_profile.email
                    }))}
                    name="user_email"
                    value={user_email}
                    onChange={(e) => handleChange('user_email', e && e.value)}
                  />
                </FormGroup>
              </Col>
            )}
            <Col sm={6}>
              <FormGroup>
                <ControlLabel>
                  Select role
                </ControlLabel>
                <Select
                  className={error && !user_role ? "errorSelect" : ""}
                  options={Object.values(usersRoles)}
                  name="user_role"
                  value={user_role}
                  onChange={(e) => handleChange('user_role', e && e.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          {error && (
          <Alert bsStyle="danger" className="errorAlert">
            {errorText || 'Please check you entries!'}
          </Alert>)}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={onSave}>
            {user_id ? 'Save' : 'Add Admin'}
          </Button>
          <Button onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
