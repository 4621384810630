import styled from 'styled-components';
import React from "react";
import PropTypes from "prop-types";
import { COLORS } from 'app/constants/Style';

const StyledIconContainer = styled.span`
  background-color: ${COLORS.lightBackground};
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  cursor:pointer;

  > svg {
    font-size: 17px;
    position: relative;
    top: 3px;
    left: 3px;
    color: ${props => props.disabled ? 'rgba(0,0,0,0.26)' : 'black'};
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

const ActionIcon = ({ onClick, icon, disabled }) => {
  return (
    <StyledIconContainer
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      disabled={disabled}
    >
      {icon}
    </StyledIconContainer>
  );
};

ActionIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

ActionIcon.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default ActionIcon;
