import type * as apiTypes from './concepts.apiV2.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchConceptsData: apiTypes.FetchConceptsData = async params => {
  return fetch({
    url: `/concepts`,
    dataType: 'Concept',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
