import React from 'react';
import PropTypes from 'prop-types';
import FilterArrow from './FilterArrow';
import Typography from '../Typography';

const HeaderSort = ({
  type,
  onClick,
  item
}) => (
  <Typography
    variant="p"
    bold
    className="headFilter"
    onClick={onClick}
  >
    {item.name}
    {item.filter && (
      <FilterArrow
        type={type}
        className="headFilter_arrow"
        size="large"
      />
    )}
  </Typography>
);

HeaderSort.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null])
  ]),
  onClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  })
};

HeaderSort.defaultProps = {
  type: null,
  item: {}
};

export default HeaderSort;
