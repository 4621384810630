import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import Typography from "./Typography";

function Textarea({
  className,
  labelClassName,
  inputClassName,
  label,
  placeholder,
  value,
  name,
  onChange,
  isError,
  isLabelLink,
  isDisabled
}) {
  return (
    <label
      className={cn(
        'textarea_wrapper',
        className
      )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
        {
          isLabelLink && (
            <a
              href={value}
              title={placeholder}
            >
              <LaunchRoundedIcon />
            </a>
          )
        }
      </Typography>
      <textarea
        className={cn(
          'textarea_input',
          {
            textarea_input_error: isError,
            textarea_input_disabled: isDisabled
          },
          inputClassName
        )}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
      />
    </label>
  );
}


Textarea.propTypes = {
  isError: PropTypes.bool,
  isLabelLink: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired
};

Textarea.defaultProps = {
  isError: false,
  isLabelLink: false,
  isDisabled: false,
  className: '',
  labelClassName: '',
  inputClassName: '',
  label: '',
  placeholder: '',
  name: ''
};


export default Textarea;
