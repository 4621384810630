import _ from 'lodash';
import moment from 'moment';
import * as env from 'shared/config';
import { category_to_api, categories_skipped_in_date_search } from 'shared/utils/category';

export const DEFAULT_DOCUMENT_SORT_KEY = 'publication_date';
export const DEFAULT_DASH_CCPA_PARAMS = {
  search_sort: DEFAULT_DOCUMENT_SORT_KEY,
  order: 'desc',
  published_from: moment().subtract(1, 'week').format('MM/DD/YYYY'),
  search_query: 'CCPA OR "California Consumer Privacy Act"'
};
export const DEFAULT_DASH_COVID_PARAMS = {
  search_sort: DEFAULT_DOCUMENT_SORT_KEY,
  order: 'desc',
  published_from: moment().subtract(1, 'week').format('MM/DD/YYYY'),
  advanced_search: 'text = "coronavirus" OR text = "covid-19" OR text = "covid"'
};

export function buildUrlFromParams(params = {}, definedUrl) {
  params = {
    ...params
  };

  let url = definedUrl || `${env.apiUrl}/documents`;

  if (params.id) {
    url += '/' + params.id;
  }

  url += '?';

  if (params.spider_name) {
    url += `spider_name=${params.spider_name}`;
  }

  if (params.folder_id) {
    url += `folder_id=${params.folder_id}`;
    url += `&all_agencies=true`;
    url += `&all_topics=true`;
  }

  if (params.offset) {
    url += `&offset=${params.offset}`;
  }

  if (params.limit) {
    url += `&limit=${params.limit}`;
  } else if (params.folder_id) {
    url += `&limit=1000`; //FIXME: tmp high limit in folder view, add pagination later
  } else {
    url += `&limit=20`;
  }

  if (params.exclusive_fields && params.exclusive_fields.length > 0) {
    for (const field of params.exclusive_fields) {
      url += `&exclusive_fields=${field}`;
    }
  }

  if (!_.isNil(params.agency_ids) && params.agency_ids.length > 0) {
    if (typeof params.agency_ids === 'string') {
      url += `&agency_ids=${params.agency_ids}`;
    } else {
      if (params.agency_ids.length && Array.isArray(params.agency_ids)) {
        url += `&agency_ids=${params.agency_ids.join(',')}`;
      }
    }
  }

  if (!_.isNil(params.agency) && params.agency.length > 0) {
    if (typeof params.agency === 'string') {
      url += `&agency_id=${params.agency}`;
    } else {
      for (const agency of params.agency) {
        url += `&agency_id=${agency}`;
      }
    }
  }

  if (!_.isNil(params.jurisdiction) && params.jurisdiction.length > 0) {
    if (typeof params.jurisdiction === 'string') {
      url += `&jurisdiction=${params.jurisdiction}`;
    } else {
      for (const jurisdiction of params.jurisdiction) {
        url += `&jurisdiction=${jurisdiction}`;
      }
    }
  }

  if (!_.isNil(params.news_source_id) && params.news_source_id.length > 0) {
    if (typeof params.news_source_id === 'string') {
      url += `&news_source_id=${params.news_source_id}`;
    } else {
      for (const news_source of params.news_source_id) {
        if (parseInt(news_source, 10)) {
          url += `&news_source_id=${news_source}`;
        }
      }
    }
  }

  if (!_.isNil(params.override_min_score)) {
    url += `&min_score=${params.override_min_score}`;
  } else if (!_.isNil(params.min_score)) {
    url += `&min_score=${params.min_score}`;
  }

  if (!_.isNil(params.min_score)) {
    url += `&all_agencies=true`;
    url += `&all_topics=true`;
  }

  if (!_.isNil(params.entity) && params.entity.length > 0) {
    if (typeof params.entity === 'string') {
      url += `&entity_id=${params.entity}`;
    } else {
      for (const entity of params.entity) {
        url += `&entity_id=${entity}`;
      }
    }
  }

  if (!_.isNil(params.skip_agency) && params.skip_agency.length > 0) {
    if (typeof params.skip_agency === 'string') {
      url += `&skip_agency=${params.skip_agency}`;
    } else {
      for (const agency of params.skip_agency) {
        url += `&skip_agency=${agency}`;
      }
    }
  }

  if (!_.isNil(params.skip_category) && params.skip_category.length > 0) {
    if (typeof params.skip_category === 'string') {
      url += `&skip_category=${params.skip_category}`;
    } else {
      for (const category of params.skip_category) {
        url += `&skip_category=${category}`;
      }
    }
  }

  // n.b. need to encodeURIComponent because this came from a file rather than over the wire
  if (!_.isNil(params.category) && params.category.length > 0) {
    if (typeof params.category === 'string') {
      params.category = [params.category];
    }
    for (const category of params.category) {
      url += `&${category_to_api(category)}`;
    }
  }

  if (!_.isNil(params.read) && params.read.length > 0) {
    url += `&read=${params.read}`;
    //read folder/read filter view only
    if (params.read_folder_view) {
      // n.b. the api default behavior for filtering limits the list of agencies
      // we need to peel off this restriction here
      url += `&all_agencies=true`;
      url += `&all_topics=true`;
    }
  }

  if (!_.isNil(params.bookmarked) && params.bookmarked.length > 0) {
    url += `&bookmarked=${params.bookmarked}`;
    // n.b. the api default behavior for filtering limits the list of agencies
    // we need to peel off this restriction here
    url += `&all_agencies=true`;
    url += `&all_topics=true`;
  }

  if (!_.isNil(params.published_to) && params.published_to.length > 0) {
    url += `&published_to=${params.published_to}`;
  }

  if (!_.isNil(params.published_from) && params.published_from.length > 0) {
    url += `&published_from=${params.published_from}`;
  }

  if (!_.isNil(params.compliance_to) && params.compliance_to.length > 0) {
    url += `&compliance_to=${params.compliance_to}`;
  }

  if (!_.isNil(params.compliance_from) && params.compliance_from.length > 0) {
    url += `&compliance_from=${params.compliance_from}`;
  }

  if (!_.isNil(params.comments_close_to) && params.comments_close_to.length > 0) {
    url += `&comments_close_to=${params.comments_close_to}`;
  }

  if (!_.isNil(params.comments_close_from) && params.comments_close_from.length > 0) {
    url += `&comments_close_from=${params.comments_close_from}`;
  }

  if (!_.isNil(params.key_date_to) && params.key_date_to.length > 0) {
    url += `&key_date_to=${params.key_date_to}`;
  }

  if (!_.isNil(params.key_date_from) && params.key_date_from.length > 0) {
    url += `&key_date_from=${params.key_date_from}`;
  }

  if (!_.isNil(params.sort) && params.sort.length > 0) {
    url += `&sort=${params.sort}`;
  }

  if (!_.isNil(params.order) && params.order.length > 0) {
    url += `&order=${params.order}`;
  }

  // temporary bag-of-words search query for 'violation'
  if (!_.isNil(params.violation_query) && params.violation_query.length > 0) {
    url += `&violation_query=${params.violation_query}`;
  }

  if (params.search_query) {
    url += `&query=${params.search_query}`;
  }

  if (!_.isNil(params.search_sort)) {
    url += `&search_sort=${params.search_sort}`;
    url += `&all_agencies=true`;
    url += `&all_topics=true`;
  }

  if (!_.isNil(params.topic_id) && params.topic_id.length > 0) {
    if (typeof params.topic_id === 'string') {
      url += `&topic_id=${params.topic_id}`;
    } else {
      for (const id of params.topic_id) {
        url += `&topic_id=${id}`;
      }
    }
  }

  if (!_.isNil(params.label) && params.label.length > 0) {
    if (typeof params.label === 'string') {
      url += `&label=${encodeURIComponent(params.label)}`;
    } else {
      for (const id of params.label) {
        url += `&label=${id}`;
      }
    }
  }

  if (!_.isNil(params.regulation_id)) {
    if (typeof params.regulation_id === 'string') {
      url += `&regulation_id=${params.regulation_id}`;
    } else {
      for (const reg_id of params.regulation_id) {
        url += `&regulation_id=${reg_id}`;
      }
    }
  }

  if (!_.isNil(params.act_id) && params.act_id.length > 0) {
    if (typeof params.act_id === 'string') {
      url += `&act_id=${params.act_id}`;
    } else {
      for (const act of params.act_id) {
        url += `&act_id=${act}`;
      }
    }
  }

  if (!_.isNil(params.bank_id) && params.bank_id.length > 0) {
    if (typeof params.bank_id === 'string') {
      url += `&bank_id=${params.bank_id}`;
    } else {
      for (const bank of params.bank_id) {
        url += `&bank_id=${bank}`;
      }
    }
  }

  if (!_.isNil(params.citation) && params.citation.length > 0) {
    url += `&citation=${encodeURIComponent(params.citation)}`;
  }

  if (!_.isNil(params.title) && params.title.length > 0) {
    url += `&title=${params.title}`;
  }

  if (!_.isNil(params.text) && params.text.length > 0) {
    url += `&text=${encodeURIComponent(params.text)}`;
  }

  if (!_.isNil(params.advanced_search) && params.advanced_search.length > 0) {
    url += `&advanced_search=${encodeURIComponent(params.advanced_search)}`;
  }

  if (!_.isNil(params.search_sort) && params.search_sort === 'date') {
    // n.b. the api default behavior for filtering limits the list of agencies
    // we need to peel off this restriction here
    url += `&all_agencies=true`;
    url += `&all_topics=true`;

    // we need the full text in order to do client-side highlighting
    // n.b. this will definitely slow us down.. is there another way?
    url += `&full_text=true`;

    // ensure we get the most recent documents first
    url += `&sort=publication_date&order=desc`;

    // ensure we return mentions that triggered the proposed filter match
    // so we can highlight those terms
    url += `&include_mentions_for_filter=true`;

    // only use autosuggest filters when we are in date/filter mode
    // n.b. agencies is handled separately, and leads to somewhat of a
    // doubling up effect, but i think that is probably OK

    if (!_.isNil(params.docket_id)) {
      url += `&docket_id=${params.docket_id}`;
    }

    if (!_.isNil(params.concept_id)) {
      url += `&concept_id=${params.concept_id}`;
    }

    if (!_.isNil(params.citation_id)) {
      url += `&citation_id=${params.citation_id}`;
    }

    for (const category of categories_skipped_in_date_search) {
      url += `&skip_category=${category}`;
    }
  }

  if (!_.isNil(params.more_like_doc_id)) {
    url += `&more_like_doc_id=${params.more_like_doc_id}`;
  }

  if (!_.isNil(params.comments_for_id)) {
    url += `&comments_for_id=${params.comments_for_id}`;
  }

  if (!_.isNil(params.pdf_url)) {
    url += `&pdf_url=${params.pdf_url}`;
  }

  if (!_.isNil(params.all_agencies)) {
    url += `&all_agencies=${params.all_agencies}`;
  }

  if (!_.isNil(params.all_topics)) {
    url += `&all_topics=${params.all_topics}`;
  }

  if (!_.isNil(params.task_owner)) {
    url += `&task_owner=${params.task_owner}`;
  }

  if (!_.isNil(params.has_sentences)) {
    url += `&has_sentences=${params.has_sentences}`;
  }

  if (!_.isNil(params.has_annotations)) {
    url += `&has_annotations=${params.has_annotations}`;
  }

  if (!_.isNil(params.flagged_status)) {
    url += `&flagged_status=${params.flagged_status}`;
  }

  if (!_.isNil(params.has_obligations)) {
    url += `&has_obligations=${params.has_obligations}`;
  }

  if (!_.isNil(params.is_premium_content)) {
    url += `&is_premium_content=${params.is_premium_content}`;
  }

  if (!_.isNil(params.decorate_children)) {
    url += `&decorate_children=${params.decorate_children}`;
  }

  if (!_.isNil(params.resource_code)) {
    url += `&skip_fields_for_resource_code=${params.resource_code}`;
  }

  if (!_.isNil(params.doc_details)) {
    url += `&skip_fields_for_right_panel=${params.doc_details}`;
  }

  if (!_.isNil(params.size)) {
    url += `&size=${params.size}`;
  }

  // make skipping unused fields the default behavior, unless overriden
  if (_.isNil(params.skip_unused_fields)) {
    params.skip_unused_fields = true;
  }

  // Adding option to skip a set of fields, termed unused fields, the idea being that these fields
  // are not used by the front-end application currently, so we provide a boolean flag to toggle
  // them all off in one go to reduce the size of the payload
  if (params.skip_unused_fields) {
    url += '&skip_unused_fields=true';
  }

  if (!_.isNil(params.get_count_only)) {
    url += `&get_count_only=${params.get_count_only}`;
  }

  if (!_.isNil(params.location_id)) {
    url += `&location_id=${params.location_id}`;
  }

  if (!_.isNil(params.respondent) && params.respondent.length > 0) {
    // if (params.respondent) {
    url += `&respondent=${params.respondent}`;
  }

  if (!_.isNil(params.monetary_penalty) && params.monetary_penalty > 0) {
    url += `&monetary_penalty=${params.monetary_penalty}`;
  }

  if (!_.isNil(params.monetary_penalty_min) && params.monetary_penalty_min > 0) {
    url += `&monetary_penalty_min=${params.monetary_penalty_min}`;
  }

  if (!_.isNil(params.monetary_penalty_max) && params.monetary_penalty_max > 0) {
    url += `&monetary_penalty_max=${params.monetary_penalty_max}`;
  }

  if (params.timeline_view) {
    url += `&timeline_view=true`;
  }

  if (!_.isNil(params.pub_date_offset)) {
    url += `&pub_date_offset=${params.pub_date_offset}`;
  }

  if (!_.isNil(params.effective_on_date_offset)) {
    url += `&effective_on_date_offset=${params.effective_on_date_offset}`;
  }

  if (!_.isNil(params.comments_close_date_offset)) {
    url += `&comments_close_date_offset=${params.comments_close_date_offset}`;
  }

  if (params.previous_docs) {
    url += `&previous_docs=true`;
  }

  if (!_.isNil(params.pub_date_count)) {
    url += `&pub_date_count=${params.pub_date_count}`;
  }

  if (!_.isNil(params.effective_on_count)) {
    url += `&effective_on_count=${params.effective_on_count}`;
  }

  if (!_.isNil(params.comments_close_count)) {
    url += `&comments_close_count=${params.comments_close_count}`;
  }

  if (params.autocomplete_query) {
    url += `&autocomplete_query=true`;
  }

  if (params.original_query_param) {
    url += `&original_query_param=true`;
  }

  if (!_.isNil(params.doc_id)) {
    url += `&doc_id=${params.doc_id}`;
  }

  if (!_.isNil(params.related_doc_id)) {
    url += `&related_doc_id=${params.related_doc_id}`;
  }

  const topicThresholdKey = params.topic_threshold_key || localStorage.getItem('topicThresholdKey');
  if (topicThresholdKey) {
    url += `&topic_threshold_key=${topicThresholdKey}`;
  }

  return url;
}


export const process_combined_documents = (clear_current, state, action) => {
  const metadata = {
    isReady: true,
    isFetching: false,
    newest_dates: {
      ...state.newest_dates
    },
    oldest_dates: {
      ...state.oldest_dates
    },
    offsets: {
      ...state.offsets
    }
  };

  const combined_list = clear_current ? [] : state.combined_list;
  let offsets = {};
  let combined_count = 0;

  const new_data = action.data;
  const sort_params = Object.keys(action.data);

  let document_index;

  if (clear_current) {
    document_index = {};

    for (const sort of sort_params) {
      document_index[sort] = {};
    }
  } else {
    document_index = _.cloneDeep(state.document_index);
  }

  // handles the publication_date sorted documents first, because we need various
  // values populated based on the entire set before making decisions about documents
  // sorted on other fields
  // TODO: can this be re-factored a so more code can be shared?
  const has_publication_date = sort_params.indexOf('publication_date') !== -1;

  let newest_publication_date = moment(state.newest_dates.publication_date || -10e9);
  let oldest_publication_date = state.oldest_dates.publication_date
    ? moment(state.oldest_dates.publication_date)
    : moment();
  let publication_doc_count = 0;

  // hash that keeps track of which doc id and sort date pairs we've already added to
  // our list, so that when a document has effective / comments / publication dates
  // that are the same, we don't display it more than once on that specific date
  const doc_id_sort_date_mapping = {};

  // requestDocumentBefore uses this combiner too, and does not use publication_date as a sort
  // field, so we need to skip it here
  if (has_publication_date) {
    offsets = action.data.publication_date.offsets;

    for (const doc of action.data.publication_date.documents) {
      publication_doc_count += 1;

      // kind of weird, get doc.publication_date
      const sort_date = _.get(doc, 'publication_date');

      // occasionally the API returns documents that are missing the requested sort_date.
      // Usually happens with rule.effecitive_on
      if (!sort_date) {
        continue;
      }

      // // skip duplicate documents
      // if (document_index.publication_date[doc.id]) {
      //   continue;
      // }

      const sort_date_moment = moment(sort_date);
      const doc_id_sort_date_key = doc.id + sort_date_moment.format('YYYY-MM-DD');

      // for publication date only, we need to make sure we track the oldest date
      // since for the other sort fields, we don't want to show any of these values (unless
      // there were no results for publication_date, i.e. this is the end)
      if (sort_date && sort_date_moment.isBefore(oldest_publication_date)) {
        oldest_publication_date = sort_date_moment;
        metadata.oldest_dates.publication_date = sort_date;
      }

      if (sort_date && sort_date_moment.isAfter(newest_publication_date)) {
        newest_publication_date = sort_date_moment;
        metadata.newest_dates.publication_date = sort_date;
      }

      doc.sort_date = sort_date;
      doc.sort_basis = 'publication_date';
      document_index.publication_date[doc.id] = true;
      combined_list.push(doc);
      combined_count++;
      doc_id_sort_date_mapping[doc_id_sort_date_key] = true;
    }
  }

  for (const sort of sort_params) {
    // publication_date handled specially first above
    if (sort === 'publication_date') {
      continue;
    }

    const cur_docs = action.data[sort].documents;
    let newest_date = moment(state.newest_dates[sort] || -10e9);
    let oldest_date = state.oldest_dates[sort] ? moment(state.oldest_dates[sort]) : moment();

    for (const doc of cur_docs) {
      // kind of weird, get doc.publication_date, doc.rule.effective_on, etc
      const sort_date = _.get(doc, sort);

      // occasionally the API returns documents that are missing the requested sort_date.
      // Usually happens with rule.effecitive_on
      if (!sort_date) {
        continue;
      }

      // skip duplicate documents
      if (document_index[sort][doc.id]) {
        continue;
      }

      const sort_date_moment = moment(sort_date);
      const doc_id_sort_date_key = doc.id + sort_date_moment.format('YYYY-MM-DD');

      // if any document retrieved on a query sorted by compliance_date or comments_close_date
      // has a document older than the oldest document sorted by publication_date, skip it
      // otherwise the user might see dates that diverge greatly from the timeline ordering
      // the oldest one that we do get gets marked below so we will always come back to these
      // everytime a user scrolls downwards, re-checking if they are in the range we care about
      // at that point.
      // n.b. the one exception being if there are no documents in the bottom-most scrolled
      // list of publication_date sorted documents, then we can just tack everything else
      // on the end
      if (
        sort_date
        && sort_date_moment.isBefore(oldest_publication_date)
        && publication_doc_count > 0
      ) {
        continue;
      }

      // if we have already seen this doc on this same sort date, we don't need to add it to the
      // list again, otherwise we'd end up with duplicates in the display
      if (doc_id_sort_date_mapping[doc_id_sort_date_key]) {
        continue;
      }

      if (sort_date && sort_date_moment.isAfter(newest_date)) {
        newest_date = sort_date_moment;
        metadata.newest_dates[sort] = sort_date;
      }

      // track the oldest dates so we can send date_to queries to the api to limit what we show
      if (sort_date && sort_date_moment.isBefore(oldest_date)) {
        oldest_date = sort_date_moment;
        metadata.oldest_dates[sort] = sort_date;
      }

      doc.sort_date = sort_date;
      doc.sort_basis = sort;

      document_index[sort][doc.id] = true;

      combined_list.push(doc);
      combined_count++;
      doc_id_sort_date_mapping[doc_id_sort_date_key] = true;
    }
  }

  // TODO potential optimization:
  // we can rely on the fact that each document list that we
  // receive from the api is already sorted. Instead of doing a O(N lgN)
  // sort after merging the arrays, we could do a O(N) merge operation.
  // Essentially this is half of a merge sort

  return _.assign({}, state, metadata, new_data, {
    count: combined_count,
    combined_list: _.orderBy(combined_list, 'sort_date', 'desc'),
    document_index,
    offsets
  });
};
