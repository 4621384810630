import type { GlobalState } from '../../reducers';
import type { OrganizationState } from './organizations.types';

import { createSelector } from '@reduxjs/toolkit';

export const getOrganizationReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.organization as OrganizationState
);

export const getOrganization = createSelector(
  getOrganizationReducer,
  (organizationReducer: OrganizationState) => organizationReducer.organization
);

export const getOrganizationIsLoading = createSelector(
  getOrganizationReducer,
  (organizationReducer: OrganizationState) => organizationReducer.isFetching
);

export const getOrganizationMembers = createSelector(
  getOrganization,
  (organization: OrganizationState['organization']) => organization?.organization_members ?? []
);
