import React from 'react';

const headerColumns = [
  'Command name', 'Status', 'User', 'Parameters', 'Details', ''
];

const CrawlerPerformanceCommandRequestTableHeader = props => {
  const tableHead = headerColumns.map((item, index) => (
    <div
      className="centeredHeaderName"
      key={index}
    >
      {item}
    </div>)
  );
  return (
    <div className="adminToolTableFlexRow flexHeader">
      {tableHead}
    </div>
  );
};

export default CrawlerPerformanceCommandRequestTableHeader;
