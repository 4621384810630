export enum COMMAND_REQUESTS_DATA_FETCH_QUERY_PARAMS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  STATUS = 'status',
  COMMAND_STATUS = 'command_status',
  STATUS_DETAILS = 'status_details',
  UPLOAD_LINK = 'upload_link',
  USER = 'USER',
  USER_ID = 'user_id',
  USER_IDS = 'user_ids',
  TYPE = 'type',
  COMMAND_IDS = 'command_ids',
  PARAMETERS = 'parameters',
  MULTI_INPUT = 'multi_input',
  CRAWL_ID = 'crawl_id',
  CRAWL_IDS = 'crawl_ids',
  COMMAND_NAME = 'command_name',
  CREATED_AT_START = 'created_at_start',
  CREATED_AT_END = 'created_at_end',
  UPDATED_AT_START = 'updated_at_start',
  UPDATED_AT_END = 'updated_at_end',
  AUTO_QA_URL = 'auto_qa_url'
}

export enum COMMANDS_DATA_FETCH_QUERY_PARAMS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  DESCRIPTION = 'description',
  PARAMETERS = 'parameters',
  CREATED_AT_START = 'created_at_start',
  CREATED_AT_END = 'created_at_end',
  UPDATED_AT_START = 'updated_at_start',
  UPDATED_AT_END = 'updated_at_end'
}
