const commandRequestTypes = {
  enforcementUpload: 'enforcement-upload',
  crawlRunNow: 'on_demand_crawl_run',
  trainModel: 'train_model',
  crawlRunNowCustom: 'run_now_custom_crawl',
  crawlPerformance: 'get_crawl_performance',
  crawlSpreadsheetDoc: 'crawl_spreadsheet_documents',
  deprecateDocuments: 'deprecate_documents',
  convertConfig: 'convert_spec_to_config',
  crawlRunNowOsapi: 'run_now_osapi_crawl',
  documentSeeding: 'document_seeding',
  automatedCrawlQa: 'automated_crawl_qa',
  bulkDocumentUpdate: 'bulk_document_update'
};

const COMMAND_REQUEST_NAMES = {
  DRY_RUN_CRAWLER_COMMAND: 'Dry Run Crawl',
  RUN_CRAWLER_COMMAND: 'Run Crawler',
  RUN_CUSTOM_CRAWLER_COMMAND: 'Run Custom Crawl',
  RUN_FEEDLY_CRAWLER_COMMAND: 'Run Feedly Crawl',
  UPLOAD_ENFORCEMENT_COMMAND: 'Upload Enforcement',
  CRAWL_SPREADSHEET_DOC: 'Crawl spreadsheet documents',
  CRAWL_PERFORMANCE_COMMAND: 'Crawl Performance Command',
  DOCUMENT_DEPRECATION_COMMAND: 'Documents Deprecation Command',
  SPEC_TO_CONFIG_CONVERSION: 'Convert spec to config',
  RUN_OSAPI_CRAWLER_COMMAND: 'Run OSAPI Crawl',
  DOCUMENT_SEEDING: 'Document Seeding',
  AUTOMATED_CRAWL_QA_COMMAND: 'Automated crawl QA',
  BULK_DOCUMENT_UPDATE: 'Bulk Document Update'
};

const COMMAND_REQUEST_STATUS = {
  QUEUED: 'queued',
  MSG_QUEUED: 'msg_queued',
  TIMED_OUT: 'timed_out',
  FAILED: 'failed',
  FILE_UPLOADING: 'file_uploading',
  IN_PROGRESS: 'in_progress',
  PROCESSING_DRY_RUN: 'processing_dry_run',
  PROCESSING_COMMIT_TO_DB: 'processing_commit_to_db',
  PROCESSING_READY_FOR_FIX: 'processing_ready_for_fix',
  PROCESSED_READY_FOR_FIX: 'processed_ready_for_fix',
  COMMIT_TO_DB: 'commit_to_db',
  READY_FOR_DB: 'ready_for_db',
  READY_FOR_FIX: 'ready_for_fix',
  COMPLETED: 'complete',
  READY_FOR_COMMIT: 'ready_for_commit_to_db',
  READY_FOR_FIX_ENFORCEMENT: 'ready_for_fix_enforcement'
};

const commandsSupportRunNow = [
  commandRequestTypes.crawlRunNow,
  commandRequestTypes.crawlRunNowCustom,
  commandRequestTypes.crawlSpreadsheetDoc,
  commandRequestTypes.crawlRunNowOsapi
];

export {
  commandRequestTypes,
  commandsSupportRunNow,
  COMMAND_REQUEST_NAMES,
  COMMAND_REQUEST_STATUS
};
