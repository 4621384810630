import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  RELATION_TYPES,
  ERRORS,
  INFO_HELP,
  NAMES_RELATION
} from '../CrawlSpecCreate.constants';
import SpecElementsWrapper from "./SpecElementsWrapper";
import Select from "../../../components/ReactSelect/Select";
import Checkbox from "../../../components/Checkbox_NEW";

const Relation = ({
  disabled,
  show,
  element,
  changeRelation,
  errorFields
}) => {
  const typeRelationOptions = useMemo(
    () => Object.values(RELATION_TYPES).map(
      item => ({ value: item, label: item })
    ),
    []
  );

  return (
    <SpecElementsWrapper
      show={show}
      label="Relation"
      tooltip={INFO_HELP.RELATION}
    >
      <Select
        label="Type"
        value={element[NAMES_RELATION.RELATION_TYPE]}
        onChange={e => changeRelation(NAMES_RELATION.RELATION_TYPE, e)}
        options={typeRelationOptions}
        isDisabled={disabled}
        isError={errorFields.includes(NAMES_RELATION.RELATION_TYPE)}
        errorText={ERRORS.relationType}
      />
      <Checkbox
        label="Clear Exist Relation"
        disabled={disabled}
        onClick={checked => changeRelation(NAMES_RELATION.CLEAR_EXISTING_RELATIONS, checked)}
        isError={errorFields.includes(NAMES_RELATION.CLEAR_EXISTING_RELATIONS)}
        checked={element[NAMES_RELATION.CLEAR_EXISTING_RELATIONS]}
      />
    </SpecElementsWrapper>
  );
};

Relation.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  element: PropTypes.shape({}),
  changeRelation: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Relation.defaultProps = {
  disabled: false,
  show: false,
  element: {},
  errorFields: []
};

export default Relation;
