export const nodeEnv = process.env.NODE_ENV;
export const port = process.env.PORT;
export const apiUrl = process.env.REACT_APP_JURISPECT_API_URL || '';
export const gaApiUrl = process.env.REACT_APP_JURISPECT_API_URL_GA || '';
export const dataApiUrl = process.env.REACT_APP_JURISPECT_DATA_API_URL || '';
export const slotDataApiUrl = process.env.REACT_APP_JURISPECT_SLOT_DATA_API_URL || '';
export const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
export const apiKey = process.env.REACT_APP_API_KEY;
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const appUrl = process.env.REACT_APP_APPLICATION_URL;
export const agencyLandingPageUrl = process.env.REACT_APP_AGENCY_LANDING_PAGE_URL;
export const appAdminUrl = process.env.REACT_APP_ADMIN_APPLICATION_URL;
export const linkedInLoginRedirectUrl = process.env.REACT_APP_LINKEDIN_LOGIN_REDIRECT_URL;
export const linkedInActivateRedirectUrl = process.env.REACT_APP_LINKEDIN_ACTIVATE_REDIRECT_URL;
export const linkedInClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const googleLoginRedirectUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL;
export const googleActivateRedirectUrl = process.env.REACT_APP_GOOGLE_ACTIVATE_REDIRECT_URL;
export const onboardingTooltipEnabled = process.env.REACT_APP_ONBOARDING_TOOLTIP_ENABLED;
export const rssEnabled = process.env.REACT_APP_RSS_ENABLED;
export const rssUrl = process.env.REACT_APP_RSS_URL;
export const rssLimit = process.env.REACT_APP_RSS_LIMIT;
export const rssDays = process.env.REACT_APP_RSS_DAYS;
export const activateVideo = process.env.REACT_APP_ACTIVATE_PAGE_VIDEO;
export const appType = process.env.REACT_APP_APP_TYPE;
export const notificationsEnabled = process.env.REACT_APP_NOTIFICATIONS_ENABLED;
export const workflowEnabled = process.env.REACT_APP_WORKFLOW_ENABLED;
export const enforcementExplorerEnabled = process.env.REACT_APP_ENFORCEMENT_EXPLORER_ENABLED;
export const maxFileSizeRunNow = process.env.REACT_APP_MAX_FILE_SIZE_RUN_NOW;
export const googleSearchAPIKey = process.env.REACT_APP_GOOGLE_SEARCH_API_KEY;
export const googleSearchCXId = process.env.REACT_APP_GOOGLE_SEARCH_CX_ID;
export const prerenderToken = process.env.REACT_APP_PRERENDER_TOKEN;
export const diffingEnabled = process.env.REACT_APP_DIFFING_ENABLED;
export const tasksPageV2 = process.env.REACT_APP_TASKS_PAGE_V2;
export const tasksPageV2Reassign = process.env.REACT_APP_TASKS_PAGE_V2_REASSIGN;
export const newsSourceS3BucketUrl = process.env.REACT_APP_NEWSSOURCE_S3_BUCKET_URL;
export const configHerokuAuthKey = process.env.REACT_APP_CONFIG_HEROKU_AUTH_KEY;
export const configHerokuApiAppIds = process.env.REACT_APP_CONFIG_HEROKU_API_APP_IDS;
export const activationUrl = process.env.REACT_APP_ACCOUNT_ACTIVATION_URL;
export const newThresholdsEnabled = process.env.REACT_APP_NEW_THRESHOLDS_ENABLED;
export const supportIframeURL = process.env.REACT_APP_SUPPORT_IFRAME_URL;
export const expertConnectionResourcesEnabled =
  process.env.REACT_APP_EXPERT_CONNECTION_RESOURCES_ENABLED;
export const userPoolId = process.env.REACT_APP_USER_POOL_ID || null;
export const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID || null;
export const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN || null;
export const googleAnalyticsCode = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE || null;
export const mixPanelEnabled = process.env.REACT_APP_MIXPANED_ENABLED || false;

export const wafBypassHeader = process.env.REACT_APP_WAF_BYPASS_HEADER || '';
export const wafBypassHeaderValue = process.env.REACT_APP_WAF_BYPASS_HEADER_VALUE || '';
