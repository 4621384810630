import { fetch } from 'fetch';

export function api_updateDashboardLayout(params, rnToken) {
  return fetch({
    url: '/dashboard_layout',
    method: 'POST',
    dataType: 'DashboardLayout',
    params,
    customToken: rnToken
  });
}

export function api_readNewFeatureTip(email, data) {
  return fetch({
    url: `/users/${encodeURIComponent(email)}`,
    params: data,
    dataType: 'Feature',
    method: 'POST'
  });
}

export function api_deleteUser(email) {
  return fetch({
    url: `/delete_user/${email}`,
    dataType: 'User',
    method: 'DELETE'
  });
}

export function api_fetchAppNotifications(params) {
  return fetch({
    url: '/app-notifications/all',
    dataType: 'AppNotifications',
    params,
    method: 'GET'
  });
}

export function api_createAppNotification(data) {
  return fetch({
    url: '/app-notifications',
    dataType: 'AppNotifications',
    method: 'POST',
    params: data
  });
}

export function api_editAppNotification(editNotificationId, data) {
  return fetch({
    url: `/app-notifications/${editNotificationId}`,
    dataType: 'AppNotifications',
    params: data,
    method: 'POST'
  });
}

export function api_updateUser(email, params, rnToken) {
  return fetch({
    url: `/users/${encodeURIComponent(email)}`,
    dataType: 'User',
    params,
    method: 'POST',
    customToken: rnToken
  });
}

export function api_getUserCreatedDocuments(status) {
  return fetch({
    url: `/user_created_documents`,
    dataType: 'UserCreatedDocuments',
    method: 'GET',
    params: { status }
  });
}

export function api_getCurrentUser(rnToken) {
  return fetch({
    url: '/current_user',
    dataType: 'CurrentUser',
    method: 'GET',
    customToken: rnToken
  });
}

export function api_getAllUsers() {
  return fetch({
    url: '/users',
    dataType: 'Users',
    method: 'GET'
  });
}

export function api_postSearchQuery(data) {
  return fetch({
    url: '/search_queries',
    params: data,
    method: 'POST'
  });
}

export function api_rateSearchResult(data) {
  return fetch({
    url: '/rated_results',
    params: data,
    method: 'POST'
  });
}

export function api_getUser(email) {
  return fetch({
    url: `/users/${email}`,
    method: 'GET'
  });
}

export function api_authorizePCUser({ email, pcSourceId, docId }) {
  return fetch({
    url: '/premium_content/authorize',
    method: 'POST',
    params: { email, premium_content_source_id: pcSourceId, document_id: docId }
  });
}

export function api_sendConfirmationEmail(email) {
  return fetch({
    url: '/send_confirm_email',
    method: 'POST',
    params: { email }
  });
}

export function api_addUserToOrgFromInvite(params, rnToken) {
  return fetch({
    url: '/join_organization_from_invite',
    method: 'POST',
    customToken: rnToken,
    params
  });
}

export function api_unfollowCategories(data) {
  return fetch({
    url: '/unfollow_categories',
    params: data,
    method: 'POST'
  });
}

export function api_followCategories(data) {
  return fetch({
    url: '/follow_categories',
    params: data,
    method: 'POST'
  });
}

export function api_getRSSConfigurations(params) {
  return fetch({
    url: '/rss_configurations',
    method: 'GET',
    params
  });
}

export function api_editRSSConfiguration(uuid, params) {
  return fetch({
    url: `/rss_configuration/${uuid}`,
    method: 'POST',
    params
  });
}

export function api_createRSSConfiguration(params) {
  return fetch({
    url: `/rss_configuration`,
    method: 'POST',
    params
  });
}
