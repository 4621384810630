import _ from "lodash";
import {
  RECEIVE_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC,
  CLEAR_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC,
  FILTER_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC
} from './spiderRunReports.actions';

export const getInitialSpiderRunReportsCrawlerStatisticState = () => null;

export const spiderRunReportsCrawlerStatistic = (
  state = getInitialSpiderRunReportsCrawlerStatisticState(), action
) => {
  switch (action.type) {
    case RECEIVE_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC: {
      return action.spiderRunReportsCrawlerStatistic;
    }
    case CLEAR_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC: {
      return null;
    }
    case FILTER_SPIDER_RUN_REPORTS_CRAWLER_STATISTIC: {
      const { sort, columName } = action.filteredFieldName;

      const sortedSpiderReports = _.cloneDeep(state);
      sortedSpiderReports.sort((item1, item2) => {
        const firstReport = item1[columName];
        const secondReport = item2[columName];

        if ((!firstReport && secondReport) || (firstReport < secondReport)) {
          return sort[columName] ? 1 : -1;
        }
        if ((firstReport && !secondReport) || (firstReport > secondReport)) {
          return sort[columName] ? -1 : 1;
        }
        return 0;
      });

      return sortedSpiderReports;
    }
    default:
      return state;
  }
};
