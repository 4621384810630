export const login = '/login';
export const resetPassword = '/reset-password';
export const activate = '/activate';
export const privacy = '/privacy';
export const terms = '/terms';
export const nonAuthSupport = '/nonAuthSupport';
export const socialCallback = '/socialcallback';
export const plans = '/plans';
export const unsubscribe = '/unsubscribe';
export const dashboard = '/dashboard';
export const content = '/content';
export const sources = '/sources';
export const insights = '/insights';
export const resources = '/resources';
export const enforcementExplorer = '/enforcementexplorer';
export const support = '/support';
export const manageWorkflow = '/manage-workflow';
export const folders = '/folders';
export const account = '/account';
export const settings = '/settings';
export const legal = '/legal';
export const checkout = '/checkout';
export const notifications = '/notifications';
export const tasks = '/tasks';
export const topics = '/topics';
