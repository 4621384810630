import { useDidMount, useUpdateEffect, useWillUnmount } from 'admin/utils/hooks';

export const useAlertLifecycle = ({ props, localState, localActions }) => {
  const setAlertTimeout = () => {
    localActions.setVisibilityFlag(true);

    const timeout = setTimeout(() => {
      localActions.setVisibilityFlag(false);
    }, props.timeout);

    localActions.setLocalTimeout(timeout);
  };

  const removeAlertTimeout = () => {
    if (localState.timeout) {
      clearTimeout(localState.timeout);
    }
  };

  useDidMount(() => {
    setAlertTimeout();
  });

  useUpdateEffect(() => {
    removeAlertTimeout();
    setAlertTimeout();
  }, [props.type, props.title, props.content]);

  useWillUnmount(() => {
    removeAlertTimeout();
  });
};
