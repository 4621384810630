import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import GlassdoorHeader from './GlassdoorHeader';
import GlassdoorTable from './GlassdoorTable/GlassdoorTable';
import GlassdoorFooter from './GlassdoorFooter';
import './Glassdoor.styles.scss';
import { getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.actions';
import { formatJur } from "./Glassdoor.helper";

const Glassdoor = () => {
  const dispatch = useDispatch();

  // ----- JURISDICTIONS -----
  const jurisdictions = useSelector(
    state => _.get(state.jurisdictionsData, ['items'], [])
  );
  const jurisdictionsCount = useSelector(
    state => _.get(state.jurisdictionsData, ['count'], 1)
  );
  const jurisdictionsLoad = useSelector(
    state => _.get(state.jurisdictionsData, ['load'], false)
  );
  const jurisdictionsTimestamp = useSelector(
    state => _.get(state.jurisdictionsData, ['timestamp'], '')
  );

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({});

  const requestJurisdictions = () => {
    dispatch(getJurisdictions({
      glassdoor: true,
      offset: (page - 1) * limit,
      limit
    }));
  };

  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(requestJurisdictions, [page, limit]);

  const setSearchValues = (name, value) => {
    setSearch(prevState => ({ ...prevState, [name]: value }));
    setPage(1);
  };

  const toggleSearch = () => {
    // run call to get data
  };

  return (
    <div className="glassdoor">
      <GlassdoorHeader
        changeFilter={setSearchValues}
        search={search}
        toggleSearch={toggleSearch}
      />
      <GlassdoorTable
        // jurisdictions
        jur={formatJur(jurisdictions, jurisdictionsTimestamp)}
        jurLoad={jurisdictionsLoad}
      />
      <GlassdoorFooter
        onChangePage={setPage}
        onChangeLimit={setLimit}
        limit={limit}
        pageQuantity={Math.ceil(jurisdictionsCount / limit)}
      />
    </div>
  );
};

export default Glassdoor;
