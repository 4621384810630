import type { CrawlStatusSelectProps } from './CrawlStatusSelect.types';

import React from 'react';
import {
  Button,
  Dropdown,
  IconArrowLeftSolid,
  IconArrowRightSolid,
  List
} from '@compliance.ai/web-components';
import { useCrawlStatusSelectData, useCrawlStatusSelectHandlers } from './hooks';

import './crawl-status-select.scss';

export const CrawlStatusSelect = ({
  crawlState,
  crawlStateOptions,
  changeCrawlState,
  ...props
}: CrawlStatusSelectProps) => {
  const { localState, localActions } = useCrawlStatusSelectData();

  const handlers = useCrawlStatusSelectHandlers({
    props: {
      changeCrawlState
    },
    localActions
  });

  return (
    <Dropdown
      {...props}
      isOpen={localState.isDropdownOpen}
      onClose={handlers.handleDropdownClose}
      containerClassName="crawl-status-select__trigger-content"
      className="crawl-status-select__dropdown-content"
      triggerContent={
        <Button
          className="crawl-status-select__trigger-button"
          type={crawlState?.buttonType}
          startIcon={crawlState?.startIcon}
          endIcon={localState.isDropdownOpen ? <IconArrowLeftSolid /> : <IconArrowRightSolid />}
          onClick={handlers.handleTriggerClick}
        >
          <div className="crawl-status-select__trigger-button-label">{crawlState?.label}</div>
        </Button>
      }
      dropdownContent={
        <List
          className="crawl-status-select__state-options-list"
          options={crawlStateOptions}
          onOptionClick={handlers.handleCrawlStateOptionClick}
          selectedOptions={[]}
        />
      }
      shouldDisablePortal={false}
    />
  );
};
