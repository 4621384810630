import { fetch, BASE_API_OPTIONS } from 'fetch';

export function api_getExternalApiCrawlsStatuses(params) {
  return fetch({
    url: `/external-api-crawls-status`,
    method: 'GET',
    dataType: 'RSSFeeds',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_getExternalApiCrawlsRunStatuses(params) {
  return fetch({
    url: `/external-api-crawls-run-status`,
    method: 'GET',
    dataType: 'RSSFeeds',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_getExternalApiCrawls(params) {
  return fetch({
    url: `/external-api-crawls`,
    dataType: 'ExternalApiCrawl',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postExternalApiCrawls(params) {
  return fetch({
    url: `/external-api-crawls`,
    dataType: 'ExternalApiCrawl',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putExternalApiCrawls(params) {
  return fetch({
    url: `/external-api-crawls`,
    dataType: 'ExternalApiCrawl',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteExternalApiCrawls(params) {
  return fetch({
    url: `/external-api-crawls`,
    dataType: 'ExternalApiCrawl',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
