import type { LogsLinkCellData } from './useLogsLinkCellData.types';
import type { LogsLinkCellHandlers } from './useLogsLinkCellHandlers.types';

export const useLogsLinkCellHandlers = ({
  localActions
}: {
  localActions: LogsLinkCellData['localActions'];
}): LogsLinkCellHandlers => {
  const handleModalToggle: LogsLinkCellHandlers['handleModalToggle'] = isOpen => () => {
    localActions.setIsModalOpen(isOpen);
  };

  return {
    handleModalToggle
  };
};
