import _ from "lodash";
import { api_getExternalApiCrawls } from 'shared/features/externalApiCrawl/externalApiCrawl.api';
import { api_getCategories } from 'shared/features/categories/categories/categories.api';
import { api_getAgencies } from 'shared/features/agencyData/agencyData.api.js';

const formatSelectOptions = (array, valueName, labelName) => array.map(item => ({
  value: _.get(item, valueName, item),
  label: _.get(item, labelName, item)
}));

export const loadAgencyOptions = (
  name, searchValue, responseName
) => api_getAgencies(
  { [name]: searchValue }
)
  .then(agency => agency.items)
  .then(agency => formatSelectOptions(agency, 'id', responseName));

export const loadCategoryOptions = (
  name, searchValue, responseName
) => api_getCategories(
  { [name]: searchValue }
)
  .then(cat => cat.items)
  .then(cat => formatSelectOptions(cat, responseName, responseName));


export const loadSpiderNameOptions = (
  name, searchValue, responseName
) => api_getExternalApiCrawls(
  { [name]: searchValue, light_version: true }
)
  .then(crawl => crawl.items)
  .then(crawl => formatSelectOptions(crawl, 'spider_name', responseName));
