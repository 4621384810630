import type { EventsCellValue } from './EventsCell.types';

import * as uiLib from '@compliance.ai/web-components';
import * as components from 'admin/components';

import { LogsModal } from '../../LogsModal';

import './_events-cell.scss';

export const EventsCell: Required<uiLib.Column>['render'] = (value, row) => {
  const documentId = (value as EventsCellValue)?.documentId;

  return (
    <uiLib.ErrorBoundary>
      <LogsModal title={`Doc ID ${documentId}: Events`}>
        <components.EventsTable
          documentId={documentId}
          classNames={{
            container: 'events-cell__table-outer-container',
            tableContainer: 'events-cell__table-inner-container'
          }}
        />
      </LogsModal>
    </uiLib.ErrorBoundary>
  );
};
