import type { ComponentProps } from 'react';
import type { useNewsSourcesFiltersAsyncLoaders } from './useNewsSourcesFiltersAsyncLoaders';

import * as uiLib from '@compliance.ai/web-components';
import * as newsSourcesConstants from 'shared/features/sources/newsSources/newsSources.constants';

export const useNewsSourcesFiltersConfig = ({
  filterAsyncLoaders
}: {
  filterAsyncLoaders: ReturnType<typeof useNewsSourcesFiltersAsyncLoaders>;
}): ComponentProps<typeof uiLib.Filters>['config'] => [
  {
    key: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.JURISDICTION_IDS,
    label: 'Jurisdiction',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad:
      filterAsyncLoaders[newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.JURISDICTION_IDS]
  },
  {
    key: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.AGENCY_IDS,
    label: 'Agency',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad:
      filterAsyncLoaders[newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.AGENCY_IDS]
  }
];
