import { nameOfCategories as NOC } from './dashboardCategory';

const typeOfAccess = {
  edit: 'edit',
  view: 'view',
  none: 'none'
};

const namesOfRoles = {
  superAdmin: 'super_admin',
  dataAdmin: 'data_admin',
  userAdmin: 'user_admin',
  basicAdmin: 'basic_admin',
  dataAssociateAdmin: 'data_associate_admin',
  admin: 'admin',
  qa: 'qa',
  contributor: 'contributor'
};

export default {
  [namesOfRoles.superAdmin]: {
    value: namesOfRoles.superAdmin,
    label: 'Super Admin',
    permissions: {
      [NOC.adminSettings]: typeOfAccess.edit,
      [NOC.devOps]: typeOfAccess.edit,
      [NOC.documentQA]: typeOfAccess.edit,
      [NOC.documentData]: typeOfAccess.edit,
      [NOC.jurisdictionAnalyzer]: typeOfAccess.edit,
      [NOC.topics]: typeOfAccess.edit,
      [NOC.userManagement]: typeOfAccess.edit,
      [NOC.visualizationPrototypes]: typeOfAccess.view,
      [NOC.myAnnotationTasks]: typeOfAccess.edit,
      [NOC.customSettings]: typeOfAccess.edit
    }
  },
  [namesOfRoles.dataAdmin]: {
    value: namesOfRoles.dataAdmin,
    label: 'Data Admin',
    permissions: {
      [NOC.adminSettings]: typeOfAccess.view,
      [NOC.devOps]: typeOfAccess.edit,
      [NOC.documentQA]: typeOfAccess.edit,
      [NOC.documentData]: typeOfAccess.edit,
      [NOC.jurisdictionAnalyzer]: typeOfAccess.edit,
      [NOC.topics]: typeOfAccess.edit,
      [NOC.userManagement]: typeOfAccess.view,
      [NOC.visualizationPrototypes]: typeOfAccess.view,
      [NOC.myAnnotationTasks]: typeOfAccess.edit,
      [NOC.customSettings]: typeOfAccess.edit
    }
  },
  [namesOfRoles.userAdmin]: {
    value: namesOfRoles.userAdmin,
    label: 'User Admin',
    permissions: {
      [NOC.adminSettings]: typeOfAccess.view,
      [NOC.devOps]: typeOfAccess.view,
      [NOC.documentQA]: typeOfAccess.edit,
      [NOC.documentData]: typeOfAccess.view,
      [NOC.jurisdictionAnalyzer]: typeOfAccess.edit,
      [NOC.topics]: typeOfAccess.view,
      [NOC.userManagement]: typeOfAccess.edit,
      [NOC.visualizationPrototypes]: typeOfAccess.view,
      [NOC.myAnnotationTasks]: typeOfAccess.edit,
      [NOC.customSettings]: typeOfAccess.edit
    }
  },
  [namesOfRoles.basicAdmin]: {
    value: namesOfRoles.basicAdmin,
    label: 'Basic Admin',
    permissions: {
      [NOC.adminSettings]: typeOfAccess.view,
      [NOC.devOps]: typeOfAccess.view,
      [NOC.documentQA]: typeOfAccess.view,
      [NOC.documentData]: typeOfAccess.view,
      [NOC.jurisdictionAnalyzer]: typeOfAccess.edit,
      [NOC.topics]: typeOfAccess.view,
      [NOC.userManagement]: typeOfAccess.view,
      [NOC.visualizationPrototypes]: typeOfAccess.view,
      [NOC.myAnnotationTasks]: typeOfAccess.edit,
      [NOC.customSettings]: typeOfAccess.edit
    }
  },
  [namesOfRoles.dataAssociateAdmin]: {
    value: namesOfRoles.dataAssociateAdmin,
    label: 'Data Associate Admin',
    permissions: {
      [NOC.adminSettings]: typeOfAccess.none,
      [NOC.devOps]: typeOfAccess.none,
      [NOC.documentQA]: typeOfAccess.edit,
      [NOC.documentData]: typeOfAccess.none,
      [NOC.jurisdictionAnalyzer]: typeOfAccess.edit,
      [NOC.topics]: typeOfAccess.none,
      [NOC.userManagement]: typeOfAccess.none,
      [NOC.visualizationPrototypes]: typeOfAccess.none,
      [NOC.myAnnotationTasks]: typeOfAccess.edit,
      [NOC.customSettings]: typeOfAccess.edit
    }
  }
};

export { typeOfAccess, namesOfRoles };
