import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withHistory } from 'admin/utils/hooks';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import PropTypes from "prop-types";
import CategoryIcon from '@material-ui/icons/Category';
import PopoverInfo from '../../components/CrawlCreationPopoverInfo';
import { clearErrors } from 'shared/features/error/actions';
import { popoverName } from "../../constants/popoverConst";
import {
  getCrawlerRawSpec,
  postCrawlRawSpec,
  clearCrawSpec
} from 'shared/features/crawlerSettings/crawlerDocuments/crawlerDocuments.actions';
import Button from '../../components/Button';
import {
  stepTemplate,
  firstStepTemplate,
  exampleTemplate
} from './crawlCreation.constants';
import collectCrawlInformation, { getCrawlSettings, getCrawlStepSettings } from './CrawlCreaction.helpers';
import CrawlCreationStep from './CrawlCreationStep';
import CrawlCreationMainSettings from './CrawlCreationMainSettings';

function CrawlerCreationTool({
  reduxPostCrawlRawSpec,
  reduxGetCrawlerRawSpec,
  reduxClearCrawSpec,
  crawlerDocuments,
  match: { params },
  isSaveProhibit
}) {
  const [crawlSettings, setCrawlSettings] = useState(getCrawlSettings());
  const [steps, setStepInfo] = useState([firstStepTemplate]);
  const [examples, setExample] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    reduxClearCrawSpec();
    const crawlID = params.id;
    if (crawlID) {
      reduxGetCrawlerRawSpec(crawlID);
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    const rawCrawlSpec = crawlerDocuments.spec;
    if (_.get(rawCrawlSpec, 'spider_name')) {
      setCrawlSettings(getCrawlSettings(rawCrawlSpec));
      const crawlStepSettings = getCrawlStepSettings(_.get(rawCrawlSpec, ['configurations', 'steps'], {}));
      setStepInfo(crawlStepSettings.steps);
      setExample(crawlStepSettings.examples);
    } else {
      setCrawlSettings(getCrawlSettings());
      setStepInfo([firstStepTemplate]);
      setExample([]);
    }
  }, [crawlerDocuments.spec]);

  const createNewCrawl = () => {
    const crawlSpecification = collectCrawlInformation(crawlSettings, steps, examples);
    if (!crawlSpecification) {
      setError(true);
    } else {
      reduxPostCrawlRawSpec(crawlSpecification);
    }
  };

  // Main settings function
  const handleChangeMainCrawlSettings = (e) => {
    setCrawlSettings({ ...crawlSettings, [e.target.name]: e.target.value });
  };

  // Step functions
  const addNewStep = () => {
    setStepInfo([...steps, { ...stepTemplate, id: uuidv4() }]);
  };

  const deleteStep = (id) => {
    setStepInfo(steps.filter(step => step.id !== id));
  };

  const updateStep = (id, updatedStep) => {
    setStepInfo(steps.map(step => (step.id === id ? updatedStep : step)));
  };

  // Examples functions
  const addExample = (id) => {
    setExample([...examples, { ...exampleTemplate, id }]);
  };

  const deleteExample = (id) => {
    setExample(examples.filter(example => example.id !== id));
  };

  const updateExample = (id, updatedExample) => {
    setExample(examples.map(example => (example.id === id ? updatedExample : example)));
  };

  return (
    <div className="thinBtn AdminToolPageTemplate">
      <Button
        color="highlighted"
        className="rightBtn"
        onClick={createNewCrawl}
        disabled={isSaveProhibit}
      >
        Update Spec
      </Button>
      <h1>Crawl creation tool</h1>
      <hr />
      <h3>Main settings</h3>
      <div className="adminToolColumnsWrapper">
        <CrawlCreationMainSettings
          handleChange={handleChangeMainCrawlSettings}
          currentState={crawlSettings}
          error={error}
          disabled={isSaveProhibit}
        />
      </div>
      <hr />
      <h3>Crawl configurations</h3>
      <h4>
        Steps
        <PopoverInfo
          popoverConst={popoverName.stepsInfo}
        />
      </h4>
      {steps.map(step => (
        <CrawlCreationStep
          key={step.id}
          error={error}
          disabled={isSaveProhibit}
          // Steps
          step={step}
          deleteStep={deleteStep}
          nextSteps={steps.map(item => item.name)}
          updateStep={updateStep}
          // Examples
          examples={examples}
          addExample={addExample}
          deleteExample={deleteExample}
          updateExample={updateExample}
        />
      ))}
      <Button
        bsStyle="primary"
        className="wideButton"
        onClick={addNewStep}
        disabled={isSaveProhibit}
      >
        <CategoryIcon className="buttonIcon" />
        + Add New Step
      </Button>
    </div>
  );
}

CrawlerCreationTool.propTypes = {
  reduxPostCrawlRawSpec: PropTypes.func.isRequired,
  reduxGetCrawlerRawSpec: PropTypes.func.isRequired,
  reduxClearCrawSpec: PropTypes.func.isRequired,
  crawlerDocuments: PropTypes.shape({
    spec: {}
  }),
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  isSaveProhibit: PropTypes.bool
};

CrawlerCreationTool.defaultProps = {
  crawlerDocuments: {},
  params: { id: '' },
  isSaveProhibit: false
};

const mapStateToProps = state => {
  return {
    current_user: state.current_user,
    crawlerDocuments: state.crawlerDocuments
  };
};

const ReduxCrawlerCreationTool = compose(
  withHistory,
  connect(mapStateToProps, {
    clearErrors,
    reduxGetCrawlerRawSpec: getCrawlerRawSpec,
    reduxPostCrawlRawSpec: postCrawlRawSpec,
    reduxClearCrawSpec: clearCrawSpec
  })
)(CrawlerCreationTool);

export default ReduxCrawlerCreationTool;
