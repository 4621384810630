import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import "./CrawlSpecCreate.styles.scss";
import {
  getCrawlerRawSpec,
  postCrawlRawSpec,
  clearCrawSpec
} from 'shared/features/crawlerSettings/crawlerDocuments/crawlerDocuments.actions';
import { checkGeneral } from './CrawlSpecCreate.helper.error';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import CrawlSpecCreateMain from './CrawlSpecCreateMain';
import CrawlSpecCreateExtra from './CrawlSpecCreateExtra';
import CrawlSpecCreateConfig from './CrawlSpecCreateConfig';
import { FIRST_STEP, TEMPLATE_CONFIGURATION } from "./CrawlSpecCreate.constants";
import CrawlCollectorSpec from "./CrawlCollectorSpec.module";
import CrawlParserSpec from "./CrawlParserSpec.module";

function CrawlSpecCreate({
  editMode,
  modalAction,
  crawlConfig,
  setCrawlConfig
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const crawlSpec = _.get(useSelector(state => state.crawlerDocuments), 'spec', {});
  const crawlSpecOptions = _.get(useSelector(state => state.crawlerDocuments), 'specOptions', {});

  const [errorFields, setErrorFields] = useState({});
  const [specMain, setSpecMain] = useState({});
  const [categoryConversion, setCategoryConversion] = useState(null);
  const [config, setConfig] = useState(TEMPLATE_CONFIGURATION);
  const [steps, setSteps] = useState([FIRST_STEP]);
  const [contexts, setContexts] = useState([]);
  const [docs, setDocs] = useState([]);
  const [actions, setActions] = useState([]);
  const [relations, setRelations] = useState([]);
  const [resetCounters, setResetCounters] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [extractTags, setExtractTags] = useState([]);
  const [paths, setPaths] = useState([]);
  const [examples, setExample] = useState([]);

  useEffect(() => {
    dispatch(clearCrawSpec());
    if (id) dispatch(getCrawlerRawSpec(id));
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const calculateSpecInfo = () => {
    // check that crawlSpec exist and have api_table
    if (!_.get(crawlSpec, 'api_table')) return;
    const parseSpec = new CrawlParserSpec(crawlSpec);
    parseSpec.parseCrawl();
    const { general, configuration } = parseSpec.parseSpec;
    setSpecMain(general);
    setConfig(configuration.config);
    setCategoryConversion(configuration.categoryConversion);
    setSteps(configuration.steps);
    setContexts(configuration.contexts);
    setDocs(configuration.docs);
    setActions(configuration.actions);
    setRelations(configuration.relations);
    setResetCounters(configuration.resetCounters);
    setMetaData(configuration.metaData);
    setLocations(configuration.locations);
    setExtractTags(configuration.extractTags);
    setPaths(configuration.paths);
    setExample(configuration.examples);
  };

  useEffect(calculateSpecInfo, [crawlSpec]);

  const saveSpec = () => {
    const CrawlCollector = new CrawlCollectorSpec({
      specMain,
      config,
      categoryConversion,
      steps,
      contexts,
      docs,
      actions,
      relations,
      resetCounters,
      metaData,
      locations,
      extractTags,
      paths,
      examples
    });
    const newSpec = CrawlCollector.collectSpecInfo();
    if (CrawlCollector.error) {
      setErrorFields(CrawlCollector.errorCollection);
      CrawlCollector.showErrors();
    } else {
      dispatch(postCrawlRawSpec({ ...newSpec, crawl_id: id }));
      CrawlCollector.showSuccess();
      setErrorFields({});
    }
  };

  const handleChanges = (name, value) => {
    setErrorFields(checkGeneral({
      errorFields,
      fieldName: name
    }));
    setSpecMain({
      ...specMain,
      [name]: value
    });
  };

  const handleChangesEvent = (e) => {
    const { name = null, value = null } = e.target;
    handleChanges(name, value);
  };

  return (
    <div className="crawl-spec">
      <div className="crawl-spec_header">
        <Typography variant="h1">Crawl creation tool</Typography>
        <Button
          color="highlighted"
          size="small"
          onClick={saveSpec}
        >
          Update Spec
        </Button>
      </div>
      <hr />
      <CrawlSpecCreateMain
        editMode={editMode}
        specInfo={specMain}
        errorFields={_.get(errorFields, 'main', [])}
        handleChanges={handleChanges}
      />
      <hr />
      <CrawlSpecCreateExtra
        editMode={editMode}
        errorFields={_.get(errorFields, 'main', [])}
        handleChangesEvent={handleChangesEvent}
        handleChanges={handleChanges}
        modalAction={modalAction}
        setCrawlConfig={setCrawlConfig}
        crawlConfig={crawlConfig}
      />
      <hr />
      <CrawlSpecCreateConfig
        editMode={editMode}
        errorFields={errorFields}
        setErrorFields={setErrorFields}
        // elements
        // configuration
        config={config}
        setConfig={setConfig}
        // category conversion
        categoryConversion={categoryConversion}
        setCategoryConversion={setCategoryConversion}
        // // steps
        steps={steps}
        setSteps={setSteps}
        // // context
        contexts={contexts}
        setContexts={setContexts}
        // // docs
        docs={docs}
        setDocs={setDocs}
        // // actions
        actions={actions}
        setActions={setActions}
        // // relations
        relations={relations}
        setRelations={setRelations}
        // // reset counter
        resetCounters={resetCounters}
        setResetCounters={setResetCounters}
        // // meta data
        metaData={metaData}
        setMetaData={setMetaData}
        // // extract tags
        extractTags={extractTags}
        setExtractTags={setExtractTags}
        // // location
        locations={locations}
        setLocations={setLocations}
        // // paths
        paths={paths}
        setPaths={setPaths}
        // // examples
        examples={examples}
        setExample={setExample}
        // // spec options
        crawlSpecOptions={crawlSpecOptions}
      />
    </div>
  );
}

CrawlSpecCreate.propTypes = {
  editMode: PropTypes.bool,
  modalAction: PropTypes.func,
  setCrawlConfig: PropTypes.func,
  crawlConfig: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
};

CrawlSpecCreate.defaultProps = {
  editMode: false,
  modalAction: () => {},
  setCrawlConfig: () => {},
  crawlConfig: ""
};

export default CrawlSpecCreate;
