import { createSlice } from "@reduxjs/toolkit";
import {
  startLoad,
  finishLoad,
  createDocumentRelation,
  updateDocumentRelation
} from './documentRelations.actions';

export const reducerName = 'documentRelations';
export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

export const { reducer } = createSlice({
  name: reducerName,
  initialState,
  extraReducers: {
    [startLoad.type]: (state) => {
      state.load = true
    },
    [finishLoad.type]: (state) => {
      state.timestamp = Date().toString()
      state.load = false
    },
    [createDocumentRelation.type]: (state, action) => {
      state.count = state.count+1
      state.items = [...state.items, action.payload]
    },
    [updateDocumentRelation.type]: (state, action) => {
      state.items = state.items.map(item => item.id === action.payload.id ? action.payload : item)
    }
  }
});
