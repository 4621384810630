import type { MetaCategoryFromResponse } from 'shared/features/categories/metaCategories/metaCategories.api.types';

import * as errorUtils from 'shared/utils/errors';
import * as metaCategoriesApi from 'shared/features/categories/metaCategories/metaCategories.api';
import { CategoryEditModalHandlers } from './useCategoryEditModalHandlers.types';

export const useCategoryEditModalHandlers = (): CategoryEditModalHandlers => {
  const handleMetaCategoryFetch: CategoryEditModalHandlers['handleMetaCategoryFetch'] = async (
    inputString: string
  ) => {
    try {
      const response = await metaCategoriesApi.api_getMetaCategories({ name: inputString });

      return response.items.map((metaCategory: MetaCategoryFromResponse) => ({
        label: metaCategory.name,
        value: metaCategory.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    handleMetaCategoryFetch
  };
};
