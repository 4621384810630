import _ from 'lodash';

export const formatAgenciesActivitiesGlanceDocs = (agenciesActivitiesGlanceDocs) => {
  const documentObject = {};
  _.forEach(agenciesActivitiesGlanceDocs, entry => {
    documentObject[entry.key] = {
      docs: entry.top_10_documents.hits.hits.map(hit => {
        return hit._source;
      }),
      doc_count: entry.doc_count,
      categories: entry.by_category.buckets
    };
  });

  return documentObject;
};
