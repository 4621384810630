import type { SpiderRunReportFromResponse } from 'shared/features/spiderRunReports/spiderRunReports.api.types';
import type { ProcessedDocsCellData } from './useProcessedDocsCellData.types';
import type { ProcessedDocsCellProps } from '../ProcessedDocsCell.types';

import { TABLE_VARIABLES } from '../../../CrawlActivityTable.constants';

import * as lodash from 'lodash';

import { useMemo } from 'react';

export const useProcessedDocsCellData = ({
  row
}: Pick<ProcessedDocsCellProps, 'row'>): ProcessedDocsCellData => {
  const formattedData = useMemo(() => {
    const report = row as SpiderRunReportFromResponse;

    return {
      processedDocsCount:
        lodash.get(report, TABLE_VARIABLES.docs_ingested, 0) +
        lodash.get(report, TABLE_VARIABLES.docs_skipped, 0) +
        lodash.get(report, TABLE_VARIABLES.docs_failed, 0) +
        lodash.get(report, TABLE_VARIABLES.docs_updated, 0)
    };
  }, [row]);

  return {
    formattedData
  };
};
