import React from 'react';
import { Table, Form, FormGroup, Col, ControlLabel, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchModelValidationTable } from 'shared/features/topics/topics.actions';


class ModelValidationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topic_id: '',
      topicIdToDisplay: 1 //1 is lending,

    };
  }

  componentDidMount() {
    this.props.fetchModelValidationTable(this.state.topicIdToDisplay);
  }

  filterByStatus = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    this.setState(new_state, function () {
      if (this.state.topic_id !== 'all') {
        this.props.fetchModelValidationTable(this.state.topic_id);
      } else {
        this.props.fetchModelValidationTable({});
      }
    });
  };

  render() {
    if (
      !this.props.topic_annotation_model_validations
      || !this.props.topic_annotation_model_validations.isReady
    ) {
      return null;
    }

    const list_items = [];
    const topics = this.props.topic_annotation_model_validations
      .items.topic_annotation_model_validations;
    topics.forEach(entity => {
      list_items.push(
        <tr>
          <th className="table-first-col-color-bg">
            {entity.sprint}
          </th>
          <td>
            {entity.precision.toFixed(3)}
          </td>
          <td>
            {entity.recall.toFixed(3)}
          </td>
          <td>
            {entity.accuracy.toFixed(3)}
          </td>
          <td>
            {entity.f1_score.toFixed(3)}
          </td>
        </tr>
      );
    });

    return (
      <div className="aggregatedAnnotations">
        <h1>Model Validation Table</h1>
        <Form horizontal>
          <FormGroup bsSize="small">
            <Col sm={3}>
              <ControlLabel className="validation-table-filter">Topic filter:</ControlLabel>
              <FormControl
                componentClass="select"
                defaultValue={this.state.topic_id}
                onChange={e => this.filterByStatus('topic_id', e)}
                className="validation-topics"
              >
                <option value="1">Lending</option>
                <option value="2">BSA/AML</option>
                <option value="3">Mortgage Lending</option>
                <option value="4">Crowdfunding</option>
                <option value="5">FCPA/ABAC</option>
                <option value="6">Credit</option>
                <option value="7">Deposits</option>
                <option value="9">Insurance</option>
                <option value="10">Privacy</option>
                <option value="11">Securities</option>
                <option value="12">Trusts</option>
                <option value="13">Payments</option>
                <option value="14">Cybersecurity</option>
                <option value="15">Leasing</option>
                <option value="16">Debt Collection</option>
                <option value="17">Commercial Lending</option>
                <option value="18">Consumer Lending</option>
                <option value="19">Payday Lending</option>
              </FormControl>
            </Col>
          </FormGroup>
        </Form>
        <Table className="model-validation-table">
          <thead>
            <tr>
              <th>Sprint</th>
              <th>Precision</th>
              <th>Recall</th>
              <th>Accuracy</th>
              <th>F-Score</th>
            </tr>
          </thead>
          <tbody>
            {list_items}
          </tbody>
        </Table>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    topic_annotation_model_validations: state.topic_annotation_model_validations,
    ...state.sources
  };
};

const ReduxAggregatedAnnotations = connect(mapStateToProps, { fetchModelValidationTable })(
  ModelValidationTable
);

export default ReduxAggregatedAnnotations;
