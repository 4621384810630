import type { GlobalState } from 'shared/reducers';
import type { CrawlerCatalogData } from './useCrawlerCatalogData.types';

import * as crawlerActivitySelectors from 'shared/features/crawlerSettings/crawlerActivity/crawlerActivity.selectors';
import * as crawlerDocumentsSelectors from 'shared/features/crawlerSettings/crawlerDocuments/crawlerDocuments.selectors';
import * as userSelectors from 'shared/features/user/user.selectors';
import * as commandsSelectors from 'shared/features/commands/commands.selector';
import * as externalApiCrawlSelectors from 'shared/features/externalApiCrawl/externalApiCrawl.selectors';
import * as constants from '../CrawlerCatalog.constants';

import { crawlStates } from 'admin/constants/crawlersTypes';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCrawlerCatalogData = (): CrawlerCatalogData => {
  const reduxState = useSelector<GlobalState, CrawlerCatalogData['reduxState']>(state => ({
    crawlerActivity: crawlerActivitySelectors.getCrawlerActivityReducer(state),
    crawlerDocuments: crawlerDocumentsSelectors.getCrawlerDocumentsReducer(state),
    currentUser: userSelectors.getCurrentUser(state),
    commands: commandsSelectors.getCommands(state),
    commandRequests: commandsSelectors.getCommandRequest(state),
    crawlDetails: externalApiCrawlSelectors.getFirstExternalApiCrawlElement(state),
    timestamp: externalApiCrawlSelectors.getExternalApiCrawlTimestamp(state)
  }));

  const [shouldShowViewModal, setShouldShowViewModal] =
    useState<CrawlerCatalogData['localState']['shouldShowViewModal']>(false);
  const [shouldShowDryRunModal, setShouldShowDryRunModal] =
    useState<CrawlerCatalogData['localState']['shouldShowDryRunModal']>(false);
  const [extraContentModalData, setExtraContentModalData] =
    useState<CrawlerCatalogData['localState']['extraContentModalData']>(null);
  const [shouldShowModal, setShouldShowModal] =
    useState<CrawlerCatalogData['localState']['shouldShowModal']>(false);
  const [mode, setMode] = useState<CrawlerCatalogData['localState']['mode']>(
    constants.MODE_CONFIRMATION_MODAL.EXPORT
  );
  const [crawlConfig, setCrawlConfig] = useState<CrawlerCatalogData['localState']['crawlConfig']>(
    JSON.stringify(reduxState.crawlerDocuments.config, null, 2)
  );

  const localState: CrawlerCatalogData['localState'] = {
    shouldShowViewModal,
    shouldShowDryRunModal,
    extraContentModalData,
    shouldShowModal,
    mode,
    crawlConfig
  };

  const localActions: CrawlerCatalogData['localActions'] = useMemo(
    () => ({
      setShouldShowViewModal,
      setShouldShowDryRunModal,
      setExtraContentModalData,
      setShouldShowModal,
      setMode,
      setCrawlConfig
    }),
    []
  );

  const formattedData: CrawlerCatalogData['formattedData'] = useMemo(
    () => ({
      initialCrawlConfig: JSON.stringify(reduxState.crawlerDocuments.config, null, 2),
      isEditDisabled: reduxState.crawlDetails.status !== crawlStates.construction
    }),
    [reduxState.crawlDetails, reduxState.crawlerDocuments.config]
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
