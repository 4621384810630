const popoverName = {
  // Crawl creation info
  stepsInfo: "stepsInfo",
  contextInfo: 'contextInfo',
  docInfo: 'docInfo',
  isUrlInfo: 'isUrlInfo',
  exampleUrlInfo: 'exampleUrlInfo',
  exampleTxtInfo: 'exampleTxtInfo',
  exampleOccurrenceInfo: 'exampleOccurrenceInfo'
};

const popoverSettings = [
  // Crawl creation info
  {
    name: popoverName.stepsInfo,
    title: 'Steps info',
    text: 'List of steps. Each step may contain context and document metadata.'
  },
  {
    name: popoverName.contextInfo,
    title: 'Context info',
    text: 'Defines data that will be pushed to the next step. If context is present then the next step must be defined.'
  },
  {
    name: popoverName.docInfo,
    title: 'Doc info',
    text: 'Doc contains document metadata that will be stored in the database.'
  },
  {
    name: popoverName.isUrlInfo,
    title: '',
    text: 'Defines whether the context is URL or not.'
  },
  {
    name: popoverName.exampleUrlInfo,
    title: '',
    text: 'Url of the web page that contains the example.'
  },
  {
    name: popoverName.exampleTxtInfo,
    title: '',
    text: 'Text of the example.'
  },
  {
    name: popoverName.exampleOccurrenceInfo,
    title: '',
    text: 'Defines ordinal number of the example occurrence if the example is present several times on the source URL.'
  }
];

export { popoverName, popoverSettings };
