import {fetch, AUTHENTICATION_OPTIONS_KEYS} from "fetch";

export function api_fetchAutoComplete(partialValue, params) {
  return fetch({
    url: `/autosuggest/${encodeURIComponent(partialValue)}`,
    method: 'GET',
    dataType: 'AutoComplete',
    params
  });
}

export function api_fetchIframeAutoComplete(params, useAPIKey) {
  return fetch({
    url: `/suggestion`,
    method: 'GET',
    authenticationType: useAPIKey ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : AUTHENTICATION_OPTIONS_KEYS.REDUX_STATE,
    params,
    dataType: 'AutoComplete'
  });
}

export function api_fetchSearchQueries(params) {
  return fetch({
    url: `/search_queries`,
    params,
    method: 'GET',
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY,
    dataType: 'SearchQueries'
  });
}
