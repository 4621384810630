import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { withHistory } from 'admin/utils/hooks';
import queryString from 'query-string';
import MModal from "admin/components/MaterialComponents/Modal/MModal";
import Loader from "admin/components/MaterialComponents/Loaders/Loader";
import { colors } from 'admin/components/MaterialComponents/colors/colors';
import { getUserByIdAC } from "shared/features/userModal/userModal.actions";
import MButton from "admin/components/MaterialComponents/Buttons/Button/mButton";
import {
  changeGeneralTableValues,
  setGeneralTableOptions
} from "shared/features/userModal/userModal.reducer";
import UserModalHeader from "./UserModalHeader";
import UserModalMainInfo from "./UserModalMainInfo";
import UserDeleteModal from "../../UserDeleteModal/UserDeleteModal";

const useStyles = makeStyles({
  defaultBlueButton: {
    float: 'right',
    color: colors.BLUE_MAIN,
    marginRight: 10,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: colors.BLUE_TRANSPARENT,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.BACKGROUND_BLUE
    }
  },
  loader: {
    marginTop: '25%',
    height: 200,
    backgroundColor: colors.WHITE
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    height: '80%',
    width: '70%',
    overflowX: 'auto'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  row: {
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowButton: {
    paddingBottom: 20,
    display: 'flex'
  },
  blockWrapper: {
    display: 'flex',
    margin: '10px 20px 10px 20px',
    borderBottom: `1px solid ${colors.GREY_LIGHT}`
  },
  blockLabel: {
    fontSize: 16,
    minWidth: 250,
    fontWeight: 'bold'
  },
  fields: {
    display: 'flex',
    width: '100%',
    '&:not(:last-child)': {
      marginRight: 10
    }
  },
  deleteButtonWrapper: {
    float: 'right',
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: 'center'
  },
  blockRows: {
    width: '100%'
  }
});

const UserInfoModal = ({
  all_industries,
  all_organizations,
  all_payment_types,
  all_plans,
  all_premium_content_sources,
  all_roles,
  all_subscriptions,
  all_teams,
  close,
  location,
  reduxGetUserByIdAC,
  reduxSetGeneralTableOptions,
  showModal,
  user
}) => {
  const classes = useStyles();
  const parsedQuery = queryString.parse(location.search);

  const [loading, setLoadingStatus] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModalStatus] = useState(false);

  const options = {
    all_industries,
    all_organizations,
    all_premium_content_sources,
    all_plans,
    all_roles,
    all_subscriptions,
    all_teams,
    all_payment_types
  };
  useEffect(() => {
    reduxSetGeneralTableOptions(options);
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    all_industries,
    all_organizations,
    all_premium_content_sources,
    all_plans,
    all_roles,
    all_subscriptions,
    all_teams,
    all_payment_types
  ]);

  useEffect(() => {
    if (parsedQuery.user_id) {
      setLoadingStatus(true);
      reduxGetUserByIdAC(parsedQuery.user_id)
        .then(
          () => setLoadingStatus(false)
        );
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUser = () => {
    setShowDeleteModalStatus(true);
  };

  const mountUserDeleteModal = () => {
    if (showDeleteModal) {
      return (
        <UserDeleteModal
          email={user.email}
          close={(isDeleted) => {
            setShowDeleteModalStatus(false);
            if (isDeleted) {
              close();
            }
          }}
          showModal={showDeleteModal}
        />
      );
    }
    return null;
  };

  return (
    <MModal
      showModal={showModal}
      closeModal={close}
      classNameModal={classes.modal}
      classNamePaper={classes.paper}
    >
      {
          loading
            ? <Loader classNameLoader={classes.loader} />
            : (
              <>
                <UserModalHeader setEditMode={setEditMode} editMode={editMode} />
                <UserModalMainInfo editMode={editMode} />
                <div className={classes.deleteButtonWrapper}>
                  <MButton
                    onClick={() => deleteUser()}
                    classes={classes.defaultBlueButton}
                  >
                    Delete user
                  </MButton>
                </div>
                {mountUserDeleteModal()}
              </>
            )
          }
    </MModal>
  );
};


UserInfoModal.propTypes = {
  all_industries: PropTypes.arrayOf(PropTypes.string).isRequired,
  all_organizations: PropTypes.arrayOf(PropTypes.string).isRequired,
  all_payment_types: PropTypes.arrayOf(PropTypes.string).isRequired,
  all_plans: PropTypes.arrayOf(PropTypes.object).isRequired,
  all_premium_content_sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  all_roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  all_subscriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  all_teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  close: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  reduxGetUserByIdAC: PropTypes.func.isRequired,
  reduxSetGeneralTableOptions: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  all_organizations: state.all_organizations.organizations,
  all_plans: state.all_plans.items,
  all_premium_content_sources: state.premiumContent.pcSources,
  all_subscriptions: state.all_subscriptions.subscriptions,
  all_teams: state.teams.all_teams,
  all_roles: state.all_roles.roles,
  all_payment_types: state.all_payment_types.payment_types,
  all_industries: state.all_industries.industries,
  generalTable: state.userModal.generalTable,
  userSubscriptions: state.subscriptions.subscriptions,
  user: state.userModal.initialUserData
});

export default compose(withHistory, connect(mapStateToProps,
  { reduxGetUserByIdAC: getUserByIdAC,
    reduxChangeGeneralTableValues: changeGeneralTableValues,
    reduxSetGeneralTableOptions: setGeneralTableOptions
  }))(UserInfoModal);
