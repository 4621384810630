import type { CommandRequestsData } from './useCommandRequestsData.types';
import type { GlobalState } from 'shared/reducers';
import type { CommandRequestsCreationModalData } from '../elements/CommandRequestsCreationModal/hooks/useCommandRequestsCreationModalData.types';

import * as uiLib from '@compliance.ai/web-components';

import { getUsersEmails } from 'shared/features/user/user.selectors';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCommandRequestsData = (): CommandRequestsData => {
  const [filters, setFilters] = useState<CommandRequestsData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<CommandRequestsData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: 50
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<CommandRequestsData['localState']['reloadTrigger']>(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] =
    useState<CommandRequestsData['localState']['isDetailsModalOpen']>(false);
  const [isCreationModalOpen, setIsCreationModalOpen] =
    useState<CommandRequestsData['localState']['isCreationModalOpen']>(false);
  const [detailsModalInfo, setDetailsModalInfo] =
    useState<CommandRequestsData['localState']['detailsModalInfo']>('');
  const [selectedCommandRequest, setSelectedCommandRequest] =
    useState<CommandRequestsCreationModalData['localState']['selectedCommandRequest']>(null);

  const reduxState = useSelector<GlobalState, CommandRequestsData['reduxState']>(state => ({
    usersEmails: getUsersEmails(state)
  }));

  const localState: CommandRequestsData['localState'] = {
    filters,
    tableParams,
    reloadTrigger,
    isDetailsModalOpen,
    isCreationModalOpen,
    detailsModalInfo,
    selectedCommandRequest
  };

  const localActions: CommandRequestsData['localActions'] = useMemo(
    () => ({
      setFilters,
      setTableParams,
      setReloadTrigger,
      setIsDetailsModalOpen,
      setIsCreationModalOpen,
      setDetailsModalInfo,
      setSelectedCommandRequest
    }),
    []
  );

  return {
    localState,
    localActions,
    reduxState
  };
};
