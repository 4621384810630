import React, { useState } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '../../../components/Typography';
import { colors } from '../../../components/MaterialComponents/colors/colors';

const CrawlCatalogListItem = ({ fieldValue, fieldName }) => {
  // item main settings (icon, main value)
  const targetIcon = fieldValue
    ? <CheckCircleIcon htmlColor={colors.GREEN_BUTTONS} />
    : <CancelIcon htmlColor={colors.RED_TOMATO} />;

  return (
    <li className="iconTextBlock">
      {targetIcon}
      <Typography variant="p">{fieldName}</Typography>
    </li>
  );
};

const CrawlCatalogInfoList = ({ listTitle, crawlInfo, requiredFields }) => {
  const [listVisible, setListVisibility] = useState(false);
  const requiredFiledComplete = requiredFields.every(item => Array.isArray(item)
    ? item.some(optionalItem => _.get(crawlInfo, optionalItem))
    : _.get(crawlInfo, item)
  );
  const statusIcon = requiredFiledComplete
    ? <CheckCircleIcon htmlColor={colors.GREEN_BUTTONS} />
    : <CancelIcon htmlColor={colors.RED_TOMATO} />;
  return (
    <div className="requiredFieldsList">
      <div
        className="requiredFieldsList_title"
        onClick={() => setListVisibility(!listVisible)}
      >
        <div className="iconTextBlock">
          {statusIcon}
          <Typography variant="label" color="gray">
            {listTitle}
          </Typography>
        </div>
        <Typography variant="h5" color="gray">
          {listVisible ? "+" : "—"}
        </Typography>
      </div>
      <ul>
        {listVisible && requiredFields.map(fieldName => (
          <CrawlCatalogListItem
            key={fieldName}
            fieldName={
              Array.isArray(fieldName)
                ? fieldName.join('/')
                : fieldName
            }
            fieldValue={
              Array.isArray(fieldName)
                ? fieldName.some(optionalItem => _.get(crawlInfo, optionalItem, ''))
                : _.get(crawlInfo, fieldName, '')
            }
          />))}
      </ul>
    </div>
  );
};

CrawlCatalogInfoList.propTypes = {
  listTitle: PropTypes.string,
  crawlInfo: PropTypes.shape({}).isRequired,
  requiredFields: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])).isRequired
};

CrawlCatalogInfoList.defaultProps = {
  listTitle: ''
};

export default CrawlCatalogInfoList;
