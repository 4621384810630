import type { FC } from 'react';
import type { AppProps } from './App.types';

import React from 'react';
import { AppHeader } from '../AppHeader';
import { AppLeftBar } from './elements';
import { TooltipMessage } from '../../components/Tooltip';
import { useAppData, useAppHandlers, useAppLifecycle } from './hooks';
import './_app.scss';

export const App: FC<AppProps> = ({ children }) => {
  const { reduxState } = useAppData();

  const handlers = useAppHandlers({
    reduxState
  });

  useAppLifecycle({
    onInitialDataFetch: handlers.handleInitialDataFetch,
    onMixpanelSetup: handlers.handleMixpanelSetup
  });

  if (!reduxState.currentUserId || reduxState.isFetchingCurrentUser) {
    return null;
  }

  return (
    <div className="app">
      <TooltipMessage classNameTooltipID="global-tooltip" />
      <AppHeader fullName={reduxState.currentUserFullname || reduxState.currentUserEmail} />
      <div className="app__content-container">
        <AppLeftBar />
        <div className="app__content">{children}</div>
      </div>
    </div>
  );
};
