import type { FC } from 'react';
import type { BulkReprocessModalRuleProps } from './BulkReprocessModalRule.types';

import * as uiLib from '@compliance.ai/web-components';

import { useBulkReprocessModalRuleData } from './hooks';

import './_bulk-reprocess-modal-rule.scss';

export const BulkReprocessModalRule: FC<BulkReprocessModalRuleProps> = ({
  action,
  onActionChange,
  availableBulkReprocessActions
}) => {
  const { formattedData } = useBulkReprocessModalRuleData({
    props: {
      action,
      availableBulkReprocessActions
    }
  });

  return (
    <div className="bulk-reprocess-modal-rule">
      <div className="bulk-reprocess-modal-rule__section">
        <uiLib.LabeledField label="Select action" className="bulk-reprocess-modal-rule__label">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            value={action as uiLib.SelectProps['value']}
            options={formattedData.bulkReprocessActionsOptions}
            onChange={onActionChange}
            isSearchable={false}
          />
        </uiLib.LabeledField>
      </div>
    </div>
  );
};
