import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';
import {
  api_getCategories,
  api_postCategories,
  api_putCategories,
  api_deleteCategories
} from './categories.api';
import {
  setCategory,
  createCategory,
  updateCategory,
  removeCategory,
  initialState
} from './categories.reducer';

const SUCCESS = {
  categoryCreated: 'Categories successfully created',
  categoryUpdated: 'Categories successfully updated',
  categoryDeleted: 'Categories successfully deleted'
};

export const getCategories = params => dispatch =>
  api_getCategories(params).then(res => dispatch(setCategory(res)));

export const createCategories = params => dispatch =>
  api_postCategories(params).then(res => {
    setNotification({
      message: { text: SUCCESS.categoryCreated, title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(createCategory(res));
  });

export const updateCategories = params => dispatch =>
  api_putCategories(params).then(res => {
    setNotification({
      message: { text: SUCCESS.categoryUpdated, title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(updateCategory(res));
  });

export const removeCategories = params => dispatch =>
  api_deleteCategories(params).then(res => {
    setNotification({
      message: { text: SUCCESS.categoryDeleted, title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(removeCategory(params));
  });

export const clearCategories = () => dispatch => dispatch(setCategory(initialState));
