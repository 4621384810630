// ----- TABLE CONSTANTS -----

export const CONCEPT_ATTRIBUTES = {
  id: 'id',
  name: 'name',
  description: 'description',
  advancedSearch: 'advanced_search',
  metaConcept: 'meta_concept',
  multiField: 'multi_input',
  isActive: 'is_active',
  createdAt: 'created_at',
  updatedAt: 'updated_at'
};

// Errors

export const ERRORS = {
  commonError: 'This value is required'
};

// concept details used by ConceptDetails component (just help to show structure)
export const CONCEPT_DETAILS = {
  // general info
  [CONCEPT_ATTRIBUTES.name]: '', // string
  [CONCEPT_ATTRIBUTES.id]: 'new', // number | new
  [CONCEPT_ATTRIBUTES.advancedSearch]: '', // string
  [CONCEPT_ATTRIBUTES.description]: '', // string
  [CONCEPT_ATTRIBUTES.metaConcept]: null, // { id, name, description, updated_at }
  [CONCEPT_ATTRIBUTES.isActive]: false, // boolean
  [CONCEPT_ATTRIBUTES.createdAt]: '', // string
  [CONCEPT_ATTRIBUTES.updatedAt]: '' // string
};

export const CONCEPT_ACTIVE_TYPES = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};

export const DELETE_CONFIRMATION_MODAL = {
  title: 'Delete concept',
  body: 'Are you sure you want to delete this concept?'
};
