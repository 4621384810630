import type { CrawlActivityTableHandlers } from './useCrawlActivityTableHandlers.types';
import type { ComponentProps } from 'react';
import type { SpiderRunReportFromResponse } from 'shared/features/spiderRunReports/spiderRunReports.api.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../CrawlActivityTable.constants';
import * as dateConstants from 'admin/constants/date';

export const useCrawlActivityTableDataSelector = () => {
  return (
    data: Awaited<ReturnType<CrawlActivityTableHandlers['handleReportsFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      results: data.results.map(row => {
        const report = row as SpiderRunReportFromResponse;

        return {
          ...report,

          isExpandable: (report[constants.TABLE_VARIABLES.child_reports_count] ?? 0) > 0,

          [constants.TABLE_VARIABLES.start_time]: {
            value: report[constants.TABLE_VARIABLES.start_time],
            passedTimezone: uiLib.TIMEZONES.UTC,
            passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
            timezone: uiLib.getLocalTimezone(),
            format: uiLib.DATE_FORMATS.API_DATE_TIME
          } as uiLib.DateCellValue,

          [constants.TABLE_VARIABLES.end_time]: {
            value: report[constants.TABLE_VARIABLES.end_time],
            passedTimezone: uiLib.TIMEZONES.UTC,
            passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
            timezone: uiLib.getLocalTimezone(),
            format: uiLib.DATE_FORMATS.API_DATE_TIME
          } as uiLib.DateCellValue,

          [constants.TABLE_VARIABLES.status]: report[constants.TABLE_VARIABLES.status],

          [constants.TABLE_VARIABLES.docs_error]: report[constants.TABLE_VARIABLES.docs_error],

          [constants.TABLE_VARIABLES.run_type]: report[constants.TABLE_VARIABLES.run_type],

          [constants.TABLE_VARIABLES.docs_ingested]:
            report[constants.TABLE_VARIABLES.docs_ingested],

          [constants.TABLE_VARIABLES.docs_updated]: report[constants.TABLE_VARIABLES.docs_updated],

          [constants.TABLE_VARIABLES.docs_skipped]: report[constants.TABLE_VARIABLES.docs_skipped],

          [constants.TABLE_VARIABLES.docs_failed]: report[constants.TABLE_VARIABLES.docs_failed],

          [constants.TABLE_VARIABLES.docs_processed]:
            report[constants.TABLE_VARIABLES.docs_processed],

          [constants.TABLE_VARIABLES.docs_ingested_sla]:
            report[constants.TABLE_VARIABLES.docs_ingested_sla],

          [constants.TABLE_VARIABLES.docs_ingested_outside_sla]:
            report[constants.TABLE_VARIABLES.docs_ingested_outside_sla],

          [constants.TABLE_VARIABLES.id]: report[constants.TABLE_VARIABLES.id]
        };
      }),
      count: data.count
    };
  };
};
