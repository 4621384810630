import type { EitlLabelsImportModalProps } from '../EitlLabelsImportModal.types';
import type { EitlLabelsImportModalData } from './useEitlLabelsImportModalData.types';

import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { useMutation } from '@tanstack/react-query';
import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';

export const useEitlLabelsImportModalMutations = ({
  props,
  localState
}: {
  props: Pick<EitlLabelsImportModalProps, 'onConfirm'>;
  localState: EitlLabelsImportModalData['localState'];
}) => {
  const eitlLabelsApi = useEitlLabelsApi();

  const importEitlLabels = useMutation<Awaited<void>, Error, void>({
    mutationKey: queryKeysConstants.COMPLEX_QUERY_KEY_GETTERS[
      queryKeysConstants.QUERY_KEYS.EITL_LABELS
    ][queryKeysConstants.QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_IMPORT]({
      file: localState.attachments[0]?.file ?? {}
    }),
    mutationFn: async () => {
      await eitlLabelsApi.bulkImportEitlLabels(localState.attachments[0].file);
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      props.onConfirm();

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        message: 'EITL Labels successfully imported'
      });
    }
  });

  return {
    importEitlLabels
  };
};
