import { fetch, BASE_API_OPTIONS } from 'fetch';

export function api_fetchAllCommands() {
  return fetch({
    url: `/commands/`,
    method: 'GET',
    dataType: 'commands',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_createNewCommand(params) {
  return fetch({
    url: `/commands/`,
    method: 'POST',
    params,
    dataType: 'commands',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_updateCurrentCommand(commandID, params) {
  return fetch({
    url: `/commands/${commandID}`,
    method: 'PATCH',
    params,
    dataType: 'commands',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_createAutoQaCommandRequest(params) {
  return fetch({
    url: `/crawl_auto_qa_request`,
    method: 'POST',
    params,
    dataType: 'command_requests',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_fetchAllCommandRequests(params) {
  return fetch({
    url: `/command_requests`,
    method: 'GET',
    dataType: 'command_requests',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_createCommandRequest(params) {
  return fetch({
    url: `/command_requests`,
    method: 'POST',
    params,
    dataType: 'command_requests',
    headers: {
      enctype: 'multipart/form-data'
    },
    formData: true,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_updateCommandRequest(id, params) {
  return fetch({
    url: `/command_requests/${id}`,
    method: 'PATCH',
    params,
    dataType: 'command_requests',
    headers: {
      enctype: 'multipart/form-data'
    },
    formData: true,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}
