import type { BodyCrawlStatusProps } from './BodyCrawlStatus.types';
import type { FC } from 'react';

import React from 'react';
import { Button, Tooltip } from '@compliance.ai/web-components';
import classnames from 'classnames';
import { useBodyCrawlStatusData } from './hooks';
import { BODY_CRAWL_STATUS_TEXT_ALIGNMENT } from './BodyCrawlStatus.constants';
import './_body-crawl-status.scss';

export const BodyCrawlStatus: FC<BodyCrawlStatusProps> = ({
  report,
  textAlign,
  className = ''
}) => {
  const { formattedData } = useBodyCrawlStatusData({ report });

  return (
    <Tooltip title={formattedData.status.tooltip || formattedData.status.text}>
      <Button
        type={formattedData.status.type}
        className={classnames('body-crawl-status__button', className, {
          'body-crawl-status__button--is-left-aligned':
            textAlign === BODY_CRAWL_STATUS_TEXT_ALIGNMENT.LEFT,
          'body-crawl-status__button--is-center-aligned':
            textAlign === BODY_CRAWL_STATUS_TEXT_ALIGNMENT.CENTER
        })}
      >
        {formattedData.status.text}
      </Button>
    </Tooltip>
  );
};
