export const AGENCY_ACTIVE_TYPES = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};

export const AGENCY_TYPES = {
  FEDERAL: 'federal',
  STATE: 'state',
  INTERNATIONAL: 'international',
  SRO: 'sro',
  FEDERAL_JUDICIARY: 'federal_judiciary',
  FEDERAL_EXECUTIVE: 'federal_executive'
};

export const AGENCY_GENERAL = {
  agencyId: 'agency_id',
  parentID: 'parent_id',
  name: 'name',
  shortName: 'short_name',
  slug: 'slug',
  type: 'type',
  url: 'url',
  description: 'description',
  timesCited: 'times_cited',
  active: 'active',
  blacklisted: 'blacklisted',
  popular: 'popular',
  jurisdiction: 'jurisdiction',
  sourceType: 'source_type'
};

export const AGENCY_WORDS = {
  synonyms: 'synonyms',
  departments: 'departments',
  subdepartments: 'subdepartments'
};

export const AGENCY_LOGO = {
  logoImage: 'logo_image',
  logoUrl: 'logo_url'
};

// Errors

export const ERRORS = {
  commonError: 'This value is required'
};

// --- Predefined state

// agency details use by AgencyDetails component (just help to show structure)
export const AGENCY_DETAILS = {
  // AGENCY_GENERAL
  [AGENCY_GENERAL.agencyId]: '', // string || number
  [AGENCY_GENERAL.parentID]: '', // string || number
  [AGENCY_GENERAL.name]: '', // string
  [AGENCY_GENERAL.shortName]: '', // string
  [AGENCY_GENERAL.slug]: '', // string
  [AGENCY_GENERAL.url]: '', // string
  [AGENCY_GENERAL.description]: '', // string
  [AGENCY_GENERAL.timesCited]: '', // string
  [AGENCY_GENERAL.active]: false, // boolean
  [AGENCY_GENERAL.blacklisted]: false, // boolean
  [AGENCY_GENERAL.popular]: false, // boolean
  [AGENCY_GENERAL.jurisdiction]: null, // { value: "", label: "" }
  [AGENCY_GENERAL.type]: null, // { value: "", label: "" }
  [AGENCY_GENERAL.sourceType]: null, // { value: "", label: "" }
  // AGENCY_LOGO
  [AGENCY_LOGO.logoUrl]: '', // string
  [AGENCY_LOGO.logoImage]: [], // array
  //
  [AGENCY_WORDS.synonyms]: [], // array
  [AGENCY_WORDS.departments]: [], // array
  [AGENCY_WORDS.subdepartments]: [] // array
};
