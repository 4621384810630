import * as errorUtils from 'shared/utils/errors';
import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';

import { useCallback } from 'react';
import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';
export const useEitlLabelsAsyncSelectOptions = () => {
  const eitlLabelsApi = useEitlLabelsApi();

  const handleFetch = useCallback(
    async (inputString: string) => {
      try {
        const { labels } = await eitlLabelsApi.getEitlLabels({
          name: inputString ?? ''
        });

        return labels.map(eitlLabelsApiHelpers.formatEitlLabelsOption);
      } catch (e) {
        errorUtils.logError(e);
        return [];
      }
    },
    [eitlLabelsApi]
  );

  return {
    handlers: {
      handleFetch
    }
  };
};
