import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Select } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import { colors } from '../colors/colors';
import MTypography from '../Typography/mTypography';

const useStyle = makeStyles({
  defaultOptionStyle: {
    backgroundColor: colors.NAVY_BLUE_NEW,
    fontSize: 13,
    lineHeight: '20px',
    color: colors.WHITE,
    height: 20,
    '&.jurispect-Mui-disabled': {
      opacity: 1
    }
  },
  defaultIconStyle: {
    color: colors.WHITE,
    height: 15
  },
  defaultLabelStyle: {
    cursor: 'pointer',
    padding: '5px 10px',
    fontSize: 14,
    color: colors.GREY_DARK,
    '&:hover': {
      backgroundColor: colors.BACKGROUND_MEDIUM
    }
  },
  defaultPadding: {
    paddingRight: 5
  }
});

const MAutocomplete = ({
  classNameTextField,
  defaultValue = [],
  disabled,
  disableUnderline,
  desiredProperty,
  fullWidth,
  labelId,
  labelClass,
  label,
  onChange,
  optionLabel = 'label',
  optionClass,
  options = [],
  size,
  valueName = 'value',
  variant,
  wrapperClass,
  ...props
}) => {
  const classes = useStyle();
  const deleteValue = option => {
    const value = props.value.filter(element => element !== option);
    if (!disabled) onChange(value);
  };

  const returnValue = option => {
    return desiredProperty ? option[desiredProperty] : option;
  };

  const handleChange = option => {
    onChange(option.target.value);
  };

  return (
    <FormControl className={wrapperClass} fullWidth={fullWidth}>
      <MTypography id={labelId} className={labelClass}>
        {label}
      </MTypography>
      <Select
        disabled={disabled}
        multiple
        inputProps={{
          id: 'select-multiple'
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        value={props.value}
        defaultValue={defaultValue}
        onChange={handleChange}
        className={classNameTextField}
        disableUnderline={disableUnderline || true}
        renderValue={value =>
          _.map(
            _.filter(value, option => option),
            option => {
              return (
                <Chip
                  key={option.name ? option.name : option}
                  label={option.name ? option.name : option}
                  disabled={disabled}
                  className={classes.defaultOptionStyle}
                  //Yeah, it's bug of MU Chip, if I won't add onDelete={() => {}}
                  //it will hide deleteIcon, and onDelete doesn't work without
                  //Autocomplete component
                  onDelete={() => {}}
                  deleteIcon={
                    <CloseIcon
                      className={classes.defaultIconStyle}
                      onMouseDown={event => {
                        if (!disabled) {
                          event.stopPropagation();
                          deleteValue(option);
                        }
                      }}
                      onClick={deleteValue}
                    />
                  }
                />
              );
            }
          )
        }
      >
        {options.map(option => (
          <MenuItem key={returnValue(option)} value={option} className={classes.defaultLabelStyle}>
            {returnValue(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MAutocomplete.propTypes = {
  className: PropTypes.string,
  classNameTextField: PropTypes.string,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  desiredProperty: PropTypes.string,
  fullWidth: PropTypes.bool,
  labelId: PropTypes.number,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  optionLabel: PropTypes.string,
  optionClass: PropTypes.string,
  size: PropTypes.string,
  valueName: PropTypes.string,
  variant: PropTypes.string,
  wrapperClass: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string)
};

MAutocomplete.defaultProps = {
  className: '',
  classNameTextField: '',
  disabled: false,
  disableUnderline: false,
  desiredProperty: '',
  fullWidth: false,
  labelId: null,
  labelClass: '',
  label: '',
  optionLabel: '',
  optionClass: '',
  size: '',
  valueName: '',
  variant: '',
  wrapperClass: '',
  value: []
};

export default MAutocomplete;
