import type { LogsModalData } from './useLogsModalData.types';

import { useMemo, useState } from 'react';

export const useLogsModalData = (): LogsModalData => {
  const [isOpen, setIsOpen] = useState(false);

  const localState: LogsModalData['localState'] = {
    isOpen
  };

  const localActions: LogsModalData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
