import type { AuthCheckHandlers } from './useAuthCheckHandlers.types';
import type { AuthCheckData } from './useAuthCheckData.types';

import * as errorUtils from 'shared/utils/errors';

import { useUserReduxActions } from 'shared/features/user/hooks';
import { useCallback } from 'react';

export const useAuthCheckHandlers = ({
  localState,
  localActions,
  reduxState
}: {
  localState: AuthCheckData['localState'];
  localActions: AuthCheckData['localActions'];
  reduxState: AuthCheckData['reduxState'];
}): AuthCheckHandlers => {
  const userReduxActions = useUserReduxActions();

  const handleDataCheck: AuthCheckHandlers['handleDataCheck'] = useCallback(async () => {
    try {
      if (!localState.isReady && reduxState.JWTToken) {
        await userReduxActions.fetchCurrentUser();
        localActions.setIsReady(true);
      }
    } catch (e) {
      errorUtils.logError(e as Error);
    }
  }, [localActions, localState.isReady, reduxState.JWTToken, userReduxActions]);

  return {
    handleDataCheck
  };
};
