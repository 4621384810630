import { api_getAllIndustries } from "./industries.api";
import { setIndustries } from "./industries.reducer";
import { openErrorSnackbar } from "../snackbar/snackbar.reducer";

export const ERROR_GET_INDUSTRIES = 'Unable to get industries';

export const getAllIndustriesAC = () => async dispatch => {
  try {
    const res = await api_getAllIndustries();

    dispatch(setIndustries(res.industries));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR_GET_INDUSTRIES));

    throw e;
  }
};
