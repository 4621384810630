import React from "react";
import PropTypes from "prop-types";
import MIconButton from "../../IconButton/mIconButton";

const ButtonGroup = ({ buttons }) => {
  return buttons.map(button => {
    return (
      <MIconButton onClick={() => button.onClick()} className={button.classes} key={button.label}>
        {button.label}
        {button.icon}
      </MIconButton>
    );
  }
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ButtonGroup;
