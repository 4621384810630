import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAuthenticationLoadingState,
  getUserAuthenticatedState
} from 'shared/features/auth/auth.selectors';

export const useLoginData = ({ verificationCodeEmailData }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordResetOpen, setPasswordResetOpenState] = useState(
    verificationCodeEmailData.shouldUseEmailPasswordResetFlow
  );

  const reduxState = useSelector(state => ({
    isLoading: getAuthenticationLoadingState(state),
    isAuthenticated: getUserAuthenticatedState(state)
  }));

  const localActions = useMemo(() => {
    return {
      setEmail,
      setPassword,
      setPasswordResetOpenState
    };
  }, []);

  return {
    localActions,
    localState: {
      email,
      password,
      isPasswordResetOpen
    },
    reduxState
  };
};
