import type { CategoryData } from './useCaiCategoriesData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo, useState } from 'react';
import { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';

export const useCaiCategoriesData = (): CategoryData => {
  const [filters, setFilters] = useState<CategoryData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<CategoryData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: 50
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<CategoryData['localState']['reloadTrigger']>(false);
  const [isEditModalOpen, setIsEditModalOpen] =
    useState<CategoryData['localState']['isEditModalOpen']>(false);
  const [isSplitModalOpen, setIsSplitModalOpen] =
    useState<CategoryData['localState']['isSplitModalOpen']>(false);
  const [isMergeModalOpen, setIsMergeModalOpen] =
    useState<CategoryData['localState']['isMergeModalOpen']>(false);
  const [categoryToEdit, setCategoryToEdit] =
    useState<CategoryData['localState']['categoryToEdit']>(null);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryData['localState']['selectedCategories']
  >([]);
  const [tableCategories, setTableCategories] = useState<
    CategoryData['localState']['tableCategories']
  >([]);

  const formattedData: CategoryData['formattedData'] = useMemo(
    () => ({
      isSplitDisabled:
        selectedCategories.length !== 1 ||
        (
          tableCategories.find(
            item => item.id === selectedCategories[0]
          ) as CaiCategoriesFromResponse
        )?.scraped_categories?.length <= 1,
      isMergeDisabled: selectedCategories.length < 1
    }),
    [selectedCategories, tableCategories]
  );

  const localState: CategoryData['localState'] = {
    filters,
    tableParams,
    reloadTrigger,
    isEditModalOpen,
    isSplitModalOpen,
    isMergeModalOpen,
    categoryToEdit,
    selectedCategories,
    tableCategories
  };

  const localActions: CategoryData['localActions'] = useMemo(
    () => ({
      setFilters,
      setTableParams,
      setReloadTrigger,
      setIsEditModalOpen,
      setIsSplitModalOpen,
      setIsMergeModalOpen,
      setCategoryToEdit,
      setSelectedCategories,
      setTableCategories
    }),
    []
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
