import React from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '../../../components/Typography';
import { colors } from '../../../components/MaterialComponents/colors/colors';

const CrawlCatalogListItem = ({ fieldValue, fieldName }) => {
  // item main settings (icon, main value)
  const targetIcon = fieldValue
    ? <CheckCircleIcon htmlColor={colors.GREEN_BUTTONS} />
    : <ErrorIcon htmlColor={colors.YELLOW_WARNING} />;

  fieldValue = String(fieldValue);
  if (fieldValue.startsWith('http')) {
    fieldName = <a href={fieldValue}>{fieldName}</a>;
  }
  return (
    <li className="iconTextBlock">
      {targetIcon}
      <Typography variant="p">{fieldName}</Typography>
    </li>
  );
};

const CrawlCatalogSimpleInfoList = ({ listTitle, crawlInfo, requiredFields }) => {
  return (
    <div className="requiredSimpleFieldsList">
      <Typography variant="label" color="gray">
        {listTitle}
      </Typography>
      <ul>
        {requiredFields.map(fieldName => (
          <CrawlCatalogListItem
            key={fieldName}
            fieldName={fieldName}
            fieldValue={_.get(crawlInfo, fieldName, '')}
          />))}
      </ul>
    </div>
  );
};

CrawlCatalogSimpleInfoList.propTypes = {
  listTitle: PropTypes.string,
  crawlInfo: PropTypes.shape({}).isRequired,
  requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired
};

CrawlCatalogSimpleInfoList.defaultProps = {
  listTitle: ''
};

export default CrawlCatalogSimpleInfoList;
