import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { closeSnackbar } from "shared/features/snackbar/snackbar.reducer";
import MIconButton from "../IconButton/mIconButton";
import { colors } from '../colors/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    color: colors.WHITE
  },
  iconButton: {
    paddingRight: 50
  },
  label: {
    paddingRight: 50
  }
});

const MSnackbar = ({ closeTime, label, open, severity, ...props }) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{
        root: classes.success
      }}
      open={open}
      autoHideDuration={closeTime}
      onClose={props.closeSnackbar}
      action={
        <div className={classes.wrapper}>
          <MIconButton onClick={() => props.closeSnackbar()} className={classes.iconButton}>
            <CloseIcon fontSize='small' color='secondary' />
          </MIconButton>
          <h5 className={classes.label}>{label}</h5>
        </div>
      }
    />
  );
};


MSnackbar.propTypes = {
  closeTime: PropTypes.number,
  label: PropTypes.string,
  open: PropTypes.bool,
  severity: PropTypes.string
};
MSnackbar.defaultProps = {
  closeTime: 6000,
  label: '',
  open: false,
  severity: ''
};

const mapStateToProps = state => ({
  closeTime: state.snackbar.closeTime,
  label: state.snackbar.label,
  open: state.snackbar.open,
  severity: state.snackbar.severity
});

export default connect(mapStateToProps, { closeSnackbar })(MSnackbar);
