import { api_getDocuments } from 'shared/features/documents/documents.api';
import { api_getResourcesHeadDocs } from './resources.api';

export const RESET_NAV = 'RESET_NAV';
export const SET_INITIAL_PATH = 'SET_INITIAL_PATH';
export const SET_PATH = 'SET_PATH';
export const SET_LAST_REQUESTED_ID = 'SET_LAST_REQUESTED_ID';
export const REQUEST_RESOURCES_DOCS = 'REQUEST_RESOURCES_DOCS';
export const RECEIVE_RESOURCES_DOCS = 'RECEIVE_RESOURCES_DOCS';
export const REQUEST_HEAD_DOCS = 'REQUEST_HEAD_DOCS';
export const RECEIVE_HEAD_DOCS = 'RECEIVE_HEAD_DOCS';

export const resetNav = () => dispatch => {
  dispatch({ type: RESET_NAV });
};

export const setInitialPath = () => dispatch => {
  dispatch({ type: SET_INITIAL_PATH });
};

export const setPath = ({ columnIndex, selectedId, newColumn }) => dispatch => {
  dispatch({ type: SET_PATH, columnIndex, selectedId, newColumn });
};

export const setLastRequestedId = ({ id }) => dispatch => {
  dispatch({ type: SET_LAST_REQUESTED_ID, id });
};

export const requestResourcesDoc = ({ id }) => async (dispatch, getState) => {
  dispatch({ type: REQUEST_RESOURCES_DOCS, id });
  const { resourcesNav: { requestedDocs } } = getState();
  if (requestedDocs[id]) {
    dispatch({ type: RECEIVE_RESOURCES_DOCS, payload: { document: requestedDocs[id] }, id });
  } else {
    const payload = await api_getDocuments({ id, decorate_children: true });
    dispatch({ type: RECEIVE_RESOURCES_DOCS, payload, id });
  }
};

export const requestResourcesHeadDocs = () => async dispatch => {
  dispatch({ type: REQUEST_HEAD_DOCS });
  const payload = await api_getResourcesHeadDocs();
  dispatch({ type: RECEIVE_HEAD_DOCS, payload });
};
