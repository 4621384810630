import Bugsnag from '@bugsnag/js';
import React from 'react';

import '@compliance.ai/web-components/dist/style.css';
import { createRoot } from 'react-dom/client';
import { nodeEnv, bugsnagApiKey } from './shared/config';
import { Router } from './admin/router';
import { initializeGoogleAnalytics } from './shared/utils/analytics';
import { initializeInterceptors } from './fetch/fetch.interceptors';

import 'font-awesome/css/font-awesome.css';
import './admin/styles/main.scss';

if (bugsnagApiKey) {
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    releaseStage: nodeEnv,
    enabledReleaseStages: ['production']
  });
}

initializeInterceptors();
initializeGoogleAnalytics();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // TODO StrictMode causes react-bootstrap modals to not show.
  // Uncomment when figure out how to remove or bump react-bootstrap
  // <React.StrictMode>
  <Router />
  // </React.StrictMode>
);
