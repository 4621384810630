import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ERRORS, INFO_HELP, NAMES_DOC } from '../CrawlSpecCreate.constants';
import SpecElementsWrapper from "./SpecElementsWrapper";
import ExpandableBtnList from "../HelpComponents/ExpandableBtnList";
import { createNewMetaData } from "../CrawlSpecCreate.helper";
import Checkbox from "../../../components/Checkbox_NEW";

const Document = ({
  disabled,
  show,
  currentMetaDataUUID,
  element,
  changeCurrentDocField,
  metaData,
  changeMetaData,
  changeCurrentMetaData,
  clearMetaDataElements,
  errorFieldsMetaData,
  errorFields
}) => {
  const changeDocMetaData = (name, value) => {
    changeCurrentDocField(name, value.map(item => item.id));
  };

  const addNewMetaData = () => {
    const newMetaData = createNewMetaData();
    changeMetaData([...metaData, newMetaData]);
    return newMetaData;
  };

  const metaDataIDs = _.get(element, NAMES_DOC.META_DATA, []);

  return (
    <SpecElementsWrapper
      show={show}
      label="Doc"
      tooltip={INFO_HELP.DOC}
    >
      <Checkbox
        label="Incomplete pub date"
        disabled={disabled}
        onClick={checked => changeCurrentDocField(NAMES_DOC.INCOMPLETE_PUB_DATE, checked)}
        isError={errorFields.includes(NAMES_DOC.INCOMPLETE_PUB_DATE)}
        checked={element[NAMES_DOC.INCOMPLETE_PUB_DATE]}
      />
      <ExpandableBtnList
        label="Meta Data"
        isDisabled={disabled}
        activeID={currentMetaDataUUID}
        value={metaData.filter(item => metaDataIDs.includes(item.id))}
        name={NAMES_DOC.META_DATA}
        addMoreName="Meta Data"
        onChange={changeDocMetaData}
        onClickBtn={changeCurrentMetaData}
        createNewItemTemplate={addNewMetaData}
        helpRemoveFunc={clearMetaDataElements}
        errorFields={Object.keys(errorFieldsMetaData)}
        isError={
          errorFields.includes(NAMES_DOC.META_DATA)
          && !_.get(element, [NAMES_DOC.META_DATA, 'length'], '')
        }
        errorText={ERRORS.docMetaData}
      />
    </SpecElementsWrapper>
  );
};

Document.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentMetaDataUUID: PropTypes.string,
  element: PropTypes.shape({}),
  metaData: PropTypes.arrayOf(PropTypes.shape({})),
  changeMetaData: PropTypes.func.isRequired,
  changeCurrentDocField: PropTypes.func.isRequired,
  changeCurrentMetaData: PropTypes.func.isRequired,
  clearMetaDataElements: PropTypes.func.isRequired,
  errorFieldsMetaData: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Document.defaultProps = {
  disabled: false,
  show: false,
  currentMetaDataUUID: '',
  element: {},
  metaData: [],
  errorFieldsMetaData: {},
  errorFields: []
};

export default Document;
