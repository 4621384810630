import {fetch, BASE_API_OPTIONS} from "fetch";

export function api_getCrawlerActivity(crawlId, params) {
  return fetch({
    url: `/crawl_statistic/${crawlId}`,
    dataType: 'Crawler',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postExportCrawlInfo(crawlId) {
  return fetch({
    url: `/export_crawl/${crawlId}`,
    dataType: 'Crawler',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_postImportCrawlInfo(crawlId) {
  return fetch({
    url: `/import_crawl/${crawlId}`,
    dataType: 'Crawler',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_postExportCrawlConstructionInfo(crawlId) {
  return fetch({
    url: `/export_construction_config/${crawlId}`,
    dataType: 'Crawler',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}
