import type { FC } from 'react';
import type { EitlLabelsImportModalProps } from './EitlLabelsImportModal.types';

import React from 'react';

import * as constants from './EitlLabelsImportModal.constants';
import * as uiLib from '@compliance.ai/web-components';

import {
  useEitlLabelsImportModalData,
  useEitlLabelsImportModalHandlers,
  useEitlLabelsImportModalMutations,
  useEitlLabelsImportModalFormattedData
} from './hooks';

import './_eitl-labels-import-modal.scss';

export const EitlLabelsImportModal: FC<EitlLabelsImportModalProps> = ({ onConfirm, onClose }) => {
  const { localState, localActions } = useEitlLabelsImportModalData();

  const handlers = useEitlLabelsImportModalHandlers({
    localActions
  });

  const mutations = useEitlLabelsImportModalMutations({
    props: {
      onConfirm
    },
    localState
  });

  const formattedData = useEitlLabelsImportModalFormattedData({
    localState,
    mutations
  });

  return (
    <uiLib.Modal
      isOpen
      title="Import EITL Labels"
      onSubmit={mutations.importEitlLabels.mutate}
      onClose={onClose}
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Upload"
      withFooter
      classNames={{
        container: 'eitl-labels-import-modal__container',
        content: 'eitl-labels-import-modal__content'
      }}
    >
      <div className="eitl-labels-import-modal__content-container">
        {mutations.importEitlLabels.isLoading && <uiLib.Loader withoutBackground={false} />}
        <uiLib.Typography>Enter labels in a single column in your file.</uiLib.Typography>
        <div className="eitl-labels-import-modal__dropzone-wrapper">
          <uiLib.Dropzone
            onSelectFiles={handlers.handleSelectFiles}
            maxFiles={constants.MAX_ALLOWED_ATTACHMENT_FILES}
            accept={constants.ALLOWED_FILE_EXTENSIONS}
            shouldShowAcceptedFileTypes
            areFilesDeletable
          />
        </div>
      </div>
    </uiLib.Modal>
  );
};
