import type { NewsSourcesHandlers } from './useNewsSourcesHandlers.types';
import type { NewsSourcesData } from './useNewsSourcesData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as newSourcesApi from 'shared/features/sources/newsSources/newsSources.api';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useNewsSourcesHandlers.helpers';
import ROUTES from 'admin/constants/routes';
import { useNavigate } from 'react-router-dom';

export const useNewsSourcesHandlers = ({
  localState,
  localActions
}: {
  localState: NewsSourcesData['localState'];
  localActions: NewsSourcesData['localActions'];
}): NewsSourcesHandlers => {
  const navigate = useNavigate();

  const handleNewsSourcesFetch: NewsSourcesHandlers['handleNewsSourcesFetch'] = async params => {
    try {
      const newsSourcesResponse = await newSourcesApi.api_getNewsSources({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      return {
        results: newsSourcesResponse.items.map(helpers.formatNewsSource),
        count: newsSourcesResponse.count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleFiltersSubmit: NewsSourcesHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleCreateNewsSource: NewsSourcesHandlers['handleCreateNewsSource'] = () => {
    navigate(`${ROUTES.sources}/new`);
  };

  return {
    handleNewsSourcesFetch,
    handleFiltersSubmit,
    handleCreateNewsSource
  };
};
