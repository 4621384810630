import React from 'react';
import { Radio } from 'react-bootstrap';

class ObligationAnnotation extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const obligation_row = this.props.obligation_row;
    if (!obligation_row) {
      return null;
    }
    // const input_color = (obligation_row.obligation_value===0)? "#d1d1d1" : "#FFFFFF";
    // const bgdcolor = { 'background-color': input_color };
    return (
      <tr>
        <td>{obligation_row.row_id}</td>
        <td>
          <div className='obligation-sentence'>{obligation_row.sentence}</div>
        </td>
        <td>
          <Radio
            name={'obligation_value_' + obligation_row.id}
            value='1'
            inline
            checked={obligation_row.obligation_value === 1}
            onChange={e => this.props.handleObligationAnnotationFieldChange(
              obligation_row.id,
              e
            )}
          >
            Yes
          </Radio>
          <Radio
            name={'obligation_value_' + obligation_row.id}
            value='0'
            inline
            checked={obligation_row.obligation_value === 0}
            onChange={e => this.props.handleObligationAnnotationFieldChange(
              obligation_row.id,
              e
            )}
          >
            No
          </Radio>
        </td>
        <td>
          <input
            //disabled={(obligation_row.obligation_value===0)? "disabled" : ""}
            //style={bgdcolor}
            min='0'
            type='number'
            className='obligation-group-textbox'
            //value={(obligation_row.obligation_value===0)? 0 : obligation_row.group}
            value={obligation_row.group}
            name={'group_field_' + obligation_row.id}
            onChange={e => this.props.handleGroupFieldChange(obligation_row.id, e)}
          />
        </td>
      </tr>
    );
  }
}

export default ObligationAnnotation;
