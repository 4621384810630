import { defaultStateAgencies } from 'shared/utils/defaultSources';

import {
  FOLLOW_AGENCIES,
  FOLLOWED_AGENCIES,
  REQUEST_AGENCIES,
  RECEIVE_AGENCIES,
  REQUEST_SEARCH_FILTER_AGENCIES,
  RECEIVE_SEARCH_FILTER_AGENCIES
} from './agencies.actions';

export const getInitialAgenciesState = () => ({
  isFetching: false,
  isReady: false,
  pending_updates: 0,
  items: [],
  itemsObj: {},
  followed_agencies: [],
  followedAgenciesObj: {},
  search_filter_agencies: [],
  statesStatic: defaultStateAgencies
});

export const agencies = (state = getInitialAgenciesState(), action) => {
  switch (action.type) {
    case REQUEST_AGENCIES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_AGENCIES: {
      if (action.following) {
        const { agencies: followed_agencies } = action;
        const followedAgenciesObj = followed_agencies.reduce(
          (obj, agency) => {
            obj[agency.id] = agency;
            return obj;
          },
          {}
        );

        return {
          ...state,
          isFetching: false,
          isReady: true,
          followed_agencies,
          followedAgenciesObj
        };
      }

      const { agencies: items } = action;
      const { itemsObj, followed_agencies, followedAgenciesObj } = items.reduce(
        (obj, agency) => {
          obj.itemsObj[agency.id] = agency;
          if (agency.following) {
            obj.followed_agencies.push(agency);
            obj.followedAgenciesObj[agency.id] = agency;
          }
          return obj;
        },
        { itemsObj: {}, followed_agencies: [], followedAgenciesObj: {} }
      );

      return {
        ...state,
        isFetching: false,
        isReady: true,
        items,
        itemsObj,
        followed_agencies,
        followedAgenciesObj
      };
    }
    case REQUEST_SEARCH_FILTER_AGENCIES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_SEARCH_FILTER_AGENCIES:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        search_filter_agencies: action.agencies
      };
    case FOLLOW_AGENCIES: {
      const { itemsObj } = state;
      action.agencies.forEach(({ id, following }) => {
        itemsObj[id].following = following;
      });

      const { items, followed_agencies, followedAgenciesObj } = Object.values(itemsObj).reduce(
        (obj, agency) => {
          obj.items.push(agency);
          if (agency.following) {
            obj.followed_agencies.push(agency);
            obj.followedAgenciesObj[agency.id] = agency;
          }
          return obj;
        },
        { items: [], followed_agencies: [], followedAgenciesObj: {} }
      );

      return {
        ...state,
        pending_updates: state.pending_updates + 1,
        items,
        itemsObj,
        followed_agencies,
        followedAgenciesObj
      };
    }
    case FOLLOWED_AGENCIES:
      return {
        ...state,
        pending_updates: state.pending_updates - 1
      };
    default:
      return state;
  }
};

export const getInitialAgenciesAdminState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  agencies: {}
});

export const agencies_admin = (state = getInitialAgenciesAdminState(), action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const getInitialAgenciesAdminToolState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  agencies: []
});

export const all_agencies_admin_tool = (state = getInitialAgenciesAdminToolState(), action) => {
  switch (action.type) {
    default:
      return state;
  }
};
