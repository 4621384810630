import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Chip } from "@material-ui/core";
import { COLORS } from 'app/constants/Style';

export const CHIP_STATUS_PRIMARY = 'primary';
export const CHIP_STATUS_SUCCESS = 'success';
export const CHIP_STATUS_ERROR = 'error';
export const CHIP_STATUS_BASIC = 'basic';

export const TEST_ID = 'chip-test-id';

const determineStatusBackgroundColor = status => {
  switch (status) {
    case CHIP_STATUS_PRIMARY:
      return COLORS.hoverBlue;

    case CHIP_STATUS_SUCCESS:
      return COLORS.feedbackGreen;

    case CHIP_STATUS_ERROR:
      return COLORS.dangerRed;

    default:
      return COLORS.backgroundGrey;
  }
};

const StyledChip = styled(Chip)`
  && {
    font-size: 12px;
    border-radius: 5px;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: ${props => props.status === CHIP_STATUS_BASIC ? 'black' : 'white'};
    background-color: ${props => determineStatusBackgroundColor(props.status)};
  }
`;

const SharedChip = (props) => {
  const { label, onClick, disabled, status } = props;

  return (
    <StyledChip
      data-testid={TEST_ID}
      label={label}
      status={status}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

SharedChip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func,
  status: PropTypes.oneOf([
    CHIP_STATUS_PRIMARY,
    CHIP_STATUS_SUCCESS,
    CHIP_STATUS_ERROR,
    CHIP_STATUS_BASIC
  ]),
  disabled: PropTypes.bool
};

SharedChip.defaultProps = {
  disabled: false,
  status: CHIP_STATUS_BASIC,
  onClick: undefined
};

export default SharedChip;
