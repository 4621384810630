import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import { withHistory } from 'admin/utils/hooks';
import OrgTeam from './OrgTeam';
import { fetchCurrentUser } from 'shared/features/user/user.actions';
import { fetchOrganization } from 'shared/features/organizations/organizations.actions';
import ROUTES from '../../constants/routes';

class ManageOrgTeams extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let org_id = '';
    const parsedQuery = queryString.parse(this.props.location.search);
    if (parsedQuery.org_id) {
      const org_ids = _.sortBy(parsedQuery, 'org_id');
      org_id = parseInt(org_ids[0], 0);
    } else {
      org_id = this.props.current_user.user.organization.id;
    }
    if (org_id) {
      this.props.fetchOrganization(org_id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.current_user.user.organization && nextProps.current_user.user.organization) {
      this.props.fetchOrganization(nextProps.current_user.user.organization.id);
    }
  }

  backToAllOrgs = () => {
    this.props.history.push(ROUTES.organizations);
  };

  render() {
    if (!this.props.organization.isReady) {
      return null;
    }
    const active_teams = this.props.organization.organization.teams;
    const teams_container = _.orderBy(active_teams, 'name', 'desc').map(team => {
      return <OrgTeam key={team.id} team={team} />;
    });
    const teamNr = this.props.organization.organization.teams
      ? this.props.organization.organization.teams.length
      : 0;
    const teamsNrLabel = teamNr > 1 ? 'Teams' : 'Team';
    return (
      <div className="manage-org-teams">
        <div className="back-to-all-orgs" onClick={this.backToAllOrgs}>
          <i className="material-icons">keyboard_arrow_left</i>
          <div>{'All Organizations'}</div>
        </div>
        <div className="org-info">
          <div className="org-name">{this.props.organization.organization.name}</div>
          <div className="org-licenses-teams-nr">{teamNr + ' ' + teamsNrLabel}</div>
        </div>
        <div className="org-teams-container">{teams_container}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ current_user, organization }) => {
  return { current_user, organization };
};

export default connect(
  mapStateToProps,
  {
    fetchCurrentUser,
    fetchOrganization
  }
)(withHistory(ManageOrgTeams));
