import React from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from "moment";
import { Button, ControlLabel } from 'react-bootstrap';
import {
  fetchAllCommands,
  fetchAllCommandRequests,
  uploadNewCommandRequest
} from 'shared/features/commands/commands.actions';
import { getCategories } from 'shared/features/categories/categories/categories.actions';
import { getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.actions';
import { getAgencies } from 'shared/features/agencyData/agencyData.actions';
import { fetchCollections } from 'shared/features/collection/collection.actions';
import { fetchSentenceModel } from 'shared/features/sentenceModel/sentenceModel.actions';
import PopupInfo from '../../components/PopupInfo';
import { popupName } from '../../constants/popupInfoConst';
import CollectionToolModal from './CollectionToolModal';
import CollectionTableHeader from './CollectionTableHeader';
import CollectionTableBody from './CollectionTableBody';
import SelectRender, { typeOfField } from '../../components/SelectRender';
//import Spinner from '../../components/spinner';
import { clearErrors } from 'shared/features/error/actions';
import { commandRequestTypes } from "../../constants/commandRequestTypes";
import {withAdminPermissions} from "../../utils/hooks";

const dateFormat = 'MM/DD/YYYY';

class CollectionTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopupText: '',
      showInfoPopup: false,
      categories: null,
      jurisdictions: null,
      agencies: null,
      sentence_model_id: null,
      from_date: null,
      to_date: null
    };
  }

  componentDidMount() {
    const {
      categories, all_agencies_admin_tool, jurisdictions,
      sentenceModel, command_requests, commands
    } = this.props;

    if (!categories.items
      || !categories.items.length) {
      this.props.getCategories();
    }
    if (!all_agencies_admin_tool.items
      || !all_agencies_admin_tool.items.length) {
      this.props.getAgencies();
    }
    if (!jurisdictions.items
      || !jurisdictions.items.length) {
      this.props.getJurisdictions({ get_all: true });
    }
    if (!sentenceModel) {
      this.props.fetchSentenceModel();
    }
    if (!command_requests.items
      || !command_requests.items.length) {
      this.props.fetchAllCommandRequests();
    }
    if (!commands.items
      || !commands.items.length) {
      this.props.fetchAllCommands();
    }

    this.props.clearErrors('collections');
    this.props.fetchCollections();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleModal = (value, collection) => {
    this.setState({
      showModal: value,
      collection: collection || {}
    });
  };

  filterRequest = () => {
    const { from_date, to_date } = this.state;
    const params = {
      categories: this.state.categories,
      jurisdictions: this.state.jurisdictions,
      agencies: this.state.agencies,
      sentence_model_id: this.state.sentence_model_id,
      start_pub_date: from_date && moment(from_date).format(dateFormat),
      finish_pub_date: to_date && moment(to_date).format(dateFormat)
    };
    this.props.fetchCollections(params);
  };

  trainNowCommand = (collection) => {
    let commandType;
    try {
      commandType = this.props.commands.items
        .filter(command => command.parameters.type === commandRequestTypes.trainModel)[0].id;
    } catch (e) {
      this.setState({
        infoPopupText: `Command with type '${commandRequestTypes.trainModel}' doesn't exist.`,
        showInfoPopup: true
      });
      return;
    }

    const command_request_parameters = {
      name: `Train Model: ${collection.name}`,
      type: commandType,
      user_id: this.props.current_user.user.id,
      parameters: {
        collection_id: collection.id
      }
    };
    const params = new window.FormData();
    params.append('command_request_parameters', JSON.stringify(command_request_parameters));
    this.props.uploadNewCommandRequest(params);
  };

  render() {
    const {
      categories, all_agencies_admin_tool, haveEditAccess,
      jurisdictions,
      //collections,
      sentenceModel
    } = this.props;

    // const preLoadIsReady = !this.props.all_agencies_admin_tool.load
    //   || !this.props.command_requests.isReady
    //   || !sentenceModel
    //   || !collections;

    const selectorElements = [
      {
        id: 1,
        state_name: 'categories',
        name: 'Category',
        multiSave: true,
        categories: categories.items
          && categories.items.map(item => ({
            value: item.app_category,
            label: item.app_category
          })),
        typeOfInput: typeOfField.select
      },
      {
        id: 2,
        state_name: 'jurisdictions',
        name: 'Jurisdictions',
        multiSave: true,
        categories: jurisdictions.items.map(item => ({
          value: item.short_name,
          label: item.short_name
        })),
        typeOfInput: typeOfField.select
      },
      {
        id: 3,
        state_name: 'agencies',
        name: 'Agency',
        multiSave: true,
        categories: all_agencies_admin_tool.items.map(item => ({
          value: item.short_name || item.name,
          label: item.short_name || item.name
        })),
        typeOfInput: typeOfField.select
      },
      {
        id: 5,
        state_name: 'sentence_model_id',
        name: 'Sentence Model',
        multiSave: false,
        categories: sentenceModel && sentenceModel.map(item => ({
          value: item.id,
          label: item.name
        })),
        typeOfInput: typeOfField.select
      }
    ];

    const selectBarRender = selectorElements.map(item => (
      <SelectRender
        key={item.id}
        item={item}
        state={this.state}
        handleChange={this.handleChange}
      />
    ));

    const publicationDate = (
      <div className="filterAdminToolWrap_elem" key={4}>
        <ControlLabel>{'Date of creation'}</ControlLabel>
        <div className="filterAdminToolWrap_elem_double">
          <DatePicker
            className="filterAdminToolWrap_elem_double_datePicker"
            placeholderText="From"
            selected={this.state.from_date}
            onChange={(date) => this.handleChange({
              target: {
                value: date,
                name: 'from_date'
              }
            })}
            isClearable
          />
          <DatePicker
            className="filterAdminToolWrap_elem_double_datePicker"
            placeholderText="To"
            selected={this.state.to_date}
            onChange={(date) => this.handleChange({
              target: {
                value: date,
                name: 'to_date'
              }
            })}
            isClearable
          />
        </div>
      </div>
    );
    return (
      <div className="thinBtn AdminToolPageTemplate">
        <h1>Collection Admin Tool</h1>
        <hr />
        <div className="filterAdminToolWrap">
          {publicationDate}
          {selectBarRender}
        </div>
        <hr />
        {haveEditAccess && (
          <Button
            bsStyle="primary"
            onClick={() => {
              this.toggleModal(true);
            }}
          >
            Add new
          </Button>
        )}
        <Button
          bsStyle="primary"
          className="rightBtn"
          onClick={this.filterRequest}
        >
          Filter
        </Button>
        <div style={{ clear: 'both' }} />
        <div className="adminToolTableFlex">
          <CollectionTableHeader />
          <CollectionTableBody
            command_requests={this.props.command_requests}
            collections={this.props.collections}
            toggleModal={this.toggleModal}
            trainNowCommand={this.trainNowCommand}
          />
        </div>
        {/*{preLoadIsReady && (*/}
        {/*  <div className="spinnerWrapper">*/}
        {/*    <Spinner />*/}
        {/*  </div>*/}
        {/*)}*/}
        <CollectionToolModal
          showModal={this.state.showModal}
          closeModal={() => this.toggleModal(false)}
          selectorElements={selectorElements}
          collection={this.state.collection}
          dateFormat={dateFormat}
        />
        <PopupInfo
          show={!!this.props.errors.collections || this.state.showInfoPopup}
          onHide={() => {
            this.props.clearErrors('collections');
            this.setState({
              showInfoPopup: false,
              infoPopupText: ''
            });
          }}
          popupName={popupName.errorRespPopup}
          errorText={
            this.state.infoPopupText
            || this.props.errors.collections
            && this.props.errors.collections[0]
            && this.props.errors.collections[0].responseText
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.documentCategories,
    sources: state.sources,
    errors: state.errors,
    all_agencies_admin_tool: state.agencyData,
    jurisdictions: state.jurisdictionsData,
    viewer: state.current_user,
    collections: state.collections,
    sentenceModel: state.sentenceModel,
    command_requests: state.command_requests,
    commands: state.commands,
    current_user: state.current_user
  };
};

const ReduxCollectionTool = withAdminPermissions(connect(mapStateToProps, {
  getCategories,
  getAgencies,
  getJurisdictions,
  fetchCollections,
  clearErrors,
  fetchSentenceModel,
  fetchAllCommands,
  fetchAllCommandRequests,
  uploadNewCommandRequest
})(CollectionTool));

export default ReduxCollectionTool;
