import type { NetworkError } from 'shared/utils/errors';

import { QUERY_KEYS } from 'admin/constants/queryKeys';
import { StatusCodes } from 'http-status-codes';

import * as constants from '../AuthCheck.constants';
import * as routes from 'app/constants/Routes';

import { useAuthReduxActions } from 'shared/features/auth/hooks';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const useAuthCheckQueries = () => {
  const authReduxActions = useAuthReduxActions();
  const navigate = useNavigate();

  const refreshTokensQuery = useQuery({
    queryKey: [QUERY_KEYS.SESSION_TOKENS],
    queryFn: () => authReduxActions.refreshSessionTokens(),
    onError: (e: NetworkError) => {
      if (!e.status || [StatusCodes.BAD_GATEWAY, StatusCodes.GATEWAY_TIMEOUT].includes(e?.status)) {
        refreshTokensQuery.refetch();
      } else {
        navigate(routes.login);
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: constants.TOKENS_REFRESH_INTERVAL,
    refetchOnReconnect: true,
    refetchIntervalInBackground: true
  });

  return {
    refreshTokensQuery
  };
};
