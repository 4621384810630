import type { FC } from 'react';
import type { CrawlerQADownloadActionProps } from './CrawlerQADownloadAction.types';

import React from 'react';
import * as uiLib from '@compliance.ai/web-components';

import './_crawl-qa-download.scss';

export const CrawlerQADownloadAction: FC<CrawlerQADownloadActionProps> = ({ category }) => {
  const categoryLynk = category?.qa_url?.href;
  return (
    <uiLib.Button
      className="crawl-qa-download__button"
      isDisabled={!categoryLynk}
      href={categoryLynk}
      type={uiLib.BUTTON_TYPES.SECONDARY}
    >
      <uiLib.IconDownload />
    </uiLib.Button>
  );
};
