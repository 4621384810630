import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { EXPECTED_ERROR_MESSAGES, STATUS_CODES } from './auth.constants';

import * as authApi from './auth.api';

export const SET_TOKENS = 'SET_TOKENS';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const SIGN_UP_USER = 'SIGN_UP_USER';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const REFRESH_SESSION_TOKENS = 'REFRESH_SESSION_TOKENS';
export const CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SIGN_OUT = 'SIGN_OUT';

export const setTokens = createAction(SET_TOKENS);

export const authenticateUser = createAsyncThunk(
  AUTHENTICATE_USER,
  async ({ email, password }, { dispatch }) => {
    try {
      await authApi.signOut();

      const tokens = await authApi.authenticate({
        email,
        password
      });

      dispatch(setTokens(tokens));

      return tokens;
    } catch (e) {
      throw e;
    }
  }
);

export const signUpUser = createAsyncThunk(
  SIGN_UP_USER,
  async ({ email, password, orgInvitationToken }, { dispatch }) => {
    try {
      await authApi.signOut();

      const response = await authApi.signUp({
        email,
        password,
        orgInvitationToken
      });

      const statusCode = Array.isArray(response) ? response[1] : STATUS_CODES.OK;

      if (STATUS_CODES.INTERNAL_SERVER_ERROR === statusCode) {
        throw new Error(EXPECTED_ERROR_MESSAGES.VALIDATION_ERROR);
      }

      const tokens = await authApi.authenticate({
        email,
        password
      });

      dispatch(setTokens(tokens));

      return tokens;
    } catch (e) {
      throw e;
    }
  }
);

export const refreshSessionTokens = createAsyncThunk(
  REFRESH_SESSION_TOKENS,
  async ({} = {}, { dispatch, rejectWithValue }) => {
    try {
      const tokens = await authApi.refreshSession();

      dispatch(setTokens(tokens));

      return tokens;
    } catch (e) {
      /**
       *
       * Rejecting with values in order to access the returned status code
       *
       */
      return rejectWithValue({
        type: e?.type,
        message: e?.message,
        status: e?.status
      });
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  SEND_PASSWORD_RESET_EMAIL,
  async ({ email }) => {
    try {
      await authApi.sendPasswordResetEmail({
        email
      });
    } catch (e) {
      throw e;
    }
  }
);

export const confirmNewPassword = createAsyncThunk(
  CONFIRM_NEW_PASSWORD,
  async ({ email, verificationCode, newPassword }) => {
    try {
      await authApi.confirmNewPassword({
        email,
        verificationCode,
        newPassword
      });
    } catch (e) {
      throw e;
    }
  }
);

export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  async ({ oldPassword, newPassword }) => {
    try {
      await authApi.changePassword({ oldPassword, newPassword });
    } catch (e) {
      throw e;
    }
  }
);

export const signOut = createAsyncThunk(SIGN_OUT, async () => {
  try {
    await authApi.signOut();
  } catch (e) {
    throw e;
  }
});
