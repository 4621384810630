import React from 'react';
import PropTypes from "prop-types";
import cn from "classnames";
import Typography from "../../../../../components/Typography";

const GlassdoorTableHeader = ({
  leftChild,
  rightChild,
  className,
  leftBlockClass
}) => (
  <div className={cn("glassdoor_table_header", className)}>
    {leftChild && (
      <div className={cn("glassdoor_table_header_block", leftBlockClass)}>
        {leftChild}
      </div>
    )}
    {rightChild && (
      <div className="glassdoor_table_header_block">
        {rightChild}
      </div>
    )}
  </div>
);

GlassdoorTableHeader.propTypes = {
  leftChild: PropTypes.element,
  rightChild: PropTypes.element,
  leftBlockClass: PropTypes.string
};

GlassdoorTableHeader.defaultProps = {
  leftChild: null,
  rightChild: null,
  leftBlockClass: ''
};

export const HeaderRow = ({
  items
}) => (
  <div className="table-row">
    {items.map((item, index) => (
      <div className={cn("table-cell", { "crawler-name": item === 'Crawler' })} key={`${item}-${index}`}>
        <Typography bold variant="p" color="transparent_black">{item}</Typography>
      </div>
    ))}
  </div>
);

export default GlassdoorTableHeader;
