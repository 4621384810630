import _ from "lodash";
import uuidv4 from "uuid/v4";
import {
  ACTION_METHODS,
  ACTION_TYPES,
  RELATION_TYPES,
  FIRST_STEP,
  NAMES_CAT_CONV,
  NAMES_ACTION,
  NAMES_CONTEXT,
  NAMES_DOC,
  NAMES_EXAMPLE,
  NAMES_EXTRACT_TAGS,
  NAMES_RELATION,
  NAMES_META_DATA,
  NAMES_LOCATION,
  NAMES_PATH,
  NAMES_STEP,
  NAMES_RESET_COUNTER,
  SPEC_GENERAL_SETTINGS,
  TEMPLATE_ACTION,
  TEMPLATE_RELATION,
  TEMPLATE_CONTEXT,
  TEMPLATE_DOC,
  TEMPLATE_EXAMPLE,
  TEMPLATE_LOCATION,
  TEMPLATE_EXTRACT_TAGS,
  TEMPLATE_META_DATA,
  TEMPLATE_RESET_COUNTER,
  TEMPLATE_STEP,
  UUID_PREFIXES,
  VALUE_TYPES,
  CAT_CONV_APPROACH_TYPES,
  CODE_FILTER_EXTRA_OPTION,
  SPEC_GENERAL,
  TEMPLATE_CONFIGURATION,
  NAMES_CONFIG
} from "./CrawlSpecCreate.constants";

export default class CrawlParserSpec {
  // CrawlParserSpec - class get raw spec and split do different elements
  // (step / context / doc / example)
  /*
    rawSpec - object - spec from bucket
    general - array of strings - include all main info (spider_name, agency, category and etc.)
    config - object - configuration information out of steps
    categoryConversion - object - category conversion information
    steps - array of objects - include all steps and info about them
    contexts - array of objects - include all contexts and info about them
    docs - array of objects - include all docs elements and info about them
    actions - array of objects - include all actions elements and info about them
    relations - array of objects - include all relations elements and info about them
    resetCounters - array of objects - include all reset counters elements and info about them
    metaData - array of objects - include all metaData elements and info about them
    locations - array of objects - include all locations elements and info about them
    extractTags - array of objects - include all extractTags elements and info about them
    paths - array of objects - include all path (arrays of examples)
    examples - array of objects - include all examples and info about them
  */
  constructor(crawlSpec) {
    this.rawSpec = crawlSpec;
    // parse spec info
    this.general = [];
    this.config = {};
    this.categoryConversion = {};
    this.steps = [];
    this.contexts = [];
    this.documents = [];
    this.actions = [];
    this.relations = [];
    this.resetCounters = [];
    this.metaData = [];
    this.locations = [];
    this.extractTags = [];
    this.paths = [];
    this.examples = [];
  }

  parseGeneralInfo() {
    this.general = SPEC_GENERAL_SETTINGS.reduce(
      (mainInfo, item) => {
        let value = _.get(this.rawSpec, item.name, '');
        switch (item.name) {
          case SPEC_GENERAL.apiTable:
          case SPEC_GENERAL.metaTable:
            value = { label: value, value };
            break;
          default:
            console.log(value);
        }
        return { ...mainInfo, [item.name]: value };
      },
      {}
    );
  }

  getNextStep(value) {
    let step = _.get(value, 'next_step', []);
    if (!_.isArray(step)) step = [step];
    return step.map(item => ({ label: item, value: item }));
  }

  parseConfigStepInfo() {
    const specSteps = _.get(this.rawSpec, ['configurations', 'steps'], {});

    _.forOwn(specSteps, (value, key) => {
      if (key === FIRST_STEP.name) {
        this.steps.push({
          ...FIRST_STEP,
          [NAMES_STEP.NEXT_STEP]: this.getNextStep(value)
        });
      } else {
        // context
        let context = _.get(value, 'context', null);
        if (context) context = this.parseContext(context);
        // doc
        let doc = _.get(value, 'doc', null);
        if (doc) doc = this.parseDoc(doc);
        // actions
        let actions = _.get(value, 'actions', []);
        if (actions.length) actions = this.parseActions(actions);
        // relations
        let relations = _.get(value, 'create_relations', []);
        if (relations.length) relations = this.parseRelations(relations);
        // reset counter
        let resetCounter = _.get(value, 'reset_counter', null);
        if (resetCounter) resetCounter = this.parseResetCounter(resetCounter);

        const stepRaw = {
          ...TEMPLATE_STEP,
          id: `${UUID_PREFIXES.STEP}${uuidv4()}`,
          // own values
          [NAMES_STEP.NAME]: key,
          [NAMES_STEP.DESC]: _.get(value, 'description', ''),
          [NAMES_STEP.PRE_GENERATED_FULL_TEXT]: _.get(value, 'pre_generate_full_text', ''),
          [NAMES_STEP.SAVE_DOC]: _.get(value, 'save_document', true),
          [NAMES_STEP.PROCESS_RELATED_DOCUMENTS]: _.get(value, 'process_related_documents', false),
          [NAMES_STEP.IS_PARENT]: _.get(value, 'is_parent', false),
          [NAMES_STEP.CONTINUE_CRAWL]: _.get(value, 'continue_crawl', false),
          [NAMES_STEP.DEBUG]: _.get(value, 'debug', false),
          // parse values
          [NAMES_STEP.CONTEXT]: context,
          [NAMES_STEP.DOC]: doc,
          [NAMES_STEP.ACTIONS]: actions,
          [NAMES_STEP.RELATION]: relations,
          [NAMES_STEP.RESET_COUNTER]: resetCounter,
          [NAMES_STEP.NEXT_STEP]: this.getNextStep(value)
        };
        this.steps.push(stepRaw);
      }
    });
  }

  parseContext(context) {
    const contextPath = this.parsePaths(_.get(context, 'examples', []));
    const contextUUID = `${UUID_PREFIXES.CONTEXT}${uuidv4()}`;
    const formatContext = {
      ...TEMPLATE_CONTEXT,
      [NAMES_CONTEXT.CODE_FILTER]: _.get(context, 'code_filter', ''),
      [NAMES_CONTEXT.TAG_FILTER]: _.get(context, 'tag_filter', ''),
      [NAMES_CONTEXT.URL]: _.get(context, 'is_url', false),
      [NAMES_CONTEXT.IS_TEXT]: _.get(context, 'is_text', false),
      [NAMES_CONTEXT.IS_SINGLE]: _.get(context, 'is_single', false),
      [NAMES_CONTEXT.IS_SIBLING_TAG]: _.get(context, 'is_sibling_tag', false),
      [NAMES_CONTEXT.COMBINE_CONTEXT]: _.get(context, 'combine_context', false),
      [NAMES_CONTEXT.IS_PAGE_SOURCE]: _.get(context, 'is_page_source', false),
      [NAMES_CONTEXT.PATH]: contextPath,
      id: contextUUID
    };

    this.contexts.push(formatContext);
    return contextUUID;
  }

  parseActions(actions) {
    const actionsUUID = [];
    const formatActions = actions.map((action) => {
      const actionExamples = this.parseExamples(_.get(action, 'examples', []));

      const actionType = _.get(action, 'type', ACTION_TYPES.CLICK);
      const actionMethod = _.get(action, 'method', ACTION_METHODS.XPATH);

      const actionUUID = `${UUID_PREFIXES.ACTION}${uuidv4()}`;
      actionsUUID.push(actionUUID);
      return {
        ...TEMPLATE_ACTION,
        [NAMES_ACTION.METHOD]: actionMethod && { value: actionMethod, label: actionMethod },
        [NAMES_ACTION.TYPE]: actionType && { value: actionType, label: actionType },
        [NAMES_ACTION.COUNTER]: _.get(action, 'use_counter', false),
        [NAMES_ACTION.REPEAT]: _.toString(_.get(action, 'repeat', null)),
        [NAMES_ACTION.PATH]: _.toString(_.get(action, 'path', null)),
        [NAMES_ACTION.EXAMPLES]: actionExamples,
        id: actionUUID
      };
    });

    this.actions = [...this.actions, ...formatActions];
    return actionsUUID;
  }

  parseRelations(relations) {
    const relationsUUID = [];
    const formatRelations = relations.map((relation) => {
      const relationType = _.get(relation, 'relation_type', RELATION_TYPES.LANG);

      const relationUUID = `${UUID_PREFIXES.ACTION}${uuidv4()}`;
      relationsUUID.push(relationUUID);
      return {
        ...TEMPLATE_RELATION,
        [NAMES_RELATION.RELATION_TYPE]:
          relationType && { value: relationType, label: relationType },
        [NAMES_RELATION.CLEAR_EXISTING_RELATIONS]:
          _.get(relation, 'clear_existing_relations', false),
        id: relationUUID
      };
    });

    this.relations = [...this.relations, ...formatRelations];
    return relationsUUID;
  }

  parseResetCounter(resetCounter) {
    let steps = _.get(resetCounter, 'step', []);
    if (!_.isArray(steps)) steps = [steps];
    steps = steps.map(item => ({ label: item, value: item }));

    const resetCounterUUID = `${UUID_PREFIXES.RESET_COUNTER}${uuidv4()}`;
    const formatResetCounter = {
      ...TEMPLATE_RESET_COUNTER,
      [NAMES_RESET_COUNTER.STEP]: steps,
      id: resetCounterUUID
    };

    this.resetCounters.push(formatResetCounter);
    return resetCounterUUID;
  }

  parseDoc(doc) {
    const metaDataID = [];
    _.forOwn(doc, (value, key) => {
      if (key !== 'incomplete_pub_date') {
        const rawMetaData = this.parseMetaData(value, key);
        metaDataID.push(rawMetaData);
      }
    });
    const docID = `${UUID_PREFIXES.DOC}${uuidv4()}`;
    const formatDoc = {
      ...TEMPLATE_DOC,
      [NAMES_DOC.META_DATA]: metaDataID,
      [NAMES_DOC.INCOMPLETE_PUB_DATE]: _.get(doc, 'incomplete_pub_date', false),
      id: docID
    };

    this.documents.push(formatDoc);
    return docID;
  }

  parseMetaData(metaData, name) {
    let metaDataPaths;
    let metaDataExtractTags;
    let metaDataLocations;
    let defaultType;
    let defaultValue;
    if (_.get(metaData, 'examples')) {
      metaDataPaths = this.parsePaths(_.get(metaData, 'examples', []));

      if (_.get(metaData, 'extract_tags')) {
        metaDataExtractTags = this.parseExtractTags(_.get(metaData, 'extract_tags', {}));
      }

      metaDataLocations = _.get(metaData, 'locations', []);
      if (metaDataLocations.length) {
        metaDataLocations = metaDataLocations.map(item => this.parseLocations(item));
      }

      defaultType = VALUE_TYPES.NOT_USE;
      defaultValue = null;
    } else {
      metaDataPaths = [];
      metaDataExtractTags = null;
      metaDataLocations = [];
      const defaultMeta = this.getDefaultValue(metaData);
      defaultType = defaultMeta.type;
      defaultValue = defaultMeta.value;
    }
    const metaDataUUID = `${UUID_PREFIXES.META_DATA}${uuidv4(metaData)}`;

    let metaDataCodeFilter = _.get(metaData, 'code_filter', '');
    if (metaDataCodeFilter && _.isString(metaDataCodeFilter)) {
      metaDataCodeFilter = {
        value: metaDataCodeFilter,
        label: CODE_FILTER_EXTRA_OPTION.label
      };
    }

    const metaDataRaw = {
      ...TEMPLATE_META_DATA,
      id: metaDataUUID,
      [NAMES_META_DATA.NAME]: name,
      [NAMES_META_DATA.REGEX]: _.get(metaData, 'regex', ''),
      [NAMES_META_DATA.DATE_FORMAT]: _.get(metaData, 'date_format', ''),
      [NAMES_META_DATA.IS_TEXT]: _.get(metaData, 'is_text', false),
      [NAMES_META_DATA.IS_URL]: _.get(metaData, 'is_url', false),
      [NAMES_META_DATA.IS_SIBLING_TAG]: _.get(metaData, 'is_sibling_tag', false),
      [NAMES_META_DATA.IS_SIBLING_TEXT]: _.get(metaData, 'is_sibling_text', false),
      [NAMES_META_DATA.IS_SINGLE]: _.get(metaData, 'is_single', false),
      [NAMES_META_DATA.IS_DATE]: _.get(metaData, 'is_date', false),
      [NAMES_META_DATA.PARSE_PDF]: _.get(metaData, 'parse_pdf', false),
      [NAMES_META_DATA.FROM_URL]: _.get(metaData, 'from_url', false),
      [NAMES_META_DATA.IS_CURRENT_URL]: _.get(metaData, 'is_current_url', false),
      [NAMES_META_DATA.DEFAULT_VALUE]: defaultValue,
      [NAMES_META_DATA.VALUE_TYPE]: this.createSelectOption(defaultType),
      [NAMES_META_DATA.PATH]: metaDataPaths,
      [NAMES_META_DATA.EXTRACT_TAGS]: metaDataExtractTags,
      [NAMES_META_DATA.LOCATIONS]: metaDataLocations,
      [NAMES_META_DATA.CODE_FILTER]: metaDataCodeFilter,
      [NAMES_META_DATA.DAY_FIRST]: _.get(metaData, 'dayfirst', false),
      [NAMES_META_DATA.IS_TEXT_GENERATED]: _.get(metaData, 'is_text_generated', false)
    };

    this.metaData.push(metaDataRaw);
    return metaDataUUID;
  }

  parseLocations(location) {
    const locationUUID = `${UUID_PREFIXES.LOCATION}${uuidv4()}`;

    let regex = _.get(location, 'regex', []);
    if (!Array.isArray(regex) && regex) {
      regex = [regex];
    }

    const formatLocation = {
      ...TEMPLATE_LOCATION,
      [NAMES_LOCATION.PAGES]: _.get(location, 'pages', ''),
      [NAMES_LOCATION.COORDINATES_TOP]: _.get(location, ['coordinates', 'top'], 0),
      [NAMES_LOCATION.COORDINATES_BOTTOM]: _.get(location, ['coordinates', 'bottom'], 0),
      [NAMES_LOCATION.COORDINATES_LEFT]: _.get(location, ['coordinates', 'left'], 0),
      [NAMES_LOCATION.COORDINATES_RIGHT]: _.get(location, ['coordinates', 'right'], 0),
      // [NAMES_LOCATION.MARGINS]: _.get(location, 'margins', ''),
      [NAMES_LOCATION.MARGINS]: null,
      [NAMES_LOCATION.REMOVE_PREPOSITIONS]: _.get(location, 'remove_prepositions', false),
      [NAMES_LOCATION.REGEX]: regex,
      id: locationUUID
    };

    this.locations.push(formatLocation);
    return locationUUID;
  }

  parseExtractTags(extreactTags) {
    const extractTagsPaths = this.parsePaths(_.get(extreactTags, 'examples', []));
    const extractTagsUUID = `${UUID_PREFIXES.EXTRACT_TAGS}${uuidv4()}`;
    const formatExtractTag = {
      ...TEMPLATE_EXTRACT_TAGS,
      [NAMES_EXTRACT_TAGS.PATH]: extractTagsPaths,
      id: extractTagsUUID
    };

    this.extractTags.push(formatExtractTag);
    return extractTagsUUID;
  }

  parsePaths(rawPaths) {
    const pathsUUID = [];

    const objectExamples = [];
    const arrayExamples = [];
    const stringPath = [];

    rawPaths.forEach(item => {
      // logic to support custom path
      if (_.isString(item)) stringPath.push(item);
      // required to support old spec structure with simple examples arrays
      else if (_.isArray(item)) arrayExamples.push(item);
      else objectExamples.push(item);
    });

    let concatPaths = [...arrayExamples, ...stringPath];
    concatPaths = objectExamples.length ? [...concatPaths, objectExamples] : concatPaths;
    const paths = concatPaths.map(path => {
      const pathID = `${UUID_PREFIXES.PATH}${uuidv4()}`;
      pathsUUID.push(pathID);
      return {
        [NAMES_PATH.EXAMPLES]: _.isArray(path) ? this.parseExamples(path) : [],
        [NAMES_PATH.PATH]: _.isString(path) ? path : "",
        id: pathID
      };
    });
    this.paths = [...this.paths, ...paths];
    return pathsUUID;
  }

  parseExamples(rawExamples) {
    const examplesUUID = [];
    const examples = rawExamples.map(example => {
      const exampleID = `${UUID_PREFIXES.EXAMPLE}${uuidv4()}`;
      examplesUUID.push(exampleID);
      return {
        ...TEMPLATE_EXAMPLE,
        [NAMES_EXAMPLE.URL]: String(_.get(example, 'url', '')),
        [NAMES_EXAMPLE.TEXT]: String(_.get(example, 'text', '')),
        [NAMES_EXAMPLE.OCCURRENCE]: String(_.get(example, 'occurrence', 1)),
        [NAMES_EXAMPLE.EXACT_MATCH]: _.get(example, 'exact_match', false),
        id: exampleID
      };
    });
    this.examples = [...this.examples, ...examples];
    return examplesUUID;
  }

  createSelectOption = (value) => ({ value, label: _.capitalize(value) });

  getDefaultValue(value) {
    let defaultType;
    let defaultValue;
    if (_.isNull(value)) {
      defaultType = VALUE_TYPES.NULL;
      defaultValue = null;
    } else if (_.isBoolean(value)) {
      defaultType = VALUE_TYPES.BOOL;
      defaultValue = value;
    } else if (_.isNumber(value)) {
      defaultType = VALUE_TYPES.NUMBER;
      defaultValue = value;
    } else if (_.isString(value)) {
      defaultType = VALUE_TYPES.STRING;
      defaultValue = value;
    } else {
      defaultType = VALUE_TYPES.NOT_USE;
      defaultValue = null;
    }
    return {
      value: defaultValue,
      type: defaultType
    };
  }

  parseConfigCategoryConversionInfo() {
    const specCategoryConversion = _.get(this.rawSpec, ['configurations', 'category_conversion', 0]);

    if (specCategoryConversion) {
      const approach = _.get(specCategoryConversion, 'category_approach', CAT_CONV_APPROACH_TYPES.CONTAINING);
      const map = _.get(specCategoryConversion, 'conversion_map', {});
      const mapConversionArray = [];
      _.forOwn(map, (value, label) => {
        mapConversionArray.push({
          value,
          label,
          id: `${UUID_PREFIXES.CONVERSION_MAP}${uuidv4()}`
        });
      });
      const rawCategoryConversion = {
        [NAMES_CAT_CONV.DEFAULT]: _.get(specCategoryConversion, 'default_category', ''),
        [NAMES_CAT_CONV.APPROACH]: { value: approach, label: approach },
        [NAMES_CAT_CONV.MAP]: mapConversionArray
      };
      this.categoryConversion = rawCategoryConversion;
    } else {
      this.categoryConversion = null;
    }
  }

  parseConfigConfigurationInfo() {
    const fullTextPercentage = _.get(this.rawSpec, ['configurations', 'full_text_percentage'], null);

    this.config = {
      ...TEMPLATE_CONFIGURATION,
      [NAMES_CONFIG.FULL_TEXT_PERCENTAGE]: fullTextPercentage
    };
  }

  parseCrawl() {
    this.parseGeneralInfo();
    this.parseConfigStepInfo();
    this.parseConfigCategoryConversionInfo();
    this.parseConfigConfigurationInfo();
  }

  get parseSpec() {
    return {
      general: this.general,
      configuration: {
        config: this.config,
        categoryConversion: this.categoryConversion,
        steps: this.steps,
        contexts: this.contexts,
        docs: this.documents,
        actions: this.actions,
        relations: this.relations,
        resetCounters: this.resetCounters,
        metaData: this.metaData,
        locations: this.locations,
        extractTags: this.extractTags,
        paths: this.paths,
        examples: this.examples
      }
    };
  }

  set spec(crawlSpec) {
    this.rawSpec = crawlSpec;
  }
}
