import type { CommandRequestsCreationModalHandlers } from './useCommandRequestsCreationModalHandlers.types';

import { useEffect } from 'react';

export const useCommandRequestsCreationModalLifecycle = ({
  onCommandChanged
}: {
  onCommandChanged: CommandRequestsCreationModalHandlers['handleChangeCommand'];
}): void => {
  useEffect(onCommandChanged, [onCommandChanged]);
};
