import _ from "lodash";
import { useMemo } from "react";
import moment from "moment";
import {
  CAT_CONST,
  JUR_CONST,
  SOURCE_TYPES,
  SRS_CONST,
  CRL_CONST,
  TIME_SCHEME,
  SCRAP_CAT_CONST
} from './Glassdoor.constants';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const getPubType = (type) => useMemo(() => (
  [SOURCE_TYPES.NEWS_SOURCE, SOURCE_TYPES.PREMIUM].includes(type)
    ? 'news_source_id'
    : 'agency_id'
), [type]);

export const getJurInfo = jur => ({
  [JUR_CONST.NAME]: _.get(jur, 'name', null),
  [JUR_CONST.SHORT_NAME]: _.get(jur, 'short_name', null),
  [JUR_CONST.ID]: _.get(jur, 'id', null),
  [JUR_CONST.DOC_COUNT]: _.get(jur, 'document_count', 0)
});

// eslint-disable-next-line react-hooks/rules-of-hooks
export const formatJur = (items, timestamp) => useMemo(
  () => items.map(item => getJurInfo(item)),
  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [timestamp]
);

export const getSrcInfo = src => ({
  [SRS_CONST.NAME]: _.get(src, 'name', null),
  [SRS_CONST.SHORT_NAME]: _.get(src, 'short_name', null),
  [SRS_CONST.ID]: _.get(src, 'id', null),
  [SRS_CONST.STATUS]: _.get(src, 'status', null),
  [SRS_CONST.DOC_COUNT]: _.get(src, 'document_count', 0),
  [SRS_CONST.TYPE]: _.get(src, 'type', null),
  [SRS_CONST.ISSUE]: _.get(src, 'issue', false)
});

// eslint-disable-next-line react-hooks/rules-of-hooks
export const formatSrc = (items, timestamp) => useMemo(
  () => items.map(item => getSrcInfo(item)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [timestamp]
);

export const getCatInfo = src => ({
  [CAT_CONST.NAME]: _.get(src, 'name', '-'),
  [CAT_CONST.ID]: _.get(src, 'id', null),
  [CAT_CONST.DOC_COUNT]: _.get(src, 'doc_count', 0),
  [CAT_CONST.SCRAPPED_COUNT]: _.get(src, 'count_scrapped', 0)
});

// eslint-disable-next-line react-hooks/rules-of-hooks
export const formatCat = (items, timestamp) => useMemo(
  () => items.map(item => getCatInfo(item)),
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [timestamp]
);

export const getCatScrapInfo = src => ({
  [SCRAP_CAT_CONST.NAME]: _.get(src, 'name', '-'),
  [SCRAP_CAT_CONST.ID]: _.get(src, 'id', null),
  [SCRAP_CAT_CONST.JURISDICTION_ID]: _.get(src, 'jurisdiction_id', null)
});

// eslint-disable-next-line react-hooks/rules-of-hooks
export const formatScrapCat = (items, timestamp) => useMemo(
  () => items.map(item => getCatScrapInfo(item)),
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [timestamp]
);

export const formatTime = (time) => time ? moment(time).format(TIME_SCHEME) : '-';

export const getScrappCatInfo = (scrappedCategories) => {
  return _.isArray(scrappedCategories) && scrappedCategories.length
    ? scrappedCategories.map(item => getCatInfo(item))
    : [getCatInfo(null)];
};

export const getCrlInfo = src => ({
  [CRL_CONST.ID]: _.get(src, 'id', null),
  [CRL_CONST.ISSUE_IDENTIFIED]: formatTime(_.get(src, 'issue_identified', null)),
  [CRL_CONST.ISSUE_TYPE]: _.get(src, 'issue_type', null),
  [CRL_CONST.JIRA_STATUS]: _.get(src, 'jira_status', null),
  [CRL_CONST.JIRA_TICKET]: _.get(src, 'jira_ticket', null),
  [CRL_CONST.JIRA_LINK]: _.get(src, 'ticket_link', null),
  [CRL_CONST.LAST_CHECK]: formatTime(_.get(src, 'last_checked', null)),
  [CRL_CONST.ORIGINAL_ETA]: formatTime(_.get(src, 'original_ETA', null)),
  [CRL_CONST.QA_STATUS]: _.get(src, 'qa_status', null),
  [CRL_CONST.CRAWL_LINK]: _.get(src, ['crawl_info', 'crawl_link'], null),
  [CRL_CONST.SPIDER_NAME]: _.get(src, ['crawl_info', 'spider_name'], null),
  [CRL_CONST.CREATED_AT]: formatTime(_.get(src, ['crawl_info', 'created_at'], null)),
  [CRL_CONST.SCRAPPED_CATEGORY]: getScrappCatInfo(_.get(src, ['crawl_info', 'scrapped_category'], [])),
  // this fields used for badge
  [CRL_CONST.STATUS]: _.get(src, ['crawl_info', 'status'], 'idle'),
  [CRL_CONST.IS_ACTIVE]: _.get(src, ['crawl_info', 'is_active'], false)
});

// eslint-disable-next-line react-hooks/rules-of-hooks
export const formatCrl = (items, timestamp) => useMemo(
  () => items.map(item => getCrlInfo(item)),
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [timestamp]
);

export const getCrlStateColorAndLabel = status => {
  const statusLower = _.isString(status) ? status.toLowerCase() : '';
  if (statusLower.includes('ok')) {
    return { type: 'success', label: 'OK' };
  }
  if (statusLower.includes('miss')) {
    return { type: 'warning', label: 'Issue' };
  }
  return { type: 'error', label: 'Issue' };
};
