import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'admin/utils/hooks';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import { TABLE_VARIABLES } from './CrawlMonitorTool.constants';
import CrawlMonitorToolHeaderSearchModal from './CrawlMonitorToolHeaderSearchModal/CrawlMonitorToolHeaderSearchModal';
import CRAWL_ROUTES from '../../constants/routes';
import { Alert, ALERT_TYPES } from '@compliance.ai/web-components';

const CrawlMonitorToolHeader = ({
  changeFilter,
  clearSearch,
  requestCrawls,
  search,
  appliedSearch,
  show,
  toggleSearch,
  error
}) => {
  const history = useHistory();

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    changeFilter(name, value);
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') toggleSearch();
  };

  const appliedFilters = _.compact(Object.values(appliedSearch));

  const filtersWording = appliedFilters.length
    ? `Filters ${String.fromCodePoint(0x00b7)} ${appliedFilters.length}`
    : 'Filters';

  return (
    <div className="crawlMonitor_header">
      <div className="crawlMonitor_header_search_container">
        <TextInput
          className="crawlMonitor_header_multi-input"
          placeholder="Search ID, Crawler ID, or Source URL"
          onChange={handleChangesEvent}
          name={TABLE_VARIABLES.multiField}
          value={search[TABLE_VARIABLES.multiField]}
          onKeyPress={handleEnterPress}
        />
        <Button onClick={toggleSearch}>{filtersWording}</Button>
      </div>
      <div>
        <Button
          onClick={() => {
            history.push(`${CRAWL_ROUTES.crawlerTool}/new`);
          }}
        >
          Add Crawl
        </Button>
      </div>
      {error && (
        <Alert type={ALERT_TYPES.ERROR} content={error} className="adminToolErrorMessage" />
      )}
      <CrawlMonitorToolHeaderSearchModal
        clearSearch={clearSearch}
        requestCrawls={requestCrawls}
        changeFilter={changeFilter}
        handleChangesEvent={handleChangesEvent}
        search={search}
        show={show}
      />
    </div>
  );
};

CrawlMonitorToolHeader.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  requestCrawls: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  search: PropTypes.shape({}),
  appliedSearch: PropTypes.shape({}),
  show: PropTypes.bool
};

CrawlMonitorToolHeader.defaultProps = {
  search: {},
  appliedSearch: {},
  show: false
};

export default CrawlMonitorToolHeader;
