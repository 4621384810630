import {
  GET_EXPERT_CONNECTION_DATA,
  GET_EXPERTS,
  GET_AREAS_OF_EXPERTISE
} from './expertConnection.actions';

export const EC_INITIAL_STATE = {
  experts: [],
  areasOfExpertise: [],
  aoeMap: {}
};

export const expertConnection = (state = EC_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EXPERT_CONNECTION_DATA:
      return {
        ...state,
        experts: action.payload.experts,
        areasOfExpertise: action.payload.areas_of_expertise,
        aoeMap: action.payload.experts.reduce((acc, expert) => {
          for (const aoe of expert.areas_of_expertise) {
            if (acc[aoe.name]) {
              acc[aoe.name].push(expert.name);
            } else {
              acc[aoe.name] = [ expert.name ];
            }
          }
          return acc;
        }, {})
      };
    case GET_EXPERTS:
      return {
        ...state,
        experts: action.payload.experts
      };
    case GET_AREAS_OF_EXPERTISE:
      return {
        ...state,
        areasOfExpertise: action.payload.areas_of_expertise
      };
    default:
      return state;
  }
};
