import type { AnyAction } from 'redux';
import type { OrganizationReduxActions } from './useOrganizationReduxActions.types';

import * as organizationsActions from 'shared/features/organizations/organizations.actions';

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useOrganizationReduxActions = (): OrganizationReduxActions => {
  const dispatch = useDispatch();

  const fetchOrganization: OrganizationReduxActions['fetchOrganization'] = useCallback(
    async (...args: Parameters<typeof organizationsActions.fetchOrganization>) => {
      await dispatch(organizationsActions.fetchOrganization(...args) as unknown as AnyAction);
    },
    [dispatch]
  );

  return {
    fetchOrganization
  };
};
