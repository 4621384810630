import type { FC } from 'react';

import React from 'react';

import * as elements from './elements';

export const CrawlerActivity: FC = () => {
  return (
    <>
      <div className="crawlActivity">
        <elements.CrawlActivityBillboard />
        <elements.CrawlActivityTable />
      </div>
    </>
  );
};
