import type { CategoryMergeModalData } from './useCategoryMergeModalData.types';
import type { CategoryEditModalFormattedData } from './useCategoryMergeModalFormattedData.types';
import type { useCategoryMergeModalMutations } from './useCategoryMergeModalMutations';

import * as constants from '../CategoryMergeModal.constants';

import { useMemo } from 'react';

export const useCategoryMergeModalFormattedData = ({
  localState,
  mutations
}: {
  localState: CategoryMergeModalData['localState'];
  mutations: ReturnType<typeof useCategoryMergeModalMutations>;
}): CategoryEditModalFormattedData =>
  useMemo(
    () => ({
      isSubmitButtonDisabled: !localState.docCaiCategory,
      isLoading: mutations.mergeCaiCategory.isLoading,
      isMetaCategoryDisabled: localState.docCaiCategory?.type !== constants.CREATED_TYPE
    }),
    [localState.docCaiCategory, mutations.mergeCaiCategory.isLoading]
  );
