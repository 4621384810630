import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useHistory } from 'admin/utils/hooks';
import {
  createConcepts,
  getConcepts,
  updateConcepts,
  removeConcepts
} from '../../../../shared/features/concepts/concepts.actions';
import Tabs from '../../../components/Tabs';
import ConceptDetailsGeneral from './ConceptDetailsGeneral';
import {
  CONCEPT_ACTIVE_TYPES,
  CONCEPT_DETAILS,
  DELETE_CONFIRMATION_MODAL,
  CONCEPT_ATTRIBUTES
} from './ConceptDetails.constants';
import { fromRawInfoToDetails, fromDetailsToRawInfo } from './ConceptDetails.helper';
import './ConceptDetails.styles.scss';
import { setNotification, NOTIFICATION_TYPES } from '../../../components/Notification';
import ROUTES from '../../../constants/routes';
import ConceptPageStatus from './ConceptPageStatus';
import ConfirmationModal from '../../CrawlerPage/ConfirmationModal';
import {
  CONTENT_MANAGEMENT_TAB_KEYS,
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM
} from '../../ContentManagement/ContentManagement.constants';

const ConceptDetails = ({}) => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const current_user = useSelector(state => state.current_user.user);
  const conceptDetails = _.get(
    useSelector(state => state.concepts),
    ['items', 0],
    {}
  );

  const [editMode, setEditMode] = useState(isNaN(+id));
  const [errorFields, setErrorFields] = useState([]);
  const [details, setDetails] = useState(CONCEPT_DETAILS);
  const [status, setStatus] = useState(CONCEPT_ACTIVE_TYPES.DEACTIVATED);
  const [confirmationModal, showConfirmationModal] = useState(false);

  const calculateConcept = () => {
    const formatDetails = !isNaN(+id)
      ? fromRawInfoToDetails(conceptDetails)
      : fromRawInfoToDetails(details);
    setDetails(formatDetails);
    setStatus(
      formatDetails[CONCEPT_ATTRIBUTES.isActive]
        ? CONCEPT_ACTIVE_TYPES.ACTIVE
        : CONCEPT_ACTIVE_TYPES.DEACTIVATED
    );
  };

  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(calculateConcept, [id, conceptDetails.id, conceptDetails.updated_at]);

  useEffect(() => {
    // redirect to details page after record creation
    // !editMode to handle situation when there is some concept
    // already in store (navigate from other page)
    if (isNaN(+id) && conceptDetails.id && !editMode) {
      history.push(`${ROUTES.concepts}/${conceptDetails.id}`);
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptDetails.id]);

  const toggleEditMode = () => {
    if (editMode) {
      calculateConcept();
    }
    setEditMode(!editMode);
  };

  const handleChanges = (name, value) => {
    setDetails({
      ...details,
      [name]: value
    });
  };

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const saveChanges = async () => {
    const { details: conceptInfo, errors } = await fromDetailsToRawInfo(details, conceptDetails);
    conceptInfo.user = `${_.get(current_user, 'first_name')} ${_.get(current_user, 'last_name')}`;
    if (errors.length) {
      setErrorFields(errors.map(err => err.title));
      errors.forEach(error => setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR }));
      return;
    }
    setEditMode(!editMode);
    if (!isNaN(+id)) {
      dispatch(updateConcepts({ ...conceptInfo, id })).then(res => {
        if (res.id) {
          setErrorFields([]);
          dispatch(getConcepts({ id: res.id }));
        }
      });
    } else {
      dispatch(createConcepts({ ...conceptInfo, id: 0 })).then(res => {
        if (res.id) {
          setErrorFields([]);
          history.push(`${ROUTES.conceptTool}/${res.id}`);
          dispatch(getConcepts({ id: res.id }));
        }
      });
    }
  };

  const changeStatus = newStatus => {
    dispatch(updateConcepts({ id, ...newStatus.updateValue }));
    setStatus(newStatus.value);
  };

  const isNewMode = id === 'new';

  const toggleConfirmationModal = () => {
    return showConfirmationModal(!confirmationModal);
  };

  const onDelete = () => {
    dispatch(removeConcepts({ id: +id })).then(() =>
      history.push(
        `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.CONCEPTS}`,
        location.state
      )
    );
  };

  return (
    <>
      <Tabs
        className="concept-navigation_tabs"
        headClassName="concept-navigation_tabs_head"
        tabClassName="concept-navigation_tabs_tab"
        extraInfo={
          <ConceptPageStatus details={details} status={status} changeStatus={changeStatus} />
        }
      >
        <ConceptDetailsGeneral
          label={'Concept Details'}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          details={details}
          saveChanges={saveChanges}
          handleChanges={handleChanges}
          handleChangesEvent={handleChangesEvent}
          errorFields={errorFields}
          isNewMode={isNewMode}
          handleDelete={toggleConfirmationModal}
        />
      </Tabs>
      <ConfirmationModal
        show={!!confirmationModal}
        mode={DELETE_CONFIRMATION_MODAL}
        onHide={toggleConfirmationModal}
        modalAction={onDelete}
      />
    </>
  );
};

ConceptDetails.propTypes = {
  conceptDetails: PropTypes.shape({}),
  current_user: PropTypes.shape({})
};

ConceptDetails.defaultProps = {
  conceptDetails: {},
  current_user: {}
};

export default ConceptDetails;
