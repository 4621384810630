import type { BulkReprocessModalRuleProps } from '../BulkReprocessModalRule.types';

import * as constants from '../BulkReprocessModalRule.constants';
import { SelectOption } from '@compliance.ai/web-components';

export const getBulkReprocessActionsOptions = (
  props: Pick<BulkReprocessModalRuleProps, 'action'>
): SelectOption[] => {
  return constants.BULK_REPROCESS_ACTIONS_OPTIONS;
};
