import {
  SET_PRIMARY_FILTER,
  SET_ENFORCEMENT_FILTER,
  SET_JURISDICTION_FILTER
} from './filters.actions';

export const FILTER_INITIAL_STATE = {
  primaryFilter: {
    filterUrl: null,
    filterParams: null,
    defaultFilters: false
  },
  enforcementFilter: {
    filterUrl: null,
    filterParams: null,
    defaultFilters: false
  },
  jurisdictionFilter: {
    filterParams: {
      search_query: '',
      jurisdiction: [],
      topic_id: [],
      category: ['CFR', 'Statute', 'State Code', 'US Code', 'Law', 'Public Act', 'US Public Law'],
      regulation_id: [],
      act_id: [],
      published_from: null,
      published_to: null,
      order: 'desc',
      search_sort: 'publication_date',
      summary_page: 'summary',
      skip_jurisdiction_analyser_fields: true,
      currentConcept: null,
      currentMetric: null
    },
    defaultFilters: false
  }
};

export const filters = (state = FILTER_INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRIMARY_FILTER:
      return {
        ...state,
        primaryFilter: action.payload
      };
    case SET_ENFORCEMENT_FILTER:
      return {
        ...state,
        enforcementFilter: action.payload
      };
    case SET_JURISDICTION_FILTER:
      return {
        ...state,
        jurisdictionFilter: action.payload
      };
    default:
      return state;
  }
};
