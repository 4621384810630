import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { api_getGlassdoor } from 'shared/features/glassdoor/glassdoor.api';
import Typography from '../../../../components/Typography';
import Spinner from '../../../../components/spinner';
import CrawlStatus from '../../../CrawlMonitorTool/CrawlMonitorTable/BodyCrawlStatus';
import { CRL_CONST, CAT_CONST, CRL_STATUS, SCRAP_CAT_CONST } from '../Glassdoor.constants';
import { formatCrl, getCrlStateColorAndLabel } from '../Glassdoor.helper';
import Info from './GlassdoorTableComponents/Info';
import Badge from '../../../../components/Badge';

const ERROR = 'Crawl QA Upload Failed';
const NOT_FOUND = 'Crawl QA for this Publisher and CAI Category not found';

const GlassdoorCrawlQA = ({ scrapedCat, artifacts, openPub }) => {
  const [load, setLoad] = useState(false);
  const [crawlQA, setCrawlQA] = useState([]);
  const [count, setCount] = useState(0);
  const [timestamp, setTimestamp] = useState('time');
  const [error, setError] = useState('');

  const request = async () => {
    await setLoad(true);
    await setError('');
    try {
      const response = await api_getGlassdoor({
        glassdoor: true,
        // get_all: true,
        limit: 15,
        offset: crawlQA.length,
        scraped_category_id: scrapedCat[SCRAP_CAT_CONST.ID],
        ...artifacts
      });
      const items = _.get(response, 'items', []);
      const countItems = _.get(response, 'count', []);
      if (items) {
        await setCrawlQA([...crawlQA, ...items]);
        await setCount(countItems);
        await setTimestamp(Date().toString());
      }
    } catch {
      await setError(ERROR);
    } finally {
      await setLoad(false);
    }
  };

  useEffect(() => {
    if (scrapedCat[CAT_CONST.ID] && openPub) {
      request();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrapedCat[CAT_CONST.ID], openPub]);

  const formatCrawlQA = formatCrl(crawlQA, timestamp);

  return (
    <div className="glassdoor_table glassdoor_table_crawl-qa">
      {formatCrawlQA.map((qa, index) => (
        <GlassdoorCrawl
          key={qa[CRL_CONST.ID]}
          showCAI={!index}
          scrapCategory={scrapedCat}
          qa={qa}
        />
      ))}
      {load && (
        <Info>
          <Spinner />
        </Info>
      )}
      {error && (
        <Info>
          <Typography bold variant="p" color="red">
            {error}
          </Typography>
        </Info>
      )}
      {!formatCrawlQA.length && !load && !error && (
        <Info>
          <Typography bold variant="p" color="yellow">
            {NOT_FOUND}
          </Typography>
        </Info>
      )}
      {count > crawlQA.length && !load && (
        <Info>
          <Typography onClick={request} bold variant="p" color="blue">
            Load More QA
          </Typography>
        </Info>
      )}
    </div>
  );
};

GlassdoorCrawlQA.propTypes = {
  scrapedCat: PropTypes.shape({
    id: PropTypes.number
  }),
  artifacts: PropTypes.shape({
    jurisdiction_id: PropTypes.number
  }),
  openPub: PropTypes.bool
};

GlassdoorCrawlQA.defaultProps = {
  scrapedCat: {},
  artifacts: {},
  openPub: false
};

const GlassdoorCrawl = ({ qa }) => (
  <div className="table-row crawl-qa-table_row">
    <div className={'crawler-name'}>
      <a href={qa[CRL_CONST.CRAWL_LINK]} target="_blank" rel="noopener noreferrer">
        <Typography variant="p">{qa[CRL_CONST.SPIDER_NAME]}</Typography>
      </a>
    </div>
    <CrawlStatus crawl={qa} />
    <CrawlQARow info={qa[CRL_CONST.LAST_CHECK]} />
    <CrawlQARow info={qa[CRL_CONST.CREATED_AT]} />
    <CrawlQABadge state={qa[CRL_CONST.ISSUE_TYPE]} />
    <CrawlQARow
      info={qa[CRL_CONST.ISSUE_TYPE] === CRL_STATUS.OK ? '-' : qa[CRL_CONST.ISSUE_TYPE]}
    />
    <CrawlQARow info={qa[CRL_CONST.ISSUE_IDENTIFIED]} />
    <CrawlQARow info={qa[CRL_CONST.ORIGINAL_ETA]} />
    <div>
      {qa[CRL_CONST.JIRA_LINK] ? (
        <a href={qa[CRL_CONST.JIRA_LINK]}>
          <Typography variant="p">{qa[CRL_CONST.JIRA_TICKET]}</Typography>
        </a>
      ) : (
        <Typography variant="p">{'-'}</Typography>
      )}
    </div>
    <CrawlQARow info={qa[CRL_CONST.JIRA_STATUS]} />
  </div>
);

GlassdoorCrawl.propTypes = {
  qa: PropTypes.shape({
    id: PropTypes.number
  }),
  scrapCategory: PropTypes.shape({
    id: PropTypes.number
  })
};

GlassdoorCrawl.defaultProps = {
  qa: {},
  scrapCategory: {}
};

const CrawlQARow = ({ info }) => (
  <div>
    <Typography variant="p">{info || '-'}</Typography>
  </div>
);

CrawlQARow.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
};

CrawlQARow.defaultProps = {
  info: null
};

const CrawlQABadge = ({ state }) => {
  const { type, label } = getCrlStateColorAndLabel(state);
  return (
    <div>
      <Badge type={type} size="small" className="source-badge">
        {label}
      </Badge>
    </div>
  );
};

CrawlQABadge.propTypes = {
  state: PropTypes.string
};

CrawlQABadge.defaultProps = {
  state: ''
};

export default GlassdoorCrawlQA;
