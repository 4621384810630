import { BASE_API_OPTIONS, fetch } from 'fetch';
import type * as apiTypes from './user.apiV2.types';

export const fetchGetAllUsers: apiTypes.FetchUsersData = async params => {
  return fetch({
    url: '/users',
    dataType: 'Users',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_APP,
    params
  });
};
