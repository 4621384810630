import React from 'react';
import {
  Table,
  Button,
  Pagination,
  Form,
  FormGroup,
  ControlLabel,
  Col,
  FormControl
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';

import { fetchAggregatedAnnotations } from 'shared/features/annotations/annotations.actions';
import AggregatedAnnotation from './AggregatedAnnotation';

class AggregatedAnnotations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aggregated_annotations: [],
      sortAsc: true,
      field: '',
      limit: 20,
      count: 0,
      topicIdToDisplay: 1, //1 is lending,
      offset: 0,
      id_filter_tmp: '',
      id_filter: ''
    };
  }

  componentDidMount() {
    this.props
      .fetchAggregatedAnnotations(this.state.topicIdToDisplay)
      .then(() => this.forceUpdate());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      aggregated_annotations: nextProps.aggregated_annotations.aggregated_annotations,
      total: nextProps.aggregated_annotations.total
    });
  }

  sortAggregatedAnnotations = field => {
    const sort = {
      [`sorting_${field}`]: this.state.sortAsc ? 'ascending' : 'descending'
    };
    this.props.fetchAggregatedAnnotations(this.state.topicIdToDisplay, sort);
    this.setState(state => ({ sortAsc: !state.sortAsc }));
  };

  sortIcons = field => {
    if (
      field === 'doc_id'
      || field === 'is_in_agreement'
      || field === 'gold_difficulty'
      || field === 'arbitrary_tags'
      || field === 'notes'
    ) {
      return (
        <span
          className="aggregatedAnnotationsSortIcons"
          onClick={() => this.sortAggregatedAnnotations(field)}
        >
          <i className="material-icons">keyboard_arrow_down</i>
        </span>
      );
    }
    return null;
  };

  handlePageChange = eventKey => {
    this.setState(state => {
      const offset = state.limit * (eventKey - 1);
      return {
        page: eventKey,
        offset
      };
    }, () => {
      const offset = this.state.offset;
      this.props
        .fetchAggregatedAnnotations(this.state.topicIdToDisplay, { offset })
        .then(() => this.forceUpdate());
    });
  };

  handleDocFilter = doc_id => {
    this.props
      .fetchAggregatedAnnotations(this.state.topicIdToDisplay, { filter_doc_id: doc_id })
      .then(() => this.forceUpdate());
  };

  handleSelectTopicChange = topic => {
    this.setState({ topicIdToDisplay: topic.value });
    this.props.fetchAggregatedAnnotations(topic.value).then(() => this.forceUpdate());
  };

  filter(data) {
    if (this.state.id_filter.length === 0) {
      return data;
    }
    return data.filter(item => {
      return item.doc_id.toString() === this.state.id_filter;
    });
  }

  render() {
    const fields = [
      'doc_id',
      'doc_title',
      'annotation_task_topic_group_name',
      'judges',
      'is_in_agreement',
      'gold annotation',
      'gold_difficulty',
      'arbitrary_tags',
      'notes'
    ];
    const { haveEditAccess } = this.props;
    return (
      <div className="aggregatedAnnotations">
        <Form horizontal>
          <FormGroup bsSize="small">
            <Col sm={8}>
              <h1 id="menu" className="aggregatedAnnotations-head">
                Aggregated Annotations
              </h1>
            </Col>
            <Col sm={3}>
              <ControlLabel>Document ID</ControlLabel>
              <FormControl
                type="text"
                value={this.state.id_filter_tmp}
                onChange={e => this.setState({ id_filter_tmp: e.target.value })}
              />
            </Col>
            <Col sm={1}>
              <Button
                bsStyle="primary"
                style={{ fontSize: '10px', marginTop: '21px', height: '33px', paddingTop: '8px' }}
                bsSize="xsmall"
                onClick={() => this.handleDocFilter(this.state.id_filter_tmp)}
              >
                Search
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <Table>
          <thead>
            <tr className="aggregatedAnnotations-head">
              {fields.map((field, i) => {
                if (field === 'annotation_task_topic_group_description') {
                  return (
                    <th key={i}>
                      topic_group_desc
                      {this.sortIcons(field)}
                    </th>
                  );
                }
                if (field === 'notes') {
                  return (
                    <th key={i} className="notes-inc">
                      notes
                      {this.sortIcons(field)}
                    </th>
                  );
                }
                if (field === 'annotation_task_topic_group_name') {
                  const extendedTopics = [
                    ...this.props.sources.defaultTopics
                  ];
                  return (
                    <th key={i}>
                      <Select
                        className="topicFilter"
                        options={extendedTopics.map(topic => ({
                          value: topic.id,
                          label: topic.label
                        }))}
                        value={this.state.topicIdToDisplay}
                        onChange={obj => this.handleSelectTopicChange(obj)}
                      />
                      topic_group_name
                      {this.sortIcons(field)}
                    </th>
                  );
                }
                return (
                  <th key={i}>
                    {field}
                    {this.sortIcons(field)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.filter(this.state.aggregated_annotations).map((annotation, i) => (
              <AggregatedAnnotation
                {...annotation}
                fields={fields}
                topicIdToDisplay={this.state.topicIdToDisplay}
                key={i}
                offset={this.state.offset}
                haveEditAccess={haveEditAccess}
              />
            ))}
          </tbody>
        </Table>
        {this.state.id_filter.length === 0 && (
          <Pagination
            bsSize="small"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            items={Math.ceil(this.state.total / this.state.limit)}
            maxButtons={5}
            activePage={this.state.page}
            onSelect={this.handlePageChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    viewer: state.current_user,
    aggregated_annotations: state.aggregated_annotations,
    ...state.sources
  };
};

const ReduxAggregatedAnnotations = connect(mapStateToProps, { fetchAggregatedAnnotations })(
  AggregatedAnnotations
);

export default ReduxAggregatedAnnotations;
