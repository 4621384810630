import type { CrawlStatusSelectProps } from '../CrawlStatusSelect.types';
import type { CrawlStatusSelectData } from './useCrawlStatusSelectData.types';
import type { CrawlStatusSelectHandlers } from './useCrawlStatusSelectHandlers.types';

export const useCrawlStatusSelectHandlers = ({
  props: { changeCrawlState },
  localActions
}: {
  props: Pick<CrawlStatusSelectProps, 'changeCrawlState'>;
  localActions: CrawlStatusSelectData['localActions'];
}): CrawlStatusSelectHandlers => {
  const handleTriggerClick: CrawlStatusSelectHandlers['handleTriggerClick'] = () =>
    localActions.setIsDropdownOpen(isOpen => !isOpen);

  const handleDropdownClose: CrawlStatusSelectHandlers['handleDropdownClose'] = () =>
    localActions.setIsDropdownOpen(false);

  const handleCrawlStateOptionClick: CrawlStatusSelectHandlers['handleCrawlStateOptionClick'] = ([
    option
  ]) => {
    changeCrawlState(option);
    localActions.setIsDropdownOpen(false);
  };

  return {
    handleTriggerClick,
    handleDropdownClose,
    handleCrawlStateOptionClick
  };
};
