import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const defaultStyles = {
  h0: 'typography_h0',
  h1: 'typography_h1',
  h2: 'typography_h2',
  h3: 'typography_h3',
  h4: 'typography_h4',
  h5: 'typography_h5',
  title: 'typography_title',
  subtitle: 'typography_subtitle',
  body: 'typography_body',
  p: 'typography_p',
  label: 'typography_label',
  tag: 'typography_tag'
};

const defaultColors = {
  black: 'typography_black',
  blue: 'typography_blue',
  transparent_black: 'typography_transparent-black',
  white: 'typography_white',
  gray: 'typography_gray',
  red: 'typography_red',
  yellow: 'typography_yellow',
  darkGrey: 'typography_dark-grey',
  inherit: 'typography_inherit'
};

function Typography({
  children,
  variant,
  className,
  uppercase,
  italic,
  bold,
  color,
  name,
  hidden,
  onClick
}) {
  return (
    <span
      onClick={onClick}
      name={name}
      className={cn(
        'typography',
        defaultStyles[variant],
        {
          typography_uppercase: uppercase,
          typography_bold: bold,
          typography_italic: italic,
          typography_hidden: hidden
        },
        defaultColors[color],
        className
      )}
    >
      {children}
    </span>
  );
}

Typography.propTypes = {
  variant: PropTypes.oneOf(Object.keys(defaultStyles)).isRequired,
  color: PropTypes.oneOf(Object.keys(defaultColors)),
  uppercase: PropTypes.bool,
  italic: PropTypes.bool,
  bold: PropTypes.bool,
  hidden: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.node,
  onClick: PropTypes.func
};

Typography.defaultProps = {
  uppercase: false,
  italic: false,
  bold: false,
  hidden: false,
  color: 'inherit'
};

export default Typography;
