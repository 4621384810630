import type { FC } from 'react';
import type { CategoriesFiltersProps } from './CategoriesFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useCategoriesFiltersAsyncLoaders,
  useCategoriesFiltersConfig,
  useCategoriesFiltersHandlers
} from './hooks';

import './_categories-filters.scss';

export const CategoriesFilters: FC<CategoriesFiltersProps> = ({ onSubmit }) => {
  const filterAsyncLoaders = useCategoriesFiltersAsyncLoaders();

  const filterConfig = useCategoriesFiltersConfig({
    filterAsyncLoaders
  });

  const handlers = useCategoriesFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search Cai Name"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="cai-categories-filters__container"
    />
  );
};
