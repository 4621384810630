import type { ConceptsHandlers } from './useConceptsHandlers.types';
import type { ConceptsData } from './useConceptsData.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as conceptDataApi from 'shared/features/concepts/concepts.apiV2';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useConceptsHandlers.helpers';

import { useNavigate } from 'react-router-dom';

export const useConceptsHandlers = ({
  localState,
  localActions
}: {
  localState: ConceptsData['localState'];
  localActions: ConceptsData['localActions'];
}): ConceptsHandlers => {
  const navigate = useNavigate();
  const handleConceptsFetch: ConceptsHandlers['handleConceptsFetch'] = async (params, options) => {
    try {
      const conceptsResponse = await conceptDataApi.fetchConceptsData({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      return {
        results: conceptsResponse.items,
        count: conceptsResponse.count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleFiltersSubmit: ConceptsHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleCreateNewConcept: ConceptsHandlers['handleCreateNewConcept'] = () => {
    navigate(`${ROUTES.conceptTool}/new`);
  };

  return {
    handleConceptsFetch,
    handleFiltersSubmit,
    handleCreateNewConcept
  };
};
