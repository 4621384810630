import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoIdToken,
  CognitoUserSession,
  CognitoAccessToken
} from 'amazon-cognito-identity-js';
import { userPoolId, clientId } from 'shared/config';
import { StatusCodes } from 'http-status-codes';

import * as errorUtils from 'shared/utils/errors';

export const GENERIC_NO_USER_MESSAGE = 'Current user is not detected';

export const AUTH_TYPES = {
  USER_PASSWORD_AUTH: 'USER_PASSWORD_AUTH'
};

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId
};

const userPool = new CognitoUserPool(poolData);

const parseError = error => {
  return error.message || JSON.stringify(error);
};

const createUserAttributes = attributes => {
  return Object.entries(attributes).map(([key, value]) => {
    return new CognitoUserAttribute({
      Name: key,
      Value: value
    });
  });
};

const signUpCallback = (resolve, reject) => (error, result) => {
  if (error) {
    reject(parseError(error));
  } else {
    resolve({ cognitoUser: result.user });
  }
};

export const signUp = ({ username, password, attributes = {} }) => {
  return new Promise((resolve, reject) => {
    const attributesList = createUserAttributes(attributes);

    userPool.signUp(username, password, attributesList, null, signUpCallback(resolve, reject));
  });
};

const confirmRegisteredUserCallback = (resolve, reject) => error => {
  if (error) {
    reject(parseError(error));
  } else {
    resolve();
  }
};

export const confirmRegisteredUser = ({ username, code }) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, confirmRegisteredUserCallback(resolve, reject));
  });
};

const signInSuccessCallback = resolve => result => {
  const accessToken = result.getAccessToken().getJwtToken();
  const idToken = result.getIdToken().getJwtToken();
  const refreshToken = result.refreshToken.getToken();

  resolve({
    accessToken,
    idToken,
    refreshToken
  });
};

const signInErrorCallback = reject => error => {
  reject(parseError(error));
};

export const signIn = ({ username, password }) => {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    });

    const userData = {
      Username: username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.setAuthenticationFlowType(AUTH_TYPES.USER_PASSWORD_AUTH);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: signInSuccessCallback(resolve),
      onFailure: signInErrorCallback(reject)
    });
  });
};

export const signInUsingTokens = ({ accessToken, idToken, refreshToken }) => {
  return new Promise(resolve => {
    const { email } = new CognitoIdToken({ IdToken: idToken }).decodePayload();

    const userData = {
      Username: email,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    const signInSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({
        IdToken: idToken
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: accessToken
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: refreshToken
      })
    });

    cognitoUser.setSignInUserSession(signInSession);

    resolve({
      accessToken,
      idToken,
      refreshToken
    });
  });
};

export const signOut = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(GENERIC_NO_USER_MESSAGE);
    } else {
      cognitoUser.signOut();
      resolve();
    }
  });
};

const signOutGlobalSuccessCallback = resolve => result => {
  resolve(result);
};

const signOutGlobalErrorCallback = reject => error => {
  reject(parseError(error));
};

export const signOutGlobal = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(GENERIC_NO_USER_MESSAGE);
    } else {
      cognitoUser.globalSignOut({
        onSuccess: signOutGlobalSuccessCallback(resolve),
        onFailure: signOutGlobalErrorCallback(reject)
      });
    }
  });
};

const passwordResetSuccessCallback = resolve => result => {
  resolve(result);
};

const passwordResetErrorCallback = reject => error => {
  reject(parseError(error));
};

export const passwordReset = username => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: passwordResetSuccessCallback(resolve),
      onFailure: passwordResetErrorCallback(reject)
    });
  });
};

const confirmNewPasswordSuccessCallback = resolve => result => {
  resolve(result);
};

const confirmNewPasswordErrorCallback = reject => error => {
  reject(parseError(error));
};

export const confirmNewPassword = ({ email, verificationCode, newPassword }) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: email,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: confirmNewPasswordSuccessCallback(resolve),
      onFailure: confirmNewPasswordErrorCallback(reject)
    });
  });
};

const changePasswordCallback = (resolve, reject) => (error, result) => {
  if (error) {
    reject(parseError(error));
  } else {
    resolve(result);
  }
};

export const changePassword = ({ oldPassword, newPassword }) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    /**
     *
     * Note: the call of getSession is required. For seme mysterious reason,
     * the SDK that we are using requires .getSession to be called before the change of password.
     *
     * Issue: https://github.com/amazon-archives/amazon-cognito-identity-js/issues/35
     *
     */
    cognitoUser.getSession(error => {
      if (error) {
        reject(parseError(error));
      }

      cognitoUser.changePassword(oldPassword, newPassword, changePasswordCallback(resolve, reject));
    });
  });
};

const updateUserAttributesCallback = (resolve, reject) => (error, result) => {
  if (error) {
    reject(parseError(error));
  } else {
    resolve(result);
  }
};

export const updateUserAttributes = attributes => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    const updatedAttributes = createUserAttributes(attributes);

    cognitoUser.updateAttributes(updatedAttributes, updateUserAttributesCallback(resolve, reject));
  });
};

const getUserAttributesCallback = (resolve, reject) => (error, result) => {
  if (error) {
    reject(parseError(error));
  } else {
    const attributes = Object.fromEntries(
      result.map(attribute => [attribute.getName(), attribute.getValue()])
    );
    resolve(attributes);
  }
};

export const getUserAttributes = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.getUserAttributes(getUserAttributesCallback(resolve, reject));
  });
};

const deleteUserAttributesCallback = (resolve, reject) => (error, result) => {
  if (error) {
    reject(parseError(error));
  } else {
    resolve(result);
  }
};

export const deleteUserAttributes = attributesNames => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.deleteAttributes(attributesNames, deleteUserAttributesCallback(resolve, reject));
  });
};

const completeNewPasswordChallengeSuccessCallback = resolve => result => {
  resolve(result);
};

const completeNewPasswordChallengeErrorCallback = reject => error => {
  reject(parseError(error));
};

export const completeNewPasswordChallenge = ({ newPassword, userAttributes }) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: completeNewPasswordChallengeSuccessCallback(resolve),
      onFailure: completeNewPasswordChallengeErrorCallback(reject)
    });
  });
};

const getSessionCallback = (resolve, reject) => (error, session) => {
  if (error) {
    reject(parseError(error));
  } else {
    resolve(session);
  }
};

export const getSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(GENERIC_NO_USER_MESSAGE);
    } else {
      cognitoUser.getSession(getSessionCallback(resolve, reject));
    }
  });
};

const refreshSessionCallback = (resolve, reject) => (error, result) => {
  if (error) {
    reject(parseError(error));
  } else {
    const accessToken = result.getAccessToken().getJwtToken();
    const idToken = result.getIdToken().getJwtToken();
    const refreshToken = result.refreshToken.getToken();

    resolve({
      accessToken,
      idToken,
      refreshToken
    });
  }
};

export const refreshSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(
        new errorUtils.NetworkError({
          type: 'Network error',
          message: GENERIC_NO_USER_MESSAGE,
          status: StatusCodes.BAD_REQUEST
        })
      );
    } else {
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
        } else {
          const token = new CognitoRefreshToken({
            RefreshToken: session.refreshToken && session.refreshToken.getToken()
          });

          cognitoUser.refreshSession(token, refreshSessionCallback(resolve, reject));
        }
      });
    }
  });
};

const deleteUserCallback = (resolve, reject) => (err, result) => {
  if (err) {
    reject(parseError(err));
  } else {
    resolve(result);
  }
};

export const deleteUser = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(GENERIC_NO_USER_MESSAGE);
    }

    cognitoUser.deleteUser(deleteUserCallback(resolve, reject));
  });
};
