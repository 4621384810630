import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { COLORS } from 'app/constants/Style';

const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    .jurispect-MuiFormLabel-root {
      color: ${COLORS.darkGrey};
      font-size: 11px;
      &:not(.jurispect-MuiInputLabel-shrink) {
        padding-left: 10px;
        font-size: 14px;
      }
    }
    .jurispect-MuiInputBase-root {
      height: 38px;
      margin-top: ${props => (props.label ? '12px' : '0px')};
      font-size: 14px;
      border: solid 1px #cccccc;
      input {
        padding-left: 10px;
      }

      &:hover:before,
      &:hover:after,
      &:before,
      &:after {
        border: none;
      }
    }
  }
`;

function TextFieldSharedComponent({ value, type, onChange, disabled, label, placeholder }) {
  return (
    <StyledTextField
      value={value}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      label={label}
    />
  );
}

TextFieldSharedComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

TextFieldSharedComponent.defaultProps = {
  value: '',
  placeholder: null,
  disabled: false,
  label: '',
  type: undefined,
  onChange: null
};

export default TextFieldSharedComponent;
