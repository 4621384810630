import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  authenticateUser as reduxAuthenticateUser,
  signUpUser as reduxSignUpUser,
  refreshSessionTokens as reduxRefreshSessionTokens,
  changePassword as reduxChangePassword,
  signOut as reduxSignOut,
  confirmNewPassword as reduxConfirmNewPassword,
  sendPasswordResetEmail as reduxSendPasswordResetEmail,
  setTokens as reduxSetTokens
} from 'shared/features/auth/auth.actions';

export const useAuthReduxActions = () => {
  const dispatch = useDispatch();

  const setTokens = useCallback(
    tokens => {
      dispatch(reduxSetTokens(tokens));
    },
    [dispatch]
  );

  const authenticateUser = useCallback(
    async ({ email, password }) => {
      return dispatch(
        reduxAuthenticateUser({
          email,
          password
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const signUpUser = useCallback(
    async ({ email, password, orgInvitationToken }) => {
      return dispatch(
        reduxSignUpUser({
          email,
          password,
          orgInvitationToken
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const refreshSessionTokens = useCallback(async () => {
    return dispatch(reduxRefreshSessionTokens()).then(unwrapResult);
  }, [dispatch]);

  const changePassword = useCallback(
    async ({ oldPassword, newPassword }) => {
      return dispatch(
        reduxChangePassword({
          oldPassword,
          newPassword
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const confirmNewPassword = useCallback(
    async ({ email, verificationCode, newPassword }) => {
      return dispatch(
        reduxConfirmNewPassword({
          email,
          verificationCode,
          newPassword
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const sendPasswordResetEmail = useCallback(
    async ({ email }) => {
      return dispatch(
        reduxSendPasswordResetEmail({
          email
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    await dispatch(reduxSignOut()).then(unwrapResult);
  }, [dispatch]);

  return {
    setTokens,
    authenticateUser,
    signUpUser,
    refreshSessionTokens,
    changePassword,
    confirmNewPassword,
    sendPasswordResetEmail,
    signOut
  };
};
