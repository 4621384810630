import Select from '@material-ui/core/Select';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MMenuItem from '../MenuItem/mMenuItem';
import MTypography from "../Typography/mTypography";
import { colors } from "../colors/colors";

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    alignItems: 'center'
  },
  addPadding: {
    paddingLeft: 5
  },
  error: {
    border: `1px solid ${colors.RED_TOMATO}`
  },
  defaultLabelStyle: {
    fontSize: 14,
    color: colors.GREY_DARK
  }
});

const MSelect = ({
  className,
  desiredProperty,
  disabled,
  disablePlaceholder,
  disableUnderline,
  error,
  fullWidth,
  inputProps,
  label,
  labelClass,
  labelId,
  listItemIconClasses,
  menuItemClasses,
  name,
  onChange,
  options,
  selectClass,
  selectClasses,
  shrink,
  topLabel,
  type,
  value,
  valueItemClass,
  variant,
  wrapperClass,
  ...restProps
}) => {
  const classes = useStyles();
  const onHandleChange = data => {
    onChange(data);
  };
  return (
    <FormControl
      fullWidth={fullWidth}
      error={error}
      className={classNames(wrapperClass)}
    >
      <MTypography
        id={labelId}
        shrink={shrink}
        className={labelClass || undefined}
      >
        {label}
      </MTypography>
      <div className={classNames(className || undefined, error ? classes.error : undefined)}>
        <Select
          className={selectClass || undefined}
          labelId={labelId}
          variant={variant}
          name={name}
          type={type}
          fullWidth={fullWidth}
          value={value || ''}
          disabled={disabled}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          renderValue={(renderedValue) => {
            return (
              <div className={classNames(valueItemClass)}>
                {
                  renderedValue
                    && (
                      <>
                        <div>
                          {renderedValue.icon}
                        </div>
                        <div className={classes.addPadding}>
                          {renderedValue.label || renderedValue}
                        </div>
                      </>
                    )
                }
              </div>
            );
          }}
          disableUnderline={disableUnderline || true}
          {...restProps}
        >
          <div>
            {!disablePlaceholder && (
              <MMenuItem value="" disabled onClick={() => {}}>
                <em>Please Select</em>
              </MMenuItem>
            )}
            {options.map(view => (
              view
                && (
                  <MMenuItem
                    key={view.value || view}
                    value={view}
                    classes={menuItemClasses}
                    onClick={() => onHandleChange(view)}
                  >
                    {
                  view.icon
                    ? (
                      <ListItemIcon
                        className={
                          classNames(listItemIconClasses
                          || classes.listItem, classes.defaultLabelStyle)
                        }
                      >
                        <div>
                          {view.icon}
                        </div>
                        <div className={classes.addPadding}>
                          {view.label}
                        </div>
                      </ListItemIcon>
                    )
                    :(
                      <div className={
                      classNames(listItemIconClasses
                        || classes.listItem, classes.defaultLabelStyle)
                    }
                      >
                        {(view && typeof view === "object")
                          ? (view.name || view.label)
                          : view
                        }
                      </div>
                    )
                }
                  </MMenuItem>
                )
            ))}
          </div>
        </Select>
      </div>
    </FormControl>
  );
};


MSelect.propTypes = {
  desiredProperty: PropTypes.string,
  disabled: PropTypes.bool,
  disablePlaceholder: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  labelId: PropTypes.number,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  listItemIconClasses: PropTypes.string,
  menuItemClasses: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectClass: PropTypes.string,
  selectClasses: PropTypes.string,
  shrink: PropTypes.string,
  topLabel: PropTypes.string,
  type: PropTypes.string,
  valueItemClass: PropTypes.string,
  variant: PropTypes.string,
  wrapperClass: PropTypes.string
};

MSelect.defaultProps = {
  desiredProperty: '',
  disabled: false,
  disablePlaceholder: false,
  disableUnderline: false,
  error: false,
  fullWidth: false,
  labelId: null,
  label: '',
  labelClass: '',
  listItemIconClasses: '',
  menuItemClasses: '',
  name: '',
  selectClass: '',
  selectClasses: '',
  shrink: 'false',
  topLabel: '',
  type: '',
  valueItemClass: '',
  variant: 'standard',
  wrapperClass: ''
};


export default MSelect;
