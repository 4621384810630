import type { ComponentProps } from 'react';
import type { EventsTableHandlers } from './useEventsTableHandlers.types';

import * as uiLib from '@compliance.ai/web-components';
import * as documentEventsConstants from 'shared/features/documentEvents/documentEvents.constants';
import * as dateConstants from 'admin/constants/date';

export const useEventsTableDataSelector = () => {
  return (
    data: Awaited<ReturnType<EventsTableHandlers['handleDataFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => ({
        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.ID]:
          row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.ID],

        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.CREATED_AT]: {
          value: row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.CREATED_AT],
          passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
          passedTimezone: uiLib.TIMEZONES.UTC,
          format: uiLib.DATE_FORMATS.API_DATE_TIME
        } as uiLib.DateCellValue,

        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.UPDATED_AT]: {
          value: row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.UPDATED_AT],
          passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
          passedTimezone: uiLib.TIMEZONES.UTC,
          format: uiLib.DATE_FORMATS.API_DATE_TIME
        } as uiLib.DateCellValue,

        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.PREV_VALUE]:
          row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.PREV_VALUE],

        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.NEW_VALUE]:
          row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.NEW_VALUE],

        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.NOTES]:
          row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.NOTES],

        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.DETAILS]:
          row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.DETAILS],

        [documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.DOC_ATTRIBUTE_NAME]:
          row[documentEventsConstants.DOCUMENT_EVENT_ATTRIBUTE.DOC_ATTRIBUTE_NAME]
      }))
    };
  };
};
