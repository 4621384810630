import {
  RECEIVE_SENTENCE_MODEL,
  CLEAR_SENTENCE_MODEL
} from './sentenceModel.actions';

export const getInitialSentenceModelState = () => null;

export const sentenceModel = (state = getInitialSentenceModelState(), action) => {
  switch (action.type) {
    case RECEIVE_SENTENCE_MODEL: {
      return action.sentenceModel;
    }
    case CLEAR_SENTENCE_MODEL: {
      return null;
    }
    default:
      return state;
  }
};
