import {
  api_subscribe,
  api_fetchSubscriptions,
  api_updateUserSubscription,
  api_updateUserSubscriptionByUserId,
  api_fetchAllSubscriptions,
  api_unsubscribeBasicUser
} from './subscriptions.api';
import {getUserAuthenticatedState} from "../auth/auth.selectors";

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED';
export const REQUEST_SUBSCRIPTIONS = 'REQUEST_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';
export const REQUEST_ALL_SUBSCRIPTIONS = 'REQUEST_ALL_SUBSCRIPTIONS';
export const RECEIVE_ALL_SUBSCRIPTIONS = 'RECEIVE_ALL_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const SUBSCRIPTION_UPDATED = 'SUBSCRIPTION_UPDATED';

function requestAllSubscriptions() {
  return {
    type: REQUEST_ALL_SUBSCRIPTIONS
  };
}

function receiveAllSubscriptions(json) {
  return {
    type: RECEIVE_ALL_SUBSCRIPTIONS,
    all_subscriptions: json.all_subscriptions
  };
}

function updateSubscription(id, params) {
  return {
    type: UPDATE_SUBSCRIPTION,
    id,
    params
  };
}

function subscriptionUpdated(json) {
  return {
    type: SUBSCRIPTION_UPDATED,
    subscription: json
  };
}

function createSubscription() {
  return {
    type: CREATE_SUBSCRIPTION
  };
}

function subscriptionCreated() {
  return {
    type: SUBSCRIPTION_CREATED
  };
}

export function subscribe(data) {
  return function doFetch(dispatch) {
    dispatch(createSubscription());
    return api_subscribe(data)
      .then(response => {
        dispatch(subscriptionCreated());
        return response;
      });
  };
}

function requestSubscriptions() {
  return {
    type: REQUEST_SUBSCRIPTIONS
  };
}

function receiveSubscriptions(data) {
  return {
    type: RECEIVE_SUBSCRIPTIONS,
    subscriptions: data.subscriptions
  };
}

export function fetchSubscriptions() {
  return function doFetch(dispatch, getState) {
    const isAuthenticated = getUserAuthenticatedState(getState())

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    dispatch(requestSubscriptions());
    return api_fetchSubscriptions()
      .then(response => {
        dispatch(receiveSubscriptions(response));
        return response;
      });
  };
}

export function updateUserSubscription(subscription_id, params) {
  return function dopost(dispatch) {
    dispatch(updateSubscription(subscription_id, params));
    return api_updateUserSubscription(subscription_id, params)
      .then(response => {
        dispatch(subscriptionUpdated(response));
        return response;
      });
  };
}

export function updateUserSubscriptionByUserId(user_id, params) {
  return function dopost(dispatch) {
    // dispatch(updateSubscription(subscription_id, params));
    return api_updateUserSubscriptionByUserId(user_id, params)
      .then(response => {
        dispatch(subscriptionUpdated(response));
        return response;
      });
  };
}

export function fetchAllSubscriptions() {
  return function dofetch(dispatch) {
    dispatch(requestAllSubscriptions());
    return api_fetchAllSubscriptions()
      .then(response => {
        dispatch(receiveAllSubscriptions(response));
        return response;
      });
  };
}

export function unsubscribeBasicUser(params) {
  return function doPost(dispatch) {
    return api_unsubscribeBasicUser(params)
      .then(response => response);
  };
}
