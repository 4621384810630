import type { FlagModalData } from './useFlagModalData.types';
import type { FlagModalHandlers } from './useFlagModalHandlers.types';
import type { SelectOption } from '@compliance.ai/web-components';
import type { FlagModalProps } from '../FlagModal.types';

import * as errorUtils from 'shared/utils/errors';
import * as helpers from '../../../../DocumentPage/DocumentDetails/elements/DocumentDetailsTabs/DocumentDetails.helper';
import {
  documentFlagIssueTypes,
  documentFlagOptions
} from '../../../../DocumentPage/DocumentDetails/elements/DocumentFlagModal/DocumentFlagModal.constants';
import * as documentDetailsConstants from '../../../../DocumentPage/DocumentDetails/DocumentDetails.constants';
import * as documentDataConstants from 'shared/features/documentData/documentData.constants';
import * as documentPipelineStatusesConstants from '../../../../../constants/docPipelineStatuses';

export const useFlagModalHandlers = ({
  reduxState,
  localState,
  localActions,
  props
}: {
  reduxState: FlagModalData['reduxState'];
  localState: FlagModalData['localState'];
  localActions: FlagModalData['localActions'];
  props: {
    onClose: FlagModalProps['onClose'];
    onSubmit: FlagModalProps['onSubmit'];
    updateDocFlag: FlagModalProps['updateDocFlag'];
    selectedDocumentIds: FlagModalProps['selectedDocumentIds'];
    statusToSet: FlagModalProps['statusToSet'];
  };
}): FlagModalHandlers => {
  const handleClose: FlagModalHandlers['handleClose'] = () => {
    localActions.setField('');
    localActions.setNotes('');
    localActions.setRelatedDocuments('');
    localActions.setError(false);
    localActions.setIssueType(documentFlagIssueTypes.TECHNICAL);
    props.onClose();
  };

  const sendUpdateRequest: FlagModalHandlers['sendUpdateRequest'] = async params => {
    try {
      if (
        [
          documentDetailsConstants.DOC_ACTIONS.FLAG.type,
          documentDetailsConstants.DOC_ACTIONS.HIDE.type
        ].includes(props.statusToSet as documentPipelineStatusesConstants.DOCUMENT_PIPELINE_STATUS)
      ) {
        await props.updateDocFlag({
          selectedDocumentIds: props.selectedDocumentIds,
          variables: [
            {
              action: documentDataConstants.DocumentsBulkUpdateActions.FLAG,
              statusUpdate: {
                ...helpers.pickFlaggingParams(params)?.flagged,
                pipelineStatus: props.statusToSet,
                user_id: params?.userId
              }
            }
          ]
        });
      }
    } catch (e) {
      errorUtils.logError(e);
    }
  };

  const handleSubmit: FlagModalHandlers['handleSubmit'] = async () => {
    try {
      localActions.setIsLoading(true);

      const params = {
        field: localState.field,
        notes: localState.notes,
        issueType: localState.issueType,
        issueSeverity: documentFlagOptions[props.statusToSet] as SelectOption,
        relatedDocuments: localState.relatedDocuments.trim(),
        action: props.statusToSet,
        userId: reduxState?.user?.id
      };
      await sendUpdateRequest(params);
    } catch (e) {
      errorUtils.logError(e);
    } finally {
      localActions.setIsLoading(false);
      props.onSubmit();
      handleClose();
    }
  };

  return {
    handleClose,
    sendUpdateRequest,
    handleSubmit
  };
};
