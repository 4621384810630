export enum COMMAND_REQUESTS_ATTRIBUTES {
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  STATUS = 'status',
  STATUS_DETAILS = 'status_details',
  FAILED_DOCS = 'failed_docs',
  SUCCEEDED_DOCS = 'succeeded_docs',
  UPLOAD_LINK = 'upload_link',
  QA_URL = 'qa_url',
  QA_URL_FILE_NAME = 'qa_url_file_name',
  USER = 'USER',
  USER_ID = 'user_id',
  TYPE = 'type',
  PARAMETERS = 'parameters',
  SPIDER_NAMES = 'spider_names'
}
