import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_fetchAllPublications() {
  return fetch({
    url: `/publications`,
    method: 'GET',
    dataType: 'Publications',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_updatePublication(publicationId, params) {
  return fetch({
    url: `/publications/${publicationId}`,
    method: 'POST',
    params,
    dataType: 'publications',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}
