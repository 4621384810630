import React from "react";
import Tabs from 'app/common/Tabs';
import CustomSettingsRSS from './CustomSettingsRSS';

const CustomSettings = () => {
  const tabs = [
    { title: 'iFrame', content: 'Coming Soon' },
    { title: 'RSS', content: <CustomSettingsRSS />, default: true },
    { title: 'iFrame', content: 'Coming Soon' }
  ];

  return (<Tabs tabs={tabs} />);
};

export default CustomSettings;
