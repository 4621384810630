import type { BodyCrawlStatusProps } from '../BodyCrawlStatus.types';
import type { ReactNode } from 'react';

import { BUTTON_TYPES } from '@compliance.ai/web-components';
import { TABLE_VARIABLES } from '../../../../../CrawlActivityTable.constants';
import { REPORT_STATUSES, REPORT_STATUS_LABELS } from '../BodyCrawlStatus.constants';

export const getStatus = (
  report: BodyCrawlStatusProps['report']
): {
  text: string;
  type: BUTTON_TYPES;
  tooltip?: ReactNode;
} => {
  switch (report[TABLE_VARIABLES.status]) {
    case REPORT_STATUSES.STOPPED: {
      return { text: REPORT_STATUS_LABELS[REPORT_STATUSES.STOPPED], type: BUTTON_TYPES.WARNING };
    }

    case REPORT_STATUSES.UNFINISHED: {
      return { text: REPORT_STATUS_LABELS[REPORT_STATUSES.UNFINISHED], type: BUTTON_TYPES.DELETE };
    }

    case REPORT_STATUSES.TIMED_OUT: {
      return { text: REPORT_STATUS_LABELS[REPORT_STATUSES.TIMED_OUT], type: BUTTON_TYPES.DELETE };
    }

    case REPORT_STATUSES.ERROR: {
      const status = {
        text: REPORT_STATUS_LABELS[REPORT_STATUSES.ERROR],
        type: BUTTON_TYPES.DELETE
      };

      if (
        report[TABLE_VARIABLES.docs_failed] > report[TABLE_VARIABLES.docs_ingested] &&
        report[TABLE_VARIABLES.docs_failed] > report[TABLE_VARIABLES.docs_skipped]
      ) {
        return {
          ...status,
          tooltip: report[TABLE_VARIABLES.failed_docs_stat]
        };
      }

      return status;
    }

    case REPORT_STATUSES.FAILED: {
      return { text: REPORT_STATUS_LABELS[REPORT_STATUSES.FAILED], type: BUTTON_TYPES.DELETE };
    }

    case REPORT_STATUSES.PROCESSED_WITH_FAILURE: {
      return {
        text: REPORT_STATUS_LABELS[REPORT_STATUSES.PROCESSED_WITH_FAILURE],
        type: BUTTON_TYPES.WARNING
      };
    }

    default: {
      return { text: REPORT_STATUS_LABELS[REPORT_STATUSES.PROCESSED], type: BUTTON_TYPES.SUCCESS };
    }
  }
};
