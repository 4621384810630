import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../../../../components/Tabs';
import Typography from '../../../../../components/Typography';
import Button from '../../../../../components/Button';
import DocumentDetailsFields from './elements/DocumentDetailsFields/DocumentDetailsFields';
import { useDocumentDetailsTabsData } from './hooks/useDocumentDetailsTabsData';
import { useDocumentDetailsTabsHandlers } from './hooks/useDocumentDetailsTabsHandlers';
import { useDocumentSettingsNavigationLifecycle } from './hooks/useDocumentDetailsTabsLifecycle';

const DocumentDetailsTabs = ({
  id,
  docDetailsInfo,
  docTimestamp,
  user,
  updateDoc,
  flagAction,
  onTakeAction
}) => {
  const { localActions, localState, formattedData } = useDocumentDetailsTabsData({
    id,
    flagAction,
    docDetailsInfo
  });

  const handlers = useDocumentDetailsTabsHandlers({
    id,
    docDetailsInfo,
    user,
    updateDoc,
    onTakeAction,
    localActions,
    localState
  });
  useDocumentSettingsNavigationLifecycle({
    onCalculateDocDetails: handlers?.handleCalculateDocDetails,
    onTakeAction: handlers?.handleTakeAction,
    localActions,
    localState,
    flagAction
  });

  return (
    <div className="document-details_tabs">
      <div className="document-details_tabs_title">
        <div className="document-details_tabs_title_id">
          <Typography variant="title" bold>
            Details
          </Typography>
          <Typography variant="body" color="gray" className="document-details_tabs_title_doc-id">
            {`Doc ID ${localState?.docDetails.id}`}
          </Typography>
        </div>
        <div className="document-details_tabs_button-block">
          <Button
            onClick={handlers?.handleToggleEditMode}
            color="blue"
            disabled={formattedData?.isEditableStatus}
          >
            {localState?.editMode ? 'Cancel' : 'Edit'}
          </Button>
          {localState?.editMode && (
            <Button size="custom" color="success" onClick={handlers?.handleUpdateDocument}>
              Submit Changes
            </Button>
          )}
        </div>
      </div>
      <Tabs headClassName="document-details_tabs_tabs-header">
        <DocumentDetailsFields
          label="Fields"
          editMode={localState?.editMode}
          docDetails={localState?.docDetails}
          errorFields={localState?.errorFields}
          handleChanges={handlers?.handleChanges}
          handleChangesEvent={handlers?.handleChangesEvent}
          action={localState?.action}
        />
        {/*<div label="History" />*/}
        {/*<div label="Comments" />*/}
      </Tabs>
    </div>
  );
};

DocumentDetailsTabs.propTypes = {
  docDetailsInfo: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  updateDoc: PropTypes.func.isRequired,
  docTimestamp: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onTakeAction: PropTypes.func.isRequired
};

DocumentDetailsTabs.defaultProps = {
  docTimestamp: '',
  id: ''
};

export default DocumentDetailsTabs;
