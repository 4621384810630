export enum TABLE_FIELDS {
  ID = 'id',
  NAME = 'name',
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  CRAWLS_COUNT = 'crawlsCount',
  DOCUMENT_COUNT = 'documentCount'
}

export enum API_KEYS {
  NAME = 'name',
  OFFSET = 'offset',
  LIMIT = 'limit',
  CREATED_AT = 'created_at',
  USER_ID = 'user_id'
}

export enum EITL_LABELS_FILTER_KEY {
  CREATED_AT = 'Date created',
  USER_ID = 'Created by'
}
