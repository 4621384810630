import type { BulkEditModalHandlers } from '../../hooks/useSourcePageHandlers.types';
import type { SourcePageData } from '../../hooks/useSourcePageData.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useSourcePageStatusData,
  useSourcePageStatusHandlers,
  useSourcePageStatusListConfig
} from './hooks';

export const SourcePageStatus = ({
  newsSourceDetails,
  status,
  onChangeStatus
}: {
  newsSourceDetails: SourcePageData['reduxState']['newsSource'];
  status: boolean;
  onChangeStatus: BulkEditModalHandlers['handleUpdateNewsSourceStatus'];
}) => {
  const { localActions, localState, formattedData } = useSourcePageStatusData({
    newsSourceDetails
  });
  const handlers = useSourcePageStatusHandlers({
    props: {
      onChangeStatus
    },
    localActions
  });
  const listConfig = useSourcePageStatusListConfig();
  return (
    <div className="sources_status">
      <div className="sources_status_date-block">
        <uiLib.Typography isItalic>{`Created ${formattedData.createdDate}`}</uiLib.Typography>
        <uiLib.Typography isItalic>{`Updated ${formattedData.updatedDate}`}</uiLib.Typography>
      </div>
      <div className="sources_status_status-block">
        <uiLib.Typography>Status</uiLib.Typography>
        <uiLib.Dropdown
          className="sources_status_status-block_dropdown"
          isOpen={localState.isOpen}
          onClose={handlers.handleStatusDropdownToggle(false)}
          triggerContent={
            <uiLib.Button
              className="sources_status_status-block_dropdown_trigger"
              onClick={handlers.handleStatusDropdownToggle(true)}
              type={status ? uiLib.BUTTON_TYPES.SUCCESS : uiLib.BUTTON_TYPES.DELETE}
            >
              {status ? <uiLib.IconCheck /> : <uiLib.IconWarning />}
              <uiLib.Typography>{status ? 'Activated' : 'Deactivated'}</uiLib.Typography>
              <uiLib.IconEdit />
            </uiLib.Button>
          }
          dropdownContent={
            <uiLib.List
              selectedOptions={[]}
              onOptionClick={handlers.handleListOptionClick}
              options={listConfig.options}
            />
          }
        />
      </div>
      <div className="sources_status_icon-block">
        <uiLib.Button onClick={handlers.handleGoBack} type={uiLib.BUTTON_TYPES.SECONDARY}>
          <uiLib.IconClose fontSize="large" />
        </uiLib.Button>
      </div>
    </div>
  );
};
