import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from '../../../components/DatePicker';
import { ARCHIVE_UNIFIED } from '../../../constants/runNowParametersNames';
import Switch from '../../../components/Switch';

const CrawlRunNowModalBodyArchiveUnified = ({ parameters, setParameter, setBunchParameters }) => {
  const handleChanges = (name, value) => {
    setParameter(name, value);
  };

  const switchSplit = event => {
    setBunchParameters({
      [event.target.name]: [event.target.value],
      [ARCHIVE_UNIFIED.DATE_START]: moment().startOf('year').toDate(),
      [ARCHIVE_UNIFIED.DATE_FINISH]: new Date()
    });
  };

  return (
    <div>
      <Switch
        name={ARCHIVE_UNIFIED.SPLIT_RUN}
        label="Horizontal Split Run"
        className="updateCheckBox"
        value={parameters[ARCHIVE_UNIFIED.SPLIT_RUN]}
        onChange={switchSplit}
      />
      {parameters[ARCHIVE_UNIFIED.SPLIT_RUN] && (
        <>
          <div className="dateWrapper">
            <DatePicker
              label="Starting Date"
              clearIcon={null}
              onChange={date => {
                handleChanges(ARCHIVE_UNIFIED.DATE_START, date);
              }}
              value={parameters[ARCHIVE_UNIFIED.DATE_START]}
            />
            <DatePicker
              label="Finishing Date"
              clearIcon={null}
              onChange={date => {
                handleChanges(ARCHIVE_UNIFIED.DATE_FINISH, date);
              }}
              value={parameters[ARCHIVE_UNIFIED.DATE_FINISH]}
            />
          </div>
        </>
      )}
    </div>
  );
};

CrawlRunNowModalBodyArchiveUnified.propTypes = {
  parameters: PropTypes.shape({}),
  setParameter: PropTypes.func.isRequired,
  setBunchParameters: PropTypes.func.isRequired
};

CrawlRunNowModalBodyArchiveUnified.defaultProps = {
  parameters: {}
};

export default CrawlRunNowModalBodyArchiveUnified;
