import {
  api_fetchContributorPoints,
  api_fetchContributorReviewsCount
} from './contributor.api';

export const REQUEST_CONTRIBUTOR_REVIEWS_COUNT = 'REQUEST_CONTRIBUTOR_REVIEWS_COUNT';
export const RECEIVE_CONTRIBUTOR_REVIEWS_COUNT = 'RECEIVE_CONTRIBUTOR_REVIEWS_COUNT';
export const REQUEST_CONTRIBUTOR_POINTS = 'REQUEST_CONTRIBUTOR_POINTS';
export const RECEIVE_CONTRIBUTOR_POINTS = 'RECEIVE_CONTRIBUTOR_POINTS';


function requestContributorReviewsCount() {
  return {
    type: REQUEST_CONTRIBUTOR_REVIEWS_COUNT
  };
}

function receiveContributorReviewsCount(json) {
  return {
    type: RECEIVE_CONTRIBUTOR_REVIEWS_COUNT,
    contributor_reviews_count: json
  };
}

function requestContributorPoints() {
  return {
    type: REQUEST_CONTRIBUTOR_POINTS
  };
}

function recieveContributorPoints(data) {
  return {
    type: RECEIVE_CONTRIBUTOR_POINTS,
    data
  };
}

export function fetchContributorReviewsCount(annotation_task_id, data) {
  return function dofetch(dispatch) {
    dispatch(requestContributorReviewsCount());
    return api_fetchContributorReviewsCount(annotation_task_id, data)
      .then(response => {
        dispatch(receiveContributorReviewsCount(response));
        return response;
      });
  };
}

export function fetchContributorPoints(data) {
  return function doFetch(dispatch) {
    dispatch(requestContributorPoints());
    return api_fetchContributorPoints(data)
      .then(response => {
        dispatch(recieveContributorPoints(response));
        return response;
      });
  };
}
