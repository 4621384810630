import {
  clearCrawSpec as reduxClearCrawSpec,
  clearCrawConfig as reduxClearCrawConfig,
  clearCrawDryRun as reduxClearCrawDryRun
} from '../crawlerDocuments.actions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useCrawlerDocumentsReduxActions = () => {
  const dispatch = useDispatch();

  const clearCrawSpec = useCallback(() => {
    return dispatch(reduxClearCrawSpec());
  }, [dispatch]);

  const clearCrawConfig = useCallback(() => {
    return dispatch(reduxClearCrawConfig());
  }, [dispatch]);

  const clearCrawDryRun = useCallback(() => {
    return dispatch(reduxClearCrawDryRun());
  }, [dispatch]);

  return useMemo(
    () => ({
      clearCrawSpec,
      clearCrawConfig,
      clearCrawDryRun
    }),
    [clearCrawSpec, clearCrawConfig, clearCrawDryRun]
  );
};
