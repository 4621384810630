import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ERRORS,
  FIRST_STEP,
  INFO_HELP,
  NAMES_CONFIG,
  TEMPLATE_CAT_CONV
} from '../CrawlSpecCreate.constants';
import BtnNewElement from "../HelpComponents/BtnNewElement";
import SpecElementsWrapper from "./SpecElementsWrapper";
import TextInput from "../../../components/TextInput";

const Configuration = ({
  show,
  showSteps,
  disabled,
  changeStepsFiled,
  element,
  steps,
  changeSteps,
  setShowSteps,
  showCategoryConversion,
  changeConfiguration,
  changeCategoryConversion,
  categoryConversion,
  setCategoryConversion,
  errorFields
}) => {
  const addStartStep = () => {
    changeStepsFiled([FIRST_STEP]);
  };

  const removeSteps = () => {
    setShowSteps(false);
    changeStepsFiled([]);
  };

  const addCatConvert = () => {
    setCategoryConversion(TEMPLATE_CAT_CONV);
  };

  const removeCatConvert = () => {
    changeCategoryConversion(false);
    setCategoryConversion(null);
  };

  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changeConfiguration(name, value);
  };

  return (
    <SpecElementsWrapper
      show={show}
      label="Configuration"
      tooltip={INFO_HELP.CONFIGURATION}
    >
      <BtnNewElement
        elementExist={!!steps.length}
        isDisabled={disabled}
        label="Steps"
        active={showSteps}
        name={NAMES_CONFIG.STEPS}
        addNew={addStartStep}
        removeItem={removeSteps}
        setCurrent={() => changeSteps(true)}
        isError={!_.isEmpty(errorFields.steps)}
      />
      <BtnNewElement
        elementExist={!!categoryConversion}
        isDisabled={disabled}
        label="Category Conversion"
        active={showCategoryConversion}
        name={NAMES_CONFIG.CATEGORY_CONVERSION}
        addNew={addCatConvert}
        removeItem={removeCatConvert}
        setCurrent={() => changeCategoryConversion(true)}
        isError={!!_.get(errorFields, 'categoryConversion', []).length}
      />
      <TextInput
        label="Full Text Percentage"
        type="number"
        name={NAMES_CONFIG.FULL_TEXT_PERCENTAGE}
        value={element[NAMES_CONFIG.FULL_TEXT_PERCENTAGE]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={_.get(errorFields, 'configuration', []).includes(NAMES_CONFIG.FULL_TEXT_PERCENTAGE)}
        errorText={ERRORS.fullTextPercentage}
      />
    </SpecElementsWrapper>
  );
};

Configuration.propTypes = {
  show: PropTypes.bool,
  showSteps: PropTypes.bool,
  disabled: PropTypes.bool,
  element: PropTypes.shape({}),
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  changeStepsFiled: PropTypes.func.isRequired,
  changeSteps: PropTypes.func.isRequired,
  setShowSteps: PropTypes.func.isRequired,
  showCategoryConversion: PropTypes.bool,
  changeConfiguration: PropTypes.func.isRequired,
  changeCategoryConversion: PropTypes.func.isRequired,
  categoryConversion: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.oneOf([null])
  ]),
  setCategoryConversion: PropTypes.func.isRequired,
  errorFields: PropTypes.shape({})
};

Configuration.defaultProps = {
  show: false,
  disabled: false,
  showSteps: false,
  element: {},
  steps: [],
  showCategoryConversion: false,
  categoryConversion: {},
  errorFields: {}
};

export default Configuration;
