import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { Table } from 'react-bootstrap';
import moment from 'moment';

import SubscriptionModal from './SubscriptionModal';
import { fetchAllPlans } from 'shared/features/plans/plans.actions';
import { fetchAllSubscriptions } from 'shared/features/subscriptions/subscriptions.actions';
import {useDidMount, withAdminPermissions} from "../../utils/hooks";

const Subscriptions = ({
  haveEditAccess = false,
  fetchSubscriptions,
  fetchPlans,
  all_subscriptions
}) => {
  const [open, setModalOpen] = useState(false);
  const [selectedSubscription, setSelectSubscription] = useState(null);

  useDidMount(() => {
    fetchSubscriptions();
    fetchPlans();
  });

  const openModal = subscription => {
    setModalOpen(true);
    setSelectSubscription(subscription);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectSubscription(null);
  };

  const updateTable = () => {
    fetchSubscriptions();
  };

  const renderSubscriptions = () => {
    const { isReady = false, subscriptions = [] } = all_subscriptions;

    return sortBy(subscriptions, 'email')
      .map(subscription => (
        <tr
          key={subscription.subscription_id}
          onClick={() => {
            if (haveEditAccess && isReady) {
              openModal(subscription);
            }
          }}
        >
          <td>{`${subscription.first_name} ${subscription.last_name}`}</td>
          <td>{subscription.email}</td>
          <td>{(subscription.roles || []).join()}</td>
          <td>{subscription.status}</td>
          <td>{subscription.status_reason}</td>
          <td>{subscription.plan_name}</td>
          <td>{moment(subscription.start_date).format('MM-DD-YYYY')}</td>
          <td>
            {subscription.expiration_date
              ? moment(subscription.expiration_date).format('MM-DD-YYYY')
              : 'n/a: recurring'}
          </td>
          <td>{subscription.payment_type}</td>
          <td>{subscription.notes}</td>
        </tr>
      ));
  };

  return (
    <div className="subscriptions-container">
      <h1>Subscriptions</h1>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th>User Name</th>
            <th>User Email</th>
            <th>User Type</th>
            <th>Status</th>
            <th>Status Reason</th>
            <th>Plan Name</th>
            <th>Start Date</th>
            <th>Subscription End Date</th>
            <th>Payment Type</th>
            <th>Suspend Notes</th>
          </tr>
        </thead>
        <tbody>{renderSubscriptions()}</tbody>
      </Table>
      {open && (
        <SubscriptionModal
          close={closeModal}
          open={open}
          subscription={selectedSubscription}
          updateTable={updateTable}
        />
      )}
    </div>
  );
};

Subscriptions.propTypes = {
  haveEditAccess: PropTypes.bool.isRequired,
  all_subscriptions: PropTypes.shape({
    isReady: PropTypes.bool,
    isFetching: PropTypes.bool,
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        subscription_id: PropTypes.number,
        suspension_date: PropTypes.string,
        plan_id: PropTypes.number,
        plan_name: PropTypes.string,
        user_id: PropTypes.number,
        email: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        payment_type: PropTypes.string,
        notes: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.string,
        status_reason: PropTypes.string,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        expiration_date: PropTypes.string
      })
    )
  }).isRequired,
  fetchSubscriptions: PropTypes.func.isRequired,
  fetchPlans: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSubscriptions: () => {
      dispatch(fetchAllSubscriptions());
    },
    fetchPlans: () => {
      dispatch(fetchAllPlans());
    }
  };
};

const mapStateToProps = state => {
  return {
    all_subscriptions: state.all_subscriptions
  };
};

const ReduxSubscriptions = withAdminPermissions(connect(mapStateToProps, mapDispatchToProps)(Subscriptions));

export default ReduxSubscriptions;
