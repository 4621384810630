export const AGENCY_TYPES = {
  STATE: 'state',
  INTERNATION: 'internation',
  FEDERAL: 'federal',
  SRO: 'sro',
  FEDERAL_JUDICIARY: 'federal_judiciary',
  FEDERAL_EXECUTIVE: 'federal_executive'
};

// ----- TABLE CONSTANTS -----

export const AGENCY_ATTRIBUTES = {
  id: 'id',
  jurisdictionId: 'jurisdiction_id',
  jurisdiction: 'jurisdiction',
  parentId: 'parent_id',
  url: 'url',
  name: 'name',
  popular: 'popular',
  shortName: 'short_name',
  slug: 'slug',
  timesCited: 'times_cited',
  type: 'type',
  agencyId: 'agency_id',
  active: 'active',
  blacklisted: 'blacklisted',
  parentName: 'parent_name',
  logoUrl: 'logo_url',
  description: 'description',
  multiField: 'multi_input'
};

export const DEFAULT_AGENCY_ATTRIBUTES = [
  AGENCY_ATTRIBUTES.name,
  AGENCY_ATTRIBUTES.shortName,
  AGENCY_ATTRIBUTES.agencyId,
  AGENCY_ATTRIBUTES.jurisdictionId,
  AGENCY_ATTRIBUTES.jurisdiction,
  AGENCY_ATTRIBUTES.type,
  AGENCY_ATTRIBUTES.slug,
  AGENCY_ATTRIBUTES.parentId,
  AGENCY_ATTRIBUTES.url,
  AGENCY_ATTRIBUTES.active,
  AGENCY_ATTRIBUTES.blacklisted,
  AGENCY_ATTRIBUTES.popular
];

export const COLUMNS_CONFIG = [
  { name: 'Agency name', id: AGENCY_ATTRIBUTES.name, filter: true },
  { name: 'Agency short name', id: AGENCY_ATTRIBUTES.shortName, filter: true },
  { name: 'Parent ID', id: AGENCY_ATTRIBUTES.parentId, filter: true },
  { name: 'Agency type', id: AGENCY_ATTRIBUTES.type, filter: true },
  { name: 'Jurisdiction', id: AGENCY_ATTRIBUTES.jurisdiction, filter: true },
  { name: 'Active', id: AGENCY_ATTRIBUTES.active, filter: true },
  { name: 'Blacklisted', id: AGENCY_ATTRIBUTES.blacklisted, filter: true },
  { name: 'Popular', id: AGENCY_ATTRIBUTES.popular, filter: true },
  { name: 'Slug', id: AGENCY_ATTRIBUTES.slug, filter: false },
  { name: 'Url', id: AGENCY_ATTRIBUTES.url, filter: false },
  { name: 'Jurisdiction ID', id: AGENCY_ATTRIBUTES.jurisdictionId, filter: false },
  { name: 'Agency ID', id: AGENCY_ATTRIBUTES.agencyId, filter: true }
];
