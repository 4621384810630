import { setNotification, NOTIFICATION_TYPES } from "admin/components/Notification";
import {
  api_getAgencies,
  api_putAgencies,
  api_postAgencies,
  api_deleteAgencies
} from './agencyData.api';
import {
  startLoad,
  finishLoad,
  setAgency,
  updateAgency,
  createAgency,
  deleteAgency,
  initialState
} from './agencyData.reducer';

const SUCCESS = {
  agencyCreated: 'Agency successfully created',
  agencyUpdated: 'Agency successfully updated',
  agencyDeleted: 'Agency successfully deleted'
};

export const getAgencies = params => dispatch => {
  dispatch(startLoad());
  return api_getAgencies(params)
    .then(res => dispatch(setAgency(res)))
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const createAgencies = params => dispatch => {
  dispatch(startLoad());
  return api_postAgencies(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.agencyCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createAgency(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const updateAgencies = params => dispatch => {
  dispatch(startLoad());
  return api_putAgencies(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.agencyUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateAgency(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const removeAgencies = params => dispatch => {
  dispatch(startLoad());
  return api_deleteAgencies(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.agencyDeleted, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteAgency(params));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const clearAgencies = (params) => dispatch => {
  dispatch(finishLoad());
  return dispatch(setAgency(params || initialState));
};
