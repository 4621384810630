import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../../components/Typography';
import TextInput from '../../../components/TextInput';
import FileUploadInput from '../../../components/FileUploadInput';
import {
  AGENCY_LOGO
} from "./AgencyDetails.constants";

const AgencyDetailsAdditionalInfo = ({
  editMode,
  handleChanges,
  handleChangesEvent,
  agencyDetails,
  errorFields
}) => {
  const disableEdit = !editMode;

  return (
    <div className="additional-info">
      <div className="additional-info_block">
        <Typography variant="h5">Source Logo</Typography>
        <FileUploadInput
          label="Logo"
          onChange={handleChanges}
          // accept='image/*'
          isDisabled={disableEdit}
          name={AGENCY_LOGO.logoImage}
          uploadedFiles={agencyDetails[AGENCY_LOGO.logoImage]}
        />
        {disableEdit && agencyDetails[AGENCY_LOGO.logoUrl] && (
          <img
            className="additional-info_logo-image"
            src={agencyDetails[AGENCY_LOGO.logoUrl]}
            alt={agencyDetails[AGENCY_LOGO.logoUrl].substring(0,30)}
          />
        )}
        <TextInput
          name={AGENCY_LOGO.logoUrl}
          label="Logo URL"
          value={agencyDetails[AGENCY_LOGO.logoUrl]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_LOGO.logoUrl)}
          isLink
        />
      </div>
    </div>
  );
};

AgencyDetailsAdditionalInfo.propTypes = {
  editMode: PropTypes.bool,
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  agencyDetails: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

AgencyDetailsAdditionalInfo.defaultProps = {
  editMode: false,
  agencyDetails: {},
  errorFields: []
};

export default AgencyDetailsAdditionalInfo;
