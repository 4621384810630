import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_getGlassdoor(params) {
  return fetch({
    url: `/glassdoor`,
    dataType: 'Glassdoor',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postGlassdoor(params) {
  return fetch({
    url: `/glassdoor`,
    dataType: 'Glassdoor',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putGlassdoor(params) {
  return fetch({
    url: `/glassdoor`,
    dataType: 'Glassdoor',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteGlassdoor(params) {
  return fetch({
    url: `/glassdoor`,
    dataType: 'Glassdoor',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
