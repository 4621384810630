import React from 'react';

const CollectionTableHeader = props => (
  <div className="adminToolTableFlexRow flexHeader">
    <div>Name</div>
    <div>Description</div>
    <div>Categories</div>
    <div>Agencies</div>
    <div>Jurisdictions</div>
    <div>Date interval</div>
    <div />
  </div>
);

export default CollectionTableHeader;
