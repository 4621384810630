import { createSlice } from "@reduxjs/toolkit";
import { snapshotDocsHelper, timeComparisonDocsHelper } from "./enforcementActions.helper";

export const reducerName = 'enforcementActions';

export const initialState = {
  snapshot: {},
  timeComparison: {}
};

const enforcementActionsWidget = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setSnapshotDocs: (state, action) => {
      state.snapshot = snapshotDocsHelper(state.snapshot, action.payload);
    },
    setTimeComparisonDocs: (state, action) => {
      state.timeComparison = timeComparisonDocsHelper(state.timeComparison, action.payload);
    }
  }
});

export default enforcementActionsWidget.reducer;
export const {
  setSnapshotDocs,
  setTimeComparisonDocs
} = enforcementActionsWidget.actions;
