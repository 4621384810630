import type { CommandRequestsTemplatesData } from './useCommandRequestsTemplatesData.types';
import type { CommandRequestsCreationModalData } from '../../../hooks/useCommandRequestsCreationModalData.types';

import * as constants from '../CommandRequestsTemplates.constants';

import { useMemo, useState } from 'react';
export const useCommandRequestsTemplatesData = ({
  parentState,
  parentActions
}: {
  parentState: CommandRequestsCreationModalData['localState'];
  parentActions: CommandRequestsCreationModalData['localActions'];
}): CommandRequestsTemplatesData => {
  const [isSettingOpen, setIsSettingOpen] =
    useState<CommandRequestsTemplatesData['localState']['isSettingOpen']>(false);

  const formattedData: CommandRequestsTemplatesData['formattedData'] = useMemo(() => {
    const command = parentState.selectedCommand;
    return {
      isAdditionalSettings: !!command?.parameters?.additional_params,
      isFile: !!command?.parameters?.file,
      fileAccept: command?.parameters?.file?.type?.split(',') || constants.FILE_ACCEPT_DEFAULT
    };
  }, [parentState.selectedCommand]);

  const localState: CommandRequestsTemplatesData['localState'] = {
    isSettingOpen
  };

  const localActions: CommandRequestsTemplatesData['localActions'] = useMemo(
    () => ({
      setIsSettingOpen
    }),
    []
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
