import type { DocumentStatusCellValue } from './DocumentStatusCell.types';

import * as uiLib from '@compliance.ai/web-components';

import { DocumentStatus } from '../../DocumentStatus';

export const DocumentStatusCell: Required<uiLib.Column>['render'] = value => {
  const statusValue = value as DocumentStatusCellValue;

  return (
    <DocumentStatus
      pipelineStatus={statusValue.pipelineStatus}
      documentExaminationRecord={statusValue.documentExaminationRecord}
    />
  );
};
