// ----- TABLE CONSTANTS -----

export const TABLE_VARIABLES = {
  id: 'id',
  name: 'name',
  description: 'description',
  document_categories: 'document_categories',
  surface_in_filter: 'surface_in_filter',
  app_filter: 'app_filter',
  indexed: 'indexed'
};

export const DEFAULT_TABLE_COLUMNS = [
  TABLE_VARIABLES.name,
  TABLE_VARIABLES.description,
  TABLE_VARIABLES.document_categories,
  TABLE_VARIABLES.app_filter
];

export const COLUMNS_CONFIG = [
  { name: 'Name', id: TABLE_VARIABLES.name },
  { name: 'Description', id: TABLE_VARIABLES.description },
  { name: 'Categories', id: TABLE_VARIABLES.document_categories },
  { name: 'In filters', id: TABLE_VARIABLES.app_filter }
];
