import React from 'react';
import PropTypes from 'prop-types';
import DocumentDetailsTabs from './elements/DocumentDetailsTabs/DocumentDetailsTabs';
import DocumentFlagModal from './elements/DocumentFlagModal/DocumentFlagModal';
import { useDocumentDetailsData } from './hooks/useDocumentDetailsData';
import { useDocumentDetailsHandlers } from './hooks/useDocumentDetailsHandlers';
import { useDocumentDetailsLifecycle } from './hooks/useDocumentDetailsLifecycle';
const DocumentDetails = ({ id, docDetailsInfo, docTimestamp, user, updateDoc }) => {
  const { formattedData, localActions, localState } = useDocumentDetailsData({ docDetailsInfo });
  const handlers = useDocumentDetailsHandlers({ localActions, formattedData });
  useDocumentDetailsLifecycle({
    onRenderPdf: handlers?.handleRenderPdf
  });

  return (
    <div className="document-info">
      {/* we are preparing the division "document-text" for the pdf renderer that we call in the handler*/}
      <div className="document-text" id="document-text" />
      <div className="document-details">
        <DocumentDetailsTabs
          key={JSON.stringify(localState?.flagAction)}
          id={id}
          docTimestamp={docTimestamp}
          docDetailsInfo={docDetailsInfo}
          user={user}
          updateDoc={updateDoc}
          flagAction={localState?.flagAction}
          onTakeAction={handlers?.handleTakeAction}
        />
      </div>
      <DocumentFlagModal
        action={localState?.flagAction}
        id={id}
        show={!!localState?.flagAction}
        updateDoc={updateDoc}
        onHide={handlers?.handleHideModal}
        docTimestamp={docTimestamp}
        docDetailsInfo={docDetailsInfo}
        user={user}
      />
    </div>
  );
};

DocumentDetails.propTypes = {
  docDetailsInfo: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  updateDoc: PropTypes.func.isRequired,
  docTimestamp: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

DocumentDetails.defaultProps = {
  docTimestamp: '',
  id: ''
};

export default DocumentDetails;
