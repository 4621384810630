import type { FC } from 'react';
import type { DryRunReportsTableProps } from './DryRunReportsTable.types';

import React from 'react';

import * as constants from './DryRunReportsTable.constants';
import * as uiLib from '@compliance.ai/web-components';

import {
  useDryRunReportsChildTableDataSelector,
  useDryRunReportsTableDataSelector,
  useDryRunReportsTableHandlers,
  useDryRunReportsTableRows
} from './hooks';

import './_dry-run-reports-table.scss';

export const DryRunReportsTable: FC<DryRunReportsTableProps> = ({
  reports,
  onOpenDryRunInfoModal,
  onOpenModal
}) => {
  const tableRows = useDryRunReportsTableRows({
    props: {
      reports
    }
  });

  const handlers = useDryRunReportsTableHandlers({
    props: {
      onOpenDryRunInfoModal
    }
  });

  const tableDataSelector = useDryRunReportsTableDataSelector({
    events: {
      onReasonClick: handlers.handleReasonClick,
      onOpenModal: onOpenModal
    }
  });

  const tableChildDataSelector = useDryRunReportsChildTableDataSelector({
    events: {
      onReasonClick: handlers.handleReasonClick,
      onOpenModal: onOpenModal
    }
  });

  return (
    <uiLib.ErrorBoundary>
      <uiLib.Table
        localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
        columns={constants.COLUMNS}
        rowKey={constants.TABLE_KEYS.INDEX}
        rows={tableRows}
        shouldShowColumnsSettingsButton
        canHaveExpandableRows
        onRowExpand={handlers.handleChildReportsOpen}
        queryOptions={{
          queryKey: [tableRows.length],
          select: tableDataSelector,
          expandableRowSelect: tableChildDataSelector
        }}
        classNames={{
          container: 'dry-run-reports__list',
          tableContainer: 'dry-run-reports__list-inner-container',
          cell: 'dry-run-reports__list-cell',
          row: 'dry-run-reports__list-row'
        }}
      />
    </uiLib.ErrorBoundary>
  );
};
