import React from 'react';
import PropTypes from "prop-types";
import { DEFAULT_TABLE_COLUMNS } from "./DocumentCategories.constants";
import docsCategoryTableHeaderCells from './headerCells';
import docsCategoryTableBodyCells from './bodyCells';
import { formatDocCategories } from './DocumentCategories.helper';
import CustomTable from '../../components/Table';

const DocumentCategoriesTable = ({
  categories,
  setCategoryModal,
  selectedCategory,
  selectCategory,
  changeFilter,
  sort
}) => {
  const selectCategoryFilter = ({ category, add }) => {
    selectCategory({
      category: categories.find(item => item.id === category.id),
      add
    });
  };

  const categoriesFormat = formatDocCategories(categories);

  return (
    <CustomTable
      header={docsCategoryTableHeaderCells({
        categoryInfo: DEFAULT_TABLE_COLUMNS,
        changeFilter,
        sort
      })}
      data={docsCategoryTableBodyCells({
        categoriesFormat,
        categoryInfo: DEFAULT_TABLE_COLUMNS,
        selectedCategory,
        selectCategory: selectCategoryFilter
      })}
      onClick={setCategoryModal}
    />
  );
};

DocumentCategoriesTable.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  setCategoryModal: PropTypes.func.isRequired,
  selectedCategory: PropTypes.arrayOf(PropTypes.number),
  selectCategory: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      sorting_priority: PropTypes.number,
      sorting_direction: PropTypes.string
    })
  })
};

DocumentCategoriesTable.defaultProps = {
  categories: [],
  selectedCategory: [],
  sort: {}
};

export default DocumentCategoriesTable;
