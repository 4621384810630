import type { FC } from 'react';
import type { NewsSourcesFiltersProps } from './NewsSourcesFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useNewsSourcesFiltersAsyncLoaders,
  useNewsSourcesFiltersConfig,
  useNewsSourcesFiltersHandlers
} from './hooks';

import './_news-sources-filters.scss';

export const NewsSourcesFilters: FC<NewsSourcesFiltersProps> = ({ onSubmit }) => {
  const filterAsyncLoaders = useNewsSourcesFiltersAsyncLoaders();

  const filterConfig = useNewsSourcesFiltersConfig({
    filterAsyncLoaders
  });

  const handlers = useNewsSourcesFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search News Source Name"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="news-sources-filters__container"
    />
  );
};
