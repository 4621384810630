import type { SpiderRunReportFromResponse } from 'shared/features/spiderRunReports/spiderRunReports.api.types';
import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../LogsModal.constants';

export const getLogsForTable = (
  report: SpiderRunReportFromResponse
): Required<ComponentProps<typeof uiLib.Table>>['rows'] => {
  const logs = report.logs ?? {};

  return Object.entries(logs).map(([logKey, logValue]) => ({
    [constants.TABLE_KEY.ISSUE]: logKey,
    [constants.TABLE_KEY.NUMBER_OF_ISSUES_OR_TIME]:
      logKey === 'last_hb'
        ? uiLib.formatDate(logValue, {
            format: uiLib.DATE_FORMATS.API_DATE_TIME
          })
        : logValue
  }));
};
