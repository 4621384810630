import React from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import {
  fetchAllCommands,
  createNewCommand,
  updateCurrentCommand
} from 'shared/features/commands/commands.actions';
import Spinner from '../../components/spinner';
import CommandsToolsModal from './CommandsToolsModal';
import PopupInfo from "../../components/PopupInfo";
import { popupName } from "../../constants/popupInfoConst";
import {withAdminPermissions} from "../../utils/hooks";

class CommandsTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textForError: '',
      showModal: false,
      showInfoPopup: false,
      popupName: "",
      showSpinner: false,
      name: '',
      description: '',
      type: '',
      file: false,
      fileType: '',
      additionalParameters: [],
      requiredFile: false,
      error: false,
      errorText: '',
      id: ''
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAllCommands();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      error: false,
      name: '',
      description: '',
      type: '',
      file: false,
      fileType: '',
      additionalParameters: [],
      requiredFile: false,
      errorText: '',
      id: ''
    });
  };

  addNewParam = () => {
    const { additionalParameters } = this.state;
    const newId = additionalParameters.length > 0
      ? additionalParameters[additionalParameters.length - 1].id + 1
      : 1;
    this.setState({
      additionalParameters: [...additionalParameters, { id: newId, name: '', type: 'str', default: '' }]
    });
  };

  changeAdditionalParam = (parameter, e) => {
    const { additionalParameters } = this.state;
    const newAdditionalParameters = additionalParameters.map(item => {
      if (parameter.id === item.id) {
        return Object.assign({}, item, e);
      }
      return item;
    });
    this.setState({
      additionalParameters: newAdditionalParameters
    });
  };

  deleteAdditionalParam = (parameter) => {
    const { additionalParameters } = this.state;
    const newAdditionalParameters = additionalParameters.filter(item => item.id !== parameter.id);
    this.setState({
      additionalParameters: newAdditionalParameters
    });
  };

  checkBeforeSave = () => {
    const {
      name, description, type, file, fileType,
      additionalParameters, requiredFile
    } = this.state;
    if (!name.trim() || !type.trim()) {
      this.setState({
        error: true,
        errorText: "Please don't forget fill fields name and type."
      });
      return;
    }
    const lengthOfParameters = additionalParameters.length;
    for (let i = 0; i < lengthOfParameters; i++) {
      const param = additionalParameters[i];
      if (
        !param.name.trim()
        || (param.type === 'str' && !param.default.trim())
        || (param.type === 'num' && isNaN(+param.default))
      ) {
        this.setState({
          error: true,
          errorText: "Pleas check you parameters."
        });
        return;
      }
    }
    const newCommand = {
      name: name.trim(),
      description: description.trim()
    };
    const parameters = { type };
    if (file) {
      parameters.file = {
        type: fileType,
        required: requiredFile
      };
    }
    if (additionalParameters.length > 0) {
      parameters.additional_params = additionalParameters.map(item => ({
        name: item.name.trim(),
        type: item.type,
        default: item.type === 'num' ? +item.default : item.default.trim()
      }));
    }

    newCommand.parameters = parameters;
    this.closeModal();
    this.onSave(newCommand);
  };

  onSave = async (newCommand) => {
    const { id } = this.state;
    this.setState({
      showSpinner: true
    });
    let resp;
    if (id) {
      await this.props.updateCurrentCommand(newCommand, id).catch(response => {
        resp = response && response.response && JSON.parse(response.response);
      });
    } else {
      await this.props.createNewCommand(newCommand).catch(response => {
        resp = response && response.response && JSON.parse(response.response);
      });
    }
    if (resp && resp.errors) {
      this.setState({
        textForError: resp.errors,
        popupName: popupName.errorRespPopup,
        showInfoPopup: true,
        showSpinner: false
      });
    } else {
      this.setState({
        popupName: popupName.afterChangePopup,
        showInfoPopup: true,
        showSpinner: false
      });
    }
  };

  openEditPopup = (command) => {
    this.openModal();
    this.setState({
      name: command.name || '',
      description: command.description || '',
      type: command.parameters.type || '',
      file: !!command.parameters.file,
      fileType: (command.parameters.file && command.parameters.file.type) || '',
      requiredFile: (command.parameters.file && command.parameters.file.required) || false,
      additionalParameters: (command.parameters.additional_params
        && command.parameters.additional_params.map((item, index) => ({
          ...item,
          id: index
        }))) || [],
      id: command.id
    });
  };

  render() {
    const { haveEditAccess } = this.props;
    const list_items = this.props.commands.items.map((item, index) => {
      let command_details = [];
      if (item.parameters) {
        if (item.parameters.additional_params) {
          command_details = item.parameters.additional_params.map((parameter) => (
            <li key={parameter.name}>{`${parameter.name}: ${parameter.default}`}</li>
          ));
        }
        if (item.parameters.file) {
          command_details.push(<li key={'file'}>{`file: ${item.parameters.file.type || 'unknown format'}`}</li>);
        }
      }
      return (
        <tr
          key={index}
          onClick={() => {
            if (haveEditAccess) this.openEditPopup(item);
          }}
        >
          <td>{item.created_at}</td>
          <td>{item.updated_at}</td>
          <td>{item.name}</td>
          <td>{command_details}</td>
          <td>{item.description}</td>
        </tr>
      );
    });
    return (
      <div className="document-meta-table thinBtn">
        <h1>Commands Tool</h1>
        {haveEditAccess && (
          <div className="boldNotification">
            <p className="boldNotification_Text">Create new command</p>
            <Button bsStyle="primary" onClick={this.openModal}>
              Create
            </Button>
          </div>
        )}
        <Table striped condensed hover className="commandTable">
          <thead>
            <tr>
              <th>Create Date</th>
              <th>Update Date</th>
              <th>Command Name</th>
              <th>Parameters</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>{list_items}</tbody>
        </Table>
        <CommandsToolsModal
          closeModal={this.closeModal}
          showModal={this.state.showModal}
          handleChange={this.handleChange}
          onSave={this.checkBeforeSave}
          name={this.state.name}
          description={this.state.description}
          type={this.state.type}
          file={this.state.file}
          fileType={this.state.fileType}
          requiredFile={this.state.requiredFile}
          additionalParameters={this.state.additionalParameters}
          addNewParam={this.addNewParam}
          changeAdditionalParam={this.changeAdditionalParam}
          deleteAdditionalParam={this.deleteAdditionalParam}
          error={this.state.error}
          errorText={this.state.errorText}
        />
        <PopupInfo
          show={this.state.showInfoPopup}
          onHide={() => {
            this.setState({ showInfoPopup: false, textForError: "" });
          }}
          popupName={this.state.popupName}
          errorText={this.state.textForError}
        />
        {(!this.props.commands.isReady || this.state.showSpinner) && (
          <div className="spinnerWrapper">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}


const
  mapStateToProps = state => {
    return {
      commands: state.commands,
      viewer: state.current_user
    };
  };

const
  ReduxCommandRequestsTools = withAdminPermissions(connect(mapStateToProps, {
    fetchAllCommands,
    createNewCommand,
    updateCurrentCommand
  })(CommandsTools));

export default ReduxCommandRequestsTools;
