import type { ConceptsFiltersHandlers } from './useConceptsFiltersHandlers.types';
import type { ConceptsFiltersProps } from '../ConceptsFilters.types';

import * as helpers from './useConceptsFiltersHandlers.helpers';

export const useConceptsFiltersHandlers = ({
  props
}: {
  props: Pick<ConceptsFiltersProps, 'onSubmit'>;
}): ConceptsFiltersHandlers => {
  const handleFiltersSubmit: ConceptsFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatConceptsQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
