import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'admin/constants/projectSettings';

export const reducerName = 'crawlerActivity';
export const initialState = {};

export function setCrawlerActivityFunction(state, action) {
  const activityInfo = action.payload;
  const totalRuns = activityInfo.total_runs || 1;
  const countAverage = total => ((total || 0) / totalRuns).toFixed(1) || 0;

  const getTimeValue = time => {
    return time ? moment(time).format(DATE_TIME_FORMAT) : 'N/A';
  };

  return {
    ...activityInfo,
    first_run: getTimeValue(activityInfo.first_run),
    last_run: getTimeValue(activityInfo.last_run),
    avg_ingested: countAverage(activityInfo.total_ingested),
    avg_skipped: countAverage(activityInfo.total_skipped),
    avg_errors: countAverage(activityInfo.total_errors),
    avg_processed: countAverage(activityInfo.total_processed),
    avg_ingested_sla: countAverage(activityInfo.total_ingested_sla),
    avg_ingested_outside_sla: countAverage(
      activityInfo.total_ingested - activityInfo.total_ingested_sla
    )
  };
}

const crawlerActivityReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setCrawlerActivity: setCrawlerActivityFunction
  }
});

export default crawlerActivityReducer.reducer;
export const { setCrawlerActivity } = crawlerActivityReducer.actions;
