import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getMetaCategories } from 'shared/features/categories/metaCategories/metaCategories.actions';
import Typography from "../../components/Typography";
import DocumentMetaCategoriesButtons from "./DocumentMetaCategoriesButtons";
import DocumentMetaCategoriesTable from "./DocumentMetaCategoriesTable";
import DocumentMetaCategoriesFooter from "./DocumentMetaCategoriesFooter";
import DocumentMetaCategoriesModal from "./DocumentMetaCategoriesModal";

const DocumentMetaCategories = () => {
  const dispatch = useDispatch();
  const metaCategories = useSelector(state => state.documentMetaCategories);

  // table settings
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(7);
  // modal settings
  const [editModal, setEditModal] = useState(false);
  const [metaCategory, setMetaCategory] = useState({});

  const categoriesRequest = () => {
    dispatch(getMetaCategories({
      offset: (page - 1) * limit,
      limit
    }));
  };

  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(categoriesRequest, [page, limit]);

  const openMetaCategoryModal = (row, index) => {
    setMetaCategory(_.get(metaCategories, ['items', index], {}));
    setEditModal(true);
  };

  return (
    <div className="document-categories">
      <Typography variant="h4">Document Meta Categories</Typography>
      <DocumentMetaCategoriesButtons
        addMetaCategory={openMetaCategoryModal}
      />
      <DocumentMetaCategoriesTable
        metaCategories={metaCategories.items}
        setMetaCategoryModal={openMetaCategoryModal}
      />
      <DocumentMetaCategoriesFooter
        onChangePage={setPage}
        onChangeLimit={setLimit}
        limit={limit}
        pageQuantity={Math.ceil(metaCategories.count / limit)}
      />
      <DocumentMetaCategoriesModal
        show={editModal}
        onHide={() => setEditModal(false)}
        currentMetaCategory={metaCategory}
      />
    </div>

  );
};

export default DocumentMetaCategories;
