import type { FC } from 'react';
import type { AgencyCellProps } from './AgencyCell.types';

import * as uiLib from '@compliance.ai/web-components';

import { useAgencyCellData } from './hooks/useAgencyCellData';

export const AgencyCell: FC<AgencyCellProps> = ({ row }) => {
  const { formattedData } = useAgencyCellData(row);

  return (
    <div>
      {formattedData.agenciesText.map(agencyText => {
        return (
          <uiLib.Tooltip isInteractive title={agencyText}>
            <uiLib.Typography shouldTruncate>{agencyText}</uiLib.Typography>
          </uiLib.Tooltip>
        );
      })}
    </div>
  );
};
