import type { CommandRequestsCreationModalData } from './useCommandRequestsCreationModalData.types';
import type { GlobalState } from 'shared/reducers';

import { getCurrentUserId } from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCommandRequestsCreationModalData = (): CommandRequestsCreationModalData => {
  const [selectedCommand, setSelectedCommand] =
    useState<CommandRequestsCreationModalData['localState']['selectedCommand']>(null);
  const [selectedCommandRequest, setSelectedCommandRequest] =
    useState<CommandRequestsCreationModalData['localState']['selectedCommandRequest']>(null);
  const [isUserInputError, setIsUserInputError] =
    useState<CommandRequestsCreationModalData['localState']['isUserInputError']>(false);
  const [commandName, setCommandName] =
    useState<CommandRequestsCreationModalData['localState']['commandName']>('');
  const [additionalParams, setAdditionalParams] = useState<
    CommandRequestsCreationModalData['localState']['additionalParams']
  >({});
  const [files, setFiles] = useState<CommandRequestsCreationModalData['localState']['files']>([]);

  const formattedData: CommandRequestsCreationModalData['formattedData'] = useMemo(() => {
    return {
      isSubmitDisabled: Boolean(
        !selectedCommand || (selectedCommand?.parameters?.file?.required && !files?.[0]?.name)
      )
    };
  }, [files, selectedCommand]);

  const reduxState = useSelector<GlobalState, CommandRequestsCreationModalData['reduxState']>(
    state => ({
      userId: getCurrentUserId(state)
    })
  );

  const localState: CommandRequestsCreationModalData['localState'] = {
    selectedCommand,
    selectedCommandRequest,
    isUserInputError,
    commandName,
    additionalParams,
    files
  };

  const localActions: CommandRequestsCreationModalData['localActions'] = useMemo(
    () => ({
      setSelectedCommand,
      setSelectedCommandRequest,
      setIsUserInputError,
      setCommandName,
      setAdditionalParams,
      setFiles
    }),
    []
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
