import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../../components/Badge';
import { getCrawlStatusBadgeInfo } from '../CrawlMonitorTool.helper';

const BodyCrawlStatus = ({ crawl }) => {
  const badgeInfo = getCrawlStatusBadgeInfo(crawl);
  return (
    <Badge type={badgeInfo.type} size="medium" title={badgeInfo.text}>
      {badgeInfo.text}
    </Badge>
  );
};

BodyCrawlStatus.propTypes = {
  crawl: PropTypes.shape({
    id: PropTypes.number
  })
};

BodyCrawlStatus.defaultProps = {
  crawl: {}
};

export default BodyCrawlStatus;
