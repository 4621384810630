import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'documentCategories';
export const initialState = { items: [], count: 0 };

const documentCategoriesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setCategory: (state, action) => action.payload,
    createCategory: (state, action) => ({
      ...state,
      count: state.count+1,
      items: [...state.items, action.payload]
    }),
    updateCategory: (state, action) => ({
      ...state,
      items: state.items.map(item => item.id === action.payload.id ? action.payload : item)
    }),
    removeCategory: (state, action) => ({
      ...state,
      count: state.count-1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default documentCategoriesReducer.reducer;
export const {
  setCategory,
  createCategory,
  updateCategory,
  removeCategory
} = documentCategoriesReducer.actions;
