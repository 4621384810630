import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_fetchAllTermSamplingGroups() {
  return fetch({
    url: `/term_sampling_groups`,
    method: 'GET',
    dataType: 'TermSamplingGroup',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_createTermSamplingGroups(params) {
  return fetch({
    url: `/term_sampling_groups`,
    method: 'POST',
    params,
    dataType: 'TermSamplingGroup',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_updateTermSamplingGroup(termSamplingGroupId, params) {
  return fetch({
    url: `/term_sampling_groups/${termSamplingGroupId}`,
    method: 'POST',
    params,
    dataType: 'TermSamplingGroup',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}
