import type { SourcePageStatusData } from './useSourcePageStatusData.types';
import type { SourcePageData } from '../../../hooks/useSourcePageData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo, useState } from 'react';

export const useSourcePageStatusData = ({
  newsSourceDetails
}: {
  newsSourceDetails: SourcePageData['reduxState']['newsSource'];
}): SourcePageStatusData => {
  const [isOpen, setIsOpen] = useState<SourcePageStatusData['localState']['isOpen']>(false);

  const localState: SourcePageStatusData['localState'] = {
    isOpen
  };

  const localActions: SourcePageStatusData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  const formattedData: SourcePageStatusData['formattedData'] = useMemo(
    () => ({
      createdDate: uiLib.formatDate(newsSourceDetails?.created_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      }),
      updatedDate: uiLib.formatDate(newsSourceDetails?.updated_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      })
    }),
    [newsSourceDetails?.created_at, newsSourceDetails?.updated_at]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
