import { fetch, BASE_API_OPTIONS } from 'fetch';

export function api_getScrapedCategories(params) {
  return fetch({
    url: `/scraped-categories/`,
    dataType: 'Categories',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postScrapedCategories(params) {
  return fetch({
    url: `/scraped-categories/`,
    dataType: 'Categories',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putScrapedCategories(params) {
  return fetch({
    url: `/scraped-categories/`,
    dataType: 'Categories',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteScrapedCategories(params) {
  return fetch({
    url: `/scraped-categories/`,
    dataType: 'Categories',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
