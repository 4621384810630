import React, { useState } from 'react';
import PropTypes from "prop-types";
import Spinner from "../../../../components/spinner";
import Accordion from "./Accordion";
import { JUR_CONST } from "../Glassdoor.constants";
import Typography from "../../../../components/Typography";
import GlassdoorSources from "./GlassdoorSources";
import Header from "./GlassdoorTableComponents/Header";
import Info from "./GlassdoorTableComponents/Info";

const GlassdoorTable = ({
  jur,
  jurLoad
}) => (
  <div className="glassdoor_table">
    <Header
      leftChild={<Typography bold variant="p" color="transparent_black">Jurisdiction</Typography>}
      // commented to speedup glassdoor (counter doesn't come from be)
      // rightChild={<Typography bold variant="p" color="transparent_black"># of Docs</Typography>}
    />
    {jur.map(item => (
      <GlassdoorJurisdiction
        key={item[JUR_CONST.ID]}
        item={item}
      />
    ))}
    {jurLoad && <Info><Spinner /></Info>}
  </div>
);

GlassdoorTable.propTypes = {
  jur: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  jurLoad: PropTypes.bool
};

GlassdoorTable.defaultProps = {
  jur: [],
  jurLoad: false
};

const GlassdoorJurisdiction = ({
  item
}) => {
  const [open, setOpen] = useState(false);

  const toggleAccordion = (id, isOpen) => {
    setOpen(!isOpen);
  };

  return (
    <Accordion
      key={item[JUR_CONST.ID]}
      open={open}
      leftInfo={(<Typography variant="p">{item[JUR_CONST.SHORT_NAME]}</Typography>)}
      // commented to speedup glassdoor (counter doesn't come from be)
      // rightInfo={(<Typography variant="p">{item[JUR_CONST.DOC_COUNT]}</Typography>)}
      onClick={toggleAccordion}
      id={item[JUR_CONST.ID]}
    >
      <GlassdoorSources
        openJur={open}
        jurID={item[JUR_CONST.ID]}
      />
    </Accordion>
  );
};

GlassdoorJurisdiction.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  })
};

GlassdoorJurisdiction.defaultProps = {
  item: {}
};

export default GlassdoorTable;
