import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import "./DefaultValue.styles.scss";
import { ERRORS, NAMES_META_DATA, VALUE_TYPES } from '../CrawlSpecCreate.constants';
import Typography from "../../../components/Typography";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/ReactSelect/Select";
import Checkbox from "../../../components/Checkbox_NEW";

const DefaultValue = ({
  disabled,
  valueType,
  valueTypeName,
  value,
  valueName,
  changeValue,
  errorFields
}) => {
  const typeValueOptions = useMemo(
    () => Object.values(VALUE_TYPES).map(
      item => ({ value: item, label: _.capitalize(item) })
    ),
    []
  );

  const handleChangesEvent = (e) => {
    const { name, value: inputValue } = e.target;
    changeValue(name, inputValue);
  };

  const getElement = () => {
    const type = _.get(valueType, 'value', VALUE_TYPES.NOT_USE);
    let element = null;
    if (type === VALUE_TYPES.BOOL) {
      element = (<Checkbox
        disabled={disabled}
        label="Default Value"
        checked={value}
        className="default-value_checkbox"
        onClick={checked => changeValue(valueName, checked)}
      />);
    } else if (type === VALUE_TYPES.NULL) {
      element = (
        <label className="default-value_null">
          <Typography variant="title" color="blue">
            NULL
          </Typography>
        </label>
      );
    } else if (type === VALUE_TYPES.NOT_USE) {
      element = null;
    } else {
      element = (<TextInput
        label="Default Value"
        name={valueName}
        value={value}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_META_DATA.DEFAULT_VALUE)}
        errorText={ERRORS.defaultValue}
      />);
    }
    return element;
  };

  return (
    <div className="default-value">
      <Select
        label="Type"
        value={valueType}
        onChange={e => changeValue(valueTypeName, e)}
        options={typeValueOptions}
        isDisabled={disabled}
        isError={errorFields.includes(valueTypeName)}
        errorText={ERRORS.defaultType}
      />
      {getElement()}
    </div>
  );
};

DefaultValue.propTypes = {
  disabled: PropTypes.bool,
  changeValue: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string),
  valueName: PropTypes.string.isRequired,
  valueTypeName: PropTypes.string.isRequired,
  valueType: PropTypes.shape({}),
  value: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]
  )
};

DefaultValue.defaultProps = {
  disabled: false,
  errorFields: [],
  value: null,
  valueType: {}
};

export default DefaultValue;
