import { fetch, BASE_API_OPTIONS } from "fetch";

export function api_getCategories(params) {
  return fetch({
    url: `/categories`,
    dataType: 'Categories',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postCategories(params) {
  return fetch({
    url: `/categories`,
    dataType: 'Categories',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putCategories(params) {
  return fetch({
    url: `/categories`,
    dataType: 'Categories',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteCategories(params) {
  return fetch({
    url: `/categories`,
    dataType: 'Categories',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
