import { ORG_IP_ERROR, ORG_IP_ERROR_DESCRIPTION, ERROR_MESSAGES } from './fetch.constants';
import Bugsnag from '@bugsnag/js';
import { ALERT_TYPES, showNotification } from '@compliance.ai/web-components';
import { convertAxiosErrorToObject, getErrorData, logError } from './fetch.errors.helpers';

/**
 * Redirects user on UnauthorizedAccess error
 */
export const onUnauthorizedAccessError = (e, dataType) => {
  const { errorMessage } = getErrorData(e.response);

  if (errorMessage.includes(ORG_IP_ERROR)) {
    Bugsnag.notify(new Error(ORG_IP_ERROR_DESCRIPTION));

    showNotification({
      type: ALERT_TYPES.ERROR,
      title: dataType,
      message: ORG_IP_ERROR_DESCRIPTION
    });
  }

  Bugsnag.notify(convertAxiosErrorToObject(e));

  return Promise.resolve();
};

/**
 * Shows error notifications and sends Bugsnag repost
 */
export const onResponseError = (e, dataType, shouldHideErrorNotifications = false) => {
  if (process.env.NODE_ENV !== 'test' && !shouldHideErrorNotifications) {
    logError({
      errorResponse: e.response,
      dataType
    });
  }

  return Promise.reject(convertAxiosErrorToObject(e));
};

/**
 * Shows error notification
 */
export const onRequestError = (e, dataType, shouldHideErrorNotifications = false) => {
  if (!shouldHideErrorNotifications) {
    showNotification({
      type: ALERT_TYPES.ERROR,
      title: dataType,
      message: e.message || ERROR_MESSAGES.NETWORK
    });
  }

  return Promise.reject(convertAxiosErrorToObject(e));
};

/**
 * When something totally unexpected happened
 */
export const onUnexpectedError = (e, dataType, shouldHideErrorNotifications = false) => {
  if (!shouldHideErrorNotifications) {
    showNotification({
      type: ALERT_TYPES.ERROR,
      title: dataType,
      message: e.message || ERROR_MESSAGES.UNKNOWN
    });
  }

  return Promise.reject(convertAxiosErrorToObject(e));
};
