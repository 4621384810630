import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getStartDate, getEndDate } from '../../hooks/useFiltersHandlers.helpers';
import CustomDatePicker from '../../../../../../../../../components/CustomDatePicker';
import Button from '../../../../../../../../../components/Button';
import { DATE_TIME_FORMAT } from '../../../../../../../../../constants/projectSettings';

const ButtonBlock = ({ pastWeek, pastMonth, pastThree, pastYear }) => (
  <div className="body-search-block_date-btns">
    <Button onClick={pastWeek} color="highlighted">
      Past Week
    </Button>
    <Button onClick={pastMonth} color="highlighted">
      Past Month
    </Button>
    <Button onClick={pastThree} color="highlighted">
      Past 3 Month
    </Button>
    <Button onClick={pastYear} color="highlighted">
      Past Year
    </Button>
  </div>
);

const CrawlActivityHeaderSearchGapTime = ({ handleChanges, name, label, search }) => {
  const startDateName = getStartDate(name);
  const endDateName = getEndDate(name);
  const startDate = search[startDateName];
  const endDate = search[endDateName];
  const stringStartDate = startDate ? moment(startDate).format(DATE_TIME_FORMAT) : '';
  const stringEndDate = endDate ? moment(endDate).format(DATE_TIME_FORMAT) : '';

  const onChange = dates => {
    const [start, end] = dates;
    handleChanges(startDateName, start);
    handleChanges(endDateName, end);
  };

  const setPastDates = days => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - days);
    handleChanges(startDateName, newDate);
    handleChanges(endDateName, new Date());
  };

  return (
    <CustomDatePicker
      extraContent={
        <ButtonBlock
          pastWeek={() => setPastDates(7)}
          pastMonth={() => setPastDates(30)}
          pastThree={() => setPastDates(90)}
          pastYear={() => setPastDates(365)}
        />
      }
      label={label}
      onChange={onChange}
      value={(stringStartDate || stringEndDate) && `${stringStartDate} - ${stringEndDate}`}
      startDate={startDate}
      endDate={endDate}
      monthsShown={2}
      selectsRange
    />
  );
};

CrawlActivityHeaderSearchGapTime.propTypes = {
  handleChanges: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  search: PropTypes.shape({})
};

CrawlActivityHeaderSearchGapTime.defaultProps = {
  label: '',
  name: '',
  search: {}
};

export default CrawlActivityHeaderSearchGapTime;
