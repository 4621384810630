import React, { useMemo } from 'react';
import _ from "lodash";
import { COLUMNS_CONFIG } from './DocumentMetaCategories.constants';
import HeaderSort from '../../components/SortElements/HeaderSort';

const getMetaCategoryHeader = ({
  metaCategoryInfo
}) => {
  const metaCategoryColumns = metaCategoryInfo.map(
    item => COLUMNS_CONFIG.find(col => col.id === item)
  );
  const formatInfo = metaCategoryColumns.map(item => (
    <HeaderSort
      type={_.get([], [item.id, 'sorting_direction'], null)}
      onClick={() => {}}
      item={item}
    />
  ));

  return formatInfo;
};

export default ({ metaCategoryInfo }) => {
  return useMemo(() => [getMetaCategoryHeader({
    metaCategoryInfo
  })], [metaCategoryInfo]);
};
