export enum BULK_EDIT_ACTIONS {
  ADD = 'ADD',
  EDIT = 'EDIT',
  REMOVE = 'REMOVE'
}

export enum BULK_EDIT_ATTRIBUTES {
  EITL_LABEL = 'EITL_LABEL',
  SUMMARY = 'SUMMARY'
}
