import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Radio as BootstrapRadio } from 'react-bootstrap';
import classNames from 'classnames';
import './_radio.scss';

export const TEST_ID = 'radio';

const Radio = ({
  checked: propsChecked,
  useInternalChecked,
  onChange,
  label,
  className,
  disabled
}) => {
  const [stateChecked, setStateChecked] = useState(null);

  const handleCheckClick = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    const checked = Boolean(e.target.checked);

    setStateChecked(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  const checked = useInternalChecked ? stateChecked: propsChecked;

  return (
    <BootstrapRadio
      data-testid={TEST_ID}
      className={classNames(className, {
        "radio-common-component": true,
        "radio-common-component--disabled": disabled
      })}
      inline
      checked={checked}
      onChange={handleCheckClick}
      disabled={disabled}
    >
      {label}
    </BootstrapRadio>
  );
};

export default Radio;

Radio.propTypes = {
  checked: PropTypes.bool,
  useInternalChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

Radio.defaultProps = {
  checked: false,
  onChange: () => {},
  useInternalChecked: false,
  disabled: false,
  label: ''
};
