import _ from 'lodash';
import {
  api_fetchAllTermSamplingGroups,
  api_createTermSamplingGroups,
  api_updateTermSamplingGroup
} from './term.api';

export const REQUEST_TERM_SAMPLING_GROUPS = 'REQUEST_TERM_SAMPLING_GROUPS';
export const RECEIVE_TERM_SAMPLING_GROUPS = 'RECEIVE_TERM_SAMPLING_GROUPS';
export const CREATE_TERM_SAMPLING_GROUP = 'CREATE_TERM_SAMPLING_GROUP';
export const TERM_SAMPLING_GROUP_CREATED = 'TERM_SAMPLING_GROUP_CREATED';
export const UPDATE_TERM_SAMPLING_GROUP = 'UPDATE_TERM_SAMPLING_GROUP';
export const TERM_SAMPLING_GROUP_UPDATED = 'TERM_SAMPLING_GROUP_UPDATED';

function requestAllTermSamplingGroups() {
  return {
    type: REQUEST_TERM_SAMPLING_GROUPS
  };
}

function receiveAllTermSamplingGroups(json) {
  return {
    type: RECEIVE_TERM_SAMPLING_GROUPS,
    term_sampling_groups: json
  };
}

function createTermSamplingGroup(params) {
  return {
    type: CREATE_TERM_SAMPLING_GROUP,
    params
  };
}

function termSamplingGroupCreated(json) {
  return {
    type: TERM_SAMPLING_GROUP_CREATED,
    term_sampling_group: json
  };
}

function updateTermSamplingGroup() {
  return {
    type: UPDATE_TERM_SAMPLING_GROUP
  };
}

function termSamplingGroupUpdated(json) {
  return {
    type: TERM_SAMPLING_GROUP_UPDATED,
    term_sampling_group: _.get(json, 'term_sampling_group')
  };
}

export function fetchAllTermSamplingGroups() {
  return function dofetch(dispatch) {
    dispatch(requestAllTermSamplingGroups());
    return api_fetchAllTermSamplingGroups()
      .then(response => {
        dispatch(receiveAllTermSamplingGroups(response));
      });
  };
}

export function createTermSamplingGroupsFromParams(params) {
  return function dopost(dispatch) {
    dispatch(createTermSamplingGroup(params));
    return api_createTermSamplingGroups(params)
      .then(response => {
        dispatch(termSamplingGroupCreated(response));
        return response;
      })
      .catch(() => {
        dispatch(termSamplingGroupCreated(null));
      });
  };
}

export function updateTermSamplingGroupWithParams(termSamplingGroupId, data) {
  return function dopost(dispatch) {
    dispatch(updateTermSamplingGroup(data));
    return api_updateTermSamplingGroup(termSamplingGroupId, data)
      .then(response => {
        dispatch(termSamplingGroupUpdated(response));
        return response;
      })
      .catch(() => {
        dispatch(termSamplingGroupUpdated(null));
      });
  };
}
