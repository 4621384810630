import { find, get } from 'lodash';
import { DOC_STATUS_COLORS } from '../../admin/constants/document';
import { DOCUMENT_PIPELINE_STATUSES } from '../../admin/constants/docPipelineStatuses';

export const getDocStatusColor = status => {
  let docStatusColor = DOC_STATUS_COLORS.DEFAULT;
  if (
    [
      DOCUMENT_PIPELINE_STATUSES.NEW.type,
      DOCUMENT_PIPELINE_STATUSES.UPDATED.type,
      DOCUMENT_PIPELINE_STATUSES.ORIGINAL.type,
      DOCUMENT_PIPELINE_STATUSES.VERSIONIZED_DOC.type,
      DOCUMENT_PIPELINE_STATUSES.SENTENCIFIED.type,
      DOCUMENT_PIPELINE_STATUSES.CLASSIFIED.type,
      DOCUMENT_PIPELINE_STATUSES.PROCESSED.type,
      DOCUMENT_PIPELINE_STATUSES.CLIENT_ONLY.type
    ].includes(status)
  ) {
    docStatusColor = DOC_STATUS_COLORS.GREEN;
  } else if (
    [
      DOCUMENT_PIPELINE_STATUSES.EXTRACTED.type,
      DOCUMENT_PIPELINE_STATUSES.PROBLEMATIC.type,
      DOCUMENT_PIPELINE_STATUSES.OFF_TOPIC.type,
      DOCUMENT_PIPELINE_STATUSES.HTMLPDFCONVFAILED.type
    ].includes(status)
  ) {
    docStatusColor = DOC_STATUS_COLORS.RED;
  } else if (
    [
      DOCUMENT_PIPELINE_STATUSES.SCRAPED.type,
      DOCUMENT_PIPELINE_STATUSES.GENERATED.type,
      DOCUMENT_PIPELINE_STATUSES.RESCRAPED.type,
      DOCUMENT_PIPELINE_STATUSES.HTMLPDFCONV.type,
      DOCUMENT_PIPELINE_STATUSES.DUPLICATED.type,
      DOCUMENT_PIPELINE_STATUSES.POTENTIAL_DUPLICATE.type
    ].includes(status)
  ) {
    docStatusColor = DOC_STATUS_COLORS.ORANGE;
  } else if ([DOCUMENT_PIPELINE_STATUSES.INCOMPLETE.type].includes(status)) {
    docStatusColor = DOC_STATUS_COLORS.GRAY;
  } else if ([DOCUMENT_PIPELINE_STATUSES.DEPRECATED.type].includes(status)) {
    docStatusColor = DOC_STATUS_COLORS.LIGHT_RED;
  } else if ([DOCUMENT_PIPELINE_STATUSES.HIDDEN.type].includes(status)) {
    docStatusColor = DOC_STATUS_COLORS.PURPLE;
  } else if ([DOCUMENT_PIPELINE_STATUSES.FLAGGED.type].includes(status)) {
    docStatusColor = DOC_STATUS_COLORS.BROWN;
  } else if ([DOCUMENT_PIPELINE_STATUSES.OUT_OF_VERTICAL.type].includes(status)) {
    docStatusColor = DOC_STATUS_COLORS.PINK;
  }
  return docStatusColor;
};

export const getDocStatusLabel = status => {
  const a = find(Object.values(DOCUMENT_PIPELINE_STATUSES), { type: status });
  return get(a, 'label', '');
};
