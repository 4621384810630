import React from 'react';

import Typography from 'admin/components/Typography';

import * as elements from './elements';

export const CrawlActivityBillboardBlock = ({ blockInfo, crawlerActivity }) => (
  <article style={{ flex: blockInfo.content.length }}>
    <header>
      <Typography variant="title" color="white">
        {blockInfo.title}
      </Typography>
    </header>
    <section>
      {blockInfo.content.map(content => (
        <elements.ContentBlock
          // TODO Refactor
          key={JSON.stringify(content)}
          content={content}
          crawlerActivity={crawlerActivity}
        />
      ))}
    </section>
  </article>
);
