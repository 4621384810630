export const CONFIG = [
  {
    title: 'Runs',
    content: [
      { name: 'Total', paramName: 'total_runs' },
      { name: 'Initial', paramName: 'first_run', time: true },
      { name: 'Last', paramName: 'last_run', time: true }
    ]
  },
  {
    title: 'Ingested',
    content: [
      { name: 'Total', paramName: 'total_ingested' },
      { name: 'Average', paramName: 'avg_ingested' },
      { name: 'Last', paramName: 'last_ingested' }
    ]
  },
  {
    title: 'Updated',
    content: [
      { name: 'Total', paramName: 'total_updated' },
      { name: 'Average', paramName: 'avg_updated' },
      { name: 'Last', paramName: 'last_updated' }
    ]
  },
  {
    title: 'Skipped',
    content: [
      { name: 'Average', paramName: 'avg_skipped' },
      { name: 'Last', paramName: 'last_skipped' }
    ]
  },
  {
    title: 'Errors',
    content: [
      { name: 'Average', paramName: 'avg_errors' },
      { name: 'Last', paramName: 'last_errors' }
    ]
  },
  {
    title: 'Failed',
    content: [
      { name: 'Average', paramName: 'avg_failed' },
      { name: 'Last', paramName: 'last_failed' }
    ]
  },
  {
    title: 'Processed',
    content: [
      { name: 'Average', paramName: 'avg_processed' },
      { name: 'Avg Within SLA', paramName: 'avg_ingested_sla' },
      { name: 'Avg Outside of SLA', paramName: 'avg_ingested_outside_sla' }
    ]
  }
];
