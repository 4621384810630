import type { ApiSortSettings } from './useConceptsData.types';

import * as constants from '../Concepts.constants';
import * as conceptsConstants from 'admin/constants/concepts';

export const getSortField = (sortString: string): conceptsConstants.CONCEPTS_ATTRIBUTES => {
  return (
    sortString.at(0) === '-' ? sortString.slice(1) : sortString
  ) as conceptsConstants.CONCEPTS_ATTRIBUTES;
};

export const getSortDirection = (sortString: string): constants.SORT_DIRECTIONS => {
  return sortString.at(0) === '-' ? constants.SORT_DIRECTIONS.DESC : constants.SORT_DIRECTIONS.ASC;
};

export const getApiSortSettings = (sortParam: string | null): ApiSortSettings | undefined => {
  if (sortParam === null) {
    /**
     * Undefined ensures that the query param won't be included in the payload
     */
    return undefined;
  }

  const sortField = getSortField(sortParam);

  return {
    [sortField]: {
      sorting_priority: 1,
      sorting_direction: getSortDirection(sortParam)
    }
  };
};
