import { useEffect } from 'react';
import { CrawlSettingsHandlers } from './useCrawlSettingsHandlers.types';

export const useCrawlDetailsLifecycle = ({
  onInitialisation,
  onReportChange,
  onStatusChange
}: {
  onInitialisation: CrawlSettingsHandlers['handleInitialisation'];
  onReportChange: CrawlSettingsHandlers['handleSpiderRunReportChange'];
  onStatusChange: CrawlSettingsHandlers['handleStatusChange'];
}): void => {
  useEffect(onInitialisation, [onInitialisation]);
  useEffect(onReportChange, [onReportChange]);
  useEffect(onStatusChange, [onStatusChange]);
};
