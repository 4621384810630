import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Typography from './Typography';

const navigationButtons = {
  FIRST: 'First',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  LAST: 'Last'
};

function Pagination({
  name,
  pageQuantity,
  className,
  onChange,
  externalActivePage,
  maxButtons = pageQuantity < 5 ? pageQuantity : 5,
  isInfinitePagination,
  isInfinitePaginationLastPage
}) {
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    setActivePage(externalActivePage);
  }, [externalActivePage]);

  const handlePress = value => {
    let sendValue;
    switch (value) {
      case navigationButtons.FIRST:
        sendValue = 1;
        break;
      case navigationButtons.PREVIOUS:
        if (activePage > 1) sendValue = activePage - 1;
        else sendValue = 1;
        break;
      case navigationButtons.NEXT:
        if (activePage < pageQuantity) sendValue = activePage + 1;
        else sendValue = pageQuantity;
        break;
      case navigationButtons.LAST:
        sendValue = pageQuantity;
        break;
      default:
        sendValue = value;
    }
    setActivePage(sendValue);
    onChange(sendValue);
  };

  const getDisabledState = page => {
    if (page === navigationButtons.NEXT && isInfinitePagination && isInfinitePaginationLastPage) {
      return true;
    }
    if (activePage === 1) {
      return [navigationButtons.FIRST, navigationButtons.PREVIOUS].includes(page);
    }
    if (activePage === pageQuantity) {
      return [navigationButtons.NEXT, navigationButtons.LAST].includes(page);
    }
    return false;
  };

  const renderPage = page => {
    const disabled = getDisabledState(page);
    return (
      <div
        key={page}
        onClick={() => !disabled && handlePress(page)}
        className={cn({
          active_page: page === activePage,
          disabled
        })}
      >
        <Typography variant="p" color={page === activePage ? 'white' : 'black'}>
          {page}
        </Typography>
      </div>
    );
  };

  const renderButtons = quantity => {
    let startButton = 1;
    const halfPagination = Math.floor(maxButtons / 2);
    if (activePage < maxButtons) startButton = 1;
    else if (activePage > pageQuantity - halfPagination) {
      startButton = pageQuantity - (maxButtons - 1);
    } else startButton = activePage - halfPagination;

    if (startButton < 1) startButton = 1;
    const buttonsArray = [...new Array(maxButtons)].map(() => {
      const btnCount = startButton;
      startButton += 1;
      return btnCount;
    });

    return buttonsArray.map(renderPage);
  };

  return (
    <div className={cn('pagination', className)}>
      {renderPage(navigationButtons.FIRST)}
      {renderPage(navigationButtons.PREVIOUS)}
      {!isInfinitePagination && renderButtons(pageQuantity)}
      {renderPage(navigationButtons.NEXT)}
      {!isInfinitePagination && renderPage(navigationButtons.LAST)}
    </div>
  );
}

Pagination.propTypes = {
  pageQuantity: PropTypes.number,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  externalActivePage: PropTypes.number,
  isInfinitePagination: PropTypes.bool,
  isInfinitePaginationLastPage: PropTypes.bool
};

Pagination.defaultProps = {
  pageQuantity: 5,
  name: '',
  className: '',
  externalActivePage: 1,
  isInfinitePagination: false,
  isInfinitePaginationLastPage: false
};

export default Pagination;
