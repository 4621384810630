import * as env from 'shared/config';

export enum AUTHENTICATION_OPTIONS_KEYS {
  API_KEY = 'API_KEY',
  REDUX_STATE = 'REDUX_STATE'
}

export enum BASE_API_OPTIONS {
  BASE_API_APP = 'app',
  BASE_API_DATA = 'data',
  BASE_API_ABSOLUTE = 'absolute',
  BASE_API_SLOT = 'slot',
  BASE_API_GA = 'ga'
}

export const API_PREFIXES: Record<BASE_API_OPTIONS, string> = {
  [BASE_API_OPTIONS.BASE_API_APP]: env.apiUrl,
  [BASE_API_OPTIONS.BASE_API_ABSOLUTE]: '',
  [BASE_API_OPTIONS.BASE_API_DATA]: env.dataApiUrl,
  [BASE_API_OPTIONS.BASE_API_SLOT]: env.slotDataApiUrl,
  [BASE_API_OPTIONS.BASE_API_GA]: env.gaApiUrl
};

export const HTTP_METHODS_WITH_BODY = ['POST', 'PATCH', 'PUT', 'DELETE'];

export const ORG_IP_ERROR = 'invalid org IP';
export const ORG_IP_ERROR_DESCRIPTION =
  "Access to this application is restricted, please contact your organization's administrator for more information.";

export const ERROR_TYPES = {
  TIMEOUT: 'timeout',
  SERVER: 'server',
  NETWORK: 'network',
  UNKNOWN: 'unkown'
};

export const ERROR_MESSAGES = {
  TIMEOUT: 'Timeout Exception',
  SERVER: 'Server Exception',
  NETWORK: 'Network Exception',
  UNKNOWN: 'Unexpected Exception'
};

export const MIXPANEL_URL = '/mxp/event';
