export enum NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  NAME = 'name',
  IS_SHARED = 'is_shared',
  ACTIVE = 'active',
  LOGO_URL = 'logo_url',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  ID = 'id',
  JURISDICTIONS = 'jurisdiction',
  AGENCY_IDS = 'agency_ids',
  JURISDICTION_IDS = 'jurisdiction_ids',
  AGENCIES = 'agencies',
  JURISDICTION = 'jurisdiction'
}
