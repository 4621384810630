import type { BulkEditModalRule } from './useBulkEditModalData.types';
import type {
  AvailableBulkEditActions,
  AvailableBulkEditAttributes
} from './useBulkEditModalFormattedData.types';

import * as constants from '../BulkEditModal.constants';

export const isRuleCompleted = ({ action, attribute, value }: BulkEditModalRule) => {
  const isValueEmpty = !value || !value.length;

  if (
    action === constants.BULK_EDIT_ACTIONS.REMOVE &&
    attribute === constants.BULK_EDIT_ATTRIBUTES.SUMMARY
  ) {
    return isValueEmpty;
  }

  return Boolean(action) && Boolean(attribute) && !isValueEmpty;
};

export const isBulkEditAttributeSelected = (
  rules: BulkEditModalRule[],
  attribute: constants.BULK_EDIT_ATTRIBUTES
) => rules.some(rule => rule.attribute === attribute);

export const getAvailableBulkEditActions = (
  rules: BulkEditModalRule[]
): constants.BULK_EDIT_ACTIONS[] => {
  const isEitlLabelSelected = isBulkEditAttributeSelected(
    rules,
    constants.BULK_EDIT_ATTRIBUTES.EITL_LABEL
  );

  const isSummarySelected = isBulkEditAttributeSelected(
    rules,
    constants.BULK_EDIT_ATTRIBUTES.SUMMARY
  );

  if (isEitlLabelSelected && isSummarySelected) {
    return [];
  }

  if (isEitlLabelSelected) {
    return [constants.BULK_EDIT_ACTIONS.EDIT];
  }

  return [
    constants.BULK_EDIT_ACTIONS.ADD,
    constants.BULK_EDIT_ACTIONS.EDIT,
    constants.BULK_EDIT_ACTIONS.REMOVE
  ];
};

export const getAvailableBulkEditAttributes = (
  rules: BulkEditModalRule[]
): constants.BULK_EDIT_ATTRIBUTES[] => {
  const isEitlLabelSelected = isBulkEditAttributeSelected(
    rules,
    constants.BULK_EDIT_ATTRIBUTES.EITL_LABEL
  );
  const isSummarySelected = isBulkEditAttributeSelected(
    rules,
    constants.BULK_EDIT_ATTRIBUTES.SUMMARY
  );

  if (isEitlLabelSelected && isSummarySelected) {
    return [];
  }

  if (isEitlLabelSelected) {
    return [constants.BULK_EDIT_ATTRIBUTES.SUMMARY];
  }

  if (isSummarySelected) {
    return [constants.BULK_EDIT_ATTRIBUTES.EITL_LABEL];
  }

  return [constants.BULK_EDIT_ATTRIBUTES.EITL_LABEL, constants.BULK_EDIT_ATTRIBUTES.SUMMARY];
};

export const getAvailableActionsMap = (rules: BulkEditModalRule[]): AvailableBulkEditActions =>
  Object.fromEntries(
    rules.map(currentRule => {
      const availableActions = getAvailableBulkEditActions(
        rules.filter(rule => currentRule.id !== rule.id)
      );

      return [currentRule.id, availableActions];
    })
  );
