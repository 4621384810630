import { useEffect } from 'react';
import { DocumentDetailsHandlers } from './useDocumentDetailsHandlers.types';

export const useDocumentDetailsLifecycle = ({
  onRenderPdf
}: {
  onRenderPdf: DocumentDetailsHandlers['handleRenderPdf'];
}): void => {
  useEffect(onRenderPdf, [onRenderPdf]);
};
