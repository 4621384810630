import type { FetchConfig } from './fetch.types';
import type { AxiosError, AxiosRequestConfig } from 'axios';

import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import {
  onResponseError,
  onRequestError,
  onUnauthorizedAccessError,
  onUnexpectedError
} from './fetch.errors';
import { API_PREFIXES, BASE_API_OPTIONS } from './fetch.constants';

export const fetch = async <Return>({
  method,
  url,
  params,
  authenticationType,
  customToken,
  baseApi,
  contentType,
  headers,
  dataType,
  shouldUseAbsoluteUrl,
  shouldHideErrorNotifications,
  shouldDisableQueryParamsArrayFormatting,
  ...restConfig
}: FetchConfig): Promise<Return | never> => {
  try {
    return await axios.request({
      /**
       * Pass our config to axios request interceptors
       * All the request formatting logic is handled there
       */
      url: shouldUseAbsoluteUrl
        ? url
        : `${API_PREFIXES[baseApi || BASE_API_OPTIONS.BASE_API_APP]}${url}`,
      method: method,
      isPureAxiosRequest: false,
      fetchConfig: {
        params,
        authenticationType,
        customToken,
        baseApi,
        contentType,
        headers,
        dataType,
        shouldHideErrorNotifications,
        shouldDisableQueryParamsArrayFormatting,
        ...restConfig
      }
    } as AxiosRequestConfig & { isPureAxiosRequest: boolean });
  } catch (error) {
    const e = error as AxiosError;

    /**
     * 	Client received 401
     */
    if (e?.response?.status === StatusCodes.UNAUTHORIZED) {
      return (onUnauthorizedAccessError(e, dataType) as unknown) as Return;
    }

    /**
     * 	Client received an error response (5xx, 4xx)
     */
    if (e.response && e.response.status !== StatusCodes.UNAUTHORIZED) {
      return onResponseError(e, dataType, shouldHideErrorNotifications);
    }

    /**
     * 	Client never received a response
     */
    if (e.request) {
      return onRequestError(e, dataType, shouldHideErrorNotifications);
    }

    return onUnexpectedError(e, dataType, shouldHideErrorNotifications);
  }
};
