import {
  AUTHENTICATION_OPTIONS_KEYS,
  HTTP_METHODS_WITH_BODY,
  BASE_API_OPTIONS
} from './fetch.constants';
import queryString from 'query-string';
import auth from 'shared/utils/auth';

import * as env from 'shared/config';

const AUTHENTICATION_OPTIONS = {
  [AUTHENTICATION_OPTIONS_KEYS.API_KEY]: () => env.apiKey,
  [AUTHENTICATION_OPTIONS_KEYS.REDUX_STATE]: () => auth.getToken()
};

export const getAuthorizationHeader = ({ baseApi, customToken, authenticationType }) => {
  const tokenByPassedType =
    AUTHENTICATION_OPTIONS[authenticationType] && AUTHENTICATION_OPTIONS[authenticationType]();
  const tokenFromReduxState = AUTHENTICATION_OPTIONS[AUTHENTICATION_OPTIONS_KEYS.REDUX_STATE]();
  const tokenFromConfig = AUTHENTICATION_OPTIONS[AUTHENTICATION_OPTIONS_KEYS.API_KEY]();

  const defaultToken = tokenByPassedType || tokenFromReduxState || tokenFromConfig;

  const value = customToken ? customToken : defaultToken;

  if (value && baseApi !== BASE_API_OPTIONS.BASE_API_ABSOLUTE) {
    return value;
  }
};

export const getRequestParams = (method, params, jsonSpaces) => {
  const requestParams = {};

  if (params) {
    if (HTTP_METHODS_WITH_BODY.includes(method.toUpperCase())) {
      // Make the request `data` for POST, PUT, DELETE, PATCH
      // `data` is the data to be sent as the request body
      // Must be of one of the following types:
      // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
      // - Browser only: FormData, File, Blob
      // - Node only: Stream, Buffer
      if (params instanceof FormData || params instanceof URLSearchParams) {
        requestParams['data'] = params;
      } else {
        requestParams['data'] = JSON.stringify(params, null, jsonSpaces || 0);
      }
    } else {
      // Make the request `params` for GET, HEAD
      // `params` are the URL parameters to be sent with the request
      // Must be a plain object or a URLSearchParams object
      if (typeof params !== 'object') {
        requestParams['params'] = queryString.parse(params);
      } else {
        requestParams['params'] = params;
      }

      // We need to pass empty `data` to set the `content-type` in axios GET request.
      // Yeah, it is weired, but axios removes the `content-type` if there is no `data` in request
      // https://github.com/axios/axios/issues/86#issuecomment-139638284
      requestParams['data'] = {};
    }
  }

  return requestParams;
};

export const getRequestHeaders = ({
  headers,
  contentType,
  baseApi,
  customToken,
  authenticationType,
  requestParams
}) => {
  const Authorization = getAuthorizationHeader({
    baseApi,
    customToken,
    authenticationType
  });

  let requestHeaders = {
    ...headers,
    'Content-Type': contentType || 'application/json',
    Authorization: Authorization,
    ...(Boolean(env.wafBypassHeader && env.wafBypassHeaderValue) && {
      [env.wafBypassHeader]: env.wafBypassHeaderValue
    })
  };

  // Since run it on the browser and it is window.FormData instance, set the formData header manually
  // config.data.getHeaders() is not function

  if (requestParams.data instanceof FormData) {
    requestHeaders = {
      ...requestHeaders,
      enctype: 'multipart/form-data'
    };
  }

  return requestHeaders;
};
