import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ACTION_TYPES_SETTINGS,
  ACTION_METHODS,
  ACTION_TYPES,
  NAMES_ACTION,
  ERRORS,
  INFO_HELP
} from '../CrawlSpecCreate.constants';
import Checkbox from "../../../components/Checkbox_NEW";
import ExpandableBtnList from '../HelpComponents/ExpandableBtnList';
import SpecElementsWrapper from "./SpecElementsWrapper";
import Select from "../../../components/ReactSelect/Select";
import TextInput from "../../../components/TextInput";

const Action = ({
  disabled,
  show,
  currentExampleUUID,
  element,
  changeAction,
  changeCurrentExample,
  addNewExample,
  clearExampleElements,
  errorFieldsExamples,
  errorFields
}) => {
  const typeActionOptions = useMemo(
    () => Object.values(ACTION_TYPES).map(
      item => ({ value: item, label: item })
    ),
    []
  );

  const methodActionOptions = useMemo(
    () => Object.values(ACTION_METHODS).map(
      item => ({ value: item, label: item })
    ),
    []
  );

  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changeAction(name, value);
  };

  const ACTION_SETTING = _.get(
    ACTION_TYPES_SETTINGS,
    _.get(element[NAMES_ACTION.TYPE], 'value'),
    []
  );

  return (
    <SpecElementsWrapper
      show={show}
      label="Action"
      tooltip={INFO_HELP.ACTION}
    >
      <Select
        label="Type"
        value={element[NAMES_ACTION.TYPE]}
        onChange={e => changeAction(NAMES_ACTION.TYPE, e)}
        options={typeActionOptions}
        isDisabled={disabled}
        isError={errorFields.includes(NAMES_ACTION.TYPE)}
        errorText={ERRORS.actionType}
      />
      <Select
        label="Method"
        value={element[NAMES_ACTION.METHOD]}
        onChange={e => changeAction(NAMES_ACTION.METHOD, e)}
        options={methodActionOptions}
        isDisabled
        isError={errorFields.includes(NAMES_ACTION.METHOD)}
        errorText={ERRORS.actionMethod}
      />
      <TextInput
        label="Repeat"
        type="number"
        name={NAMES_ACTION.REPEAT}
        value={element[NAMES_ACTION.REPEAT]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_ACTION.REPEAT)}
        errorText={ERRORS.actionRepeat}
      />
      <Checkbox
        label="Use Counter"
        disabled={disabled}
        onClick={checked => changeAction(NAMES_ACTION.COUNTER, checked)}
        checked={element[NAMES_ACTION.COUNTER]}
      />
      <ExpandableBtnList
        label="Examples"
        isDisabled={disabled}
        isDisabledBtn={!ACTION_SETTING.includes(NAMES_ACTION.EXAMPLES)}
        value={element[NAMES_ACTION.EXAMPLES]}
        name={NAMES_ACTION.EXAMPLES}
        addMoreName="Example"
        activeID={currentExampleUUID}
        onChange={changeAction}
        onClickBtn={changeCurrentExample}
        createNewItemTemplate={addNewExample}
        helpRemoveFunc={clearExampleElements}
        errorFields={Object.keys(errorFieldsExamples)}
        isError={
            errorFields.includes(NAMES_ACTION.EXAMPLES)
            && !_.get(element, [NAMES_ACTION.EXAMPLES, 'length'], '')
          }
        errorText={ERRORS.actionExample}
      />
      <TextInput
        label="Path"
        type="number"
        name={NAMES_ACTION.PATH}
        value={element[NAMES_ACTION.PATH]}
        isDisabled={disabled || !ACTION_SETTING.includes(NAMES_ACTION.PATH)}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_ACTION.PATH)}
        errorText={ERRORS.actionPath}
      />
    </SpecElementsWrapper>
  );
};

Action.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentExampleUUID: PropTypes.string,
  element: PropTypes.shape({}),
  changeAction: PropTypes.func.isRequired,
  changeCurrentExample: PropTypes.func.isRequired,
  addNewExample: PropTypes.func.isRequired,
  clearExampleElements: PropTypes.func.isRequired,
  errorFieldsExamples: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Action.defaultProps = {
  disabled: false,
  currentExampleUUID: '',
  show: false,
  element: {},
  errorFieldsExamples: {},
  errorFields: []
};

export default Action;
