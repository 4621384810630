import React, { useState } from "react";
import classnames from 'classnames';
import PropTypes from "prop-types";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Checkbox as BootstrapCheckbox } from "react-bootstrap";
import './_checkbox.scss';

export const TEST_ID = 'checkbox';

const Checkbox = ({
  checked: propsChecked,
  useInternalChecked,
  onChange,
  label,
  className,
  disabled
}) => {
  const [stateChecked, setStateChecked] = useState(null);

  const handleCheckClick = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    const checked = Boolean(e.target.checked);

    setStateChecked(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  const checked = useInternalChecked ? stateChecked: propsChecked;
  return (
    <BootstrapCheckbox
      data-testid={TEST_ID}
      onClick={handleCheckClick}
      onChange={() => {}}
      checked={checked}
      inline
      className={classnames(className, {
        'checkbox-common-component': true,
        'checkbox-common-component--disabled': disabled
      })}
    >
      <div className="checkbox-common-component__wrapper">
        {checked ? (
          <CheckBoxIcon className="checkbox-common-component__icon--checked" />
        ) : (
          <CheckBoxOutlineBlankIcon className="checkbox-common-component__icon--unchecked" />
        )}
        <span className="checkbox-common-component__label">{label}</span>
      </div>
    </BootstrapCheckbox>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  useInternalChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
};

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {},
  useInternalChecked: false,
  disabled: false,
  className: '',
  label: ''
};

export default Checkbox;
