import React from 'react';
import _ from "lodash";
import { Button } from "react-bootstrap";
import { COMMAND_REQUEST_STATUS, commandRequestTypes } from "../../constants/commandRequestTypes";

const CollectionTableBody = ({ command_requests, collections, toggleModal, trainNowCommand }) => {
  const filtered_command_requests = _.reduce(
    command_requests.items,
    (collected_requests, request) => {
      if (
        request
        && request.type
        && request.type.parameters
        && request.type.parameters.type
        && request.type.parameters.type === commandRequestTypes.trainModel
      ) {
        collected_requests[request.parameters.collection_id] = request.status;
      }
      return collected_requests;
    },
    {}
  );

  return collections && collections.map && collections.map(collection => (
    <div
      key={collection.id}
      className="adminToolTableFlexRow"
      onClick={() => {
        toggleModal(true, collection);
      }}
    >
      <div>{collection.name}</div>
      <div>{collection.description}</div>
      <div>{collection.categories.join(', ')}</div>
      <div>{collection.agencies.join(', ')}</div>
      <div>{collection.jurisdictions.join(', ')}</div>
      <div>
        {collection.details && collection.details.start_pub_date}
        -
        {collection.details && collection.details.finish_pub_date}
      </div>
      <div>
        <Button
          disabled={
              filtered_command_requests[collection.id]
              && (
                filtered_command_requests[collection.id] !== COMMAND_REQUEST_STATUS.FAILED
                && filtered_command_requests[collection.id] !== COMMAND_REQUEST_STATUS.COMPLETED
              )
            }
          bsStyle="primary"
          onClick={(e) => {
            e.stopPropagation();
            trainNowCommand(collection);
          }}
        >
          Train Model
        </Button>
      </div>
    </div>
  ))
    || null;
};

export default CollectionTableBody;
