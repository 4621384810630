import React from 'react';
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CrawlCreationExampleIcon from "./CrawlCreationExample";

const CrawlerCreationExamplesRender = ({
  examples,
  metaData={},
  deleteExample,
  updateExample,
  includedExamples,
  stepUpdateElements,
  exampleType,
  error,
  disabled
}) => {
  return (
    <>
      {
        examples
          .filter(example => includedExamples.includes(example.id))
          .map(example => (
            <CrawlCreationExampleIcon
              key={example.id}
              example={example}
              deleteExample={deleteExample}
              updateExample={updateExample}
              error={error}
              disabled={disabled}
            />
          ))
      }
      <Button
        bsStyle="primary"
        className="wideButton"
        disabled={disabled}
        onClick={() => {
          stepUpdateElements(exampleType, metaData);
        }}
      >
        <ChangeHistoryIcon className="buttonIcon" />
        + Add New Example
      </Button>
    </>
  );
};

CrawlerCreationExamplesRender.propTypes = {
  examples: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
    occurrence: PropTypes.string
  })),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  stepUpdateElements: PropTypes.func.isRequired,
  metaData: PropTypes.shape({}),
  includedExamples: PropTypes.arrayOf(PropTypes.string),
  exampleType: PropTypes.string,
  deleteExample: PropTypes.func.isRequired,
  updateExample: PropTypes.func.isRequired
};

CrawlerCreationExamplesRender.defaultProps = {
  examples: [],
  error: false,
  disabled: false,
  metaData: {},
  includedExamples: [],
  exampleType: ''
};

export default CrawlerCreationExamplesRender;
