import React from "react";
import PropTypes from "prop-types";
import TextInput from "../../../components/TextInput";
import { GLASSDOOR_CONSTANTS_TABLE } from './Glassdoor.constants';

const GlassdoorHeader = ({
  changeFilter,
  search,
  toggleSearch
}) => {
  const handleChangesEvent = e => {
    const { name, value } = e.target;
    changeFilter(name, value);
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') toggleSearch();
  };

  return (
    <div className="glassdoor_header">
      <TextInput
        className="glassdoor_header_multi-input"
        placeholder="Search Source"
        onChange={handleChangesEvent}
        name={GLASSDOOR_CONSTANTS_TABLE.searchInput}
        value={search[GLASSDOOR_CONSTANTS_TABLE.searchInput]}
        onKeyPress={handleEnterPress}
      />
    </div>
  );
};

GlassdoorHeader.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  search: PropTypes.shape({})
};

GlassdoorHeader.defaultProps = {
  search: {}
};

export default GlassdoorHeader;
