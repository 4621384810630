import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  AUTOCOMPLETE,
  BUTTON,
  CALENDAR,
  GROUP_OF_FIELDS,
  INPUT,
  PHONE,
  SELECT,
  TEXTFIELD
} from '../../../../constants/fieldTypes';
import MSelect from 'admin/components/MaterialComponents/Select/mSelect';
import MAutocomplete from 'admin/components/MaterialComponents/Autocomplete/mAutocomplete';
import CDatePicker from 'admin/components/MaterialComponents/DatePicker/DatePicker';
import MButton from 'admin/components/MaterialComponents/Buttons/Button/mButton';
import MTextField from 'admin/components/MaterialComponents/TextField/mTextField';
import {
  changeGeneralTableValues,
  addAdditionalPremiumContentSource,
  removePremiumContentSource,
  changePremiumContentSource
} from 'shared/features/userModal/userModal.reducer';
import { colors } from 'admin/components/MaterialComponents/colors/colors';
import NotEditModeField from './NotEditModeFields';
import { sendConfirmationEmail } from '../../../../../shared/features/user/user.actions';
import { resetPasswordAC } from '../../../../../shared/features/resetPasswod/resetPassword.actions';
import MPhoneInput from 'admin/components/MaterialComponents/PhoneInput/MPhoneInput';
import {
  RESEND_CONFIRMATION_EMAIL_LABEL,
  RESET_PASSWORD_LABEL,
  PROVIDER_LABEL,
  START_DATE_LABEL
} from 'shared/features/userModal/labelConstants';

const useStyle = makeStyles({
  defaultBlueButton: {
    color: colors.BLUE_MAIN,
    marginRight: 10,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: colors.BLUE_TRANSPARENT,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.BACKGROUND_BLUE
    }
  },
  disabledTableItem: {
    borderRadius: 5,
    marginRight: 15,
    '& .jurispect-MuiInputBase-root': {
      backgroundColor: colors.WHITE,
      borderRadius: 5
    },
    '& .jurispect-MuiSelect-icon': {
      marginTop: 6
    },
    '& .jurispect-MuiInput-input': {
      color: colors.BLACK_MAIN,
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      paddingLeft: 5,
      backgroundColor: colors.WHITE,
      height: 20
    }
  },
  tableItem: {
    border: `1px solid ${colors.GREY_LIGHT}`,
    borderRadius: 5,
    '& .jurispect-MuiInputBase-root': {
      backgroundColor: colors.BACKGROUND_MEDIUM,
      borderRadius: 5
    },
    '& .jurispect-MuiSelect-icon': {
      marginTop: 6
    },
    '& .jurispect-MuiInput-input': {
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      paddingLeft: 5,
      backgroundColor: colors.BACKGROUND_MEDIUM,
      height: 20
    }
  },
  labelClass: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.GREY_MEDIUM
  },
  multiSelectorDisabled: {
    background: 'none !important',
    border: 'none !important'
  },
  multiSelector: {
    backgroundColor: colors.BACKGROUND_MEDIUM,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    minHeight: 33,
    border: `1px solid ${colors.GREY_LIGHT}`,
    '& .jurispect-MuiAutocomplete-tag': {
      paddingBottom: 5,
      paddingTop: 5,
      backgroundColor: colors.BLUE_MAIN,
      color: colors.WHITE
    }
  },
  calendarClass: {
    '& .react-date-picker__wrapper': {
      height: 35,
      backgroundColor: colors.BACKGROUND_MEDIUM,
      border: `1px solid ${colors.GREY_LIGHT}`,
      borderRadius: 5
    }
  },
  phoneClass: {
    background: colors.WHITE,
    '& .input': {
      height: '100%'
    },
    '&.input': {
      height: '100%'
    }
  },
  groupWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  groupRowWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  removeIcon: {
    color: colors.RED_TOMATO,
    fontSize: 26,
    margin: '5px 20px'
  },
  removeIconFirst: {
    color: colors.RED_TOMATO,
    fontSize: 26,
    margin: '15px 20px 0px 20px'
  },
  addIcon: {
    color: colors.BLUE_MAIN,
    fontSize: 26
  }
});

const EditModeFields = ({
  rowField,
  currentEmail,
  reduxSendConfirmationEmail,
  reduxResetPasswordAC,
  reduxChangeGeneralTableValues,
  reduxAddAdditionalPremiumContentSource,
  reduxRemovePremiumContentSource,
  editMode,
  location,
  reduxChangePremiumContentSource
}) => {
  const classes = useStyle();

  const returnCorrectSelectValue = value => {
    if (value) {
      if (typeof value.name === 'string') return value.name;
      return value;
    }
    return '';
  };

  const onRowFieldChange = (responseName, value) => {
    const payload = {
      responseName,
      value
    };
    reduxChangeGeneralTableValues(payload);
  };

  const buttonFieldActions = label => {
    if (label === RESEND_CONFIRMATION_EMAIL_LABEL) {
      reduxSendConfirmationEmail(currentEmail);
    }
    if (label === RESET_PASSWORD_LABEL) {
      reduxResetPasswordAC(currentEmail);
    }
  };

  const renderValue = field => {
    if (!field || !field.value) return 'No data';
    if (typeof field.value === 'string') {
      return field.value;
    }
    if (field.value && field.value.name) {
      return field.value.name;
    }
    return field.value.label || '';
  };

  if (!editMode) {
    return (
      <NotEditModeField
        buttonFieldActions={buttonFieldActions}
        rowField={rowField}
        renderValue={renderValue}
        onRowFieldChange={onRowFieldChange}
      />
    );
  }

  switch (rowField.type) {
    case INPUT: {
      return (
        <MTextField
          fullWidth
          labelClass={classes.labelClass}
          className={classes.tableItem}
          label={_.get(rowField, 'label', '')}
          value={rowField && rowField.value}
          onChange={event => {
            onRowFieldChange(rowField.responseName, event.target.value);
          }}
        />
      );
    }
    case SELECT: {
      return (
        <MSelect
          fullWidth
          selectClass={classes.tableItem}
          labelClass={classes.labelClass}
          label={_.get(rowField, 'label', '')}
          onChange={value => {
            onRowFieldChange(rowField.responseName, value);
          }}
          options={rowField && rowField.options}
          value={returnCorrectSelectValue(rowField && rowField.value)}
        />
      );
    }
    case AUTOCOMPLETE: {
      return (
        <MAutocomplete
          disableUnderline
          fullWidth
          multiple
          label={_.get(rowField, 'label', '')}
          labelClass={classes.labelClass}
          desiredProperty={rowField.desiredProperty}
          classNameTextField={classes.multiSelector}
          onChange={value => onRowFieldChange(rowField.responseName, value)}
          options={rowField.options}
          size="small"
          value={returnCorrectSelectValue(rowField && rowField.value)}
        />
      );
    }
    case CALENDAR: {
      return (
        <CDatePicker
          labelClass={classes.labelClass}
          fullWidth
          calendarClass={classes.calendarClass}
          textfieldClass={classes.calendarClass}
          value={_.get(rowField, 'value')}
          label={_.get(rowField, 'label', '')}
          onChange={value => {
            onRowFieldChange(rowField.responseName, value);
          }}
        />
      );
    }
    case BUTTON: {
      return (
        <MButton
          onClick={() => buttonFieldActions(rowField.label)}
          classes={classes.defaultBlueButton}
        >
          {_.get(rowField, 'label', '')}
        </MButton>
      );
    }
    case PHONE: {
      return (
        <MPhoneInput
          fullWidth
          labelClass={classes.labelClass}
          className={classes.phoneClass}
          label={_.get(rowField, 'label', '')}
          value={_.get(rowField, 'value')}
          onChange={value => {
            onRowFieldChange(rowField.responseName, value);
          }}
        />
      );
    }
    case TEXTFIELD: {
      return (
        <MTextField
          labelClass={classes.labelClass}
          className={classes.disabledTableItem}
          label={_.get(rowField, 'label', '')}
          value={renderValue(rowField && rowField)}
          disabled
        />
      );
    }
    case GROUP_OF_FIELDS: {
      return (
        <div className={classes.groupWrapper}>
          {rowField.value.map((premium, index) => (
            <div className={classes.groupRowWrapper}>
              <MSelect
                fullWidth
                selectClass={classes.tableItem}
                labelClass={classes.labelClass}
                onChange={value => {
                  reduxChangePremiumContentSource({ value, index });
                }}
                label={index === 0 && PROVIDER_LABEL}
                options={rowField.options}
                value={premium.premium_content_source_name}
              />
              <RemoveCircleOutlineIcon
                onClick={() => reduxRemovePremiumContentSource(index)}
                className={index === 0 ? classes.removeIconFirst : classes.removeIcon}
              />
              <MTextField
                label={index === 0 && START_DATE_LABEL}
                labelClass={classes.labelClass}
                className={classes.disabledTableItem}
                value={premium.created_at}
                disabled
              />
            </div>
          ))}
          <AddCircleOutlineIcon
            className={classes.addIcon}
            onClick={() => reduxAddAdditionalPremiumContentSource()}
          />
        </div>
      );
    }
    default:
      return null;
  }
};

const mapStateToProps = state => ({
  currentEmail: state.userModal.initialUserData.email
});

EditModeFields.propTypes = {
  rowField: PropTypes.objectOf(PropTypes.any).isRequired,
  currentEmail: PropTypes.string.isRequired,
  reduxSendConfirmationEmail: PropTypes.func.isRequired,
  reduxResetPasswordAC: PropTypes.func.isRequired,
  reduxChangeGeneralTableValues: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, {
  reduxChangeGeneralTableValues: changeGeneralTableValues,
  reduxSendConfirmationEmail: sendConfirmationEmail,
  reduxResetPasswordAC: resetPasswordAC,
  reduxAddAdditionalPremiumContentSource: addAdditionalPremiumContentSource,
  reduxRemovePremiumContentSource: removePremiumContentSource,
  reduxChangePremiumContentSource: changePremiumContentSource
})(EditModeFields);
