import type { CategoryEditModalData } from './useCategoryEditModalData.types';
import type { NormalizedCaiCategory } from 'shared/features/categories/caiCategories/caiCategories.apiV2.types';
import type { CategoryEditModalProps } from '../CategoryEditModal.types';

export const formatEditRequestParams = ({
  category,
  localState
}: {
  category: CategoryEditModalProps['category'];
  localState: CategoryEditModalData['localState'];
}): NormalizedCaiCategory => {
  let params = {};
  if (localState?.caiCategoryName && localState?.caiCategoryName !== category?.name) {
    params = { ...params, name: localState?.caiCategoryName };
  }
  if (
    localState?.caiCategoryDescription &&
    localState?.caiCategoryDescription !== category?.description
  ) {
    params = { ...params, description: localState?.caiCategoryDescription };
  }
  if (localState?.surfaceInFilter !== category?.surface_in_filter) {
    params = { ...params, surface_in_filter: localState?.surfaceInFilter };
  }
  if (
    localState?.docMetaCategory?.value &&
    localState?.docMetaCategory?.value !== category?.meta_category?.id
  ) {
    params = { ...params, doc_meta_category_id: localState?.docMetaCategory?.value };
  }
  return params;
};
