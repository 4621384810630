import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  createMetaCategories,
  updateMetaCategories,
  removeMetaCategories
} from "shared/features/categories/metaCategories/metaCategories.actions";
import {
  getFormatMetaCategoryInfo,
  fromMetaCategoryToRawInfo
} from "./DocumentMetaCategories.helper";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Switch from "../../components/Switch";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";
import { TABLE_VARIABLES } from "./DocumentMetaCategories.constants";

const DocumentMetaCategoriesModal = ({
  show,
  onHide,
  currentMetaCategory
}) => {
  const dispatch = useDispatch();

  const [metaCategory, setMetaCategory] = useState({});

  useEffect(() => {
    setMetaCategory(getFormatMetaCategoryInfo(currentMetaCategory));
  }, [show, currentMetaCategory]);

  const handleChanges = (name, value) => setMetaCategory({ ...metaCategory, [name]: value });

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const updateMetaCategory = () => {
    const metaCategoryChanges = fromMetaCategoryToRawInfo(metaCategory, currentMetaCategory);
    if (metaCategoryChanges) {
      const metaCategoryId = currentMetaCategory.id;
      if (metaCategoryId) {
        dispatch(updateMetaCategories({ ...metaCategoryChanges, id: metaCategoryId }));
      } else {
        dispatch(createMetaCategories(metaCategoryChanges));
      }
      onHide();
    }
  };

  const deleteMetaCategory = () => {
    const metaCategoryId = currentMetaCategory.id;
    if (metaCategoryId) {
      dispatch(removeMetaCategories({ id: metaCategoryId }));
      onHide();
    }
  };

  return (
    <Modal
      className="document-categories_edit-modal"
      title={`Document Meta Category`}
      show={show}
      onHide={onHide}
      size={'small'}
    >
      <div className="document-categories_edit-modal_body">
        <TextInput
          label="Name"
          name={TABLE_VARIABLES.name}
          value={metaCategory[TABLE_VARIABLES.name]}
          onChange={handleChangesEvent}
        />
        <Textarea
          label="Description"
          name={TABLE_VARIABLES.description}
          value={metaCategory[TABLE_VARIABLES.description]}
          onChange={handleChangesEvent}
        />
        <Switch
          name={TABLE_VARIABLES.app_filter}
          label="Shoe in app filter"
          value={metaCategory[TABLE_VARIABLES.app_filter]}
          onChange={handleChangesEvent}
        />
      </div>
      <div className="document-categories_edit-modal_footer">
        <Button
          onClick={updateMetaCategory}
          color="blue"
        >
          Save
        </Button>
        <Button onClick={onHide} color="highlighted">Cancel</Button>
        <Button
          onClick={deleteMetaCategory}
          color="error"
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

DocumentMetaCategoriesModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  currentMetaCategory: PropTypes.shape({
    id: PropTypes.number
  })
};

DocumentMetaCategoriesModal.defaultProps = {
  show: false,
  currentMetaCategory: {}
};

export default DocumentMetaCategoriesModal;
