import type { FC } from 'react';
import type { EitlLabelsSectionProps } from './EitlLabelsSection.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { EitlLabelsSelect } from 'admin/components/EitlLabelsSelect';

import { useEitlLabelsSectionHandlers } from './hooks';

import './_eitl-labels-section.scss';

export const EitlLabelsSection: FC<EitlLabelsSectionProps> = ({ isDisabled, labels, onChange }) => {
  const handlers = useEitlLabelsSectionHandlers({
    props: {
      labels,
      onChange
    }
  });

  return (
    <div className="eitl-labels-section">
      <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>EITL</uiLib.Typography>
      <uiLib.LabeledField label="EITL Labels">
        <EitlLabelsSelect
          value={labels}
          onChange={handlers.handleEitlLabelsChange}
          isDisabled={isDisabled}
          onEitlLabelCreate={handlers.handleEitlLabelCreate}
        />
      </uiLib.LabeledField>
    </div>
  );
};
