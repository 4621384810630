export const TIME_SCHEME = 'M/D/YYYY';

// ----- TABLE CONSTANTS -----
export const GLASSDOOR_CONSTANTS_TABLE = {
  searchInput: 'multi_input'
};

export const SOURCE_TYPES = {
  PREMIUM: 'premium',
  NEWS_SOURCE: 'news_source',
  AGENCY: 'agency'
};

export const JUR_CONST = {
  NAME: 'name',
  SHORT_NAME: 'short_name',
  ID: 'id',
  DOC_COUNT: 'document_count'
};

export const SRS_CONST = {
  NAME: 'name',
  SHORT_NAME: 'short_name',
  ID: 'id',
  STATUS: 'status',
  DOC_COUNT: 'document_count',
  TYPE: 'type',
  ISSUE: 'issue'
};

export const CAT_CONST = {
  NAME: 'name',
  ID: 'id',
  DOC_COUNT: 'doc_count',
  SCRAPPED_COUNT: 'count_scrapped'
};

export const SCRAP_CAT_CONST = {
  NAME: 'name',
  ID: 'id',
  JURISDICTION_ID: 'jurisdiction_id'
};

export const CRL_CONST = {
  SPIDER_NAME: 'spider_name',
  CRAWL_LINK: 'crawl_link',
  ID: 'id',
  TYPE: 'type',
  ISSUE: 'issue',
  CAI_CATEGORY: 'cai_category',
  SCRAPPED_CATEGORY: 'scrapped_category',
  LAST_CHECK: 'last_check',
  CREATED_AT: 'created_at',
  QA_STATUS: 'qa_status',
  ISSUE_TYPE: 'issue_type',
  ISSUE_IDENTIFIED: 'issue_identified',
  ORIGINAL_ETA: 'original_eta',
  JIRA_TICKET: 'jira_ticket',
  JIRA_LINK: 'jira_link',
  JIRA_STATUS: 'jira_status',
  // this fields used for badge (name are important)
  STATUS: 'status',
  IS_ACTIVE: 'is_active'
};

export const CRL_STATUS = {
  OK: 'Crawl OK',
  SOURCE_OK: 'Source OK',
  MISSING_LESS_5_DOCS: 'Missing "<5" Docs',
  MISSING_MORE_5_DOCS: 'Missing ">5" Docs'
};
