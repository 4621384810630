import type { ComponentProps } from 'react';
import type * as uiLib from '@compliance.ai/web-components';

import * as elements from './elements';

export enum TABLE_KEY {
  ISSUE = 'issue',
  NUMBER_OF_ISSUES_OR_TIME = 'numberOfIssuesOrTime'
}

export const TABLE_LOCAL_STORAGE_KEY = 'logsModalTable';

export const COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Issue',
    dataKey: TABLE_KEY.ISSUE,
    isInitiallyVisible: true,
    isDynamic: false
  },
  {
    title: 'Number of issues or time',
    dataKey: TABLE_KEY.NUMBER_OF_ISSUES_OR_TIME,
    isInitiallyVisible: true,
    isDynamic: false,
    className: 'logs-modal__table-cell',
    Component: elements.NumberOfIssuesCell
  }
];
