import type { CommandRequestsTableConfig } from './useCrawlerQAReportsTableConfig.types';
import type { CrawlQAReportsHandlers } from './useCrawlerQAReportsHandlers.types';

import * as uiLib from '@compliance.ai/web-components';
import * as commandRequestsConstants from 'admin/constants/commandRequests';
import * as elements from '../elements';
import { TableQAReport } from '../CrawlerQAReports.types';

export const useCrawlerQAReportsTableConfig = ({
  events
}: {
  events: {
    onDelete: CrawlQAReportsHandlers['handleDelete'];
  };
}): CommandRequestsTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'ID',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.ID,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.ID,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Created At',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.CREATED_AT,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.CREATED_AT,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.DateCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Updated At',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPDATED_AT,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.DateCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Command Name',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.NAME,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.NAME,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Type',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.TYPE,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.TYPE,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Status',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Status Details',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS_DETAILS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS_DETAILS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'User Id',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER_ID,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER_ID,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'User',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'QA File Name',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.QA_URL_FILE_NAME,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.QA_URL_FILE_NAME,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'QA File',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.QA_URL,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.QA_URL,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Upload Link',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPLOAD_LINK,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPLOAD_LINK,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Passed docs',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.SUCCEEDED_DOCS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.SUCCEEDED_DOCS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Failed docs',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.FAILED_DOCS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.FAILED_DOCS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Parameters',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.PARAMETERS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.PARAMETERS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    }
  ];

  const actions: (uiLib.RowAction | uiLib.ColumnAction)[] = [
    {
      title: 'Download',
      shouldPreventRefreshOnTrigger: false,
      icon: <uiLib.IconDownload />,
      render: (category: uiLib.Row) => (
        <elements.CrawlerQADownloadAction category={category as TableQAReport} />
      ),
      isRowAction: true,
      disableColumn: true
    } as uiLib.RowAction,
    {
      title: 'Delete',
      shouldPreventRefreshOnTrigger: true,
      icon: <uiLib.IconDelete />,
      trigger: events.onDelete,
      isRowAction: true,
      disableColumn: true
    } as uiLib.RowAction
  ];

  return {
    columns,
    actions
  };
};
