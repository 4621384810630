import moment from 'moment';

import {
  REQUEST_DOC_TASKS_BY_STATUS,
  RECEIVE_DOC_TASKS_BY_STATUS,
  RECEIVE_DOC_TASKS_BY_DATE,
  CLEAR_DOC_TASKS,
  RECEIVE_ALL_WORKFLOWS
} from './docTasks.actions';

export const getInitialDocTasksByStatusState = () => ({ docTasks: {} });

export const docTasksByStatus = (state = getInitialDocTasksByStatusState(), action) => {
  switch (action.type) {
    case REQUEST_DOC_TASKS_BY_STATUS: {
      const { assignee_id } = action;
      const updatedDocTasks = action.queries.reduce(
        (docTasks, query) => {
          if (
            docTasks[assignee_id]
            && docTasks[assignee_id][query.status]
            && docTasks[assignee_id][query.status].doc_tasks
          ) {
            docTasks[assignee_id][query.status] = {
              ...docTasks[assignee_id][query.status],
              doc_tasks: null
            };
          }
          return docTasks;
        },
        state.docTasks
      );

      return {
        ...state,
        docTasks: updatedDocTasks
      };
    }
    case RECEIVE_DOC_TASKS_BY_STATUS:
      return {
        ...state,
        docTasks: {
          ...state.docTasks,
          [action.response.assignee_id]: {
            ...state.docTasks[action.response.assignee_id],
            ...action.response.doc_tasks_by_status
          }
        }
      };
    case CLEAR_DOC_TASKS:
      return getInitialDocTasksByStatusState();
    default:
      return state;
  }
};

export const getInitialDocTasksByDateState = () => ({ formattedDocTasks: [] });

export const docTasksByDate = (state = getInitialDocTasksByDateState(), action) => {
  switch (action.type) {
    case RECEIVE_DOC_TASKS_BY_DATE: {
      const formattedDocTasks = action.response.doc_tasks_by_date.doc_tasks.map(({
        id: doc_task_id,
        workflow_id,
        deadline,
        name: task_name,
        properties,
        task_status: status,
        doc_id,
        assignee_email: email
      }) => ({
        doc_task_id,
        workflow_id,
        start: moment(deadline),
        end: moment(deadline),
        title: (
          (properties.document && properties.document.title)
            ? `${task_name} – ${properties.document.title}`
            : task_name
        ),
        allDay: true,
        status,
        doc_id,
        task_name,
        deadline,
        email
      }));

      return {
        ...state,
        formattedDocTasks
      };
    }
    case CLEAR_DOC_TASKS:
      return getInitialDocTasksByDateState();
    default:
      return state;
  }
};

export const getInitialAllWorkflowsState = () => ({ items: [] });

export const allWorkflows = (state = getInitialAllWorkflowsState(), action) => {
  switch (action.type) {
    case RECEIVE_ALL_WORKFLOWS:
      return {
        ...state,
        items: action.response.all_workflows.map(({ name, id, deleted }) => ({
          label: deleted ? `(deleted) ${name}` : name,
          value: id
        }))
      };
    default:
      return state;
  }
};
