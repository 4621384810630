import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.actions';
import { formatSearchValues } from "./JurisdictionTool.helper";
import Typography from "../../components/Typography";
import JurisdictionToolFilter from "./JurisdictionToolFilter";
import JurisdictionToolButtons from "./JurisdictionToolButtons";
import JurisdictionToolTable from "./JurisdictionToolTable";
import JurisdictionToolFooter from "./JurisdictionToolFooter";
import JurisdictionToolModal from "./JurisdictionToolModal";

const JurisdictionTool = () => {
  const dispatch = useDispatch();
  const jurisdictions = useSelector(state => state.jurisdictionsData);

  // table settings
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(7);
  const [search, setSearch] = useState({});
  // modal settings
  const [jurisdictionModal, setJurisdictionModal] = useState(false);
  const [jurisdiction, setJurisdiction] = useState({});

  const jurisdictionsRequest = () => {
    dispatch(getJurisdictions({
      offset: (page - 1) * limit,
      limit,
      ...formatSearchValues(search)
    }));
  };

  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(jurisdictionsRequest, [page, limit]);

  const setSearchValues = (name, value) => {
    setSearch({
      ...search,
      [name]: value
    });
  };

  const openJurisdictionModal = (row, index) => {
    setJurisdiction(_.get(jurisdictions, ['items', index], {}));
    setJurisdictionModal(true);
  };

  return (
    <div className="jurisdiction">
      <Typography variant="h4">Jurisdiction Tool</Typography>
      <hr />
      <JurisdictionToolFilter
        jurisdictions={jurisdictions.items}
        setSearchValues={setSearchValues}
        search={search}
      />
      <hr />
      <JurisdictionToolButtons
        createJurisdiction={openJurisdictionModal}
        filter={jurisdictionsRequest}
      />
      <JurisdictionToolTable
        jurisdictions={jurisdictions.items}
        setJurisdictionModal={openJurisdictionModal}
      />
      <JurisdictionToolFooter
        onChangePage={setPage}
        onChangeLimit={setLimit}
        limit={limit}
        pageQuantity={Math.ceil(jurisdictions.count / limit)}
      />
      <JurisdictionToolModal
        show={jurisdictionModal}
        onHide={() => setJurisdictionModal(false)}
        currentJurisdiction={jurisdiction}
      />
    </div>
  );
};

export default JurisdictionTool;
