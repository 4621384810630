import React from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import updated from 'shared/images/misc/updated.png';

export default [
  { value: 'new_feature', label: 'New Feature', icon: <PlayArrowIcon /> },
  {
    value: 'content_update',
    label: 'Content Update',
    icon: <img src={updated} alt='updated' className='app-notification-header-updated-img' />
  }
];
