import type { FC } from 'react';
import type { LogsModalProps } from './LogsModal.types';

import * as uiLib from '@compliance.ai/web-components';

import { useLogsModalData, useLogsModalHandlers } from './hooks';

import './_logs-modal.scss';

export const LogsModal: FC<LogsModalProps> = ({ title, children }) => {
  const { localState, localActions } = useLogsModalData();

  const handlers = useLogsModalHandlers({
    localActions
  });

  return (
    <>
      {localState.isOpen && (
        <uiLib.Modal
          title={title}
          isOpen
          onClose={handlers.handleModalToggle(false)}
          shouldShowFullscreenButton
          classNames={{
            container: 'logs-modal'
          }}
        >
          {children}
        </uiLib.Modal>
      )}
      <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleModalToggle(true)}>
        View
      </uiLib.Button>
    </>
  );
};
