import type { DocsLinkCellData } from './useDocsLinkCellData.types';
import type { DocsLinkCellHandlers } from './useDocsLinkCellHandlers.types';

import type * as uiLib from '@compliance.ai/web-components';

export const useDocsLinkCellHandlers = ({
  localActions
}: {
  localActions: DocsLinkCellData['localActions'];
}): DocsLinkCellHandlers => {
  const handleModalToggle: DocsLinkCellHandlers['handleModalToggle'] = isOpen => () => {
    localActions.setIsModalOpen(isOpen);
  };

  return {
    handleModalToggle
  };
};
