import { fetch, BASE_API_OPTIONS } from 'fetch';

export function api_getDocumentsScrapedCategory(params) {
  return fetch({
    url: `/document-scraped-categories`,
    method: 'GET',
    dataType: 'Document Scraped Category',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_getCitations(params) {
  return fetch({
    url: `/document-citation`,
    dataType: 'Document Citations',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_getDocumentsStatuses(params) {
  return fetch({
    url: `/document-data-status`,
    method: 'GET',
    dataType: 'Document',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_getDocumentsInteractionStatuses(params) {
  return fetch({
    url: `/document-data-interaction-status`,
    method: 'GET',
    dataType: 'Document',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_getDocuments(params) {
  return fetch({
    url: `/document-data`,
    dataType: 'Document',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

// Looks like this request don't exist on jurispect-data
export function api_postDocuments(params) {
  return fetch({
    url: `/document-data`,
    dataType: 'Document',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putDocuments(params) {
  return fetch({
    url: `/document-data`,
    dataType: 'Document',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteDocuments(params) {
  return fetch({
    url: `/document-data`,
    dataType: 'Document',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
