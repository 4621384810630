import type { NewsSourcesData } from './useNewsSourcesData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo, useState } from 'react';

export const useNewsSourcesData = (): NewsSourcesData => {
  const [filters, setFilters] = useState<NewsSourcesData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<NewsSourcesData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: 50
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<NewsSourcesData['localState']['reloadTrigger']>(false);

  const localState: NewsSourcesData['localState'] = {
    filters,
    tableParams,
    reloadTrigger
  };

  const localActions: NewsSourcesData['localActions'] = useMemo(
    () => ({
      setFilters,
      setTableParams,
      setReloadTrigger
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
