import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Checkbox } from 'react-bootstrap';
import { fetchCurrentUser } from 'shared/features/user/user.actions';
import {
  fetchOrganization, removeTeamMember, updateTeamMember
} from 'shared/features/organizations/organizations.actions';
import { ADMIN } from "../../../app/constants/UserRoles";


class OrgTeamMember extends React.Component {
  constructor(props) {
    super(props);
  }

  toggleAdminRole = (checked) => {
    const member_team_roles = this.props.member.team_user_roles || [];
    const new_team_roles = this.props.member.team_user_roles.includes(ADMIN)
      ? this.props.member.team_user_roles.filter(role => role !== ADMIN)
      : [...member_team_roles, ADMIN];

    this.props.updateTeamMember(
      this.props.member.team_id,
      this.props.member.user_id,
      new_team_roles
    ).then(() => {
      this.props.fetchOrganization(this.props.organization.organization.id);
    });
  };

  removeTeamMember = () => {
    this.props.removeTeamMember(
      this.props.member.team_id,
      this.props.member.user_id
    ).then(() => {
      this.props.fetchOrganization(this.props.organization.organization.id);
    });
  }

  render() {
    if (
      !this.props.member
    ) {
      return null;
    }
    const memberName = this.props.member.last_name ? (
      `${_.upperFirst(this.props.member.first_name)} ${_.upperFirst(this.props.member.last_name)}`
    ) : _.upperFirst(this.props.member.first_name);

    return (
      <div className="team-member">
        <div className="member-info">
          <div className="member-name">
            {`${memberName}${this.props.member.active ? '' : ' (Expired User)'}`}
          </div>
          <div className="email-address">
            {this.props.member.email}
          </div>
        </div>
        <div className="member-tool">
          <div className="modify-role">
            <Checkbox
              onChange={(e) => this.toggleAdminRole(e.target.checked)}
              checked={this.props.member.team_user_roles.includes(ADMIN)}
              inline
            >
              <i className="material-icons unchecked clickable">check_box_outline_blank</i>
              <i className="material-icons checked clickable">check_box</i>
            </Checkbox>
            <label>{'Admin'}</label>
          </div>
          <div className="remove-member" onClick={this.removeTeamMember}>
            Remove
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ current_user, organization }) => {
  return { current_user, organization };
};

export default connect(mapStateToProps, {
  fetchCurrentUser,
  fetchOrganization,
  removeTeamMember,
  updateTeamMember
})(OrgTeamMember);
