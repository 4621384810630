import * as actions from '../crawlerHistory.actions';

import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useCrawlerHistoryReduxActions = () => {
  const dispatch = useDispatch();

  const clearCrawHistory = useCallback(() => {
    return dispatch(actions.clearCrawHistory());
  }, [dispatch]);

  const getHistory = useCallback(
    (...params) => {
      return dispatch(actions.getCrawlerHistoryAC(...params));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      clearCrawHistory,
      getHistory
    }),
    [clearCrawHistory, getHistory]
  );
};
