import React, { useCallback } from 'react';
import { AuthFormAlert } from '../AuthFormAlert';
import { useAuthFormAlertData } from './useAuthFormAlertData';

export const useAuthFormAlert = () => {
  const { localState, localActions } = useAuthFormAlertData();

  const AlertComponent = useCallback(
    props => <AuthFormAlert {...props} message={localState.message} />,
    [localState.message]
  );

  return {
    Alert: AlertComponent,
    alertActions: localActions
  };
};
