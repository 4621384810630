import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'glassdoorData';
export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const agenciesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setGlassdoor: (state, action) => action.payload,
    createGlassdoor: (state, action) => ({
      ...state,
      count: state.count+1,
      items: [...state.items, action.payload]
    }),
    updateGlassdoor: (state, action) => ({
      ...state,
      items: state.items.map(item => item.id === action.payload.id ? action.payload : item)
    }),
    deleteGlassdoor: (state, action) => ({
      ...state,
      count: state.count-1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default agenciesReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setGlassdoor,
  createGlassdoor,
  updateGlassdoor,
  deleteGlassdoor
} = agenciesReducer.actions;
