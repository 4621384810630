import type * as documentDataTypes from 'shared/features/documentData/documentData.types';

import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';

export const getUniqueDocumentsEitlLabels = (
  documents: documentDataTypes.DocumentDataFromResponse[]
) => {
  const allEitlLabels = documents
    .map(document => document?.eitl_labels ?? [])
    .map(eitlLabels => eitlLabels.map(eitlLabelsApiHelpers.formatEitlLabelsOption))
    .flat();

  return Object.values(
    Object.fromEntries(
      allEitlLabels.map(eitlLabelOption => [eitlLabelOption.value, eitlLabelOption])
    )
  );
};
