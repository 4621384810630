import type * as apiTypes from './commands.apiv2.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchCommandRequestsData: apiTypes.FetchCommandRequestsData = async params => {
  return fetch({
    url: `/command_requests`,
    dataType: 'command_requests',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const fetchCommandRequestsStatusData: apiTypes.FetchCommandRequestsStatusData =
  async params => {
    return fetch({
      url: `/command_requests/status/`,
      dataType: 'command_requests_status',
      method: 'GET',
      baseApi: BASE_API_OPTIONS.BASE_API_DATA,
      params
    });
  };

export const fetchCommandsData: apiTypes.FetchCommandsData = async params => {
  return fetch({
    url: `/commands/`,
    dataType: 'commands',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
