import type { ComponentProps } from 'react';
import * as uiLib from '@compliance.ai/web-components';
import * as helpers from '../CrawlMonitorTool.helper';

/**
 *
 * This hooks transforms the data received in handleCrawlsFetch
 * and transforms it to the expected format of the table column renderers
 *
 */
export const useCrawlMonitorToolDataSelector = () => {
  return (
    data: Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results?.map(helpers.getCrawlInfo)
    };
  };
};
