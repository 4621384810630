import type { GlobalState } from 'shared/reducers';
import type { BulkEditModalData } from './useBulkEditModalData.types';

import * as hookConstants from './useBulkEditModalData.constants';
import * as userSelectors from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBulkEditModalData = (): BulkEditModalData => {
  const [rules, setRules] = useState<BulkEditModalData['localState']['rules']>(
    hookConstants.BULK_EDIT_MODAL_INITIAL_RULES
  );

  const localState: BulkEditModalData['localState'] = {
    rules
  };

  const localActions: BulkEditModalData['localActions'] = useMemo(
    () => ({
      setRules
    }),
    []
  );

  const reduxState: BulkEditModalData['reduxState'] = useSelector<
    GlobalState,
    BulkEditModalData['reduxState']
  >(state => ({
    currentUserId: userSelectors.getCurrentUserId(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
