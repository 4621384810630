import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes, { arrayOf } from 'prop-types';
import { colors } from '../colors/colors';

const allyProps = index => {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  };
};

const useStyles = makeStyles({
  baseStyle: {
    '& .jurispect-MuiPaper-root': {
      backgroundColor: colors.WHITE,
      color: colors.BLACK_MAIN
    },
    '& .jurispect-MuiPaper-elevation4': {
      boxShadow: 'none'
    }
  },
  wrapper: {
    display: 'flex',
    background: '#F0F0F0',
    boxShadow: '0 1px 0 0 #DDDDDD',
    justifyContent: 'space-between'
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row'
  },
  indicator: {
    backgroundColor: colors.BLUE_MAIN
  },
  tab: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    '& span': {
      fontSize: 20,
      lineHeight: '20px',
      color: colors.GREY_DARK,
      textTransform: 'none'
    },
    '& .jurispect-Mui-selected span': {
      color: colors.BLUE_MAIN
    },
    '& button': {
      marginRight: 50,
      padding: 0,
      minWidth: 'auto'
    }
  }
});

const MTabs = ({
  additionalInfo,
  className,
  classNameWrapper,
  generalInfo,
  indicatorClassname,
  onChange,
  showComponent,
  tabClassname,
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(generalInfo[0].label);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={classNameWrapper || classes.wrapper}>
      <div className={className || classes.baseStyle}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={tabClassname || classes.tab}
            indicatorColor="primary"
            scrollButtons="auto"
            variant="scrollable"
            classes={{
              indicator: indicatorClassname || classes.indicator
            }}
          >
            {generalInfo.map((component, index) => (
              <Tab
                key={component.label}
                value={component.label}
                label={component.label}
                wrapped
                {...allyProps(index)}
              />
            ))}
          </Tabs>
        </AppBar>
      </div>
      {additionalInfo}
      {generalInfo.map(component => {
        if (component.label === showComponent) {
          return <Fragment key={component.label}>{component.component}</Fragment>;
        }
        return null;
      })}
    </div>
  );
};

MTabs.propTypes = {
  additionalInfo: PropTypes.node,
  className: PropTypes.string,
  generalInfo: arrayOf(PropTypes.object),
  indicatorClassname: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showComponent: PropTypes.string,
  tabClassname: PropTypes.string
};

MTabs.defaultProps = {
  additionalInfo: null,
  className: '',
  generalInfo: [],
  indicatorClassname: '',
  showComponent: '',
  tabClassname: ''
};

export default MTabs;
