import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Table } from 'react-bootstrap';
import { fetchOnboardingResults } from 'shared/features/annotations/annotations.actions';
import OnboardingUserDetail from './OnboardingUserDetail';

class OnbaordingResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserStats: false,
      currentUserID: 1,
      currentUserName: '',
      currentTopicGroup: 1
    };
  }

  componentDidMount() {
    this.props.fetchOnboardingResults();
  }

  hideUserModal = () => {
    this.setState({ showUserStats: false });
  }

  calculateAccuracy = (difficulty_obj) => {
    const total = difficulty_obj.total_done;
    const correct = difficulty_obj.num_correct;
    let accuracy = 0;

    if (total > 0) {
      if (correct > 0) {
        accuracy = (correct / total * 100).toFixed(1);
      }
    }

    return { total, correct, accuracy };
  }

  renderUserStats = (user_id, user_name, annotation_task_group_id) => {
    this.setState({
      showUserStats: true,
      currentUserID: user_id,
      currentUserName: user_name,
      currentTopicGroup: annotation_task_group_id
    });
  }

  renderOnboardingStats = user => {
    // pulling properties off user, destructrued
    const { accuracy_counts,
      annotation_task_group_id,
      topic_name,
      total_gold_annotations_available,
      user_email,
      user_first_name,
      user_id,
      user_last_name } = user;

    // calculate accuracies
    // return difficulty stats
    let easy_details = { total: 0, correct: 0, accuracy: 0 };
    let medium_details = { total: 0, correct: 0, accuracy: 0 };
    let hard_details = { total: 0, correct: 0, accuracy: 0 };
    let no_difficulty_details = { total: 0, correct: 0, accuracy: 0 };
    if ('easy' in accuracy_counts) {
      easy_details = this.calculateAccuracy(accuracy_counts.easy);
    }
    if ('medium' in accuracy_counts) {
      medium_details = this.calculateAccuracy(accuracy_counts.medium);
    }
    if ('hard' in accuracy_counts) {
      hard_details = this.calculateAccuracy(accuracy_counts.hard);
    }
    if ('no_gold_difficulty_assigned' in accuracy_counts) {
      no_difficulty_details = this.calculateAccuracy(accuracy_counts.no_gold_difficulty_assigned);
    }

    let total_details = {
      total_done: (
        easy_details.total
        + medium_details.total
        + hard_details.total
        + no_difficulty_details.total),
      num_correct: (
        easy_details.correct
        + medium_details.correct
        + hard_details.correct
        + no_difficulty_details.correct)
    };
    total_details = this.calculateAccuracy(total_details);

    // create unique userKey for mapping <tr>
    const userKey = `user_${user_id}-topic_${topic_name}`;
    // user full name
    let user_name = user_first_name;
    if (user_last_name !== null) {
      user_name = `${user_first_name} ${user_last_name}`;
    }

    return (
      <tr
        key={userKey}
        onClick={() => this.renderUserStats(user_id, user_name, annotation_task_group_id)}
      >
        <td>{user_name}</td>
        <td>{user_email}</td>
        <td>{topic_name}</td>
        <td>
          {'Total: ' + total_details.total}
          <br />
          {'Easy: ' + easy_details.total}
          <br />
          {'Medium: ' + medium_details.total}
          <br />
          {'Hard: ' + hard_details.total}
          <br />
          {'No difficulty: ' + no_difficulty_details.total}
          <br />
        </td>
        <td>{total_details.accuracy}</td>
        <td>{easy_details.accuracy}</td>
        <td>{medium_details.accuracy}</td>
        <td>{hard_details.accuracy}</td>
        <td>{total_details.total + ' / ' + total_gold_annotations_available}</td>
      </tr>
    );
  }

  render() {
    const { onboarding_results } = this.props;
    const tableHeaders = [
      'Name',
      'Email',
      'Topic',
      'Total Annotated',
      'Total Accuracy',
      'Easy Accuracy',
      'Medium Accuracy',
      'Hard Accuracy',
      'Annotations Completed'
    ];

    if (onboarding_results.length === undefined || onboarding_results.length < 1) {
      return <div>Loading User Statistics</div>;
    }

    return (
      <div className="onboarding-results">
        <h1>Onboarding Report</h1>
        <Table>
          <thead>
            <tr>
              {
                tableHeaders.map(title => {
                  return (
                    <td key={title}>{title}</td>
                  );
                })
              }
            </tr>
          </thead>
          <tbody>
            {this.props.onboarding_results.map(this.renderOnboardingStats)}
          </tbody>
        </Table>
        { this.state.showUserStats && (
          <Modal
            show={this.state.showUserStats}
            dialogClassName="user-stats-modal"
            onHide={this.hideUserModal}
          >
            <Modal.Header closeButton>
              <h1>{'Annotation details for ' + this.state.currentUserName}</h1>
            </Modal.Header>
            <OnboardingUserDetail
              user_id={this.state.currentUserID}
              user_name={this.state.currentUserName}
              topic_group_id={this.state.currentTopicGroup}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ onboarding_results }) => {
  return { onboarding_results };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchOnboardingResults }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OnbaordingResults);
