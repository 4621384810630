import React, { useState } from "react";
import moment from 'moment';
import { connect } from "react-redux";
import { Close, Edit } from "@material-ui/icons";
import styled from "styled-components";

import {
  getRSSConfigurations,
  editRSSConfiguration,
  createRSSConfiguration
} from 'shared/features/user/user.actions';
import Table from 'app/common/Table';
import DefaultModal from 'app/common/Modal';
import Chip, { CHIP_STATUS_SUCCESS, CHIP_STATUS_ERROR } from 'app/common/Chip';
import Button from 'app/common/Button';
import Loader from 'app/common/Loader';
import { COLORS } from 'app/constants/Style';

import ManageModal from './manageModal';

const NEW_RSS_DATA = {
  active: true,
  name: '',
  doc_filter: {},
  published_in_last_x_days: null,
  fields: {
    required: [
      { doc_attribute: 'title', rss_attribute: 'title' },
      { doc_attribute: 'id', rss_attribute: 'id' },
      { doc_attribute: 'id', rss_attribute: 'guid' },
      { doc_attribute: 'web_url', rss_attribute: 'link' },
      { doc_attribute: 'full_text', rss_attribute: 'content', max_length: 1000 },
      { doc_attribute: 'publication_date', rss_attribute: 'date', formatting: 'datetime' },
      { doc_attribute: 'web_url', rss_attribute: 'url' },
      { doc_attribute: 'full_text', rss_attribute: 'description', max_length: 1000 }
    ],
    extended: []
  }
};

const ModalContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.barGrey};
  font-size: 18px;
  font-weight: bold;
  padding: 15px;

  svg {
    cursor: pointer;
    font-size: 20px;
  }
`;

const ModalContainer = styled.div`
  background-color:white;
`;

const ModalContainerContent = styled.div`
  padding: 20px;
  max-height: 500px;
  overflow-y: scroll;
`;

const CustomSettingsRSS = ({
  getRSSConfigurations: reduxGetRSSConfigurations,
  editRSSConfiguration: reduxEditRSSConfiguration,
  createRSSConfiguration: reduxCreateRSSConfiguration
}) => {
  const [saving, setSaving] = useState(false);
  const [manageModalData, setManageModalData] = useState(NEW_RSS_DATA);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const handleAddRSSOpen = () => {
    setManageModalData(NEW_RSS_DATA);
    setManageModalOpen(true);
  };
  const onClose = () => setManageModalOpen(false);
  const handleSave = async data => {
    setSaving(true);
    if (manageModalData.uuid) {
      await reduxEditRSSConfiguration(manageModalData.uuid, data);
    } else {
      await reduxCreateRSSConfiguration(data);
    }
    setSaving(false);
    onClose();
  };

  return (
    <>
      <DefaultModal
        open={manageModalOpen}
        customContainer
        onClose={() => setManageModalOpen(false)}
      >
        <ModalContainer>
          <ModalContainerHeader>
            <span>
              {manageModalData.id ? `Edit ${manageModalData.name}` : 'Add RSS'}
            </span>
            <Close onClick={onClose} />
          </ModalContainerHeader>
          <ModalContainerContent>
            {saving ? <Loader /> : (
              <ManageModal
                handleSave={handleSave}
                active={manageModalData.active}
                name={manageModalData.name}
                doc_filter={manageModalData.doc_filter}
                uuid={manageModalData.uuid}
                published_in_last_x_days={manageModalData.published_in_last_x_days}
                fields={manageModalData.fields}
              />
            )}
          </ModalContainerContent>
        </ModalContainer>
      </DefaultModal>
      <Table
        triggerReload={saving}
        paginationSection={
          <Button onClick={handleAddRSSOpen} type="primary">
            Add RSS
          </Button>
        }
        apiFetch={reduxGetRSSConfigurations}
        actions={[{
          title: 'Edit',
          icon: <Edit />,
          disableColumn: true,
          trigger: (items) => {
            if (items.length === 1) {
              setManageModalData(items[0]);
              setManageModalOpen(true);
            }
          }
        }]}
        columns={[{
          title: 'Status',
          dataKey: 'active',
          sortKey: 'active',
          render: active => (
            <Chip
              status={active ? CHIP_STATUS_SUCCESS : CHIP_STATUS_ERROR}
              label={active ? 'Active' : 'Inactive'}
            />
          )
        }, {
          title: 'Name',
          dataKey: 'name',
          sortKey: 'name'
        }, {
          title: 'UUID',
          sortKey: 'uuid',
          dataKey: 'uuid'
        }, {
          title: 'Created',
          dataKey: 'created_at',
          sortKey: 'created_at',
          render: created_at => moment(created_at).local().format('MMM DD, YYYY')
        }, {
          title: 'Updated',
          dataKey: 'updated_at',
          sortKey: 'updated_at',
          render: updated_at => moment(updated_at).local().format('MMM DD, YYYY')
        }]}
      />
    </>
  );
};

CustomSettingsRSS.propTypes = {};

CustomSettingsRSS.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  { getRSSConfigurations, editRSSConfiguration, createRSSConfiguration }
)(CustomSettingsRSS);
