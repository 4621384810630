import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useHistory } from 'admin/utils/hooks';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReportIcon from "@material-ui/icons/Report";
import ROUTES from "../../constants/routes";
import { DATE_TIME_FORMAT } from '../../constants/projectSettings';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Select from "../../components/ReactSelect/Select";
import { AGENCY_ACTIVE_TYPES } from './AgencyDetails/AgencyDetails.constants';

const AGENCY_OBJECT_STATUSES = {
  [AGENCY_ACTIVE_TYPES.ACTIVE]: {
    label: (
      <Typography variant="p">
        <CheckCircleIcon />
        {' Active'}
      </Typography>
    ),
    value: AGENCY_ACTIVE_TYPES.ACTIVE,
    updateValue: { active: true },
    className: 'navigation-select_green'
  },
  [AGENCY_ACTIVE_TYPES.DEACTIVATED]: {
    label: (
      <Typography variant="p">
        <ReportIcon />
        {' Deactivate'}
      </Typography>
    ),
    value: AGENCY_ACTIVE_TYPES.DEACTIVATED,
    updateValue: { active: false },
    className: 'navigation-select_red'
  }
};

const AgencySettingsStatus = ({
  agencyDetails,
  changeStatus,
  status
}) => {
  const history = useHistory();
  const location = useLocation();

  // help functions
  const goBack = () => {
    // history.push(ROUTES.agencyTool, location.state);
    history.push(ROUTES.contentManagement, location.state);
  };
  const formatDate = useCallback(date => moment(date).format(DATE_TIME_FORMAT), []);

  return (
    <div className="agency-navigation_status">
      <div className="agency-navigation_status_date-block">
        <Typography variant="p" italic>
          {`Created ${formatDate(agencyDetails.created_at)}`}
        </Typography>
        <Typography variant="p" italic>
          {`Updated ${formatDate(agencyDetails.updated_at)}`}
        </Typography>
      </div>
      <div className="agency-navigation_status_status-block">
        <Typography variant="p" color="gray">Status</Typography>
        <Select
          className="agency-navigation_status_status-block_select-wrapper"
          selectClassName={cn(
            "agency-navigation_status_status-block_select-wrapper_select",
            AGENCY_OBJECT_STATUSES[status].className
          )}
          value={AGENCY_OBJECT_STATUSES[status]}
          onChange={changeStatus}
          options={Object.values(AGENCY_OBJECT_STATUSES)}
          isSearchable={false}
        />
      </div>
      <div className="agency-navigation_status_icon-block">
        <Button onClick={goBack}>
          <CloseIcon fontSize='large' />
        </Button>
      </div>
    </div>
  );
};

AgencySettingsStatus.propTypes = {
  agencyDetails: PropTypes.shape({}),
  changeStatus: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(AGENCY_ACTIVE_TYPES)).isRequired
};

AgencySettingsStatus.defaultProps = {
  agencyDetails: {}
};

export default AgencySettingsStatus;
