import auth from "shared/utils/auth";
import * as env from 'shared/config';

export function initiateDownload(blob, filename) {
  // in IE, use proprietary API to trigger file download
  // for everything else, create a link with a data url and then click on it
  // source: http://stackoverflow.com/a/33542499

  if (window.navigator.msSaveOrOpenBlob) {
    // for IE
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    const file_url = URL.createObjectURL(blob);
    a.href = file_url;
    a.download = filename;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
    window.URL.revokeObjectURL(file_url);
  }
}

export function downloadFromURL(url) {
  const a = document.createElement('a');
  a.href = url;
  window.document.body.appendChild(a);
  a.click();
  window.document.body.removeChild(a);
}

export function emailDownload(ids) {
  const url = `${env.apiUrl}/email_document_summary?ids=${ids.join(',')}&access_token=${auth.getToken()}`;
  downloadFromURL(url);
}

export const buildGetRequestURL = (route, params) => {
  let url = `${env.apiUrl}/${route}?access_token=${auth.getToken()}`;
  for (const key in params) {
    if (Array.isArray(params[key])) {
      for (const value of params[key]) {
        url += `&${key}=${value}`;
      }
    } else {
      url += `&${key}=${params[key]}`;
    }
  }
  return url;
};

export const exportDownload = (maskedFileName) => {
  const url = `${env.apiUrl}/export/${maskedFileName}?access_token=${auth.getToken()}`;
  downloadFromURL(url);
};
