import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../../../components/Button';
import { checkProductionEnv } from '../../../utils/checkENVIRONMENT';
import CrawlerCatalogInfoList from "./CrawlerCatalogInfoList";
import CrawlerCatalogSimpleInfoList from "./CrawlerCatalogSimpleInfoList";
import Typography from '../../../components/Typography';
import { createDateDescription } from './CrawlerCatalog.helper';
import {
  REQUIRED_CRAWL_DETAILS,
  REQUIRED_CRAWL_FILES,
  EXPORTED_CRAW_FILES
} from "./CrawlerCatalog.constants";
import CrawlCatalogCommandRequestResult from "./CrawlerCatalogCommandRequestResult";

const productionEnv = checkProductionEnv();

const CrawlsCatalogRightPanel = ({
  openExportModal,
  openImportModal,
  openConvertModal,
  openViewModal,
  crawlDetails,
  crawlerActivity,
  crawlerDocuments,
  convertCommandRequest
}) => {
  const configExportDate = _.get(crawlerActivity, 'catalog_config_update_date', null);
  const configImportDate = _.get(crawlerActivity, 'crawl_config_update_date', null);
  const configConvertDate = _.get(crawlerActivity, 'crawl_construction_config_update_date', null);
  const configPrepared = crawlerDocuments.spec.crawl_id || crawlerDocuments.spec.spider_name;

  return (
    <div className="crawlCatalog_rightPanel">
      <CrawlerCatalogInfoList
        listTitle="Required Crawl Details"
        crawlInfo={crawlDetails}
        requiredFields={REQUIRED_CRAWL_DETAILS}
      />
      <CrawlerCatalogInfoList
        listTitle="Required Crawl Files"
        crawlInfo={crawlerActivity}
        requiredFields={REQUIRED_CRAWL_FILES}
      />
      <Button
        color="blue"
        onClick={openExportModal}
        disabled={productionEnv}
      >
        Export to Catalog
      </Button>
      <Typography italic hidden={productionEnv} variant="p" className="crawlCatalog_rightPanel_action-date">
        {createDateDescription(configExportDate, 'exported')}
      </Typography>
      <CrawlerCatalogSimpleInfoList
        listTitle="Exported Crawl Files"
        crawlInfo={crawlerActivity}
        requiredFields={EXPORTED_CRAW_FILES}
      />
      <Button
        color="blue"
        onClick={openImportModal}
      >
        Import from Catalog
      </Button>
      <Typography italic variant="p" className="crawlCatalog_rightPanel_action-date">
        {createDateDescription(configImportDate, 'imported')}
      </Typography>
      <Button
        color="blue"
        onClick={openConvertModal}
        disabled={!configPrepared}
      >
        Convert JSON
      </Button>
      <Typography
        bold
        color="blue"
        variant="p"
        className="crawlCatalog_rightPanel_crawl-config crawlCatalog_rightPanel_action-date"
        onClick={openViewModal}
      >
        View crawl_config
      </Typography>
      <Typography
        italic
        variant="p"
        hidden={!configPrepared}
        className="crawlCatalog_rightPanel_action-date"
      >
        {createDateDescription(configConvertDate, 'converted')}
      </Typography>
      <CrawlCatalogCommandRequestResult
        commandRequest={convertCommandRequest}
      />
    </div>
  );
};

CrawlsCatalogRightPanel.propTypes = {
  openExportModal: PropTypes.func.isRequired,
  openImportModal: PropTypes.func.isRequired,
  openConvertModal: PropTypes.func.isRequired,
  openViewModal: PropTypes.func.isRequired,
  crawlerActivity: PropTypes.shape({
    crawler_id: PropTypes.number
  }),
  crawlDetails: PropTypes.shape({
    id: PropTypes.number,
    is_active: PropTypes.bool
  }).isRequired,
  convertCommandRequest: PropTypes.shape({})
};

CrawlsCatalogRightPanel.defaultProps = {
  crawlerActivity: {},
  convertCommandRequest: {}
};

export default CrawlsCatalogRightPanel;
