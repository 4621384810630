import React from 'react';
import PropTypes from "prop-types";
import { DEFAULT_TABLE_COLUMNS } from "./DocumentMetaCategories.constants";
import docsMetaCategoryTableHeaderCells from './headerCells';
import docsMetaCategoryTableBodyCells from './bodyCells';
import { formatDocMetaCategories } from './DocumentMetaCategories.helper';
import CustomTable from '../../components/Table';

const DocumentMetaCategoriesTable = ({
  metaCategories,
  setMetaCategoryModal
}) => {
  const metaCategoriesFormat = formatDocMetaCategories(metaCategories);

  return (
    <CustomTable
      className="document-categories_meta-table"
      header={docsMetaCategoryTableHeaderCells({
        metaCategoryInfo: DEFAULT_TABLE_COLUMNS
      })}
      data={docsMetaCategoryTableBodyCells({
        metaCategoriesFormat,
        metaCategoryInfo: DEFAULT_TABLE_COLUMNS
      })}
      onClick={setMetaCategoryModal}
    />
  );
};

DocumentMetaCategoriesTable.propTypes = {
  metaCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  setMetaCategoryModal: PropTypes.func.isRequired
};

DocumentMetaCategoriesTable.defaultProps = {
  metaCategories: []
};

export default DocumentMetaCategoriesTable;
