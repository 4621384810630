import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { AUTOCOMPLETE, BUTTON, CALENDAR, GROUP_OF_FIELDS } from '../../../../constants/fieldTypes';
import { colors } from 'admin/components/MaterialComponents/colors/colors';
import MButton from 'admin/components/MaterialComponents/Buttons/Button/mButton';
import MAutocomplete from 'admin/components/MaterialComponents/Autocomplete/mAutocomplete';
import CDatePicker from 'admin/components/MaterialComponents/DatePicker/DatePicker';
import MTextField from 'admin/components/MaterialComponents/TextField/mTextField';
import { PROVIDER_LABEL, START_DATE_LABEL } from 'shared/features/userModal/labelConstants';

const useStyle = makeStyles({
  calendarDisabledClass: {
    '& .react-date-picker__wrapper': {
      color: colors.BLACK_MAIN,
      height: 35,
      backgroundColor: colors.WHITE,
      border: 'none'
    }
  },
  defaultBlueButton: {
    color: colors.BLUE_MAIN,
    marginRight: 10,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: colors.BLUE_TRANSPARENT,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.BACKGROUND_BLUE
    }
  },
  disabledTableItem: {
    borderRadius: 5,
    marginRight: 15,
    '& .jurispect-MuiInputBase-root': {
      backgroundColor: colors.WHITE,
      borderRadius: 5
    },
    '& .jurispect-MuiSelect-icon': {
      marginTop: 6
    },
    '& .jurispect-MuiInput-input': {
      color: colors.BLACK_MAIN,
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      paddingLeft: 5,
      backgroundColor: colors.WHITE,
      height: 20
    }
  },
  labelClass: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.GREY_MEDIUM
  },
  multiSelectorDisabled: {
    background: 'none !important',
    border: 'none !important'
  },
  multiSelector: {
    backgroundColor: colors.BACKGROUND_MEDIUM,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    minHeight: 33,
    border: `1px solid ${colors.GREY_LIGHT}`,
    '& .jurispect-MuiAutocomplete-tag': {
      paddingBottom: 5,
      paddingTop: 5,
      backgroundColor: colors.BLUE_MAIN,
      color: colors.WHITE
    }
  },
  optionClass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.BLUE_MAIN,
    height: 22,
    padding: '4px 5px 4px 5px',
    color: colors.WHITE,
    margin: '2px 0px 2px 5px',
    borderRadius: 15,
    '& p': {
      fontSize: 13
    }
  },
  groupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 40
  },
  groupRowWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  }
});

const NotEditModeField = ({ buttonFieldActions, rowField, renderValue, onRowFieldChange }) => {
  const classes = useStyle();
  if (rowField.type === BUTTON) {
    return (
      <MButton
        onClick={() => buttonFieldActions(rowField.label)}
        classes={classes.defaultBlueButton}
      >
        {rowField.label}
      </MButton>
    );
  }
  if (rowField.type === AUTOCOMPLETE) {
    return (
      <MAutocomplete
        disabled
        fullWidth
        multiple
        label={_.get(rowField, 'label', '')}
        labelClass={classes.labelClass}
        classNameTextField={classNames(classes.multiSelector, classes.multiSelectorDisabled)}
        onChange={value => onRowFieldChange(rowField.label, value)}
        optionClass={classes.optionClass}
        options={rowField.options}
        size="small"
        value={rowField.value}
      />
    );
  }
  if (rowField.type === CALENDAR) {
    return (
      <CDatePicker
        disabled
        calendarIcon={null}
        clearIcon={null}
        labelClass={classes.labelClass}
        fullWidth
        calendarClass={classes.calendarDisabledClass}
        textfieldClass={classes.calendarClass}
        value={rowField.value}
        label={_.get(rowField, 'label', '')}
        onChange={value => {
          onRowFieldChange(rowField.responseName, value);
        }}
      />
    );
  }
  if (rowField.type === GROUP_OF_FIELDS) {
    return (
      <div className={classes.groupWrapper}>
        {rowField.value.map((premium, index) => (
          <div className={classes.groupRowWrapper}>
            <MTextField
              fullWidth
              className={classes.disabledTableItem}
              labelClass={classes.labelClass}
              label={index === 0 && PROVIDER_LABEL}
              value={premium.premium_content_source_name}
              disabled
            />
            <MTextField
              label={index === 0 && START_DATE_LABEL}
              labelClass={classes.labelClass}
              className={classes.disabledTableItem}
              value={premium.created_at}
              disabled
            />
          </div>
        ))}
      </div>
    );
  }
  return (
    <MTextField
      fullWidth
      labelClass={classes.labelClass}
      className={classes.disabledTableItem}
      label={_.get(rowField, 'label', '')}
      value={renderValue(rowField)}
      disabled
    />
  );
};

NotEditModeField.propTypes = {
  buttonFieldActions: PropTypes.func.isRequired,
  rowField: PropTypes.objectOf(PropTypes.any).isRequired,
  renderValue: PropTypes.func.isRequired,
  onRowFieldChange: PropTypes.func.isRequired
};

export default NotEditModeField;
