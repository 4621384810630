import type { ExportModalData } from './useExportModalData.types';
import type { ExportModalProps } from '../ExportModal.types';

import * as lodash from 'lodash';

import * as helpers from './useExportModalData.helpers';

import * as constants from '../ExportModal.constants';

import { useState, useMemo } from 'react';

export const useExportModalData = ({
  props
}: {
  props: Pick<ExportModalProps, 'contentType' | 'documentsCount'>;
}) => {
  const [selectedColumns, setSelectedColumns] = useState(constants.BASIC_EXPORT_COLUMNS);

  const localState: ExportModalData['localState'] = {
    selectedColumns
  };

  const localActions: ExportModalData['localActions'] = useMemo(
    () => ({
      setSelectedColumns
    }),
    [setSelectedColumns]
  );

  const formattedData: ExportModalData['formattedData'] = useMemo(() => {
    const { contentType, documentsCount } = props;

    const isExportDisabled = lodash.isEmpty(selectedColumns);

    const basicColumnsToDisplay = [
      constants.BASIC_EXPORT_COLUMNS_ONE,
      constants.BASIC_EXPORT_COLUMNS_TWO,
      constants.BASIC_EXPORT_COLUMNS_THREE
    ];

    const apiColumns = helpers.formatColumnsToKeys(selectedColumns);

    const headerText = `${documentsCount} document${
      documentsCount > 1 ? 's have' : ' has'
    } been selected for export: Select the data fields you want to include in the ${contentType}:`;

    return { isExportDisabled, apiColumns, headerText, basicColumnsToDisplay };
  }, [props, selectedColumns]);

  return {
    localState,
    localActions,
    formattedData
  };
};
