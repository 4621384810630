import type { BulkEditModalData } from './useBulkEditModalData.types';
import type { BulkEditModalProps } from '../BulkEditModal.types';
import type { useBulkEditModalQueries } from './useBulkEditModalQueries';

import * as uiLib from '@compliance.ai/web-components';
import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as commandRequestTypeConstants from 'admin/constants/commandRequestTypes';
import * as documentDataApi from 'shared/features/documentData/documentData.apiV2';
import * as helpers from './useBulkEditModalMutations.helpers';
import * as errorUtils from 'shared/utils/errors';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useBulkEditModalMutations = ({
  props,
  localState,
  reduxState,
  queries
}: {
  props: Pick<BulkEditModalProps, 'selectedDocumentIds' | 'onSubmit' | 'onClose'>;
  localState: BulkEditModalData['localState'];
  reduxState: BulkEditModalData['reduxState'];
  queries: ReturnType<typeof useBulkEditModalQueries>;
}) => {
  const queryClient = useQueryClient();

  const commandId = helpers.findBulkUpdateCommandId({ commands: queries.fetchCommands.data });

  const updateDocumentDataMutation = useMutation<
    Awaited<ReturnType<typeof documentDataApi.updateDocumentDataBulk>>,
    Error,
    void
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.DOCUMENTS_DATA],
    mutationFn: () =>
      documentDataApi.updateDocumentDataBulk({
        name: commandRequestTypeConstants.COMMAND_REQUEST_NAMES.BULK_DOCUMENT_UPDATE,
        type: commandId as number,
        user_id: reduxState.currentUserId,
        parameters: {
          docIds: props.selectedDocumentIds,
          actions: helpers.convertModalRulesToApiActions(localState)
        }
      }),
    onError: errorUtils.logReactQueryError,
    onSuccess: async () => {
      props.onClose();
      props.onSubmit();

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        title: 'Documents update request is successfully created.'
      });

      await queryClient.invalidateQueries([queryKeysConstants.QUERY_KEYS.DOCUMENTS_DATA]);
    }
  });

  return {
    updateDocumentDataMutation
  };
};
