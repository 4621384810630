import type { FC } from 'react';
import type { BoolCellProps } from './BoolCell.types';
import type { UnofficialPubDateBoolCellValue, DiffableBoolCellValue } from './BoolCell.types';
import * as uiLib from '@compliance.ai/web-components';

export const UnOfficialPubDateBoolCell: FC<BoolCellProps> = ({ row }) => {
  const boolValue = row.has_unofficial_publication_date as UnofficialPubDateBoolCellValue;
  return <uiLib.Typography>{boolValue ? 'True' : 'False'}</uiLib.Typography>;
};
export const DiffableBoolCell: FC<BoolCellProps> = ({ row }) => {
  const boolValue = row.diffable as DiffableBoolCellValue;
  return <uiLib.Typography>{boolValue ? 'True' : 'False'}</uiLib.Typography>;
};
