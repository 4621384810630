import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'reportDocuments';
export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const reportDocumentsReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setReportDocuments: (state, action) => (action.payload)
  }
});

export default reportDocumentsReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setReportDocuments
} = reportDocumentsReducer.actions;
