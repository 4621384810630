import React, { useMemo, useState } from 'react';
import { ALERT_TYPES } from 'app/common';
import { ErrorAlertMessage } from '../elements';

export const useAuthFormAlertData = () => {
  const [message, setMessage] = useState(null);

  const localActions = useMemo(() => {
    const resetMessage = () => {
      setMessage(null);
    };

    const setSuccessMessage = (newMessage) => {
      setMessage({
        type: ALERT_TYPES.SUCCESS,
        title: 'Success',
        content: newMessage
      });
    };

    const setErrorMessage = (newMessage) => {
      setMessage({
        type: ALERT_TYPES.ERROR,
        title: null,
        content: newMessage
      });
    };

    const setErrorMessageByType = messageType => {
      setErrorMessage(<ErrorAlertMessage type={messageType} />);
    };

    return {
      resetMessage,
      setSuccessMessage,
      setErrorMessage,
      setErrorMessageByType
    };
  }, []);

  return {
    localState: {
      message
    },
    localActions
  };
};
