import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './CommandRequests.constants';
import * as categoryConstants from 'admin/constants/categories';
import * as elements from './elements';
import * as errorUtils from 'shared/utils/errors';

import {
  useCommandRequestsData,
  useCommandRequestsHandlers,
  useCommandRequestsTableConfig,
  useCommandRequestsTableDataSelector
} from './hooks';

import './_command-requests.scss';

export const CommandRequests: FC = () => {
  const { localState, localActions, reduxState } = useCommandRequestsData();

  const handlers = useCommandRequestsHandlers({ localState, localActions });

  const tableConfig = useCommandRequestsTableConfig();

  const tableDataSelector = useCommandRequestsTableDataSelector({
    reduxState,
    events: { onDetailsClick: handlers.handleDetailsModalOpen }
  });

  return (
    <uiLib.ErrorBoundary>
      <div className="command-requests__container">
        <div className="command-requests__filters">
          <elements.CommandRequestsFilters onSubmit={handlers.handleFiltersSubmit} />
          <uiLib.Button onClick={handlers.handleCreationModalOpen}>
            Create command request
          </uiLib.Button>
        </div>
        <div className="command-requests__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            rowKey={categoryConstants.CATEGORY_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleCommandRequestsFetch}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_COMMAND_REQUESTS_COUNT}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
            classNames={{
              container: 'command-requests__table-outer-container',
              tableContainer: 'command-requests__table-inner-container',
              toolbar: 'command-requests__table-toolbar',
              headerCell: 'command-requests__table-header-cell',
              selectedRowsCount: 'command-requests__table-selected-rows-count'
            }}
          />
        </div>
      </div>
      {localState.isDetailsModalOpen && (
        <elements.CommandRequestsDetailsModal
          details={localState.detailsModalInfo}
          onClose={handlers.handleDetailsModalClose}
          selectedCommandRequest={localState.selectedCommandRequest}
        />
      )}
      <elements.CommandRequestsCreationModal
        isOpen={localState.isCreationModalOpen}
        onClose={handlers.handleCreationModalClose}
      />
    </uiLib.ErrorBoundary>
  );
};
