import type { NewsSourcesFiltersHandlers } from './useNewsSourcesFiltersHandlers.types';
import type { NewsSourcesFiltersProps } from '../NewsSourcesFilters.types';

import * as helpers from './useNewsSourcesFiltersHandlers.helpers';

export const useNewsSourcesFiltersHandlers = ({
  props
}: {
  props: Pick<NewsSourcesFiltersProps, 'onSubmit'>;
}): NewsSourcesFiltersHandlers => {
  const handleFiltersSubmit: NewsSourcesFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatNewsSourcesQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
