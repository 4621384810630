import type { CrawlDetailsConfigurationData } from './useCrawlDetailsConfigurationData.types';
import type { GlobalState } from 'shared/reducers';

import { getFirstExternalApiCrawlElement } from 'shared/features/externalApiCrawl/externalApiCrawl.selectors';

import { CRAWL_DETAILS } from 'admin/containers/CrawlerPage/CrawlerDetails/CrawlerDetails.constants';
import { crawlStates } from 'admin/constants/crawlersTypes';

import { useSelector } from 'react-redux';

import { useMemo, useState } from 'react';
import { checkProductionEnv } from 'admin/utils/checkENVIRONMENT';

export const useCrawlDetailsConfigurationData = (): CrawlDetailsConfigurationData => {
  const reduxState = useSelector<GlobalState, CrawlDetailsConfigurationData['reduxState']>(
    state => ({
      crawlDetailsInfo: getFirstExternalApiCrawlElement(state)
    })
  );

  const [crawlDetails] =
    useState<CrawlDetailsConfigurationData['localState']['crawlDetails']>(CRAWL_DETAILS);

  const [crawlSentencificationConfiguration, setCrawlSentencificationConfiguration] = useState<
    CrawlDetailsConfigurationData['localState']['crawlSentencificationConfiguration']
  >(JSON.stringify(crawlDetails.sentencification_configuration, null, 2));

  const isEditDisabled = useMemo(() => {
    return crawlDetails.status !== crawlStates.construction;
  }, [crawlDetails.status]);
  const isProductionEnvironment = useMemo(() => {
    return checkProductionEnv();
  }, []);

  const localActions: CrawlDetailsConfigurationData['localActions'] = useMemo(
    () => ({
      setCrawlSentencificationConfiguration
    }),
    []
  );

  return {
    reduxState,
    localState: {
      crawlDetails,
      crawlSentencificationConfiguration
    },
    localActions,
    formattedData: {
      isEditDisabled,
      isProductionEnvironment
    }
  };
};
