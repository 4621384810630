import _ from "lodash";
import { RULE_COMMENTS_CLOSE_ON, RULE_EFFECTIVE_ON } from "../../../constants/SortRules";

export const getDocumentsInRightOrder = (state, payload, sortRule) => {
  const { docs, id } = payload;
  const getRule = (doc) => {
    if (sortRule === RULE_COMMENTS_CLOSE_ON) return doc.rule.comments_close_on;
    if (sortRule === RULE_EFFECTIVE_ON) return doc.rule.effective_on;
    return doc.rule.comments_close_on || doc.rule.effective_on;
  };
  const sort = doc => doc.rule && getRule(doc);
  state[id] = docs.length ? _.sortBy(docs, sort) : [];
  return state;
};

export const getDocumentsWithoutDuplicates = (state, id) => {
  const docsWithoutDuplicates = state.sortedByEffectiveDate[id]
    ? _.cloneDeep(state.sortedByEffectiveDate[id])
    : [];
  const sortedByCommentsCloseDate = state.sortedByCommentsCloseDate[id] || [];
  sortedByCommentsCloseDate.forEach(doc => {
    if (!_.find(docsWithoutDuplicates, ['id', doc.id])) docsWithoutDuplicates.push(doc);
  });

  return getDocumentsInRightOrder(
    state.sortedByEffectiveAndCommentCloseDate,
    {
      docs: docsWithoutDuplicates.slice(0, 20),
      id
    }
  );
};
