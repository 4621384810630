import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getConcepts
} from "../../../shared/features/concepts/concepts.actions";
import ConceptDetails from './ConceptDetails/ConceptDetails';

const ConceptPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNaN(+id)) {
      dispatch(getConcepts({ id }));
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConceptDetails />
    </>
  );
};

export default ConceptPage;
