import type { CategoryEditModalData } from './useCategoryEditModalData.types';
import type { CategoryEditModalProps } from '../CategoryEditModal.types';

import { useMemo, useState } from 'react';

export const useCategoryEditModalData = ({
  props
}: {
  props: Pick<CategoryEditModalProps, 'category'>;
}): CategoryEditModalData => {
  const [caiCategoryName, setCaiCategoryName] = useState<
    CategoryEditModalData['localState']['caiCategoryName']
  >(props.category?.name || '');
  const [caiCategoryDescription, setCaiCategoryDescription] = useState<
    CategoryEditModalData['localState']['caiCategoryDescription']
  >(props.category?.description ?? '');
  const [docMetaCategory, setDocMetaCategory] = useState<
    CategoryEditModalData['localState']['docMetaCategory']
  >({ value: props.category?.meta_category?.id, label: props.category?.meta_category?.name });
  const [surfaceInFilter, setSurfaceInFilter] = useState<
    CategoryEditModalData['localState']['surfaceInFilter']
  >(props.category?.surface_in_filter ?? false);

  const localState: CategoryEditModalData['localState'] = {
    caiCategoryName,
    caiCategoryDescription,
    docMetaCategory,
    surfaceInFilter
  };

  const localActions: CategoryEditModalData['localActions'] = useMemo(
    () => ({
      setCaiCategoryName,
      setCaiCategoryDescription,
      setDocMetaCategory,
      setSurfaceInFilter
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
