import { openErrorSnackbar } from '../../snackbar/snackbar.reducer';
import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';
import {
  api_getCrawlerActivity,
  api_postExportCrawlInfo,
  api_postImportCrawlInfo,
  api_postExportCrawlConstructionInfo
} from './crawlerActivity.api';
import { setCrawlerActivity } from './crawlerActivity.reducer';

const ERRORS = {
  getCrawlerStatistic: 'Request for crawl statistic is failed'
};

const SUCCESS = {
  crawlExported: 'Crawl successfully exported to catalog',
  crawlImported: 'Crawl successfully imported to catalog',
  crawlFilesUpdated: 'Crawl config/spec was successfully updated'
};

const getCrawlerStatistic = (crawlId, params) => dispatch => (
  api_getCrawlerActivity(crawlId, params)
    .then(
      (res) => {
        dispatch(setCrawlerActivity(res));
      })
    .catch(
      (err) => {
        dispatch(openErrorSnackbar(ERRORS.getCrawlerStatistic));
        dispatch(setCrawlerActivity({}));
      }
    )
);

const postExportCrawlInfo = (crawlId) => dispatch => (
  api_postExportCrawlInfo(crawlId)
    .then((res) => {
      setNotification({
        message: { text: SUCCESS.crawlExported, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(setCrawlerActivity(res));
    })
);

const postImportCrawlInfo = (crawlId) => dispatch => (
  api_postImportCrawlInfo(crawlId)
    .then((res) => {
      setNotification({
        message: { text: SUCCESS.crawlImported, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(setCrawlerActivity(res));
    })
);

const postExportCrawlConstructionInfo = (crawlId) => dispatch => (
  api_postExportCrawlConstructionInfo(crawlId)
    .then((res) => {
      setNotification({
        message: { text: SUCCESS.crawlFilesUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(setCrawlerActivity(res));
    })
);

export {
  getCrawlerStatistic,
  postExportCrawlInfo,
  postImportCrawlInfo,
  postExportCrawlConstructionInfo,
  ERRORS
};
