import type { ComponentProps } from 'react';
import { DryRunDebugInfo, DryRunReport } from '../DryRunReportsTable.types';
import type { DryRunReportsTableProps } from '../DryRunReportsTable.types';
import type { DryRunReportsTableHandlers } from './useDryRunReportsTableHandlers.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../DryRunReportsTable.constants';
import * as helpers from './useDryRunReportsTableDataSelector.helpers';
import ROUTES from 'admin/constants/routes';

export const useDryRunReportsTableDataSelector = ({
  events
}: {
  events: {
    onReasonClick: DryRunReportsTableHandlers['handleReasonClick'];
    onOpenModal: DryRunReportsTableProps['onOpenModal'];
  };
}) => {
  return (
    data: Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      results: data.results.map(result => {
        const report = result as DryRunReport;
        return {
          isExpandable: !!report?.expandableInfo,

          expandableInfo: report?.expandableInfo,

          [constants.TABLE_KEYS.INDEX]: result[constants.TABLE_KEYS.INDEX],

          [constants.TABLE_KEYS.TITLE]: report.title,

          [constants.TABLE_KEYS.STATUS]: report.status,

          [constants.TABLE_KEYS.REASON]: {
            children: helpers.formatReason(report, events.onOpenModal, false),
            tooltip: helpers.formatReason(report, events.onOpenModal, true),
            onClick: events.onReasonClick(report)
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.PUBLICATION_DATE]: {
            value: uiLib.isValidDate(report.publication_date) ? report.publication_date : null,
            format: uiLib.DATE_FORMATS.API_DATE,
            passedTimezone: uiLib.TIMEZONES.UTC,
            timezone: uiLib.TIMEZONES.UTC,
            shouldKeepLocalTime: true
          } as uiLib.DateCellValue,

          /**
           * Some ids can be zeros
           */
          [constants.TABLE_KEYS.PARENT_ID]: uiLib.isNumber(report.parent_id)
            ? String(report.parent_id)
            : null,

          [constants.TABLE_KEYS.PARENT_ID]: {
            children: uiLib.isNumber(report.parent_id) ? String(report.parent_id) : null,
            tooltip: report.parent_id,
            href: report.parent_id
              ? `${window.location.origin}${ROUTES.documents}/${report.parent_id}`
              : null
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.OFFICIAL_ID]: report.official_id,

          [constants.TABLE_KEYS.CATEGORY]: report.category,

          [constants.TABLE_KEYS.PDF_URL]: {
            href: report.pdf_url,
            tooltip: report.pdf_url,
            children: report.pdf_url
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.WEB_URL]: {
            href: report.web_url,
            tooltip: report.web_url,
            children: report.web_url
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.DOCKET_ID]: report.unified_docket?.unified_docket_id,

          [constants.TABLE_KEYS.EFFECTIVE_DATE]: {
            value: report.rule?.effective_on,
            format: uiLib.DATE_FORMATS.API_DATE,
            passedTimezone: uiLib.TIMEZONES.UTC,
            timezone: uiLib.TIMEZONES.UTC,
            shouldKeepLocalTime: true
          } as uiLib.DateCellValue,

          [constants.TABLE_KEYS.SUMMARY_TEXT]: report.summary_text,

          [constants.TABLE_KEYS.RELATION]: report.relation
        };
      }),

      count: data.count
    };
  };
};

export const useDryRunReportsChildTableDataSelector = ({
  events
}: {
  events: {
    onReasonClick: DryRunReportsTableHandlers['handleReasonClick'];
    onOpenModal: DryRunReportsTableProps['onOpenModal'];
  };
}) => {
  return (
    data: Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      results: data.results.map((result, index) => {
        const report = result as DryRunDebugInfo;
        return {
          [constants.TABLE_KEYS.INDEX]: index + 1,

          [constants.TABLE_KEYS.TITLE]: `next_step: ${report?.next_steps}`,

          [constants.TABLE_KEYS.STATUS]: 'debug',

          [constants.TABLE_KEYS.REASON]: {
            children: `Doc: ${report?.doc}`.slice(0, 50),
            tooltip: report?.doc?.slice(0, 10),
            onClick: events.onReasonClick({ reason: report?.doc as DryRunReport['reason'] })
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.PDF_URL]: {
            children: `Source: ${report?.source}: ${report?.doc}`.slice(0, 50),
            tooltip: report?.source?.slice(0, 100),
            onClick: events.onReasonClick({ reason: report?.source as DryRunReport['reason'] })
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.WEB_URL]: {
            children: `Results: ${report?.results}: ${report?.doc}`.slice(0, 50),
            tooltip: report?.results?.slice(0, 100),
            onClick: events.onReasonClick({ reason: report?.results as DryRunReport['reason'] })
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.PARENT_ID]: {
            href: report?.current_url,
            tooltip: report?.current_url,
            children: `Current Url: ${report?.current_url}`
          } as uiLib.LinkCellValue
        };
      }),

      count: data.count
    };
  };
};
