import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../components/Button";

const DocumentCategoriesButtons = ({
  createJurisdiction,
  filter
}) => {
  return (
    <div className="jurisdiction_button-block">
      <Button onClick={createJurisdiction}>Create</Button>
      <Button onClick={filter}>Filter</Button>
    </div>
  );
};

DocumentCategoriesButtons.propTypes = {
  createJurisdiction: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired
};

export default DocumentCategoriesButtons;
