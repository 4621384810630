import type { FC } from 'react';
import type { ErrorBoundaryProviderProps } from '@compliance.ai/web-components';
import type { ProviderProps } from 'react-redux';

import React from 'react';
import Bugsnag from '@bugsnag/js';

import * as muiStyles from '@material-ui/styles';
import * as uiLib from '@compliance.ai/web-components';

import { AppRoutes, AppSwitch } from './elements';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as muiConstants from 'shared/constants/Mui';

const queryClient = new QueryClient();
const queryClientStoragePersister = createSyncStoragePersister({
  storage: window.localStorage
});

export const Router: FC = () => {
  const handleError: ErrorBoundaryProviderProps['onError'] = (error, errorInfo) => {
    Bugsnag.notify(error);
    console.error(error, errorInfo);
  };

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: queryClientStoragePersister }}
    >
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store as ProviderProps['store']}>
        <muiStyles.StylesProvider
          injectFirst
          generateClassName={muiConstants.MUI_CLASSNAMES_GENERATOR}
        >
          <uiLib.ErrorBoundaryProvider onError={handleError}>
            <BrowserRouter>
              <uiLib.ThemeProvider>
                <uiLib.RouterBlockerProvider>
                  <uiLib.DatePickerProvider>
                    <uiLib.TabsProvider>
                      <uiLib.AlertNotificationsProvider>
                        <AppSwitch>
                          <AppRoutes />
                        </AppSwitch>
                      </uiLib.AlertNotificationsProvider>
                    </uiLib.TabsProvider>
                  </uiLib.DatePickerProvider>
                </uiLib.RouterBlockerProvider>
              </uiLib.ThemeProvider>
            </BrowserRouter>
          </uiLib.ErrorBoundaryProvider>
        </muiStyles.StylesProvider>
      </Provider>
    </PersistQueryClientProvider>
  );
};
