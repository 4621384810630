import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import Typography from "./Typography";

function Switch({
  className,
  labelClassName,
  inputClassName,
  label,
  placeholder,
  value,
  name,
  onChange,
  isError,
  isDisabled,
  children
}) {
  const handleChanges = e => {
    const switchName = e.target.name;
    const switchValue = e.target.checked;
    onChange({ target: { name: switchName, value: switchValue } });
  };

  return (
    <label
      className={cn(
        'switch_wrapper',
        className
      )}
    >
      <div className={"switch_input_wrapper"}>
        <Typography
          variant="tag"
          color="gray"
          className={cn(
            inputClassName,
            {
              switch_label_error: isError,
              switch_label_disable: isDisabled
            }
          )}
        >
          {label}
        </Typography>
        <label className="switch_toggle_wrapper">
          <input
            type="checkbox"
            name={name}
            checked={value || false}
            value={value || ''}
            placeholder={placeholder}
            onChange={handleChanges}
            disabled={isDisabled}
          />
          <span
            className={cn(
              'switch_toggle_input',
              {
                switch_toggle_input_error: isError,
                switch_toggle_input_disable: isDisabled
              },
              inputClassName
            )}
          />
        </label>
      </div>
      {children}
    </label>
  );
}


Switch.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired
};

Switch.defaultProps = {
  isError: false,
  isDisabled: false,
  className: '',
  labelClassName: '',
  inputClassName: '',
  label: '',
  placeholder: '',
  name: ''
};


export default Switch;
