import {fetch, AUTHENTICATION_OPTIONS_KEYS} from "fetch";

export function api_getInsightGraphData(agg, params, useAPIKey) {
  return fetch({
    url: `/${agg}`,
    method: 'GET',
    dataType: 'InsightsGraph',
    params,
    authenticationType: useAPIKey ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : AUTHENTICATION_OPTIONS_KEYS.REDUX_STATE
  });
}

export function api_getInsightsCSVBySlug(slug) {
  return fetch({
    url: `/insights_csv/${slug}`,
    method: 'GET',
    dataType: 'Insights',
    params: {}
  });
}

export function api_getInsightsCSV(slug) {
  return fetch({
    url: '/insights_csv_by_slug',
    method: 'GET',
    dataType: 'Insights',
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY,
    params: { slug }
  });
}

export function api_getEnforcementActionsData({ agencyIds }) {
  return fetch({
    url: '/agency_enforcements_last_year',
    method: 'GET',
    dataType: 'EnforcmentActions',
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY,
    params: {
      agency_id: agencyIds
    }
  });
}
