import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as documentEventConstants from 'shared/features/documentEvents/documentEvents.constants';

export const LOCAL_STORAGE_KEY = 'eventsTable';

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

export const COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Created At',
    dataKey: documentEventConstants.DOCUMENT_EVENT_ATTRIBUTE.CREATED_AT,
    Component: uiLib.DateCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Updated At',
    dataKey: documentEventConstants.DOCUMENT_EVENT_ATTRIBUTE.UPDATED_AT,
    Component: uiLib.DateCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Prev Value',
    dataKey: documentEventConstants.DOCUMENT_EVENT_ATTRIBUTE.PREV_VALUE,
    Component: uiLib.TextCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'New Value',
    dataKey: documentEventConstants.DOCUMENT_EVENT_ATTRIBUTE.NEW_VALUE,
    Component: uiLib.TextCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Notes',
    dataKey: documentEventConstants.DOCUMENT_EVENT_ATTRIBUTE.NOTES,
    Component: uiLib.TextCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Details',
    dataKey: documentEventConstants.DOCUMENT_EVENT_ATTRIBUTE.DETAILS,
    Component: uiLib.JSONCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.M
  },
  {
    title: 'Doc Attribute Name',
    dataKey: documentEventConstants.DOCUMENT_EVENT_ATTRIBUTE.DOC_ATTRIBUTE_NAME,
    Component: uiLib.TextCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  }
];
