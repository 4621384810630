import type { FC } from 'react';
import type { DocumentFiltersProps } from './DocumentFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useDocumentFiltersAsyncLoaders,
  useDocumentFiltersConfig,
  useDocumentFiltersHandlers
} from './hooks';

import './_document-filters.scss';

export const DocumentFilters: FC<DocumentFiltersProps> = ({ onSubmit }) => {
  const filterAsyncLoaders = useDocumentFiltersAsyncLoaders();

  const filterConfig = useDocumentFiltersConfig({
    filterAsyncLoaders
  });

  const handlers = useDocumentFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search Doc ID"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="document-filters__container"
    />
  );
};
