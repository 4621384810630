import type { DocumentDetails, DocumentDetailsTabsData } from './useDocumentDetailsTabsData.types';
import type { DocumentSettingsNavigationData } from '../../../../hooks/useDocumentSettingsNavigationData.types';
import type { DOCUMENT_PIPELINE_STATUS } from '../../../../../../constants/docPipelineStatuses';
import type { UseDocumentDetailsTabsHandlers } from './useDocumentDetailsTabsHandlers.types';
import type { UseDocumentSettingsNavigationHandlersTypes } from '../../../../hooks/useDocumentSettingsNavigationHandlers.types';
import type { DocumentDetailsHandlers } from '../../../hooks/useDocumentDetailsHandlers.types';

import { fromDetailsToRawInfo, fromRawInfoToDetails } from '../DocumentDetails.helper';
import { DOCUMENT_GENERAL, documentFlagActions } from '../../../DocumentDetails.constants';
import _ from 'lodash';
import { NOTIFICATION_TYPES, setNotification } from '../../../../../../components/Notification';

import { useCallback } from 'react';

export const useDocumentDetailsTabsHandlers = ({
  id,
  docDetailsInfo,
  user,
  updateDoc,
  onTakeAction,
  localActions,
  localState
}: {
  id: DocumentSettingsNavigationData['formattedData']['id'];
  docDetailsInfo: DocumentSettingsNavigationData['reduxState']['docDetailsInfo'];
  user: DocumentSettingsNavigationData['reduxState']['currentUser'];
  updateDoc: UseDocumentSettingsNavigationHandlersTypes['handleUpdateDocuments'];
  onTakeAction: DocumentDetailsHandlers['handleTakeAction'];
  localActions: DocumentDetailsTabsData['localActions'];
  localState: DocumentDetailsTabsData['localState'];
}): UseDocumentDetailsTabsHandlers => {
  const handleCalculateDocDetails: UseDocumentDetailsTabsHandlers['handleCalculateDocDetails'] =
    useCallback(() => {
      const formatDocDetails = fromRawInfoToDetails(docDetailsInfo);
      localActions?.setDocDetails(formatDocDetails);
    }, [docDetailsInfo, localActions]);

  const handleChanges: UseDocumentDetailsTabsHandlers['handleChanges'] = (name, value) => {
    if (name === DOCUMENT_GENERAL.takeAction) {
      return localActions?.setAction((value as { value: string }).value);
    }
    return localActions?.setDocDetails({
      ...localState?.docDetails,
      [name]: value
    });
  };

  const handleChangesEvent: UseDocumentDetailsTabsHandlers['handleChangesEvent'] = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const handleToggleEditMode: UseDocumentDetailsTabsHandlers['handleToggleEditMode'] = () => {
    if (localState?.editMode) handleCalculateDocDetails();
    localActions?.setEditMode(!localState?.editMode);
  };

  const handleUpdateDocument = () => {
    const { details, errors } = fromDetailsToRawInfo(localState?.docDetails, docDetailsInfo);
    (details as DocumentDetails).user = `${_.get(user, 'first_name')} ${_.get(user, 'last_name')}`;
    if (errors.length) {
      localActions?.setErrorFields(errors.map(err => err.title));
      errors.forEach(error => setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR }));
      return;
    }
    handleToggleEditMode();
    updateDoc({ ...details, id });
  };

  const handleTakeAction = () => {
    if (!localState?.action) {
      return;
    }
    const { details } = fromDetailsToRawInfo(localState?.docDetails, docDetailsInfo);
    (details as DocumentDetails).user = `${_.get(user, 'first_name')} ${_.get(user, 'last_name')}`;
    localActions?.setEditMode(false);
    if (documentFlagActions.includes(localState?.action as DOCUMENT_PIPELINE_STATUS)) {
      onTakeAction({
        ...details,
        [DOCUMENT_GENERAL.pipelineStatus]: localState?.action,
        id: id as string
      });
    } else {
      updateDoc({ ...details, [DOCUMENT_GENERAL.pipelineStatus]: localState?.action, id });
    }
  };

  return {
    handleCalculateDocDetails,
    handleChanges,
    handleChangesEvent,
    handleToggleEditMode,
    handleUpdateDocument,
    handleTakeAction
  };
};
