import React from 'react';
import PropTypes from 'prop-types';
import { LabeledField, TextField, Button, BUTTON_TYPES } from 'app/common';
import { useEmailConfirmationData, useEmailConfirmationHandlers } from './hooks';
import './_email-confirmation.scss';

export const EmailConfirmation = ({ onSubmit }) => {
  const { localState, localActions } = useEmailConfirmationData();

  const { handleSubmit } = useEmailConfirmationHandlers({
    props: {
      onSubmit
    },
    localState
  });

  return (
    <>
      <LabeledField label="New password" className="email-confirmation__labeled-field">
        <TextField
          type="password"
          value={localState.newPassword}
          onChange={localActions.setNewPassword}
          placeholder="New password"
          required
        />
      </LabeledField>
      <LabeledField label="Confirm password" className="email-confirmation__labeled-field">
        <TextField
          type="password"
          value={localState.confirmationPassword}
          onChange={localActions.setConfirmationPassword}
          placeholder="Re-enter new password"
          required
        />
      </LabeledField>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        className="email-confirmation__confirm-button"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );
};

EmailConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
