import type { EitlLabelOption } from 'shared/features/eitlLabels/eitlLabels.types';
import type { EitlLabelsSectionProps } from '../EitlLabelsSection.types';
import type { EitlLabelsSectionHandlers } from './useEitlLabelsSectionHandlers.types';

import * as crawlDetailsConstants from 'admin/containers/CrawlerPage/CrawlerDetails/CrawlerDetails.constants';

export const useEitlLabelsSectionHandlers = ({
  props
}: {
  props: Pick<EitlLabelsSectionProps, 'labels' | 'onChange'>;
}): EitlLabelsSectionHandlers => {
  const handleEitlLabelsChange: EitlLabelsSectionHandlers['handleEitlLabelsChange'] = value => {
    props.onChange(crawlDetailsConstants.CRAWL_GENERAL.eitlLabels, value as EitlLabelOption[]);
  };

  const handleEitlLabelCreate: EitlLabelsSectionHandlers['handleEitlLabelCreate'] =
    eitlLabelOption => {
      const eitlLabelOptions = [...props.labels, eitlLabelOption];

      props.onChange(crawlDetailsConstants.CRAWL_GENERAL.eitlLabels, eitlLabelOptions);
    };

  return {
    handleEitlLabelsChange,
    handleEitlLabelCreate
  };
};
