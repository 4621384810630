import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import {
  mainCrawlSettings,
  firstStepTemplate,
  stepTemplate,
  exampleTemplate,
  documentTemplate
} from './crawlCreation.constants';

function collectExactExamples(ids, examples) {
  const collectExamples = examples.filter(example => ids.includes(example.id));
  return collectExamples.map(example => ({
    url: example.url,
    text: example.text,
    occurrence: +example.occurrence
  }));
}

function collectMetaData(metaData, examples) {
  return metaData.reduce((allMetaData, data) => {
    allMetaData[data.name] = {
      examples: collectExactExamples(data.examples, examples)
    };
    return allMetaData;
  }, {});
}

function collectSteps(steps, examples) {
  // Settings for parent_context
  const parentContextCollector = steps.reduce((parentCollector, step) => {
    if (step.contextExamples && step.contextExamples.length && !step.contextIsUrl) {
      parentCollector[step.next_step] = step.name;
    }
    return parentCollector;
  }, {});

  return steps.reduce((allSteps, step) => {
    // Main settings
    const formattedStep = {
      description: step.description
    };
    // Context settings
    if (step.contextExamples && step.contextExamples.length) {
      formattedStep.context = {
        is_url: step.contextIsUrl,
        examples: collectExactExamples(step.contextExamples, examples)
      };
    }
    // Doc settings
    if (step.doc && step.doc.length) {
      formattedStep.doc = collectMetaData(step.doc, examples);
    }
    // Next step settings
    if (step.next_step) {
      formattedStep.next_step = step.next_step && step.next_step.split(',');
    }
    // parent_context settings
    if (parentContextCollector[step.name]) {
      formattedStep.parent_context = parentContextCollector[step.name];
    }

    allSteps[step.name] = formattedStep;
    return allSteps;
  }, {});
}

function checkCrawlSettings(crawlSettings) {
  // crawlSettings is object
  return _.some(crawlSettings, value => !value);
}

function checkCommonStepDependencies(steps, examplesID) {
  return steps.some(step => {
    // check main params
    const errorMainParams = !step.description || !step.name;

    // check next step if content exist (required)
    const errorContextNextStep = step.contextExamples
      && _.intersection(step.contextExamples, examplesID).length
      && !step.next_step;

    // check names in meta data
    const errorMetaData = step.doc
      && step.doc.length
      && step.doc.some(doc => !doc.name);

    return errorMainParams || errorContextNextStep || errorMetaData;
  });
}

function checkExampleSettings(examples) {
  return examples.some(example => (
    !example.url
    || !example.text
    || !example.occurrence
  ));
}

const formatCrawlSettings = crawlSettings => ({
  ...crawlSettings,
  agency_id: Number(_.get(crawlSettings, 'agency_id', '')) || ''
});

export default function (crawlSettings, steps, examples) {
  const examplesID = examples.map(step => step.id);
  if (
    checkCrawlSettings(crawlSettings)
    || checkCommonStepDependencies(steps, examplesID)
    || checkExampleSettings(examples)
  ) {
    return false;
  }

  return {
    ...formatCrawlSettings(crawlSettings),
    configurations: {
      steps: collectSteps(steps, examples)
    }
  };
}


// ----------------- Functions to convert crawl spec json to code acceptable view ------------------

function getCrawlSettings(rawSettings = {}) {
  return mainCrawlSettings.reduce(
    (commonState, item) => ({ ...commonState, [item.state_name]: _.get(rawSettings, item.state_name, '') }),
    {}
  );
}

function getStepExamples(rawExamples = []) {
  const examplesUUID = [];
  const examples = rawExamples.map(example => {
    const exampleID = uuidv4();
    examplesUUID.push(exampleID);
    return {
      ...exampleTemplate,
      ...example,
      occurrence: String(_.get(example, 'occurrence', 1)),
      id: exampleID
    };
  });
  return { examplesUUID, examples };
}

function getStepMetaData(rawMetaData = {}) {
  let metaDataExamples = [];
  const metaData = [];
  _.forOwn(rawMetaData, (value, key) => {
    const docExamples = getStepExamples(_.get(value, 'examples', []));
    metaData.push({
      ...documentTemplate,
      id: uuidv4(),
      name: key,
      examples: docExamples.examplesUUID
    });
    metaDataExamples = [
      ...metaDataExamples,
      ...docExamples.examples
    ];
  });
  return { metaData, metaDataExamples };
}

// 01.03.2021 - added support for multi steps
// this functions help to format next steps
function getNextStep(value) {
  let step = _.get(value, 'next_step', null);
  if (_.isArray(step)) {
    step = step.join(',');
  }
  return step;
}

function getCrawlStepSettings(rawSettings = {}) {
  const stepsCollection = [];
  let examplesCollection = [];
  _.forOwn(rawSettings, (value, key) => {
    if (key === firstStepTemplate.name) {
      stepsCollection.push({
        ...firstStepTemplate,
        next_step: getNextStep(value)
      });
    } else {
      const contextExamples = getStepExamples(_.get(value, ['context', 'examples'], []));
      const metaData = getStepMetaData(_.get(value, 'doc', {}));
      stepsCollection.push({
        ...stepTemplate,
        name: key,
        id: uuidv4(),
        description: _.get(value, 'description', ''),
        contextIsUrl: _.get(value, ['context', 'is_url'], false),
        next_step: getNextStep(value),
        contextExamples: contextExamples.examplesUUID,
        doc: metaData.metaData
      });
      examplesCollection = [
        ...examplesCollection,
        ...contextExamples.examples,
        ...metaData.metaDataExamples
      ];
    }
  });
  return {
    steps: stepsCollection,
    examples: examplesCollection
  };
}


export {
  collectExactExamples, collectMetaData, collectSteps, checkCrawlSettings,
  checkCommonStepDependencies, checkExampleSettings, getCrawlSettings, getCrawlStepSettings,
  getStepExamples, getStepMetaData
};
