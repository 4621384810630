import type { FC } from 'react';
import type { ProcessedDocsCellProps } from './ProcessedDocsCell.types';

import { useProcessedDocsCellData } from './hooks';

export const ProcessedDocsCell: FC<ProcessedDocsCellProps> = ({ row }) => {
  const { formattedData } = useProcessedDocsCellData({ row });

  return <>{formattedData.processedDocsCount}</>;
};
