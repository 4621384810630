import {
  api_fetchSentenceModel
} from './sentenceModel.api';

export const RECEIVE_SENTENCE_MODEL = 'RECEIVE_SENTENCE_MODEL';
export const CLEAR_SENTENCE_MODEL = 'CLEAR_SENTENCE_MODEL';

function receiveSentenceModel(json) {
  return {
    type: RECEIVE_SENTENCE_MODEL,
    sentenceModel: json
  };
}

function clearSentenceModel(json) {
  return {
    type: CLEAR_SENTENCE_MODEL,
    sentenceModel: null
  };
}

export function fetchSentenceModel(params = {}) {
  return function dofetch(dispatch) {
    dispatch(clearSentenceModel());
    return api_fetchSentenceModel(params)
      .then(response => {
        dispatch(receiveSentenceModel(response));
        return response;
      })
      .catch(error => {
        dispatch(receiveSentenceModel({}));
      });
  };
}
