import { fetch, AUTHENTICATION_OPTIONS_KEYS } from "fetch";

export function api_fetchAgencies(following, search_filter, useAPIToken) {
  const params = {};
  if (following) {
    params.following = following;
  }
  if (search_filter) {
    params.search_filter = search_filter;
  }

  return fetch({
    url: `/agencies`,
    params,
    dataType: 'agencies',
    method: 'GET',
    authenticationType: useAPIToken ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : AUTHENTICATION_OPTIONS_KEYS.REDUX_STATE
  });
}

export function api_fetchAgencySummaries(agency_id) {
  return fetch({
    url: `/agency_summaries`,
    params: { agency_id },
    method: 'GET',
    dataType: 'agency_summaries',
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY
  });
}

export function api_followAgencies(agencies) {
  return fetch({
    url: '/agencies',
    params: agencies,
    dataType: 'sources',
    method: 'POST'
  });
}
