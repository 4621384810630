import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/Button";

const CrawlRunNowModalBodyBottomBtnBlock = ({ closePopup, runNow, onCancel }) => {
  return (
    <div className="footerBtnBlock">
      <Button
        size="small"
        color="highlighted"
        onClick={() => {
          onCancel();
          closePopup();
        }}
      >
        Cancel
      </Button>
      <Button
        size="small"
        onClick={runNow}
      >
        Run
      </Button>
    </div>
  );
};

CrawlRunNowModalBodyBottomBtnBlock.propTypes = {
  closePopup: PropTypes.func.isRequired,
  runNow: PropTypes.func,
  onCancel: PropTypes.func
};

CrawlRunNowModalBodyBottomBtnBlock.defaultProps = {
  runNow: () => {},
  onCancel: () => {}
};

export default CrawlRunNowModalBodyBottomBtnBlock;
