import type { ComponentProps } from 'react';
import type { DocumentStatusCellValue } from 'admin/components';
import type { ReportDocumentFromResponse } from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.api.types';
import type { DocsModalHandlers } from './useDocsModalHandlers.types';
import type { AuditEntriesCellValue, EventsCellValue } from 'admin/components';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../DocsModal.constants';
import * as reportDocumentsConstants from 'shared/features/crawlerSettings/reportDocuments/reportDocuments.constants';
import * as dateConstants from 'admin/constants/date';

/**
 *
 * This hooks transforms the data received in handleDocumentsFetch
 * and transforms it to the expected format of the table column renderers
 *
 */
export const useDocsModalTableDataSelector = () => {
  return (
    data: Awaited<ReturnType<DocsModalHandlers['handleDocumentsFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => {
        const document = row as ReportDocumentFromResponse;

        return {
          [constants.TABLE_KEYS.DOC_ID_KEY]:
            document.id || `${document?.title}-${document.publication_date}-${document?.web_url}`,
          [constants.TABLE_KEYS.TITLE]: document.title,

          [constants.TABLE_KEYS.STATUS]: document.status,

          [constants.TABLE_KEYS.REASON]: document.reason,

          [constants.TABLE_KEYS.ID]: {
            href: `${window.location.origin}/documents/${document.id}`,
            tooltip: `${window.location.origin}/documents/${document.id}`,
            children: document.id
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.AUDIT_ENTRIES]: {
            documentId: document.id
          } as AuditEntriesCellValue,

          [constants.TABLE_KEYS.EVENTS]: {
            documentId: document.id
          } as EventsCellValue,

          [constants.TABLE_KEYS.PIPELINE_STATUS]: {
            pipelineStatus: document.pipeline_status,
            documentExaminationRecord: document.document_examination_record
          } as DocumentStatusCellValue,

          [constants.TABLE_KEYS.PUBLICATION_DATE]: {
            value:
              document.status !== reportDocumentsConstants.CRAWL_STATUS.SKIPPED
                ? document.publication_date
                : null,
            passedTimezone: uiLib.TIMEZONES.UTC,
            timezone: uiLib.TIMEZONES.UTC,
            format: uiLib.DATE_FORMATS.HUMANIZED_MONTH_DAY_YEAR,
            /**
             * Passed pub date is not exactly UTC. We just cast it to UTC and keep the time returned from
             * the api so the date won't shift after formatting
             */
            shouldKeepLocalTime: true
          } as uiLib.DateCellValue,

          [constants.TABLE_KEYS.CREATED_AT]: {
            value:
              document.status !== reportDocumentsConstants.CRAWL_STATUS.SKIPPED
                ? document.created_at
                : null,
            passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
            passedTimezone: uiLib.TIMEZONES.UTC,
            timezone: uiLib.getLocalTimezone(),
            format: uiLib.DATE_FORMATS.API_DATE_TIME
          } as uiLib.DateCellValue,

          [constants.TABLE_KEYS.UPDATED_AT]: {
            value:
              document.status !== reportDocumentsConstants.CRAWL_STATUS.SKIPPED
                ? document.updated_at
                : null,
            passedFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT,
            passedTimezone: uiLib.TIMEZONES.UTC,
            timezone: uiLib.getLocalTimezone(),
            format: uiLib.DATE_FORMATS.API_DATE_TIME
          } as uiLib.DateCellValue,

          [constants.TABLE_KEYS.PDF_URL]: {
            href: document.pdf_url,
            tooltip: document.pdf_url,
            children: document.pdf_url
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.WEB_URL]: {
            href: document.web_url,
            tooltip: document.web_url,
            children: document.web_url
          } as uiLib.LinkCellValue,

          [constants.TABLE_KEYS.CATEGORY]: document.category
        };
      })
    };
  };
};
