import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import _ from 'lodash';
import { Button, Pagination } from 'react-bootstrap';
import {
  fetchSpiderRunReportsCrawlerStatistic
} from 'shared/features/spiderRunReports/spiderRunReports.actions';
import PopupInfo from '../../components/PopupInfo';
import { popupName } from '../../constants/popupInfoConst';
import CrawlerPerformanceToolModal from './CrawlerPerformanceToolModal';
import CrawlerPerformanceTableHeader from './CrawlerPerformanceTableHeader';
import CrawlerPerformanceTableBody from './CrawlerPerformanceTableBody';
import CrawlerPerformanceCommandRequestTableHeader from './CrawlerPerformanceCommandRequestTableHeader';
import CrawlerPerformanceCommandRequestTableBody from './CrawlerPerformanceCommandRequestTableBody';
import { getExternalApiCrawls } from 'shared/features/externalApiCrawl/externalApiCrawl.actions';
import SelectRender, { typeOfField } from '../../components/SelectRender';
import Spinner from '../../components/spinner';
import DualDatepicker from '../../components/DualDatepicker';
import { clearErrors } from 'shared/features/error/actions';
import {
  fetchAllCommandRequests, uploadNewCommandRequest, fetchAllCommands
} from 'shared/features/commands/commands.actions';
import {
  getExactCommandRequests
} from 'shared/features/commands/commands.selector';
import { commandRequestTypes } from "../../constants/commandRequestTypes";
import CRAWLERS_TYPES, { CRAWL_ACTIVITY_TYPE } from "../../constants/crawlersTypes";

const dateFormat = 'MM/DD/YYYY';
const round_to = 2;
const limit = 10;

class CrawlerPerformanceTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopupText: '',
      showSecondInfoTable: false,
      showInfoPopup: false,
      showPreload: false,
      sort: {},
      currentSort: { field: 'docs_ingested', type: true },

      spider_name: null,
      is_active: null,
      type: null,
      start_start_date: null,
      finish_finish_date: null,
      only_with_statistic: null,

      spiderRunReport: {},

      page: 1,
      amountOfButtons: 1,
      s3_file_name: ''
    };
  }

  componentDidMount() {
    this.props.clearErrors('spider_run_reports_crawler_statistic');
    this.props.getExternalApiCrawls({});

    if (!this.props.commands.items.length) {
      this.props.fetchAllCommands();
    }
    if (!this.props.command_requests.items.length) {
      this.props.fetchAllCommandRequests();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  countOffset = () => {
    const { page } = this.state;
    return limit * (page - 1);
  };

  showGeneratedInfo = (crawlPerformanceRequest) => {
    const status_details = crawlPerformanceRequest.status_details || '';
    const amountOfFiles = status_details.split('_')[0];
    if (!status_details || !amountOfFiles) {
      this.setState({
        infoPopupText: `Sorry, I can't get file property from this request.`,
        showInfoPopup: true
      });
      return;
    }
    this.setState({
      showSecondInfoTable: true,
      page: 1,
      amountOfButtons: Math.ceil((+amountOfFiles || 1) / (limit || 10)),
      currentSort: { field: 'docs_ingested', type: true },
      s3_file_name: status_details
    }, this.requestSpiderRunReportsCrawlerStatistic);
  };

  requestSpiderRunReportsCrawlerStatistic = () => {
    const { s3_file_name, currentSort } = this.state;
    this.props.fetchSpiderRunReportsCrawlerStatistic({
      s3_file_name,
      limit,
      offset: this.countOffset(),
      sort_field: currentSort.field,
      sort_type: currentSort.type
    });
  };

  handlePageChange = (page) => {
    this.setState({
      page
    }, this.requestSpiderRunReportsCrawlerStatistic);
  };

  toggleModal = (value, spiderRunReport) => {
    this.setState({
      showModal: value,
      spiderRunReport: spiderRunReport || {}
    });
  };

  collectInfo = () => {
    const performanceCommandParams = {};
    const {
      start_start_date, finish_finish_date,
      is_active, type, spider_name, only_with_statistic
    } = this.state;
    performanceCommandParams.parameters = {
      is_active,
      spider_name,
      type,
      only_with_statistic,
      start_start_date: start_start_date && moment(start_start_date).format(dateFormat),
      finish_finish_date: finish_finish_date && moment(finish_finish_date).format(dateFormat)
    };

    try {
      performanceCommandParams.type = this.props.commands.items
        .filter(command => command.parameters.type === commandRequestTypes.crawlPerformance)[0].id;
    } catch (e) {
      this.setState({
        infoPopupText: `Command with type '${commandRequestTypes.crawlPerformance}' doesn't exist.`,
        showInfoPopup: true
      });
      return false;
    }

    return performanceCommandParams;
  };

  createDateRequest = () => {
    const collectInfo = this.collectInfo();
    if (!collectInfo) {
      return;
    }
    const command_request_parameters = {
      name: `Get statistic: ${_.map(collectInfo.parameters, (value, key) => (`${key}:${value}`))}`,
      user_id: this.props.current_user.user.id,
      ...collectInfo
    };

    const commandRequestParams = new window.FormData();
    commandRequestParams.append('command_request_parameters', JSON.stringify(command_request_parameters));
    this.props.uploadNewCommandRequest(commandRequestParams);
  };

  sortTable = (columName) => {
    const { sort } = this.state;
    this.setState({
      sort: { ...sort, [columName]: !sort[columName] },
      currentSort: { field: columName, type: !sort[columName] }
    }, this.requestSpiderRunReportsCrawlerStatistic);
  };

  averageCounter = (total, total_runs) => (total / total_runs || 0).toFixed(round_to);

  render() {
    const {
      rss_feeds, commands, command_requests
    } = this.props;

    const preLoadIsReady = !commands.isReady
      || !command_requests.isReady;

    const selectorElements = [
      {
        id: 1,
        state_name: 'is_active',
        name: 'is active',
        categories: CRAWL_ACTIVITY_TYPE.map(item => ({
          value: item,
          label: JSON.stringify(item)
        })),
        typeOfInput: typeOfField.select
      },
      {
        id: 2,
        state_name: 'type',
        name: 'Type',
        multiType: true,
        categories: _.map(CRAWLERS_TYPES, item => ({
          value: item.value,
          label: item.label
        })),
        typeOfInput: typeOfField.select
      },
      {
        id: 3,
        state_name: 'spider_name',
        name: 'Spider Name',
        categories: rss_feeds.items.length
          && rss_feeds.items.map(item => ({
            value: item.spider_name,
            label: item.spider_name
          })),
        typeOfInput: typeOfField.select
      },
      {
        id: 4,
        state_name: 'only_with_statistic',
        name: 'Has SLA reports',
        categories: [
          { value: true, label: 'True' }
        ],
        typeOfInput: typeOfField.select
      }
    ];

    const selectBarRender = selectorElements.map(item => (
      <SelectRender
        key={item.id}
        item={item}
        state={this.state}
        handleChange={this.handleChange}
      />
    ));

    return (
      <div className="thinBtn AdminToolPageTemplate">
        <h1>Crawler Performance Tool</h1>
        <hr />
        <div className="filterAdminToolWrap">
          <DualDatepicker
            title={'Time of run'}
            stateNames={['start_start_date', 'finish_finish_date']}
            state={this.state}
            handleChange={this.handleChange}
          />
          {selectBarRender}
        </div>
        <hr />
        {this.state.showSecondInfoTable && (
          <Button
            bsStyle="primary"
            onClick={() => this.setState({ showSecondInfoTable: false })}
          >
            Back
          </Button>
        )}
        <Button
          bsStyle="primary"
          className="rightBtn"
          onClick={this.createDateRequest}
        >
          Create request
        </Button>
        <div style={{ clear: 'both' }} />
        {this.state.showSecondInfoTable ? (
          <>
            <div className="adminToolTableFlex">
              <CrawlerPerformanceTableHeader
                sortTable={this.sortTable}
                sort={this.state.sort || {}}
              />
              <CrawlerPerformanceTableBody
                spiderRunReports={this.props.crawlerPerformance || []}
                toggleModal={this.toggleModal}
                averageCounter={this.averageCounter}
              />
            </div>
            <Pagination
              bsSize="small"
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              items={this.state.amountOfButtons}
              maxButtons={5}
              activePage={this.state.page}
              onSelect={this.handlePageChange}
            />
          </>
        ) : (
          <div className="adminToolTableFlex">
            <CrawlerPerformanceCommandRequestTableHeader />
            <CrawlerPerformanceCommandRequestTableBody
              crawlPerformanceRequests={this.props.crawlPerformanceRequests}
              showGeneratedInfo={this.showGeneratedInfo}
            />
          </div>
        )}
        {preLoadIsReady && (
          <div className="spinnerWrapper">
            <Spinner />
          </div>
        )}
        <CrawlerPerformanceToolModal
          showModal={this.state.showModal}
          closeModal={() => this.toggleModal(false)}
          spiderRunReport={this.state.spiderRunReport}
          dateFormat={dateFormat}
          averageCounter={this.averageCounter}
        />
        <PopupInfo
          show={!!this.props.errors.spider_run_reports_crawler_statistic
          || this.state.showInfoPopup}
          onHide={() => {
            this.props.clearErrors('spider_run_reports_crawler_statistic');
            this.setState({
              showInfoPopup: false,
              infoPopupText: ''
            });
          }}
          popupName={popupName.errorRespPopup}
          errorText={
            this.state.infoPopupText
            || this.props.errors.spider_run_reports_crawler_statistic
            && this.props.errors.spider_run_reports_crawler_statistic[0]
            && this.props.errors.spider_run_reports_crawler_statistic[0].responseText
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    rss_feeds: state.externalApiCrawl,
    crawlerPerformance: state.spiderRunReportsCrawlerStatistic,
    current_user: state.current_user,
    commands: state.commands,
    command_requests: state.command_requests,
    crawlPerformanceRequests: getExactCommandRequests(state, commandRequestTypes.crawlPerformance)
  };
};

const ReduxCollectionTool = connect(mapStateToProps, {
  fetchSpiderRunReportsCrawlerStatistic,
  clearErrors,
  getExternalApiCrawls,
  fetchAllCommandRequests,
  uploadNewCommandRequest,
  fetchAllCommands
})(CrawlerPerformanceTool);

export default ReduxCollectionTool;
