import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'admin/utils/hooks';
import cn from 'classnames';
import ReportIcon from '@material-ui/icons/Report';
import ROUTES from '../../../constants/routes';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import Select from '../../../components/ReactSelect/Select';
import { CONCEPT_ACTIVE_TYPES, CONCEPT_ATTRIBUTES } from './ConceptDetails.constants';
import {
  CONTENT_MANAGEMENT_TAB_KEYS,
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM
} from '../../ContentManagement/ContentManagement.constants';

const CONCEPT_STATUSES = {
  [CONCEPT_ACTIVE_TYPES.ACTIVE]: {
    label: (
      <Typography variant="p">
        <CheckCircleIcon />
        {' Active'}
      </Typography>
    ),
    value: CONCEPT_ACTIVE_TYPES.ACTIVE,
    updateValue: { is_active: true },
    className: 'navigation-select_green'
  },
  [CONCEPT_ACTIVE_TYPES.DEACTIVATED]: {
    label: (
      <Typography variant="p">
        <ReportIcon />
        {' Deactivated'}
      </Typography>
    ),
    value: CONCEPT_ACTIVE_TYPES.DEACTIVATED,
    updateValue: { is_active: false },
    className: 'navigation-select_red'
  }
};

const ConceptPageStatus = ({ details, status, changeStatus }) => {
  const history = useHistory();
  const location = useLocation();

  const goBack = () =>
    history.push(
      `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.CONCEPTS}`,
      location.state
    );

  const isActivationDisabled = details.id === 'new';

  const createdAt = useMemo(
    () => details[CONCEPT_ATTRIBUTES.createdAt],
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [details[CONCEPT_ATTRIBUTES.createdAt]]
  );

  const updatedAt = useMemo(
    () => details[CONCEPT_ATTRIBUTES.updatedAt],
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [details[CONCEPT_ATTRIBUTES.updatedAt]]
  );

  return (
    <div className="concept-navigation_status">
      <div className="concept-navigation_status_date-block">
        <Typography italic>{`Created: ${createdAt}`}</Typography>
        <Typography italic>{`Updated: ${updatedAt}`}</Typography>
      </div>
      <div className="concept-navigation_status_status-block">
        <Typography variant="p" color="gray">
          Status
        </Typography>
        <Select
          className="concept-navigation_status_status-block_select-wrapper"
          selectClassName={cn(
            'concept-navigation_status_status-block_select-wrapper_select',
            CONCEPT_STATUSES[status].className
          )}
          value={CONCEPT_STATUSES[status]}
          onChange={changeStatus}
          options={Object.values(CONCEPT_STATUSES)}
          isSearchable={false}
          isDisabled={isActivationDisabled}
        />
      </div>
      <div className="concept-navigation_status_icon-block">
        <Button onClick={goBack}>
          <CloseIcon fontSize="large" />
        </Button>
      </div>
    </div>
  );
};

ConceptPageStatus.propTypes = {
  details: PropTypes.shape({}),
  changeStatus: PropTypes.func.isRequired,
  status: PropTypes.shape({}).isRequired
};

ConceptPageStatus.defaultProps = {
  details: {}
};

export default ConceptPageStatus;
