import _ from "lodash";
import { useMemo } from "react";
import { AGENCY_ATTRIBUTES } from "./Sources.constants";
import { api_getJurisdictions } from '../../../../shared/features/jurisdictionsData/jurisdictionsData.api';
import { api_getAgencies } from '../../../../shared/features/agencyData/agencyData.api';

const formatSelectOptions = (array, valueName, labelName) => array.map(item => ({
  value: _.get(item, valueName, item),
  label: _.get(item, labelName, item)
}));

export const loadJurisdictionsOptions = (
  name, searchValue, responseName
) => api_getJurisdictions(
  { [name]: searchValue }
)
  .then(jur => jur.items)
  .then(jur => formatSelectOptions(jur, responseName, responseName));

export const loadAgencyOptions = (
  name, searchValue, responseName
) => api_getAgencies(
  { [name]: searchValue }
)
  .then(agency => agency.items)
  .then(agency => formatSelectOptions(agency, responseName, responseName));

export const getAgencyInfo = agency => {
  return {
    [AGENCY_ATTRIBUTES.name]: _.get(agency, 'name', null),
    [AGENCY_ATTRIBUTES.shortName]: _.get(agency, 'short_name', null),
    [AGENCY_ATTRIBUTES.agencyId]: _.get(agency, ['words', 'agency_id'], null),
    [AGENCY_ATTRIBUTES.parentId]: _.get(agency, 'parent_id', null),
    [AGENCY_ATTRIBUTES.type]: _.get(agency, 'type', null),
    [AGENCY_ATTRIBUTES.url]: _.get(agency, 'url', null),
    [AGENCY_ATTRIBUTES.jurisdiction]: _.get(agency, 'jurisdiction', null),
    [AGENCY_ATTRIBUTES.jurisdictionId]: _.get(agency, 'jurisdiction_id', null),
    [AGENCY_ATTRIBUTES.slug]: _.get(agency, 'slug', null),
    [AGENCY_ATTRIBUTES.active]: _.get(agency, 'active', null),
    [AGENCY_ATTRIBUTES.blacklisted]: _.get(agency, 'blacklisted', null),
    [AGENCY_ATTRIBUTES.popular]: _.get(agency, 'popular', null),
    [AGENCY_ATTRIBUTES.description]: _.get(agency, 'description', null),
    [AGENCY_ATTRIBUTES.id]: _.get(agency, 'id', null),
    [AGENCY_ATTRIBUTES.logoUrl]: _.get(agency, 'logo_url', null),
    [AGENCY_ATTRIBUTES.parentName]: _.get(agency, 'parent_name', null),
    [AGENCY_ATTRIBUTES.timesCited]: _.get(agency, 'times_cited', null)
  };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const formatAgenciesFormat = agencies => useMemo(
  () => agencies.map(getAgencyInfo),
  [agencies]
);

// ----- Search Options Helper -----

export const formatSearchValues = searchValues => {
  const formatParameters = {};
  for (const key in searchValues) {
    if (Object.prototype.hasOwnProperty.call(searchValues, key)) {
      let value = null;
      switch (key) {
        case AGENCY_ATTRIBUTES.shortName:
        case AGENCY_ATTRIBUTES.jurisdictionId:
        case AGENCY_ATTRIBUTES.jurisdiction: {
          const array = _.get(searchValues, key, []);
          value = array && array.length
            ? array.map(item => item.value)
            : null;
          break;
        }
        case AGENCY_ATTRIBUTES.multiField:
          value = _.get(searchValues, key, null);
          break;
        default:
          break;
      }
      if (_.isBoolean(value) || value) formatParameters[key] = value;
    }
  }
  return formatParameters;
};
