import React from 'react';
import cn from "classnames";

export default function Spinner({ className = '' }) {
  return (
    <div className={cn("stairs-spinner", className)}>
      <div />
      <div />
      <div />
    </div>
  );
}
