export const SUPER_ADMIN = 'super_admin';
export const TEAM_ADMIN = 'team_admin';
export const WORKFLOW_ADMIN = 'workflow_admin';
export const DATA_ADMIN = 'data_admin';
export const USER_ADMIN = 'user_admin';
export const DATA_ASSOCIATED_ADMIN = 'data_associate_admin';
export const BASIC_ADMIN = 'basic_admin';
export const ADMIN = 'admin';
export const RSS = 'rss';
export const QA = 'qa';
export const CONTRIBUTOR = 'contributor';
export const POWER_USER = 'power_user';

export const ALL_USER_ROLES = [
  POWER_USER,
  CONTRIBUTOR,
  QA,
  RSS,
  ADMIN,
  BASIC_ADMIN,
  DATA_ASSOCIATED_ADMIN,
  USER_ADMIN,
  DATA_ADMIN,
  WORKFLOW_ADMIN,
  TEAM_ADMIN,
  SUPER_ADMIN
];
