import React from 'react';
import PropTypes from 'prop-types';

import * as constants from './CrawlerDetailsAdditionalInfo.constants';
import * as detailsConstants from '../../CrawlerDetails.constants';
import * as elements from './elements';
import * as uiLib from '@compliance.ai/web-components';

import Typography from 'admin/components/Typography';
import ExpandableList from 'admin/components/ExpandableList';
import TextInput from 'admin/components/TextInput';
import Textarea from 'admin/components/Textarea';
import Switch from 'admin/components/Switch';
import Select from 'admin/components/ReactSelect/Select';
import DatePicker from '../../../../../components/DatePicker';
import { ARCHIVE_UNIFIED } from '../../../../../constants/runNowParametersNames';
import { DOCUMENT_GENERAL } from '../../../../DocumentPage/DocumentDetails/DocumentDetails.constants';
import { CRAWL_GENERAL } from '../../CrawlerDetails.constants';
import CRAWL_TYPES from '../../../../../constants/crawlersTypes';

const isResourceText =
  'If this toggle is turned on, new and existing documents from this crawl will be surfaced in the Resource section of Compliance.ai application.';
const notRequiredPubDateText =
  'If this toggle is turned on, documents ingested or updated by this crawl will be surfaced in the Compliance.ai platform with system generated publication date. This is mainly used in cases where the source does not provide publication dates to their documents';
const isCheckAllText =
  'Do not terminate crawler when pub_date of the document is earlier than starting date of the defined date interval';
const useProxyText = 'Use a proxy when accessing the source';
const startingDate = 'Use a proxy when accessing the source';
export const CrawlerDetailsAdditionalInfo = ({
  eitlLabelEnabled,
  editMode,
  handleChanges,
  handleChangesEvent,
  crawlDetails,
  errorFields,
  openViewModal,
  openViewSentencificationConfigurationModal,
  openViewAutoQAModal,
  enableAutoTranslationSelectedOption
}) => {
  const disableEdit = !editMode;
  return (
    <div className="additionalInfo">
      <div className="additionalInfo_block">
        <Typography variant="h5">Associated Links</Typography>
        <ExpandableList
          label="Source URL"
          name={detailsConstants.CRAWL_LINKS.sourceURL}
          value={crawlDetails[detailsConstants.CRAWL_LINKS.sourceURL] || []}
          isDisabled={disableEdit}
          onChange={handleChanges}
          isLink
        />
        <ExpandableList
          label="Associated JIRA Tickets"
          name={detailsConstants.CRAWL_LINKS.JIRA}
          value={crawlDetails[detailsConstants.CRAWL_LINKS.JIRA] || []}
          isDisabled={disableEdit}
          onChange={handleChanges}
          isLink
        />
        <ExpandableList
          label="Other Links"
          name={detailsConstants.CRAWL_LINKS.other}
          value={crawlDetails[detailsConstants.CRAWL_LINKS.other] || []}
          isDisabled={disableEdit}
          onChange={handleChanges}
          isLink
        />
        <TextInput
          name={detailsConstants.CRAWL_LINKS.QA}
          placeholder="QA Spreadsheet"
          value={crawlDetails[detailsConstants.CRAWL_LINKS.QA]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isLink
        />
        <TextInput
          name={detailsConstants.CRAWL_LINKS.bucket}
          placeholder="S3 Bucket"
          value={crawlDetails[detailsConstants.CRAWL_LINKS.bucket]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isLink
        />
        <TextInput
          name={detailsConstants.CRAWL_LINKS.specification}
          placeholder="Specification"
          value={crawlDetails[detailsConstants.CRAWL_LINKS.specification]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isLink
        />
      </div>
      <div className="additionalInfo_block">
        <Typography variant="h5">Provisions</Typography>
        <Switch
          name={detailsConstants.CRAWL_PROVISION.enableDocumentVersioning}
          label="Surface Document Versions as Stand-Alone Documents"
          value={crawlDetails[detailsConstants.CRAWL_PROVISION.enableDocumentVersioning]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        >
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={constants.DOC_VERSIONING_TOOLTIP}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </Switch>
        <Select
          name={detailsConstants.CRAWL_PROVISION.enableAutoTranslation}
          label="Auto translation"
          isDisabled={disableEdit}
          value={enableAutoTranslationSelectedOption}
          options={detailsConstants.ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS}
          onChange={e => handleChanges(detailsConstants.CRAWL_PROVISION.enableAutoTranslation, e)}
          isError={errorFields.includes(detailsConstants.CRAWL_PROVISION.enableAutoTranslation)}
        />
        <Switch
          name={detailsConstants.CRAWL_PROVISION.notRequirePubDate}
          label={'Publication Date not Required'}
          value={crawlDetails[detailsConstants.CRAWL_PROVISION.notRequirePubDate]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        >
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={notRequiredPubDateText}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </Switch>
        <Switch
          name={detailsConstants.CRAWL_PROVISION.isResource}
          label={'Resource Crawl'}
          value={crawlDetails[detailsConstants.CRAWL_PROVISION.isResource]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        >
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={isResourceText}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </Switch>
        <Switch
          name={detailsConstants.CRAWL_PROVISION.isCheckAll}
          label={'Check All Publication Dates'}
          value={crawlDetails[detailsConstants.CRAWL_PROVISION.isCheckAll]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        >
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={isCheckAllText}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </Switch>
        <Switch
          name={detailsConstants.CRAWL_PROVISION.useProxy}
          label={'Use Proxy'}
          value={crawlDetails[detailsConstants.CRAWL_PROVISION.useProxy]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        >
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            placement={uiLib.TOOLTIP_POSITION.BOTTOM}
            title={useProxyText}
          >
            <uiLib.IconInfoRounded className="infoTooltipIcon" />
          </uiLib.Tooltip>
        </Switch>
        <uiLib.LabeledField label={'Starting Date'}>
          <uiLib.DatePicker
            value={crawlDetails[detailsConstants.CRAWL_PROVISION.startingDate]}
            onChange={date => {
              handleChanges(detailsConstants.CRAWL_PROVISION.startingDate, date);
            }}
            isDisabled={disableEdit}
            format={uiLib.getLocalDateFormat()}
            placeholder={uiLib.getLocalDateFormat().toLowerCase()}
          />
        </uiLib.LabeledField>
        <TextInput
          name={detailsConstants.CRAWL_PROVISION.nodeURL}
          placeholder="Root Node URL"
          value={crawlDetails[detailsConstants.CRAWL_PROVISION.nodeURL]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isError={errorFields.includes(detailsConstants.CRAWL_PROVISION.nodeURL)}
          isLink
        />
        <uiLib.LabeledField label="Root Shelf Id" isInline>
          <uiLib.TextField
            name={detailsConstants.CRAWL_PROVISION.rootShelfId}
            value={crawlDetails[detailsConstants.CRAWL_PROVISION.rootShelfId]}
            onChange={handleChangesEvent}
            isDisabled
          />
        </uiLib.LabeledField>
        <Textarea
          name={detailsConstants.CRAWL_PROVISION.docket}
          placeholder="Dockets"
          value={crawlDetails[detailsConstants.CRAWL_PROVISION.docket]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isLink
        />
        <uiLib.Button onClick={openViewModal} className="additionalInfo_button">
          View source structure
        </uiLib.Button>
        <uiLib.Button
          onClick={openViewSentencificationConfigurationModal}
          className="additionalInfo_button"
        >
          View sentencification configuration
        </uiLib.Button>
        <uiLib.Button onClick={openViewAutoQAModal} className="additionalInfo_button">
          View Crawl Auto QA
        </uiLib.Button>
      </div>
      {eitlLabelEnabled && (
        <div className="additionalInfo_block">
          <elements.EitlLabelsSection
            labels={crawlDetails[detailsConstants.CRAWL_GENERAL.eitlLabels]}
            onChange={handleChanges}
            isDisabled={disableEdit}
          />
        </div>
      )}
      <div className="additionalInfo_block">
        <Typography variant="h5">Privacy</Typography>
        <Switch
          name={detailsConstants.CRAWL_PRIVACY.access}
          label="Restricted access"
          value={crawlDetails[detailsConstants.CRAWL_PRIVACY.access]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
        />
      </div>
    </div>
  );
};

CrawlerDetailsAdditionalInfo.propTypes = {
  editMode: PropTypes.bool,
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  crawlDetails: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string),
  openViewModal: PropTypes.func.isRequired
};

CrawlerDetailsAdditionalInfo.defaultProps = {
  editMode: false,
  crawlDetails: {},
  errorFields: []
};
