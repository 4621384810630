import type { FC } from 'react';
import type { ButtonLinkCellProps } from './ButtonDownloadCell.types';

import * as uiLib from '@compliance.ai/web-components';

import './_button-download-cell.scss';

export const ButtonDownloadCell: FC<ButtonLinkCellProps> = ({ report, onOpenModal }) => {
  return (
    <div className="dry-run-reports-button-download-wrapper">
      <uiLib.Tooltip title={report?.summary_text}>
        <uiLib.Button
          type={uiLib.BUTTON_TYPES.SECONDARY}
          onClick={() => onOpenModal([report?.official_id as string, report?.reason as string])}
        >
          <uiLib.Typography>{`View ${report?.title}`}</uiLib.Typography>
        </uiLib.Button>
      </uiLib.Tooltip>
      <uiLib.Button href={report?.reason as string}>
        <uiLib.Typography>{`Download ${report?.title}`}</uiLib.Typography>
      </uiLib.Button>
    </div>
  );
};
