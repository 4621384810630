import type { FC } from 'react';
import type { ArchivedProps, ArchivedCellValue } from './ArchivedCell.types';

import * as uiLib from '@compliance.ai/web-components';

export const ArchivedCell: FC<ArchivedProps> = ({ row }) => {
  const isArchivedValue = row.is_archived as ArchivedCellValue;

  return <uiLib.Typography>{isArchivedValue ? 'True' : 'False'}</uiLib.Typography>;
};
