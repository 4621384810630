import type { CommandRequestsLinksButtonCellHandlers } from './useCommandRequestsLinksButtonCellHandlers.types';

import { downloadFromURL } from 'app/utils/downloads';

export const useCommandRequestsLinksButtonCellHandlers = ({
  props
}: {
  props: {
    downloadLinks: string[];
  };
}): CommandRequestsLinksButtonCellHandlers => {
  const handleDownloadFiles: CommandRequestsLinksButtonCellHandlers['handleDownloadFiles'] = () => {
    props.downloadLinks.forEach((fileLink, index) =>
      setTimeout(() => downloadFromURL(fileLink), index * 2000)
    );
  };

  return {
    handleDownloadFiles
  };
};
