import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_getMetaConcepts(params) {
  return fetch({
    url: `/meta-concepts`,
    dataType: 'MetaConcept',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
