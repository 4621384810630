import _ from 'lodash';
import {
  api_fetchAllPublications,
  api_updatePublication
} from './publication.api';

export const REQUEST_ALL_PUBLICATIONS = 'REQUEST_ALL_PUBLICATIONS';
export const RECEIVE_ALL_PUBLICATIONS = 'RECEIVE_ALL_PUBLICATIONS';
export const UPDATE_PUBLICATION = 'UPDATE_PUBLICATION';
export const PUBLICATION_UPDATED = 'PUBLICATION_UPDATED';

function requestAllPublications() {
  return {
    type: REQUEST_ALL_PUBLICATIONS
  };
}

function receiveAllPublications(json) {
  return {
    type: RECEIVE_ALL_PUBLICATIONS,
    publications: json.publications
  };
}

function updatePublication() {
  return {
    type: UPDATE_PUBLICATION
  };
}

function publicationUpdated(json) {
  return {
    type: PUBLICATION_UPDATED,
    publication: _.get(json, 'publication')
  };
}

export function fetchAllPublications() {
  return function dofetch(dispatch) {
    dispatch(requestAllPublications());
    return api_fetchAllPublications()
      .then(response => {
        dispatch(receiveAllPublications(response));
      });
  };
}

export function updatePublicationWithParams(publicationId, data) {
  return function dopost(dispatch) {
    dispatch(updatePublication());
    return api_updatePublication(publicationId, data)
      .then(response => {
        dispatch(publicationUpdated(response));
      })
      .catch(error => {
        dispatch(publicationUpdated(null));
      });
  };
}
