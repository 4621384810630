import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'crawlerDocuments';
export const initialState = {
  spec: {},
  config: {},
  dryRun: {},
  specOptions: {}
};

const crawlerDocumentReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setCrawlerSpec: (state, action) => ({ ...state, spec: action.payload }),
    setCrawlerConfig: (state, action) => ({ ...state, config: action.payload }),
    setCrawlerDryRun: (state, action) => ({ ...state, dryRun: action.payload }),
    setCrawlerSpecOptions: (state, action) => ({ ...state, specOptions: action.payload })
  }
});

export default crawlerDocumentReducer.reducer;
export const {
  setCrawlerSpec,
  setCrawlerConfig,
  setCrawlerDryRun,
  setCrawlerSpecOptions
} = crawlerDocumentReducer.actions;
