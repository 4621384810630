import {
  api_getExternalApiCrawls,
  api_postExternalApiCrawls,
  api_putExternalApiCrawls,
  api_deleteExternalApiCrawls
} from './externalApiCrawl.api';
import {
  startLoad,
  finishLoad,
  setExternalApiCrawl,
  createExternalApiCrawl,
  updateExternalApiCrawl,
  deleteExternalApiCrawl,
  initialState
} from './externalApiCrawl.reducer';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from './externalApiCrawl.constants';

export const getExternalApiCrawls = params => async dispatch => {
  try {
    dispatch(startLoad());
    const response = await api_getExternalApiCrawls(params);
    await dispatch(setExternalApiCrawl(response));
    dispatch(finishLoad());
    return response;
  } catch (error) {
    dispatch(finishLoad());
  }
};

export const createExternalApiCrawls = params => async dispatch => {
  try {
    dispatch(startLoad());
    const response = await api_postExternalApiCrawls(params);
    await dispatch(createExternalApiCrawl(response));
    uiLib.showNotification(constants.EXTERNAL_API_CRAWL_SUCCESS_MESSAGES.CREATED);
    await dispatch(finishLoad());
    return response;
  } catch (error) {
    dispatch(finishLoad());
  }
};

export const updateExternalApiCrawls = params => async dispatch => {
  try {
    dispatch(startLoad());
    const response = await api_putExternalApiCrawls(params);
    await dispatch(updateExternalApiCrawl(response));
    uiLib.showNotification(constants.EXTERNAL_API_CRAWL_SUCCESS_MESSAGES.UPDATED);
    dispatch(finishLoad());
    return response;
  } catch (error) {
    dispatch(finishLoad());
  }
};

export const removeExternalApiCrawls = params => async dispatch => {
  try {
    dispatch(startLoad());
    const response = await api_deleteExternalApiCrawls(params);
    await dispatch(deleteExternalApiCrawl(params));
    uiLib.showNotification(constants.EXTERNAL_API_CRAWL_SUCCESS_MESSAGES.DELETED);
    dispatch(finishLoad());
    return response;
  } catch (error) {
    dispatch(finishLoad());
  }
};

export const clearExternalApiCrawls = params => async dispatch => {
  try {
    dispatch(startLoad());
    const response = await dispatch(setExternalApiCrawl(params || initialState));
    dispatch(finishLoad());
    return response;
  } catch (error) {
    dispatch(finishLoad());
  }
};
