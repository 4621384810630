import type { FC } from 'react';
import type { CategoryEditModalProps } from './CategoryEditModal.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useCategoryEditModalHandlers,
  useCategoryEditModalData,
  useCategoryEditModalFormattedData,
  useCategoryEditModalMutations
} from './hooks';

export const CategoryEditModal: FC<CategoryEditModalProps> = ({ category, onConfirm, onClose }) => {
  const { localState, localActions } = useCategoryEditModalData({ props: { category } });

  const mutations = useCategoryEditModalMutations({
    props: {
      category,
      onConfirm
    },
    localState
  });

  const handler = useCategoryEditModalHandlers();

  const formattedData = useCategoryEditModalFormattedData({
    props: {
      category
    },
    localState,
    mutations
  });

  return (
    <uiLib.Modal
      isOpen
      title="Edit Cai Category"
      withFooter
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Edit"
      onSubmit={mutations.updateCaiCategory.mutate}
      onClose={onClose}
    >
      <div>
        {mutations.updateCaiCategory.isLoading && <uiLib.Loader withoutBackground={false} />}
        <uiLib.LabeledField label="Cai Category name">
          <uiLib.TextField
            value={localState.caiCategoryName}
            onChange={localActions.setCaiCategoryName}
            required
          />
        </uiLib.LabeledField>
        <uiLib.LabeledField label="Cai Category description">
          <uiLib.TextField
            value={localState.caiCategoryDescription}
            onChange={localActions.setCaiCategoryDescription}
          />
        </uiLib.LabeledField>

        <uiLib.LabeledField label="Meta Category">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            isSearchable
            isAsync
            loadOptions={handler.handleMetaCategoryFetch}
            value={localState.docMetaCategory}
            placeholder="# meta category"
            onChange={value => localActions.setDocMetaCategory(value as uiLib.SelectOption)}
          />
        </uiLib.LabeledField>
        <uiLib.Switch
          label="Surface in filter"
          isChecked={localState.surfaceInFilter}
          onChange={localActions.setSurfaceInFilter}
        />
        <uiLib.Select
          isDisabled
          isMulti
          options={formattedData.jurisdictions}
          value={formattedData.jurisdictions}
        />
      </div>
    </uiLib.Modal>
  );
};
