import type { EitlLabelRenameModalProps } from '../EitlLabelRenameModal.types';
import type { EitlLabelRenameModalData } from './useEitlLabelRenameModalData.types';

import * as eitlLabelsApiTypes from 'shared/features/eitlLabels/eitlLabels.api.types';
import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { useMutation } from '@tanstack/react-query';
import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';

export const useEitlLabelRenameModalMutations = ({
  props,
  localState
}: {
  props: Pick<EitlLabelRenameModalProps, 'label' | 'onConfirm'>;
  localState: EitlLabelRenameModalData['localState'];
}) => {
  const eitlLabelsApi = useEitlLabelsApi();

  const renameEitlLabel = useMutation<Awaited<eitlLabelsApiTypes.NormalizedEitlLabel>, Error, void>(
    {
      mutationKey: queryKeysConstants.COMPLEX_QUERY_KEY_GETTERS[
        queryKeysConstants.QUERY_KEYS.EITL_LABELS
      ][queryKeysConstants.QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_RENAME]({
        id: Number(props.label.id),
        name: localState.eitlLabelName
      }),
      mutationFn: () =>
        eitlLabelsApi.modifyEitlLabel({
          id: Number(props.label.id),
          params: { name: localState.eitlLabelName }
        }),
      onError: errorUtils.logReactQueryError,
      onSuccess: () => {
        props.onConfirm();

        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.SUCCESS,
          message: 'EITL Label successfully renamed'
        });
      }
    }
  );

  return {
    renameEitlLabel
  };
};
