import React from 'react';
import { RUN_STATUS_BADGE } from '../../CrawlMonitorTool.constants';
import Badge from '../../../../components/Badge';
import * as uiLib from '@compliance.ai/web-components';

export const CrawlRunStatus: Required<uiLib.Column>['render'] = value => {
  let badgeInfo;
  if (value === 'timed_out') {
    badgeInfo = RUN_STATUS_BADGE.TIMED_OUT;
  } else if (value === 'stopped') {
    badgeInfo = RUN_STATUS_BADGE.STOPPED;
  } else if (value === 'warning') {
    badgeInfo = RUN_STATUS_BADGE.WARNING;
  } else if (value === 'failed') {
    badgeInfo = RUN_STATUS_BADGE.FAILED;
  } else if (value === 'error') {
    badgeInfo = RUN_STATUS_BADGE.ERROR;
  } else if (value === 'processed') {
    badgeInfo = RUN_STATUS_BADGE.SUCCESS;
  }
  if (!badgeInfo) {
    return <div />;
  }
  return (
    <Badge type={badgeInfo.type} size={'medium'} title={badgeInfo.text} className={''}>
      {badgeInfo.text}
    </Badge>
  );
};
