// ISSUE_SEVERITY
export const REVIEW = { name: 'REVIEW', value: 'review' };
export const HIDE_NOW = { name: 'HIDE NOW', value: 'hide_now' };
export const DUPLICATE = { name: 'DUPLICATE', value: 'duplicated' };
export const POTENTIALLY_DUPLICATE = { name: 'POTENTIALLY DUPLICATE', value: 'potential_duplicate' };

// ISSUE_TYPE
export const TECHNICAL = { name: 'TECHNICAL', value: 'technical' };
export const NOT_RELEVANT = { name: 'NOT RELEVANT', value: 'not relevant' };

// ERRORS
export const ERROR_RELATED_DOCS_REQUIRED = 'Field "Related documents" is required.';

// ISSUE_SEVERITY that flag on jurispect_data
export const DATA_ISSUE_STATUSES = [DUPLICATE.value, POTENTIALLY_DUPLICATE.value];

export const ISSUE_SEVERITY = [REVIEW, HIDE_NOW, DUPLICATE, POTENTIALLY_DUPLICATE];
export const ISSUE_TYPE = [TECHNICAL, NOT_RELEVANT];
