import React from 'react';
import { connect } from 'react-redux';
import { Modal, FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';
import queryString from 'query-string';
import { withHistory } from 'admin/utils/hooks';
import {
  inviteToOrg,
  fetchOrganization
} from 'shared/features/organizations/organizations.actions';

class InviteOrgModal extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      email: '',
      roleOption: {}
    };
    this.state = this.initialState;
  }

  closeModal = () => {
    this.props.close();
    this.setState(this.initialState);
  };

  handleRangeChange = option => {
    this.setState({ roleOption: option });
  };

  inviteUser = async (e) => {
    e.preventDefault();
    const { org_id } = queryString.parse(this.props.location.search);
    const org_user_roles = this.state.roleOption.value ? [this.state.roleOption.value] : [];
    await this.props.inviteToOrg({ invitee_email: this.state.email, org_id, org_user_roles });
    this.closeModal();
    this.props.fetchOrganization(org_id);
  };

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.closeModal}
        dialogClassName="invite-admin-org-modal"
      >
        <Modal.Body>
          <form action="src/admin/containers/EditOrganizations" onSubmit={this.inviteUser}>
            <div className="invite-popup">
              <FormControl
                onChange={e => this.setState({ email: e.target.value })}
                value={this.state.email}
                required
                type="email"
                placeholder="invite with email"
              />
              <div className="Content">
                <span className="invite-user-area">
                  <p>To invite</p>
                  <Select
                    options={this.props.orgRoleOptions}
                    value={this.state.roleOption.value}
                    onChange={option => this.handleRangeChange(option)}
                    clearable={false}
                    isMulti={false}
                  />
                </span>
                <Button className="invite-btn" type="submit">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
export default connect(
  mapStateToProps,
  { inviteToOrg, fetchOrganization }
)(withHistory(InviteOrgModal));
