import type { GlobalState } from 'shared/reducers';
import type { EitlLabelAddModalData } from './useEitlLabelAddModalData.types';

import * as userSelectors from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useEitlLabelAddModalData = (): EitlLabelAddModalData => {
  const [eitlLabelName, setEitlLabelName] =
    useState<EitlLabelAddModalData['localState']['eitlLabelName']>('');

  const localState: EitlLabelAddModalData['localState'] = {
    eitlLabelName
  };

  const localActions: EitlLabelAddModalData['localActions'] = useMemo(
    () => ({
      setEitlLabelName
    }),
    []
  );

  const reduxState: EitlLabelAddModalData['reduxState'] = useSelector<
    GlobalState,
    EitlLabelAddModalData['reduxState']
  >(state => ({
    currentUserId: userSelectors.getCurrentUserId(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
