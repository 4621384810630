import React from "react";
import PropTypes from "prop-types";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/ReactSelect/Select";
import { STATISTIC_TYPES } from "../CrawlMonitorTool.constants";
import { getStartName, getEndName, getTypeName } from "../CrawlMonitorTool.helper";
import Typography from "../../../components/Typography";

const CrawlMonitorToolHeaderSearchGapSelector = ({
  handleChanges,
  handleEventChanges,
  name,
  label,
  search,
  options,
  disableSecond,
  isTypeClearable
}) => {
  const startName = getStartName(name);
  const endName = getEndName(name);
  const typeName = getTypeName(name);
  return (
    <label className="body-search-block_gap-selector">
      <Typography
        variant="tag"
        color="gray"
      >
        {label}
      </Typography>
      <div className="body-search-block_gap-selector_inputs-wrapper">
        <TextInput
          name={startName}
          onChange={handleEventChanges}
          value={search[startName]}
          type="number"
        />
        <TextInput
          name={endName}
          onChange={handleEventChanges}
          value={search[endName]}
          type="number"
          isDisabled={disableSecond}
        />
        <Select
          onChange={e => handleChanges(typeName, e)}
          options={options}
          value={search[typeName]}
          isClearable={isTypeClearable}
        />
      </div>
    </label>
  );
};

CrawlMonitorToolHeaderSearchGapSelector.propTypes = {
  handleChanges: PropTypes.func.isRequired,
  handleEventChanges: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  disableSecond: PropTypes.bool,
  isTypeClearable: PropTypes.bool,
  search: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }))
};

CrawlMonitorToolHeaderSearchGapSelector.defaultProps = {
  label: '',
  name: '',
  search: {},
  options: Object.values(STATISTIC_TYPES),
  disableSecond: false,
  isTypeClearable: true
};

export default CrawlMonitorToolHeaderSearchGapSelector;
