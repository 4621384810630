import _ from 'lodash';
import {
  RECEIVE_DOC_TYPES,
  REQUEST_ENFORCEMENTS,
  RECEIVE_ENFORCEMENTS,
  REQUEST_AGGREGATE_ENFORCEMENTS,
  RECEIVE_AGGREGATE_ENFORCEMENTS,
  REQUEST_GOOGLE_RESULTS,
  RECEIVE_GOOGLE_RESULTS,
  CLEAR_DOCUMENTS,
  REQUEST_DOCUMENTS,
  RECEIVE_DOCUMENTS,
  RECEIVE_DOCUMENTS_FOR_DASH_CCPA,
  RECEIVE_DOCUMENTS_FOR_DASH_COVID,
  REQUEST_DOCUMENTS_AFTER,
  RECEIVE_DOCUMENTS_AFTER,
  REQUEST_DOCUMENTS_BEFORE,
  RECEIVE_DOCUMENTS_BEFORE,
  REQUEST_FULL_DOCUMENTS,
  RECEIVE_FULL_DOCUMENTS,
  RECEIVE_RELATED_DOCUMENT_COUNT,
  REQUEST_ALL_DOCUMENTS,
  RECEIVE_ALL_DOCUMENTS,
  FLAG_DOCUMENT,
  FLAGGED_DOCUMENT,
  UPDATE_DOCUMENT,
  DOCUMENT_UPDATED,
  REQUEST_INCOMPLETE_DOCUMENTS,
  RECEIVE_INCOMPLETE_DOCUMENTS,
  CREATE_DOCUMENT,
  DOCUMENT_CREATED,
  REQUEST_SIMPLE_DOCUMENTS,
  RECEIVE_SIMPLE_DOCUMENTS,
  REQUEST_HIDDEN_DOCUMENTS,
  RECEIVE_HIDDEN_DOCUMENTS,
  REQUEST_DOCUMENT_DETAILS,
  RECEIVE_DOCUMENT_DETAILS,
  REQUEST_DOCUMENT,
  RECEIVE_DOCUMENT,
  ADD_DOCS_TO_DIFF,
  RECEIVE_DOCUMENT_COMMENTS_COUNT,
  MARK_DOCUMENT_BOOKMARKED,
  RECEIVE_SEARCH_RESULTS_RELEVANCE,
  REQUEST_SEARCH_RESULTS_RELEVANCE,
  REQUEST_RESOURCE_CODE,
  RECEIVE_RESOURCE_CODE,
  REQUEST_NEW_DOCUMENT_URL_HEADERS,
  RECEIVE_NEW_DOCUMENT_URL_HEADERS,
  REQUEST_POPULAR_DOCS,
  RECEIVE_POPULAR_DOCS,
  REQUEST_RECENT_ACTIVITY,
  RECEIVE_RECENT_ACTIVITY,
  REQUEST_DOCUMENTS_FOR_DASH_COVID,
  REQUEST_DOCUMENTS_FOR_DASH_CCPA,
  REMOVE_FROM_DOCUMENT_LIST
} from './documents.actions';
import { process_combined_documents, DEFAULT_DOCUMENT_SORT_KEY } from './documents.helper';

export const getInitialDocTypesState = () => ({});

export const docTypes = (state = getInitialDocTypesState(), action) => {
  switch (action.type) {
    case RECEIVE_DOC_TYPES:
      return {
        ...state,
        docTypes: action.docTypes
      };
    default:
      return state;
  }
};

export const getInitialEnforcementsState = () => ({
  isLoading: false,
  enforcements: [],
  isFetching: false,
  isReady: false,
  aggregateEnforcements: {}
});
export const enforcements = (state = getInitialEnforcementsState(), action) => {
  switch (action.type) {
    case REQUEST_AGGREGATE_ENFORCEMENTS:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE_AGGREGATE_ENFORCEMENTS:
      return {
        ...state,
        isLoading: false,
        aggregateEnforcements: action.aggregateEnforcements.reduce((obj, data) => ({
          ...obj,
          [data.short_name]: data
        }), {})
      };

    case REQUEST_ENFORCEMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ENFORCEMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        enforcements: action.payload
      };
    default:
      return state;
  }
};

export const getInitialGoogleSearchResultsState = () => ({
  isFetching: false,
  isReady: false,
  googleSearchResults: {
    searchInformation: {
      totalResults: ''
    },
    items: []
  }
});

export const googleSearchResults = (state = getInitialGoogleSearchResultsState(), action) => {
  switch (action.type) {
    case REQUEST_GOOGLE_RESULTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_GOOGLE_RESULTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        googleSearchResults: action.payload
      };
    default:
      return state;
  }
};

export const getInitialRecentDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  recent_documents: []
});

export const recent_documents = (state = getInitialRecentDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_SIMPLE_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_SIMPLE_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        recent_documents: action.recent_documents
      };
    default:
      return state;
  }
};

export const getInitialHiddenDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  hidden_documents: []
});

export const hidden_documents = (state = getInitialHiddenDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_HIDDEN_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_HIDDEN_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.hidden_documents
      };
    default:
      return state;
  }
};

export const getInitialDocumentDetailsState = () => ({
  isFetching: false,
  isReady: false,
  documents: {},
  related_count: {}, // by id
  comments_count: {} // by id
});

export const document_details = (state = getInitialDocumentDetailsState(), action) => {
  switch (action.type) {
    case REQUEST_DOCUMENT_DETAILS: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_DOCUMENT_DETAILS: {
      const new_documents = {};
      new_documents[action.document.id] = action.document;

      const new_state = {
        ...state,
        isFetching: false,
        isReady: true,
        documents: {
          ...state.documents,
          [action.document.id]: action.document
        }
      };
      return new_state;
    }
    case RECEIVE_RELATED_DOCUMENT_COUNT: {
      const doc_id = action.params.more_like_doc_id;
      const count = _.get(action.response, 'count', 0);

      return {
        ...state,
        related_count: {
          ...state.related_count,
          [doc_id]: count
        }
      };
    }
    case RECEIVE_DOCUMENT_COMMENTS_COUNT: {
      const doc_id = action.params.comments_for_id;
      const count = _.get(action.response, 'count', 0);

      return {
        ...state,
        comments_count: {
          ...state.comments_count,
          [doc_id]: count
        }
      };
    }
    case MARK_DOCUMENT_BOOKMARKED: {
      const new_state = _.cloneDeep(state); // XXX more targeted
      const my_docs = _.filter(new_state.documents, doc => {
        return _.includes(action.ids, doc.id);
      });
      for (const doc of my_docs) {
        doc.bookmarked = action.bookmarked_status;
      }

      for (const id of Object.keys(new_state.documents)) {
        const doc = new_state.documents[id];

        if (doc.children) {
          for (const child of doc.children.filter(c => _.includes(action.ids, c.id))) {
            child.bookmarked = action.bookmarked_status;
          }
        }
      }

      return new_state;
    }
    default:
      return state;
  }
};

export const getInitialCurrentDocumentState = () => ({
  isFetching: false,
  isReady: false,
  doc: {}
});

export const current_document = (state = getInitialCurrentDocumentState(), action) => {
  switch (action.type) {
    case REQUEST_DOCUMENT:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_DOCUMENT:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        doc: action.payload
      };
    default:
      return state;
  }
};

export const getInitialFlaggedDocumentState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  document: {}
});

export const flagged_document = (state = getInitialFlaggedDocumentState(), action) => {
  switch (action.type) {
    case FLAG_DOCUMENT:
      return {
        ...state,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case FLAGGED_DOCUMENT:
      return {
        ...state,
        isReady: true,
        isUpdating: false,
        updated: true,
        document: _.get(action, 'document', state.document)
      };
    default:
      return state;
  }
};

export const getInitialUpdatedDocumentState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  document: {}
});

export const updated_document = (state = getInitialUpdatedDocumentState(), action) => {
  switch (action.type) {
    case UPDATE_DOCUMENT:
      return {
        ...state,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case DOCUMENT_UPDATED:
      return {
        ...state,
        isReady: true,
        isUpdating: false,
        updated: true,
        document: _.get(action, 'document', state.document)
      };
    default:
      return state;
  }
};

export const getInitialCreateDocumentState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  document: {}
});

export const create_document = (state = getInitialCreateDocumentState(), action) => {
  switch (action.type) {
    case CREATE_DOCUMENT:
      return {
        ...state,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case DOCUMENT_CREATED:
      return {
        ...state,
        isReady: true,
        isUpdating: false,
        updated: true,
        document: _.get(action, 'document', state.document)
      };
    default:
      return state;
  }
};

export const getInitialDocumentsFullState = () => ({
  isFetching: false,
  isReady: false,
  ids: {}
});

export const documents_full = (state = getInitialDocumentsFullState(), action) => {
  switch (action.type) {
    case REQUEST_FULL_DOCUMENTS: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_FULL_DOCUMENTS: {
      const new_ids = {};
      for (const document of action.documents) {
        new_ids[document.id] = document;
      }
      const new_state = {
        ...state,
        ids: {
          ...state.ids,
          ...new_ids
        }
      };

      if (!action.no_ready_update) {
        new_state.isFetching = false;
        new_state.isReady = true;
      }

      return new_state;
    }
    default: {
      return state;
    }
  }
};

export const getInitialAllDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  alldocuments: []
});

//seperate documents store for admin document editing interface
export const all_documents = (state = getInitialAllDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ALL_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.alldocuments
      };
    case REMOVE_FROM_DOCUMENT_LIST:
      return {
        ...state,
        items: {
          documents: state.items.documents.filter(document => document.id !== action.doc.id)
        }
      };
    default:
      return state;
  }
};

export const getInitialIncompleteDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  items: []
});

//seperate documents store for admin document editing interface
export const incomplete_documents = (state = getInitialIncompleteDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_INCOMPLETE_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_INCOMPLETE_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.incomplete_documents
      };
    default:
      return state;
  }
};

export const getInitialDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  count: 0,
  combined_list: [],
  document_index: {},
  offset: 0,
  dashCCPADocuments: {
    isReady: false,
    count: 0,
    documents: []
  },
  dashCovidDocuments: {
    isReady: false,
    count: 0,
    documents: []
  },
  newest_dates: {},
  oldest_dates: {},
  offsets: {},
  documentsToDiff: []
});

export const documents = (state = getInitialDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_DOCUMENTS_FOR_DASH_COVID:
      return {
        ...state,
        dashCovidDocuments: {
          ...state.dashCovidDocuments,
          isReady: false
        }
      };

    case REQUEST_DOCUMENTS_FOR_DASH_CCPA:
      return {
        ...state,
        dashCCPADocuments: {
          ...state.dashCCPADocuments,
          isReady: false
        }
      };

    case RECEIVE_DOCUMENTS_FOR_DASH_CCPA:
      return {
        ...state,
        dashCCPADocuments: {
          ...state.dashCCPADocuments,
          count: action.data[DEFAULT_DOCUMENT_SORT_KEY].count,
          documents: action.data[DEFAULT_DOCUMENT_SORT_KEY].documents,
          isReady: true
        }
      };

    case RECEIVE_DOCUMENTS_FOR_DASH_COVID:
      return {
        ...state,
        dashCovidDocuments: {
          ...state.dashCovidDocuments,
          count: action.data[DEFAULT_DOCUMENT_SORT_KEY].count,
          documents: action.data[DEFAULT_DOCUMENT_SORT_KEY].documents,
          isReady: true
        }
      };

    case REQUEST_DOCUMENTS:
    case REQUEST_DOCUMENTS_AFTER:
    case REQUEST_DOCUMENTS_BEFORE: {
      return {
        ...state,
        offset: _.get(action, 'params.offset', 0),
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_DOCUMENTS: {
      return process_combined_documents(true, state, action);
    }
    case RECEIVE_DOCUMENTS_BEFORE:
    case RECEIVE_DOCUMENTS_AFTER: {
      return process_combined_documents(false, state, action);
    }
    case CLEAR_DOCUMENTS: {
      return getInitialDocumentsState();
    }
    case MARK_DOCUMENT_BOOKMARKED: {
      return {
        ...state,
        combined_list: state.combined_list.map(doc => {
          if (_.includes(action.ids, doc.id)) {
            doc.bookmarked = action.bookmarked_status;
          }
          return doc;
        })
      };
    }
    case ADD_DOCS_TO_DIFF: {
      return {
        ...state,
        documentsToDiff: action.documentsToDiff
      };
    }

    default: {
      return state;
    }
  }
};

export const getInitialSearchResultsRelevanceState = () => ({
  isFetching: false,
  isReady: false,
  results: {}
});

// store for relevance-based search results
export const search_results_relevance = (
  state = getInitialSearchResultsRelevanceState(),
  action
) => {
  switch (action.type) {
    case REQUEST_SEARCH_RESULTS_RELEVANCE: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_SEARCH_RESULTS_RELEVANCE: {
      return {
        ...state,
        isFetching: false,
        isReady: true,
        results: action.data
      };
    }
    case MARK_DOCUMENT_BOOKMARKED: {
      const new_state = _.cloneDeep(state); // XXX more targeted
      const my_docs = _.filter(new_state.results.documents, doc => {
        return _.includes(action.ids, doc.id);
      });
      for (const doc of my_docs) {
        doc.bookmarked = action.bookmarked_status;
      }
      return new_state;
    }
    default: {
      return state;
    }
  }
};

export const getInitialUSStateState = () => ({
  isFetching: false,
  isReady: false,
  codes: {}
});

export const us_state = (state = getInitialUSStateState(), action) => {
  switch (action.type) {
    case REQUEST_RESOURCE_CODE: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_RESOURCE_CODE: {
      const state_code_ids = {};
      for (const branch of action.branches) {
        state_code_ids[branch.id] = branch;
      }

      const new_state = {
        ...state,
        isFetching: false,
        isReady: true,
        codes: {
          ...state.codes,
          ...state_code_ids
        }
      };

      return new_state;
    }

    default:
      return state;
  }
};


export const getInitialNewDocumentURLHeaders = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  headers: {}
});

export const new_document_url_headers = (state = getInitialNewDocumentURLHeaders(), action) => {
  switch (action.type) {
    case REQUEST_NEW_DOCUMENT_URL_HEADERS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_NEW_DOCUMENT_URL_HEADERS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.headers
      };
    default:
      return state;
  }
};

export const getInitialPopularDocsState = () => ({
  isFetching: false,
  isReady: false,
  popular_docs: []
});

export const popular_docs = (state = getInitialPopularDocsState(), action) => {
  switch (action.type) {
    case REQUEST_POPULAR_DOCS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_POPULAR_DOCS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        popular_docs: action.popular_docs
      };
    default:
      return state;
  }
};

export const getInitialRecentActivityState = () => ({
  isFetching: false,
  isReady: false,
  document_stats: [],
  total_updates: null
});

export const recent_activity = (state = getInitialRecentActivityState(), action) => {
  switch (action.type) {
    case REQUEST_RECENT_ACTIVITY: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_RECENT_ACTIVITY: {
      return {
        ...state,
        isFetching: false,
        isReady: true,
        document_stats: action.document_stats,
        total_updates: action.total_updates
      };
    }
    default: {
      return state;
    }
  }
};
