import React, { useMemo } from "react";
import _ from 'lodash';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { AGENCY_ATTRIBUTES } from '../Sources.constants';

const getAgencyRowInfo = ({
  agency,
  activeInfo
}) => {
  const formatInfo = activeInfo.map(field => {
    const fieldValue = _.get(agency, field, null);
    switch (field) {
      case AGENCY_ATTRIBUTES.url:
        return <a href={fieldValue}>{fieldValue && _.truncate(fieldValue)}</a>;
      case AGENCY_ATTRIBUTES.active:
      case AGENCY_ATTRIBUTES.blacklisted:
      case AGENCY_ATTRIBUTES.popular:
        return fieldValue && <CheckCircleIcon className="cai-categories_table_filter-status_true" />;
      default:
        return fieldValue;
    }
  });
  return [
    ...formatInfo,
    // this for settings column
    ''
  ];
};

export default ({
  agenciesFormat,
  activeInfo
}) => {
  return useMemo(
    () => agenciesFormat.map(agency => getAgencyRowInfo({
      agency,
      activeInfo
    })),
    [agenciesFormat, activeInfo]
  );
};
