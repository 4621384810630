import {
  REQUEST_SLOT_INFO_FOR_DOC,
  RECEIVE_SLOT_INFO_FOR_DOC
} from './slot.actions';

export const getInitialSlotToolDocInfoState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  slot_tool_doc: []
});

export const slot_tool_doc_info = (state = getInitialSlotToolDocInfoState(), action) => {
  switch (action.type) {
    case REQUEST_SLOT_INFO_FOR_DOC:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_SLOT_INFO_FOR_DOC:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        slot_tool_doc: action.slot_tool_doc
      };
    default:
      return state;
  }
};
