import type { EditCrawlConfigModalProps } from '../EditCrawlConfigModal.types';
import type { EditCrawlConfigModalHandlers } from './useEditCrawlConfigModalHandlers.types';

export const useEditCrawlConfigModalHandlers = ({
  props
}: {
  props: Pick<EditCrawlConfigModalProps, 'initialCrawlConfig' | 'onCrawlConfigChange' | 'onClose'>;
}): EditCrawlConfigModalHandlers => {
  const handleClose: EditCrawlConfigModalHandlers['handleClose'] = () => {
    props.onCrawlConfigChange(props.initialCrawlConfig);
    props.onClose();
  };

  return {
    handleClose
  };
};
