import { fetch } from 'fetch';

export function api_fetchActs(ids) {
  return fetch({
    url: `/acts`,
    params: {
      ids: ids.join(',')
    },
    dataType: 'regulations',
    method: 'GET'
  });
}
