import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from "styled-components";
import classnames from 'classnames';

const StyledModal = styled(Modal)`
  padding: 150px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);

  .default-modal-header {
    width: ${props => props.width};
    margin: auto;
    background-color: #FFFFFF;
    display: flex;
    height: 54px;
    padding: 20px;
    align-items: center;
    justify-content: flex-end;

    .default-modal-closeicon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .default-modal-children-container {
    background-color: #FFFFFF;
    width: ${props => props.width};
    margin: auto;
    padding: 0px 20px 20px 20px;
  }
`;

const DefaultModal = ({
  dataTestId,
  children,
  closeAfterTransition,
  container,
  width,
  customContainer,
  disableAutoFocus,
  disableBackdropClick,
  disableEnforceFocus,
  disableEscapeKeyDown,
  disablePortal,
  disableRestoreFocus,
  disableScrollLock,
  hideBackdrop,
  keepMounted,
  onBackdropClick,
  onClose,
  onEscapeKeyDown,
  onRendered,
  open,
  classname,
  childContainerClassName,
  headerClassName
}) => {
  return (
    <StyledModal
      data-testid={dataTestId}
      closeAfterTransition={closeAfterTransition}
      container={container}
      disableAutoFocus={disableAutoFocus}
      disableBackdropClick={disableBackdropClick}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disablePortal={disablePortal}
      disableRestoreFocus={disableRestoreFocus}
      disableScrollLock={disableScrollLock}
      hideBackdrop={hideBackdrop}
      keepMounted={keepMounted}
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      onEscapeKeyDown={onEscapeKeyDown}
      onRendered={onRendered}
      open={open}
      width={width}
      className={classname}
    >
      <>
        {customContainer ? children : (
          <>
            <div className={classnames(headerClassName, "default-modal-header")}>
              <CloseIcon className="default-modal-closeicon" onClick={onClose} fontSize="large" />
            </div>
            <div className={classnames(childContainerClassName, 'default-modal-children-container')}>
              {children}
            </div>
          </>
        )}
      </>
    </StyledModal>
  );
};

export default DefaultModal;

DefaultModal.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.element.isRequired,
  closeAfterTransition: PropTypes.bool,
  container: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customContainer: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disablePortal: PropTypes.bool,
  disableRestoreFocus: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  keepMounted: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onEscapeKeyDown: PropTypes.func,
  onRendered: PropTypes.func,
  open: PropTypes.bool.isRequired,
  classname: PropTypes.string,
  childContainerClassName: PropTypes.string,
  headerClassName: PropTypes.string
};

DefaultModal.defaultProps = {
  dataTestId: '',
  closeAfterTransition: null,
  container: null,
  width: '70%',
  customContainer: false,
  disableAutoFocus: null,
  disableBackdropClick: null,
  disableEnforceFocus: null,
  disableEscapeKeyDown: null,
  disablePortal: null,
  disableRestoreFocus: null,
  disableScrollLock: null,
  hideBackdrop: null,
  keepMounted: null,
  onBackdropClick: null,
  onEscapeKeyDown: null,
  onRendered: null,
  classname: '',
  childContainerClassName: '',
  headerClassName: ''
};
