import type * as types from './documentAuditEntries.api.types';

import * as fetch from 'fetch';

export const fetchDocumentAuditEntries: types.FetchDocumentAuditEntries = async params => {
  return fetch.fetch({
    url: '/document-audit-entries/',
    dataType: 'Document Event',
    baseApi: fetch.BASE_API_OPTIONS.BASE_API_DATA,
    method: 'GET',
    params: params
  });
};
