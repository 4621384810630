import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Comments from './elements/Comments/Comments';
import { CrawlerHistory } from './elements/CrawlerHistory';
import { colors } from 'admin/components/MaterialComponents/colors/colors';
import MTabs from 'admin/components/MaterialComponents/Tab/mTab';

const COMMENTS = 'Comments';
const HISTORY = 'History';

const useStyle = makeStyles({
  wrapper: {
    marginRight: 70
  },
  indicator: {
    backgroundColor: colors.WHITE
  },
  additionalInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30
  },
  tab: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    '& span': {
      fontSize: 16,
      lineHeight: 1.8,
      fontWeight: 600,
      textTransform: 'capitalize'
    },
    '& button': {
      padding: 0,
      minWidth: 'auto',
      fontSize: 18,
      lineHeight: '45px',
      marginRight: 23
    }
  }
});

const generalInfo = [
  { label: COMMENTS, component: <Comments /> },
  { label: HISTORY, component: <CrawlerHistory /> }
];

export const AdditionalInfo = () => {
  const [showComponent, setComponent] = useState(COMMENTS);
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <MTabs
        classNameWrapper={classes.additionalInfo}
        generalInfo={generalInfo}
        showComponent={showComponent}
        onChange={setComponent}
        indicatorClassname={classes.indicator}
        tabClassname={classes.tab}
      />
    </div>
  );
};
