import type { CommandRequestsData } from '../../hooks/useCommandRequestsData.types';
import type { CommandRequestsHandlers } from '../../hooks/useCommandRequestsHandlers.types';
import type { CommandSelect } from './hooks/useCommandRequestsCreationModalData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import {
  useCommandRequestsCreationModalData,
  useCommandRequestsCreationModalHandlers,
  useCommandRequestsCreationModalLifecycle
} from './hooks';

export const CommandRequestsCreationModal = ({
  isOpen,
  onClose
}: {
  isOpen: CommandRequestsData['localState']['isCreationModalOpen'];
  onClose: CommandRequestsHandlers['handleCreationModalClose'];
}) => {
  const { localState, localActions, formattedData, reduxState } =
    useCommandRequestsCreationModalData();
  const handlers = useCommandRequestsCreationModalHandlers({
    localState,
    localActions,
    reduxState,
    onClose
  });
  useCommandRequestsCreationModalLifecycle({ onCommandChanged: handlers.handleChangeCommand });
  return (
    <uiLib.Modal
      isOpen={isOpen}
      onClose={onClose}
      withFooter
      primaryButtonText="Save"
      title="Create new command"
      onSubmit={handlers.handleCreateCommandRequest}
      isPrimaryButtonDisabled={formattedData.isSubmitDisabled}
    >
      <div>
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          isSearchable
          isAsync
          loadOptions={handlers.handleCommandsFetch}
          shouldFetchDefaultOptions
          value={localState.selectedCommand}
          placeholder="Command"
          onChange={value => localActions.setSelectedCommand(value as CommandSelect)}
        />
        {localState.selectedCommand?.value && (
          <elements.CommandRequestsTemplates
            parentState={localState}
            parentActions={localActions}
            parentHandlers={handlers}
          />
        )}
        {localState.isUserInputError && (
          <uiLib.Alert type={uiLib.ALERT_TYPES.ERROR} content="Please check you entries!" />
        )}
      </div>
    </uiLib.Modal>
  );
};
