// this component should not be reused
// will be refactored in the future to ./Checkbox_NEW

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography from "./Typography";

function Checkbox({
  className,
  onClick,
  checked,
  labelClassName,
  label,
  size,
  disabled
}) {
  const checkBoxPress = (e) => {
    if (disabled) return;
    onClick(!checked, e);
  };

  const getCheckbox = () => {
    if (checked) {
      return (<CheckBoxIcon
        fontSize={size}
      />);
    }
    return (<CheckBoxOutlineBlankIcon
      fontSize={size}
    />);
  };

  return (
    <div
      className={cn(
        "checkbox_wrapper",
        className,
        {
          // this styles could be used to apply custom styles
          checked_checkbox: checked,
          unchecked_checkbox: !checked
        }
      )}
      onClick={checkBoxPress}
    >
      {getCheckbox()}
      <Typography
        variant="p"
        className={cn(labelClassName)}
      >
        {label}
      </Typography>
    </div>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  className: '',
  labelClassName: '',
  label: '',
  size: 'default'
};

export default Checkbox;
