import React from "react";
import { components } from "react-select-3";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";
import Checkbox from "../Checkbox";
import Typography from "../Typography";

const CustomMenuList = ({
  props,
  onChange,
  values,
  onChangeCategory,
  categoryValue
}) => {
  const handleChanges = (value, item) => {
    let result;
    const allValues = values || [];
    if (value) {
      result = [...allValues, item];
    } else {
      result = allValues.filter(sub => sub.value !== item.value);
    }
    onChange(result);
  };

  return (
    <components.MenuList {...props} className="custom-select_menu">
      <div className="custom-select_menu-list">
        <div className="custom-select_menu-list_category">
          {props.options.map(item => (
            <div
              onClick={() => onChangeCategory(item)}
              key={item.value}
              className={cn(
                "custom-select_menu-list_item",
                {
                  "custom-select_menu-list_item_active": item.value === _.get(categoryValue, 'value', null)
                }
              )}
            >
              <Typography variant="p">{item.label}</Typography>
            </div>
          ))}
        </div>
        <div className="custom-select_menu-list_sub-category">
          {_.get(categoryValue, 'options', []).map((item, i) => {
            const selectedItem = !!_.find(values, item);
            return (
              <div
                key={`${item.value}${i}`}
                className={cn(
                  "custom-select_menu-list_item",
                  {
                    "custom-select_menu-list_item_active": selectedItem
                  }
                )}
              >
                <Checkbox
                  label={item.label}
                  checked={selectedItem}
                  onClick={value => handleChanges(value, item)}
                />
              </div>
            );
          }
          )}
        </div>
      </div>
    </components.MenuList>
  );
};

CustomMenuList.propTypes = {
  props: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired
};

CustomMenuList.defaultProps = {
  props: {}
};

export default CustomMenuList;
