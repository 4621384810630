import type { FC } from 'react';

import React from 'react';
import { Tabs } from '@compliance.ai/web-components';
import { CATEGORIES_TAB_QUERY_PARAM, CATEGORIES_TABS } from './Categories.constants';

import '../_content-management.scss';

const Categories: FC = () => {
  return (
    <Tabs
      className="content-management"
      tabsBarClassName="content-management__tabs-bar"
      tabs={CATEGORIES_TABS}
      isRoutable
      routeKey={CATEGORIES_TAB_QUERY_PARAM}
    />
  );
};

export default Categories;
