import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';

export const TEXT_LENGTH = 40;

export const TOOLTIP_LENGTH = 100;

export const ALLOWED_SELECTED_COMMAND_REQUESTS_COUNT = 5000;

export const TABLE_LOCAL_STORAGE_KEY = 'commandRequests';

export enum SORT_DIRECTIONS {
  ASC = 'asc',
  DESC = 'desc'
}

export const ROWS_PER_PAGE_OPTIONS: Required<
  ComponentProps<typeof uiLib.Table>
>['rowsPerPageOptions'] = [20, 50, 100, 200, 500, 1000, 2500, 5000];
