import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, FormControl, ControlLabel, FormGroup, Alert } from 'react-bootstrap';
import _ from 'lodash';
import { flagDocument, updateDataDocument } from 'shared/features/documents/documents.actions';
import {
  ERROR_RELATED_DOCS_REQUIRED,
  DATA_ISSUE_STATUSES,
  ISSUE_SEVERITY, ISSUE_TYPE
} from './DocumentModal.constants';

class DocumentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saveDisabled: false,
      issue_severity: 'review',
      issue_type: 'technical',
      related_documents: '',
      error: null,
      field: '',
      notes: ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.flagged_document.isUpdating) {
      // close the modal on success
      if (this.props.showModal && this.state.saveDisabled) {
        this.props.close();
      }
      this.setState({
        saveDisabled: false
      });
    }
  }

  handleFieldChange = (changedfieldname, event) => {
    const new_state = { error: null };
    new_state[changedfieldname] = event.target.value;
    this.setState(new_state);
  };

  formatRelatedDocuments = relatedDocumentsIds => {
    return relatedDocumentsIds.split(',').map(value => +(value.trim()));
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ saveDisabled: true });
    const { issue_severity, related_documents } = this.state;
    if (DATA_ISSUE_STATUSES.includes(issue_severity)) {
      if (!related_documents) {
        this.setState({ error: ERROR_RELATED_DOCS_REQUIRED });
        return;
      }
      this.props.updateDataDocument(this.props.document_id, {
        pipeline_status: issue_severity,
        related_documents: this.formatRelatedDocuments(related_documents)
      }).then(this.flagDocuments);
    } else {
      this.flagDocuments();
    }
    this.props.close();
  };

  flagDocuments = () => {
    this.props.flagDocument(this.props.document_id, _.cloneDeep(this.state)).then(() => {
      this.props.updateDocumentsResult();
    });
  };

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.close}>
        <Modal.Header>
          <Modal.Title>
            {'Document ID: ' + this.props.document_id}
          </Modal.Title>
        </Modal.Header>
        <form action="src/admin/containers/DocumentAdminTool" onSubmit={this.handleSubmit}>
          <Modal.Body>
            <FormGroup controlId="formControlsSelect">
              <ControlLabel>Issue Severity</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                defaultValue={this.state.issue_severity}
                onChange={e => this.handleFieldChange('issue_severity', e)}
              >
                {ISSUE_SEVERITY.map(issue => (<option value={issue.value}>{issue.name}</option>))}
              </FormControl>
            </FormGroup>
            <FormGroup controlId="formControlsSelect">
              <ControlLabel>Issue Type</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                defaultValue={this.state.issue_type}
                onChange={e => this.handleFieldChange('issue_type', e)}
              >
                {ISSUE_TYPE.map(issue => (<option value={issue.value}>{issue.name}</option>))}
              </FormControl>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Related Documents</ControlLabel>
              <FormControl type="text" onChange={e => this.handleFieldChange('related_documents', e)} />
            </FormGroup>
            {this.state.error && (
            <Alert bsStyle="danger" className="adminToolErrorMessage">
              {this.state.error}
            </Alert>
            )}
            <FormGroup>
              <ControlLabel>Field</ControlLabel>
              <FormControl type="text" onChange={e => this.handleFieldChange('field', e)} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Notes</ControlLabel>
              <FormControl
                componentClass="textarea"
                onChange={e => this.handleFieldChange('notes', e)}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.close}>Close</Button>
            <Button bsStyle="primary" type="submit" disabled={this.state.saveDisabled}>
              Flag
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    flagDocument: (id, data) => {
      return dispatch(flagDocument(id, data));
    },
    updateDataDocument: (id, data) => {
      return dispatch(updateDataDocument(id, data));
    }
  };
};

const mapStateToProps = state => {
  return {
    flagged_document: state.flagged_document,
    errors: state.errors
  };
};

const ReduxDocumentModal = connect(mapStateToProps, mapDispatchToProps)(DocumentModal);

export default ReduxDocumentModal;
