import React from 'react';
import PropTypes from "prop-types";
import {
  loadJurisdictionsCategoryOptions,
  loadJurisdictionsCountryOptions,
  loadJurisdictionsOptions
} from "./JurisdictionTool.helper";
import { SELECT_BOOL, TABLE_VARIABLES } from "./JurisdictionTool.constants";
import Select from "../../components/ReactSelect/Select";
import AsyncSelect from "../../components/ReactSelect/SelectAsync";

const JurisdictionToolFilter = ({
  jurisdictions,
  setSearchValues,
  search
}) => {
  return (
    <div className="jurisdiction_filter">
      <AsyncSelect
        label="Jurisdictions"
        value={search[TABLE_VARIABLES.name]}
        onChange={e => setSearchValues(TABLE_VARIABLES.name, e)}
        options={jurisdictions}
        loadOptions={value => loadJurisdictionsOptions(
          TABLE_VARIABLES.name, value, TABLE_VARIABLES.name
        )}
        isClearable
      />
      <AsyncSelect
        label="Short Name"
        value={search[TABLE_VARIABLES.short_name]}
        onChange={e => setSearchValues(TABLE_VARIABLES.short_name, e)}
        options={jurisdictions}
        loadOptions={value => loadJurisdictionsOptions(
          TABLE_VARIABLES.short_name, value, TABLE_VARIABLES.short_name
        )}
        isClearable
      />
      <AsyncSelect
        label="Country"
        value={search[TABLE_VARIABLES.country]}
        onChange={e => setSearchValues(TABLE_VARIABLES.country, e)}
        loadOptions={value => loadJurisdictionsCountryOptions(
          TABLE_VARIABLES.country, value, TABLE_VARIABLES.country
        )}
        isClearable
      />
      <AsyncSelect
        label="Category"
        value={search[TABLE_VARIABLES.category]}
        onChange={e => setSearchValues(TABLE_VARIABLES.category, e)}
        loadOptions={value => loadJurisdictionsCategoryOptions(
          TABLE_VARIABLES.category, value, TABLE_VARIABLES.category
        )}
        isClearable
      />
      <Select
        label="Active"
        value={search[TABLE_VARIABLES.active]}
        options={SELECT_BOOL}
        onChange={e => setSearchValues(TABLE_VARIABLES.active, e)}
        isClearable
      />
    </div>

  );
};
JurisdictionToolFilter.propTypes = {
  jurisdictions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  setSearchValues: PropTypes.func.isRequired,
  search: PropTypes.shape({})
};

JurisdictionToolFilter.defaultProps = {
  jurisdictions: [],
  search: {}
};

export default JurisdictionToolFilter;
