import type { NumberOfIssuesCellData } from './useNumberOfIssuesCellData.types';
import type { NumberOfIssuesCellProps } from '../NumberOfIssuesCell.types';

import * as spiderRunReportsConstants from 'shared/features/spiderRunReports/spiderRunReports.constants';
import * as logsModalConstants from '../../../LogsModal.constants';

import { useMemo, useState } from 'react';

export const useNumberOfIssuesCellData = ({
  props
}: {
  props: Pick<NumberOfIssuesCellProps, 'cellValue' | 'row'>;
}): NumberOfIssuesCellData => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const localState: NumberOfIssuesCellData['localState'] = {
    isModalOpen
  };

  const localActions: NumberOfIssuesCellData['localActions'] = useMemo(
    () => ({
      setIsModalOpen
    }),
    []
  );

  const formattedData: NumberOfIssuesCellData['formattedData'] = useMemo(() => {
    const logType = [
      spiderRunReportsConstants.LOG_TYPES.TIME_TRACKING,
      spiderRunReportsConstants.LOG_TYPES.CRAWLER_LOG,
      spiderRunReportsConstants.LOG_TYPES.CRAWLING_METRICS,
      spiderRunReportsConstants.LOG_TYPES.VIDEO_LOG,
      spiderRunReportsConstants.LOG_TYPES.TEXT_LOG
    ].find(type => (props.row[logsModalConstants.TABLE_KEY.ISSUE] as string).includes(type));

    const shouldShowExtraLogsButton =
      Boolean(logType) &&
      (String(props.cellValue).includes('https://') ||
        (String(props.cellValue).startsWith('http://') &&
          String(props.cellValue).includes('/local_file_access')));

    return {
      logType,
      shouldShowExtraLogsButton
    };
  }, [props.cellValue, props.row]);

  return {
    localState,
    localActions,
    formattedData
  };
};
