import type { ComponentProps } from 'react';
import type { useCommandRequestsFiltersAsyncLoaders } from './useCommandRequestsFiltersAsyncLoaders';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../CommandRequestsFilters.constants';
import * as commandRequestsConstants from 'admin/constants/commandRequests';

export const useCommandRequestsFiltersConfig = ({
  filterAsyncLoaders
}: {
  filterAsyncLoaders: ReturnType<typeof useCommandRequestsFiltersAsyncLoaders>;
}): ComponentProps<typeof uiLib.Filters>['config'] => [
  {
    key: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.TYPE,
    label: 'Type',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.TYPE]
  },
  {
    key: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS,
    label: 'Status',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS]
  },
  {
    key: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.SPIDER_NAMES,
    label: 'Spider Name',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad:
      filterAsyncLoaders[commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.SPIDER_NAMES]
  },
  {
    key: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER_ID,
    label: 'User',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER_ID]
  },
  {
    key: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.CREATED_AT,
    label: 'Created At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPDATED_AT,
    label: 'Updated At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  }
];
