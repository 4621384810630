import type { MomentInput } from 'moment';
import type * as scrapedCategoryDataAPi from 'shared/features/categories/scrapedCategories/scrapedCategories.apiV2';

import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';
import * as scrapedCategoryDataConstants from 'shared/features/categories/scrapedCategories/scrapedCategories.constants';

export const getLocalDateEnd = (date?: MomentInput): Date => {
  const startOfNextDay = uiLib.getDateAfterDate({
    date,
    amount: 1,
    unitOfTime: uiLib.DATE_UNITS.DAY
  });

  return uiLib.getDateBeforeDate({
    date: startOfNextDay,
    amount: 1,
    unitOfTime: uiLib.DATE_UNITS.MILLISECONDS
  }) as Date;
};

export const getToday = () =>
  uiLib.getStartOfDate(new Date(), uiLib.DATE_UNITS.DAY, {
    passedTimezone: uiLib.getLocalTimezone(),
    shouldKeepLocalTime: false
  });

export const formatStartDate = (date?: MomentInput) => {
  return date
    ? uiLib.formatDate(date, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME_WITH_T,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      })
    : null;
};

export const formatEndDate = (date?: MomentInput) => {
  if (date === null) {
    return null;
  }

  /**
   * getLocalDateEnd tries to solve issue with filtering documents by public_date,
   * if end date is before 12:00AM of the day document was published, api doesn't return it,
   * solution is to increase the end date up to 23:59:59 for all filtering requests
   */
  const endDate = getLocalDateEnd(date);

  return uiLib.getEndOfDate(endDate, uiLib.DATE_UNITS.DAY, {
    format: uiLib.DATE_FORMATS.API_DATE_TIME_WITH_T,
    passedTimezone: uiLib.getLocalTimezone(),
    timezone: uiLib.TIMEZONES.UTC
  });
};

export const formatDateWithModifiers =
  ({
    startDateQueryParamKey,
    endDateQueryParamKey
  }: {
    startDateQueryParamKey: string;
    endDateQueryParamKey: string;
  }) =>
  (filterValue: uiLib.FilterValue): Record<string, unknown> => {
    const value = filterValue as uiLib.DatePickerWithModifiersProps['value'];

    switch (value?.modifier) {
      case uiLib.DATE_MODIFIERS.BETWEEN: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA),
          [endDateQueryParamKey]: formatEndDate(value?.dateB)
        };
      }

      case uiLib.DATE_MODIFIERS.ON: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA),
          [endDateQueryParamKey]: formatEndDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.ON_OR_BEFORE: {
        return {
          [endDateQueryParamKey]: formatEndDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.ON_OR_AFTER: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.TODAY: {
        const today = getToday();
        return {
          [startDateQueryParamKey]: formatStartDate(today),
          [endDateQueryParamKey]: formatEndDate(today)
        };
      }

      case uiLib.DATE_MODIFIERS.YESTERDAY: {
        const today = getToday();
        const date = uiLib.getDateBeforeDate({
          date: today,
          amount: 1,
          unitOfTime: uiLib.DATE_UNITS.DAY
        });

        return {
          [startDateQueryParamKey]: formatStartDate(date instanceof Date ? date : null),
          [endDateQueryParamKey]: formatEndDate(date instanceof Date ? date : null)
        };
      }

      case uiLib.DATE_MODIFIERS.TOMORROW: {
        const today = getToday();
        const date = uiLib.getDateAfterDate({
          date: today,
          amount: 1,
          unitOfTime: uiLib.DATE_UNITS.DAY
        });

        return {
          [startDateQueryParamKey]: formatStartDate(date instanceof Date ? date : null),
          [endDateQueryParamKey]: formatEndDate(date instanceof Date ? date : null)
        };
      }

      default: {
        return {};
      }
    }
  };

export const formatText =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const value = filterValue as string;

    if (value) {
      return {
        [queryParamKey]: value
      };
    }

    return {};
  };

export const formatMultiSelectValues =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const values = filterValue as uiLib.SelectOption[];

    if (Array.isArray(values) && values.length) {
      return {
        [queryParamKey]: values.map(({ value }) => value)
      };
    }

    return {};
  };

export const formatSelectValues =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const option = filterValue as uiLib.SelectOption;
    if (option) {
      return {
        [queryParamKey]: option?.value
      };
    }

    return {};
  };

export const formatNumericWithModifiersValue =
  ({
    countQueryParamKey,
    countFromQueryParamKey,
    countToQueryParamKey
  }: {
    countQueryParamKey: string;
    countFromQueryParamKey: string;
    countToQueryParamKey: string;
  }) =>
  (filterValue: uiLib.FilterValue) => {
    const value = filterValue as uiLib.NumericFieldWithModifiersProps['value'];

    switch (value?.modifier) {
      case uiLib.NUMERIC_MODIFIERS.EQUAL: {
        return {
          [countQueryParamKey]: value.valueA
        };
      }

      case uiLib.NUMERIC_MODIFIERS.BETWEEN: {
        return {
          [countFromQueryParamKey]: value.valueA,
          [countToQueryParamKey]: value.valueB
        };
      }

      case uiLib.NUMERIC_MODIFIERS.GREATER_OR_EQUAL: {
        return {
          [countFromQueryParamKey]: value.valueA
        };
      }

      case uiLib.NUMERIC_MODIFIERS.LESS_OR_EQUAL: {
        return {
          [countToQueryParamKey]: value.valueA
        };
      }

      default: {
        return {};
      }
    }
  };

export const CATEGORY_FILTER_VALUES_PARSERS: Record<
  string,
  (
    value: uiLib.FilterValue
  ) => Parameters<typeof scrapedCategoryDataAPi.fetchScrapedCategoriesData>[0]
> = {
  [categoryConstants.CATEGORY_ATTRIBUTES.ID]: formatMultiSelectValues({
    queryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.SCRAPED_CATEGORY_IDS
  }),
  [categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION]: formatMultiSelectValues({
    queryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.JURISDICTION_IDS
  }),
  [categoryConstants.CATEGORY_ATTRIBUTES.AGENCY]: formatMultiSelectValues({
    queryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.AGENCY_IDS
  }),
  [categoryConstants.CATEGORY_ATTRIBUTES.DOCUMENT_CAI_CATEGORY]: formatMultiSelectValues({
    queryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.CAI_CATEGORY_IDS
  }),
  [categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT]: formatDateWithModifiers({
    startDateQueryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.CREATED_AT_START,
    endDateQueryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.CREATED_AT_END
  }),
  [categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT]: formatDateWithModifiers({
    startDateQueryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.UPDATED_AT_START,
    endDateQueryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.UPDATED_AT_END
  }),
  [categoryConstants.CATEGORY_ATTRIBUTES.SURFACE_DOCS]: formatSelectValues({
    queryParamKey:
      scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.SURFACE_DOCS
  })
};

export const formatCategoriesQueryParams = (
  values: uiLib.FiltersProps['values'],
  searchValue: string
): Record<string, unknown> => {
  return Object.entries(values).reduce(
    (formattedQueryParams, [filterKey, filterValue]) => {
      return {
        ...formattedQueryParams,
        ...CATEGORY_FILTER_VALUES_PARSERS[filterKey](filterValue)
      };
    },
    {
      [scrapedCategoryDataConstants.SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS.MULTI_INPUT]:
        searchValue
    } as Record<string, unknown>
  );
};
