import type { BulkEditModalRuleData } from './useBulkEditModalRuleData.types';
import type { BulkEditModalRuleProps } from '../BulkEditModalRule.types';

import * as helpers from './useBulkEditModalRuleData.helpers';
import * as constants from '../BulkEditModalRule.constants';

import { useMemo } from 'react';

export const useBulkEditModalRuleData = ({
  props
}: {
  props: Pick<BulkEditModalRuleProps, 'action' | 'attribute' | 'availableBulkEditActions'>;
}): BulkEditModalRuleData => {
  const formattedData: BulkEditModalRuleData['formattedData'] = useMemo(
    () => ({
      isAttributeSectionShown: Boolean(props.action),
      isValueSectionShown: Boolean(props.action) && Boolean(props.attribute),
      valueInputLabel:
        constants.BULK_EDIT_ATTRIBUTES_OPTIONS.find(
          attributeOption => props.attribute === attributeOption.value
        )?.label ?? null,
      bulkEditAttributesOptions: helpers.getBulkEditAttributesOptions(props)
    }),
    [props]
  );

  return {
    formattedData
  };
};
