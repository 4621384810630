import type { LogsLinkCellData } from './useLogsLinkCellData.types';
import type { SpiderRunReportFromResponse } from 'shared/features/crawlerSettings/spiderRunReports/spiderRunReports.api.types';
import type { LogsLinkCellProps } from '../LogsLinkCell.types';

import { useMemo, useState } from 'react';

import * as helpers from './useLogsLinkCellData.helpers';

export const useLogsLinkCellData = ({ row }: Pick<LogsLinkCellProps, 'row'>): LogsLinkCellData => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const localState: LogsLinkCellData['localState'] = {
    isModalOpen
  };

  const localActions: LogsLinkCellData['localActions'] = useMemo(
    () => ({
      setIsModalOpen
    }),
    []
  );

  const formattedData: LogsLinkCellData['formattedData'] = useMemo(() => {
    return {
      logsTotalCount: helpers.getLogsTotalCount(row as SpiderRunReportFromResponse)
    };
  }, [row]);

  return {
    localState,
    localActions,
    formattedData
  };
};
