import type { EitlLabelAddModalProps } from '../EitlLabelAddModal.types';
import type { EitlLabelAddModalData } from './useEitlLabelAddModalData.types';

import * as eitlLabelsApiTypes from 'shared/features/eitlLabels/eitlLabels.api.types';
import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { useMutation } from '@tanstack/react-query';
import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';

export const useEitlLabelAddModalMutations = ({
  props,
  localState,
  reduxState
}: {
  props: Pick<EitlLabelAddModalProps, 'onConfirm'>;
  localState: EitlLabelAddModalData['localState'];
  reduxState: EitlLabelAddModalData['reduxState'];
}) => {
  const eitlLabelsApi = useEitlLabelsApi();

  const addEitlLabel = useMutation<Awaited<eitlLabelsApiTypes.NormalizedEitlLabel>, Error, void>({
    mutationKey: queryKeysConstants.COMPLEX_QUERY_KEY_GETTERS[
      queryKeysConstants.QUERY_KEYS.EITL_LABELS
    ][queryKeysConstants.QUERY_SECTIONS.CONTENT_MANAGEMENT_EITL_LABELS_ADD]({
      name: localState.eitlLabelName
    }),
    mutationFn: () =>
      eitlLabelsApi.addEitlLabel({
        user_id: reduxState.currentUserId,
        name: localState.eitlLabelName
      }),
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      props.onConfirm();

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        message: 'EITL Label successfully added'
      });
    }
  });

  return {
    addEitlLabel
  };
};
