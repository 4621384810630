import type { CrawlMonitorToolData } from './useCrawlMonitorToolData.types';
import type { CrawlMonitorToolHandlers } from './useCrawlMonitorToolHandlers.types';
import type { DevComplexityValue } from './useCrawlMonitorToolHandlers.types';

import * as uiLib from '@compliance.ai/web-components';
import * as helpers from '../CrawlMonitorTool.helper';
import * as constants from '../CrawlMonitorTool.constants';
import * as errorUtils from '../../../../shared/utils/errors';
import * as crawlerDataApi from 'shared/features/externalApiCrawl/externalApiCrawl.api';

import * as crawlerTypesConstants from '../../../constants/crawlersTypes';
import * as crawlerSettingsConstants from '../../CrawlerPage/CrawlerSettings.constants';
import * as crawlerDetailsConstants from '../../CrawlerPage/CrawlerDetails/CrawlerDetails.constants';
import * as commandRequestTypesConstants from '../../../constants/commandRequestTypes';
import { useExternalApiCrawlReduxActions } from 'shared/features/externalApiCrawl/hooks';

export const useCrawlMonitorToolHandlers = ({
  localState,
  localActions,
  reduxState
}: {
  localState: CrawlMonitorToolData['localState'];
  localActions: CrawlMonitorToolData['localActions'];
  reduxState: CrawlMonitorToolData['reduxState'];
}): CrawlMonitorToolHandlers => {
  const externalApiCrawlActions = useExternalApiCrawlReduxActions();

  const handleCrawlersFetch: CrawlMonitorToolHandlers['handleCrawlersFetch'] = async params => {
    const isDesc = params?.sort?.startsWith('-');
    const name = isDesc ? params?.sort?.substring(1) : params?.sort;
    try {
      const crawlersResponse = await crawlerDataApi.api_getExternalApiCrawls({
        ...params,
        ...helpers.formatSearchValues(localState.search),
        [uiLib.TABLE_PARAMS.SORT]: name
          ? {
              [name]: { sorting_priority: 1, sorting_direction: isDesc ? 'desc' : 'asc' }
            }
          : null
      });
      // Required for run now crawl modal
      externalApiCrawlActions.clearExternalApiCrawls(crawlersResponse);
      return {
        results: crawlersResponse?.items,
        count: crawlersResponse?.count
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleSetSelectedCrawl: CrawlMonitorToolHandlers['handleSetSelectedCrawl'] = rowKeys => {
    localActions.setError('');
    localActions.setSelectedCrawls(rowKeys.map(Number));
  };

  const handleUncheckAllCrawls: CrawlMonitorToolHandlers['handleUncheckAllCrawls'] = () => {
    localActions.setError('');
    localActions.setSelectedCrawls([]);
  };

  const handleChangeDevComplexityValues: CrawlMonitorToolHandlers['handleChangeDevComplexityValues'] =
    (name, value) => {
      return localActions.setSearch(prevState => ({
        ...prevState,
        [name]: value,
        ...helpers.getDevComplexityUpdate(
          name,
          value,
          localState.search[helpers.getTypeName(constants.TABLE_VARIABLES.devComplexity)]
        )
      }));
    };

  const handleSetSearchValues: CrawlMonitorToolHandlers['handleSetSearchValues'] = (
    name,
    value
  ) => {
    if (name.includes(constants.TABLE_VARIABLES.devComplexity)) {
      handleChangeDevComplexityValues(name, value as DevComplexityValue);
    }
    localActions.setSearch(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClearSearch: CrawlMonitorToolHandlers['handleClearSearch'] = () =>
    localActions.setSearch({});

  const handleDeactivateSelectedCrawls: CrawlMonitorToolHandlers['handleDeactivateSelectedCrawls'] =
    () => {
      localState.selectedCrawl.forEach(crawlId => {
        externalApiCrawlActions.updateExternalApiCrawls({ is_active: false, id: crawlId });
      });
    };

  const handleMoveToIdleSelectedCrawls: CrawlMonitorToolHandlers['handleMoveToIdleSelectedCrawls'] =
    () => {
      const timedOutCrawlsSize = reduxState.crawls.filter(
        item =>
          localState.selectedCrawl.includes(item?.id) &&
          item.status === commandRequestTypesConstants.COMMAND_REQUEST_STATUS.TIMED_OUT
      ).length;
      if (timedOutCrawlsSize > 0) {
        localActions.setShowModal(true);
      } else {
        localActions.setError('no timed out crawls selected!!');
      }
    };

  const moveToIdleSelectedCrawls: CrawlMonitorToolHandlers['handleMoveToIdleSelectedCrawls'] =
    async () => {
      localActions.setIsActionDisabled(true);
      await externalApiCrawlActions.updateExternalApiCrawls({
        crawl_ids: localState.selectedCrawl,
        bulk_op: 'move_status',
        status: crawlerTypesConstants.crawlStates.idle
      });
      localActions.setShowModal(false);
      localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
      localActions.setDeselectAllCrawlsTrigger(
        deselectAllCrawlsTrigger => !deselectAllCrawlsTrigger
      );
      localActions.setIsActionDisabled(false);
    };

  const handleApplyCrawlFilters: CrawlMonitorToolHandlers['handleApplyCrawlFilters'] = () => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setSearchModal(false);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleToggleSearch: CrawlMonitorToolHandlers['handleToggleSearch'] = () => {
    if (localState.search[constants.TABLE_VARIABLES.multiField]) {
      handleApplyCrawlFilters();
    } else {
      localActions.setSearchModal(!localState.searchModal);
    }
  };

  const handleConfirmationModalAction: CrawlMonitorToolHandlers['handleConfirmationModalAction'] =
    () => {
      switch (localState.mode.title) {
        case crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.MOVE.title:
          moveToIdleSelectedCrawls();
          break;

        default:
          localActions.setIsActionDisabled(false);
          uiLib.showNotification(crawlerDetailsConstants.ERROR_MODAL_ACTION);
      }
    };

  const handleConfirmationModalCancel: CrawlMonitorToolHandlers['handleConfirmationModalCancel'] =
    () => {
      switch (localState.mode.title) {
        case crawlerSettingsConstants.MODE_CONFIRMATION_MODAL.MOVE.title:
          break;
        default:
          uiLib.showNotification(crawlerDetailsConstants.ERROR_MODAL_ACTION);
      }
      localActions.setShowModal(false);
    };

  return {
    handleSetSelectedCrawl,
    handleUncheckAllCrawls,
    handleChangeDevComplexityValues,
    handleSetSearchValues,
    handleClearSearch,
    handleDeactivateSelectedCrawls,
    handleMoveToIdleSelectedCrawls,
    handleApplyCrawlFilters,
    handleToggleSearch,
    handleCrawlersFetch,
    handleConfirmationModalAction,
    handleConfirmationModalCancel
  };
};
