import type { BulkEditModalData, BulkEditModalRule } from './useBulkEditModalData.types';

export const BULK_EDIT_MODAL_EMPTY_RULE: Omit<BulkEditModalRule, 'id'> = {
  action: null,
  attribute: null,
  value: null
};

export const BULK_EDIT_MODAL_INITIAL_RULES: BulkEditModalData['localState']['rules'] = [
  {
    id: 0,
    ...BULK_EDIT_MODAL_EMPTY_RULE
  }
];
