import React from 'react';
import PropTypes from "prop-types";
import Typography from '../../../components/Typography';
import TextInput from '../../../components/TextInput';
import Pagination from '../../../components/Pagination';

const GlassdoorFooter = ({
  onChangePage,
  pageQuantity,
  onChangeLimit,
  limit,
  externalActivePage
}) => (
  <div className='glassdoor_footer'>
    <Pagination
      clasName="pagination"
      pageQuantity={pageQuantity}
      onChange={onChangePage}
      externalActivePage={externalActivePage}
    />
    <div className="limit-wrapper">
      <Typography variant="p">Show</Typography>
      <TextInput
        className='limit-input'
        type="number"
        onChange={(e) => onChangeLimit(e.target.value)}
        value={limit}
      />
      <Typography variant="p">{"Jurisdictions"}</Typography>
    </div>
  </div>
);

GlassdoorFooter.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pageQuantity: PropTypes.number,
  externalActivePage: PropTypes.number
};

GlassdoorFooter.defaultProps = {
  pageQuantity: 1,
  externalActivePage: 1
};

export default GlassdoorFooter;
