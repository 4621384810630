import { fetch, BASE_API_OPTIONS } from "fetch";

export function api_getConcepts(params) {
  return fetch({
    url: `/concepts`,
    dataType: 'Concept',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postConcepts(params) {
  return fetch({
    url: `/concepts`,
    dataType: 'Concept',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putConcepts(params) {
  return fetch({
    url: `/concepts`,
    dataType: 'Concept',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteConcepts(params) {
  return fetch({
    url: `/concepts`,
    dataType: 'Concept',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
