import {
  api_fetchAllProvenances
} from './provenances.api';

export const REQUEST_PROVENANCES = 'REQUEST_PROVENANCES';
export const RECEIVE_PROVENANCES = 'RECEIVE_PROVENANCES';

function requestAllProvenances() {
  return {
    type: REQUEST_PROVENANCES
  };
}

function receiveAllProvenances(json) {
  return {
    type: RECEIVE_PROVENANCES,
    provenances: json
  };
}

export function fetchAllProvenances() {
  return function dofetch(dispatch) {
    dispatch(requestAllProvenances());

    return api_fetchAllProvenances()
      .then(response => {
        dispatch(receiveAllProvenances(response));
      });
  };
}
