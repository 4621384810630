import _ from "lodash";
import { ERRORS } from "../containers/CrawlerPage/DocumentsIngested/DocumentsIngested.constants";
import { COMMAND_REQUEST_PARAMETERS } from "../constants/runNowParametersNames";

export function createCommandRequest({
  name,
  parameters,
  commands,
  current_user,
  commandRequestName
}) {
  const commandType = commands
    .find(command => _.get(command, 'name') === commandRequestName);

  // error check block
  let error = null;
  if (!commandType) error = ERRORS.commandMissed;
  else if (!current_user.id) error = ERRORS.userIdMissed;
  if (error) return { error };

  const commandRequestParameters = {
    name,
    type: commandType.id,
    user_id: current_user.id,
    parameters
  };

  const commandRequest = new window.FormData();
  commandRequest.append(
    COMMAND_REQUEST_PARAMETERS, JSON.stringify(commandRequestParameters)
  );

  return commandRequest;
}
