import type { DocsLinkCellData } from './useDocsLinkCellData.types';

import { useMemo, useState } from 'react';

export const useDocsLinkCellData = (): DocsLinkCellData => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const localState: DocsLinkCellData['localState'] = {
    isModalOpen
  };

  const localActions: DocsLinkCellData['localActions'] = useMemo(
    () => ({
      setIsModalOpen
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
