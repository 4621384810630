import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NAMES_CONTEXT, ERRORS, INFO_HELP } from '../CrawlSpecCreate.constants';
import Checkbox from "../../../components/Checkbox_NEW";
import ExpandableBtnList from '../HelpComponents/ExpandableBtnList';
import SpecElementsWrapper from "./SpecElementsWrapper";
import TextInput from "../../../components/TextInput";

const Context = ({
  disabled,
  show,
  currentPathUUID,
  element,
  changeContext,
  changeCurrentPath,
  addNewPath,
  clearPathElements,
  errorFieldsPaths,
  errorFields
}) => {
  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changeContext(name, value);
  };

  return (
    <SpecElementsWrapper
      show={show}
      label="Context"
      tooltip={INFO_HELP.CONTEXT}
    >
      <TextInput
        label="Code Filter"
        name={NAMES_CONTEXT.CODE_FILTER}
        value={element[NAMES_CONTEXT.CODE_FILTER]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_CONTEXT.CODE_FILTER)}
      />
      <TextInput
        label="Tag Filter"
        name={NAMES_CONTEXT.TAG_FILTER}
        value={element[NAMES_CONTEXT.TAG_FILTER]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_CONTEXT.TAG_FILTER)}
      />
      <Checkbox
        label="Is URL"
        onClick={checked => changeContext(NAMES_CONTEXT.URL, checked)}
        checked={element[NAMES_CONTEXT.URL]}
        disabled={disabled}
      />
      <Checkbox
        label="Is Text"
        onClick={checked => changeContext(NAMES_CONTEXT.IS_TEXT, checked)}
        checked={element[NAMES_CONTEXT.IS_TEXT]}
        disabled={disabled}
      />
      <Checkbox
        label="Is Single"
        onClick={checked => changeContext(NAMES_CONTEXT.IS_SINGLE, checked)}
        checked={element[NAMES_CONTEXT.IS_SINGLE]}
        disabled={disabled}
      />
      <Checkbox
        label="Is Sibling Tag"
        onClick={checked => changeContext(NAMES_CONTEXT.IS_SIBLING_TAG, checked)}
        checked={element[NAMES_CONTEXT.IS_SIBLING_TAG]}
        disabled={disabled}
      />
      <Checkbox
        label="Combine Context"
        onClick={checked => changeContext(NAMES_CONTEXT.COMBINE_CONTEXT, checked)}
        checked={element[NAMES_CONTEXT.COMBINE_CONTEXT]}
        disabled={disabled}
      />
      <Checkbox
        label="Is Page Source"
        onClick={checked => changeContext(NAMES_CONTEXT.IS_PAGE_SOURCE, checked)}
        checked={element[NAMES_CONTEXT.IS_PAGE_SOURCE]}
        disabled={disabled}
      />
      <ExpandableBtnList
        label="Paths"
        isDisabled={disabled}
        value={element[NAMES_CONTEXT.PATH]}
        name={NAMES_CONTEXT.PATH}
        addMoreName="Path"
        activeID={currentPathUUID}
        onChange={changeContext}
        onClickBtn={changeCurrentPath}
        createNewItemTemplate={addNewPath}
        helpRemoveFunc={clearPathElements}
        errorFields={Object.keys(errorFieldsPaths)}
        isError={
          errorFields.includes(NAMES_CONTEXT.PATH)
          && !_.get(element, [NAMES_CONTEXT.PATH, 'length'], '')
        }
        errorText={ERRORS.contextDataPath}
      />
    </SpecElementsWrapper>
  );
};

Context.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentPathUUID: PropTypes.string,
  element: PropTypes.shape({}),
  changeContext: PropTypes.func.isRequired,
  changeCurrentPath: PropTypes.func.isRequired,
  addNewPath: PropTypes.func.isRequired,
  clearPathElements: PropTypes.func.isRequired,
  errorFieldsPaths: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

Context.defaultProps = {
  disabled: false,
  currentPathUUID: '',
  show: false,
  element: {},
  errorFieldsPaths: {},
  errorFields: []
};

export default Context;
