import { createSelector } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './crawlerComments.constants';
import { GlobalState } from '../../../reducers';
import { CommentFromResponse } from './crawlerComments.api.types';

export const getComments = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state?.[REDUCER_NAME]
);

export const getCommentsList = createSelector(
  getComments,
  (currentCommentsReducer): CommentFromResponse[] => {
    return currentCommentsReducer?.comments;
  }
);
