import React from "react";
import PropTypes from 'prop-types';
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useHistory } from 'admin/utils/hooks';
import queryString from 'query-string';
import MIconButton from "admin/components/MaterialComponents/IconButton/mIconButton";
import MButton from "admin/components/MaterialComponents/Buttons/Button/mButton";
import { changeGeneralTableValues, prepareDataForUpdatingUser } from "shared/features/userModal/userModal.reducer";
import { colors } from "admin/components/MaterialComponents/colors/colors";
import { updateUserByIdAC } from "shared/features/userModal/userModal.actions";
import ROUTES from '../../../../constants/routes';

const CANCEL = 'Cancel';
const EDIT = 'Edit';
const SAVE_CHANGES = 'Save Changes';

const useStyles = makeStyles({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.GREY_LIGHT}`,
    padding: 15
  },
  saveButton: {
    color: colors.GREEN_BUTTONS,
    marginLeft: 10,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: colors.BACKGROUND_GREEN,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.BACKGROUND_GREEN_HOVER
    }
  },
  defaultBlueButton: {
    color: colors.BLUE_MAIN,
    marginLeft: 10,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: colors.BLUE_TRANSPARENT,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.BACKGROUND_BLUE
    }
  },
  userName: {
    fontWeight: 'bold',
    fontSize: 19
  }
});


const UserModalHeader = ({
  editMode,
  initialUser,
  reduxChangeGeneralTableValues,
  reduxUpdateUserByIdAC,
  setEditMode
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);

  const onCancelClick = () => {
    reduxChangeGeneralTableValues(initialUser);
    setEditMode(false);
  };

  const onSaveChangesClick = () => {
    reduxUpdateUserByIdAC(parsedQuery.user_id);
    setEditMode(false);
  };

  const buttonGroup = [
    {
      label: CANCEL,
      edit: true,
      onClick: () => onCancelClick(),
      className: classes.defaultBlueButton
    },
    {
      label: SAVE_CHANGES,
      edit: true,
      onClick: () => onSaveChangesClick(),
      className: classes.saveButton
    },
    {
      label: EDIT,
      edit: false,
      onClick: () => setEditMode(true),
      className: classes.defaultBlueButton
    }
  ];

  const mountEditButtonGroup = () => {
    return buttonGroup.map(button => {
      if (editMode === button.edit) {
        return (
          <MButton
            onClick={() => button.onClick()}
            classes={button.className}
            key={button.label}
          >
            {button.label}
          </MButton>
        );
      }
      return null;
    });
  };

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.userName}>
        {`${initialUser.first_name} ${initialUser.last_name}`}
        {mountEditButtonGroup()}
      </div>
      <div>
        <MIconButton
          onClick={() => { history.push(ROUTES.users); }}
          classes={classes.crawlerSettingClose}
        >
          <CloseIcon fontSize='large' />
        </MIconButton>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  initialUser: state.userModal.initialUserData
});


UserModalHeader.propTypes = {
  editMode: PropTypes.bool.isRequired,
  initialUser: PropTypes.objectOf(PropTypes.any).isRequired,
  reduxChangeGeneralTableValues: PropTypes.func.isRequired,
  reduxUpdateUserByIdAC: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  reduxChangeGeneralTableValues: changeGeneralTableValues,
  reduxUpdateUserByIdAC: updateUserByIdAC,
  reduxPrepareDataForUpdatingUser: prepareDataForUpdatingUser
})(UserModalHeader);
