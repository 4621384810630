import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import Select from '../../../../../components/ReactSelect/Select';
import { documentFlagIssueTypes, documentFlagOptions } from './DocumentFlagModal.constants';
import TextInput from '../../../../../components/TextInput';
import Textarea from '../../../../../components/Textarea';
import './DocumentFlagModal.scss';
import { DOC_ACTIONS, DOCUMENT_DETAILS, DOCUMENT_GENERAL } from '../../DocumentDetails.constants';
import {
  fromDetailsToRawInfo,
  fromRawInfoToDetails,
  pickDuplicateActionParams,
  pickFlaggingParams
} from '../DocumentDetailsTabs/DocumentDetails.helper';
import { getDocuments } from 'shared/features/documentData/documentData.actions';
import {
  api_updateDataDocument,
  api_updateDocument
} from 'shared/features/documents/documents.api';

import * as uiLib from '@compliance.ai/web-components';
import * as errorUtils from 'shared/utils/errors';

const DocumentFlagModal = ({
  show,
  onSubmit,
  onHide,
  updateDoc,
  id,
  action,
  docTimestamp,
  docDetailsInfo,
  uncheckAllDocuments,
  user
}) => {
  const dispatch = useDispatch();

  const [docDetails, setDocDetails] = useState(DOCUMENT_DETAILS);
  const [issueType, setIssueType] = useState(documentFlagIssueTypes.TECHNICAL);
  const [field, setField] = useState('');
  const [notes, setNotes] = useState('');
  const [relatedDocuments, setRelatedDocuments] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const calculateDocDetails = () => {
    const formatDocDetails = fromRawInfoToDetails(docDetailsInfo);
    setDocDetails(formatDocDetails);
  };

  // TODO Fix deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(calculateDocDetails, [docTimestamp]);

  const issueTypeOptions = Object.values(documentFlagIssueTypes);

  const isActionOfDuplicateType = [
    DOC_ACTIONS.SET_DUPLICATE.type,
    DOC_ACTIONS.SET_POTENTIAL_DUPLICATE.type
  ].includes(action);

  function handleClose() {
    setField('');
    setNotes('');
    setRelatedDocuments('');
    setError(false);
    setIssueType(documentFlagIssueTypes.TECHNICAL);
    onHide();
  }
  const sendUpdateRequest = async (documentId, params, details) => {
    try {
      const request = () =>
        isActionOfDuplicateType
          ? api_updateDataDocument(documentId, pickDuplicateActionParams(params))
          : api_updateDocument(documentId, pickFlaggingParams(params));

      await request();

      if (!isActionOfDuplicateType) {
        // this regresses often the updateDoc does not take an array just an object
        // after a few regression , we fixed it on the api side, nevertheless, one more time
        await updateDoc({ ...details, [DOCUMENT_GENERAL.pipelineStatus]: action, id: documentId });
        await dispatch(getDocuments({ id: documentId, extend_info: true }));
      } else {
        await dispatch(getDocuments({ id: documentId, extend_info: true }));
      }
    } catch (e) {
      errorUtils.logError(e);
    }
  };

  const handleSubmit = async () => {
    try {
      if (isActionOfDuplicateType && !relatedDocuments.trim()) {
        setError(true);
        return;
      }

      setIsLoading(true);

      const params = {
        field,
        notes,
        issueType,
        issueSeverity: documentFlagOptions[action],
        relatedDocuments: relatedDocuments.trim(),
        action
      };
      let { details } = fromDetailsToRawInfo(docDetails, docDetailsInfo);
      const editorUser = `${_.get(user, 'first_name')} ${_.get(user, 'last_name')}`;
      // TODO check if details will be null
      if (Array.isArray(id)) {
        details = { user: editorUser };
        await Promise.all(
          id.map(async item => {
            await sendUpdateRequest(item, params, details);
          })
        );
        if (uncheckAllDocuments) uncheckAllDocuments();
      } else {
        details.user = editorUser;
        await sendUpdateRequest(id, params, details);
      }
    } catch (e) {
      errorUtils.logError(e);
    } finally {
      setIsLoading(false);
      onSubmit();
      handleClose();
    }
  };

  return (
    <uiLib.Modal
      className="documentDetails-Modal"
      title={`Take action on Document ID: ${id}`}
      isOpen={show}
      onClose={handleClose}
      withFooter
      secondaryButtonText="Cancel"
      primaryButtonText="Submit"
      isPrimaryButtonDisabled={isLoading}
      onSubmit={handleSubmit}
      classNames={{
        title: 'documentDetails-Modal__title',
        content: 'documentDetails-Modal__content'
      }}
    >
      {isLoading && <uiLib.Loader withoutBackground={false} />}
      <div className="bodyWrapper">
        <Select
          label="Issue Severity"
          options={[documentFlagOptions[action]]}
          value={documentFlagOptions[action]}
          isDisabled
          onChange={() => {}}
        />
        {!isActionOfDuplicateType && (
          <Select
            label="Issue Type"
            options={issueTypeOptions}
            value={issueType}
            onChange={setIssueType}
            isDisabled={isLoading}
          />
        )}
        <TextInput
          label={'Related documents'}
          name={'Related documents'}
          onChange={e => {
            if (!!e.target.value.trim()) {
              setError(false);
            }
            setRelatedDocuments(e.target.value);
          }}
          value={relatedDocuments}
          type={'text'}
          placeholder={'Enter related documents'}
          isError={error}
          errorText={`Field "Related documents" is required`}
          isDisabled={isLoading}
        />
        {!isActionOfDuplicateType && (
          <>
            <TextInput
              label={'Field'}
              name={'Field'}
              onChange={e => setField(e.target.value)}
              value={field}
              type={'text'}
              placeholder={'Enter field'}
              isDisabled={isLoading}
            />
            <Textarea
              label={'Notes'}
              name={'Notes'}
              onChange={e => setNotes(e.target.value)}
              value={notes}
              type={'text'}
              placeholder={'Enter notes'}
              isDisabled={isLoading}
            />
          </>
        )}
      </div>
    </uiLib.Modal>
  );
};

DocumentFlagModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
  docDetailsInfo: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  updateDoc: PropTypes.func.isRequired,
  uncheckAllDocuments: PropTypes.func,
  docTimestamp: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]).isRequired,
  action: PropTypes.string
};

DocumentFlagModal.defaultProps = {
  show: false,
  onSubmit: () => {},
  onHide: () => {},
  uncheckAllDocuments: () => {},
  docTimestamp: '',
  action: null
};

export default DocumentFlagModal;
