import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import uuidv4 from 'uuid/v4';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from "prop-types";
import SelectRender from '../../components/SelectRender';
import PopoverInfo from '../../components/CrawlCreationPopoverInfo';
import CrawlCreationExamplesRender from './CrawlCreationExamplesRender';
import {
  stepFieldTemplate,
  contextCheckBox,
  nextStepFieldTemplate,
  documentTemplate,
  docMetaData,
  metaDataName
} from './crawlCreation.constants';
import { popoverName } from "../../constants/popoverConst";

const exampleContainerTypes = {
  context: 'contextExamples',
  doc: 'doc',
  examples: 'examples'
};

const CrawlerCreationStep = ({
  error,
  disabled,
  // Steps
  step,
  deleteStep,
  nextSteps,
  updateStep,
  // Examples
  examples,
  addExample,
  deleteExample,
  updateExample
}) => {
  const [collapsed, setCollapse] = useState(false);

  const nextStepField = {
    ...nextStepFieldTemplate,
    categories: nextSteps.map(stepName => ({ value: stepName, label: stepName }))
  };

  const updateCurrentStep = (e) => {
    updateStep(step.id, { ...step, [e.target.name]: e.target.value });
  };

  const stepUpdateModel = (name, value) => {
    updateCurrentStep({ target: { name, value } });
  };

  // Meta Data Functions
  const deleteMetaData = (id) => {
    const newMetaData = step[exampleContainerTypes.doc]
      .filter(metaData => metaData.id !== id);
    stepUpdateModel(exampleContainerTypes.doc, newMetaData);
  };

  const updateMetaData = (id, e) => {
    const newMetaData = step[exampleContainerTypes.doc]
      .map(metaData => (
        metaData.id === id
          ? { ...metaData, [e.target.name]: e.target.value }
          : metaData
      ));
    stepUpdateModel(exampleContainerTypes.doc, newMetaData);
  };

  // Element creation function
  const stepUpdateElements = (container, metaData = {}) => {
    const newExampleId = uuidv4();
    let createdValue = [];
    if (container === exampleContainerTypes.context) {
      // Create new example for context
      addExample(newExampleId);
      createdValue = newExampleId;
    } else if (container === exampleContainerTypes.doc) {
      // Create new meta data
      createdValue = { id: newExampleId, ...documentTemplate };
    } else if (container === exampleContainerTypes.examples) {
      // Create new example for meta data in doc
      addExample(newExampleId);
      updateMetaData(metaData.id, {
        target: {
          name: container,
          value: [...metaData[container], newExampleId]
        }
      });
      return;
    }
    stepUpdateModel(container, [...step[container], createdValue]);
  };

  return (
    <div className="stepsWrapper">
      {
        !step.isNotEditable
        && !disabled
        && <div className="smallRoundBtn deleteBtn" onClick={() => deleteStep(step.id)}>x</div>
      }
      <div className="smallRoundBtn shrinkBtn" onClick={() => setCollapse(!collapsed)}>{collapsed ? '+' : '-'}</div>
      {collapsed ? (
        <div className="adminToolLeftPadding">
          <h5 className="objectName">{step.name}</h5>
        </div>
      ) : (
        <div className="adminToolLeftPadding">
          {stepFieldTemplate.map(field => (
            <SelectRender
              key={field.name}
              item={{ ...field, disabled: step.isNotEditable || disabled }}
              state={step}
              handleChange={updateCurrentStep}
              style={["filterAdminToolWrap_wideElem"]}
              error={error}
            />
          ))
          }
          {!step.isNotEditable && (
            <>
              <h4>
                Context
                <PopoverInfo
                  popoverConst={popoverName.contextInfo}
                />
              </h4>
              <SelectRender
                item={{ ...contextCheckBox, disabled }}
                state={step}
                handleChange={updateCurrentStep}
                style={["filterAdminToolWrap_wideElem"]}
                error={error}
                popover={popoverName.isUrlInfo}
              />
              <CrawlCreationExamplesRender
                examples={examples}
                deleteExample={deleteExample}
                updateExample={updateExample}
                stepUpdateElements={stepUpdateElements}
                includedExamples={step[exampleContainerTypes.context]}
                exampleType={exampleContainerTypes.context}
                error={error}
                disabled={disabled}
              />
              <h4>
                Doc
                <PopoverInfo
                  popoverConst={popoverName.docInfo}
                />
              </h4>
              {step[exampleContainerTypes.doc].map(metaData => (
                <div key={metaData.id}>
                  {
                    !disabled
                    && <div className="smallRoundBtn deleteBtn" onClick={() => deleteMetaData(metaData.id)}>x</div>
                  }
                  <div className="adminToolLeftPadding">
                    <SelectRender
                      item={{
                        ...docMetaData,
                        disabled,
                        categories: metaDataName.map(meta => ({ label: meta, value: meta }))
                      }}
                      state={metaData}
                      handleChange={(e) => updateMetaData(metaData.id, e)}
                      style={["filterAdminToolWrap_wideElem filterAdminToolWrap_bottomGup"]}
                      error={error}
                    />
                    <CrawlCreationExamplesRender
                      examples={examples}
                      metaData={metaData}
                      deleteExample={deleteExample}
                      updateExample={updateExample}
                      stepUpdateElements={stepUpdateElements}
                      includedExamples={metaData[exampleContainerTypes.examples]}
                      exampleType={exampleContainerTypes.examples}
                      error={error}
                      disabled={disabled}
                    />
                  </div>
                </div>
              ))}
              <Button
                bsStyle="primary"
                className="wideButton"
                disabled={disabled}
                onClick={() => stepUpdateElements(exampleContainerTypes.doc)}
              >
                <CheckBoxOutlineBlankIcon className="buttonIcon" />
                + Add New Meta Data
              </Button>
            </>
          )}
          <SelectRender
            item={{ ...nextStepField, disabled }}
            state={step}
            handleChange={updateCurrentStep}
            style={["filterAdminToolWrap_wideElem"]}
            error={error}
          />
        </div>
      )}
    </div>
  );
};

CrawlerCreationStep.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  deleteStep: PropTypes.func.isRequired,
  nextSteps: PropTypes.arrayOf(PropTypes.string),
  updateStep: PropTypes.func.isRequired,
  examples: PropTypes.arrayOf(PropTypes.object),
  addExample: PropTypes.func.isRequired,
  deleteExample: PropTypes.func.isRequired,
  updateExample: PropTypes.func.isRequired
};

CrawlerCreationStep.defaultProps = {
  error: false,
  disabled: false,
  nextSteps: [],
  examples: []
};

export default CrawlerCreationStep;
