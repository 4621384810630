import type { FC } from 'react';
import type { EitlLabelAddModalProps } from './EitlLabelAddModal.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import {
  useEitlLabelAddModalData,
  useEitlLabelAddModalMutations,
  useEitlLabelAddModalFormattedData
} from './hooks';

import './_eitl-label-add-modal.scss';

export const EitlLabelAddModal: FC<EitlLabelAddModalProps> = ({ onConfirm, onClose }) => {
  const { localState, localActions, reduxState } = useEitlLabelAddModalData();

  const mutations = useEitlLabelAddModalMutations({
    props: {
      onConfirm
    },
    localState,
    reduxState
  });

  const formattedData = useEitlLabelAddModalFormattedData({
    localState,
    mutations
  });

  return (
    <uiLib.Modal
      isOpen
      title="Add EITL Label"
      withFooter
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Add"
      onSubmit={mutations.addEitlLabel.mutate}
      onClose={onClose}
      classNames={{
        container: 'eitl-label-add-modal__container',
        content: 'eitl-label-add-modal__content'
      }}
    >
      <div className="eitl-label-add-modal__content-container">
        {mutations.addEitlLabel.isLoading && <uiLib.Loader withoutBackground={false} />}
        <uiLib.LabeledField label="EITL Label name">
          <uiLib.TextField
            value={localState.eitlLabelName}
            onChange={localActions.setEitlLabelName}
            placeholder="New EITL Label name"
            required
          />
        </uiLib.LabeledField>
      </div>
    </uiLib.Modal>
  );
};
