import {
  api_fetchContributorStatistics,
  api_fetchAnnotationTasksByUser,
  api_editAggregatedAnnotation,
  api_requestAggregatedAnnotations,
  api_editAggregatedAnnotationResearch,
  api_fetchActiveTopicAnnotationModelIds,
  api_editAggregatedAnnotationGold,
  api_fetchAllAnnotationTasks,
  api_createAnnotationTaskFromParams,
  api_updateAnnotationTaskWithParams,
  api_fetchAnnotationJob,
  api_deleteAnnotationTaskWithParams,
  api_createAnnotationsForJob,
  api_fetchAnnotationStatistics,
  api_fetchAllAnnotationJobs,
  api_fetchAnnotationJobById,
  api_fetchAllSkippedAnnotations,
  api_fetchAnnotationTaskTopicGroups,
  api_postAnnotationTaskTopicGroup,
  api_deleteAnnotationTaskTopicGroup,
  api_fetchOnboardingResults
} from './annotations.api';

export const REQUEST_ANNOTATION_JOBS = 'REQUEST_ANNOTATION_JOBS';
export const RECEIVE_ANNOTATION_JOBS = 'RECEIVE_ANNOTATION_JOBS';
export const CREATE_ANNOTATIONS_FOR_JOB = 'CREATE_ANNOTATIONS_FOR_JOB';
export const ANNOTATIONS_FOR_JOB_CREATED = 'ANNOTATIONS_FOR_JOB_CREATED';
export const REQUEST_ANNOTATION_STATISTICS = 'REQUEST_ANNOTATION_STATISTICS';
export const RECEIVE_ANNOTATION_STATISTICS = 'RECEIVE_ANNOTATION_STATISTICS';
export const REQUEST_ALL_ANNOTATION_JOBS = 'REQUEST_ALL_ANNOTATION_JOBS';
export const RECEIVE_ALL_ANNOTATION_JOBS = 'RECEIVE_ALL_ANNOTATION_JOBS';
export const REQUEST_ANNOTATION_JOB_BY_ID = 'REQUEST_ANNOTATION_JOB_BY_ID';
export const RECEIVE_ANNOTATION_JOB_BY_ID = 'RECEIVE_ANNOTATION_JOB_BY_ID';
export const REQUEST_All_SKIPPED_ANNOTATIONS = 'REQUEST_All_SKIPPED_ANNOTATIONS';
export const RECEIVE_All_SKIPPED_ANNOTATIONS = 'RECEIVE_All_SKIPPED_ANNOTATIONS';
export const REQUEST_ANNOTATION_TASK_TOPIC_GROUPS = 'REQUEST_ANNOTATION_TASK_TOPIC_GROUPS';
export const RECEIVE_ANNOTATION_TASK_TOPIC_GROUPS = 'RECEIVE_ANNOTATION_TASK_TOPIC_GROUPS';
export const EDIT_ANNOTATION_TOPIC_GROUP = 'EDIT_ANNOTATION_TOPIC_GROUP';
export const ANNOTATION_TOPIC_GROUP_EDITED = 'ANNOTATION_TOPIC_GROUP_EDITED';
export const REQUEST_AGGREGATED_ANNOTATIONS = 'REQUEST_AGGREGATED_ANNOTATIONS';
export const RECEIVE_AGGREGATED_ANNOTATIONS = 'RECEIVE_AGGREGATED_ANNOTATIONS';
export const EDIT_AGGREGATED_ANNOTATION = 'EDIT_AGGREGATED_ANNOTATION';
export const AGGREGATED_ANNOTATION_EDITED = 'AGGREGATED_ANNOTATION_EDITED';
export const FETCH_ANNOTATION_TASKS_BY_USER = 'FETCH_ANNOTATION_TASKS_BY_USER';
export const REQUEST_ALL_ANNOTATION_TASKS = 'REQUEST_ALL_ANNOTATION_TASKS';
export const RECEIVE_ALL_ANNOTATION_TASKS = 'RECEIVE_ALL_ANNOTATION_TASKS';
export const CREATE_ANNOTATION_TASK = 'CREATE_ANNOTATION_TASK';
export const ANNOTATION_TASK_CREATED = 'ANNOTATION_TASK_CREATED';
export const UPDATE_ANNOTATION_TASK = 'UPDATE_ANNOTATION_TASK';
export const ANNOTATION_TASK_UPDATED = 'ANNOTATION_TASK_UPDATED';
export const DELETE_ANNOTATION_TASK = 'DELETE_ANNOTATION_TASK';
export const ANNOTATION_TASK_DELETED = 'ANNOTATION_TASK_DELETED';
export const REQUEST_CONTRIBUTOR_STATISTICS = 'REQUEST_CONTRIBUTOR_STATISTICS';
export const RECEIVE_CONTRIBUTOR_STATISTICS = 'RECEIVE_CONTRIBUTOR_STATISTICS';
export const FETCH_ONBOARDING_RESULTS = 'FETCH_ONBOARDING_RESULTS';

function requestContributorStatistics(task_id) {
  return {
    type: REQUEST_CONTRIBUTOR_STATISTICS
  };
}

function receiveContributorStatistics(json) {
  return {
    type: RECEIVE_CONTRIBUTOR_STATISTICS,
    contributor_statistics: json
  };
}

function requestAllAnnotationTasks() {
  return {
    type: REQUEST_ALL_ANNOTATION_TASKS
  };
}

function receiveAllAnnotationTasks(json) {
  return {
    type: RECEIVE_ALL_ANNOTATION_TASKS,
    annotation_tasks: json.annotation_tasks
  };
}

function createAnnotationTask(params) {
  return {
    type: CREATE_ANNOTATION_TASK,
    params
  };
}

function annotationTaskCreated(json) {
  return {
    type: ANNOTATION_TASK_CREATED,
    annotation_task: json
  };
}

function updateAnnotationTask() {
  return {
    type: UPDATE_ANNOTATION_TASK
  };
}

function annotationTaskUpdated(json) {
  return {
    type: ANNOTATION_TASK_UPDATED,
    annotation_task: json ? json.annotation_task : null
  };
}

function deleteAnnotationTask() {
  return {
    type: DELETE_ANNOTATION_TASK
  };
}

function annotationTaskDeleted(json) {
  return {
    type: ANNOTATION_TASK_DELETED,
    annotation_task: json
  };
}

function requestAnnotationJobs() {
  return {
    type: REQUEST_ANNOTATION_JOBS
  };
}

function receiveAnnotationJobs(json) {
  return {
    type: RECEIVE_ANNOTATION_JOBS,
    annotation_jobs: json
  };
}

function createAnnotations(task_id, job_id, params) {
  return {
    type: CREATE_ANNOTATIONS_FOR_JOB,
    task_id,
    job_id,
    params
  };
}

function annotationsCreated(json) {
  return {
    type: ANNOTATIONS_FOR_JOB_CREATED,
    annotations_for_job: json
  };
}

function requestAnnotationStatistics(task_id) {
  return {
    type: REQUEST_ANNOTATION_STATISTICS
  };
}

function receiveAnnotationStatistics(json) {
  return {
    type: RECEIVE_ANNOTATION_STATISTICS,
    statistics: json
  };
}

function requestAllAnnotationJobs(task_id) {
  return {
    type: REQUEST_ALL_ANNOTATION_JOBS
  };
}

function receiveAllAnnotationJobs(json) {
  return {
    type: RECEIVE_ALL_ANNOTATION_JOBS,
    all_annotation_jobs: json
  };
}

function requestAnnotationJobById() {
  return {
    type: REQUEST_ANNOTATION_JOB_BY_ID
  };
}

function receiveAnnotationJobById(json) {
  return {
    type: RECEIVE_ANNOTATION_JOB_BY_ID,
    annotation_jobs: json
  };
}

function requestAllSkippedAnnotations() {
  return {
    type: REQUEST_All_SKIPPED_ANNOTATIONS
  };
}

function receiveAllSkippedAnnotations(json) {
  return {
    type: RECEIVE_All_SKIPPED_ANNOTATIONS,
    all_skipped_annotations: json.annotation_jobs
  };
}

function requestAnnotationTaskTopicGroups() {
  return {
    type: REQUEST_ANNOTATION_TASK_TOPIC_GROUPS
  };
}

function receiveAnnotationTaskTopicGroups(data) {
  return {
    type: RECEIVE_ANNOTATION_TASK_TOPIC_GROUPS,
    annotation_task_topic_groups: data.annotation_task_groups
  };
}

function editAnnotationTaskTopicGroup() {
  return {
    type: EDIT_ANNOTATION_TOPIC_GROUP
  };
}

function annotationTaskTopicGroupEdited() {
  return {
    type: ANNOTATION_TOPIC_GROUP_EDITED
  };
}

function requestAggregatedAnnotations() {
  return {
    type: REQUEST_AGGREGATED_ANNOTATIONS
  };
}

function receiveAggregatedAnnotations(data) {
  return {
    type: RECEIVE_AGGREGATED_ANNOTATIONS,
    total: data.total,
    aggregated_annotations: data.aggregated_annotations
  };
}

export function fetchContributorStatistics(annotation_task_id, data) {
  return function doFetch(dispatch) {
    dispatch(requestContributorStatistics(annotation_task_id));
    return api_fetchContributorStatistics(annotation_task_id, data)
      .then(response => {
        dispatch(receiveContributorStatistics(response));
        return response;
      });
  };
}

export function fetchAnnotationTasksByUser(topic_group_id, user_id) {
  return {
    type: FETCH_ANNOTATION_TASKS_BY_USER,
    payload: api_fetchAnnotationTasksByUser(topic_group_id, user_id)
  };
}

export function editAggregatedAnnotation(data, aggregated_annotation_id) {
  return function doPost() {
    return api_editAggregatedAnnotation(aggregated_annotation_id, data).then(response => response);
  };
}

export function fetchAggregatedAnnotations(topic_id, data = { sorting_doc_id: 'ascending' }) {
  return function doFetch(dispatch) {
    dispatch(requestAggregatedAnnotations());
    return api_requestAggregatedAnnotations(topic_id, data)
      .then(response => {
        dispatch(receiveAggregatedAnnotations(response));
        return response;
      });
  };
}

export function editAggregatedAnnotationResearch(data, aggregated_annotation_id) {
  return function doPost(dispatch) {
    return api_editAggregatedAnnotationResearch(aggregated_annotation_id, data)
      .then(response => response);
  };
}

export function fetchActiveTopicAnnotationModelIds(topic_id) {
  return function doFetch(dispatch) {
    return api_fetchActiveTopicAnnotationModelIds(topic_id).then(response => response);
  };
}

export function editAggregatedAnnotationGold(data, aggregated_annotation_id) {
  return function doPost(dispatch) {
    return api_editAggregatedAnnotationGold(aggregated_annotation_id, data)
      .then(response => response);
  };
}

export function fetchAllAnnotationTasks(data) {
  return function dofetch(dispatch) {
    dispatch(requestAllAnnotationTasks());
    return api_fetchAllAnnotationTasks()
      .then(response => {
        dispatch(receiveAllAnnotationTasks(response));
        return response;
      });
  };
}

export function createAnnotationTaskFromParams(data) {
  return function doPost(dispatch) {
    dispatch(createAnnotationTask(data));
    return api_createAnnotationTaskFromParams(data)
      .then(response => {
        dispatch(annotationTaskCreated(response));
        return response;
      })
      .catch(() => dispatch(annotationTaskCreated(null)));
  };
}

export function updateAnnotationTaskWithParams(annotation_task_id, data) {
  return function dopost(dispatch) {
    dispatch(updateAnnotationTask());
    return api_updateAnnotationTaskWithParams(annotation_task_id, data)
      .then(response => {
        dispatch(annotationTaskUpdated(response));
      });
  };
}

export function deleteAnnotationTaskWithParams(annotation_task_id, data) {
  return function doDelete(dispatch) {
    dispatch(deleteAnnotationTask());
    return api_deleteAnnotationTaskWithParams(annotation_task_id, data)
      .then(response => {
        dispatch(annotationTaskDeleted(response));
      })
      .catch(error => dispatch(annotationTaskDeleted(null)));
  };
}

export function fetchAnnotationJob(annotation_task_id) {
  return function dofetch(dispatch) {
    dispatch(requestAnnotationJobs());
    return api_fetchAnnotationJob(annotation_task_id)
      .then(response => {
        dispatch(receiveAnnotationJobs(response));
        return response;
      });
  };
}

export function createAnnotationsForJob(annotation_task_id, annotation_job_id, data) {
  return function dopost(dispatch) {
    dispatch(createAnnotations(annotation_task_id, annotation_job_id, data));
    return api_createAnnotationsForJob(annotation_task_id, annotation_job_id, data)
      .then(response => {
        dispatch(annotationsCreated(response));
        return response;
      });
  };
}

export function fetchAnnotationStatistics(annotation_task_id, data) {
  return function dofetch(dispatch) {
    dispatch(requestAnnotationStatistics(annotation_task_id));
    return api_fetchAnnotationStatistics(annotation_task_id, data)
      .then(response => {
        dispatch(receiveAnnotationStatistics(response));
        return response;
      });
  };
}

export function fetchAllAnnotationJobs(annotation_task_id, data) {
  return function dofetch(dispatch) {
    dispatch(requestAllAnnotationJobs(annotation_task_id));
    return api_fetchAllAnnotationJobs(annotation_task_id, data)
      .then(response => {
        dispatch(receiveAllAnnotationJobs(response));
        return response;
      });
  };
}

export function fetchAnnotationJobById(annotation_task_id, annotation_job_id) {
  return function dofetch(dispatch) {
    dispatch(requestAnnotationJobById());
    return api_fetchAnnotationJobById(annotation_task_id, annotation_job_id)
      .then(response => {
        dispatch(receiveAnnotationJobById(response));
      });
  };
}

export function fetchAllSkippedAnnotations() {
  return function dofetch(dispatch) {
    dispatch(requestAllSkippedAnnotations());
    return api_fetchAllSkippedAnnotations()
      .then(response => {
        dispatch(receiveAllSkippedAnnotations(response));
        return response;
      });
  };
}

export function fetchAnnotationTaskTopicGroups(id) {
  return function doFetch(dispatch) {
    dispatch(requestAnnotationTaskTopicGroups());
    return api_fetchAnnotationTaskTopicGroups(id)
      .then(response => {
        dispatch(receiveAnnotationTaskTopicGroups(response));
        return response;
      });
  };
}

export function postAnnotationTaskTopicGroup(data, id) {
  return function doPost(dispatch) {
    dispatch(editAnnotationTaskTopicGroup());
    return api_postAnnotationTaskTopicGroup(id, data)
      .then(response => {
        dispatch(annotationTaskTopicGroupEdited(response));
        return response;
      });
  };
}

export function deleteAnnotationTaskTopicGroup(id) {
  return function doDelete(dispatch) {
    return api_deleteAnnotationTaskTopicGroup(id)
      .then(response => {
        return response;
      });
  };
}

function receiveOnboardingResults(results) {
  return {
    type: FETCH_ONBOARDING_RESULTS,
    results
  };
}

export function fetchOnboardingResults() {
  return function fetch(dispatch) {
    return api_fetchOnboardingResults().then(
      response => dispatch(receiveOnboardingResults(response))
    );
  };
}
