import type { CrawlerCatalogData } from './useCrawlerCatalogData.types';
import type { CrawlerCatalogHandlers } from './useCrawlerCatalogHandlers.types';

import * as constants from '../CrawlerCatalog.constants';

import { useCallback } from 'react';

export const useCrawlerCatalogHandlers = ({
  localActions,
  formattedData
}: {
  localActions: CrawlerCatalogData['localActions'];
  formattedData: CrawlerCatalogData['formattedData'];
}): CrawlerCatalogHandlers => {
  const handleOpenModal: CrawlerCatalogHandlers['handleOpenModal'] = info => {
    localActions.setExtraContentModalData(info);
  };

  const handleEditCrawlConfigModalSubmit: CrawlerCatalogHandlers['handleEditCrawlConfigModalSubmit'] =
    () => {
      localActions.setMode(constants.MODE_CONFIRMATION_MODAL.EDIT_CONFIG);
      localActions.setShouldShowModal(true);
    };

  const handleEditCrawlConfigModalClose: CrawlerCatalogHandlers['handleEditCrawlConfigModalClose'] =
    () => {
      localActions.setShouldShowViewModal(false);
    };

  const handleInitialCrawlConfigChange: CrawlerCatalogHandlers['handleInitialCrawlConfigChange'] =
    useCallback(() => {
      localActions.setCrawlConfig(formattedData.initialCrawlConfig);
    }, [localActions, formattedData.initialCrawlConfig]);

  return {
    handleOpenModal,
    handleEditCrawlConfigModalSubmit,
    handleEditCrawlConfigModalClose,
    handleInitialCrawlConfigChange
  };
};
