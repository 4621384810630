import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_fetchSpiderRunReportsCrawlerStatistic(params = {}) {
  return fetch({
    url: '/spider_run_report_crawlers_performance',
    method: 'GET',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}
