import { fetch, BASE_API_OPTIONS } from "fetch";

export function api_getMetaCategories(params) {
  return fetch({
    url: `/meta-categories`,
    dataType: 'MetaCategories',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postMetaCategories(params) {
  return fetch({
    url: `/meta-categories`,
    dataType: 'MetaCategories',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putMetaCategories(params) {
  return fetch({
    url: `/meta-categories`,
    dataType: 'MetaCategories',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteMetaCategories(params) {
  return fetch({
    url: `/meta-categories`,
    dataType: 'MetaCategories',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
