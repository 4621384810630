import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';

export const ALLOWED_SELECTED_CATEGORY_COUNT = 5000;

export const TABLE_LOCAL_STORAGE_KEY = 'scrapedCategoriesSplit';

export const ROWS_PER_PAGE: Required<ComponentProps<typeof uiLib.Table>>['rowsPerPageOptions'] = [
  10, 50, 100, 200, 500
];

export const CAI_CATEGORY_DROPDOWN_OFFSET = 0;
export const CAI_CATEGORY_DROPDOWN_LIMIT = 1000;

export enum TABLE_KEYS {
  NAME = 'name',
  JURISDICTION = 'jurisdiction'
}

export const CREATED_TYPE = 'NEW';

export const COLUMNS: uiLib.Column[] = [
  {
    title: 'Scraped Category',
    dataKey: TABLE_KEYS.NAME,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.M,
    isDynamic: false,
    Component: uiLib.TextCell
  },
  {
    title: 'Jurisdictions',
    dataKey: TABLE_KEYS.JURISDICTION,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.M,
    isDynamic: false,
    Component: uiLib.TextCell
  }
];
