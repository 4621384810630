import { OverlayTrigger, Popover } from "react-bootstrap";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import { popoverSettings } from "../constants/popoverConst";

const PopoverInfo = props => {
  const { title, text } = popoverSettings
    .find(popover => popover.name === props.popoverConst);
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="right"
      overlay={(
        <Popover id="popover-trigger-hover-focus" title={title}>
          {text}
        </Popover>
      )}
    >
      <InfoIcon className="infoIcon" />
    </OverlayTrigger>
  );
};

PopoverInfo.propTypes = {
  popoverConst: PropTypes.string.isRequired
};

export default PopoverInfo;
