import React from 'react';
import PropTypes from 'prop-types';
import { ERRORS, INFO_HELP, NAMES_STEP, NAMES_RESET_COUNTER } from '../CrawlSpecCreate.constants';
import Select from "../../../components/ReactSelect/Select";
import SpecElementsWrapper from "./SpecElementsWrapper";

const ResetCounter = ({
  disabled,
  show,
  element,
  steps,
  changeResetCounter,
  errorFields
}) => {
  return (
    <SpecElementsWrapper
      show={show}
      label="Reset Counter"
      tooltip={INFO_HELP.RESET_COUNTER}
    >
      <Select
        label="Next Step"
        value={element[NAMES_RESET_COUNTER.STEP]}
        onChange={e => changeResetCounter(NAMES_RESET_COUNTER.STEP, e)}
        options={
            steps.map(item => ({
              value: item[NAMES_STEP.NAME],
              label: item[NAMES_STEP.NAME]
            }))
          }
        isDisabled={disabled}
        isClearable
        isMulti
        isError={errorFields.includes(NAMES_RESET_COUNTER.STEP)}
        errorText={ERRORS.resetCounterStep}
      />
    </SpecElementsWrapper>
  );
};

ResetCounter.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  element: PropTypes.shape({}),
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  changeResetCounter: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

ResetCounter.defaultProps = {
  disabled: false,
  show: false,
  element: {},
  steps: [],
  errorFields: []
};

export default ResetCounter;
