import { createSlice } from "@reduxjs/toolkit";
import { getDocumentsInRightOrder, getDocumentsWithoutDuplicates } from "./approachingDeadlines.helper";
import { RULE_COMMENTS_CLOSE_ON, RULE_EFFECTIVE_ON } from "../../../constants/SortRules";

export const reducerName = 'approachingDeadline';

export const initialState = {
  documentsCountSortedByEffectiveDate: {},
  documentsCountSortedByCommentsCloseDate: {},
  sortedByEffectiveDate: {},
  sortedByCommentsCloseDate: {},
  sortedByEffectiveAndCommentCloseDate: {}
};

const approachingDeadlinesWidget = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setSortByEffectiveDateDocs: (state, action) => {
      state.sortedByEffectiveDate = getDocumentsInRightOrder(
        state.sortedByEffectiveDate,
        action.payload,
        RULE_EFFECTIVE_ON
      );
      state.documentsCountSortedByEffectiveDate[action.payload.id] = action.payload.count;
    },
    setSortByCommentsCloseDocs: (state, action) => {
      state.sortedByCommentsCloseDate = getDocumentsInRightOrder(
        state.sortedByCommentsCloseDate,
        action.payload,
        RULE_COMMENTS_CLOSE_ON
      );
      state.documentsCountSortedByCommentsCloseDate[action.payload.id] = action.payload.count;
    },
    setSortByEffectiveAndCommentCloseDate: (state, action) => {
      state.sortedByEffectiveAndCommentCloseDate = getDocumentsWithoutDuplicates(
        state,
        action.payload
      );
    },
    clearState: (state) => {
      state.documentsCountSortedByEffectiveDate= {};
      state.documentsCountSortedByCommentsCloseDate= {};
      state.sortedByEffectiveDate= {};
      state.sortedByCommentsCloseDate= {};
      state.sortedByEffectiveAndCommentCloseDate = {};
    }
  }
});

export default approachingDeadlinesWidget.reducer;
export const {
  setSortByEffectiveDateDocs,
  setSortByCommentsCloseDocs,
  setSortByEffectiveAndCommentCloseDate,
  clearState
} = approachingDeadlinesWidget.actions;
