import React from 'react';
import Typography from '../../../../../../../../../components/Typography';
import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';

export const ContentBlock = ({ content, crawlerActivity }) => {
  return crawlerActivity ? (
    <div key={content.name}>
      <Typography variant={content.time ? 'title' : 'h0'} bold>
        {_.get(crawlerActivity, content.paramName, 0)}
      </Typography>
      <Typography className="p subtitle" variant="body" color="gray">
        {content.name}
      </Typography>
    </div>
  ) : (
    <CircularProgress key={content.name} />
  );
};
