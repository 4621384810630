import { UseDocumentSettingsNavigationHandlersTypes } from './useDocumentSettingsNavigationHandlers.types';
import { useEffect } from 'react';

export const useDocumentSettingsNavigationLifecycle = ({
  onGetDocument
}: {
  onGetDocument: UseDocumentSettingsNavigationHandlersTypes['handleGetDocuments'];
}) => {
  useEffect(onGetDocument, [onGetDocument]);
};
