import { openErrorSnackbar } from "../../snackbar/snackbar.reducer";
import { setHistoryArray } from "./crawlerHistory.reducer";
import { api_getCrawlerHistory } from "./crawlerHistory.api";

export const ERROR_GET_HISTORY = 'Can not get crawler history';

export const getCrawlerHistoryAC = (crawlId) => async dispatch => {
  try {
    const res = await api_getCrawlerHistory(crawlId);
    await dispatch(setHistoryArray(res.history));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR_GET_HISTORY));

    throw e
  }
};

export const clearCrawHistory = () => dispatch => dispatch(setHistoryArray([]));
