import React from 'react';
import Logo from 'shared/images/logos/ComplianceAi-white.png';
import './_auth-form.scss';

export const AuthForm = ({ children }) => {
  return (
    <div className="auth-form">
      <section className="auth-form__header">
        <a
          href="https://compliance.ai"
          target="_blank"
          rel="noopener noreferrer"
          className="auth-form__logo"
        >
          <img src={Logo} alt="logo" className="auth-form__logo-image" />
        </a>
        <span className="auth-form__admin-text">
          admin tool
        </span>
      </section>
      {children}
    </div>
  );
};
