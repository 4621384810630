export const ID_KEY = 'Doc ID';
export const CREATED_AT_KEY = 'Created Date';
export const UPDATED_AT_KEY = 'Updated Date';
export const PUBLICATION_DATE_KEY = 'Pub Date';
export const FULL_TEXT_KEY = 'Full Text';
export const FULL_TEXT_HASH_KEY = 'Full Text Hash';
export const SUMMARY_TEXT_KEY = 'Summary Text';
export const TITLE_KEY = 'Title';
export const PDF_URL_KEY = 'PDF URL';
export const WEB_URL_KEY = 'WEB URL';
export const META_TABLE_KEY = 'Meta Table';
export const API_TABLE_KEY = 'API Table';
export const PIPELINE_STATUS_KEY = 'Status';
export const INTERACTION_STATUS_KEY = 'State';
export const OFFICIAL_ID_KEY = 'Official ID';
export const CITATION_IDS_KEY = 'Citation ids';
export const PARENT_ID_KEY = 'Parent ID';
export const DOC_SCRAPED_CATEGORY_ID_KEY = 'Scrapped Category ID';
export const SCRAPED_CATEGORY_KEY = 'Scraped Category';
export const JURISDICTION_ID_KEY = 'Jurisdiction ID';
export const JURISDICTION_KEY = 'Jurisdiction';
export const AGENCY_IDS_KEY = 'Agency ids';
export const SPIDER_NAME_KEY = 'Spider Name';
export const SPIDER_ID_KEY = 'Spider ID';
export const PDF_HASH_KEY = 'Pdf Hash';
export const CATEGORY_KEY = 'Category';
export const DOCUMENT_EXAMINATION_RECORD_KEY = 'Document examination record';
export const DOCKET_ID_KEY = 'Docket ID';
export const RESPONDENT_NAME_KEY = 'Respondent Name';
export const EFFECTIVE_DATE_KEY = 'Effective Date';
export const COMMENT_CLOSED_DATE_KEY = 'Comment Closed Date';
export const LANGUAGES_KEY = 'Languages';
export const RELATIONS_KEY = 'Relations';
export const VERSIONS_KEY = 'Versions';
export const RULE_KEY = 'Rule';
export const INTERNAL_UPDATED_AT_KEY = 'Internal Updated Date';
export const EITL_LABELS_KEY = 'EITL labels';
export const AGENCIES_KEY = 'Agencies';
export const CAI_CATEGORY_ID_KEY = 'CAI category ID';
export const PARENT_DOC_KEY = 'Parent Doc';
export const MAINSTREAM_NEWS_KEY = 'Mainstream news';
export const LATEST_DOCUMENT_VERSION_KEY = 'Latest Document version';
export const HAS_UNOFFICIAL_PUBLICATION_DATE_KEY = 'Unofficial Publication Date';
export const DIFFABLE_KEY = 'Diffable';
export const ENFORCEMENT_KEY = 'Enforcement';
export const VIOLATION_KEY = 'Violation';
export const PENALTY_KEY = 'Penalty';
export const RESPONDENT_KEY = 'Respondent';

export const API_KEYS = {
  [ID_KEY]: 'id',
  [CREATED_AT_KEY]: 'created_at',
  [UPDATED_AT_KEY]: 'updated_at',
  [PUBLICATION_DATE_KEY]: 'publication_date',
  [FULL_TEXT_KEY]: 'full_text',
  [SUMMARY_TEXT_KEY]: 'summary_text',
  [TITLE_KEY]: 'title',
  [PDF_URL_KEY]: 'pdf_url',
  [WEB_URL_KEY]: 'web_url',
  [META_TABLE_KEY]: 'meta_table',
  [API_TABLE_KEY]: 'api_table',
  [PIPELINE_STATUS_KEY]: 'pipeline_status',
  [INTERACTION_STATUS_KEY]: 'interaction_status',
  [OFFICIAL_ID_KEY]: 'official_id',
  [CITATION_IDS_KEY]: 'citation_ids',
  [PARENT_ID_KEY]: 'parent_id',
  [DOC_SCRAPED_CATEGORY_ID_KEY]: 'doc_scraped_category_id',
  [SCRAPED_CATEGORY_KEY]: 'scraped_category',
  [JURISDICTION_ID_KEY]: 'jurisdiction_id',
  [AGENCY_IDS_KEY]: 'agency_ids',
  [SPIDER_NAME_KEY]: 'spider_name',
  [SPIDER_ID_KEY]: 'spider_id',
  [PDF_HASH_KEY]: 'pdf_hash',
  [FULL_TEXT_HASH_KEY]: 'full_text_hash',
  [JURISDICTION_KEY]: 'jurisdiction',
  [CATEGORY_KEY]: 'category',
  [DOCUMENT_EXAMINATION_RECORD_KEY]: 'document_examination_record',
  [DOCKET_ID_KEY]: 'docket_id',
  [RESPONDENT_NAME_KEY]: 'respondent_name',
  [EFFECTIVE_DATE_KEY]: 'effective_on',
  [COMMENT_CLOSED_DATE_KEY]: 'comment_closed_on',
  [LANGUAGES_KEY]: 'document_languages',
  [RELATIONS_KEY]: 'document_relations',
  [VERSIONS_KEY]: 'document_versions',
  [RULE_KEY]: 'rule',
  [INTERNAL_UPDATED_AT_KEY]: 'internal_updated_at',
  [EITL_LABELS_KEY]: 'eitl_labels',
  [AGENCIES_KEY]: 'agencies',
  [CAI_CATEGORY_ID_KEY]: 'cai_category_id',
  [PARENT_DOC_KEY]: 'parent_doc',
  [MAINSTREAM_NEWS_KEY]: 'mainstream_news',
  [LATEST_DOCUMENT_VERSION_KEY]: 'latest_document_version',
  [HAS_UNOFFICIAL_PUBLICATION_DATE_KEY]: 'has_unofficial_publication_date',
  [DIFFABLE_KEY]: 'diffable',
  [ENFORCEMENT_KEY]: 'enforcement',
  [VIOLATION_KEY]: 'violation',
  [PENALTY_KEY]: 'penalty',
  [RESPONDENT_KEY]: 'respondent'
};

export enum DOCUMENT_ATTRIBUTES {
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  PUBLICATION_DATE = 'publication_date',
  FULL_TEXT = 'full_text',
  FULL_XML = 'full_xml',
  SUMMARY_TEXT = 'summary_text',
  TITLE = 'title',
  PDF_URL = 'pdf_url',
  WEB_URL = 'web_url',
  META_TABLE = 'meta_table',
  API_TABLE = 'api_table',
  PIPELINE_STATUS = 'pipeline_status',
  PIPELINE_STATUSES = 'pipeline_statuses',
  INTERACTION_STATUS = 'interaction_status',
  INTERACTION_STATUSES = 'interaction_statuses',
  OFFICIAL_ID = 'official_id',
  OFFICIAL_IDS = 'official_ids',
  CITATION = 'citation',
  CITATIONS = 'citation_id',
  CITATION_IDS = 'citation_ids',
  PARENT_ID = 'parent_id',
  DOC_SCRAPED_CATEGORY_ID = 'doc_scraped_category_id',
  SCRAPED_CATEGORY = 'scraped_category',
  JURISDICTION_ID = 'jurisdiction_id',
  AGENCY_IDS = 'agency_ids',
  AGENCY_ID = 'agency_id',
  SPIDER_NAME = 'spider_name',
  SPIDER_NAMES = 'spider_names',
  SPIDER_ID = 'spider_id',
  PDF_HASH = 'pdf_hash',
  FULL_TEXT_HASH = 'full_text_hash',
  JURISDICTION = 'jurisdiction',
  CATEGORY = 'category',
  DOC_SCRAPPED_CATEGORY = 'doc_scraped_category',
  DOCUMENT_EXAMINATION_RECORD = 'document_examination_record',
  DOCKET_ID = 'docket_id',
  RESPONDENT_NAME = 'respondent_name',
  EFFECTIVE_DATE = 'effective_on',
  COMMENT_CLOSED_DATE = 'comment_closed_on',
  META_CATEGORY = 'meta_category',
  LANGUAGES = 'document_languages',
  RELATIONS = 'document_relations',
  VERSIONS = 'document_versions',
  SHELF_ID = 'shelf_id',
  ROOT_SHELF_ID = 'root_shelf_id',
  IS_SHELF_EXIST = 'is_shelf_exist',
  LANGUAGE_RELATIONS = 'language_document_relation',
  RULE = 'rule',
  INTERNAL_UPDATED_AT = 'internal_updated_at',
  EITL_LABELS = 'eitl_labels',
  AGENCIES = 'agencies',
  CAI_CATEGORY_ID = 'cai_category_id',
  CAI_CATEGORY_IDS = 'cai_category_ids',
  PARENT_DOC = 'parent_doc',
  MAINSTREAM_NEWS = 'mainstream_news',
  LATEST_DOCUMENT_VERSION = 'latest_document_version',
  HAS_UNOFFICIAL_PUBLICATION_DATE = 'has_unofficial_publication_date',
  VERSIONIZED = 'versionized',
  DIFFABLE = 'diffable',
  ENFORCEMENT = 'enforcement',
  VIOLATION = 'violation',
  PENALTY = 'penalty',
  RESPONDENT = 'respondent',
  PARENT_DOCUMENT = 'parent_document'
}

export enum DOC_STATUS_COLORS {
  DEFAULT = 'default',
  GREEN = 'green',
  RED = 'red',
  ORANGE = 'orange',
  GRAY = 'gray',
  LIGHT_RED = 'light_red',
  PURPLE = 'purple',
  BROWN = 'brown',
  PINK = 'pink'
}
