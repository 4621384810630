import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../../shared/reducers';
import { getCurrentUser } from '../../../../shared/features/user/user.selectors';
import {
  getDocumentApiTimestamp,
  getFirstDocumentApiElement
} from '../../../../shared/features/documentData/documentData.selectors';
import { DocumentSettingsNavigationData } from './useDocumentSettingsNavigationData.types';

export const useDocumentSettingsNavigationData = (): DocumentSettingsNavigationData => {
  const { id } = useParams();

  const reduxState = useSelector<GlobalState, DocumentSettingsNavigationData['reduxState']>(
    state => ({
      currentUser: getCurrentUser(state),
      docDetailsInfo: getFirstDocumentApiElement(state),
      docTimestamp: getDocumentApiTimestamp(state)
    })
  );

  return {
    reduxState,
    formattedData: {
      id
    }
  };
};
