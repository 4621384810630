import type { FC } from 'react';

import React from 'react';
import { Tabs } from '@compliance.ai/web-components';
import {
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM,
  CONTENT_MANAGEMENT_TABS
} from './ContentManagement.constants';

import './_content-management.scss';

const ContentManagement: FC = () => {
  return (
    <Tabs
      className="content-management"
      tabsBarClassName="content-management__tabs-bar"
      tabs={CONTENT_MANAGEMENT_TABS}
      isRoutable
      routeKey={CONTENT_MANAGEMENT_TAB_QUERY_PARAM}
    />
  );
};

export default ContentManagement;
