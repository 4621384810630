import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
// icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import BugReportIcon from '@material-ui/icons/BugReport';
import TocIcon from '@material-ui/icons/Toc';
import StorageIcon from '@material-ui/icons/Storage';
import DescriptionIcon from '@material-ui/icons/Description';
import ROUTES from '../../../../constants/routes';
import Typography from "../../../../components/Typography";
import classnames from 'classnames';
import './_app-left-bar.scss';

// this should be moved to to the separate const file with restyling
// now it here to don't create mess up in files
const LEFT_BAR_ELEMENTS = [
  { path: ROUTES.dashboard, text: 'Dashboard', icon: <DashboardIcon /> },
  { path: ROUTES.crawlerMonitoring, text: 'Crawler & Reporting', icon: <BugReportIcon /> },
  { path: ROUTES.documents, text: 'Document Management', icon: <DescriptionIcon /> },
  { path: ROUTES.contentManagement, text: 'Content Management', icon: <TocIcon /> },
  { path: ROUTES.customSettings, text: 'Custom Settings', icon: <StorageIcon /> }
];

export const AppLeftBar = () => {
  const location = useLocation();

  return (
    <div className="app-left-bar">
      {LEFT_BAR_ELEMENTS.map((route, index) => (
        <NavLink
          key={index}
          to={route.path}
          isActive={false}
          className={classnames("app-left-bar__link", {
            "app-left-bar__link--is-active": location.pathname.includes(route.path)
          })}
        >
          {route.icon}
          <Typography variant="tag" bold>{route.text}</Typography>
        </NavLink>
      ))}
    </div>
  )
};
