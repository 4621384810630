import type * as apiTypes from './eitlLabels.api.types';
import type * as eitlLabelsTypes from './eitlLabels.types';

import * as uiLib from '@compliance.ai/web-components';

export const normalizeEitlLabelsResponse = (
  response: apiTypes.GetEitlLabelsResponse
): apiTypes.NormalizedEitlLabelsResponse => {
  return {
    count: response.count,
    labels: response.items.map(normalizeEitlLabel)
  };
};

export const normalizeEitlLabel = (
  eitlLabel: apiTypes.EitlLabelFromResponse
): apiTypes.NormalizedEitlLabel => {
  return {
    id: eitlLabel.id,
    name: eitlLabel.name,
    createdAt: uiLib.isValidDate(eitlLabel.created_at) ? eitlLabel.created_at : '',
    userId: eitlLabel.user_id,
    crawlsCount: eitlLabel.crawls_count,
    documentCount: eitlLabel.document_count
  };
};

export const formatEitlLabelsOption = (
  normalizedEitlLabel: Pick<apiTypes.NormalizedEitlLabel, 'id' | 'name'>
): eitlLabelsTypes.EitlLabelOption => ({
  label: normalizedEitlLabel.name,
  value: normalizedEitlLabel.id
});

export const formatEitlLabelsOptions = (
  response: apiTypes.NormalizedEitlLabelsResponse
): eitlLabelsTypes.EitlLabelOption[] => {
  return response.labels.map(formatEitlLabelsOption);
};

export const convertOptionToEitlLabelData = (
  eitlLabelOption: eitlLabelsTypes.EitlLabelOption
): apiTypes.EitlLabelDataFromResponse => ({
  id: eitlLabelOption.value,
  name: eitlLabelOption.label
});
