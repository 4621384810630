import type { FC } from 'react';
import type { ButtonLinkCellProps } from './CommandRequestsLinksButtonCell.types';

import * as uiLib from '@compliance.ai/web-components';

import { useCommandRequestsLinksButtonCellHandlers } from './hooks';

export const CommandRequestsLinksButtonCell: FC<ButtonLinkCellProps> = ({ details }) => {
  const handlers = useCommandRequestsLinksButtonCellHandlers({ props: { downloadLinks: details } });
  return (
    <div className="command-requests-links-button-cell">
      <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleDownloadFiles}>
        <uiLib.Typography>Download Info</uiLib.Typography>
      </uiLib.Button>
    </div>
  );
};
