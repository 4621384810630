// COMMON
import type { ComponentProps } from 'react';
import * as uiLib from '@compliance.ai/web-components';
import * as crawlConstants from 'admin/constants/crawlers';
import * as elements from './elements';

export const DATE_FORMAT = 'M-D-YYYY H:m:s';
export const ROUNDING_DEGREE = 2;
export const DATE_FORMAT_SEARCH = 'YYYY-MM-DDTHH:mm:ss';

// SELECT
export const STATISTIC_TYPES = {
  AVG: { value: 'avg', label: 'Average' },
  LAST: { value: 'last', label: 'Last Run' },
  TOTAL: { value: 'total', label: 'Total' }
};

export const SELECT_BOOL = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];

export const SELECT_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

// ERRORS
export const RUN_NOW_ERROR = {
  title: 'Run Now error',
  text: 'For multiply Run Now all chosen crawl should be unified. Or should be chosen only one crawler.'
};

// ----- CRAWL BADGE SETTINGS -----

export const STATUS_BADGE = {
  RUNNING: { text: 'Running', type: 'info' },
  ACTIVE: { text: 'Idle', type: 'success' },
  QUEUED: { text: 'Queued', type: 'success' },
  DEACTIVATED: { text: 'Deactivated', type: 'error' },
  CONSTRUCTION: { text: 'Construction', type: 'warning' },
  FAILED: { text: 'Failed', type: 'error' },
  TIMED_OUT: { text: 'Timed Out', type: 'error' },
  STOP_RUN: { text: 'Stop Run', type: 'info' },
  AUTO_QA: { text: 'Auto QA', type: 'info' },
  SOURCE_CHANGED: { text: 'Source Changed', type: 'info' }
};

export const RUN_STATUS_BADGE = {
  WARNING: { text: 'Processed', type: 'warning' },
  ERROR: { text: 'Error', type: 'error' },
  SUCCESS: { text: 'Processed', type: 'success' },
  FAILED: { text: 'Failed', type: 'error' },
  STOPPED: { text: 'Stopped', type: 'warning' },
  TIMED_OUT: { text: 'Timed Out', type: 'error' }
};

// ----- TABLE CONSTANTS -----

export const TABLE_VARIABLES = {
  id: 'id',
  spider_name: 'spider_name',
  is_active: 'is_active',
  is_resource: 'is_resource',
  status: 'status',
  state: 'state',
  run_status: 'run_status',
  news_source: 'news_source',
  external_api_id: 'external_api_id',
  source_url: 'source_url',
  jurisdiction: 'jurisdiction',
  agency: 'agency',
  type: 'type',
  category: 'category',
  total_runs: 'total_runs',
  last_run: 'last_run',
  first_run: 'first_run',
  ingested_average: 'ingested_average',
  total_ingested: 'total_ingested',
  last_ingested: 'last_ingested',
  last_ingestion_time: 'last_ingestion_time',
  last_skipped: 'last_skipped',
  total_skipped: 'total_skipped',
  skipped_average: 'skipped_average',
  crawler_recurring_frequency: 'details.crawler_recur.interval',
  last_processed: 'crawler_statistics.last_processed',
  total_processed: 'crawler_statistics.total_processed',
  processed_average: 'total_processed/total_runs',
  last_errors: 'crawler_statistics.last_errors',
  total_errors: 'crawler_statistics.total_errors',
  errors_average: 'total_errors/total_runs',
  total_ingested_sla: 'crawler_statistics.total_ingested_sla',
  ingested_sla_average: 'total_ingested_sla/total_runs',
  total_ingested_outside_sla: 'total_ingested-total_ingested_sla',
  ingested_outside_sla_average: 'total_ingested_outside_sla/total_runs',
  scraped_agencies: 'doc_scraped_agencies',
  scraped_news_sources: 'doc_scraped_news_sources',
  scraped_categories: 'doc_scraped_categories',
  // only filters
  is_versioning: 'enable_document_versioning',
  meta_category: 'meta_category',
  crawl_total_runs: 'crawl_total_runs',
  docs_processed: 'docs_processed',
  docs_skipped: 'docs_skipped',
  docs_ingested: 'docs_ingested',
  docs_errors: 'docs_errors',
  docs_within_sla: 'docs_within_sla',
  docs_outside_sla: 'docs_outside_sla',
  last_updated_time: 'last_updated_time',
  crawler_frequency: 'crawler_frequency',
  multiField: 'multi_input',
  spiderRunReportStatus: 'spider_run_report_status',
  spiderRunReportID: 'spider_run_report_id',
  last_spider_run_report_failed_docs_stat: 'last_spider_run_report_failed_docs_stat',
  last_crawled: 'last_crawled',
  devComplexity: 'dev_complexity'
};

enum DEV_COMPLEXITY {
  CUSTOM = 'custom',
  SIMPLE = 'simple',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  HUGE = 'huge'
}

export const DEV_COMPLEXITY_FILTER_OPTIONS = [
  { value: DEV_COMPLEXITY.CUSTOM, label: 'Custom' },
  { value: DEV_COMPLEXITY.SIMPLE, label: 'Simple', start: 0, end: 14 },
  { value: DEV_COMPLEXITY.LOW, label: 'Low', start: 15, end: 26 },
  { value: DEV_COMPLEXITY.MEDIUM, label: 'Medium', start: 27, end: 32 },
  { value: DEV_COMPLEXITY.HIGH, label: 'High', start: 33, end: 37 },
  { value: DEV_COMPLEXITY.HUGE, label: 'Huge', start: 38, end: 1000 }
];

export const ALLOWED_SELECTED_CRAWL_COUNT = 300;

export const TABLE_LOCAL_STORAGE_KEY = 'crawlerManagement';

export const CRAWL_ACTION_AMOUNT = 5;
export const CRAWL_MOVE_TO_IDLE_ACTION_AMOUNT = 50;

export const COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'ID',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.ID,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.ID,
    isDynamic: false,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Spider Name',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.SPIDER_NAME,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.SPIDER_NAME,
    isDynamic: false,
    isInitiallyVisible: true,
    Component: uiLib.LinkCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'State',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.STATUS,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.STATUS,
    isDynamic: true,
    isInitiallyVisible: true,
    render: elements.CrawlStatus,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Status',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.RUN_STATUS,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.RUN_STATUS,
    isDynamic: true,
    isInitiallyVisible: true,
    render: elements.CrawlRunStatus,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'News Source',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.NEWS_SOURCE,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.NEWS_SOURCE,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Crawl ID',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.EXTERNAL_API_ID,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.EXTERNAL_API_ID,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Source URL',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.SOURCE_URL,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.SOURCE_URL,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Jurisdiction',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.JURISDICTION,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.JURISDICTION,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Agency',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.AGENCY,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.AGENCY,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Type',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TYPE,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.TYPE,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Category',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.CATEGORY,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.CATEGORY,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Recurring Frequency',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.CRAWLER_RECURRING_FREQUENCY,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Total Runs',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_RUNS,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_RUNS,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Last Run Time',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_RUN,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_RUN,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'First Run Time',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.FIRST_RUN,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.FIRST_RUN,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Avg Ingested',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.INGESTED_AVERAGE,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.INGESTED_AVERAGE,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Total Ingested',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_INGESTED,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_INGESTED,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Last Ingested',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_INGESTED,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_INGESTED,
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Last Ingested Date',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_INGESTION_TIME,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_INGESTION_TIME,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Last Skipped',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_SKIPPED,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_SKIPPED,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Total Skipped',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_SKIPPED,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_SKIPPED,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Avg Skipped',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.SKIPPED_AVERAGE,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.SKIPPED_AVERAGE,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Last Processed',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_PROCESSED,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_PROCESSED,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Total Processed',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_PROCESSED,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_PROCESSED,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Avg Processed',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.PROCESSED_AVERAGE,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.PROCESSED_AVERAGE,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Last Docs With Errors',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_ERRORS,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.LAST_ERRORS,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Total Docs With Errors',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_ERRORS,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_ERRORS,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Avg Docs With Errors',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.ERRORS_AVERAGE,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Total Ingested Within SLA',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_INGESTED_SLA,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Avg Ingested Within SLA',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.INGESTED_SLA_AVERAGE,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Total Ingested Outside SLA',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.TOTAL_INGESTED_OUTSIDE_SLA,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Avg Ingested Outside SLA',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.INGESTED_OUTSIDE_SLA_AVERAGE,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Scraped Agencies',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.SCRAPED_AGENCIES,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Scraped News Sources',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.SCRAPED_NEWS_SOURCES,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Scraped Categories',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.SCRAPED_CATEGORIES,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Relative Complexity',
    dataKey: crawlConstants.CRAWLER_ATTRIBUTES.DEV_COMPLEXITY,
    sortKey: crawlConstants.CRAWLER_ATTRIBUTES.DEV_COMPLEXITY,
    isDynamic: true,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  }
];

export const ROWS_PER_PAGE_OPTIONS: Required<
  ComponentProps<typeof uiLib.Table>
>['rowsPerPageOptions'] = [10, 50, 100, 200, 500, 1000, 2500, 5000];
