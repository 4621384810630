import _ from 'lodash';
import {
  REQUEST_CONTRIBUTOR_REVIEWS_COUNT,
  RECEIVE_CONTRIBUTOR_REVIEWS_COUNT,
  REQUEST_CONTRIBUTOR_POINTS,
  RECEIVE_CONTRIBUTOR_POINTS
} from './contributor.actions';

export const getInitialContributorReviewsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  contributor_reviews_count: {}
});

export const contributor_reviews = (state = getInitialContributorReviewsState(), action) => {
  switch (action.type) {
    case REQUEST_CONTRIBUTOR_REVIEWS_COUNT:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_CONTRIBUTOR_REVIEWS_COUNT:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        contributor_reviews_count: action.contributor_reviews_count,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialContributorPointsState = () => ({
  isFetching: false,
  isReady: false,
  scores: { onboarding: {}, anytime: {}, weekly: {} },
  contributor_point_types: [],
  contributor_points: {}
});

export const contributor_points = (state = getInitialContributorPointsState(), action) => {
  switch (action.type) {
    case REQUEST_CONTRIBUTOR_POINTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_CONTRIBUTOR_POINTS: {
      // group the possible points in an object
      const contributorPointTypes = action.data.contributor_point_types;
      const scores = contributorPointTypes.reduce((mem, pointType) => {
        const group = pointType.frequency;
        if (_.isNil(mem[group])) {
          mem[group] = { possibleTotalPoints: 0, totalPoints: 0 };
        } else {
          mem[group].possibleTotalPoints += pointType.points_per_action;
        }
        const subgroup = pointType.point_group_name;
        if (_.isNil(mem[group][subgroup])) {
          mem[group][subgroup] = { possiblePoints: pointType.points_per_action, points: 0 };
        } else {
          mem[group][subgroup].possiblePoints += pointType.points_per_action;
        }
        return mem;
      }, {});

      // adds points to scores object
      // a table used to look up point type by its id
      const contributorPointTypesTable = contributorPointTypes.reduce((mem, pointType) => {
        mem[pointType.id] = pointType;
        return mem;
      }, {});

      const contributorPoints = action.data.contributor_points;
      Object.keys(contributorPoints).forEach(group => {
        contributorPoints[group].reduce((mem, point) => {
          const pointType = contributorPointTypesTable[point.contributor_point_type_id];
          mem[group].totalPoints += point.num_points;
          mem[group][pointType.point_group_name].points += point.num_points;
          return mem;
        }, scores);
      });
      return {
        ...state,
        isFetching: false,
        isReady: true,
        ...action.data,
        scores
      };
    }
    default:
      return state;
  }
};
