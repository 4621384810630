import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './CaiCategories.constants';
import * as categoryConstants from 'admin/constants/categories';
import * as elements from './elements';

import {
  useCaiCategoriesData,
  useCaiCategoriesHandlers,
  useCaiCategoriesTableConfig
} from './hooks';

import './_cai-categories-management.scss';

export const CaiCategories: FC = () => {
  const { localState, localActions, formattedData } = useCaiCategoriesData();

  const handlers = useCaiCategoriesHandlers({ localState, localActions });

  const tableConfig = useCaiCategoriesTableConfig({
    events: {
      onSplit: handlers.handleSplitCategoryModalOpen,
      onMerge: handlers.handleMergeCategoryModalOpen,
      onEditCaiCategoryModalOpen: handlers.handleEditCategoryModalOpen
    },
    formattedData
  });

  return (
    <uiLib.ErrorBoundary>
      <div className="cai-categories-management__container">
        <div className="cai-categories-management__filters">
          <elements.CategoriesFilters onSubmit={handlers.handleFiltersSubmit} />
        </div>
        <div className="cai-categories-management__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            actions={tableConfig.actions}
            rowKey={categoryConstants.CATEGORY_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleCategoriesFetch}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            areRowsSelectable
            onSelectedRowKeysChange={handlers.handleSetSelectedCategory}
            selectedRowsCountPlacement={uiLib.SELECTED_ROWS_COUNT_PLACEMENT.TOOLBAR}
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_CAI_CATEGORIES_COUNT}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            classNames={{
              container: 'cai-categories-management__table-outer-container',
              tableContainer: 'cai-categories-management__table-inner-container',
              toolbar: 'cai-categories-management__table-toolbar',
              headerCell: 'cai-categories-management__table-header-cell',
              selectedRowsCount: 'cai-categories-management__table-selected-rows-count'
            }}
          />
        </div>
      </div>
      {localState.isEditModalOpen && (
        <elements.CategoryEditModal
          category={localState.categoryToEdit}
          onClose={handlers.handleEditCategoryModalClose}
          onConfirm={handlers.handleEditCategoryModalConfirm}
        />
      )}
      {localState.isSplitModalOpen && (
        <elements.CategorySplitModal
          categoryId={localState.selectedCategories[0]}
          onClose={handlers.handleSplitCategoryModalClose}
          onConfirm={handlers.handleSplitCategoryModalConfirm}
        />
      )}
      {localState.isMergeModalOpen && (
        <elements.CategoryMergeModal
          categoryIds={localState.selectedCategories}
          onClose={handlers.handleMergeCategoryModalClose}
          onConfirm={handlers.handleMergeCategoryModalConfirm}
        />
      )}
    </uiLib.ErrorBoundary>
  );
};
