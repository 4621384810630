import { fetch, BASE_API_OPTIONS } from 'fetch';

export function api_getCaiCategories(params) {
  return fetch({
    url: `/cai-categories/`,
    dataType: 'CAI Categories',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postCaiCategories(params) {
  return fetch({
    url: `/cai-categories/`,
    dataType: 'CAI Categories',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putCaiCategories(params) {
  return fetch({
    url: `/cai-categories/`,
    dataType: 'CAI Categories',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteCaiCategories(params) {
  return fetch({
    url: `/cai-categories/`,
    dataType: 'CAI Categories',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
