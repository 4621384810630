import type { CrawlQAReportsData } from './useCrawlerQAReportsData.types';
import type { GlobalState } from 'shared/reducers';

import * as uiLib from '@compliance.ai/web-components';
import { getUsersEmails } from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useCrawlerQAReportsData = (): CrawlQAReportsData => {
  const params = useParams();

  const [tableParams, setTableParams] = useState<CrawlQAReportsData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: 50
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<CrawlQAReportsData['localState']['reloadTrigger']>(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] =
    useState<CrawlQAReportsData['localState']['isDetailsModalOpen']>(false);
  const [detailsModalInfo, setDetailsModalInfo] =
    useState<CrawlQAReportsData['localState']['detailsModalInfo']>('');
  const [selectedCommandRequest, setSelectedCommandRequest] =
    useState<CrawlQAReportsData['localState']['selectedCommandRequest']>(null);

  const reduxState = useSelector<GlobalState, CrawlQAReportsData['reduxState']>(state => ({
    usersEmails: getUsersEmails(state)
  }));

  const formattedData: CrawlQAReportsData['formattedData'] = useMemo(
    () => ({
      crawlId: String(params?.id)
    }),
    [params?.id]
  );

  const localState: CrawlQAReportsData['localState'] = {
    tableParams,
    reloadTrigger,
    isDetailsModalOpen,
    detailsModalInfo,
    selectedCommandRequest
  };

  const localActions: CrawlQAReportsData['localActions'] = useMemo(
    () => ({
      setTableParams,
      setReloadTrigger,
      setIsDetailsModalOpen,
      setDetailsModalInfo,
      setSelectedCommandRequest
    }),
    []
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
