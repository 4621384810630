import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'userRoles';

export const initialState = {
  roles: []
};

const userRolesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    }
  }
});


export default userRolesReducer.reducer;
export const {
  setRoles
} = userRolesReducer.actions;
