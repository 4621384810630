export const INFO_LABEL = 'Info';
export const ACCOUNT_LABEL = 'Account';
export const SUBSCRIPTION_LABEL = 'Subscription';
export const PREMIUM_CONTENT_ACCESS_LABEL = 'Premium Content Access';
export const TEAMS_LABEL = 'Teams';
export const ORGANIZATION_LABEL = 'Organization';
export const INDUSTRY_LABEL = 'Industry';
export const TITLE_LABEL = 'Title';
export const ROLE_LABEL = 'Role';
export const ADDRESS_LABEL = 'Address';
export const PHONE_LABEL = 'Phone';
export const EMAIL_LABEL = 'Email';
export const DATE_JOINED_LABEL = 'Date Joined';
export const DATE_CONFIRMED_LABEL = 'Date Confirmed';
export const DATE_ACTIVATED_LABEL = 'Date Activated';
export const LAST_LOGIN_LABEL = 'Last Login';
export const PLAN_TIME_LABEL = 'Plan Time';
export const START_DATE_LABEL = 'Start Date';
export const END_DATE_LABEL = 'End Date';
export const PAYMENT_TYPE_LABEL = 'Payment Type';
export const PROVIDER_LABEL = 'Provider';
export const RESET_PASSWORD_LABEL = 'Reset Password';
export const RESEND_CONFIRMATION_EMAIL_LABEL = 'Resend Confirmation Email';
