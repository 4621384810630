import type { EitlLabelRenameModalProps } from '../EitlLabelRenameModal.types';
import type { EitlLabelRenameModalData } from './useEitlLabelRenameModalData.types';
import type { EitlLabelRenameModalFormattedData } from './useEitlLabelRenameModalFormattedData.types';
import type { useEitlLabelRenameModalMutations } from './useEitlLabelRenameModalMutations';

import { useMemo } from 'react';

export const useEitlLabelRenameModalFormattedData = ({
  props,
  localState,
  mutations
}: {
  props: Pick<EitlLabelRenameModalProps, 'label'>;
  localState: EitlLabelRenameModalData['localState'];
  mutations: ReturnType<typeof useEitlLabelRenameModalMutations>;
}): EitlLabelRenameModalFormattedData =>
  useMemo(
    () => ({
      isSubmitButtonDisabled:
        !localState.eitlLabelName ||
        mutations.renameEitlLabel.isLoading ||
        props.label.name === localState.eitlLabelName
    }),
    [localState.eitlLabelName, mutations.renameEitlLabel.isLoading, props.label.name]
  );
