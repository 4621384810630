import moment from 'moment';
import _ from 'lodash';
import { api_getUserById, api_updateUserById } from "./userModal.api";
import {
  changeGeneralTableValues,
  prepareDataForUpdatingUser,
  setInitialUserData
} from "./userModal.reducer";
import { openErrorSnackbar, openInfoSnackbar } from "../snackbar/snackbar.reducer";
import { modifiedCurrentUser } from "../user/user.actions";
import { updateUserSubscriptionByUserId } from "../subscriptions/subscriptions.actions";
import { SUBSCRIPTION_LABEL } from "./labelConstants";

export const SUCCESS_UPDATE_USER = 'User has been updated';
export const ERROR_UPDATE_USER = 'Can not update user data';
export const ERROR_GET_USER_BY_ID = 'Can not get user data';

export const getUserByIdAC = id => async (dispatch) => {
  try {
    const res = await api_getUserById(id);

    const user = res.user;
    dispatch(setInitialUserData(user));
    dispatch(changeGeneralTableValues(user));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR_GET_USER_BY_ID));

    throw e
  }
};

export const updateUserByIdAC = (id) => async (dispatch, getState) => {
  try {
    const currentUserId = getState().current_user.user.id;
    const allPlans = getState().all_plans.items;
    const date = (value) => moment(value).format('YYYY-MM-DD');
    const subscription = {
      expiration_date: '',
      latest: true,
      modified_by_user_id: currentUserId,
      // changing payment type to stripe provides multiply backend errors
      // because backend can not get correct stripe id
      // payment_type: '',
      plan_id: null,
      start_date: '',
      status: "active",
      stripe_id: '',
      user_id: id
    };
    const subscriptionRows = getState()
      .userModal.generalTable.filter(block => block.label === SUBSCRIPTION_LABEL)[0].rows;

    subscriptionRows.forEach(row => row.forEach(field => {
      if (field.responseName === 'start_subscription') subscription.start_date = date(field.value);
      if (field.responseName === 'end_subscription') subscription.expiration_date = date(field.value);
      // changing payment type to stripe provides multiply backend errors
      // because backend can not get correct stripe id
      // if (field.responseName === 'payment_type') subscription.payment_type = field.value;
      if (field.responseName === 'subscription') {
        subscription.stripe_id = field.value.stripe_id;
        subscription.plan_id = field.value.id
          || _.get(allPlans.filter(({ stripe_id }) => stripe_id === field.value.stripe_id)[0], 'id');
      }
    }));
    await dispatch(prepareDataForUpdatingUser());

    const dataForUpdate = getState().userModal.dataForUpdate;

    const res = await api_updateUserById(id, dataForUpdate);

    dispatch(updateUserSubscriptionByUserId(id, subscription));
    dispatch(setInitialUserData(res.user));
    dispatch(changeGeneralTableValues(res.user));
    dispatch(modifiedCurrentUser(res));
    dispatch(openInfoSnackbar(SUCCESS_UPDATE_USER));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR_UPDATE_USER));
    throw e
  }
};
