import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import SourcesHeaderSearchModalBtnBlock from "./SourcesSearchModalBtnBlock";
import { AGENCY_ATTRIBUTES } from "../Sources.constants";
import {
  loadJurisdictionsOptions,
  loadAgencyOptions
} from "../Sources.helper";
import AsyncSelect from "../../../../components/ReactSelect/SelectAsync";

const SourcesHeaderSearchModal = ({
  clearSearch,
  requestAgencies,
  changeFilter,
  search,
  show
}) => {
  return (
    <div className={cn(
      "document-manager_header_search-modal",
      {
        "document-manager_header_search-modal_visible": show
      }
    )}
    >
      <div className="body-search-block">

        <AsyncSelect
          label="Agency"
          value={search[AGENCY_ATTRIBUTES.shortName]}
          onChange={e => changeFilter(AGENCY_ATTRIBUTES.shortName, e)}
          loadOptions={
            value => loadAgencyOptions('short_name', value, 'short_name')
          }
          isMulti
          isClearable
        />
        <AsyncSelect
          label="Jurisdictions"
          value={search[AGENCY_ATTRIBUTES.jurisdiction]}
          onChange={e => changeFilter(AGENCY_ATTRIBUTES.jurisdiction, e)}
          loadOptions={
            value => loadJurisdictionsOptions('short_name', value, 'short_name')
          }
          isMulti
          isClearable
        />
      </div>
      <SourcesHeaderSearchModalBtnBlock
        clearSearch={clearSearch}
        requestAgencies={requestAgencies}
      />
    </div>
  );
};

SourcesHeaderSearchModal.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  requestAgencies: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  search: PropTypes.shape({}),
  show: PropTypes.bool
};

SourcesHeaderSearchModal.defaultProps = {
  search: {},
  show: false
};

export default SourcesHeaderSearchModal;
