import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import OrgTeamMember from './OrgTeamMember';
import { fetchCurrentUser } from 'shared/features/user/user.actions';
import {
  fetchOrganization,
  deleteTeam
} from 'shared/features/organizations/organizations.actions';
import PopupInfo from '../../components/PopupInfo';
import { popupName } from '../../constants/popupInfoConst';
import { ADMIN } from "../../../app/constants/UserRoles";

class OrgTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_team_members: true,
      popupName: '',
      showInfoPopup: false
    };
  }

  toggleTeamMembersVisibility = () => {
    this.setState(state => ({ show_team_members: !state.show_team_members }));
  };

  deleteTeam = () => {
    this.setState({ showInfoPopup: false });
    this.props.deleteTeam(this.props.team.id).then(() => {
      this.props.fetchOrganization(this.props.organization.organization.id);
    });
  };

  confirmDeleteTeam = () => {
    this.setState({
      showInfoPopup: true,
      popupName: popupName.deleteOrganizationTeamPopup
    });
  };

  render() {
    if (!this.props.team) {
      return null;
    }
    const showHide = this.state.show_team_members
      ? (<i className="material-icons">keyboard_arrow_down</i>)
      : (<i className="material-icons">keyboard_arrow_right</i>);
    const infoSeperator = this.state.show_team_members
      ? ' - '
      : ' . ';
    const memberNr = this.props.team.team_members
      ? this.props.team.team_members.length
      : 0;
    const adminNr = this.props.team.team_members
      ? (
        this.props.team.team_members.reduce((number, member) => {
          if (member.team_user_roles.includes(ADMIN)) {
            return number +1;
          }
          return number;
        }, 0))
      : 0;
    const memberLabel = memberNr > 1 ? ' members ' : ' member ';
    const adminLabel = adminNr > 1 ? ' admins ' : ' admin ';
    const active_members = this.props.team.team_members;
    const members_container = this.state.show_team_members
      ? (
        _.orderBy(active_members, ['last_name', 'first_name'], 'desc')
          .map((member, index) => {
            return (
              <OrgTeamMember
                key={member.id}
                member={member}
              />
            );
          }))
      : [];
    return (
      <div className="manage-team">
        <div className="team-info-and-tool">
          <div className="show-hide-team-info">
            <div className="show-hide-members" onClick={this.toggleTeamMembersVisibility}>
              {showHide}
            </div>
            <div className="team-info">
              {this.props.team.name + infoSeperator + memberNr + memberLabel
              + infoSeperator + adminNr + adminLabel}
            </div>
          </div>
          <div className="delete-team" onClick={this.confirmDeleteTeam}>
            <i className="material-icons">delete</i>
          </div>
        </div>
        <div className="members-container">
          {members_container}
        </div>
        <PopupInfo
          show={this.state.showInfoPopup}
          onHide={() => {
            this.setState({ showInfoPopup: false });
          }}
          popupName={this.state.popupName}
          onSecondBtn={this.deleteTeam}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ current_user, organization }) => {
  return { current_user, organization };
};

export default connect(mapStateToProps, {
  fetchCurrentUser,
  fetchOrganization,
  deleteTeam
})(OrgTeam);
