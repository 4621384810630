import PropTypes from 'prop-types';

import Typography from 'admin/components/Typography';
import TextInput from 'admin/components/TextInput';
import Select from 'admin/components/ReactSelect/Select';

import * as constants from './SourceDetails.constants';
import * as uiLib from '@compliance.ai/web-components';
import { SOURCE_TYPES } from 'admin/constants/sources';

import { useMemo } from 'react';

const SourceDetailsGeneral = ({
  editMode,
  toggleEditMode,
  sourceDetails,
  saveChanges,
  handleChanges,
  handleChangesEvent,
  errorFields,
  switchSource
}) => {
  const disableEdit = !editMode;
  const SOURCE_TYPES_OPTIONS = useMemo(
    () =>
      Object.values(SOURCE_TYPES).map(item => ({
        value: item,
        label: item
      })),
    []
  );

  return (
    <div className="generalInfo">
      <div className="generalInfo_header">
        <Typography variant="h5">General info</Typography>
        <div className="generalInfo_header_btnBlock">
          <uiLib.Button onClick={toggleEditMode} type={uiLib.BUTTON_TYPES.SECONDARY}>
            {editMode ? 'Cancel' : 'Edit'}
          </uiLib.Button>
          {editMode && (
            <uiLib.Button onClick={saveChanges} type={uiLib.BUTTON_TYPES.SUCCESS}>
              Save Changes
            </uiLib.Button>
          )}
        </div>
      </div>
      <div className="generalInfo_body">
        {/* First row */}
        <TextInput
          className="input_4"
          label="Name"
          name={constants.SOURCE_GENERAL.name}
          value={sourceDetails[constants.SOURCE_GENERAL.name]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isError={errorFields.includes(constants.SOURCE_GENERAL.name)}
        />
        <TextInput
          className="input_2"
          label="ID"
          isDisabled
          name={constants.SOURCE_GENERAL.id}
          value={sourceDetails[constants.SOURCE_GENERAL.id]}
          onChange={handleChangesEvent}
          isError={errorFields.includes(constants.SOURCE_GENERAL.id)}
        />
        <Select
          className="input_2"
          label="Source Type"
          isDisabled
          value={sourceDetails[constants.SOURCE_GENERAL.source_type]}
          options={SOURCE_TYPES_OPTIONS}
          onChange={switchSource}
        />
      </div>
    </div>
  );
};

SourceDetailsGeneral.propTypes = {
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func.isRequired,
  sourceDetails: PropTypes.shape({}),
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  switchSource: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

SourceDetailsGeneral.defaultProps = {
  editMode: false,
  sourceDetails: {},
  errorFields: []
};

export default SourceDetailsGeneral;
