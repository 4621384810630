import type { EitlLabelsApi } from './useEitlLabelsApi.types';

import * as hookConstants from './useEitlLabelsApi.constants';
import * as api from '../eitlLabels.api';
import * as helpers from '../eitlLabels.api.helpers';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { StatusCodes } from 'http-status-codes';

export const useEitlLabelsApi = (): EitlLabelsApi => {
  const getEitlLabels: EitlLabelsApi['getEitlLabels'] = async params => {
    try {
      const response = await api.getEitlLabels(params);

      return helpers.normalizeEitlLabelsResponse(response);
    } catch (e) {
      errorUtils.logError(e as Error);
      throw e;
    }
  };

  const addEitlLabel: EitlLabelsApi['addEitlLabel'] = async params => {
    try {
      const response = await api.addEitlLabel(params);

      return helpers.normalizeEitlLabel(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      if (isValidationError) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
          title: 'Validation error',
          message: hookConstants.EITL_LABELS_ERRORS_MESSAGES.EITL_LABEL_NAME_VALIDATION
        });
      }

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const modifyEitlLabel: EitlLabelsApi['modifyEitlLabel'] = async ({ id, params }) => {
    try {
      const response = await api.modifyEitlLabel(id, params);

      return helpers.normalizeEitlLabel(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      if (isValidationError) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
          title: 'Validation error',
          message: hookConstants.EITL_LABELS_ERRORS_MESSAGES.EITL_LABEL_NAME_VALIDATION
        });
      }

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const deleteEitlLabel: EitlLabelsApi['deleteEitlLabel'] = async id => {
    try {
      return await api.deleteEitlLabel(id);
    } catch (e) {
      errorUtils.logError(e as Error, 'Error');
      throw e;
    }
  };

  const bulkImportEitlLabels: EitlLabelsApi['bulkImportEitlLabels'] = async file => {
    try {
      const formData = new FormData();

      formData.append('file', file);

      return await api.bulkImportEitlLabels(formData);
    } catch (e) {
      errorUtils.logError(e as Error, 'Error');
      throw e;
    }
  };

  return {
    getEitlLabels,
    addEitlLabel,
    modifyEitlLabel,
    deleteEitlLabel,
    bulkImportEitlLabels
  };
};
