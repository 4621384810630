import type { FC } from 'react';
import type {
  SurfaceInFilterCellProps,
  SurfaceInFilterCellValue
} from './SurfaceInFilterCell.types';

import * as uiLib from '@compliance.ai/web-components';

export const SurfaceInFilterCell: FC<SurfaceInFilterCellProps> = ({ row }) => {
  const surfaceInFilterValue = row.surface_in_filter as SurfaceInFilterCellValue;

  return (
    <uiLib.Typography>
      {surfaceInFilterValue ? <uiLib.IconSuccessRounded color={'primary'} /> : null}
    </uiLib.Typography>
  );
};
