import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import { COLUMNS_CONFIG, DEFAULT_AGENCY_ATTRIBUTES } from '../Sources.constants';
import SortableList from '../../../../components/SortableList';
import Checkbox from '../../../../components/Checkbox';
import Button from '../../../../components/Button';

const SettingsModal = ({ show, onHide, activeColumns, setActiveInfo }) => {
  const [columns, setColumns] = useState(DEFAULT_AGENCY_ATTRIBUTES);
  useEffect(() => setColumns(activeColumns), [activeColumns]);

  const handleColumnChanges = colum => {
    setColumns(
      columns.includes(colum) ? columns.filter(item => item !== colum) : [...columns, colum]
    );
  };

  const saveChanges = () => {
    setActiveInfo(columns);
    onHide();
  };

  return (
    <Modal title="Choose which columns you see" show={show} onHide={onHide}>
      <div className="tableSettingModal_body">
        <div className="tableSettingModal_contentWrapper">
          <div className="tableSettingModal_contentWrapper_chooseColumn">
            {COLUMNS_CONFIG.map(colum => (
              <Checkbox
                key={colum.id}
                label={colum.name}
                onClick={() => handleColumnChanges(colum.id)}
                checked={columns.includes(colum.id)}
                className="crawlMonitorTable_crawlCheckbox"
              />
            ))}
          </div>
          <div className="tableSettingModal_contentWrapper_chooseColumnOrder">
            <SortableList
              label="Selected columns"
              elementClassName="sortableColumnElement"
              list={columns.map(item => COLUMNS_CONFIG.find(col => col.id === item))}
              onChange={sorted => setColumns(sorted.map(item => item.id))}
            />
          </div>
        </div>
        <div>
          <div className="footerBtnBlock">
            <Button color="highlighted" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={saveChanges}>Save</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SettingsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  setActiveInfo: PropTypes.func.isRequired,
  activeColumns: PropTypes.arrayOf(PropTypes.string)
};

SettingsModal.defaultProps = {
  show: false,
  activeColumns: []
};

export default SettingsModal;
