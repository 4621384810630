import type { GlobalState } from 'shared/reducers';
import type { EitlLabelsData, EitlLabelsLocalState } from './useEitlLabelsData.types';

import * as constants from './useEitlLabelsData.constants';
import * as userSelectors from 'shared/features/user/user.selectors';
import * as organizationsSelectors from 'shared/features/organizations/organizations.selectors';
import * as organizationApiHelpers from 'shared/features/organizations/organizations.api.helpers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useEitlLabelsData = (): EitlLabelsData => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    useState<EitlLabelsLocalState['isDeleteModalOpen']>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] =
    useState<EitlLabelsLocalState['isRenameModalOpen']>(false);
  const [isAddModalOpen, setIsAddModalOpen] =
    useState<EitlLabelsLocalState['isAddModalOpen']>(false);
  const [isImportModalOpen, setIsImportModalOpen] =
    useState<EitlLabelsLocalState['isImportModalOpen']>(false);
  const [labelToRename, setLabelToRename] = useState<EitlLabelsLocalState['labelToRename']>(null);
  const [filters, setFilters] = useState<EitlLabelsLocalState['filters']>(
    constants.FILTERS_INITIAL_STATE
  );
  const [refetchTrigger, setRefetchTrigger] =
    useState<EitlLabelsLocalState['refetchTrigger']>(false);
  const [labelToDelete, setLabelToDelete] = useState<EitlLabelsLocalState['labelToDelete']>(null);
  const [searchValue, setSearchValue] = useState<EitlLabelsLocalState['searchValue']>('');
  const [params, setParams] = useState<EitlLabelsLocalState['params']>(
    constants.EITL_LABELS_TABLE_INITIAL_PARAMS
  );
  const [labels, setLabels] = useState<EitlLabelsLocalState['labels']>([]);

  const localState: EitlLabelsData['localState'] = {
    isDeleteModalOpen,
    isRenameModalOpen,
    isAddModalOpen,
    isImportModalOpen,
    labelToRename,
    filters,
    refetchTrigger,
    labelToDelete,
    searchValue,
    params,
    labels
  };

  const localActions: EitlLabelsData['localActions'] = useMemo(
    () => ({
      setIsDeleteModalOpen,
      setIsRenameModalOpen,
      setIsAddModalOpen,
      setIsImportModalOpen,
      setLabelToRename,
      setFilters,
      setRefetchTrigger,
      setLabelToDelete,
      setSearchValue,
      setParams,
      setLabels
    }),
    []
  );

  const reduxState: EitlLabelsData['reduxState'] = useSelector<
    GlobalState,
    EitlLabelsData['reduxState']
  >(state => ({
    currentUserId: userSelectors.getCurrentUserId(state),
    currentUserOrgId: userSelectors.getCurrentUserOrgId(state),
    isOrganizationLoading: organizationsSelectors.getOrganizationIsLoading(state),
    organizationMembers: organizationsSelectors.getOrganizationMembers(state)
  }));

  const formattedData: EitlLabelsData['formattedData'] = useMemo(
    () => ({
      orgMembersOptions: reduxState.organizationMembers.map(
        organizationApiHelpers.getOrgMemberOption
      )
    }),
    [reduxState.organizationMembers]
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
