import React, { useMemo } from 'react';
import _ from 'lodash';
import SettingsIcon from '@material-ui/icons/Settings';
import { COLUMNS_CONFIG } from '../Sources.constants';
import HeaderSort from '../../../../components/SortElements/HeaderSort';

const getAgencyHeader = ({ sort, changeFilter, activeInfo, openSettings }) => {
  const activeColumns = activeInfo.map(item => COLUMNS_CONFIG.find(col => col.id === item));
  const formatInfo = activeColumns.map(item => (
    <HeaderSort
      type={_.get(sort, [item.id, 'sorting_direction'], null)}
      onClick={() => changeFilter(item.id)}
      item={item}
    />
  ));
  return [
    // settings for checkbox
    ...formatInfo,
    // this for settings column
    <SettingsIcon onClick={openSettings} />
  ];
};

export default ({ sort, changeFilter, activeInfo, openSettings }) => {
  return useMemo(
    () => [
      getAgencyHeader({ sort, activeInfo, changeFilter, openSettings }) //changeFilter
    ],
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, activeInfo]
  );
};
