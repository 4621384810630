import { useMemo } from "react";
import _ from 'lodash';
import { TABLE_VARIABLES } from "./JurisdictionTool.constants";

const getJurisdictionRowInfo = ({
  jurisdiction,
  jurisdictionInfo
}) => {
  return jurisdictionInfo.map(field => {
    const fieldValue = _.get(jurisdiction, field, null);
    switch (field) {
      case TABLE_VARIABLES.active:
        return String(fieldValue);
      default:
        return fieldValue;
    }
  });
};

export default ({
  jurisdictionFormat,
  jurisdictionInfo
}) => {
  return useMemo(
    () => jurisdictionFormat.map(jurisdiction => getJurisdictionRowInfo({
      jurisdiction,
      jurisdictionInfo
    })),
    [jurisdictionFormat, jurisdictionInfo]
  );
};
