import { TABLE_VARIABLES } from '../../../CrawlActivityTable.constants';
import { DATE_FORMAT_SEARCH } from '../Filters.constants';

import * as lodash from 'lodash';
import moment from 'moment/moment';

/**
 *
 * TODO Refactor the whole file
 *
 */

// GAP Selectors helpers (necessary to keep naming logic in one place)
export const getStartName = (name: string) => `${name}_start`;
export const getEndName = (name: string) => `${name}_end`;

// GAP Time helpers (necessary to keep naming logic in one place)
export const getStartDate = (name: string) => `${name}_start`;
export const getEndDate = (name: string) => `${name}_end`;

export const formatFilterValues = (
  searchValues: Record<string, unknown>
): Record<string, unknown> => {
  const formatParameters = {};

  for (const key in searchValues) {
    if (Object.prototype.hasOwnProperty.call(searchValues, key)) {
      let value = null;

      switch (key) {
        case TABLE_VARIABLES.run_type:
          value = lodash.get(searchValues, [key, 'value'], null);
          break;
        case getEndName(TABLE_VARIABLES.docs_ingested):
        case getEndName(TABLE_VARIABLES.docs_updated):
        case getEndName(TABLE_VARIABLES.docs_skipped):
        case getEndName(TABLE_VARIABLES.docs_failed):
        case getEndName(TABLE_VARIABLES.docs_processed):
        case getStartName(TABLE_VARIABLES.docs_ingested):
        case getStartName(TABLE_VARIABLES.docs_updated):
        case getStartName(TABLE_VARIABLES.docs_skipped):
        case getStartName(TABLE_VARIABLES.docs_failed):
        case getStartName(TABLE_VARIABLES.docs_processed):
          value = lodash.get(searchValues, key, null);
          break;
        case getStartDate(TABLE_VARIABLES.run_start):
        case getEndDate(TABLE_VARIABLES.run_start): {
          const time = lodash.get(searchValues, key, null);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value = time && moment(time).format(DATE_FORMAT_SEARCH);
          break;
        }
        default:
          break;
      }

      if (lodash.isBoolean(value) || value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatParameters[key] = value;
      }
    }
  }
  return formatParameters;
};
