import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import Typography from '../../components/Typography';
import Configuration from './SpecElements/Configuration';
import CategoryConversion from './SpecElements/CategoryConversion';
import StepsList from './SpecElements/StepsList';
import Step from './SpecElements/Step';
import Context from './SpecElements/Context';
import Document from './SpecElements/Document';
import Action from './SpecElements/Action';
import Relation from './SpecElements/Relation';
import ResetCounter from './SpecElements/ResetCounter';
import MetaData from './SpecElements/MetaData';
import ExtractTags from "./SpecElements/ExtractTags";
import Location from "./SpecElements/Location";
import Path from "./SpecElements/Path";
import Example from './SpecElements/Example';
import { createNewExample, createNewPath } from "./CrawlSpecCreate.helper";
import {
  checkConfiguration,
  checkCategoryConversion,
  checkStep,
  checkContext,
  checkDoc,
  checkAction,
  checkRelation,
  checkResetCounter,
  checkMetaData,
  checkLocation,
  checkExtractTags,
  checkPath,
  checkExample
} from "./CrawlSpecCreate.helper.error";
import { CODE_FILTER_EXTRA_OPTION, NAMES_META_DATA } from "./CrawlSpecCreate.constants";
import CodeFilterModal from "./SpecElements/CodeFilterModal";

const CrawlSpecCreateConfig = ({
  editMode,
  errorFields,
  setErrorFields,
  // element
  config,
  setConfig,
  categoryConversion,
  setCategoryConversion,
  steps,
  setSteps,
  contexts,
  setContexts,
  docs,
  setDocs,
  actions,
  setActions,
  relations,
  setRelations,
  resetCounters,
  setResetCounters,
  metaData,
  setMetaData,
  locations,
  setLocations,
  extractTags,
  setExtractTags,
  paths,
  setPaths,
  examples,
  setExample,
  crawlSpecOptions
}) => {
  const disableEdit = !editMode;
  const [fold, setFold] = useState(false);
  const [showCodeFilterModal, setShowCodeFilterModal] = useState(false);
  const [showSteps, setShowSteps] = useState(true);
  const [showCategoryConversion, setShowCategoryConversion] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentContext, setCurrentContext] = useState(null);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentRelation, setCurrentRelation] = useState(null);
  const [currentResetCounter, setCurrentResetCounter] = useState(null);
  const [currentMetaData, setCurrentMetaData] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentExtractTag, setCurrentExtractTag] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);
  const [currentExample, setCurrentExample] = useState(null);

  const getCurrentUUID = () => ({
    steps: showSteps,
    step: currentStep,
    context: currentContext,
    doc: currentDoc,
    action: currentAction,
    relation: currentRelation,
    resetCounter: currentResetCounter,
    metaData: currentMetaData,
    location: currentLocation,
    extractTags: currentExtractTag,
    path: currentPath,
    example: currentExample
  });

  // CLEAR FUNCTIONS

  const clearExampleElements = exampleID => {
    if (!exampleID || currentExample === exampleID) {
      setCurrentExample(null);
    }
  };

  const clearPathElements = pathID => {
    if (!pathID || currentPath === pathID) {
      clearExampleElements();
      setCurrentPath(null);
    }
  };

  const clearLocationElements = locationID => {
    if (!locationID || currentLocation === locationID) {
      clearExampleElements();
      setCurrentLocation(null);
    }
  };

  const clearExtractTagsElements = extractTagsID => {
    if (!extractTagsID || currentExtractTag === extractTagsID) {
      clearExampleElements();
      setCurrentExtractTag(null);
    }
  };

  const clearMetaDataElements = ID => {
    if (!ID || currentMetaData === ID || currentExtractTag === ID) {
      clearExampleElements();
      setCurrentMetaData(null);
      clearLocationElements(null);
      clearExtractTagsElements(null);
      clearPathElements(null);
    }
  };

  const clearActionElements = actionID => {
    if (!actionID || currentAction === actionID) {
      clearExampleElements();
      setCurrentAction(null);
    }
  };

  const clearRelationElements = relationID => {
    if (!relationID || currentRelation === relationID) {
      setCurrentRelation(null);
    }
  };

  const clearStepElements = ID => {
    if (!ID || [currentContext, currentDoc, currentResetCounter].includes(ID)) {
      clearActionElements();
      clearMetaDataElements();
      setCurrentContext(null);
      setCurrentDoc(null);
      setCurrentResetCounter(null);
    }
  };

  const clearAllSteps = stepID => {
    if (!stepID || stepID === currentStep) {
      clearStepElements();
      setCurrentStep(null);
    }
  };

  // CHANGE UUID FUNCTIONS

  const changeCategoryConversion = open => {
    if (open) {
      clearAllSteps();
      setShowSteps(false);
      setShowCategoryConversion(true);
    }
  };

  const changeSteps = open => {
    if (open) {
      clearAllSteps();
      setShowSteps(true);
      setShowCategoryConversion(false);
    }
  };

  const changeCurrentStep = step => {
    // we set id, not whole step because we should up-to-date updates in steps
    clearStepElements();
    setCurrentStep(step.id);
  };

  const changeCurrentContext = contextUUID => {
    clearMetaDataElements();
    setCurrentContext(contextUUID);
    setCurrentRelation(null);
    setCurrentResetCounter(null);
    setCurrentDoc(null);
    setCurrentAction(null);
  };

  const changeCurrentDoc = docUUID => {
    clearMetaDataElements();
    setCurrentDoc(docUUID);
    setCurrentRelation(null);
    setCurrentResetCounter(null);
    setCurrentContext(null);
    setCurrentAction(null);
  };

  const changeCurrentAction = actionUUID => {
    clearMetaDataElements();
    setCurrentAction(actionUUID);
    setCurrentRelation(null);
    setCurrentResetCounter(null);
    setCurrentContext(null);
    setCurrentDoc(null);
  };

  const changeCurrentRelation = relationUUID => {
    clearMetaDataElements();
    setCurrentRelation(relationUUID);
    setCurrentResetCounter(null);
    setCurrentAction(null);
    setCurrentContext(null);
    setCurrentDoc(null);
  };

  const changeCurrentResetCounter = resetCounterUUID => {
    clearMetaDataElements();
    setCurrentResetCounter(resetCounterUUID);
    setCurrentRelation(null);
    setCurrentAction(null);
    setCurrentContext(null);
    setCurrentDoc(null);
  };

  const changeCurrentMetaData = metaDataEl => {
    const metaDataUUID = metaDataEl.id;
    clearLocationElements();
    clearExtractTagsElements();
    setCurrentMetaData(metaDataUUID);
  };

  const changeCurrentLocation = locationUUID => {
    clearPathElements();
    clearExtractTagsElements();
    setCurrentLocation(locationUUID);
  };

  const changeCurrentExtractTags = extractTagsUUID => {
    clearPathElements();
    clearLocationElements();
    setCurrentExtractTag(extractTagsUUID);
  };

  const changeCurrentPath = (pathUUID, clearExtractTags = true) => {
    if (clearExtractTags) {
      clearExtractTagsElements();
    } else {
      clearExampleElements();
    }
    clearPathElements();
    setCurrentPath(pathUUID);
  };

  const changeCurrentExample = exampleUUID => {
    setCurrentExample(exampleUUID);
  };

  // CHANGE WHOLE ELEMENTS FUNCTIONS

  const changeConfigurationField = (name, value) => {
    setErrorFields(checkConfiguration({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setConfig({
      ...config,
      [name]: value
    });
  };

  const changeCategoryConversionField = (name, value) => {
    setErrorFields(checkCategoryConversion({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setCategoryConversion({
      ...categoryConversion,
      [name]: value
    });
  };

  const changeCurrentStepField = (name, value) => {
    setErrorFields(checkStep({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setSteps(
      steps.map(
        item => item.id === currentStep
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentContextField = (name, value) => {
    setErrorFields(checkContext({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setContexts(
      contexts.map(
        item => item.id === currentContext
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentDocField = (name, value) => {
    setErrorFields(checkDoc({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setDocs(
      docs.map(
        item => item.id === currentDoc
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentActionField = (name, value) => {
    setErrorFields(checkAction({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setActions(
      actions.map(
        item => item.id === currentAction
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentRelationField = (name, value) => {
    setErrorFields(checkRelation({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setRelations(
      relations.map(
        item => item.id === currentRelation
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentResentCounterField = (name, value) => {
    setErrorFields(checkResetCounter({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setResetCounters(
      resetCounters.map(
        item => item.id === currentResetCounter
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentMetaDataField = (name, value) => {
    if (
      name === NAMES_META_DATA.CODE_FILTER
      && value
      && value.value === CODE_FILTER_EXTRA_OPTION.value
    ) {
      setShowCodeFilterModal(true);
      return;
    }
    setErrorFields(checkMetaData({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setMetaData(
      (prev) => prev.map(
        item => item.id === currentMetaData
          ? {
            ...item,
            [name]: value
          }
          : item
      )
    );
  };

  const changeCurrentLocationField = (name, value) => {
    setErrorFields(checkLocation({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setLocations(
      locations.map(
        item => item.id === currentLocation
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentExtractTagsField = (name, value) => {
    setErrorFields(checkExtractTags({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setExtractTags(
      extractTags.map(
        item => item.id === currentExtractTag
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentPathField = (name, value) => {
    setErrorFields(checkPath({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setPaths(
      paths.map(
        item => item.id === currentPath
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  const changeCurrentExampleField = (name, value) => {
    setErrorFields(checkExample({
      errorFields,
      currentUUID: getCurrentUUID(),
      fieldName: name
    }));
    setExample(
      examples.map(
        item => item.id === currentExample
          ? { ...item, [name]: value }
          : item
      )
    );
  };

  // Add new elements helpers
  // new element helpers that used only once moved to the target components
  // e.g. (context, actions, metadata, docs, steps and etc.)

  const addNewExample = () => {
    const newExample = createNewExample();
    setExample([...examples, newExample]);
    return newExample.id;
  };

  const addNewPath = () => {
    const newPath = createNewPath();
    setPaths([...paths, newPath]);
    return newPath.id;
  };

  return (
    <div className="crawl-spec_section crawl-spec_config">
      <div className="crawl-spec_section_head">
        <Typography variant="h3">{`${fold ? '-' : '+'}`}</Typography>
        <Typography
          variant="h3"
          onClick={() => setFold(!fold)}
        >
          Spec Configuration
        </Typography>
      </div>
      <div
        className={cn(
          'crawl-spec_section_body crawl-spec_config_body',
          { 'crawl-spec_section_body_fold': fold }
        )}
      >
        {/* Order of elements is important */}
        <Configuration
          show
          disabled={disableEdit}
          showSteps={showSteps}
          changeSteps={changeSteps}
          setShowSteps={setShowSteps}
          element={config}
          steps={steps}
          changeConfiguration={changeConfigurationField}
          changeStepsFiled={setSteps}
          showCategoryConversion={showCategoryConversion}
          changeCategoryConversion={changeCategoryConversion}
          categoryConversion={categoryConversion}
          setCategoryConversion={setCategoryConversion}
          errorFields={errorFields}
        />
        <CategoryConversion
          show={showCategoryConversion}
          disabled={disableEdit}
          element={categoryConversion || {}}
          changeCategoryConversionField={changeCategoryConversionField}
          errorFields={_.get(errorFields, 'categoryConversion', [])}
        />
        <StepsList
          show={showSteps}
          currentUUID={currentStep}
          disabled={disableEdit}
          changeElements={setSteps}
          element={steps}
          changeCurrentStep={changeCurrentStep}
          clearAllSteps={clearAllSteps}
          errorFields={_.get(errorFields, 'steps', {})}
        />
        <Step
          show={!!currentStep}
          currentContextUUID={currentContext}
          currentDocUUID={currentDoc}
          currentActionUUID={currentAction}
          currentRelationUUID={currentRelation}
          currentResetCounterUUID={currentResetCounter}
          disabled={disableEdit}
          element={steps.find(item => item.id === currentStep) || {}}
          steps={steps}
          contexts={contexts}
          docs={docs}
          actions={actions}
          relations={relations}
          resetCounters={resetCounters}
          changeStep={changeCurrentStepField}
          changeContext={setContexts}
          changeCurrentContext={changeCurrentContext}
          changeCurrentDoc={changeCurrentDoc}
          changeCurrentAction={changeCurrentAction}
          changeCurrentRelation={changeCurrentRelation}
          changeCurrentResetCounter={changeCurrentResetCounter}
          changeDoc={setDocs}
          changeAction={setActions}
          changeRelation={setRelations}
          changeResetCounter={setResetCounters}
          clearStepElements={clearStepElements}
          clearActionElements={clearActionElements}
          clearRelationElements={clearRelationElements}
          errorFieldsActions={_.get(errorFields, 'actions', {})}
          errorFieldsRelations={_.get(errorFields, 'relations', {})}
          errorFields={_.get(errorFields, ['steps', currentStep], [])}
        />
        <Context
          show={!!currentContext}
          currentPathUUID={currentPath}
          disabled={disableEdit}
          element={contexts.find(item => item.id === currentContext) || {}}
          changeContext={changeCurrentContextField}
          changeCurrentPath={changeCurrentPath}
          addNewPath={addNewPath}
          clearPathElements={clearPathElements}
          errorFieldsPaths={_.get(errorFields, 'paths', {})}
          errorFields={_.get(errorFields, ['contexts', currentContext], [])}
        />
        <Document
          show={!!currentDoc}
          currentMetaDataUUID={currentMetaData}
          disabled={disableEdit}
          element={docs.find(item => item.id === currentDoc) || {}}
          changeCurrentDocField={changeCurrentDocField}
          metaData={metaData}
          changeMetaData={setMetaData}
          changeCurrentMetaData={changeCurrentMetaData}
          clearMetaDataElements={clearMetaDataElements}
          errorFieldsMetaData={_.get(errorFields, 'metaData', {})}
          errorFields={_.get(errorFields, ['docs', currentDoc], [])}
        />
        <Action
          show={!!currentAction}
          currentExampleUUID={currentExample}
          disabled={disableEdit}
          element={actions.find(item => item.id === currentAction) || {}}
          changeAction={changeCurrentActionField}
          changeCurrentExample={changeCurrentExample}
          addNewExample={addNewExample}
          clearExampleElements={clearExampleElements}
          errorFieldsExamples={_.get(errorFields, 'examples', {})}
          errorFields={_.get(errorFields, ['actions', currentAction], [])}
        />
        <Relation
          show={!!currentRelation}
          disabled={disableEdit}
          element={relations.find(item => item.id === currentRelation) || {}}
          changeRelation={changeCurrentRelationField}
          errorFields={_.get(errorFields, ['relations', currentRelation], [])}
        />
        <ResetCounter
          show={!!currentResetCounter}
          disabled={disableEdit}
          steps={steps}
          element={resetCounters.find(item => item.id === currentResetCounter) || {}}
          changeResetCounter={changeCurrentResentCounterField}
          errorFields={_.get(errorFields, ['resetCounters', currentResetCounter], [])}
        />
        <MetaData
          show={!!currentMetaData}
          currentPathUUID={currentPath}
          currentExtractTagsUUID={currentExtractTag}
          currentLocationUUID={currentLocation}
          disabled={disableEdit}
          element={metaData.find(item => item.id === currentMetaData) || {}}
          extractTags={extractTags}
          locations={locations}
          changeMetaData={changeCurrentMetaDataField}
          changeCurrentPath={changeCurrentPath}
          changeCurrentExtractTags={changeCurrentExtractTags}
          changeCurrentLocation={changeCurrentLocation}
          addNewPath={addNewPath}
          changeExtractTags={setExtractTags}
          changeLocation={setLocations}
          clearPathElements={clearPathElements}
          clearLocationElements={clearLocationElements}
          clearMetaDataElements={clearMetaDataElements}
          errorFieldsPaths={_.get(errorFields, 'paths', {})}
          errorFieldsLocations={_.get(errorFields, 'locations', {})}
          errorFields={_.get(errorFields, ['metaData', currentMetaData], [])}
          crawlSpecOptions={crawlSpecOptions}
        />
        <Location
          show={!!currentLocation}
          disabled={disableEdit}
          element={locations.find(item => item.id === currentLocation) || {}}
          changeLocation={changeCurrentLocationField}
          errorFields={_.get(errorFields, ['locations', currentLocation], [])}
        />
        <ExtractTags
          show={!!currentExtractTag}
          currentPathUUID={currentPath}
          disabled={disableEdit}
          element={extractTags.find(item => item.id === currentExtractTag) || {}}
          changeExtractTags={changeCurrentExtractTagsField}
          changeCurrentPath={uuid => changeCurrentPath(uuid, false)}
          addNewPath={addNewPath}
          clearPathElements={clearPathElements}
          errorFieldsPaths={_.get(errorFields, 'paths', {})}
          errorFields={_.get(errorFields, ['extractTags', currentExtractTag], [])}
        />
        <Path
          show={!!currentPath}
          currentExampleUUID={currentExample}
          disabled={disableEdit}
          element={paths.find(item => item.id === currentPath) || {}}
          changePath={changeCurrentPathField}
          changeCurrentExample={changeCurrentExample}
          addNewExample={addNewExample}
          clearExampleElements={clearExampleElements}
          errorFieldsExamples={_.get(errorFields, 'examples', {})}
          errorFields={_.get(errorFields, ['paths', currentPath], [])}
        />
        <Example
          show={!!currentExample}
          disabled={disableEdit}
          element={examples.find(item => item.id === currentExample) || {}}
          changeExample={changeCurrentExampleField}
          errorFields={_.get(errorFields, ['examples', currentExample], [])}
        />
      </div>
      <CodeFilterModal
        show={showCodeFilterModal}
        onHide={() => setShowCodeFilterModal(false)}
        saveCodeFilter={
          (codeFilterValue) => {
            changeCurrentMetaDataField(
              NAMES_META_DATA.CODE_FILTER, {
                value: codeFilterValue,
                label: CODE_FILTER_EXTRA_OPTION.label
              }
            );
            setShowCodeFilterModal(false);
          }
        }
      />
    </div>
  );
};

CrawlSpecCreateConfig.propTypes = {
  editMode: PropTypes.bool,
  errorFields: PropTypes.shape({}),
  setErrorFields: PropTypes.func.isRequired,
  // elements
  config: PropTypes.shape({}),
  setConfig: PropTypes.func.isRequired,
  categoryConversion: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.oneOf([null])
  ]),
  setCategoryConversion: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  setSteps: PropTypes.func.isRequired,
  contexts: PropTypes.arrayOf(PropTypes.shape({})),
  setContexts: PropTypes.func.isRequired,
  docs: PropTypes.arrayOf(PropTypes.shape({})),
  setDocs: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  setActions: PropTypes.func.isRequired,
  relations: PropTypes.arrayOf(PropTypes.shape({})),
  setRelations: PropTypes.func.isRequired,
  resetCounters: PropTypes.arrayOf(PropTypes.shape({})),
  setResetCounters: PropTypes.func.isRequired,
  metaData: PropTypes.arrayOf(PropTypes.shape({})),
  setMetaData: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  setLocations: PropTypes.func.isRequired,
  extractTags: PropTypes.arrayOf(PropTypes.shape({})),
  setExtractTags: PropTypes.func.isRequired,
  paths: PropTypes.arrayOf(PropTypes.shape({})),
  setPaths: PropTypes.func.isRequired,
  examples: PropTypes.arrayOf(PropTypes.shape({})),
  setExample: PropTypes.func.isRequired,
  crawlSpecOptions: PropTypes.shape({})
};

CrawlSpecCreateConfig.defaultProps = {
  editMode: false,
  errorFields: {},
  config: {},
  categoryConversion: null,
  steps: [],
  contexts: [],
  docs: [],
  actions: [],
  relations: [],
  resetCounters: [],
  metaData: [],
  locations: [],
  extractTags: [],
  paths: [],
  examples: [],
  crawlSpecOptions: {}
};

export default CrawlSpecCreateConfig;
