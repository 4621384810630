import type { CrawlerCatalogHandlers } from './useCrawlerCatalogHandlers.types';

import { useEffect } from 'react';

export const useCrawlerCatalogLifecycle = ({
  onInitialCrawlConfigChange
}: {
  onInitialCrawlConfigChange: CrawlerCatalogHandlers['handleInitialCrawlConfigChange'];
}) => {
  useEffect(onInitialCrawlConfigChange, [onInitialCrawlConfigChange]);
};
