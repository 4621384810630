import React, {useMemo} from 'react';
import PropTypes from "prop-types";
import { DEFAULT_TABLE_COLUMNS } from "./JurisdictionTool.constants";
import jurisdictionTableHeaderCells from './headerCells';
import jurisdictionTableBodyCells from './bodyCells';
import { getJurisdictionInfo} from './JurisdictionTool.helper';
import CustomTable from '../../components/Table';

const JurisdictionToolTable = ({
  jurisdictions,
  setJurisdictionModal
}) => {
  const jurisdictionFormat = useMemo(
    () => jurisdictions.map(getJurisdictionInfo), [jurisdictions]
  );

  return (
    <CustomTable
      className="jurisdiction_table"
      header={jurisdictionTableHeaderCells({
        jurisdictionInfo: DEFAULT_TABLE_COLUMNS
      })}
      data={jurisdictionTableBodyCells({
        jurisdictionFormat,
        jurisdictionInfo: DEFAULT_TABLE_COLUMNS
      })}
      onClick={setJurisdictionModal}
    />
  );
};

JurisdictionToolTable.propTypes = {
  jurisdictions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  setJurisdictionModal: PropTypes.func.isRequired
};

JurisdictionToolTable.defaultProps = {
  jurisdictions: []
};

export default JurisdictionToolTable;
