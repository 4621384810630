import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_fetchSlotInfoForDoc(docType, slot, docId) {
  return fetch({
    url: `/slot_tool/${docType}/${slot}/${docId}`,
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_SLOT,
    dataType: 'SlotInfo'
  });
}
