import type { FiltersData } from './useFiltersData.types';

import * as lodash from 'lodash';

export const getFiltersButtonText = (filters: FiltersData['localState']['filters']) => {
  if (lodash.isEmpty(filters)) {
    return 'Filters';
  }

  return `Filters · ${Object.keys(filters).length}`;
};
