import type { CategoriesFiltersHandlers } from './useCategoriesFiltersHandlers.types';
import type { CategoriesFiltersProps } from '../CategoriesFilters.types';

import * as helpers from './useCategoriesFiltersHandlers.helpers';

export const useCategoriesFiltersHandlers = ({
  props
}: {
  props: Pick<CategoriesFiltersProps, 'onSubmit'>;
}): CategoriesFiltersHandlers => {
  const handleFiltersSubmit: CategoriesFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatCategoriesQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
