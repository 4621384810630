import {
  createCommentAC as reduxCreateCommentAC,
  clearCrawComments as reduxClearCrawComments,
  getCommentAC as reduxGetCommentAC
} from '../crawlerComments.actions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useCrawlerCommentsReduxActions = () => {
  const dispatch = useDispatch();

  const createCommentAC = useCallback(
    (...params) => {
      return dispatch(reduxCreateCommentAC(...params));
    },
    [dispatch]
  );

  const getCommentAC = useCallback(
    (...params) => {
      return dispatch(reduxGetCommentAC(...params));
    },
    [dispatch]
  );

  const clearCrawComments = useCallback(() => {
    return dispatch(reduxClearCrawComments());
  }, [dispatch]);

  return useMemo(
    () => ({
      createCommentAC,
      clearCrawComments,
      getCommentAC
    }),
    [createCommentAC, clearCrawComments, getCommentAC]
  );
};
