import type { FC } from 'react';
import type { EditCrawlConfigModalProps } from './EditCrawlConfigModal.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { useEditCrawlConfigModalData, useEditCrawlConfigModalHandlers } from './hooks';

import './_edit-crawl-config-modal.scss';

export const EditCrawlConfigModal: FC<EditCrawlConfigModalProps> = ({
  onSubmit,
  onClose,
  initialCrawlConfig,
  crawlConfig,
  onCrawlConfigChange,
  isEditDisabled
}) => {
  const { formattedData } = useEditCrawlConfigModalData({
    props: {
      initialCrawlConfig,
      crawlConfig,
      isEditDisabled
    }
  });

  const handlers = useEditCrawlConfigModalHandlers({
    props: {
      initialCrawlConfig,
      onCrawlConfigChange,
      onClose
    }
  });

  return (
    <uiLib.Modal
      isOpen
      className="edit-crawl-config-modal"
      withFooter
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Save changes"
      title={formattedData.modalTitle}
      onClose={handlers.handleClose}
      onSubmit={onSubmit}
      shouldShowFullscreenButton
    >
      <div className="edit-crawl-config-modal__content-container">
        <uiLib.CodeEditor
          value={crawlConfig}
          onChange={onCrawlConfigChange}
          isReadOnly={formattedData.isEditorReadOnly}
        />
      </div>
    </uiLib.Modal>
  );
};
