import { fetch } from 'shared/utils/api';

export const api_checkEmail = ({ params }) => {
  return fetch({
    url: `/check_email?email=${params.email}`,
    method: 'GET'
  });
};

export const api_resetPassword = (email) => {
  return fetch({
    url: `/reset_password`,
    method: 'POST',
    params: { email }
  });
};
