import * as lodash from 'lodash';
import ReactGA from 'react-ga';
import { fetch, AUTHENTICATION_OPTIONS_KEYS, MIXPANEL_URL } from 'fetch';
import * as env from '../config';
import * as usageMonitoringUtils from '../../app/utils/usageMonitoring';
import * as localStorageUtils from '../../app/utils/localStorage-utils';
import * as uiLib from '@compliance.ai/web-components';

export const initializeGoogleAnalytics = () => {
  ReactGA.initialize(env.googleAnalyticsCode);
};

export const setGoogleAnalyticsDimension = ({ dimensionKey, dimensionValue }) => {
  ReactGA.set({ [dimensionKey]: dimensionValue });
};

export const sendGoogleAnalyticsPageView = ({ path, trackerNames, title }) => {
  ReactGA.pageview(path, trackerNames, title);
};

export const sendGoogleAnalyticsException = ({ description, isFatal }) => {
  ReactGA.exception({
    description,
    fatal: Boolean(isFatal)
  });
};

export const sendGoogleAnalyticsEvent = ({ category, action, label, value }) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

export function safe_ga(analyticsAction, analyticsType, ...params) {
  if (analyticsAction === 'set') {
    setGoogleAnalyticsDimension({
      dimensionKey: analyticsType,
      dimensionValue: params[0]
    });
  }

  if (analyticsAction === 'send') {
    if (analyticsType === 'event') {
      sendGoogleAnalyticsEvent({
        category: params[0],
        action: params[1],
        label: params[2],
        value: params[3]
      });
    }
  }

  return true;
}

export function submit_timing(start_time, category, variable, label) {
  if (!start_time) {
    return false;
  }

  const value = Date.now() - start_time;

  ReactGA.timing({
    category,
    variable,
    value,
    label
  });

  return true;
}

export function safe_mixpanel_track(title, properties) {
  usageMonitoringUtils.incrementActivityUse();

  if (!env.mixPanelEnabled) return true;

  const mxp_distinct_id = localStorage.getItem('mxp_distinct_id');
  let uuid = null;

  if (!mxp_distinct_id || mxp_distinct_id === 'null') {
    uuid = uiLib.getRandomId();
    localStorageUtils.safelySetLocalStorageData('mxp_distinct_id', uuid);
  }

  const params = lodash.extend({}, properties, _event_properties()); // eslint-disable-line

  fetch({
    url: MIXPANEL_URL,
    method: 'POST',
    params: { distinct_id: uuid ?? mxp_distinct_id, title, properties: params },
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY
  });

  return true;
}

export function mixpanel_people_set(properties) {
  if (!env.mixPanelEnabled) return true;

  if (!localStorage.mxp_distinct_id || localStorage.mxp_distinct_id === 'null') {
    const uuid = uiLib.getRandomId();
    localStorage.setItem('mxp_distinct_id', uuid);
  }

  const distinct_id = localStorage.mxp_distinct_id;

  fetch({
    url: '/mxp/people_set',
    method: 'POST',
    params: { distinct_id, properties },
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY
  });
}

export function mixpanel_identify(id) {
  if (id) {
    localStorage.setItem('mxp_distinct_id', id);
  }
}

// Only for events
// undefined key values are ignored in mixpanel
export function safe_analytics(title, ...args) {
  if (env.appType !== 'web') return '';

  const params = [...args];
  title = title === 'default' ? params.join(' – ') : title;

  const mxp_properties = {
    hitType: 'event',
    eventCategory: params[0],
    eventAction: params[1],
    eventLabel: params[2]
  };

  const ga_success = safe_ga('send', 'event', ...params);
  const mxp_success = safe_mixpanel_track(title, mxp_properties);

  return ga_success && mxp_success;
}

/*------------------- Helper functions adapted from mixpanel js library ------------------- */

function _strip_empty_properties(p) {
  const ret = {};
  lodash.forEach(p, (v, k) => {
    if (lodash.isString(v) && v.length > 0) {
      ret[k] = v;
    }
  });
  return ret;
}

function _os() {
  const a = window.navigator.userAgent;
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return 'Windows Phone';
    }
    return 'Windows';
  }
  if (/(iPhone|iPad|iPod)/.test(a)) {
    return 'iOS';
  }
  if (/Android/.test(a)) {
    return 'Android';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry';
  }
  if (/Mac/i.test(a)) {
    return 'Mac OS X';
  }
  if (/Linux/.test(a)) {
    return 'Linux';
  }
  if (/CrOS/.test(a)) {
    return 'Chrome OS';
  }
  return '';
}

function _browser(user_agent, vendor, opera) {
  vendor = vendor || ''; // vendor is undefined for at least IE9
  if (opera || lodash.includes(user_agent, ' OPR/')) {
    if (lodash.includes(user_agent, 'Mini')) {
      return 'Opera Mini';
    }
    return 'Opera';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return 'BlackBerry';
  }
  if (lodash.includes(user_agent, 'IEMobile') || lodash.includes(user_agent, 'WPDesktop')) {
    return 'Internet Explorer Mobile';
  }
  if (lodash.includes(user_agent, 'Edge')) {
    return 'Microsoft Edge';
  }
  if (lodash.includes(user_agent, 'FBIOS')) {
    return 'Facebook Mobile';
  }
  if (lodash.includes(user_agent, 'Chrome')) {
    return 'Chrome';
  }
  if (lodash.includes(user_agent, 'CriOS')) {
    return 'Chrome iOS';
  }
  if (lodash.includes(user_agent, 'UCWEB') || lodash.includes(user_agent, 'UCBrowser')) {
    return 'UC Browser';
  }
  if (lodash.includes(user_agent, 'FxiOS')) {
    return 'Firefox iOS';
  }
  if (lodash.includes(vendor, 'Apple')) {
    if (lodash.includes(user_agent, 'Mobile')) {
      return 'Mobile Safari';
    }
    return 'Safari';
  }
  if (lodash.includes(user_agent, 'Android')) {
    return 'Android Mobile';
  }
  if (lodash.includes(user_agent, 'Konqueror')) {
    return 'Konqueror';
  }
  if (lodash.includes(user_agent, 'Firefox')) {
    return 'Firefox';
  }
  if (lodash.includes(user_agent, 'MSIE') || lodash.includes(user_agent, 'Trident/')) {
    return 'Internet Explorer';
  }
  if (lodash.includes(user_agent, 'Gecko')) {
    return 'Mozilla';
  }
  return '';
}

function _referringDomain(referrer) {
  const split = referrer.split('/');
  if (split.length >= 3) {
    return split[2];
  }
  return '';
}

function _device(user_agent) {
  if (/Windows Phone/i.test(user_agent) || /WPDesktop/.test(user_agent)) {
    return 'Windows Phone';
  }
  if (/iPad/.test(user_agent)) {
    return 'iPad';
  }
  if (/iPod/.test(user_agent)) {
    return 'iPod Touch';
  }
  if (/iPhone/.test(user_agent)) {
    return 'iPhone';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return 'BlackBerry';
  }
  if (/Android/.test(user_agent)) {
    return 'Android';
  }
  return '';
}

function _browserVersion(userAgent, vendor, opera) {
  const browser = _browser(userAgent, vendor, opera);
  const versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/
  };
  const regex = versionRegexs[browser];
  if (regex === undefined) {
    return null;
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
}

function _timestamp() {
  Date.now =
    Date.now ||
    (() => {
      return +new Date(); //eslint-disable-line new-parens
    });
  return Date.now();
}

function _searchEngine(referrer) {
  if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'google';
  }
  if (referrer.search('https?://(.*)bing.com') === 0) {
    return 'bing';
  }
  if (referrer.search('https?://(.*)yahoo.com') === 0) {
    return 'yahoo';
  }
  if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
    return 'duckduckgo';
  }
  return null;
}

function _getQueryParam(url, param) {
  // Expects a raw URL
  param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regexS = '[\\?&]' + param + '=([^&#]*)';
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return '';
  }
  return decodeURIComponent(results[1]).replace(/\+/g, ' ');
}

function _searchInfo(referrer) {
  const search = _searchEngine(referrer);
  const param = search !== 'yahoo' ? 'q' : 'p';
  const ret = {};

  if (search !== null) {
    ret.$search_engine = search;
    const keyword = _getQueryParam(referrer, param);
    if (keyword.length) {
      ret.mp_keyword = keyword;
    }
  }
  return ret;
}

function _event_properties() {
  return lodash.extend(
    _strip_empty_properties({
      $os: _os(),
      $browser: _browser(window.navigator.userAgent, window.navigator.vendor, window.opera),
      $referrer: window.document.referrer,
      $referring_domain: _referringDomain(window.document.referrer),
      $device: _device(window.navigator.userAgent)
    }),
    {
      $current_url: window.location.href,
      $browser_version: _browserVersion(
        window.navigator.userAgent,
        window.navigator.vendor,
        window.opera
      ),
      $screen_height: window.screen.height,
      $screen_width: window.screen.width,
      time: _timestamp() / 1000 // epoch time in seconds
    },
    _searchInfo(window.document.referrer)
  );
}
