import { setNotification, NOTIFICATION_TYPES } from "admin/components/Notification";
import {
  api_putDocumentRelation,
  api_postDocumentRelation
} from './documentRelations.api';
import {createAction} from "@reduxjs/toolkit";

export const START_LOAD = 'START_LOAD'
export const FINISH_LOAD = 'FINISH_LOAD'
export const CREATE_DOCUMENT_RELATION = 'CREATE_DOCUMENT_RELATION'
export const UPDATE_DOCUMENT_RELATION = 'UPDATE_DOCUMENT_RELATION'

export const startLoad = createAction(START_LOAD);
export const finishLoad = createAction(FINISH_LOAD);
export const createDocumentRelation = createAction(CREATE_DOCUMENT_RELATION);
export const updateDocumentRelation = createAction(UPDATE_DOCUMENT_RELATION);

const SUCCESS = {
  documentCreated: 'Document Relation successfully created',
  documentUpdated: 'Document Relation successfully updated'
};

export const createDocumentRelations = params => dispatch => {
  dispatch(startLoad());
  return api_postDocumentRelation(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.documentCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createDocumentRelation(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const updateDocumentRelations = params => dispatch => {
  dispatch(startLoad());
  return api_putDocumentRelation(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.documentUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateDocumentRelation(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};
