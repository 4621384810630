import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';

export const ALLOWED_SELECTED_CAI_CATEGORIES_COUNT = 5000;

export const TABLE_LOCAL_STORAGE_KEY = 'caiCategoriesManagement';

export enum SORT_DIRECTIONS {
  ASC = 'asc',
  DESC = 'desc'
}

export const ROWS_PER_PAGE_OPTIONS: Required<
  ComponentProps<typeof uiLib.Table>
>['rowsPerPageOptions'] = [50, 100, 200, 500, 1000, 2500, 5000];
