import type { ComponentProps } from 'react';

import { DOCUMENT_AUDIT_ENTRY_ATTRIBUTE } from 'shared/features/documentAuditEntries/documentAuditEntries.constants';

import * as uiLib from '@compliance.ai/web-components';
import * as components from '../TableCells/DocumentStatusCell';

export const LOCAL_STORAGE_KEY = 'auditEntriesTable';
export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

export const COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Created At',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.CREATED_AT,
    Component: uiLib.DateCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Updated At',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.UPDATED_AT,
    Component: uiLib.DateCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Real Created At',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.REAL_CREATED_AT,
    Component: uiLib.DateCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Process',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.PROCESS,
    Component: uiLib.TextCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Incoming Pipeline Status',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.INCOMING_PIPELINE_STATUS,
    render: components.DocumentStatusCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Resulting Pipeline Status',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.RESULTING_PIPELINE_STATUS,
    render: components.DocumentStatusCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Details',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.DETAILS,
    Component: uiLib.JSONCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.M
  },
  {
    title: 'Notes',
    dataKey: DOCUMENT_AUDIT_ENTRY_ATTRIBUTE.NOTES,
    Component: uiLib.TextCell,
    isInitiallyVisible: true,
    isDynamic: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  }
];
