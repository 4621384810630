import {
  REQUEST_GOOGLE_ANALYTICS_REPORTS,
  RECEIVE_GOOGLE_ANALYTICS_REPORTS,
  REQUEST_SPIDER_NAMES,
  RECEIVE_SPIDER_NAMES,
  REQUEST_STATISTICS,
  RECEIVE_STATISTICS,
  REQUEST_LAST_RUN_REPORT,
  RECEIVE_LAST_RUN_REPORT,
  REQUEST_MARKETING_CAMPAIGNS,
  RECEIVE_MARKETING_CAMPAIGNS,
  REQUEST_CAMPAIGN_DETAILS,
  RECEIVE_CAMPAIGN_DETAILS
} from './analytics.actions';

export const getInitialGoogleAnalyticsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  reports: []
});

export const google_analytics = (state = getInitialGoogleAnalyticsState(),action) => {
  switch (action.type) {
    case REQUEST_GOOGLE_ANALYTICS_REPORTS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_GOOGLE_ANALYTICS_REPORTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        reports: action.reports,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialSpiderNamesState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const spider_names = (state = getInitialSpiderNamesState(), action) => {
  switch (action.type) {
    case REQUEST_SPIDER_NAMES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_SPIDER_NAMES:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.spidernames
      };
    default:
      return state;
  }
};

export const getInitialAllStatisticsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  statistics: []
});

export const all_statistics = (state = getInitialAllStatisticsState(), action) => {
  switch (action.type) {
    case REQUEST_STATISTICS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_STATISTICS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        statistics: action.statistics,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialLastRunReportState = () => ({
  isFetching: false,
  isReady: false,
  items: {}
});

export const last_run_report = (state = getInitialLastRunReportState(), action) => {
  switch (action.type) {
    case REQUEST_LAST_RUN_REPORT:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        items: {}
      };
    case RECEIVE_LAST_RUN_REPORT:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.reunreport
      };
    default:
      return state;
  }
};

export const getInitialMarketingState = () => ({
  isFetching: false,
  isReady: false,
  campaigns: {},
  details: []
});

export const marketing = (state = getInitialMarketingState(), action) => {
  switch (action.type) {
    case REQUEST_MARKETING_CAMPAIGNS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_MARKETING_CAMPAIGNS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        campaigns: action.campaigns
      };
    case REQUEST_CAMPAIGN_DETAILS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_CAMPAIGN_DETAILS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        details: action.details
      };
    default:
      return state;
  }
};
