import React from 'react';

import * as constants from './CrawlerDetails.constants';
import * as elements from './elements';

import ConfirmationModal from '../ConfirmationModal';

import { useCrawlDetailsData } from './hooks/useCrawlDetailsData';
import { useCrawlDetailsLifecycle } from './hooks/useCrawlDetailsLifecycle';
import { useCrawlDetailsHandlers } from './hooks/useCrawlDetailsHandlers';

export const CrawlerDetails = () => {
  const { reduxState, localState, localActions, formattedData } = useCrawlDetailsData();

  const handlers = useCrawlDetailsHandlers({
    reduxState,
    localState,
    localActions,
    formattedData
  });

  useCrawlDetailsLifecycle({
    onCalculateCrawlDetails: handlers.handleCalculateCrawlDetails,
    onCreateNewCrawl: handlers.handleCreateNewCrawl
  });

  return (
    <div className="crawlDetails">
      <div className="crawlDetails_body">
        <elements.CrawlerDetailsGeneral
          editMode={localState.editMode}
          handleToggleEditMode={handlers.handleToggleEditMode}
          crawlDetails={localState.crawlDetails}
          handleSaveChanges={handlers.handleSaveChanges}
          handleChanges={handlers.handleChanges}
          handleChangesEvent={handlers.handleChangesEvent}
          errorFields={localState.errorFields}
        />
        <elements.CrawlerDetailsRecur
          editMode={localState.editMode}
          crawlDetails={localState.crawlDetails}
          handleChanges={handlers.handleChanges}
          handleChangesEvent={handlers.handleChangesEvent}
          errorFields={localState.errorFields}
        />
        <elements.AdditionalInfo />
        <elements.ViewCrawlSourceStructureModal
          show={localState.showViewModal}
          onHide={() => localActions.setShowViewModal(false)}
          modalAction={handlers.handleOpenModal}
          changeCrawlSourceStructure={localActions.setCrawlSourceStructure}
          isEditDisabled={formattedData.isEditDisabled}
          crawlSourceStructure={localState.crawlSourceStructure}
        />
        <elements.ViewCrawlSentencificationConfigurationModal
          show={localState.showSentencificationConfigurationModal}
          onHide={handlers.handleCloseModal}
          onModalAction={handlers.handleOpenModal}
          // TODO: [DATAWEB-2356] once edit enabled
          // changeCrawlSentencificationConfiguration={handlers.handleChangeSentencificationConfiguration}
          crawlSentencificationConfigurartion={localState.crawlSentencificationConfiguration}
        />
        <elements.ViewCrawlAutoQAResultModal
          show={localState.showAutoQAModal}
          onHide={() => localActions.setShowAutoQAModal(false)}
          crawlAutoQA={reduxState.autoQACommandRequests[0]?.status_details}
        />
        <ConfirmationModal
          show={localState.showModal}
          mode={constants.MODE_CONFIRMATION_MODAL.EDIT_SOURCE_STRUCTURE}
          onHide={() => localActions.setShowModal(false)}
          modalAction={handlers.handleSaveCrawlSourceStructure}
        />
      </div>
      <div className="crawlDetails_rightBar">
        <elements.CrawlerDetailsAdditionalInfo
          eitlLabelEnabled={formattedData.eitlLabelEnabled}
          editMode={localState.editMode}
          crawlDetails={localState.crawlDetails}
          handleChanges={handlers.handleChanges}
          handleChangesEvent={handlers.handleChangesEvent}
          errorFields={localState.errorFields}
          openViewModal={() => localActions.setShowViewModal(true)}
          openViewSentencificationConfigurationModal={
            handlers.handleOpenSentencificationConfigurationModal
          }
          openViewAutoQAModal={() => localActions.setShowAutoQAModal(true)}
          enableAutoTranslationSelectedOption={formattedData.enableAutoTranslationSelectedOption}
        />
      </div>
    </div>
  );
};
