import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  FormGroup,
  Col,
  Row,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap';
import Select from 'react-select';
import { withHistory } from 'admin/utils/hooks';
import _ from 'lodash';

import {
  fetchAllAnnotationTasks,
  fetchAnnotationJob,
  createAnnotationsForJob,
  fetchAllAnnotationJobs,
  fetchAnnotationJobById,
  fetchAnnotationTaskTopicGroups
} from 'shared/features/annotations/annotations.actions';
import ObligationAnnotation from './ObligationAnnotation';
import ROUTES from '../../constants/routes';

const TOPIC_DEFINITION = {
  Lending:
    'an entity (bank, credit union, P2P lender,'
    + 'other lender) lends money to a borrower '
    + '(individual or business) to pay for something '
    + '(car, home, personal expenses, etc.).',
  'BSA/AML':
    'The Bank Secrecy Act / Anti-Money Laundering rules '
    + '(BSA/AML) requires U.S. financial '
    + 'institutions to assist the U.S. government to detect and prevent '
    + 'money laundering and fraud. Money Laundering '
    + 'includes the concealment of the origins of illegally obtained money, '
    + 'typically involving bank transfers.'
    + 'Fraud includes deception intended to result in financial gain.',
  'Mortgage Lending':
    // eslint-disable-next-line operator-linebreak
    'A mortgage is a loan used to purchase property ' +
    '(such as a house). Bank (or other lender) lends money to borrower; '
    + 'the loan is secured by the title of the property.',
  'Consumer Lending':
    'an entity (bank, credit union, P2P lender, '
    + 'other lender) lends money to a borrower (individual consumer) to pay '
    + 'for something (car, home, personal expenses, etc.).',
  'Commercial Lending':
    'an entity (bank, other lender) lends money to '
    + 'a commercial entity (non-consumer entity) to pay '
    + 'for something (usually real estate).',
  Crowdfunding:
    'the practice of funding a project or venture by raising '
    + 'small monetary contributions from multiple investors, typically through '
    + 'an online platform. The funds may be provided in exchange for a) loan repayment '
    + '(with or without interest), b) equity, c) other “rewards,” '
    + 'like an invite to a product launch.',
  Deposits:
    'Deposits of money made to banking institutions '
    + 'for safe keeping. May include checking accounts, '
    + 'savings accounts, and money market accounts.',
  Obligation:
    'an act or course of action to which a person is morally or legally bound; a duty or commitment.'
};

class AnnotationTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arbitraryTags: '',
      annotation_reasoning: '',
      button_disabled: true,
      count: 0,
      difficulty: '',
      final_answer: '',
      is_gold_evaluation: false,
      is_positive: null,
      is_training_task: false,
      notes: '',
      notes_disabled: false,
      previousAnnotation: false,
      previous_job_id: null,
      reveal_answer: false,
      select_disabled: false,
      showAlert: false,
      topic_annotation_id: null,
      obligation_annotation: []
    };
  }

  UNSAFE_componentWillMount() {
    const { id, topic } = queryString.parse(this.props.location.search);
    this.setState({
      task_id: id,
      topic
    });
    this.props.fetchAnnotationJob(id);
    this.props.fetchAnnotationTaskTopicGroups();
  }

  componentDidMount() {
    // get total number of annotations from queue
    this.props
      .fetchAllAnnotationJobs(this.state.task_id, {
        status: 'queued',
        count_only: true
      })
      .then(response => {
        this.setState({ total: response.total });
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.annotation_jobs.isReady
      && nextProps.annotation_jobs.items.annotation_job
      && !_.isEqual(this.props.annotation_jobs, nextProps.annotation_jobs)
    ) {
      let answer = null;
      let annotation_id = null;
      let previousAnnotationIsLoaded = false;
      let previous_notes = '';
      let is_training = false;
      let is_gold = false;
      let props_obligation_annotation = [];
      // get answer for the last completed annotation.
      // assume that topic_annotations array is available only if fetched job is completed.
      const annotations = nextProps.annotation_jobs.items.annotation_job.topic_annotations;
      if (annotations && annotations.length > 0) {
        // get first annotation. Currently it's only one annotation saved per job.
        const topic_annotation = annotations[0];
        const prev_answer = topic_annotation.is_positive;
        answer = prev_answer ? 'yes' : 'no';
        annotation_id = topic_annotation.id;
        previousAnnotationIsLoaded = true;
      }
      if (nextProps.annotation_jobs.items.annotation_job.notes) {
        previous_notes = nextProps.annotation_jobs.items.annotation_job.notes;
      }
      if (nextProps.annotation_jobs.items.annotation_job.obligation_annotation) {
        const {
          obligation_annotation: nextObligationAnnotation
        } = nextProps.annotation_jobs.items.annotation_job;
        props_obligation_annotation = nextObligationAnnotation.slice(0);
      }
      if (
        nextProps.annotation_jobs.isReady
        && nextProps.annotation_jobs.items.is_training_task
        && nextProps.annotation_jobs.items.annotation_job.is_gold_evaluation
      ) {
        is_training = nextProps.annotation_jobs.items.is_training_task;
        is_gold = nextProps.annotation_jobs.items.annotation_job.is_gold_evaluation;
      }
      this.setState({
        job_id: nextProps.annotation_jobs.items.annotation_job.id,
        is_positive: answer,
        is_training_task: is_training,
        is_gold_evaluation: is_gold,
        notes: previous_notes,
        topic_annotation_id: annotation_id,
        previousAnnotation: previousAnnotationIsLoaded,
        showAlert: false
      });
      if (props_obligation_annotation !== []) {
        this.setState({ obligation_annotation: props_obligation_annotation });
      }
    }
  }

  getParams = () => {
    const topic_annotations = [];
    const annotation_result = {};
    annotation_result.is_positive = this.state.is_positive === 'yes';
    annotation_result.topic_name = this.state.topic;
    const details = {};
    if (this.state.topics) {
      details.topics = this.state.topics;
    }
    if (details) {
      annotation_result.details = details;
    }
    if (this.state.topic_annotation_id) {
      annotation_result.topic_annotation_id = this.state.topic_annotation_id;
    }
    topic_annotations.push(annotation_result);
    const params = {};
    params.topic_name = this.state.topic;
    params.topic_annotations = topic_annotations;
    if (this.state.notes) {
      params.notes = this.state.notes;
    }
    if (this.state.difficulty) {
      params.user_difficulty = this.state.difficulty;
    }
    if (this.state.arbitraryTags && this.state.arbitraryTags.length > 0) {
      params.arbitrary_tags = this.state.arbitraryTags;
    }
    if (this.state.obligation_annotation) {
      params.obligation_annotation = this.state.obligation_annotation;
    }
    return params;
  };

  handleFieldChange = event => {
    const new_state = {};
    new_state.showAlert = false;
    new_state[event.target.name] = event.target.value;
    this.setState(new_state);
  };

  submit = () => {
    const new_state = {};
    if (!this.state.previousAnnotation) {
      new_state.count = this.state.count + 1;
      new_state.total = this.state.total - 1;
    }
    new_state.previous_job_id = this.state.job_id;
    new_state.difficulty = '';
    new_state.arbitraryTags = '';
    new_state.reveal_answer = false;
    new_state.select_disabled = false;
    new_state.notes_disabled = false;
    new_state.button_disabled = true;
    new_state.final_answer = '';
    new_state.obligation_annotation = '';
    this.props.createAnnotationsForJob(
      this.state.task_id,
      this.state.job_id,
      this.getParams()
    );
    this.setState(new_state);
    this.props.fetchAnnotationJob(this.state.task_id);
  };

  handleDone = () => {
    this.props
      .createAnnotationsForJob(
        this.state.task_id,
        this.state.job_id,
        this.getParams()
      )
      .then(() => {
        this.setState(state => {
          return {
            difficulty: null,
            arbitraryTags: null,
            reveal_answer: !state.reveal_answer,
            select_disabled: false,
            notes_disabled: !state.notes_disabled
          };
        });
        window.location = `#${ROUTES.dashboard}`;
      });
  };

  handleError = () => {
    this.setState(state => {
      return {
        total: state.total - 1,
        previous_job_id: state.job_id,
        showAlert: false
      };
    });
    const params = {};
    params.error = true;
    if (!this.isEmpty(this.state.notes)) {
      params.notes = this.state.notes;
    }
    this.props.createAnnotationsForJob(
      this.state.task_id,
      this.state.job_id,
      params
    );
    this.props.fetchAnnotationJob(this.state.task_id);
  };

  handleSkip = () => {
    // Check that notes are provided for skipped document
    if (this.isEmpty(this.state.notes)) {
      this.setState({ showAlert: true });
    } else {
      this.setState(state => {
        return {
          total: state.total - 1,
          previous_job_id: state.job_id
        };
      });
      const params = {};
      params.skip = true;
      params.notes = this.state.notes;
      this.props.createAnnotationsForJob(
        this.state.task_id,
        this.state.job_id,
        params
      );
      this.props.fetchAnnotationJob(this.state.task_id);
    }
  };

  handleObligationAnnotationFieldChange = (id, event) => {
    const index = this.state.obligation_annotation.findIndex(
      item => item.id === id
    );
    const targetValue = parseInt(event.target.value, 10);
    this.setState(state => {
      const obligation_annotation = [...state.obligation_annotation];
      if (~index) {
        const obligation_item = {
          ...obligation_annotation[index],
          obligation_value: targetValue
        };
        obligation_annotation[index] = obligation_item;
      }
      return { obligation_annotation };
    });
  };

  handleGroupFieldChange = (id, event) => {
    const index = this.state.obligation_annotation.findIndex(
      item => item.id === id
    );
    const targetValue = parseInt(event.target.value, 10);
    this.setState(state => {
      const obligation_annotation = [...state.obligation_annotation];
      if (~index) {
        const obligation_item = {
          ...obligation_annotation[index],
          group: targetValue
        };
        obligation_annotation[index] = obligation_item;
      }
      return { obligation_annotation };
    });
  };

  openNextDocument = () => {
    this.setState(state => ({ total: state.total - 1 }));
    this.props.fetchAnnotationJob(this.state.task_id);
  };

  openPreviousDocument = () => {
    this.setState(state => {
      return {
        previous_job_id: null,
        showAlert: false,
        total: state.total + 1
      };
    });
    const params = {};
    params.complete_later = true;
    this.props.createAnnotationsForJob(
      this.state.task_id,
      this.state.job_id,
      params
    );
    this.props.fetchAnnotationJobById(
      this.state.task_id,
      this.state.previous_job_id
    );
  };

  isEmpty = str => {
    return str.length === 0 || !str.trim();
  };

  showAnswer = () => {
    if (this.isEmpty(this.state.notes)) {
      this.setState({ showAlert: true });
    } else if (
      this.props.annotation_jobs.isReady
      && this.props.annotation_jobs.items.annotation_job.is_gold_evaluation
    ) {
      this.setState(state => {
        return {
          reveal_answer: !state.reveal_answer,
          select_disabled: false,
          notes_disabled: false,
          button_disabled: false,
          annotation_reasoning: this.props.annotation_jobs.items
            .correct_judgment_notes
        };
      });
      if (
        (this.props.annotation_jobs.items.correct_judgment === true
          && this.state.is_positive === 'yes')
        || (this.props.annotation_jobs.items.correct_judgment === false
          && this.state.is_positive === 'no')
      ) {
        this.setState({ final_answer: 'Correct' });
      } else {
        this.setState({ final_answer: 'Incorrect' });
      }
    }
  };

  render() {
    const answerClass = `annotation-tool-answer ${
      this.state.reveal_answer ? '' : 'hidden'
    }`;
    const answerColor = `${
      this.state.final_answer === 'Correct'
        ? 'correct-answer'
        : 'incorrect-answer'
    }`;
    if (!this.props.annotation_jobs || !this.props.annotation_jobs.isReady) {
      return null;
    }
    if (this.props.annotation_jobs.items.errors) {
      return (
        <div>
          <h4>{this.props.annotation_jobs.items.errors}</h4>
          <Button bsStyle='primary' onClick={this.openNextDocument}>
            Next
          </Button>
        </div>
      );
    }
    if (!this.props.annotation_jobs.items.annotation_job) {
      return (
        <div>
          <h4>Queue is empty</h4>
        </div>
      );
    }

    const obligationItems = this.state.topic === 'Obligation'
      && this.state.obligation_annotation.map(item => (
        <ObligationAnnotation
          key={item.id}
          obligation_row={item}
          handleObligationAnnotationFieldChange={
            this.handleObligationAnnotationFieldChange
          }
          handleGroupFieldChange={this.handleGroupFieldChange}
        />
      ));
    const document = this.props.annotation_jobs.items.document;
    const agencies_display = [];
    for (const agency of document.agencies) {
      agencies_display.push(agency.short_name);
    }

    const topic = _.find(this.props.annotation_task_topic_groups, [
      'name',
      this.state.topic
    ]);
    const arbitraryTags = topic ? topic.arbitrary_tags : [];
    const arbitraryTagOptions = arbitraryTags.map(tag => {
      return { value: tag, label: tag };
    });

    return (
      <div className='annotation-tool'>
        <Row>
          <Col sm={10}>
            <h1>{document.title}</h1>
          </Col>
          <Col sm={2}>
            <div className='counter-container'>
              <h1>{this.state.count}</h1>
              <p>completed</p>
              <p>this session</p>
              <p>{'Total queued: ' + this.state.total}</p>
            </div>
          </Col>
        </Row>
        <p>
          <b>Author: </b>
          {agencies_display.join()}
        </p>
        <p>
          <b>Publication Date: </b>
          {document.publication_date}
        </p>
        <p>
          <b>Doc ID: </b>
          {document.id}
        </p>
        <p>
          <b>Document type: </b>
          {document.category}
        </p>
        <p>
          <b>Official ID: </b>
          {document.official_id}
        </p>
        <p>
          <b>Web url: </b>
          <a href={document.web_url} target='_blank' rel="noopener noreferrer">
            {document.web_url}
          </a>
        </p>
        <p>
          <b>Pdf url: </b>
          <a href={document.pdf_url} target='_blank' rel="noopener noreferrer">
            {document.pdf_url}
          </a>
        </p>
        <div className='text-container'>{document.full_text}</div>
        {this.state.topic === 'Obligation' && obligationItems !== null
          && (
            <div>
              {obligationItems.length > 0 ? (
                <div className='obligation-sentence-comp'>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Sentences</th>
                        <th>Is obligation ?</th>
                        <th>Group</th>
                      </tr>
                    </thead>
                    <tbody>{obligationItems}</tbody>
                  </table>
                </div>
              ) : (
                <div className='obligation-sentence-comp-alt'>
                  <p>
                    This document hasn't been sentencified yet. You can skip it for
                    now. Come back later !!
                    {' '}
                  </p>
                </div>
              )}
            </div>
          )
        }
        <Row>
          <Col sm={6}>
            {this.state.topic !== 'Enforcement' ? (
              <FormGroup className='topic-form-group'>
                <h4>Difficulty of Judgment</h4>
                <select
                  name='difficulty'
                  className='form-control'
                  value={this.state.difficulty}
                  onChange={this.handleFieldChange}
                >
                  <option value='' />
                  <option value='easy'>easy</option>
                  <option value='medium'>medium</option>
                  <option value='hard'>hard</option>
                </select>
              </FormGroup>
            ) : null}
            {this.state.topic !== 'Enforcement' && (
              <FormGroup>
                <h4>Arbitrary Tags</h4>
                <Select
                  options={arbitraryTagOptions}
                  value={this.state.arbitraryTags}
                  onChange={objects => {
                    this.setState({
                      arbitraryTags: objects.map(object => object.value)
                    });
                  }}
                  multi
                />
              </FormGroup>
            )}
            <FormGroup className='topic-form-group'>
              {this.state.topic === 'Enforcement' ? (
                <h4>
                  {'Is this a '}
                  <b>Final Enforcement Action</b>
                  ?
                </h4>
              ) : (
                <h4>
                  Is this a
                  {' '}
                  <span className='text-capitalize'>
                    <b>{this.state.topic}</b>
                  </span>
                  {' '}
                  document?
                </h4>
              )}
              <ToggleButtonGroup
                type='radio'
                name='is_positive'
                value={this.state.is_positive}
                onChange={value => this.handleFieldChange({
                  target: { name: 'is_positive', value }
                })}
              >
                <ToggleButton value={'yes'}>YES</ToggleButton>
                <ToggleButton value={'no'}>NO</ToggleButton>
              </ToggleButtonGroup>
            </FormGroup>
            <FormGroup className='topic-form-group'>
              <div className='training-notes'>
                <FormControl
                  name='notes'
                  className='notes-text-area'
                  componentClass='textarea'
                  placeholder='Notes (required for training tasks or skipped document)'
                  value={this.state.notes}
                  onChange={this.handleFieldChange}
                  disabled={this.state.notes_disabled}
                  required={this.state.is_training_task}
                />
                <div
                  className={
                    this.state.showAlert ? 'alert alert-danger' : 'hide-notes'
                  }
                  role='alert'
                >
                  {
                    'Notes are required for training tasks or when skipping a document.'
                  }
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <div
              className={
                TOPIC_DEFINITION[this.state.topic]
                || this.state.topic !== 'Enforcement'
                  ? ''
                  : 'hidden'
              }
            >
              <h4>{'Definition of ' + this.state.topic}</h4>
              <div className='topic-definition'>
                {TOPIC_DEFINITION[this.state.topic]}
              </div>
            </div>
            <div className={answerClass}>
              <h3 className={answerColor}>{this.state.final_answer}</h3>
              <p>{this.state.annotation_reasoning}</p>
              <Button bsStyle='primary' onClick={this.handleDone}>
                Return to Dashboard
              </Button>
              <Button bsStyle='primary' onClick={this.submit}>
                Next
              </Button>
            </div>
          </Col>
        </Row>
        {this.state.is_training_task && this.state.is_gold_evaluation ? (
          <Row>
            <Col sm={6}>
              <div>
                <Button
                  bsStyle='primary'
                  onClick={this.showAnswer}
                  disabled={
                    !this.state.is_positive || !this.state.button_disabled
                  }
                >
                  Confirm Answer
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6}>
              <div>
                <Button bsStyle='primary' onClick={this.handleError}>
                  Error
                </Button>
                <Button
                  bsStyle='primary'
                  disabled={!this.state.previous_job_id}
                  onClick={this.openPreviousDocument}
                >
                  Back
                </Button>
                <Button bsStyle='primary' onClick={this.handleSkip}>
                  Skip
                </Button>
                <Button
                  bsStyle='primary'
                  onClick={this.handleDone}
                  disabled={!this.state.is_positive}
                >
                  {"I'm done"}
                </Button>
                <Button
                  bsStyle='primary'
                  onClick={this.submit}
                  disabled={!this.state.is_positive}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAllAnnotationTasks: () => {
      dispatch(fetchAllAnnotationTasks());
    },
    fetchAnnotationJob: task_id => {
      dispatch(fetchAnnotationJob(task_id));
    },
    createAnnotationsForJob: (task_id, job_id, params) => {
      return dispatch(createAnnotationsForJob(task_id, job_id, params));
    },
    fetchAllAnnotationJobs: (task_id, params) => {
      return dispatch(fetchAllAnnotationJobs(task_id, params));
    },
    fetchAnnotationJobById: (task_id, job_id) => {
      dispatch(fetchAnnotationJobById(task_id, job_id));
    },
    fetchAnnotationTaskTopicGroups: () => {
      return dispatch(fetchAnnotationTaskTopicGroups());
    }
  };
};

const mapStateToProps = state => {
  return {
    all_annotation_tasks: state.all_annotation_tasks,
    annotation_jobs: state.annotation_jobs,
    annotations_for_job: state.annotations_for_job,
    all_annotation_jobs: state.all_annotation_jobs,
    annotation_task_topic_groups:
      state.annotation_task_topic_groups.annotation_task_topic_groups,
    user: state.current_user.user
  };
};

const ReduxAnnotationTool = connect(
  mapStateToProps,
  mapDispatchToProps
)(withHistory(AnnotationTool));

export default ReduxAnnotationTool;
