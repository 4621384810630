import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Modal,
  Button
} from 'react-bootstrap';

const CrawlerPerformanceModal = (
  {
    showModal,
    closeModal,
    dateFormat,
    spiderRunReport = {},
    averageCounter
  }
) => {
  const dataFormatter = (information) => (
    _.map(information, (value, key) => {
      const docsNumber = value[0]
        ? `${value[0]} (${value[1]})`
        : value[0];
      return (
        <li className="shortedInfoText" key={key}>
          {`${key.length > 60 ? (docsNumber + ' - ') : ''}${key}: ${docsNumber}`}
        </li>
      );
    })
  );

  const total_skipped = spiderRunReport.docs_skipped
    || 0;
  const total_ingested = spiderRunReport.docs_ingested
    || 0;
  const total_runs = spiderRunReport.total_runs
    || 0;
  const total_errors = spiderRunReport.docs_error
    || 0;

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="docMetaModal thinBtn"
    >
      <Modal.Header>
        <Modal.Title>Crawler performance information</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <div>
            <h4>{`Total Runs - ${total_runs}`}</h4>
            <h4>{`Ingested documents - ${total_ingested} (average: ${averageCounter(total_ingested, total_runs)})`}</h4>
          </div>
          <div>
            <h4>{`Skipped documents - ${total_skipped} (average: ${averageCounter(total_skipped, total_runs)})`}</h4>
            <ul>
              {dataFormatter(spiderRunReport.skipped_docs_stat || {})}
            </ul>
          </div>
          <div>
            <h4>{`Error documents - ${total_errors} (average: ${averageCounter(total_errors, total_runs)})`}</h4>
            <ul>
              {dataFormatter(spiderRunReport.logs || {})}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

CrawlerPerformanceModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  averageCounter: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  spiderRunReport: PropTypes.shape({
    spider_name: PropTypes.string
  })
};

CrawlerPerformanceModal.defaultProps = {
  showModal: false,
  dateFormat: 'MM/DD/YYYY',
  spiderRunReport: null
};


export default CrawlerPerformanceModal;
