import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Modal from '../../../components/Modal';
import CrawlRunNowModalBody from './CrawlRunNowModalBody';

const CrawlRunNowModal = ({ show, onHide, onCancel, createRunNow, crawlDetails }) => {
  return (
    <Modal
      className={classnames('crawlRunNow-Modal', 'settingsModal')}
      title="Run Now"
      show={show}
      onHide={onHide}
      onCancel={onCancel}
      size="small"
    >
      <CrawlRunNowModalBody
        closePopup={onHide}
        show={show}
        createRunNowCommandRequest={createRunNow}
        crawlDetails={crawlDetails}
        onCancel={onCancel}
      />
    </Modal>
  );
};

CrawlRunNowModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  createRunNow: PropTypes.func,
  crawlDetails: PropTypes.shape({})
};

CrawlRunNowModal.defaultProps = {
  onCancel: () => {},
  createRunNow: () => {},
  crawlDetails: {}
};

export default CrawlRunNowModal;
