import React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Pagination,
  Col,
  ControlLabel,
  FormControl,
  Form,
  FormGroup
} from 'react-bootstrap';
import _ from 'lodash';
import Select from 'react-select';

import {
  fetchAllDocuments,
  fetchDocuments,
  fetchFullDocuments,
  flagDocument,
  fetchHiddenDocuments,
  updateDocument,
  fetchFilteredDataDocs,
  updateDataDocument,
  REMOVE_FROM_DOCUMENT_LIST
} from 'shared/features/documents/documents.actions';
import { fetchAgencies } from 'shared/features/agencies/agencies.actions';
import DocumentUpdateModal from './DocumentUpdateModal';
import {withAdminPermissions} from "../../utils/hooks";

const FLAGGED = { value: 'FLAGGED', name: 'flagged' };
const PROCESSED = { value: 'PROCESSED', name: 'processed' };
const HIDDEN = { value: 'HIDDEN', name: 'hidden' };
const CONTRIBUTOR_FLAG = { value: 'CONTRIBUTOR_FLAG', name: 'contributor_flagged' };
const FUTURE_PUBLICATION = { value: 'FUTURE_PUBLICATION', name: 'future_publication' };
const DUPLICATE = { value: 'DUPLICATE', name: 'duplicated' };
const POTENTIALLY_DUPLICATE = { value: 'POTENTIALLY_DUPLICATE', name: 'potential_duplicate' };

const UPDATE_DOCS_STATUSES = [
  FLAGGED, PROCESSED, HIDDEN, CONTRIBUTOR_FLAG, FUTURE_PUBLICATION, DUPLICATE, POTENTIALLY_DUPLICATE
];

// jurispect_data statuses
const DATA_STATUSES = [FUTURE_PUBLICATION.name, DUPLICATE.name, POTENTIALLY_DUPLICATE.name];

class DocumentUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 25,
      page: 1,
      offset: 0,
      showModal: false,
      openedDocumentId: null,
      userFlaggedDocumentsID: null,
      all_agencies: true,
      flagged_status: FLAGGED.name,
      agency_id: '',
      hidden_documents_view: false,
      filtered_by_id: false,
      id: ''
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAllDocuments(_.cloneDeep(this.state));
    this.props.fetchAgencies();
  }

  getParams = () => {
    const params = _.cloneDeep(this.state);
    const selected_agencies = params.agency_id;
    if (selected_agencies) {
      params.all_agencies = false;
      params.agency_ids = selected_agencies;
      params.agency_id = selected_agencies.split(',').map(Number);
    } else {
      params.all_agencies = true;
    }
    return params;
  };

  handlePageChange = eventKey => {
    const new_state = {};
    new_state.page = eventKey;
    new_state.offset = this.state.limit * (eventKey - 1);
    if (this.state.hidden_documents_view) {
      this.setState(new_state);
    } else {
      this.setState(new_state, () => {
        if (DATA_STATUSES.includes(this.state.flagged_status)) {
          this.props.fetchFilteredDataDocs(this.getParams());
        } else {
          this.props.fetchAllDocuments(this.getParams());
        }
      });
    }
  };

  handleStatusChange = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    this.setState(new_state);
  };

  handleFilterDocId = () => {
    const new_state = { offset: 0, page: 1 };
    new_state.hidden_documents_view = false;
    new_state.filtered_by_id = true;
    new_state.skip_unused_fields = false;
    this.setState(new_state, () => {
      this.props.fetchFullDocuments(this.getParams());
    });
  };

  handleFieldChange = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    new_state.showAlert = false;
    this.setState(new_state);
  };

  handleFilter = () => {
    const new_state = { offset: 0, page: 1 };
    if (this.state.flagged_status === HIDDEN.name) {
      new_state.hidden_documents_view = true;
      new_state.filtered_by_id = false;
      this.setState(new_state, () => {
        this.props.fetchHiddenDocuments({});
      });
    } else if (DATA_STATUSES.includes(this.state.flagged_status)) {
      this.props.fetchFilteredDataDocs(this.getParams());
    } else {
      new_state.hidden_documents_view = false;
      new_state.filtered_by_id = false;
      this.setState(new_state, () => {
        this.props.fetchAllDocuments(this.getParams());
      });
    }
  };

  handleAgencyChange = (field, val) => {
    const new_state = {};
    new_state[field] = val;
    this.setState(new_state);
  };

  openModal = (document_id, flagged) => {
    if (DATA_STATUSES.includes(this.state.flagged_status)) {
      this.props.fetchFilteredDataDocs({
        doc_id: document_id,
        flagged_status: this.state.flagged_status
      }, { saveFullDataDocuments: true });
    } else {
      this.props.fetchFullDocuments({ id: document_id });
    }

    this.setState({
      showModal: true,
      openedDocumentId: document_id,
      userFlaggedDocumentsID: flagged && flagged.user_flagged_document_id
    });
  };

  close = () => {
    this.setState({ showModal: false });
  };

  updateDocumentsResult = () => {
    if (DATA_STATUSES.includes(this.state.flagged_status)) {
      this.props.fetchFilteredDataDocs(this.getParams());
    } else {
      this.props.fetchAllDocuments(this.getParams());
    }
  };

  unflagDocument = (doc_id, user_flagged_document_id, document, notDuplicate = true) => {
    const params = {};
    if (this.state.flagged_status === FUTURE_PUBLICATION.name) {
      this.unflagFuturePubDocs(doc_id, document);
      return;
    }
    if (this.state.flagged_status === POTENTIALLY_DUPLICATE.name && !notDuplicate) {
      this.props.updateDataDocument(doc_id, {
        pipeline_status: DUPLICATE.name
      }, REMOVE_FROM_DOCUMENT_LIST);
    }
    if (DATA_STATUSES.includes(this.state.flagged_status) && notDuplicate) {
      this.props.updateDataDocument(doc_id, {
        original_doc: true
      }, REMOVE_FROM_DOCUMENT_LIST);
      params.duplicate_status = this.state.flagged_status;
    }
    if (!!user_flagged_document_id) {
      params.id = user_flagged_document_id;
      params.status = 'skipped';
      this.props.flagDocument(doc_id, params).then(() => {
        this.props.fetchAllDocuments(this.getParams());
      });
    }
  };

  unflagFuturePubDocs = (doc_id, document) => {
    const { future_publication_date, ...document_misc } = document.document_misc;
    this.props.updateDataDocument(doc_id, {
      document_misc: { details: document_misc },
      publication_date: future_publication_date
    }, REMOVE_FROM_DOCUMENT_LIST);
  };

  showAgainDocument = doc_id => {
    const params = {};
    params.issue_severity = 'show_now';
    params.issue_type = 'show again';
    this.props.flagDocument(doc_id, params).then(() => {
      this.props.fetchHiddenDocuments({});
    });
  };

  submitWithoutUpdating = (doc_id, flagged_doc_id) => {
    const params = {};
    params.skip_contributor_notes = true;
    params.user_flagged_document_id = flagged_doc_id;
    this.props.updateDocument(doc_id, params).then(() => {
      this.props.fetchAllDocuments(this.getParams());
    });
  };

  render() {
    const { haveEditAccess } = this.props;
    const allAgencies = this.props.agencies.items;
    const agencies_options = [];
    allAgencies.forEach((agency, i) => {
      agencies_options.push({
        value: agency.id,
        label: agency.short_name ? agency.short_name : agency.name
      });
    });
    let documents = [];
    let count;
    if (this.state.hidden_documents_view) {
      if (!this.props.hidden_documents || !this.props.hidden_documents.isReady) {
        return null;
      }
      count = this.props.hidden_documents.items.hidden_documents.length;
      documents = _.slice(
        this.props.hidden_documents.items.hidden_documents,
        this.state.offset,
        this.state.offset + this.state.limit
      );
    } else {
      if (!this.props.all_documents || !this.props.all_documents.isReady) {
        return null;
      }
      documents = this.props.all_documents.items.documents;
      count = this.props.all_documents.items.count;
    }

    const get_agencies = document => {
      const agency_list = [];
      for (const agency of document.agencies || []) {
        agency_list.push(agency.short_name);
      }
      return agency_list.join(', ');
    };

    const max_buttons = Math.ceil(count / this.state.limit);
    const rows = [];
    const documentes = [];
    if (this.state.filtered_by_id === true) {
      if (
        !this.props.documents_full.isReady
        || !this.props.documents_full.ids[this.state.id]
      ) {
        return null;
      }
      const doc = this.props.documents_full.ids[this.state.id];
      documentes.push(doc);
    } else {
      if (this.state.hidden_documents_view) {
        documents.forEach((document, i) => {
          if (document.data) {
            rows.push(
              <tr key={i}>
                <td>
                  <p className="uppercase">
                    {document.doc_id + ' | ' + document.status + ' | ' + document.issue_severity}
                  </p>
                  <p className={document.field ? '' : 'hidden'}>
                    {'Field: ' + document.field}
                  </p>
                  <p className={document.notes ? '' : 'hidden'}>
                    {'Notes: ' + document.notes}
                  </p>
                  <p>
                    <b>Title: </b>
                    {document.data.title}
                  </p>
                  <p>
                    <b>Summary Text: </b>
                    {document.data.summary_text}
                  </p>
                  <p>
                    <b>Category: </b>
                    {document.data.category}
                  </p>
                  <p>
                    <b>Spider Name: </b>
                    {document.data.spider_name}
                  </p>
                  <p>
                    <b>Publication Date: </b>
                    {document.data.publication_date}
                  </p>
                </td>
                <td>
                  <Button bsStyle="primary" onClick={() => this.showAgainDocument(document.doc_id)}>
                    Show Again
                  </Button>
                </td>
              </tr>
            );
          }
        });
      } else {
        let isProcessed;
        let isSeverityHideNow;
        const isContributorView = this.state.flagged_status
          === CONTRIBUTOR_FLAG.name;
        documents.forEach((document, i) => {
          if (_.isNull(document.flagged)) {
            return;
          }
          if (document.flagged && document.flagged.status !== 'skipped') {
            isProcessed = document.flagged.status !== FLAGGED.name;
            isSeverityHideNow = document.flagged.issue_severity === 'hide_now';
            if (DATA_STATUSES.includes(this.state.flagged_status)) {
              isProcessed = false;
            }
            const contributor_notes = [];
            if (document.flagged.multiple_field) {
              const fields = document.flagged.multiple_field;
              _.forOwn(fields, (value, key) => {
                contributor_notes.push(
                  <p>
                    {key + ': ' + value}
                  </p>
                );
              });
            }
            let status_to_display = document.flagged.status;
            if (this.state.flagged_status === CONTRIBUTOR_FLAG.name) {
              status_to_display = 'Contributor Flag';
            } else if (this.state.flagged_status === DUPLICATE.name) {
              status_to_display = DUPLICATE.value;
            } else if (this.state.flagged_status === POTENTIALLY_DUPLICATE.name) {
              status_to_display = POTENTIALLY_DUPLICATE.value;
            }
            rows.push(
              <tr key={i}>
                <td>
                  <p className="uppercase">
                    {document.id + ' | ' + status_to_display + ' | ' + document.flagged.issue_severity}
                  </p>
                  <p className={document.flagged.field ? '' : 'hidden'}>
                    {'Field: ' + document.flagged.field}
                  </p>
                  <p className={document.flagged.notes ? '' : 'hidden'}>
                    {'Notes: ' + document.flagged.notes}
                  </p>
                  <div className={document.flagged.multiple_field ? '' : 'hidden'}>
                    {'Contributor Notes: ' + contributor_notes}
                  </div>
                  <p>
                    <b>Title: </b>
                    {document.title}
                  </p>
                  <p>
                    <b>Summary Text: </b>
                    {document.summary_text}
                  </p>
                  <p>
                    <b>Category: </b>
                    {document.category}
                  </p>
                  <p>
                    <b>Spider Name: </b>
                    {document.spider_name}
                  </p>
                  <p>
                    <b>Publication Date: </b>
                    {
                      _.get(document.document_misc, 'future_publication_date')
                      || document.publication_date
                    }
                  </p>
                  <p>
                    <b>Agencies: </b>
                    {get_agencies(document)}
                  </p>
                  {
                    _.get(document.duplicate, 'length', null)
                    && (
                      <p>
                        <b>Duplicate: </b>
                        {document.duplicate.map(duplDoc => (
                          <>
                            <a href={duplDoc.web_url || duplDoc.pdf_url}>{duplDoc.id}</a>
                            {', '}
                          </>
                        ))}
                      </p>
                    )
                  }
                  {
                    _.get(document.potential_duplicate, 'length', null)
                    && (
                      <p>
                        <b>Potential Duplicate: </b>
                        {document.potential_duplicate.map(duplDoc => (
                          <>
                            <a href={duplDoc.web_url || duplDoc.pdf_url}>{duplDoc.id}</a>
                            {', '}
                          </>
                        ))}
                      </p>
                    )
                  }
                </td>
                <td>
                  {haveEditAccess && (
                    <React.Fragment>
                      <Button
                        className={isProcessed ? 'hidden' : ''}
                        bsStyle="primary"
                        onClick={() => this.openModal(document.id, document.flagged)}
                      >
                        Update.
                      </Button>
                      <Button
                        className={
                          isProcessed
                          || isSeverityHideNow
                          || isContributorView
                            ? 'hidden'
                            : ''
                        }
                        bsStyle="primary"
                        onClick={() => {
                          this.unflagDocument(
                            document.id, document.flagged.user_flagged_document_id, document
                          );
                        }}
                      >
                        {
                          [
                            DUPLICATE.name,
                            POTENTIALLY_DUPLICATE.name
                          ].includes(this.state.flagged_status)
                            ? 'Not Duplicate'
                            : 'Unflag'
                        }
                      </Button>
                      <Button
                        className={
                          this.state.flagged_status === CONTRIBUTOR_FLAG.name
                            ? ''
                            : 'hidden'
                        }
                        bsStyle="primary"
                        onClick={() => this.submitWithoutUpdating(
                          document.id,
                          document.flagged.user_flagged_document_id
                        )}
                      >
                        Do not update
                      </Button>
                    </React.Fragment>
                  )}
                </td>
              </tr>
            );
          }
        });
      }
    }
    if (documentes) {
      let isProcessed;
      let isSeverityHideNow;
      const isContributorView = this.state.flagged_status
        === CONTRIBUTOR_FLAG.name;
      documentes.forEach((document, i) => {
        if (_.isNull(document.flagged)) {
          return;
        }
        if (document.flagged.status !== 'skipped') {
          isProcessed = document.flagged.status !== FLAGGED.name;
          isSeverityHideNow = document.flagged.issue_severity === 'hide_now';
          const contributor_notes = [];
          if (document.flagged.multiple_field) {
            const fields = document.flagged.multiple_field;
            _.forOwn(fields, (value, key) => {
              contributor_notes.push(
                <p>
                  {key + ': ' + value}
                </p>
              );
            });
          }
          let status_to_display = document.flagged.status;
          if (this.state.flagged_status === CONTRIBUTOR_FLAG.name) {
            status_to_display = 'Contributor Flag';
          } else if (this.state.flagged_status === DUPLICATE.name) {
            status_to_display = DUPLICATE.value;
          } else if (this.state.flagged_status === POTENTIALLY_DUPLICATE.name) {
            status_to_display = POTENTIALLY_DUPLICATE.value;
          }
          rows.push(
            <tr key={i}>
              <td>
                <p className="uppercase">
                  {document.id + ' | ' + status_to_display + ' | ' + document.flagged.issue_severity}
                </p>
                <p className={document.flagged.field ? '' : 'hidden'}>
                  {'Field: ' + document.flagged.field}
                </p>
                <p className={document.flagged.notes ? '' : 'hidden'}>
                  {'Notes: ' + document.flagged.notes}
                </p>
                <div className={document.flagged.multiple_field ? '' : 'hidden'}>
                  {'Contributor Notes: ' + contributor_notes}
                </div>
                <p>
                  <b>Title: </b>
                  {document.title}
                </p>
                <p>
                  <b>Summary Text: </b>
                  {document.summary_text}
                </p>
                <p>
                  <b>Category: </b>
                  {document.category}
                </p>
                <p>
                  <b>Spider Name: </b>
                  {document.spider_name}
                </p>
                <p>
                  <b>Publication Date: </b>
                  {document.publication_date}
                </p>
                <p>
                  <b>Agencies: </b>
                  {get_agencies(document)}
                </p>
              </td>
              <td>
                {haveEditAccess && (
                  <React.Fragment>
                    <Button
                      className={isProcessed ? 'hidden' : ''}
                      bsStyle="primary"
                      onClick={() => this.openModal(document.id, document.flagged)}
                    >
                      Update
                    </Button>
                    <Button
                      className={isProcessed || isSeverityHideNow || isContributorView ? 'hidden' : ''}
                      bsStyle="primary"
                      onClick={() => this.unflagDocument(
                        document.id, document.flagged.user_flagged_document_id
                      )}
                    >
                      Unflag
                    </Button>
                    <Button
                      className={
                        this.state.flagged_status === CONTRIBUTOR_FLAG.name
                          ? ''
                          : 'hidden'
                      }
                      bsStyle="primary"
                      onClick={() => this.submitWithoutUpdating(
                        document.id,
                        document.flagged.user_flagged_document_id
                      )}
                    >
                      Do not update
                    </Button>
                  </React.Fragment>
                )}
              </td>
            </tr>
          );
        }
      });
    }

    return (
      <div className="main-container">
        <Form horizontal>
          <FormGroup bsSize="small">
            <Col sm={6}>
              <h1 id="menu" className="aggregatedAnnotations-head">
                Update Documents
              </h1>
            </Col>
            <Col sm={4}>
              <ControlLabel>Document ID</ControlLabel>
              <FormControl
                type="text"
                value={this.state.id}
                onChange={e => this.handleFieldChange('id', e)}
              />
            </Col>
            <Col sm={2}>
              <Button
                bsStyle="primary"
                style={{ fontSize: '10px', marginTop: '21px', height: '33px', paddingTop: '8px' }}
                bsSize="xsmall"
                onClick={this.handleFilterDocId}
              >
                Search
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <Form horizontal>
          <FormGroup bsSize="small">
            <Col sm={4}>
              <ControlLabel>Filter by status</ControlLabel>
              <FormControl
                componentClass="select"
                defaultValue={this.state.flagged_status}
                onChange={e => this.handleStatusChange('flagged_status', e)}
              >
                {UPDATE_DOCS_STATUSES.map(stats => (
                  <option key={stats.value} value={stats.name}>{stats.value}</option>
                ))}
              </FormControl>
            </Col>
            <Col sm={8}>
              <ControlLabel>Agencies</ControlLabel>
              <Select
                options={agencies_options}
                value={this.state.agency_id}
                multi
                onChange={objs => this.handleAgencyChange('agency_id', objs.map(obj => obj.value).join(','))}
                disabled={this.state.hidden_documents_view}
              />
            </Col>
          </FormGroup>
          <Button bsStyle="primary" bsSize="xsmall" onClick={this.handleFilter}>
            Filter
          </Button>
        </Form>
        <Pagination
          bsSize="small"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          items={max_buttons}
          maxButtons={5}
          activePage={this.state.page}
          onSelect={this.handlePageChange}
        />
        <Table striped condensed hover>
          <tbody>{rows}</tbody>
        </Table>
        <Pagination
          bsSize="small"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          items={max_buttons}
          maxButtons={5}
          activePage={this.state.page}
          onSelect={this.handlePageChange}
        />
        <DocumentUpdateModal
          close={this.close}
          document_id={this.state.openedDocumentId}
          userFlaggedDocumentsID={this.state.userFlaggedDocumentsID}
          showModal={this.state.showModal}
          updateDocumentsResult={this.updateDocumentsResult}
          flagged_status={this.state.flagged_status}
          unflagDocument={this.unflagDocument}
          CONTRIBUTOR_FLAG={CONTRIBUTOR_FLAG}
          FUTURE_PUBLICATION={FUTURE_PUBLICATION}
          DUPLICATE={DUPLICATE}
          POTENTIALLY_DUPLICATE={POTENTIALLY_DUPLICATE}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAllDocuments: params => {
      dispatch(fetchAllDocuments(params));
    },
    fetchDocuments: id => {
      dispatch(fetchDocuments(id));
    },
    fetchFullDocuments: id => {
      dispatch(fetchFullDocuments(id));
    },
    flagDocument: (id, data) => {
      return dispatch(flagDocument(id, data));
    },
    fetchHiddenDocuments: params => {
      dispatch(fetchHiddenDocuments(params));
    },
    updateDocument: (id, data) => {
      return dispatch(updateDocument(id, data));
    },
    fetchAgencies: following => {
      dispatch(fetchAgencies());
    },
    fetchFilteredDataDocs: (params, extraParams) => {
      dispatch(fetchFilteredDataDocs(params, extraParams));
    },
    updateDataDocument: (doc_id, params, exactAction) => {
      return dispatch(updateDataDocument(doc_id, params, exactAction));
    }
  };
};

const mapStateToProps = state => {
  return {
    all_documents: state.all_documents,
    documents_full: state.documents_full,
    flagged_document: state.flagged_document,
    hidden_documents: state.hidden_documents,
    agencies: state.agencies,
    viewer: state.current_user
  };
};

const ReduxDocumentUpdate = withAdminPermissions(connect(mapStateToProps, mapDispatchToProps)(DocumentUpdate));

export default ReduxDocumentUpdate;
