import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "./Typography";

function FileUploadInput({
  className,
  labelClassName,
  inputClassName,
  label,
  name,
  placeholder,
  onChange,
  isError,
  hide,
  isDisabled,
  accept,
  uploadedFiles
}) {
  const uploadFiles = event => {
    const parameterFile = event.target.files;
    onChange(name, [...uploadedFiles, ...parameterFile]);
  };

  const removeFile = (index) => {
    onChange(name, uploadedFiles.filter((item, itemIndex) => itemIndex !== index));
  };

  return (
    <div className={cn(
      'file-upload-input_wrapper',
      {
        'file-upload-input_wrapper_hide': hide,
        'file-upload-input_wrapper_disabled': isDisabled
      },
      className
    )}
    >
      <Typography
        variant="label"
        color="gray"
        className={labelClassName}
      >
        {label}
      </Typography>
      <div className={cn(
        'file-upload-input_drop-area-wrapper',
        {
          'file-upload-input_drop-area-wrapper_error': isError,
          'file-upload-input_drop-area-wrapper_disabled': isDisabled
        },
        inputClassName
      )}
      >
        <input
          type="file"
          accept={accept}
          name={name}
          placeholder={placeholder}
          onChange={uploadFiles}
          disabled={isDisabled}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      </div>
      {uploadedFiles && uploadedFiles.map((file, index) => (
        <div
          key={index}
          className={cn('file-upload-input_uploaded-file')}
        >
          <Typography
            variant="p"
          >
            {String(file.label || file.name).substring(0,30)}
          </Typography>
          <CloseIcon
            color="error"
            onClick={() => removeFile(index)}
          />
        </div>
      ))}
    </div>
  );
}


FileUploadInput.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hide: PropTypes.bool,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string
};

FileUploadInput.defaultProps = {
  isError: false,
  hide: false,
  isDisabled: false,
  uploadedFiles: [],
  className: '',
  labelClassName: '',
  inputClassName: '',
  label: '',
  name: '',
  placeholder: '',
  accept: ''
};


export default FileUploadInput;
