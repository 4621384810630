import type { MetaCategoryFromResponse } from 'shared/features/categories/metaCategories/metaCategories.api.types';
import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { CategoryMergeModalHandlers } from './useCategoryMergeModalHandlers.types';
import type { CategoryMergeModalData } from './useCategoryMergeModalData.types';
import type { CategoryMergeModalProps } from '../CategoryMergeModal.types';
import type { ScrapedCategoryFromResponse } from 'shared/features/categories/scrapedCategories/scrapedCategories.types';
import type { useCategoryMergeModalMutations } from './useCategoryMergeModalMutations';

import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';
import * as caiCategoryDataApi from 'shared/features/categories/caiCategories/caiCategories.apiV2';
import * as metaCategoriesApi from 'shared/features/categories/metaCategories/metaCategories.api';
import * as constants from '../CategoryMergeModal.constants';

export const useCategoryMergeModalHandlers = ({
  props,
  mutations,
  localState,
  localActions
}: {
  props: Pick<CategoryMergeModalProps, 'categoryIds' | 'onConfirm'>;
  mutations: ReturnType<typeof useCategoryMergeModalMutations>;
  localState: CategoryMergeModalData['localState'];
  localActions: CategoryMergeModalData['localActions'];
}): CategoryMergeModalHandlers => {
  const handleScrapedCategoriesFetch: CategoryMergeModalHandlers['handleScrapedCategoriesFetch'] =
    async () => {
      try {
        const categoriesResponse = await caiCategoryDataApi.fetchCaiCategoriesData({
          cai_categories: props.categoryIds
        });

        localActions.setCategoriesToMerge(categoriesResponse.items);
        const scrapedCategories = categoriesResponse.items.reduce(
          (scrapedCategories: ScrapedCategoryFromResponse[], caiCategory) => [
            ...scrapedCategories,
            ...(caiCategory?.scraped_categories as ScrapedCategoryFromResponse[])
          ],
          []
        );

        return {
          results: scrapedCategories,
          count: scrapedCategories?.length
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    };

  const handleMetaCategoryFetch: CategoryMergeModalHandlers['handleMetaCategoryFetch'] = async (
    inputString: string
  ) => {
    try {
      const response = await metaCategoriesApi.api_getMetaCategories({ name: inputString });

      return response.items.map((metaCategory: MetaCategoryFromResponse) => ({
        label: metaCategory.name,
        value: metaCategory.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleCaiCategoryFetch: CategoryMergeModalHandlers['handleCaiCategoryFetch'] = async (
    inputString: string
  ) => {
    try {
      const response = await caiCategoryDataApi.fetchCaiCategoriesData({
        name: inputString,
        offset: constants.CAI_CATEGORY_DROPDOWN_OFFSET,
        limit: constants.CAI_CATEGORY_DROPDOWN_LIMIT
      });

      return response.items.map((caiCategory: CaiCategoriesFromResponse) => ({
        ...caiCategory,
        label: `${caiCategory.name} (${caiCategory.id})`,
        value: caiCategory.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleChangeCaiCategory: CategoryMergeModalHandlers['handleChangeCaiCategory'] =
    caiCategory => {
      const category = caiCategory as uiLib.SelectOption & CaiCategoriesFromResponse;
      localActions.setDocCaiCategory(category);
      localActions.setDocMetaCategory({
        label: category?.meta_category?.name,
        value: category?.meta_category?.id
      });
    };

  const handleAddNewCaiCategory: CategoryMergeModalHandlers['handleAddNewCaiCategory'] =
    caiCategory => {
      localActions.setDocCaiCategory({
        label: caiCategory,
        value: 'new',
        type: constants.CREATED_TYPE
      });
      localActions.setDocMetaCategory(null);
    };

  const handleSetSelectedCategory: CategoryMergeModalHandlers['handleSetSelectedCategory'] =
    rowKeys => {
      localActions.setSelectedCategories(rowKeys.map(Number));
    };

  const handleMergeCaiCategory: CategoryMergeModalHandlers['handleMergeCaiCategory'] = () => {
    mutations.mergeCaiCategory.mutate();
    props.onConfirm();
  };

  const handleMetaCategorySelect: CategoryMergeModalHandlers['handleMetaCategorySelect'] =
    value => {
      localActions.setDocMetaCategory(value as uiLib.SelectOption);
    };

  return {
    handleScrapedCategoriesFetch,
    handleMetaCategoryFetch,
    handleCaiCategoryFetch,
    handleSetSelectedCategory,
    handleChangeCaiCategory,
    handleMergeCaiCategory,
    handleMetaCategorySelect,
    handleAddNewCaiCategory
  };
};
