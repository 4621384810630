import {
  CREATE_ANNOTATION_TASK,
  ANNOTATION_TASK_CREATED,
  ANNOTATION_TASK_UPDATED,
  ANNOTATION_TASK_DELETED,
  DELETE_ANNOTATION_TASK,
  UPDATE_ANNOTATION_TASK,
  REQUEST_ANNOTATION_STATISTICS,
  RECEIVE_ANNOTATION_STATISTICS,
  REQUEST_CONTRIBUTOR_STATISTICS,
  RECEIVE_CONTRIBUTOR_STATISTICS,
  REQUEST_ALL_ANNOTATION_TASKS,
  RECEIVE_ALL_ANNOTATION_TASKS,
  REQUEST_ANNOTATION_JOBS,
  RECEIVE_ANNOTATION_JOBS,
  CREATE_ANNOTATIONS_FOR_JOB,
  ANNOTATIONS_FOR_JOB_CREATED,
  REQUEST_ANNOTATION_TASK_TOPIC_GROUPS,
  RECEIVE_ANNOTATION_TASK_TOPIC_GROUPS,
  REQUEST_AGGREGATED_ANNOTATIONS,
  RECEIVE_AGGREGATED_ANNOTATIONS,
  REQUEST_All_SKIPPED_ANNOTATIONS,
  RECEIVE_All_SKIPPED_ANNOTATIONS,
  REQUEST_ANNOTATION_JOB_BY_ID,
  RECEIVE_ANNOTATION_JOB_BY_ID,
  REQUEST_ALL_ANNOTATION_JOBS,
  RECEIVE_ALL_ANNOTATION_JOBS,
  FETCH_ANNOTATION_TASKS_BY_USER,
  FETCH_ONBOARDING_RESULTS
} from './annotations.actions';

export const getInitialAnnotationTaskState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  isDeleting: false,
  updated: false,
  annotation_task: {},
  statistics: {},
  contributor_statistics: {}
});

export const annotation_task = (state = getInitialAnnotationTaskState(), action) => {
  switch (action.type) {
    case CREATE_ANNOTATION_TASK:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case ANNOTATION_TASK_CREATED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        annotation_task: action.annotation_task || state.annotation_task
      };

    case UPDATE_ANNOTATION_TASK:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case ANNOTATION_TASK_UPDATED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        annotation_task: action.annotation_task || state.annotation_task
      };
    case DELETE_ANNOTATION_TASK:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isDeleting: true,
        updated: false
      };
    case ANNOTATION_TASK_DELETED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isDeleting: false,
        updated: true,
        annotation_task: null
      };
    case REQUEST_ANNOTATION_STATISTICS:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case RECEIVE_ANNOTATION_STATISTICS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        statistics: action.statistics
      };
    case REQUEST_CONTRIBUTOR_STATISTICS:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case RECEIVE_CONTRIBUTOR_STATISTICS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        contributor_statistics: action.contributor_statistics
      };
    default:
      return state;
  }
};

export const getInitialAllAnnotationTasksState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  annotation_tasks: []
});

export const all_annotation_tasks = (state = getInitialAllAnnotationTasksState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_ANNOTATION_TASKS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ALL_ANNOTATION_TASKS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        annotation_tasks: action.annotation_tasks,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialAnnotationTaskTopicGroupsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  annotation_task_topic_groups: []
});

export const annotation_task_topic_groups = (
  state = getInitialAnnotationTaskTopicGroupsState(),
  action
) => {
  switch (action.type) {
    case REQUEST_ANNOTATION_TASK_TOPIC_GROUPS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ANNOTATION_TASK_TOPIC_GROUPS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        annotation_task_topic_groups: action.annotation_task_topic_groups
      };
    default:
      return state;
  }
};

export const getInitialAnnotationJobsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  annotation_jobs: []
});

export const annotation_jobs = (state = getInitialAnnotationJobsState(), action) => {
  switch (action.type) {
    case REQUEST_ANNOTATION_JOBS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ANNOTATION_JOBS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.annotation_jobs,
        updated: false
      };
    case REQUEST_ANNOTATION_JOB_BY_ID:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ANNOTATION_JOB_BY_ID:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.annotation_jobs,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialAnnotationsForJobState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  annotations_for_job: {}
});

export const annotations_for_job = (state = getInitialAnnotationsForJobState(), action) => {
  switch (action.type) {
    case CREATE_ANNOTATIONS_FOR_JOB:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case ANNOTATIONS_FOR_JOB_CREATED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        document: action.annotations_for_job
      };
    default:
      return state;
  }
};

export const getInitialAllAnnotationJobsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  all_annotation_jobs: []
});

export const all_annotation_jobs = (state = getInitialAllAnnotationJobsState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_ANNOTATION_JOBS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ALL_ANNOTATION_JOBS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.all_annotation_jobs,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialAllSkippedAnnotationsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  skipped_annotations: []
});

export const all_skipped_annotations = (state = getInitialAllSkippedAnnotationsState(), action) => {
  switch (action.type) {
    case REQUEST_All_SKIPPED_ANNOTATIONS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_All_SKIPPED_ANNOTATIONS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        skipped_annotations: action.all_skipped_annotations,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialAggregatedAnnotationsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  aggregated_annotations: [],
  total: 0
});

export const aggregated_annotations = (state = getInitialAggregatedAnnotationsState(), action) => {
  switch (action.type) {
    case REQUEST_AGGREGATED_ANNOTATIONS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_AGGREGATED_ANNOTATIONS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        total: action.total,
        aggregated_annotations: action.aggregated_annotations
      };
    default:
      return state;
  }
};

export const getInitialOnboardingUserDetailState = () => ({});
export const onboarding_user_detail = (state = getInitialOnboardingUserDetailState(), action) => {
  switch (action.type) {
    case FETCH_ANNOTATION_TASKS_BY_USER:
      return action.payload;
    default:
      return state;
  }
};

export const getInitialOnboardingResultsState = () => ({});
export const onboarding_results = (state = getInitialOnboardingResultsState(), action) => {
  switch (action.type) {
    case FETCH_ONBOARDING_RESULTS:
      return action.results;
    default:
      return state;
  }
};
