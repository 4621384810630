import uuidv4 from "uuid/v4";
import {
  TEMPLATE_STEP,
  TEMPLATE_CONTEXT,
  TEMPLATE_DOC,
  TEMPLATE_ACTION,
  TEMPLATE_RELATION,
  TEMPLATE_RESET_COUNTER,
  TEMPLATE_META_DATA,
  TEMPLATE_EXTRACT_TAGS,
  TEMPLATE_LOCATION,
  TEMPLATE_PATH,
  TEMPLATE_EXAMPLE,
  UUID_PREFIXES
} from './CrawlSpecCreate.constants';

// ------ CREATE NEW ELEMENTS ------

export const createNewStep = () => ({
  ...TEMPLATE_STEP,
  id: `${UUID_PREFIXES.STEP}${uuidv4()}`
});

export const createNewContext = () => ({
  ...TEMPLATE_CONTEXT,
  id: `${UUID_PREFIXES.CONTEXT}${uuidv4()}`
});

export const createNewDoc = () => ({
  ...TEMPLATE_DOC,
  id: `${UUID_PREFIXES.DOC}${uuidv4()}`
});

export const createNewAction = () => ({
  ...TEMPLATE_ACTION,
  id: `${UUID_PREFIXES.ACTION}${uuidv4()}`
});

export const createNewRelation = () => ({
  ...TEMPLATE_RELATION,
  id: `${UUID_PREFIXES.RELATION}${uuidv4()}`
});

export const createNewResetCounter = () => ({
  ...TEMPLATE_RESET_COUNTER,
  id: `${UUID_PREFIXES.RESET_COUNTER}${uuidv4()}`
});

export const createNewMetaData = () => ({
  ...TEMPLATE_META_DATA,
  id: `${UUID_PREFIXES.META_DATA}${uuidv4()}`
});

export const createNewExtractTags = () => ({
  ...TEMPLATE_EXTRACT_TAGS,
  id: `${UUID_PREFIXES.EXTRACT_TAGS}${uuidv4()}`
});

export const createNewLocation = () => ({
  ...TEMPLATE_LOCATION,
  id: `${UUID_PREFIXES.LOCATION}${uuidv4()}`
});

export const createNewPath = () => ({
  ...TEMPLATE_PATH,
  id: `${UUID_PREFIXES.PATH}${uuidv4()}`
});

export const createNewExample = () => ({
  ...TEMPLATE_EXAMPLE,
  id: `${UUID_PREFIXES.EXAMPLE}${uuidv4()}`
});

export const formatAsLabelValue = (value) => (
  value && { label: value, value });
