import { setNotification, NOTIFICATION_TYPES } from "admin/components/Notification";
import {
  api_getConcepts,
  api_putConcepts,
  api_postConcepts,
  api_deleteConcepts
} from './concepts.api';
import {
  startLoad,
  finishLoad,
  setConcept,
  createConcept,
  updateConcept,
  deleteConcept
} from './concepts.reducer';

const SUCCESS = {
  conceptCreated: 'Concept was successfully created',
  conceptUpdated: 'Concept was successfully updated',
  conceptDeleted: 'Concept was successfully deleted'
};

export const getConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_getConcepts(params)
    .then(res => {
      dispatch(setConcept(res));
      dispatch(finishLoad());
    })
    .catch(res => dispatch(finishLoad()));
};

export const createConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_postConcepts(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.conceptCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createConcept(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const updateConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_putConcepts(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.conceptUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateConcept(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const removeConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_deleteConcepts(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.conceptDeleted, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteConcept(params));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};
