import {
  REQUEST_PROVENANCES,
  RECEIVE_PROVENANCES
} from './provenances.actions';

export const getInitialProvenancesState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const provenances = (state = getInitialProvenancesState(), action) => {
  switch (action.type) {
    case REQUEST_PROVENANCES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_PROVENANCES:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.provenances
      };
    default:
      return state;
  }
};
