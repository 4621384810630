import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { fetchMetrics } from 'shared/features/metrics/metric.actions';
import { fetchConcepts } from "../../../shared/features/jurisdictionAnalyzerConcepts/jurisdictionAnalyzerConcept.actions";
import { fetchDocumentTypes } from 'shared/features/documents/documents.actions';
import { fetchRegulations } from 'shared/features/regulations/regulations.actions';
import { fetchDefaultSources } from 'shared/features/sources/sources.actions';
import { getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.actions';
import { fetchActs } from 'shared/features/acts/acts.actions';
import { clearErrors } from 'shared/features/error/actions';
import MetricsModal from './MetricsModal';

import PopupInfo from '../../components/PopupInfo';
import { popupName } from '../../constants/popupInfoConst';
import Spinner from '../../components/spinner';

class Metrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      metric: {}
    };
  }

  componentDidMount() {
    this.props.fetchMetrics();
    this.props.fetchConcepts();
    this.props.getJurisdictions({ get_all: true });
    this.props.fetchRegulations();
    this.props.fetchDefaultSources();
    this.props.fetchDocumentTypes();
    this.props.clearErrors('jurisdiction_analyzer_metrics');
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openModal = metric => {
    this.setState({ showModal: true, metric });
  };

  closeModal = () => {
    this.setState({ showModal: false, metric: {} });
  };

  render() {
    const { metrics } = this.props;

    const header = (
      <div className="adminToolTableFlexRow flexHeader">
        <div>Metric</div>
        <div>Description</div>
        <div>Concept</div>
        <div>Filters</div>
        <div>Search</div>
        <div>Active</div>
      </div>
    );

    const list_items_flex = metrics
      && metrics.jurisdiction_analyzer_metrics
      && metrics.jurisdiction_analyzer_metrics.map(metric => (
        <div
          key={metric.id}
          className="adminToolTableFlexRow"
          onClick={() => this.openModal(metric)}
        >
          <div>{metric.name}</div>
          <div>{metric.description}</div>
          <div>{metric.concept && metric.concept.name}</div>
          <div>
            <ul className="listOfInfoInTable">
              {_.transform(metric.filters, (result, value, key) => {
                if (key === 'publication_date_from' || key === 'publication_date_to') {
                  result.push(<li key={key}>{`${key}: ${value}`}</li>);
                } else if (_.isArray(value) && value.length > 0) {
                  result.push(<li key={key}>{`${key}: ${value.join(', ')}`}</li>);
                }
              }, [])}
            </ul>
          </div>
          <div>{metric.advance_search}</div>
          <div>{metric.active.toString()}</div>
        </div>
      ));

    return (
      <div className='AdminToolPageTemplate thinBtn'>
        <h1>Jurisdiction Analyzer: Metrics</h1>
        <Button
          bsStyle="primary"
          onClick={() => this.openModal()}
        >
          Create
        </Button>
        <div style={{ clear: 'both' }} />
        <div className="adminToolTableFlex">
          {header}
          {list_items_flex}
        </div>
        <MetricsModal
          closeModal={this.closeModal}
          showModal={this.state.showModal}
          metric={this.state.metric || {}}
        />
        {!this.props.metrics && (
          <div className="spinnerWrapper">
            <Spinner />
          </div>
        )}
        <PopupInfo
          show={!!this.props.errors.jurisdiction_analyzer_metrics}
          onHide={() => {
            this.props.clearErrors('jurisdiction_analyzer_metrics');
          }}
          popupName={popupName.errorRespPopup}
          errorText={
            this.props.errors.jurisdiction_analyzer_metrics
            && this.props.errors.jurisdiction_analyzer_metrics[0]
            && this.props.errors.jurisdiction_analyzer_metrics[0].responseText
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    metrics: state.metrics,
    errors: state.errors
  };
};

const ReduxMetrics = connect(mapStateToProps, {
  fetchMetrics,
  clearErrors,
  getJurisdictions,
  fetchActs,
  fetchRegulations,
  fetchDefaultSources,
  fetchDocumentTypes,
  fetchConcepts
})(Metrics);

export default ReduxMetrics;
