import React, { FC } from 'react';
import { Modal, TextArea } from '@compliance.ai/web-components';
import type { ViewCrawlAutoQAResultModalProps } from './ViewCrawlAutoQAResultModal.types';

export const ViewCrawlAutoQAResultModal: FC<ViewCrawlAutoQAResultModalProps> = ({
  show,
  onHide,
  crawlAutoQA
}: ViewCrawlAutoQAResultModalProps) => {
  return (
    <Modal title="View Crawl Auto QA Result" isOpen={show} onClose={onHide} withFooter={false}>
      <div className="confirmationModal_body">
        <TextArea
          value={crawlAutoQA}
          isDisabled
          maxRows={30}
          minRows={10}
          placeholder="Crawl Auto QA"
        />
      </div>
    </Modal>
  );
};
