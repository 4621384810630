export const colors = {
  WHITE: '#FFFFFF',
  GREY: '#E6E6E6',
  PRIMARY_MEDIUM: '#ffe0b2',
  PRIMARY_LIGHT: '#fff3e0',
  GREEN_MAIN: '#6cb043',
  GREEN_BUTTONS: '#5BB100',
  BLUE_MAIN: '#0B8AFC',
  BLUE_TRANSPARENT: '#E6F3FE',
  BLUE_MAIN_DISABLED: '#4da3fc',
  NAVY_BLUE_NEW: '#068BFF',
  BLACK_MAIN: '#262625',
  GREY_DARK: '#575757',
  GREY_MEDIUM: '#969696',
  GREY_LIGHT: '#d6d5d5',
  INPUT_BORDER_GRAY: '#E5E5E5',
  BACKGROUND_DARK: '#e8e8e8',
  BACKGROUND_MEDIUM: '#f2f2f2',
  BACKGROUND_LIGHT: '#fafafa',
  BACKGROUND_RED: '#FAEBEE',
  BACKGROUND_RED_HOVER: '#f5c0b1',
  YELLOW_WARNING: '#FFCC00',
  BACKGROUND_GREEN: '#EEF7E5',
  BACKGROUND_GREEN_HOVER: '#c2f5ac',
  BACKGROUND_BLUE: '#c4e5f5',
  BACKGROUND_TABS: '#F0F0F0',
  RED_TOMATO: '#f44336',
  INPUT_LABEL: '#B2B2B2',
  LABEL_GRAY: '#4A4A4A',
  INPUT_BORDER_DARK_GRAY: '#CCCCCC',
  SUB_TITLE_DARK: '#2D2D2D',
  LABEL_GRAY_LIGHT: '#C7C7C7',
  SECTION_BORDER_GRAY: '#D8D8D8',
  BLACK: '#000000'
};
