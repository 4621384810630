import type { GlobalState } from 'shared/reducers';
import type { AppData } from './useAppData.types';

import * as userSelectors from 'shared/features/user/user.selectors';

import { useSelector } from 'react-redux';

export const useAppData = (): AppData => {
  const reduxState = useSelector<GlobalState, AppData['reduxState']>(state => ({
    currentUserId: userSelectors.getCurrentUserId(state),
    currentUserEmail: userSelectors.getCurrentUserEmail(state),
    currentUserFullname: userSelectors.getCurrentUserFullname(state),
    currentUserRoles: userSelectors.getCurrentUserRoles(state),
    isCurrentUserInternal: userSelectors.getCurrentIsInternal(state),
    isFetchingCurrentUser: userSelectors.getCurrentIsFetching(state)
  }));

  return {
    reduxState
  };
};
