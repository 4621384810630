import type { FC } from 'react';
import type { AuthCheckProps } from './AuthCheck.types';

import React from 'react';
import { Loader, LOADER_SIZES } from '@compliance.ai/web-components';
import {
  useAuthCheckData,
  useAuthCheckHandlers,
  useAuthCheckLifecycle,
  useAuthCheckQueries
} from './hooks';
import './_auth-check.scss';

export const AuthCheck: FC<AuthCheckProps> = ({ children }) => {
  const { localState, localActions, reduxState } = useAuthCheckData();

  useAuthCheckQueries();

  const handlers = useAuthCheckHandlers({
    localState,
    localActions,
    reduxState
  });

  useAuthCheckLifecycle({
    onDataCheck: handlers.handleDataCheck
  });

  if (!localState.isReady || !reduxState.isAuthenticated) {
    return (
      <Loader
        containerClassName="auth-check__loader-container"
        backgroundClassName="auth-check__loader-background"
        withoutBackground={false}
        size={LOADER_SIZES.LARGE}
      />
    );
  }

  return children;
};
