import React from "react";
import cn from 'classnames';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "./Typography";

const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';

const defaultSizeType = {
  [SMALL]: 'modal_small',
  [MEDIUM]: 'modal_medium',
  [LARGE]: 'modal_large'
};

function Modal({
  title,
  children,
  show,
  onHide,
  className,
  size,
  onCancel,
  titleChildren
}) {
  return (
    <div
      className={cn(
        "modalBackground",
        {
          modalBackground_block: show,
          modalBackground_hide: !show
        },
        className
      )}
      onClick={onHide}
    >
      <div
        className={cn(
          "modal",
          defaultSizeType[size],
        )}
        onClick={e => e.stopPropagation()}
      >
        <header>
          <Typography
            variant="h5"
            className="modal-title"
          >
            {title}
          </Typography>
          <div className="modal-right">
            <div className="modal-right_child">
              {titleChildren}
            </div>
            <Typography
              onClick={() => {
                onCancel();
                onHide();
              }}
              variant="h4"
              className="modal-close"
            >
              <CloseIcon fontSize='large' />
            </Typography>
          </div>
        </header>
        {children}
      </div>

    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(defaultSizeType)),
  onHide: PropTypes.func,
  onCancel: PropTypes.func
};

Modal.defaultProps = {
  title: '',
  show: false,
  size: MEDIUM,
  onHide: () => {
  },
  onCancel: () => {
  }
};

export default Modal;
