import type { CategoryHandlers } from './useScrapedCategoriesHandlers.types';
import type { ComponentProps } from 'react';
import type { ScrapedCategoryFromResponse } from 'shared/features/categories/scrapedCategories/scrapedCategories.types';
import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { JurisdictionFromResponse } from 'shared/features/jurisdictionsData/jurisdictionsData.api.types';
import type { AgencyFromResponse } from 'shared/features/agencies/agencies.api.types';

import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';

export const useScrapedCategoriesSelector = () => {
  return (
    data: Awaited<ReturnType<CategoryHandlers['handleCategoriesFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => {
        const category = row as ScrapedCategoryFromResponse;
        const jurisdiction = category[
          categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION
        ] as JurisdictionFromResponse;
        return {
          [categoryConstants.CATEGORY_ATTRIBUTES.ID]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.ID],
          [categoryConstants.CATEGORY_ATTRIBUTES.NAME]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.NAME],
          [categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT],
          [categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT],
          [categoryConstants.CATEGORY_ATTRIBUTES.DOCUMENT_CAI_CATEGORY]: (
            category[
              categoryConstants.CATEGORY_ATTRIBUTES.DOCUMENT_CAI_CATEGORY
            ] as CaiCategoriesFromResponse
          )?.name,
          [categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION]:
            jurisdiction?.short_name || jurisdiction?.name,
          [categoryConstants.CATEGORY_ATTRIBUTES.AGENCIES]: (
            category[categoryConstants.CATEGORY_ATTRIBUTES.AGENCIES] as AgencyFromResponse[]
          )
            ?.map(agency => agency.short_name || agency.name)
            ?.join(', '),
          [categoryConstants.CATEGORY_ATTRIBUTES.META_CATEGORY]: (
            category[
              categoryConstants.CATEGORY_ATTRIBUTES.DOCUMENT_CAI_CATEGORY
            ] as CaiCategoriesFromResponse
          )?.doc_meta_category?.name,
          [categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION_ID]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION_ID],
          [categoryConstants.CATEGORY_ATTRIBUTES.DOC_CAI_CATEGORY_ID]:
            category[categoryConstants.CATEGORY_ATTRIBUTES.DOC_CAI_CATEGORY_ID]
        };
      })
    };
  };
};
