import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import Switch from '../../../components/Switch';
import { IMPORT_EXPORT_DETAILS, IMPORT_EXPORT_GENERAL } from './DocumentsIngested.constants';

const DocumentsImportExportModal = ({
  show,
  onHide,
  modalAction,
  spiderName
}) => {
  const [importExportDetails, setCrawlImportExportDetails] = useState(IMPORT_EXPORT_DETAILS);
  const [error, setError] = useState(false);

  const closeModal = () => {
    setCrawlImportExportDetails(IMPORT_EXPORT_DETAILS);
    setError(false);
    onHide();
  };

  const handleImportExportAction = () => {
    if (importExportDetails[IMPORT_EXPORT_GENERAL.importExportId]
      && importExportDetails[IMPORT_EXPORT_GENERAL.docIds]) {
      modalAction(importExportDetails);
      closeModal();
    } else {
      setError(true);
    }
  };

  const handleChanges = (name, value) => {
    if ([IMPORT_EXPORT_GENERAL.exportMode, IMPORT_EXPORT_GENERAL.importMode].includes(name)) {
      return setCrawlImportExportDetails({
        ...importExportDetails,
        [IMPORT_EXPORT_GENERAL.exportMode]: !importExportDetails[IMPORT_EXPORT_GENERAL.exportMode],
        [IMPORT_EXPORT_GENERAL.importMode]: !importExportDetails[IMPORT_EXPORT_GENERAL.importMode]
      });
    }
    return setCrawlImportExportDetails({
      ...importExportDetails,
      [name]: value
    });
  };

  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  return (
    <Modal
      className="crawlDocumentsIngested_deprecationModal"
      title="Confirm Import/Export Documents"
      show={show}
      onHide={closeModal}
      size="small"
    >
      <div className="crawlDocumentsIngested_deprecationModal_body">
        <div className="crawlDocumentsIngested_deprecationModal_body_content">
          <TextInput
            className="crawlDocumentsIngested_deprecationModal_body_content_confirmInput"
            name={IMPORT_EXPORT_GENERAL.importExportId}
            value={importExportDetails[IMPORT_EXPORT_GENERAL.importExportId]}
            onChange={handleChangesEvent}
            isError={error}
            label={<Typography variant="tag" color="gray">Import/Export Id</Typography>}
          />
          <TextInput
            className="crawlDocumentsIngested_deprecationModal_body_content_confirmInput"
            value={spiderName}
            isDisabled
            label={<Typography variant="tag" color="gray">Spider Name</Typography>}
          />
          <TextInput
            className="crawlDocumentsIngested_deprecationModal_body_content_confirmInput"
            name={IMPORT_EXPORT_GENERAL.docIds}
            value={importExportDetails[IMPORT_EXPORT_GENERAL.docIds]}
            onChange={handleChangesEvent}
            isError={error}
            label={<Typography variant="tag" color="gray">Documents Ids</Typography>}
          />
          <Switch
            name={IMPORT_EXPORT_GENERAL.importMode}
            label="Import Mode"
            value={importExportDetails[IMPORT_EXPORT_GENERAL.importMode]}
            onChange={handleChangesEvent}
          />
          <Switch
            name={IMPORT_EXPORT_GENERAL.exportMode}
            label="Export Mode"
            value={importExportDetails[IMPORT_EXPORT_GENERAL.exportMode]}
            onChange={handleChangesEvent}
          />
        </div>
        <div className="buttonBlock">
          <Button onClick={closeModal} color="highlighted" size="small">Cancel</Button>
          <Button onClick={handleImportExportAction} color="blue" size="small">Import/Export Documents</Button>
        </div>
      </div>
    </Modal>
  );
};

DocumentsImportExportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  modalAction: PropTypes.func.isRequired,
  spiderName: PropTypes.string
};

DocumentsImportExportModal.defaultProps = {
  spiderName: ''
};

export default DocumentsImportExportModal;
