import type { CategoryEditModalProps } from '../CategoryEditModal.types';
import type { CategoryEditModalData } from './useCategoryEditModalData.types';
import type { CategoryEditModalFormattedData } from './useCategoryEditModalFormattedData.types';
import type { ScrapedCategoryFromResponse } from 'shared/features/categories/scrapedCategories/scrapedCategories.types';
import type { useCategoryEditModalMutations } from './useCategoryEditModalMutations';

import { useMemo } from 'react';

export const useCategoryEditModalFormattedData = ({
  props,
  localState,
  mutations
}: {
  props: Pick<CategoryEditModalProps, 'category'>;
  localState: CategoryEditModalData['localState'];
  mutations: ReturnType<typeof useCategoryEditModalMutations>;
}): CategoryEditModalFormattedData =>
  useMemo(
    () => ({
      isSubmitButtonDisabled: !localState.caiCategoryName || mutations.updateCaiCategory.isLoading,
      jurisdictions: (props.category?.scraped_categories as ScrapedCategoryFromResponse[])?.map(
        item => ({
          label: item?.jurisdiction?.short_name || item?.jurisdiction?.name,
          value: item?.id
        })
      )
    }),
    [
      localState.caiCategoryName,
      mutations.updateCaiCategory.isLoading,
      props.category?.scraped_categories
    ]
  );
