import type { CrawlActivityTableHandlers } from './useCrawlActivityTableHandlers.types';
import type { CrawlActivityTableData } from './useCrawlActivityTableData.types';

import * as errorUtils from 'shared/utils/errors';
import * as spiderRunReportsApi from 'shared/features/crawlerSettings/spiderRunReports/spiderRunReports.api';
import * as constants from '../CrawlActivityTable.constants';

import { TABLE_VARIABLES } from '../CrawlActivityTable.constants';

import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const useCrawlActivityTableHandlers = ({
  localState,
  localActions
}: {
  localState: CrawlActivityTableData['localState'];
  localActions: CrawlActivityTableData['localActions'];
}): CrawlActivityTableHandlers => {
  const { id } = useParams();

  const handleFiltersChange: CrawlActivityTableHandlers['handleFiltersChange'] = filters => {
    localActions.setFilters(filters);
    localActions.setRefetchTrigger(trigger => !trigger);
  };

  const handleReportsFetch: CrawlActivityTableHandlers['handleReportsFetch'] = useCallback(
    async params => {
      try {
        const response = await spiderRunReportsApi.getSpiderRunReports(Number(id), {
          ...params,
          ...localState.filters,
          ...constants.REPORTS_BASE_QUERY_PARAMS
        });

        return {
          results: response.items,
          count: response.count
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    },
    [id, localState.filters]
  );

  const handleChildReportsFetch: CrawlActivityTableHandlers['handleChildReportsFetch'] =
    useCallback(
      async row => {
        try {
          const response = await spiderRunReportsApi.getSpiderRunReports(Number(id), {
            ...constants.CHILD_REPORTS_BASE_QUERY_PARAMS,
            parent_report_id: row[TABLE_VARIABLES.id]
          });

          return {
            results: response.items,
            count: response.count
          };
        } catch (e) {
          errorUtils.logError(e);

          return {
            results: [],
            count: 0
          };
        }
      },
      [id]
    );

  return {
    handleFiltersChange,
    handleReportsFetch,
    handleChildReportsFetch
  };
};
