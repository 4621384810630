import type { EitlLabelsHandlers } from './useEitlLabelsHandlers.types';

import { useDidMount } from '@compliance.ai/web-components';

export const useEitlLabelsLifecycle = ({
  onOrganizationFetch
}: {
  onOrganizationFetch: EitlLabelsHandlers['handleOrganizationFetch'];
}) => {
  useDidMount(() => {
    onOrganizationFetch();
  });
};
