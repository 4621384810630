import type { DocumentStatusProps } from './DocumentStatus.types';
import type { FC } from 'react';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as documentUtils from 'utils/document';
import * as constants from './DocumentStatus.constants';

import { useDocumentStatusData } from './hooks';

import './_document-status.scss';

export const DocumentStatus: FC<DocumentStatusProps> = ({
  pipelineStatus,
  documentExaminationRecord
}) => {
  const { formattedData } = useDocumentStatusData({
    pipelineStatus,
    documentExaminationRecord
  });

  if (!formattedData.statusText) {
    return <uiLib.Typography className="document-status__empty-value">None</uiLib.Typography>;
  }

  return (
    <uiLib.Tooltip
      title={formattedData.tooltipText}
      isDisabled={!formattedData.shouldRenderTooltip}
    >
      <uiLib.Typography
        status={constants.DOCUMENT_STATUS_COLORS[documentUtils.getDocStatusColor(pipelineStatus)]}
        isBold
        className="document-status__text"
      >
        {formattedData.statusText}
        {formattedData.shouldRenderTooltip && <uiLib.IconInfoRounded />}
      </uiLib.Typography>
    </uiLib.Tooltip>
  );
};
