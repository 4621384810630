import type { NumberOfIssuesCellHandlers } from './useNumberOfIssuesCellHandlers.types';
import type { NumberOfIssuesCellData } from './useNumberOfIssuesCellData.types';

export const useNumberOfIssuesCellHandlers = ({
  localActions
}: {
  localActions: NumberOfIssuesCellData['localActions'];
}): NumberOfIssuesCellHandlers => {
  const handleModalToggle: NumberOfIssuesCellHandlers['handleModalToggle'] = isOpen => () => {
    localActions.setIsModalOpen(isOpen);
  };

  return {
    handleModalToggle
  };
};
