import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";

const MTypography = ({ children, className, shrink, ...props }) => {
  return (
    <Typography className={className} {...props} shrink={shrink}>{children}</Typography>
  );
};

MTypography.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  shrink: PropTypes.string
};

MTypography.defaultProps = {
  children: null,
  className: '',
  shrink: 'false'
};

export default MTypography;
