import React from "react";
import PropTypes from "prop-types";
import { useHistory } from 'admin/utils/hooks';
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { AGENCY_ATTRIBUTES } from './Sources.constants';
import SourcesHeaderSearchModal from './SourcesHeaderSearchModal/SourcesSearchModal';
import AGENCY_ROUTES from "../../../constants/routes";

const SourcesHeader = ({
  changeFilter,
  clearSearch,
  requestAgencies,
  search,
  show,
  toggleSearch,
  agenciesCount
}) => {
  const history = useHistory();

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    changeFilter(name, value);
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') toggleSearch();
  };

  return (
    <div className="agency_header">
      <div className="agency_header-search">
        <TextInput
          className="agency_header_multi-input"
          placeholder="Search Agency"
          onChange={handleChangesEvent}
          name={AGENCY_ATTRIBUTES.multiField}
          value={search[AGENCY_ATTRIBUTES.multiField]}
          onKeyPress={handleEnterPress}
        />
        <Button
          onClick={toggleSearch}
        >
          {`Filters (${agenciesCount})`}
        </Button>
        {/*<Button disabled onClick={() => {}} color="highlighted">Bulk Edit</Button>*/}
      </div>
      <Button onClick={() => {
        history.push(`${AGENCY_ROUTES.agenciesAdminTool}/new`);
      }}
      >
        Add Source
      </Button>
      <SourcesHeaderSearchModal
        clearSearch={clearSearch}
        requestAgencies={requestAgencies}
        changeFilter={changeFilter}
        handleChangesEvent={handleChangesEvent}
        search={search}
        show={show}
      />
    </div>
  );
};

SourcesHeader.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  requestAgencies: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  search: PropTypes.shape({}),
  show: PropTypes.bool,
  agenciesCount: PropTypes.number
};

SourcesHeader.defaultProps = {
  search: {},
  show: false,
  agenciesCount: 0
};

export default SourcesHeader;
