import type { EitlLabelsSelectHandlers } from './useEitlLabelsSelectHandlers.types';
import type { EitlLabelsSelectData } from './useEitlLabelsSelectData.types';

import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';
import * as constants from '../EitlLabelsSelect.constants';

import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';

export const useEitlLabelsSelectHandlers = ({
  localActions
}: {
  localActions: EitlLabelsSelectData['localActions'];
}): EitlLabelsSelectHandlers => {
  const eitlLabelsApi = useEitlLabelsApi();

  const handleEitlLabelsFetch: EitlLabelsSelectHandlers['handleEitlLabelsFetch'] =
    async inputValue => {
      try {
        const response = await eitlLabelsApi.getEitlLabels({
          name: inputValue.toLowerCase(),
          limit: constants.EITL_LABELS_COUNT_PER_REQUEST
        });

        return eitlLabelsApiHelpers.formatEitlLabelsOptions(response);
      } catch (e) {
        return [];
      }
    };

  const handleEitlLabelsSelectToggle: EitlLabelsSelectHandlers['handleEitlLabelsSelectToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  return {
    handleEitlLabelsFetch,
    handleEitlLabelsSelectToggle
  };
};
