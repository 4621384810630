import type { FC } from 'react';
import type { JurisdictionCellProps } from './JurisdictionCell.types';

import * as uiLib from '@compliance.ai/web-components';

import { useJurisdictionCellData } from './hooks/useJurisdictionCellData';

export const JurisdictionCell: FC<JurisdictionCellProps> = ({ row }) => {
  const { formattedData } = useJurisdictionCellData(row);

  return (
    <div>
      {formattedData.jurisdictionsText.map(jurisdictionText => (
        <uiLib.Tooltip isInteractive title={jurisdictionText}>
          <uiLib.Typography shouldTruncate>{jurisdictionText}</uiLib.Typography>
        </uiLib.Tooltip>
      ))}
    </div>
  );
};
