import * as uiLib from '@compliance.ai/web-components';

export const REDUCER_NAME = 'externalApiCrawl';

export const SPIDER_NAMES_COUNT_PER_REQUEST = 100;

export const EXTERNAL_API_CRAWL_SUCCESS_MESSAGES = {
  CREATED: {
    message: 'External APi Crawl successfully created',
    title: 'Success',
    type: uiLib.ALERT_TYPES.SUCCESS
  },
  UPDATED: {
    message: 'External APi Crawl successfully updated',
    title: 'Success',
    type: uiLib.ALERT_TYPES.SUCCESS
  },
  DELETED: {
    message: 'External APi Crawl successfully deleted',
    title: 'Success',
    type: uiLib.ALERT_TYPES.SUCCESS
  }
};
