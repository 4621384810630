import type { ReactNode } from 'react';
import type { HistoryFromResponse } from 'shared/features/crawlerSettings/crawlerHistory/crawlerHistory.api.types';
import type { ElementField } from './CrawlerHistoryElement.types';

import { SKIP_FIELDS } from './CrawlerHistoryElement.constants';
import CrawlerHistoryElementLine from './CrawlerHistoryElementLine';

export const isBoolean = (value: ElementField) => {
  return typeof value === 'boolean';
};

export const isNumber = (value: ElementField) => {
  return typeof value === 'number';
};

export const isString = (value: ElementField) => {
  return typeof value === 'string';
};

export const isArray = (value: ElementField) => {
  return Array.isArray(value);
};
export const isObject = (value: ElementField) => {
  return typeof value === 'object';
};

export const collectFields = ({ data }: { data: HistoryFromResponse['new_data'] }): ReactNode[] => {
  if (!data) return [];
  return Object.entries(data).map(field => {
    const label = field[0];
    const value = field[1];

    if (SKIP_FIELDS.includes(label)) return null;
    else if (!isBoolean(value) && !value)
      return CrawlerHistoryElementLine({ label, value: String('null') });
    else if (isBoolean(value) || isNumber(value) || isString(value))
      return CrawlerHistoryElementLine({ label, value: String(value) });
    else if (isArray(value)) {
      if (!(value as []).length) CrawlerHistoryElementLine({ label, value: String('[]') });
      return CrawlerHistoryElementLine({
        label,
        value: (value as []).map((text: ElementField) => JSON.stringify(text)).join(', ')
      });
    } else if (isObject(value)) {
      return collectFields({ data: value as HistoryFromResponse['new_data'] });
    } else return CrawlerHistoryElementLine({ label, value: String('null') });
  });
};
