import type { GetSpiderRunReports } from './spiderRunReports.api.types';

import * as fetch from 'fetch';

import axios from 'axios';

export const getSpiderRunReports: GetSpiderRunReports = (crawlId, params) => {
  return fetch.fetch({
    url: `/spider_run_reports/${crawlId}`,
    dataType: 'Reports',
    method: 'GET',
    baseApi: fetch.BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const getExtraReportInfo = (link: string) => {
  return axios(link);
};

/**
 * Backwards compatability
 */
export const api_getSpiderRunReports = getSpiderRunReports;
export const api_getExtraReportInfo = getExtraReportInfo;
