import type { EitlLabelRenameModalData } from './useEitlLabelRenameModalData.types';
import type { EitlLabelRenameModalProps } from '../EitlLabelRenameModal.types';

import { useMemo, useState } from 'react';

export const useEitlLabelRenameModalData = ({
  props
}: {
  props: Pick<EitlLabelRenameModalProps, 'label'>;
}): EitlLabelRenameModalData => {
  const [eitlLabelName, setEitlLabelName] = useState<
    EitlLabelRenameModalData['localState']['eitlLabelName']
  >(props.label.name);

  const localState: EitlLabelRenameModalData['localState'] = {
    eitlLabelName
  };

  const localActions: EitlLabelRenameModalData['localActions'] = useMemo(
    () => ({
      setEitlLabelName
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
