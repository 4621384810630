import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './externalApiCrawl.constants';

export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const externalApiCrawlsReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setExternalApiCrawl: (state, action) => action.payload,
    createExternalApiCrawl: (state, action) => ({
      ...state,
      count: state.count + 1,
      items: [...state.items, action.payload]
    }),
    updateExternalApiCrawl: (state, action) => ({
      ...state,
      items: state.items.map(item => (item.id === action.payload.id ? action.payload : item))
    }),
    deleteExternalApiCrawl: (state, action) => ({
      ...state,
      count: state.count - 1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default externalApiCrawlsReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setExternalApiCrawl,
  createExternalApiCrawl,
  updateExternalApiCrawl,
  deleteExternalApiCrawl
} = externalApiCrawlsReducer.actions;
