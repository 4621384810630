import { createSelector } from 'reselect';
import { GlobalState } from '../../reducers';
import { CommandRequestFromResponse } from './commands.api.types';

export const getCommandRequestsReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.command_requests
);

export const getCommandRequest = createSelector(
  getCommandRequestsReducer,
  (currentCommandRequestsReducer): CommandRequestFromResponse[] => {
    return currentCommandRequestsReducer?.items ?? [];
  }
);

export const getFirstCommandRequest = createSelector(
  getCommandRequest,
  (currentCommandRequestsReducer): CommandRequestFromResponse => {
    return currentCommandRequestsReducer[0] ?? {};
  }
);

export const getExactCommandRequests = (state: GlobalState, commandRequestName: string) =>
  state.command_requests.items.filter(
    (item: CommandRequestFromResponse) => item?.type?.parameters?.type === commandRequestName
  );

export const getExactCommandRequestsByName = (state: GlobalState, commandRequestName: string) =>
  state.command_requests.items.filter(
    (item: CommandRequestFromResponse) => item?.type?.name === commandRequestName
  );

export const getCommandsReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.commands
);

export const getCommands = createSelector(getCommandsReducer, currentCommandsReducer => {
  return currentCommandsReducer?.items ?? [];
});
