import type { EitlLabelsFilters } from '../EitlLabels.types';

import * as constants from '../EitlLabels.constants';
import * as uiLib from '@compliance.ai/web-components';

export const FILTERS_INITIAL_STATE: EitlLabelsFilters = {
  [constants.EITL_LABELS_FILTER_KEY.CREATED_AT]: '',
  [constants.EITL_LABELS_FILTER_KEY.USER_ID]: null
};

export const EITL_LABELS_PER_PAGE = 50;

export const EITL_LABELS_TABLE_INITIAL_PARAMS: uiLib.Params = {
  [uiLib.TABLE_PARAMS.LIMIT]: EITL_LABELS_PER_PAGE,
  [uiLib.TABLE_PARAMS.OFFSET]: 0,
  [uiLib.TABLE_PARAMS.SORT]: null
};
