import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import cn from 'classnames';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import Typography from "./Typography";

export const INPUT_TYPE = {
  EMAIL: 'email',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel',
  TEXT: 'text',
  URL: 'url'
};

function TextInput({
  className,
  labelClassName,
  inputClassName,
  label,
  placeholder,
  value,
  name,
  onChange,
  isError,
  isDisabled,
  isLink,
  isLabelLink,
  type,
  pattern,
  inputMode,
  onKeyPress,
  errorText
}) {
  const renderInput = (renderLink) => {
    if (renderLink) {
      const text = placeholder || value;
      return (
        <a
          href={value}
          title={placeholder}
          className={cn(
            'text-input_link',
            {
              'text-input_link_empty': !value
            },
            inputClassName
          )}
        >
          {text && text.substring(0, 50)}
        </a>
      );
    }
    return (
      <input
        className={cn(
          'text-input_input',
          {
            'text-input_input_error': isError,
            'text-input_input_disabled': isDisabled
          },
          inputClassName
        )}
        inputMode={inputMode}
        name={name}
        type={type}
        pattern={pattern}
        value={_.isNull(value) || _.isUndefined(value) ? "" : value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
        onKeyPress={onKeyPress}
      />
    );
  };
  return (
    <label
      className={cn(
        'text-input_wrapper',
        className
      )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
        {
          isLabelLink && (
            <a
              href={value}
              title={placeholder}
            >
              <LaunchRoundedIcon />
            </a>
          )
        }
      </Typography>
      {renderInput(isLink && isDisabled)}
      <Typography
        variant="tag"
        color="red"
        hidden={!(isError && errorText)}
      >
        {errorText}
      </Typography>
    </label>
  );
}


TextInput.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLink: PropTypes.bool,
  isLabelLink: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  pattern: PropTypes.string,
  inputMode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  errorText: PropTypes.string
};

TextInput.defaultProps = {
  isError: false,
  isDisabled: false,
  isLink: false,
  isLabelLink: false,
  className: '',
  labelClassName: '',
  inputClassName: '',
  label: '',
  placeholder: '',
  name: '',
  type: '',
  pattern: '',
  inputMode: '',
  onKeyPress: () => {
  },
  errorText: ''
};


export default TextInput;
