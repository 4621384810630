import {fetch, BASE_API_OPTIONS} from "fetch";

export function api_postDocumentRelation(params) {
  return fetch({
    url: `/document-relation`,
    dataType: 'Document',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putDocumentRelation(params) {
  return fetch({
    url: `/document-relation`,
    dataType: 'Document',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_getDocumentLanguages(params) {
  return fetch({
    url: `/languages`,
    dataType: 'Document',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
