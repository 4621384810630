import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

const CrawlerHistoryElementLine: FC<uiLib.SelectOption> = ({ label, value }) => {
  return (
    <div className="crawler-history_field">
      <uiLib.Typography isBold>{label}</uiLib.Typography>
      <uiLib.Typography>{value}</uiLib.Typography>
    </div>
  );
};

export default CrawlerHistoryElementLine;
