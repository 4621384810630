import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const DeleteErrorModal = ({
  close_error_modal,
  email,
  errorText,
  showModal
}) => {
  const [error, setErrorValue] = useState('Unspecified error');

  useEffect(() => {
    if (errorText.includes('unsuccessful')) {
      setErrorValue('Error: Delete unsuccessful. Something went wrong :(');
    } else if (errorText.includes('doesn\'t exist')) {
      setErrorValue(`Error: ${email} not found.`);
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorText]);

  return (
    <Modal
      show={showModal}
      onHide={close_error_modal}
    >
      <Modal.Header>
        <Modal.Title>{email}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close_error_modal}>Okay</Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteErrorModal.propTypes = {
  errorText: PropTypes.string,
  email: PropTypes.string,
  close_error_modal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

DeleteErrorModal.defaultProps = {
  errorText: '',
  email: ''
};

export default DeleteErrorModal;
