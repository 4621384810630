import type { ReactNode } from 'react';
import type { CommandRequestsData } from './useCommandRequestsData.types';
import type { CommandRequestsHandlers } from './useCommandRequestsHandlers.types';

import * as constants from '../CommandRequests.constants';
import * as elements from '../elements';

export const formatDetails = (
  details: CommandRequestsData['localState']['detailsModalInfo'],
  selectedCommandRequest: CommandRequestsData['localState']['selectedCommandRequest'],
  onOpenModal: CommandRequestsHandlers['handleDetailsModalOpen']
): ReactNode => {
  if (!details) return null;
  else if (details.length < constants.TEXT_LENGTH) return details;
  return (
    <elements.CommandRequestsOpenModalButtonCell
      details={details}
      selectedCommandRequest={selectedCommandRequest}
      onOpenModal={onOpenModal}
    />
  );
};

export const formatLinkDetails = (details: string[]): ReactNode => {
  return <elements.CommandRequestsLinksButtonCell details={details} />;
};
