import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import Typography from '../../../components/Typography';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import { updateDocumentRelations } from '../../../../shared/features/documentRelations/documentRelations.actions';

const DocumentRelationsList = ({
  docRelations,
  currentDocRelationID,
  changeCurrentDocRelation,
  editMode,
  user,
  toggleEditMode
}) => {
  const dispatch = useDispatch();
  const updateDoc = parameters => dispatch(updateDocumentRelations(parameters));

  const updateDocRelation = (item, itemId, id, related_doc_id) => {
    const details = docRelations[itemId];
    if (related_doc_id !== details.related_doc_id) {
      const user_details = `${_.get(user, 'first_name')} ${_.get(user, 'last_name')}`;
      updateDoc({ related_doc_id, id, user_details });
    }
    toggleEditMode();
  };

  return (
    <div className="document-list_details_items_list">
      {docRelations ? (
        docRelations.map((item, id) => {
          const itemId = _.get(item, 'id');
          const isActive = itemId === currentDocRelationID;
          // TODO This is totally the opposite of the rules of hooks. Fix this nightmare
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [relatedDocID, setRelatedDocID] = useState(_.get(item, 'related_doc_id', null));
          const relationType = _.get(item, 'relation_type', '');
          const relationDetails = _.get(item, 'relation_details', '');
          const language = _.get(item, 'language_details.language_name', null);
          const disableEdit = editMode ? !isActive : true;

          // TODO This is totally the opposite of the rules of hooks. Fix this nightmare
          // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
          useEffect(() => setRelatedDocID(_.get(item, 'related_doc_id', null)), [isActive]);

          return (
            <div
              key={itemId}
              onClick={() => changeCurrentDocRelation(itemId)}
              className={cn('document-list_details_items_list_element', {
                'document-list_details_items_list_element_active': isActive,
                'document-list_details_items_list_element_disabled': !isActive
              })}
            >
              <TextInput
                className="input_2"
                label="Related Document ID:"
                name={relatedDocID || null}
                value={relatedDocID || null}
                isDisabled={disableEdit}
                onChange={e => setRelatedDocID(e.target.value)}
              />
              <Typography variant="p">{`Relation Type: ${relationType}`}</Typography>
              <Typography variant="p">{`Relation Details: ${JSON.stringify(
                relationDetails
              )}`}</Typography>
              <Typography variant="p">{`Language: ${language}`}</Typography>
              {editMode && isActive && (
                <Button
                  size="custom"
                  color="success"
                  onClick={() => updateDocRelation(item, id, itemId, relatedDocID)}
                >
                  Submit
                </Button>
              )}
            </div>
          );
        })
      ) : (
        <div>No document relations found!</div>
      )}
    </div>
  );
};

DocumentRelationsList.propTypes = {
  docRelations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentDocRelationID: PropTypes.number.isRequired,
  changeCurrentDocRelation: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  toggleEditMode: PropTypes.func.isRequired
};

export default DocumentRelationsList;
