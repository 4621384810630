import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_followTopics(params) {
  return fetch({
    url: `/topics`,
    method: 'POST',
    dataType: 'Topics',
    params
  });
}

export function api_fetchTopics() {
  return fetch({
    url: `/topics`,
    method: 'GET',
    dataType: 'Topics'
  });
}

export function api_fetchTopicsStats() {
  return fetch({
    url: `/topics_stats`,
    method: 'GET',
    dataType: 'Topic Stats'
  });
}

export function api_fetchModelValidationTable(topicId) {
  return fetch({
    url: `/topic_annotation_model_validations/${topicId}`,
    method: 'GET',
    dataType: 'TopicAnnotationValidation',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_fetchAllTopics() {
  return fetch({
    url: `/topics`,
    method: 'GET',
    dataType: 'Topics',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_createTopic(params) {
  return fetch({
    url: `/topics`,
    method: 'POST',
    params,
    dataType: 'Topics',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_updateTopic(topicId, params) {
  return fetch({
    url: `/topics/${topicId}`,
    method: 'POST',
    params,
    dataType: 'Topics',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_fetchDocumentCountsForTopics(params) {
  return fetch({
    url: `/documents-counts-for-topics`,
    method: 'GET',
    params,
    dataType: 'DocumentCountForTopic'
  });
}
