import * as apiTypes from './eitlLabels.api.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const getEitlLabels: apiTypes.GetEitlLabels = params => {
  return fetch({
    url: '/eitl_labels/',
    method: 'GET',
    dataType: 'EITL Labels',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    shouldHideErrorNotifications: true
  });
};

export const addEitlLabel: apiTypes.AddEitlLabel = params => {
  return fetch({
    url: '/eitl_labels/',
    method: 'POST',
    dataType: 'EITL Labels',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    shouldHideErrorNotifications: true
  });
};

export const modifyEitlLabel: apiTypes.ModifyEitlLabel = (id, params) => {
  return fetch({
    url: `/eitl_labels/${id}`,
    method: 'PATCH',
    dataType: 'EITL Labels',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    shouldHideErrorNotifications: true
  });
};

export const deleteEitlLabel: apiTypes.DeleteEitlLabel = id => {
  return fetch({
    url: `/eitl_labels/${id}`,
    method: 'DELETE',
    dataType: 'EITL Labels',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    shouldHideErrorNotifications: true
  });
};

export const bulkImportEitlLabels: apiTypes.BulkImportEitlLabels = formData => {
  return fetch({
    url: '/eitl_labels/bulk/',
    method: 'POST',
    dataType: 'EITL Labels',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params: formData,
    shouldHideErrorNotifications: true
  });
};
