import _ from 'lodash';

let from_api_mapping = {};

let to_api_mapping = {};

let to_api_mapping_id = {};

export function category_from_api(name) {
  return from_api_mapping[name] || name;
}

export function categoryToList(categories) {
  return _.flatten(
    _.map(categories, category => _.map(
      to_api_mapping[category], entry => to_api_mapping_id[entry]
    ))
  ).filter(category => category);
}

export function category_to_api_id(name, prefix='category_id=') {
  if (to_api_mapping_id[name]) {
    return prefix + to_api_mapping_id[name];
  }

  return false;
}


export function category_to_api(name, prefix='category=') {
  if (to_api_mapping[name]) {
    return to_api_mapping[name].map(entry => {
      return category_to_api_id(entry) || prefix + encodeURIComponent(entry);
    }).join('&');
  }

  return prefix + name;
}

export const setApiMapping = docTypes => {
  if (!_.isEmpty(docTypes)) {
    if (from_api_mapping !== docTypes.docTypes.scraped_to_app_dic) {
      from_api_mapping = docTypes.docTypes.scraped_to_app_dic;
    }
    if (to_api_mapping !== docTypes.docTypes.app_to_scraped_dic) {
      to_api_mapping = docTypes.docTypes.app_to_scraped_dic;
    }
    if (to_api_mapping_id !== docTypes.docTypes.scrap_category_dic) {
      to_api_mapping_id = docTypes.docTypes.scrap_category_dic;
    }
  }
};

export const docTypesSelector = state => state.docTypes;

// skip News on timeline since it is handled in the news feed
// skip Enforcement Metadata in timeline because there is no guarantee of well-formedness
export const categories_skipped_on_timeline = [
  'Enforcement Metadata',
  'State Code',
  'State Code Navigation',
  'Statute',
  'Statute Navigation',
  'Statutes',
  'Statutes Navigation',
  'US Code',
  'US Code Navigation',
  'US Public Law',
  'US Public Law Navigation',
  'CFR',
  'CFR Navigation'
];

// Skips "Code" categories for date sorted search results, they show near the top due
// to an artifact of how we assign publication dates
export const categories_skipped_in_date_search = [
  'State Code',
  'US Code',
  'US Code Navigation',
  'State Code Navigation',
  'US Public Law',
  'US Public Law Navigation',
  'Statute',
  'Statute Navigation',
  'Statutes',
  'Statutes Navigation'
];


export const categories_skipped_on_search = ['Enforcement Metadata'];
