import React from 'react';
import errorFace from 'shared/images/misc/error-face-24px.png';
import './_defaultFallback.scss';

const DefaultFallback = () => {
  return (
    <div className="defaut-fallback-error-container">
      <img alt='' className="error-message-image" src={errorFace} />
      <h1 className="error-message-text">Something went wrong! Please refresh and try again.</h1>
    </div>
  );
};

export default DefaultFallback;
