import type { AppData } from './useAppData.types';
import type { AppHandlers } from './useAppHandlers.types';

import * as analytics from 'shared/utils/analytics';

import { useUserReduxActions } from 'shared/features/user/hooks';
import { useSourcesReduxActions } from 'shared/features/sources/hooks';
import { useCallback } from 'react';

export const useAppHandlers = ({
  reduxState
}: {
  reduxState: AppData['reduxState'];
}): AppHandlers => {
  const userReduxActions = useUserReduxActions();
  const sourcesReduxActions = useSourcesReduxActions();

  const handleInitialDataFetch: AppHandlers['handleInitialDataFetch'] = useCallback(async () => {
    await Promise.all([
      sourcesReduxActions.fetchDefaultSources(),
      userReduxActions.fetchAllUsers(),
      userReduxActions.fetchAppNotifications({ all: true })
    ]);
  }, [sourcesReduxActions, userReduxActions]);

  const handleMixpanelSetup: AppHandlers['handleMixpanelSetup'] = useCallback(() => {
    if (reduxState.currentUserId && !reduxState.isFetchingCurrentUser) {
      analytics.mixpanel_identify(reduxState.currentUserEmail);
      analytics.mixpanel_people_set({
        $email: reduxState.currentUserEmail,
        user_id: reduxState.currentUserId,
        is_internal: reduxState.isCurrentUserInternal,
        roles: reduxState.currentUserRoles
      });
    }
  }, [
    reduxState.currentUserEmail,
    reduxState.currentUserId,
    reduxState.currentUserRoles,
    reduxState.isCurrentUserInternal,
    reduxState.isFetchingCurrentUser
  ]);

  return {
    handleInitialDataFetch,
    handleMixpanelSetup
  };
};
