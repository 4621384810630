import _ from 'lodash';
import {
  api_getCrawlerRawDryRun,
  api_getCrawlerRawSpec,
  api_postCrawlRawSpec,
  api_getCrawlerRawConfig,
  api_postCrawlRawConfig,
  api_getCrawlSpecOptions
} from './crawlerDocuments.api';
import {
  setCrawlerSpec,
  setCrawlerConfig,
  setCrawlerDryRun,
  setCrawlerSpecOptions
} from './crawlerDocuments.reducer';
import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';

const getCrawlerRawDryRun = crawlId => dispatch =>
  api_getCrawlerRawDryRun(crawlId).then(res => {
    dispatch(setCrawlerDryRun(res));
  });

const getCrawlerRawSpec = crawlId => dispatch =>
  api_getCrawlerRawSpec(crawlId).then(res => {
    dispatch(setCrawlerSpec(res));
  });

const postCrawlRawSpec = crawlSpec => dispatch =>
  api_postCrawlRawSpec(crawlSpec).then(res => {
    setNotification({
      message: { text: _.get(res, 'details', 'Success'), title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(setCrawlerSpec(crawlSpec));
  });

const getCrawlerRawConfig = crawlId => dispatch =>
  api_getCrawlerRawConfig(crawlId).then(res => {
    dispatch(setCrawlerConfig(res));
  });

const getCrawlerSpecOptions = () => dispatch =>
  api_getCrawlSpecOptions().then(res => {
    dispatch(setCrawlerSpecOptions(res));
  });

const postCrawlRawConfig = crawlConfig => dispatch =>
  api_postCrawlRawConfig(crawlConfig).then(res => {
    setNotification({
      message: { text: _.get(res, 'details', 'Success'), title: 'Success' },
      type: NOTIFICATION_TYPES.SUCCESS
    });
    dispatch(setCrawlerConfig(crawlConfig));
  });

const clearCrawSpec = () => dispatch => dispatch(setCrawlerSpec({}));
const clearCrawConfig = () => dispatch => dispatch(setCrawlerConfig({}));
const clearCrawDryRun = () => dispatch => dispatch(setCrawlerDryRun({}));

export {
  getCrawlerRawDryRun,
  getCrawlerRawSpec,
  getCrawlerSpecOptions,
  postCrawlRawSpec,
  postCrawlRawConfig,
  getCrawlerRawConfig,
  clearCrawSpec,
  clearCrawConfig,
  clearCrawDryRun
};
