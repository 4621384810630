import React from 'react';
import './_auth-form-container.scss';

export const AuthFormContainer = ({ children }) => {
  return (
    <div className="auth-form__outer-container">
      <div className="auth-form__container">{children}</div>
    </div>
  );
};
