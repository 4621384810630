import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { BUTTON } from "../../../../constants/fieldTypes";
import Field from "../Fields/EditModeFields";
import { colors } from "admin/components/MaterialComponents/colors/colors";

const useStyles = makeStyles({
  row: {
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowButton: {
    paddingBottom: 20,
    display: 'flex'
  },
  blockWrapper: {
    display: 'flex',
    margin: '10px 20px 10px 20px',
    borderBottom: `1px solid ${colors.GREY_LIGHT}`
  },
  blockLabel: {
    fontSize: 16,
    minWidth: 250,
    fontWeight: 'bold'
  },
  fields: {
    display: 'flex',
    width: '100%',
    '&:not(:last-child)': {
      marginRight: 10
    }
  },
  deleteButtonWrapper: {
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'right',
    alignItems: 'center'
  },
  blockRows: {
    width: '100%'
  }
});

const UserModalMainInfo = ({
  editMode,
  generalTable
}) => {
  const classes = useStyles();

  const mountRow = (row) => {
    return row.map((rowField, index) => {
      return (
        <div
          className={row[0].type === BUTTON
            ? classes.rowButton
            : classes.fields}
          key={`${rowField.label}${index}`}
        >
          <Field rowField={rowField} editMode={editMode} />
        </div>
      );
    });
  };

  return generalTable.map(block => {
    return (
      <div className={classes.blockWrapper} key={block.label}>
        <div className={classes.blockLabel}>
          {block.label}
        </div>
        <div className={classes.blockRows}>
          {Object.values(block.rows).map((row, index) => {
            return (
              <div
                className={row[0].type === BUTTON
                  ? classes.rowButton
                  : classes.row}
                key={row[0].responseName || index}
              >
                {mountRow(row)}
              </div>
            );
          }
          )}
        </div>
      </div>
    );
  });
};

const mapStateToProps = state => ({
  generalTable: state.userModal.generalTable
});

UserModalMainInfo.propTypes = {
  editMode: PropTypes.bool.isRequired,
  generalTable: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(mapStateToProps, null)(UserModalMainInfo);
