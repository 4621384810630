import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectRender from '../../components/SelectRender';
import { exampleFieldTemplate } from './crawlCreation.constants';

const CrawlerCreationExample = ({
  example,
  deleteExample,
  updateExample,
  error,
  disabled
}) => {
  const [collapsed, setCollapse] = useState(false);

  const handleMainSettingsExampleChanges = (e) => {
    updateExample(example.id, { ...example, [e.target.name]: e.target.value });
  };

  return (
    <>
      {!disabled && <div className="smallRoundBtn deleteBtn" onClick={() => deleteExample(example.id)}>x</div>}
      <div className="smallRoundBtn shrinkBtn" onClick={() => setCollapse(!collapsed)}>{collapsed ? '+' : '-'}</div>
      {collapsed ? (
        <div className="adminToolLeftPadding">
          <h5 className="objectName">{example.url}</h5>
        </div>
      ) : (
        <div className="adminToolLeftPadding">
          {exampleFieldTemplate.map(field => (
            <SelectRender
              key={field.state_name}
              item={{ ...field, disabled }}
              state={example}
              handleChange={handleMainSettingsExampleChanges}
              style={["filterAdminToolWrap_wideElem"]}
              error={error}
              popover={field.popover}
            />
          ))
          }
        </div>
      )}
    </>
  );
};

CrawlerCreationExample.propTypes = {
  example: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
    occurrence: PropTypes.string
  }).isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  deleteExample: PropTypes.func.isRequired,
  updateExample: PropTypes.func.isRequired
};

CrawlerCreationExample.defaultProps = {
  error: false,
  disabled: false
};

export default CrawlerCreationExample;
