import type { DropdownProps } from '@compliance.ai/web-components';

export const DROPDOWN_ANCHOR_ORIGIN: DropdownProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'right'
};

export const DROPDOWN_TRANSFORM_ORIGIN: DropdownProps['transformOrigin'] = {
  vertical: 'top',
  horizontal: 'right'
};
