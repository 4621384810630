import type { AppHeaderProps } from './AppHeader.types';
import type { FC } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'shared/images/logos/logo-white-name.svg';
import { Dropdown, IconArrowRightSolid } from '@compliance.ai/web-components';
import ROUTES from '../../constants/routes';
import { DROPDOWN_ANCHOR_ORIGIN, DROPDOWN_TRANSFORM_ORIGIN } from './AppHeader.constants';
import Typography from '../../components/Typography';
import { useAppHeaderHandlers } from './hooks';
import './_app-header.scss';

export const AppHeader: FC<AppHeaderProps> = ({ fullName }) => {
  const handlers = useAppHeaderHandlers();

  return (
    <div className="app-header">
      <div className="app-header__logo-wrapper">
        <Link to={ROUTES.dashboard} className="app-header__logo-link">
          <img src={logo} alt="logo" className="app-header__logo" />
        </Link>
        <div className="app-header__logo-separator" />
        <Link to={ROUTES.dashboard} title="Dashboard" className="app-header__logo-text-link">
          <Typography variant="h4" color="white" bold>
            admin tool
          </Typography>
        </Link>
      </div>
      <Dropdown
        triggerContent={
          <span className="app-header__username">
            {fullName}
            <IconArrowRightSolid className="app-header__arrow" />
          </span>
        }
        anchorOrigin={DROPDOWN_ANCHOR_ORIGIN}
        transformOrigin={DROPDOWN_TRANSFORM_ORIGIN}
        className="app-header__dropdown"
        dropdownContent={
          <ul className="app-header__list">
            <Link to={ROUTES.settings}>
              <i className="fa fa-cog fa-fw" />
              Settings
            </Link>
            <Link to={ROUTES.support}>
              <i className="fa fa-question-circle fa-fw" />
              Support
            </Link>
            <span onClick={handlers.handleLogOut}>
              <i className="fa fa-power-off fa-fw" />
              Logout
            </span>
          </ul>
        }
      />
    </div>
  );
};
