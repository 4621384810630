import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import * as actions from '../sources.actions';

export const useSourcesReduxActions = () => {
  const dispatch = useDispatch();

  const fetchDefaultSources = useCallback(
    async (...args) => {
      return dispatch(actions.fetchDefaultSources(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchDefaultSources
    }),
    [fetchDefaultSources]
  );
};
