import moment from 'moment';
import { CALENDAR } from "../../../admin/constants/fieldTypes";
import { today } from "../../../app/utils/keyDates";
import { PREMIUM_CONTENT_ACCESS_LABEL, PROVIDER_LABEL } from "./labelConstants";

export const formatDate = (date) => moment(date).format('MM/DD/YYYY');

export const isDate = (data) => {
  if (!data) return false;
  return !isNaN(new Date(data).getMonth());
};

export const changeValuesFromModal = (field, action) => {
  if (action.responseName === field.responseName) {
    field.value = action.value;
  }
  return field;
};

export const changeValuesFromAPI = (field, action) => {
  if (field.responseName in action) {
    if (isDate(action[field.responseName]) && field.type !== CALENDAR) {
      field.value = formatDate(action[field.responseName]);
    } else field.value = action[field.responseName];
  } else if (action.properties && field.responseName) {
    if (field.responseName in action.properties) {
      if (isDate(action.properties[field.responseName]) && field.type !== CALENDAR) {
        field.value = formatDate(action.properties[field.responseName]);
      } else field.value = action.properties[field.responseName];
    }
  }
  return field;
};

export const changeGeneralTableValuesAC = (state, action) => {
  state.forEach(block => block.rows.forEach(row => row.forEach(field => {
    if (action.responseName) {
      changeValuesFromModal(field, action);
    } else changeValuesFromAPI(field, action);
  }))
  );

  return state;
};

export const setGeneralTableOptionsAC = (state, action) => {
  state.forEach(block => block.rows.forEach(row => row.forEach(field => {
    if (action[field.optionResponseName]) {
      field.options = action[field.optionResponseName];
    }
  })
  ));
  return state;
};

export const prepareDataForUpdatingUserAC = (initData, values) => {
  values.forEach(block => block.rows.forEach(row => row.forEach(field => {
    if (field.responseName in initData) {
      initData[field.responseName] = field.value;
    }
    if (field.responseName in initData.properties) {
      initData.properties[field.responseName] = field.value;
    }
    if (field.label === PROVIDER_LABEL) {
      initData[field.responseName] = field.value.map(value => value.premium_content_source_id);
    }
  })));
  return initData;
};

export const addAdditionalPremiumContentSourceAC = (state) => {
  const newPremiumContent = {
    created_at: today,
    premium_content_source_id: null,
    premium_content_source_name: ''
  };
  state.forEach(block => {
    if (block.label === PREMIUM_CONTENT_ACCESS_LABEL) {
      block.rows[0][0].value.push(newPremiumContent);
    }
    return block;
  });
  return state;
};


export const removePremiumContentSourceAC = (state, index) => {
  return state.map(block => {
    if (block.label === PREMIUM_CONTENT_ACCESS_LABEL) {
      block.rows[0][0].value.splice(index, 1);
    }
    return block;
  });
};

export const changePremiumContentSourceAC = (state, { index, value }) => {
  state.forEach(block => {
    if (block.label === PREMIUM_CONTENT_ACCESS_LABEL) {
      block.rows[0][0].value[index].premium_content_source_name = value.name;
      block.rows[0][0].value[index].premium_content_source_id = value.id;
    }
  });
  return state;
};
