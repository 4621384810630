import React from 'react';
import PropTypes from "prop-types";
import {
  loadCategoryOptions,
  loadMetaCategoryOptions,
  loadJurisdictionsOptions
} from "./DocumentCategories.helper";
import { SELECT_BOOL, TABLE_VARIABLES } from "./DocumentCategories.constants";
import Select from "../../components/ReactSelect/Select";
import AsyncSelect from "../../components/ReactSelect/SelectAsync";

const DocumentCategoriesFilter = ({
  categories,
  metaCategories,
  setSearchValues,
  search
}) => {
  return (
    <div className="document-categories_filter">
      <AsyncSelect
        label="Scraped category"
        value={search[TABLE_VARIABLES.scraped_category]}
        onChange={e => setSearchValues(TABLE_VARIABLES.scraped_category, e)}
        options={categories}
        loadOptions={value => loadCategoryOptions(
          TABLE_VARIABLES.scraped_category, value, TABLE_VARIABLES.scraped_category
        )}
        isClearable
      />
      <AsyncSelect
        label="App category"
        value={search[TABLE_VARIABLES.app_category]}
        onChange={e => setSearchValues(TABLE_VARIABLES.app_category, e)}
        options={categories}
        loadOptions={value => loadCategoryOptions(
          TABLE_VARIABLES.app_category, value, TABLE_VARIABLES.app_category
        )}
        isClearable
      />
      <AsyncSelect
        label="Meta category"
        value={search[TABLE_VARIABLES.document_meta_category]}
        onChange={e => setSearchValues(TABLE_VARIABLES.document_meta_category, e)}
        options={metaCategories}
        loadOptions={value => loadMetaCategoryOptions(
          'name', value, 'name'
        )}
        isClearable
      />
      <Select
        label="Surface docs in app"
        value={search[TABLE_VARIABLES.surface_docs]}
        options={SELECT_BOOL}
        onChange={e => setSearchValues(TABLE_VARIABLES.surface_docs, e)}
        isClearable
      />
      <Select
        label="Category filters in app"
        value={search[TABLE_VARIABLES.surface_category]}
        options={SELECT_BOOL}
        onChange={e => setSearchValues(TABLE_VARIABLES.surface_category, e)}
        isClearable
      />
      <AsyncSelect
        label="Jurisdictions"
        value={search[TABLE_VARIABLES.jurisdictions]}
        onChange={e => setSearchValues(TABLE_VARIABLES.jurisdictions, e)}
        loadOptions={
          value => loadJurisdictionsOptions('short_name', value, 'short_name')
        }
        isClearable
      />
    </div>

  );
};
DocumentCategoriesFilter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  metaCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  setSearchValues: PropTypes.func.isRequired,
  search: PropTypes.shape({})
};

DocumentCategoriesFilter.defaultProps = {
  categories: [],
  metaCategories: [],
  search: {}
};

export default DocumentCategoriesFilter;
