import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './crawlerHistory.constants';

export const reducerName = REDUCER_NAME;
export const initialState = {
  history: []
};

const crawlerHistory = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setHistoryArray: (state, action) => {
      state.history = action.payload;
    }
  }
});

export default crawlerHistory.reducer;
export const { setHistoryArray } = crawlerHistory.actions;
