// This component used only there
// should be moved to 'component' folder if used in other containers
import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import "./ExpandableBtnList.styles.scss";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";

function ExpandableList({
  className,
  labelClassName,
  btnClassName,
  addMoreClassName,
  label,
  value,
  onChange,
  onClickBtn,
  isError,
  name,
  addMoreName,
  isDisabled,
  isDisabledBtn,
  createNewItemTemplate,
  helpRemoveFunc,
  errorFields,
  errorText,
  activeID
}) {
  const addItem = () => {
    onChange(name, [ ...value, createNewItemTemplate() ]);
  };

  const removeItem = (index, UUID) => {
    onChange(name, value.filter((item, itemIndex) => itemIndex !== index));
    if (helpRemoveFunc) helpRemoveFunc(UUID);
  };

  return (
    <div
      className={cn(
        'expandable-btn-list_wrapper',
        className,
        { 'expandable-btn-list_wrapper_error': isError }
      )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
      </Typography>
      {value.map((item, index) => {
        const itemID = item.id || item;
        const itemActive = itemID === activeID;
        const itemError = errorFields.includes(itemID);
        return (
          <div
            key={index}
            className={cn(
              'expandable-btn-list_item',
              {
                'expandable-btn-list_item_disabled': isDisabled
              }
            )}
          >
            <Button
              className={cn(
                'expandable-btn-list_item_btn',
                {
                  'expandable-btn-list_item_btn_active': itemActive,
                  'expandable-btn-list_item_btn_active_error': itemActive && itemError
                }
              )}
              name={index}
              isError={itemError}
              color="highlighted"
              disabled={isDisabledBtn}
              isDisabledBtn={isDisabled}
              btnClassName={btnClassName}
              onClick={() => onClickBtn(item)}
            >
              {item.name || `${addMoreName || label} ${index + 1}`}
            </Button>
            <CloseIcon
              onClick={() => !item.locked && removeItem(index, itemID)}
              className={cn(
                "expandable-btn-list_item_close-icon",
                {
                  "expandable-btn-list_item_close-icon_active": !item.locked,
                  "expandable-btn-list_item_close-icon_disabled": item.locked
                }
              )}
            />
          </div>
        );
      })}
      <Typography
        variant="label"
        color="blue"
        hidden={isDisabled}
        className={cn(
          addMoreClassName,
          { 'expandable-btn-list_add-more_error': isError }
        )}
        onClick={addItem}
      >
        {`+ Add ${addMoreName || 'More'}`}
      </Typography>
      <Typography
        variant="tag"
        color="red"
        hidden={!(isError && errorText)}
      >
        {errorText}
      </Typography>
    </div>
  );
}


ExpandableList.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDisabledBtn: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  btnClassName: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  label: PropTypes.string,
  name: PropTypes.string,
  addMoreName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClickBtn: PropTypes.func.isRequired,
  createNewItemTemplate: PropTypes.func.isRequired,
  helpRemoveFunc: PropTypes.func,
  errorFields: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
  activeID: PropTypes.string
};

ExpandableList.defaultProps = {
  isError: false,
  isDisabled: false,
  isDisabledBtn: false,
  className: '',
  value: [],
  labelClassName: '',
  btnClassName: '',
  label: '',
  name: '',
  addMoreName: '',
  helpRemoveFunc: () => {},
  errorFields: [],
  errorText: '',
  activeID: ''
};

export default ExpandableList;
