/* eslint-disable max-len */

// XXX: temp list of popular agencies that will soon be included in the
// agency properties in elasticsearch
export const popular_agency_short_names = new Set([
  'CA-BOC',
  'CA-CANLII',
  'CFPB',
  'CFTC',
  'CH-FINMA',
  'DOJ',
  'DOL',
  'EBSA',
  'EOP',
  'Fannie',
  'FDIC',
  'FFIEC',
  'FHA',
  'FINCEN',
  'FINRA',
  'Freddie',
  'FRS',
  'FTC',
  'NCUA',
  'NYSE',
  'OCC',
  'OFAC',
  'RHS',
  'SEC',
  'TREAS',
  'VA',
  'UK-BOE',
  'UK-CMA',
  'UK-FCA',
  'UK-HMT',
  'UK-ICO',
  'UK-PRA',
  'INT-BIS',
  'INT-EBA',
  'INT-EC',
  'INT-FATF',
  'INT-FSB',
  'INT-IOSCO',
  'INT-TWG',
  'UK-LSB',
  'UK-JMLSG'
]);

export const federalAgenciesBeingEvaluated = [];

export const otherFederalAgencies = [
  {
    active: true,
    id: 12,
    name: "Agriculture Department",
    short_name: "USDA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 157,
    name: "Farm Service Agency",
    short_name: "FSA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 142,
    name: "Engineers Corps",
    short_name: "COE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 386,
    name: "Occupational Safety and Health Administration",
    short_name: "OSHA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 530,
    name: "Workers Compensation Programs Office",
    short_name: "OWCP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 204,
    name: "Foreign Claims Settlement Commission",
    short_name: "FCSC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 409,
    name: "Postal Regulatory Commission",
    short_name: "PRC-Reg",
    type: "federal_executive"
  },
  {
    active: true,
    id: 6,
    name: "Agency for International Development",
    short_name: "USAID",
    type: "federal_executive"
  },
  {
    active: true,
    id: 133,
    name: "Employment and Training Administration",
    short_name: "ETA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 511,
    name: "United States Institute of Peace",
    short_name: "USIP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 97,
    name: "Defense Acquisition Regulations System",
    short_name: "DARS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 162,
    name: "Federal Contract Compliance Programs Office",
    short_name: "OFCCP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 176,
    name: "Federal Labor Relations Authority",
    short_name: "FLRA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 179,
    name: "Federal Mediation and Conciliation Service",
    short_name: "FMCS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 186,
    name: "Federal Register Office",
    short_name: "REGISTER",
    type: "federal_executive"
  },
  {
    active: true,
    id: 215,
    name: "Government Ethics Office",
    short_name: "OGE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 235,
    name: "Indian Arts and Crafts Board",
    short_name: "IACB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 354,
    name: "National Intelligence, Office of the National Director",
    short_name: "ODNI",
    type: "federal_executive"
  },
  {
    active: true,
    id: 250,
    name: "Inter-American Foundation",
    short_name: "IAF",
    type: "federal_executive"
  },
  {
    active: true,
    id: 258,
    name: "International Development Cooperation Agency",
    short_name: "IDCA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 278,
    name: "Local Television Loan Guarantee Board",
    type: "federal_executive"
  },
  {
    active: true,
    id: 280,
    name: "Management and Budget Office",
    short_name: "OMB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 330,
    name: "National Consumer Cooperative Bank",
    short_name: "NCB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 340,
    name: "National Economic Council",
    short_name: "NEC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 344,
    name: "National Geospatial-Intelligence Agency",
    short_name: "NGA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 346,
    name: "National Historical Publications and Records Commission",
    short_name: "NHPRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 50,
    name: "Christopher Columbus Quincentenary Jubilee Commission",
    short_name: "Christopher Columbus Quincentenary Jubilee Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 79,
    name: "Competitiveness Policy Council",
    short_name: "CPC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 96,
    name: "Customs Service",
    type: "federal_executive"
  },
  {
    active: true,
    id: 102,
    name: "Defense Criminal Investigative Service",
    short_name: "DCIS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 110,
    name: "Defense Special Weapons Agency",
    short_name: "DSWA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 583,
    name: "Gulf Coast Ecosystem Restoration Council",
    short_name: "Gulf Restoration Council",
    type: "federal_executive"
  },
  {
    active: true,
    id: 24,
    name: "Antitrust Modernization Commission",
    short_name: "AMC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 189,
    name: "Federal Retirement Thrift Investment Board",
    short_name: "FRTIB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 4,
    name: "African Development Foundation",
    short_name: "USADF",
    type: "federal_executive"
  },
  {
    active: true,
    id: 488,
    name: "Thrift Depositor Protection Oversight Board",
    short_name: "TDPOB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 13,
    name: "Air Force Department",
    short_name: "USAF",
    type: "federal_executive"
  },
  {
    active: true,
    id: 21,
    name: "Amtrak Reform Council",
    short_name: "AMTRAK-ARC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 18,
    name: "Alcohol and Tobacco Tax and Trade Bureau",
    short_name: "TTB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 28,
    name: "Architectural and Transportation Barriers Compliance Board",
    short_name: "ATBCB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 36,
    name: "Barry M. Goldwater Scholarship and Excellence in Education Foundation",
    short_name: "BGSEEF",
    type: "federal_executive"
  },
  {
    active: true,
    id: 489,
    name: "Thrift Supervision Office",
    short_name: "OTS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 499,
    name: "U.S. Citizenship and Immigration Services",
    short_name: "USCIS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 501,
    name: "U.S. Customs and Border Protection",
    short_name: "USCBP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 513,
    name: "United States Mint",
    short_name: "USMINT",
    type: "federal_executive"
  },
  {
    active: true,
    id: 518,
    name: "Utah Reclamation Mitigation and Conservation Commission",
    short_name: "URMCC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 524,
    name: "Wage and Hour Division",
    short_name: "WHD",
    type: "federal_executive"
  },
  {
    active: true,
    id: 543,
    name: "Interstate Commerce Commission",
    short_name: "ICC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 578,
    name: "Council of the Inspectors General on Integrity and Efficiency",
    short_name: "CIGIE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 372,
    name: "National Technical Information Service",
    short_name: "NTIS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 388,
    name: "Ocean Policy Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 396,
    name: "Ounce of Prevention Council",
    type: "federal_executive"
  },
  {
    active: true,
    id: 397,
    name: "Overseas Private Investment Corporation",
    short_name: "OPIC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 401,
    name: "Parole Commission",
    short_name: "USPC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 404,
    name: "Pension and Welfare Benefits Administration",
    short_name: "PWBA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 417,
    name: "Presidential Documents",
    short_name: "PRES",
    type: "federal_executive"
  },
  {
    active: true,
    id: 465,
    name: "Secret Service",
    short_name: "USSS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 2,
    name: "Administration Office, Executive Office of the President",
    short_name: "OA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 254,
    name: "Internal Revenue Service",
    short_name: "IRS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 482,
    name: "Susquehanna River Basin Commission",
    short_name: "SRBC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 175,
    name: "Federal Housing Finance Board",
    short_name: "FHFB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 216,
    name: "Government National Mortgage Association",
    short_name: "GinnieMae",
    type: "federal_executive"
  },
  {
    active: true,
    id: 217,
    name: "Government Publishing Office",
    short_name: "GPO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 220,
    name: "Harry S. Truman Scholarship Foundation",
    short_name: "HST",
    type: "federal_executive"
  },
  {
    active: true,
    id: 256,
    name: "International Broadcasting Board",
    short_name: "IBB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 252,
    name: "Intergovernmental Relations Advisory Commission",
    short_name: "ACIR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 87,
    name: "Copyright Office, Library of Congress",
    short_name: "COLC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 94,
    name: "Court Services and Offender Supervision Agency for the District of Columbia",
    short_name: "CSOSA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 101,
    name: "Defense Contract Audit Agency",
    short_name: "DCAA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 105,
    name: "Defense Intelligence Agency",
    short_name: "DIA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 515,
    name: "United States Sentencing Commission",
    short_name: "USSC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 115,
    name: "Disability Employment Policy Office",
    short_name: "ODEP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 150,
    name: "Export Administration Bureau",
    short_name: "EAB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 156,
    name: "Farm Credit System Insurance Corporation",
    short_name: "FCSIC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 259,
    name: "International Investment Office",
    short_name: "IIO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 260,
    name: "International Joint Commission-United States and Canada",
    short_name: "IJC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 264,
    name: "Japan-United States Friendship Commission",
    short_name: "JUSFC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 284,
    name: "Medicare Payment Advisory Commission",
    short_name: "MedPAC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 305,
    name: "National Bankruptcy Review Commission",
    short_name: "NBRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 336,
    name: "National Crime Prevention and Privacy Compact Council",
    short_name: "NCPPCC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 358,
    name: "National Nanotechnology Coordination Office",
    short_name: "NNCO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 364,
    name: "National Prison Rape Elimination Commission",
    short_name: "NPREC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 381,
    name: "Northeast Interstate Low-Level Radioactive Waste Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 318,
    name: "National Commission on Intermodal Transportation",
    type: "federal_executive"
  },
  {
    active: true,
    id: 506,
    name: "Uniformed Services University of the Health Sciences",
    short_name: "USUHS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 70,
    name: "Commission on the Bicentennial of the United States Constitution",
    type: "federal_executive"
  },
  {
    active: true,
    id: 19,
    name: "Alcohol, Tobacco, Firearms, and Explosives Bureau",
    short_name: "ATF",
    type: "federal_executive"
  },
  {
    active: true,
    id: 27,
    name: "Architect of the Capitol",
    short_name: "AOC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 39,
    name: "Board of Directors of the Hope for Homeowners Program",
    short_name: "HOPE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 61,
    name: "Commission on Immigration Reform",
    short_name: "CIR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 390,
    name: "Office of National Drug Control Policy",
    short_name: "ONDCP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 394,
    name: "Oklahoma City National Memorial Trust",
    type: "federal_executive"
  },
  {
    active: true,
    id: 403,
    name: "Peace Corps",
    short_name: "PC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 407,
    name: "Physician Payment Review Commission",
    short_name: "PPRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 412,
    name: "Presidential Advisory Committee on Gulf War Veterans' Illnesses",
    type: "federal_executive"
  },
  {
    active: true,
    id: 449,
    name: "Regulatory Information Service Center",
    short_name: "RISC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 496,
    name: "Travel and Tourism Administration",
    short_name: "USTTA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 437,
    name: "Prisons Bureau",
    short_name: "BOP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 23,
    name: "Antitrust Division",
    short_name: "ANTITRUST",
    type: "federal_executive"
  },
  {
    active: true,
    id: 127,
    name: "Election Assistance Commission",
    short_name: "EAC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 342,
    name: "National Foundation on the Arts and the Humanities",
    short_name: "ARTS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 410,
    name: "Postal Service",
    short_name: "USPS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 241,
    name: "Industry and Security Bureau",
    short_name: "BIS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 288,
    name: "Mine Safety and Health Administration",
    short_name: "MSHA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 3,
    name: "Administrative Office of United States Courts",
    short_name: "AO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 331,
    name: "National Council on Disability",
    short_name: "NCD",
    type: "federal_executive"
  },
  {
    active: true,
    id: 78,
    name: "Community Development Financial Institutions Fund",
    short_name: "CDFI",
    type: "federal_executive"
  },
  {
    active: true,
    id: 589,
    name: "National Endowment for the Humanities",
    type: "federal_executive"
  },
  {
    active: true,
    id: 579,
    name: "Special Inspector General for Afghanistan Reconstruction",
    short_name: "SIGAR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 585,
    name: "Fiscal Service",
    short_name: "FS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 124,
    name: "Economics and Statistics Administration",
    short_name: "ECSA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 129,
    name: "Emergency Oil and Gas Guaranteed Loan Board",
    type: "federal_executive"
  },
  {
    active: true,
    id: 143,
    name: "Engraving and Printing Bureau",
    short_name: "BEP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 158,
    name: "Federal Accounting Standards Advisory Board",
    short_name: "FASAB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 177,
    name: "Federal Law Enforcement Training Center",
    short_name: "FLETC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 184,
    name: "Federal Procurement Policy Office",
    short_name: "OFPP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 206,
    name: "Foreign Service Impasse Disputes Panel",
    short_name: "Disputes Panel",
    type: "federal_executive"
  },
  {
    active: true,
    id: 210,
    name: "General Services Administration",
    short_name: "GSA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 263,
    name: "James Madison Memorial Fellowship Foundation",
    type: "federal_executive"
  },
  {
    active: true,
    id: 249,
    name: "Interagency Floodplain Management Review Committee",
    short_name: "IFMRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 267,
    name: "Judicial Review Commission on Foreign Asset Control",
    type: "federal_executive"
  },
  {
    active: true,
    id: 276,
    name: "Legal Services Corporation",
    short_name: "LSC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 326,
    name: "National Commission on the Cost of Higher Education",
    type: "federal_executive"
  },
  {
    active: true,
    id: 26,
    name: "Appalachian States Low-Level Radioactive Waste Commission",
    short_name: "APPAL",
    type: "federal_executive"
  },
  {
    active: true,
    id: 30,
    name: "Armed Forces Retirement Home",
    short_name: "AFRH",
    type: "federal_executive"
  },
  {
    active: true,
    id: 43,
    name: "Census Monitoring Board",
    type: "federal_executive"
  },
  {
    active: true,
    id: 81,
    name: "Congressional Budget Office",
    short_name: "CBO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 86,
    name: "Coordinating Council on Juvenile Justice and Delinquency Prevention",
    short_name: "CCJJDP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 93,
    name: "Counsel to the President",
    type: "federal_executive"
  },
  {
    active: true,
    id: 343,
    name: "National Gambling Impact Study Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 351,
    name: "National Institute of Justice",
    short_name: "NIJ",
    type: "federal_executive"
  },
  {
    active: true,
    id: 370,
    name: "National Skill Standards Board",
    short_name: "NSSB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 377,
    name: "Navajo and Hopi Indian Relocation Office",
    short_name: "ONHIR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 387,
    name: "Occupational Safety and Health Review Commission",
    short_name: "OSHRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 428,
    name: "President's Council on Integrity and Efficiency",
    short_name: "PCIE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 429,
    name: "President's Council on Sustainable Development",
    short_name: "PCSD",
    type: "federal_executive"
  },
  {
    active: true,
    id: 436,
    name: "Presidio Trust",
    short_name: "PT",
    type: "federal_executive"
  },
  {
    active: true,
    id: 335,
    name: "National Credit Union Administration",
    short_name: "NCUA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 120,
    name: "Economic Development Administration",
    short_name: "EDA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 160,
    name: "Federal Bureau of Investigation",
    short_name: "FBI",
    type: "federal_executive"
  },
  {
    active: true,
    id: 261,
    name: "International Trade Administration",
    short_name: "ITA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 402,
    name: "Patent and Trademark Office",
    short_name: "PTO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 444,
    name: "Railroad Retirement Board",
    short_name: "RRB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 406,
    name: "Personnel Management Office",
    short_name: "OPM",
    type: "federal_executive"
  },
  {
    active: true,
    id: 476,
    name: "State Department",
    short_name: "DOS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 151,
    name: "Export-Import Bank",
    short_name: "EIB/USEIB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 348,
    name: "National Institute for Literacy",
    short_name: "NIL",
    type: "federal_executive"
  },
  {
    active: true,
    id: 391,
    name: "Office of Policy Development",
    type: "federal_executive"
  },
  {
    active: true,
    id: 452,
    name: "Resolution Trust Corporation",
    short_name: "RTC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 542,
    name: "International Organizations Employees Loyalty Board",
    type: "federal_executive"
  },
  {
    active: true,
    id: 441,
    name: "Public Debt Bureau",
    short_name: "BPD",
    type: "federal_executive"
  },
  {
    active: true,
    id: 456,
    name: "Rural Business-Cooperative Service",
    short_name: "RBS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 457,
    name: "Rural Housing and Community Development Service",
    short_name: "RHCDS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 484,
    name: "Technology Administration",
    short_name: "TA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 564,
    name: "Postal Rate Commission",
    short_name: "PRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 584,
    name: "First Responder Network Authority",
    short_name: "FirstNet",
    type: "federal_executive"
  },
  {
    active: true,
    id: 304,
    name: "National Archives and Records Administration",
    short_name: "NARA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 14,
    name: "Air Quality National Commission",
    short_name: "NCAQ",
    type: "federal_executive"
  },
  {
    active: true,
    id: 474,
    name: "Special Inspector General For Iraq Reconstruction",
    short_name: "SIGIR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 473,
    name: "Special Counsel Office",
    short_name: "OSC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 504,
    name: "U.S. Trade Deficit Review Commission",
    short_name: "TDRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 509,
    name: "United States Enrichment Corporation",
    short_name: "USEC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 528,
    name: "Women's Business Enterprise Interagency Committee",
    type: "federal_executive"
  },
  {
    active: true,
    id: 574,
    name: "Financial Research Office",
    short_name: "OFR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 357,
    name: "National Mediation Board",
    short_name: "NMB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 430,
    name: "President's Critical Infrastructure Protection Board",
    short_name: "PCCIP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 529,
    name: "Women's Progress Commemoration Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 440,
    name: "Prospective Payment Assessment Commission",
    short_name: "ProPAC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 445,
    name: "Reagan-Udall Foundation for the Food and Drug Administration",
    type: "federal_executive"
  },
  {
    active: true,
    id: 57,
    name: "Commission of Fine Arts",
    short_name: "CFA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 52,
    name: "Civil Rights Commission",
    short_name: "CRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 65,
    name: "Commission on Protecting and Reducing Government Secrecy",
    type: "federal_executive"
  },
  {
    active: true,
    id: 68,
    name: "Commission on Structural Alternatives for the Federal Courts of Appeals",
    type: "federal_executive"
  },
  {
    active: true,
    id: 99,
    name: "Defense Base Closure and Realignment Commission",
    short_name: "BRAC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 118,
    name: "Economic Analysis Bureau",
    short_name: "BEA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 132,
    name: "Employees Compensation Appeals Board",
    short_name: "ECAB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 183,
    name: "Federal Prison Industries",
    short_name: "FPI",
    type: "federal_executive"
  },
  {
    active: true,
    id: 182,
    name: "Federal Pay, Advisory Committee",
    type: "federal_executive"
  },
  {
    active: true,
    id: 190,
    name: "Federal Service Impasses Panel",
    short_name: "FSIP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 232,
    name: "Immigration and Naturalization Service",
    short_name: "INS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 228,
    name: "Housing and Urban Development Department",
    short_name: "HUD",
    type: "federal_executive"
  },
  {
    active: true,
    id: 269,
    name: "Justice Programs Office",
    short_name: "OJP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 277,
    name: "Library of Congress",
    short_name: "LOC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 281,
    name: "Marine Mammal Commission",
    short_name: "MMC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 317,
    name: "National Commission on Fiscal Responsibility and Reform",
    type: "federal_executive"
  },
  {
    active: true,
    id: 320,
    name: "National Commission on Libraries and Information Science",
    short_name: "NCLIS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 355,
    name: "National Labor Relations Board",
    short_name: "NLRB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 91,
    name: "Corporation for National and Community Service",
    short_name: "CORP/CNCS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 285,
    name: "Merit Systems Protection Board",
    short_name: "MSPB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 464,
    name: "Science and Technology Policy Office",
    short_name: "OSTP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 503,
    name: "U.S. Immigration and Customs Enforcement",
    short_name: "ICE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 41,
    name: "Broadcasting Board of Governors",
    short_name: "BBG",
    type: "federal_executive"
  },
  {
    active: true,
    id: 47,
    name: "Chemical Safety and Hazard Investigation Board",
    short_name: "CSB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 54,
    name: "Commerce Department",
    short_name: "DOC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 74,
    name: "Committee for Purchase From People Who Are Blind or Severely Disabled",
    short_name: "CPPBSD",
    type: "federal_executive"
  },
  {
    active: true,
    id: 46,
    name: "Central Intelligence Agency",
    short_name: "CIA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 75,
    name: "Committee for the Implementation of Textile Agreements",
    short_name: "CITA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 329,
    name: "National Communications System",
    short_name: "NCS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 431,
    name: "President's Economic Policy Advisory Board",
    short_name: "PERAB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 591,
    name: "Institute of Museum and Library Services",
    type: "federal_executive"
  },
  {
    active: true,
    id: 307,
    name: "National Bipartisan Commission on Future of Medicare",
    type: "federal_executive"
  },
  {
    active: true,
    id: 88,
    name: "Copyright Royalty Board",
    short_name: "LOC-CRB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 25,
    name: "Appalachian Regional Commission",
    short_name: "ARC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 33,
    name: "Assassination Records Review Board",
    type: "federal_executive"
  },
  {
    active: true,
    id: 32,
    name: "Army Department",
    short_name: "USA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 470,
    name: "Social Security Administration",
    short_name: "SSA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 116,
    name: "Drug Enforcement Administration",
    short_name: "DEA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 166,
    name: "Federal Emergency Management Agency",
    short_name: "FEMA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 178,
    name: "Federal Maritime Commission",
    short_name: "FMC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 187,
    name: "Federal Register, Administrative Committee",
    short_name: "ACFR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 195,
    name: "Financial Crisis Inquiry Commission",
    short_name: "FINCIC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 207,
    name: "Foreign Service Labor Relations Board",
    short_name: "FSLRB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 211,
    name: "Geographic names Board",
    short_name: "BGN",
    type: "federal_executive"
  },
  {
    active: true,
    id: 246,
    name: "Institute of American Indian and Alaska Native Culture and Arts Development",
    short_name: "IAIA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 104,
    name: "Defense Information Systems Agency",
    short_name: "DISA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 107,
    name: "Defense Logistics Agency",
    short_name: "DLA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 112,
    name: "Delaware River Basin Commission",
    short_name: "DRBC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 134,
    name: "Employment Standards Administration",
    short_name: "DOL-ESA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 196,
    name: "Bureau of the Fiscal Service",
    type: "federal_executive"
  },
  {
    active: true,
    id: 148,
    name: "Executive Council on Integrity and Efficiency",
    short_name: "ECIE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 265,
    name: "Joint Board for Enrollment of Actuaries",
    short_name: "JBEA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 270,
    name: "Juvenile Justice and Delinquency Prevention Office",
    short_name: "OJJDP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 291,
    name: "Minority Business Development Agency",
    short_name: "MBDA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 294,
    name: "Monetary Offices",
    type: "federal_executive"
  },
  {
    active: true,
    id: 321,
    name: "National Commission on Manufactured Housing",
    type: "federal_executive"
  },
  {
    active: true,
    id: 325,
    name: "National Commission on Terrorist Attacks Upon the United States",
    short_name: "9-11 Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 334,
    name: "National Counterintelligence Center",
    short_name: "NCTC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 349,
    name: "National Institute of Corrections",
    short_name: "NIC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 363,
    name: "National Partnership for Reinventing Government",
    short_name: "NPR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 365,
    name: "National Railroad Passenger Corporation",
    short_name: "AMTRAK",
    type: "federal_executive"
  },
  {
    active: true,
    id: 95,
    name: "Crime and Security in U.S. Seaports, Interagency Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 266,
    name: "Judicial Conference of the United States",
    short_name: "USJC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 274,
    name: "Labor-Management Standards Office",
    short_name: "LMSO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 368,
    name: "National Security Council",
    short_name: "NSC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 447,
    name: "Recovery Accountability and Transparency Board",
    short_name: "RATB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 519,
    name: "Valles Caldera Trust",
    short_name: "VCNP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 20,
    name: "American Battle Monuments Commission",
    short_name: "ABMC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 149,
    name: "Executive Office for Immigration Review",
    short_name: "EOIR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 31,
    name: "Arms Control and Disarmament Agency",
    short_name: "ACDA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 38,
    name: "Bipartisan Commission on Entitlement and Tax Reform",
    type: "federal_executive"
  },
  {
    active: true,
    id: 399,
    name: "Pacific Northwest Electric Power and Conservation Planning Council",
    short_name: "PACIFIC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 400,
    name: "Panama Canal Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 413,
    name: "Presidential Commission on Assignment of Women in the Armed Forces",
    type: "federal_executive"
  },
  {
    active: true,
    id: 438,
    name: "Privacy and Civil Liberties Oversight Board",
    short_name: "PCLOB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 469,
    name: "Smithsonian Institution",
    type: "federal_executive"
  },
  {
    active: true,
    id: 490,
    name: "Trade and Development Agency",
    short_name: "USTDA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 468,
    name: "Small Business Administration",
    short_name: "SBA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 487,
    name: "The White House Office",
    type: "federal_executive"
  },
  {
    active: true,
    id: 486,
    name: "Tennessee Valley Authority",
    short_name: "TVA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 491,
    name: "Trade Representative, Office of United States",
    short_name: "USTR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 502,
    name: "U.S. House of Representatives",
    short_name: "HOUSE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 557,
    name: "ACTION",
    short_name: "ACTION",
    type: "federal_executive"
  },
  {
    active: true,
    id: 565,
    name: "Financial Stability Oversight Council",
    short_name: "FSOC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 174,
    name: "Federal Housing Finance Agency",
    short_name: "FHFA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 180,
    name: "Federal Mine Safety and Health Review Commission",
    short_name: "MSHFRC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 42,
    name: "Census Bureau",
    short_name: "USBC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 103,
    name: "Defense Department",
    short_name: "DOD",
    type: "federal_executive"
  },
  {
    active: true,
    id: 126,
    name: "Education Department",
    short_name: "ED",
    type: "federal_executive"
  },
  {
    active: true,
    id: 262,
    name: "International Trade Commission",
    short_name: "ITC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 296,
    name: "Morris K. Udall and Stewart L. Udall Foundation",
    short_name: "Udall Foundation",
    type: "federal_executive"
  },
  {
    active: true,
    id: 374,
    name: "National Transportation Safety Board",
    short_name: "NTSB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 379,
    name: "Neighborhood Reinvestment Corporation",
    short_name: "NEIGHBOR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 405,
    name: "Pension Benefit Guaranty Corporation",
    short_name: "PBGC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 227,
    name: "Homeland Security Department",
    short_name: "DHS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 243,
    name: "Information Security Oversight Office",
    short_name: "ISOO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 272,
    name: "Labor Statistics Bureau",
    short_name: "BLS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 92,
    name: "Council on Environmental Quality",
    short_name: "CEQ",
    type: "federal_executive"
  },
  {
    active: true,
    id: 154,
    name: "Farm Credit Administration",
    short_name: "FCA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 113,
    name: "Denali Commission",
    short_name: "DC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 128,
    name: "Electronic Commerce Advisory Commission",
    short_name: "eCOMMERCE",
    type: "federal_executive"
  },
  {
    active: true,
    id: 130,
    name: "Emergency Steel Guarantee Loan Board",
    type: "federal_executive"
  },
  {
    active: true,
    id: 173,
    name: "Federal Housing Enterprise Oversight Office",
    short_name: "OFHEO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 205,
    name: "Foreign Service Grievance Board",
    short_name: "FSGB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 225,
    name: "Advisory Council on Historic Preservation",
    short_name: "ACHP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 293,
    name: "Mississippi River Commission",
    short_name: "MISS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 308,
    name: "National Capital Planning Commission",
    short_name: "NCPC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 341,
    name: "National Education Goals Panel",
    short_name: "NEGP",
    type: "federal_executive"
  },
  {
    active: true,
    id: 67,
    name: "Commission on Review of Overseas Military Facility Structure of the United States",
    type: "federal_executive"
  },
  {
    active: true,
    id: 71,
    name: "Commission on the Future of the United States Aerospace Industry",
    type: "federal_executive"
  },
  {
    active: true,
    id: 72,
    name: "Commission on the Social Security Notch Issue",
    type: "federal_executive"
  },
  {
    active: true,
    id: 84,
    name: "Consumer Product Safety Commission",
    short_name: "CPSC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 106,
    name: "Defense Investigative Service",
    short_name: "DIS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 108,
    name: "Defense Mapping Agency",
    short_name: "DMA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 208,
    name: "Foreign-Trade Zones Board",
    short_name: "FTZB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 213,
    name: "Government Accountability Office",
    short_name: "GAO",
    type: "federal_executive"
  },
  {
    active: true,
    id: 352,
    name: "National Institute of Standards and Technology",
    short_name: "NIST",
    type: "federal_executive"
  },
  {
    active: true,
    id: 378,
    name: "Navy Department",
    short_name: "USN",
    type: "federal_executive"
  },
  {
    active: true,
    id: 385,
    name: "Nuclear Waste Technical Review Board",
    short_name: "NWTRB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 147,
    name: "Equal Employment Opportunity Commission",
    short_name: "EEOC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 588,
    name: "National Endowment for the Arts",
    short_name: "NEA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 161,
    name: "Federal Communications Commission",
    short_name: "FCC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 89,
    name: "Copyright Royalty Judges, Library of Congress",
    short_name: "LOC-CRJ",
    type: "federal_executive"
  },
  {
    active: true,
    id: 255,
    name: "International Boundary and Water Commission, United States and Mexico",
    short_name: "IBWC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 287,
    name: "Millennium Challenge Corporation",
    short_name: "MCC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 477,
    name: "State Justice Institute",
    short_name: "SJI",
    type: "federal_executive"
  },
  {
    active: true,
    id: 367,
    name: "National Security Agency/Central Security Service",
    short_name: "NSA/CSS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 582,
    name: "Military Compensation and Retirement Modernization Commission",
    short_name: "Military Compensation and Retirement Modernization Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 15,
    name: "Air Transportation Stabilization Board",
    short_name: "ATSB",
    type: "federal_executive"
  },
  {
    active: true,
    id: 29,
    name: "Arctic Research Commission",
    short_name: "ARCTIC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 494,
    name: "Transportation Security Administration",
    short_name: "TSA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 498,
    name: "Twenty-First Century Workforce Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 510,
    name: "United States Information Agency",
    short_name: "USIA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 512,
    name: "United States Marshals Service",
    short_name: "USMS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 505,
    name: "U.S.-China Economic and Security Review Commission",
    short_name: "USCC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 521,
    name: "Veterans Employment and Training Service",
    short_name: "VETS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 523,
    name: "Victims of Crime Office",
    short_name: "OVC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 539,
    name: "Federal Acquisition Regulation System",
    short_name: "FAR",
    type: "federal_executive"
  },
  {
    active: true,
    id: 566,
    name: "Administrative Conference of the United States",
    short_name: "ACUS",
    type: "federal_executive"
  },
  {
    active: true,
    id: 69,
    name: "Commission on the Advancement of Federal Law Enforcement",
    type: "federal_executive"
  },
  {
    active: true,
    id: 347,
    name: "National Indian Gaming Commission",
    short_name: "NIGC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 375,
    name: "National Women's Business Council",
    short_name: "NWBC",
    type: "federal_executive"
  },
  {
    active: true,
    id: 369,
    name: "National Shipping Authority",
    short_name: "NSA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 380,
    name: "Northeast Dairy Compact Commission",
    type: "federal_executive"
  },
  {
    active: true,
    id: 373,
    name: "National Telecommunications and Information Administration",
    short_name: "NTIA",
    type: "federal_executive"
  },
  {
    active: true,
    id: 467,
    name: "Selective Service System",
    short_name: "SSS",
    type: "federal_executive"
  },
  { active: true,
    id: 9022,
    name: 'Municipal Securities Rulemaking Board',
    short_name: 'MSRB',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  { active: true,
    id: 12000001,
    name: 'Bank for International Settlements',
    short_name: 'INT-BIS',
    type: 'international',
    jurisdiction: 'INT'
  }
];

export const otherFederalAgenciesObj = otherFederalAgencies.reduce((mem, agency) => {
  mem[agency.id] = agency;
  return mem;
}, {});

export const defaultFederalAgencies = [
  {
    id: 573,
    name: 'Consumer Financial Protection Bureau',
    short_name: 'CFPB',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 203,
    name: 'Office of Foreign Assets Control',
    short_name: 'OFAC',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 80,
    name: 'Comptroller of the Currency',
    short_name: 'OCC',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 164,
    name: 'Federal Deposit Insurance Corporation',
    short_name: 'FDIC',
    onboard_default: true,
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 188,
    name: 'Federal Reserve System',
    display_name: 'FRS',
    short_name: 'FRS',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 9015,
    name: 'Financial Industry Regulatory Authority',
    short_name: 'FINRA',
    type: 'sro',
    jurisdiction: 'US'
  },
  {
    id: 9030,
    name: 'New York Stock Exchange',
    short_name: 'NYSE',
    type: 'sro',
    jurisdiction: 'US'
  },
  {
    id: 466,
    name: 'Securities and Exchange Commission',
    short_name: 'SEC',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 497,
    name: 'Treasury Department',
    short_name: 'TREAS',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 131,
    name: 'Employee Benefits Security Administration',
    short_name: 'EBSA',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 268,
    name: 'Justice Department',
    short_name: 'DOJ',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 192,
    name: 'Federal Trade Commission',
    short_name: 'FTC',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 271,
    name: 'Labor Department',
    short_name: 'DOL',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 168,
    name: 'Federal Financial Institutions Examination Council',
    short_name: 'FFIEC',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 538,
    name: 'Executive Office of the President',
    short_name: 'EOP',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 194,
    name: 'Financial Crimes Enforcement Network',
    short_name: 'FINCEN',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 30002,
    name: 'Freddie Mac',
    short_name: 'FREDDIE',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 30001,
    name: 'Fannie Mae',
    short_name: 'FANNIE',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 30003,
    name: 'Federal Housing Administration',
    short_name: 'FHA',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 458,
    name: 'Rural Housing Service',
    short_name: 'RHS',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 520,
    name: 'Veterans Administration',
    short_name: 'VA',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 77,
    name: 'Commodity Futures Trading Commission',
    short_name: 'CFTC',
    type: 'federal_executive',
    jurisdiction: 'US'
  },
  {
    id: 11000001,
    name: 'Bank of England',
    short_name: 'UK-BOE',
    type: 'international',
    jurisdiction: 'UK'
  },
  {
    id: 10000001,
    name: 'Financial Conduct Authority',
    short_name: 'UK-FCA',
    type: 'international',
    jurisdiction: 'UK'
  },
  {
    id: 11000002,
    name: 'Prudential Regulation Authority',
    short_name: 'UK-PRA',
    type: 'international',
    jurisdiction: 'UK'
  }
];

export const defaultFederalAgenciesObj = defaultFederalAgencies.reduce((mem, agency) => {
  mem[agency.id] = agency;
  return mem;
}, {});

export const defaultJurisdictions = ['US', 'UK'];

/*
FIXME: As of 01/12, defaultStateAgencies are being used through out the application in following way:

(1) When new user signs up for the application they are presented with all 50 states listed below

(2) When the user selects to follow states with a parent_id (ie. avaliable with data on the app refered to as 'id' below)
    they are combined with the federal agencies ids they've selected and stored in the user_agency table as was the norm

(3) When the user selects a state without a parent_id (ie. no data on the app) this relation is saved to the user_follow_entity
    table with the entity_id being the generic ES id provided on the jurisdictions table in ES (note - this has no relation to the
    parent_id mentioned above or the admin_code_id need for the state navigator feature)

Ideally we'd remove all the hardcoded data provided on this file and move it to a table in the backend with ES. For the defaultFederalAgencies above
this can already be done, as it is mostly used for back up in case the ES agencies table happens to be missing some value needed in the frontend. For the
defaultStateAgencies below however, this will require some updating of the jurisdictions table in ES. As of today, the table is missing: parent_ids, a
field for state, a field for the admin_code_id, and a field for fin_related_doc_ids.

If these are not added somewhere on the table, the below will be necessary to keep the frontend functioning properly.

In addition, to help standardize the state relations even further all selected states should be placed in to the user_follow_entity table. Once this is done
all the places where the agencies store is used needs to have the states store added to get access to the user's followed states.

*/
/*
  XXX: Temp flag to use when state data is needed on integration site but not production
  let HIDDEN_STATE_ID = null;
  let HIDDEN_STATE_CODE_ID = null;

  if (process.env.STATE_DATA_ENABLED) {
    HIDDEN_STATE_ID = ;
    HIDDEN_STATE_CODE_ID = ;
}
*/

// If adding a new jursidiction here, please add them to app/constants/Jurisidctions
export const defaultStateAgencies = [
  // XXX update when these are added to the system

  /* n.b - to be added when data is avaliable
  {
    name: 'US-Code',
    id: null,
    short_name: 'US-Code',
    state: 'United States',
    type: 'united states',
    admin_code_id: null,
    statute_id: null,
    jurisdiction_id: null,
    following: null,
    country: 'US'
  },*/
  {
    name: 'CA Dept of Business Oversight',
    id: 100000,
    admin_code_id: null,
    jurisdiction_id: 6,
    short_name: 'CA-DBO',
    state: 'CA',
    type: 'california',
    following: null,
    country: 'US'
  },
  {
    name: 'California',
    id: 100003,
    admin_code_id: 1468291,
    statute_id: 3079392,
    jurisdiction_id: 6,
    short_name: 'US-CA',
    state: 'CA',
    type: 'california',
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Delaware',
    type: 'delaware',
    short_name: 'US-DE',
    state: 'DE',
    id: 200000,
    admin_code_id: 1616179,
    statute_id: 5671254,
    jurisdiction_id: 10,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Texas',
    type: 'texas',
    short_name: 'US-TX',
    state: 'TX',
    id: 300000,
    admin_code_id: 1509185,
    statute_id: 3043229,
    jurisdiction_id: 48,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'New York',
    type: 'new_york',
    short_name: 'US-NY',
    state: 'NY',
    id: 400000,
    admin_code_id: 1468292,
    jurisdiction_id: 36,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Florida',
    type: 'florida',
    short_name: 'US-FL',
    state: 'FL',
    id: 500000,
    admin_code_id: 1377737,
    jurisdiction_id: 12,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Massachusetts',
    type: 'massachusetts',
    short_name: 'US-MA',
    state: 'MA',
    id: 600000,
    admin_code_id: 1616137,
    statute_id: 3148127,
    jurisdiction_id: 25,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Colorado',
    type: 'colorado',
    short_name: 'US-CO',
    state: 'CO',
    id: 700000,
    admin_code_id: 1405155,
    jurisdiction_id: 8,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Connecticut',
    id: 800000,
    short_name: 'US-CT',
    state: 'CT',
    type: 'connecticut',
    admin_code_id: 3039350,
    statute_id: 1468721,
    jurisdiction_id: 9,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Oregon',
    id: 900000,
    short_name: 'US-OR',
    state: 'OR',
    type: 'oregon',
    admin_code_id: 1471760,
    jurisdiction_id: 41,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Nevada',
    type: 'nevada',
    short_name: 'US-NV',
    state: 'NV',
    id: 1000000,
    admin_code_id: 1482618,
    jurisdiction_id: 32,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Washington',
    type: 'washington',
    short_name: 'US-WA',
    state: 'WA',
    id: 1100000,
    admin_code_id: 1596970,
    jurisdiction_id: 53,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Illinois',
    type: 'illinois',
    short_name: 'US-IL',
    state: 'IL',
    id: 1200000,
    admin_code_id: 1597052,
    statute_id: 3036109,
    jurisdiction_id: 17,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Missouri',
    type: 'missouri',
    short_name: 'US-MO',
    state: 'MO',
    id: 1400000,
    admin_code_id: 1681738,
    statute_id: 3276391,
    jurisdiction_id: 29,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Virginia',
    type: 'virginia',
    short_name: 'US-VA',
    state: 'VA',
    id: 1500000,
    admin_code_id: 1616868,
    jurisdiction_id: 51,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'North Carolina',
    type: 'north_carolina',
    short_name: 'US-NC',
    state: 'NC',
    id: 1600000,
    admin_code_id: 1658361,
    jurisdiction_id: 37,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Indiana',
    type: 'indiana',
    short_name: 'US-IN',
    state: 'IN',
    id: 1700000,
    admin_code_id: 1680478,
    statute_id: 5671304,
    jurisdiction_id: 18,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Pennsylvania',
    type: 'pennsylvania',
    short_name: 'US-PA',
    state: 'PA',
    id: 1300000,
    admin_code_id: 2390290,
    statute_id: 3148117,
    jurisdiction_id: 42,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Alabama',
    type: 'alabama',
    short_name: 'US-AL',
    state: 'AL',
    id: 3500000,
    admin_code_id: null,
    statute_id: 2428530,
    jurisdiction_id: 1,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Alaska',
    type: 'alaska',
    short_name: 'US-AK',
    state: 'AK',
    id: 4300000,
    admin_code_id: null,
    jurisdiction_id: 2,
    active: true,
    external_link: 'http://www.legis.state.ak.us/basis/folioproxy.asp?url=http://wwwjnu01.legis.state.ak.us/cgi-bin/folioisa.dll/aac/query=[JUMP:%27Title3Chap02%27]/doc/{@1}?firsthit',
    following: null,
    country: 'US'
  },
  {
    name: 'Arizona',
    type: 'arizona',
    short_name: 'US-AZ',
    state: 'AZ',
    id: 3300000,
    admin_code_id: 2389266,
    jurisdiction_id: 4,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Arkansas',
    type: 'arkansas',
    short_name: 'US-AR',
    state: 'AR',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 5,
    active: null,
    external_link: 'http://www.lexisnexis.com/hottopics/arcode/Default.asp',
    following: null,
    country: 'US'
  },
  {
    name: 'Georgia',
    type: 'georgia',
    short_name: 'US-GA',
    state: 'GA',
    id: 4500000,
    admin_code_id: null,
    statute_id: 3352568,
    jurisdiction_id: 13,
    active: true,
    external_link: 'http://rules.sos.ga.gov/gac/',
    following: null,
    country: 'US'
  },
  {
    name: 'Hawaii',
    type: 'hawaii',
    short_name: 'US-HI',
    state: 'HI',
    id: 4100000,
    admin_code_id: null,
    jurisdiction_id: 15,
    active: false,
    external_link: 'http://ltgov.hawaii.gov/the-office/administrative-rules/',
    following: null,
    country: 'US'
  },
  {
    name: 'Idaho',
    type: 'idaho',
    short_name: 'US-ID',
    state: 'ID',
    id: 3200000,
    admin_code_id: 2350440,
    jurisdiction_id: 16,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Iowa',
    type: 'iowa',
    short_name: 'US-IA',
    state: 'IA',
    id: 1800000,
    admin_code_id: 2056707,
    jurisdiction_id: 19,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Kansas',
    type: 'kansas',
    short_name: 'US-KS',
    state: 'KS',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 20,
    active: null,
    external_link: 'http://www.kssos.org/pubs/pubs_kar.aspx',
    following: null,
    country: 'US'
  },
  {
    name: 'Kentucky',
    type: 'kentucky',
    short_name: 'US-KY',
    state: 'KY',
    id: 2600000,
    admin_code_id: 2205330,
    jurisdiction_id: 21,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Louisiana',
    type: 'louisiana',
    short_name: 'US-LA',
    state: 'LA',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 22,
    active: null,
    external_link: 'http://www.doa.la.gov/Pages/osr/lac/books.aspx',
    following: null,
    country: 'US'
  },
  {
    name: 'Maine',
    type: 'maine',
    short_name: 'US-ME',
    state: 'ME',
    id: 3600000,
    // admin_code_id: 2427899,
    jurisdiction_id: 23,
    active: null,
    external_link: 'http://www.maine.gov/sos/cec/rules/rules.html',
    following: null,
    country: 'US'
  },
  {
    name: 'Maryland',
    type: 'maryland',
    short_name: 'US-MD',
    state: 'MD',
    id: 4000000,
    admin_code_id: null,
    jurisdiction_id: 24,
    active: false,
    external_link: 'http://www.dsd.state.md.us/COMAR/subtitle_chapters/Titles.aspx',
    following: null,
    country: 'US'
  },
  {
    name: 'Michigan',
    type: 'michigan',
    short_name: 'US-MI',
    state: 'MI',
    id: 2200000,
    admin_code_id: 2136292,
    statute_id: 5671425,
    jurisdiction_id: 26,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Minnesota',
    type: 'minnesota',
    short_name: 'US-MN',
    state: 'MN',
    id: 2500000,
    admin_code_id: 2169882,
    statute_id: 5671724,
    jurisdiction_id: 27,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Mississippi',
    type: 'mississippi',
    short_name: 'US-MS',
    state: 'MS',
    id: 3800000,
    admin_code_id: null,
    jurisdiction_id: 28,
    active: false,
    external_link: 'http://www.sos.ms.gov/adminsearch/default.aspx',
    following: null,
    country: 'US'
  },
  {
    name: 'Montana',
    type: 'montana',
    short_name: 'US-MT',
    state: 'MT',
    id: 3100000,
    admin_code_id: 2330398,
    jurisdiction_id: 30,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Nebraska',
    type: 'nebraska',
    short_name: 'US-NE',
    state: 'NE',
    id: 2300000,
    admin_code_id: 2094078,
    jurisdiction_id: 31,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'New Hampshire',
    type: 'new_hampshire',
    short_name: 'US-NH',
    state: 'NH',
    id: 3400000,
    admin_code_id: 2351048,
    jurisdiction_id: 33,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'New Jersey',
    type: 'new_jersey',
    short_name: 'US-NJ',
    state: 'NJ',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 34,
    active: null,
    external_link: 'http://www.lexisnexis.com/hottopics/njcode/',
    following: null,
    country: 'US'
  },
  {
    name: 'New Mexico',
    type: 'new_mexico',
    short_name: 'US-NM',
    state: 'NM',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 35,
    active: null,
    external_link: 'http://164.64.110.239/nmac/_titles.htm',
    following: null,
    country: 'US'
  },
  {
    name: 'North Dakota',
    type: 'north_dakota',
    short_name: 'US-ND',
    state: 'ND',
    id: 2800000,
    admin_code_id: 2238807,
    jurisdiction_id: 38,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Ohio',
    type: 'ohio',
    short_name: 'US-OH',
    state: 'OH',
    id: 4400000,
    admin_code_id: 3280261,
    statute_id: 3280252,
    jurisdiction_id: 39,
    active: true,
    external_link: 'http://codes.ohio.gov/oac/',
    following: null,
    country: 'US'
  },
  {
    name: 'Oklahoma',
    type: 'oklahoma',
    short_name: 'US-OK',
    state: 'OK',
    id: 2400000,
    admin_code_id: 2096122,
    jurisdiction_id: 40,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'RI Department of Business Regulation',
    id: 4200001,
    admin_code_id: null,
    jurisdiction_id: 44,
    short_name: 'RI-DBR',
    state: 'RI',
    type: 'rhode_island',
    following: null,
    country: 'US'
  },
  {
    name: 'Rhode Island',
    type: 'rhode_island',
    short_name: 'US-RI',
    state: 'RI',
    id: 4200000,
    admin_code_id: null,
    jurisdiction_id: 44,
    active: true,
    external_link: 'http://sos.ri.gov/rules/',
    following: null,
    country: 'US'
  },
  {
    name: 'South Carolina',
    type: 'south_carolina',
    short_name: 'US-SC',
    state: 'SC',
    id: 3000000,
    admin_code_id: 2346423,
    jurisdiction_id: 45,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'South Dakota',
    type: 'south_dakota',
    short_name: 'US-SD',
    state: 'SD',
    id: 2900000,
    admin_code_id: 2241826,
    jurisdiction_id: 46,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Tennessee',
    type: 'tennessee',
    short_name: 'US-TN',
    state: 'TN',
    id: 2000000,
    admin_code_id: 2084784,
    jurisdiction_id: 47,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Utah',
    type: 'utah',
    short_name: 'US-UT',
    state: 'UT',
    id: 1900000,
    admin_code_id: 2063009,
    jurisdiction_id: 49,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Vermont',
    type: 'vermont',
    short_name: 'US-VT',
    state: 'VT',
    id: 3700000,
    admin_code_id: null,
    jurisdiction_id: 50,
    active: false,
    external_link: 'http://www.lexisnexis.com/hottopics/codeofvtrules/',
    following: null,
    country: 'US'
  },
  {
    name: 'West Virgina',
    type: 'west_virgina',
    short_name: 'US-WV',
    state: 'WV',
    id: 3900000,
    admin_code_id: null,
    jurisdiction_id: 54,
    active: false,
    external_link: 'http://apps.sos.wv.gov/adlaw/csr/',
    following: null,
    country: 'US'
  },
  {
    name: 'Wisconsin',
    type: 'wisconsin',
    short_name: 'US-WI',
    state: 'WI',
    id: 2100000,
    admin_code_id: 2085368,
    jurisdiction_id: 55,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Wyoming',
    type: 'wyoming',
    short_name: 'US-WY',
    state: 'WY',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 56,
    active: null,
    external_link: 'https://rules.wyo.gov/Search.aspx?mode=1',
    following: null,
    country: 'US'
  },
  {
    name: 'Washington DC',
    type: 'washington_dc',
    short_name: 'US-DC',
    state: 'DC',
    id: 2700000,
    admin_code_id: 2214985,
    jurisdiction_id: 11,
    active: true,
    following: null,
    country: 'US'
  }
];
if (window.location.host.includes('integration')) {
  defaultStateAgencies.push({
    name: 'Guam',
    id: 8000001,
    admin_code_id: 4851940,
    jurisdiction_id: 57,
    short_name: 'US-GU',
    state: 'GU',
    type: 'guam',
    active: true,
    following: null,
    country: 'US'
  });
}

export const defaultStateAgenciesObj = defaultStateAgencies.reduce((mem, agency) => {
  mem[agency.id || agency.jurisdiction_id] = agency;
  return mem;
}, {});

const defaultPubLaws = [
  {
    name: '115th Congress (2017 – 2018)',
    official_id: 'Public Law 115',
    short_name: 'Publaw 115',
    publaw_id: 2089813
  },
  {
    name: '114th Congress (2015 – 2016)',
    official_id: 'Public Law 114',
    short_name: 'Publaw 114',
    publaw_id: 2089811
  },
  {
    name: '113th Congress (2013 – 2014)',
    official_id: 'Public Law 113',
    short_name: 'Publaw 113',
    publaw_id: 2089809
  },
  {
    name: '112th Congress (2011 – 2012)',
    official_id: 'Public Law 112',
    short_name: 'Publaw 112',
    publaw_id: 2089807
  },
  {
    name: '111th Congress (2009 – 2010)',
    official_id: 'Public Law 111',
    short_name: 'Publaw 111',
    publaw_id: 2089805
  },
  {
    name: '110th Congress (2007 – 2008)',
    official_id: 'Public Law 110',
    short_name: 'Publaw 110',
    publaw_id: 2089803
  },
  {
    name: '109th Congress (2004 – 2006)',
    official_id: 'Public Law 109',
    short_name: 'Publaw 109',
    publaw_id: 2089801
  },
  {
    name: '108th Congress (2003 – 2004)',
    official_id: 'Public Law 108',
    short_name: 'Publaw 108',
    publaw_id: 2089799
  },
  {
    name: '107th Congress (2001 – 2002)',
    official_id: 'Public Law 107',
    short_name: 'Publaw 107',
    publaw_id: 2089797
  },
  {
    name: '106th Congress (1999 – 2000)',
    official_id: 'Public Law 106',
    short_name: 'Publaw 106',
    publaw_id: 2089795
  },
  {
    name: '105th Congress (1997 – 1998)',
    official_id: 'Public Law 105',
    short_name: 'Publaw 105',
    publaw_id: 2089793
  },
  {
    name: '104th Congress (1995 – 1996)',
    official_id: 'Public Law 104',
    short_name: 'Publaw 104',
    publaw_id: 2089791
  }
];

// Temporarily adding to integration so data team can test.
// Data currently does not exist on the production ES database
// so we don't want this on staging or production.
// (And of course, long term none of this should be hardcoded.)
if (window.location.host.includes('integration')) {
  defaultPubLaws.unshift({
    name: '116th Congress (2019 – 2020)',
    official_id: 'Public Law 116',
    short_name: 'Publaw 116',
    publaw_id: 3514346
  });
} else {
  // for pro
  defaultPubLaws.unshift({
    name: '116th Congress (2019 – 2020)',
    official_id: 'Public Law 116',
    short_name: 'Publaw 116',
    publaw_id: 3733331
  });
}

export { defaultPubLaws };

export const defaultPubLawsObj = defaultPubLaws.reduce((mem, item) => {
  mem[item.publaw_id] = item;
  return mem;
}, {});

export const defaultUSCode = {
  name: 'US Code',
  id: 1708777
};

export const defaultCFR = {
  name: 'CFR',
  id: 1917019
};

// TODO: this list must be kept in sync with server
export const defaultTopics = [
  { id: 1, label: 'Lending', description: null },
  { id: 2, label: 'BSA/AML', description: 'Bank Secrecy Act/Anti-Money Laundering' },
  { id: 3, label: 'Mortgage Lending', description: null },
  { id: 4, label: 'Crowdfunding', description: null },
  { id: 5, label: 'FCPA', description: 'Foreign Corrupt Practices Act' },
  { id: 6, label: 'Credit', description: null },
  { id: 7, label: 'Deposits', description: null },
  { id: 8, label: 'Bank Operations', description: null },
  { id: 9, label: 'Insurance', description: null },
  { id: 10, label: 'Privacy', description: null },
  { id: 11, label: 'Securities', description: null },
  { id: 12, label: 'Trust', description: null },
  { id: 13, label: 'Payments', description: null },
  { id: 14, label: 'Cybersecurity', description: null },
  { id: 15, label: 'Leasing', description: null },
  { id: 16, label: 'Debt Collection', description: null },
  { id: 17, label: 'Commercial Lending', description: null },
  { id: 18, label: 'Consumer Lending', description: null },
  { id: 19,
    label: 'Payday Loans',
    description: 'A payday loan (aka “small dollar loan” or “deferred deposit transaction” and '
    + '“deferred presentment transaction” or “payday advance”) is a short-term loan, often for $500 or less; repayment is typically '
    + 'due on the borrower’s next payday. When a borrower takes out a payday loan, he typically has to give the lender access to his '
    + 'checking account or write a post-dated check for the full balance that the lender can deposit when the loan is due.'
  }
];
