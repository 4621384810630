import {
  REQUEST_ORGANIZATION,
  RECEIVE_ORGANIZATION,
  RECEIVE_ORGANIZATION_LABELS,
  REQUEST_UPDATE_ORGANIZATION,
  RECEIVE_UPDATE_ORGANIZATION,
  REQUEST_ALLORGANIZATION,
  RECEIVE_ALLORGANIZATION,
  CREATE_ORGANIZATION,
  ORGANIZATION_CREATED,
  REQUEST_DELETE_ORGANIZATION,
  RECEIVE_DELETE_ORGANIZATION,
  REQUEST_TEAMS,
  RECEIVE_TEAMS,
  REQUEST_ALL_TEAM_MEMBERS,
  RECEIVE_ALL_TEAM_MEMBERS
} from './organizations.actions';

export const getInitialOrganizationState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  isUpdated: false,
  organization: {}
});

export const organization = (state = getInitialOrganizationState(), action) => {
  switch (action.type) {
    case REQUEST_ORGANIZATION:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ORGANIZATION:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        organization: action.organization
      };
    case REQUEST_UPDATE_ORGANIZATION:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false
      };
    case RECEIVE_UPDATE_ORGANIZATION:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        organization: action.organization
      };
    case RECEIVE_ORGANIZATION_LABELS:
      return {
        ...state,
        organization: { ...state.organization, labels: action.payload }
      };
    default:
      return state;
  }
};

export const getInitialAllOrganizationsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  isUpdated: false,
  organizations: []
});

export const all_organizations = (state = getInitialAllOrganizationsState(), action) => {
  switch (action.type) {
    case REQUEST_ALLORGANIZATION:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ALLORGANIZATION:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        organizations: action.organizations
      };
    case CREATE_ORGANIZATION:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        isUpdated: false
      };
    case ORGANIZATION_CREATED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        isUpdated: true
      };
    case REQUEST_DELETE_ORGANIZATION:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isDeleting: true,
        isUpdated: false
      };
    case RECEIVE_DELETE_ORGANIZATION:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isDeleting: false,
        isUpdated: true,
        organizations: null
      };
    default:
      return state;
  }
};

export const getInitialTeamsState = () => ({
  isFetching: false,
  isReady: false,
  all_teams: [],
  all_team_members: []
});

export const teams = (state = getInitialTeamsState(), action) => {
  switch (action.type) {
    case REQUEST_TEAMS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_TEAMS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        all_teams: action.teams
      };
    case REQUEST_ALL_TEAM_MEMBERS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ALL_TEAM_MEMBERS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        all_team_members: action.team_members
      };
    default:
      return state;
  }
};
