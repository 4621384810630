import React from 'react';
import PropTypes from 'prop-types';
import { Alert, ALERT_TYPES } from '../Alert';

export const AuthFormAlert = ({ message, ...props }) => {
  if (!message) {
    return null;
  }

  return <Alert {...props} type={message.type} title={message.title} content={message.content} />;
};

AuthFormAlert.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  }),
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isNotification: PropTypes.bool,
  isInlineNotification: PropTypes.bool,
  timeout: PropTypes.number
};

AuthFormAlert.defaultProps = {
  message: null,
  type: null,
  title: null,
  content: null,
  className: '',
  dataTestId: null,
  isNotification: false,
  isInlineNotification: false,
  timeout: null
};
