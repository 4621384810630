import type { JurisdictionFromResponse } from 'shared/features/jurisdictionsData/jurisdictionsData.api.types';
import type { AgencyFromResponse } from 'shared/features/agencies/agencies.api.types';

import * as errorUtils from 'shared/utils/errors';
import * as newsSourcesConstants from 'shared/features/sources/newsSources/newsSources.constants';
import * as jurisdictionsApi from 'shared/features/jurisdictionsData/jurisdictionsData.api';
import * as agenciesApi from 'shared/features/agencyData/agencyData.api';

export const useNewsSourcesFiltersAsyncLoaders = () => {
  const handleJurisdictionsFetch = async (inputString: string) => {
    try {
      const response = await jurisdictionsApi.api_getJurisdictions({ short_name: inputString });

      return response.items.map((jurisdiction: JurisdictionFromResponse) => ({
        value: jurisdiction.id,
        label: jurisdiction.short_name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleAgenciesFetch = async (inputString: string) => {
    try {
      const response = await agenciesApi.api_getAgencies({ short_name: inputString });

      return response.items.map((agency: AgencyFromResponse) => ({
        value: agency.id,
        label: agency.short_name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    [newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.JURISDICTION_IDS]:
      handleJurisdictionsFetch,
    [newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.AGENCY_IDS]: handleAgenciesFetch
  };
};
