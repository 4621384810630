import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  updateCategories,
  createCategories
} from "shared/features/categories/categories/categories.actions";
import {
  getFormatCategoryInfo,
  loadMetaCategoryOptions,
  fromCategoryToRawInfo,
  checkNewSplitCategory
} from "./DocumentCategories.helper";
import Modal from "../../components/Modal";
import AsyncSelect from "../../components/ReactSelect/SelectAsync";
import Button from "../../components/Button";
import SortableList from "../../components/SortableList";
import { TABLE_VARIABLES } from "./DocumentCategories.constants";

const DocumentCategoriesSplitModal = ({
  show,
  onHide,
  currentCategory
}) => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState({});
  const [newCategory, setNewCategory] = useState({});

  useEffect(() => {
    setCategory(getFormatCategoryInfo(currentCategory));
    setNewCategory(getFormatCategoryInfo({ ...currentCategory, jurisdictions: [] }));
  }, [show, currentCategory]);

  const handleChanges = (name, value) => {
    console.log("handleChanges", name, value);
    setCategory({ ...category, [name]: value });
  };

  const handleNewCategoryChanges = (name, value) => {
    console.log("handleNewCategoryChanges", name, value);
    setNewCategory({ ...newCategory, [name]: value });
  };

  const updateCategory = () => {
    const categoryChanges = fromCategoryToRawInfo(category, currentCategory);
    const categoryNewChanges = fromCategoryToRawInfo(newCategory, {});

    if (categoryChanges && categoryNewChanges) {
      const result = checkNewSplitCategory(category, newCategory);
      if (result) {
        dispatch(updateCategories({ ...categoryChanges, id: currentCategory.id }));
        dispatch(createCategories(categoryNewChanges));
        onHide();
      }
    }
  };

  return (
    <Modal
      className="document-categories_edit-modal"
      title={`Category: ${currentCategory.scraped_category}`}
      show={show}
      onHide={onHide}
      size={'small'}
    >
      <div className="document-categories_edit-modal_body-split">
        <div className="document-categories_edit-modal_body-split_block">
          <SortableList
            label="Jurisdictions"
            sortableClassName="split-jurisdictions"
            elementClassName="split-jurisdictions_element"
            list={category[TABLE_VARIABLES.jurisdictions] || []}
            onChange={sorted => handleChanges(TABLE_VARIABLES.jurisdictions, sorted)}
            group={TABLE_VARIABLES.jurisdictions}
            options={{ group: TABLE_VARIABLES.jurisdictions }}
          />
          <SortableList
            label="Jurisdictions"
            sortableClassName="split-jurisdictions"
            elementClassName="split-jurisdictions_element"
            list={newCategory[TABLE_VARIABLES.jurisdictions] || []}
            onChange={sorted => handleNewCategoryChanges(TABLE_VARIABLES.jurisdictions, sorted)}
            group={TABLE_VARIABLES.jurisdictions}
            options={{ group: TABLE_VARIABLES.jurisdictions }}
          />
        </div>
        <div className="document-categories_edit-modal_body-split_block">
          <AsyncSelect
            label="Meta category"
            value={category[TABLE_VARIABLES.document_meta_category]}
            onChange={e => handleChanges(TABLE_VARIABLES.document_meta_category, e)}
            loadOptions={value => loadMetaCategoryOptions(
              'name', value, 'name'
            )}
          />
          <AsyncSelect
            label="Meta category"
            value={newCategory[TABLE_VARIABLES.document_meta_category]}
            onChange={e => handleNewCategoryChanges(TABLE_VARIABLES.document_meta_category, e)}
            loadOptions={value => loadMetaCategoryOptions(
              'name', value, 'name'
            )}
          />
        </div>
      </div>
      <div className="document-categories_edit-modal_footer">
        <Button onClick={onHide} color="highlighted">Cancel</Button>
        <Button
          onClick={updateCategory}
          color="blue"
        >
          Save changes
        </Button>
      </div>
    </Modal>
  );
};

DocumentCategoriesSplitModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  currentCategory: PropTypes.shape({
    id: PropTypes.number
  })
};

DocumentCategoriesSplitModal.defaultProps = {
  show: false,
  currentCategory: {}
};

export default DocumentCategoriesSplitModal;
