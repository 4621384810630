import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'documentMetaCategories';
export const initialState = { items: [], count: 0 };

const documentMetaCategoriesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setMetaCategory: (state, action) => action.payload,
    createMetaCategory: (state, action) => ({
      ...state,
      count: state.count+1,
      items: [...state.items, action.payload]
    }),
    updateMetaCategory: (state, action) => ({
      ...state,
      items: state.items.map(item => item.id === action.payload.id ? action.payload : item)
    }),
    removeMetaCategory: (state, action) => ({
      ...state,
      count: state.count-1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default documentMetaCategoriesReducer.reducer;
export const {
  setMetaCategory,
  createMetaCategory,
  updateMetaCategory,
  removeMetaCategory
} = documentMetaCategoriesReducer.actions;
