import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  CODE_FILTER_EXTRA_OPTION,
  ERRORS,
  INFO_HELP,
  NAMES_META_DATA,
  SPEC_OPTIONS,
  VALUE_TYPES
} from '../CrawlSpecCreate.constants';
import ExpandableBtnList from '../HelpComponents/ExpandableBtnList';
import Checkbox from '../../../components/Checkbox_NEW';
import SpecElementsWrapper from './SpecElementsWrapper';
import TextInput from '../../../components/TextInput';
import DefaultValue from '../HelpComponents/DefaultValue';
import BtnNewElement from '../HelpComponents/BtnNewElement';
import { createNewExtractTags, createNewLocation } from '../CrawlSpecCreate.helper';
import Select from '../../../components/ReactSelect/Select';

const MetaData = ({
  disabled,
  show,
  currentPathUUID,
  currentExtractTagsUUID,
  currentLocationUUID,
  element,
  extractTags,
  locations,
  changeMetaData,
  changeCurrentPath,
  changeCurrentExtractTags,
  changeCurrentLocation,
  addNewPath,
  changeExtractTags,
  changeLocation,
  clearPathElements,
  clearLocationElements,
  clearMetaDataElements,
  errorFieldsPaths,
  errorFieldsLocations,
  errorFields,
  crawlSpecOptions
}) => {
  const checkPreDefinedCheckboxes = value => {
    changeMetaData(NAMES_META_DATA.IS_SINGLE, false);
    changeMetaData(NAMES_META_DATA.IS_TEXT, false);
    changeMetaData(NAMES_META_DATA.IS_DATE, false);
    changeMetaData(NAMES_META_DATA.IS_URL, false);
    switch (value) {
      case 'title':
      case 'summary':
        changeMetaData(NAMES_META_DATA.IS_SINGLE, true);
        changeMetaData(NAMES_META_DATA.IS_TEXT, true);
        break;
      case 'pub_date':
        changeMetaData(NAMES_META_DATA.IS_SINGLE, true);
        changeMetaData(NAMES_META_DATA.IS_TEXT, true);
        changeMetaData(NAMES_META_DATA.IS_DATE, true);
        break;
      case 'web_url':
      case 'pdf_url':
        changeMetaData(NAMES_META_DATA.IS_SINGLE, true);
        changeMetaData(NAMES_META_DATA.IS_URL, true);
        break;
      default:
        break;
    }
  };

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    changeMetaData(name, value);
  };

  const handleChangesName = e => {
    checkPreDefinedCheckboxes(e.target.value);
    handleChangesEvent(e);
  };

  const addNewExtractTag = () => {
    const newExtractTags = createNewExtractTags();
    changeExtractTags([...extractTags, newExtractTags]);
    changeMetaData(NAMES_META_DATA.EXTRACT_TAGS, newExtractTags.id);
  };

  const addNewLocation = () => {
    const newLocation = createNewLocation();
    changeLocation([...locations, newLocation]);
    return newLocation.id;
  };

  const metaType = _.get(element, [NAMES_META_DATA.VALUE_TYPE, 'value'], VALUE_TYPES.NOT_USE);
  const codeFilterOptions = _.get(crawlSpecOptions, SPEC_OPTIONS.CODE_FILTER_OPTIONS, []);
  const disableByType = metaType !== VALUE_TYPES.NOT_USE;

  return (
    <SpecElementsWrapper show={show} label="Meta Data" tooltip={INFO_HELP.META_DATA}>
      <TextInput
        label="Name"
        name={NAMES_META_DATA.NAME}
        value={element[NAMES_META_DATA.NAME]}
        isDisabled={disabled}
        onChange={handleChangesName}
        isError={errorFields.includes(NAMES_META_DATA.NAME)}
        errorText={ERRORS.metaDataName}
      />
      <DefaultValue
        disabled={disabled}
        valueType={element[NAMES_META_DATA.VALUE_TYPE]}
        valueTypeName={NAMES_META_DATA.VALUE_TYPE}
        value={element[NAMES_META_DATA.DEFAULT_VALUE]}
        valueName={NAMES_META_DATA.DEFAULT_VALUE}
        changeValue={changeMetaData}
        errorFields={errorFields}
      />
      <Select
        label="Regex"
        name={NAMES_META_DATA.REGEX}
        value={element[NAMES_META_DATA.REGEX]}
        onChange={e => changeMetaData(NAMES_META_DATA.REGEX, e)}
        options={crawlSpecOptions[SPEC_OPTIONS.DATE_OPTIONS]}
        isDisabled={disabled || disableByType}
        isError={errorFields.includes(NAMES_META_DATA.REGEX)}
        errorText={ERRORS.metaDataRegex}
        isSearchable
        isClearable
      />
      <Select
        label="Code Filter"
        name={NAMES_META_DATA.CODE_FILTER}
        value={element[NAMES_META_DATA.CODE_FILTER]}
        onChange={e => changeMetaData(NAMES_META_DATA.CODE_FILTER, e)}
        options={[...codeFilterOptions, CODE_FILTER_EXTRA_OPTION]}
        isDisabled={disabled || disableByType}
        isError={errorFields.includes(NAMES_META_DATA.CODE_FILTER)}
        errorText={ERRORS.metaDataRegex}
        isSearchable
        isClearable
      />
      <TextInput
        label="Date Format"
        name={NAMES_META_DATA.DATE_FORMAT}
        value={element[NAMES_META_DATA.DATE_FORMAT]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_META_DATA.DATE_FORMAT)}
      />
      <Checkbox
        label="Is Text"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_TEXT, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_TEXT)}
        checked={element[NAMES_META_DATA.IS_TEXT]}
      />
      <Checkbox
        label="Is URL"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_URL, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_URL)}
        checked={element[NAMES_META_DATA.IS_URL]}
      />
      <Checkbox
        label="Is Single"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_SINGLE, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_SINGLE)}
        checked={element[NAMES_META_DATA.IS_SINGLE]}
      />
      <Checkbox
        label="Is Sibling Tag"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_SIBLING_TAG, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_SIBLING_TAG)}
        checked={element[NAMES_META_DATA.IS_SIBLING_TAG]}
      />
      <Checkbox
        label="Is Sibling Text"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_SIBLING_TEXT, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_SIBLING_TEXT)}
        checked={element[NAMES_META_DATA.IS_SIBLING_TEXT]}
      />
      <Checkbox
        label="Is Date (Pub Date)"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_DATE, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_DATE)}
        checked={element[NAMES_META_DATA.IS_DATE]}
      />
      <Checkbox
        label="Parse PDF"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.PARSE_PDF, checked)}
        isError={errorFields.includes(NAMES_META_DATA.PARSE_PDF)}
        checked={element[NAMES_META_DATA.PARSE_PDF]}
      />
      <Checkbox
        label="From URL"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.FROM_URL, checked)}
        isError={errorFields.includes(NAMES_META_DATA.FROM_URL)}
        checked={element[NAMES_META_DATA.FROM_URL]}
      />
      <Checkbox
        label="Is Current URL"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_CURRENT_URL, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_CURRENT_URL)}
        checked={element[NAMES_META_DATA.IS_CURRENT_URL]}
      />
      <Checkbox
        label="Day First (Pub Date)"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.DAY_FIRST, checked)}
        isError={errorFields.includes(NAMES_META_DATA.DAY_FIRST)}
        checked={element[NAMES_META_DATA.DAY_FIRST]}
      />
      <Checkbox
        label="Is Text Generated"
        disabled={disabled || disableByType}
        onClick={checked => changeMetaData(NAMES_META_DATA.IS_TEXT_GENERATED, checked)}
        isError={errorFields.includes(NAMES_META_DATA.IS_TEXT_GENERATED)}
        checked={element[NAMES_META_DATA.IS_TEXT_GENERATED]}
      />
      <BtnNewElement
        elementExist={!!element[NAMES_META_DATA.EXTRACT_TAGS]}
        isDisabled={disabled || disableByType}
        label="Extract Tags"
        active={element[NAMES_META_DATA.EXTRACT_TAGS] === currentExtractTagsUUID}
        name={NAMES_META_DATA.EXTRACT_TAGS}
        addNew={addNewExtractTag}
        removeItem={() => {
          changeMetaData(NAMES_META_DATA.EXTRACT_TAGS, null);
          clearMetaDataElements(element[NAMES_META_DATA.EXTRACT_TAGS]);
        }}
        setCurrent={() => changeCurrentExtractTags(element[NAMES_META_DATA.EXTRACT_TAGS])}
        isError={errorFields.includes(NAMES_META_DATA.EXTRACT_TAGS)}
      />
      <ExpandableBtnList
        label="Locations"
        isDisabled={disabled}
        isDisabledBtn={disableByType}
        value={element[NAMES_META_DATA.LOCATIONS]}
        name={NAMES_META_DATA.LOCATIONS}
        addMoreName="Location"
        activeID={currentLocationUUID}
        onChange={changeMetaData}
        onClickBtn={changeCurrentLocation}
        createNewItemTemplate={addNewLocation}
        helpRemoveFunc={clearLocationElements}
        errorFields={Object.keys(errorFieldsLocations)}
        isError={
          errorFields.includes(NAMES_META_DATA.LOCATIONS) &&
          !_.get(element, [NAMES_META_DATA.LOCATIONS, 'length'], '')
        }
      />
      <ExpandableBtnList
        label="Paths"
        isDisabled={disabled}
        isDisabledBtn={disableByType}
        value={element[NAMES_META_DATA.PATH]}
        name={NAMES_META_DATA.PATH}
        addMoreName="Path"
        activeID={currentPathUUID}
        onChange={changeMetaData}
        onClickBtn={changeCurrentPath}
        createNewItemTemplate={addNewPath}
        helpRemoveFunc={clearPathElements}
        errorFields={Object.keys(errorFieldsPaths)}
        isError={
          errorFields.includes(NAMES_META_DATA.PATH) &&
          !_.get(element, [NAMES_META_DATA.PATH, 'length'], '')
        }
        errorText={ERRORS.metaDataPath}
      />
    </SpecElementsWrapper>
  );
};

MetaData.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentPathUUID: PropTypes.string,
  currentExtractTagsUUID: PropTypes.string,
  currentLocationUUID: PropTypes.string,
  element: PropTypes.shape({}),
  extractTags: PropTypes.arrayOf(PropTypes.shape({})),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  addNewPath: PropTypes.func.isRequired,
  changeMetaData: PropTypes.func.isRequired,
  changeCurrentPath: PropTypes.func.isRequired,
  changeCurrentExtractTags: PropTypes.func.isRequired,
  changeCurrentLocation: PropTypes.func.isRequired,
  changeExtractTags: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  clearPathElements: PropTypes.func.isRequired,
  clearLocationElements: PropTypes.func.isRequired,
  clearMetaDataElements: PropTypes.func.isRequired,
  errorFieldsPaths: PropTypes.shape({}),
  errorFieldsLocations: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string),
  crawlSpecOptions: PropTypes.shape({})
};

MetaData.defaultProps = {
  disabled: false,
  currentPathUUID: '',
  currentExtractTagsUUID: '',
  currentLocationUUID: '',
  show: false,
  element: {},
  extractTags: [],
  locations: [],
  errorFieldsPaths: {},
  errorFieldsLocations: {},
  errorFields: [],
  crawlSpecOptions: {}
};

export default MetaData;
