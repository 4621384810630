import React from 'react';
import { Button } from "react-bootstrap";
import _ from 'lodash';

const CrawlerPerformanceTableBody = ({ crawlPerformanceRequests, showGeneratedInfo }) => {
  return crawlPerformanceRequests
    && crawlPerformanceRequests.map
    && crawlPerformanceRequests.map(crawlPerformanceRequest => {
      crawlPerformanceRequest.user = crawlPerformanceRequest.user || {};
      return (
        <div
          key={crawlPerformanceRequest.id}
          className="adminToolTableFlexRow"
        >
          <div>{crawlPerformanceRequest.name}</div>
          <div>{crawlPerformanceRequest.status}</div>
          <div>{crawlPerformanceRequest.user.email}</div>
          <div>
            <ul>
              {_.map(crawlPerformanceRequest.parameters, (value, key) => (<li>{`${key}: ${value}`}</li>))}
            </ul>
          </div>
          <div>{crawlPerformanceRequest.status_details}</div>
          <div>
            {crawlPerformanceRequest.status === 'complete' && (
              <Button
                className="rightBtn tallButton"
                bsStyle="primary"
                onClick={() => {
                  showGeneratedInfo(crawlPerformanceRequest);
                }}
              >
                {'>>>'}
              </Button>
            )}
          </div>
        </div>
      );
    });
};

export default CrawlerPerformanceTableBody;
