import React from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import "./BtnNewElement.styles.scss";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";

const BtnNewElement = ({
  elementExist,
  isDisabled,
  label,
  name,
  addNew,
  removeItem,
  setCurrent,
  isError,
  active
}) => (
  <div
    className={cn(
      'btn-new-element',
      {
        'btn-new-element_disabled': isDisabled,
        'btn-new-element_error': isError
      }
    )}
  >
    {
      elementExist ? (
        <>
          <Button
            className={cn(
              'btn-new-element_exist_btn',
              {
                'btn-new-element_exist_btn_active': active,
                'btn-new-element_exist_btn_active_error': active && isError
              }
            )}
            color="highlighted"
            onClick={setCurrent}
          >
            {label}
          </Button>
          <CloseIcon
            onClick={removeItem}
            className="btn-new-element_exist_icon btn-new-element_exist_icon_active"
          />
        </>
      ) : (
        <Typography
          variant="label"
          color="blue"
          className={cn({
            'btn-new-element_new_disabled': isDisabled,
            'btn-new-element_new_error': isError
          })}
          onClick={addNew}
        >
          {`+ Add ${label}`}
        </Typography>
      )
    }
  </div>
);

BtnNewElement.propTypes = {
  elementExist: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  addNew: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  active: PropTypes.bool
};

BtnNewElement.defaultProps = {
  isDisabled: false,
  label: '',
  name: '',
  isError: false,
  active: false
};

export default BtnNewElement;
