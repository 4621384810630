import React, { useMemo } from 'react';
import _ from "lodash";
import { COLUMNS_CONFIG } from './JurisdictionTool.constants';
import HeaderSort from '../../components/SortElements/HeaderSort';

const getJurisdictionHeader = ({ jurisdictionInfo }) => {
  const jurisdictionColumns = jurisdictionInfo.map(
    item => COLUMNS_CONFIG.find(col => col.id === item)
  );
  return jurisdictionColumns.map(item => (
    <HeaderSort
      type={_.get([], [item.id, 'sorting_direction'], null)}
      onClick={() => {}}
      item={item}
    />
  ));
};

export default ({ jurisdictionInfo }) => {
  return useMemo(() => [getJurisdictionHeader({
    jurisdictionInfo
  })], [jurisdictionInfo]);
};
