import React from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import _ from 'lodash';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import { fetchAllUsers, updateSpecifiedUser } from 'shared/features/user/user.actions';
import { popupName, popupSettings } from "../../constants/popupInfoConst";
import { namesOfRoles } from '../../constants/userRoles';
import { clearErrors } from 'shared/features/error/actions';

import Spinner from '../../components/spinner';
import AdminRolesToolModal from './AdminRolesToolModal';
import PopupInfo from "../../components/PopupInfo";
import {withAdminPermissions} from "../../utils/hooks";

const defaultModalConfig = {
  showModal: false,
  error: false,
  errorText: '',
  user_id: '',
  user_email: '',
  user_role: ''
};

class AdminRolesTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textForError: '',
      showInfoPopup: false,
      popupName: "",
      showSpinner: false,
      ...defaultModalConfig
    };
  }

  componentDidMount() {
    this.props.fetchAllUsers();
    this.props.clearErrors('settings');
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({
      ...defaultModalConfig
    });
  };

  editUserRole = (user_profile) => {
    this.setState({
      user_id: user_profile.id,
      user_email: user_profile.email,
      user_role: _.head(_.intersection(Object.values(namesOfRoles), user_profile.roles)),
      showModal: true
    });
  };

  deleteUserRole = (user_profile) => {
    const popupBoddy = popupSettings.find(item => item.name === popupName.deleteUserRole);
    this.setState({
      user_id: user_profile.id,
      user_email: user_profile.email,
      user_role: user_profile.roles[0],
      popupName: popupName.deleteUserRole,
      showInfoPopup: true,
      textForError: `${popupBoddy.body} ${user_profile.email}?`
    });
  };

  findUserAndReturnOldAdminRoles = (user_email) => {
    const { all_users } = this.props;
    const userRoles = _.get(all_users.users.find(user_profile => user_profile.email === user_email), 'roles');
    const isChangeCategoryAccess = {};
    const valuesForDel = _.intersection(Object.values(namesOfRoles), userRoles);
    valuesForDel.forEach(role => {
      isChangeCategoryAccess[role] = false;
    });

    return isChangeCategoryAccess;
  };

  onAddEditRole = () => {
    const { viewer } = this.props;
    const { user_email, user_role } = this.state;
    if (!user_email || !user_role) {
      this.setState({ error: true, errorText: '' });
      return;
    }
    if (viewer.user.email === user_email) {
      this.setState({
        error: true,
        errorText: "You can't change your own role!"
      });
      return;
    }

    const isChangeCategoryAccess = this.findUserAndReturnOldAdminRoles(user_email);
    isChangeCategoryAccess[user_role] = true;

    this.requestOnChangeUserRole(user_email, isChangeCategoryAccess);
  };

  onDeleteRole = () => {
    const { user_email } = this.state;
    const isChangeCategoryAccess = this.findUserAndReturnOldAdminRoles(user_email);
    this.requestOnChangeUserRole(user_email, isChangeCategoryAccess);
  };

  requestOnChangeUserRole = (user_email, isChangeCategoryAccess) => {
    this.props.clearErrors('settings');
    this.setState({
      popupName: popupName.errorRespPopup,
      showInfoPopup: false
    });
    this.props.updateSpecifiedUser(user_email, { isChangeCategoryAccess });
    this.closeModal();
    this.props.fetchAllUsers();
  };

  hideInfoPopup = () => {
    this.setState({
      showInfoPopup: false,
      textForError: ""
    });
    this.props.clearErrors('settings');
  };

  render() {
    const { haveEditAccess } = this.props;
    const user_list = this.props.all_users.users.map(user_profile => (
      <tr key={user_profile.id}>
        <td>
          {user_profile.email}
        </td>
        <td>
          {user_profile.roles.join(', ')}
        </td>
        <td className="adminRolesTable_editColumn">
          {user_profile.email !== this.props.viewer.user.email && haveEditAccess && (
            <React.Fragment>
              <DeleteIcon onClick={() => this.deleteUserRole(user_profile)} />
              <CreateIcon onClick={() => this.editUserRole(user_profile)} />
            </React.Fragment>
          )}
        </td>
      </tr>
    ));
    return (
      <div className="document-meta-table thinBtn">
        <h1>
          Admin Roles Tools
        </h1>
        {haveEditAccess && (
          <div className="boldNotification">
            <p className="boldNotification_Text">
              Add new role to user
            </p>
            <Button bsStyle="primary" onClick={this.openModal}>
              Add
            </Button>
          </div>
        )}
        <Table striped condensed hover className="adminRolesTable">
          <thead>
            <tr>
              <th>
                User
              </th>
              <th>
                Role
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {user_list}
          </tbody>
        </Table>
        <AdminRolesToolModal
          closeModal={this.closeModal}
          showModal={this.state.showModal}
          handleChange={this.handleChange}
          onSave={this.onAddEditRole}
          error={this.state.error}
          errorText={this.state.errorText}
          user_email={this.state.user_email}
          user_role={this.state.user_role}
          all_users={this.props.all_users.users}
          user_id={this.state.user_id}
        />
        <PopupInfo
          show={this.state.showInfoPopup || !!this.props.errors.settings}
          onHide={this.hideInfoPopup}
          popupName={this.state.popupName}
          errorText={this.state.textForError}
          onSecondBtn={this.onDeleteRole}
        />
        {(!this.props.all_users.isReady || this.state.showSpinner) && (
          <div className="spinnerWrapper">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}


const
  mapStateToProps = state => {
    return {
      all_users: state.all_users,
      viewer: state.current_user,
      errors: state.errors
    };
  };

const
  ReduxAdminRolesTool = withAdminPermissions(connect(mapStateToProps, {
    clearErrors,
    fetchAllUsers,
    updateSpecifiedUser
  })(AdminRolesTool));

export default ReduxAdminRolesTool;
