import type { EitlLabelActionsDropdownProps } from '../EitlLabelActionsDropdown.types';
import type { EitlLabelActionsDropdownListConfig } from './useEitlLabelActionsDropdownListConfig.types';

import React from 'react';

import * as constants from '../EitlLabelActionsDropdown.constants';
import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useEitlLabelActionsDropdownListConfig = ({
  props
}: {
  props: Pick<EitlLabelActionsDropdownProps, 'shouldShowDeleteOption'>;
}): EitlLabelActionsDropdownListConfig => {
  const options: EitlLabelActionsDropdownListConfig['options'] = useMemo(
    () => [
      {
        startIcon: <uiLib.IconEdit />,
        label: (
          <uiLib.Typography className="eitl-label-actions__list-option-label">
            Rename
          </uiLib.Typography>
        ),
        value: constants.LABEL_ACTIONS_VALUES.RENAME
      },
      ...(props.shouldShowDeleteOption
        ? [
            {
              startIcon: <uiLib.IconDelete />,
              label: (
                <uiLib.Typography className="eitl-label-actions__list-option-label">
                  Delete
                </uiLib.Typography>
              ),
              value: constants.LABEL_ACTIONS_VALUES.DELETE
            }
          ]
        : [])
    ],
    [props.shouldShowDeleteOption]
  );

  return {
    options
  };
};
