import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../components/Checkbox';

const BodyCategoryCheckbox = ({ category, selectCategory, selectedCategory }) => (
  <Checkbox
    onClick={(checked, e) => {
      e.stopPropagation();
      selectCategory({ category, add: checked });
    }}
    checked={selectedCategory.some(cat => cat.id === category.id)}
  />
);

BodyCategoryCheckbox.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number
  }),
  selectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.arrayOf(PropTypes.number)
};

BodyCategoryCheckbox.defaultProps = {
  category: {},
  selectedCategory: []
};

export default BodyCategoryCheckbox;
