import { createAsyncThunk } from '@reduxjs/toolkit';
import { openErrorSnackbar } from '../../snackbar/snackbar.reducer';
import { api_getSpiderRunReports } from './spiderRunReports.api';

export const FETCH_SPIDER_RUN_REPORTS = 'FETCH_SPIDER_RUN_REPORTS';

export const ERRORS = {
  getSpiderRunReports: 'Request for spider run reports is failed'
};

export const getSpiderRunReports = createAsyncThunk(
  FETCH_SPIDER_RUN_REPORTS,
  async ({ crawlId, params }, { dispatch }) => {
    try {
      return await api_getSpiderRunReports(crawlId, params);
    } catch (e) {
      dispatch(openErrorSnackbar(ERRORS.getSpiderRunReports));
      throw e;
    }
  }
);
