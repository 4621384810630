import React from 'react';

const headerColumns = [
  { name: 'Spider name' },
  { name: 'Ingested', stateName: 'docs_ingested' },
  { name: 'Ingested average' },
  { name: 'Skipped', stateName: 'docs_skipped' },
  { name: 'Skipped average' },
  { name: 'Errors', stateName: 'docs_error' },
  { name: 'Errors average' },
  { name: 'Total Runs', stateName: 'total_runs' }
];

const CrawlerPerformanceTableHeader = props => {
  const tableHead = headerColumns.map((item, index) => (
    <div
      onClick={() => props.sortTable(item.stateName)}
      className="centeredHeaderName"
      key={index}
    >
      {item.name}
      {item.stateName && (
        <i className="material-icons sortIcon">
          {props.sort[item.stateName] ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
        </i>)}
    </div>)
  );
  return (
    <div className="adminToolTableFlexRow flexHeader">
      {tableHead}
    </div>
  );
};

export default CrawlerPerformanceTableHeader;
