import Bugsnag from '@bugsnag/js';

import { StatusCodes } from 'http-status-codes';

import * as constants from './fetch.constants';
import * as analytics from 'shared/utils/analytics';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

export const getStringifiedErrorMessage = error => {
  try {
    if (typeof error !== 'string') {
      return JSON.stringify(error);
    }

    return error;
  } catch (e) {
    errorUtils.logError(e);

    return 'Unknown error';
  }
};

/**
 *
 * Convert the axios error to the object to use and handle errors in Redux
 * @param {Error} axiosError - axios error returned from axios reuest
 * @return {Object} - formatted object to use in redux
 *
 * Returned object has following types, and we use it in redux error handling:
 *
 *  - TIMEOUT: Timeouted from the API request(408, 504 and request timeouted error)
 *  - SERVER: API response with the error (4xx ~ 5xx)
 *  - NETWORK: Client network error
 *  - UNKNOWN: Unknown and unexpected error
 */

export const convertAxiosErrorToObject = axiosError => {
  const errorObject = new errorUtils.NetworkError({
    status: 0,
    type: constants.ERROR_TYPES.UNKNOWN,
    message: constants.ERROR_MESSAGES.UNKNOWN
  });

  if (axiosError.response) {
    const { status, data: { message, error } = {} } = axiosError.response;

    errorObject.status = status;
    errorObject.message = getStringifiedErrorMessage(
      message || error || constants.ERROR_MESSAGES.SERVER
    );
    errorObject.type = [StatusCodes.GATEWAY_TIMEOUT, StatusCodes.REQUEST_TIMEOUT].includes(status)
      ? constants.ERROR_TYPES.TIMEOUT
      : constants.ERROR_TYPES.SERVER;
  } else if (axiosError.request) {
    errorObject.message = axiosError.message || constants.ERROR_MESSAGES.NETWORK;
    errorObject.type =
      axiosError?.code === 'ECONNABORTED'
        ? constants.ERROR_TYPES.TIMEOUT
        : constants.ERROR_TYPES.NETWORK;
  }

  return errorObject;
};

export const getErrorData = errorResponse => {
  const {
    status: errorStatus,
    statusText: errorStatusText,
    data: errorData,
    request: { _url: errorURL }
  } = errorResponse;

  const errorMessage = getStringifiedErrorMessage(
    errorData.message || errorData.error || constants.ERROR_MESSAGES.SERVER
  );

  return {
    errorStatus,
    errorStatusText,
    errorURL,
    errorMessage,
    errorData
  };
};

export const logError = ({ dataType, errorResponse }) => {
  const { errorStatus, errorStatusText, errorMessage, errorURL } = getErrorData(errorResponse);

  const metadata = {
    component: {
      name: dataType
    },
    xhr: {
      status: errorStatus,
      statusText: errorStatusText,
      url: errorURL
    }
  };

  Bugsnag.notify(errorMessage);
  Bugsnag.addMetadata('XMLHttpRequestError', metadata);

  uiLib.showNotification({
    type: uiLib.ALERT_TYPES.ERROR,
    title: dataType,
    message: errorMessage
  });

  analytics.sendGoogleAnalyticsException({
    description: errorMessage,
    isFatal: true
  });

  analytics.safe_mixpanel_track('Exception – Other', {
    hitType: 'exception',
    exDescription: errorMessage,
    exFatal: true
  });
};
