import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './documentFileLink.constants';

export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const documentFileLinkReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setDocumentFileLink: (state, action) => action.payload
  }
});

export default documentFileLinkReducer.reducer;
export const { startLoad, finishLoad, setDocumentFileLink } = documentFileLinkReducer.actions;
