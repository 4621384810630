import React from 'react';
import PropTypes from 'prop-types';
import * as lodash from 'lodash';
import Typography from '../../../components/Typography';
import Badge from '../../../components/Badge';
import * as commandRequestTypesConstants from '../../../constants/commandRequestTypes';
import './CrawlerCatalogCommandRequestResult.styles.scss';

const getBadgeType = status => {
  let result;
  switch (status) {
    case commandRequestTypesConstants.COMMAND_REQUEST_STATUS.QUEUED:
    case commandRequestTypesConstants.COMMAND_REQUEST_STATUS.MSG_QUEUED:
      result = 'warning';
      break;
    case commandRequestTypesConstants.COMMAND_REQUEST_STATUS.TIMED_OUT:
    case commandRequestTypesConstants.COMMAND_REQUEST_STATUS.FAILED:
      result = 'error';
      break;
    case commandRequestTypesConstants.COMMAND_REQUEST_STATUS.COMPLETED:
      result = 'success';
      break;
    default:
      result = 'info';
  }
  return result;
};

const CrawlCatalogCommandRequestResult = ({ commandRequest }) => {
  return (
    <details className="request-result">
      <summary>
        <Typography variant="body">{lodash.get(commandRequest, 'name')}</Typography>
        <Badge type={getBadgeType(lodash.get(commandRequest, 'status'))} size="medium">
          {lodash.get(commandRequest, 'status')}
        </Badge>
      </summary>
      <div>
        <Typography variant="body">{`Created: ${lodash.get(
          commandRequest,
          'created_at'
        )}`}</Typography>
      </div>
      <div>
        <Typography variant="body">{`Updated: ${lodash.get(
          commandRequest,
          'updated_at'
        )}`}</Typography>
      </div>
      <div>
        <Typography variant="body">{lodash.get(commandRequest, ['type', 'name'])}</Typography>
      </div>
      <div>
        <Typography variant="p">{lodash.get(commandRequest, 'status_details')}</Typography>
      </div>
      <div>
        <Typography variant="body">
          {`${lodash.get(commandRequest, ['user', 'first_name'])} ${lodash.get(commandRequest, [
            'user',
            'last_name'
          ])}`}
        </Typography>
      </div>
      <div>
        <a href={lodash.get(commandRequest, 'upload_link', '#') || '#'}>
          <Typography variant="body">
            {lodash.get(commandRequest, ['parameters', 'file_name'])}
          </Typography>
        </a>
      </div>
    </details>
  );
};

CrawlCatalogCommandRequestResult.propTypes = {
  commandRequest: PropTypes.oneOfType([
    PropTypes.number, // to cover null case
    PropTypes.shape({})
  ])
};

CrawlCatalogCommandRequestResult.defaultProps = {
  commandRequest: {}
};

export default CrawlCatalogCommandRequestResult;
