import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";

const MMenuItem = React.forwardRef(({ children, classes, disabled, onClick, value }, ref) => (
  <MenuItem classes={classes} onClick={onClick} innerRef={ref} value={value} disabled={disabled}>
    {children}
  </MenuItem>
));

MMenuItem.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

MMenuItem.defaultProps = {
  children: null,
  classes: '',
  disabled: false
};

export default MMenuItem;
