import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'snackbar';

export const snackbarStatuses = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success'
};

export const initialState = {
  severity: '',
  label: '',
  open: false,
  closeTime: 6000
};

const snackbarReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    openErrorSnackbar: (state, action) => {
      state.severity = snackbarStatuses.ERROR;
      state.label = action.payload;
      state.open = true;
    },
    openWarningSnackbar: (state, action) => {
      state.severity = snackbarStatuses.WARNING;
      state.label = action.payload;
      state.open = true;
    },
    openInfoSnackbar: (state, action) => {
      state.severity = snackbarStatuses.INFO;
      state.label = action.payload;
      state.open = true;
    },
    openSuccessSnackbar: (state, action) => {
      state.severity = snackbarStatuses.SUCCESS;
      state.label = action.payload;
      state.open = true;
    },
    openCustomSnackbar: (state, action) => {
      state.severity = action.payload.severity;
      state.label = action.payload.label;
      state.closeTime = action.payload.closeTime || 6000;
      state.open = true;
    },
    closeSnackbar: (state, action) => {
      state.open = false;
      state.severity = '';
      state.label = '';
    }
  }
});

export default snackbarReducer.reducer;
export const {
  openErrorSnackbar,
  openWarningSnackbar,
  openInfoSnackbar,
  openSuccessSnackbar,
  openCustomSnackbar,
  closeSnackbar } = snackbarReducer.actions;
