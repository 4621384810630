export enum REPORT_STATUSES {
  PROCESSED = 'processed',
  PROCESSED_WITH_FAILURE = 'processed_with_failure',
  ERROR = 'error',
  STOPPED = 'stopped',
  FAILED = 'failed',
  TIMED_OUT = 'timed_out',
  UNFINISHED = 'unfinished'
}

export const REPORT_STATUS_LABELS: Record<REPORT_STATUSES, string> = {
  [REPORT_STATUSES.PROCESSED]: 'Processed',
  [REPORT_STATUSES.PROCESSED_WITH_FAILURE]: 'Processed',
  [REPORT_STATUSES.ERROR]: 'Error',
  [REPORT_STATUSES.STOPPED]: 'Stopped',
  [REPORT_STATUSES.FAILED]: 'Failed',
  [REPORT_STATUSES.TIMED_OUT]: 'Timed Out',
  [REPORT_STATUSES.UNFINISHED]: 'Unfinished'
};

export enum BODY_CRAWL_STATUS_TEXT_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center'
}
