import type { DryRunReport } from '../../../DryRunReportsTable.types';

import { DryRunReportStatus } from '../../../DryRunReportsTable.constants';

import * as uiLib from '@compliance.ai/web-components';

export const getStatus = (reportStatus: DryRunReport['status']) => {
  switch (reportStatus) {
    case DryRunReportStatus.Skipped:
      return { reportStatus, typographyStatus: uiLib.TYPOGRAPHY_STATUS.WARNING };
    case DryRunReportStatus.Error:
      return { reportStatus, typographyStatus: uiLib.TYPOGRAPHY_STATUS.ERROR };
    case DryRunReportStatus.Info:
    case DryRunReportStatus.Debug:
      return { reportStatus, typographyStatus: uiLib.TYPOGRAPHY_STATUS.INITIAL };
    default:
      return {
        reportStatus: DryRunReportStatus.Success,
        typographyStatus: uiLib.TYPOGRAPHY_STATUS.SUCCESS
      };
  }
};
