import type { LogsModalProps } from '../LogsModal.types';
import type { LogsModalData } from './useLogsModalData.types';

import * as helpers from './useLogsModalData.helpers';

import { useMemo } from 'react';

export const useLogsModalData = ({ report }: Pick<LogsModalProps, 'report'>): LogsModalData => {
  const formattedData = useMemo(
    () => ({
      rows: helpers.getLogsForTable(report)
    }),
    [report]
  );

  return {
    formattedData
  };
};
