import type { ExportModalProps } from '../ExportModal.types';
import type { ExportModalData } from './useExportModalData.types';
import type { ExportModalHandlers } from './useExportModalHandlers.types';

import * as constants from '../ExportModal.constants';

export const useExportModalHandlers = ({
  props,
  localState,
  localActions,
  formattedData
}: {
  props: Pick<ExportModalProps, 'contentType' | 'onExport' | 'onClose'>;
  localState: ExportModalData['localState'];
  localActions: ExportModalData['localActions'];
  formattedData: ExportModalData['formattedData'];
}): ExportModalHandlers => {
  const handleSelectAll = () => {
    localActions.setSelectedColumns(constants.BASIC_EXPORT_COLUMNS);
  };

  const handleClearAll = () => {
    localActions.setSelectedColumns([]);
  };

  const handleColumnSelectChange: ExportModalHandlers['handleColumnSelectChange'] =
    column => isSelected => {
      let selectedColumns = localState.selectedColumns.filter(col => col !== column);

      if (isSelected) {
        selectedColumns = [...selectedColumns, column];
      }

      localActions.setSelectedColumns(selectedColumns);
    };

  const handleExport = () => {
    if (!formattedData.isExportDisabled) {
      props.onExport(props.contentType, formattedData.apiColumns);
      props.onClose();
    }
  };

  return {
    handleSelectAll,
    handleClearAll,
    handleColumnSelectChange,
    handleExport
  };
};
