import {
  fetchAllCommandRequests as reduxFetchAllCommandRequests,
  fetchAllCommands as reduxFetchAllCommands,
  uploadNewCommandRequest as reduxUploadNewCommandRequest,
  createNewAutoQaCommandRequest as reduxCreateNewAutoQaCommandRequest
} from '../commands.actions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useCommandsReduxActions = () => {
  const dispatch = useDispatch();

  const fetchAllCommandRequests = useCallback(
    (...params) => {
      return dispatch(reduxFetchAllCommandRequests(...params));
    },
    [dispatch]
  );

  const fetchAllCommands = useCallback(
    (...params) => {
      return dispatch(reduxFetchAllCommands(...params));
    },
    [dispatch]
  );

  const uploadNewCommandRequest = useCallback(
    (...params) => {
      return dispatch(reduxUploadNewCommandRequest(...params));
    },
    [dispatch]
  );

  const createNewAutoQaCommandRequest = useCallback(
    (...params) => {
      return dispatch(reduxCreateNewAutoQaCommandRequest(...params));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchAllCommandRequests,
      fetchAllCommands,
      uploadNewCommandRequest,
      createNewAutoQaCommandRequest
    }),
    [
      fetchAllCommandRequests,
      fetchAllCommands,
      uploadNewCommandRequest,
      createNewAutoQaCommandRequest
    ]
  );
};
