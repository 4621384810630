import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import "./Checkbox_NEW.styles.scss";
import Typography from "./Typography";

function Checkbox_NEW({
  className,
  onClick,
  checked,
  labelClassName,
  label,
  size,
  disabled
}) {
  const checkBoxPress = (e) => {
    onClick(!checked, e);
  };

  return (
    <label className={cn("checkbox-custom", className)}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={checkBoxPress}
      />
      <span className="checkbox-custom_checkmark" />
      <Typography
        variant="label"
        className={cn(labelClassName)}
      >
        {label}
      </Typography>
    </label>
  );
}

Checkbox_NEW.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string
};

Checkbox_NEW.defaultProps = {
  checked: false,
  disabled: false,
  className: '',
  labelClassName: '',
  label: '',
  size: 'default'
};

export default Checkbox_NEW;
