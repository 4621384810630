import type { FC } from 'react';
import type { ConfirmationModalProps } from './ConfirmationModal.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';
export const ConfirmationModal: FC<ConfirmationModalProps> = ({ mode, onSubmit, onClose }) => {
  return (
    <uiLib.Modal
      isOpen
      title={mode.title}
      onClose={onClose}
      onSubmit={onSubmit}
      withFooter
      secondaryButtonText="Cancel"
      primaryButtonText={mode.title}
    >
      <uiLib.Typography component="p">{mode.body}</uiLib.Typography>
    </uiLib.Modal>
  );
};
