import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import { api_getScrapedCategories } from 'shared/features/categories/scrapedCategories/scrapedCategories.api';
import Typography from "../../../../components/Typography";
import Spinner from "../../../../components/spinner";
import { SCRAP_CAT_CONST } from '../Glassdoor.constants';
import { formatScrapCat } from "../Glassdoor.helper";
import Info from "./GlassdoorTableComponents/Info";
import Header, { HeaderRow } from "./GlassdoorTableComponents/Header";
import GlassdoorCrawlQA from "./GlassdoorCrawlQA";

const ERROR = 'Scraped Category Upload Failed';
const NOT_FOUND = 'Scraped Category for this Cai Category';

const GlassdoorScrapedCategories = ({
  openPub,
  caiCategoryID,
  artifacts
}) => {
  const [load, setLoad] = useState(false);
  const [scrapedCategory, setScrapedCategory] = useState([]);
  const [count, setCount] = useState(0);
  const [timestamp, setTimestamp] = useState('time');
  const [error, setError] = useState('');

  const request = async () => {
    await setLoad(true);
    await setError('');
    try {
      const response = await api_getScrapedCategories({
        glassdoor: true,
        doc_cai_category_id: caiCategoryID,
        limit: 5,
        offset: scrapedCategory.length,
        ...artifacts
      });
      const items = _.get(response, 'items', []);
      const countItems = _.get(response, 'count', []);
      if (items) {
        await setScrapedCategory([...scrapedCategory, ...items]);
        await setCount(countItems);
        await setTimestamp(Date().toString());
      }
    } catch {
      await setError(ERROR);
    } finally {
      await setLoad(false);
    }
  };

  useEffect(() => {
    if (openPub && caiCategoryID) {
      request();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPub, caiCategoryID]);

  const formatScrapedCategoryCategory = formatScrapCat(scrapedCategory, timestamp);

  return (
    <div className="glassdoor_table glassdoor_table_left-border">
      <Header
        leftBlockClass="category-header-left-block"
        leftChild={(
          <div className="scraped-category scraped-category_head">
            <div><Typography bold variant="p" color="transparent_black">Scraped Category</Typography></div>
            <HeaderRow items={[
              'Crawler', 'Crawler Status', 'Last Check', 'Added', 'QA Status', 'Issue Type',
              'Issue Identified', 'Original ETA', 'Jira Ticket', 'Jira Status'
            ]}
            />
          </div>
        )}
      />
      <div>
        {formatScrapedCategoryCategory.map(item => (
          <div
            key={`${item[SCRAP_CAT_CONST.ID]}-${item[SCRAP_CAT_CONST.NAME]}`}
            className="scraped-category scraped-category_body"
          >
            <div className="scraped-category_body_scrap">{item[SCRAP_CAT_CONST.NAME]}</div>
            <GlassdoorCrawlQA
              scrapedCat={item}
              openPub={openPub}
              artifacts={{
                ...artifacts,
                cai_category_id: caiCategoryID
              }}
            />
            {/*This div should be used for counter*/}
            {/*<div className="scraped-category_counter" />*/}
          </div>
        ))}
      </div>
      {load && <Info><Spinner /></Info>}
      {error && <Info><Typography bold variant="p" color="red">{error}</Typography></Info>}
      {!formatScrapedCategoryCategory.length && !load && !error && (
        <Info><Typography bold variant="p" color="yellow">{NOT_FOUND}</Typography></Info>
      )}
      {count > scrapedCategory.length && !load && (
        <Info>
          <Typography onClick={request} bold variant="p" color="blue" className={"load-more"}>
            Load More
            Scraped
          </Typography>
        </Info>
      )}
    </div>
  );
};

GlassdoorScrapedCategories.propTypes = {
  openPub: PropTypes.bool,
  caiCategoryID: PropTypes.number,
  artifacts: PropTypes.shape({
    jurisdiction_id: PropTypes.number
  })
};

GlassdoorScrapedCategories.defaultProps = {
  openPub: false,
  caiCategoryID: null,
  artifacts: {}
};


export default GlassdoorScrapedCategories;
