import React from "react";
import _ from 'lodash';
import PropTypes from "prop-types";
import customCrawlSettings from "../../../constants/customCrawlRunNowSettings";
import { AUTOCOMPLETE } from "../../../constants/fieldTypes";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/ReactSelect/Select";

const CrawlRunNowModalBodyArchiveCustom = ({
  parameters,
  setParameter,
  crawlExternalApiId
}) => {
  const crawlSettings = _.get(customCrawlSettings, crawlExternalApiId, []);

  const handleChangesAutocomplete = (name, value) => {
    console.log(value);

    setParameter(name, value);
  };

  const handleChangeTextInput = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setParameter(name, value);
  };

  const buildField = (fieldSettings) => {
    console.log(parameters);
    switch (fieldSettings.type) {
      case AUTOCOMPLETE: {
        return (
          <Select
            label={fieldSettings.name}
            options={fieldSettings.options.map(option => ({ value: option, label: option }))}
            value={
              parameters[fieldSettings.value]
              && parameters[fieldSettings.value].map(item => ({ value: item, label: item }))
            }
            onChange={value => handleChangesAutocomplete(
              fieldSettings.value,
              value.map(v => v.value)
            )}
            isMulti={fieldSettings.multiple}
          />
        );
      }
      // by default we use TextInput
      default: {
        return (
          <TextInput
            label={fieldSettings.name}
            name={fieldSettings.value}
            onChange={handleChangeTextInput}
            value={parameters[fieldSettings.value]}
          />
        );
      }
    }
  };

  return crawlSettings.map(buildField);
};

CrawlRunNowModalBodyArchiveCustom.propTypes = {
  parameters: PropTypes.shape({}),
  setParameter: PropTypes.func,
  crawlExternalApiId: PropTypes.string
};

CrawlRunNowModalBodyArchiveCustom.defaultProps = {
  parameters: {},
  setParameter: () => {},
  crawlExternalApiId: ''
};

export default CrawlRunNowModalBodyArchiveCustom;
