import type { CommandRequestsTableConfig } from './useCommandRequestsTableConfig.types';

import * as uiLib from '@compliance.ai/web-components';
import * as commandRequestsConstants from 'admin/constants/commandRequests';

export const useCommandRequestsTableConfig = (): CommandRequestsTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'ID',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.ID,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.ID,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Created At',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.CREATED_AT,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.CREATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.DateCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Updated At',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPDATED_AT,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.DateCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Command Name',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.NAME,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.NAME,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Type',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.TYPE,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.TYPE,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Status',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Status Details',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS_DETAILS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.STATUS_DETAILS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'User Id',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER_ID,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER_ID,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'User',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.USER,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Upload Link',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPLOAD_LINK,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.UPLOAD_LINK,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Parameters',
      dataKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.PARAMETERS,
      sortKey: commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.PARAMETERS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    }
  ];

  return {
    columns
  };
};
