import type { ConceptsHandlers } from './useConceptsHandlers.types';
import type { ComponentProps } from 'react';
import type { ConceptFromResponse } from 'shared/features/concepts/concepts.types';
import type { MetaConceptFromResponse } from 'shared/features/metaConcepts/metaConcepts.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as conceptsConstants from 'admin/constants/concepts';

export const useConceptsSelector = () => {
  return (
    data: Awaited<ReturnType<ConceptsHandlers['handleConceptsFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => {
        const concept = row as ConceptFromResponse;
        return {
          [conceptsConstants.CONCEPTS_ATTRIBUTES.ID]:
            concept[conceptsConstants.CONCEPTS_ATTRIBUTES.ID],
          [conceptsConstants.CONCEPTS_ATTRIBUTES.NAME]:
            concept[conceptsConstants.CONCEPTS_ATTRIBUTES.NAME],
          [conceptsConstants.CONCEPTS_ATTRIBUTES.NAME]: {
            href: `${window.location.origin}${ROUTES.conceptTool}/${
              concept[conceptsConstants.CONCEPTS_ATTRIBUTES.ID]
            }`,
            tooltip: concept[conceptsConstants.CONCEPTS_ATTRIBUTES.NAME],
            children: concept[conceptsConstants.CONCEPTS_ATTRIBUTES.NAME]
          } as uiLib.LinkCellValue,
          [conceptsConstants.CONCEPTS_ATTRIBUTES.CREATED_AT]:
            concept[conceptsConstants.CONCEPTS_ATTRIBUTES.CREATED_AT],
          [conceptsConstants.CONCEPTS_ATTRIBUTES.UPDATED_AT]:
            concept[conceptsConstants.CONCEPTS_ATTRIBUTES.UPDATED_AT],
          [conceptsConstants.CONCEPTS_ATTRIBUTES.META_CONCEPT]: (
            concept[conceptsConstants.CONCEPTS_ATTRIBUTES.META_CONCEPT] as MetaConceptFromResponse
          )?.name,
          [conceptsConstants.CONCEPTS_ATTRIBUTES.IS_ACTIVE]:
            concept[conceptsConstants.CONCEPTS_ATTRIBUTES.IS_ACTIVE]
        };
      })
    };
  };
};
