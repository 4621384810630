import type { EitlLabelActionsDropdownHandlers } from './useEitlLabelActionsDropdownHandlers.types';
import type { EitlLabelActionsDropdownData } from './useEitlLabelActionsDropdownData.types';
import type { EitlLabelActionsDropdownProps } from '../EitlLabelActionsDropdown.types';

import * as constants from '../EitlLabelActionsDropdown.constants';

export const useEitlLabelActionsDropdownHandlers = ({
  props,
  localActions
}: {
  props: Pick<EitlLabelActionsDropdownProps, 'onRenameModalOpen' | 'onDeleteModalOpen'>;
  localActions: EitlLabelActionsDropdownData['localActions'];
}): EitlLabelActionsDropdownHandlers => {
  const handleEitlLabelActionsDropdownToggle: EitlLabelActionsDropdownHandlers['handleEitlLabelActionsDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleListOptionClick: EitlLabelActionsDropdownHandlers['handleListOptionClick'] = ([
    option
  ]) => {
    switch (option.value) {
      case constants.LABEL_ACTIONS_VALUES.RENAME: {
        props.onRenameModalOpen();
        break;
      }

      case constants.LABEL_ACTIONS_VALUES.DELETE: {
        props.onDeleteModalOpen();
        break;
      }
    }

    localActions.setIsOpen(false);
  };

  return {
    handleEitlLabelActionsDropdownToggle,
    handleListOptionClick
  };
};
