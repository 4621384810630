import { api_checkEmail, api_resetPassword } from "./resetPassword.api";
import { openErrorSnackbar, openSuccessSnackbar } from "../snackbar/snackbar.reducer";

export const SUCCESS_RESET_PASSWORD = 'Check email to get more information';
export const ERROR_RESET_PASSWORD = 'Unable to reset password. Please try again later';

export const resetPasswordAC = email => async (dispatch) => {
  try {
    await api_checkEmail({ params: { email } });
    await api_resetPassword(email);

    dispatch(openSuccessSnackbar(SUCCESS_RESET_PASSWORD));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR_RESET_PASSWORD));

    throw e
  }
};
