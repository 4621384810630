import type { EitlLabelCreateDropdownProps } from '../EitlLabelCreateDropdown.types';
import type { EitlLabelDropdownData } from './useEitlLabelCreateDropdownData.types';
import type { EitlLabelCreateDropdownHandlers } from './useEitlLabelCreateDropdownHandlers.types';

import * as constants from '../EitlLabelCreateDropdown.constants';

export const useEitlLabelCreateDropdownHandlers = ({
  props,
  localActions
}: {
  props: Pick<EitlLabelCreateDropdownProps, 'onAddModalOpen' | 'onImportModalOpen'>;
  localActions: EitlLabelDropdownData['localActions'];
}): EitlLabelCreateDropdownHandlers => {
  const handleEitlLabelCreateDropdownToggle: EitlLabelCreateDropdownHandlers['handleEitlLabelCreateDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleListOptionClick: EitlLabelCreateDropdownHandlers['handleListOptionClick'] = ([
    option
  ]) => {
    switch (option.value) {
      case constants.EITL_LABEL_CREATION_VALUES.ADD: {
        props.onAddModalOpen();
        break;
      }

      case constants.EITL_LABEL_CREATION_VALUES.IMPORT: {
        props.onImportModalOpen();
        break;
      }
    }

    localActions.setIsOpen(false);
  };

  return {
    handleEitlLabelCreateDropdownToggle,
    handleListOptionClick
  };
};
