import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { AgencyCellData } from './useAgencyCellData.types';

import type * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useAgencyCellData = (row: uiLib.Row): AgencyCellData => {
  const scrapedCategoriesValue =
    row.scraped_categories as CaiCategoriesFromResponse['scraped_categories'];
  const agencyValue = scrapedCategoriesValue.map(item => item?.agencies);
  const agenciesText = agencyValue.map(agencies =>
    agencies?.map(agency => agency?.short_name || agency?.name).join(', ')
  );
  const formattedData = useMemo(() => ({ agenciesText }), [agenciesText]);

  return {
    formattedData
  };
};
