import {
  REQUEST_TOPICS,
  RECEIVE_TOPICS,
  REQUEST_ALL_TOPICS,
  RECEIVE_ALL_TOPICS,
  REQUEST_TOPICS_STATS,
  RECEIVE_TOPICS_STATS,
  REQUEST_MODEL_VALIDATION_TABLE,
  RECEIVE_MODEL_VALIDATION_TABLE,
  FOLLOW_TOPICS
} from './topics.actions';

export const getInitialTopicsState = () => ({
  isFetching: false,
  isReady: false,
  realFollowedTopics: [],
  realFollowedTopicsObj: {},
  followed_topics: [],
  followedTopicsObj: {}
});

export const topics = (state = getInitialTopicsState(), action) => {
  switch (action.type) {
    case REQUEST_TOPICS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_TOPICS: {
      const realFollowedTopics = action.topics.filter(topic => topic.following);
      const realFollowedTopicsObj = realFollowedTopics.reduce(
        (obj, topic) => {
          obj[topic.topic_id] = topic;
          return obj;
        },
        {}
      );
      const followed_topics = action.topics;
      const followedTopicsObj = followed_topics.reduce(
        (obj, topic) => {
          obj[topic.topic_id] = topic;
          return obj;
        },
        {}
      );

      return {
        ...state,
        isFetching: false,
        isReady: true,
        followed_topics,
        followedTopicsObj,
        realFollowedTopics,
        realFollowedTopicsObj
      };
    }
    case FOLLOW_TOPICS: {
      const { followedTopicsObj } = state;
      action.topics.forEach(topic => {
        followedTopicsObj[topic.id].following = topic.following;
      });
      const newRealFollowedTopics = Object.values(followedTopicsObj).filter(
        topic => topic.following
      );
      const newRealFollowedTopicsObj = newRealFollowedTopics.reduce(
        (obj, topic) => {
          obj[topic.topic_id] = topic;
          return obj;
        },
        {}
      );

      return {
        ...state,
        realFollowedTopics: newRealFollowedTopics,
        realFollowedTopicsObj: newRealFollowedTopicsObj,
        followed_topics: Object.values(followedTopicsObj),
        followedTopicsObj
      };
    }
    default:
      return state;
  }
};

export const getInitialAllTopicsState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const all_topics = (state = getInitialAllTopicsState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_TOPICS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ALL_TOPICS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.all_topics
      };
    default:
      return state;
  }
};

export const getInitialTopicsStatsState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const topics_stats = (state = getInitialTopicsStatsState(), action) => {
  switch (action.type) {
    case REQUEST_TOPICS_STATS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_TOPICS_STATS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.topics_stats
      };
    default:
      return state;
  }
};

export const getInitialTopicAnnotationModelValidationsState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const topic_annotation_model_validations = (
  state = getInitialTopicAnnotationModelValidationsState(),
  action
) => {
  switch (action.type) {
    case REQUEST_MODEL_VALIDATION_TABLE:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_MODEL_VALIDATION_TABLE:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.topic_annotation_model_validations
      };
    default:
      return state;
  }
};
