import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  createJurisdictions,
  updateJurisdictions
} from "shared/features/jurisdictionsData/jurisdictionsData.actions";
import {
  loadJurisdictionsCategoryOptions,
  loadJurisdictionsCountryOptions,
  loadJurisdictionsOptions,
  getFormatJurisdictionInfo,
  fromJurisdictionToRawInfo
} from "./JurisdictionTool.helper";
import Modal from "../../components/Modal";
import AsyncCreatableSelect from "../../components/ReactSelect/SelectAsyncCreatabe";
import AsyncSelect from "../../components/ReactSelect/SelectAsync";
import Button from "../../components/Button";
import { TABLE_VARIABLES } from "./JurisdictionTool.constants";
import TextInput from "../../components/TextInput";
import Switch from "../../components/Switch";

const JurisdictionToolModal = ({
  show,
  onHide,
  currentJurisdiction
}) => {
  const dispatch = useDispatch();

  const [jurisdiction, setJurisdiction] = useState({});

  useEffect(() => {
    setJurisdiction(getFormatJurisdictionInfo(currentJurisdiction));
  }, [show, currentJurisdiction]);

  const handleChanges = (name, value) => setJurisdiction({ ...jurisdiction, [name]: value });

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const updateJurisdiction = () => {
    const jurisdictionChanges = fromJurisdictionToRawInfo(jurisdiction, currentJurisdiction);
    if (jurisdictionChanges) {
      const jurisdictionId = currentJurisdiction.id;
      if (jurisdictionId) {
        dispatch(updateJurisdictions({ ...jurisdictionChanges, id: jurisdictionId }));
      } else {
        dispatch(createJurisdictions(jurisdictionChanges));
      }
      onHide();
    }
  };

  return (
    <Modal
      className="jurisdiction_edit-modal"
      title={`Jurisdiction`}
      show={show}
      onHide={onHide}
      size={'small'}
    >
      <div className="jurisdiction_edit-modal_body">
        <TextInput
          label="Id"
          name={TABLE_VARIABLES.id}
          value={jurisdiction[TABLE_VARIABLES.id]}
          onChange={handleChangesEvent}
          isDisabled={currentJurisdiction.id}
        />
        <TextInput
          label="Name"
          name={TABLE_VARIABLES.name}
          value={jurisdiction[TABLE_VARIABLES.name]}
          onChange={handleChangesEvent}
        />
        <TextInput
          label="Short Name"
          name={TABLE_VARIABLES.short_name}
          value={jurisdiction[TABLE_VARIABLES.short_name]}
          onChange={handleChangesEvent}
        />
        <AsyncCreatableSelect
          label="Country"
          value={jurisdiction[TABLE_VARIABLES.country]}
          onChange={e => handleChanges(TABLE_VARIABLES.country, e)}
          loadOptions={value => loadJurisdictionsCountryOptions(
            TABLE_VARIABLES.country, value, TABLE_VARIABLES.country
          )}
        />
        <AsyncCreatableSelect
          label="Category"
          value={jurisdiction[TABLE_VARIABLES.category]}
          onChange={e => handleChanges(TABLE_VARIABLES.category, e)}
          loadOptions={value => loadJurisdictionsCategoryOptions(
            TABLE_VARIABLES.category, value, TABLE_VARIABLES.category
          )}
        />
        <AsyncSelect
          label="Parent"
          value={jurisdiction[TABLE_VARIABLES.parent_id]}
          onChange={e => handleChanges(TABLE_VARIABLES.parent_id, e)}
          loadOptions={value => loadJurisdictionsOptions(
            TABLE_VARIABLES.name, value, TABLE_VARIABLES.name
          )}
        />
        <Switch
          label="Active"
          name={TABLE_VARIABLES.active}
          value={jurisdiction[TABLE_VARIABLES.active]}
          onChange={handleChangesEvent}
        />
      </div>
      <div className="jurisdiction_edit-modal_footer">
        <Button onClick={onHide} color="highlighted">Cancel</Button>
        <Button
          onClick={updateJurisdiction}
          color="blue"
        >
          Save changes
        </Button>
      </div>
    </Modal>
  );
};

JurisdictionToolModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  currentJurisdiction: PropTypes.shape({
    id: PropTypes.number
  })
};

JurisdictionToolModal.defaultProps = {
  show: false,
  currentJurisdiction: {}
};

export default JurisdictionToolModal;
