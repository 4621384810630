import {
  popular_agency_short_names
} from 'shared/utils/defaultSources';
import {
  api_fetchAgencies,
  api_fetchAgencySummaries,
  api_followAgencies
} from './agencies.api';
import {getUserAuthenticatedState} from "../auth/auth.selectors";

export const REQUEST_AGENCIES = 'REQUEST_AGENCIES';
export const RECEIVE_AGENCIES = 'RECEIVE_AGENCIES';
export const FOLLOW_AGENCIES = 'FOLLOW_AGENCIES';
export const FOLLOWED_AGENCIES = 'FOLLOWED_AGENCIES';
export const REQUEST_SEARCH_FILTER_AGENCIES = 'REQUEST_SEARCH_FILTER_AGENCIES';
export const RECEIVE_SEARCH_FILTER_AGENCIES = 'RECEIVE_SEARCH_FILTER_AGENCIES';
export const AGENCY_ADMIN_TOOL_CREATED = 'AGENCY_ADMIN_TOOL_CREATED';

function followAgenciesBegin(agencies) {
  return {
    type: FOLLOW_AGENCIES,
    agencies: agencies.agencies
  };
}

function followedAgencies(json) {
  return {
    type: FOLLOWED_AGENCIES
  };
}


function requestAgencies(following) {
  return {
    type: REQUEST_AGENCIES,
    following
  };
}

function receiveAgencies(json, following) {
  return {
    type: RECEIVE_AGENCIES,
    agencies: json.agencies,
    following
  };
}

function requestSearchFilterAgencies() {
  return {
    type: REQUEST_SEARCH_FILTER_AGENCIES
  };
}

function receiveSearchFilterAgencies(json) {
  return {
    type: RECEIVE_SEARCH_FILTER_AGENCIES,
    agencies: json.agencies
  };
}

export function AgencyAdminToolCreated(json) {
  return {
    type: AGENCY_ADMIN_TOOL_CREATED,
    agencies: json ? json.agency : null
  };
}

export function followDefaultAgencies(agencies) {
  agencies.agencies.forEach(agency => {
    if (agency.type !== 'state' && popular_agency_short_names.has(agency.short_name)) {
      agency.following = true;
    }
  });
  return agencies;
}

export function fetchAgencies( //change
  following, search_filter = false, initial_fetch = false, useAPIToken = false) {
  return function dofetch(dispatch, getState) {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated && !search_filter && !initial_fetch) {
      return Promise.resolve();
    }
    if (search_filter) {
      dispatch(requestSearchFilterAgencies(search_filter));
    } else {
      dispatch(requestAgencies(following));
    }

    return api_fetchAgencies(following, search_filter, useAPIToken)
      .then(response => {
        if (search_filter) {
          dispatch(receiveSearchFilterAgencies(response));
        } else {
          if (!isAuthenticated && initial_fetch) {
            response = followDefaultAgencies(response);
          }
          dispatch(receiveAgencies(response, following));
        }
        return response;
      });
  };
}

export function fetchAgencySummaries(params) {
  return function dofetch(dispatch) {
    return api_fetchAgencySummaries(params.agency_id).then(response => response);
  };
}

export function followAgencies(changed_agencies) { //use in dif actions
  return function dopost(dispatch, getState) {
    const isAuthenticated = getUserAuthenticatedState(getState());

    dispatch(followAgenciesBegin(changed_agencies));
    if (!isAuthenticated) {
      return Promise.resolve();
    }

    return api_followAgencies(changed_agencies)
      .then(response => {
        dispatch(followedAgencies(response));
        return response;
      });
  };
}
