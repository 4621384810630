import type { BulkEditModalHandlers } from './useSourcePageHandlers.types';

import { useEffect } from 'react';

export const useSourcePageLifecycle = ({
  onInitialDataFetch
}: {
  onInitialDataFetch: BulkEditModalHandlers['handleLoadNewsSource'];
}) => {
  useEffect(() => {
    onInitialDataFetch();
  }, [onInitialDataFetch]);
};
