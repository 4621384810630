import type { FC } from 'react';
import type { LogsModalProps } from './LogsModal.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './LogsModal.constants';

import { useLogsModalData } from './hooks';

import './_logs-modal.scss';

export const LogsModal: FC<LogsModalProps> = ({ report, onClose }) => {
  const { formattedData } = useLogsModalData({
    report
  });

  return (
    <uiLib.ErrorBoundary>
      <uiLib.Modal
        title="Documents"
        isOpen
        onClose={onClose}
        classNames={{
          container: 'logs-modal__container'
        }}
      >
        <uiLib.Table
          rowKey={constants.TABLE_KEY.ISSUE}
          columns={constants.COLUMNS}
          rows={formattedData.rows}
          localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
          shouldRenderToolbar={false}
          shouldShowFirstPageButton
          shouldShowLastPageButton
          classNames={{
            container: 'logs-modal__table-outer-container',
            tableContainer: 'logs-modal__table-inner-container'
          }}
        />
      </uiLib.Modal>
    </uiLib.ErrorBoundary>
  );
};
