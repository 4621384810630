import type { DocumentDataFromResponse } from 'shared/features/documentData/documentData.types';
import type { ExportHandlers } from './useExportHandlers.types';
import type { ExportData } from './useExportData.types';
import type { ExportProps } from '../Export.types';

import * as uiLib from '@compliance.ai/web-components';

import * as documentDataConstants from 'shared/features/documentData/documentData.constants';

export const useExportHandlers = ({
  props,
  localActions
}: {
  props: Pick<ExportProps, 'selectedDocumentIds' | 'onStatusAction'>;
  localActions: ExportData['localActions'];
}): ExportHandlers => {
  const handleOpenExportModal = () => {
    localActions.setIsExportModalOpen(true);
  };

  const handleCloseExportModal = () => {
    localActions.setIsExportModalOpen(false);
  };

  const handleContentTypeSelect: ExportHandlers['handleContentTypeSelect'] = contentType => {
    localActions.setContentType(contentType);
  };

  const handleExport: ExportHandlers['handleExport'] = (contentType, columns) => {
    props.onStatusAction({
      selectedDocumentIds: props.selectedDocumentIds as DocumentDataFromResponse['id'][],
      variables: [
        {
          action: documentDataConstants.DocumentsBulkUpdateActions.EXPORT,
          downloadFileType: contentType,
          columns
        }
      ]
    });

    uiLib.showNotification({
      type: uiLib.ALERT_TYPES.INFO,
      title: 'Preparing your file',
      message: 'Your file will be ready in command requests.'
    });

    localActions.setIsExportModalOpen(false);
  };

  return {
    handleOpenExportModal,
    handleCloseExportModal,
    handleContentTypeSelect,
    handleExport
  };
};
