import type { GlobalState } from 'shared/reducers';
import type { BulkReprocessModalData } from './useBulkReprocessModalData.types';

import * as hookConstants from './useBulkReprocessModalData.constants';
import * as userSelectors from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBulkReprocessModalData = (): BulkReprocessModalData => {
  const [rules, setRules] = useState<BulkReprocessModalData['localState']['rules']>(
    hookConstants.BULK_REPROCESS_MODAL_INITIAL_RULES
  );

  const localState: BulkReprocessModalData['localState'] = {
    rules
  };

  const localActions: BulkReprocessModalData['localActions'] = useMemo(
    () => ({
      setRules
    }),
    []
  );

  const reduxState: BulkReprocessModalData['reduxState'] = useSelector<
    GlobalState,
    BulkReprocessModalData['reduxState']
  >(state => ({
    currentUserId: userSelectors.getCurrentUserId(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
