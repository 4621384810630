import type { SourcePageStatusProps } from '../SourcePageStatus.types';
import type { SourcePageStatusData } from './useSourcePageStatusData.types';
import type { SourcePageStatusHandlers } from './useSourcePageStatusHandlers.types';

import ROUTES from 'admin/constants/routes';
import * as constants from '../SourcePageStatus.constants';

import { useHistory } from 'admin/utils/hooks';
import { useLocation } from 'react-router-dom';

export const useSourcePageStatusHandlers = ({
  props,
  localActions
}: {
  props: Pick<SourcePageStatusProps, 'onChangeStatus'>;
  localActions: SourcePageStatusData['localActions'];
}): SourcePageStatusHandlers => {
  const history = useHistory();
  const location = useLocation();

  const handleStatusDropdownToggle: SourcePageStatusHandlers['handleStatusDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleListOptionClick: SourcePageStatusHandlers['handleListOptionClick'] = ([option]) => {
    switch (option.value) {
      case constants.NEWS_SOURCES_CREATION_VALUES.ACTIVE: {
        props.onChangeStatus(true);
        break;
      }

      case constants.NEWS_SOURCES_CREATION_VALUES.DEACTIVATED: {
        props.onChangeStatus(false);
        break;
      }
    }

    localActions.setIsOpen(false);
  };

  const handleGoBack: SourcePageStatusHandlers['handleGoBack'] = () => {
    history.push(`${ROUTES.contentManagement}?tab=news_premium_content`, location.state);
  };

  return {
    handleStatusDropdownToggle,
    handleListOptionClick,
    handleGoBack
  };
};
