import { api_fetchSlotInfoForDoc } from './slot.api';

export const REQUEST_SLOT_INFO_FOR_DOC = 'REQUEST_SLOT_INFO_FOR_DOC';
export const RECEIVE_SLOT_INFO_FOR_DOC = 'RECEIVE_SLOT_INFO_FOR_DOC';

function requestSlotInfoForDoc(doc_type, slot, doc_id) {
  return {
    type: REQUEST_SLOT_INFO_FOR_DOC,
    doc_type,
    slot,
    doc_id
  };
}

function receiveSlotInfoForDoc(json) {
  return {
    type: RECEIVE_SLOT_INFO_FOR_DOC,
    slot_tool_doc: json
  };
}

export function fetchSlotInfoForDoc(doc_type, slot, doc_id) {
  return function doFetch(dispatch) {
    dispatch(requestSlotInfoForDoc(doc_type, slot, doc_id));
    return api_fetchSlotInfoForDoc(doc_type, slot, doc_id)
      .then(response => {
        dispatch(receiveSlotInfoForDoc(response));
      });
  };
}
