import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CrawlMonitorToolHeaderSearchModalBtnBlock from './CrawlMonitorToolHeaderSearchModalBtnBlock';
import Select from '../../../components/ReactSelect/Select';
import CustomMenuList from '../../../components/ReactSelect/CustomMenuList';
import {
  TABLE_VARIABLES,
  SELECT_BOOL,
  SELECT_OPTIONS,
  DEV_COMPLEXITY_FILTER_OPTIONS
} from '../CrawlMonitorTool.constants';
import CRAWL_TYPES from '../../../constants/crawlersTypes';
import {
  loadJurisdictionsOptions,
  loadAgencyOptions,
  loadMetaCategoryOptions,
  loadSpiderNameOptions,
  loadCrawlStatusOptions,
  loadCrawlRunStatusOptions
} from '../CrawlMonitorTool.helper';
import AsyncSelect from '../../../components/ReactSelect/SelectAsync';
import CrawlMonitorToolHeaderSearchGapSelector from './CrawlMonitorToolHeaderSearchGapSelector';
import CrawlMonitorToolHeaderSearchGapTime from './CrawlMonitorToolHeaderSearchGapTime';

const CrawlMonitorToolHeaderSearchModal = ({
  clearSearch,
  requestCrawls,
  changeFilter,
  search,
  handleChangesEvent,
  show
}) => {
  return (
    <div
      className={cn('crawlMonitor_header_search-modal', {
        'crawlMonitor_header_search-modal_visible': show
      })}
    >
      <div className="body-search-block">
        <AsyncSelect
          label="Jurisdictions"
          value={search[TABLE_VARIABLES.jurisdiction]}
          onChange={e => changeFilter(TABLE_VARIABLES.jurisdiction, e)}
          loadOptions={value => loadJurisdictionsOptions('short_name', value, 'short_name')}
          isMulti
          isClearable
        />
        <AsyncSelect
          label="Agency"
          value={search[TABLE_VARIABLES.scraped_agencies]}
          onChange={e => changeFilter(TABLE_VARIABLES.scraped_agencies, e)}
          loadOptions={value => loadAgencyOptions('short_name', value, 'short_name')}
          isMulti
          isClearable
        />
        <AsyncSelect
          label="Document Type / Category"
          value={search[TABLE_VARIABLES.scraped_categories]}
          onChange={e => changeFilter(TABLE_VARIABLES.scraped_categories, e)}
          loadOptions={value => loadMetaCategoryOptions('name', value, 'name')}
          isMulti
          isClearable
          defaultOptions
          components={{
            MenuList: props => (
              <CustomMenuList
                props={props}
                onChange={e => changeFilter(TABLE_VARIABLES.scraped_categories, e)}
                values={search[TABLE_VARIABLES.scraped_categories]}
                onChangeCategory={e => changeFilter(TABLE_VARIABLES.meta_category, e)}
                categoryValue={search[TABLE_VARIABLES.meta_category]}
              />
            )
          }}
        />
        <Select
          label="Is Active"
          value={search[TABLE_VARIABLES.is_active]}
          onChange={e => changeFilter(TABLE_VARIABLES.is_active, e)}
          options={SELECT_BOOL}
          isClearable
        />
        <AsyncSelect
          label="State"
          value={search[TABLE_VARIABLES.status]}
          onChange={e => changeFilter(TABLE_VARIABLES.status, e)}
          loadOptions={value => loadCrawlStatusOptions('status', value, 'status')}
          isMulti
          isClearable
          defaultOptions
        />
        <AsyncSelect
          label="InteractionStatus"
          value={search[TABLE_VARIABLES.run_status]}
          onChange={e => changeFilter(TABLE_VARIABLES.run_status, e)}
          loadOptions={value => loadCrawlRunStatusOptions('run_status', value, 'run_status')}
          isMulti
          isClearable
          defaultOptions
        />
        <AsyncSelect
          label="Spider Name"
          value={search[TABLE_VARIABLES.spider_name]}
          onChange={e => changeFilter(TABLE_VARIABLES.spider_name, e)}
          loadOptions={value => loadSpiderNameOptions('spider_name', value, 'spider_name')}
          isMulti
          isClearable
        />
        <Select
          label="Crawler Type"
          value={search[TABLE_VARIABLES.type]}
          onChange={e => changeFilter(TABLE_VARIABLES.type, e)}
          options={Object.values(CRAWL_TYPES)}
          isMulti
          isClearable
        />
        <CrawlMonitorToolHeaderSearchGapTime
          label="First Run Date"
          handleChanges={changeFilter}
          name={TABLE_VARIABLES.first_run}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapTime
          label="Last Run Date"
          handleChanges={changeFilter}
          name={TABLE_VARIABLES.last_run}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapTime
          label="Last Date Docs Were Ingested"
          handleChanges={changeFilter}
          name={TABLE_VARIABLES.last_ingestion_time}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapTime
          label="Last Time Crawl Was Updated"
          handleChanges={changeFilter}
          name={TABLE_VARIABLES.last_updated_time}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Crawl Frequency"
          name={TABLE_VARIABLES.crawler_frequency}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
          options={[{ value: 'hour', label: 'Hours' }]}
          disableSecond
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Total Runs"
          name={TABLE_VARIABLES.crawl_total_runs}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
          options={[{ value: 'total', label: 'Total' }]}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Docs Processes"
          name={TABLE_VARIABLES.docs_processed}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Docs Skipped"
          name={TABLE_VARIABLES.docs_skipped}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Docs Ingested"
          name={TABLE_VARIABLES.docs_ingested}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Docs With Errors"
          name={TABLE_VARIABLES.docs_errors}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Docs Processed Within SLA"
          name={TABLE_VARIABLES.docs_within_sla}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Docs Processed Outside of SLA"
          name={TABLE_VARIABLES.docs_outside_sla}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
        />
        <CrawlMonitorToolHeaderSearchGapSelector
          label="Relative Complexity"
          name={TABLE_VARIABLES.devComplexity}
          handleEventChanges={handleChangesEvent}
          handleChanges={changeFilter}
          search={search}
          options={DEV_COMPLEXITY_FILTER_OPTIONS}
          isTypeClearable={false}
        />
        <Select
          label="Resource Crawl"
          value={search[TABLE_VARIABLES.is_resource]}
          onChange={e => changeFilter(TABLE_VARIABLES.is_resource, e)}
          options={SELECT_OPTIONS}
          isClearable
        />
        <Select
          label="Versioned Crawl"
          value={search[TABLE_VARIABLES.is_versioning]}
          onChange={e => changeFilter(TABLE_VARIABLES.is_versioning, e)}
          options={SELECT_BOOL}
          isClearable
        />
      </div>
      <CrawlMonitorToolHeaderSearchModalBtnBlock
        clearSearch={clearSearch}
        requestCrawls={requestCrawls}
      />
    </div>
  );
};

CrawlMonitorToolHeaderSearchModal.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  requestCrawls: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  search: PropTypes.shape({}),
  show: PropTypes.bool
};

CrawlMonitorToolHeaderSearchModal.defaultProps = {
  search: {},
  show: false
};

export default CrawlMonitorToolHeaderSearchModal;
