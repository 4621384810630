import * as fetch from 'fetch';

export function api_getReportDocuments(reportId, params) {
  return fetch.fetch({
    url: `/reports_documents/${reportId}`,
    dataType: 'Documents',
    method: 'GET',
    baseApi: fetch.BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
