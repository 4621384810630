import type * as eitlLabelsTypes from 'shared/features/eitlLabels/eitlLabels.types';
import type * as documentDataApiTypes from 'shared/features/documentData/documentData.apiV2.types';
import type * as commandsApiTypes from 'shared/features/commands/commands.api.types';
import type * as documentDataConstants from 'shared/features/documentData/documentData.constants';

import type { BulkEditModalRule } from './useBulkEditModalData.types';

import { COMMAND_REQUEST_NAMES } from 'admin/constants/commandRequestTypes';
import * as constants from '../BulkEditModal.constants';

export const convertModalRulesToApiActions = ({
  rules
}: {
  rules: BulkEditModalRule[];
}): documentDataApiTypes.DocumentsBulkUpdatePayloadAction[] => {
  return rules.flatMap(rule => {
    if (
      !rule.action ||
      (rule.attribute === constants.BULK_EDIT_ATTRIBUTES.EITL_LABEL &&
        !Array.isArray(rule.value)) ||
      (rule.attribute === constants.BULK_EDIT_ATTRIBUTES.SUMMARY && !rule.value)
    ) {
      return [];
    }

    if (
      rule.action === constants.BULK_EDIT_ACTIONS.EDIT &&
      rule.attribute === constants.BULK_EDIT_ATTRIBUTES.SUMMARY &&
      typeof rule.value === 'string'
    ) {
      return [
        {
          action: rule.action.toLowerCase() as documentDataConstants.DocumentsBulkUpdateActions,
          summaryText: rule.value
        } as documentDataApiTypes.DocumentsBulkUpdatePayloadAction
      ];
    }

    if (rule.attribute === constants.BULK_EDIT_ATTRIBUTES.EITL_LABEL && Array.isArray(rule.value)) {
      const eitlLabelOptions = rule.value as eitlLabelsTypes.EitlLabelOption[];

      return [
        {
          action: rule.action.toLowerCase() as documentDataConstants.DocumentsBulkUpdateActions,
          labelIds: eitlLabelOptions.map(eitlLabelOption => eitlLabelOption.value)
        } as documentDataApiTypes.DocumentsBulkUpdatePayloadAction
      ];
    }

    return [];
  });
};

export const findBulkUpdateCommandId = ({
  commands
}: {
  commands?: commandsApiTypes.CommandRequestFromResponse[];
}) => {
  return commands?.find(command => command.name === COMMAND_REQUEST_NAMES.BULK_DOCUMENT_UPDATE)?.id;
};
