import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from "prop-types";


const MIconButton = ({ children, classes, onClick, size, ...props }) => {
  return (
    <IconButton onClick={onClick} className={classes} size={size || 'small'} {...props}>
      {children}
    </IconButton>
  );
};

MIconButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string
};

MIconButton.defaultProps = {
  children: null,
  classes: '',
  size: ''
};

export default MIconButton;
