export const STATUS_CODES = {
  OK: 200,
  INTERNAL_SERVER_ERROR: 500
};

export const REDUCER_NAME = 'auth';

export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred. Please try again later.';

export const QUERY_PARAMS = {
  EMAIL: 'email',
  ORG_INVITATION_TOKEN: 'org_invitation_token',
  VERIFICATION_CODE: 'verification_code'
};

export const EXPECTED_ERROR_MESSAGES = {
  ATTEMPTS_EXCEEDED: 'Attempt limit exceeded, please try after some time.',
  VALIDATION_ERROR: 'validation error',
  PASSWORD_POLICY: 'Password did not conform with policy',
  INVALID_VERIFICATION_CODE: 'Invalid code provided, please request a code again.',
  INVALID_ORG_INVITATION_TOKEN: 'Invalid org_invitation_token',
  USER_DOES_NOT_EXIST: 'User does not exist.',
  CURRENT_USER_IS_NOT_DETECTED: 'Current user is not detected',
  INCORRECT_USERNAME_OR_PASSWORD: 'Incorrect username or password.'
};
