import type { CSSProperties } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

export const TABLE_LOCAL_STORAGE_KEY = 'DRY_RUN_TABLE_COLUMNS_SETTINGS_KEY';

export enum DryRunReportStatus {
  Success = 'success',
  Error = 'error',
  Skipped = 'skipped',
  Ingested = 'ingested',
  Updated = 'updated',
  Info = 'info',
  Debug = 'debug'
}

export const DRY_RUN_STATUS_TITLES: Record<DryRunReportStatus, string> = {
  [DryRunReportStatus.Debug]: 'Debug',
  [DryRunReportStatus.Error]: 'Failed',
  [DryRunReportStatus.Info]: 'Info',
  [DryRunReportStatus.Ingested]: 'Ingested',
  [DryRunReportStatus.Skipped]: 'Skipped',
  [DryRunReportStatus.Success]: 'Success',
  [DryRunReportStatus.Updated]: 'Updated'
};

export enum TABLE_KEYS {
  INDEX = 'index',
  TITLE = 'title',
  STATUS = 'status',
  REASON = 'reason',
  PUBLICATION_DATE = 'publication_date',
  PARENT_ID = 'parent_id',
  OFFICIAL_ID = 'official_id',
  CATEGORY = 'category',
  PDF_URL = 'pdf_url',
  WEB_URL = 'web_url',
  DOCKET_ID = 'unified_docket_id',
  EFFECTIVE_DATE = 'effective_date',
  SUMMARY_TEXT = 'summary_text',
  RELATION = 'relation'
}

export const COLUMNS_WIDTHS: Record<TABLE_KEYS, CSSProperties['width']> = {
  [TABLE_KEYS.INDEX]: '3rem',
  [TABLE_KEYS.TITLE]: '25rem',
  [TABLE_KEYS.STATUS]: '10rem',
  [TABLE_KEYS.REASON]: '25rem',
  [TABLE_KEYS.PUBLICATION_DATE]: '10rem',
  [TABLE_KEYS.PARENT_ID]: '10rem',
  [TABLE_KEYS.OFFICIAL_ID]: '15rem',
  [TABLE_KEYS.CATEGORY]: '10rem',
  [TABLE_KEYS.PDF_URL]: '15rem',
  [TABLE_KEYS.WEB_URL]: '15rem',
  [TABLE_KEYS.DOCKET_ID]: '10rem',
  [TABLE_KEYS.EFFECTIVE_DATE]: '10rem',
  [TABLE_KEYS.SUMMARY_TEXT]: '50rem',
  [TABLE_KEYS.RELATION]: '10rem'
};

export const COLUMNS: uiLib.Column[] = [
  {
    title: 'No.',
    dataKey: TABLE_KEYS.INDEX,
    width: COLUMNS_WIDTHS[TABLE_KEYS.INDEX],
    isDynamic: false,
    Component: uiLib.TextCell
  },
  {
    title: 'Title',
    dataKey: TABLE_KEYS.TITLE,
    width: COLUMNS_WIDTHS[TABLE_KEYS.TITLE],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell
  },
  {
    title: 'Status',
    dataKey: TABLE_KEYS.STATUS,
    width: COLUMNS_WIDTHS[TABLE_KEYS.STATUS],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: elements.StatusCell
  },
  {
    title: 'Reason',
    dataKey: TABLE_KEYS.REASON,
    width: COLUMNS_WIDTHS[TABLE_KEYS.REASON],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.LinkCell
  },
  {
    title: 'Pub Date',
    dataKey: TABLE_KEYS.PUBLICATION_DATE,
    width: COLUMNS_WIDTHS[TABLE_KEYS.PUBLICATION_DATE],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.DateCell
  },
  {
    title: 'Parent Id',
    dataKey: TABLE_KEYS.PARENT_ID,
    width: COLUMNS_WIDTHS[TABLE_KEYS.PARENT_ID],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.LinkCell
  },
  {
    title: 'Official Id',
    dataKey: TABLE_KEYS.OFFICIAL_ID,
    width: COLUMNS_WIDTHS[TABLE_KEYS.OFFICIAL_ID],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell
  },
  {
    title: 'Category',
    dataKey: TABLE_KEYS.CATEGORY,
    width: COLUMNS_WIDTHS[TABLE_KEYS.CATEGORY],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell
  },
  {
    title: 'PDF URL',
    dataKey: TABLE_KEYS.PDF_URL,
    width: COLUMNS_WIDTHS[TABLE_KEYS.PDF_URL],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.LinkCell
  },
  {
    title: 'Web URL',
    dataKey: TABLE_KEYS.WEB_URL,
    width: COLUMNS_WIDTHS[TABLE_KEYS.WEB_URL],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.LinkCell
  },
  {
    title: 'Docket Id',
    dataKey: TABLE_KEYS.DOCKET_ID,
    width: COLUMNS_WIDTHS[TABLE_KEYS.DOCKET_ID],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell
  },
  {
    title: 'Effective Date',
    dataKey: TABLE_KEYS.EFFECTIVE_DATE,
    width: COLUMNS_WIDTHS[TABLE_KEYS.EFFECTIVE_DATE],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.DateCell
  },
  {
    title: 'Summary Text',
    dataKey: TABLE_KEYS.SUMMARY_TEXT,
    width: COLUMNS_WIDTHS[TABLE_KEYS.SUMMARY_TEXT],
    isDynamic: true,
    isInitiallyVisible: true,
    Component: uiLib.TextCell
  },
  {
    title: 'Relation',
    dataKey: TABLE_KEYS.RELATION,
    width: COLUMNS_WIDTHS[TABLE_KEYS.RELATION],
    isDynamic: true,
    isInitiallyVisible: false,
    Component: elements.RelationsCell
  }
];
