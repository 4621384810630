import type { CategorySplitModalData } from './useCategorySplitModalData.types';

import { useMemo, useState } from 'react';

export const useCategorySplitModalData = (): CategorySplitModalData => {
  const [docMetaCategory, setDocMetaCategory] =
    useState<CategorySplitModalData['localState']['docMetaCategory']>(null);
  const [docCaiCategory, setDocCaiCategory] =
    useState<CategorySplitModalData['localState']['docCaiCategory']>(null);
  const [selectedCategories, setSelectedCategories] = useState<
    CategorySplitModalData['localState']['selectedCategories']
  >([]);
  const [categoryToSplit, setCategoryToSplit] =
    useState<CategorySplitModalData['localState']['categoryToSplit']>(null);

  const localState: CategorySplitModalData['localState'] = {
    docMetaCategory,
    docCaiCategory,
    selectedCategories,
    categoryToSplit
  };

  const localActions: CategorySplitModalData['localActions'] = useMemo(
    () => ({
      setDocMetaCategory,
      setDocCaiCategory,
      setSelectedCategories,
      setCategoryToSplit
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
