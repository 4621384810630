import * as errorUtils from 'shared/utils/errors';

export const safelyResetLocalStorageData = (key, data) => {
  try {
    localStorage.removeItem(key);
    localStorage.setItem(key, data);
  } catch (e) {
    errorUtils.logError(e);
  }
};

export const safelySetLocalStorageData = (key, data) => {
  try {
    localStorage.setItem(key, data);
  } catch (Exception) {
    safelyResetLocalStorageData(key, data);
  }
};
