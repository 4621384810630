import { useHistory } from 'admin/utils/hooks';
import { safe_analytics } from 'shared/utils/analytics';
import { ERROR_ALERT_MESSAGE_TYPES } from "app/common";
import { EXPECTED_ERROR_MESSAGES } from "shared/features/auth/auth.constants";

export const useLoginHandlers = ({
  localState,
  localActions,
  reduxActions,
  alertActions,
  verificationCodeEmailData
}) => {
  const history = useHistory();

  const handleSubmitClick = async () => {
    try {
      safe_analytics('default', 'Login', 'Button click', 'Login');

      await reduxActions.authenticateUser({
        email: localState.email,
        password: localState.password
      });

      history.push('/');
    } catch (e) {
      if (e.message === EXPECTED_ERROR_MESSAGES.INCORRECT_USERNAME_OR_PASSWORD) {
        alertActions.setErrorMessageByType(
          ERROR_ALERT_MESSAGE_TYPES.INCORRECT_USERNAME_OR_PASSWORD
        );
      } else {
        alertActions.setErrorMessageByType(ERROR_ALERT_MESSAGE_TYPES.UNKNOWN_ERROR);
      }
    }
  };

  const handleForgetPasswordModalOpen = () => {
    localActions.setPasswordResetOpenState(true);
  };

  const handleForgetPasswordModalClose = () => {
    localActions.setPasswordResetOpenState(false);
  };

  const handleMessagePasswordConfirmed = () => {
    alertActions.setSuccessMessage('Password has been successfully changed.');

    /**
     *
     * Reset query params after successful reset of password
     * This will ensure that when user clicks on the Forget your password? link
     * He will see the default flow without preselected email and code
     *
     */
    if (verificationCodeEmailData.shouldUseEmailPasswordResetFlow) {
      history.replace(history.location.pathname);
    }
  };

  const handleInvalidCodeError = () => {
    /**
     *
     * Reset query params after error.
     * This will ensure that when user clicks on the Forget your password? link
     * He will see the default flow without preselected email and code
     *
     */
    history.replace(history.location.pathname);
  };

  return {
    handleSubmitClick,
    handleForgetPasswordModalOpen,
    handleForgetPasswordModalClose,
    handleMessagePasswordConfirmed,
    handleInvalidCodeError
  };
};
