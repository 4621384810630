import type { FC } from 'react';
import type { CategorySplitModalProps } from './CategorySplitModal.types';

import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';
import * as constants from './CategorySplitModal.constants';
import * as errorUtils from 'shared/utils/errors';

import {
  useCategorySplitModalHandlers,
  useCategorySplitModalData,
  useCategorySplitModalFormattedData,
  useCategorySplitModalMutations,
  useCategorySplitModalSelector
} from './hooks';

import './_cai-category-split-modal.scss';

export const CategorySplitModal: FC<CategorySplitModalProps> = ({
  categoryId,
  onConfirm,
  onClose
}) => {
  const { localState, localActions } = useCategorySplitModalData();

  const mutations = useCategorySplitModalMutations({
    localState,
    localActions
  });

  const handlers = useCategorySplitModalHandlers({
    props: { categoryId, onConfirm },
    mutations,
    localState,
    localActions
  });

  const tableDataSelector = useCategorySplitModalSelector();

  const formattedData = useCategorySplitModalFormattedData({
    localState,
    mutations
  });

  return (
    <uiLib.Modal
      isOpen
      title="Split Cai Category"
      withFooter
      className="cai-category-split-modal"
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Split"
      onSubmit={handlers.handleSplitCaiCategory}
      onClose={onClose}
    >
      <div>
        {formattedData.isLoading && <uiLib.Loader withoutBackground={false} />}
        <uiLib.Typography className="cai-category-split-modal__section-title">
          Step 1. Select the Scraped Categories you want to split into a new CAI Category.
        </uiLib.Typography>
        <uiLib.Table
          rowKey={categoryConstants.CATEGORY_ATTRIBUTES.ID}
          initialRowsPerPage={constants.ROWS_PER_PAGE[0]}
          localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
          columns={constants.COLUMNS}
          onDataFetch={handlers.handleScrapedCategoriesFetch}
          areRowsSelectable
          onSelectedRowKeysChange={handlers.handleSetSelectedCategory}
          selectedRowsCountPlacement={uiLib.SELECTED_ROWS_COUNT_PLACEMENT.TOOLBAR}
          queryOptions={{
            select: tableDataSelector,
            onError: errorUtils.logReactQueryError
          }}
        />
        <uiLib.Typography className="cai-category-split-modal__section-title">
          Step 2. Select the CAI Category where you want to move your selected Scraped Categories.
        </uiLib.Typography>
        <div className="cai-category-split-modal__new-category">
          <uiLib.LabeledField label="New CAI Category">
            <uiLib.Select
              type={uiLib.SELECT_TYPES.DEFAULT}
              isSearchable
              isAsync
              isCreatable
              loadOptions={handlers.handleCaiCategoryFetch}
              value={localState.docCaiCategory}
              placeholder="# cai category"
              onChange={handlers.handleChangeCaiCategory}
              onCreateOption={handlers.handleAddNewCaiCategory}
            />
          </uiLib.LabeledField>
          <uiLib.LabeledField label="Meta Category">
            <uiLib.Select
              type={uiLib.SELECT_TYPES.DEFAULT}
              isSearchable
              isAsync
              loadOptions={handlers.handleMetaCategoryFetch}
              value={localState.docMetaCategory}
              placeholder="# meta category"
              isDisabled={formattedData.isMetaCategoryDisabled}
              onChange={handlers.handleMetaCategorySelect}
            />
          </uiLib.LabeledField>
        </div>
      </div>
    </uiLib.Modal>
  );
};
