import React from 'react';
import PropTypes from 'prop-types';
import loader from './CircularLoader.svg';

const Loader = ({
  classNameLoader
}) => {
  return <img src={loader} alt="loader" className={classNameLoader} />;
};

Loader.propTypes = {
  classNameLoader: PropTypes.string.isRequired
};

export default Loader;
