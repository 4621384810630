import type { FiltersData } from './useFiltersData.types';

import * as helpers from './useFiltersData.helpers';

import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useFiltersData = (): FiltersData => {
  const location = useLocation();

  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const [filters, setFilters] = useState(
    (location.state ?? {}) as FiltersData['localState']['filters']
  );

  const localState: FiltersData['localState'] = {
    areFiltersOpen,
    filters
  };

  const localActions: FiltersData['localActions'] = useMemo(
    () => ({
      setFilters,
      setAreFiltersOpen
    }),
    []
  );

  const formattedData: FiltersData['formattedData'] = useMemo(
    () => ({
      filtersButtonText: helpers.getFiltersButtonText(filters)
    }),
    [filters]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
