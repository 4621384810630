import type { StatusCellData } from './useStatusCellData.types';
import type { StatusCellProps } from '../StatusCell.types';

import { useMemo } from 'react';
import { getStatus } from './useStatusCellData.helpers';

export const useStatusCellData = ({
  cellValue
}: Pick<StatusCellProps, 'cellValue'>): StatusCellData => {
  const { reportStatus, typographyStatus } = useMemo(() => getStatus(cellValue), [cellValue]);

  return {
    formattedData: {
      reportStatus,
      typographyStatus
    }
  };
};
