/* eslint-disable global-require */
import {store} from 'store';
import * as selectors from "../features/auth/auth.selectors";

export default {
  getToken: () => {
    const state = store.getState();
    return selectors.getJWTToken(state);
  },

  loggedIn: () => {
    const state = store.getState();
    return Boolean(selectors.getUserAuthenticatedState(state));
  }
};
