import {
  RECEIVE_CONCEPTS,
  CLEAR_CONCEPTS
} from './jurisdictionAnalyzerConcept.actions';

export const getInitialConceptState = () => null;

export const jurisdictionAnalyzerConcepts = (state = getInitialConceptState(), action) => {
  switch (action.type) {
    case RECEIVE_CONCEPTS: {
      return action.concepts;
    }
    case CLEAR_CONCEPTS: {
      return null;
    }
    default:
      return state;
  }
};
