import { fetch, BASE_API_OPTIONS, AUTHENTICATION_OPTIONS_KEYS } from "fetch";

export function api_fetchAllSpiderNames() {
  return fetch({
    url: `/spider_names`,
    method: 'GET',
    dataType: 'SpiderNames'
  });
}

export function api_fetchSpiderRunReport(spiderName) {
  return fetch({
    url: `/spider_run_report/${spiderName}`,
    method: 'GET',
    dataType: 'SpiderReport',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_fetchStatistics(params) {
  return fetch({
    url: `/rules_created_by_time`,
    method: 'GET',
    params,
    dataType: 'Statistics',
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY
  });
}

export function api_fetchGoogleAnalyticsReports(params) {
  return fetch({
    url: `/google_analytics_top_documents`,
    method: 'GET',
    params,
    dataType: 'GoogleAnalytics',
    baseApi: BASE_API_OPTIONS.BASE_API_GA
  });
}

export function api_fetchMarketingCampaigns() {
  return fetch({
    url: `/marketing_campaigns`,
    method: 'GET',
    dataType: 'MarketingCampaign'
  });
}

export function api_createMarketingCampaign(params) {
  return fetch({
    url: `/marketing_campaigns`,
    method: 'POST',
    params,
    dataType: 'MarketingCampaign'
  });
}

export function api_updateMarketingCampaign(id, params) {
  return fetch({
    url: `/marketing_campaigns/${id}`,
    method: 'POST',
    params,
    dataType: 'MarketingCampaign'
  });
}

export function api_fetchMarketingCampaign(id) {
  return fetch({
    url: `/marketing_campaigns/${id}`,
    method: 'GET',
    dataType: 'MarketingCampaign'
  });
}
