import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SpecElementsWrapper from "./SpecElementsWrapper";
import {
  CAT_CONV_APPROACH_TYPES,
  ERRORS,
  INFO_HELP,
  NAMES_CAT_CONV
} from "../CrawlSpecCreate.constants";
import Select from "../../../components/ReactSelect/Select";
import TextInput from "../../../components/TextInput";
import ExpandableMapList from "../HelpComponents/ExpandableMapList";

const CategoryConversion = ({
  disabled,
  show,
  element,
  changeCategoryConversionField,
  errorFields
}) => {
  const approachCatConvOptions = useMemo(
    () => Object.values(CAT_CONV_APPROACH_TYPES).map(
      item => ({ value: item, label: item })
    ),
    []
  );

  const changeCatConv = (name, value) => {
    changeCategoryConversionField(name, value);
  };

  const handleChangesEvent = (e) => {
    const { name, value } = e.target;
    changeCatConv(name, value);
  };

  const createNewConversionMapElement = () => ({
    value: '',
    label: ''
  });

  return (
    <SpecElementsWrapper
      show={show}
      label="Category Conversion"
      tooltip={INFO_HELP.CATEGORY_CONVERSION}
    >
      <Select
        label="Type"
        value={element[NAMES_CAT_CONV.APPROACH]}
        onChange={e => changeCatConv(NAMES_CAT_CONV.APPROACH, e)}
        options={approachCatConvOptions}
        isDisabled={disabled}
        isError={errorFields.includes(NAMES_CAT_CONV.APPROACH)}
        errorText={ERRORS.categoryConversionApproach}
      />
      <TextInput
        label="Default Category"
        name={NAMES_CAT_CONV.DEFAULT}
        value={element[NAMES_CAT_CONV.DEFAULT]}
        isDisabled={disabled}
        onChange={handleChangesEvent}
        isError={errorFields.includes(NAMES_CAT_CONV.DEFAULT)}
        errorText={ERRORS.categoryConversionDefault}
      />
      <ExpandableMapList
        label="Conversion Map"
        name={NAMES_CAT_CONV.MAP}
        value={element[NAMES_CAT_CONV.MAP]}
        onChange={changeCatConv}
        createNewItemTemplate={createNewConversionMapElement}
        isError={errorFields.includes(NAMES_CAT_CONV.MAP)}
      />
    </SpecElementsWrapper>
  );
};

CategoryConversion.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  element: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.oneOf([null])
  ]),
  changeCategoryConversionField: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

CategoryConversion.defaultProps = {
  disabled: false,
  show: false,
  element: {},
  errorFields: []
};

export default CategoryConversion;
