import type { MetaCategoryFromResponse } from 'shared/features/categories/metaCategories/metaCategories.api.types';
import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { CategorySplitModalHandlers } from './useCategorySplitModalHandlers.types';
import type { CategorySplitModalData } from './useCategorySplitModalData.types';
import type { CategorySplitModalProps } from '../CategorySplitModal.types';
import type { useCategorySplitModalMutations } from './useCategorySplitModalMutations';

import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';
import * as caiCategoryDataApi from 'shared/features/categories/caiCategories/caiCategories.apiV2';
import * as metaCategoriesApi from 'shared/features/categories/metaCategories/metaCategories.api';
import * as constants from '../CategorySplitModal.constants';

export const useCategorySplitModalHandlers = ({
  props,
  mutations,
  localState,
  localActions
}: {
  props: Pick<CategorySplitModalProps, 'categoryId' | 'onConfirm'>;
  mutations: ReturnType<typeof useCategorySplitModalMutations>;
  localState: CategorySplitModalData['localState'];
  localActions: CategorySplitModalData['localActions'];
}): CategorySplitModalHandlers => {
  const handleScrapedCategoriesFetch: CategorySplitModalHandlers['handleScrapedCategoriesFetch'] =
    async () => {
      try {
        const categoriesResponse = await caiCategoryDataApi.fetchCaiCategoriesData({
          id: props.categoryId
        });

        localActions.setCategoryToSplit(categoriesResponse.items[0]);
        const scrapedCategories = categoriesResponse.items[0]?.scraped_categories;

        return {
          results: scrapedCategories,
          count: scrapedCategories?.length
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    };

  const handleMetaCategoryFetch: CategorySplitModalHandlers['handleMetaCategoryFetch'] = async (
    inputString: string
  ) => {
    try {
      const response = await metaCategoriesApi.api_getMetaCategories({ name: inputString });

      return response.items.map((metaCategory: MetaCategoryFromResponse) => ({
        label: metaCategory.name,
        value: metaCategory.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleCaiCategoryFetch: CategorySplitModalHandlers['handleCaiCategoryFetch'] = async (
    inputString: string
  ) => {
    try {
      const response = await caiCategoryDataApi.fetchCaiCategoriesData({
        name: inputString,
        offset: constants.CAI_CATEGORY_DROPDOWN_OFFSET,
        limit: constants.CAI_CATEGORY_DROPDOWN_LIMIT
      });

      return response.items.map((caiCategory: CaiCategoriesFromResponse) => ({
        ...caiCategory,
        label: `${caiCategory.name} (${caiCategory.id})`,
        value: caiCategory.id
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleChangeCaiCategory: CategorySplitModalHandlers['handleChangeCaiCategory'] =
    caiCategory => {
      const category = caiCategory as uiLib.SelectOption & CaiCategoriesFromResponse;
      localActions.setDocCaiCategory(category);
      localActions.setDocMetaCategory({
        label: category?.meta_category?.name,
        value: category?.meta_category?.id
      });
    };

  const handleAddNewCaiCategory: CategorySplitModalHandlers['handleAddNewCaiCategory'] =
    caiCategory => {
      localActions.setDocCaiCategory({
        label: caiCategory,
        value: 'new',
        type: constants.CREATED_TYPE
      });
      localActions.setDocMetaCategory(null);
    };

  const handleSetSelectedCategory: CategorySplitModalHandlers['handleSetSelectedCategory'] =
    rowKeys => {
      localActions.setSelectedCategories(rowKeys.map(Number));
    };

  const handleSplitCaiCategory: CategorySplitModalHandlers['handleSplitCaiCategory'] = () => {
    mutations.splitCaiCategory.mutate();
    props.onConfirm();
  };

  const handleMetaCategorySelect: CategorySplitModalHandlers['handleMetaCategorySelect'] =
    value => {
      localActions.setDocMetaCategory(value as uiLib.SelectOption);
    };

  return {
    handleScrapedCategoriesFetch,
    handleMetaCategoryFetch,
    handleCaiCategoryFetch,
    handleSetSelectedCategory,
    handleChangeCaiCategory,
    handleSplitCaiCategory,
    handleMetaCategorySelect,
    handleAddNewCaiCategory
  };
};
