import { postExportCrawlConstructionInfo as reduxPostExportCrawlConstructionInfo } from '../crawlerActivity.actions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useCrawlerActivityReduxActions = () => {
  const dispatch = useDispatch();

  const postExportCrawlConstructionInfo = useCallback(
    (...params) => {
      return dispatch(reduxPostExportCrawlConstructionInfo(...params));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      postExportCrawlConstructionInfo
    }),
    [postExportCrawlConstructionInfo]
  );
};
