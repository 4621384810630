import _ from 'lodash';
import { api_getDocumentLanguages } from 'shared/features/documentRelations/documentRelations.api';


// ----- Load Options -----

const formatSelectOptions = (array, valueName, labelName) => array.map(item => ({
  value: _.get(item, valueName, item),
  label: _.get(item, labelName, item)
}));


export const loadLanguages = (
  name, searchValue, responseName
) => api_getDocumentLanguages(
  { [name]: searchValue }
)
  .then(lang => lang.items)
  .then(lang => formatSelectOptions(lang, 'name', responseName));


export function formDocumentRelationParameters(parameters, current_user) {
  return {
    user_id: `${_.get(current_user, 'first_name')} ${_.get(current_user, 'last_name')}`,
    ...parameters
  };
}

export {
  formatSelectOptions
};
