import React from 'react';
import PropTypes from "prop-types";
import './Accordion.scss';
import cn from 'classnames';

const Accordion = ({
  open,
  leftInfo,
  rightInfo,
  onClick,
  children,
  id,
  headerClass
}) => (
  <div className="accordion">
    <div
      className={cn("accordion_button", headerClass)}
      onClick={() => onClick(id, open)}
    >
      <span className={cn("accordion_button_block_symbol", {
        accordion_button_block_symbol_open: open
      })}
      />
      <div>
        <div className="accordion_button_block">
          {leftInfo}
        </div>
        <div className="accordion_button_block">
          {rightInfo}
        </div>
      </div>
    </div>
    <div className={cn("accordion_panel", {
      accordion_panel_inactive: !open
    })}
    >
      {children}
    </div>
  </div>
);

export default Accordion;

Accordion.propTypes = {
  open: PropTypes.bool,
  headerClass: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftInfo: PropTypes.node,
  rightInfo: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired
};

Accordion.defaultProps = {
  open: false,
  headerClass: '',
  id: '',
  leftInfo: null,
  rightInfo: null,
  children: null
};
