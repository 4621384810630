import { naturalSortWithRomanNumerals } from 'app/utils/sort/sort';
import {
  RESET_NAV,
  SET_INITIAL_PATH,
  SET_PATH,
  SET_LAST_REQUESTED_ID,
  REQUEST_RESOURCES_DOCS,
  RECEIVE_RESOURCES_DOCS,
  REQUEST_HEAD_DOCS,
  RECEIVE_HEAD_DOCS
} from './resources.actions';

export const getInitialResourcesNavState = () => ({
  path: [],
  docs: {},
  requestedDocs: {},
  lastRequestedId: null
});

export const resourcesNav = (state = getInitialResourcesNavState(), action) => {
  switch (action.type) {
    case RESET_NAV: {
      return getInitialResourcesNavState();
    }
    case SET_INITIAL_PATH: {
      return {
        ...state,
        path: [{ selectedId: null, docIds: null }]
      };
    }
    case SET_LAST_REQUESTED_ID: {
      return {
        ...state,
        lastRequestedId: action.id
      };
    }
    case SET_PATH: {
      const { columnIndex, selectedId, newColumn } = action;
      const newPath = state.path.slice(0, columnIndex + 1);
      newPath[columnIndex].selectedId = selectedId;
      if (newColumn) {
        newPath.push({ selectedId: null, docIds: null });
      }

      return {
        ...state,
        path: newPath
      };
    }
    case REQUEST_RESOURCES_DOCS: {
      return {
        ...state,
        lastRequestedId: action.id
      };
    }
    case RECEIVE_RESOURCES_DOCS: {
      const { payload: { document }, id } = action;
      const { children } = document;
      const { path, requestedDocs, lastRequestedId } = state;

      const { ids: childrenIds, docs: newDocs } = children
        .sort(naturalSortWithRomanNumerals)
        .reduce(
          ({ ids, docs }, child) => {
            if (document.mainstream_news && document.mainstream_news.news_source) {
              child.mainstream_news = { news_source: document.mainstream_news.news_source };
            }
            ids.push(child.id);
            docs[child.id] = child;
            return { ids, docs };
          },
          { ids: [], docs: {} }
        );

      const newRequestedDocs = { ...requestedDocs };
      newRequestedDocs[id] = document;

      const newState = {
        ...state,
        docs: { ...state.docs, ...newDocs },
        requestedDocs: newRequestedDocs
      };

      if (id === lastRequestedId) {
        const newPath = [...path];
        newPath[newPath.length-1].docIds = childrenIds;
        newState.path = newPath;
      }

      return newState;
    }
    default: {
      return state;
    }
  }
};

export const getInitialResourcesHeadDocsState = () => ({ headDocs: null });

export const resourcesHeadDocs = (state = getInitialResourcesHeadDocsState(), action) => {
  switch (action.type) {
    case REQUEST_HEAD_DOCS: {
      return {
        ...state,
        headDocs: null
      };
    }
    case RECEIVE_HEAD_DOCS: {
      return {
        ...state,
        headDocs: action.payload.docs.reduce((docs, doc) => {
          docs[doc.id] = doc;
          return docs;
        }, {})
      };
    }
    default: {
      return state;
    }
  }
};
