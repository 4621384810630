import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_fetchSentenceModel(params = {}) {
  return fetch({
    url: '/sentence_model',
    method: 'GET',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}
