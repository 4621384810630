import type { CategoryMergeModalData } from './useCategoryMergeModalData.types';
import type * as caiCateogryApiTypes from 'shared/features/categories/caiCategories/caiCategories.apiV2.types';

import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as helpers from './useCategoryMergeModalMutations.helpers';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../CategoryMergeModal.constants';

import { useMutation } from '@tanstack/react-query';
import { useCaiCategoriesApi } from 'shared/features/categories/caiCategories/hooks';
import { useScrapedCategoriesApi } from 'shared/features/categories/scrapedCategories/hooks';

export const useCategoryMergeModalMutations = ({
  localState,
  localActions
}: {
  localState: CategoryMergeModalData['localState'];
  localActions: CategoryMergeModalData['localActions'];
}) => {
  const caiCategoriesApi = useCaiCategoriesApi();
  const scrapedCategoriesApi = useScrapedCategoriesApi();

  const mergeCaiCategory = useMutation<
    Awaited<caiCateogryApiTypes.NormalizedCaiCategory>,
    Error,
    void
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.CAI_CATEGORIES],
    onError: error => {
      errorUtils.logReactQueryError(error);
      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.ERROR,
        message: error.message
      });
    },
    mutationFn: () =>
      caiCategoriesApi.mergeCaiCategory({
        new_cai_category: localState.docCaiCategory as uiLib.SelectOption,
        cai_categories_to_merge: localState.categoriesToMerge.map(category => category.id),
        new_meta_category: localState.docMetaCategory?.value,
        scraped_categories_to_merge: localState.selectedCategories
      }),
    onSuccess: normalizedCaiCategory => {
      localActions.setDocCaiCategory({
        ...normalizedCaiCategory,
        label: normalizedCaiCategory.name,
        value: normalizedCaiCategory.id,
        type: constants.CREATED_TYPE
      });
      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        message: 'Cai Category successfully added'
      });
    }
  });

  return {
    mergeCaiCategory
  };
};
