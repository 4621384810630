import type { GlobalState } from 'shared/reducers';
import type { EitlLabelsSelectData } from './useEitlLabelsSelectData.types';

import * as userSelectors from 'shared/features/user/user.selectors';

import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

export const useEitlLabelsSelectData = (): EitlLabelsSelectData => {
  const [isOpen, setIsOpen] = useState<EitlLabelsSelectData['localState']['isOpen']>(false);

  const localState: EitlLabelsSelectData['localState'] = {
    isOpen
  };

  const localActions: EitlLabelsSelectData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  const reduxState: EitlLabelsSelectData['reduxState'] = useSelector<
    GlobalState,
    EitlLabelsSelectData['reduxState']
  >(state => ({
    currentUserId: userSelectors.getCurrentUserId(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
