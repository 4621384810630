import type { FC } from 'react';
import type { EitlLabelRenameModalProps } from './EitlLabelRenameModal.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import {
  useEitlLabelRenameModalData,
  useEitlLabelRenameModalMutations,
  useEitlLabelRenameModalFormattedData
} from './hooks';

import './_eitl-label-rename-modal.scss';

export const EitlLabelRenameModal: FC<EitlLabelRenameModalProps> = ({
  label,
  onConfirm,
  onClose
}) => {
  const { localState, localActions } = useEitlLabelRenameModalData({
    props: {
      label
    }
  });

  const mutations = useEitlLabelRenameModalMutations({
    props: {
      label,
      onConfirm
    },
    localState
  });

  const formattedData = useEitlLabelRenameModalFormattedData({
    props: {
      label
    },
    localState,
    mutations
  });

  return (
    <uiLib.Modal
      isOpen
      title="Rename EITL Label"
      withFooter
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Rename"
      onSubmit={mutations.renameEitlLabel.mutate}
      onClose={onClose}
      classNames={{
        container: 'eitl-label-rename-modal__container',
        content: 'eitl-label-rename-modal__content'
      }}
    >
      <div className="eitl-label-rename-modal__content-container">
        {mutations.renameEitlLabel.isLoading && <uiLib.Loader withoutBackground={false} />}
        <uiLib.LabeledField label="EITL Label name">
          <uiLib.TextField
            value={localState.eitlLabelName}
            onChange={localActions.setEitlLabelName}
            required
          />
        </uiLib.LabeledField>
      </div>
    </uiLib.Modal>
  );
};
