import _ from 'lodash';
import {
  REQUEST_COMMANDS,
  RECEIVE_COMMANDS,
  CREATE_COMMAND,
  CREATED_COMMAND,
  UPDATE_COMMAND,
  UPDATED_COMMAND,
  REQUEST_COMMAND_REQUESTS,
  RECEIVE_COMMAND_REQUESTS,
  UPLOAD_COMMAND_REQUESTS,
  UPLOADED_COMMAND_REQUEST,
  UPDATE_COMMAND_REQUEST,
  COMMAND_REQUEST_UPDATED
} from './commands.actions';

export const getInitialCommandsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  items: []
});

export const commands = (state = getInitialCommandsState(), action) => {
  switch (action.type) {
    case REQUEST_COMMANDS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        items: []
      };
    case RECEIVE_COMMANDS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.commands
      };
    case CREATE_COMMAND:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case CREATED_COMMAND:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        items: action.commands
      };
    case UPDATE_COMMAND:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case UPDATED_COMMAND:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        items: action.commands
      };
    default:
      return state;
  }
};

export const getInitialCommandRequestsState = () => ({
  isFetching: false,
  isReady: false,
  updated: false,
  isUpdating: false,
  items: []
});

export const command_requests = (state = getInitialCommandRequestsState(), action) => {
  switch (action.type) {
    case REQUEST_COMMAND_REQUESTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_COMMAND_REQUESTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: _.get(action.command_requests, 'command_request', []),
        count: _.get(action.command_requests, 'command_request_count', 10)
      };
    case UPLOAD_COMMAND_REQUESTS:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case UPLOADED_COMMAND_REQUEST:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        items: _.get(action.command_requests, 'command_request', []),
        count: _.get(action.command_requests, 'command_request_count', 10)
      };
    case UPDATE_COMMAND_REQUEST:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case COMMAND_REQUEST_UPDATED:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        items: _.get(action.command_requests, 'command_request', action.command_requests),
        count: _.get(action.command_requests, 'command_request_count', 0)
      };
    default:
      return state;
  }
};
