import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as uiLib from '@compliance.ai/web-components';
import { AGENCY_GENERAL, AGENCY_WORDS, AGENCY_TYPES } from './AgencyDetails.constants';
import { loadJurisdictionsOptions, calculateSourceTypes } from './AgencyDetails.helper';
import ExpandableList from 'admin/components/ExpandableList';

const AgencyDetailsGeneral = ({
  editMode,
  toggleEditMode,
  agencyDetails,
  saveChanges,
  handleChanges,
  handleChangesEvent,
  errorFields,
  switchSource,
  isNewMode
}) => {
  const disableEdit = !editMode;

  const AGENCY_TYPES_OPTIONS = useMemo(
    () => Object.values(AGENCY_TYPES).map(item => ({ label: item, value: item })),
    []
  );

  const SOURCE_TYPES_OPTIONS = calculateSourceTypes();

  return (
    <div className="general-info">
      <div className="general-info_header">
        <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.H5}>General info</uiLib.Typography>
        <div className="general-info_header_btn-block">
          <uiLib.Button type={uiLib.BUTTON_TYPES.SECONDARY} onClick={toggleEditMode}>
            {editMode ? 'Cancel' : 'Edit'}
          </uiLib.Button>
          {editMode && (
            <uiLib.Button type={uiLib.BUTTON_TYPES.SUCCESS} onClick={saveChanges}>
              Save Changes
            </uiLib.Button>
          )}
        </div>
      </div>
      <div className="general-info_body">
        {/* First row */}
        <uiLib.TextField
          classNames={{ labeledFieldContainer: 'input_4' }}
          label="Name"
          name={AGENCY_GENERAL.name}
          value={agencyDetails[AGENCY_GENERAL.name]}
          isDisabled={disableEdit}
          onChange={value => handleChanges(AGENCY_GENERAL.name, value)}
          error={errorFields.includes(AGENCY_GENERAL.name)}
        />
        <uiLib.TextField
          classNames={{ labeledFieldContainer: 'input_2' }}
          label="ID"
          isDisabled={disableEdit || !isNewMode}
          name={AGENCY_GENERAL.agencyId}
          value={agencyDetails[AGENCY_GENERAL.agencyId]}
          onChange={value => handleChanges(AGENCY_GENERAL.agencyId, value)}
          error={errorFields.includes(AGENCY_GENERAL.agencyId)}
        />
        <uiLib.LabeledField label="Source Type" className="input_2">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            label="Source Type"
            isDisabled
            value={agencyDetails[AGENCY_GENERAL.sourceType]}
            options={SOURCE_TYPES_OPTIONS}
            onChange={switchSource}
          />
        </uiLib.LabeledField>
        {/* Second row */}
        <uiLib.TextField
          classNames={{ labeledFieldContainer: 'input_8' }}
          label="Description"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.description}
          value={agencyDetails[AGENCY_GENERAL.description]}
          onChange={value => handleChanges(AGENCY_GENERAL.description, value)}
          error={errorFields.includes(AGENCY_GENERAL.description)}
        />
        {/* Third row */}
        <uiLib.TextField
          classNames={{ labeledFieldContainer: 'input_2' }}
          label="Short Name"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.shortName}
          value={agencyDetails[AGENCY_GENERAL.shortName]}
          onChange={value => handleChanges(AGENCY_GENERAL.shortName, value)}
          error={errorFields.includes(AGENCY_GENERAL.shortName)}
        />
        <uiLib.LabeledField label="Jurisdiction" className="input_2">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            isSearchable
            isAsync
            isDisabled={disableEdit}
            label="Jurisdiction"
            loadOptions={value => loadJurisdictionsOptions('short_name', value, 'short_name')}
            shouldFetchDefaultOptions
            value={agencyDetails[AGENCY_GENERAL.jurisdiction]}
            isClearable
            onChange={e => handleChanges(AGENCY_GENERAL.jurisdiction, e)}
          />
        </uiLib.LabeledField>
        <uiLib.LabeledField label="Agency Type" className="input_2">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            label="Agency Type"
            isDisabled={disableEdit}
            value={agencyDetails[AGENCY_GENERAL.type]}
            options={AGENCY_TYPES_OPTIONS}
            onChange={e => handleChanges(AGENCY_GENERAL.type, e)}
          />
        </uiLib.LabeledField>
        <uiLib.TextField
          classNames={{ labeledFieldContainer: 'input_2' }}
          label="Parend ID"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.parentID}
          value={agencyDetails[AGENCY_GENERAL.parentID]}
          onChange={value => handleChanges(AGENCY_GENERAL.parentID, value)}
          error={errorFields.includes(AGENCY_GENERAL.parentID)}
        />
        {/* Fourth row */}
        <uiLib.TextField
          classNames={{ labeledFieldContainer: 'input_4' }}
          label="URL"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.url}
          value={agencyDetails[AGENCY_GENERAL.url]}
          onChange={value => handleChanges(AGENCY_GENERAL.url, value)}
          error={errorFields.includes(AGENCY_GENERAL.url)}
        />
        <uiLib.TextField
          classNames={{ labeledFieldContainer: 'input_4' }}
          label="Slug"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.slug}
          value={agencyDetails[AGENCY_GENERAL.slug]}
          onChange={value => handleChanges(AGENCY_GENERAL.slug, value)}
          error={errorFields.includes(AGENCY_GENERAL.slug)}
        />
        {/* Fourth row */}
        <uiLib.Switch
          name={AGENCY_GENERAL.popular}
          label="Popular"
          className="input_2"
          isChecked={agencyDetails[AGENCY_GENERAL.popular]}
          isDisabled={disableEdit}
          onChange={e => handleChanges(AGENCY_GENERAL.popular, e)}
        />
        <uiLib.Switch
          name={AGENCY_GENERAL.blacklisted}
          label="Blacklisted"
          className="input_2"
          isChecked={agencyDetails[AGENCY_GENERAL.blacklisted]}
          onChange={e => handleChanges(AGENCY_GENERAL.blacklisted, e)}
          isDisabled={disableEdit}
        />
        <div className="input_4" />
        {/* Fifth row */}
        <ExpandableList
          label="Synonyms"
          className="input_1"
          name={AGENCY_WORDS.synonyms}
          value={agencyDetails[AGENCY_WORDS.synonyms]}
          isDisabled={disableEdit}
          onChange={handleChanges}
        />
        <ExpandableList
          label="Departments"
          className="input_1"
          name={AGENCY_WORDS.departments}
          value={agencyDetails[AGENCY_WORDS.departments]}
          isDisabled={disableEdit}
          onChange={handleChanges}
        />
        <ExpandableList
          label="Sub-departments"
          className="input_1"
          name={AGENCY_WORDS.subdepartments}
          value={agencyDetails[AGENCY_WORDS.subdepartments]}
          isDisabled={disableEdit}
          onChange={handleChanges}
        />
      </div>
    </div>
  );
};

AgencyDetailsGeneral.propTypes = {
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func.isRequired,
  agencyDetails: PropTypes.shape({}),
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  switchSource: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string),
  isNewMode: PropTypes.bool.isRequired
};

AgencyDetailsGeneral.defaultProps = {
  editMode: false,
  agencyDetails: {},
  errorFields: []
};

export default AgencyDetailsGeneral;
