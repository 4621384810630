import { fetch, BASE_API_OPTIONS } from "fetch";

export function api_fetchCollections(params = {}) {
  return fetch({
    url: '/collections',
    method: 'GET',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_addCollection(params) {
  return fetch({
    url: `/collections`,
    method: 'POST',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_updateCollection(currentID, params) {
  return fetch({
    url: `/collections/${currentID}`,
    method: 'POST',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}

export function api_deleteCollection(currentID) {
  return fetch({
    url: `/collections/${currentID}`,
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
}
