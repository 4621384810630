import _ from 'lodash';
import {
  api_getDocTypes,
  api_fetchDocDiff,
  api_fetchAggregateEnforcements,
  api_getEnforcements,
  api_fetchGoogleResults,
  api_getDocuments,
  api_getDataDocs,
  api_getIncompleteDocuments,
  api_updateDocuments,
  api_updateDocument,
  api_updateDataDocument,
  api_createDocument,
  api_getHiddenDocuments,
  api_getDocument,
  api_fetchPopularDocs,
  api_getDocumentResponseHeaders,
  api_getDocumentsBasic,
  api_fetchSentences,
  api_getDocsForIFrame
} from './documents.api';
import {
  REQUEST_FOLDER_DOCUMENTS, RECEIVE_FOLDER_DOCUMENTS
} from '../folders/folders.actions';
import { DEFAULT_DOCUMENT_SORT_KEY, DEFAULT_DASH_CCPA_PARAMS, DEFAULT_DASH_COVID_PARAMS } from './documents.helper';

import { recieveFollowedCategories } from 'shared/features/user/user.actions';
import {getUserAuthenticatedState} from "../auth/auth.selectors";

export const FETCH_SENTENCES_REQUEST = 'FETCH_SENTENCES_REQUEST';
export const FETCH_SENTENCES_SUCCESS = 'FETCH_SENTENCES_SUCCESS';
export const FETCH_SENTENCES_FAILURE = 'FETCH_SENTENCES_FAILURE';
export const RECEIVE_DOC_TYPES = "RECEIVE_DOC_TYPES";
export const REQUEST_AGGREGATE_ENFORCEMENTS = 'REQUEST_AGGREGATE_ENFORCEMENTS';
export const RECEIVE_AGGREGATE_ENFORCEMENTS = 'RECEIVE_AGGREGATE_ENFORCEMENTS';
export const REQUEST_ENFORCEMENTS = 'REQUEST_ENFORCEMENTS';
export const RECEIVE_ENFORCEMENTS = 'RECEIVE_ENFORCEMENTS';
export const REQUEST_GOOGLE_RESULTS = 'REQUEST_GOOGLE_RESULTS';
export const RECEIVE_GOOGLE_RESULTS = 'RECEIVE_GOOGLE_RESULTS';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const REQUEST_DOCUMENTS_FOR_DASH_CCPA = 'REQUEST_DOCUMENTS_FOR_DASH_CCPA';
export const RECEIVE_DOCUMENTS_FOR_DASH_CCPA = 'RECEIVE_DOCUMENTS_FOR_DASH_CCPA';
export const REQUEST_DOCUMENTS_FOR_DASH_COVID = 'REQUEST_DOCUMENTS_FOR_DASH_COVID';
export const RECEIVE_DOCUMENTS_FOR_DASH_COVID = 'RECEIVE_DOCUMENTS_FOR_DASH_COVID';
export const REQUEST_DOCUMENTS = 'REQUEST_DOCUMENTS';
export const REQUEST_DOCUMENTS_BEFORE = 'REQUEST_DOCUMENTS_BEFORE';
export const REQUEST_DOCUMENTS_AFTER = 'REQUEST_DOCUMENTS_AFTER';
export const RECEIVE_DOCUMENTS = 'RECEIVE_DOCUMENTS';
export const REQUEST_DOCUMENT = 'REQUEST_DOCUMENT';
export const RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT';
export const RECEIVE_DOCUMENTS_BEFORE = 'RECEIVE_DOCUMENTS_BEFORE';
export const RECEIVE_DOCUMENTS_AFTER = 'RECEIVE_DOCUMENTS_AFTER';
export const REQUEST_FULL_DOCUMENTS = 'REQUEST_FULL_DOCUMENTS';
export const RECEIVE_FULL_DOCUMENTS = 'RECEIVE_FULL_DOCUMENTS';
export const REQUEST_RELATED_DOCUMENT_COUNT = 'REQUEST_RELATED_DOCUMENT_COUNT';
export const RECEIVE_RELATED_DOCUMENT_COUNT = 'RECEIVE_RELATED_DOCUMENT_COUNT';
export const REQUEST_ALL_DOCUMENTS = 'REQUEST_ALL_DOCUMENTS';
export const RECEIVE_ALL_DOCUMENTS = 'RECEIVE_ALL_DOCUMENTS';
export const FLAG_DOCUMENT = 'FLAG_DOCUMENT';
export const FLAGGED_DOCUMENT = 'FLAGGED_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED';
export const REQUEST_INCOMPLETE_DOCUMENTS = 'REQUEST_INCOMPLETE_DOCUMENTS';
export const RECEIVE_INCOMPLETE_DOCUMENTS = 'RECEIVE_INCOMPLETE_DOCUMENTS';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const DOCUMENT_CREATED = 'DOCUMENT_CREATED';
export const ADD_DOCS_TO_DIFF = 'ADD_DOCS_TO_DIFF';
export const RECEIVE_DOCUMENT_COMMENTS_COUNT = 'RECEIVE_DOCUMENT_COMMENTS_COUNT';
export const REQUEST_HIDDEN_DOCUMENTS = 'REQUEST_HIDDEN_DOCUMENTS';
export const RECEIVE_HIDDEN_DOCUMENTS = 'RECEIVE_HIDDEN_DOCUMENTS';
export const REQUEST_DOCUMENT_DETAILS = 'REQUEST_DOCUMENT_DETAILS';
export const RECEIVE_DOCUMENT_DETAILS = 'RECEIVE_DOCUMENT_DETAILS';
export const REQUEST_SIMPLE_DOCUMENTS = 'REQUEST_SIMPLE_DOCUMENTS';
export const RECEIVE_SIMPLE_DOCUMENTS = 'RECEIVE_SIMPLE_DOCUMENTS';
export const MARK_DOCUMENT_BOOKMARKED = 'MARK_DOCUMENT_BOOKMARKED';
export const MARKED_DOCUMENT_AS_BOOKMARKED = 'MARKED_DOCUMENT_AS_BOOKMARKED';
export const REQUEST_SEARCH_RESULTS_RELEVANCE = 'REQUEST_SEARCH_RESULTS_RELEVANCE';
export const RECEIVE_SEARCH_RESULTS_RELEVANCE = 'RECEIVE_SEARCH_RESULTS_RELEVANCE';
export const REQUEST_RESOURCE_CODE = 'REQUEST_RESOURCE_CODE';
export const RECEIVE_RESOURCE_CODE = 'RECEIVE_RESOURCE_CODE';
export const REQUEST_POPULAR_DOCS = 'REQUEST_POPULAR_DOCS';
export const RECEIVE_POPULAR_DOCS = 'RECEIVE_POPULAR_DOCS';
export const REQUEST_NEW_DOCUMENT_URL_HEADERS = 'REQUEST_NEW_DOCUMENT_URL_HEADERS';
export const RECEIVE_NEW_DOCUMENT_URL_HEADERS = 'RECEIVE_NEW_DOCUMENT_URL_HEADERS';
export const REQUEST_RECENT_ACTIVITY = 'REQUEST_RECENT_ACTIVITY';
export const RECEIVE_RECENT_ACTIVITY = 'RECEIVE_RECENT_ACTIVITY';
export const REMOVE_FROM_DOCUMENT_LIST = 'REMOVE_FROM_DOCUMENT_LIST';

function mungeDocumentResponses(extra_params_list, responses) {
  const data = {};

  extra_params_list.forEach((extra_params, i) => {
    data[extra_params.sort] = {
      documents: responses[i].documents,
      count: responses[i].count,
      offsets: responses[i].offsets
    };
  });

  return data;
}

export function makeDocumentRequests(commonParams, listOfParams, rnToken) {
  return listOfParams.map(params => {
    return api_getDocuments({
      ...commonParams,
      ...params
    }, rnToken);
  });
}

function recieveDocTypes(docTypes) {
  return {
    type: RECEIVE_DOC_TYPES,
    docTypes
  };
}

function requestAggregateEnforcements() {
  return {
    type: REQUEST_AGGREGATE_ENFORCEMENTS
  };
}

function receiveAggregateEnforcements(aggregateEnforcements) {
  return {
    type: RECEIVE_AGGREGATE_ENFORCEMENTS,
    aggregateEnforcements
  };
}

function requestEnforcements() {
  return {
    type: REQUEST_ENFORCEMENTS
  };
}

function receiveEnforcements(response) {
  return {
    type: RECEIVE_ENFORCEMENTS,
    payload: response
  };
}

function requestGoogleResults() {
  return {
    type: REQUEST_GOOGLE_RESULTS
  };
}

function receiveGoogleResults(response) {
  return {
    type: RECEIVE_GOOGLE_RESULTS,
    payload: response
  };
}

function receiveDocumentsAfter(params, extraParamsList, responses) {
  const data = mungeDocumentResponses(extraParamsList, responses);

  return {
    type: RECEIVE_DOCUMENTS_AFTER,
    params,
    data
  };
}

function receiveDocumentsBefore(params, extraParamsList, responses) {
  const data = mungeDocumentResponses(extraParamsList, responses);

  return {
    type: RECEIVE_DOCUMENTS_BEFORE,
    params,
    data
  };
}

export function clearDocuments() {
  return {
    type: CLEAR_DOCUMENTS,
    documents: null
  };
}

function requestFullDocuments(params) {
  return {
    type: REQUEST_FULL_DOCUMENTS,
    params
  };
}

function receiveFullDocuments(params, json, no_ready_update) {
  return {
    type: RECEIVE_FULL_DOCUMENTS,
    params,
    no_ready_update,
    documents: _.map(json, 'document')
  };
}

function receiveFullDataDocuments(documents) {
  return {
    type: RECEIVE_FULL_DOCUMENTS,
    params: {},
    no_ready_update: false,
    documents
  };
}

function requestDocumentDetails() {
  return {
    type: REQUEST_DOCUMENT_DETAILS
  };
}

function recieveDocumentDetails(response) {
  return {
    type: RECEIVE_DOCUMENT_DETAILS,
    document: _.map(response, 'document')[0]
  };
}

function requestDocumentsAfter(params) {
  return {
    type: REQUEST_DOCUMENTS_AFTER,
    params
  };
}

function requestDocumentsBefore(params) {
  return {
    type: REQUEST_DOCUMENTS_BEFORE,
    params
  };
}

function requestAllDocuments() {
  return {
    type: REQUEST_ALL_DOCUMENTS
  };
}

function receiveAllDocuments(json) {
  return {
    type: RECEIVE_ALL_DOCUMENTS,
    alldocuments: json
  };
}


function requestIncompleteDocuments() {
  return {
    type: REQUEST_INCOMPLETE_DOCUMENTS
  };
}

function receiveIncompleteDocuments(json) {
  return {
    type: RECEIVE_INCOMPLETE_DOCUMENTS,
    incomplete_documents: json
  };
}

function markDocumentBookmarked(ids, bookmarked_status) {
  return {
    type: MARK_DOCUMENT_BOOKMARKED,
    ids,
    bookmarked_status
  };
}

function markDocumentAsFlagged(id, params) {
  return {
    type: FLAG_DOCUMENT,
    id,
    params
  };
}

function markedDocumentAsFlagged(json) {
  return {
    type: FLAGGED_DOCUMENT,
    document: _.get(json, 'document')
  };
}

function updateDocumentData(id, params) {
  return {
    type: UPDATE_DOCUMENT,
    id,
    params
  };
}

function documentDataUpdated(json) {
  return {
    type: DOCUMENT_UPDATED,
    document: _.get(json, 'document')
  };
}

function markedDocumentAsBookmarked(json) {
  return {
    type: MARKED_DOCUMENT_AS_BOOKMARKED,
    documents: _.get(json, 'documents')
  };
}

function requestRelatedDocumentCount(params) {
  return {
    type: REQUEST_RELATED_DOCUMENT_COUNT,
    params
  };
}

function receiveRelatedDocumentCount(params, response) {
  return {
    type: RECEIVE_RELATED_DOCUMENT_COUNT,
    params,
    response
  };
}

function createDocument(params) {
  return {
    type: CREATE_DOCUMENT,
    params
  };
}

function createdDocument(document) {
  return {
    type: DOCUMENT_CREATED,
    document
  };
}

function requestHiddenDocuments() {
  return {
    type: REQUEST_HIDDEN_DOCUMENTS
  };
}

function receiveHiddenDocuments(json) {
  return {
    type: RECEIVE_HIDDEN_DOCUMENTS,
    hidden_documents: json
  };
}

function requestSimpleDocuments() {
  return {
    type: REQUEST_SIMPLE_DOCUMENTS
  };
}

function receiveSimpleDocuments(data) {
  return {
    type: RECEIVE_SIMPLE_DOCUMENTS,
    recent_documents: data.documents
  };
}

function updateDocumentsList(action, doc) {
  return {
    type: action,
    doc
  };
}

function requestDocuments(params, sorts, type) {
  return {
    type,
    params
  };
}

function requestSearchResults(params) {
  return {
    type: REQUEST_SEARCH_RESULTS_RELEVANCE,
    params
  };
}

function receiveDocuments(params, extraParamsList, responses, type) {
  const data = mungeDocumentResponses(extraParamsList, responses);
  return {
    type,
    params,
    data
  };
}

function receiveSearchResults(data) {
  return {
    type: RECEIVE_SEARCH_RESULTS_RELEVANCE,
    data
  };
}

function requestResourceCode() {
  return {
    type: REQUEST_RESOURCE_CODE
  };
}

function receiveResourceCode(json) {
  return {
    type: RECEIVE_RESOURCE_CODE,
    branches: _.map(json, 'document')
  };
}

function receiveDocumentCommentsCount(params, response) {
  return {
    type: RECEIVE_DOCUMENT_COMMENTS_COUNT,
    params,
    response
  };
}

function requestDocument() {
  return {
    type: REQUEST_DOCUMENT
  };
}

function receiveDocument(response) {
  return {
    type: RECEIVE_DOCUMENT,
    payload: response
  };
}

function requestPopularDocs() {
  return {
    type: REQUEST_POPULAR_DOCS
  };
}

function recievePopularDocs(data) {
  return {
    type: RECEIVE_POPULAR_DOCS,
    popular_docs: data.popular_docs
  };
}

function requestNewDocumentUrlHeaders() {
  return {
    type: REQUEST_NEW_DOCUMENT_URL_HEADERS
  };
}

function receiveNewDocumentUrlHeaders(json) {
  return {
    type: RECEIVE_NEW_DOCUMENT_URL_HEADERS,
    headers: json
  };
}

function requestRecentActivity() {
  return {
    type: REQUEST_RECENT_ACTIVITY
  };
}

function receiveRecentActivity(response, agencies) {
  const agenciesArray = response.aggregations.filtered_documents['by_agencies.id'].buckets;
  const total_updates = response.aggregations.filtered_documents.doc_count;

  // put agencies into an object to reduce lookup time complexity
  const agenciesObj = agenciesArray.reduce((mem, agency) => {
    mem[agency.key] = {
      categories: agency.by_category.buckets
    };
    return mem;
  }, {});
  // filter by user's agencies and format data for reducer
  const document_stats = agencies.map(agency => {
    const myAgencyObj = {
      agency_id: agency,
      categories: {}
    };
    if (!_.isNil(agenciesObj[agency])) {
      const categories = agenciesObj[agency].categories.reduce((mem, category) => {
        mem[category.key] = category.doc_count;
        return mem;
      }, {});
      myAgencyObj.categories = categories;
    }

    return myAgencyObj;
  });

  return {
    type: RECEIVE_RECENT_ACTIVITY,
    document_stats,
    total_updates
  };
}

export function fetchGoogleResults(queryParam) {
  return function doFetch(dispatch) {
    dispatch(requestGoogleResults());
    return api_fetchGoogleResults(queryParam)
      .then(response => dispatch(receiveGoogleResults(response)));
  };
}

export function fetchEnforcementData(parameters, useAPIKey) {
  return function doFetch(dispatch) {
    dispatch(requestEnforcements());
    return api_getEnforcements(parameters, useAPIKey)
      .then(response => dispatch(receiveEnforcements(response)));
  };
}

function getAllCategoriesObject(DocTypes) {
  const result = {};
  Object.keys(DocTypes.app_to_scraped_dic).forEach(appCategory => {
    result[appCategory] = true;
  });

  return result;
}

export function fetchDocumentTypes() {
  return function doFetch(dispatch, getState) {
    const isAuthenticated = getUserAuthenticatedState(getState());

    return api_getDocTypes().then(DocTypes => {
      if (!isAuthenticated) {
        const allCategoriesObject = getAllCategoriesObject(DocTypes);
        dispatch(recieveFollowedCategories(allCategoriesObject));
      }
      dispatch(recieveDocTypes(DocTypes));
    });
  };
}

export function fetchDiff(params) {
  return function doFetch() {
    return api_fetchDocDiff(params).then(response => response);
  };
}

export function fetchAggregateEnforcements(params) {
  return function doFetch(dispatch) {
    dispatch(requestAggregateEnforcements());
    return api_fetchAggregateEnforcements(params).then(response => {
      dispatch(receiveAggregateEnforcements(response));
    });
  };
}

export function fetchSearchResults(params = {}) {
  return function dofetch(dispatch) {
    dispatch(requestSearchResults(params));
    return api_getDocuments(params)
      .then(response => {
        dispatch(receiveSearchResults(response));
      });
  };
}

export function fetchDocuments(
  params = {},
  extra_params,
  rnToken,
  request_type = REQUEST_DOCUMENTS,
  receive_type = RECEIVE_DOCUMENTS
) {
  //necessary for dual timeline view of folder docs and reg timeline docs
  if (params.folder_id && !params.folderTimelineView) {
    request_type = REQUEST_FOLDER_DOCUMENTS;
    receive_type = RECEIVE_FOLDER_DOCUMENTS;
  }
  const common_params = _.assign({}, params, extra_params);
  const per_request_params = [{ sort: params.sort ? params.sort : DEFAULT_DOCUMENT_SORT_KEY }];

  return async function dofetch(dispatch, getState) {
    dispatch(requestDocuments(common_params, per_request_params, request_type));
    return Promise.all(makeDocumentRequests(common_params, per_request_params, rnToken))
      .then(responses => {
        dispatch(receiveDocuments(common_params, per_request_params, responses, receive_type));
        return responses;
      });
  };
}

export function fetchDocumentsForDashCCPA(params) {
  return fetchDocuments(
    { ...DEFAULT_DASH_CCPA_PARAMS, ...params },
    null,
    null,
    REQUEST_DOCUMENTS_FOR_DASH_CCPA,
    RECEIVE_DOCUMENTS_FOR_DASH_CCPA
  );
}

export function fetchDocumentsForDashCovid(params) {
  return fetchDocuments(
    { ...DEFAULT_DASH_COVID_PARAMS, ...params },
    null,
    null,
    REQUEST_DOCUMENTS_FOR_DASH_COVID,
    RECEIVE_DOCUMENTS_FOR_DASH_COVID
  );
}

export function simpleFetchDocuments(params = {}, rnToken) {
  return async function doFetch(dispatch) {
    dispatch(requestSimpleDocuments());
    return api_getDocuments(params, rnToken)
      .then(response => {
        dispatch(receiveSimpleDocuments(response));
        return response;
      });
  };
}

export function fetchDocumentsAfter(commonParams = {}, extraParamsList) {
  return async function dofetch(dispatch) {
    dispatch(requestDocumentsAfter(commonParams, extraParamsList));

    return Promise.all(makeDocumentRequests(commonParams, extraParamsList))
      .then(responses => {
        dispatch(receiveDocumentsAfter(commonParams, extraParamsList, responses));
      });
  };
}

export function fetchDocumentsBefore(commonParams = {}, extraParamsList) {
  return async function dofetch(dispatch) {
    dispatch(requestDocumentsBefore(commonParams, extraParamsList));

    return Promise.all(makeDocumentRequests(commonParams, extraParamsList))
      .then(responses => {
        dispatch(receiveDocumentsBefore(commonParams, extraParamsList, responses));
      });
  };
}

export function fetchFullDocuments(params = {}, no_ready_update) {
  no_ready_update = !!no_ready_update;
  const ids = _.isArray(params.id) ? params.id : [params.id];
  const commonParams = {
    ...params,
    id: undefined
  };

  return function dofetch(dispatch) {
    if (params.resource_code) {
      dispatch(requestResourceCode());
    } else if (params.doc_details) {
      dispatch(requestDocumentDetails());
    } else {
      dispatch(requestFullDocuments(params, ids));
    }
    const promises = ids.map(id => api_getDocuments({
      ...commonParams,
      id
    }));

    return Promise.all(promises)
      .then(responses => {
        if (params.resource_code) {
          dispatch(receiveResourceCode(responses));
        } else if (params.doc_details) {
          dispatch(recieveDocumentDetails(responses));
        } else {
          dispatch(receiveFullDocuments(commonParams, responses, no_ready_update));
        }
        return responses;
      });
  };
}

export function fetchAllDocuments(params) {
  return function dofetch(dispatch) {
    dispatch(requestAllDocuments());
    return api_getDocuments({
      ...params,
      agency: _.get(params, 'agency_id'),
      topic_id: _.get(params, 'topic_id')
    }).then(response => {
      dispatch(receiveAllDocuments(response));
      return response;
    });
  };
}

export function fetchIncompleteDocs(params) {
  return function dofetch(dispatch) {
    dispatch(requestIncompleteDocuments());
    return api_getIncompleteDocuments(params)
      .then(response => {
        dispatch(receiveIncompleteDocuments(response));
        return response;
      });
  };
}

export function markDocumentAsBookmarked(doc_ids, bookmarked_status) {
  let document_ids = doc_ids;

  if (!_.isArray(doc_ids)) {
    document_ids = [doc_ids];
  }

  return function dopost(dispatch) {
    dispatch(markDocumentBookmarked(document_ids, bookmarked_status));
    return api_updateDocuments(document_ids, { bookmarked: bookmarked_status })
      .then(response => {
        dispatch(markedDocumentAsBookmarked(response));
      });
  };
}

export function flagDocument(doc_id, flagged) {
  const params = { flagged };
  return function dopost(dispatch) {
    dispatch(markDocumentAsFlagged(doc_id, params));
    return api_updateDocument(doc_id, params)
      .then(response => {
        dispatch(markedDocumentAsFlagged(response));
        return response;
      })
      .catch(error => {
        dispatch(markedDocumentAsFlagged(null));
      });
  };
}

export function updateDocument(doc_id, update) {
  return function dopost(dispatch) {
    dispatch(updateDocumentData(doc_id, update));
    return api_updateDocument(doc_id, { update })
      .then(response => {
        dispatch(documentDataUpdated(response));
        return response;
      })
      .catch(error => {
        dispatch(documentDataUpdated(null));
      });
  };
}

export function updateDataDocument(doc_id, params, action) {
  return function dopost(dispatch) {
    dispatch(updateDocumentData(doc_id, params));
    return api_updateDataDocument(doc_id, params)
      .then(response => {
        dispatch(documentDataUpdated(response));
        if (action) {
          dispatch(updateDocumentsList(action, response));
        }
        return response;
      })
      .catch(error => {
        dispatch(documentDataUpdated(null));
      });
  };
}

export function fetchRelatedDocumentCount(params = {}) {
  return function dofetch(dispatch) {
    dispatch(requestRelatedDocumentCount(params));
    return api_getDocuments({
      ...params,
      search_sort: 'relevance',
      get_count_only: true
    })
      .then(response => {
        dispatch(receiveRelatedDocumentCount(params, response));
      });
  };
}

export function fetchDocumentCommentsCount(comments_for_id) {
  const params = {
    comments_for_id,
    get_count_only: true
  };

  return function dofetch(dispatch) {
    return api_getDocuments(params)
      .then(response => {
        dispatch(receiveDocumentCommentsCount(params, response));
      });
  };
}

export function createDocumentFromUrl(params) {
  return function dopost(dispatch) {
    dispatch(createDocument(params));
    return api_createDocument(params)
      .then(response => {
        dispatch(createdDocument(response));
        return response;
      })
      .catch(() => {
        dispatch(createdDocument(null));
      });
  };
}

export function fetchHiddenDocuments(data) {
  return function dofetch(dispatch) {
    dispatch(requestHiddenDocuments());
    return api_getHiddenDocuments(data)
      .then(response => {
        dispatch(receiveHiddenDocuments(response));
      });
  };
}

export function fetchDocument(doc_id) {
  return function getDocument(dispatch) {
    dispatch(requestDocument());
    return api_getDocument(doc_id)
      .then(response => {
        dispatch(receiveDocument(response));
        return response.document;
      });
  };
}

export function addDocsToDiff(documents) {
  return {
    type: ADD_DOCS_TO_DIFF,
    documentsToDiff: documents
  };
}

export function fetchPopularDocs(data = { num_queries: 5 }) {
  return function doFetch(dispatch) {
    dispatch(requestPopularDocs());
    return api_fetchPopularDocs(data)
      .then(response => {
        dispatch(recievePopularDocs(response));
        return response;
      });
  };
}

export function getResponseHeaders(data) {
  return function dofetch(dispatch) {
    dispatch(requestNewDocumentUrlHeaders());
    return api_getDocumentResponseHeaders(data)
      .then(response => {
        dispatch(receiveNewDocumentUrlHeaders(response));
        return response;
      });
  };
}

export function fetchIframeDocs(data) {
  return function dofetch() {
    return api_getDocumentsBasic(data);
  };
}


export function fetchIframeDocsBuildUrl(data) {
  return function dofetch() {
    return api_getDocsForIFrame(data);
  };
}

export function fetchSentences(docIds, use_obligation_version, contentType) {
  return async dispatch => {
    dispatch({ type: FETCH_SENTENCES_REQUEST });

    try {
      const response = await api_fetchSentences(docIds, { use_obligation_version }, contentType);
      dispatch({ type: FETCH_SENTENCES_SUCCESS, response });

      return { response };
    } catch (e) {
      return dispatch({ type: FETCH_SENTENCES_FAILURE });
    }
  };
}

export function fetchFilteredDataDocs(params, extraParameters) {
  return function dofetch(dispatch) {
    dispatch(requestAllDocuments());
    return api_getDataDocs(params)
      .then(response => {
        if (_.get(extraParameters, 'saveFullDataDocuments')) {
          dispatch(receiveFullDataDocuments(_.get(response, 'documents')));
        }
        dispatch(receiveAllDocuments(response));
        return response;
      });
  };
}
