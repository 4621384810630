// ----- SELECT CONSTANTS -----

export const SELECT_BOOL = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];

// ----- TABLE CONSTANTS -----

export const TABLE_VARIABLES = {
  id: 'id',
  name: 'name',
  short_name: 'short_name',
  category: 'category',
  parent_id: 'parent_id',
  times_cited: 'times_cited',
  active: 'active',
  country: 'country'
};

export const DEFAULT_TABLE_COLUMNS = [
  TABLE_VARIABLES.id,
  TABLE_VARIABLES.name,
  TABLE_VARIABLES.short_name,
  TABLE_VARIABLES.category,
  TABLE_VARIABLES.country,
  TABLE_VARIABLES.parent_id,
  TABLE_VARIABLES.active
];

export const COLUMNS_CONFIG = [
  { name: 'Id', id: TABLE_VARIABLES.id },
  { name: 'Name', id: TABLE_VARIABLES.name },
  { name: 'Short Name', id: TABLE_VARIABLES.short_name },
  { name: 'Category', id: TABLE_VARIABLES.category },
  { name: 'Country', id: TABLE_VARIABLES.country },
  { name: 'Parent Id', id: TABLE_VARIABLES.parent_id },
  { name: 'Active', id: TABLE_VARIABLES.active }
];
