export const MY_TASKS = { label: 'My Tasks Only', value: 'myTasksOnly' };
export const ALL_ORG_TASKS = { label: 'All Organization Tasks', value: 'allOrganizationTasks' };
export const MY_OBLIGATION_TASKS = { label: 'My Obligation Tasks', value: 'myObligationTasks' };
export const ALL_OBLIGATION_TASKS = { label: 'All Obligation Tasks', value: 'allObligationTasks' };
export const TASK_SELECT_OPTIONS = [
  MY_TASKS,
  ALL_ORG_TASKS,
  MY_OBLIGATION_TASKS,
  ALL_OBLIGATION_TASKS
];

export const LIST_VIEW = 'list';
export const CALENDAR_VIEW = 'calendar';

export const HIDE_BLOCKED_TASKS = { label: 'Hide blocked tasks', value: true };
export const SHOW_BLOCKED_TASKS = { label: 'Include blocked tasks', value: false };
export const BLOCKED_TASK_SELECT_OPTIONS = [HIDE_BLOCKED_TASKS, SHOW_BLOCKED_TASKS];

export const CREATED_AT = 'created_at';
export const DUE_DATE = 'due_date';

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const DEFAULT_LIMIT = 20;

export const INCOMPLETE = 'incomplete';
export const TO_DO = 'to_do';
export const PAST_DUE = 'past_due';
export const DONE = 'done';

const STATUS_PARAMS = {
  [TO_DO]: {
    limit: DEFAULT_LIMIT,
    offset: 0,
    order: DESCENDING,
    sort: CREATED_AT
  },
  [PAST_DUE]: {
    limit: DEFAULT_LIMIT,
    offset: 0,
    order: DESCENDING,
    sort: DUE_DATE
  },
  [DONE]: {
    limit: DEFAULT_LIMIT,
    offset: 0,
    order: DESCENDING,
    sort: DUE_DATE
  }
};

export const DEFAULT_QUERIES = (obligationSuperTasksOnly = false, hideBlockedTasks = false) => ([
  {
    ...STATUS_PARAMS[TO_DO],
    status: TO_DO,
    obligation_super_tasks_only: obligationSuperTasksOnly,
    hide_blocked_tasks: hideBlockedTasks
  },
  {
    ...STATUS_PARAMS[PAST_DUE],
    status: PAST_DUE,
    obligation_super_tasks_only: obligationSuperTasksOnly,
    hide_blocked_tasks: hideBlockedTasks
  },
  {
    ...STATUS_PARAMS[DONE],
    status: DONE,
    obligation_super_tasks_only: obligationSuperTasksOnly,
    hide_blocked_tasks: hideBlockedTasks
  }
]);

export const DEFAULT_DOC_TASKS = {
  [TO_DO]: {
    ...STATUS_PARAMS[TO_DO],
    count: 0,
    doc_tasks: null,
    length: 0
  },
  [PAST_DUE]: {
    ...STATUS_PARAMS[PAST_DUE],
    count: 0,
    doc_tasks: null,
    length: 0
  },
  [DONE]: {
    ...STATUS_PARAMS[DONE],
    count: 0,
    doc_tasks: null,
    length: 0
  }
};

export const STATUS_METADATA = {
  [TO_DO]: {
    name: 'To Do',
    className: 'to-do'
  },
  [PAST_DUE]: {
    name: 'Past Due',
    className: 'past-due'
  },
  [DONE]: {
    name: 'Done',
    className: 'done'
  }
};

export const SORT_METADATA = {
  [CREATED_AT]: {
    name: 'Created Date'
  },
  [DUE_DATE]: {
    name: 'Due Date'
  }
};

export const SORT_OPTIONS = [CREATED_AT, DUE_DATE];
