import type { CrawlerDetailsGeneralProps } from '../CrawlerDetailsGeneral.types';
import type { CrawlerDetailsGeneralHandlers } from './useCrawlerDetailsGeneralHandlers.types';

import {
  AUTO_TRANSLATION_TYPE,
  CRAWL_GENERAL,
  CRAWL_PROVISION,
  DEFAULT_AUTO_TRANSLATION_OPTION,
  ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS
} from '../../../CrawlerDetails.constants';
import { CRAWLER_TYPE } from '../../../../../../constants/crawlersTypes';

export const useCrawlerDetailsGeneralHandlers = ({
  props
}: {
  props: Pick<CrawlerDetailsGeneralProps, 'handleChanges'>;
}): CrawlerDetailsGeneralHandlers => {
  const handleCrawlerTypeChange: CrawlerDetailsGeneralHandlers['handleCrawlerTypeChange'] =
    option => {
      props.handleChanges(CRAWL_GENERAL.crawlType, option);

      /**
       *
       * Due to high costs of auto-translation for data_admin type of crawlers
       * this feature needs to be disabled by default
       *
       */
      if (option?.value === CRAWLER_TYPE.DATA_ADMIN) {
        const disabledOption = ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS.find(
          option => option.type === AUTO_TRANSLATION_TYPE.DISABLED
        );

        props.handleChanges(
          CRAWL_PROVISION.enableAutoTranslation,
          disabledOption ?? DEFAULT_AUTO_TRANSLATION_OPTION
        );
      } else {
        props.handleChanges(CRAWL_PROVISION.enableAutoTranslation, DEFAULT_AUTO_TRANSLATION_OPTION);
      }
    };

  return {
    handleCrawlerTypeChange
  };
};
