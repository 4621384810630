import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import SelectCreatable from 'react-select-3/creatable';
import Typography from '../Typography';

function CustomSelectCreatable({
  className,
  selectClassName,
  labelClassName,
  isDisabled,
  isError,
  isMulti,
  options,
  label,
  isSearchable,
  isClearable,
  placeholder,
  value,
  onChange
}) {
  return (
    <label className={cn(
      "select_wrapper",
      className
    )}
    >
      <Typography
        variant="label"
        color="gray"
        bold
        className={labelClassName}
      >
        {label}
      </Typography>
      <SelectCreatable
        classNamePrefix={'custom-select'}
        className={cn(
          selectClassName,
          isError && `${selectClassName}_error`
        )}
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isClearable={isClearable}
        onChange={onChange}
        isSearchable={isSearchable}
        value={value}
      />
    </label>
  );
}


CustomSelectCreatable.propTypes = {
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

CustomSelectCreatable.defaultProps = {
  isMulti: false,
  isDisabled: false,
  isClearable: false,
  isSearchable: true
};


export default CustomSelectCreatable;
