import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Pagination,
  Table,
  FormGroup,
  FormControl,
  ControlLabel,
  Form,
  Button,
  Radio
} from 'react-bootstrap';
import _ from 'lodash';
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import {
  fetchAllDocuments,
  fetchFullDocuments
} from 'shared/features/documents/documents.actions';
import { fetchAllSpiderNames } from 'shared/features/analytics/analytics.actions';
import { getCategories } from 'shared/features/categories/categories/categories.actions';
import { fetchAllProvenances } from 'shared/features/provenances/provenances.actions';
import {
  fetchAgencies
  // fetchAllAgencyAdminTool
} from 'shared/features/agencies/agencies.actions';
import { getAgencies } from 'shared/features/agencyData/agencyData.actions';
import DocumentModal from './DocumentModal';
import {withAdminPermissions} from "../../utils/hooks";

const searchingByStringOptions = [
  {
    value: 'url',
    label: 'url'
  }
];

class AdminTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      created_at: 24,
      recent_time: '24',
      limit: 25,
      page: 1,
      category: '',
      spider_name: '',
      provenance: '',
      offset: 0,
      showModal: false,
      openedDocumentId: null,
      all_agencies: true,
      all_topics: true,
      document_id: '',
      filtered_by_id: false,
      filtered_document_id: '',
      showAlert: false,
      agency_id: '',
      topic_id: '',
      from_date: null,
      to_date: null,
      date_type: 'created_at',
      searchByStringType: '',
      searchString: ''
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAllDocuments(_.cloneDeep(this.state));
    this.props.getCategories();
    this.props.fetchAllSpiderNames();
    this.props.fetchAllProvenances();
    this.props.fetchAgencies();
    this.props.getAgencies();
  }

  getParams = () => {
    const params = {};
    params.limit = this.state.limit;
    params.offset = this.state.offset;

    if (this.state.category) {
      params.category = this.state.category;
    }
    if (this.state.provenance) {
      params.provenance = this.state.provenance;
    }
    if (this.state.spider_name) {
      params.spider_name = this.state.spider_name;
    }
    if (this.state.agency_id) {
      params.all_agencies = false;
      params.agency_id = this.state.agency_id.split(',').map(Number);
    } else {
      params.all_agencies = true;
    }
    if (this.state.topic_id) {
      params.all_topics = false;
      params.topic_id = this.state.topic_id;
    } else {
      params.all_topics = true;
    }
    if (this.state.date_type === 'created_at') {
      if (this.state.recent_time) {
        params.created_at = this.state.recent_time;
      } else {
        if (this.state.from_date) {
          // This is done as the jurispect_api/helpers/search_helper uses different date format
          // for created_at to query ES.
          const temp_date = moment(this.state.from_date, 'MM/DD/YYYY');
          // Format used in the backend for created_at to query ES. - %Y-%m-%d %H:%M:%S.%f
          params.created_from = moment(temp_date).format('YYYY-MM-DD 00:00:00.000000');
        }
        if (this.state.to_date) {
          const temp_date = moment(this.state.to_date, 'MM/DD/YYYY');
          params.created_to = moment(temp_date).format('YYYY-MM-DD 00:00:00.000000');
        }
      }
    } else if (this.state.date_type === 'publication_date') {
      if (this.state.recent_time) {
        params.published_at = this.state.recent_time;
      } else {
        if (this.state.from_date) {
          params.published_from = this.state.from_date.format('MM/DD/YYYY');
        }
        if (this.state.to_date) {
          params.published_to = this.state.to_date.format('MM/DD/YYYY');
        }
      }
    }
    if (this.state.searchByStringType && this.state.searchString) {
      params.search_by_string_type = this.state.searchByStringType;
      params.search_by_string_str = this.state.searchString;
    }
    return params;
  };

  handlePageChange = eventKey => {
    this.setState(state => {
      return {
        page: eventKey,
        offset: state.limit * (eventKey - 1)
      };
    },
    () => {
      this.props.fetchAllDocuments(this.getParams());
    });
  };

  handleFieldChange = (field, event) => {
    if (field === 'document_id') {
      this.setState({
        spider_name: '',
        category: '',
        provenance: '',
        agency_id: '',
        topic_id: ''
      });
    } else {
      this.setState({ document_id: '' });
    }
    const new_state = {};
    new_state[field] = event.target.value;
    new_state.showAlert = false;
    this.setState(new_state);
  };

  handleSelectChange = (field, val) => {
    const new_state = {};
    new_state.showAlert = false;
    if (field === 'recent_time') {
      new_state.from_date = null;
      new_state.to_date = null;
    }
    new_state[field] = val;
    new_state.document_id = '';
    this.setState(new_state);
  };

  handleAgencyChange = (field, val) => {
    this.setState({ [field]: val });
  };

  handleFilter = () => {
    if (this.state.document_id) {
      if (!/^\d+$/.test(this.state.document_id)) {
        this.setState({ showAlert: true });
        return;
      }
      this.setState(state => {
        return {
          offset: 0,
          page: 1,
          filtered_by_id: true,
          filtered_document_id: state.document_id
        };
      },
      () => {
        this.props.fetchFullDocuments({ id: this.state.document_id });
      });
    } else {
      this.setState(
        {
          offset: 0,
          page: 1,
          filtered_by_id: false,
          filtered_document_id: ''
        },
        () => {
          this.props.fetchAllDocuments(this.getParams());
        }
      );
    }
  };

  openModal = id => {
    this.setState({ showModal: true, openedDocumentId: id });
  };

  close = () => {
    this.setState({ showModal: false });
  };

  updateDocumentsResult = () => {
    if (this.state.filtered_by_id) {
      this.props.fetchFullDocuments({ id: this.state.document_id });
    } else {
      this.setState({ scrollTop: this.refs.main_container.scrollTop });
      this.props.fetchAllDocuments(this.getParams()).then(() => {
        if (this.refs.main_container) {
          this.refs.main_container.scrollTop = this.state.scrollTop;
        }
      });
    }
  };

  isDocumentNotFound = () => {
    const errors = this.props.errors || {};
    if (
      errors.document_summary
      && errors.document_summary.length > 0
      && errors.document_summary[0].responseURL
      && errors.document_summary[0].responseURL.includes('/' + this.state.filtered_document_id + '?')
    ) {
      return true;
    }
    return false;
  };

  handleFromDateChange = date => {
    this.setState({ from_date: date, recent_time: null });
  };

  handleToDateChange = date => {
    this.setState({ to_date: date, recent_time: null });
  };

  handleDateFieldChange = (changedfieldname, event) => {
    const new_state = {};
    new_state[changedfieldname] = event.target.value;
    this.setState(new_state);
  };

  recent_time_options = [
    { value: '24', label: '24 hours' },
    { value: '48', label: '48 hours' },
    { value: '72', label: '72 hours' }
  ];

  render() {
    if (
      !this.props.all_documents.isReady
      || !this.props.spider_names.isReady
      || !this.props.provenances.isReady
      || !this.props.sources.isReady
      || !this.props.agencies.isReady
      //|| !this.props.all_agencies_admin_tool.isReady
    ) {
      return null;
    }
    const { haveEditAccess } = this.props;
    const allCategories = this.props.categories.items;
    const categories_options = [];
    allCategories.forEach((category, i) => {
      categories_options.push({
        value: category.app_category,
        label: category.app_category
      });
    });

    const allSpiderNames = this.props.spider_names.items.spider_names;
    const spider_names_options = [];
    allSpiderNames.forEach((spider_name, i) => {
      spider_names_options.push({ value: spider_name, label: spider_name });
    });

    const allProvenances = this.props.provenances.items.provenances;
    const provenance_options = [];
    allProvenances.forEach((provenance, i) => {
      provenance_options.push({ value: provenance, label: provenance });
    });

    const allAgencies = this.props.all_agencies_admin_tool.items;
    const agencies_options = [];
    allAgencies.forEach((agency, i) => {
      agencies_options.push({
        value: agency.id,
        label: agency.short_name ? agency.short_name : agency.name
      });
    });

    let documents = [];
    let count = 1;

    const documentNotFound = this.isDocumentNotFound();
    if (this.state.filtered_by_id) {
      if (documentNotFound) {
        //show empty search result if document is not found in index
        count = 0;
      } else {
        if (
          !this.props.documents_full.isReady
          || !this.props.documents_full.ids[this.state.filtered_document_id]
        ) {
          return null;
        }
        const doc = this.props.documents_full.ids[this.state.filtered_document_id];
        documents.push(doc);
      }
    } else {
      documents = this.props.all_documents.items.documents;
      count = this.props.all_documents.items.count;
    }

    const get_agencies = document => {
      const agency_list = [];
      if (document.agencies) {
        for (const agency of document.agencies) {
          agency_list.push(agency.short_name);
        }
      }
      return agency_list.join(', ');
    };

    const total_pages = Math.ceil(count / this.state.limit);
    // Limit number of displayed documents to 10000 (400 pages with 25 per page).
    // Otherwise elesticsearch will return error "Result window is too large".
    const max_buttons = total_pages > 400 ? 400 : total_pages;
    const rows = [];
    const getStatus = flagged_object => {
      if (_.isNull(flagged_object) || flagged_object.status === 'skipped') {
        return 'not flagged';
      }
      return flagged_object.status;
    };

    documents.forEach((document, i) => {
      rows.push(
        <tr key={i}>
          <td className="document-data">
            <p className="uppercase">
              {document.id + ' | ' + getStatus(document.flagged) + ' '}
            </p>
            <p>
              <b>Title: </b>
              {document.title}
            </p>
            <p>
              <b>Summary Text: </b>
              {document.summary_text}
            </p>
            <p>
              <b>Category: </b>
              {document.category}
            </p>
            <p>
              <b>Spider Name: </b>
              {document.spider_name}
            </p>
            <p>
              <b>Provenance: </b>
              {document.provenance}
            </p>
            <p>
              <b>Agencies: </b>
              {get_agencies(document)}
            </p>
            <p>
              <b>Topics: </b>
              {document.topics
                .map(
                  topic => topic.name
                    + '('
                    + [topic.judge_count, topic.positive_judgments, topic.model_probability].join(
                      ', '
                    )
                    + ')'
                )
                .join(', ')}
            </p>
            <p>
              <b>Web url: </b>
              <a href={document.web_url} target="_blank" rel="noopener noreferrer">
                {document.web_url}
              </a>
            </p>
            <p>
              <b>Pdf url: </b>
              <a href={document.pdf_url} target="_blank" rel="noopener noreferrer">
                {document.pdf_url}
              </a>
            </p>
            <p>
              <b>Publication date: </b>
              {moment(document.publication_date).format('YYYY-MM-DD')}
            </p>
            <p>
              <b>Created at date: </b>
              {moment(document.created_at).format('YYYY-MM-DD')}
            </p>
          </td>
          <td>
            {haveEditAccess && (
            <Button
              bsStyle="primary"
              onClick={() => this.openModal(documents[i].id)}
              disabled={document.flagged && document.flagged.status === 'flagged'}
            >
              Flag
            </Button>
            )}
          </td>
        </tr>
      );
    });
    return (
      <div ref="main_container" className="main-container">
        <h1>Document Admin Tool</h1>
        <Form horizontal className="filter-container documentAdminTool">
          <FormGroup>
            <Col sm={4}>
              <ControlLabel>{'Date Type'}</ControlLabel>
              <div className="volume-stat-radio-btn">
                <Radio
                  name="date_type"
                  value="created_at"
                  inline
                  checked={this.state.date_type === 'created_at'}
                  onChange={e => this.handleDateFieldChange('date_type', e)}
                >
                  Created
                </Radio>
                <Radio
                  name="date_type"
                  value="publication_date"
                  inline
                  checked={this.state.date_type === 'publication_date'}
                  onChange={e => this.handleDateFieldChange('date_type', e)}
                >
                  Publication
                </Radio>
              </div>

              <ControlLabel>Filter by most recent</ControlLabel>
              <Select
                options={this.recent_time_options}
                value={this.state.recent_time}
                onChange={obj => this.handleSelectChange('recent_time', obj.value)}
              />
              <ControlLabel>{'Select range'}</ControlLabel>
              <div className="">
                <DatePicker
                  className="date-picker-doc-tool"
                  placeholderText="From"
                  selected={this.state.from_date}
                  onChange={this.handleFromDateChange}
                  isClearable
                />
                <DatePicker
                  className="date-picker-doc-tool"
                  placeholderText="To"
                  selected={this.state.to_date}
                  onChange={this.handleToDateChange}
                  isClearable
                />
              </div>
            </Col>
            <Col sm={4}>
              <ControlLabel>Spider Name</ControlLabel>
              <Select
                options={spider_names_options}
                value={this.state.spider_name}
                onChange={obj => this.handleSelectChange('spider_name', obj.value)}
              />
              <ControlLabel>Category</ControlLabel>
              <Select
                options={categories_options}
                value={this.state.category}
                onChange={obj => this.handleSelectChange('category', obj.value)}
              />
              <ControlLabel>Provenance</ControlLabel>
              <Select
                options={provenance_options}
                value={this.state.provenance}
                onChange={obj => this.handleSelectChange('provenance', obj.value)}
              />
              <ControlLabel>Agencies</ControlLabel>
              <Select
                options={agencies_options}
                value={this.state.agency_id}
                multi
                onChange={objs => this.handleAgencyChange('agency_id', objs.map(obj => obj.value).join(','))}
                disabled={this.state.hidden_documents_view}
              />
              <ControlLabel>Topics</ControlLabel>
              <Select
                options={this.props.sources.sources.activeTopics.map(topic => ({
                  value: topic.id,
                  label: topic.name
                }))}
                value={this.state.topic_id}
                multi
                onChange={objs => this.handleSelectChange('topic_id', objs.map(obj => obj.value))}
              />
            </Col>
            <Col sm={4}>
              <ControlLabel>Document ID</ControlLabel>
              <FormControl
                type="text"
                value={this.state.document_id}
                onChange={e => this.handleFieldChange('document_id', e)}
              />
              <FormGroup controlId="formHorizontalSearchType" className="topMarginBlock">
                <Col componentClass={ControlLabel} sm={5} className="textAlignLeft">
                  Search by string
                </Col>
                <Col sm={7}>
                  <Select
                    options={searchingByStringOptions}
                    value={this.state.searchByStringType}
                    onChange={obj => this.handleSelectChange('searchByStringType', obj && obj.value)}
                  />
                </Col>
              </FormGroup>
              <FormControl
                type="text"
                placeholder="Enter string"
                value={this.state.searchString}
                onChange={e => this.handleFieldChange('searchString', e)}
              />
            </Col>
          </FormGroup>
          <Button bsStyle="primary" bsSize="xsmall" onClick={this.handleFilter}>
            Filter
          </Button>
          <div className={this.state.showAlert ? 'alert alert-danger' : 'hidden'} role="alert">
            Documents ID must be an integer
          </div>
        </Form>
        <Pagination
          bsSize="small"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          items={max_buttons}
          maxButtons={5}
          activePage={this.state.page}
          onSelect={this.handlePageChange}
        />
        <Table className="documents-table" striped condensed hover>
          <tbody>{rows}</tbody>
        </Table>
        <Pagination
          bsSize="small"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          items={max_buttons}
          maxButtons={5}
          activePage={this.state.page}
          onSelect={this.handlePageChange}
        />
        <DocumentModal
          close={this.close}
          document_id={this.state.openedDocumentId}
          showModal={this.state.showModal}
          updateDocumentsResult={this.updateDocumentsResult}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAllDocuments: params => {
      return dispatch(fetchAllDocuments(params));
    },
    getCategories: () => {
      dispatch(getCategories());
    },
    fetchAllSpiderNames: () => {
      dispatch(fetchAllSpiderNames());
    },
    fetchAllProvenances: () => {
      dispatch(fetchAllProvenances());
    },
    fetchFullDocuments: id => {
      dispatch(fetchFullDocuments(id));
    },
    fetchAgencies: following => {
      dispatch(fetchAgencies());
    },
    getAgencies: params => {
      dispatch(getAgencies(params));
    }
  };
};

const mapStateToProps = state => {
  return {
    all_documents: state.all_documents,
    categories: state.documentCategories,
    spider_names: state.spider_names,
    provenances: state.provenances,
    documents_full: state.documents_full,
    sources: state.sources,
    errors: state.errors,
    agencies: state.agencies,
    all_agencies_admin_tool: state.agencyData,
    viewer: state.current_user
  };
};

const ReduxAdminTool = withAdminPermissions(connect(mapStateToProps, mapDispatchToProps)(AdminTool));

export default ReduxAdminTool;
