import type { CategoryHandlers } from './useScrapedCategoriesHandlers.types';
import type { ScrapedCategoriesTableConfig } from './useScrapedCategoriesTableConfig.types';
import type { TableCategory } from '../ScrapedCategories.types';

import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';

export const useScrapedCategoriesTableConfig = (): ScrapedCategoriesTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'ID',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.ID,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.ID,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Scraped Category',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.NAME,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.NAME,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Jurisdiction',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.JURISDICTION,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Cai Category',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.DOCUMENT_CAI_CATEGORY,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.DOCUMENT_CAI_CATEGORY,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Meta Category',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.META_CATEGORY,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.META_CATEGORY,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Created At',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Updated At',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Agencies',
      dataKey: categoryConstants.CATEGORY_ATTRIBUTES.AGENCIES,
      sortKey: categoryConstants.CATEGORY_ATTRIBUTES.AGENCIES,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    }
  ];
  return {
    columns
  };
};
