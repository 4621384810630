import type { Row } from '@compliance.ai/web-components';
import type { DryRunReportsTableProps } from '../DryRunReportsTable.types';

import * as constants from '../DryRunReportsTable.constants';

export const useDryRunReportsTableRows = ({
  props
}: {
  props: Pick<DryRunReportsTableProps, 'reports'>;
}): Row[] => {
  return props.reports.map((report, reportIndex) => ({
    ...report,
    [constants.TABLE_KEYS.INDEX]: reportIndex + 1
  }));
};
