import type { FC } from 'react';
import type { StatusCellProps } from './StatusCell.types';

import { DRY_RUN_STATUS_TITLES } from '../../DryRunReportsTable.constants';

import * as uiLib from '@compliance.ai/web-components';

import { useStatusCellData } from './hooks';

export const StatusCell: FC<StatusCellProps> = ({ cellValue }) => {
  const { formattedData } = useStatusCellData({ cellValue });

  return (
    <uiLib.Typography
      type={uiLib.TYPOGRAPHY_TYPES.BODY1}
      status={formattedData.typographyStatus}
      isBold
    >
      {DRY_RUN_STATUS_TITLES[formattedData.reportStatus]}
    </uiLib.Typography>
  );
};
