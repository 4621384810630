import { reducer as authReducer } from './auth/auth.reducer';
import { REDUCER_NAME as AUTH_REDUCER_NAME } from './auth/auth.constants';
import documentFileLinkReducer from './documentFileLink/documentFileLink.reducer';
import { REDUCER_NAME as DOCUMENT_FILE_LINK } from './documentFileLink/documentFileLink.constants';
import { agencies, agencies_admin, all_agencies_admin_tool } from './agencies/agencies.reducer';
import { errors } from './error/reducer';
import { subscriptions, all_subscriptions } from './subscriptions/subscriptions.reducer';
import { provenances } from './provenances/provenances.reducer';
import { regulations } from './regulations/regulations.reducer';
import { slot_tool_doc_info } from './slot/slot.reducer';
import {
  all_statistics,
  spider_names,
  google_analytics,
  marketing,
  last_run_report
} from './analytics/analytics.reducer';
import { all_plans } from './plans/plans.reducer';
import { acts } from './acts/acts.reducer';
import { autocompletes, search_queries } from './search/search.reducer';
import { banks } from './banks/banks.reducer';
import { dashboard } from './dashboard/dashboard.reducer';
import jurisdictionsData from './jurisdictionsData/jurisdictionsData.reducer';
import { jurisdictionAnalyzerConcepts } from './jurisdictionAnalyzerConcepts/jurisdictionAnalyzerConcept.reducer';
import { metrics } from './metrics/metric.reducer';
import { premiumContent } from './premiumContent/premiumContent.reducer';
import { filters } from './filters/filters.reducer';
import { commands, command_requests } from './commands/commands.reducer';
import { user_folder } from './folders/folders.reducer';
import { tags } from './tags/tags.reducer';
import caiCategories from './categories/caiCategories/caiCategories.reducer';
import scrapedCategories from './categories/scrapedCategories/scrapedCategories.reducer';
import documentCategories from './categories/categories/categories.reducer';
import documentMetaCategories from './categories/metaCategories/metaCategories.reducer';
import { organization, teams, all_organizations } from './organizations/organizations.reducer';
import {
  topics,
  all_topics,
  topics_stats,
  topic_annotation_model_validations
} from './topics/topics.reducer';
import { all_publications, publication } from './publication/publication.reducer';
import { popular_sources, sources } from './sources/sources.reducer';
import newsSources from './sources/newsSources/newsSources.reducer';
import glassdoorData from './glassdoor/glassdoor.reducer';
import externalApiCrawl from './externalApiCrawl/externalApiCrawl.reducer';
import { current_view } from './view/view.reducer';
import {
  annotation_task,
  all_annotation_tasks,
  annotation_task_topic_groups,
  annotation_jobs,
  annotations_for_job,
  all_annotation_jobs,
  all_skipped_annotations,
  onboarding_results,
  aggregated_annotations,
  onboarding_user_detail
} from './annotations/annotations.reducer';
import { widgets } from './widgets/widgets.reducer';
import { expertConnection } from './expertConnection/expertConnection.reducer';
import { insights_graphs, insights_csv, enforcementsData } from './insights/insights.reducer';
import { term_sampling_groups, term_sampling_group } from './term/term.reducer';
import {
  all_documents,
  create_document,
  current_document,
  docTypes,
  document_details,
  documents,
  documents_full,
  enforcements,
  flagged_document,
  googleSearchResults,
  hidden_documents,
  incomplete_documents,
  recent_documents,
  search_results_relevance,
  updated_document,
  us_state,
  popular_docs,
  new_document_url_headers,
  recent_activity
} from './documents/documents.reducer';
import {
  current_user,
  all_users,
  specified_user,
  user_vote,
  user_created_documents
} from './user/user.reducer';
import { docTasksByStatus, docTasksByDate } from './docTasks/docTasks.reducers';
import { teamTaskFilters } from './teamTasks/teamTasks.reducers';
import { contributor_points, contributor_reviews } from './contributor/contributor.reducer';
import { spiderRunReportsCrawlerStatistic } from './spiderRunReports/spiderRunReports.reducer';
import snackbar from './snackbar/snackbar.reducer';
import crawlerComments from './crawlerSettings/crawlerComments/crawlerComments.reducer';
import crawlerHistory from './crawlerSettings/crawlerHistory/crawlerHistory.reducer';
import crawlerDocuments from './crawlerSettings/crawlerDocuments/crawlerDocuments.reducer';
import approachingDeadlinesWidget from './widgets/ApproachingDeadlines/approachingDeadlines.reducer';
import userModal from './userModal/userModal.reducer';
import all_roles from './userRoles/userRoles.reducer';
import all_payment_types from './paymentTypes/paymentTypes.reducer';
import all_industries from './industries/industries.reducer';
import crawlerActivity from './crawlerSettings/crawlerActivity/crawlerActivity.reducer';
import documentData from './documentData/documentData.reducer';
import agencyData from './agencyData/agencyData.reducer';
import reportDocuments from './crawlerSettings/reportDocuments/reportDocuments.reducer';
import enforcementActionsWidget from './widgets/EnforcementWidgets/enforcementActions.reducer';
import concepts from './concepts/concepts.reducer';
import { thresholds } from './thresholds/thresholds.reducers';
import { collections } from './collection/collection.reducer';
import { sentenceModel } from './sentenceModel/sentenceModel.reducer';
import { resourcesNav, resourcesHeadDocs } from './resources/resources.reducers';
import {
  reducerName as documentRelationsReducerName,
  reducer as documentRelationsReducer
} from './documentRelations/documentRelations.reducer';
import {
  REDUCER_NAME as SPIDER_RUN_REPORTS_REDUCER_NAME,
  reducer as spiderRunReportsReducer
} from './crawlerSettings/spiderRunReports/spiderRunReports.reducer';

export default {
  [AUTH_REDUCER_NAME]: authReducer,
  [DOCUMENT_FILE_LINK]: documentFileLinkReducer,
  [documentRelationsReducerName]: documentRelationsReducer,
  [SPIDER_RUN_REPORTS_REDUCER_NAME]: spiderRunReportsReducer,
  acts,
  agencies_admin,
  agencies,
  aggregated_annotations,
  all_agencies_admin_tool,
  all_annotation_jobs,
  all_annotation_tasks,
  all_documents,
  all_industries,
  all_organizations,
  all_payment_types,
  all_plans,
  all_publications,
  all_roles,
  all_skipped_annotations,
  all_statistics,
  all_subscriptions,
  all_topics,
  all_users,
  annotation_jobs,
  annotation_task_topic_groups,
  annotation_task,
  annotations_for_job,
  approachingDeadlinesWidget,
  autocompletes,
  banks,
  caiCategories,
  scrapedCategories,
  documentCategories,
  documentData,
  agencyData,
  documentMetaCategories,
  command_requests,
  commands,
  concepts,
  collections,
  contributor_points,
  contributor_reviews,
  crawlerComments,
  crawlerHistory,
  crawlerDocuments,
  crawlerActivity,
  create_document,
  current_document,
  current_user,
  current_view,
  dashboard,
  docTasksByDate,
  docTasksByStatus,
  docTypes,
  document_details,
  documents_full,
  documents,
  enforcements,
  enforcementsData,
  enforcementActionsWidget,
  errors,
  expertConnection,
  externalApiCrawl,
  filters,
  flagged_document,
  google_analytics,
  googleSearchResults,
  glassdoorData,
  hidden_documents,
  incomplete_documents,
  insights_csv,
  insights_graphs,
  jurisdictionAnalyzerConcepts,
  jurisdictionsData,
  last_run_report,
  marketing,
  metrics,
  newsSources,
  new_document_url_headers,
  onboarding_results,
  onboarding_user_detail,
  organization,
  popular_docs,
  popular_sources,
  premiumContent,
  provenances,
  publication,
  recent_activity,
  recent_documents,
  regulations,
  reportDocuments,
  resourcesHeadDocs,
  resourcesNav,
  search_queries,
  search_results_relevance,
  sentenceModel,
  slot_tool_doc_info,
  snackbar,
  sources,
  specified_user,
  spider_names,
  spiderRunReportsCrawlerStatistic,
  subscriptions,
  tags,
  teams,
  teamTaskFilters,
  term_sampling_group,
  term_sampling_groups,
  thresholds,
  topic_annotation_model_validations,
  topics_stats,
  topics,
  updated_document,
  user_created_documents,
  user_folder,
  user_vote,
  us_state,
  widgets,
  userModal
};
