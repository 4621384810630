import { useMemo } from "react";
import _ from 'lodash';
import { TABLE_VARIABLES } from "./DocumentMetaCategories.constants";
import { NOTIFICATION_TYPES, setNotification } from "../../components/Notification";

// ----- Format Info For Table -----

const getAppCategories = metaCategory => {
  const appCategories = _.get(metaCategory, 'document_categories', []);
  return appCategories.map(item => item.app_category);
};

const getMetaCategoryInfo = metaCategory => {
  return {
    [TABLE_VARIABLES.id]: _.get(metaCategory, 'id', null),
    [TABLE_VARIABLES.name]: _.get(metaCategory, 'name', ''),
    [TABLE_VARIABLES.description]: _.get(metaCategory, 'description', ''),
    [TABLE_VARIABLES.app_filter]: _.get(metaCategory, 'app_filter', false),
    [TABLE_VARIABLES.document_categories]: getAppCategories(metaCategory)
  };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const formatDocMetaCategories = metaCategories => useMemo(
  () => metaCategories.map(getMetaCategoryInfo), [metaCategories]
);

// ----- Format Info For Edit / Create -----

export const getFormatMetaCategoryInfo = metaCategory => {
  return {
    [TABLE_VARIABLES.id]: _.get(metaCategory, 'id', null),
    [TABLE_VARIABLES.name]: _.get(metaCategory, 'name', ''),
    [TABLE_VARIABLES.description]: _.get(metaCategory, 'description', ""),
    [TABLE_VARIABLES.app_filter]: _.get(metaCategory, 'app_filter', false),
    [TABLE_VARIABLES.surface_in_filter]: _.get(metaCategory, 'surface_in_filter', false),
    [TABLE_VARIABLES.indexed]: _.get(metaCategory, 'indexed', false)
  };
};

export const fromMetaCategoryToRawInfo = (newInfo, oldInfo) => {
  const metaCategory = {};
  const errors = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath, oldPath, superPath, predefinedValue, checkFunction
  }) => {
    const valueNew = predefinedValue || _.get(newInfo, newPath, null);
    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(metaCategory, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew);
        if (result) errors.push({ text: result, title: newPath[0] });
      }
    }
  };

  setDifference({
    newPath: [TABLE_VARIABLES.name],
    oldPath: ['name']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.description],
    oldPath: ['description']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.app_filter],
    oldPath: ['app_filter']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.surface_in_filter],
    oldPath: ['surface_in_filter']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.surface_category],
    oldPath: ['surface_category']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.indexed],
    oldPath: ['indexed']
  });

  if (errors.length) {
    errors.forEach(
      error => setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR, width: 800 })
    );
    return null;
  }

  return metaCategory;
};

// export only for tests
export { getMetaCategoryInfo };
