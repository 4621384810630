import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_labeled-field.scss';

export const LabeledField = ({
  label,
  className,
  isInline,
  isLabelUnderElement,
  isRightAligned,
  isCapitalized,
  children
}) => {
  return (
    <div
      className={classNames(className, {
        'labeled-field': true,
        'labeled-field--inline': isInline
      })}
    >
      {isLabelUnderElement && children}
      <span
        className={classNames({
          'labeled-field__label': true,
          'labeled-field__label--is-capitalized': isCapitalized,
          'labeled-field__label--is-right-aligned': isRightAligned,
          'labeled-field__label--is-label-under-element': isLabelUnderElement
        })}
      >
        {label}
      </span>
      {!isLabelUnderElement && children}
    </div>
  );
};

LabeledField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  isInline: PropTypes.bool,
  isRightAligned: PropTypes.bool,
  isLabelUnderElement: PropTypes.bool,
  isCapitalized: PropTypes.bool
};

LabeledField.defaultProps = {
  label: '',
  children: null,
  isInline: false,
  isRightAligned: false,
  isLabelUnderElement: false,
  isCapitalized: false
};
