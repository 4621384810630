import { buildUrlFromParams } from '../documents/documents.helper';

const getFilterValues = (filter) => {
  if (!filter) return [];
  filter = Array.isArray(filter) ? filter : [filter];
  return filter.map(option => {
    if (!option) return null;
    if (option.value) return option.value;
    return option;
  });
};

export const SET_PRIMARY_FILTER = 'SET_PRIMARY_FILTER';
export const SET_ENFORCEMENT_FILTER = 'SET_ENFORCEMENT_FILTER';
export const SET_JURISDICTION_FILTER = 'SET_JURISDICTION_FILTER';

export const setPrimaryFilter = ({ filterParams }) => dispatch => {
  const filterUrl = buildUrlFromParams(filterParams);
  const filterCriteria = { filterUrl, filterParams };
  dispatch({ type: SET_PRIMARY_FILTER, payload: filterCriteria });
};

export const setEnforcementFilter = ({ filterParams }) => dispatch => {
  const filterUrl = buildUrlFromParams(filterParams);
  const filterCriteria = { filterUrl, filterParams };
  dispatch({ type: SET_ENFORCEMENT_FILTER, payload: filterCriteria });
};

export const setJurisdictionFilter = ({ filterParams, defaultFilters }) => dispatch => {
  filterParams = {
    ...filterParams,
    jurisdiction: getFilterValues(filterParams.jurisdiction),
    topic_id: getFilterValues(filterParams.topic_id),
    category: getFilterValues(filterParams.category),
    regulation_id: getFilterValues(filterParams.regulation_id),
    act_id: getFilterValues(filterParams.act_id)
  };

  const filterCriteria = { filterParams, defaultFilters };
  dispatch({ type: SET_JURISDICTION_FILTER, payload: filterCriteria });
};
