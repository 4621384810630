import type { EitlLabelsImportModalData } from './useEitlLabelsImportModalData.types';
import type { EitlLabelsImportModalHandlers } from './useEitlLabelsImportModalHandlers.types';

export const useEitlLabelsImportModalHandlers = ({
  localActions
}: {
  localActions: EitlLabelsImportModalData['localActions'];
}): EitlLabelsImportModalHandlers => {
  const handleSelectFiles: EitlLabelsImportModalHandlers['handleSelectFiles'] = files => {
    const formattedFiles = files.map(file => ({
      file,
      userFileName: file.name
    }));

    localActions.setAttachments(formattedFiles);
  };

  return {
    handleSelectFiles
  };
};
