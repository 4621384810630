export enum DOCUMENT_EVENT_ATTRIBUTE {
  ID = 'id',
  CREATED_AT = 'created_at',
  DETAILS = 'details',
  DOC_ATTRIBUTE_NAME = 'doc_attribute_name',
  DOC_ID = 'doc_id',
  EVENT_TYPE = 'event_type',
  NEW_VALUE = 'new_value',
  NOTES = 'notes',
  PREV_VALUE = 'prev_value',
  PROCESS = 'process',
  UPDATED_AT = 'updated_at'
}
