import React, { useState } from "react";
import PropTypes from 'prop-types';
import CustomTable from "../../../../components/Table";
import { formatAgenciesFormat } from "../Sources.helper";
import sourcesTableBodyCells from "./bodyCells";
import sourcesTableHeaderCells from "./headerCells";
import SettingsModal from "./SettingModal";

const SourcesTable = ({
  agencies,
  load,
  sort,
  activeInfo,
  changeFilter,
  setActiveInfo,
  navigateAgencyDetails
}) => {
  const [openSettings, setOpenSettings] = useState(false);
  const agenciesFormat = formatAgenciesFormat(agencies);

  return (
    <>
      <CustomTable
        topFixedColumns={1}
        leftFixedColumns={3}
        className="agency-table"
        onClick={navigateAgencyDetails}
        header={sourcesTableHeaderCells({
          sort,
          activeInfo,
          changeFilter,
          openSettings: () => setOpenSettings(true)
        })}
        data={sourcesTableBodyCells({
          agenciesFormat,
          activeInfo
        })}
        load={load}
      />
      <SettingsModal
        show={openSettings}
        onHide={() => setOpenSettings(false)}
        activeColumns={activeInfo}
        setActiveInfo={setActiveInfo}
      />
    </>
  );
};

export default SourcesTable;

SourcesTable.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  navigateAgencyDetails: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      sorting_priority: PropTypes.number,
      sorting_direction: PropTypes.string
    })
  }),
  activeInfo: PropTypes.arrayOf(PropTypes.string),
  load: PropTypes.bool
};

SourcesTable.defaultProps = {
  agencies: [],
  sort: {},
  activeInfo: [],
  load: false
};
