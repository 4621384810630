import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './crawlerComments.constants';

export const initialState = {
  comments: []
};

const crawlerCommentsReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setCommentsArray: (state, action) => {
      state.comments = action.payload.comments;
    },
    addCommentToArray: (state, action) => {
      state.comments = [action.payload.comment, ...state.comments];
    }
  }
});

export default crawlerCommentsReducer.reducer;
export const { setCommentsArray, addCommentToArray } = crawlerCommentsReducer.actions;
