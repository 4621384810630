import ROUTES from 'admin/constants/routes';
import { useAuthReduxActions } from 'shared/features/auth/hooks';

const handleReloadWithoutCache = e => {
  if (e.persisted) {
    window.removeEventListener('pageshow', handleReloadWithoutCache, false);
    window.location.reload();
  }
};

export const useAppHeaderHandlers = () => {
  const authReduxActions = useAuthReduxActions();

  const handleLogOut = async () => {
    try {
      await authReduxActions.signOut();

      /**
       * For Safari. Sometime Safari caches the page and it prevents a proper user logout.
       */
      window.addEventListener('pageshow', handleReloadWithoutCache, false);

      window.location.href = ROUTES.login;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    handleLogOut
  };
};
