import styled from "styled-components";
import { Button as MaterialButton } from "@material-ui/core";

const StyledMaterialButton = styled(MaterialButton)`
  && {
    box-shadow: none;
    background-color: ${(props) => (props.buttontype === "primary" ? "#068BFF" : "white")};
    text-transform: none;
    letter-spacing: 0px;
    color: ${(props) => (props.buttontype === "primary" ? "white" : "#068BFF")};
    font-size: 14px;
    border-radius: 0px;
    height: 38px;
    line-height: 38px;
    padding: 0px 23px;

    border: ${(props) => (props.buttontype === "primary" ? "none" : "solid 1px #068BFF")};

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: ${(props) => (props.buttontype === "primary" ? "#056FCC" : "#F2F9FF")};
      border: ${(props) => (props.buttontype === "primary" ? "none" : "solid 1px #056FCC")};
      color: ${(props) => (props.buttontype === "primary" ? "white" : "#056FCC")};
    }

    &[disabled=true] {
      background-color: ${(props) => (props.buttontype === "primary" ? "#BBBBBB" : "white")};
      color: ${(props) => (props.buttontype === "primary" ? "white" : "#BBBBBB")};
    }
  }
`;

export default StyledMaterialButton;
