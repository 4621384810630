export enum SCRAPED_CATEGORIES_DATA_FETCH_QUERY_PARAMS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  ID = 'id',
  NAME = 'name',
  DOC_CAI_CATEGORY_ID = 'doc_cai_category_id',
  JURISDICTION_ID = 'jurisdiction_id',
  SCRAPED_CATEGORY_IDS = 'scraped_category_ids',
  SURFACE_DOCS = 'surface_docs',
  GLASSDOOR = 'glassdoor',
  GET_ALL = 'get_all',
  MULTI_INPUT = 'multi_input',
  AGENCY_IDS = 'agency_ids',
  JURISDICTION_IDS = 'jurisdiction_ids',
  CAI_CATEGORY_IDS = 'cai_category_ids',
  CREATED_AT_START = 'created_at_start',
  CREATED_AT_END = 'created_at_end',
  UPDATED_AT_START = 'updated_at_start',
  UPDATED_AT_END = 'updated_at_end'
}
