import _ from 'lodash';
import { DATE_FORMAT, LINK_INFO, REQUEST_ERROR } from './CrawlerCatalog.constants';
import { createCommandRequest } from '../../../utils/commandRequestHelper';
import { formatDate } from '@compliance.ai/web-components';
import { LOG_TYPES } from 'shared/features/spiderRunReports/spiderRunReports.constants';

export const formatDateHelper = date =>
  formatDate(date, {
    format: DATE_FORMAT,
    shouldKeepLocalTime: false
  });

export const createDateDescription = (date, keyWord) =>
  date
    ? `Crawl ${keyWord} successfully on ${formatDateHelper(date)}`
    : `Crawl has not been ${keyWord} yet.`;

export const createNewCommandRequest = ({
  crawlDetailsInfo,
  commands,
  current_user,
  commandName,
  extraParams
}) => {
  const crawlSpiderName = crawlDetailsInfo.spider_name;
  // error check block
  let error = null;
  if (!crawlSpiderName) error = REQUEST_ERROR.spiderNameMissed;
  if (error) return { error };

  return createCommandRequest({
    name: `${commandName}: ${crawlSpiderName}`,
    parameters: {
      ...extraParams,
      spider_name: crawlSpiderName,
      crawl_id: crawlDetailsInfo.id
    },
    commands,
    current_user,
    commandRequestName: commandName
  });
};

export const collectSkippedDocs = skippedDocs => {
  const arrayOfDocs = [];
  _.forOwn(skippedDocs, (docs, reason) => {
    _.forOwn(docs, (doc, date) => {
      if (date !== 'total') {
        arrayOfDocs.push({
          reason,
          status: 'skipped',
          publication_date: date,
          id: _.get(doc, 'official_id'),
          title: _.get(doc, 'title'),
          pdf_url: _.get(doc, 'pdf_url'),
          web_url: _.get(doc, 'web_url'),
          category: _.get(doc, 'category'),
          meta_table: _.get(doc, 'meta_table'),
          api_table: _.get(doc, 'api_table')
        });
      }
    });
  });
  return arrayOfDocs;
};

export const collectErrorDocs = errorDocs => {
  const arrayOfDocs = [];
  _.forOwn(errorDocs, (doc, date) => {
    const message = _.get(doc, 'message');
    const type = _.get(doc, 'type');
    if (message && type) {
      const isMessageObject = _.isObject(message);
      arrayOfDocs.push({
        reason: `${isMessageObject ? _.get(message, 'step_name') : message}, ${type}`,
        expandableInfo: isMessageObject ? _.get(message, 'results') : null,
        status: type || 'error',
        publication_date: date,
        title: _.get(doc, 'title'),
        pdf_url: _.get(doc, 'pdf_url'),
        web_url: _.get(doc, 'web_url'),
        category: _.get(doc, 'category'),
        meta_table: _.get(doc, 'meta_table'),
        api_table: _.get(doc, 'api_table')
      });
    } else if ([LOG_TYPES.VIDEO_LOG, LOG_TYPES.TEXT_LOG, LOG_TYPES.TEXT_METRICS].includes(date)) {
      arrayOfDocs.push({
        reason: doc,
        status: 'info',
        publication_date: null,
        official_id: date,
        ...LINK_INFO[date]
      });
    }
  });
  return arrayOfDocs;
};

export const concatIngestedDocsRelation = dryRun => {
  const docs = dryRun?.docs ?? [];
  const docsRelations = Array.isArray(dryRun?.docs_relations) ? dryRun?.docs_relations : [];

  const relation = docsRelations.reduce(
    (acc, rel) => ({
      ...acc,
      [rel?.doc_id]: [
        ...(acc?.[rel?.doc_id] ?? []),
        {
          relatedDocId: rel?.related_doc_id,
          relationType: rel?.relation_type_id,
          relationName: rel?.relation_name
        }
      ]
    }),
    {}
  );

  return docs.map(doc => ({
    ...doc,
    relation: relation[doc.id]
  }));
};
