import React from "react";
import PropTypes from "prop-types";
import DatePicker from 'react-date-picker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import cn from 'classnames';
import Typography from "./Typography";

function CustomDatePicker({
  className,
  labelClassName,
  pickerClassName,
  calendarClassName,
  label,
  value,
  onChange,
  isError,
  isDisabled,
  clearIcon,
  calendarIcon
}) {
  return (
    <label className={cn(
      'date-picker_wrapper',
      className
    )}
    >
      <Typography
        variant="label"
        color="gray"
        bold
        className={labelClassName}
      >
        {label}
      </Typography>
      <DatePicker
        className={cn("date-picker_field", pickerClassName)}
        calendarClassName={cn("date-picker_calendar", calendarClassName)}
        onChange={onChange}
        disabled={isDisabled}
        value={value}
        calendarIcon={calendarIcon || (<CalendarTodayIcon />)}
        clearIcon={clearIcon}
      />
    </label>
  );
}


CustomDatePicker.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  pickerClassName: PropTypes.string,
  calendarClassName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

CustomDatePicker.defaultProps = {
  isError: false,
  isDisabled: false,
  className: '',
  labelClassName: '',
  pickerClassName: '',
  calendarClassName: '',
  label: ''
};


export default CustomDatePicker;
