import _ from "lodash";
import {
  REQUEST_ALL_USERS,
  RECEIVE_ALL_USERS,
  REQUEST_CURRENT_USER,
  RECEIVE_CURRENT_USER,
  MODIFY_CURRENT_USER,
  MODIFIED_CURRENT_USER,
  NEW_FEATURE_TIP_HAS_BEEN_READ,
  CLEAR_CURRENT_USER_UPDATED_STATE,
  REQUEST_SPECIFIED_USER,
  RECEIVE_SPECIFIED_USER,
  MODIFY_SPECIFIED_USER,
  MODIFIED_SPECIFIED_USER,
  REQUEST_USER_CREATED_DOCUMENTS,
  RECEIVE_USER_CREATED_DOCUMENTS,
  SET_AUTH,
  CREATE_SEARCH_QUERY,
  SEARCH_RESULT_RATED,
  RECEIVE_UNAUTHENTIATED_CURRENT_USER,
  RECEIVE_UPDATE_DASHBOARD_LAYOUT,
  AUTHORIZE_PREMIUM_CONTENT,
  RECEIVE_APP_NOTIFICATIONS,
  REQUEST_UPDATE_DASHBOARD_LAYOUT,
  RECIEVE_FOLLOWED_CATEGORIES,
  REMOVE_FOLLOWED_CATEGORIES,
  ADD_FOLLOWED_CATEGORIES
} from './user.actions';
import dashboardRoles, { namesOfRoles } from "admin/constants/userRoles";

export const getInitialCurrentUserState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  appNotifications: [],
  user: {
    isAuthenticated: false,
    jwt: '',
    roles: [],
    properties: {},
    dashboard_layouts: [],
    pc_docs_access: {},
    followed_categories: {}
  }
});

export const current_user = (state = getInitialCurrentUserState(), action) => {
  switch (action.type) {
    case REQUEST_CURRENT_USER:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_CURRENT_USER: {
      const dashboardRole = _.head(_.intersection(Object.values(namesOfRoles), action.user.roles));
      let permissions = {};
      if (dashboardRole) {
        permissions = dashboardRoles[dashboardRole].permissions;
      }
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user: { ...state.user, ...action.user, permissions },
        updated: false
      };
    }
    case RECEIVE_UNAUTHENTIATED_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user: state.user,
        updated: false
      };
    case MODIFY_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };

    case NEW_FEATURE_TIP_HAS_BEEN_READ:
      return {
        ...state,
        user: {
          ...state.user,
          properties: {
            ...state.user.properties,
            read_new_feature_tip: {
              ...state.user.properties.read_new_feature_tip,
              [action.featureId]: true
            }
          }
        }
      };

    case MODIFIED_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        user: action.user ? { ...state.user, ...action.user } : state.user
      };
    case CLEAR_CURRENT_USER_UPDATED_STATE:
      return {
        ...state,
        updated: false
      };
    case RECEIVE_APP_NOTIFICATIONS:
      return {
        ...state,
        appNotifications: action.appNotifications
      };
    case SET_AUTH:
      return {
        ...state,
        user: { ...state.user, isAuthenticated: action.isAuthenticated, jwt: action.jwt }
      };
    case REQUEST_UPDATE_DASHBOARD_LAYOUT:
      return {
        ...state,
        isReady: false
      };
    case RECEIVE_UPDATE_DASHBOARD_LAYOUT:
      return {
        ...state,
        isReady: true,
        user: {
          ...state.user,
          // If the dashboard already exists then update it otherwise add it
          dashboard_layouts: ((dashboard_type, layout, components) => {
            let foundLayout = false;
            const layouts = state.user.dashboard_layouts.map(dl => {
              if (dl.dashboard_type === dashboard_type) {
                foundLayout = true;
                return { ...dl, layout, components };
              }

              return dl;
            });

            if (!foundLayout) {
              layouts.push({ dashboard_type, layout, components });
            }

            return layouts;
          })(action.dashboard_type, action.layout, action.components)
        }
      };
    case AUTHORIZE_PREMIUM_CONTENT:
      return {
        ...state,
        user: {
          ...state.user,
          pc_docs_access: action.payload
        }
      };
    case RECIEVE_FOLLOWED_CATEGORIES:
      return {
        ...state,
        user: {
          ...state.user,
          followed_categories: action.payload
        }
      };
    case REMOVE_FOLLOWED_CATEGORIES:
      return {
        ...state,
        user: {
          ...state.user,
          followed_categories: ((categories) => {
            const newCategoriesObject = _.cloneDeep(state.user.followed_categories);
            categories.forEach(category => {
              if (newCategoriesObject[category]) {
                delete newCategoriesObject[category];
              }
            });

            return newCategoriesObject;
          })(action.payload)
        }
      };
    case ADD_FOLLOWED_CATEGORIES:
      return {
        ...state,
        user: {
          ...state.user,
          followed_categories: ((categories) => {
            const newCategoriesObject = _.cloneDeep(state.user.followed_categories);
            categories.forEach(category => {
              newCategoriesObject[category] = true;
            });

            return newCategoriesObject;
          })(action.payload)
        }
      };
    default:
      return state;
  }
};

export const getInitialAllUsersState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  users: []
});

export const all_users = (state = getInitialAllUsersState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_USERS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ALL_USERS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        users: action.users,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialSpecifiedUserState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  user: {}
});

export const specified_user = (state = getInitialSpecifiedUserState(), action) => {
  switch (action.type) {
    case REQUEST_SPECIFIED_USER:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_SPECIFIED_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user: action.user,
        updated: false
      };
    case MODIFY_SPECIFIED_USER:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case MODIFIED_SPECIFIED_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        user: action.user || state.user // error state if action.user is null
      };
    case CLEAR_CURRENT_USER_UPDATED_STATE:
      return {
        ...state,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialUserVoteState = () => ({
  search_args: {},
  voted_docs: {}
});

export const user_vote = (state = getInitialUserVoteState(), action) => {
  switch (action.type) {
    case CREATE_SEARCH_QUERY: {
      return {
        ...state,
        search_args: action.search_args
      };
    }
    case SEARCH_RESULT_RATED: {
      return {
        ...state,
        voted_docs: {
          ...state.voted_docs,
          [action.doc_id]: {
            relevance: action.is_relevant,
            search_args: action.search_args
          }
        }
      };
    }
    default:
      return state;
  }
};

export const getInitialUserCreatedDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  user_created_documents: []
});

export const user_created_documents = (state = getInitialUserCreatedDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_USER_CREATED_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_USER_CREATED_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.user_created_documents,
        updated: false
      };
    default:
      return state;
  }
};
