import moment from 'moment';
import _ from 'lodash';
import { maxFileSizeRunNow } from 'shared/config';
import {
  COMMAND_REQUEST_PARAMETERS,
  CRAWL_ID,
  CRAWL_TYPE,
  SPIDER_NAME,
  RUN_TYPE,
  SPREADSHEET,
  SPREADSHEET_NAME,
  ARCHIVE_FILE,
  ARCHIVE_FILE_NAME,
  STARTING_DATE,
  FINISHING_DATE,
  UPDATE_FIELD_SOFT,
  UPDATE_FIELD_FORCE,
  UPDATE_FIELD_VERSIONING,
  ARCHIVE_UNIFIED,
  DEBUG_MODE,
  VIDEO_MODE,
  CURSOR_RUN,
  AUTO_RESUME,
  DRY_RUN
} from '../../../constants/runNowParametersNames';
import CRAWL_TYPES from '../../../constants/crawlersTypes';
import {
  WRONG_DATE_SIZE,
  WRONG_FILE_SIZE,
  SECOND_FILE,
  REQUIRED_VALUES_MISSED,
  DIFFERENT_FILE_NAMES,
  WRONG_SPREADSHEET_EXTENSION,
  WRONG_ARCHIVE_EXTENSION
} from '../../../constants/errors';
import RUN_NOW_TYPES from '../../../constants/runNowTypes';

import customCrawlSettings, {
  extraWrappingFields,
  predefinedValues
} from '../../../constants/customCrawlRunNowSettings';
import { resourceCrawlsComponents } from '../../../constants/resourceCrawlRunNowSettings';

const DEFAULT_VALUE = '0'; // using for all missed custom values
const DEFAULT_UPDATE_DOCUMENTS_VALUE = false;
const DEFAULT_CRAWL_RUN_DEBUG_MODE_VALUE = false;
const DEFAULT_CRAWL_RUN_VIDEO_MODE_VALUE = false;
const DEFAULT_CRAWL_CURSOR_RUN_MODE_VALUE = false;
const DEFAULT_CRAWL_AUTO_RESUME_MODE_VALUE = false;
const DEFAULT_CRAWL_DRY_RUN_VALUE = false;
const DATE_FORMAT = 'MM/DD/YYYY';

// ----- ----- Parameters collect functions ----- -----

function checkDate(formattedParameters, parameters) {
  const formatParameters = _.clone(formattedParameters);

  const startingDate = parameters[STARTING_DATE];
  const finishingDate = parameters[FINISHING_DATE];
  if (startingDate && finishingDate) {
    const startDate = moment(startingDate).format(DATE_FORMAT);
    const finishDate = moment(finishingDate).format(DATE_FORMAT);

    formatParameters[STARTING_DATE] = startDate;
    formatParameters[FINISHING_DATE] = finishDate;
    if (startingDate > finishingDate) {
      formatParameters.error = WRONG_DATE_SIZE;
    }
  }
  return formatParameters;
}

function checkFile(formattedParameters, parameters) {
  const formatParameters = _.clone(formattedParameters);

  // this function check common parameters of uploaded files
  function checkProvidedFilesCategory(files, name) {
    const firstFile = files[0];
    if (firstFile) {
      formatParameters[name] = firstFile.name;
      if (files.length > 1) {
        formatParameters.error = SECOND_FILE;
      } else if (firstFile.size > maxFileSizeRunNow) {
        formatParameters.error = WRONG_FILE_SIZE;
      }
    }
  }

  // check provided spreadsheets files
  checkProvidedFilesCategory(_.get(parameters, SPREADSHEET, []), SPREADSHEET_NAME);

  // check provided premium content zip files
  checkProvidedFilesCategory(_.get(parameters, ARCHIVE_FILE, []), ARCHIVE_FILE_NAME);

  return formatParameters;
}

function checkHorizontalRun(formattedParameters, parameters) {
  const formatParameters = _.clone(formattedParameters);

  if (parameters[ARCHIVE_UNIFIED.SPLIT_RUN]) {
    formatParameters[RUN_TYPE] = RUN_NOW_TYPES.horizontal_split.commandRequestName;
    const horizontalSplit = {};

    const startingDate = parameters[ARCHIVE_UNIFIED.DATE_START];
    const finishingDate = parameters[ARCHIVE_UNIFIED.DATE_FINISH];

    const startDate = moment(startingDate).format(DATE_FORMAT);
    const finishDate = moment(finishingDate).format(DATE_FORMAT);

    horizontalSplit.starting_date = startDate;
    horizontalSplit.finishing_date = finishDate;
    if (startingDate > finishingDate) {
      formatParameters.error = WRONG_DATE_SIZE;
    }

    formatParameters.horizontal_split_run = horizontalSplit;
  }

  return formatParameters;
}

function checkPremiumCrawlFiles(formattedParameters, crawlDetails) {
  const formatParameters = _.clone(formattedParameters);

  if (crawlDetails.type === CRAWL_TYPES.premium.value) {
    const decomposeFileName = fileName => {
      const decomposeName = fileName.split('.');
      return {
        name: _.get(decomposeName, 0, ''),
        extension: _.get(decomposeName, 1, '')
      };
    };

    const spreadsheetName = decomposeFileName(_.get(formatParameters, SPREADSHEET_NAME, ''));
    const archiveName = decomposeFileName(_.get(formatParameters, ARCHIVE_FILE_NAME, ''));

    if (spreadsheetName.name !== archiveName.name) {
      formatParameters.error = DIFFERENT_FILE_NAMES;
    } else if (spreadsheetName.extension !== 'xlsx') {
      formatParameters.error = WRONG_SPREADSHEET_EXTENSION;
    } else if (archiveName.extension !== 'zip') {
      formatParameters.error = WRONG_ARCHIVE_EXTENSION;
    }
  }
  return formatParameters;
}

function checkCustomCrawlParameters(formattedParameters, parameters, crawlDetails) {
  const formatParameters = _.clone(formattedParameters);

  if (crawlDetails.type === CRAWL_TYPES.custom.value) {
    const crawlSettings = _.get(customCrawlSettings, crawlDetails.external_api_id, []);

    // if custom crawl has predefined values, format them to the convenience of use
    /*
        example:
          before: [ { value: 'field1', predefinedValue: 0 } ]
          after: { 'field1': 0 }
      */
    const predefinedCrawValues = _.get(predefinedValues, crawlDetails.external_api_id, []);
    let predefinedCrawValuesFormat = {};
    if (predefinedCrawValues.length) {
      predefinedCrawValuesFormat = _.reduce(
        predefinedCrawValues,
        (result, value) => {
          result[value.value] = value.predefinedValue;
          return result;
        },
        {}
      );
    }

    crawlSettings.forEach(customFiles => {
      const filedName = customFiles.value;
      const defaultValue = _.get(customFiles, 'defaultValue', DEFAULT_VALUE);
      const appliedValue = _.get(parameters, filedName, defaultValue);

      if (extraWrappingFields[filedName]) {
        // check if field needs extra wrap
        formatParameters[filedName] = { [extraWrappingFields[filedName]]: appliedValue };
      } else if (filedName in predefinedCrawValuesFormat) {
        // check if field has predefined value
        formatParameters[filedName] = predefinedCrawValuesFormat[filedName];
      } else {
        formatParameters[filedName] = appliedValue;
      }
    });
  }

  return formatParameters;
}

function checkCodebotCrawlParameters(formattedParameters, parameters, crawlDetails) {
  const formatParameters = _.clone(formattedParameters);

  if (crawlDetails.type === CRAWL_TYPES.codebot.value) {
    Object.keys(resourceCrawlsComponents).forEach(component => {
      if (parameters[component]) {
        formatParameters[component] = parameters[component];
      }
    });
  }

  return formatParameters;
}

function checkRequiredParameters(formattedParameters, commandRequestType) {
  const formatParameters = _.clone(formattedParameters);
  const missedParameters = [];

  if (_.get(commandRequestType.requiredValues, 'length')) {
    commandRequestType.requiredValues.forEach(value => {
      if (!(value in formatParameters)) missedParameters.push(value);
    });
    if (missedParameters.length) {
      formatParameters.error = `${REQUIRED_VALUES_MISSED}${missedParameters.join(', ')}`;
    }
  }
  return formatParameters;
}

function formRunNowCommandRequestParameters(commandRequestType, parameters, crawlDetails) {
  let formattedParameters = {
    [CRAWL_ID]: crawlDetails.id,
    [CRAWL_TYPE]: crawlDetails.type,
    [SPIDER_NAME]: crawlDetails.spider_name,
    [RUN_TYPE]: commandRequestType.commandRequestName,
    [UPDATE_FIELD_SOFT]: _.get(parameters, UPDATE_FIELD_SOFT, false),
    [UPDATE_FIELD_FORCE]: _.get(parameters, UPDATE_FIELD_FORCE, false),
    [UPDATE_FIELD_VERSIONING]: _.get(parameters, UPDATE_FIELD_VERSIONING, false),
    [DEBUG_MODE]: _.get(parameters, DEBUG_MODE, false),
    [VIDEO_MODE]: _.get(parameters, VIDEO_MODE, false),
    [CURSOR_RUN]: _.get(parameters, CURSOR_RUN, false) || _.get(parameters, AUTO_RESUME, false),
    [AUTO_RESUME]: _.get(parameters, AUTO_RESUME, false),
    [DRY_RUN]: _.get(parameters, DRY_RUN, false)
  };

  formattedParameters = checkDate(formattedParameters, parameters);
  formattedParameters = checkFile(formattedParameters, parameters);
  formattedParameters = checkHorizontalRun(formattedParameters, parameters);
  formattedParameters = checkPremiumCrawlFiles(formattedParameters, crawlDetails);
  formattedParameters = checkCustomCrawlParameters(formattedParameters, parameters, crawlDetails);
  formattedParameters = checkCodebotCrawlParameters(formattedParameters, parameters, crawlDetails);
  formattedParameters = checkRequiredParameters(formattedParameters, commandRequestType);

  return formattedParameters;
}

// ----- ----- Request body formatted functions ----- -----

// this is not pure function (due to using FormData)
function attachFilesToFormData(commandRequest, parameters) {
  const spreadsheet = _.get(parameters, [SPREADSHEET, 0]);
  const archiveFile = _.get(parameters, [ARCHIVE_FILE, 0]);
  if (spreadsheet) {
    commandRequest.append(SPREADSHEET, spreadsheet);
  }
  if (archiveFile) {
    commandRequest.append(ARCHIVE_FILE, archiveFile);
  }
}

function formFormDateRunNowCommand(formattedParameters, parameters, command, current_user) {
  const commandRequestParameters = {
    name: `Run Now: ${formattedParameters[SPIDER_NAME]}`,
    type: command.id,
    user_id: _.get(current_user, ['user', 'id'], null),
    parameters: formattedParameters
  };

  const commandRequest = new window.FormData();
  commandRequest.append(COMMAND_REQUEST_PARAMETERS, JSON.stringify(commandRequestParameters));
  // mutate formData 'commandRequest'
  attachFilesToFormData(commandRequest, parameters);

  return commandRequest;
}

// ----- ----- Reformat exist request to other crawl ----- -----

function reformatCrawlCommandRequest(crawlInfo, formatRequest) {
  const newCommandRequest = new window.FormData();
  const spiderName = crawlInfo.spider_name;
  const commandRequestJSON = formatRequest.get(COMMAND_REQUEST_PARAMETERS);
  const commandRequestParameters = JSON.parse(commandRequestJSON);
  const parameters = commandRequestParameters.parameters;
  parameters[CRAWL_ID] = crawlInfo.id;
  parameters[SPIDER_NAME] = spiderName;
  commandRequestParameters.parameters = parameters;
  commandRequestParameters.name = `Run Now: ${spiderName}`;
  newCommandRequest.append(COMMAND_REQUEST_PARAMETERS, JSON.stringify(commandRequestParameters));
  return newCommandRequest;
}

export {
  formRunNowCommandRequestParameters,
  formFormDateRunNowCommand,
  reformatCrawlCommandRequest,
  // use only in tests
  DEFAULT_VALUE,
  DEFAULT_UPDATE_DOCUMENTS_VALUE,
  DEFAULT_CRAWL_RUN_DEBUG_MODE_VALUE,
  DEFAULT_CRAWL_RUN_VIDEO_MODE_VALUE,
  DEFAULT_CRAWL_CURSOR_RUN_MODE_VALUE,
  DEFAULT_CRAWL_AUTO_RESUME_MODE_VALUE,
  DEFAULT_CRAWL_DRY_RUN_VALUE,
  checkDate,
  checkFile,
  checkHorizontalRun,
  checkPremiumCrawlFiles,
  checkCustomCrawlParameters,
  checkRequiredParameters,
  attachFilesToFormData,
  checkCodebotCrawlParameters
};
