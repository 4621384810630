import type { BulkEditModalRule } from './useBulkEditModalData.types';

import * as hookConstants from './useBulkEditModalData.constants';

export const getNewRuleId = (rules: BulkEditModalRule[]): BulkEditModalRule['id'] => {
  return Math.max(...rules.map(({ id }) => id), 0) + 1;
};

export const getNewEmptyRule = (id: BulkEditModalRule['id']): BulkEditModalRule => {
  return {
    id,
    ...hookConstants.BULK_EDIT_MODAL_EMPTY_RULE
  };
};
