import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import moment from "moment";
import _ from 'lodash';
import Select from "react-select";
import {
  Modal,
  Button,
  ControlLabel,
  FormGroup,
  Col, Row, FormControl
} from 'react-bootstrap';
import { addCollection, updateCollection, deleteCollection } from 'shared/features/collection/collection.actions';
import CustomDatePicker from "../../components/CustomDatePicker";

const filtersObject = {
  name: '',
  description: '',
  jurisdictions: [],
  agencies: [],
  categories: [],
  sentence_model_id: null
};

const CollectionModal = (
  {
    showModal,
    closeModal,
    selectorElements,
    collection,
    dateFormat,
    addCollection: reduxaddCollection,
    updateCollection: reduxupdateCollection,
    deleteCollection: reduxdeleteCollection
  }
) => {
  const [pubDateStart, setPubDateStart] = useState(moment());
  const [pubDateFinish, setPubDateFinish] = useState(moment());
  const [filters, setFilter] = useState({});

  useEffect(() => {
    let mainInfoObject = _.cloneDeep(filtersObject);
    let startDate = moment();
    let finishDate = moment();
    if (collection && collection.id) {
      mainInfoObject = collection;
      startDate = collection.details
        && collection.details.start_pub_date
        && moment(collection.details.start_pub_date, dateFormat);
      finishDate = collection.details
        && collection.details.finish_pub_date
        && moment(collection.details.finish_pub_date, dateFormat);
    }
    setFilter(mainInfoObject);
    setPubDateStart(startDate);
    setPubDateFinish(finishDate);
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const saveFilterData = (stateName, e) => {
    setFilter({ ...filters, [stateName]: e });
  };

  const removeCollection = () => {
    reduxdeleteCollection(collection.id);
    closeModal();
  };

  const collectedParams = () => {
    return {
      ...filters,
      details: {
        start_pub_date: moment(pubDateStart).format(dateFormat),
        finish_pub_date: moment(pubDateFinish).format(dateFormat)
      }
    };
  };

  const saveCollection = () => {
    const collectCollection = collectedParams();
    delete collectCollection.sentence_model;
    const collectionId = collection && collection.id;
    if (collectionId) {
      reduxupdateCollection(collectionId, collectCollection);
    } else {
      reduxaddCollection(collectCollection);
    }
    closeModal();
  };

  const filterGroup = selectorElements.map(filterElem => (
    <Row key={filterElem.state_name} className="filterSpace">
      <Col componentClass={ControlLabel} sm={2}>
        {filterElem.name}
      </Col>
      <Col sm={10}>
        <Select
          value={filters[filterElem.state_name]}
          onChange={(e) => saveFilterData(
            filterElem.state_name,
            filterElem.multiSave
              ? e && e.map(elem => elem.value)
              : e && e.value
          )}
          options={filterElem.categories}
          multi={filterElem.multiSave}
        />
      </Col>
    </Row>
  ));

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="docMetaModal thinBtn"
    >
      <Modal.Header>
        <Modal.Title>Create, Edit, or Delete Collection</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <Row className="filterSpace">
            <Col componentClass={ControlLabel} sm={2}>
              Name
            </Col>
            <Col sm={10}>
              <FormControl
                type="text"
                name="name"
                onChange={(e) => saveFilterData(e.target.name, e.target.value)}
                value={filters.name}
              />
            </Col>
          </Row>
          <Row className="filterSpace">
            <Col componentClass={ControlLabel} sm={2}>
              Description
            </Col>
            <Col sm={10}>
              <FormControl
                componentClass="textarea"
                name="description"
                onChange={(e) => saveFilterData(e.target.name, e.target.value)}
                value={filters.description}
              />
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={2}>
              Publication date
            </Col>
            <Col sm={5}>
              <FormGroup>
                <ControlLabel>
                  From
                </ControlLabel>
                <CustomDatePicker
                  onChange={setPubDateStart}
                  selected={pubDateStart}
                />
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <ControlLabel>
                  To
                </ControlLabel>
                <CustomDatePicker
                  onChange={setPubDateFinish}
                  selected={pubDateFinish}
                />
              </FormGroup>
            </Col>
          </Row>
          {filterGroup}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={saveCollection}>Save</Button>
          <Button onClick={closeModal}>Cancel</Button>
          {collection && collection.id && (
            <Button
              bsStyle="danger"
              onClick={removeCollection}
            >
              Delete
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};
CollectionModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  selectorElements: PropTypes.arrayOf(PropTypes.object),
  dateFormat: PropTypes.string,
  collection: PropTypes.shape({})
};

CollectionModal.defaultProps = {
  showModal: false,
  selectorElements: [],
  dateFormat: 'MM/DD/YYYY',
  collection: null
};


const mapStateToProps = state => {
  return {
    docTypes: state.docTypes
  };
};

const ReduxCollectionModal = connect(mapStateToProps, {
  addCollection,
  updateCollection,
  deleteCollection
})(CollectionModal);

export default ReduxCollectionModal;
