import type {AdminPermissionsWrapperProps} from "./AdminPermissionsWrapper.types";
import type {FC} from 'react';

import React from 'react';
import Typography from "admin/components/Typography";
import {ADMIN_PERMISSIONS, useAdminPermissions} from "admin/utils/hooks";

export const AdminPermissionsWrapper: FC<AdminPermissionsWrapperProps> = ({ children }) => {
  const adminPermissions = useAdminPermissions();

  if (!adminPermissions[ADMIN_PERMISSIONS.VIEW]) {
    return (
      <Typography variant='h5'>
        You don't have access to view this page
      </Typography>
    )
  }

  return children
}
