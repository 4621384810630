import type { FC } from 'react';
import type { ExportDropdownProps } from './ExportDropdown.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../../Export.constants';

import { useExportDropdownData, useExportDropdownHandlers } from './hooks';

import './export-dropdown.scss';

export const ExportDropdown: FC<ExportDropdownProps> = ({
  selectedDocumentIds,
  isDownloading,
  onExportModalOpen,
  onSelectContentType
}) => {
  const { localState, localActions, formattedData } = useExportDropdownData({
    props: {
      isDownloading,
      selectedDocumentIds
    }
  });

  const handlers = useExportDropdownHandlers({
    props: {
      selectedDocumentIds,
      onExportModalOpen,
      onSelectContentType
    },
    localActions
  });

  return (
    <uiLib.Dropdown
      isOpen={localState.isOpen}
      onClose={handlers.handleDropdownToggle(false)}
      triggerContent={
        <>
          <uiLib.ActionButton
            isDisabled={formattedData.isDisabled}
            onClick={handlers.handleDropdownToggle(true)}
            tooltip="Export"
            tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
          >
            <uiLib.IconDownload />
          </uiLib.ActionButton>
        </>
      }
      dropdownContent={
        <uiLib.LabeledField
          label={<uiLib.Typography>Download as...</uiLib.Typography>}
          labelClassName="export-dropdown__label"
        >
          <uiLib.List
            options={constants.EXPORT_OPTIONS}
            selectedOptions={[]}
            onOptionClick={handlers.handleContentTypeSelect}
          />
        </uiLib.LabeledField>
      }
      className="export-dropdown__dropdown"
      containerClassName="export-dropdown__container"
    />
  );
};
