import { useState } from 'react';

export const useAlertData = () => {
  const [isVisible, setVisibilityFlag] = useState(false);
  const [timeout, setLocalTimeout] = useState(null);

  return {
    localState: {
      isVisible,
      timeout
    },
    localActions: {
      setVisibilityFlag,
      setLocalTimeout
    }
  };
};
