import React from 'react';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './CrawlMonitorTool.constants';
import * as crawlConstants from 'admin/constants/crawlers';

import CrawlMonitorMultiRunNow from './CrawlMonitorMultiRunNow';
import CrawlMonitorToolHeader from './CrawlMonitorToolHeader';

import {
  useCrawlMonitorToolData,
  useCrawlMonitorToolHandlers,
  useCrawlMonitorToolTableActions
} from './hooks';

import './_crawl-monitor.scss';
import { useCrawlMonitorToolDataSelector } from './hooks/useCrawlMonitorToolDataSelector';
import * as errorUtils from '../../../shared/utils/errors';
import ConfirmationModal from '../CrawlerPage/ConfirmationModal';

const CrawlMonitorTool: FC = () => {
  const { localState, localActions, reduxState, formattedData } = useCrawlMonitorToolData();

  const handlers = useCrawlMonitorToolHandlers({
    localState,
    localActions,
    reduxState
  });
  const tableActions = useCrawlMonitorToolTableActions({
    localState,
    events: {
      onRunNow: localActions.setRunNowModalActive,
      onDeactivate: handlers.handleDeactivateSelectedCrawls,
      onMoveToIdle: handlers.handleMoveToIdleSelectedCrawls
    }
  });

  const tableDataSelector = useCrawlMonitorToolDataSelector();

  return (
    <uiLib.ErrorBoundary>
      <CrawlMonitorMultiRunNow
        show={localState.runNowModalActive}
        onHide={() => localActions.setRunNowModalActive(false)}
        selectedCrawl={localState.selectedCrawl}
      />
      <div className="crawl-monitor__container">
        <div className="crawl-monitor__filters">
          <CrawlMonitorToolHeader
            changeFilter={handlers.handleSetSearchValues}
            clearSearch={handlers.handleClearSearch}
            requestCrawls={handlers.handleApplyCrawlFilters}
            search={localState.search}
            show={localState.searchModal}
            toggleSearch={handlers.handleToggleSearch}
            appliedSearch={localState.appliedSearch}
            error={localState.error}
          />
        </div>
        <div className="crawl-monitor__table-wrapper">
          <uiLib.Table
            rowKey={crawlConstants.CRAWLER_ATTRIBUTES.ID}
            columns={constants.COLUMNS}
            actions={tableActions}
            onDataFetch={handlers.handleCrawlersFetch}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldRenderToolbar
            shouldShowRefreshButton
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            areRowsSelectable
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_CRAWL_COUNT}
            onSelectedRowKeysChange={handlers.handleSetSelectedCrawl}
            selectedRowsCountPlacement={uiLib.SELECTED_ROWS_COUNT_PLACEMENT.TABLE}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            shouldShowTableOptions
            onParamsChange={localActions.setTableParams}
            externalParams={formattedData.externalTableParams}
            queryParams={localState.tableParams}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
          />
        </div>
        <ConfirmationModal
          show={localState.showModal}
          mode={formattedData.formattedMode}
          onHide={() => localActions.setShowModal(false)}
          modalAction={handlers.handleConfirmationModalAction}
          onCancel={handlers.handleConfirmationModalCancel}
        />
      </div>
    </uiLib.ErrorBoundary>
  );
};

export default CrawlMonitorTool;
