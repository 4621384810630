import React from 'react';

const CrawlerPerformanceTableBody = ({ spiderRunReports, toggleModal, averageCounter }) => {
  return spiderRunReports && spiderRunReports.map && spiderRunReports.map(spiderRunReport => {
    const total_skipped = spiderRunReport.docs_skipped
      || 0;
    // const total_failed = spiderRunReport.docs_failed
    //   || 0;
    const total_ingested = spiderRunReport.docs_ingested
      || 0;
    const total_runs = spiderRunReport.total_runs
      || 0;
    const total_errors = spiderRunReport.docs_error
      || 0;
    // const total_updated = spiderRunReport.docs_updated
    //   || 0;

    return (
      <div
        key={spiderRunReport.spider_name}
        className="adminToolTableFlexRow"
        onClick={() => {
          toggleModal(true, spiderRunReport);
        }}
      >
        <div>{spiderRunReport.spider_name}</div>
        <div>{total_ingested}</div>
        <div>{averageCounter(total_ingested, total_runs)}</div>
        <div>{total_skipped}</div>
        <div>{averageCounter(total_skipped, total_runs)}</div>
        <div>{total_errors}</div>
        <div>{averageCounter(total_errors, total_runs)}</div>
        <div>{total_runs}</div>
      </div>
    );
  });
};

export default CrawlerPerformanceTableBody;
