import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, FormGroup, ControlLabel, Radio, FormControl } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isEmpty } from 'lodash';

const DATE_FORMAT1 = 'YYYY-MM-DD';
const DATE_FORMAT2 = 'MM-DD-YYYY';

const FIELDS = {
  EXTEND_DATE: 'extend_date',
  PLAN_ID: 'plan_id',
  PAYMENT_TYPE: 'payment_type',
  EXPIRATION_DATE: 'expiration_date',
  NOTES: 'notes'
};

const PAYMENT_TYPES = ['stripe', 'invoice'];

const INITIAL_STATE = {
  [FIELDS.EXTEND_DATE]: null,
  [FIELDS.PLAN_ID]: null,
  [FIELDS.PAYMENT_TYPE]: null,
  [FIELDS.EXPIRATION_DATE]: null,
  [FIELDS.NOTES]: ''
};

import { updateUserSubscription } from 'shared/features/subscriptions/subscriptions.actions';

const SubscriptionModal = ({
  subscription,
  open = false,
  close,
  updateTable,
  updateSubscription,
  all_plans
}) => {
  const [subscriptionOption, setSubscriptionOption] = useState(INITIAL_STATE);

  const handleSubscriptionOptionChange = (fieldName, value) => {
    const newSubscriptionOption = {
      ...INITIAL_STATE,
      [fieldName]: value
    };

    if (fieldName === FIELDS.EXPIRATION_DATE) {
      newSubscriptionOption[FIELDS.NOTES] = subscriptionOption[FIELDS.NOTES];
    }

    if (fieldName === FIELDS.NOTES) {
      newSubscriptionOption[FIELDS.EXPIRATION_DATE] = subscriptionOption[FIELDS.EXPIRATION_DATE];
    }

    setSubscriptionOption(newSubscriptionOption);
  };

  const getParams = () => {
    const params = {};
    if (subscriptionOption[FIELDS.EXTEND_DATE]) {
      params[FIELDS.EXPIRATION_DATE] = moment(subscriptionOption[FIELDS.EXTEND_DATE]).format(
        DATE_FORMAT1
      );
    }
    if (subscriptionOption[FIELDS.EXPIRATION_DATE]) {
      params[FIELDS.EXPIRATION_DATE] = moment(subscriptionOption[FIELDS.EXPIRATION_DATE]).format(
        DATE_FORMAT1
      );
    }
    if (subscriptionOption[FIELDS.NOTES]) {
      params[FIELDS.NOTES] = subscriptionOption[FIELDS.NOTES];
    }
    if (subscriptionOption[FIELDS.PLAN_ID]) {
      params[FIELDS.PLAN_ID] = parseInt(subscriptionOption[FIELDS.PLAN_ID], 10);
    }
    if (subscriptionOption[FIELDS.PAYMENT_TYPE]) {
      params[FIELDS.PAYMENT_TYPE] = subscriptionOption[FIELDS.PAYMENT_TYPE];
    }
    return params;
  };

  const handleSubmit = event => {
    event.preventDefault();
    close();
    updateSubscription(subscription.subscription_id, getParams()).then(() => {
      updateTable();
    });
  };

  const renderPlanSelection = () => {
    const { items = [] } = all_plans;

    return items.map(plan => (
      <Radio
        key={plan.id}
        name={FIELDS.PLAN_ID}
        value={plan.id}
        checked={subscriptionOption[FIELDS.PLAN_ID] === plan.id.toString()}
        onChange={e => handleSubscriptionOptionChange(FIELDS.PLAN_ID, e.target.value)}
      >
        {plan.name}
      </Radio>
    ));
  };

  const renderPaymentTypeSelection = () => PAYMENT_TYPES.map(payment_type => (
    <Radio
      key={payment_type}
      name={FIELDS.PAYMENT_TYPE}
      value={payment_type}
      checked={subscriptionOption[FIELDS.PAYMENT_TYPE] === payment_type}
      onChange={e => handleSubscriptionOptionChange(FIELDS.PAYMENT_TYPE, e.target.value)}
    >
      {payment_type}
    </Radio>
  ));

  return (
    <Modal show={open} onHide={close}>
      <Modal.Header>
        <Modal.Title>
          <p>{`Manage Subscription for ${subscription.email}`}</p>
          <div className="subscription-header">
            <p>{`Plan Name: ${subscription.plan_name}`}</p>
            <p>{`Start Date: ${moment(subscription.start_date).format(DATE_FORMAT2)}`}</p>
            <p>
              {`End Date: ${
                subscription.expiration_date
                  ? moment(subscription.expiration_date).format(DATE_FORMAT2)
                  : 'n/a: recurring'
              }`}
            </p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <form action="src/admin/containers/Subscriptions" onSubmit={handleSubmit}>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Extend to</ControlLabel>
            <div className="subscription-date">
              <DatePicker
                selected={subscriptionOption[FIELDS.EXTEND_DATE]}
                onChange={date => handleSubscriptionOptionChange(FIELDS.EXTEND_DATE, date)}
                isClearable
              />
            </div>

            <ControlLabel>Convert to</ControlLabel>
            <div>{renderPlanSelection()}</div>

            <ControlLabel>Change payment to:</ControlLabel>
            <div>{renderPaymentTypeSelection()}</div>

            <ControlLabel>Expire on</ControlLabel>
            <div className="subscription-date">
              <DatePicker
                selected={subscriptionOption[FIELDS.EXPIRATION_DATE]}
                onChange={date => handleSubscriptionOptionChange(FIELDS.EXPIRATION_DATE, date)}
                isClearable
              />
            </div>

            <ControlLabel>{'Enter text - reason why expired'}</ControlLabel>
            <FormControl
              componentClass="textarea"
              value={subscriptionOption[FIELDS.NOTES]}
              onChange={e => handleSubscriptionOptionChange(FIELDS.NOTES, e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close}>Close</Button>
          <Button bsStyle="primary" type="submit" disabled={isEmpty(getParams())}>
            Update
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

SubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    isReady: PropTypes.bool,
    isFetching: PropTypes.bool,
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        subscription_id: PropTypes.number,
        suspension_date: PropTypes.string,
        plan_id: PropTypes.number,
        plan_name: PropTypes.string,
        user_id: PropTypes.number,
        email: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        payment_type: PropTypes.string,
        notes: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.string,
        status_reason: PropTypes.string,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        expiration_date: PropTypes.string
      })
    )
  }).isRequired,
  all_plans: PropTypes.shape({
    isReady: PropTypes.bool,
    isFetching: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        stripe_id: PropTypes.string
      })
    )
  }).isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    updateSubscription: (subscription_id, data) => {
      return dispatch(updateUserSubscription(subscription_id, data));
    }
  };
};

const mapStateToProps = state => {
  return {
    all_subscriptions: state.all_subscriptions,
    all_plans: state.all_plans
  };
};

const ReduxSubscriptionModal = connect(mapStateToProps, mapDispatchToProps)(SubscriptionModal);

export default ReduxSubscriptionModal;
