import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../../shared/reducers';
import { getFirstExternalApiCrawlElement } from '../../../../shared/features/externalApiCrawl/externalApiCrawl.selectors';
import { getCurrentUser } from '../../../../shared/features/user/user.selectors';
import {
  getCommands,
  getFirstCommandRequest
} from '../../../../shared/features/commands/commands.selector';
import { getFirstSpiderRunReport } from '../../../../shared/features/spiderRunReports/spiderRunReports.selector';
import { MAIN_TENANTS_HOSTNAME, MODE_CONFIRMATION_MODAL } from '../CrawlerSettings.constants';
import { useMemo, useState } from 'react';
import { CrawlSettingsData } from './useCrawlSettingsData.types';

export const useCrawlSettingsData = (): CrawlSettingsData => {
  const { id } = useParams();
  const { search } = useLocation();
  const isMainTenant = MAIN_TENANTS_HOSTNAME.includes(window.location.hostname);

  const reduxState = useSelector<GlobalState, CrawlSettingsData['reduxState']>(state => ({
    commands: getCommands(state),
    currentUser: getCurrentUser(state),
    crawlDetails: getFirstExternalApiCrawlElement(state),
    commandRequest: getFirstCommandRequest(state),
    spiderRunReport: getFirstSpiderRunReport(state)
  }));

  const [runNowModalActive, setRunNowModalActive] =
    useState<CrawlSettingsData['localState']['runNowModalActive']>(false);
  const [showModal, setShowModal] = useState<CrawlSettingsData['localState']['showModal']>(false);
  const [mode, setMode] = useState<CrawlSettingsData['localState']['mode']>(
    MODE_CONFIRMATION_MODAL.EXPORT
  );
  const [showInfoModal, setShowInfoModal] =
    useState<CrawlSettingsData['localState']['showInfoModal']>(false);
  const [crawlerState, setCrawlerState] =
    useState<CrawlSettingsData['localState']['crawlerState']>(null);
  const [crawlerSavedState, setCrawlerSavedState] =
    useState<CrawlSettingsData['localState']['crawlerSavedState']>(null);
  const [isCommandRequest, setCommandRequest] =
    useState<CrawlSettingsData['localState']['isCommandRequest']>(false);
  const [lastReport, setLastReport] = useState<CrawlSettingsData['localState']['lastReport']>(null);
  const [runNowCommandRequest, setRunNowCommandRequest] =
    useState<CrawlSettingsData['localState']['runNowCommandRequest']>(null);
  // current action
  // this variable is necessary to identify current action
  // important because we have some same common steps for different actions,
  // but we don't want to repeat big parts of code
  const [currentAction, setCurrentAction] = useState<
    CrawlSettingsData['localState']['currentAction']
  >(MODE_CONFIRMATION_MODAL.EXPORT);

  const localActions = useMemo(
    () => ({
      setRunNowModalActive,
      setShowModal,
      setMode,
      setShowInfoModal,
      setCrawlerState,
      setCrawlerSavedState,
      setCommandRequest,
      setLastReport,
      setRunNowCommandRequest,
      setCurrentAction
    }),
    []
  );

  return {
    reduxState,
    localState: {
      runNowModalActive,
      showModal,
      mode,
      showInfoModal,
      crawlerState,
      crawlerSavedState,
      isCommandRequest,
      lastReport,
      runNowCommandRequest,
      currentAction
    },
    localActions,
    formattedData: {
      id,
      search,
      isMainTenant
    }
  };
};
