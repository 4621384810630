import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import Typography from '../../components/Typography';
import Switch from "../../components/Switch";
import Button from "../../components/Button";

const CrawlSpecCreateExtra = ({
  editMode,
  crawlConfig,
  handleChangesEvent,
  setCrawlConfig,
  handleChanges,
  errorFields,
  modalAction
}) => {
  const disableEdit = !editMode;
  let parsedCrawlConfig;
  try {
    parsedCrawlConfig = JSON.parse(crawlConfig);
    console.log('Readable json');
  } catch (e) {
    parsedCrawlConfig = {};
    console.log('Warning: Not readable json');
  }
  const [fold, setFold] = useState(false);

  let skipExternalDocuments = false;
  try {
    skipExternalDocuments = _.get(
      parsedCrawlConfig,
      ['configurations', 'skip_external_documents'],
      false
    );
  } catch (e) {
    console.log("Crawl config not valid");
    skipExternalDocuments = false;
  }

  const changeConfigField = (value, path) => {
    const newCrawlConfig = JSON.stringify(_.set(parsedCrawlConfig, path, value));
    setCrawlConfig(newCrawlConfig);
  };

  return (
    <div className="crawl-spec_section crawl-spec_general-info">
      <div className="crawl-spec_section_head">
        <Typography variant="h3">{`${fold ? '-' : '+'}`}</Typography>
        <Typography
          variant="h3"
          onClick={() => setFold(!fold)}
        >
          Extra Config Info
        </Typography>
      </div>
      <div
        className={cn(
          'crawl-spec_section_body crawl-spec_general-info_body',
          { 'crawl-spec_section_body_fold': fold }
        )}
      >
        <Switch
          name="skipExternalDocuments"
          label="Skip External Documents"
          className="input_2"
          value={skipExternalDocuments}
          onChange={() => changeConfigField(!skipExternalDocuments, ['configurations', 'skip_external_documents'])}
          isDisabled={disableEdit}
        />
        <Button
          color="highlighted"
          size="small"
          disabled={disableEdit}
          onClick={modalAction}
        >
          Update config
        </Button>
      </div>
    </div>
  );
};

CrawlSpecCreateExtra.propTypes = {
  editMode: PropTypes.bool,
  handleChangesEvent: PropTypes.func.isRequired,
  handleChanges: PropTypes.func.isRequired,
  modalAction: PropTypes.func,
  setCrawlConfig: PropTypes.func,
  crawlConfig: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

CrawlSpecCreateExtra.defaultProps = {
  editMode: false,
  modalAction: () => {},
  setCrawlConfig: () => {},
  crawlConfig: "",
  errorFields: []
};

export default CrawlSpecCreateExtra;
