import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export const DESC = 'desc';
export const ASC = 'asc';

function FilterArrow({
  className,
  onClick,
  type,
  size,
  disabled
}) {
  const filterArrowPress = () => {
    if (disabled) return;
    onClick();
  };

  const getFilterArrow = () => {
    switch (type) {
      case DESC:
        return (<KeyboardArrowDownIcon
          fontSize={size}
        />);
      case ASC:
        return (<KeyboardArrowUpIcon
          fontSize={size}
        />);
      default:
        return null;
    }
  };

  return (
    <span
      className={cn(className)}
      onClick={filterArrowPress}
    >
      {getFilterArrow()}
    </span>
  );
}

FilterArrow.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string
};

FilterArrow.defaultProps = {
  type: '',
  disabled: false,
  className: '',
  size: '',
  onClick: () => {}
};

export default FilterArrow;
