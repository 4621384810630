import type { CommandRequestsCreationModalData } from '../../hooks/useCommandRequestsCreationModalData.types';
import type { CommandRequestsCreationModalHandlers } from '../../hooks/useCommandRequestsCreationModalHandlers.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './CommandRequestsTemplates.constants';

import { useCommandRequestsTemplatesData, useCommandRequestsTemplatesHandlers } from './hooks';

export const CommandRequestsTemplates = ({
  parentState,
  parentActions,
  parentHandlers
}: {
  parentState: CommandRequestsCreationModalData['localState'];
  parentActions: CommandRequestsCreationModalData['localActions'];
  parentHandlers: CommandRequestsCreationModalHandlers;
}) => {
  const { localState, localActions, formattedData } = useCommandRequestsTemplatesData({
    parentState,
    parentActions
  });
  const handlers = useCommandRequestsTemplatesHandlers({ localState, localActions });
  return (
    <div>
      <uiLib.LabeledField label="Command Request Name">
        <uiLib.TextField
          value={parentState.commandName}
          onChange={parentActions.setCommandName}
          required
        />
      </uiLib.LabeledField>
      {formattedData.isAdditionalSettings && (
        <uiLib.Switch
          label="Settings"
          isChecked={localState.isSettingOpen}
          onChange={handlers.handleSwitchSettings}
        />
      )}
      {localState.isSettingOpen && (
        <div>
          <uiLib.Typography>Number of row</uiLib.Typography>
          {formattedData.isAdditionalSettings &&
            parentState.selectedCommand?.parameters?.additional_params?.map((item, index) => (
              <uiLib.LabeledField
                label={item.name.length > 36 ? item.name.slice(0, 36) + '...' : item.name}
                key={index}
              >
                <uiLib.TextField
                  value={parentState.additionalParams?.[item?.name]}
                  onChange={value => parentHandlers.handleChangeAdditionalParam(item?.name, value)}
                  required
                />
              </uiLib.LabeledField>
            ))}
        </div>
      )}
      {formattedData.isFile && (
        <div>
          <uiLib.Dropzone
            accept={formattedData.fileAccept}
            maxFiles={constants.FILE_MAX_AMOUNT}
            shouldShowAcceptedFileTypes
            onSelectFiles={parentActions.setFiles}
          />
        </div>
      )}
    </div>
  );
};
