import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import './AgencySettings.styles.scss';
import AgencyDetails from './AgencyDetails/AgencyDetails';
import AgencySettingsStatus from './AgencySettingsStatus';
import {
  clearAgencies,
  getAgencies,
  updateAgencies
} from 'shared/features/agencyData/agencyData.actions';
import { AGENCY_ACTIVE_TYPES } from './AgencyDetails/AgencyDetails.constants';

const AgencySettings = ({
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const agencyDetails = _.get(useSelector(state => state.agencyData), ['items', 0], {});

  const [status, setStatus] = useState(AGENCY_ACTIVE_TYPES.DEACTIVATED);

  useEffect(() => {
    dispatch(clearAgencies({ items: [{ id }], count: 1 }));
    if (!isNaN(+id)) {
      dispatch(getAgencies({ id, extend_info: true }));
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agencyDetails.active) setStatus(AGENCY_ACTIVE_TYPES.ACTIVE);
    else setStatus(AGENCY_ACTIVE_TYPES.DEACTIVATED);
  }, [agencyDetails.active]);

  // handle user switch of statuses
  const changeAgencyStatus = agencyStatus => {
    dispatch(updateAgencies({ id, ...agencyStatus.updateValue }));
    setStatus(agencyStatus.value);
  };

  return (
    <>
      <Tabs
        className="agency-navigation_tabs"
        headClassName="agency-navigation_tabs_head"
        extraInfo={
          <AgencySettingsStatus
            changeStatus={changeAgencyStatus}
            agencyDetails={agencyDetails}
            status={status}
          />
        }
      >
        <AgencyDetails label="Publisher Details" />
      </Tabs>
    </>
  );
};

export default AgencySettings;
