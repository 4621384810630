import { createSlice } from "@reduxjs/toolkit";

export const reducerName = 'industries';

export const initialState = {
  industries: []
};

const industriesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setIndustries: (state, action) => {
      state.industries = action.payload;
    }
  }
});


export default industriesReducer.reducer;
export const {
  setIndustries
} = industriesReducer.actions;
