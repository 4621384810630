import type { FC } from 'react';
import type { ExportProps } from './Export.types';

import * as elements from './elements';

import { useExportData, useExportHandlers } from './hooks';

export const Export: FC<ExportProps> = ({ selectedDocumentIds, onStatusAction }) => {
  const { localState, localActions } = useExportData();

  const handlers = useExportHandlers({
    props: { selectedDocumentIds, onStatusAction },
    localActions
  });

  return (
    <>
      <elements.ExportDropdown
        selectedDocumentIds={selectedDocumentIds}
        isDownloading={localState.isDownloading}
        onSelectContentType={handlers.handleContentTypeSelect}
        onExportModalOpen={handlers.handleOpenExportModal}
      />

      {localState.isExportModalOpen && (
        <elements.ExportModal
          documentsCount={selectedDocumentIds.length}
          contentType={localState.contentType}
          onExport={handlers.handleExport}
          onClose={handlers.handleCloseExportModal}
        />
      )}
    </>
  );
};
