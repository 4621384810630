import uuidv4 from 'uuid/v4';
import { INPUT_TYPE } from '../../components/TextInput';

// name inside spec
export const SPEC_GENERAL = {
  apiTable: 'api_table',
  metaTable: 'meta_table'
};

export const CONFIG_EXTRA = {
  skipExternalDocuments: 'skip_external_documents'
};

// input settings
export const SPEC_GENERAL_SETTINGS = [
  { name: SPEC_GENERAL.apiTable, required: true, type: INPUT_TYPE.TEXT },
  { name: SPEC_GENERAL.metaTable, required: true, type: INPUT_TYPE.TEXT }
];

// value types

export const VALUE_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  BOOL: 'bool',
  NULL: 'null',
  NOT_USE: 'not use'
};

// configuration const

export const NAMES_CONFIG = {
  STEPS: 'steps',
  CATEGORY_CONVERSION: 'category_conversion',
  FULL_TEXT_PERCENTAGE: 'full_text_percentage'
};

export const TEMPLATE_CONFIGURATION = {
  [NAMES_CONFIG.FULL_TEXT_PERCENTAGE]: null
  // not usable values for now
  // [NAMES_CONFIG.CATEGORY_CONVERSION]: null,
  // [NAMES_CONFIG.STEPS]: true
};

// category conversion const

export const CAT_CONV_APPROACH_TYPES = {
  CLOSEST_TO_BEGIN: 'closest_to_begin',
  CONTAINING: 'containing',
  EXACT_MATCH: 'exact_match'
};

export const NAMES_CAT_CONV = {
  APPROACH: 'category_approach',
  MAP: 'conversion_map',
  DEFAULT: 'default_category'
};

export const TEMPLATE_CAT_CONV = {
  [NAMES_CAT_CONV.APPROACH]: {
    value: CAT_CONV_APPROACH_TYPES.CONTAINING,
    label: CAT_CONV_APPROACH_TYPES.CONTAINING
  },
  [NAMES_CAT_CONV.MAP]: [],
  [NAMES_CAT_CONV.DEFAULT]: ''
};

// uuid prefixes
export const UUID_PREFIXES = {
  STEP: 'step_',
  CONTEXT: 'context_',
  DOC: 'doc_',
  ACTION: 'action_',
  RELATION: 'relation_',
  RESET_COUNTER: 'reset-counter_',
  META_DATA: 'meta-data_',
  LOCATION: 'location_',
  EXTRACT_TAGS: 'extract-tags_',
  PATH: 'path_',
  EXAMPLE: 'example_'
};

// step const

export const NAMES_STEP = {
  NAME: 'name',
  DESC: 'description',
  NEXT_STEP: 'next_step',
  CONTEXT: 'context',
  DOC: 'doc',
  ACTIONS: 'actions',
  SAVE_DOC: 'save_document',
  PROCESS_RELATED_DOCUMENTS: 'process_related_documents',
  IS_PARENT: 'is_parent',
  CONTINUE_CRAWL: 'continue_crawl',
  RELATION: 'create_relations',
  RESET_COUNTER: 'reset_counter',
  DEBUG: 'debug',
  PRE_GENERATED_FULL_TEXT: 'pre_generate_full_text'
};

export const FIRST_STEP = {
  id: `${UUID_PREFIXES.STEP}${uuidv4()}`,
  [NAMES_STEP.NAME]: 'start',
  [NAMES_STEP.DESC]: 'Starting step that loads main web-page.',
  locked: true
};

export const TEMPLATE_STEP = {
  [NAMES_STEP.NAME]: 'new step',
  [NAMES_STEP.DESC]: '',
  [NAMES_STEP.ACTIONS]: [],
  [NAMES_STEP.RELATION]: [],
  [NAMES_STEP.SAVE_DOC]: true,
  [NAMES_STEP.PROCESS_RELATED_DOCUMENTS]: false,
  [NAMES_STEP.IS_PARENT]: false,
  [NAMES_STEP.CONTINUE_CRAWL]: false
};

// context const

export const NAMES_CONTEXT = {
  CODE_FILTER: 'code_filter',
  TAG_FILTER: 'tag_filter',
  URL: 'is_url',
  IS_TEXT: 'is_text',
  IS_SINGLE: 'is_single',
  IS_SIBLING_TAG: 'is_sibling_tag',
  COMBINE_CONTEXT: 'combine_context',
  IS_PAGE_SOURCE: 'is_page_source',
  PATH: 'path',
  // this field not in work yet
  EXTRACT_TAGS: 'extract_tags'
};

export const TEMPLATE_CONTEXT = {
  [NAMES_CONTEXT.URL]: false,
  [NAMES_CONTEXT.IS_TEXT]: false,
  [NAMES_CONTEXT.IS_SINGLE]: false,
  [NAMES_CONTEXT.IS_SIBLING_TAG]: false,
  [NAMES_CONTEXT.COMBINE_CONTEXT]: false,
  [NAMES_CONTEXT.IS_PAGE_SOURCE]: false,
  [NAMES_CONTEXT.PATH]: []
};

// doc const

export const NAMES_DOC = {
  META_DATA: 'meta_data',
  INCOMPLETE_PUB_DATE: 'incomplete_pub_date'
};

export const TEMPLATE_DOC = {
  [NAMES_DOC.META_DATA]: [],
  [NAMES_DOC.INCOMPLETE_PUB_DATE]: false
};

// action const

export const ACTION_TYPES = {
  CLICK: 'click',
  NEW_WINDOW: 'create_new_window',
  SWITCH_WINDOW: 'switch_to_window',
  SCROLL: 'scroll_into_view'
};

export const ACTION_METHODS = {
  XPATH: 'xpath'
};

export const NAMES_ACTION = {
  TYPE: 'type',
  METHOD: 'method',
  EXAMPLES: 'examples',
  COUNTER: 'use_counter',
  REPEAT: 'repeat',
  PATH: 'path'
};

export const TEMPLATE_ACTION = {
  [NAMES_ACTION.TYPE]: { value: ACTION_TYPES.CLICK, label: ACTION_TYPES.CLICK },
  [NAMES_ACTION.METHOD]: { value: ACTION_METHODS.XPATH, label: ACTION_METHODS.XPATH },
  [NAMES_ACTION.EXAMPLES]: [],
  [NAMES_ACTION.COUNTER]: false,
  [NAMES_ACTION.REPEAT]: null,
  [NAMES_ACTION.PATH]: null
};

// we show fields that shown in settings (works for examples / path only for now)
export const ACTION_TYPES_SETTINGS = {
  [ACTION_TYPES.CLICK]: [ NAMES_ACTION.EXAMPLES ],
  [ACTION_TYPES.NEW_WINDOW]: [],
  [ACTION_TYPES.SWITCH_WINDOW]: [ NAMES_ACTION.PATH ],
  [ACTION_TYPES.SCROLL]: [ NAMES_ACTION.EXAMPLES ]
};

// relation const

export const RELATION_TYPES = {
  DOC: 'documents',
  LANG: 'language'
};

export const NAMES_RELATION = {
  RELATION_TYPE: 'relation_type',
  CLEAR_EXISTING_RELATIONS: 'clear_existing_relations'
};

export const TEMPLATE_RELATION = {
  [NAMES_RELATION.RELATION_TYPE]: { label: RELATION_TYPES.LANG, value: RELATION_TYPES.LANG },
  [NAMES_RELATION.CLEAR_EXISTING_RELATIONS]: false
};

// reset counter const

export const NAMES_RESET_COUNTER = {
  STEP: 'step'
};

export const TEMPLATE_RESET_COUNTER = {
  [NAMES_RESET_COUNTER.STEP]: []
};

// meta data const

export const NAMES_META_DATA = {
  PATH: 'path',
  LOCATIONS: 'locations',
  REGEX: 'regex',
  DEFAULT_VALUE: 'default_value',
  VALUE_TYPE: 'value_type',
  NAME: 'name',
  EXTRACT_TAGS: 'extract_tags',
  DATE_FORMAT: 'date_format',
  IS_TEXT: 'is_text',
  IS_URL: 'is_url',
  IS_SINGLE: 'is_single',
  IS_SIBLING_TAG: 'is_sibling_tag',
  IS_SIBLING_TEXT: 'is_sibling_text',
  IS_DATE: 'is_date',
  PARSE_PDF: 'parse_pdf',
  FROM_URL: 'from_url',
  IS_CURRENT_URL: 'is_current_url',
  CODE_FILTER: 'code_filter',
  DAY_FIRST: 'dayfirst',
  IS_TEXT_GENERATED: 'is_text_generated'
};

export const TEMPLATE_META_DATA = {
  [NAMES_META_DATA.PATH]: [],
  [NAMES_META_DATA.LOCATIONS]: [],
  [NAMES_META_DATA.REGEX]: '',
  [NAMES_META_DATA.IS_TEXT]: false,
  [NAMES_META_DATA.IS_URL]: false,
  [NAMES_META_DATA.IS_SINGLE]: false,
  [NAMES_META_DATA.IS_SIBLING_TAG]: false,
  [NAMES_META_DATA.IS_SIBLING_TEXT]: false,
  [NAMES_META_DATA.IS_DATE]: false,
  [NAMES_META_DATA.DAY_FIRST]: false,
  [NAMES_META_DATA.PARSE_PDF]: false,
  [NAMES_META_DATA.FROM_URL]: false,
  [NAMES_META_DATA.IS_CURRENT_URL]: false,
  [NAMES_META_DATA.IS_TEXT_GENERATED]: false
};

// location const

export const NAMES_LOCATION = {
  PAGES: 'pages',
  COORDINATES_TOP: 'coordinates.top',
  COORDINATES_BOTTOM: 'coordinates.bottom',
  COORDINATES_LEFT: 'coordinates.left',
  COORDINATES_RIGHT: 'coordinates.right',
  MARGINS: 'margins',
  REMOVE_PREPOSITIONS: 'remove_prepositions',
  REGEX: 'regex'
};

export const TEMPLATE_LOCATION = {
  [NAMES_LOCATION.PAGES]: '',
  [NAMES_LOCATION.COORDINATES_TOP]: 0,
  [NAMES_LOCATION.COORDINATES_BOTTOM]: 0,
  [NAMES_LOCATION.COORDINATES_LEFT]: 0,
  [NAMES_LOCATION.COORDINATES_RIGHT]: 0,
  [NAMES_LOCATION.MARGINS]: 0,
  [NAMES_LOCATION.REMOVE_PREPOSITIONS]: false,
  [NAMES_LOCATION.REGEX]: []
};

// extract tags const

export const NAMES_EXTRACT_TAGS = {
  PATH: 'path'
};

export const TEMPLATE_EXTRACT_TAGS = {
  [NAMES_EXTRACT_TAGS.PATH]: []
};

// path const

export const NAMES_PATH = {
  EXAMPLES: 'examples',
  PATH: 'path'
};

export const TEMPLATE_PATH = {
  [NAMES_PATH.EXAMPLES]: [],
  [NAMES_PATH.PATH]: ''
};

// example const

export const NAMES_EXAMPLE = {
  URL: 'url',
  TEXT: 'text',
  OCCURRENCE: 'occurrence',
  EXACT_MATCH: 'exact_match'
};

export const TEMPLATE_EXAMPLE = {
  [NAMES_EXAMPLE.URL]: '',
  [NAMES_EXAMPLE.TEXT]: '',
  [NAMES_EXAMPLE.OCCURRENCE]: '1',
  [NAMES_EXAMPLE.EXACT_MATCH]: false
};

// ------ ERRORS -----

export const ERRORS = {
  common: 'Spec not saved due to bunch of issues.',
  // configuration
  fullTextPercentage: 'Fill text percentage should be a number',
  // category conversion
  categoryConversionApproach: 'Approach is required',
  categoryConversionDefault: 'Default value is required',
  // step error
  stepName: 'Step name is required',
  stepDesc: 'Step description is required',
  stepActions: 'Action Error',
  stepRelations: 'Relation Error',
  stepNextStep: 'Next step is required if you have context or actions',
  // context error
  contextDataPath: 'Context require paths',
  contextExample: 'Context require examples',
  // doc error
  docMetaData: 'Doc meta data issue',
  // action error
  actionExample: 'Context require examples',
  actionType: 'Action Type is required',
  actionMethod: 'Method Type is required',
  actionRepeat: 'Repeat Type should be number',
  actionPath: 'Repeat Type should be number',
  // relation error
  relationType: 'Relation type is required',
  // reset counter error
  resetCounterStep: 'Step is required',
  // meta-data error
  metaDataName: 'Meta Data require uniq name',
  metaDataPath: 'Meta Data require paths',
  metaDataExample: 'Meta Data require examples',
  metaDataRegex: 'Meta Data regex issue',
  // location error
  locationPages: 'Pages is required',
  locationCoordinates: 'Coordinates are required',
  // extract tags error
  extractTagsPath: 'Extract tags require paths',
  extractTagsExample: 'Extract tags require examples',
  // path error
  pathExample: 'Path require examples or custom path',
  // example error
  exampleURL: 'Example URL is required',
  exampleOccur: 'Example Occurrence is required',
  // elements errors
  defaultValue: 'Default data type issue (wrong value)',
  defaultType: 'Default type issue (not found)'
};

// ------ SUCCESS MESSAGE -----

export const SUCCESS_MESSAGES = {
  specCreated: 'Spec has right formation.'
};

// ------ Info (show to user with tooltips) ------

export const INFO_HELP = {
  CONFIGURATION: 'Includes category and steps',
  CATEGORY_CONVERSION: 'Responsible for applied categories',
  STEPS_LIST: 'List of all steps',
  STEP: 'Includes info about step config',
  CONTEXT: 'Show which block will be handed over to next step',
  ACTION: 'Action that should be done with current step',
  RELATION: 'Show relation that should be created with current step',
  RESET_COUNTER: 'Help to reset counter fro multi language docs',
  DOC: 'Include information about collected meta data',
  META_DATA: 'Include path to collected meta data',
  EXTRACT_TAGS: 'Include path that should we removed',
  PATH: 'Collection of examples',
  EXAMPLE: 'Used to generate exact path to elements'
};

export const SETTINGS_TOOLTIP = {
  tooltipX: '-15px',
  tooltipY: '5px',
  tooltipArrowX: '20px',
  tooltipArrowY: '-9px'
};

export const SPEC_OPTIONS = {
  DATE_OPTIONS: 'date_options',
  CODE_FILTER_OPTIONS: 'code_filter_options'
};

export const CODE_FILTER_EXTRA_OPTION = {
  value: "custom_if_else_code_filter_string",
  label: "SIMPLE CUSTOM FILTER STRING / NUMBERS"
};

export const API_TABLE_DEFAULT_OPTIONS = [
  { label: 'legal_api_docs', value: 'legal_api_docs' },
  { label: 'fed_api_docs', value: 'fed_api_docs' }
];

export const META_TABLE_DEFAULT_OPTIONS = [
  { label: 'enforcements', value: 'enforcements' },
  { label: 'agency_updates', value: 'agency_updates' },
  { label: 'keyword', value: 'keyword' },
  { label: 'bill_docket', value: 'bill_docket' },
  { label: 'mainstream_news', value: 'mainstream_news' },
  { label: 'proposed_rule', value: 'proposed_rule' },
  { label: 'rules', value: 'rules' }
];

export const TEMPLATE_LABEL_VALUE = {
  label: 'random',
  value: 'random'
};
