import type { LogsModalData } from './useLogsModalData.types';
import type { LogsModalHandlers } from './useLogsModalHandlers.types';

export const useLogsModalHandlers = ({
  localActions
}: {
  localActions: LogsModalData['localActions'];
}): LogsModalHandlers => {
  const handleModalToggle: LogsModalHandlers['handleModalToggle'] = isOpen => () => {
    localActions.setIsOpen(isOpen);
  };

  return {
    handleModalToggle
  };
};
