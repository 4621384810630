import type { FC } from 'react';
import type { FiltersProps } from './Filters.types';

import * as elements from './elements';
import * as uiLib from '@compliance.ai/web-components';

import { useFiltersData, useFiltersHandlers } from './hooks';

import './_filters.scss';

/**
 *
 * TODO Refactor using UI lib's Filters. Note: currently they don't have number inputs
 *
 */
export const Filters: FC<FiltersProps> = ({ onChange }) => {
  const { localState, localActions, formattedData } = useFiltersData();

  const handlers = useFiltersHandlers({
    props: {
      onChange
    },
    localState,
    localActions
  });

  return (
    <uiLib.ClickAwayListener onClickAway={handlers.handleFiltersToggle(false)}>
      <div className="crawl-activity-table-filters__container">
        <uiLib.Button onClick={handlers.handleFiltersToggle(true)}>
          {formattedData.filtersButtonText}
        </uiLib.Button>
        <elements.FiltersModal
          clearSearch={handlers.handleFiltersClear}
          requestCrawls={handlers.handleFiltersApply}
          changeFilter={handlers.handleFiltersChange}
          handleChangesEvent={handlers.handleFilterInputChange}
          search={localState.filters}
          show={localState.areFiltersOpen}
        />
      </div>
    </uiLib.ClickAwayListener>
  );
};
