import Tabs from '../../components/Tabs';
import * as elements from './elements';

import { useSourcePageData, useSourcePageHandlers, useSourcePageLifecycle } from './hooks';

const SourcePage = () => {
  const { formattedData, reduxState } = useSourcePageData();
  const handlers = useSourcePageHandlers({ formattedData });
  useSourcePageLifecycle({ onInitialDataFetch: handlers.handleLoadNewsSource });

  return (
    <>
      <Tabs
        className="sources_tabs"
        headClassName="sources_tabs_head"
        tabClassName="sources_tabs_tab"
        extraInfo={
          <elements.SourcePageStatus
            onChangeStatus={handlers.handleUpdateNewsSourceStatus}
            newsSourceDetails={reduxState.newsSource}
            status={formattedData.isActive}
          />
        }
      >
        <elements.SourceDetails label="Source Details" />
      </Tabs>
    </>
  );
};

export default SourcePage;
