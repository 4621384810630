import type * as apiTypes from './caiCategories.apiV2.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchCaiCategoriesData: apiTypes.FetchCaiCategoriesData = async params => {
  return fetch({
    url: `/cai-categories/`,
    dataType: 'CAI Categories',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const modifyCaiCategory: apiTypes.ModifyCaiCategory = (id, params) => {
  return fetch({
    url: `/cai-categories/${id}`,
    method: 'PATCH',
    dataType: 'CAI Categories',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    shouldHideErrorNotifications: true
  });
};

export const addCaiCategory: apiTypes.AddCaiCategory = params => {
  return fetch({
    url: `/cai-categories/`,
    method: 'POST',
    dataType: 'CAI Categories',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params,
    shouldHideErrorNotifications: true
  });
};

export const splitCaiCategory: apiTypes.SplitCaiCategory = params => {
  return fetch({
    url: `/cai-categories/split/`,
    method: 'POST',
    dataType: 'CAI Categories',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const mergeCaiCategory: apiTypes.MergeCaiCategory = params => {
  return fetch({
    url: `/cai-categories/merge/`,
    method: 'POST',
    dataType: 'CAI Categories',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
