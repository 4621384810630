import {
  REQUEST_ALL_SHARED_FOLDERS,
  RECEIVE_ALL_SHARED_FOLDERS,
  ADD_NEW_FOLDER,
  NEW_FOLDER_ADDED,
  REQUEST_FOLDERS,
  RECEIVE_FOLDERS,
  CHANGE_SELECTED_FOLDER,
  CLEAR_SELECTED_FOLDER,
  REQUEST_FOLDER_DOCUMENTS,
  RECEIVE_FOLDER_DOCUMENTS,
  DOCUMENTS_ADDED_TO_FOLDER,
  CLEAR_FOLDER_DOCUMENTS,
  DELETE_CONFIRM_FOLDER_OPEN,
  DELETE_CONFIRM_FOLDER_CLOSE,
  SHARE_FOLDER_MENU_OPEN,
  SHARE_FOLDER_MENU_CLOSE,
  COPY_FOLDER_MENU_OPEN,
  COPY_FOLDER_MENU_CLOSE,
  OPEN_ADD_FOLDER_MENU,
  CLOSE_ADD_FOLDER_MENU
} from './folders.actions';

export const getInitialState = () => ({
  isFetching: false,
  isReady: false,
  personal_folders: [],
  shared_folders: [],
  document_folders: {},
  selected_folder: {},
  documents: {},
  add_folder_menu_open: false,
  new_folder_being_added: false,
  shared_folder_users: [],
  shared_folder_permissions: [],
  user_shared_folders_dict: [],
  delete_confirm_open: false,
  share_folder_menu_open: false,
  copy_folder_menu_open: false,
  addSharedMenu: false,
  updateSharedFolder: false,
  shared_menu: ''
});

export const user_folder = (state = getInitialState(), action) => {
  switch (action.type) {
    case REQUEST_FOLDERS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_FOLDERS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        personal_folders: action.personal_folders,
        shared_folders: action.shared_folders,
        document_folders: action.document_folders
      };
    case REQUEST_FOLDER_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_FOLDER_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        documents: action.data
      };
    case CLEAR_FOLDER_DOCUMENTS:
      return {
        ...state,
        documents: {}
      };
    case CHANGE_SELECTED_FOLDER:
      return {
        ...state,
        selected_folder: action.folder
      };
    case CLEAR_SELECTED_FOLDER:
      return {
        ...state,
        selected_folder: {}
      };
    case OPEN_ADD_FOLDER_MENU:
      return {
        ...state,
        add_folder_menu_open: true
      };
    case CLOSE_ADD_FOLDER_MENU:
      return {
        ...state,
        add_folder_menu_open: false
      };
    case ADD_NEW_FOLDER:
      return {
        ...state,
        new_folder_being_added: true
      };
    case NEW_FOLDER_ADDED:
      return {
        ...state,
        new_folder_being_added: false
      };
    case DOCUMENTS_ADDED_TO_FOLDER:
      return {
        ...state,
        document_folders: action.document_folders
      };
    case DELETE_CONFIRM_FOLDER_OPEN:
      return {
        ...state,
        delete_confirm_open: true
      };
    case DELETE_CONFIRM_FOLDER_CLOSE:
      return {
        ...state,
        delete_confirm_open: false
      };
    case SHARE_FOLDER_MENU_OPEN:
      return {
        ...state,
        share_folder_menu_open: true,
        shared_menu: action.menu
      };
    case SHARE_FOLDER_MENU_CLOSE:
      return {
        ...state,
        share_folder_menu_open: false,
        shared_menu: action.menu
      };
    case COPY_FOLDER_MENU_OPEN:
      return {
        ...state,
        copy_folder_menu_open: true
      };
    case COPY_FOLDER_MENU_CLOSE:
      return {
        ...state,
        copy_folder_menu_open: false
      };
    case REQUEST_ALL_SHARED_FOLDERS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ALL_SHARED_FOLDERS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user_shared_folders_dict: action.user_shared_folders_dict
      };
    default:
      return state;
  }
};
