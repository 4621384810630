import type { FiltersProps } from '../Filters.types';
import type { FiltersHandlers } from './useFiltersHandlers.types';
import type { FiltersData } from './useFiltersData.types';

import * as helpers from './useFiltersHandlers.helpers';

import { useCallback } from 'react';

export const useFiltersHandlers = ({
  props,
  localState,
  localActions
}: {
  props: Pick<FiltersProps, 'onChange'>;
  localState: FiltersData['localState'];
  localActions: FiltersData['localActions'];
}): FiltersHandlers => {
  const handleFiltersToggle: FiltersHandlers['handleFiltersToggle'] = isOpen => () => {
    localActions.setAreFiltersOpen(isOpen);
  };

  const handleFiltersClear: FiltersHandlers['handleFiltersClear'] = () => {
    localActions.setFilters({});
  };

  const handleFiltersChange: FiltersHandlers['handleFiltersChange'] = (name, value) => {
    localActions.setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleFilterInputChange: FiltersHandlers['handleFilterInputChange'] = e => {
    const { name, value } = e.target;

    localActions.setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleFiltersApply: FiltersHandlers['handleFiltersApply'] = useCallback(() => {
    props.onChange(helpers.formatFilterValues(localState.filters));
    localActions.setAreFiltersOpen(false);
  }, [localActions, localState.filters, props]);

  return {
    handleFiltersClear,
    handleFiltersToggle,
    handleFiltersChange,
    handleFilterInputChange,
    handleFiltersApply
  };
};
