import {
  RECEIVE_METRICS,
  CLEAR_METRICS
} from './metric.actions';

export const getInitialMetricState = () => null;

export const metrics = (state = getInitialMetricState(), action) => {
  switch (action.type) {
    case RECEIVE_METRICS: {
      return action.metrics;
    }
    case CLEAR_METRICS: {
      return null;
    }
    default:
      return state;
  }
};
