import {fetch, BASE_API_OPTIONS} from "fetch";

const api_createComment = (params) => {
  return fetch({
    url: `/comment_crawler`,
    dataType: 'Crawler',
    method: 'POST',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
};

const api_getComments = (crawlID, params) => {
  return fetch({
    url: `/comment_crawler/${crawlID}`,
    dataType: 'Crawler',
    method: 'GET',
    params,
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
};

export { api_getComments, api_createComment };
