import React, { useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Typography from "./Typography";
import Spinner from "./spinner";

function Table({
  title,
  header,
  footer,
  colgroup,
  data,
  className,
  leftFixedColumns = 0,
  topFixedColumns = 0,
  classNameCaption,
  classNameHeader,
  classNameBody,
  classNameFooter,
  onClick,
  load
}) {
  useEffect(() => {
    // count / attach sticky styles if leftFixedColumns or topFixedColumns
    if (leftFixedColumns || topFixedColumns) {
      // get all rows
      const allRows = document.querySelectorAll('.custom-table .custom-table_wrapper tr');
      allRows.forEach((row, rowIndex) => {
        // predefined values
        let topStickySpace = 0;
        let leftStickySpace = 0;
        const cells = row.querySelectorAll('td');
        cells.forEach((cell, cellIndex) => {
          // fixed left cell styles
          if (cellIndex < leftFixedColumns) {
            // add sticky styles for sticky cell (column)
            cell.classList.add('stickyLeftFixedCell');
            // add shadow to the last sticky cell (column)
            if (cellIndex === leftFixedColumns - 1) {
              cell.classList.add('lastLeftStickyCell');
            }
            // add left gap for sticky cell (column)
            const stickyElemWidth = cell.offsetWidth;
            // count gap for next cell (column)
            cell.style.left = `${leftStickySpace}px`;
            leftStickySpace += stickyElemWidth;
          }
          // fixed top cell styles
          if (rowIndex < topFixedColumns) {
            // add sticky styles for sticky cell (row)
            cell.classList.add('stickyHeaderCell');
            // add top gap for sticky cell (row)
            cell.style.top = `${topStickySpace}px`;
            // add styles for sticky cell (column + row)
            if (cellIndex < leftFixedColumns) {
              cell.classList.add('stickyHeaderLeftFixedCell');
            }
          }
        });
        if (rowIndex < topFixedColumns) {
          // count gap for next row
          const stickyElemHeight = row.offsetHeight;
          topStickySpace += stickyElemHeight;
        }
      });
    }
  });


  const getContent = (content, clickable) => content.map((items, indexTR) => (
    <tr
      onClick={() => clickable && onClick(items, indexTR)}
      key={indexTR}
    >
      {items.map((item, indexTD) => {
        const value = _.get(item, 'value', item);
        const titleTR = String(value);
        const disableTitle = _.get(item, 'disableTitle', false)
          || React.isValidElement(item);
        return (
          <td
            key={`${indexTR},${indexTD},${titleTR}`}
            title={disableTitle ? '' : titleTR}
            colSpan={_.get(item, 'colspan', 1)}
            rowSpan={_.get(item, 'rowspan', 1)}
          >
            <Typography variant="p" className="custom-table_content-wrapper">
              {value}
            </Typography>
          </td>
        );
      })}
    </tr>
  ));

  const getSpinner = () => (
    <tr align="center">
      <td colSpan="100%" className="custom-table_spinner">
        <Spinner />
      </td>
    </tr>
  );

  return (
    <div className="custom-table">
      <table className={cn(className, 'custom-table_wrapper')}>
        <caption className={cn('custom-table_caption', classNameCaption)}>
          <Typography variant="h5">
            {title}
          </Typography>
        </caption>
        <colgroup>
          {colgroup.map(item => <col span={item.span} className={item.className} />)}
        </colgroup>
        <thead className={cn('custom-table_head', classNameHeader)}>
          {getContent(header)}
        </thead>
        <tbody className={cn('custom-table_body', classNameBody)}>
          {load ? getSpinner() : getContent(data, true)}
        </tbody>
        <tfoot className={cn('custom-table_footer', classNameFooter)}>
          {getContent(footer)}
        </tfoot>
      </table>
    </div>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  colgroup: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    span: PropTypes.number
  })),
  header: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  footer: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  className: PropTypes.string,
  classNameCaption: PropTypes.string,
  classNameHeader: PropTypes.string,
  classNameBody: PropTypes.string,
  classNameFooter: PropTypes.string,
  onClick: PropTypes.func,
  leftFixedColumns: PropTypes.number,
  topFixedColumns: PropTypes.number,
  load: PropTypes.bool
};

Table.defaultProps = {
  title: '',
  colgroup: [],
  header: [],
  footer: [],
  className: '',
  classNameCaption: '',
  classNameHeader: '',
  classNameBody: '',
  classNameFooter: '',
  onClick: () => {
  },
  leftFixedColumns: 0,
  topFixedColumns: 0,
  load: false
};

export default Table;
