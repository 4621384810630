import type { TabsProps } from '@compliance.ai/web-components';

import React from 'react';
import CaiCategories from './CaiCategories';
import ScrapedCategories from './ScrapedCategories';

export const CATEGORIES_TAB_QUERY_PARAM = 'categoriesTab';

export enum CATEGORIES_TAB_KEYS {
  CAI_CATEGORIES = 'cai_categories',
  SCRAPED_CATEGORIES = 'scraped_categories'
}

export const CATEGORIES_TABS: Required<TabsProps>['tabs'] = [
  {
    title: 'Cai Categories',
    content: <CaiCategories />,
    [CATEGORIES_TAB_QUERY_PARAM]: CATEGORIES_TAB_KEYS.CAI_CATEGORIES
  },
  {
    title: 'Scraped Categories',
    content: <ScrapedCategories />,
    [CATEGORIES_TAB_QUERY_PARAM]: CATEGORIES_TAB_KEYS.SCRAPED_CATEGORIES
  }
];
