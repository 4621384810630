import {
  RECEIVE_COLLECTIONS,
  CLEAR_COLLECTIONS
} from './collection.actions';

export const getInitialCollectionState = () => null;

export const collections = (state = getInitialCollectionState(), action) => {
  switch (action.type) {
    case RECEIVE_COLLECTIONS: {
      return action.collections;
    }
    case CLEAR_COLLECTIONS: {
      return null;
    }
    default:
      return state;
  }
};
