import { useMemo } from 'react';
import _ from 'lodash';
import { api_getCategories } from 'shared/features/categories/categories/categories.api';
import { api_getMetaCategories } from 'shared/features/categories/metaCategories/metaCategories.api';
import { api_getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.api';

import { TABLE_VARIABLES, ERRORS } from './DocumentCategories.constants';
import { NOTIFICATION_TYPES, setNotification } from '../../components/Notification';

// ----- Load Options -----

const formatSelectOptions = (array, valueName, labelName) =>
  array.map(item => ({
    value: item[valueName],
    label: item[labelName]
  }));

export const loadCategoryOptions = (name, searchValue, responseName) =>
  api_getCategories({ [name]: searchValue })
    .then(cat => cat.items)
    .then(cat => formatSelectOptions(cat, responseName, responseName));

export const loadMetaCategoryOptions = (name, searchValue, responseName) =>
  api_getMetaCategories({ [name]: searchValue })
    .then(cat => cat.items)
    .then(cat => formatSelectOptions(cat, 'id', responseName));

export const loadJurisdictionsOptions = (name, searchValue, responseName) =>
  api_getJurisdictions({ [name]: searchValue })
    .then(cat => cat.items)
    .then(cat => formatSelectOptions(cat, responseName, responseName));

// ----- Format Info For Table -----

const getCategoryInfo = category => {
  return {
    [TABLE_VARIABLES.id]: _.get(category, 'id', null),
    [TABLE_VARIABLES.scraped_category]: _.get(category, 'scraped_category', ''),
    [TABLE_VARIABLES.app_category]: _.get(category, 'app_category', ''),
    [TABLE_VARIABLES.document_meta_category]: _.get(
      category,
      ['document_meta_category', 'name'],
      ''
    ),
    [TABLE_VARIABLES.surface_docs]: _.get(category, 'surface_docs', false),
    [TABLE_VARIABLES.surface_category]: _.get(category, 'surface_category', false),
    [TABLE_VARIABLES.jurisdictions]: _.get(category, 'jurisdictions', []),
    [TABLE_VARIABLES.doc_category_id]: _.get(category, 'doc_category_id', [])
  };
};

export const formatDocCategories = categories =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMemo(() => categories.map(getCategoryInfo), [categories]);

export const formatSearchValues = searchValues => {
  const formatParameters = {};
  for (const key in searchValues) {
    if (Object.prototype.hasOwnProperty.call(searchValues, key)) {
      let value = null;
      switch (key) {
        case TABLE_VARIABLES.document_meta_category:
        case TABLE_VARIABLES.jurisdictions:
        case TABLE_VARIABLES.scraped_category:
        case TABLE_VARIABLES.app_category:
        case TABLE_VARIABLES.surface_category:
        case TABLE_VARIABLES.surface_docs:
          value = _.get(searchValues, [key, 'value'], null);
          break;
        default:
          break;
      }
      if (_.isBoolean(value) || value) formatParameters[key] = value;
    }
  }
  return formatParameters;
};

// ----- Format Info For Edit / Create -----

const getAppCategory = category => {
  const appCategory = _.get(category, 'app_category', null);
  return appCategory ? { value: appCategory, label: appCategory } : null;
};

const getScratchCategory = category => {
  const scratchCategory = _.get(category, 'scraped_category', null);
  return scratchCategory ? { value: scratchCategory, label: scratchCategory } : null;
};

const getMetaCategory = category => {
  const metaCategory = _.get(category, 'document_meta_category', null);
  const name = _.get(metaCategory, 'name', null);
  const id = _.get(metaCategory, 'id', null);
  return name && id ? { value: id, label: name } : null;
};

const getJurisdictions = category => {
  const jurisdictions = _.get(category, 'jurisdictions', null);
  return jurisdictions && Array.isArray(jurisdictions)
    ? jurisdictions.map(item => ({ value: item, label: item }))
    : null;
};

export const getFormatCategoryInfo = category => {
  return {
    [TABLE_VARIABLES.id]: _.get(category, 'id', null),
    [TABLE_VARIABLES.app_category]: getAppCategory(category),
    [TABLE_VARIABLES.scraped_category]: getScratchCategory(category),
    [TABLE_VARIABLES.document_meta_category]: getMetaCategory(category),
    [TABLE_VARIABLES.surface_docs]: _.get(category, 'surface_docs', false),
    [TABLE_VARIABLES.surface_category]: _.get(category, 'surface_category', false),
    [TABLE_VARIABLES.indexed]: _.get(category, 'indexed', false),
    [TABLE_VARIABLES.category_details]: _.get(category, 'category_details', ''),
    [TABLE_VARIABLES.description]: _.get(category, 'description', ''),
    [TABLE_VARIABLES.doc_category_id]: _.get(category, 'doc_category_id', null),
    [TABLE_VARIABLES.jurisdictions]: getJurisdictions(category)
  };
};

export const fromCategoryToRawInfo = (newInfo, oldInfo) => {
  const category = {};
  const errors = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }) => {
    const valueNew = predefinedValue || _.get(newInfo, newPath, null);
    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(category, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew);
        if (result) errors.push({ text: result, title: newPath[0] });
      }
    }
  };

  setDifference({
    newPath: [TABLE_VARIABLES.app_category, 'value'],
    oldPath: ['app_category']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.scraped_category, 'value'],
    oldPath: ['scraped_category']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.document_meta_category, 'value'],
    oldPath: ['document_meta_categories_id']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.surface_docs],
    oldPath: ['surface_docs']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.surface_category],
    oldPath: ['surface_category']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.indexed],
    oldPath: ['indexed']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.category_details],
    oldPath: ['category_details']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.doc_category_id],
    oldPath: ['doc_category_id']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.description],
    oldPath: ['description']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.jurisdictions],
    oldPath: ['jurisdictions'],
    predefinedValue:
      Array.isArray(newInfo[TABLE_VARIABLES.jurisdictions]) &&
      newInfo[TABLE_VARIABLES.jurisdictions].map(item => item.label)
  });

  if (errors.length) {
    errors.forEach(error =>
      setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR, width: 800 })
    );
    return null;
  }

  return category;
};

// ----- Combine Categories Helper -----

export const checkCombinedCategories = categories => {
  const error = [];

  if (categories.length < 2) {
    error.push(ERRORS.errorCombineLength);
  }
  const appCategory = _.get(categories, [0, 'app_category'], '');
  if (!categories.every(item => item.app_category === appCategory)) {
    error.push(ERRORS.errorAppCategory);
  }
  const scrapedCategory = _.get(categories, [0, 'scraped_category'], '');
  if (!categories.every(item => item.scraped_category === scrapedCategory)) {
    error.push(ERRORS.errorScrapedCategory);
  }
  const docCategoryId = _.get(categories, [0, 'doc_category_id'], '');
  if (!categories.every(item => item.doc_category_id === docCategoryId)) {
    error.push(ERRORS.errorDocCategoryId);
  }

  if (error.length) {
    error.forEach(item => {
      setNotification({
        message: item,
        type: NOTIFICATION_TYPES.ERROR,
        width: 800
      });
    });
    return null;
  }

  return {
    ...categories[0],
    jurisdictionsAll: [
      ...new Set(categories.reduce((accum, item) => [...accum, ...item.jurisdictions], []))
    ],
    categoriesIds: categories.map(item => item.id)
  };
};

// ----- Split Categories Helper -----

export const checkSplitCategory = categories => {
  const error = [];

  if (!categories.length) {
    error.push(ERRORS.errorSplitLength);
  }
  const jurisdictions = _.get(categories, [0, 'jurisdictions'], []);
  if (jurisdictions.length < 2) {
    error.push(ERRORS.errorJurisdictions);
  }

  if (error.length) {
    error.forEach(item => {
      setNotification({
        message: item,
        type: NOTIFICATION_TYPES.ERROR,
        width: 800
      });
    });
    return null;
  }

  return categories[0];
};

export const checkNewSplitCategory = (updateCategory, newCategory) => {
  const error = [];

  if (!updateCategory[TABLE_VARIABLES.jurisdictions].length) {
    error.push(ERRORS.errorSplitJurisdictions);
  }
  if (!newCategory[TABLE_VARIABLES.jurisdictions].length) {
    error.push(ERRORS.errorSplitJurisdictions);
  }
  if (
    _.get(updateCategory, [TABLE_VARIABLES.document_meta_category, 'value']) ===
    _.get(newCategory, [TABLE_VARIABLES.document_meta_category, 'value'])
  ) {
    error.push(ERRORS.errorMetaCategory);
  }

  if (error.length) {
    error.forEach(item => {
      setNotification({
        message: item,
        type: NOTIFICATION_TYPES.ERROR,
        width: 800
      });
    });
    return null;
  }

  return {
    update: updateCategory,
    new: newCategory
  };
};

// export only for tests
export { formatSelectOptions, getCategoryInfo };
