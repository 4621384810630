import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import StyledMaterialButton from "./StyledMaterialButton";
import './_button.scss';

export const TEST_ID = 'button-test-id';

export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  CANCEL: 'cancel',
  APPLY: 'apply',
  DELETE: 'delete',
  TERTIARY: 'tertiary',
  TERTIARY_BOLD: 'tertiary-bold'
};

export const CustomizedMaterialButton = ({
  onClick,
  type,
  startIcon,
  text,
  children,
  disabled,
  className,
  dataTestId,
  ...props
}) => {
  return (
    <StyledMaterialButton
      data-testid={dataTestId}
      buttontype={type}
      onClick={onClick}
      startIcon={startIcon}
      variant="contained"
      className={className}
      disabled={disabled}
      {...props}
    >
      {text || children}
    </StyledMaterialButton>
  );
};

export const DefaultButton = ({
  onClick,
  text,
  children,
  disabled,
  className,
  dataTestId,
  ...props
}) => {
  return (
    <button
      data-testid={dataTestId}
      type="button"
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {text || children}
    </button>
  );
};

const BUTTON_COMPONENTS = {
  [BUTTON_TYPES.PRIMARY]: CustomizedMaterialButton,
  [BUTTON_TYPES.SECONDARY]: CustomizedMaterialButton,
  [BUTTON_TYPES.TERTIARY]: DefaultButton,
  [BUTTON_TYPES.TERTIARY_BOLD]: DefaultButton,
  [BUTTON_TYPES.APPLY]: DefaultButton,
  [BUTTON_TYPES.CANCEL]: DefaultButton,
  [BUTTON_TYPES.DELETE]: DefaultButton
};

const Button = ({ onClick, type, startIcon, text, children, disabled, className, ...props }) => {
  const ButtonComponent = BUTTON_COMPONENTS[type];

  const buttonClassName = classNames(className, `button-${type}`);

  return (
    <ButtonComponent
      onClick={onClick}
      type={type}
      text={text}
      disabled={disabled}
      className={buttonClassName}
      {...props}
    >
      {children}
    </ButtonComponent>
  );
};

export default Button;

Button.propTypes = {
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  startIcon: PropTypes.element,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: BUTTON_TYPES.PRIMARY,
  startIcon: null,
  onClick: () => {},
  text: null,
  className: '',
  children: null,
  dataTestId: TEST_ID,
  disabled: false
};
