import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, button, FormControl } from 'react-bootstrap';
import { createOrganizations } from 'shared/features/organizations/organizations.actions';
import ROUTES from '../constants/routes';

class CreateOrganizations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      url: '',
      address: '',
      phone: ''
    };
  }

  CreateOrg = event => {
    event.preventDefault();
    const params = {};
    const properties = {};
    params.name = this.state.name;
    params.properties = properties;
    properties.url = this.state.url;
    properties.address = this.state.address;
    properties.phone = this.state.phone;
    properties.workflow_emails = {
      task_created: true,
      task_near_due: true,
      task_overdue: true,
      task_done: true
    };

    this.props.createOrganizations(params).then(() => {
      window.location=`#${ROUTES.organizations}`;
    });
  };

  OrgFieldChange = (changedfieldname, event) => {
    const new_state = {};
    new_state[changedfieldname] = event.target.value;
    this.setState(new_state);
  };

  render() {
    return (
      <div className='single-org-container'>
        <h1><b>Create an Organization</b></h1>
        <form action="src/admin/containers" onSubmit={this.CreateOrg}>
          <Table striped condensed hover className='organizations-detail-table'>
            <tbody>
              <tr>
                <td>
                  <h1>
                    <span>
                      <FormControl
                        onChange={e => this.OrgFieldChange('name', e)}
                        value={this.state.name}
                      />
                    </span>
                  </h1>
                  <p>
                    <span>
                      <b>URL: </b>
                      <FormControl onChange={e => this.OrgFieldChange('url', e)} value={this.state.url} />
                    </span>

                  </p>
                  <p>
                    <span>
                      <b>Address: </b>
                      <FormControl onChange={e => this.OrgFieldChange('address', e)} value={this.state.address} />
                    </span>
                  </p>
                  <p>
                    <span>
                      <b>Phone: </b>
                      <FormControl onChange={e => this.OrgFieldChange('phone', e)} value={this.state.phone} />
                    </span>
                  </p>
                </td>
                <td className="allorg-team-id">
                  <p><span><b>Teams: </b></span></p>
                  <p>
                    <span className='org-team-id-show'><b>ID: </b></span>
                  </p>
                </td>
                <td>
                  <p className='createOrgButtons'>
                    <Link to='/Organizations'>
                      <button type="button" className='org-btn-cancel'>Cancel</button>
                    </Link>
                    <button type="submit" className='org-btn-create'>Create</button>
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createOrganizations: data => {
      return dispatch(createOrganizations(data));
    }
  };
};

const mapStateToProps = state => {
  return {
    organization: state.organization
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganizations);
