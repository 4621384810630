import type { CrawlerHistoryData } from './useCrawlerHistoryData.types';
import type { GlobalState } from 'shared/reducers';

import * as externalApiCrawlSelectors from 'shared/features/externalApiCrawl/externalApiCrawl.selectors';
import * as crawlerHistorySelectors from 'shared/features/crawlerSettings/crawlerHistory/crawlerHistory.selectors';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useCrawlerHistoryData = (): CrawlerHistoryData => {
  const { id } = useParams();

  const reduxState = useSelector<GlobalState, CrawlerHistoryData['reduxState']>(state => ({
    history: crawlerHistorySelectors.getHistoryList(state),
    crawlDetailsInfo: externalApiCrawlSelectors.getFirstExternalApiCrawlElement(state)
  }));

  const formattedData: CrawlerHistoryData['formattedData'] = useMemo(() => ({ id }), [id]);

  return {
    reduxState,
    formattedData
  };
};
