import * as cognitoUtils from 'shared/utils/cognitoUtils';
import * as errorUtils from 'shared/utils/errors';

import { userPoolId, clientId } from 'shared/config';
import { fetch } from 'shared/utils/api';
import { EXPECTED_ERROR_MESSAGES } from './auth.constants';
import { getErrorMessageFromResponse } from './auth.api.helpers';

export const authenticateUsingCognito = async ({ email, password }) => {
  try {
    const { accessToken, idToken, refreshToken } = await cognitoUtils.signIn({
      username: email,
      password
    });

    const { jwt_token: JWTToken } = await fetch({
      url: '/auth_cognito_jwt_token',
      method: 'POST',
      params: {
        token: idToken,
        user_pool_id: userPoolId,
        app_client_id: clientId
      }
    });

    return {
      JWTToken,
      accessToken,
      idToken,
      refreshToken
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const authenticateUsingApi = async ({ email, password }) => {
  try {
    const { jwt_token } = await fetch({
      url: '/login',
      method: 'POST',
      params: {
        email,
        password
      }
    });

    return {
      JWTToken: jwt_token,
      accessToken: null,
      idToken: null,
      refreshToken: null
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const authenticate = async ({ email, password }) => {
  try {
    return authenticateUsingCognito({ email, password });
  } catch (e) {
    const errorMessage = getErrorMessageFromResponse(e);

    if (errorMessage === EXPECTED_ERROR_MESSAGES.USER_DOES_NOT_EXIST) {
      return authenticateUsingApi({ email, password });
    }

    throw new Error(errorMessage);
  }
};

export const signUp = async ({ email, password, orgInvitationToken }) => {
  try {
    return await fetch({
      url: '/activate_cognito',
      method: 'POST',
      params: {
        email,
        password,
        org_invitation_token: orgInvitationToken
      }
    });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const confirmRegisteredUser = async ({ email, code }) => {
  try {
    await cognitoUtils.confirmRegisteredUser({
      username: email,
      code
    });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const refreshSession = async () => {
  try {
    const { accessToken, idToken, refreshToken } = await cognitoUtils.refreshSession();

    const { jwt_token: JWTToken } = await fetch({
      url: '/auth_cognito_jwt_token',
      method: 'POST',
      params: {
        token: idToken,
        user_pool_id: userPoolId,
        app_client_id: clientId
      }
    });

    return {
      JWTToken,
      accessToken,
      idToken,
      refreshToken
    };
  } catch (e) {
    throw new errorUtils.NetworkError({
      message: getErrorMessageFromResponse(e),
      status: e?.status
    });
  }
};

export const sendPasswordResetEmail = async ({ email }) => {
  try {
    await cognitoUtils.passwordReset(email);
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const confirmNewPassword = async ({ email, verificationCode, newPassword }) => {
  try {
    await cognitoUtils.confirmNewPassword({ email, verificationCode, newPassword });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  try {
    await cognitoUtils.changePassword({ oldPassword, newPassword });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const signOut = async () => {
  try {
    await cognitoUtils.signOut();
  } catch (e) {
    const errorMessage = getErrorMessageFromResponse(e);

    if (errorMessage === EXPECTED_ERROR_MESSAGES.CURRENT_USER_IS_NOT_DETECTED) {
      return;
    }

    throw new Error(errorMessage);
  }
};
