import type { CategorySplitModalData } from './useCategorySplitModalData.types';
import type * as caiCateogryApiTypes from 'shared/features/categories/caiCategories/caiCategories.apiV2.types';

import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../CategorySplitModal.constants';

import { useMutation } from '@tanstack/react-query';
import { useCaiCategoriesApi } from 'shared/features/categories/caiCategories/hooks';

export const useCategorySplitModalMutations = ({
  localState,
  localActions
}: {
  localState: CategorySplitModalData['localState'];
  localActions: CategorySplitModalData['localActions'];
}) => {
  const caiCategoriesApi = useCaiCategoriesApi();

  const splitCaiCategory = useMutation<
    Awaited<caiCateogryApiTypes.NormalizedCaiCategory>,
    Error,
    void
  >({
    mutationKey: [queryKeysConstants.QUERY_KEYS.CAI_CATEGORIES],
    onError: error => {
      errorUtils.logReactQueryError(error);
    },
    mutationFn: () =>
      caiCategoriesApi.splitCaiCategory({
        new_cai_category: localState.docCaiCategory as uiLib.SelectOption,
        cai_category_to_split: localState.categoryToSplit?.id as number,
        new_meta_category: localState.docMetaCategory?.value,
        scraped_categories_to_split: localState.selectedCategories
      }),
    onSuccess: normalizedCaiCategory => {
      localActions.setDocCaiCategory({
        ...normalizedCaiCategory,
        label: normalizedCaiCategory.name,
        value: normalizedCaiCategory.id,
        type: constants.CREATED_TYPE
      });
      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        message: 'Cai Category successfully added'
      });
    }
  });

  return {
    splitCaiCategory
  };
};
