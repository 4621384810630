import React from "react";
import {
  FormControl,
  ControlLabel,
  FormGroup,
  Checkbox
} from "react-bootstrap";
import Select, { Creatable } from "react-select";
import classnames from 'classnames';
import PopoverInfo from "./CrawlCreationPopoverInfo";

const typeOfField = {
  select: 'SELECT',
  createSelect: 'CREATE_SELECT',
  input: 'INPUT',
  textarea: 'TEXTAREA',
  checkbox: 'CHECKBOX'
};

function buildCreateSelectCategories(defaultCategory, id_or_name) {
  if (!defaultCategory.some(item => item.value === id_or_name)) {
    defaultCategory.push({
      value: id_or_name,
      label: id_or_name
    });
  }
  return defaultCategory;
}

function SelectRender(
  {
    item,
    state,
    handleChange,
    style = [],
    error,
    popover
  }
) {
  const showErrorStyle = item.required
    && !item.disabled
    && (state.error || error)
    && !state[item.state_name];
  return (
    <FormGroup
      key={item.id || item.state_name}
      className={classnames("filterAdminToolWrap_elem", style)}
    >
      <ControlLabel>
        {item.name || item.labelName}
        {popover && (
          <PopoverInfo
            popoverConst={popover}
          />
        )}
      </ControlLabel>
      {item.typeOfInput === typeOfField.createSelect && (
        <Creatable
          className={
            showErrorStyle
              ? "emptyFormSelectAdminToolControl"
              : ""
          }
          options={
            buildCreateSelectCategories(item.categories, state[item.state_name])
          }
          value={state[item.state_name]}
          name={item.state_name}
          onChange={e => {
            handleChange({ target: { name: item.state_name, value: e && e.value } });
          }}
        />
      )}
      {item.typeOfInput === typeOfField.input && (
        <FormControl
          className={
            showErrorStyle
            && !state[item.state_name].trim()
              ? "emptyFormAdminToolControl"
              : ""
          }
          type="text"
          name={item.state_name}
          onChange={(e) => {
            if (!item.reqExp || (item.reqExp && item.reqExp.test(e.target.value))) {
              handleChange(e);
            }
          }}
          value={state[item.state_name]}
          disabled={item.disabled}
        />
      )}
      {item.typeOfInput === typeOfField.textarea && (
        <FormControl
          className={
            showErrorStyle
            && !state[item.state_name].trim()
              ? "emptyFormAdminToolControl"
              : ""
          }
          componentClass="textarea"
          name={item.state_name}
          onChange={(e) => {
            if (!item.reqExp || (item.reqExp && item.reqExp.test(e.target.value))) {
              handleChange(e);
            }
          }}
          value={state[item.state_name]}
          disabled={item.disabled}
        />
      )}
      {item.typeOfInput === typeOfField.select && (
        <Select
          className={
            showErrorStyle
            && typeof state[item.state_name] !== 'boolean'
              ? "emptyFormSelectAdminToolControl"
              : ""
          }
          options={item.categories || []}
          value={state[item.state_name]}
          multi={item.multiType}
          name={item.state_name}
          disabled={item.disabled}
          onChange={e => handleChange({
            target: {
              name: item.state_name,
              value: item.multiType ? e.map(obj => obj.value).join(',') : e && e.value
            }
          })}
        />
      )}
      {item.typeOfInput === typeOfField.checkbox && (
        <Checkbox
          className={
            showErrorStyle
            && typeof state[item.state_name] !== 'boolean'
              ? "emptyFormSelectAdminToolControl"
              : ""
          }
          name={item.state_name}
          checked={state[item.state_name]}
          onChange={e => handleChange({
            target: {
              name: item.state_name,
              value: !state[item.state_name]
            }
          })}
        />
      )}
    </FormGroup>
  );
}

export { typeOfField };
export default SelectRender;
