import React from 'react';
import { OverlayTrigger, Tooltip as ReactBootstrapTooltip } from "react-bootstrap";
import PropTypes from 'prop-types';
import './_tooltip.scss';

const Tooltip = ({
  id,
  title,
  children,
  placement
}) => {
  return (
    <OverlayTrigger
      overlay={(
        <ReactBootstrapTooltip id={id} className={'tooltip'}>
          {title}
        </ReactBootstrapTooltip>
      )}
      placement={placement}
    >
      <span>
        {children}
      </span>
    </OverlayTrigger>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom'])
};

Tooltip.defaultProps = {
  children: null,
  placement: 'top'
};
