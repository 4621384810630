import type {
  CrawlSettingsConfirmationModalMode,
  CrawlSettingsData
} from './useCrawlSettingsData.types';
import type { CrawlSettingsHandlers } from './useCrawlSettingsHandlers.types';
import type { CrawlSettingsCrawlState } from './useCrawlSettingsData.types';

import * as lodash from 'lodash';
import * as uiLib from '@compliance.ai/web-components';
import * as helpers from '../CrawlerSettings.helpers';
import * as constants from '../CrawlerSettings.constants';
import * as crawlerTypesConstants from '../../../constants/crawlersTypes';
import * as crawlerDetailsConstants from '../CrawlerDetails/CrawlerDetails.constants';
import * as commandRequestTypesConstants from '../../../constants/commandRequestTypes';
import { useCallback } from 'react';
import { useCommandsReduxActions } from 'shared/features/commands/hooks';
import { useExternalApiCrawlReduxActions } from 'shared/features/externalApiCrawl/hooks';
import { useCrawlerCommentsReduxActions } from 'shared/features/crawlerSettings/crawlerComments/hooks';
import { useCrawlerHistoryReduxActions } from 'shared/features/crawlerSettings/crawlerHistory/hooks';
import { useCrawlerDocumentsReduxActions } from 'shared/features/crawlerSettings/crawlerDocuments/hooks';
import { useSpiderRunReportsReduxActions } from 'shared/features/crawlerSettings/spiderRunReports/hooks';
import { useCrawlerActivityReduxActions } from 'shared/features/crawlerSettings/crawlerActivity/hooks';

export const useCrawlDetailsHandlers = ({
  reduxState,
  localState,
  localActions,
  formattedData
}: CrawlSettingsData): CrawlSettingsHandlers => {
  const crawlerHistoryReduxActions = useCrawlerHistoryReduxActions();
  const crawlerCommentsReduxActions = useCrawlerCommentsReduxActions();
  const externalApiCrawlReduxActions = useExternalApiCrawlReduxActions();
  const commandsReduxActions = useCommandsReduxActions();
  const crawlerDocumentsReduxActions = useCrawlerDocumentsReduxActions();
  const spiderRunReportReduxActions = useSpiderRunReportsReduxActions();
  const crawlerActivityReduxActions = useCrawlerActivityReduxActions();

  const requestLastSpiderRunReport = useCallback(
    (extraProps = { exclude_types: ['dry_run'], parent_report_id: 0 }) => {
      spiderRunReportReduxActions.getSpiderRunReports({
        crawlId: formattedData.id,
        params: { offset: 0, limit: 1, ...extraProps }
      });
    },
    [formattedData.id, spiderRunReportReduxActions]
  );

  const handleCheckCommandRequest = useCallback(() => {
    if (reduxState.commandRequest.id) {
      return ![
        commandRequestTypesConstants.COMMAND_REQUEST_STATUS.FAILED,
        commandRequestTypesConstants.COMMAND_REQUEST_STATUS.COMPLETED,
        commandRequestTypesConstants.COMMAND_REQUEST_STATUS.TIMED_OUT
      ].includes(reduxState.commandRequest.status);
    }
    return false;
  }, [reduxState.commandRequest.id, reduxState.commandRequest.status]);

  const handleInitialisation = useCallback(() => {
    // clear info
    crawlerHistoryReduxActions.clearCrawHistory();
    crawlerCommentsReduxActions.clearCrawComments();
    externalApiCrawlReduxActions.clearExternalApiCrawls({
      items: [{ id: formattedData.id }],
      count: 1
    });
    // request new info
    if (!isNaN(Number(formattedData.id))) {
      externalApiCrawlReduxActions.getExternalApiCrawls({ id: formattedData.id });
      commandsReduxActions.fetchAllCommandRequests({
        crawl_id: formattedData.id,
        command_name: [
          commandRequestTypesConstants.COMMAND_REQUEST_NAMES.DRY_RUN_CRAWLER_COMMAND,
          commandRequestTypesConstants.COMMAND_REQUEST_NAMES.SPEC_TO_CONFIG_CONVERSION,
          commandRequestTypesConstants.COMMAND_REQUEST_NAMES.AUTOMATED_CRAWL_QA_COMMAND,
          commandRequestTypesConstants.COMMAND_REQUEST_NAMES.CRAWL_SPREADSHEET_DOC
        ],
        crawl_command_request_type: true
      });
      crawlerCommentsReduxActions.getCommentAC(formattedData.id);
      requestLastSpiderRunReport();
    }
    commandsReduxActions.fetchAllCommands();
    crawlerDocumentsReduxActions.clearCrawConfig();
    crawlerDocumentsReduxActions.clearCrawSpec();
    crawlerDocumentsReduxActions.clearCrawDryRun();
  }, [
    commandsReduxActions,
    crawlerCommentsReduxActions,
    crawlerDocumentsReduxActions,
    crawlerHistoryReduxActions,
    externalApiCrawlReduxActions,
    formattedData.id,
    requestLastSpiderRunReport
  ]);

  const handleSpiderRunReportChange = useCallback(() => {
    localActions.setLastReport(reduxState.spiderRunReport);
  }, [localActions, reduxState.spiderRunReport]);

  const handleStatusChange = useCallback(() => {
    if (handleCheckCommandRequest()) {
      localActions.setCommandRequest(true);
      localActions.setCrawlerState(reduxState.commandRequest.status);
    } else {
      localActions.setCommandRequest(false);
      if (reduxState.crawlDetails.is_active) {
        localActions.setCrawlerState(
          reduxState.crawlDetails.status
            ? reduxState.crawlDetails.status
            : crawlerTypesConstants.crawlStates.idle
        );
      } else if (
        reduxState.crawlDetails.status === crawlerTypesConstants.crawlStates.construction
      ) {
        localActions.setCrawlerState(crawlerTypesConstants.crawlStates.construction);
      } else {
        localActions.setCrawlerState(crawlerTypesConstants.crawlStates.deactivate);
      }
    }
  }, [
    handleCheckCommandRequest,
    localActions,
    reduxState.commandRequest.status,
    reduxState.crawlDetails.is_active,
    reduxState.crawlDetails.status
  ]);

  const handleActivateCrawl = () => {
    if (!helpers.isCrawlerDetailsWellDefined(reduxState.crawlDetails, formattedData.isMainTenant)) {
      localActions.setCrawlerSavedState(localState.crawlerState);
      return localActions.setShowInfoModal(true);
    }
    if (reduxState.crawlDetails.status === crawlerTypesConstants.crawlStates.construction) {
      crawlerActivityReduxActions.postExportCrawlConstructionInfo(reduxState.crawlDetails.id);
      externalApiCrawlReduxActions.updateExternalApiCrawls({
        id: formattedData.id,
        status: crawlerTypesConstants.crawlStates.idle,
        is_active: true
      });
    }
    return localActions.setShowModal(false);
  };

  const handleStopCrawlRun = () => {
    if (!helpers.isCrawlerDetailsWellDefined(reduxState.crawlDetails, formattedData.isMainTenant)) {
      localActions.setCrawlerSavedState(localState.crawlerState);
      return localActions.setShowInfoModal(true);
    }
    externalApiCrawlReduxActions.updateExternalApiCrawls({
      id: formattedData.id,
      status: crawlerTypesConstants.crawlStates.stopRun,
      is_active: true
    });
    return localActions.setShowModal(false);
  };

  // handle user switch of statuses
  const handleChangeCrawlState = (crawlState: CrawlSettingsCrawlState) => {
    if (!lodash.isNull(crawlState)) {
      if (crawlState.action) {
        crawlState.action();
      }
      if (crawlState.updateValue) {
        externalApiCrawlReduxActions.updateExternalApiCrawls({
          ...crawlState.updateValue,
          id: formattedData.id
        });
      }
    }
    return localActions.setCrawlerState(crawlState.value);
  };

  const handleOpenModalWithMode = (currentMode: CrawlSettingsConfirmationModalMode) => {
    localActions.setMode(currentMode);
    localActions.setShowModal(true);
  };
  // TODO: check if this is s fit for move crawls modal
  const handleCheckIfCrawlIgnore = (nextStep: () => void) => {
    // nextStep - function
    if (!reduxState.crawlDetails.spider_name_in_ignore) {
      handleOpenModalWithMode(constants.MODE_CONFIRMATION_MODAL.IGNORE);
    } else {
      nextStep();
    }
  };

  const handleRunCommandRequest = (commandRequest?: FormData) => {
    commandsReduxActions.uploadNewCommandRequest(commandRequest || localState.runNowCommandRequest);
    localActions.setRunNowCommandRequest(null);
    localActions.setShowModal(false);
  };

  const handleOpenActiveCrawl = () => {
    localActions.setCurrentAction(constants.MODE_CONFIRMATION_MODAL.EXPORT);
    if (reduxState.crawlDetails.status === crawlerTypesConstants.crawlStates.construction) {
      if (
        !helpers.isCrawlerDetailsWellDefined(reduxState.crawlDetails, formattedData.isMainTenant)
      ) {
        localActions.setCrawlerSavedState(localState.crawlerState);
        return localActions.setShowInfoModal(true);
      }
      localActions.setCrawlerSavedState(localState.crawlerState);
      return handleCheckIfCrawlIgnore(() =>
        handleOpenModalWithMode(constants.MODE_CONFIRMATION_MODAL.EXPORT)
      );
    }
    if (reduxState.crawlDetails.status === crawlerTypesConstants.crawlStates.deactivate) {
      if (
        !helpers.isCrawlerDetailsWellDefined(reduxState.crawlDetails, formattedData.isMainTenant)
      ) {
        localActions.setCrawlerSavedState(localState.crawlerState);
        return localActions.setShowInfoModal(true);
      }
    }
    return externalApiCrawlReduxActions.updateExternalApiCrawls({
      id: formattedData.id,
      status: crawlerTypesConstants.crawlStates.idle,
      is_active: true
    });
  };
  const handleConfirmationModalAction = () => {
    switch (localState.mode.title) {
      case constants.MODE_CONFIRMATION_MODAL.EXPORT.title:
        handleActivateCrawl();
        break;
      case constants.MODE_CONFIRMATION_MODAL.IGNORE.title:
        switch (localState.currentAction.title) {
          case constants.MODE_CONFIRMATION_MODAL.EXPORT.title:
            handleOpenModalWithMode(constants.MODE_CONFIRMATION_MODAL.EXPORT);
            break;
          case constants.MODE_CONFIRMATION_MODAL.IGNORE.title:
            handleRunCommandRequest();
            break;
          default:
            uiLib.showNotification(crawlerDetailsConstants.ERROR_MODAL_ACTION);
        }
        break;
      default:
        uiLib.showNotification(crawlerDetailsConstants.ERROR_MODAL_ACTION);
    }
  };

  const handleConfirmationModalCancel = () => {
    switch (localState.mode.title) {
      case constants.MODE_CONFIRMATION_MODAL.EXPORT.title:
        localActions.setCrawlerState(localState.crawlerSavedState);
        break;
      case constants.MODE_CONFIRMATION_MODAL.IGNORE.title:
        switch (localState.currentAction.title) {
          case constants.MODE_CONFIRMATION_MODAL.EXPORT.title:
            localActions.setCrawlerState(localState.crawlerSavedState);
            break;
          case constants.MODE_CONFIRMATION_MODAL.IGNORE.title:
            break;
          default:
            uiLib.showNotification(crawlerDetailsConstants.ERROR_MODAL_ACTION);
        }
        break;
      default:
        uiLib.showNotification(crawlerDetailsConstants.ERROR_MODAL_ACTION);
    }
    localActions.setShowModal(false);
  };

  const handleCreateRunNowCommandRequest = (commandRequest: FormData) => {
    localActions.setCurrentAction(constants.MODE_CONFIRMATION_MODAL.IGNORE);
    localActions.setRunNowCommandRequest(commandRequest);
    handleCheckIfCrawlIgnore(() => handleRunCommandRequest(commandRequest));
  };

  const handleRunAutoQA = () => {
    const autoQaCommandRequest = {
      parameters: { spider_name: reduxState.crawlDetails.spider_name }
    };
    commandsReduxActions.createNewAutoQaCommandRequest(autoQaCommandRequest);
    localActions.setRunNowCommandRequest(null);
    localActions.setShowModal(false);
  };

  return {
    handleStopCrawlRun,
    handleChangeCrawlState,
    handleOpenActiveCrawl,
    handleConfirmationModalAction,
    handleConfirmationModalCancel,
    handleCreateRunNowCommandRequest,
    handleRunAutoQA,
    handleInitialisation,
    handleSpiderRunReportChange,
    handleStatusChange
  };
};
