import type { SpiderRunReportFromResponse } from 'shared/features/spiderRunReports/spiderRunReports.api.types';
import type { FC } from 'react';
import type { DocsLinkCellProps } from './DocsLinkCell.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { TABLE_VARIABLES } from '../../CrawlActivityTable.constants';

import { useDocsLinkCellData, useDocsLinkCellHandlers } from './hooks';

import './_docs-link-cell.scss';

export const DocsLinkCell: FC<DocsLinkCellProps> = ({ row }) => {
  const { localState, localActions } = useDocsLinkCellData();

  const handlers = useDocsLinkCellHandlers({
    localActions
  });

  return (
    <>
      <uiLib.Button
        type={uiLib.BUTTON_TYPES.LINK}
        startIcon={<uiLib.IconFile />}
        onClick={handlers.handleModalToggle(true)}
        className="docs-link-cell__button"
      >
        View docs
      </uiLib.Button>
      {localState.isModalOpen && (
        <elements.DocsModal
          reportId={row[TABLE_VARIABLES.id] as SpiderRunReportFromResponse['id']}
          onClose={handlers.handleModalToggle(false)}
        />
      )}
    </>
  );
};
