import React from 'react';
import { connect } from 'react-redux';
import { Pagination, ControlLabel, Button } from 'react-bootstrap';
import _ from 'lodash';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { fetchIncompleteDocs } from 'shared/features/documents/documents.actions';
import { TIME_OPTIONS } from './IncompleteDocTool.constants';
import IncompleteDocumentModal from './IncompleteDocumentModal';
import {
  loadAgencyOptions,
  loadCategoryOptions,
  loadSpiderNameOptions
} from './IncompleteDocTool.helper';
import AsyncSelect from "../../components/ReactSelect/SelectAsync";
import {withAdminPermissions} from "../../utils/hooks";

class IncompleteDocTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 25,
      page: 1,
      category: [],
      spider_name: [],
      offset: 0,
      showModal: false,
      openedDocumentId: null,
      document_id: '',
      showAlert: false,
      agency_id: [],
      from_date: null,
      to_date: null,
      pipeline_status: 'incomplete'
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchIncompleteDocs(_.cloneDeep(this.state));
  }

  getParams = () => {
    const params = { pipeline_status: 'incomplete' };

    params.limit = this.state.limit;
    params.offset = this.state.offset;

    if (this.state.category && this.state.category.length) {
      params.category = this.state.category.map(item => item.value);
    }

    if (this.state.spider_name && this.state.spider_name.length) {
      params.spider_name = this.state.spider_name.map(item => item.value);
    }

    if (this.state.agency_id && this.state.agency_id.length) {
      params.all_agencies = false;
      params.agency_id = this.state.agency_id.map(item => item.value);
    } else {
      params.all_agencies = true;
    }

    if (this.state.recent_time) {
      params.created_from = moment().subtract(this.state.recent_time / 24, 'd').format('YYYY-MM-DD');
      params.created_to = moment().format('YYYY-MM-DD');
    } else {
      if (this.state.from_date) {
        params.created_from = moment(this.state.from_date).format('MM/DD/YYYY');
      }
      if (this.state.to_date) {
        params.created_to = moment(this.state.to_date).format('MM/DD/YYYY');
      }
    }
    return params;
  };

  //Keeping this for reference just in case. ¯\_(ツ)_/¯
  // getAllDefaultAgencies = () => {
  //   const list = [];
  //   defaultFederalAgencies.forEach(agency => {
  //     if (agency.id) {
  //       list.push({ value: agency.id, label: agency.short_name });
  //     }
  //   });
  //
  //   // n.b. set of agencies to be evaluated before being moved to main default list
  //   federalAgenciesBeingEvaluated.forEach(agency => {
  //     if (agency.id) {
  //       list.push({ value: agency.id, label: agency.short_name });
  //     }
  //   });
  //
  //   defaultStateAgencies.forEach(agency => {
  //     if (agency.id) {
  //       list.push({ value: agency.id, label: agency.short_name });
  //     }
  //   });
  //   return list;
  // };
  //
  // agencies_options_default_list = this.getAllDefaultAgencies();

  handlePageChange = eventKey => {
    this.setState(state => {
      return {
        page: eventKey,
        offset: state.limit * (eventKey - 1)
      };
    },
    () => {
      this.props.fetchIncompleteDocs(this.getParams());
    });
  };

  handleFieldChange = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    new_state.showAlert = false;
    this.setState(new_state);
  };

  handleSelectChange = (field, val) => {
    const new_state = {};
    new_state.showAlert = false;
    if (field === 'recent_time') {
      new_state.from_date = null;
      new_state.to_date = null;
    }
    new_state[field] = val;
    this.setState(new_state);
  };

  handleFilter = () => {
    this.setState(
      {
        offset: 0,
        page: 1
      },
      function () {
        this.props.fetchIncompleteDocs(this.getParams());
      }
    );
  };

  openModal = id => {
    this.setState({ showModal: true, openedDocumentId: id });
  };

  close = () => {
    this.setState({ showModal: false });
  };

  updateDocumentsResult = () => {
    this.setState({ scrollTop: this.refs.main_container.scrollTop });
    this.props.fetchIncompleteDocs(this.getParams()).then(() => {
      if (this.refs.main_container) {
        this.refs.main_container.scrollTop = this.state.scrollTop;
      }
    });
  };

  handleFromDateChange = date => {
    this.setState({ from_date: date, recent_time: null });
  };

  handleToDateChange = date => {
    this.setState({ to_date: date, recent_time: null });
  };

  render() {
    if (!this.props.incomplete_documents.isReady) return null;
    const { haveEditAccess } = this.props;

    const documents = this.props.incomplete_documents.items.items;
    const count = this.props.incomplete_documents.items.count;

    const get_agencies = document => {
      const agency_list = [];
      if (document.agencies) {
        for (const agency of document.agencies) {
          agency_list.push(agency.short_name);
        }
      }
      return agency_list.join(', ');
    };

    const max_buttons = Math.ceil(count / this.state.limit);
    const rows = documents.map(document => (
      <div
        key={document.id}
        className="adminToolList_oneAdminToolItem"
      >
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>ID: </b>
            {document.id}
          </div>
          {haveEditAccess && (
            <div>
              <Button bsStyle="primary" onClick={() => this.openModal(document.id)}>
                Fix
              </Button>
            </div>
          )}
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Title: </b>
            {document.title}
          </div>
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Summary Text: </b>
            {document.summary_text}
          </div>
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Category: </b>
            {document.category}
          </div>
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Spider Name: </b>
            {document.spider_name}
          </div>
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Provenance: </b>
            {document.api_table}
          </div>
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Agencies: </b>
            {get_agencies(document)}
          </div>
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Web url: </b>
            <a href={document.web_url} target="_blank" rel="noopener noreferrer">
              {document.web_url}
            </a>
          </div>
        </div>
        <div className="adminToolList_LineTwoValue">
          <div>
            <b>Pdf url: </b>
            <a href={document.pdf_url} target="_blank" rel="noopener noreferrer">
              {document.pdf_url}
            </a>
          </div>
        </div>
      </div>
    ));

    return (
      <div ref="main_container" className="thinBtn AdminToolPageTemplate">
        <h1>Incomplete Doc Tool</h1>
        <hr />
        <div className="filterAdminToolWrap">
          <div key={1} className="filterAdminToolWrap_elem">
            <ControlLabel>Filter by most recent</ControlLabel>
            <Select
              options={TIME_OPTIONS}
              value={this.state.recent_time}
              name="recent_time"
              onChange={e => this.handleSelectChange(
                'recent_time',
                e && e.value
              )}
            />
          </div>
          <div className="filterAdminToolWrap_elem">
            <ControlLabel>{'created_at range'}</ControlLabel>
            <div className="filterAdminToolWrap_elem_double">
              <DatePicker
                className="filterAdminToolWrap_elem_double_datePicker"
                placeholderText="From"
                selected={this.state.from_date}
                onChange={this.handleFromDateChange}
                isClearable
              />
              <DatePicker
                className="filterAdminToolWrap_elem_double_datePicker"
                placeholderText="To"
                selected={this.state.to_date}
                onChange={this.handleToDateChange}
                isClearable
              />
            </div>
          </div>
          <AsyncSelect
            label="Spider Name"
            className="filterAdminToolWrap_elem"
            value={this.state.spider_name}
            onChange={e => this.handleSelectChange('spider_name', e)}
            loadOptions={
            value => loadSpiderNameOptions('spider_name', value, 'spider_name')
          }
            isMulti
            isClearable
          />
          <AsyncSelect
            label="Scraped Document Category"
            className="filterAdminToolWrap_elem"
            value={this.state.category}
            onChange={e => this.handleSelectChange('category', e)}
            loadOptions={value => loadCategoryOptions(
              'scraped_category', value, 'scraped_category'
            )}
            isMulti
            isClearable
          />
          <AsyncSelect
            label="Agencies"
            className="filterAdminToolWrap_elem"
            value={this.state.agency_id}
            onChange={e => this.handleSelectChange('agency_id', e)}
            loadOptions={
            value => loadAgencyOptions('short_name', value, 'short_name')
          }
            isMulti
            isClearable
          />
        </div>
        <hr />
        <Button bsStyle="primary" bsSize="xsmall" className="rightBtn" onClick={this.handleFilter}>
          Filter
        </Button>
        <div style={{ clear: 'both' }} />
        <div className="documentAdmitToolTable">
          <div className="adminToolList">
            {rows}
          </div>
        </div>
        <Pagination
          bsSize="small"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          items={max_buttons}
          maxButtons={5}
          activePage={this.state.page}
          onSelect={this.handlePageChange}
        />
        <IncompleteDocumentModal
          close={this.close}
          document_id={this.state.openedDocumentId}
          showModal={this.state.showModal}
          page_state={_.cloneDeep(this.state)}
          reload_function={this.props.fetchIncompleteDocs}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchIncompleteDocs: params => {
      return dispatch(fetchIncompleteDocs(params));
    }
  };
};

const mapStateToProps = state => {
  return {
    incomplete_documents: state.incomplete_documents,
    errors: state.errors,
    viewer: state.current_user
  };
};

const ReduxIncompleteDocTool = withAdminPermissions(connect(mapStateToProps, mapDispatchToProps)(IncompleteDocTool));

export default ReduxIncompleteDocTool;
