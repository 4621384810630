import React from "react";
import PropTypes from "prop-types";
import TextInput from "../../../components/TextInput";
import { resourceCrawlsComponents } from "../../../constants/resourceCrawlRunNowSettings";

const CrawlRunNowModalBodyArchiveResource = ({
  parameters,
  setParameter
}) => {
  const crawlSettings = Object
    .values(resourceCrawlsComponents)
    .map(({ value, name }) => ({ value, name: `${name} (specify number)` }));

  const handleChangeTextInput = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setParameter(name, value);
  };

  const buildField = (fieldSettings) => {
    return (
      <TextInput
        label={fieldSettings.name}
        name={fieldSettings.value}
        onChange={handleChangeTextInput}
        value={parameters[fieldSettings.value]}
        type={"number"}
        isDisabled
      />
    );
  };

  return crawlSettings.map(buildField);
};

CrawlRunNowModalBodyArchiveResource.propTypes = {
  parameters: PropTypes.shape({}),
  setParameter: PropTypes.func
};

CrawlRunNowModalBodyArchiveResource.defaultProps = {
  parameters: {}
};

export default CrawlRunNowModalBodyArchiveResource;
