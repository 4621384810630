import type * as uiLib from '@compliance.ai/web-components';
import type { CrawlDetailsData } from './useCrawlDetailsData.types';
import type { GlobalState } from 'shared/reducers';

import { getCommentsList } from 'shared/features/crawlerSettings/crawlerComments/crawlerComments.selectors';
import { getFirstExternalApiCrawlElement } from 'shared/features/externalApiCrawl/externalApiCrawl.selectors';
import { getCurrentUser } from 'shared/features/user/user.selectors';
import { CRAWL_DETAILS, CRAWL_PROVISION, CRAWL_GENERAL } from '../CrawlerDetails.constants';
import { crawlStates } from 'admin/constants/crawlersTypes';
import { getEnableTranslationSelectedOption } from '../CrawlerDetails.helper';
import { getExactCommandRequestsByName } from 'shared/features/commands/commands.selector';
import { COMMAND_REQUEST_NAMES } from 'admin/constants/commandRequestTypes';
import { CRAWLER_TYPE } from 'admin/constants/crawlersTypes';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';

export const useCrawlDetailsData = (): CrawlDetailsData => {
  const { id } = useParams();

  const reduxState = useSelector<GlobalState, CrawlDetailsData['reduxState']>(state => ({
    comments: getCommentsList(state),
    crawlDetailsInfo: getFirstExternalApiCrawlElement(state),
    currentUser: getCurrentUser(state),
    autoQACommandRequests: getExactCommandRequestsByName(
      state,
      COMMAND_REQUEST_NAMES.AUTOMATED_CRAWL_QA_COMMAND
    )
  }));

  const [editMode, setEditMode] = useState<CrawlDetailsData['localState']['editMode']>(
    isNaN(Number(id))
  );
  const [errorFields, setErrorFields] = useState<CrawlDetailsData['localState']['errorFields']>([]);
  const [crawlDetails, setCrawlDetails] =
    useState<CrawlDetailsData['localState']['crawlDetails']>(CRAWL_DETAILS);
  const [showViewModal, setShowViewModal] =
    useState<CrawlDetailsData['localState']['showViewModal']>(false);
  const [showSentencificationConfigurationModal, setShowSentencificationConfigurationModal] =
    useState<CrawlDetailsData['localState']['showSentencificationConfigurationModal']>(false);
  const [showModal, setShowModal] = useState<CrawlDetailsData['localState']['showModal']>(false);
  const [showAutoQAModal, setShowAutoQAModal] =
    useState<CrawlDetailsData['localState']['showAutoQAModal']>(false);
  const [crawlSourceStructure, setCrawlSourceStructure] = useState<
    CrawlDetailsData['localState']['crawlSourceStructure']
  >(JSON.stringify(crawlDetails.source_structure, null, 2));
  const [crawlSentencificationConfiguration, setCrawlSentencificationConfiguration] = useState<
    CrawlDetailsData['localState']['crawlSentencificationConfiguration']
  >(JSON.stringify(crawlDetails.sentencification_configuration, null, 2));
  const enableAutoTranslationSelectedOption: CrawlDetailsData['formattedData']['enableAutoTranslationSelectedOption'] =
    useMemo(
      () =>
        getEnableTranslationSelectedOption(
          crawlDetails[CRAWL_PROVISION.enableAutoTranslation] as boolean | null
        ),
      [crawlDetails]
    );

  const eitlLabelEnabled =
    crawlDetails[CRAWL_GENERAL.crawlType] === null
      ? false
      : [
          CRAWLER_TYPE.UNIFIED,
          CRAWLER_TYPE.DATA_ADMIN,
          CRAWLER_TYPE.PREMIUM,
          CRAWLER_TYPE.OSAPI,
          CRAWLER_TYPE.FEEDLY,
          CRAWLER_TYPE.EMAIL,
          CRAWLER_TYPE.CODEBOT,
          CRAWLER_TYPE.CRAWLBOT,
          CRAWLER_TYPE.CUSTOM
        ].includes((crawlDetails[CRAWL_GENERAL.crawlType] as uiLib.SelectOption)?.value);
  const isEditDisabled = crawlDetails.status !== crawlStates.construction;

  const localActions: CrawlDetailsData['localActions'] = useMemo(
    () => ({
      setEditMode,
      setErrorFields,
      setCrawlDetails,
      setShowViewModal,
      setShowSentencificationConfigurationModal,
      setShowModal,
      setShowAutoQAModal,
      setCrawlSourceStructure,
      setCrawlSentencificationConfiguration
    }),
    []
  );

  return {
    reduxState,
    localState: {
      editMode,
      errorFields,
      crawlDetails,
      showViewModal,
      showSentencificationConfigurationModal,
      showModal,
      showAutoQAModal,
      crawlSourceStructure,
      crawlSentencificationConfiguration
    },
    localActions,
    formattedData: {
      eitlLabelEnabled,
      isEditDisabled,
      id,
      enableAutoTranslationSelectedOption
    }
  };
};
