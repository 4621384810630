import {
  api_fetchConcepts,
  api_addConcept,
  api_updateConcept,
  api_deleteConcept
} from './jurisdictionAnalyzerConcept.api';

export const RECEIVE_CONCEPTS = 'RECEIVE_CONCEPTS';
export const CLEAR_CONCEPTS = 'CLEAR_CONCEPTS';

function receiveConcepts(json) {
  return {
    type: RECEIVE_CONCEPTS,
    concepts: json
  };
}

function clearConcepts() {
  return {
    type: CLEAR_CONCEPTS,
    concepts: null
  };
}

export function fetchConcepts(params = {}) {
  return function dofetch(dispatch) {
    dispatch(clearConcepts());
    return api_fetchConcepts(params)
      .then(response => {
        dispatch(receiveConcepts(response));
        return response;
      })
      .catch(error => {
        dispatch(receiveConcepts({}));
      });
  };
}

export function addConcept(params) {
  return function dopost(dispatch) {
    dispatch(clearConcepts());
    return api_addConcept(params)
      .then(response => {
        dispatch(receiveConcepts(response, params));
      })
      .catch(error => {
        dispatch(receiveConcepts({}));
      });
  };
}

export function updateConcept(currentID, params) {
  return function dopost(dispatch) {
    dispatch(clearConcepts());
    return api_updateConcept(currentID, params)
      .then(response => {
        dispatch(receiveConcepts(response, params));
      })
      .catch(error => {
        dispatch(receiveConcepts({}));
      });
  };
}

export function deleteConcept(currentID, params) {
  return function dopost(dispatch) {
    dispatch(clearConcepts());
    return api_deleteConcept(currentID, params)
      .then(response => {
        dispatch(receiveConcepts(response, params));
      })
      .catch(error => {
        dispatch(receiveConcepts({}));
      });
  };
}
