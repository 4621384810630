import querystring from 'query-string';

import { INSIGHTS_PARAMS } from 'shared/utils/insights';
import {
  api_getInsightGraphData, api_getInsightsCSVBySlug, api_getInsightsCSV,
  api_getEnforcementActionsData
} from './insights.api.js';

export const REQUEST_INSIGHTS_GRAPH_DATA = 'REQUEST_INSIGHTS_GRAPH_DATA';
export const RECEIVE_INSIGHTS_GRAPH_DATA = 'RECEIVE_INSIGHTS_GRAPH_DATA';
export const REQUEST_INSIGHTS_CSV = 'REQUEST_INSIGHTS_CSV';
export const RECEIVE_INSIGHTS_CSV = 'RECEIVE_INSIGHTS_CSV';
export const FETCH_ENFORCMENT_ACTIONS_DATA = 'FETCH_ENFORCMENT_ACTIONS_DATA';

export const AGGREGATIONS = [
  'act_enforcement_matrix', 'rules_by_quarter', 'rules_by_agency'
];

function requestInsightsGraphData() {
  return {
    type: REQUEST_INSIGHTS_GRAPH_DATA
  };
}

function receiveInsightsGraphData(aggregations, json) {
  return {
    type: RECEIVE_INSIGHTS_GRAPH_DATA,
    aggregations: AGGREGATIONS,
    data: json
  };
}

function requestInsightsCsv(slug) {
  return {
    type: REQUEST_INSIGHTS_CSV
  };
}

export function receiveEnforcementActionsData(res) {
  return {
    type: FETCH_ENFORCMENT_ACTIONS_DATA,
    payload: res
  };
}

function receiveInsightsCsv(json) {
  return {
    type: RECEIVE_INSIGHTS_CSV,
    insights_csv: json
  };
}

export const fetchEnforcementActions = ({ agencyIds }) => async dispatch => {
  try {
    const res = await api_getEnforcementActionsData({ agencyIds });
    dispatch(receiveEnforcementActionsData(res));
  } catch (error) {
    // pass
  }
};

export function fetchInsightsGraphData(useAPIKey) {
  return function dofetch(dispatch) {
    dispatch(requestInsightsGraphData());
    const graphDataRequests = AGGREGATIONS.map((agg, i) => api_getInsightGraphData(
      agg,
      querystring.stringify(INSIGHTS_PARAMS[agg]),
      useAPIKey
    ));

    return Promise.all(graphDataRequests)
      .then(response => {
        dispatch(receiveInsightsGraphData(AGGREGATIONS, response));
      });
  };
}

export function fetchInsightsCsvBySlug(slug) {
  return function dofetch(dispatch) {
    dispatch(requestInsightsCsv(slug));

    return api_getInsightsCSVBySlug(slug)
      .then(response => {
        dispatch(receiveInsightsCsv(response));
        return response;
      });
  };
}

export function fetchInsightsCsv(params) {
  return function dofetch(dispatch) {
    return api_getInsightsCSV(params.slug)
      .then(response => {
        return response;
      });
  };
}
