import type { FC } from 'react';
import type { ButtonLinkCellProps } from './CrawlerQAReportsOpenModalButtonCell.types';

import * as uiLib from '@compliance.ai/web-components';

import './_crawler-qa-reports-open-modal-button-cell.scss';
export const CrawlerQAReportsOpenModalButtonCell: FC<ButtonLinkCellProps> = ({
  details,
  selectedCommandRequest,
  onOpenModal
}) => {
  return (
    <div className="dry-run-reports-button-download-wrapper">
      <uiLib.Tooltip title={details.slice(0, 10)}>
        <uiLib.Button
          type={uiLib.BUTTON_TYPES.PRIMARY}
          onClick={() => onOpenModal(details, selectedCommandRequest)}
        >
          <uiLib.Typography>Details</uiLib.Typography>
        </uiLib.Button>
      </uiLib.Tooltip>
    </div>
  );
};
