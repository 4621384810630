import { fetch } from 'shared/utils/api';

export function api_fetchContributorReviewsCount(annotationTaskId, params) {
  return fetch({
    url: `/annotation_tasks/${annotationTaskId}/contributor_reviews`,
    params,
    method: 'GET',
    dataType: 'ContributorReviews'
  });
}

export function api_fetchContributorPoints(params) {
  return fetch({
    url: `/contributor_points`,
    method: 'GET',
    params,
    dataType: 'ContributorReviews'
  });
}
