import lodash from 'lodash';
import CRAWL_ROUTES from 'admin/constants/routes';
import AgencyDetailsGeneral from './AgencyDetailsGeneral';
import AgencyDetailsAdditionalInfo from './AgencyDetailsAdditionalInfo';

import { createAgencies, updateAgencies } from 'shared/features/agencyData/agencyData.actions';
import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';
import { fromRawInfoToDetails, fromDetailsToRawInfo } from './AgencyDetails.helper';
import { AGENCY_DETAILS } from './AgencyDetails.constants';
import { SOURCE_TYPES } from 'admin/constants/sources';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'admin/utils/hooks';

import './AgencyDetails.styles.scss';

const AgencyDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const current_user = useSelector(state => state.current_user.user);
  const agencyDetailsInfo = lodash.get(
    useSelector(state => state.agencyData),
    ['items', 0],
    {}
  );

  const [editMode, setEditMode] = useState(isNaN(+id));
  const [errorFields, setErrorFields] = useState([]);
  const [agencyDetails, setAgencyDetails] = useState(AGENCY_DETAILS);

  const calculateAgencyDetails = () => {
    const formatAgencyDetails = fromRawInfoToDetails(agencyDetailsInfo);
    setAgencyDetails(formatAgencyDetails);
  };

  useEffect(calculateAgencyDetails, [agencyDetailsInfo]);

  useEffect(() => {
    if (isNaN(+id) && agencyDetailsInfo.id && !editMode) {
      history.push(`${CRAWL_ROUTES.agencyTool}/${agencyDetailsInfo.id}`);
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyDetailsInfo.id]);

  const toggleEditMode = () => {
    if (editMode) {
      calculateAgencyDetails();
    }
    setEditMode(!editMode);
  };

  const handleChanges = (name, value) => {
    setAgencyDetails({
      ...agencyDetails,
      [name]: value
    });
  };

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const saveChanges = async () => {
    const { agencyDetails: agencyInfo, errors } = await fromDetailsToRawInfo(
      agencyDetails,
      agencyDetailsInfo
    );
    agencyInfo.user = `${lodash.get(current_user, 'first_name')} ${lodash.get(
      current_user,
      'last_name'
    )}`;
    if (errors.length) {
      setErrorFields(errors.map(err => err.title));
      errors.forEach(error =>
        setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR, width: 800 })
      );
      return;
    }
    if (!isNaN(+id)) {
      dispatch(updateAgencies({ ...agencyInfo, id }));
    } else {
      dispatch(createAgencies(agencyInfo));
    }
    setEditMode(!editMode);
  };

  const switchSourceType = type => {
    if (type.value === SOURCE_TYPES.AGENCY) {
      history.push(`${CRAWL_ROUTES.agencyTool}/new`);
    } else if (type.value === SOURCE_TYPES.NEWS) {
      history.push(`${CRAWL_ROUTES.sources}/new`);
    }
  };

  const isNewMode = id === 'new';

  return (
    <div className="agency-details">
      <div className="agency-details_body">
        <AgencyDetailsGeneral
          isNewMode={isNewMode}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          agencyDetails={agencyDetails}
          saveChanges={saveChanges}
          handleChanges={handleChanges}
          handleChangesEvent={handleChangesEvent}
          errorFields={errorFields}
          switchSource={switchSourceType}
        />
      </div>
      <div className="agency-details_right-bar">
        <AgencyDetailsAdditionalInfo
          editMode={editMode}
          agencyDetails={agencyDetails}
          handleChanges={handleChanges}
          handleChangesEvent={handleChangesEvent}
          errorFields={errorFields}
        />
      </div>
    </div>
  );
};

export default AgencyDetails;
