import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as eitlLabelsDataApi from 'shared/features/eitlLabels/eitlLabels.api';
import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';
import { DocumentDetailsTabsData } from '../../../hooks/useDocumentDetailsTabsData.types';
import { UseDocumentDetailsTabsHandlers } from '../../../hooks/useDocumentDetailsTabsHandlers.types';
import { DOCUMENT_GENERAL } from '../../../../../DocumentDetails.constants';
import { SelectOption } from '@compliance.ai/web-components';

export const useDocumentDetailsFieldsMutations = ({
  onChanges,
  docDetails
}: {
  docDetails: DocumentDetailsTabsData['localState']['docDetails'];
  onChanges: UseDocumentDetailsTabsHandlers['handleChanges'];
}) => {
  const queryClient = useQueryClient();

  const createEitlLabelsDataMutation = useMutation<
    Awaited<ReturnType<typeof eitlLabelsDataApi.addEitlLabel>>,
    Error,
    Parameters<typeof eitlLabelsDataApi.addEitlLabel>
  >({
    mutationFn: variables => eitlLabelsDataApi.addEitlLabel(...variables),
    mutationKey: [queryKeysConstants.QUERY_KEYS.EITL_LABELS],
    onError: errorUtils.logReactQueryError,
    onSuccess: payload => {
      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        title: 'Eitl Label successfully created'
      });
      queryClient.invalidateQueries([queryKeysConstants.QUERY_KEYS.EITL_LABELS]);
      onChanges(DOCUMENT_GENERAL.documentLabels, [
        ...(docDetails[DOCUMENT_GENERAL.documentLabels] as SelectOption[]),
        { value: payload?.id, label: payload?.name }
      ]);
    }
  });

  return {
    createEitlLabelsDataMutation
  };
};
