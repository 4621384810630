const popupName = {
  renouncementPopup: "renouncementtPopup",
  afterSavePopup: "afterSavePopup",
  afterDeletePopup: "afterDeletePopup",
  errorRespPopup: "errorRespPopup",
  afterChangePopup: "afterChangePopup",
  emptySplitMass: "emptySplitMass",
  onlyOneJurisdictionForSplit: "onlyOneJurisdictionForSplit",
  combineWrongCategories: "combineWrongCategories",
  splittingWithTheSameMetaCategoriesPopup: "splittingWithTheSameMetaCategoriesPopup",
  newCrawlerWasAdded: "newCrawlerWasAdded",
  existCrawlerWasUpdated: "existCrawlerWasUpdated",
  uploadImageWrong: 'uploadImageWrong',
  infoPopup: 'infoPopup',
  infoPopupForEnforcements: 'infoPopupForEnforcements',
  deleteOrganizationPopup: 'deleteOrganizationPopup',
  deleteOrganizationTeamPopup: 'deleteOrganizationTeamPopup',
  deleteUserRole: 'deleteUserRole'
};

const popupSettings = [
  {
    name: popupName.afterSavePopup,
    title: 'Success',
    body: 'This meta category has been saved!'
  },
  {
    name: popupName.renouncementPopup,
    title: 'Alert',
    body: 'Are you sure you want to delete this meta-category',
    secondButton: { name: 'Delete' }
  },
  {
    name: popupName.afterDeletePopup,
    title: 'Success',
    body: 'This meta-category has been deleted'
  },
  {
    name: popupName.errorRespPopup,
    title: 'Error',
    body: 'Something went wrong, please try again.'
  },
  {
    name: popupName.afterChangePopup,
    title: 'Success',
    body: 'Changes has been saved!'
  },
  {
    name: popupName.emptySplitMass,
    title: 'Error',
    body: 'Please select category for splitting.'
  },
  {
    name: popupName.combineWrongCategories,
    title: 'Error',
    body: 'Please select from two lines with the same "App category".'
  },
  {
    name: popupName.uploadImageWrong,
    title: 'Error',
    body: 'Please select file with right extension.'
  },
  {
    name: popupName.infoPopup,
    title: 'Info',
    body: 'Special info.'
  },
  {
    name: popupName.infoPopupForEnforcements,
    title: 'Info',
    body: 'Special info.',
    secondButton: { name: 'Fix Missing Enforcement' }
  },
  {
    name: popupName.onlyOneJurisdictionForSplit,
    title: 'Error',
    body: 'Please select splitting category that contains more then 2 jurisdictions.'
  },
  {
    name: popupName.combineWrongCategories,
    title: 'Error',
    body: 'Please select data having the same scrapped & app category.'
  },
  {
    name: popupName.splittingWithTheSameMetaCategoriesPopup,
    title: 'Error',
    body: "Please don't create the same categories, choose the different meta-categories."
  },
  {
    name: popupName.newCrawlerWasAdded,
    title: "Success",
    body: "New crawler was successfully added!"
  },
  {
    name: popupName.existCrawlerWasUpdated,
    title: "Success",
    body: "Exist crawler was successfully updated!"
  },
  {
    name: popupName.deleteOrganizationPopup,
    title: 'Alert',
    body: 'Are you sure you want to delete this organization?',
    secondButton: { name: 'Delete' }
  },
  {
    name: popupName.deleteOrganizationTeamPopup,
    title: 'Alert',
    body: 'Are you sure you want to delete this organization team?',
    secondButton: { name: 'Delete' }
  },
  {
    name: popupName.deleteUserRole,
    title: "Warning",
    body: "Are you sure you wand to revoke admin Access for ",
    secondButton: { name: 'Delete' }
  }
];

export { popupName, popupSettings };
