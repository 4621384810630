import React from 'react';
import Tabs from '../../components/Tabs';
import ConfirmationModal from './ConfirmationModal';
import { CrawlerActivity } from './CrawlerActivity';
import CrawlerCatalog from './CrawlerCatalog/CrawlerCatalog';
import { CrawlerDetails } from './CrawlerDetails';
import { CrawlerQAReports } from './CrawlerQAReports';
import CrawlRunNowModal from './CrawlRunNowModal/CrawlRunNowModal';
import DocumentsIngested from './DocumentsIngested/DocumentsIngested';
import CrawlSettingsStatus from './CrawlSettingsStatus';
import InformationModal from './InformationModal';
import { getInformationModalMessage } from './CrawlerSettings.helpers';
import { TooltipMessage } from '../../components/Tooltip';
import { useCrawlSettingsData } from './hooks/useCrawlSettingsData';
import { useCrawlDetailsHandlers } from './hooks/useCrawlSettingsHandlers';
import { useCrawlDetailsLifecycle } from './hooks/useCrawlSettingsLifecycle';

const CrawlerSettings = () => {
  const { reduxState, localState, localActions, formattedData } = useCrawlSettingsData();
  const handlers = useCrawlDetailsHandlers({
    reduxState,
    localState,
    localActions,
    formattedData
  });
  useCrawlDetailsLifecycle({
    onInitialisation: handlers.handleInitialisation,
    onReportChange: handlers.handleSpiderRunReportChange,
    onStatusChange: handlers.handleStatusChange
  });
  return (
    <>
      <TooltipMessage classNameTooltipID="global-tooltip" />
      <Tabs
        className="crawl-navigation_tabs"
        headClassName="crawl-navigation_tabs_head"
        defaultChild={new URLSearchParams(formattedData.search).get('report') ? 1 : 0}
        extraInfo={
          <CrawlSettingsStatus
            crawlDetails={reduxState.crawlDetails}
            lastReport={localState.lastReport}
            crawlerState={localState.crawlerState}
            isCommandRequest={localState.isCommandRequest}
            activateCrawl={handlers.handleOpenActiveCrawl}
            stopCrawl={handlers.handleStopCrawlRun}
            openRunNowModal={() => {
              localActions.setCrawlerSavedState(localState.crawlerState);
              localActions.setRunNowModalActive(true);
            }}
            changeCrawlState={handlers.handleChangeCrawlState}
            handleRunAutoQA={handlers.handleRunAutoQA}
            commandRequest={reduxState.commandRequest}
          />
        }
      >
        <CrawlerDetails label="Crawler Details" />
        <CrawlerActivity label="Crawler Activity" />
        <DocumentsIngested label="Documents Ingested" />
        <CrawlerCatalog label="Crawler Catalog" />
        <CrawlerQAReports label="QA Reports" />
      </Tabs>
      {/*TODO: is this a good modal for move crawl*/}
      <ConfirmationModal
        show={localState.showModal}
        mode={localState.mode}
        onHide={() => localActions.setShowModal(false)}
        modalAction={handlers.handleConfirmationModalAction}
        onCancel={handlers.handleConfirmationModalCancel}
      />
      <InformationModal
        show={localState.showInfoModal}
        onHide={() => {
          localActions.setCrawlerState(localState.crawlerSavedState);
          localActions.setShowInfoModal(false);
        }}
        mode={getInformationModalMessage(reduxState.crawlDetails, formattedData.isMainTenant)}
      />
      <CrawlRunNowModal
        show={localState.runNowModalActive}
        createRunNow={handlers.handleCreateRunNowCommandRequest}
        onHide={() => localActions.setRunNowModalActive(false)}
        onCancel={() => localActions.setCrawlerState(localState.crawlerSavedState)}
        crawlDetails={reduxState.crawlDetails}
      />
    </>
  );
};

export default CrawlerSettings;
