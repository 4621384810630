import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { colors } from '../../colors/colors';

const MButtonPrimaryProgress = withStyles({
  root: {
    color: colors.WHITE
  }
})(CircularProgress);

export default MButtonPrimaryProgress;
