import type { CategoryMergeModalData } from './useCategoryMergeModalData.types';

import { useMemo, useState } from 'react';

export const useCategoryMergeModalData = (): CategoryMergeModalData => {
  const [docMetaCategory, setDocMetaCategory] =
    useState<CategoryMergeModalData['localState']['docMetaCategory']>(null);
  const [docCaiCategory, setDocCaiCategory] =
    useState<CategoryMergeModalData['localState']['docCaiCategory']>(null);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryMergeModalData['localState']['selectedCategories']
  >([]);
  const [categoriesToMerge, setCategoriesToMerge] = useState<
    CategoryMergeModalData['localState']['categoriesToMerge']
  >([]);

  const localState: CategoryMergeModalData['localState'] = {
    docMetaCategory,
    docCaiCategory,
    selectedCategories,
    categoriesToMerge
  };

  const localActions: CategoryMergeModalData['localActions'] = useMemo(
    () => ({
      setDocMetaCategory,
      setDocCaiCategory,
      setSelectedCategories,
      setCategoriesToMerge
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
