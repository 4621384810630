// This component used only there
// should be moved to 'component' folder if used in other containers
import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import "./ExpandabeMapList.styles.scss";
import Typography from "../../../components/Typography";
import TextInput from "../../../components/TextInput";

function ExpandableList({
  className,
  labelClassName,
  addMoreClassName,
  label,
  value,
  onChange,
  isError,
  name,
  addMoreName,
  isDisabled,
  createNewItemTemplate,
  errorText,
  nameFirstInput,
  nameSecondInput
}) {
  const addItem = () => {
    onChange(name, [ ...value, createNewItemTemplate() ]);
  };

  const removeItem = (index) => {
    onChange(name, value.filter((item, itemIndex) => itemIndex !== index));
  };

  const changeItemText = (fieldName, e) => {
    const updateItemIndex = Number(e.target.name);
    const newValue = value.map((item, itemIndex) => (
      itemIndex === updateItemIndex
        ? { ...item, [fieldName]: e.target.value }
        : item
    ));
    onChange(name, newValue);
  };

  return (
    <div
      className={cn(
        'expandable-map-list_wrapper',
        className,
        { 'expandable-map-list_wrapper_error': isError }
      )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
      </Typography>
      {value.map((item, index) => {
        return (
          <div
            key={index}
            className={cn(
              'expandable-map-list_item',
              {
                'expandable-map-list_item_disabled': isDisabled
              }
            )}
          >
            <TextInput
              name={index}
              value={item[nameFirstInput]}
              isError={isError && !item[nameFirstInput]}
              isDisabled={isDisabled}
              className={cn(
                'expandable-map-list_item_input',
              )
              }
              onChange={(e) => changeItemText(nameFirstInput, e)}
            />
            <TextInput
              name={index}
              value={item[nameSecondInput]}
              isError={isError && !item[nameSecondInput]}
              isDisabled={isDisabled}
              className={cn(
                'expandable-map-list_item_input',
              )
              }
              onChange={(e) => changeItemText(nameSecondInput, e)}
            />
            <CloseIcon
              onClick={() => removeItem(index)}
              className={cn(
                "expandable-map-list_item_close-icon",
                "expandable-map-list_item_close-icon_active"
              )}
            />
          </div>
        );
      })}
      <Typography
        variant="label"
        color="blue"
        hidden={isDisabled}
        className={cn(
          addMoreClassName,
          { 'expandable-map-list_add-more_error': isError }
        )}
        onClick={addItem}
      >
        {`+ Add ${addMoreName || 'More'}`}
      </Typography>
      <Typography
        variant="tag"
        color="red"
        hidden={!(isError && errorText)}
      >
        {errorText}
      </Typography>
    </div>
  );
}


ExpandableList.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  label: PropTypes.string,
  name: PropTypes.string,
  addMoreName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  createNewItemTemplate: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  nameFirstInput: PropTypes.string,
  nameSecondInput: PropTypes.string
};

ExpandableList.defaultProps = {
  isError: false,
  isDisabled: false,
  className: '',
  value: [],
  labelClassName: '',
  label: '',
  name: '',
  addMoreName: '',
  errorText: '',
  nameFirstInput: 'label',
  nameSecondInput: 'value'
};

export default ExpandableList;
