import type { CaiCategoriesFromResponse } from 'shared/features/categories/caiCategories/caiCategories.types';
import type { JurisdictionCellData } from './useJurisdictionCellData.types';

import type * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useJurisdictionCellData = (row: uiLib.Row): JurisdictionCellData => {
  const scrapedCategoriesValue =
    row.scraped_categories as CaiCategoriesFromResponse['scraped_categories'];
  const jurisdictionsText = scrapedCategoriesValue.map(
    item => item?.jurisdiction?.short_name || item?.jurisdiction?.name
  );
  const formattedData = useMemo(() => ({ jurisdictionsText }), [jurisdictionsText]);

  return {
    formattedData
  };
};
