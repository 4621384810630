import { fetch } from 'fetch';

export function api_fetchOrganization(orgId) {
  return fetch({
    url: `/organizations/${orgId}`,
    method: 'GET',
    dataType: 'Organization'
  });
}

export function api_fetchAllOrganization() {
  return fetch({
    url: `/all_organizations`,
    method: 'GET',
    dataType: 'Organization'
  });
}

export function api_createOrganization(params) {
  return fetch({
    url: `/organizations`,
    method: 'POST',
    dataType: 'Organization',
    params
  });
}

export function api_deleteOrganizations(orgId) {
  return fetch({
    url: `/organizations/${orgId}`,
    method: 'DELETE',
    dataType: 'Organization'
  });
}

export function api_updateOrganization(orgId, params) {
  return fetch({
    url: `/organizations/${orgId}`,
    method: 'POST',
    dataType: 'Organization',
    params
  });
}

export function api_addOrganizationMember(orgId, params) {
  return fetch({
    url: `/organizations/${orgId}/organization_members`,
    method: 'POST',
    dataType: 'OrganizationMember',
    params
  });
}

export function api_updateOrganizationMember(orgId, userId, params) {
  return fetch({
    url: `/organizations/${orgId}/organization_members/${userId}`,
    method: 'POST',
    dataType: 'OrganizationMember',
    params
  });
}

export function api_fetchTeams() {
  return fetch({
    url: `/teams`,
    method: 'GET',
    dataType: 'Team'
  });
}

export function api_updateTeam(teamId, params) {
  return fetch({
    url: `/teams/${teamId}`,
    method: 'POST',
    dataType: 'Team',
    params
  });
}

export function api_deleteTeam(teamId) {
  return fetch({
    url: `/teams/${teamId}`,
    method: 'DELETE',
    dataType: 'Team'
  });
}

export function api_updateTeamMember(teamId, userId, params) {
  return fetch({
    url: `/teams/${teamId}/team_members/${userId}`,
    method: 'POST',
    dataType: 'Team',
    params
  });
}

export function api_addTeam(params) {
  return fetch({
    url: `/teams`,
    method: 'POST',
    dataType: 'Team',
    params
  });
}

export function api_addTeamMember(teamId, params) {
  return fetch({
    url: `/teams/${teamId}/team_members`,
    method: 'POST',
    dataType: 'TeamMember',
    params
  });
}

export function api_deleteTeamMember(teamId, userId) {
  return fetch({
    url: `/teams/${teamId}/team_members`,
    method: 'DELETE',
    dataType: 'TeamMember',
    params: { user_id: userId }
  });
}

export function api_fetchAllTeamMembers(teamId) {
  return fetch({
    url: `/teams/${teamId}/team_members`,
    method: 'GET',
    dataType: 'TeamMember'
  });
}

export function api_inviteToOrg(orgId, params, rnToken) {
  return fetch({
    url: `/organization_invitations/${orgId}`,
    method: 'POST',
    dataType: 'OrgInvitation',
    customToken: rnToken,
    params
  });
}

export function api_removeOrgInvitation(orgId, orgInvitationId, rnToken) {
  return fetch({
    url: `/organizations/${orgId}/organization_invitations/${orgInvitationId}`,
    method: 'DELETE',
    dataType: 'OrgInvitation',
    customToken: rnToken
  });
}
