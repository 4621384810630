import type { AuditEntriesTableHandlers } from './useAuditEntriesTableHandlers.types';
import type { AuditEntriesTableProps } from '../AuditEntriesTable.types';

import * as documentAuditEntriesApi from 'shared/features/documentAuditEntries/documentAuditEntries.api';
import * as errorUtils from 'shared/utils/errors';

import { useCallback } from 'react';

export const useAuditEntriesTableHandlers = ({
  props
}: {
  props: Pick<AuditEntriesTableProps, 'documentId'>;
}): AuditEntriesTableHandlers => {
  const handleDataFetch: AuditEntriesTableHandlers['handleDataFetch'] = useCallback(
    async params => {
      try {
        const response = await documentAuditEntriesApi.fetchDocumentAuditEntries({
          ...params,
          doc_id: props.documentId
        });

        return {
          results: response.items,
          count: response.count
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    },
    [props.documentId]
  );

  return {
    handleDataFetch
  };
};
