import { api_getAllRoles } from "./userRoles.api";
import { setRoles } from "./userRoles.reducer";
import { openErrorSnackbar } from "../snackbar/snackbar.reducer";

export const ERROR_GET_USER_ROLES = 'Unable to get users userRoles';

export const getAllRolesAC = () => async dispatch => {
  try {
    const res = await api_getAllRoles();

    dispatch(setRoles(res.roles));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR_GET_USER_ROLES));

    throw e
  }
};
