import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CrawlCreationTool from '../../CrawlCreationTool/CrawlCreationTool';
import CrawlSpecCreate from '../../CrawlSpecCreate/CrawlSpecCreate';
import Switch from '../../../components/Switch';

const CrawlsCatalogBody = ({
  disableEdit,
  modalAction,
  setCrawlConfig,
  crawlConfig
}) => {
  const [showNewSpecTool, setShowNewSpecTool] = useState(true);

  return (
    <div className="crawlCatalog_body">
      <Switch
        name="spec_switch"
        label="Show new spec tool"
        value={showNewSpecTool}
        onChange={e => setShowNewSpecTool(e.target.value)}
      />
      {showNewSpecTool ? (
        <CrawlSpecCreate
          editMode={!disableEdit}
          modalAction={modalAction}
          crawlConfig={crawlConfig}
          setCrawlConfig={setCrawlConfig}
        />
      ) : (
        <CrawlCreationTool
          isSaveProhibit={disableEdit}
        />
      )}
    </div>
  );
};

CrawlsCatalogBody.propTypes = {
  disableEdit: PropTypes.bool,
  modalAction: PropTypes.func.isRequired,
  setCrawlConfig: PropTypes.func.isRequired,
  crawlConfig: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
};

CrawlsCatalogBody.defaultProps = {
  disableEdit: true,
  crawlConfig: ""
};

export default CrawlsCatalogBody;
