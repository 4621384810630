import { showNotification, ALERT_TYPES } from '@compliance.ai/web-components';
import _ from 'lodash';

export const NOTIFICATION_TYPES = {
  SUCCESS: ALERT_TYPES.SUCCESS,
  ERROR: ALERT_TYPES.ERROR,
  INFO: ALERT_TYPES.INFO,
  DEFAULT: ALERT_TYPES.INFO,
  WARNING: ALERT_TYPES.WARNING
};

export function setNotification({
  message,
  type = NOTIFICATION_TYPES.DEFAULT
}) {
  showNotification({
    title: _.get(message, 'title', 'Error'),
    message: _.get(message, 'text', 'Sorry, something went wrong.'),
    type
  });
}
