import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  updateCategories,
  removeCategories
} from "shared/features/categories/categories/categories.actions";
import {
  getFormatCategoryInfo,
  loadCategoryOptions,
  loadMetaCategoryOptions,
  fromCategoryToRawInfo
} from "./DocumentCategories.helper";
import Modal from "../../components/Modal";
import AsyncCreatableSelect from "../../components/ReactSelect/SelectAsyncCreatabe";
import AsyncSelect from "../../components/ReactSelect/SelectAsync";
import Button from "../../components/Button";
import Switch from "../../components/Switch";
import { TABLE_VARIABLES } from "./DocumentCategories.constants";
import Select from "../../components/ReactSelect/Select";

const DocumentCategoriesModal = ({
  show,
  onHide,
  currentCategory
}) => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState({});

  useEffect(() => {
    let updateCategory = currentCategory;
    if (currentCategory.categoriesIds) {
      // combine categories logic
      updateCategory = {
        ...updateCategory,
        jurisdictions: updateCategory.jurisdictionsAll
      };
    }
    setCategory(getFormatCategoryInfo(updateCategory));
  }, [show, currentCategory]);

  const handleChanges = (name, value) => setCategory({ ...category, [name]: value });

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const updateCategory = () => {
    const categoryChanges = fromCategoryToRawInfo(category, currentCategory);
    if (categoryChanges) {
      if (currentCategory.categoriesIds) {
        // combine categories logic
        const currentId = category.id;
        const removeIds = currentCategory.categoriesIds.filter(item => item !== currentId);
        dispatch(updateCategories({ ...categoryChanges, id: currentId }));
        removeIds.forEach(item => dispatch(removeCategories({ id: item })));
      } else {
        // update category logic
        dispatch(updateCategories({ ...categoryChanges, id: category.id, jurisdictions: ["INT", "IM", "PAN"] }));
      }
      onHide();
    }
  };

  return (
    <Modal
      className="document-categories_edit-modal"
      title={`Category: ${currentCategory.scraped_category}`}
      show={show}
      onHide={onHide}
      size={'small'}
    >
      <div className="document-categories_edit-modal_body">
        <AsyncCreatableSelect
          label="Name of App category"
          value={category[TABLE_VARIABLES.app_category]}
          onChange={e => handleChanges(TABLE_VARIABLES.app_category, e)}
          loadOptions={value => loadCategoryOptions(
            TABLE_VARIABLES.app_category, value, TABLE_VARIABLES.app_category
          )}
        />
        <AsyncSelect
          label="Meta category"
          value={category[TABLE_VARIABLES.document_meta_category]}
          onChange={e => handleChanges(TABLE_VARIABLES.document_meta_category, e)}
          loadOptions={value => loadMetaCategoryOptions(
            'name', value, 'name'
          )}
        />
        <Switch
          name={TABLE_VARIABLES.surface_docs}
          label="Surface docs in app"
          value={category[TABLE_VARIABLES.surface_docs]}
          onChange={handleChangesEvent}
        />
        <Switch
          name={TABLE_VARIABLES.surface_category}
          label="Surface category in app filters"
          value={category[TABLE_VARIABLES.surface_category]}
          onChange={handleChangesEvent}
        />
        <Select
          label="Jurisdictions"
          onChange={e => handleChanges(TABLE_VARIABLES.jurisdictions, e)}
          value={category[TABLE_VARIABLES.jurisdictions]}
          isDisabled
          isMulti
        />
      </div>
      <div className="document-categories_edit-modal_footer">
        <Button onClick={onHide} color="highlighted">Cancel</Button>
        <Button
          onClick={updateCategory}
          color="blue"
        >
          Save changes
        </Button>
      </div>
    </Modal>
  );
};

DocumentCategoriesModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  currentCategory: PropTypes.shape({
    id: PropTypes.number
  })
};

DocumentCategoriesModal.defaultProps = {
  show: false,
  currentCategory: {}
};

export default DocumentCategoriesModal;
