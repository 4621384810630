import { UseDocumentDetailsTabsHandlers } from './useDocumentDetailsTabsHandlers.types';
import { useEffect } from 'react';
import { DocumentDetailsTabsData } from './useDocumentDetailsTabsData.types';
import { DocumentDetailsData } from '../../../hooks/useDocumentDetailsData.types';

export const useDocumentSettingsNavigationLifecycle = ({
  onCalculateDocDetails,
  onTakeAction,
  localActions,
  localState,
  flagAction
}: {
  onCalculateDocDetails: UseDocumentDetailsTabsHandlers['handleCalculateDocDetails'];
  onTakeAction: UseDocumentDetailsTabsHandlers['handleTakeAction'];
  localActions: DocumentDetailsTabsData['localActions'];
  localState: DocumentDetailsTabsData['localState'];
  flagAction: DocumentDetailsData['localState']['flagAction'];
}) => {
  useEffect(onCalculateDocDetails, [onCalculateDocDetails]);
  // next line necessary because we should run this function only if action updated but onTakeAction function relates from doc details that change quite often
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onTakeAction, [localState?.action]);
  useEffect(() => localActions?.setAction(flagAction), [flagAction, localActions]);
};
