import type { EitlLabelsHandlers } from './useEitlLabelsHandlers.types';
import type { EitlLabelsData } from './useEitlLabelsData.types';

import type { NormalizedEitlLabel } from 'shared/features/eitlLabels/eitlLabels.api.types';

import React from 'react';

import * as constants from '../EitlLabels.constants';
import * as dateConstants from 'admin/constants/date';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { useEitlLabelsApi } from 'shared/features/eitlLabels/hooks';
import { useOrganizationReduxActions } from 'shared/features/organizations/hooks';

export const useEitlLabelsHandlers = ({
  localState,
  localActions,
  reduxState
}: {
  localState: EitlLabelsData['localState'];
  localActions: EitlLabelsData['localActions'];
  reduxState: EitlLabelsData['reduxState'];
}) => {
  const eitlLabelsApi = useEitlLabelsApi();
  const organizationReduxActions = useOrganizationReduxActions();

  const handleDeleteLabelsModalOpen: EitlLabelsHandlers['handleDeleteLabelsModalOpen'] =
    label => () => {
      localActions.setLabelToDelete(label);
      localActions.setIsDeleteModalOpen(true);
    };

  const handleDeleteLabelsModalClose: EitlLabelsHandlers['handleDeleteLabelsModalClose'] = () => {
    localActions.setIsDeleteModalOpen(false);
  };

  const handleDeleteLabelsModalConfirm: EitlLabelsHandlers['handleDeleteLabelsModalConfirm'] =
    () => {
      localActions.setIsDeleteModalOpen(false);
      localActions.setRefetchTrigger(!localState.refetchTrigger);
    };

  const handleRenameLabelModalOpen: EitlLabelsHandlers['handleRenameLabelModalOpen'] =
    label => () => {
      localActions.setLabelToRename(label);
      localActions.setIsRenameModalOpen(true);
    };

  const handleRenameLabelModalClose: EitlLabelsHandlers['handleRenameLabelModalClose'] = () => {
    localActions.setIsRenameModalOpen(false);
  };

  const handleRenameLabelModalConfirm: EitlLabelsHandlers['handleRenameLabelModalConfirm'] =
    async () => {
      localActions.setIsRenameModalOpen(false);
      localActions.setRefetchTrigger(!localState.refetchTrigger);
    };

  const handleAddLabelModalToggle: EitlLabelsHandlers['handleAddLabelModalToggle'] =
    isOpen => () => {
      localActions.setIsAddModalOpen(isOpen);
    };

  const handleAddLabelModalConfirm: EitlLabelsHandlers['handleAddLabelModalConfirm'] = () => {
    localActions.setIsAddModalOpen(false);
    localActions.setRefetchTrigger(!localState.refetchTrigger);
  };

  const handleImportLabelsModalToggle: EitlLabelsHandlers['handleImportLabelModalToggle'] =
    isOpen => () => {
      localActions.setIsImportModalOpen(isOpen);
    };

  const handleImportLabelsModalConfirm: EitlLabelsHandlers['handleImportLabelModalConfirm'] =
    () => {
      localActions.setIsImportModalOpen(false);
      localActions.setRefetchTrigger(!localState.refetchTrigger);
    };

  const handleFiltersSubmit: EitlLabelsHandlers['handleFiltersSubmit'] = (value, searchValue) => {
    localActions.setSearchValue(searchValue);
    localActions.setFilters(value as EitlLabelsData['localState']['filters']);
    localActions.setParams({
      ...localState.params,
      offset: 0
    });
    localActions.setRefetchTrigger(!localState.refetchTrigger);
  };

  const handleDataFetch: EitlLabelsHandlers['handleDataFetch'] = async params => {
    const limit = params[uiLib.TABLE_PARAMS.LIMIT];
    const offset = params[uiLib.TABLE_PARAMS.OFFSET];

    const createdAt = localState.filters[constants.EITL_LABELS_FILTER_KEY.CREATED_AT];

    const { labels, count } = await eitlLabelsApi.getEitlLabels({
      [constants.API_KEYS.CREATED_AT]: createdAt ? uiLib.formatDate(createdAt) : null,
      [constants.API_KEYS.USER_ID]:
        localState.filters[constants.EITL_LABELS_FILTER_KEY.USER_ID]?.value ?? null,
      [constants.API_KEYS.NAME]: localState.searchValue,
      [constants.API_KEYS.OFFSET]: offset,
      [constants.API_KEYS.LIMIT]: limit
    });

    localActions.setLabels(labels);

    return {
      results: labels.map((label: NormalizedEitlLabel) => ({
        [constants.TABLE_FIELDS.ID]: String(label.id),
        [constants.TABLE_FIELDS.NAME]: label.name,
        [constants.TABLE_FIELDS.CREATED_BY]: label.userId ?? '-',
        [constants.TABLE_FIELDS.CREATED_AT]: uiLib.formatDate(label.createdAt, {
          format: uiLib.DATE_FORMATS.HUMANIZED_DAY_MONTH_YEAR,
          passedDateFormat: dateConstants.UNCONVENTIONAL_DATE_FORMAT.API_WITH_GMT
        }),
        [constants.TABLE_FIELDS.CRAWLS_COUNT]: label.crawlsCount,
        [constants.TABLE_FIELDS.DOCUMENT_COUNT]: label.documentCount
      })),
      count
    };
  };

  const handleParamsChange: EitlLabelsHandlers['handleParamsChange'] = params => {
    localActions.setParams(params);
  };

  const handleOrganizationFetch: EitlLabelsHandlers['handleOrganizationFetch'] = async () => {
    try {
      await organizationReduxActions.fetchOrganization(reduxState.currentUserOrgId);
    } catch (e) {
      errorUtils.logError(e as Error);
    }
  };

  return {
    handleDeleteLabelsModalOpen,
    handleDeleteLabelsModalClose,
    handleDeleteLabelsModalConfirm,
    handleRenameLabelModalOpen,
    handleRenameLabelModalClose,
    handleRenameLabelModalConfirm,
    handleAddLabelModalToggle,
    handleAddLabelModalConfirm,
    handleImportLabelsModalToggle,
    handleImportLabelsModalConfirm,
    handleFiltersSubmit,
    handleDataFetch,
    handleParamsChange,
    handleOrganizationFetch
  };
};
