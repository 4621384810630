import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from 'app/common/Button';

const StyledDialogTitle = styled(DialogTitle)`
  padding-bottom: 0px;
  .jurispect-MuiTypography-root {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const TEST_ID = {
  DIALOG_CONTAINER: 'dialog-container',
  DIALOG_CONTENT: 'dialog-content'
};

export function DialogComponent({
  onConfirm,
  confirmButtonText,
  cancelButtonText,
  message,
  triggerContent,
  title,
  disabled
}) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleConfirm = async () => {
    try {
      await onConfirm();
      handleClose();
    } catch (err) {
      // do not close
    }
  };

  return (
    <>
      <span data-testid={TEST_ID.DIALOG_CONTAINER} onClick={handleOpen}>
        {triggerContent}
      </span>
      <Dialog open={open} onClose={handleClose}>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent data-testid={TEST_ID.DIALOG_CONTENT}>{message}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type="secondary">
            {cancelButtonText}
          </Button>
          <Button data-testid={'confirm-b'} onClick={handleConfirm} type="primary">
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DialogComponent.propTypes = {
  triggerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  title: PropTypes.string
};

DialogComponent.defaultProps = {
  disabled: false,
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
  message: 'Are you sure you want to take this action?',
  title: 'Confirmation'
};

export default DialogComponent;
