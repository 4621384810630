import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import PDFobjecct from 'pdfobject';
import _ from "lodash";
import DocumentVersionInfo from './DocumentVersionInfo';

const DocumentVersionHistory = ({ docDetailsInfo, docTimestamp }) => {
  const [docVersion, setDocVersion] = useState(_.get(docDetailsInfo, ['document_versions', 0], {}));

  useEffect(() => {
    const pdfFile = _.get(docVersion, 'file_s3_url', '');
    PDFobjecct.embed(pdfFile, 'div#document-text');
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docTimestamp, _.get(docVersion, 'id', 0)]);

  const changeCurrentDocVersion = id => setDocVersion(
    _.get(docDetailsInfo, 'document_versions', []).find(item => item.id === id)
  );

  return (
    <div className="document-list">
      <div className="document-text" id="document-text" />
      <div className="document-list_details">
        <DocumentVersionInfo
          docDetailsInfo={docDetailsInfo}
          currentDocVersion={docVersion}
          changeCurrentDocVersion={changeCurrentDocVersion}
        />
      </div>
    </div>
  );
};

DocumentVersionHistory.propTypes = {
  docDetailsInfo: PropTypes.shape({}).isRequired,
  docTimestamp: PropTypes.string
};

DocumentVersionHistory.defaultProps = {
  docTimestamp: ''
};

export default DocumentVersionHistory;
