import type { BodyCrawlStatusProps } from '../BodyCrawlStatus.types';

import { getStatus } from './useBodyCrawlStatusData.helpers';

export const useBodyCrawlStatusData = ({ report }: Pick<BodyCrawlStatusProps, 'report'>) => {
  const formattedData = {
    status: getStatus(report)
  };

  return {
    formattedData
  };
};
