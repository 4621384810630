import type { ExportDropdownHandlers } from './useExportDropdownHandlers.types';
import type { ExportDropdownData } from './useExportDropdownData.types';
import type { ExportDropdownProps } from '../ExportDropdown.types';

import * as exportConstants from 'admin/constants/Export';

export const useExportDropdownHandlers = ({
  props,
  localActions
}: {
  props: Pick<
    ExportDropdownProps,
    'selectedDocumentIds' | 'onExportModalOpen' | 'onSelectContentType'
  >;
  localActions: ExportDropdownData['localActions'];
}): ExportDropdownHandlers => {
  const handleDropdownToggle: ExportDropdownHandlers['handleDropdownToggle'] = isOpen => () => {
    localActions.setIsOpen(!props.selectedDocumentIds.length ? false : isOpen);
  };

  const handleContentTypeSelect: ExportDropdownHandlers['handleContentTypeSelect'] = ([option]) => {
    props.onSelectContentType(
      (option.value as keyof typeof exportConstants.CONTENT_TYPES) ||
        exportConstants.CONTENT_TYPES.CSV
    );
    localActions.setIsOpen(false);
    props.onExportModalOpen();
  };

  return {
    handleDropdownToggle,
    handleContentTypeSelect
  };
};
