import { fetch } from 'fetch';

export function api_fetchConcepts(params = {}) {
  return fetch({
    url: '/jurisdiction_analyzer_concepts',
    method: 'GET',
    dataType: 'Concept',
    params
  });
}

export function api_addConcept(params) {
  return fetch({
    url: `/jurisdiction_analyzer_concepts`,
    method: 'POST',
    dataType: 'Concept',
    params
  });
}

export function api_updateConcept(currentID, params) {
  return fetch({
    url: `/jurisdiction_analyzer_concepts/${currentID}`,
    method: 'POST',
    dataType: 'Concept',
    params
  });
}

export function api_deleteConcept(currentID) {
  return fetch({
    url: `/jurisdiction_analyzer_concepts/${currentID}`,
    dataType: 'Concept',
    method: 'DELETE'
  });
}
