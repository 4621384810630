import type { CrawlMonitorToolData } from './useCrawlMonitorToolData.types';
import type { CrawlMonitorToolHandlers } from './useCrawlMonitorToolHandlers.types';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../CrawlMonitorTool.constants';

export const useCrawlMonitorToolTableActions = ({
  localState,
  events
}: {
  localState: CrawlMonitorToolData['localState'];
  events: {
    onRunNow: CrawlMonitorToolData['localActions']['setRunNowModalActive'];
    onDeactivate: CrawlMonitorToolHandlers['handleDeactivateSelectedCrawls'];
    onMoveToIdle: CrawlMonitorToolHandlers['handleMoveToIdleSelectedCrawls'];
  };
}) => {
  return [
    {
      title: 'Run now',
      icon: <uiLib.IconMove />,
      shouldPreventRefreshOnTrigger: true,
      shouldKeepSelectedRows: true,
      isColumnAction: true,
      disabled:
        localState.isActionDisabled ||
        !localState.selectedCrawl.length ||
        localState.selectedCrawl.length > constants.CRAWL_ACTION_AMOUNT,
      trigger: async () => events.onRunNow(true)
    } as uiLib.ColumnAction,
    {
      title: 'Deactivate',
      icon: <uiLib.IconDangerous />,
      shouldPreventRefreshOnTrigger: true,
      shouldKeepSelectedRows: true,
      isColumnAction: true,
      disabled:
        localState.isActionDisabled ||
        !localState.selectedCrawl.length ||
        localState.selectedCrawl.length > constants.CRAWL_ACTION_AMOUNT,
      trigger: async () => events.onDeactivate()
    } as uiLib.ColumnAction,
    {
      title: 'Move crawls to idle',
      icon: <uiLib.IconDragIndicator />,
      shouldPreventRefreshOnTrigger: true,
      shouldKeepSelectedRows: true,
      isColumnAction: true,
      disabled:
        localState.isActionDisabled ||
        !localState.selectedCrawl.length ||
        localState.selectedCrawl.length > constants.CRAWL_MOVE_TO_IDLE_ACTION_AMOUNT,
      trigger: async () => events.onMoveToIdle()
    } as uiLib.ColumnAction
  ];
};
