import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

export const REPORTS_BASE_QUERY_PARAMS = {
  parent_report_id: 0,
  exclude_types: ['dry_run']
};

export const CHILD_REPORTS_BASE_QUERY_PARAMS = {
  limit: 100,
  offset: 0,
  exclude_types: ['dry_run']
};

export const TABLE_LOCAL_STORAGE_KEY = 'crawlActivityTable';

export enum TABLE_VARIABLES {
  id = 'id',
  start_time = 'start_time',
  end_time = 'end_time',
  status = 'status',
  run_type = 'run_type',
  docs_ingested = 'docs_ingested',
  docs_updated = 'docs_updated',
  docs_skipped = 'docs_skipped',
  docs_failed = 'docs_failed',
  docs_error = 'docs_error',
  docs_processed = 'docs_processed',
  docs_ingested_sla = 'docs_ingested_sla',
  docs_ingested_outside_sla = 'docs_ingested_outside_sla',
  has_child_reports = 'has_child_reports',
  child_reports_count = 'child_reports_count',
  failed_docs_stat = 'failed_docs_stat',
  logs = 'logs',
  // only filters
  multiField = 'multi_input',
  run_start = 'run_start',
  child_unfinished = 'child_unfinished'
}

export const COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Date Run Start',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.DateCell,
    dataKey: TABLE_VARIABLES.start_time
  },
  {
    title: 'Date Run End',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.DateCell,
    dataKey: TABLE_VARIABLES.end_time
  },
  {
    title: 'Status',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: elements.StatusCell,
    dataKey: TABLE_VARIABLES.status
  },
  {
    title: 'Logs',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    Component: elements.LogsLinkCell,
    dataKey: TABLE_VARIABLES.docs_error
  },
  {
    title: 'Run Type',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    dataKey: TABLE_VARIABLES.run_type
  },
  {
    title: 'Ingested',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    className: 'crawl-activity-table__column-separator',
    dataKey: TABLE_VARIABLES.docs_ingested
  },
  {
    title: 'Updated',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    dataKey: TABLE_VARIABLES.docs_updated
  },
  {
    title: 'Skipped',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    dataKey: TABLE_VARIABLES.docs_skipped
  },
  {
    title: 'Failed',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    dataKey: TABLE_VARIABLES.docs_failed
  },
  {
    title: 'Processed',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    className: 'crawl-activity-table__column-separator',
    Component: elements.ProcessedDocsCell,
    dataKey: TABLE_VARIABLES.docs_processed
  },
  {
    title: 'Within SLA',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    dataKey: TABLE_VARIABLES.docs_ingested_sla
  },
  {
    title: 'Outside of SLA',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.XS,
    dataKey: TABLE_VARIABLES.docs_ingested_outside_sla
  },
  {
    title: '',
    isDynamic: false,
    isInitiallyVisible: true,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    dataKey: TABLE_VARIABLES.id,
    Component: elements.DocsLinkCell
  }
];

export const INITIAL_ROWS_PER_PAGE = 10;

export const ROWS_PER_PAGE_OPTIONS = [1, 2, 5, 10, 20, 50, 100];
