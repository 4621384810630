import {
  api_fetchCollections,
  api_addCollection,
  api_updateCollection,
  api_deleteCollection
} from './collection.api';

export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS';
export const CLEAR_COLLECTIONS = 'CLEAR_COLLECTIONS';

function receiveCollections(json) {
  return {
    type: RECEIVE_COLLECTIONS,
    collections: json
  };
}

function clearCollections(json) {
  return {
    type: CLEAR_COLLECTIONS,
    collections: null
  };
}

export function fetchCollections(params = {}) {
  return function dofetch(dispatch) {
    dispatch(clearCollections());
    return api_fetchCollections(params)
      .then(response => {
        dispatch(receiveCollections(response));
        return response;
      })
      .catch(error => {
        dispatch(receiveCollections({}));
      });
  };
}

export function addCollection(params) {
  return function dopost(dispatch) {
    dispatch(clearCollections());
    return api_addCollection(params)
      .then(response => {
        dispatch(receiveCollections(response));
      })
      .catch(error => {
        dispatch(receiveCollections({}));
      });
  };
}

export function updateCollection(currentID, params) {
  return function dopost(dispatch) {
    dispatch(clearCollections());
    return api_updateCollection(currentID, params)
      .then(response => {
        dispatch(receiveCollections(response));
      })
      .catch(error => {
        dispatch(receiveCollections({}));
      });
  };
}

export function deleteCollection(currentID, params) {
  return function dopost(dispatch) {
    dispatch(clearCollections());
    return api_deleteCollection(currentID, params)
      .then(response => {
        dispatch(receiveCollections(response));
      })
      .catch(error => {
        dispatch(receiveCollections({}));
      });
  };
}
