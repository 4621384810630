import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { COLORS } from 'app/constants/Style';

export const TEST_ID = 'common-tabs';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white'
  },
  indicator: {
    backgroundColor: COLORS.hoverBlue
  }
}));

const TabTitle = styled(Tab)`
  font-size: 13px !important;
`;

function SharedTabs({ tabs }) {
  const classes = useStyles();
  const foundIndex = _.findIndex(tabs, 'default');
  const [tabIndex, setTabIndex] = React.useState(foundIndex !== -1 ? foundIndex : 0);

  const handleChange = (e, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          data-testid={TEST_ID}
          value={tabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            indicator: classes.indicator
          }}
          aria-label="scrollable auto tabs example"
        >
          {tabs.map(({ title }, index) => (
            <TabTitle
              label={title}
              key={index}
              id={`scrollable-auto-tab-${index}`}
              aria-controls={`scrollable-auto-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map(({ content }, index) => (
        <div
          role="tabpanel"
          key={index}
          data-testid={`tab-panel-${index}`}
          hidden={tabIndex !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
        >
          {tabIndex === index && (
            <Box p={3}>
              {content}
            </Box>
          )}
        </div>
      ))}
    </div>
  );
}

SharedTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    content: PropTypes.element.isRequired
  })).isRequired
};

export default SharedTabs;
