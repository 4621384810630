import { DocumentSettingsNavigationData } from '../../hooks/useDocumentSettingsNavigationData.types';
import { useMemo, useState } from 'react';
import { DocumentDetailsData } from './useDocumentDetailsData.types';

export const useDocumentDetailsData = ({
  docDetailsInfo
}: {
  docDetailsInfo: DocumentSettingsNavigationData['reduxState']['docDetailsInfo'];
}): DocumentDetailsData => {
  const [flagAction, setFlagAction] = useState<DocumentDetailsData['localState']['flagAction']>('');

  const pdfUrl = useMemo(
    () => docDetailsInfo?.document_versions?.[0]?.file_s3_url,
    [docDetailsInfo]
  );

  const localActions = useMemo(
    () => ({
      setFlagAction
    }),
    []
  );

  return {
    formattedData: {
      pdfUrl
    },
    localActions,
    localState: {
      flagAction
    }
  };
};
