import _ from 'lodash';
import dashboardCategory from '../constants/dashboardCategory';
import { typeOfAccess } from '../constants/userRoles';

export default function checkCurrentCategoryByURL(routePath) {
  const currentCategory = _.find(
    dashboardCategory,
    category => _.some(
      _.get(category, 'subcategory', []),
      subcategory => routePath.includes(subcategory.path)
    )
  );
  return _.get(currentCategory, 'value');
}

function hasFullAccess(user, categoryName) {
  return _.get(user, ['permissions', categoryName], '') === typeOfAccess.edit;
}

function hasAccess(user, categoryName) {
  return [typeOfAccess.view, typeOfAccess.edit].includes(_.get(user, ['permissions', categoryName], ''));
}

export { hasFullAccess, hasAccess };
