import {
  api_fetchDefaultSources
} from './sources.api';

export const REQUEST_POPULAR_SOURCES = 'REQUEST_POPULAR_SOURCES';
export const RECEIVE_POPULAR_SOURCES = 'RECEIVE_POPULAR_SOURCES';
export const REQUEST_SOURCES = 'REQUEST_SOURCES';
export const RECEIVE_SOURCES = 'RECEIVE_SOURCES';

function requestSources() {
  return {
    type: REQUEST_SOURCES
  };
}

function receiveSources(data) {
  return {
    type: RECEIVE_SOURCES,
    sources: data
  };
}

export function fetchDefaultSources() {
  return function doFetch(dispatch) {
    dispatch(requestSources());
    return api_fetchDefaultSources()
      .then(response => {
        dispatch(receiveSources(response));
        return response;
      });
  };
}
