import React from 'react';
import {
  Modal,
  Button,
  Alert,
  Col, Row,
  Checkbox,
  ControlLabel, FormGroup, FormControl
} from 'react-bootstrap';
import Select from "react-select";

export default function CommandsToolsModal(
  {
    showModal,
    closeModal,
    handleChange,
    name,
    description,
    type,
    file,
    fileType,
    requiredFile,
    error,
    errorText,
    onSave,
    additionalParameters,
    addNewParam,
    changeAdditionalParam,
    deleteAdditionalParam
  }
) {
  const paramTypeOptions = [
    { value: 'str', label: 'String' },
    { value: 'num', label: 'Number' }
  ];
  const extraParams = additionalParameters.map(item => (
    <FormGroup key={item.id}>
      <Row>
        <Col sm={4}>
          <FormControl
            className={error && !item.name.trim() ? "errorFormControl" : ""}
            type="text"
            name="name"
            value={item.name}
            maxLength="255"
            onChange={(e) => changeAdditionalParam(item, { [e.target.name]: e.target.value })}
          />
        </Col>
        <Col sm={3} className="columnWithoutLeftPadding">
          <Select
            options={paramTypeOptions}
            name="type"
            value={item.type}
            onChange={(e) => changeAdditionalParam(item, { type: e.value })}
          />
        </Col>
        <Col sm={4} className="columnWithoutLeftPadding">
          <FormControl
            className={error && ((item.type === 'str' && !item.default.trim()) || (item.type === 'num' && isNaN(+(item.default)))) ? "errorFormControl" : ""}
            type="text"
            name="default"
            value={item.default}
            maxLength="255"
            onChange={(e) => changeAdditionalParam(item, { [e.target.name]: e.target.value })}
          />
        </Col>
        <Col sm={1} className="columnWithoutLeftPadding">
          <Button bsStyle="danger" className="closeBtn" onClick={() => deleteAdditionalParam(item)}>X</Button>
        </Col>
      </Row>
    </FormGroup>
  ));
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className="docMetaModal thinBtn"
    >
      <Modal.Header>
        <Modal.Title>Create new command</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <FormGroup className={error && !name.trim() ? "errorFormGroup" : ""}>
            <ControlLabel>Name of Command:</ControlLabel>
            <FormControl
              type="text"
              name="name"
              value={name}
              maxLength="255"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Description of Command:</ControlLabel>
            <FormControl
              componentClass="textarea"
              name="description"
              value={description}
              maxLength="1000"
              onChange={handleChange}
            />
          </FormGroup>
          <hr />
          <h4 className="sectionHead">Parameters</h4>
          <FormGroup className={error && !type.trim() ? "errorFormGroup" : ""}>
            <Row>
              <Col sm={4} className="centerText">
                <ControlLabel>Type:</ControlLabel>
              </Col>
              <Col sm={8}>
                <FormControl
                  type="text"
                  name="type"
                  value={type}
                  maxLength="255"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={4} className="centerText">
                <ControlLabel>File:</ControlLabel>
              </Col>
              <Col sm={8}>
                <Checkbox
                  className="checkboxForm"
                  name="file"
                  checked={file}
                  onChange={(e) => handleChange({ target: { name: 'file', value: e.target.checked } })}
                >
                  {file ? 'True' : 'False'}
                </Checkbox>
              </Col>
            </Row>
          </FormGroup>
          {file && (
            <div>
              <FormGroup>
                <Row>
                  <Col sm={4} className="centerText">
                    <ControlLabel>Type of File:</ControlLabel>
                  </Col>
                  <Col sm={8}>
                    <FormControl
                      type="text"
                      name="fileType"
                      value={fileType}
                      placeholder=".tsv"
                      maxLength="255"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm={4} className="centerText">
                    <ControlLabel>File is required:</ControlLabel>
                  </Col>
                  <Col sm={8}>
                    <Checkbox
                      className="checkboxForm"
                      name="requiredFile"
                      onChange={(e) => handleChange({ target: { name: 'requiredFile', value: e.target.checked } })}
                      checked={requiredFile}
                    >
                      {requiredFile ? 'True' : 'False'}
                    </Checkbox>
                  </Col>
                </Row>
              </FormGroup>
            </div>
          )}
          <hr />
          <h4 className="sectionHead">Additional parameters</h4>
          {extraParams.length > 0 && (
            <FormGroup>
              <Row>
                <Col sm={4} className="centerText">
                  <ControlLabel>Name</ControlLabel>
                </Col>
                <Col sm={3} className="centerText">
                  <ControlLabel>Type</ControlLabel>
                </Col>
                <Col sm={4} className="centerText">
                  <ControlLabel>Default value</ControlLabel>
                </Col>
                <Col sm={1} />
              </Row>
            </FormGroup>
          )}
          {extraParams}
          <Button className="sectionHead" onClick={addNewParam}>Add parameter</Button>
          {error && (
            <Alert bsStyle="danger" className="errorAlert">
              {errorText || 'Please check you entries!'}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={onSave}>Submit</Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
