import type { CommandRequestsFiltersHandlers } from './useCommandRequestsFiltersHandlers.types';
import type { CommandRequestsFiltersProps } from '../CommandRequestsFilters.types';

import * as helpers from './useCommandRequestsFiltersHandlers.helpers';

export const useCommandRequestsFiltersHandlers = ({
  props
}: {
  props: Pick<CommandRequestsFiltersProps, 'onSubmit'>;
}): CommandRequestsFiltersHandlers => {
  const handleFiltersSubmit: CommandRequestsFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatCategoriesQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
