import React from 'react';
import { Button, BUTTON_TYPES, LoadingOverlay, Modal as CommonModal } from 'app/common';
import './_modal.scss';

export const Modal = ({ onClose, isLoading, children, title, headingMessage }) => {
  return (
    <CommonModal
      open
      onClose={onClose}
      childContainerClassName="modal__container"
      headerClassName="modal__header"
      maxWidth="425px"
    >
      <LoadingOverlay isLoading={isLoading}>
        <p className="modal__text-top">
          <h4>{title}</h4>
          {headingMessage}
        </p>
        {children}
        <p className="modal__text-bottom">
          Having trouble?
          {' '}
          <a href="mailto:support@compliance.ai">
            <Button type={BUTTON_TYPES.TERTIARY_BOLD}>Contact support@compliance.ai</Button>
          </a>
        </p>
      </LoadingOverlay>
    </CommonModal>
  );
};
