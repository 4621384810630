import type { BulkReprocessModalRuleData } from './useBulkReprocessModalRuleData.types';
import type { BulkReprocessModalRuleProps } from '../BulkReprocessModalRule.types';

import * as helpers from './useBulkReprocessModalRuleData.helpers';

import { useMemo } from 'react';

export const useBulkReprocessModalRuleData = ({
  props
}: {
  props: Pick<BulkReprocessModalRuleProps, 'action' | 'availableBulkReprocessActions'>;
}): BulkReprocessModalRuleData => {
  const formattedData: BulkReprocessModalRuleData['formattedData'] = useMemo(
    () => ({
      bulkReprocessActionsOptions: helpers.getBulkReprocessActionsOptions(props)
    }),
    [props]
  );

  return {
    formattedData
  };
};
