import * as documentConstants from 'admin/constants/document';
import * as constants from '../ExportModal.constants';

export const formatColumnsToKeys = (selectedColumns: string[]): string[] => {
  const apiColumns = [...constants.BASIC_EXPORT_COLUMNS]
    .filter(
      column =>
        Boolean(selectedColumns.includes(column)) &&
        Boolean(documentConstants.API_KEYS[column as keyof typeof documentConstants.API_KEYS])
    )
    .map(column => documentConstants.API_KEYS[column as keyof typeof documentConstants.API_KEYS]);

  return apiColumns;
};
