import { useMemo, useState } from 'react';
import { DOCUMENT_DETAILS, EDIT_STATUSES } from '../../../DocumentDetails.constants';
import type { DocumentSettingsNavigationData } from '../../../../hooks/useDocumentSettingsNavigationData.types';
import { DocumentDetailsData } from '../../../hooks/useDocumentDetailsData.types';
import { DocumentDetailsTabsData } from './useDocumentDetailsTabsData.types';
import _ from 'lodash';
import { getPipelineStatus } from '../DocumentDetails.helper';

export const useDocumentDetailsTabsData = ({
  id,
  flagAction,
  docDetailsInfo
}: {
  id: DocumentSettingsNavigationData['formattedData']['id'];
  flagAction: DocumentDetailsData['localState']['flagAction'];
  docDetailsInfo: DocumentSettingsNavigationData['reduxState']['docDetailsInfo'];
}): DocumentDetailsTabsData => {
  const [editMode, setEditMode] = useState<DocumentDetailsTabsData['localState']['editMode']>(
    isNaN(Number(id))
  );
  const [docDetails, setDocDetails] =
    useState<DocumentDetailsTabsData['localState']['docDetails']>(DOCUMENT_DETAILS);
  const [errorFields, setErrorFields] = useState<
    DocumentDetailsTabsData['localState']['errorFields']
  >([]);
  const [action, setAction] = useState<DocumentDetailsTabsData['localState']['action']>(flagAction);

  const isEditableStatus = useMemo(
    () => !EDIT_STATUSES.includes(_.get(getPipelineStatus(docDetailsInfo), 'value')),
    [docDetailsInfo]
  );

  const localActions = useMemo(
    () => ({
      setEditMode,
      setDocDetails,
      setErrorFields,
      setAction
    }),
    []
  );

  return {
    localActions,
    localState: {
      editMode,
      docDetails,
      errorFields,
      action
    },
    formattedData: {
      isEditableStatus
    }
  };
};
