import {
  getExternalApiCrawls as reduxGetExternalApiCrawls,
  createExternalApiCrawls as reduxCreateExternalApiCrawls,
  updateExternalApiCrawls as reduxUpdateExternalApiCrawls,
  clearExternalApiCrawls as reduxClearExternalApiCrawls
} from '../externalApiCrawl.actions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useExternalApiCrawlReduxActions = () => {
  const dispatch = useDispatch();

  const updateExternalApiCrawls = useCallback(
    (...params) => {
      return dispatch(reduxUpdateExternalApiCrawls(...params));
    },
    [dispatch]
  );

  const createExternalApiCrawls = useCallback(
    (...params) => {
      return dispatch(reduxCreateExternalApiCrawls(...params));
    },
    [dispatch]
  );

  const getExternalApiCrawls = useCallback(
    (...params) => {
      return dispatch(reduxGetExternalApiCrawls(...params));
    },
    [dispatch]
  );

  const clearExternalApiCrawls = useCallback(
    (...params) => {
      return dispatch(reduxClearExternalApiCrawls(...params));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      updateExternalApiCrawls,
      createExternalApiCrawls,
      getExternalApiCrawls,
      clearExternalApiCrawls
    }),
    [updateExternalApiCrawls, createExternalApiCrawls, getExternalApiCrawls, clearExternalApiCrawls]
  );
};
