import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../components/Button";

const DocumentCategoriesButtons = ({
  splitCategory,
  combineCategories,
  uncheckAll,
  filterCategories
}) => {
  return (
    <div className="document-categories_button-block">
      <div>
        <Button onClick={splitCategory}>Split Category</Button>
        <Button onClick={combineCategories}>Combine Categories</Button>
      </div>
      <div>
        <Button onClick={uncheckAll}>Uncheck All</Button>
        <Button onClick={filterCategories}>Filter</Button>
      </div>
    </div>
  );
};

DocumentCategoriesButtons.propTypes = {
  splitCategory: PropTypes.func.isRequired,
  combineCategories: PropTypes.func.isRequired,
  uncheckAll: PropTypes.func.isRequired,
  filterCategories: PropTypes.func.isRequired
};

export default DocumentCategoriesButtons;
