import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import Checkbox from '../../../components/Checkbox';
import Button from "../../../components/Button";
import Typography from "../../../components/Typography";
import ExpandableMapList from "../HelpComponents/ExpandableMapList";
import TextInput from "../../../components/TextInput";

const SettingsModal = ({
  show,
  onHide,
  saveCodeFilter
}) => {
  const [statementsMap, setStatementsMap] = useState([]);
  const [isNumber, setIsNumber] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [isLower, setIsLower] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');

  const createNewStatementElement = () => ({
    value: '',
    label: ''
  });

  const prepareCodeFilter = () => {
    const mapValue = statementsMap.reduce((acc, value, index) => {
      const start = index === 0 ? '' : 'else ';
      const resValue = isNumber ? value.value : `'${value.value}'`;
      const lowerCase = isLower ? '.lower()' : '';
      const checkedValue = !isSingle ? 'r' : 'results[0]';
      return `${start}${acc}${resValue} if '${value.label}'${lowerCase} in ${checkedValue}${lowerCase} `;
    }, '');
    const defVal = isNumber ? defaultValue : `'${defaultValue}'`;
    let resultString = `${mapValue}else ${defVal}`;
    if (!isSingle) resultString = `[${resultString} for r in results]`;
    return resultString;
  };

  return (
    <Modal
      title="Custom code filter"
      show={show}
      onHide={onHide}
      className="crawl-spec_code-filter"
    >
      <div className="crawl-spec_code-filter_body">
        <Typography>
          Custom filter setup
        </Typography>
        <ExpandableMapList
          label="Correspondence Map (right value will be applied if result contain left value"
          value={statementsMap}
          onChange={(name, value) => setStatementsMap(value)}
          createNewItemTemplate={createNewStatementElement}
        />
        <TextInput
          label="Default Value (if statements map haven't found any value)"
          value={defaultValue}
          onChange={(e) => setDefaultValue(e.target.value)}
        />
        <Checkbox
          label="Result value is Number"
          onClick={setIsNumber}
          checked={isNumber}
        />
        <Checkbox
          label="Single value (Only first)"
          onClick={setIsSingle}
          checked={isSingle}
        />
        <Checkbox
          label="To Lowercase (convert all verified and used for verification values to lowercase)"
          onClick={setIsLower}
          checked={isLower}
        />
        <div className="crawl-spec_code-filter_btn-block">
          <Button
            color="highlighted"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            onClick={() => saveCodeFilter(prepareCodeFilter())}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

SettingsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  saveCodeFilter: PropTypes.func.isRequired
};

SettingsModal.defaultProps = {
  show: false
};

export default SettingsModal;
