import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Table, Modal } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import _ from 'lodash';
import Select from 'react-select';
import queryString from 'query-string';
import { withHistory } from 'admin/utils/hooks';

import Loader from 'app/common/Loader';
import {
  fetchAllOrganization,
  updateOrganization,
  fetchOrganization,
  updateOrganizationMember,
  removeOrgInvitation,
  addOrganizationMember
} from 'shared/features/organizations/organizations.actions';
import InviteOrgModal from './InviteOrgModal';

class EditOrganization extends React.Component {
  state = {
    inEditMode: false,
    addUsersToOrg: [],
    roleForUsers: {},
    usersNotInAnOrg: [],
    showModal: false,
    deleteModal: {
      open: false,
      member: {},
      reassign_user_id: null
    },
    name: '',
    address: '',
    url: '',
    phone: '',
    id: '',
    loading: false
  };

  componentDidMount() {
    const parsedQuery = queryString.parse(this.props.location.search);
    const orgId = parsedQuery.org_id;
    this.orgId = orgId;
    this.props.fetchOrganization(orgId);
    this.props.fetchAllOrganization();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { organization } = nextProps.organization;

    // If we haven't loaded the organization then bail
    if (!organization.id) {
      return;
    }

    let propData = {};

    if (organization.properties) {
      propData = {
        address: organization.properties.address,
        url: organization.properties.url,
        phone: organization.properties.phone
      };
    }

    // Set the state with the data from the response
    this.setState({
      id: organization.id,
      name: organization.name,
      ...propData
    });

    // build list of users not in an org
    const { organizations: all_organizations } = nextProps.all_organizations;
    const all_users = nextProps.all_users.users;
    if (all_organizations.length >= 0 && all_users.length > 0) {
      const usersInAnOrg = new Set([]);
      all_organizations.forEach(org => {
        org.organization_members.forEach(member => {
          usersInAnOrg.add(member.user_id);
        });
      });
      const usersNotInAnOrg = all_users
        .filter(user => !usersInAnOrg.has(user.id))
        .map(user => ({ label: user.email, value: user.id }));
      this.setState({ usersNotInAnOrg });
    }
  }

  orgId = null;

  addUsersToOrg = async () => {
    if (
      this.state.addUsersToOrg.length > 0
      && this.state.roleForUsers
      && this.state.roleForUsers.label
      && this.orgId
    ) {
      const new_members = this.state.addUsersToOrg.map(user => ({
        user_id: user.value,
        org_user_roles: [this.state.roleForUsers.value]
      }));
      await this.props.addOrganizationMember(this.orgId, { new_members });
      this.setState({ addUsersToOrg: [], roleForUsers: {} });
      this.props.fetchOrganization(this.orgId);
      this.props.fetchAllOrganization();
    }
  };

  openDeleteModal = member => this.setState({
    deleteModal: { open: true, member, reassign_user_id: null }
  });

  closeDeleteModal = () => this.setState({
    deleteModal: { open: false, member: {}, reassign_user_id: null }
  });

  saveOrgOrEdit = async event => {
    event.preventDefault();
    if (!this.state.inEditMode) {
      this.setState({ inEditMode: true });
      return;
    }
    const params = {};

    // Only set the name if we are updating it
    if (this.props.organization.organization.name !== this.state.name) {
      params.name = this.state.name;
    }

    params.properties = {
      url: this.state.url,
      address: this.state.address,
      phone: this.state.phone
    };

    await this.props.updateOrganization(this.props.organization.organization.id, params);
    this.setState({ inEditMode: false });
  };

  OrgFieldChange = (fieldName, event) => this.setState({ [fieldName]: event.target.value });

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleRoleChange = (field, val, user_id, org_id) => {
    const new_state = {};
    new_state[field] = val;
    const org_user_roles = [...[], new_state.org_user_roles];
    const params = { org_user_roles };
    this.props.updateOrganizationMember(org_id, user_id, params).then(() => {
      this.props.fetchOrganization(org_id);
    });
  };

  removePendingInvitation = invitation => {
    const params = { org_id: invitation.org_id, org_invitation_id: invitation.id };
    this.props.removeOrgInvitation(params).then(() => {
      this.props.fetchOrganization(invitation.org_id);
    });
  };

  removeOrgMember = (org_id, user_id, reassign_user_id) => {
    const params = { remove_from_org: true, reassign_user_id };
    this.setState({ loading: true });
    this.props.updateOrganizationMember(org_id, user_id, params).then(() => {
      this.setState({ loading: false });
      this.props.fetchOrganization(org_id);
      this.closeDeleteModal();
    });
  };

  render() {
    const organization = this.props.organization.organization;
    const org_members = organization.organization_members;
    const orgRoleOptions = organization.roles
      && organization.roles.map(role => ({ value: role.id, label: role.name }));
    const { deleteModal } = this.state;
    const reassignableUsers = [];
    let userToDeleteLabel = '';
    _.forEach(org_members, ({ first_name, last_name, email, user_id }) => {
      // When displaying users, show the fullname or the email
      const label = first_name && last_name ? `${first_name} ${last_name}` : email;
      if (user_id === deleteModal.member.user_id) {
        userToDeleteLabel = label;
      } else {
        reassignableUsers.push({ value: user_id, label });
      }
    });

    const invitebodyrows = org_members && (
      _.sortBy(org_members, 'email').map((member, i) => {
        return (
          <tr key={i}>
            <td>
              {member.first_name + ' ' + member.last_name}
            </td>
            <td>{member.email}</td>
            <td>
              <Select
                options={orgRoleOptions}
                value={member.org_user_roles[0] && member.org_user_roles[0].id}
                onChange={obj => this.handleRoleChange('org_user_roles', obj.value, member.user_id, member.org_id)}
              />
            </td>
            <td>
              <DeleteIcon onClick={this.openDeleteModal.bind(this, member)} className="delete" />
            </td>
          </tr>
        );
      })
    );
    const pendingInvitations = this.props.organization.organization.org_invitations;
    const pendingInvitationsRows = pendingInvitations
      ? pendingInvitations
        .sort((a, b) => b.id - a.id)
        .filter(invitation => invitation.invitation_status === 'pending')
        .map(invitation => {
          const inv_role = parseInt(invitation.org_user_roles[0], 10) || '';
          const found_role = organization.roles.filter(role => role.id === inv_role);
          const invitee_role = found_role ? found_role[0].name : 'Unknown Role';
          return (
            <tr key={`${invitation.invitee_email}${invitation.id}`}>
              <td />
              <td className="pendingData">
                <span>
                  {invitation.invitee_email}
                </span>
                <div className="pending">pending invitation</div>
              </td>
              <td>{invitee_role}</td>
              <td>
                <DeleteIcon
                  onClick={() => this.removePendingInvitation(invitation)}
                  className="delete"
                />
              </td>
            </tr>
          );
        })
      : [];
    const allRows = pendingInvitationsRows.concat(invitebodyrows);

    return (
      <div className="single-org-container">
        {this.state.loading && Loader()}
        <Modal show={deleteModal.open} onHide={this.closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Tasks to Another User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-user-selection">
              <div className="delete-user-selection-description">
                {`Select another user to take over ${userToDeleteLabel}'s responsibilities`}
              </div>
              <Select
                options={reassignableUsers}
                value={deleteModal.reassign_user_id}
                clearable={false}
                onChange={({ value }) => this.setState({
                  deleteModal: { ...deleteModal, reassign_user_id: value }
                })}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!deleteModal.reassign_user_id}
                onClick={() => this.removeOrgMember(
                  deleteModal.member.org_id,
                  deleteModal.member.user_id,
                  deleteModal.reassign_user_id
                )}
              >
                Re-Assign
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <h1>Edit an Organization</h1>
        <form>
          <Table striped condensed hover className="organizations-detail-table">
            <tbody>
              <tr>
                <td>
                  <h1>
                    <span>
                      <FormControl
                        type="text"
                        onChange={e => this.OrgFieldChange('name', e)}
                        value={this.state.name}
                        disabled={!this.state.inEditMode}
                      />
                    </span>
                  </h1>
                  <p>
                    <span>
                      <b className="with-input">URL: </b>
                      <FormControl
                        type="text"
                        onChange={e => this.OrgFieldChange('url', e)}
                        value={this.state.url}
                        disabled={!this.state.inEditMode}
                      />
                    </span>
                  </p>
                  <p>
                    <span>
                      <b className="with-input">Address: </b>
                      <FormControl
                        type="text"
                        onChange={e => this.OrgFieldChange('address', e)}
                        value={this.state.address}
                        disabled={!this.state.inEditMode}
                      />
                    </span>
                  </p>
                  <p>
                    <span>
                      <b className="with-input">Phone: </b>
                      <FormControl
                        type="text"
                        onChange={e => this.OrgFieldChange('phone', e)}
                        value={this.state.phone}
                        disabled={!this.state.inEditMode}
                      />
                    </span>
                  </p>
                </td>
                <td className="allorg-team-id">
                  <p>
                    <span>
                      <b>Teams: </b>
                    </span>
                  </p>
                  <p>
                    <span className="org-team-id-show">
                      <b>{'ID: '}</b>
                      <span />
                      {this.state.id}
                    </span>
                  </p>
                </td>
                <td>
                  <div className="actionButtons">
                    {this.state.inEditMode ? (
                      <button
                        type="button"
                        onClick={() => this.setState({ inEditMode: false })}
                        className="org-btn-cancel"
                      >
                        Cancel
                      </button>
                    ) : null}
                    <button type="button" onClick={this.saveOrgOrEdit} className="org-btn-edit">
                      {this.state.inEditMode ? 'Save' : 'Edit'}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </form>
        <Table className="org-members-Invite-div">
          <tbody>
            <tr>
              <td>
                <p>
                  <b>Members: </b>
                </p>
              </td>
              <td>
                <div>
                  <button type="button" className="addUserToOrgButton" onClick={() => this.openModal()}>
                    Invite Members by Email
                  </button>
                </div>
              </td>
              <td>
                <div className="addUsersToOrgSection">
                  <button
                    type="button"
                    className="addUserToOrgButton"
                    onClick={this.addUsersToOrg}
                    disabled={
                      this.state.addUsersToOrg.length <= 0
                      || (!this.state.roleForUsers || !this.state.roleForUsers.label)
                    }
                  >
                    Add Users To Org.
                  </button>

                  <div className="selectUserBox">
                    <div>Select User</div>
                    <Select
                      className="addUsersInput"
                      options={this.state.usersNotInAnOrg}
                      value={this.state.addUsersToOrg}
                      onChange={addUsersToOrg => this.setState({ addUsersToOrg })}
                      multi
                    />
                  </div>

                  {this.state.addUsersToOrg.length > 0 ? (
                    <div className="selectRoleBox">
                      <div>Select Role</div>
                      <Select
                        className="addUsersType"
                        options={orgRoleOptions}
                        value={this.state.roleForUsers}
                        onChange={roleForUsers => this.setState({ roleForUsers })}
                      />
                    </div>
                  ) : null}

                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="org-member-list">
          <Table>
            <thead>
              <tr>
                <th>
                  {'Name '}
                  <KeyboardArrowDownIcon />
                </th>
                <th>
                  {'Email '}
                  <KeyboardArrowDownIcon />
                </th>
                <th>Role</th>
                <th />
              </tr>
            </thead>
            <tbody>{allRows}</tbody>
          </Table>
          <InviteOrgModal
            close={this.closeModal}
            showModal={this.state.showModal}
            location={this.props.location}
            orgRoleOptions={orgRoleOptions}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organization,
    organizations: state.organizations,
    all_organizations: state.all_organizations,
    all_users: state.all_users
  };
};

export default connect(mapStateToProps, {
  fetchAllOrganization,
  updateOrganization,
  fetchOrganization,
  updateOrganizationMember,
  removeOrgInvitation,
  addOrganizationMember
})(withHistory(EditOrganization));
