import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import MTypography from "../Typography/mTypography";
import { colors } from "../colors/colors";

const useStyle = makeStyles({
  withoutLabel: {
    marginTop: 20
  },
  error: {
    border: `1px solid ${colors.RED_TOMATO}`
  },
  containerStyle: {
    height: 35,
    display: 'flex',
    width: '100%',
    cursor: 'auto',
    border: 'none',
    borderStyle: 'none',
    borderRadius: 5,
    '&:disabled': {
      backgroundColor: colors.WHITE
    }
  },
  inputStyle: {
    height: '100%',
    borderRadius: 5,
    backgroundColor: colors.BACKGROUND_MEDIUM,
    border: `1px solid ${colors.GREY_LIGHT}`
  }
});

const MPhoneInput = ({
  disabled,
  error,
  fullWidth,
  label,
  labelClass,
  labelId,
  onChange,
  value,
  wrapperClass
}) => {
  const classes = useStyle();
  return (
    <FormControl
      fullWidth={fullWidth}
      error={error}
      className={classNames(wrapperClass, !label ? classes.withoutLabel : undefined)}
    >
      <MTypography id={labelId} className={labelClass}>{label}</MTypography>
      <PhoneInput
        onChange={val => onChange(val)}
        fullWidth
        containerClass={classes.containerStyle}
        inputClass={classes.inputStyle}
        preferredCountries={['us']}
        disabled={disabled}
        country={'us' || 1}
        value={value}
      />
    </FormControl>
  );
};

MPhoneInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

MPhoneInput.defaultProps = {
  disabled: false,
  error: false,
  label: '',
  labelClass: ''
};

export default MPhoneInput;
