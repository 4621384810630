import * as documentConstants from 'admin/constants/document';

/*
 * Common Document Export Columns
 */
export const BASIC_EXPORT_COLUMNS_ONE = [
  documentConstants.ID_KEY,
  documentConstants.CREATED_AT_KEY,
  documentConstants.PUBLICATION_DATE_KEY,
  documentConstants.SUMMARY_TEXT_KEY,
  documentConstants.TITLE_KEY,
  documentConstants.PDF_URL_KEY,
  documentConstants.WEB_URL_KEY,
  documentConstants.META_TABLE_KEY,
  documentConstants.FULL_TEXT_HASH_KEY,
  documentConstants.API_TABLE_KEY,
  documentConstants.PIPELINE_STATUS_KEY,
  documentConstants.OFFICIAL_ID_KEY,
  documentConstants.PARENT_ID_KEY,
  documentConstants.PDF_HASH_KEY,
  documentConstants.DOC_SCRAPED_CATEGORY_ID_KEY,
  documentConstants.SCRAPED_CATEGORY_KEY
];
export const BASIC_EXPORT_COLUMNS_TWO = [
  documentConstants.JURISDICTION_ID_KEY,
  documentConstants.AGENCY_IDS_KEY,
  documentConstants.SPIDER_NAME_KEY,
  documentConstants.JURISDICTION_KEY,
  documentConstants.CATEGORY_KEY,
  documentConstants.DOCUMENT_EXAMINATION_RECORD_KEY,
  documentConstants.SPIDER_ID_KEY,
  documentConstants.INTERACTION_STATUS_KEY,
  documentConstants.DOCKET_ID_KEY,
  documentConstants.RESPONDENT_NAME_KEY,
  documentConstants.CITATION_IDS_KEY,
  documentConstants.INTERNAL_UPDATED_AT_KEY,
  documentConstants.UPDATED_AT_KEY,
  documentConstants.RULE_KEY,
  documentConstants.LANGUAGES_KEY,
  documentConstants.RELATIONS_KEY
];
export const BASIC_EXPORT_COLUMNS_THREE = [
  documentConstants.VERSIONS_KEY,
  documentConstants.EITL_LABELS_KEY,
  documentConstants.AGENCIES_KEY,
  documentConstants.CAI_CATEGORY_ID_KEY,
  documentConstants.FULL_TEXT_KEY,
  documentConstants.PARENT_DOC_KEY,
  documentConstants.MAINSTREAM_NEWS_KEY,
  documentConstants.EFFECTIVE_DATE_KEY,
  documentConstants.LATEST_DOCUMENT_VERSION_KEY,
  documentConstants.HAS_UNOFFICIAL_PUBLICATION_DATE_KEY,
  documentConstants.DIFFABLE_KEY,
  documentConstants.ENFORCEMENT_KEY,
  documentConstants.VIOLATION_KEY,
  documentConstants.PENALTY_KEY,
  documentConstants.RESPONDENT_KEY
];
export const BASIC_EXPORT_COLUMNS = [
  ...BASIC_EXPORT_COLUMNS_ONE,
  ...BASIC_EXPORT_COLUMNS_TWO,
  ...BASIC_EXPORT_COLUMNS_THREE
];
