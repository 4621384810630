import type { ConceptsData } from './useConceptsData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../Concepts.constants';

import { useMemo, useState } from 'react';

export const useConceptsData = (): ConceptsData => {
  const [filters, setFilters] = useState<ConceptsData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<ConceptsData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: constants.TABLE_FETCH_REQUEST_LIMIT
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<ConceptsData['localState']['reloadTrigger']>(false);

  const localState: ConceptsData['localState'] = {
    filters,
    tableParams,
    reloadTrigger
  };

  const localActions: ConceptsData['localActions'] = useMemo(
    () => ({
      setFilters,
      setTableParams,
      setReloadTrigger
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
