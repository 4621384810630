import * as modalConstants from '../../BulkEditModal.constants';

export const BULK_EDIT_ACTIONS_OPTIONS = [
  {
    label: 'Add',
    value: modalConstants.BULK_EDIT_ACTIONS.ADD
  },
  {
    label: 'Edit',
    value: modalConstants.BULK_EDIT_ACTIONS.EDIT
  },
  {
    label: 'Remove',
    value: modalConstants.BULK_EDIT_ACTIONS.REMOVE
  }
];

export const BULK_EDIT_ATTRIBUTES_OPTIONS = [
  {
    label: 'EITL Label',
    value: modalConstants.BULK_EDIT_ATTRIBUTES.EITL_LABEL
  },
  {
    label: 'Summary',
    value: modalConstants.BULK_EDIT_ATTRIBUTES.SUMMARY
  }
];

export const BULK_EDIT_ACTION_ATTRIBUTES: {
  [key in modalConstants.BULK_EDIT_ACTIONS]: modalConstants.BULK_EDIT_ATTRIBUTES[];
} = {
  [modalConstants.BULK_EDIT_ACTIONS.ADD]: [modalConstants.BULK_EDIT_ATTRIBUTES.EITL_LABEL],
  [modalConstants.BULK_EDIT_ACTIONS.EDIT]: [
    modalConstants.BULK_EDIT_ATTRIBUTES.EITL_LABEL,
    modalConstants.BULK_EDIT_ATTRIBUTES.SUMMARY
  ],
  [modalConstants.BULK_EDIT_ACTIONS.REMOVE]: [modalConstants.BULK_EDIT_ATTRIBUTES.EITL_LABEL]
};
