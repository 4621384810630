import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { deleteUser, fetchSpecifiedUser, fetchAllUsers } from 'shared/features/user/user.actions';
import MModal from "admin/components/MaterialComponents/Modal/MModal";
import { colors } from "admin/components/MaterialComponents/colors/colors";
import MButton from "admin/components/MaterialComponents/Buttons/Button/mButton";
import MTypography from "admin/components/MaterialComponents/Typography/mTypography";

const useStyles = makeStyles({
  defaultBlueButton: {
    color: colors.BLUE_MAIN,
    marginRight: 10,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: colors.BLUE_TRANSPARENT,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.BACKGROUND_BLUE
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.WHITE,
    height: '60%',
    width: '40%',
    padding: 20
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  userEmail: {
    fontSize: 15,
    fontWeight: 'bold'
  }
});

const UserDeleteModal = ({
  showModal,
  close,
  email,
  reduxDeleteUser,
  reduxFetchAllUsers
}) => {
  const classes = useStyles();

  const deleteUserAction = async () => {
    await reduxDeleteUser(email);
    close(true);
    reduxFetchAllUsers();
  };

  return (
    <MModal
      showModal={showModal}
      closeModal={() => close(false)}
      classNameModal={classes.modal}
      classNamePaper={classes.paper}
    >
      <MTypography className={classes.userEmail}>{email}</MTypography>
      {window.location.host === 'admin.compliance.ai' ? (
        <div>
          <strong>Pro users should never be deleted unless absolutely necessary.</strong>
        </div>
      ) : null}
      <div>
        {'Are you sure you want to delete ' + email + '?'}
        This action cannot be undone.
      </div>
      <img alt="gif" src="https://media.giphy.com/media/naxep4vNBAOL6/giphy.gif" />

      <div className={classes.buttonWrapper}>
        <MButton onClick={() => close(false)} classes={classes.defaultBlueButton}>Cancel</MButton>
        <MButton
          onClick={() => deleteUserAction()}
          classes={classes.defaultBlueButton}
        >
          Delete
        </MButton>
      </div>
    </MModal>
  );
};

UserDeleteModal.propTypes = {
  email: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  reduxFetchAllUsers: PropTypes.func.isRequired
};

export default connect(null, {
  reduxDeleteUser: deleteUser,
  fetchSpecifiedUser,
  reduxFetchAllUsers: fetchAllUsers
})(UserDeleteModal);
