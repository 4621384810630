import type { ReactNode } from 'react';
import type { CrawlQAReportsData } from './useCrawlerQAReportsData.types';
import type { CrawlQAReportsHandlers } from './useCrawlerQAReportsHandlers.types';

import * as constants from '../CrawlerQAReports.constants';
import * as elements from '../elements';

export const formatDetails = (
  details: CrawlQAReportsData['localState']['detailsModalInfo'],
  selectedCommandRequest: CrawlQAReportsData['localState']['selectedCommandRequest'],
  onOpenModal: CrawlQAReportsHandlers['handleDetailsModalOpen']
): ReactNode => {
  if (!details) return null;
  else if (details.length < constants.TEXT_LENGTH) return details;
  return (
    <elements.CrawlerQAReportsOpenModalButtonCell
      details={details}
      selectedCommandRequest={selectedCommandRequest}
      onOpenModal={onOpenModal}
    />
  );
};
