import _ from 'lodash';
import { CRAWL_GENERAL, CRAWL_LINKS } from './CrawlerDetails/CrawlerDetails.constants';
import {
  CRAWL_DETAILS_LABEL_MAP,
  CRAWL_DETAILS_REQUIRED_FOR_ACTIVATION,
  CRAWL_DETAILS_REQUIRED_FOR_ACTIVATION_FOR_TENANT
} from './CrawlerSettings.constants';
import CRAWL_TYPES from '../../constants/crawlersTypes';
import { createCommandRequest } from '../../utils/commandRequestHelper';
import { COMMAND_REQUEST_NAMES } from '../../constants/commandRequestTypes';

export const isRelativeComplexityDefined = crawlDetails => {
  if ([CRAWL_TYPES.unified.value, CRAWL_TYPES.email.value].includes(crawlDetails.type)) {
    return !_.isNull(crawlDetails[CRAWL_GENERAL.devComplexity]);
  }
  return true;
};

export const getInformationModalMessage = (crawlDetails, isMainTenant) => {
  const crawlDetailsRequiredForActivation = isMainTenant
    ? CRAWL_DETAILS_REQUIRED_FOR_ACTIVATION
    : CRAWL_DETAILS_REQUIRED_FOR_ACTIVATION_FOR_TENANT;
  const fieldsImportantForCrawlerDefinition = _.pick(
    crawlDetails,
    crawlDetailsRequiredForActivation
  );
  const missingImportantFields = _.pickBy(fieldsImportantForCrawlerDefinition, val => !val);
  let missingFieldsString = Object.keys(missingImportantFields)
    .map(item => CRAWL_DETAILS_LABEL_MAP[item])
    .join(', ');

  if (isMainTenant && !crawlDetails[CRAWL_GENERAL.newsSource] && !crawlDetails.agency_id) {
    missingFieldsString = missingFieldsString
      ? missingFieldsString.concat(', Agencies / News Sources')
      : 'Agencies / News Sources';
  }
  if (isMainTenant && !isRelativeComplexityDefined(crawlDetails)) {
    missingFieldsString = missingFieldsString
      ? missingFieldsString.concat(', Relative Complexity')
      : 'Relative Complexity';
  }
  return {
    title: 'Crawler cannot be activated',
    body: `Following fields are not defined: ${missingFieldsString}`
  };
};

export const isCrawlerDetailsWellDefined = (crawlDetails, isMainTenant) => {
  if (isMainTenant) {
    return (
      !!crawlDetails[CRAWL_GENERAL.spiderName] &&
      !!crawlDetails.id &&
      !!crawlDetails[CRAWL_LINKS.sourceURL] &&
      (!!crawlDetails[CRAWL_GENERAL.newsSource] || !!crawlDetails.agency_id) &&
      !!crawlDetails.category &&
      !!crawlDetails[CRAWL_GENERAL.jurisdiction] &&
      isRelativeComplexityDefined(crawlDetails)
    );
  }
  return (
    !!crawlDetails[CRAWL_GENERAL.spiderName] &&
    !!crawlDetails.id &&
    !!crawlDetails[CRAWL_GENERAL.jurisdiction]
  );
};

export function createAutoQACommandRequest({
  crawlDetailsInfo,
  extraParams = {},
  commands,
  current_user
}) {
  return createCommandRequest({
    name: `Auto QA for crawl: ${crawlDetailsInfo.spider_name}`,
    parameters: {
      spider_name: crawlDetailsInfo.spider_name,
      crawl_id: crawlDetailsInfo.id,
      ...extraParams,
      additional_params: {
        check_languages: true,
        check_citations: true,
        citations_format: 'alnum',
        check_problematic: true,
        check_docket_ids: true,
        check_parent_child: true,
        doc_limit: 100
      }
    },
    commands,
    current_user,
    commandRequestName: COMMAND_REQUEST_NAMES.AUTOMATED_CRAWL_QA_COMMAND
  });
}
