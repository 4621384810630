import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  getDocuments as reduxGetDocuments,
  updateDocuments as reduxUpdateDocuments
} from '../documentData.actions';

export const useDocumentDataReduxActions = () => {
  const dispatch = useDispatch();

  const getDocuments = useCallback(
    (...params) => {
      return dispatch(reduxGetDocuments(...params));
    },
    [dispatch]
  );

  const updateDocuments = useCallback(
    (...params) => {
      return dispatch(reduxUpdateDocuments(...params));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDocuments,
      updateDocuments
    }),
    [getDocuments, updateDocuments]
  );
};
