import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as constants from './CrawlerQAReports.constants';
import * as commandRequestsConstants from 'admin/constants/commandRequests';
import * as errorUtils from 'shared/utils/errors';

import {
  useCrawlerQAReportsData,
  useCrawlerQAReportsHandlers,
  useCrawlerQAReportsTableConfig,
  useCrawlerQAReportsTableDataSelector
} from './hooks';

import './_crawler-qa-reports.scss';

export const CrawlerQAReports: FC = () => {
  const { localState, localActions, formattedData, reduxState } = useCrawlerQAReportsData();
  const handlers = useCrawlerQAReportsHandlers({ localActions, formattedData });
  const tableConfig = useCrawlerQAReportsTableConfig({
    events: {
      onDelete: handlers.handleDelete
    }
  });
  const tableDataSelector = useCrawlerQAReportsTableDataSelector({
    reduxState,
    events: { onDetailsClick: handlers.handleDetailsModalOpen }
  });
  return (
    <uiLib.ErrorBoundary>
      <div className="crawler-qa-reports__container">
        <div className="crawler-qa-reports__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            actions={tableConfig.actions}
            externalParams={localState.tableParams}
            rowKey={commandRequestsConstants.COMMAND_REQUESTS_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleCommandRequestsFetch}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
            classNames={{
              container: 'crawler-qa-reports__table-outer-container',
              tableContainer: 'crawler-qa-reports__table-inner-container',
              toolbar: 'crawler-qa-reports__table-toolbar',
              headerCell: 'crawler-qa-reports__table-header-cell',
              selectedRowsCount: 'crawler-qa-reports__table-selected-rows-count'
            }}
          />
        </div>
      </div>
      {localState.isDetailsModalOpen && (
        <elements.CrawlerQAReportsDetailsModal
          details={localState.detailsModalInfo}
          onClose={handlers.handleDetailsModalClose}
          selectedCommandRequest={localState.selectedCommandRequest}
        />
      )}
    </uiLib.ErrorBoundary>
  );
};
