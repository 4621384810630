import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import * as actions from '../user.actions';

export const useUserReduxActions = () => {
  const dispatch = useDispatch();

  const fetchCurrentUser = useCallback(
    async (...args) => {
      return dispatch(actions.fetchCurrentUser(...args));
    },
    [dispatch]
  );

  const fetchAllUsers = useCallback(
    async (...args) => {
      return dispatch(actions.fetchAllUsers(...args));
    },
    [dispatch]
  );

  const fetchAppNotifications = useCallback(
    async (...args) => {
      return dispatch(actions.fetchAppNotifications(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchCurrentUser,
      fetchAllUsers,
      fetchAppNotifications
    }),
    [fetchAllUsers, fetchAppNotifications, fetchCurrentUser]
  );
};
