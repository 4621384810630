import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../../../../../../components/TextInput';
import { getStartName, getEndName } from '../../hooks/useFiltersHandlers.helpers';
import Typography from '../../../../../../../../../components/Typography';

const CrawlActivityHeaderSearchGapSelector = ({
  handleEventChanges,
  name,
  label,
  search,
  disableSecond
}) => {
  const startName = getStartName(name);
  const endName = getEndName(name);
  return (
    <label className="body-search-block_gap-selector">
      <Typography variant="tag" color="gray">
        {label}
      </Typography>
      <div className="body-search-block_gap-selector_inputs-wrapper">
        <TextInput
          name={startName}
          placeholder="From"
          onChange={handleEventChanges}
          value={search[startName]}
          type="number"
        />
        <TextInput
          name={endName}
          placeholder="To"
          onChange={handleEventChanges}
          value={search[endName]}
          type="number"
        />
      </div>
    </label>
  );
};

CrawlActivityHeaderSearchGapSelector.propTypes = {
  handleEventChanges: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  disableSecond: PropTypes.bool,
  search: PropTypes.shape({})
};

CrawlActivityHeaderSearchGapSelector.defaultProps = {
  label: '',
  name: '',
  search: {},
  disableSecond: false
};

export default CrawlActivityHeaderSearchGapSelector;
