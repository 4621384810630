import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Typography from '../../components/Typography';
import CreatableSelect from '../../components/ReactSelect/SelectCreatable';
import {
  SPEC_GENERAL,
  API_TABLE_DEFAULT_OPTIONS,
  META_TABLE_DEFAULT_OPTIONS
} from './CrawlSpecCreate.constants';

const CrawlSpecCreateMain = ({
  editMode,
  specInfo,
  handleChanges,
  errorFields
}) => {
  const disableEdit = !editMode;

  const [fold, setFold] = useState(false);

  return (
    <div className="crawl-spec_section crawl-spec_general-info">
      <div className="crawl-spec_section_head">
        <Typography variant="h3">{`${fold ? '-' : '+'}`}</Typography>
        <Typography
          variant="h3"
          onClick={() => setFold(!fold)}
        >
          Main Spec Info
        </Typography>
      </div>
      <div
        className={cn(
          'crawl-spec_section_body crawl-spec_general-info_body',
          { 'crawl-spec_section_body_fold': fold }
        )}
      >
        <CreatableSelect
          className="input_1"
          selectClassName="input_1_select"
          labelClassName="input_1_label"
          label="Api Table"
          placeholder="Select or input..."
          options={API_TABLE_DEFAULT_OPTIONS}
          value={specInfo[SPEC_GENERAL.apiTable]}
          onChange={(e) => handleChanges(SPEC_GENERAL.apiTable, e)}
          isDisabled={disableEdit}
          isError={errorFields.includes(SPEC_GENERAL.apiTable)}
          isClearable
        />
        <CreatableSelect
          className="input_1"
          selectClassName="input_1_select"
          labelClassName="input_1_label"
          label="Meta Table"
          placeholder="Select or input..."
          options={META_TABLE_DEFAULT_OPTIONS}
          value={specInfo[SPEC_GENERAL.metaTable]}
          onChange={(e) => handleChanges(SPEC_GENERAL.metaTable, e)}
          isDisabled={disableEdit}
          isError={errorFields.includes(SPEC_GENERAL.metaTable)}
          isClearable
        />
      </div>
    </div>
  );
};

CrawlSpecCreateMain.propTypes = {
  editMode: PropTypes.bool,
  handleChanges: PropTypes.func.isRequired,
  specInfo: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

CrawlSpecCreateMain.defaultProps = {
  editMode: false,
  specInfo: {},
  errorFields: []
};

export default CrawlSpecCreateMain;
