import type { DocumentFiltersHandlers } from './useDocumentFiltersHandlers.types';
import type { DocumentFiltersProps } from '../DocumentFilters.types';

import * as helpers from './useDocumentFiltersHandlers.helpers';

export const useDocumentFiltersHandlers = ({
  props
}: {
  props: Pick<DocumentFiltersProps, 'onSubmit'>;
}): DocumentFiltersHandlers => {
  const handleFiltersSubmit: DocumentFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatDocumentsQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
