import type * as eitlLabelsTypes from 'shared/features/eitlLabels/eitlLabels.types';

import type { BulkEditModalRuleProps } from '../BulkEditModalRule.types';
import type { BulkEditModalRuleHandlers } from './useBulkEditModalRuleHandlers.types';

export const useBulkEditModalRuleHandlers = ({
  props
}: {
  props: Pick<BulkEditModalRuleProps, 'value' | 'onValueChange'>;
}): BulkEditModalRuleHandlers => {
  const handleEitlLabelCreate: BulkEditModalRuleHandlers['handleEitlLabelCreate'] =
    eitlLabelOption => {
      const eitlLabelsOptions = props.value as eitlLabelsTypes.EitlLabelOption[];

      props.onValueChange([...eitlLabelsOptions, eitlLabelOption]);
    };

  return {
    handleEitlLabelCreate
  };
};
