import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "./Typography";
import TextInput from "./TextInput";

function ExpandableList({
  className,
  labelClassName,
  inputClassName,
  addMoreClassName,
  label,
  placeholder,
  value,
  onChange,
  isError,
  name,
  isLink,
  isDisabled,
  type
}) {
  const changeItemText = (e) => {
    const newValue = [...value];
    newValue[e.target.name] = e.target.value;
    onChange(name, newValue);
  };

  const addItem = () => {
    onChange(name, [ ...value, '' ]);
  };

  const removeItem = (index) => {
    onChange(name, value.filter((item, itemIndex) => itemIndex !== index));
  };

  return (
    <label
      className={cn(
        'expandable-list_wrapper',
        className
      )}
    >
      <Typography
        variant="tag"
        color="gray"
        className={labelClassName}
      >
        {label}
      </Typography>
      {value.map((item, index) => (
        <div
          key={index}
          className={cn(
            'expandable-list_item',
            {
              'expandable-list_item_disabled': isDisabled
            }
          )}
        >
          <TextInput
            type={type}
            name={index}
            value={item}
            isError={isError}
            isDisabled={isDisabled}
            isLink={isLink}
            inputClassName={inputClassName}
            placeholder={placeholder}
            onChange={changeItemText}
          />
          <CloseIcon
            onClick={() => removeItem(index)}
            className="expandable-list_item_close-icon expandable-list_item_close-icon_active"
          />
        </div>
      ))}
      {!isDisabled && (
        <Typography
          variant="label"
          color="blue"
          className={addMoreClassName}
          onClick={addItem}
        >
          + Add More
        </Typography>
      )}
    </label>
  );
}


ExpandableList.propTypes = {
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLink: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

ExpandableList.defaultProps = {
  isError: false,
  isDisabled: false,
  isLink: false,
  className: '',
  value: [],
  labelClassName: '',
  inputClassName: '',
  label: '',
  placeholder: '',
  name: '',
  type: ''
};


export default ExpandableList;
