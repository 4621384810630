import type { SourcePageData } from './useSourcePageData.types';
import type { BulkEditModalHandlers } from './useSourcePageHandlers.types';

import { useNewsSourcesReduxActions } from 'shared/features/sources/newsSources/hooks';
import { useCallback } from 'react';

export const useSourcePageHandlers = ({
  formattedData
}: {
  formattedData: SourcePageData['formattedData'];
}): BulkEditModalHandlers => {
  const newsSourcesReduxActions = useNewsSourcesReduxActions();

  const handleLoadNewsSource: BulkEditModalHandlers['handleLoadNewsSource'] = useCallback(() => {
    newsSourcesReduxActions.clearNewsSources();
    if (!isNaN(Number(formattedData.newsSourceId))) {
      newsSourcesReduxActions.fetchNewsSource({ id: formattedData.newsSourceId });
    }
  }, [formattedData.newsSourceId, newsSourcesReduxActions]);

  const handleUpdateNewsSourceStatus: BulkEditModalHandlers['handleUpdateNewsSourceStatus'] =
    isActive => {
      newsSourcesReduxActions.updateNewsSource({
        id: formattedData.newsSourceId,
        active: isActive
      });
    };

  return {
    handleLoadNewsSource,
    handleUpdateNewsSourceStatus
  };
};
