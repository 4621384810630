import _ from 'lodash';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants/projectSettings';
import {
  ROUNDING_DEGREE,
  TABLE_VARIABLES,
  DATE_FORMAT_SEARCH,
  STATUS_BADGE
} from './CrawlMonitorTool.constants';
import { api_getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.api';
import { api_getMetaCategories } from 'shared/features/categories/metaCategories/metaCategories.api';
import {
  api_getExternalApiCrawls,
  api_getExternalApiCrawlsStatuses,
  api_getExternalApiCrawlsRunStatuses
} from 'shared/features/externalApiCrawl/externalApiCrawl.api';
import { api_getAgencies } from 'shared/features/agencyData/agencyData.api.js';
import {
  CRAWL_STATUS_LABEL_MAP,
  CRAWL_RUN_STATUS_LABEL_MAP
} from '../CrawlerPage/CrawlerSettings.constants';
import { crawlStates } from '../../constants/crawlersTypes';
import * as externalApiCrawlConstants from 'shared/features/externalApiCrawl/externalApiCrawl.constants';
import ROUTES from '../../constants/routes';
import * as constants from './CrawlMonitorTool.constants';

// ----- Load Options -----

const formatSelectOptions = (array, valueName, labelName) =>
  array.map(item => ({
    value: _.get(item, valueName, item),
    label: _.get(item, labelName, item)
  }));

export const loadJurisdictionsOptions = (name, searchValue, responseName) =>
  api_getJurisdictions({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, responseName, responseName));

export const loadAgencyOptions = (name, searchValue, responseName) =>
  api_getAgencies({ [name]: searchValue })
    .then(agency => agency.items)
    .then(agency => formatSelectOptions(agency, 'id', responseName));

export const loadMetaCategoryOptions = (name, searchValue, responseName) =>
  api_getMetaCategories({ [name]: searchValue })
    .then(cat => cat.items)
    .then(cat =>
      cat.map(item => ({
        label: item.name,
        value: item.id,
        options: item.document_categories.map(catItem => ({
          label: catItem.app_category,
          value: catItem.id
        }))
      }))
    );

export const loadCrawlStatusOptions = (name, searchValue, responseName) =>
  api_getExternalApiCrawlsStatuses({ [name]: searchValue, light_version: true }).then(status =>
    _.compact(status.items).map(item => ({
      label: CRAWL_STATUS_LABEL_MAP[item] || item,
      value: item
    }))
  );

export const loadCrawlRunStatusOptions = (name, searchValue, responseName) =>
  api_getExternalApiCrawlsRunStatuses({ [name]: searchValue, light_version: true }).then(status =>
    _.compact(status.items).map(item => ({
      label: CRAWL_RUN_STATUS_LABEL_MAP[item] || item,
      value: item
    }))
  );

export const loadSpiderNameOptions = (name, searchValue, responseName) =>
  api_getExternalApiCrawls({
    [name]: searchValue,
    limit: externalApiCrawlConstants.SPIDER_NAMES_COUNT_PER_REQUEST,
    light_version: true
  })
    .then(crawl => crawl.items)
    .then(crawl => formatSelectOptions(crawl, 'id', responseName));

const getCrawlInfo = crawl => {
  // statistic
  const total_runs = _.get(crawl, 'crawler_statistics.total_runs', 0);
  const total_ingested = _.get(crawl, 'crawler_statistics.total_ingested', 0);
  const total_skipped = _.get(crawl, 'crawler_statistics.total_skipped', 0);
  const total_processed = _.get(crawl, 'crawler_statistics.total_processed', 0);
  const total_errors = _.get(crawl, 'crawler_statistics.total_errors', 0);
  const total_ingested_sla = _.get(crawl, 'crawler_statistics.total_ingested_sla', 0);
  const total_ingested_outside_sla = total_ingested - total_ingested_sla;
  // time
  const last_run = _.get(crawl, 'crawler_statistics.last_run', '');
  const first_run = _.get(crawl, 'crawler_statistics.first_run', '');
  const last_ingestion_time = _.get(crawl, 'crawler_statistics.last_ingested_date', '');
  // help function
  const countAverage = (value = 0) =>
    total_runs ? (value / total_runs).toFixed(ROUNDING_DEGREE) : 'N/A';
  const formatTime = date => (date ? moment(date).format(DATE_TIME_FORMAT) : 'N/A');
  const formatArray = array => array.map(item => _.get(item, 'name')).join(', ');

  return {
    // common info
    [TABLE_VARIABLES.is_active]: _.get(crawl, 'is_active', null),
    [TABLE_VARIABLES.is_resource]: _.get(crawl, 'is_resource', null),
    [TABLE_VARIABLES.status]: _.get(crawl, 'status', null),
    [TABLE_VARIABLES.run_status]: _.get(crawl, 'run_status', null),
    [TABLE_VARIABLES.id]: _.get(crawl, 'id', null),
    [TABLE_VARIABLES.spider_name]: {
      href: `${window.location.origin}${ROUTES.crawlerTool}/${_.get(crawl, 'id', null)}`,
      tooltip: _.get(crawl, 'spider_name', ''),
      children: _.get(crawl, 'spider_name', '')
    },
    [TABLE_VARIABLES.news_source]: _.get(crawl, 'news_source.name', null),
    [TABLE_VARIABLES.external_api_id]: _.get(crawl, 'external_api_id', null),
    [TABLE_VARIABLES.source_url]: _.get(crawl, 'source_url', null),
    [TABLE_VARIABLES.jurisdiction]: _.get(crawl, 'jurisdiction', null),
    [TABLE_VARIABLES.agency]: _.get(crawl, 'agency.short_name', null),
    [TABLE_VARIABLES.type]: _.get(crawl, 'type', null),
    [TABLE_VARIABLES.category]: _.get(crawl, 'category', null),
    // scraped info:
    [TABLE_VARIABLES.scraped_agencies]: formatArray(_.get(crawl, 'doc_scraped_agencies', [])),
    [TABLE_VARIABLES.scraped_news_sources]: formatArray(
      _.get(crawl, 'doc_scraped_news_sources', [])
    ),
    [TABLE_VARIABLES.scraped_categories]: formatArray(_.get(crawl, 'doc_scraped_categories', [])),
    // statistic info
    [TABLE_VARIABLES.total_runs]: total_runs,
    [TABLE_VARIABLES.last_run]: formatTime(last_run),
    [TABLE_VARIABLES.first_run]: formatTime(first_run),
    // // ingested info
    [TABLE_VARIABLES.total_ingested]: total_ingested,
    [TABLE_VARIABLES.last_ingested]: _.get(crawl, 'crawler_statistics.last_ingested', null),
    [TABLE_VARIABLES.ingested_average]: countAverage(total_ingested),
    [TABLE_VARIABLES.last_ingestion_time]: formatTime(last_ingestion_time),
    // // skipped info
    [TABLE_VARIABLES.total_skipped]: total_skipped,
    [TABLE_VARIABLES.last_skipped]: _.get(crawl, 'crawler_statistics.last_skipped', null),
    [TABLE_VARIABLES.skipped_average]: countAverage(total_skipped),
    // // processed info
    [TABLE_VARIABLES.total_processed]: total_processed,
    [TABLE_VARIABLES.last_processed]: _.get(crawl, 'crawler_statistics.last_processed', null),
    [TABLE_VARIABLES.processed_average]: countAverage(total_processed),
    // // error info
    [TABLE_VARIABLES.total_errors]: total_errors,
    [TABLE_VARIABLES.last_errors]: _.get(crawl, 'crawler_statistics.last_errors', null),
    [TABLE_VARIABLES.errors_average]: countAverage(total_errors),
    // // sla info
    [TABLE_VARIABLES.total_ingested_sla]: total_ingested_sla,
    [TABLE_VARIABLES.ingested_sla_average]: countAverage(total_ingested_sla),
    // outside sla infp
    [TABLE_VARIABLES.total_ingested_outside_sla]: total_ingested_outside_sla,
    [TABLE_VARIABLES.ingested_outside_sla_average]: countAverage(total_ingested_outside_sla),
    // crawl details
    [TABLE_VARIABLES.crawler_recurring_frequency]: _.get(
      crawl,
      'details.crawler_recur.interval',
      null
    ),
    [TABLE_VARIABLES.spiderRunReportStatus]: _.get(crawl, 'details.spider_run_report_status', null),
    [TABLE_VARIABLES.spiderRunReportID]: _.get(crawl, 'details.spider_run_report_id', null),
    [TABLE_VARIABLES.last_crawled]: _.get(crawl, 'last_crawled', null),
    [TABLE_VARIABLES.devComplexity]: _.get(crawl, 'dev_complexity', 0)
  };
};

// GAP Selectors helpers (necessary to keep naming logic in one place)
export const getStartName = name => `${name}_start`;
export const getEndName = name => `${name}_end`;
export const getTypeName = name => `${name}_type`;
// GAP Time helpers (necessary to keep naming logic in one place)
export const getStartDate = name => `${name}_start`;
export const getEndDate = name => `${name}_end`;

// ----- Search Options Helper -----

export const formatSearchValues = searchValues => {
  const formatParameters = {};
  for (const key in searchValues) {
    if (Object.prototype.hasOwnProperty.call(searchValues, key)) {
      let value = null;
      switch (key) {
        case TABLE_VARIABLES.scraped_agencies:
        case TABLE_VARIABLES.scraped_categories:
        case TABLE_VARIABLES.jurisdiction:
        case TABLE_VARIABLES.spider_name:
        case TABLE_VARIABLES.status:
        case TABLE_VARIABLES.run_status:
        case TABLE_VARIABLES.type: {
          const array = _.get(searchValues, key, []);
          value = array && array.length ? array.map(item => item.value) : null;
          break;
        }
        case getTypeName(TABLE_VARIABLES.devComplexity):
        case getTypeName(TABLE_VARIABLES.crawl_total_runs):
        case getTypeName(TABLE_VARIABLES.docs_errors):
        case getTypeName(TABLE_VARIABLES.docs_ingested):
        case getTypeName(TABLE_VARIABLES.docs_outside_sla):
        case getTypeName(TABLE_VARIABLES.docs_processed):
        case getTypeName(TABLE_VARIABLES.docs_skipped):
        case getTypeName(TABLE_VARIABLES.docs_within_sla):
        case getTypeName(TABLE_VARIABLES.crawler_frequency):
        case TABLE_VARIABLES.is_resource:
        case TABLE_VARIABLES.is_active:
        case TABLE_VARIABLES.is_versioning:
          value = _.get(searchValues, [key, 'value'], null);
          break;
        case getEndName(TABLE_VARIABLES.crawl_total_runs):
        case getEndName(TABLE_VARIABLES.docs_errors):
        case getEndName(TABLE_VARIABLES.docs_ingested):
        case getEndName(TABLE_VARIABLES.docs_outside_sla):
        case getEndName(TABLE_VARIABLES.docs_processed):
        case getEndName(TABLE_VARIABLES.docs_skipped):
        case getEndName(TABLE_VARIABLES.docs_within_sla):
        case getEndName(TABLE_VARIABLES.crawler_frequency):
        case getEndName(TABLE_VARIABLES.devComplexity):
        case getStartName(TABLE_VARIABLES.crawl_total_runs):
        case getStartName(TABLE_VARIABLES.docs_errors):
        case getStartName(TABLE_VARIABLES.docs_ingested):
        case getStartName(TABLE_VARIABLES.docs_outside_sla):
        case getStartName(TABLE_VARIABLES.docs_processed):
        case getStartName(TABLE_VARIABLES.docs_skipped):
        case getStartName(TABLE_VARIABLES.docs_within_sla):
        case getStartName(TABLE_VARIABLES.crawler_frequency):
        case getStartName(TABLE_VARIABLES.devComplexity):
        case TABLE_VARIABLES.multiField:
          value = _.get(searchValues, key, null);
          break;
        case getStartDate(TABLE_VARIABLES.last_ingestion_time):
        case getStartDate(TABLE_VARIABLES.last_run):
        case getStartDate(TABLE_VARIABLES.first_run):
        case getStartDate(TABLE_VARIABLES.last_updated_time):
        case getEndDate(TABLE_VARIABLES.last_ingestion_time):
        case getEndDate(TABLE_VARIABLES.last_run):
        case getEndDate(TABLE_VARIABLES.first_run):
        case getEndDate(TABLE_VARIABLES.last_updated_time): {
          const time = _.get(searchValues, key, null);
          value = time && moment(time).format(DATE_FORMAT_SEARCH);
          break;
        }
        default:
          break;
      }
      if (_.isBoolean(value) || value) formatParameters[key] = value;
    }
  }
  return formatParameters;
};

const getCrawlStatusBadgeInfo = crawl => {
  let badgeInfo;
  switch (crawl?.status) {
    case crawlStates.running:
      badgeInfo = STATUS_BADGE.RUNNING;
      break;
    case crawlStates.construction:
      badgeInfo = STATUS_BADGE.CONSTRUCTION;
      break;
    case crawlStates.failed:
      badgeInfo = STATUS_BADGE.FAILED;
      break;
    case crawlStates.timed_out:
      badgeInfo = STATUS_BADGE.TIMED_OUT;
      break;
    case crawlStates.stopRun:
      badgeInfo = STATUS_BADGE.STOP_RUN;
      break;
    case crawlStates.autoQA:
      badgeInfo = STATUS_BADGE.AUTO_QA;
      break;
    case crawlStates.sourceChanged:
      badgeInfo = STATUS_BADGE.SOURCE_CHANGED;
      break;
    case crawlStates.queued:
      badgeInfo = STATUS_BADGE.QUEUED;
      break;
    case crawlStates.idle:
      if (crawl?.is_active) {
        badgeInfo = STATUS_BADGE.ACTIVE;
        break;
      } else {
        badgeInfo = STATUS_BADGE.DEACTIVATED;
      }
      break;
    default:
      badgeInfo = { text: crawl?.status, type: 'error' };
  }
  return badgeInfo;
};

// export only for tests
export { getCrawlInfo, formatSelectOptions, getCrawlStatusBadgeInfo };

export const defaultCrawlMonitorToolSearch = {
  is_active: { value: true, label: 'True' }
};

export const getDevComplexityUpdate = (name, value, devComplexityCurrentType) => {
  let devComplexityChange = {};
  if (
    // change type dev complexity
    // set start / end date if type not custom
    name === getTypeName(constants.TABLE_VARIABLES.devComplexity) &&
    value.value !== constants.DEV_COMPLEXITY_FILTER_OPTIONS[0].value
  ) {
    devComplexityChange = {
      [getStartName(constants.TABLE_VARIABLES.devComplexity)]: value.start,
      [getEndName(constants.TABLE_VARIABLES.devComplexity)]: value.end
    };
  } else if (
    [
      getStartName(constants.TABLE_VARIABLES.devComplexity),
      getEndName(constants.TABLE_VARIABLES.devComplexity)
    ].includes(name)
  ) {
    if (_.isEmpty(value)) {
      // change type to null if user remove value for one of range
      devComplexityChange = {
        [`${constants.TABLE_VARIABLES.devComplexity}_type`]: null
      };
    } else if (devComplexityCurrentType) {
      // set type to custom if user change one of range
      devComplexityChange = {
        [`${constants.TABLE_VARIABLES.devComplexity}_type`]:
          constants.DEV_COMPLEXITY_FILTER_OPTIONS[0]
      };
    }
  }
  return devComplexityChange;
};
