import {
  api_fetchMetrics,
  api_addMetric,
  api_updateMetric,
  api_deleteMetric
} from './metric.api';

export const RECEIVE_METRICS = 'RECEIVE_METRICS';
export const CLEAR_METRICS = 'CLEAR_METRICS';

function receiveMetrics(json) {
  return {
    type: RECEIVE_METRICS,
    metrics: json
  };
}

function clearMetrics(json) {
  return {
    type: CLEAR_METRICS,
    metrics: null
  };
}

export function fetchMetrics(params = {}) {
  return function dofetch(dispatch) {
    dispatch(clearMetrics());
    return api_fetchMetrics(params)
      .then(response => {
        dispatch(receiveMetrics(response));
        return response;
      })
      .catch(error => {
        dispatch(receiveMetrics({}));
      });
  };
}

export function addMetric(params) {
  return function dopost(dispatch) {
    dispatch(clearMetrics());
    return api_addMetric(params)
      .then(response => {
        dispatch(receiveMetrics(response));
      })
      .catch(error => {
        dispatch(receiveMetrics({}));
      });
  };
}

export function updateMetric(currentID, params) {
  return function dopost(dispatch) {
    dispatch(clearMetrics());
    return api_updateMetric(currentID, params)
      .then(response => {
        dispatch(receiveMetrics(response));
      })
      .catch(error => {
        dispatch(receiveMetrics({}));
      });
  };
}

export function deleteMetric(currentID, params) {
  return function dopost(dispatch) {
    dispatch(clearMetrics());
    return api_deleteMetric(currentID, params)
      .then(response => {
        dispatch(receiveMetrics(response));
      })
      .catch(error => {
        dispatch(receiveMetrics({}));
      });
  };
}
