// Required for export information
import { LOG_TYPES } from 'shared/features/spiderRunReports/spiderRunReports.constants';

export const REQUIRED_CRAWL_DETAILS = [
  ['agency_id', 'news_source', 'premium_content'],
  'category',
  'external_api_id',
  'jurisdiction',
  'spider_name',
  'type',
  'source_url'
];
export const REQUIRED_CRAWL_FILES = ['crawl_config', 'first_run', 'total_ingested'];
// Important Info for export
export const EXPORTED_CRAW_FILES = [
  'catalog_config_url',
  'catalog_spec_url',
  'catalog_details_url'
];

//Required info for import
export const REQUIRED_IMPORT_INFO = ['catalog_config', 'catalog_details'];

// Errors
export const ERROR_EXPORT = {
  title: 'Export crawl info error',
  text: 'Please check all required fields.'
};
export const ERROR_IMPORT = {
  title: 'Import crawl info error',
  text: 'Please check that all required files in catalog.'
};
export const ERROR_UPDATE_CONFIG = {
  title: 'Json in broken',
  text: 'Uploaded JSON should have correct structure and format.'
};
export const ERROR_MODAL_ACTION = {
  title: 'Modal action error',
  text: 'Sorry, something went wrong. please check your inputs and try again.'
};
export const ERROR_CREATE_COMMAND_REQUEST = {
  title: 'Command Request Not Created',
  text: 'Command Request Not Created.'
};

// Confirmation Modal State
export const MODE_CONFIRMATION_MODAL = {
  EXPORT: { title: 'Export', body: 'Are you want to export this crawl?' },
  IMPORT: { title: 'Import', body: 'Are you want to import this crawl?' },
  CONVERT: { title: 'Convert', body: 'Are you want to convert this crawl?' },
  EDIT_CONFIG: {
    title: 'Edit Config',
    body: 'You are changing the config directly so there will be a discrepancy between spec and config files for the crawl! Make sure the config is working as intended by doing a test run.'
  },
  DRY_RUN: { title: 'Test Run', body: 'Are you want to run this crawl in test mode?' },
  DRY_RUN_INFO: {
    title: 'Dry Run Info (Copy)',
    body: 'Dry run info was not sent, please try again.'
  }
};

// Equipment
export const DATE_FORMAT = 'MM-DD-YYYY H:m:s';

// Request command
export const REQUEST_ERROR = {
  commandMissed: 'Proper command not found.',
  spiderNameMissed: 'Spider name not found.',
  userIdMissed: 'User Id not found'
};

// Dry Run Table

export const TABLE_VARIABLES = {
  id: 'id',
  status: 'status',
  reason: 'reason',
  title: 'title',
  publication_date: 'publication_date',
  created_at: 'created_at',
  updated_at: 'updated_at',
  pdf_url: 'pdf_url',
  web_url: 'web_url',
  category: 'category',
  meta_table: 'meta_table',
  api_table: 'api_table',
  pipeline_status: 'pipeline_status'
};

export const DEFAULT_TABLE_COLUMNS = [
  TABLE_VARIABLES.status,
  TABLE_VARIABLES.reason,
  TABLE_VARIABLES.id,
  TABLE_VARIABLES.title,
  TABLE_VARIABLES.publication_date,
  TABLE_VARIABLES.created_at,
  TABLE_VARIABLES.updated_at,
  TABLE_VARIABLES.pdf_url,
  TABLE_VARIABLES.web_url,
  TABLE_VARIABLES.category,
  TABLE_VARIABLES.meta_table,
  TABLE_VARIABLES.api_table,
  TABLE_VARIABLES.pipeline_status
];

export const COLUMNS_CONFIG = [
  { name: 'Pipeline Status', id: TABLE_VARIABLES.pipeline_status },
  { name: 'Reason', id: TABLE_VARIABLES.reason },
  { name: 'Doc ID', id: TABLE_VARIABLES.id },
  { name: 'Title', id: TABLE_VARIABLES.title },
  { name: 'Pub Date', id: TABLE_VARIABLES.publication_date },
  { name: 'Created Date', id: TABLE_VARIABLES.created_at },
  { name: 'Updated Date', id: TABLE_VARIABLES.updated_at },
  { name: 'PDF URL', id: TABLE_VARIABLES.pdf_url },
  { name: 'Web URL', id: TABLE_VARIABLES.web_url },
  { name: 'Category', id: TABLE_VARIABLES.category },
  { name: 'Meta Table', id: TABLE_VARIABLES.meta_table },
  { name: 'API Table', id: TABLE_VARIABLES.api_table },
  { name: 'Status', id: TABLE_VARIABLES.status }
];
export const LINK_INFO = {
  [LOG_TYPES.VIDEO_LOG]: {
    title: 'Video',
    summary_text: 'Debug video provides info how crawl collect info form source'
  },
  [LOG_TYPES.TEXT_LOG]: {
    title: 'Trace log',
    summary_text: 'This log provides comprehensive insights for dry run crawls reports'
  },
  [LOG_TYPES.TEXT_METRICS]: {
    title: 'Metrics',
    summary_text: 'This metrics provides comprehensive insights for dry run crawls reports'
  }
};
