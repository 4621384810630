import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MTypography from '../Typography/mTypography';

const useStyle = makeStyles({
  basePadding: {
    '& .jurispect-MuiInputBase-input': {
      paddingLeft: 10
    }
  }
});

const MTextField = ({
  className,
  classesLabel,
  classesRoot,
  error,
  id,
  InputProps,
  fullWidth,
  label,
  labelClass,
  labelId,
  placeholder,
  shrink,
  variant,
  wrapperClass,
  ...props
}) => {
  const classes = useStyle();
  return (
    <FormControl fullWidth={fullWidth} error={error} className={wrapperClass}>
      <MTypography id={labelId} shrink={shrink} className={labelClass}>
        {label}
      </MTypography>
      <TextField
        id={id}
        variant={variant}
        placeholder={placeholder}
        className={classNames(className, classes.basePadding)}
        InputLabelProps={{
          shrink
        }}
        InputProps={{ ...InputProps, disableUnderline: true }}
        {...props}
      />
    </FormControl>
  );
};

MTextField.propTypes = {
  className: PropTypes.string,
  classesLabel: PropTypes.string,
  classesRoot: PropTypes.string,
  error: PropTypes.bool,
  id: PropTypes.number,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  labelId: PropTypes.number,
  placeholder: PropTypes.string,
  shrink: PropTypes.string,
  variant: PropTypes.string,
  wrapperClass: PropTypes.string
};

MTextField.defaultProps = {
  className: '',
  classesLabel: '',
  classesRoot: '',
  error: false,
  id: null,
  label: '',
  labelClass: '',
  labelId: null,
  placeholder: '',
  shrink: 'false',
  variant: 'standard',
  wrapperClass: ''
};

export default MTextField;
