import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import { api_getAgencies } from 'shared/features/agencyData/agencyData.api';
import { api_getNewsSources } from 'shared/features/sources/newsSources/newsSources.api';
import Typography from "../../../../components/Typography";
import Spinner from "../../../../components/spinner";
import Accordion from "./Accordion";
import { SRS_CONST } from '../Glassdoor.constants';
import { formatSrc } from "../Glassdoor.helper";
import GlassdoorCategories from "./GlassdoorCategories";
import Info from "./GlassdoorTableComponents/Info";
import Header, { HeaderRow } from "./GlassdoorTableComponents/Header";
import Badge from "../../../../components/Badge";

const ERROR = 'Sources Upload Failed';
const NOT_FOUND = 'Sources for this jurisdiction not found';

const GlassdoorSources = ({
  openJur,
  jurID
}) => {
  const [load, setLoad] = useState(false);
  const [sources, setSources] = useState([]);
  const [timestamp, setTimestamp] = useState('time');
  const [error, setError] = useState('');

  const requestSources = async () => {
    await setLoad(true);
    await setError('');
    try {
      const responseAgencies = await api_getAgencies({
        glassdoor: true,
        get_all: true,
        jurisdiction_id: jurID
      });
      const responseNewsSources = await api_getNewsSources({
        glassdoor: true,
        get_all: true,
        jurisdiction_id: jurID
      });
      const itemsAgency = _.get(responseAgencies, 'items', []);
      const itemsNewsSource = _.get(responseNewsSources, 'items', []);
      if (itemsAgency || itemsNewsSource) {
        await setSources([...itemsAgency, ...itemsNewsSource]);
        await setTimestamp(Date().toString());
      }
    } catch {
      await setError(ERROR);
    } finally {
      await setLoad(false);
    }
  };

  useEffect(() => {
    if (openJur && jurID) {
      requestSources();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openJur, jurID]);

  const formatSources = formatSrc(sources, timestamp);

  return (
    <div className="glassdoor_table glassdoor_table_left-border">
      <Header
        className="glassdoor_table_header_source"
        leftBlockClass="source-header-left-block"
        leftChild={(
          <HeaderRow items={[
            'Publisher', 'Publisher Type', 'Status'
          ]}
          />
        )}
        // commented to speedup glassdoor (counter doesn't come from be)
        // rightChild={
        // <Typography bold variant="p" color="transparent_black"># of Docs</Typography>
        // }
      />
      {formatSources.map(item => (
        <GlassdoorSource
          key={`${[SRS_CONST.ID]}-${item[SRS_CONST.SHORT_NAME]}`}
          item={item}
          artifacts={{
            jurisdiction_id: jurID
          }}
        />
      ))}
      {load && <Info><Spinner /></Info>}
      {error && <Info><Typography bold variant="p" color="red">{error}</Typography></Info>}
      {!formatSources.length && !load && !error && (
        <Info><Typography bold variant="p" color="yellow">{NOT_FOUND}</Typography></Info>
      )}
    </div>
  );
};

GlassdoorSources.propTypes = {
  openJur: PropTypes.bool,
  jurID: PropTypes.number
};

GlassdoorSources.defaultProps = {
  openJur: false,
  jurID: null
};

const GlassdoorSource = ({
  item,
  artifacts
}) => {
  const [open, setOpen] = useState(false);

  const toggleAccordion = (id, isOpen) => {
    setOpen(!isOpen);
  };

  return (
    <Accordion
      key={item[SRS_CONST.ID]}
      open={open}
      headerClass="accordion-update accordion-update_source"
      leftInfo={(
        <div className="table-row table-row_source">
          <div className="table-cell"><Typography variant="p">{item[SRS_CONST.SHORT_NAME]}</Typography></div>
          <div className="table-cell"><Typography variant="p">{_.capitalize(item[SRS_CONST.TYPE])}</Typography></div>
          <div className="table-cell"><Typography variant="p">{<SourceBadge state={item[SRS_CONST.ISSUE]} />}</Typography></div>
        </div>
      )}
      // commented to speedup glassdoor (counter doesn't come from be)
      // rightInfo={(<Typography variant="p">{item[SRS_CONST.DOC_COUNT]}</Typography>)}
      onClick={toggleAccordion}
      id={item[SRS_CONST.ID]}
    >
      <GlassdoorCategories
        openPub={open}
        pubID={item[SRS_CONST.ID]}
        pubType={item[SRS_CONST.TYPE]}
        artifacts={artifacts}
      />
    </Accordion>
  );
};

GlassdoorSource.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }),
  artifacts: PropTypes.shape({
    jurisdiction_id: PropTypes.number
  })
};

GlassdoorSource.defaultProps = {
  item: {},
  artifacts: {}
};

export default GlassdoorSources;

const SourceBadge = ({ state }) => {
  return state
    ? <Badge type="error" size="small" className="source-badge">Issue</Badge>
    : <Badge type="success" size="small" className="source-badge">OK</Badge>;
};
