import React from 'react';
import PropTypes from "prop-types";
import SelectRender from '../../components/SelectRender';
import {
  mainCrawlSettings
} from './crawlCreation.constants';

const CrawlerCreationMainSettings = ({
  currentState,
  handleChange,
  error,
  disabled
}) => (
  mainCrawlSettings.map(item => (
    <div className="adminToolTextFieldsWrapper" key={item.id || item.name}>
      <SelectRender
        item={{ ...item, disabled }}
        state={currentState}
        handleChange={handleChange}
        style={["filterAdminToolWrap_wideElem"]}
        error={error}
      />
    </div>
  ))
);

CrawlerCreationMainSettings.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func
};

CrawlerCreationMainSettings.defaultProps = {
  error: false,
  disabled: false,
  handleChange: () => {
  }
};


export default CrawlerCreationMainSettings;
