import type { CommandRequestsTemplatesData } from './useCommandRequestsTemplatesData.types';
import type { CommandRequestsTemplatesHandlers } from './useCommandRequestsTemplatesHandlers.types';

export const useCommandRequestsTemplatesHandlers = ({
  localState,
  localActions
}: {
  localState: CommandRequestsTemplatesData['localState'];
  localActions: CommandRequestsTemplatesData['localActions'];
}): CommandRequestsTemplatesHandlers => {
  const handleSwitchSettings: CommandRequestsTemplatesHandlers['handleSwitchSettings'] = () => {
    localActions.setIsSettingOpen(!localState.isSettingOpen);
  };

  return {
    handleSwitchSettings
  };
};
