import {
  api_fetchAllCommands,
  api_createNewCommand,
  api_updateCurrentCommand,
  api_fetchAllCommandRequests,
  api_createCommandRequest,
  api_updateCommandRequest,
  api_createAutoQaCommandRequest
} from './commands.api';

export const REQUEST_COMMANDS = 'REQUEST_COMMANDS';
export const RECEIVE_COMMANDS = 'RECEIVE_COMMANDS';
export const CREATE_COMMAND = 'CREATE_COMMAND';
export const CREATED_COMMAND = 'CREATED_COMMAND';
export const CREATE_AUTO_QA_COMMAND_REQUEST = 'CREATE_AUTO_QA_COMMAND_REQUEST';
export const CREATED_AUTO_QA_COMMAND_REQUEST = 'CREATED_AUTO_QA_COMMAND_REQUEST';
export const UPDATE_COMMAND = 'UPDATE_COMMAND';
export const UPDATED_COMMAND = 'UPDATED_COMMAND';
export const REQUEST_COMMAND_REQUESTS = 'REQUEST_COMMAND_REQUESTS';
export const RECEIVE_COMMAND_REQUESTS = 'RECEIVE_COMMAND_REQUESTS';
export const UPLOAD_COMMAND_REQUESTS = 'UPLOAD_COMMAND_REQUESTS';
export const UPLOADED_COMMAND_REQUEST = 'UPLOADED_COMMAND_REQUEST';
export const UPDATE_COMMAND_REQUEST = 'UPDATE_COMMAND_REQUEST';
export const COMMAND_REQUEST_UPDATED = 'COMMAND_REQUEST_UPDATED';

function requestAllCommands() {
  return {
    type: REQUEST_COMMANDS
  };
}

function receiveAllCommands(json) {
  return {
    type: RECEIVE_COMMANDS,
    commands: json
  };
}

function createCommand() {
  return {
    type: CREATE_COMMAND
  };
}

function createdCommand(json) {
  return {
    type: CREATED_COMMAND,
    commands: json
  };
}

function createAutoQaCommandRequest() {
  return {
    type: CREATE_AUTO_QA_COMMAND_REQUEST
  };
}

function createdAutoQaCommandRequest(json) {
  return {
    type: CREATED_AUTO_QA_COMMAND_REQUEST,
    commands: json
  };
}

function updateCommand() {
  return {
    type: UPDATE_COMMAND
  };
}

function updatedCommand(json) {
  return {
    type: UPDATED_COMMAND,
    commands: json
  };
}

function requestAllCommandRequests() {
  return {
    type: REQUEST_COMMAND_REQUESTS
  };
}

function receiveAllCommandRequests(json) {
  return {
    type: RECEIVE_COMMAND_REQUESTS,
    command_requests: json
  };
}

function uploadCommandRequests() {
  return {
    type: UPLOAD_COMMAND_REQUESTS
  };
}

function uploadedCommandRequest(json) {
  return {
    type: UPLOADED_COMMAND_REQUEST,
    command_requests: json
  };
}

function updateCommandRequest() {
  return {
    type: UPDATE_COMMAND_REQUEST
  };
}

function commandRequestUpdated(json) {
  return {
    type: COMMAND_REQUEST_UPDATED,
    command_requests: json
  };
}

export function fetchAllCommands() {
  return function dofetch(dispatch) {
    dispatch(requestAllCommands());
    return api_fetchAllCommands().then(response => {
      dispatch(receiveAllCommands(response));
    });
  };
}

export function createNewCommand(data) {
  return async function dopost(dispatch) {
    dispatch(createCommand());
    return api_createNewCommand(data).then(response => {
      dispatch(createdCommand(response));
    });
  };
}

export function createNewAutoQaCommandRequest(data) {
  return async function dopost(dispatch) {
    dispatch(createAutoQaCommandRequest());
    return api_createAutoQaCommandRequest(data).then(response => {
      dispatch(createdAutoQaCommandRequest(response));
    });
  };
}

export function updateCurrentCommand(data, id) {
  return async function dopost(dispatch) {
    dispatch(updateCommand());
    return api_updateCurrentCommand(id, data).then(response => {
      dispatch(updatedCommand(response));
    });
  };
}

export function fetchAllCommandRequests(params) {
  return function dofetch(dispatch) {
    dispatch(requestAllCommandRequests());
    return api_fetchAllCommandRequests(params).then(response => {
      dispatch(receiveAllCommandRequests(response));
    });
  };
}

export function uploadNewCommandRequest(data) {
  return async function dopost(dispatch) {
    dispatch(uploadCommandRequests());
    return api_createCommandRequest(data).then(response => {
      dispatch(uploadedCommandRequest(response));
    });
  };
}

export function updateCommandRequestWithParams(command_request_id, data) {
  return async function dopost(dispatch) {
    dispatch(updateCommandRequest());
    return api_updateCommandRequest(command_request_id, data).then(response => {
      dispatch(commandRequestUpdated(response));
    });
  };
}
