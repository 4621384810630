import type { FC } from 'react';
import type { RelationsCellProps } from './RelationsCell.types';

import * as uiLib from '@compliance.ai/web-components';

import './_relations-cell.scss';

export const RelationsCell: FC<RelationsCellProps> = ({ cellValue }) => {
  if (!Array.isArray(cellValue)) return <>-</>;

  return (
    <>
      {cellValue.map(relation => (
        <uiLib.Tooltip
          key={relation.relatedDocId}
          title={relation?.relationName || relation?.relationType}
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          classNames={{
            elementWrapper: 'CAI-relations-cell__tooltip-element-wrapper'
          }}
        >
          <uiLib.Typography>Doc ID: {relation?.relatedDocId}</uiLib.Typography>
        </uiLib.Tooltip>
      ))}
    </>
  );
};
