import {
  api_fetchAllSpiderNames,
  api_fetchSpiderRunReport,
  api_fetchStatistics,
  api_fetchGoogleAnalyticsReports,
  api_fetchMarketingCampaigns,
  api_createMarketingCampaign,
  api_updateMarketingCampaign,
  api_fetchMarketingCampaign
} from './analytics.api';
import { followAgencies, fetchAgencies } from '../agencies/agencies.actions';
import {getUserAuthenticatedState} from "../auth/auth.selectors";

export const REQUEST_GOOGLE_ANALYTICS_REPORTS = 'REQUEST_GOOGLE_ANALYTICS_REPORTS';
export const RECEIVE_GOOGLE_ANALYTICS_REPORTS = 'RECEIVE_GOOGLE_ANALYTICS_REPORTS';
export const REQUEST_SPIDER_NAMES = 'REQUEST_SPIDER_NAMES';
export const RECEIVE_SPIDER_NAMES = 'RECEIVE_SPIDER_NAMES';
export const REQUEST_STATISTICS = 'REQUEST_STATISTICS';
export const RECEIVE_STATISTICS = 'RECEIVE_STATISTICS';
export const REQUEST_LAST_RUN_REPORT = 'REQUEST_LAST_RUN_REPORT';
export const RECEIVE_LAST_RUN_REPORT = 'RECEIVE_LAST_RUN_REPORT';
export const REQUEST_MARKETING_CAMPAIGNS = 'REQUEST_MARKETING_CAMPAIGNS';
export const RECEIVE_MARKETING_CAMPAIGNS = 'RECEIVE_MARKETING_CAMPAIGNS';
export const REQUEST_CAMPAIGN_DETAILS = 'REQUEST_CAMPAIGN_DETAILS';
export const RECEIVE_CAMPAIGN_DETAILS = 'RECEIVE_CAMPAIGN_DETAILS';
export const CREATE_MARKETING_CAMPAIGN = 'CREATE_MARKETING_CAMPAIGN';
export const CREATED_MARKETING_CAMPAIGN = 'CREATED_MARKETING_CAMPAIGN';
export const UPDATE_MARKETING_CAMPAIGN = 'UPDATE_MARKETING_CAMPAIGN';
export const UPDATED_MARKETING_CAMPAIGN = 'UPDATED_MARKETING_CAMPAIGN';

function requestStatistics() {
  return {
    type: REQUEST_STATISTICS
  };
}

function receiveStatistics(json) {
  return {
    type: RECEIVE_STATISTICS,
    statistics: json
  };
}

function requestAllSpiderNames() {
  return {
    type: REQUEST_SPIDER_NAMES
  };
}

function receiveAllSpiderNames(json) {
  return {
    type: RECEIVE_SPIDER_NAMES,
    spidernames: json
  };
}

function requestLastSpiderRunReportBySpiderName() {
  return {
    type: REQUEST_LAST_RUN_REPORT
  };
}

function receiveLastSpiderRunReportBySpiderName(json) {
  return {
    type: RECEIVE_LAST_RUN_REPORT,
    reunreport: json
  };
}

function requestGoogleAnalyticsReports() {
  return {
    type: REQUEST_GOOGLE_ANALYTICS_REPORTS
  };
}

function receiveGoogleAnalyticsReports(json) {
  return {
    type: RECEIVE_GOOGLE_ANALYTICS_REPORTS,
    reports: json
  };
}

function requestMarketingCampaigns() {
  return {
    type: REQUEST_MARKETING_CAMPAIGNS
  };
}

function recieveMarketingCampaigns(data) {
  return {
    type: RECEIVE_MARKETING_CAMPAIGNS,
    campaigns: data.marketing_campaigns
  };
}

function createMarketingCampaign() {
  return {
    type: CREATE_MARKETING_CAMPAIGN
  };
}

function marketingCampaignCreated() {
  return {
    type: CREATED_MARKETING_CAMPAIGN
  };
}

function updateMarketingCampaign() {
  return {
    type: UPDATE_MARKETING_CAMPAIGN
  };
}

function marketingCampaignUpdated() {
  return {
    type: UPDATED_MARKETING_CAMPAIGN
  };
}

function requestCampaignDetails() {
  return {
    type: REQUEST_CAMPAIGN_DETAILS
  };
}

function recieveCampaignDetails(data) {
  return {
    type: RECEIVE_CAMPAIGN_DETAILS,
    details: data.marketing_campaign
  };
}

export function fetchAllSpiderNames() {
  return function dofetch(dispatch) {
    dispatch(requestAllSpiderNames());
    return api_fetchAllSpiderNames()
      .then(response => {
        dispatch(receiveAllSpiderNames(response));
      });
  };
}

export function fetchSpiderRunReport(spider_name) {
  return function dofetch(dispatch) {
    dispatch(requestLastSpiderRunReportBySpiderName());
    return api_fetchSpiderRunReport(spider_name)
      .then(response => {
        dispatch(receiveLastSpiderRunReportBySpiderName(response));
      })
      .catch(error => {
        dispatch(receiveLastSpiderRunReportBySpiderName({}));
      });
  };
}

export function fetchStatistics(data, useAPIToken) {
  return async function dofetch(dispatch, getState) {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated) {
      const followed_agencies = getState().agencies.followed_agencies;
      if (followed_agencies.length <= 0) {
        const agencies = await dispatch(fetchAgencies(false, false, true));
        dispatch(followAgencies(agencies));
      }
      data.agency_id = getState()
        .agencies.followed_agencies.filter(agency => agency.type !== 'state')
        .map(agency => agency.id);
    }
    dispatch(requestStatistics());
    return api_fetchStatistics(data, useAPIToken)
      .then(response => {
        dispatch(receiveStatistics(response));
        return response;
      });
  };
}

export function fetchGoogleAnalyticsReports(eventActions) {
  return function dofetch(dispatch) {
    dispatch(requestGoogleAnalyticsReports());
    const promises = eventActions.map(eventAction => api_fetchGoogleAnalyticsReports({
      event_action: eventAction
    }));

    return Promise.all(promises)
      .then(response => {
        dispatch(receiveGoogleAnalyticsReports(response));
      });
  };
}

export function fetchMarketingCampaigns() {
  return function dofetch(dispatch) {
    dispatch(requestMarketingCampaigns());
    return api_fetchMarketingCampaigns()
      .then(response => {
        dispatch(recieveMarketingCampaigns(response));
      });
  };
}

export function createCampaign(params) {
  return function dopost(dispatch) {
    dispatch(createMarketingCampaign());
    return api_createMarketingCampaign(params)
      .then(() => {
        dispatch(marketingCampaignCreated());
      });
  };
}

export function updateCampaign(id, params) {
  return function dopost(dispatch) {
    dispatch(updateMarketingCampaign());
    return api_updateMarketingCampaign(id, params)
      .then(response => {
        dispatch(marketingCampaignUpdated());
      });
  };
}

export function fetchCampaignDetails(id) {
  return function dofetch(dispatch) {
    dispatch(requestCampaignDetails());
    return api_fetchMarketingCampaign(id)
      .then(response => {
        dispatch(recieveCampaignDetails(response));
      });
  };
}
