import type { ExportDropdownProps } from '../ExportDropdown.types';
import type { ExportDropdownData } from './useExportDropdownData.types';

import { useMemo, useState } from 'react';

export const useExportDropdownData = ({
  props
}: {
  props: Pick<ExportDropdownProps, 'selectedDocumentIds' | 'isDownloading'>;
}): ExportDropdownData => {
  const [isOpen, setIsOpen] = useState(false);

  const formattedData = useMemo(() => {
    const isDisabled = !(props.selectedDocumentIds.length > 0 && !props.isDownloading);

    return {
      isDisabled
    };
  }, [props]);

  const localState = {
    isOpen
  };

  const localActions = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
