import type { FC } from 'react';
import type { FlagModalProps } from './FlagModal.types';
import type { SelectOption } from '@compliance.ai/web-components';

import * as uiLib from '@compliance.ai/web-components';
import { documentFlagOptions } from '../../../DocumentPage/DocumentDetails/elements/DocumentFlagModal/DocumentFlagModal.constants';
import { useFlagModalHandlers } from './hooks/useFlagModalHandlers';
import { useFlagModalData } from './hooks';

export const FlagModal: FC<FlagModalProps> = ({
  selectedDocumentIds,
  statusToSet,
  onSubmit,
  onClose,
  isOpen,
  updateDocFlag
}) => {
  const { localState, localActions, formattedData, reduxState } = useFlagModalData();
  const handlers = useFlagModalHandlers({
    reduxState,
    localState,
    localActions,
    props: {
      onClose,
      onSubmit,
      updateDocFlag,
      selectedDocumentIds,
      statusToSet
    }
  });
  return (
    <uiLib.Modal
      className="documentDetails-Modal"
      title={`Take ${documentFlagOptions[statusToSet].label} action on ${selectedDocumentIds.length} documents`}
      isOpen={isOpen}
      onClose={handlers.handleClose}
      withFooter
      secondaryButtonText="Cancel"
      primaryButtonText="Submit"
      isPrimaryButtonDisabled={localState.isLoading}
      onSubmit={handlers.handleSubmit}
      classNames={{
        title: 'documentDetails-Modal__title',
        content: 'documentDetails-Modal__content'
      }}
    >
      <>
        {localState.isLoading && <uiLib.Loader withoutBackground={false} />}
        <div className="bodyWrapper">
          <uiLib.LabeledField label="Issue Severity">
            <uiLib.Select
              value={documentFlagOptions[statusToSet] as SelectOption}
              options={[documentFlagOptions[statusToSet] as SelectOption]}
              isDisabled
            />
          </uiLib.LabeledField>
          <uiLib.LabeledField label="Issue Type">
            <uiLib.Select
              value={localState.issueType}
              options={formattedData.issueTypeOptions}
              onChange={value => localActions.setIssueType(value)}
              isDisabled={localState.isLoading}
              isClearable={false}
            />
          </uiLib.LabeledField>
          <uiLib.TextField
            label="Related documents"
            value={localState.relatedDocuments}
            type="text"
            placeholder="Enter related documents"
            isDisabled={localState.isLoading}
            onChange={value => {
              if (!!value.trim()) localActions.setError(false);
              localActions.setRelatedDocuments(value);
            }}
          />
          {localState.error && (
            <uiLib.Typography
              type={uiLib.TYPOGRAPHY_TYPES.SUBTITLE1}
              status={uiLib.TYPOGRAPHY_STATUS.ERROR}
            >{`Field "Related documents" is required`}</uiLib.Typography>
          )}
          <uiLib.TextField
            label="Field"
            onChange={localActions.setField}
            value={localState.field}
            type="text"
            placeholder="Enter field"
            isDisabled={localState.isLoading}
          />
          <uiLib.TextArea
            label="Notes"
            value={localState.notes}
            maxRows={3}
            minRows={2}
            placeholder="Enter notes"
            isDisabled={localState.isLoading}
            onChange={value => localActions.setNotes(value)}
          />
        </div>
      </>
    </uiLib.Modal>
  );
};
