import type * as apiTypes from './documentData.apiV2.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchDocumentsData: apiTypes.FetchDocumentsData = async params => {
  return fetch({
    url: '/document-data',
    dataType: 'Document',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const updateDocumentData: apiTypes.UpdateDocumentsData = async params => {
  return fetch({
    url: '/document-data',
    dataType: 'Document',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const updateDocumentDataBulk: apiTypes.UpdateDocumentsDataBulk = async params => {
  return fetch({
    url: '/document-data-bulk',
    dataType: 'Document',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
