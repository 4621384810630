import type { AuthCheckHandlers } from './useAuthCheckHandlers.types';

import { useEffect } from 'react';

export const useAuthCheckLifecycle = ({
  onDataCheck
}: {
  onDataCheck: AuthCheckHandlers['handleDataCheck'];
}) => {
  useEffect(() => {
    onDataCheck();
  }, [onDataCheck]);
};
