import { fetch, BASE_API_OPTIONS } from "fetch";

export const api_getCrawlerHistory = (crawlId) => {
  return fetch({
    url: `/history_crawler/${crawlId}`,
    dataType: 'Crawler',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
};
