import type { CategoryHandlers } from './useCaiCategoriesHandlers.types';
import type { CategoryData } from './useCaiCategoriesData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as caiCategoryDataApi from 'shared/features/categories/caiCategories/caiCategories.apiV2';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useCaiCategoriesHandlers.helpers';
import { TableCategory } from '../CaiCategories.types';

export const useCaiCategoriesHandlers = ({
  localState,
  localActions
}: {
  localState: CategoryData['localState'];
  localActions: CategoryData['localActions'];
}): CategoryHandlers => {
  const handleCategoriesFetch: CategoryHandlers['handleCategoriesFetch'] = async (
    params,
    options
  ) => {
    try {
      const categoriesResponse = await caiCategoryDataApi.fetchCaiCategoriesData({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      localActions.setTableCategories(categoriesResponse.items);
      return {
        results: categoriesResponse.items,
        count: categoriesResponse.count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleSetSelectedCategory: CategoryHandlers['handleSetSelectedCategory'] = rowKeys => {
    localActions.setSelectedCategories(rowKeys.map(Number));
  };

  const handleFiltersSubmit: CategoryHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleEditCategoryModalOpen: CategoryHandlers['handleEditCategoryModalOpen'] =
    async category => {
      localActions.setIsEditModalOpen(true);
      localActions.setCategoryToEdit(category[0] as TableCategory);
    };

  const handleEditCategoryModalClose: CategoryHandlers['handleEditCategoryModalClose'] = () => {
    localActions.setIsEditModalOpen(false);
  };

  const handleEditCategoryModalConfirm: CategoryHandlers['handleEditCategoryModalConfirm'] =
    async () => {
      localActions.setIsEditModalOpen(false);
      localActions.setReloadTrigger(!localState.reloadTrigger);
    };

  const handleSplitCategoryModalOpen: CategoryHandlers['handleSplitCategoryModalOpen'] =
    async categoriesIds => {
      localActions.setIsSplitModalOpen(true);
    };

  const handleSplitCategoryModalClose: CategoryHandlers['handleSplitCategoryModalClose'] = () => {
    localActions.setIsSplitModalOpen(false);
  };

  const handleSplitCategoryModalConfirm: CategoryHandlers['handleSplitCategoryModalConfirm'] =
    async () => {
      localActions.setIsSplitModalOpen(false);
      localActions.setReloadTrigger(!localState.reloadTrigger);
    };

  const handleMergeCategoryModalOpen: CategoryHandlers['handleMergeCategoryModalOpen'] =
    async categoriesIds => {
      localActions.setIsMergeModalOpen(true);
    };

  const handleMergeCategoryModalClose: CategoryHandlers['handleMergeCategoryModalClose'] = () => {
    localActions.setIsMergeModalOpen(false);
  };

  const handleMergeCategoryModalConfirm: CategoryHandlers['handleMergeCategoryModalConfirm'] =
    async () => {
      localActions.setIsMergeModalOpen(false);
      localActions.setReloadTrigger(!localState.reloadTrigger);
    };

  return {
    handleCategoriesFetch,
    handleFiltersSubmit,
    handleSplitCategoryModalClose,
    handleSplitCategoryModalOpen,
    handleSplitCategoryModalConfirm,
    handleEditCategoryModalClose,
    handleEditCategoryModalOpen,
    handleEditCategoryModalConfirm,
    handleSetSelectedCategory,
    handleMergeCategoryModalClose,
    handleMergeCategoryModalOpen,
    handleMergeCategoryModalConfirm
  };
};
