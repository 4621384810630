import naturalSort from "javascript-natural-sort";
import { romanNumeralToInt, isStringRomanNumeral } from 'app/utils/string';

export const naturalSortWithRomanNumerals = (a, b) => {
  const docsToSort = [a, b].map(docToSort => {
    const title = docToSort.title;
    try {
      const parts = title.split(' ');
      const chapterPart = parts[0];
      const romanNumeralParts = parts[1].split('-');
      if (chapterPart.toLowerCase() === 'chapter' && isStringRomanNumeral(romanNumeralParts[0])) {
        romanNumeralParts[0] = romanNumeralToInt(romanNumeralParts[0]);
        parts[1] = romanNumeralParts.join('-');
      }
      return parts.join(' ');
    } catch (Exception) {
      // Do nothing and leave the title to sort as is if we cannot sort by roman numerals
      return title;
    }
  });
  return naturalSort(docsToSort[0], docsToSort[1]);
};
