import type { CategoryData } from './useScrapedCategoriesData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo, useState } from 'react';

export const useScrapedCategoriesData = (): CategoryData => {
  const [filters, setFilters] = useState<CategoryData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<CategoryData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: 50
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<CategoryData['localState']['reloadTrigger']>(false);

  const localState: CategoryData['localState'] = {
    filters,
    tableParams,
    reloadTrigger
  };

  const localActions: CategoryData['localActions'] = useMemo(
    () => ({ setFilters, setTableParams, setReloadTrigger }),
    []
  );

  return {
    localState,
    localActions
  };
};
