import React from 'react';
import Badge from '../../../../components/Badge';
import { getCrawlStatusBadgeInfo } from '../../CrawlMonitorTool.helper';
import * as uiLib from '@compliance.ai/web-components';

export const CrawlStatus: Required<uiLib.Column>['render'] = (value, crawl) => {
  const badgeInfo = getCrawlStatusBadgeInfo(crawl);
  return (
    <Badge type={badgeInfo.type} size="medium" title={badgeInfo.text}>
      {badgeInfo.text}
    </Badge>
  );
};
