export enum CAI_CATEGORIES_DATA_FETCH_QUERY_PARAMS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  ID = 'id',
  NAME = 'name',
  FROM_ID = 'from_id',
  TO_ID = 'to_id',
  ACTIVE_ID = 'active_id',
  DOC_META_CATEGORY_ID = 'doc_meta_category_id',
  IS_ARCHIVED = 'is_archived',
  SURFACE_IN_FILTER = 'surface_in_filter',
  IS_SYNCED = 'is_synced',
  NEWS_SOURCE_ID = 'news_source_id',
  AGENCY_ID = 'agency_id',
  JURISDICTION_ID = 'jurisdiction_id',
  GLASSDOOR = 'glassdoor',
  GET_ALL = 'get_all',
  GET_ACTIVE = 'get_active',
  MULTI_INPUT = 'multi_input',
  AGENCIES = 'agencies',
  JURISDICTION = 'jurisdiction',
  SCRAPED_CATEGORIES = 'scraped_categories',
  CAI_CATEGORIES = 'cai_categories',
  META_CATEGORY = 'meta_category',
  CREATED_AT_START = 'created_at_start',
  CREATED_AT_END = 'created_at_end',
  UPDATED_AT_START = 'updated_at_start',
  UPDATED_AT_END = 'updated_at_end'
}
