import type { JurisdictionFromResponse } from 'shared/features/jurisdictionsData/jurisdictionsData.api.types';
import type { AgencyFromResponse } from 'shared/features/agencies/agencies.api.types';
import type { DocumentScrapedCategoryFromResponse } from 'shared/features/documentData/documentData.types';
import type { ExternalApiCrawlFromResponse } from 'shared/features/externalApiCrawl/externalApiCrawl.api.types';
import type { EitlLabelFromResponse } from 'shared/features/eitlLabels/eitlLabels.api.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './useDocumentFiltersAsyncLoaders.constants';
import * as helpers from './useDocumentFiltersAsyncLoaders.helpers';
import * as documentConstants from 'admin/constants/document';
import * as documentDataApi from 'shared/features/documentData/documentData.api';
import * as errorUtils from 'shared/utils/errors';
import * as jurisdictionsApi from 'shared/features/jurisdictionsData/jurisdictionsData.api';
import * as agenciesApi from 'shared/features/agencyData/agencyData.api';
import * as eitlLabelsApi from 'shared/features/eitlLabels/eitlLabels.api';
import * as metaCategoriesApi from 'shared/features/categories/metaCategories/metaCategories.api';
import * as externalApiCrawlApi from 'shared/features/externalApiCrawl/externalApiCrawl.api';
import * as externalApiCrawlConstants from 'shared/features/externalApiCrawl/externalApiCrawl.constants';

export const useDocumentFiltersAsyncLoaders = () => {
  const handleDocumentStatusesFetch = async (inputString: string) => {
    try {
      const response = await documentDataApi.api_getDocumentsStatuses({
        pipeline_status: inputString
      });

      return response.items.map((status: string) => ({
        value: status,
        label: status
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleDocumentInteractionStatusesFetch = async (inputString: string) => {
    try {
      const response = await documentDataApi.api_getDocumentsInteractionStatuses({
        interaction_status: inputString
      });

      return response.items.map((status: string) => ({
        value: status,
        label: status
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleJurisdictionsFetch = async (inputString: string) => {
    try {
      const response = await jurisdictionsApi.api_getJurisdictions({ short_name: inputString });

      return response.items.map((jurisdiction: JurisdictionFromResponse) => ({
        value: jurisdiction.short_name,
        label: jurisdiction.short_name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleAgenciesFetch = async (inputString: string) => {
    try {
      const response = await agenciesApi.api_getAgencies({ short_name: inputString });

      return response.items.map((agency: AgencyFromResponse) => ({
        value: agency.id,
        label: agency.short_name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleScrapedCategoryFetch = async (inputString: string) => {
    try {
      const response = await documentDataApi.api_getDocumentsScrapedCategory({ name: inputString });

      return response.items.map((scrapedCategory: DocumentScrapedCategoryFromResponse) => ({
        value: scrapedCategory.id,
        label: scrapedCategory.name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleDocTypesFetch = async (inputString: string) => {
    try {
      const response = await metaCategoriesApi.api_getMetaCategories({
        name: inputString,
        ...constants.DEFAULT_FILTERS
      });

      return helpers.createDocTypeDropdownOptions(response) as uiLib.SelectOptions;
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleSpiderNamesFetch = async (inputString: string) => {
    try {
      const response = await externalApiCrawlApi.api_getExternalApiCrawls({
        spider_name: inputString,
        limit: externalApiCrawlConstants.SPIDER_NAMES_COUNT_PER_REQUEST,
        light_version: true
      });

      return response.items.map((item: ExternalApiCrawlFromResponse) => ({
        label: item.spider_name,
        value: item.spider_name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  const handleEitlLabelsFetch = async (inputString: string) => {
    try {
      const response = await eitlLabelsApi.getEitlLabels({ name: inputString });

      return response.items.map((eitlLabel: EitlLabelFromResponse) => ({
        value: eitlLabel.id,
        label: eitlLabel.name
      }));
    } catch (e) {
      errorUtils.logError(e);
      return [];
    }
  };

  return {
    [documentConstants.DOCUMENT_ATTRIBUTES.PIPELINE_STATUS]: handleDocumentStatusesFetch,
    [documentConstants.DOCUMENT_ATTRIBUTES.INTERACTION_STATUS]:
      handleDocumentInteractionStatusesFetch,
    [documentConstants.DOCUMENT_ATTRIBUTES.JURISDICTION]: handleJurisdictionsFetch,
    [documentConstants.DOCUMENT_ATTRIBUTES.AGENCY_ID]: handleAgenciesFetch,
    [documentConstants.DOCUMENT_ATTRIBUTES.DOC_SCRAPPED_CATEGORY]: handleScrapedCategoryFetch,
    [documentConstants.DOCUMENT_ATTRIBUTES.CAI_CATEGORY_IDS]: handleDocTypesFetch,
    [documentConstants.DOCUMENT_ATTRIBUTES.SPIDER_NAME]: handleSpiderNamesFetch,
    [documentConstants.DOCUMENT_ATTRIBUTES.EITL_LABELS]: handleEitlLabelsFetch
  };
};
