import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { uploadNewCommandRequest } from 'shared/features/commands/commands.actions';
import { setNotification, NOTIFICATION_TYPES } from '../../../components/Notification';
import { createDeprecateDocsCommandRequest, createDocumentSeedingCommandRequest } from './DocumentsIngested.helper';
import Button from '../../../components/Button';
import DocumentsIngestedModal from './DocumentsIngestedModal';
import DocumentsImportExportModal from './DocumentsImportExportModal';

const DocumentsIngested = () => {
  const dispatch = useDispatch();
  const current_user = useSelector(state => state.current_user.user);
  const commands = _.get(useSelector(state => state.commands), 'items', []);
  const crawlDetailsInfo = _.get(useSelector(state => state.externalApiCrawl), ['items', 0], {});

  const [openDeprecateModal, setOpenDeprecateModal] = useState(false);
  const [openImportExportModal, setOpenImportExportModal] = useState(false);

  const deprecateCrawlDocuments = (parentIds) => {
    const request = createDeprecateDocsCommandRequest(
      { crawlDetailsInfo, commands, current_user, parentIds });
    if (request.error) {
      setNotification({
        message: { text: request.error, title: 'Command Request Not Created' },
        type: NOTIFICATION_TYPES.ERROR
      });
      return;
    }
    dispatch(uploadNewCommandRequest(request));
  };

  const importExportCrawlDocuments = (exportImportDetailsInfo) => {
    const request = createDocumentSeedingCommandRequest(
      { crawlDetailsInfo, exportImportDetailsInfo, commands, current_user }
    );
    if (request.error) {
      setNotification({
        message: { text: request.error, title: 'Command Request Not Created' },
        type: NOTIFICATION_TYPES.ERROR
      });
      return;
    }
    dispatch(uploadNewCommandRequest(request));
  };

  return (
    <>
      <div className="crawlDocumentsIngested">
        <Button
          className="crawlDocumentsIngested_deprecateBtn"
          onClick={() => setOpenDeprecateModal(true)}
        >
          Deprecate Documents
        </Button>
        <Button
          className="crawlDocumentsIngested_impotexportBtn"
          onClick={() => setOpenImportExportModal(true)}
        >
          Import/Export Documents
        </Button>
      </div>
      <DocumentsIngestedModal
        show={openDeprecateModal}
        onHide={() => setOpenDeprecateModal(false)}
        modalAction={deprecateCrawlDocuments}
        spiderName={crawlDetailsInfo.spider_name}
      />
      <DocumentsImportExportModal
        show={openImportExportModal}
        onHide={() => setOpenImportExportModal(false)}
        modalAction={importExportCrawlDocuments}
        spiderName={crawlDetailsInfo.spider_name}
      />
    </>
  );
};

export default DocumentsIngested;
