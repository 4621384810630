import type { EventsTableHandlers } from './useEventsTableHandlers.types';
import type { EventsTableProps } from '../EventsTable.types';

import { useCallback } from 'react';

import * as documentEventsApi from 'shared/features/documentEvents/documentEvents.api';
import * as errorUtils from 'shared/utils/errors';

export const useEventsTableHandlers = ({
  props
}: {
  props: Pick<EventsTableProps, 'documentId'>;
}): EventsTableHandlers => {
  const handleDataFetch: EventsTableHandlers['handleDataFetch'] = useCallback(
    async params => {
      try {
        const response = await documentEventsApi.fetchDocumentEvents({
          ...params,
          doc_id: props.documentId
        });

        return {
          results: response.items,
          count: response.count
        };
      } catch (e) {
        errorUtils.logError(e);

        return {
          results: [],
          count: 0
        };
      }
    },
    [props.documentId]
  );

  return {
    handleDataFetch
  };
};
