import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { safe_ga, safe_mixpanel_track } from "shared/utils/analytics";

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    safe_ga('send', {
      hitType: 'pageview',
      page: location.pathname,
      location: window.location.href
    });
    safe_mixpanel_track('Pageview: ' + location.pathname, {
      hitType: 'pageview',
      page: location.pathname
    });
  }, [location.pathname]);
};
