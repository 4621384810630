import type { AuditEntriesCellValue } from './AuditEntriesCell.types';

import * as uiLib from '@compliance.ai/web-components';
import * as components from 'admin/components';

import { LogsModal } from '../../LogsModal';

import './_audit-entries-cell.scss';

export const AuditEntriesCell: Required<uiLib.Column>['render'] = (value, row) => {
  const documentId = (value as AuditEntriesCellValue)?.documentId;

  return (
    <uiLib.ErrorBoundary>
      <LogsModal title={`Doc ID ${documentId}: Audit entries`}>
        <components.AuditEntriesTable
          documentId={documentId}
          classNames={{
            container: 'audit-entries-cell__table-outer-container',
            tableContainer: 'audit-entries-cell__table-inner-container'
          }}
        />
      </LogsModal>
    </uiLib.ErrorBoundary>
  );
};
