import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../../components/Modal";
import Typography from "../../components/Typography";
import Button from "../../components/Button";

const ConfirmationModal = ({
  show,
  mode,
  onHide,
  modalAction,
  onCancel
}) => {
  return (
    <Modal
      className="confirmationModal"
      title={mode.title}
      show={show}
      onHide={() => {
        onCancel();
        onHide();
      }}
      size="small"
    >
      <div className="confirmationModal_body">
        <Typography variant="p" className="confirmationModalText">
          {mode.body}
        </Typography>
        <div className="buttonBlock">
          <Button
            onClick={() => {
              onCancel();
              onHide();
            }}
            color="highlighted"
            size="medium"
          >
            Cancel
          </Button>
          <Button onClick={modalAction} color="blue" size="medium">{mode.title}</Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  mode: PropTypes.shape({}),
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  modalAction: PropTypes.func.isRequired
};

ConfirmationModal.defaultProps = {
  mode: {},
  onCancel: () => {
  }
};

export default ConfirmationModal;
