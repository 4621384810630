import type { EitlLabelsHandlers } from './useEitlLabelsHandlers.types';
import type { EitlLabelsTableConfig } from './useEitlLabelsTableConfig.types';
import type { TableEitlLabel } from '../EitlLabels.types';

import React from 'react';

import * as elements from '../elements';
import * as constants from '../EitlLabels.constants';
import * as uiLib from '@compliance.ai/web-components';

export const useEitlLabelsTableConfig = ({
  onDeleteLabelsModalOpen,
  onRenameLabelModalOpen
}: {
  onDeleteLabelsModalOpen: EitlLabelsHandlers['handleDeleteLabelsModalOpen'];
  onRenameLabelModalOpen: EitlLabelsHandlers['handleRenameLabelModalOpen'];
}): EitlLabelsTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'ID',
      dataKey: constants.TABLE_FIELDS.ID,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Name',
      dataKey: constants.TABLE_FIELDS.NAME,
      width: uiLib.TABLE_COLUMN_WIDTH.L
    },
    {
      title: '# of documents',
      dataKey: 'documentCount',
      defaultSort: false,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: '# of crawls',
      dataKey: 'crawlsCount',
      defaultSort: false,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Created by',
      dataKey: constants.TABLE_FIELDS.CREATED_BY,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Date created',
      dataKey: constants.TABLE_FIELDS.CREATED_AT,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    }
  ];

  const actions: (uiLib.RowAction | uiLib.ColumnAction)[] = [
    {
      title: '',
      render: (label: uiLib.Row) => {
        const tableLabel = label as TableEitlLabel;

        return (
          <elements.EitlLabelActionsDropdown
            onRenameModalOpen={onRenameLabelModalOpen(tableLabel)}
            onDeleteModalOpen={onDeleteLabelsModalOpen(tableLabel)}
            shouldShowDeleteOption
          />
        );
      },
      disableColumn: true
    }
  ];

  return {
    columns,
    actions
  };
};
