import type { BulkEditModalData, BulkEditModalRule } from './useBulkEditModalData.types';
import type { BulkEditModalHandlers } from './useBulkEditModalHandlers.types';

import * as helpers from './useBulkEditModalHandlers.helpers';
import * as uiLib from '@compliance.ai/web-components';

export const useBulkEditModalHandlers = ({
  localState,
  localActions
}: {
  localState: BulkEditModalData['localState'];
  localActions: BulkEditModalData['localActions'];
}): BulkEditModalHandlers => {
  const handleRuleAdd: BulkEditModalHandlers['handleRuleAdd'] = () => {
    const newRuleId = helpers.getNewRuleId(localState.rules);

    localActions.setRules([...localState.rules, helpers.getNewEmptyRule(newRuleId)]);
  };

  const handleRuleRemove: BulkEditModalHandlers['handleRuleRemove'] = id => () => {
    const updatedRules = localState.rules.filter(rule => rule.id !== id);

    localActions.setRules(updatedRules);
  };

  const handleRuleActionChange: BulkEditModalHandlers['handleRuleActionChange'] =
    id => selectValue => {
      const option = selectValue as uiLib.SelectOption;
      const ruleAction = option?.value ?? null;

      localActions.setRules(rules =>
        rules.map(rule => {
          if (rule.id === id) {
            return {
              ...rule,
              action: ruleAction as BulkEditModalRule['action'],
              attribute: null,
              value: null
            };
          }

          return rule;
        })
      );
    };

  const handleRuleAttributeChange: BulkEditModalHandlers['handleRuleAttributeChange'] =
    id => selectValue => {
      const option = selectValue as uiLib.SelectOption;
      const ruleAttribute = option?.value ?? null;

      localActions.setRules(rules =>
        rules.map(rule => {
          if (rule.id === id) {
            return {
              ...rule,
              attribute: ruleAttribute as BulkEditModalRule['attribute'],
              value: null
            };
          }

          return rule;
        })
      );
    };

  const handleRuleValueChange: BulkEditModalHandlers['handleRuleValueChange'] = id => value => {
    const ruleValue = value as BulkEditModalRule['value'];

    localActions.setRules(rules =>
      rules.map(rule => {
        if (rule.id === id) {
          return { ...rule, value: ruleValue };
        }

        return rule;
      })
    );
  };

  return {
    handleRuleAdd,
    handleRuleRemove,
    handleRuleActionChange,
    handleRuleAttributeChange,
    handleRuleValueChange
  };
};
