import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useHistory } from 'admin/utils/hooks';
import Typography from './Typography';

// Tabs components require label in props

function Tab({
  className,
  label,
  onClick,
  activeTab,
  link
}) {
  return (
    <li
      className={cn(
        "custom-tab-list-item",
        {
          "custom-tab-list-item_active": activeTab
        },
        className
      )}
      onClick={() => onClick(label, link)}
    >
      <Typography variant="title">
        {label}
      </Typography>
    </li>
  );
}

Tab.propTypes = {
  activeTab: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

Tab.defaultProps = {
  className: ''
};

function Tabs({
  children,
  className,
  headClassName,
  tabClassName,
  extraInfo,
  defaultChild
}) {
  const history = useHistory();
  children = Array.isArray(children) ? children : [children];
  const [activeTab, setActiveTab] = useState(children[defaultChild || 0].props.label);

  const onChangeTab = (label, link) => {
    setActiveTab(label);

    if (link) {
      history.push(`${link}`);
    }
  };

  // FIXME NO DEPENDENCIES!!! Fix this
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (children[defaultChild || 0].props.link) {
      setActiveTab(children[defaultChild || 0].props.label);
    }
  });

  return (
    <div
      className={cn(
        "custom-tabs",
        className
      )}
    >
      <div
        className={cn(
          "custom-tabs_list-wrapper",
          headClassName
        )}
      >
        <ol className="custom-tabs_list">
          {children.map(({ props: { label, link } }) => (
            <Tab
              activeTab={label === activeTab}
              key={String(label)}
              label={label}
              onClick={onChangeTab}
              className={tabClassName}
              link={link}
            />
          ))}
        </ol>
        <div>{extraInfo}</div>
      </div>
      <div className="custom-tabs_content-wrapper">
        {children.map((child) => (
          child.props.label === activeTab
            ? child
            : null
        ))}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object)
  ]).isRequired,
  extraInfo: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object)
  ]),
  className: PropTypes.string,
  tabClassName: PropTypes.string,
  headClassName: PropTypes.string,
  defaultChild: PropTypes.number
};

Tabs.defaultProps = {
  className: '',
  tabClassName: '',
  headClassName: '',
  extraInfo: null,
  defaultChild: 0
};

export default Tabs;
