import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../components/Button";

const DocumentMetaCategoriesButtons = ({
  addMetaCategory
}) => {
  return (
    <div>
      <Button onClick={addMetaCategory}>Add</Button>
    </div>
  );
};

DocumentMetaCategoriesButtons.propTypes = {
  addMetaCategory: PropTypes.func.isRequired
};

export default DocumentMetaCategoriesButtons;
