import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
  Modal,
  Button,
  FormControl,
  ControlLabel,
  FormGroup,
  Radio
} from 'react-bootstrap';
import { getConcepts } from "../../../shared/features/jurisdictionAnalyzerConcepts/jurisdictionAnalyzerConcept.selector";
import { addConcept, updateConcept, deleteConcept } from "../../../shared/features/jurisdictionAnalyzerConcepts/jurisdictionAnalyzerConcept.actions";

const JurisdictionAnalyzerConceptsModal = props => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const concept = props.concept;
    setName(concept.name || '');
    setDescription(concept.description || '');
    setActive(concept.active || false);
    setError(false);
  }, [props.concept]);

  const checkInput = () => {
    if (!name) {
      setError(true);
      return false;
    }
    return {
      name,
      description,
      active
    };
  };

  const saveChangesConcept = () => {
    const collectConcept = checkInput();
    const conceptId = props.concept.id;
    if (!collectConcept) {
      return;
    }
    if (conceptId) {
      props.updateConcept(conceptId, collectConcept);
    } else {
      props.addConcept(collectConcept);
    }
    props.closeModal();
  };

  const removeConcept = () => {
    props.deleteConcept(props.concept.id);
    props.closeModal();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.closeModal}
      className="docMetaModal thinBtn"
    >
      <Modal.Header>
        <Modal.Title>Create, Edit, or Delete Concept</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <FormGroup className="filterAdminToolWrap_elem">
            <ControlLabel>
              Name of Concept
            </ControlLabel>
            <FormControl
              className={
                error && !name.trim()
                  ? "emptyFormAdminToolControl"
                  : ""
              }
              type="text"
              name='name'
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </FormGroup>
          <FormGroup className="filterAdminToolWrap_elem">
            <ControlLabel>
              Description of Concept
            </ControlLabel>
            <FormControl
              componentClass="textarea"
              name='description'
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Concept Active?</ControlLabel>
            <Radio
              name="active"
              onChange={(e) => setActive(true)}
              checked={active}
            >
              True
            </Radio>
            <Radio
              name="active"
              onChange={(e) => setActive(false)}
              checked={!active}
            >
              False
            </Radio>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={saveChangesConcept}>Save</Button>
          <Button onClick={props.closeModal}>Cancel</Button>
          {props.concept && props.concept.id && (
            <Button bsStyle="danger" onClick={removeConcept}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    concepts: getConcepts(state)
  };
};

const ReduxConceptsModal = connect(mapStateToProps, {
  addConcept,
  updateConcept,
  deleteConcept
})(JurisdictionAnalyzerConceptsModal);

export default ReduxConceptsModal;
