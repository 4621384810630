import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';

const DELETE = 'DELETE';

const DocumentsIngestedModal = ({
  show,
  onHide,
  modalAction,
  spiderName
}) => {
  const [confirmationInput, setConfirmationInput] = useState('');
  const [parentIds, setParentIds] = useState('');
  const [deprecationPhrase, setDeprecationPhrase] = useState(' all ');
  const [error, setError] = useState(false);

  const closeModal = () => {
    setConfirmationInput('');
    setError(false);
    onHide();
  };

  const setParentIdsAndPhrase = (value) => {
    setParentIds(value);
    if (value !== '') {
      setDeprecationPhrase(' all children of the given parents ');
    } else {
      setDeprecationPhrase(' all ');
    }
  };

  const handleDeprecateAction = () => {
    if (confirmationInput === DELETE) {
      modalAction(parentIds);
      closeModal();
    } else {
      setError(true);
    }
  };

  return (
    <Modal
      className="crawlDocumentsIngested_deprecationModal"
      title="Confirm Deprecate Documents"
      show={show}
      onHide={closeModal}
      size="small"
    >
      <div className="crawlDocumentsIngested_deprecationModal_body">
        <div className="crawlDocumentsIngested_deprecationModal_body_content">
          <Typography variant="title" className="confirmationModalText">
            Are you sure you want to deprecate
            {deprecationPhrase}
            documents from crawl
            <Typography variant="title" bold>{` '${spiderName}' `}</Typography>
            ?
          </Typography>
          <TextInput
            className="crawlDocumentsIngested_deprecationModal_body_content_parentIds"
            value={parentIds}
            onChange={e => setParentIdsAndPhrase(e.target.value)}
            isError={error}
            label={
              <Typography variant="tag" color="gray">
                parent ids
              </Typography>
            }
          />
          <TextInput
            className="crawlDocumentsIngested_deprecationModal_body_content_confirmInput"
            value={confirmationInput}
            onChange={e => setConfirmationInput(e.target.value)}
            isError={error}
            label={
              <Typography variant="tag" color="gray">
                Type
                <Typography variant="tag" color="gray" bold uppercase> delete </Typography>
                to confirm
              </Typography>
            }
          />
        </div>
        <div className="buttonBlock">
          <Button onClick={closeModal} color="highlighted" size="small">Cancel</Button>
          <Button onClick={handleDeprecateAction} color="blue" size="small">Deprecate Documents</Button>
        </div>
      </div>
    </Modal>
  );
};

DocumentsIngestedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  modalAction: PropTypes.func.isRequired,
  spiderName: PropTypes.string
};

DocumentsIngestedModal.defaultProps = {
  spiderName: ''
};

export default DocumentsIngestedModal;
