import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './CrawlActivityTable.constants';
import * as elements from './elements';
import * as errorUtils from 'shared/utils/errors';
import * as queryKeysConstants from 'admin/constants/queryKeys';

import {
  useCrawlActivityTableData,
  useCrawlActivityTableDataSelector,
  useCrawlActivityTableHandlers
} from './hooks';

import { TABLE_VARIABLES } from './CrawlActivityTable.constants';

import './_crawl-activity-table.scss';

export const CrawlActivityTable: FC = () => {
  const { localState, localActions } = useCrawlActivityTableData();

  const handlers = useCrawlActivityTableHandlers({
    localState,
    localActions
  });

  const tableDataSelector = useCrawlActivityTableDataSelector();

  return (
    <uiLib.Table
      rowKey={TABLE_VARIABLES.id}
      rowsType="report"
      columns={constants.COLUMNS}
      localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
      shouldShowRefreshButton
      shouldShowTableOptions
      shouldShowFirstPageButton
      shouldShowLastPageButton
      canHaveExpandableRows
      customActionsElements={<elements.Filters onChange={handlers.handleFiltersChange} />}
      onDataFetch={handlers.handleReportsFetch}
      onRowExpand={handlers.handleChildReportsFetch}
      reloadTrigger={localState.refetchTrigger}
      initialRowsPerPage={constants.INITIAL_ROWS_PER_PAGE}
      rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
      queryOptions={{
        queryKey: [queryKeysConstants.QUERY_KEYS.SPIDER_RUN_REPORTS],
        select: tableDataSelector,
        expandableRowSelect: tableDataSelector,
        onError: errorUtils.logReactQueryError
      }}
      classNames={{
        toolbar: 'crawl-activity-table__toolbar',
        tableContainer: 'crawl-activity-table__table-container'
      }}
    />
  );
};
