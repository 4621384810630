import type { BulkEditModalProps } from '../BulkEditModal.types';
import type { BulkEditModalData } from './useBulkEditModalData.types';
import type { BulkEditModalFormattedData } from './useBulkEditModalFormattedData.types';
import type { useBulkEditModalQueries } from './useBulkEditModalQueries';

import * as helpers from './useBulkEditModalFormattedData.helpers';
import * as constants from '../BulkEditModal.constants';

import pluralize from 'pluralize';

import { useMemo } from 'react';
import { useBulkEditModalMutations } from './useBulkEditModalMutations';

export const useBulkEditModalFormattedData = ({
  props,
  localState,
  queries,
  mutations
}: {
  props: Pick<BulkEditModalProps, 'selectedDocumentIds'>;
  localState: BulkEditModalData['localState'];
  queries: ReturnType<typeof useBulkEditModalQueries>;
  mutations: ReturnType<typeof useBulkEditModalMutations>;
}): BulkEditModalFormattedData => {
  return useMemo(() => {
    const isUpdating = mutations.updateDocumentDataMutation.isLoading;

    return {
      modalTitle: `Bulk edit ${props.selectedDocumentIds.length} ${pluralize(
        'document',
        props.selectedDocumentIds.length
      )}`,
      isRulesListShown: localState.rules.length > 0,
      areSelectedDocumentLabelsLoaded: Boolean(queries.getSelectedDocumentsQuery.data),
      areCommandsLoaded: Boolean(queries.fetchCommands.data),
      isUpdating,
      isSubmitButtonDisabled:
        !localState.rules.every(helpers.isRuleCompleted) || !localState.rules.length || isUpdating,
      availableBulkEditActions: helpers.getAvailableActionsMap(localState.rules)
    };
  }, [
    mutations.updateDocumentDataMutation.isLoading,
    props.selectedDocumentIds.length,
    localState.rules,
    queries.getSelectedDocumentsQuery.data,
    queries.fetchCommands.data
  ]);
};
