import { DOCUMENT_ATTRIBUTES } from './document';

export enum CATEGORY_ATTRIBUTES {
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  ACTIVE_ID = 'active_id',
  DESCRIPTION = 'description',
  DOC_META_CATEGORY_ID = 'doc_meta_category_id',
  FROM_ID = 'from_id',
  IS_ARCHIVED = 'is_archived',
  IS_SYNCED = 'is_synced',
  META_CATEGORY = 'meta_category',
  NAME = 'name',
  SCRAPED_CATEGORIES = 'scraped_categories',
  SURFACE_IN_FILTER = 'surface_in_filter',
  TO_ID = 'to_id',
  // related attributes (from related tables)
  JURISDICTION = 'jurisdiction',
  JURISDICTION_ID = 'jurisdiction_id',
  AGENCY = 'agency',
  AGENCIES = 'agencies',
  DOCUMENT_CAI_CATEGORY = 'document_cai_category',
  DOC_CAI_CATEGORY_ID = 'doc_cai_category_id',
  SURFACE_DOCS = 'surface_docs',
  USER_ID = 'user_id'
}
