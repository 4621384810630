import type { FC } from 'react';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import {
  useEitlLabelsHandlers,
  useEitlLabelsData,
  useEitlLabelsTableConfig,
  useEitlLabelsFiltersConfig,
  useEitlLabelsLifecycle
} from './hooks';

import './_eitl-labels.scss';

export const EitlLabels: FC = () => {
  const { localState, localActions, reduxState, formattedData } = useEitlLabelsData();

  const handlers = useEitlLabelsHandlers({
    localState,
    localActions,
    reduxState
  });

  const tableConfig = useEitlLabelsTableConfig({
    onDeleteLabelsModalOpen: handlers.handleDeleteLabelsModalOpen,
    onRenameLabelModalOpen: handlers.handleRenameLabelModalOpen
  });

  const filtersConfig = useEitlLabelsFiltersConfig({
    reduxState,
    formattedData
  });

  useEitlLabelsLifecycle({
    onOrganizationFetch: handlers.handleOrganizationFetch
  });

  return (
    <div className="eitl-labels__container">
      <uiLib.ErrorBoundary>
        <uiLib.Table
          actions={tableConfig.actions}
          customActionsElements={
            <div className="eitl-labels__custom-actions-wrapper">
              <elements.EitlLabelCreateDropdown
                onAddModalOpen={handlers.handleAddLabelModalToggle(true)}
                onImportModalOpen={handlers.handleImportLabelsModalToggle(true)}
              />
              <uiLib.Filters
                config={filtersConfig}
                values={localState.filters}
                searchValue={localState.searchValue}
                searchPlaceholder="Search EITL Label name"
                withSearch
                onSubmit={handlers.handleFiltersSubmit}
                dateFormat={uiLib.getLocalDateFormat()}
              />
            </div>
          }
          classNames={{
            container: 'eitl-labels__table-outer-container',
            tableContainer: 'eitl-labels__table-inner-container',
            checkboxCell: 'eitl-labels__checkbox-cell',
            cell: 'eitl-labels__cell',
            toolbar: 'eitl-labels__table-toolbar'
          }}
          columns={tableConfig.columns}
          shouldShowRefreshButton
          shouldUpdateQueryParams
          shouldRenderToolbar
          onDataFetch={handlers.handleDataFetch}
          reloadTrigger={localState.refetchTrigger}
          queryParams={localState.params}
          onParamsChange={handlers.handleParamsChange}
          isLoading={reduxState.isOrganizationLoading}
          shouldShowSelectMaxRowsButton
          shouldShowTableOptions
        />
        {localState.isDeleteModalOpen && localState.labelToDelete && (
          <elements.EitlLabelDeleteModal
            label={localState.labelToDelete}
            onConfirm={handlers.handleDeleteLabelsModalConfirm}
            onClose={handlers.handleDeleteLabelsModalClose}
          />
        )}
        {localState.isRenameModalOpen && localState.labelToRename && (
          <elements.EitlLabelRenameModal
            label={localState.labelToRename}
            onConfirm={handlers.handleRenameLabelModalConfirm}
            onClose={handlers.handleRenameLabelModalClose}
          />
        )}
        {localState.isAddModalOpen && (
          <elements.EitlLabelAddModal
            onConfirm={handlers.handleAddLabelModalConfirm}
            onClose={handlers.handleAddLabelModalToggle(false)}
          />
        )}
        {localState.isImportModalOpen && (
          <elements.EitlLabelsImportModal
            onConfirm={handlers.handleImportLabelsModalConfirm}
            onClose={handlers.handleImportLabelsModalToggle(false)}
          />
        )}
      </uiLib.ErrorBoundary>
    </div>
  );
};
