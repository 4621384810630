import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { withHistory } from 'admin/utils/hooks';

import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, CircularProgress, Tooltip } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, Refresh } from '@material-ui/icons';
import Checkbox from 'app/common/Checkbox';
import ActionIcon from 'app/common/ActionIcon';
import Dialog from 'app/common/Dialog';
import { COLORS } from 'app/constants/Style';
import Loader from 'app/common/Loader';

export const SELECT_ALL_LIMIT = 600;

const INITIAL_PAGINATION_PARAMS = {
  limit: 20,
  offset: 0
};

const PaginationContainer = styled.span`
  display: flex;
  align-items: center;
`;

const PaginationSection = styled.span`
  margin-right: 15px;
  margin-bottom: 2px;
`;

const TableComponentContainer = styled.div`
  && {
    position: relative;
  }
`;

const TableContainerWrapper = styled(TableContainer)`
  && {
    height: ${props => props.compact ? 'inherit' : 'calc(100vh - 300px)'};
  }
`;

const TableBodyContainer = styled(TableBody)`
  && {
    opacity: ${props => props.loading ? '0.3' : '1'};
  }
`;

const TableElement = styled(Table)`
  && {
    th {
      background-color: white;
    }
  }
`;

const SelectAllAction = styled.span`
  && {
    cursor: pointer;
    text-decoration: underline;
    color: ${COLORS.hoverBlue};
    padding-right: 10px;
  }
`;

const SelectAllContainer = styled.div`
  && {
    background-color: #F9F9F9;
    margin: 15px 0px;
    padding: 10px;
    text-align: center;
  }
`;

const ActionTableCell = styled(TableCell)`
  && {
    padding: ${props => props.compact ? '5px 16px' : '16px'};
  }
`;

const TableCellHeader = styled(TableCell)`
  && {
    vertical-align: top;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    line-height: ${props => props.compact ? '14px' : '18px'};
    color: #4A4A4A;
    cursor: ${props => props.sortable ? 'pointer' : 'auto'};
    padding: ${props => props.compact ? '5px 16px' : '16px'};
  }
`;

const SortIcon = styled(KeyboardArrowUp)`
  && {
    display: ${props => props.possible ? 'inline-block' : 'none'};
    opacity: ${props => props.desc || props.asc ? '1' : '0'};
    transform: ${props => props.desc ? 'rotate(180deg)' : 'none'};

    &:hover {
      opacity: ${props => props.desc || props.asc ? '1' : '0.3'};
    }
  }
`;

const TableCellContent = styled(TableCell)`
  && {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
  }
`;

const CheckAllCheckboxWrapper = styled.span`
  && {
    margin-right: 5px;
    margin-top:-2px;
    > .checkbox-common-component {
      padding-left: 0px;
    }
  }
`;

const TableToolbar = styled.div`
  && {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const TableLoader = styled(CircularProgress)`
  && {
    position: absolute;
    top: 150px;
    left: calc(50% - 20px);
  }
`;

const ActionIconWrapper = styled.span`
  && {
    margin: 0px 5px;
  }
`;

const PaginationIcons = styled.span`
  && {
    > span {
      margin-left: 10px;
    }
  }
`;

const paginateData = (data, offset, limit) => data.slice(offset, limit + offset);

function SharedTableComponent({
  columns,
  rowElement,
  multiSelect,
  actions,
  rowKey,
  results,
  compact,
  triggerReload,
  history,
  location,
  apiFetch,
  noToolbarOrPagination,
  showRefresh,
  selectAll,
  typeDescription,
  paginationSection
}) {
  // Override the original search params with anything from the query string if it exists
  const searchParams = noToolbarOrPagination ? {
    offset: 0,
    limit: 1000
  } : INITIAL_PAGINATION_PARAMS;
  if (location) {
    const query = queryString.parse(location.search);
    Object.keys(searchParams).forEach(key => {
      if (_.get(query, key)) {
        searchParams[key] = query[key];
      }
    });
  }

  const defaultSort = _.get(_.find(columns, { defaultSort: true }), 'sortKey', null);

  const [loading, setLoading] = useState(false);
  const [fetchingAllSelected, setFetchingAllSelected] = useState(false);
  const [allSelected, setAllSelected] = useState([]);
  const [params, setParams] = useState({ ...searchParams, sort: defaultSort });
  const limit = parseInt(params.limit, 10);
  const offset = parseInt(params.offset, 10);
  const [data, setData] = useState(apiFetch ? [] : paginateData(results, offset, limit));
  const [checkedIdentifiers, setCheckedIdentifiers] = useState([]);
  const [count, setCount] = useState(0);
  const currentSort = params.sort;
  const allChecked = data.length > 0 && _.every(
    data,
    item => checkedIdentifiers.includes(item[rowKey])
  );
  const checkedData = data.filter(item => checkedIdentifiers.includes(_.get(item, rowKey)));
  const rowActions = actions.filter(({ disableRow }) => !disableRow);
  const columnActions = actions.filter(({ disableColumn }) => !disableColumn && multiSelect);

  const loadData = () => {
    if (!apiFetch) {
      setData(paginateData(results, offset, limit));
      return;
    }
    setAllSelected(false);
    setLoading(true);
    if (history && location) {
      const query = queryString.parse(location.search);
      history.push(
        `${location.pathname}?${queryString.stringify({
          ...query,
          ...params
        })}`
      );
    }
    apiFetch(params).then((resp) => {
      setLoading(false);
      setCount(resp.count);
      setData(resp.results);
    });
  };

  const handleBulkChecked = checkAll => setCheckedIdentifiers(
    checkAll ? _.uniq([
      ...checkedIdentifiers,
      ...data.map(item => _.get(item, rowKey))
    ]) : []
  );
  const handleActionClick = (trigger, actionData, resetChecked) => {
    if (apiFetch) {
      setLoading(true);
    }
    Promise.resolve(trigger(actionData)).then(() => loadData()).catch(() => setLoading(false));
    if (resetChecked) {
      setAllSelected(false);
      setCheckedIdentifiers([]);
    }
  };


  const toggleCheck = (identifierToToggle) => {
    if (checkedIdentifiers.includes(identifierToToggle)) {
      setCheckedIdentifiers(
        checkedIdentifiers.filter(identifier => identifier !== identifierToToggle)
      );
    } else {
      setCheckedIdentifiers(_.uniq([...checkedIdentifiers, identifierToToggle]));
    }
  };

  const handleColumnSort = sortKey => {
    // Column is not sortable
    if (!sortKey) {
      return;
    }

    const alreadySortAsc = params.sort === sortKey;
    const alreadySortDesc = params.sort === `-${sortKey}`;

    // Sort ASC by default
    let sortParam = `-${sortKey}`;
    if ((!alreadySortAsc && !alreadySortDesc) || alreadySortDesc) {
      sortParam = sortKey;
    }

    setParams({ ...params, sort: sortParam });
  };

  const shouldDisableAction = (disabled, selected) => {
    if (disabled === undefined) {
      return false;
    }
    switch (typeof (disabled)) {
      case 'boolean':
        return disabled;
      case 'function':
        return _.every(selected, item => disabled(item));
      default:
        return true;
    }
  };

  useEffect(() => {
    if (!loading) {
      loadData();
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, triggerReload]);

  const changeOffset = offsetDelta => {
    setParams({ ...params, offset: parseInt(params.offset, 10) + offsetDelta });
  };

  const handleSelectAll = async () => {
    setFetchingAllSelected(true);
    const selectAllResponse = await selectAll(params);
    setAllSelected(selectAllResponse);
    setFetchingAllSelected(false);
  };

  return (
    <TableComponentContainer>
      {!noToolbarOrPagination && (
        <TableToolbar>
          <span>
            {multiSelect && (
              <span>
                <CheckAllCheckboxWrapper data-testid="column-checkbox">
                  <Checkbox
                    onChange={handleBulkChecked.bind(this, !allChecked)}
                    checked={allChecked}
                  />
                </CheckAllCheckboxWrapper>
                {showRefresh && (
                  <ActionIconWrapper data-testid="column-action-icon">
                    <ActionIcon icon={<Refresh />} onClick={loadData} />
                  </ActionIconWrapper>
                )}
                {columnActions.map((columnAction, aIndex) => {
                  const {
                    title, trigger, disabled, confirmationDialogMessage, icon
                  } = columnAction;
                  const isDisabled = shouldDisableAction(disabled, checkedData);
                  const onConfirm = () => {
                    if (!_.isEmpty(allSelected)) {
                      return handleActionClick(trigger, allSelected.map(id => ({ id })), true);
                    }

                    return handleActionClick(trigger, checkedData, true);
                  };
                  const onActionContentClick = () => {
                    if (!confirmationDialogMessage && !isDisabled) {
                      onConfirm();
                    }
                  };
                  const content = icon ? (
                    <Tooltip title={title} key={aIndex}>
                      <ActionIconWrapper data-testid="column-action-icon">
                        <ActionIcon
                          icon={icon}
                          disabled={isDisabled}
                          onClick={onActionContentClick}
                        />
                      </ActionIconWrapper>
                    </Tooltip>
                  ) : (
                    <span onClick={onActionContentClick} key={aIndex}>
                      {title}
                    </span>
                  );

                  if (confirmationDialogMessage) {
                    return (
                      <Dialog
                        key={aIndex}
                        triggerContent={content}
                        onConfirm={onConfirm}
                        message={confirmationDialogMessage}
                        disabled={isDisabled}
                      />
                    );
                  }
                  return content;
                })}
              </span>
            )}
          </span>
          <PaginationContainer>
            {paginationSection && (
              <PaginationSection>
                {paginationSection}
              </PaginationSection>
            )}
            {count ? `${offset + 1} - ${limit + offset >= count ? count : limit + offset} of ${count}` : 'No results'}
            <PaginationIcons>
              <ActionIcon
                icon={<KeyboardArrowLeft />}
                disabled={offset === 0}
                onClick={changeOffset.bind(this, limit * -1)}
              />
              <ActionIcon
                icon={<KeyboardArrowRight />}
                disabled={limit + offset >= count}
                onClick={changeOffset.bind(this, limit)}
              />
            </PaginationIcons>
          </PaginationContainer>
        </TableToolbar>
      )}
      {loading ? <TableLoader size={40} /> : null}
      {checkedIdentifiers.length > 0 && (
        <SelectAllContainer>
          {fetchingAllSelected && <Loader size={10} />}
          {allSelected.length ? (
            <>
              {`${Math.min(SELECT_ALL_LIMIT, count)} selected. `}
              <SelectAllAction
                onClick={() => {
                  setAllSelected([]);
                  setCheckedIdentifiers([]);
                }}
              >
                Clear Selection
              </SelectAllAction>
            </>
          ) : (
            <>
              {`${checkedIdentifiers.length} ${typeDescription} are selected. `}
              {count > 0 && (
                <SelectAllAction onClick={handleSelectAll}>
                  {`Select up to ${Math.min(SELECT_ALL_LIMIT, count)} ${typeDescription}`}
                </SelectAllAction>
              )}
            </>
          )}
        </SelectAllContainer>
      )}
      <TableContainerWrapper compact={+compact}>
        <TableElement stickyHeader>
          <TableHead>
            <TableRow>
              {multiSelect && (
                <TableCell>&nbsp;</TableCell>
              )}
              {columns.map(({ title, align, sortKey }, cIndex) => (
                <TableCellHeader
                  compact={+compact}
                  key={cIndex}
                  align={align}
                  sortable={sortKey !== undefined | 0}
                  onClick={handleColumnSort.bind(this, sortKey)}
                >
                  {title}
                  <SortIcon
                    data-testid={`${title}-sort-icon`}
                    asc={sortKey === currentSort | 0}
                    desc={`-${sortKey}` === currentSort | 0}
                    possible={sortKey !== undefined | 0}
                  />
                </TableCellHeader>
              ))}
              {rowActions.length > 0 && (
                <ActionTableCell compact={1}>&nbsp;</ActionTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBodyContainer loading={loading | 0}>
            {data.map((item, rIndex) => {
              const rowIdentifier = _.get(item, rowKey);
              let elements = columns.map(({ dataKey, render, align }, cIndex) => {
                const itemData = _.get(item, dataKey);
                return (
                  <TableCellContent align={align} key={cIndex}>
                    {render ? render(itemData) : itemData}
                  </TableCellContent>
                );
              });
              if (multiSelect) {
                elements = [
                  <TableCellContent key={`${rowIdentifier}-checkbox`} data-testid="row-checkbox">
                    <Checkbox
                      onChange={toggleCheck.bind(this, rowIdentifier)}
                      checked={checkedIdentifiers.includes(rowIdentifier)}
                    />
                  </TableCellContent>,
                  ...elements
                ];
              }

              if (rowActions.length > 0) {
                elements = [
                  ...elements,
                  <TableCellContent key={`${rowIdentifier}-action`}>
                    {rowActions.map((rowAction, aIndex) => {
                      const {
                        title, trigger, disabled, confirmationDialogMessage, icon
                      } = rowAction;
                      const isDisabled = shouldDisableAction(disabled, [item]);
                      const onConfirm = () => handleActionClick(trigger, [item], true);
                      const onActionContentClick = () => {
                        if (!confirmationDialogMessage && !isDisabled) {
                          onConfirm();
                        }
                      };
                      const content = (
                        icon ? (
                          <Tooltip title={title} key={aIndex}>
                            <ActionIconWrapper data-testid="row-action-icon">
                              <ActionIcon
                                icon={icon}
                                disabled={isDisabled}
                                onClick={onActionContentClick}
                              />
                            </ActionIconWrapper>
                          </Tooltip>
                        ) : (
                          <span onClick={onActionContentClick} key={aIndex}>
                            {title}
                          </span>
                        )
                      );

                      if (confirmationDialogMessage) {
                        return (
                          <Dialog
                            key={aIndex}
                            triggerContent={content}
                            onConfirm={onConfirm}
                            message={confirmationDialogMessage}
                            disabled={isDisabled}
                          />
                        );
                      }
                      return content;
                    })}
                  </TableCellContent>
                ];
              }

              return rowElement(elements, item, rIndex);
            })}
          </TableBodyContainer>
        </TableElement>
      </TableContainerWrapper>
    </TableComponentContainer>
  );
}

SharedTableComponent.propTypes = {
  typeDescription: PropTypes.string,
  paginationSection: PropTypes.element,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataKey: PropTypes.string,
      render: PropTypes.func,
      sortKey: PropTypes.string,
      defaultSort: PropTypes.bool,
      align: PropTypes.string
    })
  ).isRequired,
  rowKey: PropTypes.string,

  // Provide either apiFetch or data
  apiFetch: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.shape({})),

  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.element,
      disableRow: PropTypes.bool,
      disableColumn: PropTypes.bool,
      confirmationDialog: PropTypes.bool,
      confirmationDialogMessage: PropTypes.string,

      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

      // Callback for triggering action, must return a promise that resolves
      trigger: PropTypes.func.isRequired
    })
  ),

  multiSelect: PropTypes.bool,
  showRefresh: PropTypes.bool,
  rowElement: PropTypes.func,
  noToolbarOrPagination: PropTypes.bool,
  compact: PropTypes.bool,
  selectAll: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape({})
  }),


  // This component current filters outside of the grid component and therefore we manually
  // trigger reload of data. If this is going to be the common pattern then build the filtering
  // into the table component
  triggerReload: PropTypes.bool
};

SharedTableComponent.defaultProps = {
  paginationSection: null,
  apiFetch: null,
  actions: [],
  compact: false,
  rowKey: 'id',
  multiSelect: false,
  rowElement: (children, item, rIndex) => <TableRow key={rIndex}>{children}</TableRow>,
  results: null,
  triggerReload: false,
  typeDescription: 'results',
  history: null,
  location: null,
  noToolbarOrPagination: false,
  showRefresh: false,
  selectAll: null
};

export default withHistory(SharedTableComponent);
