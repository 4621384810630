import _ from 'lodash';
import moment from 'moment';
import { CONCEPT_ATTRIBUTES, ERRORS } from './ConceptDetails.constants';
import { DATE_TIME_FORMAT } from '../../../constants/projectSettings';
import { api_getMetaConcepts } from 'shared/features/metaConcepts/metaConcepts.api';

// ---------------- GET Concept Info ----------------

const formatTime = date => (date ? moment(date).format(DATE_TIME_FORMAT) : 'N/A');
const formatSearch = searchString => JSON.parse(JSON.stringify(searchString));

const getMetaConcept = conceptInfo => {
  const id = _.get(conceptInfo, ['meta_concept', 'id'], '');
  const name = _.get(conceptInfo, ['meta_concept', 'name'], '');
  return _.isNumber(id) && name ? { value: id, label: name } : null;
};

// convert DB concept info to screen concept details
export const fromRawInfoToDetails = concept => ({
  [CONCEPT_ATTRIBUTES.name]: _.get(concept, 'name', ''),
  [CONCEPT_ATTRIBUTES.id]: _.get(concept, 'id', 'new'),
  [CONCEPT_ATTRIBUTES.description]: _.get(concept, 'description', ''),
  [CONCEPT_ATTRIBUTES.advancedSearch]: formatSearch(_.get(concept, 'advanced_search', '')),
  [CONCEPT_ATTRIBUTES.metaConcept]: getMetaConcept(concept),
  [CONCEPT_ATTRIBUTES.isActive]: _.get(concept, 'is_active', false),
  [CONCEPT_ATTRIBUTES.createdAt]: formatTime(_.get(concept, 'created_at', '')),
  [CONCEPT_ATTRIBUTES.updatedAt]: formatTime(_.get(concept, 'updated_at', ''))
});

// ---------------- POST Concept Info ----------------

// // check functions

export const checkRequiredFields = field => !field && ERRORS.commonError;

// function that pre-formats details for API

export const fromDetailsToRawInfo = async (newInfo, oldInfo) => {
  const details = {};
  const errors = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }) => {
    let valueNew;
    if (_.isBoolean(predefinedValue)) valueNew = predefinedValue;
    else valueNew = predefinedValue || _.get(newInfo, newPath, null);

    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(details, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew);
        if (result) errors.push({ text: result, title: newPath[0] });
      }
    }
  };

  setDifference({
    newPath: [CONCEPT_ATTRIBUTES.name],
    oldPath: ['name'],
    checkFunction: checkRequiredFields
  });
  setDifference({
    newPath: [CONCEPT_ATTRIBUTES.id],
    oldPath: ['id'],
    predefinedValue: Number(newInfo[CONCEPT_ATTRIBUTES.id]) || null,
    checkFunction: checkRequiredFields
  });
  setDifference({
    newPath: [CONCEPT_ATTRIBUTES.description],
    oldPath: ['description']
  });
  setDifference({
    newPath: [CONCEPT_ATTRIBUTES.isActive],
    oldPath: ['is_active']
  });
  setDifference({
    newPath: [CONCEPT_ATTRIBUTES.advancedSearch],
    oldPath: ['advanced_search'],
    checkFunction: checkRequiredFields
  });
  setDifference({
    newPath: [CONCEPT_ATTRIBUTES.metaConcept, 'value'],
    oldPath: ['meta_concept_id'],
    checkFunction: checkRequiredFields
  });

  return { details, errors };
};

// ---------------- GET Options ----------------

const formatSelectOptions = (array, valueName, labelName) =>
  array.map(item => ({
    value: _.get(item, valueName, item),
    label: _.get(item, labelName, item)
  }));

export const loadMetaConceptOptions = (name, searchValue, responseName) =>
  api_getMetaConcepts({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, 'id', responseName));
