import { fetch, BASE_API_OPTIONS} from "fetch";

export function api_getJurisdictions(params) {
  return fetch({
    url: `/jurisdictions`,
    dataType: 'Jurisdictions',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_postJurisdictions(params) {
  return fetch({
    url: `/jurisdictions`,
    dataType: 'Jurisdictions',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_putJurisdictions(params) {
  return fetch({
    url: `/jurisdictions`,
    dataType: 'Jurisdictions',
    method: 'PUT',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_deleteJurisdictions(params) {
  return fetch({
    url: `/jurisdictions`,
    dataType: 'Jurisdictions',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

// jurisdictions helpers

export function api_getJurisdictionsCountry(params) {
  return fetch({
    url: `/jurisdictions-country`,
    dataType: 'Jurisdictions',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}

export function api_getJurisdictionsCategory(params) {
  return fetch({
    url: `/jurisdictions-category`,
    dataType: 'Jurisdictions',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
}
