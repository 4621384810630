import type * as eitlLabelsTypes from 'shared/features/eitlLabels/eitlLabels.types';

import type { SelectOption } from '@compliance.ai/web-components';
import type { ExternalApiCrawlFromResponse } from 'shared/features/externalApiCrawl/externalApiCrawl.api.types';
import type {
  CrawlDetails,
  ExternalApiCrawlError,
  SetDifferencePropsCheckFunctionResult,
  SetDifferencePropsPath,
  SetDifferencePropsValue
} from './CrawlerDetails.types';

import * as uiLib from '@compliance.ai/web-components';
import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';
import * as _ from 'lodash';

import {
  CRAWL_GENERAL,
  CRAWL_LINKS,
  CRAWL_PROVISION,
  CRAWL_PRIVACY,
  CRAWL_EXTRA_RECURRING,
  ERRORS,
  CRAWL_SCRAPED,
  ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS
} from './CrawlerDetails.constants';
import { api_getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.api';
import { api_getCategories } from 'shared/features/categories/categories/categories.api';
import { api_getNewsSources } from 'shared/features/sources/newsSources/newsSources.api';
import { api_getAgencies } from 'shared/features/agencyData/agencyData.api.js';
import { getDevComplexityClassification } from './CrawlerDetails.constants';

// ---------------- GET Options ----------------

const formatSelectOptions = (array: [], valueName: string, labelName: string): SelectOption[] =>
  array.map(item => ({
    value: _.get(item, valueName, item),
    label: _.get(item, labelName, item)
  }));

export const loadJurisdictionsOptions = (
  name: string,
  searchValue: string,
  responseName: string
): Promise<SelectOption[]> =>
  api_getJurisdictions({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, 'id', responseName));

export const loadAgencyOptions = (
  name: string,
  searchValue: string,
  responseName: string
): Promise<SelectOption[]> =>
  api_getAgencies({ [name]: searchValue })
    .then(agency => agency.items)
    .then(agency => formatSelectOptions(agency, 'id', responseName));

export const loadNewsSourceOptions = (
  name: string,
  searchValue: string,
  responseName: string
): Promise<SelectOption[]> =>
  api_getNewsSources({ [name]: searchValue })
    .then(newsSource => newsSource.items)
    .then(newsSource => formatSelectOptions(newsSource, 'id', responseName));

export const loadCategoryOptions = (
  name: string,
  searchValue: string,
  responseName: string
): Promise<SelectOption[]> =>
  api_getCategories({ [name]: searchValue })
    .then(cat => cat.items)
    .then(cat => formatSelectOptions(cat, responseName, responseName));

// ---------------- GET Crawl Info ----------------

const getAgencies = (crawlInfo: ExternalApiCrawlFromResponse): SelectOption[] => {
  const value = crawlInfo?.agency_id;
  const label = crawlInfo?.agency?.short_name || crawlInfo?.agency?.name;
  return value && label ? [{ value, label }] : [];
};

const getCrawlType = (crawlInfo: ExternalApiCrawlFromResponse): SelectOption | null => {
  const crawlType = crawlInfo?.type;
  return crawlType ? { value: crawlType, label: crawlType } : null;
};

const getCategory = (crawlInfo: ExternalApiCrawlFromResponse): SelectOption[] => {
  const crawlCategory = crawlInfo?.category;
  return crawlCategory ? [{ value: crawlCategory, label: crawlCategory }] : [];
};

const getJurisdiction = (crawlInfo: ExternalApiCrawlFromResponse): SelectOption | null => {
  const id = crawlInfo?.jurisdiction_id;
  const name = crawlInfo?.jurisdiction;
  return _.isNumber(id) && name ? { value: id, label: name } : null;
};

const getNewsSource = (crawlInfo: ExternalApiCrawlFromResponse): SelectOption[] => {
  const value = crawlInfo?.news_source?.id;
  const label = crawlInfo?.news_source?.name;
  return value && label ? [{ value, label }] : [];
};

const getPubInPast = (crawlInfo: ExternalApiCrawlFromResponse): SelectOption | null => {
  const pubInPast = crawlInfo?.details?.crawler_recur?.published_in_past_unit;
  return pubInPast ? { value: pubInPast, label: pubInPast } : null;
};

const getPubInPastValue = (crawlInfo: ExternalApiCrawlFromResponse): string => {
  const pubInPastValue = crawlInfo?.details?.crawler_recur?.published_in_past_value;
  return pubInPastValue ? String(pubInPastValue) : '';
};

const getScheduleFrequency = (crawlInfo: ExternalApiCrawlFromResponse): string => {
  const pubInPastValue = crawlInfo?.details?.crawler_recur?.interval;
  return pubInPastValue ? String(pubInPastValue).split(' ')[0] : '';
};

const getSourceUrl = (crawlInfo: ExternalApiCrawlFromResponse): string[] => {
  const sourceUrl = crawlInfo?.source_url;
  return sourceUrl ? [sourceUrl] : [];
};

const getScrapedInfo = (
  crawlInfo: ExternalApiCrawlFromResponse,
  fieldName: string
): SelectOption[] => {
  const scrappedInfo = _.get(crawlInfo, fieldName);
  return scrappedInfo
    ? scrappedInfo.map((item: { name?: string; id?: string }) => ({
        label: item?.name ?? item,
        value: item?.id ?? item
      }))
    : [];
};

const getDevComplexityValue = (crawlInfo: ExternalApiCrawlFromResponse): string => {
  if (_.isEmpty(crawlInfo)) {
    return '0';
  }
  return _.isNil(crawlInfo?.dev_complexity) ? '0' : String(crawlInfo?.dev_complexity);
};

const getEitlLabels = (
  crawlInfo: ExternalApiCrawlFromResponse
): eitlLabelsTypes.EitlLabelOption[] => {
  const labels = crawlInfo?.eitl_labels ?? [];
  return labels.map(eitlLabelsApiHelpers.formatEitlLabelsOption);
};

// convert server crawl info to screen crawl details
export const fromRawInfoToDetails = (crawlInfo: ExternalApiCrawlFromResponse): CrawlDetails => ({
  // general info
  [CRAWL_GENERAL.agencies]: getAgencies(crawlInfo),
  [CRAWL_GENERAL.crawlType]: getCrawlType(crawlInfo),
  [CRAWL_GENERAL.docType]: getCategory(crawlInfo),
  [CRAWL_GENERAL.jurisdiction]: getJurisdiction(crawlInfo),
  [CRAWL_GENERAL.newsSource]: getNewsSource(crawlInfo),
  [CRAWL_GENERAL.pubInPast]: getPubInPast(crawlInfo),
  [CRAWL_GENERAL.pubInPastValue]: getPubInPastValue(crawlInfo),
  [CRAWL_GENERAL.frequency]: getScheduleFrequency(crawlInfo),
  [CRAWL_GENERAL.crawlerRecurActive]: crawlInfo?.details?.crawler_recur?.recur_enabled ?? false,
  [CRAWL_GENERAL.crawlID]: crawlInfo?.external_api_id ?? '',
  [CRAWL_GENERAL.spiderName]: crawlInfo?.spider_name ?? '',
  [CRAWL_GENERAL.devComplexity]: getDevComplexityValue(crawlInfo),
  [CRAWL_GENERAL.devComplexityClassification]: getDevComplexityClassification(crawlInfo),
  [CRAWL_GENERAL.eitlLabels]: getEitlLabels(crawlInfo),
  // scrapped info
  [CRAWL_SCRAPED.scrapedCategory]: getScrapedInfo(crawlInfo, 'doc_scraped_categories'), // array
  [CRAWL_SCRAPED.scrapedNewsSource]: getScrapedInfo(crawlInfo, 'doc_scraped_news_sources'), // array
  [CRAWL_SCRAPED.scrapedAgencies]: getScrapedInfo(crawlInfo, 'doc_scraped_agencies'), // array
  [CRAWL_SCRAPED.scrapedCAICategories]: getScrapedInfo(crawlInfo, 'doc_scraped_cai_categories'), // array
  // links
  [CRAWL_LINKS.sourceURL]: getSourceUrl(crawlInfo),
  [CRAWL_LINKS.JIRA]: crawlInfo?.details?.jira_tickets ?? [],
  [CRAWL_LINKS.other]: crawlInfo?.details?.other_links ?? [],
  [CRAWL_LINKS.QA]: crawlInfo?.details?.qa_link ?? '',
  [CRAWL_LINKS.bucket]: crawlInfo?.details?.s3_bucket ?? '',
  [CRAWL_LINKS.specification]: crawlInfo?.details?.specification ?? '',
  // provision
  [CRAWL_PROVISION.docket]: (crawlInfo?.details?.provision_dockets ?? []).join(','),
  [CRAWL_PROVISION.nodeURL]: crawlInfo?.details?.root_node_url ?? '',
  [CRAWL_PROVISION.rootShelfId]: crawlInfo?.details?.root_shelf_id ?? '',
  [CRAWL_PROVISION.notRequirePubDate]: crawlInfo?.details?.pub_date_not_required ?? false,
  [CRAWL_PROVISION.isCheckAll]: crawlInfo?.details?.is_check_all ?? false,
  [CRAWL_PROVISION.useProxy]: crawlInfo?.details?.use_proxy ?? false,
  [CRAWL_PROVISION.startingDate]: crawlInfo?.details?.starting_date ?? null,
  [CRAWL_PROVISION.isResource]: crawlInfo?.is_resource ?? false,
  [CRAWL_PROVISION.sourceStructure]: crawlInfo?.source_structure ?? '',
  [CRAWL_PROVISION.sentencificationConfiguration]:
    crawlInfo?.details?.sentencification_configuration ?? '',
  [CRAWL_PROVISION.enableAutoTranslation]: crawlInfo?.enable_translation ?? null,
  [CRAWL_PROVISION.enableDocumentVersioning]: crawlInfo?.enable_document_versioning ?? false,
  // privacy
  [CRAWL_PRIVACY.access]: crawlInfo?.details?.privacy_restrict ?? false,
  // extra recurring params
  [CRAWL_EXTRA_RECURRING.versioning]:
    crawlInfo?.details?.crawler_recur?.versioning_existing_documents ?? false,
  [CRAWL_EXTRA_RECURRING.forceUpdate]:
    crawlInfo?.details?.crawler_recur?.force_update_existing_documents ?? false,
  [CRAWL_EXTRA_RECURRING.softUpdate]:
    crawlInfo?.details?.crawler_recur?.update_existing_documents ?? false,
  [CRAWL_EXTRA_RECURRING.cursorRun]:
    crawlInfo?.details?.crawler_recur?.crawl_cursor_run_mode ?? false,
  [CRAWL_EXTRA_RECURRING.autoResume]:
    crawlInfo?.details?.crawler_recur?.crawl_auto_resume_mode ?? false
});

// ---------------- POST Crawl Info ----------------

// // check functions

export const checkRootNodeURL = (
  rootNodeURL: SetDifferencePropsValue,
  newInfo: CrawlDetails
): SetDifferencePropsCheckFunctionResult => {
  if (rootNodeURL) {
    try {
      const urlObject = new URL(String(rootNodeURL));
      if (!urlObject.searchParams.get('summary_id')) throw ERRORS.rootNodeURL;
    } catch (e) {
      return ERRORS.rootNodeURL;
    }
  }
  return false;
};
// TODO: [DATAWEB-2356] uncomment when  edit supported
// in case of edit support, before honoring the change make sure it is a valid format
//  export const checkSentencificationConfiguration = (
//   sentencificatonConfiguration: string
// ): SetDifferencePropsCheckFunctionResult => {
//   if (sentencificatonConfiguration) {
//     try {
//       const parsedSentConf = JSON.parse(sentencificatonConfiguration);
//     } catch (e) {
//       return ERRORS.sentencificationConfiguration;
//     }
//   }
//   return false;
// };
export const checkSourceStructure = (
  sourceStructure: string | object
): SetDifferencePropsCheckFunctionResult => {
  if (sourceStructure) {
    try {
      const ss = JSON.stringify(sourceStructure, null, 2);
      if (ss == null) throw ERRORS.sourceStructure;
    } catch (e) {
      return ERRORS.commonError;
    }
  }
  return false;
};

export const checkRequiredFields = (
  valueNew: SetDifferencePropsValue,
  newInfo: CrawlDetails
): SetDifferencePropsCheckFunctionResult => !valueNew && ERRORS.commonError;

export const checkCrawlId = (
  valueNew: SetDifferencePropsValue,
  newInfo: CrawlDetails
): string | false => {
  if (!valueNew) return false;
  const includes_spider_name = (newInfo[CRAWL_GENERAL.crawlID] as string[]).includes(
    newInfo[CRAWL_GENERAL.spiderName] as string
  );
  const is_unified_crawl =
    (newInfo[CRAWL_GENERAL.crawlType] as { value?: string | number | null })?.value === 'unified';
  if (is_unified_crawl && !includes_spider_name) return ERRORS.crawlId;
  return false;
};

export const checkDevComplexity = (
  valueNew: SetDifferencePropsValue,
  newInfo: CrawlDetails
): SetDifferencePropsCheckFunctionResult =>
  (!valueNew || Number(valueNew) < 0) &&
  newInfo[CRAWL_GENERAL.crawlType] &&
  (newInfo[CRAWL_GENERAL.crawlType] as { value?: string | number | null })?.value === 'unified' &&
  ERRORS.devComplexityError;

// function that format crawl object to send

interface SetDifferenceProps {
  newPath: SetDifferencePropsPath;
  oldPath: SetDifferencePropsPath;
  superPath?: string[];
  predefinedValue?: SetDifferencePropsValue;
  checkFunction?: (
    valueNew: SetDifferencePropsValue,
    newInfo: CrawlDetails
  ) => SetDifferencePropsCheckFunctionResult;
}

export const fromDetailsToRawInfo = (
  newInfo: CrawlDetails,
  oldInfo: ExternalApiCrawlFromResponse
): {
  crawlDetails: CrawlDetails;
  errors: ExternalApiCrawlError[];
} => {
  const crawlDetails = {};
  const errors: ExternalApiCrawlError[] = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }: SetDifferenceProps) => {
    let valueNew;
    if (_.isBoolean(predefinedValue)) valueNew = predefinedValue;
    else valueNew = predefinedValue || _.get(newInfo, newPath, null);

    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld) || (_.isNil(valueNew) && !!checkFunction)) {
      _.set(crawlDetails, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew, newInfo);
        if (result) errors.push({ text: result, title: String(newPath[0]) });
      }
    }
  };

  // general info
  setDifference({
    newPath: [CRAWL_GENERAL.agencies, 0, 'value'],
    oldPath: ['agency_id']
  });
  setDifference({
    newPath: [CRAWL_GENERAL.crawlType, 'value'],
    oldPath: ['type'],
    checkFunction: checkRequiredFields
  });
  setDifference({
    newPath: [CRAWL_GENERAL.docType, 0, 'value'],
    oldPath: ['category']
  });
  setDifference({
    newPath: [CRAWL_GENERAL.jurisdiction, 'label'],
    oldPath: ['jurisdiction']
  });
  setDifference({
    newPath: [CRAWL_GENERAL.jurisdiction, 'value'],
    oldPath: ['jurisdiction_id']
  });
  setDifference({
    newPath: [CRAWL_PROVISION.enableDocumentVersioning],
    oldPath: ['enable_document_versioning']
  });
  setDifference({
    newPath: [CRAWL_GENERAL.newsSource, 0, 'value'],
    oldPath: ['news_source', 'id'],
    superPath: ['news_source_id']
  });
  setDifference({
    newPath: [CRAWL_GENERAL.crawlID],
    oldPath: ['external_api_id'],
    checkFunction: checkCrawlId
  });
  setDifference({
    newPath: [CRAWL_GENERAL.spiderName],
    oldPath: ['spider_name'],
    checkFunction: checkRequiredFields
  });
  setDifference({
    newPath: [CRAWL_PROVISION.enableAutoTranslation],
    oldPath: ['enable_translation']
  });

  // dev complexity
  const newDevComplexity = Number(newInfo[CRAWL_GENERAL.devComplexity]);
  const oldDevComplexity = Number(oldInfo.dev_complexity);

  if (newDevComplexity !== oldDevComplexity || newInfo[CRAWL_GENERAL.devComplexity] === '') {
    _.set(crawlDetails, CRAWL_GENERAL.devComplexity, newInfo[CRAWL_GENERAL.devComplexity]);

    const result = checkDevComplexity(newInfo[CRAWL_GENERAL.devComplexity], newInfo);

    if (result) {
      errors.push({ text: result, title: String(CRAWL_GENERAL.devComplexity) });
    }
  }

  // eitl labels
  const newEitlLabels =
    (newInfo[CRAWL_GENERAL.eitlLabels] as eitlLabelsTypes.EitlLabelOption[])?.map(
      eitlLabelsApiHelpers.convertOptionToEitlLabelData
    ) ?? [];

  const oldEitlLabels = oldInfo.eitl_labels ?? [];

  if (!_.isEqual(newEitlLabels, oldEitlLabels)) {
    _.set(crawlDetails, CRAWL_GENERAL.eitlLabels, newEitlLabels);
  }

  // // check details / crawler_recur
  setDifference({
    newPath: [CRAWL_GENERAL.pubInPast, 'value'],
    oldPath: ['details', 'crawler_recur', 'published_in_past_unit']
  });
  setDifference({
    newPath: [CRAWL_GENERAL.pubInPastValue],
    oldPath: ['details', 'crawler_recur', 'published_in_past_value'],
    predefinedValue: Number(newInfo[CRAWL_GENERAL.pubInPastValue])
  });
  setDifference({
    newPath: [CRAWL_GENERAL.frequency],
    oldPath: ['details', 'crawler_recur', 'interval'],
    predefinedValue: `${newInfo[CRAWL_GENERAL.frequency]} hour`
  });
  setDifference({
    newPath: [CRAWL_GENERAL.crawlerRecurActive],
    oldPath: ['details', 'crawler_recur', 'recur_enabled']
  });

  // links
  setDifference({
    newPath: [CRAWL_LINKS.sourceURL, 0],
    oldPath: ['source_url']
  });
  setDifference({
    newPath: [CRAWL_LINKS.JIRA],
    oldPath: ['details', 'jira_tickets']
  });
  setDifference({
    newPath: [CRAWL_LINKS.other],
    oldPath: ['details', 'other_links']
  });
  setDifference({
    newPath: [CRAWL_LINKS.QA],
    oldPath: ['details', 'qa_link']
  });
  setDifference({
    newPath: [CRAWL_LINKS.bucket],
    oldPath: ['details', 's3_bucket']
  });
  setDifference({
    newPath: [CRAWL_LINKS.specification],
    oldPath: ['details', 'specification']
  });

  // provision
  setDifference({
    newPath: [CRAWL_PROVISION.docket],
    oldPath: ['details', 'provision_dockets'],
    predefinedValue: _.compact(_.get(newInfo as object, CRAWL_PROVISION.docket, '').split(','))
  });

  setDifference({
    newPath: [CRAWL_PROVISION.nodeURL],
    oldPath: ['details', 'root_node_url'],
    checkFunction: checkRootNodeURL
  });

  // TODO: [DATAWEB-2356] support edit later
  // setDifference({
  //   newPath: [CRAWL_PROVISION.sentencificationConfiguration],
  //   oldPath: ['details', 'sentencification_configuration']
  //   checkFunction: checkSentencificationConfiguration
  // });

  // support edit later
  // setDifference({
  //   newPath: [CRAWL_PROVISION.sourceStructure],
  //   oldPath: ['details', 'source_structure'],
  //   checkFunction: checkSourceStructure
  // });

  setDifference({
    newPath: [CRAWL_PROVISION.notRequirePubDate],
    oldPath: ['details', 'pub_date_not_required']
  });

  setDifference({
    newPath: [CRAWL_PROVISION.isCheckAll],
    oldPath: ['details', 'is_check_all']
  });

  setDifference({
    newPath: [CRAWL_PROVISION.useProxy],
    oldPath: ['details', 'use_proxy']
  });

  setDifference({
    newPath: [CRAWL_PROVISION.startingDate],
    oldPath: ['details', 'starting_date'],
    predefinedValue: newInfo[CRAWL_PROVISION.startingDate]
      ? uiLib.formatDate(newInfo[CRAWL_PROVISION.startingDate] as string, {
          format: uiLib.DATE_FORMATS.API_DATE_TIME_WITH_T,
          passedTimezone: uiLib.TIMEZONES.UTC,
          timezone: uiLib.TIMEZONES.UTC,
          shouldKeepLocalTime: true
        })
      : null
  });

  setDifference({
    newPath: [CRAWL_PROVISION.isResource],
    oldPath: ['is_resource']
  });

  // privacy
  setDifference({
    newPath: [CRAWL_PRIVACY.access],
    oldPath: ['details', 'privacy_restrict']
  });

  // extra recurring params
  setDifference({
    newPath: [CRAWL_EXTRA_RECURRING.versioning],
    oldPath: ['details', 'crawler_recur', 'versioning_existing_documents']
  });
  setDifference({
    newPath: [CRAWL_EXTRA_RECURRING.forceUpdate],
    oldPath: ['details', 'crawler_recur', 'force_update_existing_documents']
  });
  setDifference({
    newPath: [CRAWL_EXTRA_RECURRING.softUpdate],
    oldPath: ['details', 'crawler_recur', 'update_existing_documents']
  });
  setDifference({
    newPath: [CRAWL_EXTRA_RECURRING.cursorRun],
    oldPath: ['details', 'crawler_recur', 'crawl_cursor_run_mode']
  });
  setDifference({
    newPath: [CRAWL_EXTRA_RECURRING.autoResume],
    oldPath: ['details', 'crawler_recur', 'crawl_auto_resume_mode']
  });

  return { crawlDetails, errors };
};

export const getEnableTranslationSelectedOption = (enableTranslation: boolean | null) =>
  ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS.find(option => option.value === enableTranslation) ??
  ENABLE_AUTO_TRANSLATION_SELECT_OPTIONS[0];

/*
  export only for tests
  if function should be used in other place that this helper
  please export it by place (like export const ...)
*/
export {
  getAgencies,
  getCrawlType,
  getCategory,
  getJurisdiction,
  getNewsSource,
  getPubInPast,
  getPubInPastValue,
  getScheduleFrequency,
  getSourceUrl,
  getScrapedInfo
};
