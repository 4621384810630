import { api_getAllPaymentTypes } from "./paymentTypes.api";
import { setPaymentTypes } from "./paymentTypes.reducer";
import { openErrorSnackbar } from "../snackbar/snackbar.reducer";

export const ERROR_GET_PAYMENT_TYPES = 'Unable to get payment types';

export const getAllPaymentTypesAC = () => async dispatch => {
  try {
    const res = await api_getAllPaymentTypes();

    dispatch(setPaymentTypes(res.payment_types));
  } catch (e) {
    dispatch(openErrorSnackbar(ERROR_GET_PAYMENT_TYPES));

    throw e
  }
};
