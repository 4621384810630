import type { CrawlActivityTableData } from './useCrawlActivityTableData.types';

import { useMemo, useState } from 'react';

export const useCrawlActivityTableData = (): CrawlActivityTableData => {
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const [filters, setFilters] = useState({});

  const localState: CrawlActivityTableData['localState'] = {
    refetchTrigger,
    filters
  };

  const localActions: CrawlActivityTableData['localActions'] = useMemo(
    () => ({
      setRefetchTrigger,
      setFilters
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
