import PropTypes from 'prop-types';
import lodash from 'lodash';
import SourceDetailsAdditionalInfo from './SourceDetailsAdditionalInfo';
import SourceDetailsGeneral from './SourceDetailsGeneral';
import CRAWL_ROUTES from 'admin/constants/routes';

import { connect } from 'react-redux';
import {
  createNewsSources,
  updateNewsSources
} from 'shared/features/sources/newsSources/newsSources.actions';
import { SOURCE_DETAILS } from './SourceDetails.constants';
import { fromRawInfoToDetails, fromDetailsToRawInfo } from './SourceDetails.helper';
import { setNotification, NOTIFICATION_TYPES } from '../../../../components/Notification';
import { SOURCE_TYPES } from 'admin/constants/sources';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'admin/utils/hooks';

const SourceDetailsElements = ({
  newsSourceDetails,
  current_user,
  reduxCreateNewsSources,
  reduxUpdateNewsSources
}) => {
  const { id } = useParams();
  const history = useHistory();

  const [editMode, setEditMode] = useState(isNaN(+id));
  const [errorFields, setErrorFields] = useState([]);
  const [sourceDetails, setSourceDetails] = useState(SOURCE_DETAILS);

  const calculateNewsSources = () => {
    const formatCrawlDetails = fromRawInfoToDetails(newsSourceDetails);
    setSourceDetails(formatCrawlDetails);
  };

  useEffect(calculateNewsSources, [newsSourceDetails]);

  useEffect(() => {
    // redirect to source details page after source creation
    // !editMode to handle situation when there is some source in store (navigate from other page)
    if (isNaN(+id) && newsSourceDetails.id && !editMode) {
      history.push(`${CRAWL_ROUTES.sources}/${newsSourceDetails.id}`);
    }
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsSourceDetails.id]);

  const toggleEditMode = () => {
    if (editMode) {
      calculateNewsSources();
    }
    setEditMode(!editMode);
  };

  const handleChanges = (name, value) => {
    setSourceDetails({
      ...sourceDetails,
      [name]: value
    });
  };

  const handleChangesEvent = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const saveChanges = async () => {
    const { sourceDetails: sourceInfo, errors } = await fromDetailsToRawInfo(
      sourceDetails,
      newsSourceDetails
    );
    sourceInfo.user = `${lodash.get(current_user, 'first_name')} ${lodash.get(
      current_user,
      'last_name'
    )}`;
    if (errors.length) {
      setErrorFields(errors.map(err => err.title));
      errors.forEach(error =>
        setNotification({
          message: { text: error, title: 'Error' },
          type: NOTIFICATION_TYPES.ERROR
        })
      );
      return;
    }
    toggleEditMode();
    if (!isNaN(+id)) {
      reduxUpdateNewsSources({ ...sourceInfo, id });
    } else {
      reduxCreateNewsSources(sourceInfo);
    }
  };

  const switchSourceType = type => {
    if (type.value === SOURCE_TYPES.AGENCY) {
      history.push(`${CRAWL_ROUTES.agenciesAdminTool}/new`);
    } else if (type.value === SOURCE_TYPES.NEWS) {
      history.push(`${CRAWL_ROUTES.sources}/new`);
    }
  };

  return (
    <div className="sourceDetails">
      <div className="sourceDetails_body">
        <SourceDetailsGeneral
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          sourceDetails={sourceDetails}
          saveChanges={saveChanges}
          handleChanges={handleChanges}
          handleChangesEvent={handleChangesEvent}
          errorFields={errorFields}
          switchSource={switchSourceType}
        />
      </div>
      <div className="sourceDetails_rightBar">
        <SourceDetailsAdditionalInfo
          editMode={editMode}
          sourceDetails={sourceDetails}
          handleChanges={handleChanges}
          handleChangesEvent={handleChangesEvent}
          errorFields={errorFields}
        />
      </div>
    </div>
  );
};

SourceDetailsElements.propTypes = {
  newsSourceDetails: PropTypes.shape({}),
  current_user: PropTypes.shape({})
};

SourceDetailsElements.defaultProps = {
  newsSourceDetails: {},
  current_user: {}
};

const mapStateToProps = state => ({
  current_user: state.current_user.user,
  newsSourceDetails: lodash.get(state.newsSources, ['items', 0], {})
});

export const SourceDetails = connect(mapStateToProps, {
  reduxCreateNewsSources: createNewsSources,
  reduxUpdateNewsSources: updateNewsSources
})(SourceDetailsElements);
