import type { EitlLabelsImportModalData } from './useEitlLabelsImportModalData.types';
import type { EitlLabelsImportModalFormattedData } from './useEitlLabelsImportModalFormattedData.types';
import type { useEitlLabelsImportModalMutations } from './useEitlLabelsImportModalMutations';

import { useMemo } from 'react';

export const useEitlLabelsImportModalFormattedData = ({
  localState,
  mutations
}: {
  localState: EitlLabelsImportModalData['localState'];
  mutations: ReturnType<typeof useEitlLabelsImportModalMutations>;
}): EitlLabelsImportModalFormattedData =>
  useMemo(
    () => ({
      isSubmitButtonDisabled: !localState.attachments.length || mutations.importEitlLabels.isLoading
    }),
    [localState.attachments.length, mutations.importEitlLabels.isLoading]
  );
