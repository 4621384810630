import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NAMES_EXTRACT_TAGS, ERRORS, INFO_HELP } from '../CrawlSpecCreate.constants';
import ExpandableBtnList from '../HelpComponents/ExpandableBtnList';
import SpecElementsWrapper from "./SpecElementsWrapper";

const ExtractTags = ({
  disabled,
  show,
  currentPathUUID,
  element,
  changeExtractTags,
  changeCurrentPath,
  addNewPath,
  clearPathElements,
  errorFieldsPaths,
  errorFields
}) => {
  return (
    <SpecElementsWrapper
      show={show}
      label="Remove Info"
      tooltip={INFO_HELP.EXTRACT_TAGS}
    >
      <ExpandableBtnList
        label="Paths"
        isDisabled={disabled}
        value={element[NAMES_EXTRACT_TAGS.PATH]}
        name={NAMES_EXTRACT_TAGS.PATH}
        addMoreName="Path"
        activeID={currentPathUUID}
        onChange={changeExtractTags}
        onClickBtn={changeCurrentPath}
        createNewItemTemplate={addNewPath}
        helpRemoveFunc={clearPathElements}
        errorFields={Object.keys(errorFieldsPaths)}
        isError={
          errorFields.includes(NAMES_EXTRACT_TAGS.PATH)
          && !_.get(element, [NAMES_EXTRACT_TAGS.PATH, 'length'], '')
        }
        errorText={ERRORS.extractTagsPath}
      />
    </SpecElementsWrapper>
  );
};

ExtractTags.propTypes = {
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  currentPathUUID: PropTypes.string,
  element: PropTypes.shape({}),
  changeExtractTags: PropTypes.func.isRequired,
  changeCurrentPath: PropTypes.func.isRequired,
  addNewPath: PropTypes.func.isRequired,
  clearPathElements: PropTypes.func.isRequired,
  errorFieldsPaths: PropTypes.shape({}),
  errorFields: PropTypes.arrayOf(PropTypes.string)
};

ExtractTags.defaultProps = {
  disabled: false,
  currentPathUUID: '',
  show: false,
  element: {},
  errorFieldsPaths: {},
  errorFields: []
};

export default ExtractTags;
