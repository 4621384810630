import type { ScrapedCategoriesApi } from './useScrapedCategoriesApi.types';

import * as hookConstants from './useScrapedCategoriesApi.constants';
import * as api from '../scrapedCategories.apiV2';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { StatusCodes } from 'http-status-codes';

export const useScrapedCategoriesApi = (): ScrapedCategoriesApi => {
  const getScrapedCategories: ScrapedCategoriesApi['getScrapedCategories'] = async params => {
    try {
      const response = await api.fetchScrapedCategoriesData(params);

      return response;
    } catch (e) {
      errorUtils.logError(e as Error);
      throw e;
    }
  };

  const modifyScrapedCategory: ScrapedCategoriesApi['modifyScrapedCategory'] = async ({
    id,
    params
  }) => {
    try {
      const response = await api.modifyScrapedCategory(id, params);

      return response;
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      if (isValidationError) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
          title: 'Validation error',
          message:
            hookConstants.SCRAPED_CATEGORIES_ERRORS_MESSAGES.SCRAPED_CATEGORIES_NAME_VALIDATION
        });
      }

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  return {
    getScrapedCategories,
    modifyScrapedCategory
  };
};
