import type { CrawlDetailsHandlers } from './useCrawlDetailsHandlers.types';
import type { CrawlDetailsData } from './useCrawlDetailsData.types';
import type { SelectOption } from '@compliance.ai/web-components';

import * as errorUtils from 'shared/utils/errors';

import { NOTIFICATION_TYPES, setNotification } from 'admin/components/Notification';
import { fromDetailsToRawInfo, fromRawInfoToDetails } from '../CrawlerDetails.helper';
import {
  CRAWL_GENERAL,
  CRAWL_PROVISION,
  getDevComplexityClassification
} from '../CrawlerDetails.constants';
import CRAWL_ROUTES from 'admin/constants/routes';

import { useExternalApiCrawlReduxActions } from 'shared/features/externalApiCrawl/hooks';
import { useCrawlerCommentsReduxActions } from 'shared/features/crawlerSettings/crawlerComments/hooks';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const useCrawlDetailsHandlers = ({
  reduxState,
  localState,
  localActions,
  formattedData
}: {
  reduxState: CrawlDetailsData['reduxState'];
  localState: CrawlDetailsData['localState'];
  localActions: CrawlDetailsData['localActions'];
  formattedData: CrawlDetailsData['formattedData'];
}): CrawlDetailsHandlers => {
  const navigate = useNavigate();
  const externalApiCrawlReduxActions = useExternalApiCrawlReduxActions();
  const crawlerCommentsReduxActions = useCrawlerCommentsReduxActions();

  const handleCalculateCrawlDetails: CrawlDetailsHandlers['handleCalculateCrawlDetails'] =
    useCallback(() => {
      const formatCrawlDetails = fromRawInfoToDetails(reduxState.crawlDetailsInfo);
      localActions.setCrawlDetails(formatCrawlDetails);
    }, [localActions, reduxState.crawlDetailsInfo]);

  const handleCreateNewCrawl: CrawlDetailsHandlers['handleCreateNewCrawl'] = useCallback(() => {
    // redirect to crawl details page after crawl creation
    // !editMode to handle situation when there is some crawls in store (navigate from other page)
    if (
      isNaN(Number(formattedData.id)) &&
      reduxState.crawlDetailsInfo?.id &&
      !localState?.editMode
    ) {
      const nesComments = [...reduxState.comments].reverse();
      nesComments.forEach(comment => {
        crawlerCommentsReduxActions.createCommentAC({
          ...comment,
          crawl_id: reduxState.crawlDetailsInfo?.id
        });
      });
      navigate(`${CRAWL_ROUTES.crawlerTool}/${reduxState.crawlDetailsInfo?.id}`);
    }
  }, [
    crawlerCommentsReduxActions,
    formattedData.id,
    localState?.editMode,
    navigate,
    reduxState.comments,
    reduxState.crawlDetailsInfo?.id
  ]);

  const handleToggleEditMode: CrawlDetailsHandlers['handleToggleEditMode'] = () => {
    if (localState.editMode) {
      handleCalculateCrawlDetails();
    }
    localActions.setEditMode(!localState.editMode);
  };

  const handleOpenModal: CrawlDetailsHandlers['handleOpenModal'] = () => {
    localActions.setShowModal(true);
  };

  const handleCloseModal: CrawlDetailsHandlers['handleCloseModal'] = () => {
    localActions.setShowSentencificationConfigurationModal(false);
  };

  const handleOpenSentencificationConfigurationModal: CrawlDetailsHandlers['handleOpenSentencificationConfigurationModal'] =
    () => {
      const crawl_details = fromRawInfoToDetails(reduxState.crawlDetailsInfo);
      const sentencification_configuration = crawl_details?.sentencification_configuration;
      localActions.setCrawlSentencificationConfiguration(
        JSON.stringify(sentencification_configuration)
      );
      localActions.setShowSentencificationConfigurationModal(true);
    };

  const handleChangeSentencificationConfiguration: CrawlDetailsHandlers['handleChangeSentencificationConfiguration'] =
    (changedConfiguration: string) => {
      localActions.setCrawlSentencificationConfiguration(changedConfiguration);
    };

  const handleSaveCrawlSourceStructure: CrawlDetailsHandlers['handleSaveCrawlSourceStructure'] =
    () => {
      localActions.setShowViewModal(true);
      localActions.setShowModal(true);
    };

  const handleChanges: CrawlDetailsHandlers['handleChanges'] = (name, value) => {
    if (name === CRAWL_GENERAL.devComplexity) {
      return localActions.setCrawlDetails(crawlDetails => ({
        ...crawlDetails,
        [name]: value,
        [CRAWL_GENERAL.devComplexityClassification]: getDevComplexityClassification({
          ...crawlDetails,
          [CRAWL_GENERAL.devComplexity]: value
        })
      }));
    }
    if (name === CRAWL_GENERAL.crawlType) {
      return localActions.setCrawlDetails(crawlDetails => ({
        ...crawlDetails,
        [name]: value,
        [CRAWL_GENERAL.devComplexity]: '',
        [CRAWL_GENERAL.devComplexityClassification]: getDevComplexityClassification({
          ...crawlDetails,
          [CRAWL_GENERAL.devComplexity]: ''
        })
      }));
    }
    if (name === CRAWL_PROVISION.enableAutoTranslation) {
      const selectOption = value as SelectOption;
      return localActions.setCrawlDetails(crawlerDetails => ({
        ...crawlerDetails,
        [name]: selectOption.value
      }));
    }

    return localActions.setCrawlDetails(crawlDetails => ({
      ...crawlDetails,
      [name]: value
    }));
  };

  const handleChangesEvent: CrawlDetailsHandlers['handleChangesEvent'] = e => {
    const { name, value } = e.target;
    handleChanges(name, value);
  };

  const handleSaveChanges: CrawlDetailsHandlers['handleSaveChanges'] = async () => {
    const { crawlDetails: crawlInfo, errors } = fromDetailsToRawInfo(
      localState.crawlDetails,
      reduxState.crawlDetailsInfo
    );
    crawlInfo.user = `${reduxState.currentUser?.first_name} ${reduxState.currentUser?.last_name}`;
    if (errors.length) {
      localActions.setErrorFields(errors.map(err => err?.title));
      errors.forEach(error => setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR }));
      return;
    }
    handleToggleEditMode();
    if (!isNaN(Number(formattedData.id))) {
      try {
        const responseData = await externalApiCrawlReduxActions.updateExternalApiCrawls({
          ...crawlInfo,
          id: formattedData.id
        });
        if (responseData.id) {
          localActions.setErrorFields([]);
        }
      } catch (e) {
        errorUtils.logError(e as Error);
      }
    } else {
      try {
        const responseData = await externalApiCrawlReduxActions.createExternalApiCrawls(crawlInfo);
        const responseDataId = responseData?.id;
        if (responseDataId) {
          localActions.setErrorFields([]);
          navigate(`${CRAWL_ROUTES.crawlerTool}/${responseDataId}`);
          externalApiCrawlReduxActions.getExternalApiCrawls({ id: responseDataId });
        }
      } catch (e) {
        errorUtils.logError(e as Error);
      }
    }
  };

  return {
    handleCalculateCrawlDetails,
    handleCreateNewCrawl,
    handleOpenModal,
    handleCloseModal,
    handleSaveCrawlSourceStructure,
    handleToggleEditMode,
    handleChanges,
    handleChangesEvent,
    handleSaveChanges,
    handleOpenSentencificationConfigurationModal,
    handleChangeSentencificationConfiguration
  };
};
