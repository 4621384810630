import type { FC } from 'react';
import type { EitlLabelDeleteModalProps } from './EitlLabelDeleteModal.types';

import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import pluralize from 'pluralize';

import { useEitlLabelDeleteModalMutations } from './hooks';

import './_eitl-label-delete-modal.scss';

export const EitlLabelDeleteModal: FC<EitlLabelDeleteModalProps> = ({
  label,
  onConfirm,
  onClose
}) => {
  const mutations = useEitlLabelDeleteModalMutations({
    props: {
      label,
      onConfirm
    }
  });

  return (
    <uiLib.Modal
      isOpen
      title={`Delete "${label.name}"?`}
      onClose={onClose}
      onSubmit={mutations.deleteEitlLabel.mutate}
      withFooter
      secondaryButtonType={uiLib.BUTTON_TYPES.LINK}
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={mutations.deleteEitlLabel.isLoading}
      primaryButtonType={uiLib.BUTTON_TYPES.DELETE}
      primaryButtonText="Delete"
      classNames={{
        container: 'eitl-label-delete-modal__container',
        content: 'eitl-label-delete-modal__content'
      }}
    >
      <div className="eitl-label-delete-modal__content-container">
        {mutations.deleteEitlLabel.isLoading && <uiLib.Loader withoutBackground={false} />}
        <div className="eitl-label-delete-modal__message-container">
          The EITL Label will be deleted from {label.documentCount}{' '}
          {pluralize('document', label.documentCount)}.
        </div>
      </div>
    </uiLib.Modal>
  );
};
