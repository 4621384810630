import type { FetchNewsSourcesPayload } from 'shared/features/sources/newsSources/newsSources.api.types';

import * as uiLib from '@compliance.ai/web-components';
import * as newsSourcesConstants from 'shared/features/sources/newsSources/newsSources.constants';

export const formatMultiSelectValues =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const values = filterValue as uiLib.SelectOption[];

    if (Array.isArray(values) && values.length) {
      return {
        [queryParamKey]: values.map(({ value }) => value)
      };
    }

    return {};
  };

export const NEWS_SOURCE_FILTER_VALUES_PARSERS: Record<
  string,
  (value: uiLib.FilterValue) => FetchNewsSourcesPayload[0]
> = {
  [newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.JURISDICTION_IDS]:
    formatMultiSelectValues({
      queryParamKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.JURISDICTION_IDS
    }),
  [newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.AGENCY_IDS]: formatMultiSelectValues({
    queryParamKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.AGENCY_IDS
  })
};

export const formatNewsSourcesQueryParams = (
  values: uiLib.FiltersProps['values'],
  searchValue: string
): Record<string, unknown> => {
  return Object.entries(values).reduce(
    (formattedQueryParams, [filterKey, filterValue]) => {
      return {
        ...formattedQueryParams,
        ...NEWS_SOURCE_FILTER_VALUES_PARSERS[filterKey](filterValue)
      };
    },
    {
      [newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.NAME]: searchValue
    } as Record<string, unknown>
  );
};
