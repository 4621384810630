import type { CategoryEditModalProps } from '../CategoryEditModal.types';
import type { CategoryEditModalData } from './useCategoryEditModalData.types';
import type * as caiCateogryApiTypes from 'shared/features/categories/caiCategories/caiCategories.apiV2.types';

import * as queryKeysConstants from 'admin/constants/queryKeys';
import * as errorUtils from 'shared/utils/errors';
import * as uiLib from '@compliance.ai/web-components';

import { useMutation } from '@tanstack/react-query';
import { useCaiCategoriesApi } from 'shared/features/categories/caiCategories/hooks';
import { formatEditRequestParams } from './useCategoryEditModalMutations.helpers';

export const useCategoryEditModalMutations = ({
  props,
  localState
}: {
  props: Pick<CategoryEditModalProps, 'category' | 'onConfirm'>;
  localState: CategoryEditModalData['localState'];
}) => {
  const caiCategoriesApi = useCaiCategoriesApi();

  const updateCaiCategory = useMutation<
    Awaited<caiCateogryApiTypes.NormalizedCaiCategory>,
    Error,
    void
  >({
    mutationKey: queryKeysConstants.COMPLEX_QUERY_KEY_GETTERS[
      queryKeysConstants.QUERY_KEYS.CAI_CATEGORIES
    ][queryKeysConstants.QUERY_SECTIONS.CONTENT_MANAGEMENT_CAI_CATEGORIES_EDIT]({
      id: Number(props?.category?.id),
      name: localState.caiCategoryName,
      description: localState.caiCategoryDescription,
      surface_in_filter: localState.surfaceInFilter,
      doc_meta_category_id: localState.docMetaCategory?.value
    }),
    mutationFn: () =>
      caiCategoriesApi.modifyCaiCategory({
        id: Number(props?.category?.id),
        params: formatEditRequestParams({ category: props.category, localState })
      }),
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      props.onConfirm();

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        message: 'Cai Category successfully updated'
      });
    }
  });

  return {
    updateCaiCategory
  };
};
