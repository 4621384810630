import { DOC_ACTIONS } from '../../DocumentDetails.constants';

export const documentFlagIssueTypes = {
  TECHNICAL: { value: 'technical', label: 'Technical' },
  NOT_RELEVANT: { value: 'not relevant', label: 'Not relevant' }
};

export const documentFlagOptions = {
  [DOC_ACTIONS.FLAG.type]: { value: 'review', label: 'Review' },
  [DOC_ACTIONS.HIDE.type]: { value: 'hide_now', label: 'Hide now' },
  [DOC_ACTIONS.SET_DUPLICATE.type]: DOC_ACTIONS.SET_DUPLICATE,
  [DOC_ACTIONS.SET_POTENTIAL_DUPLICATE.type]: DOC_ACTIONS.SET_POTENTIAL_DUPLICATE
};
