import { setNotification, NOTIFICATION_TYPES } from "admin/components/Notification";
import {
  api_getDocuments,
  api_putDocuments,
  api_postDocuments,
  api_deleteDocuments
} from './documentData.api';
import {
  startLoad,
  finishLoad,
  setDocument,
  updateDocument,
  createDocument,
  deleteDocument,
  initialState
} from './documentData.reducer';

const SUCCESS = {
  documentCreated: 'Document successfully created',
  documentUpdated: 'Document successfully updated',
  documentDeleted: 'Document successfully deleted'
};

export const getDocuments = params => dispatch => {
  dispatch(startLoad());
  return api_getDocuments(params)
    .then(res => dispatch(setDocument(res)))
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const createDocuments = params => dispatch => {
  dispatch(startLoad());
  return api_postDocuments(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.documentCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createDocument(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const updateDocuments = params => dispatch => {
  dispatch(startLoad());
  return api_putDocuments(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.documentUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateDocument(res));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const removeDocuments = params => dispatch => {
  dispatch(startLoad());
  return api_deleteDocuments(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.documentDeleted, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteDocument(params));
    })
    .catch(res => dispatch(finishLoad()))
    .then(res => dispatch(finishLoad()));
};

export const clearDocuments = () => dispatch => {
  dispatch(finishLoad());
  return dispatch(setDocument(initialState));
};
