import uuidv4 from 'uuid/v4';
import { typeOfField } from "../../components/SelectRender";
import { popoverName } from "../../constants/popoverConst";

// Input settings

const mainCrawlSettings = [
  {
    state_name: 'spider_name',
    name: 'Spider Name',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'api_table',
    name: 'Api Table',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'meta_table',
    name: 'Meta Table',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'jurisdiction',
    name: 'Jurisdiction',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'agency',
    name: 'Agency',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'agency_id',
    name: 'Agency Id',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'short_name',
    name: 'Short Name',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'category',
    name: 'Category',
    required: false,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'base_url',
    name: 'Base Url',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'content_url',
    name: 'Content Url',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'specification',
    name: 'Specification',
    required: true,
    typeOfInput: typeOfField.input
  }
];

const stepFieldTemplate = [
  {
    state_name: 'name',
    name: 'Step Name',
    required: true,
    typeOfInput: typeOfField.input
  },
  {
    state_name: 'description',
    name: 'Step Description',
    required: true,
    typeOfInput: typeOfField.input
  }
];

const contextCheckBox = {
  state_name: 'contextIsUrl',
  name: 'Is Url',
  typeOfInput: typeOfField.checkbox
};

const docMetaData = {
  state_name: 'name',
  name: 'Meta Data',
  required: true,
  typeOfInput: typeOfField.createSelect
};

const exampleFieldTemplate = [
  {
    state_name: 'url',
    name: 'Url',
    required: true,
    typeOfInput: typeOfField.input,
    popover: popoverName.exampleUrlInfo
  },
  {
    state_name: 'text',
    name: 'Text',
    required: true,
    typeOfInput: typeOfField.input,
    popover: popoverName.exampleTxtInfo
  },
  {
    state_name: 'occurrence',
    name: 'Occurrence',
    required: true,
    typeOfInput: typeOfField.input,
    popover: popoverName.exampleOccurrenceInfo
  }
];

const nextStepFieldTemplate = {
  name: 'Next Step',
  state_name: 'next_step',
  categories: [],
  typeOfInput: typeOfField.select,
  multiType: true
};

// Templates

const stepTemplate = {
  name: '',
  description: '',
  contextIsUrl: false,
  contextExamples: [],
  doc: []
};

const firstStepTemplate = {
  id: uuidv4(),
  name: 'start',
  description: 'Starting step that loads main web-page.',
  isNotEditable: true
};

const exampleTemplate = {
  url: '',
  text: '',
  occurrence: '1'
};

const documentTemplate = {
  name: '',
  examples: []
};

// Other constants

const metaDataName = [
  'web_url',
  'pdf_url',
  'title',
  'pub_date',
  'html'
];

export {
  mainCrawlSettings,
  stepTemplate,
  firstStepTemplate,
  exampleTemplate,
  stepFieldTemplate,
  contextCheckBox,
  nextStepFieldTemplate,
  exampleFieldTemplate,
  documentTemplate,
  docMetaData,
  metaDataName
};
