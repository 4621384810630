import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table } from 'react-bootstrap';
import Moment from 'react-moment';
import { fetchAnnotationTasksByUser } from 'shared/features/annotations/annotations.actions';

class OnboardingUserDetail extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchAnnotationTasksByUser(this.props.topic_group_id, this.props.user_id);
  }

  renderUserStats = task_details => {
    const {
      admin_notes,
      created_at,
      doc_id,
      doc_title,
      gold_difficulty,
      gold_tags,
      is_correct_judgment,
      is_positive,
      updated_at,
      user_notes
    } = task_details;

    // const easyToReadDate = moment(created_at);
    const uniqueKey = `${doc_id}${created_at}`;
    let is_correct_judgment_string = '';
    let is_positive_string = '';

    if (is_correct_judgment !== null) {
      is_correct_judgment_string = is_correct_judgment.toString();
    }
    if (is_positive !== null) {
      is_positive_string = is_positive.toString();
    }

    return (
      <tr key={uniqueKey}>
        <td>
          <Moment date={updated_at} format="MMM d, YYYY h:mm A" />
        </td>
        <td>{doc_id}</td>
        <td>{doc_title}</td>
        <td>{is_correct_judgment_string}</td>
        <td>{gold_difficulty}</td>
        <td>{gold_tags}</td>
        <td>{is_positive_string}</td>
        <td>{user_notes}</td>
        <td>{admin_notes}</td>
      </tr>
    );
  }

  render() {
    const tableHeaders = [
      'Date Completed',
      'ID',
      'Title',
      'Accurate',
      'Gold Difficulty',
      'Gold Tag',
      'User Annotation',
      'User Notes',
      'Gold Standard Notes'
    ];

    if (
      this.props.topic_group_id === null
      || this.props.topic_group_id === undefined
      || this.props.topic_group_id < 1
    ) {
      return <div>This topic has not been assigned a topic group ID.</div>;
    }

    if (
      !this.props.onboarding_user_detail
      || this.props.onboarding_user_detail === undefined
      || this.props.onboarding_user_detail.length < 1
    ) {
      return <div>Loading User Statistics by Topic Groups</div>;
    }

    return (
      <div className="onboarding-user-detail">
        <div className="fixed-table-header-container">
          <Table>
            <thead>
              <tr>
                {
                  tableHeaders.map(title => {
                    return (
                      <td key={title}>{title}</td>
                    );
                  })
                }
              </tr>
            </thead>
            <tbody>
              {this.props.onboarding_user_detail.map(this.renderUserStats)}
            </tbody>
          </Table>
        </div>
        {/* <Pagination
          bsSize="small"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          items={Math.ceil(this.state.total / this.state.limit)}
          maxButtons={5}
          activePage={this.state.page}
          onSelect={this.handlePageChange}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = ({ onboarding_user_detail }) => {
  return { onboarding_user_detail };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchAnnotationTasksByUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingUserDetail);
